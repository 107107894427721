import * as React from 'react';
import { Box, useTheme } from '~/components/ui/mui';

interface Props {
  color: string | null | undefined;
  width: string | number;
}

export const PercentBar: React.FC<Props> = ({ color, width }) => {
  const {
    sfAssetAllocationTable: { styles: sfAssetAllocationTableStyles },
  } = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        verticalAlign: 'text-top',
        width: sfAssetAllocationTableStyles.percentBarWidth,
      }}
    >
      <Box
        sx={{
          bgcolor: color,
          display: 'inline-block',
          height: sfAssetAllocationTableStyles.percentBarHeight,
          width,
        }}
      />
    </Box>
  );
};
