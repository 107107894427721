import { useCallback, useContext, useMemo, useState } from 'react';

import OpsDashboardContext from '../../OpsDashboardContext';
import { SearchContextLiterals, TOO_MANY_SEARCH_RECORDS_ERROR } from '../../types';
import { useGetBankVerificationContentV2 } from '../contentstack';
import { QueryVariables, useGetBankVerificationRequests } from '../symphony';
import { bankVerificationTableFactory, getFormattedItems } from '../utils';
import { getBankVerificationLabelContent, getFilterCountMap, getTableColumns } from '../utils/mappers';
import {
  BankVerificationDefaultAppliedFilters,
  BankVerificationFilterConfig,
  BankVerificationTabData,
  geSelectedFilterStatuses,
} from '../utils/types';

import { FinancialAccountAssociationVerificationRequestSortField, OrderType } from '~/__generated__';
import { SelectedFilters } from '~/components/Filters/types';
import { SortConfig, TableData } from '~/components/ui/BasicTable';
import { commentColumnFactory, getSearchContext } from '~/containers/OpsDashboardV2/utils';
import { useCoreConfig } from '~/utils/config';
import { findFieldValue } from '~/utils/contentstack';
import { getPaginationContext } from '~/utils/table';
import { AsyncResult } from '~/utils/types';

// Set 10 as constant first until we have requirements to change it
const initialRecordsPerPage = 10;

export interface BankVerificationsVariable {
  handleReviewClick: (requestId: string) => void;
}

export const useGetBankVerificationTabData = ({
  handleReviewClick,
}: BankVerificationsVariable): AsyncResult<BankVerificationTabData> => {
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  const {
    contentOptions,
    onAccountClick,
    onClientClick,
    getClientNameRedirectUrl,
    getAccountNumberRedirectUrl,
    onParamsUpdate,
    params,
    opsContentData,
    currentUser,
  } = useContext(OpsDashboardContext);

  const state = useMemo(() => {
    let value;
    try {
      value = JSON.parse(params.state || '{}');
    } catch (e) {
      return BankVerificationDefaultAppliedFilters;
    }

    return value;
  }, [params]);

  const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
  const [activeRowId, setActiveRowId] = useState<string | undefined>();
  const appliedFilters: SelectedFilters = state.filters || BankVerificationDefaultAppliedFilters;
  const currentPage: number = state.currentPage || 1;
  const recordsPerPage: number = state.recordsPerPage || initialRecordsPerPage;
  const searchFilter = state.searchFilter ?? null;
  const setCurrentPage = useCallback(
    (page: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: page }) });
    },
    [onParamsUpdate, params, state],
  );
  const setRecordsPerPage = useCallback(
    (value: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, recordsPerPage: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const setSearchFilter = useCallback(
    (value: string | null) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, searchFilter: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const sortConfig: SortConfig = useMemo(
    () =>
      state.sortConfig || {
        field: 'dateCreated',
        order: OrderType.DESCENDING,
      },
    [state],
  );

  const onPageChange = useCallback(
    (selectedPage: number) => {
      setCurrentPage(selectedPage);
    },
    [setCurrentPage],
  );

  const onRowHover = useCallback(
    (rowId: string | undefined) => {
      setActiveRowId(rowId);
    },
    [setActiveRowId],
  );

  const updateRecordsPerPage = useCallback(
    (value: number) => {
      setRecordsPerPage(value);
    },
    [setRecordsPerPage],
  );

  const onSearchChange = useCallback(
    (searchText: string | null) => {
      setSearchFilter(searchText);
    },
    [setSearchFilter],
  );

  const onFilterChange = useCallback(
    (filters: SelectedFilters) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, filters }) });
    },
    [onParamsUpdate, params, state],
  );

  const onAppliedFiltersSelect = useCallback(() => {
    setFiltersPopupOpen(true);
  }, []);

  const updateOpenFiltersPopup = useCallback((value: boolean) => {
    setFiltersPopupOpen(value);
  }, []);

  const { data: contentData, loading: contentLoading, error: contentError } = useGetBankVerificationContentV2({
    variables: contentOptions,
  });

  const variables: QueryVariables = {
    statuses: geSelectedFilterStatuses(appliedFilters),
    pagination: {
      limit: recordsPerPage,
      offset: (currentPage - 1) * recordsPerPage,
    },
    order: sortConfig.order,
    searchFilter,
    fetchLatestNote: true,
    field: FinancialAccountAssociationVerificationRequestSortField.CREATED_ON,
  };

  const {
    data: bankVerificationData,
    loading: bankVerificationLoading,
    error: bankVerificationError,
    refetch: refetchBankVerificationData,
  } = useGetBankVerificationRequests({
    fetchPolicy: 'no-cache',
    variables,
  });

  const data: BankVerificationTabData = useMemo(() => {
    const onSort = () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      onParamsUpdate({
        ...params,
        state: JSON.stringify({
          ...state,
          currentPage: 1,
          sortConfig: { field: 'dateCreated', order: toggleOrder },
        }),
      });
    };

    const items = contentData?.all_bank_verification_table?.items?.[0];
    const textFields = items?.fields?.text ?? [];
    const fields = opsContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const filterContext = bankVerificationData?.financialAccountAssociationVerificationRequests.filterContext;
    const total = bankVerificationData?.financialAccountAssociationVerificationRequests.paginationContext.total ?? 0;
    const paginationContext = getPaginationContext(fields, currentPage, recordsPerPage, total, updateRecordsPerPage);
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_BANK_VERIFICATIONS);
    const bankVerificationItems = bankVerificationTableFactory(bankVerificationData, opsContentData);
    const opsDashboardContent = opsContentData?.all_ops_dashboard?.items?.[0];
    const commentColumn = commentColumnFactory(opsDashboardContent?.comment_column ?? undefined);

    const contentLabels = getBankVerificationLabelContent(items?.labels ?? []);
    const tableColumns = getTableColumns(contentData, onSort);

    const tableData: TableData[] = getFormattedItems({
      commentProps: {
        commentColumn,
        contentOptions,
        currentUser,
        refetchBankVerificationData,
      },
      items: bankVerificationItems,
      showProductName,
      onAccountClick,
      onClientClick,
      handleReviewClick,
      content: {
        reviewButtonLabel: contentLabels.reviewButtonLabel,
        textFields,
      },
      getClientNameRedirectUrl,
      getAccountNumberRedirectUrl,
      activeRowId,
      opsContentData,
    });

    return {
      bankVerificationFilterConfig: BankVerificationFilterConfig.map(filter => {
        return {
          ...filter,
          label: findFieldValue(textFields, `${filter.key.toLowerCase()}_filter`),
          options: filter.options.map(option => {
            return {
              ...option,
              label: findFieldValue(textFields, option.id.toLowerCase()),
            };
          }),
        };
      }),
      appliedFilters,
      filtersPopupOpen,
      updateOpenFiltersPopup,
      tableData,
      onFilterChange,
      appliedFiltersContent: {
        allFilter: findFieldValue(textFields, 'all_filter'),
        appliedFilters: findFieldValue(textFields, 'applied_filters'),
      },
      filtersContent: {
        allFilter: findFieldValue(textFields, 'all_filter'),
        apply: findFieldValue(textFields, 'apply_cta'),
        filters: findFieldValue(textFields, 'filters_cta'),
        resetAll: findFieldValue(textFields, 'reset_all_cta'),
      },
      onAppliedFiltersSelect,
      onPageChange,
      onRowHover,
      onSearchChange,
      currentPage,
      refetchData: refetchBankVerificationData,
      sortConfig,
      tableColumns,
      currentSearchFilter: searchFilter,
      totalPages: Math.ceil(total / recordsPerPage),
      filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
      paginationContext,
      searchError: false,
      searchContext,
    };
  }, [
    appliedFilters,
    bankVerificationData,
    contentData,
    contentOptions,
    currentPage,
    currentUser,
    filtersPopupOpen,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
    handleReviewClick,
    onAccountClick,
    onAppliedFiltersSelect,
    onClientClick,
    onFilterChange,
    onPageChange,
    onParamsUpdate,
    onSearchChange,
    opsContentData,
    params,
    refetchBankVerificationData,
    searchFilter,
    showProductName,
    sortConfig,
    state,
    updateOpenFiltersPopup,
    recordsPerPage,
    updateRecordsPerPage,
  ]);

  if (bankVerificationError?.message === TOO_MANY_SEARCH_RECORDS_ERROR) {
    return {
      data: { ...data, searchError: true },
      error: contentError,
      loading: contentLoading || bankVerificationLoading,
    };
  }

  return {
    data,
    error: bankVerificationError || contentError,
    loading: contentLoading || bankVerificationLoading,
  };
};
