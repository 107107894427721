import {
  differenceInDays,
  format,
  isAfter,
  isValid,
  startOfDay,
  startOfToday,
  startOfTomorrow,
  toDate,
} from 'date-fns';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';

import { DatePicker } from '~/components/ui/DatePicker';
import { Box, DateValidationError, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';

export type DateRange = { endDate: Date; startDate: Date };

export interface Props extends Partial<Omit<ComponentProps<typeof DatePicker>, 'onChange' | 'onError'>> {
  dataQa?: string;
  defaultValues?: DateRange;
  labels?: {
    endDate: string;
    endDateErrorLabel: string;
    invalidDateRangeErrorLabel: string;
    minDateErrorLabel: string;
    startDate: string;
    startDateErrorLabel: string;
  };
  maxDate?: Date;
  minDate?: Date;
  onChange?: (dateRange: DateRange | undefined) => void;
  onError?: (reason: DateValidationError, dateRange: DateRange | undefined) => void;
}

export const DateRangePicker: React.FC<Props> = ({
  dataQa,
  defaultValues,
  key,
  labels,
  maxDate,
  // MUI Date Picker default min date is 01/01/1900
  minDate = new Date('1900-01-01'),
  onChange,
  onError,
  ...commonDatePickerProps
}) => {
  const [endDate, setEndDate] = useState<Date | undefined>(defaultValues?.endDate);
  const [startDate, setStartDate] = useState<Date | undefined>(defaultValues?.startDate);

  const invalidDateRangeError = useMemo(() => {
    if (!endDate || !startDate) {
      return false;
    }
    return differenceInDays(startDate, endDate) > 0;
  }, [endDate, startDate]);

  const { disableFuture, disablePast } = commonDatePickerProps;
  const tomorrow = startOfTomorrow();
  const todayStart = startOfToday();
  const earliestDate = useMemo(
    // Check if past is disabled, and use the soonest of minDate or yesterday, else use minDate
    () => startOfDay(disablePast ? (isAfter(todayStart, minDate) ? todayStart : minDate) : minDate),
    [disablePast, minDate, todayStart],
  );
  const latestDate = useMemo(
    // Check if future is disabled, and use the soonest of maxDate or tomorrow, else use maxDate
    () => (disableFuture ? (maxDate ? (isAfter(tomorrow, maxDate) ? maxDate : tomorrow) : tomorrow) : maxDate),
    [disableFuture, maxDate, tomorrow],
  );
  const startDateMinDateError = useMemo(() => {
    return startDate ? !isValid(toDate(startDate)) || isAfter(earliestDate, startDate) : false;
  }, [earliestDate, startDate]);
  const startDateMaxDateError = useMemo(() => {
    return startDate ? !isValid(toDate(startDate)) || (latestDate && isAfter(startDate, latestDate)) : false;
  }, [latestDate, startDate]);
  const endDateMinDateError = useMemo(() => {
    return endDate ? !isValid(toDate(endDate)) || isAfter(earliestDate, endDate) : false;
  }, [earliestDate, endDate]);
  const endDateMaxDateError = useMemo(() => {
    return endDate ? !isValid(toDate(endDate)) || (latestDate && isAfter(endDate, latestDate)) : false;
  }, [endDate, latestDate]);

  // To call onChange when both startDate and endDate is filled and are valid
  // otherwise call onError if startDate or/and endDate is not valid
  useEffect(() => {
    if (startDate && endDate) {
      if (invalidDateRangeError) {
        onError?.('invalidDate', { endDate, startDate });
      } else if (endDateMaxDateError || startDateMaxDateError) {
        onError?.('invalidDate', { endDate, startDate });
      } else if (startDateMinDateError || endDateMinDateError) {
        onError?.('minDate', { endDate, startDate });
      } else {
        onChange?.({ endDate, startDate });
      }
    }
  }, [
    endDate,
    endDateMaxDateError,
    endDateMinDateError,
    invalidDateRangeError,
    onChange,
    onError,
    startDate,
    startDateMaxDateError,
    startDateMinDateError,
  ]);

  return (
    <Stack data-qa={dataQa} direction="row" spacing={2}>
      <Box width="50%">
        <DatePicker
          dataQa={`${dataQa}-start-date`}
          defaultValue={defaultValues?.startDate}
          error={invalidDateRangeError || startDateMaxDateError || startDateMinDateError}
          inputLabelProps={{ sx: { my: 0.5 } }}
          key={`${key}-start-date`}
          label={labels?.startDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={setStartDate}
          {...commonDatePickerProps}
        />
        {startDateMaxDateError ? (
          <Typography color="error" component="span" id="start-date-error" role="alert" variant="caption">
            {labels?.startDateErrorLabel}
          </Typography>
        ) : startDateMinDateError ? (
          <RteContent
            config={{ minDate: format(earliestDate, 'MM/dd/yyyy') }}
            data={labels?.minDateErrorLabel ?? ''}
            role="alert"
          />
        ) : null}
      </Box>
      <Box width="50%">
        <DatePicker
          dataQa={`${dataQa}-end-date`}
          defaultValue={defaultValues?.endDate}
          error={invalidDateRangeError || endDateMaxDateError || endDateMinDateError}
          inputLabelProps={{ sx: { my: 0.5 } }}
          key={`${key}-end-date`}
          label={labels?.endDate}
          maxDate={maxDate}
          minDate={startDate ?? minDate}
          onChange={setEndDate}
          {...commonDatePickerProps}
        />
        {endDateMaxDateError || invalidDateRangeError ? (
          <Typography color="error" component="span" id="end-date-error" role="alert" variant="caption">
            {endDateMaxDateError ? labels?.endDateErrorLabel : labels?.invalidDateRangeErrorLabel}
          </Typography>
        ) : endDateMinDateError ? (
          <RteContent
            config={{ minDate: format(earliestDate, 'MM/dd/yyyy') }}
            data={labels?.minDateErrorLabel ?? ''}
            role="alert"
          />
        ) : null}
      </Box>
    </Stack>
  );
};
