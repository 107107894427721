import {
  GetAccountDetailsOverviewContent,
  GetAccountDetailsOverviewContent_all_account_details_items_sections,
  GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsAccountActivity,
  GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsAddFunds,
  GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsAssetAllocation,
  GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsBillingHistory,
  GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsBillingRates,
  GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsCompositeModelPortfolioAllocation,
  GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsPerformanceChart,
  GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsQuestions,
  GetAccountDetailsOverviewContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type AccountDetailsSection = GetAccountDetailsOverviewContent_all_account_details_items_sections;
export type PerformanceChartSection = GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsPerformanceChart;
export type AccountActivitySection = GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsAccountActivity;
export type AssetAllocationSection = GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsAssetAllocation;

export type AccountDetailsBillingRatesSection = GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsBillingRates;
export type AccountDetailsBillingHistorySection = GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsBillingHistory;
export type AddFundsSection = GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsAddFunds;
export type SupportQuestionsSection = GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsQuestions;
export type CompositeModelPortfolioAllocationSection = GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsCompositeModelPortfolioAllocation;

export const isPerformanceChartSection = (section: AccountDetailsSection | null): section is PerformanceChartSection =>
  section?.__typename === 'AccountDetailsSectionsPerformanceChart';

export const isAccountActivitySection = (section: AccountDetailsSection | null): section is AccountActivitySection =>
  section?.__typename === 'AccountDetailsSectionsAccountActivity';

export const isAssetAllocationSection = (section: AccountDetailsSection | null): section is AssetAllocationSection =>
  section?.__typename === 'AccountDetailsSectionsAssetAllocation';

export const isAccountDetailsBillingRatesSection = (
  section: AccountDetailsSection | null,
): section is AccountDetailsBillingRatesSection => section?.__typename === 'AccountDetailsSectionsBillingRates';

export const isAccountDetailsBillingHistorySection = (
  section: AccountDetailsSection | null,
): section is AccountDetailsBillingHistorySection => section?.__typename === 'AccountDetailsSectionsBillingHistory';

export const isAddFundsSection = (section: AccountDetailsSection | null): section is AddFundsSection =>
  section?.__typename === 'AccountDetailsSectionsAddFunds';

export const isSupportQuestionsSection = (section: AccountDetailsSection | null): section is SupportQuestionsSection =>
  section?.__typename === 'AccountDetailsSectionsQuestions';

export const isAccountDetailsCompositeModelPortfolioAllocationSection = (
  section: AccountDetailsSection | null,
): section is CompositeModelPortfolioAllocationSection =>
  section?.__typename === 'AccountDetailsSectionsCompositeModelPortfolioAllocation';

export const useGetAccountDetailsOverviewContent = (
  options?: QueryHookOptions<GetAccountDetailsOverviewContent, GetAccountDetailsOverviewContentVariables>,
): QueryResult<GetAccountDetailsOverviewContent, GetAccountDetailsOverviewContentVariables> => {
  return useContentstackQuery(queries.GetAccountDetailsOverviewContent, options);
};
