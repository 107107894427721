import { parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { useCashTransfers } from '../../common/CashTransfer/symphony';
import { CashTransfers, CashTransfersVariables } from '../../common/CashTransfer/symphony/__generated__/query.v2';
import { getIntegerAttributeValue, ManagedProductStatusesForCashTransfers } from '../../common/CashTransfer/utils';
import { useGetOpsDashboardContent } from '../../contentstack';
import { GetOpsDashboardContent } from '../../contentstack/__generated__/query.v2';
import { statusFilterToScheduledTransferStatus, toCashTransferStatus } from '../../mappers';
import { TransferItem, TransferStatusFilter } from '../../types';
import {
  getCurrentPage,
  getLastEditDateAndTime,
  getSortedPartnerOpsTradingSuspensions,
  getTotalPages,
} from '../../utils';
import { useGetWithdrawalTableContent } from '../contentstack';
import { GetWithdrawalTableContent } from '../contentstack/__generated__/query.v2';

import { getTaxInformation } from './utils';

import {
  Field,
  FinancialAccountType,
  ManagedProductStatus,
  OrderType,
  TransferFrequencyType,
  TransferType,
} from '~/__generated__';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export enum RecurrenceOptions {
  RECURRING,
  ONE_TIME,
}

export interface WithdrawalsTabVariables {
  contentOptions: ContentOptions;
  field: Field;
  hasOpenFlags: boolean | null;
  order: OrderType;
  page: number;
  recurrenceFilter: RecurrenceOptions;
  statusFilter: TransferStatusFilter;
}

export interface WithdrawalsTabData {
  content: GetWithdrawalTableContent;
  currentPage: number;
  refetchCashWithdrawalsData: () => void;
  totalPages: number;
  withdrawalItems: TransferItem[];
}

export const useWithdrawalsTabData = ({
  page,
  recurrenceFilter,
  statusFilter,
  contentOptions,
  field,
  order,
  hasOpenFlags,
}: WithdrawalsTabVariables): AsyncResult<WithdrawalsTabData> => {
  const [state, setState] = useState<AsyncResult<WithdrawalsTabData>>({ loading: true });

  const variables: CashTransfersVariables = useMemo(
    () => ({
      limit: 10,
      offset: (page - 1) * 10,
      frequency:
        recurrenceFilter === RecurrenceOptions.ONE_TIME
          ? TransferFrequencyType.ONE_TIME
          : TransferFrequencyType.RECURRING,
      statuses: statusFilterToScheduledTransferStatus(statusFilter),
      field,
      order,
      hasOpenFlags,
      fetchLatestNote: true,
      transferType: TransferType.WITHDRAWAL,
      managedProductStatuses: ManagedProductStatusesForCashTransfers,
    }),
    [field, order, page, recurrenceFilter, statusFilter, hasOpenFlags],
  );

  // The reason for no-cache is due to the updating the list when ever we create or resolve flag  for a withdrawal, the flagged and unflagged withdrawals table should be updated.
  // Otherwise, instead of the table showing the current flagged and unflagged withdrawals data it shows the previous cached data. There is no easy way to update the cache to reflect the correct state right now.
  const {
    data: cashWithdrawalsData,
    loading: cashWithdrawalsLoading,
    error: cashWithdrawalsError,
    refetch: refetchCashWithdrawalsData,
  } = useCashTransfers({ variables, errorPolicy: 'all', fetchPolicy: 'no-cache' });

  const {
    data: contentstackData,
    loading: contentstackLoading,
    error: contentstackError,
  } = useGetWithdrawalTableContent({
    variables: contentOptions,
  });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (cashWithdrawalsError || contentstackError || opsDashboardContentError) {
      setState({
        error: cashWithdrawalsError || contentstackError || opsDashboardContentError,
        loading: false,
      });
    }
  }, [cashWithdrawalsError, contentstackError, opsDashboardContentError]);

  useEffect(() => {
    if (
      !cashWithdrawalsLoading &&
      cashWithdrawalsData &&
      !contentstackLoading &&
      contentstackData &&
      !opsDashboardContentLoading &&
      opsDashboardContentData
    ) {
      const withdrawalItems: TransferItem[] = getWithdrawalItems(
        contentOptions,
        cashWithdrawalsData,
        opsDashboardContentData,
      );
      const { limit, offset, total } = cashWithdrawalsData.cashTransfers.paginationContext;
      const currentPage = getCurrentPage({ limit, offset });
      const totalPages = getTotalPages({ limit, total });
      setState({
        data: {
          withdrawalItems,
          currentPage,
          totalPages,
          content: contentstackData,
          refetchCashWithdrawalsData,
        },
        loading: false,
      });
    }
  }, [
    cashWithdrawalsData,
    cashWithdrawalsLoading,
    contentstackLoading,
    contentstackData,
    opsDashboardContentLoading,
    opsDashboardContentData,
    refetchCashWithdrawalsData,
    contentOptions,
  ]);

  return state;
};

const getWithdrawalItems = (
  contentOptions: ContentOptions,
  data: CashTransfers,
  opsDashboardContent?: GetOpsDashboardContent,
): TransferItem[] => {
  const programeNames = opsDashboardContent?.all_product_name?.items || [];

  return data.cashTransfers.transfers.map(item => {
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(item.managedProduct?.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = item.managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = item.managedProduct?.clientParty?.id || '';
    // only used for activated account states
    const accountState = getAccountState({
      firstRebalancedOn: item.managedProduct?.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;

    return {
      id: item.id,
      accountNumber: item.managedProduct?.financialAccountNumber || '',
      accountState,
      accountTypeText: getAccountTypeText(
        item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
        opsDashboardContent?.all_account_type?.items || [],
      ),
      amount: parseFloat(item.total.value),
      clientName: `${item.managedProduct?.clientParty?.partyPerson?.givenName ?? ''} ${
        item.managedProduct?.clientParty?.partyPerson?.familyName ?? ''
      }`,
      createdAt: item.createdOn,
      destinationBankAccount: item.bankAccount?.accountNumber || '',
      flags: item.flags,
      frequency: item.frequency,
      isManagedProductTaxSheltered: !!item.managedProduct?.isTaxSheltered,
      managedProductId: item.managedProduct?.id || '',
      notes: item.notes || undefined,
      partyId,
      productName: getAccountProgramText(item.managedProduct?.program, item.managedProduct?.attributes, programeNames),
      rmdPlanId: getIntegerAttributeValue(item.attributes, 'RMD_PLAN_ID'),
      scheduledDate: item.scheduledAt || undefined,
      settlementDate: item.settlementDate || undefined,
      status: toCashTransferStatus(item.status),
      taxInformation: getTaxInformation(item.attributes),
      lastCommentData: item.entityNotes[0]
        ? {
            ...getLastEditDateAndTime(parseISO(item.entityNotes[0].created), contentOptions),
            lastValue: item.entityNotes[0].note,
          }
        : undefined,
      lastCommentPartyId: item.entityNotes[0]?.createdByPartyId ?? undefined,
    };
  });
};
