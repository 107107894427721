import React from 'react';

import { CashTransfer, useCancelTransfer } from '../modals/ViewTransfers/symphony';

import { ScheduledTransferStatus } from '~/__generated__/symphonyTypes.v2';
import { BodyRow } from '~/components/TransfersTable/BodyRow';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Tooltip } from '~/components/ui/Tooltip';
import { ContentOptions } from '~/utils/contentstack';

export type Row = CashTransfer;
export type ColumnData =
  | 'Date'
  | 'Amount'
  | 'Frequency'
  | 'Description'
  | 'Action'
  | 'Status'
  | 'Settlement Date'
  | 'Contribution Year'
  | 'UNKNOWN';

interface TransferDescription {
  deposit?: string;
  rmd?: string;
  withdrawal?: string;
}

export interface Status {
  cancelled?: string;
  completed?: string;
  in_progress?: string;
  pending?: string;
}

export interface Content {
  accountNumberFormat: string;
  action: {
    cancel?: string;
    inProgress?: string;
    pending?: string;
    pendingCancel?: string;
  };
  amount: {
    format?: 'Precise' | 'Rounded';
  };
  cancelRow: {
    bodyDeposit: string;
    bodyWithdrawal: string;
    ctas: {
      cancel: string;
      confirm: string;
    };
    errorMessage: string;
  };
  columns: Array<{
    data: ColumnData;
    header: string;
    showTooltip: boolean;
    tooltip: string;
  }>;
  date: {
    format?: string;
  };
  description: {
    monthly: TransferDescription;
    oneTime: TransferDescription;
    weekly: TransferDescription;
  };
  frequency: {
    monthly?: string;
    oneTime?: string;
    weekly?: string;
  };
  status: Status;
}

export interface Props {
  cancelTransferStatusMap: { [key: string]: ScheduledTransferStatus };
  content: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onConfirmCancel: ReturnType<typeof useCancelTransfer>[0];
  onWithdrawalCancelled?: () => void;
  rows: Row[];
  setCancelTransferStatus: (rowId: string, cancelTransferStatus?: ScheduledTransferStatus) => void;
}

export const TransfersTable: React.FC<Props> = ({
  dataQa = 'transfers-table',
  rows,
  content,
  contentOptions,
  managedProductId,
  cancelTransferStatusMap,
  onConfirmCancel,
  onWithdrawalCancelled,
  setCancelTransferStatus,
}) => {
  const {
    sfTransferTable: { root: sfTransferTableRoot },
  } = useTheme();
  return (
    <Box data-qa={dataQa}>
      <TableContainer>
        <Table sx={sfTransferTableRoot}>
          <TableHead>
            <TableRow data-qa={`${dataQa}-header-row`}>
              {content.columns.map((column, index) => (
                <TableCell data-qa={`${dataQa}-header-${column.header}`} key={index}>
                  <Box sx={{ display: 'flex' }}>
                    {column.header}
                    {column.showTooltip ? <Tooltip tooltipContent={<RteContent data={column.tooltip} />} /> : null}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <BodyRow
                cancelTransferStatusMap={cancelTransferStatusMap}
                content={content}
                contentOptions={contentOptions}
                key={`${row.id}-${row.scheduledAt}`}
                managedProductId={managedProductId}
                onConfirmCancel={onConfirmCancel}
                onWithdrawalCancelled={onWithdrawalCancelled}
                row={row}
                setCancelTransferStatus={setCancelTransferStatus}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
