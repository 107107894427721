import * as React from 'react';
import { ReactNode } from 'react';

import { HoldingsTableModalContent } from '../Funding/HoldingsTable';

import { isAmountField, isBankAccountField, isContributionYearField, isFrequencyField } from './contentstack';
import {
  GetAddFundsV2Content_all_add_funds_modal_items,
  GetAddFundsV2Content_all_add_funds_modal_v2_items,
} from './contentstack/__generated__/query.v2';
import { ConfirmationVariables, FormData, ModalContent, PlaybackVariables } from './types';

import {
  BankAccountInputWithParty,
  CreateAssetTransferInput,
  CreateCashTransferInput,
  FinancialAccountSource,
  FinancialAccountType,
  SleeveType,
  TransferFrequency,
  TransferFrequencyType,
  TransferMethod,
  TransferType,
} from '~/__generated__';
import { CASH_HOLDING, SECURITY_ID } from '~/components/Funding/HoldingsTable/utils';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { DropdownItem, DropdownItemHeader } from '~/components/ui/Dropdown/types';
import { Box, Grid } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { InvestmentMethods } from '~/containers/FundingV2/types';
import {
  BrokerageFinancialAccount,
  ExternalBrokerageFinancialAccount,
  FinancialAccount,
} from '~/hooks/financial-account/symphony';
import { DateOption } from '~/hooks/financial-account/types';
import {
  getAddAccountActions,
  getBankAccountId,
  getBankAccountLabel,
  getContributionYearOptions,
  getPlaidMaskedAccountNumber,
  isBankAccountEditable,
} from '~/utils/account';
import { FundingSources } from '~/utils/config';
import { getValueFromKeyValuePair, Locale } from '~/utils/contentstack';
import { getDaysOfWeekOptions } from '~/utils/dropdown';
import { formatCurrency } from '~/utils/format';
import { getOrdinalNumber } from '~/utils/format/currency';
import { formatDate } from '~/utils/format/date';
import { toSymphonyDateTime } from '~/utils/symphony';

export const getMaskedBrokerageAccountNumber = (brokerageAccountNumber: string): string =>
  brokerageAccountNumber
    ? brokerageAccountNumber.slice(0, -4).replace(/./g, '*') + brokerageAccountNumber.slice(-4)
    : brokerageAccountNumber;

export const getHoldingTableContent = (
  content: GetAddFundsV2Content_all_add_funds_modal_v2_items | null | undefined,
): HoldingsTableModalContent => ({
  cashNameText: getValueFromKeyValuePair('holdingCashNameText', content?.custom_labels),
  cashLabel: getValueFromKeyValuePair('holdingCashLabel', content?.labels),
  columns: {
    assets: getValueFromKeyValuePair('holdingAssets', content?.labels),
    name: getValueFromKeyValuePair('holdingName', content?.labels),
    units: getValueFromKeyValuePair('holdingUnits', content?.labels),
    estimatedValue: getValueFromKeyValuePair('holdingEstimatedValue', content?.labels),
  },
  noItemSelected: getValueFromKeyValuePair('holdingNoItemSelected', content?.labels),
  placeHolderUnits: getValueFromKeyValuePair('holdingPlaceHolderUnits', content?.labels),
  perPageText: getValueFromKeyValuePair('holdingSelectedText', content?.custom_labels),
  totalSelectedText: getValueFromKeyValuePair('holdingTotalSelectedText', content?.custom_labels),
  invalidUnits: getValueFromKeyValuePair('holdingInvalidUnits', content?.labels),
  journalingInfoText: getValueFromKeyValuePair('journalingInfoText', content?.labels),
  journalingMessageLabel: getValueFromKeyValuePair('journalingMessageLabel', content?.custom_labels),
  save: getValueFromKeyValuePair('holdingsTableSaveButton', content?.labels),
  cancel: getValueFromKeyValuePair('holdingsTableCancelButton', content?.labels),
  title: getValueFromKeyValuePair('holdingTitle', content?.labels),
  customSectionLabel: getValueFromKeyValuePair('holdingSectionLabel', content?.custom_labels),
  unitsCell: getValueFromKeyValuePair('holdingUnitsCell', content?.custom_labels),
});

// TODO: Move all the content to V2 content type so V1 dependency can be removed
export const getFormContent = (
  v1Items: GetAddFundsV2Content_all_add_funds_modal_items | null | undefined,
  v2Items: GetAddFundsV2Content_all_add_funds_modal_v2_items | null | undefined,
  locale: Locale,
): ModalContent => {
  const fields = v1Items?.fields;
  const amountField = fields?.filter(isAmountField)?.[0];
  const frequencyField = fields?.filter(isFrequencyField)?.[0];
  const bankAccountField = fields?.filter(isBankAccountField)?.[0];
  const contributionYearField = fields?.filter(isContributionYearField)?.[0];

  return {
    ctas: {
      addMoreFunds: getValueFromKeyValuePair('addMoreFunds', v2Items?.labels),
      back: getValueFromKeyValuePair('back', v2Items?.labels),
      changeInvestmentAmount: getValueFromKeyValuePair('changeInvestmentAmountButton', v2Items?.labels),
      changeInvestmentMethod: getValueFromKeyValuePair('changeInvestmentMethodButton', v2Items?.labels),
      confirm: getValueFromKeyValuePair('confirm', v2Items?.labels),
      continue: getValueFromKeyValuePair('continue', v2Items?.labels),
      done: getValueFromKeyValuePair('done', v2Items?.labels),
      editBrokerage: getValueFromKeyValuePair('editBrokerage', v2Items?.labels),
      linkBankAccount: v1Items?.link_bank_account ?? '',
      linkAccountViaPlaid: getValueFromKeyValuePair('linkAccountViaPlaid', v2Items?.labels),
      linkBrokerageAccountManually: getValueFromKeyValuePair('linkBrokerageAccountManually', v2Items?.labels),
      primary: v1Items?.modal_fields?.primary_cta ?? '',
      secondary: v1Items?.modal_fields?.secondary_cta ?? '',
      setupRecurringDeposit: getValueFromKeyValuePair('setupRecurringDeposit', v2Items?.labels),
    },
    heading: v1Items?.heading ?? '',
    amountField: {
      label: getValueFromKeyValuePair('amount', v2Items?.labels),
      placeholder: amountField?.amount?.inputConnection?.edges?.[0]?.node?.placeholder ?? '',
      maxAllowedAmount: amountField?.amount?.maximum_allowed_amount ?? Number.MAX_VALUE,
      minAllowedAmount: amountField?.amount?.minimum_allowed_amount ?? Number.MIN_VALUE,
      availableBalance: getValueFromKeyValuePair('availableBalance', v2Items?.custom_labels),
      errors: {
        minError: amountField?.amount?.amount_errors?.negative_zero_addition_amount ?? '',
        maxError: amountField?.amount?.amount_errors?.overdrawn_addition_amount ?? '',
        maxAllowedError: amountField?.amount?.amount_errors?.maximum_allowed_amount ?? '',
        requiredError: amountField?.amount?.amount_errors?.missing_addition_amount ?? '',
        accountTypeMaximumAmount: amountField?.amount?.amount_errors?.account_type_maximum_amount ?? '',
      },
    },
    errorMessages: {
      submitErrorMessage: v1Items?.submit_error_message ?? '',
      verificationErrorMessage: v1Items?.verification_error_message ?? '',
    },
    feedbackMessage: v1Items?.feedback_message ?? '',
    frequencyDropdown: {
      label: frequencyField?.frequency?.frequency_fieldConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      options: [
        {
          label: v1Items?.frequency_dropdown_values?.group?.weekly ?? '',
          value: TransferFrequency.WEEKLY,
        },
        {
          label: v1Items?.frequency_dropdown_values?.group?.monthly ?? '',
          value: TransferFrequency.MONTHLY,
        },
      ].filter(o => o.label),
    },
    fundingSourceField: {
      label: getValueFromKeyValuePair('fundingSourceFieldLabel', v2Items?.labels),
      placeholder: getValueFromKeyValuePair('fundingSourceFieldPlaceholder', v2Items?.labels),
      sources:
        v2Items?.single_options
          ?.find(option => option?.key === 'fundingSourceFieldOptions')
          ?.list?.map(item => ({
            label: item?.label ?? '',
            value: item?.value as FundingSources,
          })) ?? [],
    },
    weekdayDropdown: {
      options: getDaysOfWeekOptions(locale),
    },
    dateOptions: {
      now: frequencyField?.frequency?.time_period_cta?.now ?? '',
      later: frequencyField?.frequency?.time_period_cta?.later ?? '',
    },
    datePicker: {
      error: frequencyField?.frequency?.date_pickerConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      label: frequencyField?.frequency?.date_pickerConnection?.edges?.[0]?.node?.label?.[1]?.value ?? '',
      pastDateError: frequencyField?.frequency?.datepicker_past_date_error ?? '',
      invalidDateError: frequencyField?.frequency?.datepicker_invalid_date ?? '',
      marketHolidayError: frequencyField?.frequency?.datepicker_market_holiday_error ?? '',
      maxDateError: frequencyField?.frequency?.datepicker_max_date_error ?? '',
      monthlyDayPickerLabel: frequencyField?.frequency?.day_pickerConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      weekdayPickerLabel: getValueFromKeyValuePair('day', v2Items?.labels),
    },
    contributionYearDropdown: {
      label: contributionYearField?.contribution_year?.dropdownConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      options: getContributionYearOptions(),
      tooltip:
        contributionYearField?.contribution_year?.dropdownConnection?.edges?.[0]?.node?.label?.[0]?.tooltip ?? '',
      startDateAndMonth: contributionYearField?.contribution_year?.start_time ?? '01/01',
      endDateAndMonth: contributionYearField?.contribution_year?.end_time ?? '04/15',
    },
    accountsDropdown: {
      accountsListPlaceholder: getValueFromKeyValuePair('accountsListPlaceholder', v2Items?.labels),
      close: getValueFromKeyValuePair('bankAccountClose', v2Items?.labels),
      label: bankAccountField?.bank_account?.dropdownConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      noAccountsMessage: getValueFromKeyValuePair('noAccountsMessage', v2Items?.labels),
      addAccountOptionsHeader: getValueFromKeyValuePair('addAccountOptionsHeader', v2Items?.labels),
      availableAccountsHeader: getValueFromKeyValuePair('availableAccountsHeader', v2Items?.labels),
      noAccountsError: bankAccountField?.bank_account?.no_bank_accounts_error ?? '',
      plaidReAuthenticaion: getValueFromKeyValuePair('plaidReAuthenticaion', v2Items?.custom_labels),
      plaidReAuthenticaionLink: getValueFromKeyValuePair('plaidReAuthenticaionLink', v2Items?.labels),
    },
    footer: v1Items?.footer_ ?? '',
    iraRecurringContributionText: v1Items?.ira_recurring_contribution_text ?? '',
    pendingModalChangeWarning: {
      heading: v1Items?.pending_modal_change_warning?.heading ?? '',
      cancel: v1Items?.pending_modal_change_warning?.cancel ?? '',
      continue: v1Items?.pending_modal_change_warning?.continue ?? '',
      message: v1Items?.pending_modal_change_warning?.message ?? '',
    },
    title: v1Items?.modal_fields?.title ?? '',
    verificationWarningMessage: v1Items?.verification_warning ?? '',
    labels: {
      amount: getValueFromKeyValuePair('amount', v2Items?.labels),
      depositSuccess: getValueFromKeyValuePair('depositSuccess', v2Items?.labels),
      manualConfirmAccountNumberEntry: getValueFromKeyValuePair('manualConfirmAccountNumberEntry', v2Items?.labels),
      manualBrokerageAccountNumber: getValueFromKeyValuePair('manualBrokerageAccountNumber', v2Items?.labels),
      manualBrokerageAccountName: getValueFromKeyValuePair('manualBrokerageAccountName', v2Items?.labels),
      on: getValueFromKeyValuePair('on', v2Items?.labels),
      oneTime: getValueFromKeyValuePair('oneTime', v2Items?.labels),
      oneTimeDeposit: getValueFromKeyValuePair('oneTimeDeposit', v2Items?.labels),
      frequency: getValueFromKeyValuePair('frequency', v2Items?.labels),
      playbackTitle: getValueFromKeyValuePair('playbackTitle', v2Items?.labels),
      recurring: getValueFromKeyValuePair('recurring', v2Items?.labels),
      recurringDeposit: getValueFromKeyValuePair('recurringDeposit', v2Items?.labels),
      recurringDepositBenefit: getValueFromKeyValuePair('recurringDepositBenefit', v2Items?.labels),
      recurringDepositMessage: getValueFromKeyValuePair('recurringDepositMessage', v2Items?.labels),
      fromAccount: getValueFromKeyValuePair('fromAccount', v2Items?.labels),
      toAccount: getValueFromKeyValuePair('toAccount', v2Items?.labels),
      scheduleTransferForFutureDate: getValueFromKeyValuePair('scheduleTransferForFutureDate', v2Items?.labels),
      success: getValueFromKeyValuePair('success', v2Items?.labels),
      moneyInRestrictionHeading: getValueFromKeyValuePair('money_in_restriction_heading', v2Items?.labels),
      moneyInRestrictionContent: getValueFromKeyValuePair('money_in_restriction_content', v2Items?.labels),
    },
    customLabels: {
      brokerageAccountSource: getValueFromKeyValuePair('brokerageAccountSourceLabel', v2Items?.custom_labels),
      dcaTransferMessage: {
        [TransferFrequency.MONTHLY]: getValueFromKeyValuePair('dcaMonthlyTransferMessage', v2Items?.custom_labels),
        [TransferFrequency.WEEKLY]: getValueFromKeyValuePair('dcaWeeklyTransferMessage', v2Items?.custom_labels),
      },
      oneTimeTransferDateMessage: getValueFromKeyValuePair('oneTimeTransferDateMessage', v2Items?.custom_labels),
      transferDateMessage: getValueFromKeyValuePair('transferDateMessage', v2Items?.custom_labels),
    },
    images: {
      recurring: v2Items?.images?.find(i => i?.key === 'recurring')?.imageConnection?.edges?.[0]?.node?.url ?? '',
      success: v2Items?.images?.find(i => i?.key === 'success')?.imageConnection?.edges?.[0]?.node?.url ?? '',
    },
    holdingTableDrawer: getHoldingTableContent(v2Items),
  };
};

// export const getValidationsBasedOnFundingSource = () => {};
export const getSourceBasedAccountsDropdownItems = ({
  fundingSource,
  availableAccountsHeader,
  noAccountsMessage,
  bankAccounts,
  brokerageAccounts,
  editDetailsOnClick,
}: {
  availableAccountsHeader: string;
  bankAccounts?: FinancialAccount[];
  brokerageAccounts?: BrokerageFinancialAccount[];
  editDetailsOnClick?: (item: DropdownItem) => void;
  externalBrokerageAccounts?: ExternalBrokerageFinancialAccount[];
  fundingSource: FundingSources;
  noAccountsMessage: string;
}): (DropdownItem | DropdownItemHeader)[] => {
  const items: (DropdownItem | DropdownItemHeader)[] = [];
  items.push({
    type: 'list-item-header',
    label: availableAccountsHeader,
  });
  switch (fundingSource) {
    case FundingSources.bankAccount:
      bankAccounts?.forEach(account => {
        const accountId = getBankAccountId(
          account.id ?? null,
          account.isFromExternalSource ?? null,
          account.accountNumber ?? null,
        );
        if (accountId) {
          const maskedAccountNumber = getPlaidMaskedAccountNumber(account.attributes) ?? account.maskedAccountNumber;
          items.push({
            type: 'list-item',
            value: accountId,
            label: getBankAccountLabel(account.financialInstitution, account.productName, maskedAccountNumber),
            editDetailsOnClick:
              isBankAccountEditable(account) && account.source !== FinancialAccountSource.PLAID
                ? editDetailsOnClick
                : undefined,
          });
        }
      });
      break;
    case FundingSources.otherBrokerageAccount:
      brokerageAccounts?.forEach(account => {
        const accountId = account.id ?? null;
        if (accountId) {
          items.push({
            type: 'list-item',
            value: accountId,
            label: `${account.financialInstitution} ${account.maskedAccountNumber}`,
          });
        }
      });
      break;
    default:
      break;
  }

  if (items.length === 1 && items[0].type === 'list-item-header') {
    items.push({ label: noAccountsMessage, value: noAccountsMessage, disabled: true });
  }
  return items;
};

export const getDropdownActions = ({
  content,
  fundingSource,
  isManualLinkageForBankAccountSupported,
  isManualLinkageForBrokerageAccountSupported,
  isPlaidLinkageForBankAccountSupported,
  isPlaidLinkageForBrokerageAccountSupported,
}: {
  content: ModalContent;
  fundingSource: FundingSources;
  isManualLinkageForBankAccountSupported?: boolean;
  isManualLinkageForBrokerageAccountSupported?: boolean;
  isPlaidLinkageForBankAccountSupported?: boolean;
  isPlaidLinkageForBrokerageAccountSupported?: boolean;
}): (DropdownItem | DropdownItemHeader)[] => {
  const dropdownItems: (DropdownItem | DropdownItemHeader)[] = [];
  dropdownItems.push({
    type: 'list-item-header',
    label: content.accountsDropdown.addAccountOptionsHeader,
  });
  dropdownItems.push(
    ...getAddAccountActions(
      fundingSource === FundingSources.bankAccount
        ? {
            isManualLinkageForAccountSupported: isManualLinkageForBankAccountSupported ?? false,
            isPlaidLinkageForAccountSupported: isPlaidLinkageForBankAccountSupported ?? false,
            manualLinkText: content.ctas.linkBankAccount,
            plaidLinkText: content.ctas.linkAccountViaPlaid,
          }
        : {
            isManualLinkageForAccountSupported: isManualLinkageForBrokerageAccountSupported ?? false,
            isPlaidLinkageForAccountSupported: isPlaidLinkageForBrokerageAccountSupported ?? false,
            manualLinkText: content.ctas.linkBrokerageAccountManually,
            plaidLinkText: content.ctas.linkAccountViaPlaid,
          },
    ),
  );
  return dropdownItems;
};

export const getAmountFieldHelperText = (
  amountFieldError: any,
  minErrorMessage: string,
  maxErrorMessage: ReactNode,
  requiredErrorMessage: string,
  isMaxAllowedAmountLesserThanBalance: boolean,
  maxAllowedAmountMessage: ReactNode,
  ignoreInsufficientFunds: boolean,
  accountTypeMaximumAmount: string,
) => {
  if (amountFieldError) {
    if (amountFieldError.type === 'min') {
      return minErrorMessage;
    } else if (amountFieldError.type === 'max') {
      return ignoreInsufficientFunds
        ? maxAllowedAmountMessage
        : isMaxAllowedAmountLesserThanBalance
        ? maxAllowedAmountMessage
        : maxErrorMessage;
    } else if (amountFieldError.type === 'required') {
      return requiredErrorMessage;
    }
    if (amountFieldError.type === 'isFundingAmountValid') {
      return <RteContent data={accountTypeMaximumAmount} />;
    }
  }
  return null;
};

export const getDCAAlertMessage = ({
  dcaInitialAmount,
  dcaInvestmentPeriod,
  dcaRecurringAmount,
  dcaFrequency,
  content,
}: {
  content?: ModalContent['customLabels']['dcaTransferMessage'];
  dcaFrequency?: TransferFrequency;
  dcaInitialAmount?: number;
  dcaInvestmentPeriod?: number;
  dcaRecurringAmount?: number;
}) => {
  if (dcaFrequency && content) {
    switch (dcaFrequency) {
      case TransferFrequency.MONTHLY:
        return (
          <Alert severity="info">
            <RteContent
              config={{
                initialAmount: formatCurrency(dcaInitialAmount ?? 0),
                recurringAmount: formatCurrency(dcaRecurringAmount ?? 0),
                investmentPeriod: dcaInvestmentPeriod,
              }}
              data={content[dcaFrequency] ?? ''}
            />
          </Alert>
        );
      case TransferFrequency.WEEKLY:
        return (
          <Alert severity="info">
            <RteContent
              config={{
                initialAmount: formatCurrency(dcaInitialAmount ?? 0),
                recurringAmount: formatCurrency(dcaRecurringAmount ?? 0),
                investmentPeriod: dcaInvestmentPeriod,
              }}
              data={content[dcaFrequency] ?? ''}
            />
          </Alert>
        );
      default:
        return;
    }
  } else {
    return;
  }
};

export const getTransferDateMessage = ({
  fundingSource,
  dcaAlert,
  isOneTimeFrequencyType,
  asSoonAsPossibleDate,
  scheduledTransferDate,
  content,
}: {
  asSoonAsPossibleDate: Date;
  content?: ModalContent;
  dcaAlert?: JSX.Element;
  fundingSource: FundingSources;
  isOneTimeFrequencyType: boolean;
  scheduledTransferDate?: Date;
}) => {
  switch (fundingSource) {
    case FundingSources.bankAccount:
      return (
        <>
          {dcaAlert ? (
            dcaAlert
          ) : isOneTimeFrequencyType ? (
            <Alert severity="info">
              <RteContent
                config={{ date: formatDate(scheduledTransferDate ?? asSoonAsPossibleDate, 'MM/dd/yyyy') }}
                data={content?.customLabels.oneTimeTransferDateMessage ?? ''}
              />
            </Alert>
          ) : (
            <Alert severity="info">
              <RteContent data={content?.customLabels.transferDateMessage ?? ''} />
            </Alert>
          )}
        </>
      );
    case FundingSources.otherBrokerageAccount:
      return (
        <Alert severity="info">
          <RteContent data={content?.customLabels.transferDateMessage ?? ''} />
        </Alert>
      );
    default:
      return;
  }
};
export const getPlaybackFields = ({
  additionDayOfMonth,
  additionDayOfWeek,
  additionFrequency,
  asSoonAsPossibleDate,
  accountOptions,
  content,
  contentOptions,
  contributionYear,
  confirmedAccountNumber,
  destinationAccount,
  formAdditionAmount,
  fundingSource,
  isOneTimeFrequencyType,
  isRecurringFrequencyType,
  scheduledTransferDate,
  sourceAccountId,
  dcaAlert,
  manuallyAddedBrokerageSourceAccount,
}: PlaybackVariables) => {
  const additionFrequencyLabel =
    content?.frequencyDropdown.options.find(o => o.value === additionFrequency)?.label ?? '';

  const additionDayLabel =
    additionFrequency === TransferFrequency.WEEKLY && additionDayOfWeek
      ? content?.weekdayDropdown.options.find(o => o.value === additionDayOfWeek)?.label
      : additionFrequency === TransferFrequency.MONTHLY && additionDayOfMonth
      ? getOrdinalNumber(additionDayOfMonth)
      : '';
  return (
    <>
      <Typography variant="subtitle2">
        {isOneTimeFrequencyType ? content?.labels.oneTimeDeposit : content?.labels.recurringDeposit}
      </Typography>
      <Divider sx={{ pt: 1 }} />
      <Grid container sx={{ pt: 2 }}>
        <Grid item xs={6}>
          <Typography variant="caption">{content?.labels.fromAccount}</Typography>
          {fundingSource === FundingSources.bankAccount && (
            <Typography variant="body1">{accountOptions.find(a => a.value === sourceAccountId)?.label}</Typography>
          )}
          {[FundingSources.otherBrokerageAccount].includes(fundingSource) && (
            <RteContent
              config={{
                sourceAccountName: manuallyAddedBrokerageSourceAccount
                  ? manuallyAddedBrokerageSourceAccount
                  : `${accountOptions.find(a => a.value === sourceAccountId)?.label} ${getMaskedBrokerageAccountNumber(
                      confirmedAccountNumber ?? '',
                    )}`,
              }}
              data={content?.customLabels.brokerageAccountSource ?? ''}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption">{content?.labels.toAccount}</Typography>
          <Typography variant="body1">{destinationAccount?.accountName}</Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ pt: 2 }}>
        <Grid item xs={6}>
          <Typography variant="caption">{content?.labels.amount}</Typography>
          <Typography variant="body1">
            {formatCurrency(parseFloat(formAdditionAmount), { locale: contentOptions.locale })}
          </Typography>
        </Grid>
        {fundingSource === FundingSources.bankAccount && isOneTimeFrequencyType && contributionYear && (
          <Grid item xs={6}>
            <Typography variant="caption">{content?.contributionYearDropdown.label}</Typography>
            <Typography variant="body1">{contributionYear}</Typography>
          </Grid>
        )}
        {fundingSource === FundingSources.bankAccount && isRecurringFrequencyType && (
          <Grid item xs={6}>
            <Typography variant="caption">{content?.labels.frequency}</Typography>
            <Typography variant="body1">{`${additionFrequencyLabel} ${
              additionDayLabel ? `${content?.labels.on} ${additionDayLabel}` : ''
            }`}</Typography>
          </Grid>
        )}
      </Grid>
      {fundingSource === FundingSources.bankAccount && (
        <Grid container sx={{ pt: 3 }}>
          {getTransferDateMessage({
            fundingSource,
            isOneTimeFrequencyType,
            asSoonAsPossibleDate,
            scheduledTransferDate,
            content,
            dcaAlert,
          })}
        </Grid>
      )}
    </>
  );
};

export const getConfirmationFields = ({
  accountHasRecurringDeposit,
  content,
  isOneTimeFrequencyType,
}: ConfirmationVariables) => {
  return (
    <>
      <Box sx={{ height: '100px', py: 4 }}>
        <img src={content?.images.success} />
      </Box>
      <Typography sx={{ pb: 2 }} variant="h5">
        {content?.labels.depositSuccess}
      </Typography>
      {!accountHasRecurringDeposit && isOneTimeFrequencyType && (
        <Grid container sx={{ bgcolor: 'grey.100', my: 2 }}>
          <Grid item pt={1.5} textAlign="center" xs={2}>
            <img src={content?.images.recurring} />
          </Grid>
          <Grid item sx={{ pr: 2, py: 2 }} xs={10}>
            <Typography variant="body1">{content?.labels.recurringDepositMessage}</Typography>
            <Typography sx={{ pt: 1 }} variant="body2">
              {content?.labels.recurringDepositBenefit}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const getTransferFrequency = (
  frequency: TransferFrequencyType | undefined,
  additionFrequency: TransferFrequency,
): TransferFrequency => {
  switch (frequency) {
    case TransferFrequencyType.ONE_TIME:
      return TransferFrequency.ONE_TIME;
    case TransferFrequencyType.RECURRING:
      return additionFrequency;
    default:
      return TransferFrequency.ONE_TIME;
  }
};

export const manipulateFormData = (formData: FormData): FormData => {
  const formDataClone = { ...formData };
  switch (formDataClone.additionFrequency) {
    case TransferFrequency.MONTHLY:
      delete formDataClone.additionDateOption;
      delete formDataClone.additionDayOfWeek;
      delete formDataClone.additionDate;
      break;
    case TransferFrequency.WEEKLY:
      delete formDataClone.additionDateOption;
      delete formDataClone.additionDayOfMonth;
      delete formDataClone.additionDate;
      break;
    case TransferFrequency.ONE_TIME:
      delete formDataClone.additionDayOfWeek;
      delete formDataClone.additionDayOfMonth;
      break;
  }
  if (formDataClone.additionDateOption === DateOption.DateAsap) {
    delete formDataClone.additionDate;
  }
  return formDataClone;
};

export const createCashTransferInput = ({
  formData,
  destinationAccountId,
  externalBankAccountInput,
  transferFrequencyType,
}: {
  destinationAccountId?: string;
  externalBankAccountInput?: BankAccountInputWithParty | null;
  formData: FormData;
  transferFrequencyType?: TransferFrequencyType;
}): CreateCashTransferInput[] => {
  const isDCASelected = formData.investmentMethod === InvestmentMethods.DCA;
  const cashTransfersInput: CreateCashTransferInput[] = [
    {
      bankAccountWithParty: externalBankAccountInput,
      cashAmount: isDCASelected ? formData.dcaInitialAmount ?? '' : formData.additionAmount,
      destinationFinancialAccountId: destinationAccountId,
      sleeve: SleeveType.INVESTMENT,
      sourceFinancialAccountId: !externalBankAccountInput ? formData.eligibleSourceAccount : null,
      type: TransferType.DEPOSIT,
      frequency: getTransferFrequency(transferFrequencyType, formData.additionFrequency),
      day:
        formData.additionDayOfMonth !== undefined
          ? formData.additionDayOfMonth
          : formData.additionDayOfWeek !== undefined
          ? formData.additionDayOfWeek
          : undefined,
      scheduledAt: formData.additionDate
        ? toSymphonyDateTime(new Date(formData.additionDate.setHours(0, 0, 0, 0)))
        : undefined,
      contributionYear: formData.contributionYear,
    },
  ];
  if (isDCASelected) {
    const {
      dcaInvestmentPeriod,
      dcaInitialAmount,
      additionAmount,
      dcaFrequency = TransferFrequency.ONE_TIME,
    } = formData;
    cashTransfersInput.push({
      bankAccountWithParty: externalBankAccountInput,
      cashAmount:
        additionAmount && dcaInitialAmount && dcaInvestmentPeriod
          ? ((parseFloat(additionAmount) - parseFloat(dcaInitialAmount)) / dcaInvestmentPeriod).toString()
          : '0',
      day: 1,
      destinationFinancialAccountId: destinationAccountId,
      frequency: dcaFrequency,
      sleeve: SleeveType.INVESTMENT,
      numberOfOccurrences: formData.dcaInvestmentPeriod,
      sourceFinancialAccountId: !externalBankAccountInput ? formData.eligibleSourceAccount : null,
      type: TransferType.DEPOSIT,
    });
  }
  return cashTransfersInput;
};

export const createAssetTransferInput = ({
  accountType,
  brokerageAccount,
  formData,
  destinationAccountId,
  showManualBrokerageDetails,
}: {
  accountType: FinancialAccountType;
  brokerageAccount?: BrokerageFinancialAccount;
  destinationAccountId?: string;
  formData: FormData;
  showManualBrokerageDetails: boolean;
}): CreateAssetTransferInput => {
  const positionIds = formData.holdingData ? Object.keys(formData.holdingData).filter(id => id !== CASH_HOLDING) : [];
  const positions =
    brokerageAccount?.positions?.filter(item =>
      positionIds.includes(item.identifiers.find(i => i.name === SECURITY_ID)?.value ?? ''),
    ) ?? [];
  return {
    cashAmount: formData.holdingData?.[CASH_HOLDING]?.estimatedValue.toString() ?? '0',
    destinationFinancialAccountId: destinationAccountId ?? '',
    isFullTransfer: brokerageAccount?.positions ? positions.length === brokerageAccount.positions.length : true,
    positionTransfer: {
      estimatedValue: formData.additionAmount,
      positions: positions.map(p => ({
        identifiers: p.identifiers.map(i => ({ name: i.name, value: i.value })),
        quantities: p.quantities.map(q => ({
          marketValue: {
            currencyCode: q.marketValue?.currencyCode ?? '',
            value: q.marketValue?.value ?? '',
          },
          type: q.type,
          units: q.units,
        })),
      })),
    },
    isInitial: false,
    sourceFinancialAccountType: accountType,
    method: TransferMethod.ACAT,
    sourceFinancialAccountAccountNumber: showManualBrokerageDetails
      ? formData.brokerageAccountNumber
      : brokerageAccount?.accountNumber ?? formData.brokerageAccountNumber,
    sourceFinancialAccountBrokerageName: showManualBrokerageDetails
      ? formData.brokerageAccountName
      : brokerageAccount?.financialInstitution,
    sourceFinancialAccountBrokerageAlias: showManualBrokerageDetails
      ? formData.brokerageAccountAlias
      : brokerageAccount?.financialInstitutionAlias,
    sourceFinancialAccountId: brokerageAccount?.id ?? undefined,
    type: TransferType.DEPOSIT,
  };
};
