import { useMemo } from 'react';

import { useGetTradingSuspensionsTabContent } from '../contentstack';

import { Content, getTradingSuspensionsContent } from './utils';

import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Data {
  content: Content;
}

const useTradingSuspensionsData = (contentOptions: ContentOptions): AsyncResult<Data> => {
  const { data: content, error, loading } = useGetTradingSuspensionsTabContent({ variables: contentOptions });

  const data = useMemo(
    () => ({
      content: getTradingSuspensionsContent(content),
    }),
    [content],
  );

  return {
    data,
    error,
    loading,
  };
};

export default useTradingSuspensionsData;
