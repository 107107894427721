import { RequestTabs } from '../types';

import { FundingTabFlagItemsContent } from './contentstack';

import {
  FinancialAccountType,
  FlagReason,
  FlagStatus,
  FundingReviewStatus,
  FundingSourceType,
  ManagedProductType,
  Scalars as SymphonyScalars,
} from '~/__generated__';
import { ManagedProductAttributes } from '~/hooks/account-details/symphony';
import { ContentstackTableColumn } from '~/utils/table';

export const FundingStatusType = {
  BELOW_MINIMUM: 'BELOW_MINIMUM',
  FUNDED: 'FUNDED',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
} as const;

export type FundingStatusType = typeof FundingStatusType[keyof typeof FundingStatusType];

export const FundingStatusFilterTypes = {
  ALL: 'ALL',
  BELOW_MINIMUM: 'BELOW_MINIMUM',
  FUNDED: 'FUNDED',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
} as const;

export type FundingStatusFilterTypes = typeof FundingStatusFilterTypes[keyof typeof FundingStatusFilterTypes];

export interface FundingTabData {
  contentData: FundingTabContentData;
  fundingData: FundingTabSymphonyData;
}
export interface FundingTabContentData {
  flagsContent?: FundingTabFlagItemsContent;
  fundingSourceMapping: Record<FundingSourceType, string> | Record<string, unknown>;
  partitionTabs?: Record<RequestTabs, string>;
  partitionedByFlaggedStatus: boolean;
  staticStringMapping: Record<string, any>[];
  statusFiltersDropdownItems: {
    label: string;
    value: FundingStatusFilterTypes;
  }[];
  statuses: Record<FundingStatusType, string>;
  tableHeaders: (ContentstackTableColumn | null)[];
}

export interface AccountTypeContent {
  key: string | null;
  text: string | null;
}
export enum TransferMethods {
  ACAT = 'ACAT',
  ACH = 'ACH',
  JOURNAL = 'Journal',
  UNKNOWN = 'UNKNOWN',
}
export interface FundingTransferDetails {
  transferMethod: TransferMethods;
  transferSource: string;
}

interface FundingReviewItem {
  accountType: FinancialAccountType;
  attributes: ManagedProductAttributes[];
  createdAt: string;
  entityNotes?: {
    lastEditDate: string;
    lastEditTime: string;
    lastValue: string;
  };
  financialAccountNumber: string;
  flags: {
    created: SymphonyScalars['DateTime'];
    description: string;
    entityIdentifier: string;
    id: number;
    lastUpdated: SymphonyScalars['DateTime'];
    reason: FlagReason;
    status: FlagStatus;
  }[];
  fundingDetails: {
    fundLater: boolean;
    fundLaterAmount: string | null;
    fundingAmount: string;
    fundingReviewStatus: FundingReviewStatus;
    fundingSources: FundingSourceType[];
    fundingValue: string;
    transfers: FundingTransferDetails[] | null;
  };
  id: string;
  initialClientParty: {
    displayName: string;
    familyName: string;
    givenName: string;
    partyId: string;
  };
  lastCommentPartyId?: string;
  managedProductId: string;
  maskedFinancialAccountNumber: string;
  program: ManagedProductType;
}

export interface FundingTabSymphonyData {
  fundingReviews: FundingReviewItem[];
  pagination: {
    limit: number;
    offset: number;
    total: number;
  };
  refetchFundingReviewsData: () => void;
}
