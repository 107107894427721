import React, { FC } from 'react';

import { Link } from '~/components/ui/Link';
import { Box, Button, Grid, List, ListItem, ListItemText, PhoneIcon, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { useCoreConfig } from '~/utils/config';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { SfTheme } from '~/utils/theme/types';

export interface Content {
  body: string;
  callPhoneNumberCta?: string;
  contactAdvisorCta?: string;
  extra?: string;
  footer: string;
  helpQuestions: string[];
  launchSchedulingToolCta?: string;
  title: string;
}

export interface Props {
  content: Content;
  dataQa?: string;
  onLaunchSchedulingTool?: () => void;
  onOpenContactAdvisorModal?: () => void;
  supportEmail: string;
  supportPhoneNumber: string;
  supportPhoneNumberDisplayText: string;
}

export const SupportContact: FC<Props> = ({
  dataQa = 'supportContact',
  supportEmail,
  supportPhoneNumber,
  supportPhoneNumberDisplayText,
  content: {
    title,
    body,
    helpQuestions,
    footer,
    extra,
    launchSchedulingToolCta,
    callPhoneNumberCta,
    contactAdvisorCta,
  },
  onLaunchSchedulingTool,
  onOpenContactAdvisorModal,
}) => {
  const isMediumScreen = useIsMediumScreen();
  const {
    sfSupportContact: { styles: sfSupportContactStyles, root },
  } = useTheme<SfTheme>();
  const {
    components: {
      sfSupportContact: { showContactAdvisorCta },
    },
  } = useCoreConfig();

  const supportPhone = <Link href={`tel:${supportPhoneNumber}`}>{supportPhoneNumberDisplayText}</Link>;

  return (
    <Grid container spacing={2} sx={{ ...root }}>
      <Grid data-qa={dataQa} item md={6} overflow="auto">
        <RteContent
          data={title}
          data-qa={`${dataQa}-title`}
          sx={{
            ...sfSupportContactStyles.heading,
          }}
        />
        {extra && isMediumScreen && (
          <Box sx={{ '& img': { maxWidth: '100%' }, mt: 2 }}>
            <RteContent data={extra} data-qa={`${dataQa}-extra`} />
          </Box>
        )}
        <RteContent data={body} data-qa={`${dataQa}-body`} sx={{ pt: 2 }} />
        {helpQuestions.length > 0 && (
          <List
            disablePadding
            sx={{
              mb: 1,
              mt: 2,
              borderLeftStyle: 'solid',
              borderLeftWidth: '2px',
              borderLeftColor: theme => theme.palette.primary.main,
            }}
          >
            {helpQuestions.map((question, index) => {
              return (
                <ListItem key={index}>
                  <ListItemText
                    data-qa={`${dataQa}-helpQuestions-${index}`}
                    primary={question || 'NO TITLE'}
                    primaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
        {launchSchedulingToolCta && onLaunchSchedulingTool && (
          <Button fullWidth={isMediumScreen} onClick={onLaunchSchedulingTool} sx={{ my: 1 }} variant="contained">
            {launchSchedulingToolCta}
          </Button>
        )}
        {onOpenContactAdvisorModal && showContactAdvisorCta && (
          <Button onClick={onOpenContactAdvisorModal} sx={{ my: 1 }} variant="contained">
            {contactAdvisorCta}
          </Button>
        )}
        {callPhoneNumberCta && (
          <Box display="flex" sx={{ my: 1 }}>
            <PhoneIcon sx={{ mr: 2, color: 'primary.main' }} />
            <RteContent config={{ supportPhone }} data={callPhoneNumberCta} />
          </Box>
        )}
        <Box sx={{ mb: 2, mt: 1 }}>
          <RteContent
            config={{
              supportEmail: (
                <Link href={`mailto:${supportEmail}`} sx={{ display: 'block' }}>
                  {supportEmail}
                </Link>
              ),
              supportPhone,
            }}
            data={footer || 'NO FOOTER CONTENT'}
            data-qa={`${dataQa}-footer`}
          />
        </Box>
      </Grid>
      {extra && !isMediumScreen && (
        <Grid item sx={{ textAlign: 'right' }} xs={6}>
          <RteContent data={extra} data-qa={`${dataQa}-extra`} />
        </Grid>
      )}
    </Grid>
  );
};
