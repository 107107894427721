import React, { ComponentProps, useCallback, useContext, useMemo, useState } from 'react';

import { CommentComponentColumn } from '../common/Comment';
import { OpsAlertAndLoading } from '../common/OpsAlertAndLoading';
import { SearchBar } from '../common/SearchBar';
import OpsDashboardContext from '../OpsDashboardContext';
import { getHasOpenFlags, OpsDashboardUser } from '../utils';

import { ClosuresTable } from './ClosuresTable';
import { closureDefaultAppliedFilters, generateClosureStaticDisplayStrings } from './content';
import { ClosureItem, useClosuresTabData } from './hooks';
import { closureStatusToCloseAccountStatusForUpdate, geSelectedFilterStatuses } from './hooks/mappers';
import { useUpdateCloseWorkflowV2 } from './symphony';

import { CloseWorkflowField, OrderType } from '~/__generated__';
import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { SelectedFilters } from '~/components/Filters/types';
import { StatusChangeModal } from '~/components/modals/StatusChange';
import { NullStateProps } from '~/components/NullState';
import { Alert } from '~/components/ui/Alert';
import { BasicTable, SortConfig } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { ClosureStatus } from '~/containers/OpsDashboardV2/ClosuresTab/types';
import { ContentOptions } from '~/utils/contentstack';

type ClosuresTabProps = {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
};

const sortFieldToSymphony = new Map([['createdAt', CloseWorkflowField.CREATED]]);

interface CloseWorkflowUpdateItem {
  item: ClosureItem;
  newStatus: ClosureStatus;
}

export const ClosuresTab: React.FC<ClosuresTabProps> = ({
  contentOptions,
  commentColumn,
  nullStateConfig,
  onClientClick,
  onAccountClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  currentUser,
}) => {
  const { onParamsUpdate, params } = useContext(OpsDashboardContext);

  const state = useMemo(() => {
    let value;
    try {
      value = JSON.parse(params.state || '{}');
    } catch (e) {
      return closureDefaultAppliedFilters;
    }

    return value;
  }, [params]);
  const appliedFilters: SelectedFilters = state.filters || closureDefaultAppliedFilters;
  const currentPage: number = state.currentPage || 1;
  const recordsPerPage: number = state.recordsPerPage;
  const searchFilter = state.searchFilter ?? null;
  const setCurrentPage = useCallback(
    (page: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: page }) });
    },
    [onParamsUpdate, params, state],
  );
  const setRecordsPerPage = useCallback(
    (value: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, recordsPerPage: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const sortConfig: SortConfig = useMemo(
    () =>
      state.sortConfig || {
        order: OrderType.DESCENDING,
        field: 'createdAt',
      },
    [state],
  );

  const [isBusy, setIsBusy] = useState(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
  const [selectedCloseWorkflowUpdateItem, setSelectedCloseWorkflowUpdateItem] = useState<CloseWorkflowUpdateItem>();

  const [UpdateCloseWorkflowV2] = useUpdateCloseWorkflowV2();
  const { loading, data, error } = useClosuresTabData({
    page: currentPage,
    statuses: geSelectedFilterStatuses(appliedFilters),
    searchFilter: searchFilter || null,
    contentOptions,
    hasOpenFlags: getHasOpenFlags(appliedFilters),
    ...(sortConfig && { ...sortConfig, field: sortFieldToSymphony.get(sortConfig.field) }),
    recordsPerPage,
    updateRecordsPerPage: setRecordsPerPage,
  });

  const { open: statusModalOpen, openModal: openStatusModal, onClose: statusModalOnClose } = useModalState();

  const onSelectNewStatusForItem = (item: ClosureItem, selectedStatus: ClosureStatus) => {
    setSelectedCloseWorkflowUpdateItem({
      item,
      newStatus: selectedStatus,
    });
    openStatusModal();
  };

  const onConfirmStatusChange = async () => {
    if (selectedCloseWorkflowUpdateItem?.item && selectedCloseWorkflowUpdateItem.newStatus) {
      setIsBusy(true);
      const { newStatus } = selectedCloseWorkflowUpdateItem;
      const { id, managedProductId } = selectedCloseWorkflowUpdateItem.item;
      const selectedCloseWorkflowStatus = closureStatusToCloseAccountStatusForUpdate(newStatus);
      if (selectedCloseWorkflowStatus) {
        try {
          await UpdateCloseWorkflowV2({
            variables: {
              managedProductId,
              closeWorkflowId: id,
              status: selectedCloseWorkflowStatus,
            },
          });
          data?.refetchAccountClosuresV2Data();
        } catch (e: any) {
          setErrorSaving(e);
        } finally {
          setIsBusy(false);
          statusModalOnClose();
        }
      }
    }
  };

  const displayStrings = useMemo(() => {
    if (data?.content) {
      return generateClosureStaticDisplayStrings(data.content);
    }
    return null;
  }, [data]);

  const onSort = useCallback(
    (field: string) => () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      onParamsUpdate({
        ...params,
        state: JSON.stringify({ ...state, currentPage: 1, sortConfig: { order: toggleOrder, field } }),
      });
    },
    [onParamsUpdate, params, state, sortConfig],
  );

  const onFilterChange = useCallback(
    (filters: SelectedFilters) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, filters }) });
    },
    [onParamsUpdate, params, state],
  );

  const onAppliedFiltersSelect = useCallback(() => {
    setFiltersPopupOpen(true);
  }, []);

  const updateOpenFiltersPopup = useCallback((value: boolean) => {
    setFiltersPopupOpen(value);
  }, []);

  const setSearchFilter = useCallback(
    (value: string | null) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, searchFilter: value }) });
    },
    [onParamsUpdate, params, state],
  );

  const closuresTable = useMemo(() => {
    if (data && displayStrings) {
      return (
        <ClosuresTable
          TableComponent={BasicTable}
          commentColumn={commentColumn}
          contentOptions={contentOptions}
          currentPage={currentPage}
          currentUser={currentUser}
          displayStrings={displayStrings}
          getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
          getClientNameRedirectUrl={getClientNameRedirectUrl}
          items={data.closureItems}
          nullStateConfig={nullStateConfig}
          onAccountClick={onAccountClick}
          onClientClick={onClientClick}
          onPageChange={setCurrentPage}
          onSelectNewStatusForItem={onSelectNewStatusForItem}
          onSort={onSort}
          paginationContext={data.paginationContext}
          refetchData={() => data.refetchAccountClosuresV2Data()}
          sortConfig={sortConfig}
          totalPages={data.totalPages}
        />
      );
    }
    return null;
  }, [
    commentColumn,
    contentOptions,
    currentPage,
    currentUser,
    data,
    displayStrings,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
    nullStateConfig,
    onAccountClick,
    onClientClick,
    onSelectNewStatusForItem,
    onSort,
    setCurrentPage,
    sortConfig,
  ]);

  return (
    <Stack direction="column" spacing={3}>
      {(error || loading) && (
        <OpsAlertAndLoading
          ariaLabel="Loading closure items"
          contentOptions={contentOptions}
          error={error}
          loading={loading}
        />
      )}
      <Stack>
        {errorSaving && <Alert contentOptions={contentOptions} error={errorSaving} severity="error" />}
        {data && displayStrings && !loading && (
          <>
            <Stack alignItems="center" data-qa="filter-container" flexDirection="row" justifyContent="space-between">
              <SearchBar
                onSearchChange={setSearchFilter}
                searchContext={data.searchContext}
                searchError={data.searchError}
                value={searchFilter}
              />
              <Filters
                appliedFilters={appliedFilters}
                content={displayStrings.filtersContent}
                contentOptions={contentOptions}
                defaultAppliedFilters={closureDefaultAppliedFilters}
                filterCountMap={data.filterCountMap}
                filters={displayStrings.closuresFilterConfig}
                onSubmit={onFilterChange}
                openFiltersPopup={filtersPopupOpen}
                updateOpenFiltersPopup={updateOpenFiltersPopup}
              />
            </Stack>
            <AppliedFilters
              appliedFilters={appliedFilters}
              content={displayStrings.appliedFiltersContent}
              filters={displayStrings.closuresFilterConfig}
              onAppliedFilterSelect={onAppliedFiltersSelect}
              searchResults={
                searchFilter &&
                !data.searchError && (
                  <RteContent
                    config={{ searchCount: data.paginationContext.totalItems, searchTerm: searchFilter }}
                    data={data.searchContext.totalSearchItemsLabel}
                  />
                )
              }
            />
            <Stack data-qa="closures-table-container">
              <>
                {closuresTable}
                <StatusChangeModal
                  contentOptions={contentOptions}
                  isBusy={isBusy}
                  onClose={statusModalOnClose}
                  onConfirm={onConfirmStatusChange}
                  open={statusModalOpen}
                  showDescriptionForCompleteStatus={
                    selectedCloseWorkflowUpdateItem?.newStatus === ClosureStatus.COMPLETED
                  }
                />
              </>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};
