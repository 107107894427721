export enum AssetClassTier {
  BROAD = 'BROAD',
  ENCLOSING = 'ENCLOSING',
  MODEL = 'MODEL',
}

export interface DonutAllocation {
  asset: {
    name: string;
  };
  broadAssetClass: string | null;
  enclosingAssetClass: string | null;
  targetAllocation: string;
}

export interface AssetAllocation {
  asset: {
    name: string;
  };
  broadAssetClass?: string | null;
  enclosingAssetClass?: string | null;
  recommendedSecurities?: Array<{ security: { name: string | null; ticker: string | null } }> | null;
  targetAllocation: string;
}
