import { useMemo } from 'react';

import { TabContent } from '../../../types';
import { useGetTradeConfirmationTabContent } from '../contentstack';

import { getTradeConfirmationTabContent } from './utils';

import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

export const useTradeConfirmationTabContent = ({ contentOptions }: Variables): AsyncResult<TabContent> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetTradeConfirmationTabContent({
    variables: contentOptions,
  });

  return useMemo(
    () => ({
      error: contentError,
      data: getTradeConfirmationTabContent(contentData),
      loading: contentLoading,
    }),
    [contentData, contentError, contentLoading],
  );
};
