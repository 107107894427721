import { parseISO } from 'date-fns';
import { ComponentProps, useMemo } from 'react';

import { GoalsCard } from '../../Card';
import { useGetReadinessStatuses, useGoalsEvent } from '../../hooks';
import { GoalActionArgs } from '../../types';
import { getRetirementGoalProjection } from '../../utils';
import { GoalSummaryContent, Summary } from '..';

import { useGoalCardSummaryContent } from './useGoalCardSummaryContent';
import {
  getGoalsCardAssociatedAccounts,
  getGoalsCardAttributes,
  getGoalsCardCtas,
  getGoalsCardProgressBar,
  goalsCardSort,
} from './utils';

import { AccountType, GoalStatus, GoalType } from '~/__generated__';
import { useGetGoals } from '~/hooks/goals/amplify';
import { useCoreConfig } from '~/utils/config';
import { AsyncResult } from '~/utils/types';

type Variables = Pick<
  ComponentProps<typeof Summary>,
  'contentOptions' | 'financialAccounts' | 'onAccountClick' | 'partnerConfigId' | 'partyId'
> & {
  actions: {
    onComplete: (args: GoalActionArgs) => void;
    onDelete: (args: GoalActionArgs) => void;
    onEdit: (args: GoalActionArgs) => void;
  };
};

type GoalsCardProps = { goalId: string } & Omit<ComponentProps<typeof GoalsCard>, 'contentOptions' | 'dataQa'>;
interface Data {
  completedGoals: GoalsCardProps[];
  content: GoalSummaryContent;
  myGoals: GoalsCardProps[];
}
export type GoalsCardPropsToSort = GoalsCardProps & { dateToSortBy?: Date };

export const useGoalSummary = ({
  actions,
  contentOptions,
  financialAccounts,
  onAccountClick,
  partnerConfigId,
  partyId,
}: Variables): AsyncResult<Data> => {
  const coreConfig = useCoreConfig();
  const emitEvent = useGoalsEvent();

  const goalCardSummaryContent = useGoalCardSummaryContent({ contentOptions });
  const goalsResult = useGetGoals({ variables: { partyId } });
  const readinessStatusesResult = useGetReadinessStatuses({ contentOptions, partnerConfigId });

  const error = goalCardSummaryContent.error ?? goalsResult.error;
  const loading = goalCardSummaryContent.loading || goalsResult.loading;

  const data = useMemo(() => {
    if (error || loading || !goalCardSummaryContent.data) {
      return;
    }
    const { getDescription, getImage, getLabelRte, getLabelText } = goalCardSummaryContent.data.goalCard;

    const { activeGoals, completedGoals, pendingGoals } = (
      goalsResult.data?.getUserByPartyId?.goals?.items ?? []
    ).reduce<{
      activeGoals: GoalsCardPropsToSort[];
      completedGoals: GoalsCardPropsToSort[];
      pendingGoals: GoalsCardPropsToSort[];
    }>(
      (acc, goal) => {
        if (!goal || !goalCardSummaryContent.data) {
          return acc;
        }

        const baseContent: GoalsCardProps['content'] = {
          completedDate: getLabelRte('completedDate'),
          image: getImage(goal.partnerGoalExternalRef),
          lastUpdated: getLabelRte('lastUpdated'),
          numOfAccountAssociated: getLabelRte('accountsAssociated'),
          showLess: getLabelText('showLess'),
          showMore: getLabelText('showMore'),
          title: goal.title,
        };
        const associatedAccountsTotal =
          goal.goalAccounts?.items.filter(i => i?.account.accountType === AccountType.USER).length ?? 0;
        const attributes = getGoalsCardAttributes({
          contentOptions,
          goal,
          goalCardSummaryContentData: goalCardSummaryContent.data,
        });
        const ctas = getGoalsCardCtas({
          actions,
          coreConfig,
          emitEvent,
          goal,
          goalCardSummaryContentData: goalCardSummaryContent.data,
        });
        const dates = {
          completed: goal.completedEndDate ? parseISO(goal.completedEndDate) : undefined,
          lastUpdated: goal.updatedAt ? parseISO(goal.updatedAt) : undefined,
        };

        switch (goal.goalStatus) {
          case GoalStatus.ACTIVE_COMPLETE:
          case GoalStatus.ACTIVE_INCOMPLETE:
            let dateToSortBy;
            if (goal.goalType === GoalType.RETIREMENT && goal.goalProjection) {
              try {
                dateToSortBy = parseISO(getRetirementGoalProjection(goal.goalProjection).retirementDate);
              } catch (err) {
                console.warn(`GoalsSummary: Unable to resolve retirement date to sort by: ${err}`);
              }
            } else if (goal.targetEndDate) {
              dateToSortBy = parseISO(goal.targetEndDate);
            }
            const baseProps = {
              associatedAccounts: getGoalsCardAssociatedAccounts({
                contentOptions,
                goal,
                financialAccounts,
                goalCardSummaryContentData: goalCardSummaryContent.data,
                onAccountClick,
              }),
              associatedAccountsTotal,
              attributes,
              content: { ...baseContent, description: getDescription(goal.goalStatus) },
              ctas,
              dates,
              dateToSortBy,
              goalId: goal.id,
            };
            if (goal.goalStatus === GoalStatus.ACTIVE_COMPLETE) {
              acc.activeGoals.push({
                ...baseProps,
                progressBar: getGoalsCardProgressBar(goal, goalCardSummaryContent.data, readinessStatusesResult.data),
              });
            } else {
              acc.pendingGoals.push(baseProps);
            }
            return acc;
          case GoalStatus.COMPLETED:
            if (goal.completedEndDate) {
              acc.completedGoals.push({
                associatedAccountsTotal,
                attributes,
                content: baseContent,
                ctas,
                dates,
                dateToSortBy: parseISO(goal.completedEndDate),
                goalId: goal.id,
                variant: 'compact',
              });
            }
            return acc;
        }
      },
      { activeGoals: [], completedGoals: [], pendingGoals: [] },
    );

    return {
      completedGoals: completedGoals.sort((a, b) => goalsCardSort(a, b, 'descending')),
      content: goalCardSummaryContent.data.content,
      myGoals: [
        ...pendingGoals.sort((a, b) => a.content.title.localeCompare(b.content.title)),
        ...activeGoals.sort(goalsCardSort),
      ],
    };
  }, [
    actions,
    contentOptions,
    coreConfig,
    emitEvent,
    error,
    financialAccounts,
    goalCardSummaryContent.data,
    goalsResult.data?.getUserByPartyId?.goals?.items,
    loading,
    onAccountClick,
    readinessStatusesResult.data,
  ]);

  return { data, error, loading };
};
