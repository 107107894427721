// TODO ANR-4496: Remove after BNS stops using AccountActions
export const bnsActionNames = [
  'editGicMaturityInstructions',
  'getInTouch',
  'manageTrustedContactPerson',
  'purchaseNewInvestment',
  'purchaseMore',
  'renewGic',
  'switchWithinAnAccount',
  'viewStatements',
  'viewTaxDocuments',
  'viewPac',
  'viewTransactionHistory',
] as const;
