import { BillingRateTargetType, BillingRateType } from '~/__generated__/symphonyTypes.v2';
import {
  GetModelPortfolioDetails_managedProduct_billingDataV2_finalizedBillingRateData_blendedBillingRateComponent,
  GetModelPortfolioDetails_managedProduct_billingDataV2_stagedBillingRateData_blendedBillingRateComponent,
} from '~/hooks/comparison/symphony/__generated__/query.v2';
import {
  GetManagedProductPricing_managedProduct_billingDataV2_stagedBillingRateData_defaultRates,
  GetManagedProductPricing_managedProduct_billingDataV2_stagedBillingRateData_overriddenRates,
} from '~/hooks/pricing/symphony/__generated__/query.v2';
import { GetManagedProductPricing_managedProduct_billingDataV2_stagedBillingRateData_blendedBillingRateComponent } from '~/hooks/pricing/symphony/__generated__/query.v2';
export type BlendedBillingRate =
  | GetManagedProductPricing_managedProduct_billingDataV2_stagedBillingRateData_blendedBillingRateComponent
  | GetModelPortfolioDetails_managedProduct_billingDataV2_finalizedBillingRateData_blendedBillingRateComponent
  | GetModelPortfolioDetails_managedProduct_billingDataV2_stagedBillingRateData_blendedBillingRateComponent;

export type BillingRates =
  | GetManagedProductPricing_managedProduct_billingDataV2_stagedBillingRateData_defaultRates
  | GetManagedProductPricing_managedProduct_billingDataV2_stagedBillingRateData_overriddenRates;

export enum FeePeriod {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}
export interface BlendedRates {
  configName: BillingRateTargetType;
  label: string;
  rate: string;
  rateType: BillingRateType;
}

export interface BillingTier {
  lowerBound?: number;
  rate: number;
  upperBound?: number;
}

export interface BillingRate {
  fee: (arg0: number) => number;
  label: string;
  rateTarget: BillingRateTargetType;
  rateType: BillingRateType;
  tiers: BillingTier[];
}

export enum DiscountType {
  Percentage,
  BasisPoint,
}

export type Discount = {
  discountType?: DiscountType;
  discountValue: number;
};
