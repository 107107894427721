import React, { FC } from 'react';

import { TooltipVariantProps } from '.';

import { ModalLink } from '~/components/ui/ModalLink';
import { IconButton, InfoIcon, MuiTooltip, useTheme } from '~/components/ui/mui';

export const PopupTooltip: FC<TooltipVariantProps> = ({
  anchorText,
  'aria-label': ariaLabel,
  buttonRef,
  children,
  iconPosition,
  onBlur,
  onClick,
  onKeyDown,
  open,
  placement,
  tooltipContent,
  disableFocusListener = true,
  disableHoverListener = true,
  disableTouchListener = true,
  ...props
}) => {
  const {
    sfTooltip: { styles },
  } = useTheme();

  return (
    <MuiTooltip
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      open={open}
      placement={placement}
      ref={buttonRef}
      sx={{ display: 'contents' }}
      title={tooltipContent}
      {...props}
      PopperProps={{ disablePortal: false }}
    >
      {children ? (
        <span onClick={onClick}>{children}</span>
      ) : anchorText ? (
        <span onClick={onClick}>
          <ModalLink>{anchorText}</ModalLink>
        </span>
      ) : (
        <IconButton
          aria-label={ariaLabel}
          onClick={onClick}
          size="small"
          sx={
            iconPosition === 'superscript'
              ? { ...styles.iconButton, verticalAlign: 'top', mt: 0, pt: 0, ml: 0, pl: 0 }
              : styles.iconButton
          }
        >
          <InfoIcon fontSize="inherit" />
        </IconButton>
      )}
    </MuiTooltip>
  );
};
