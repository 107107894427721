import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetLinkBankAccountContent } from '../contentstack';
import { GetLinkBankAccountModalContent_all_link_bank_account_modal_items_error_messages } from '../contentstack/__generated__/query.v2';

import { useLazyGetFinancialInstitutionByRoutingNumber } from '~/hooks/financial-account/symphony';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface LinkBankAccountContent {
  accountNumber: string;
  accountType: {
    checking: string;
    savings: string;
  };
  bankName: string;
  bankNameNotFound: string;
  confirmAccountNumber: string;
  confirmRoutingNumber: string;
  ctas: {
    primary: string;
    secondary: string;
  };
  errorMessages?: (GetLinkBankAccountModalContent_all_link_bank_account_modal_items_error_messages | null)[] | null;
  financialInstitutionChecking: string;
  financialInstitutionSavings: string;
  header: string;
  nameOnAccount: string;
  routingNumber: string;
  submitErrorMessage: string;
  validations: {
    accountNumber: string;
    bankName: string;
    confirmAccountNumberMismatch: string;
    confirmRoutingNumberMismatch: string;
    nameOnAccount: string;
    routingNumber: string;
  };
}

export interface LinkBankAccountResult {
  financialInstitutionName?: string | null;
  financialInstitutionNameLoading: boolean;
  getFinancialInstitutioNameByRoutingNumber: (value: string | null) => void;
  updateFinancialInstitutioName: (value?: string | null) => void;
}
export interface LinkBankAccountData {
  content: LinkBankAccountContent;
  data: LinkBankAccountResult;
}

export const useGetLinkBankAccountContentData = ({
  open,
  contentOptions,
}: {
  contentOptions: ContentOptions;
  open?: boolean;
}): AsyncResult<LinkBankAccountData> => {
  const [financialInstitutionName, setFinancialInstitutiontName] = useState<string | null>();
  const {
    data: contentstackData,
    error: contentstackError,
    loading: contentstackDataLoading,
  } = useGetLinkBankAccountContent({
    variables: contentOptions,
    skip: !open,
  });
  const [
    getFinancialInstitutionByRoutingNumber,
    { data: financialAccountNameData, loading: financialAccountNameLoading },
  ] = useLazyGetFinancialInstitutionByRoutingNumber({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !open,
  });

  const getFinancialInstitutioNameByRoutingNumber = useCallback(
    (value: string | null) => {
      if (!value) {
        return;
      }
      getFinancialInstitutionByRoutingNumber({
        variables: {
          routingNumber: value,
        },
      });
    },
    [getFinancialInstitutionByRoutingNumber],
  );

  const updateFinancialInstitutioName = () => {
    setFinancialInstitutiontName(undefined);
  };

  useEffect(() => {
    if (financialAccountNameData) {
      setFinancialInstitutiontName(financialAccountNameData?.financialInstitutionByRoutingNumber);
    }
  }, [financialAccountNameData]);

  const data = useMemo(() => {
    if (contentstackError || contentstackDataLoading) {
      return;
    }

    const content = contentstackData?.all_link_bank_account_modal?.items?.[0];
    const textContent = content?.fields?.text ?? [];
    return {
      content: {
        accountType: {
          checking: content?.account_type?.checking ?? '',
          savings: content?.account_type?.savings ?? '',
        },
        accountNumber: content?.account_number?.label ?? '',
        bankName: content?.bank_name?.label ?? '',
        bankNameNotFound: findFieldValue(textContent, 'BankNameNotFound'),
        confirmAccountNumber: findFieldValue(textContent, 'ConfirmAccountNumber'),
        confirmRoutingNumber: findFieldValue(textContent, 'ConfirmRoutingNumber'),
        ctas: {
          primary: content?.ctas?.link_account ?? '',
          secondary: content?.ctas?.cancel ?? '',
        },
        errorMessages: content?.error_messages,
        financialInstitutionChecking: findFieldValue(textContent, 'FinancialInstitutionChecking'),
        financialInstitutionSavings: findFieldValue(textContent, 'FinancialInstitutionSavings'),
        header: content?.header ?? '',
        nameOnAccount: content?.name_on_account?.label ?? '',
        validations: {
          bankName: content?.bank_name?.error ?? '',
          nameOnAccount: content?.name_on_account?.error ?? '',
          routingNumber: content?.routing_number?.error ?? '',
          accountNumber: content?.account_number?.error ?? '',
          confirmAccountNumberMismatch:
            content?.validations?.find(val => val?.key === 'AccountNumberMismatch')?.value ?? '',
          confirmRoutingNumberMismatch:
            content?.validations?.find(val => val?.key === 'RoutingNumberMismatch')?.value ?? '',
        },
        routingNumber: content?.routing_number?.label ?? '',
        submitErrorMessage: content?.submit_error_message ?? '',
      },
      data: {
        financialInstitutionName,
        financialInstitutionNameLoading: financialAccountNameLoading,
        getFinancialInstitutioNameByRoutingNumber,
        updateFinancialInstitutioName,
      },
    };
  }, [
    contentstackData?.all_link_bank_account_modal?.items,
    contentstackDataLoading,
    contentstackError,
    financialInstitutionName,
    financialAccountNameData?.financialInstitutionByRoutingNumber,
    financialAccountNameLoading,
    getFinancialInstitutioNameByRoutingNumber,
  ]);

  return {
    data,
    error: contentstackError,
    loading: contentstackDataLoading,
  };
};
