import { useMemo } from 'react';

import { GetPricingContent_all_pricing_modal_items_fees } from '~/components/modals/Pricing/contentstack/__generated__/query.v2';
import { FeePeriod, getProgramFee } from '~/utils/pricing';

export const useGetEstimatedFees = (
  accountValue: number,
  feeItems: (GetPricingContent_all_pricing_modal_items_fees | null)[],
): {
  estimatedAnnualFeePercent: number;
  estimatedMonthlyFee: string;
} => {
  const estimatedAnnualFeePercent = useMemo(() => feeItems.reduce((sum, fee) => (sum += Number(fee?.value)), 0), [
    feeItems,
  ]);

  const estimatedMonthlyFee = useMemo(
    () => getProgramFee(accountValue, estimatedAnnualFeePercent / 100, FeePeriod.MONTHLY),
    [accountValue, estimatedAnnualFeePercent],
  );
  return { estimatedAnnualFeePercent, estimatedMonthlyFee };
};
