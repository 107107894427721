import {
  GetWithdrawalTableContent,
  GetWithdrawalTableContent_all_withdrawals_table_items_flag_referenceConnection_edges_node,
  GetWithdrawalTableContent_all_withdrawals_table_items_tax_withholding_infoConnection_edges_node,
  GetWithdrawalTableContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type FlagsReferenceContent = GetWithdrawalTableContent_all_withdrawals_table_items_flag_referenceConnection_edges_node;
export type TaxWithholdingContent = GetWithdrawalTableContent_all_withdrawals_table_items_tax_withholding_infoConnection_edges_node;

export const useGetWithdrawalTableContent = (
  options?: QueryHookOptions<GetWithdrawalTableContent, GetWithdrawalTableContentVariables>,
): QueryResult<GetWithdrawalTableContent, GetWithdrawalTableContentVariables> => {
  return useContentstackQuery(queries.GetWithdrawalTableContent, options);
};
