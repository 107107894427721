export enum BankVerificationModalContentLabel {
  ACCOUNT = 'account',
  ACCOUNT_ENTRY_METHOD = 'accountEntryMethod',
  ACCOUNT_TYPE_CHEQUING = 'accountTypeChequing',
  ACCOUNT_TYPE_SAVING = 'accountTypeSaving',
  ADDRESS = 'address',
  DISCREPANCIES_HEADER = 'discrepanciesHeader',
  DISCREPANCIES_TEXT = 'discrepanciesText',
  EMAIL = 'email',
  HEADER = 'header',
  ICON = 'icon',
  MANAGED_DATA_HEADER = 'managedDataHeader',
  MANUAL = 'manual',
  MASKED_ACCOUNT_NUMBER = 'maskedAccountNumber',
  NAME = 'name',
  PHONE = 'phone',
  PRIMARY_CTA = 'primaryCTA',
  ROUTING = 'routing',
  SECONDARY_CTA = 'secondaryCTA',
  SOURCE_DATA_HEADER = 'sourceDataHeader',
  STATUS = 'status',
  SUBTITLE = 'subtitle',
  TERTIARY_CTA = 'tertiaryCTA',
}
