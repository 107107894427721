import { PartyPerson } from '../symphony';

/**
 * Get the party's name from the partyPerson object using a combination of the givenName and familyName or the displayName.
 * @param partyPerson - The partyPerson object containing the party's names.
 * @returns The party's name or undefined if not applicable.
 */
export const getFullName = (partyPerson?: PartyPerson | null): string | undefined => {
  if (partyPerson) {
    if (partyPerson.displayName) {
      return partyPerson.displayName;
    }

    if (partyPerson.givenName && partyPerson.familyName) {
      return `${partyPerson.givenName} ${partyPerson.familyName}`;
    }
  }
  return undefined;
};
