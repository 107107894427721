import { QueryResult } from '@apollo/client';

import { GetPlaidReAuthenticationContent, GetPlaidReAuthenticationContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetPlaidReAuthenticateContent = (
  options?: QueryHookOptions<GetPlaidReAuthenticationContent, GetPlaidReAuthenticationContentVariables>,
): QueryResult<GetPlaidReAuthenticationContent, GetPlaidReAuthenticationContentVariables> => {
  return useContentstackQuery(queries.GetPlaidReAuthenticationContent, options);
};
