// eslint-disable-next-line no-restricted-imports
import { usePostHog } from 'posthog-js/react';
import { useCallback, useEffect, useState } from 'react';

const DEFAULT_CONFIG = {
  api_host: 'https://app.posthog.com',
  capture_performance: {
    web_vitals: true,
  },
} as const;

export const sfPosthog = {
  DEFAULT_CONFIG,

  /**
   * @param {any} location - A value representing the current routing location within an app.
   */
  useCaptureSpaPageViews: (location: any) => {
    const [previousLocation, setPreviousLocation] = useState(location);
    const posthog = usePostHog() as ReturnType<typeof usePostHog> | undefined; // usePostHog can return undefined
    const capturePageview = useCallback(() => posthog?.capture('$pageview'), [posthog]);
    useEffect(() => {
      if (location !== previousLocation) {
        capturePageview();
        setPreviousLocation(location);
      }
    }, [capturePageview, location, previousLocation]);
  },
};
