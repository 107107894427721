import { useCallback, useState } from 'react';

export const useLoadingButtonState = <T>(
  onClick?: (...args: T[]) => Promise<any> | void,
): [boolean, (...args: T[]) => Promise<any>] => {
  const [loading, setLoading] = useState(false);
  const wrappedOnClick = useCallback(
    async (...args: T[]) => {
      setLoading(true);
      try {
        await onClick?.(...args);
      } finally {
        setLoading(false);
      }
    },
    [onClick],
  );

  return [loading, wrappedOnClick];
};
