import { useCallback } from 'react';

import { useGetAllModelPortfoliosContentAcrossPages } from '~/hooks/model-portfolios/contentstack';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

type Variables = { contentOptions: ContentOptions };

type Data = {
  nameParser: (seriesBaseName: string, internalId: string) => string;
};

export const useModelPortfoliosContent = ({ contentOptions }: Variables): AsyncResult<Data> => {
  const {
    data: modelPortfoliosContent,
    error: modelPortfoliosContentError,
    loading: modelPortfoliosContentLoading,
  } = useGetAllModelPortfoliosContentAcrossPages({
    contentOptions,
  });

  const nameParser = useCallback(
    (seriesBaseName: string, internalId: string): string =>
      modelPortfoliosContent?.all_model_portfolio_data?.items?.find(
        mc => mc?.series_name === seriesBaseName && mc.internal_id === internalId,
      )?.model_portfolio_name_shorthand ?? 'Unknown',
    [modelPortfoliosContent?.all_model_portfolio_data?.items],
  );

  return {
    data: { nameParser },
    error: modelPortfoliosContentError,
    loading: modelPortfoliosContentLoading,
  };
};
