import { ComponentProps, useMemo } from 'react';

import { ContactAdvisorModal } from '..';
import { useGetContactAdvisorContent } from '../contentstack';
import { useGetPrimaryAdvisor } from '../symphony';

import { getContactAdvisorModalContent } from './utils';

import { AsyncResult } from '~/utils/types';

export type ContactAdvisorVariables = Pick<
  ComponentProps<typeof ContactAdvisorModal>,
  'contentOptions' | 'partyId' | 'open' | 'supportEmail' | 'supportPhoneNumber'
>;

export interface ContactAdvisorModalData {
  contactInfo: {
    email?: string;
    name?: string;
    phoneNumber?: string;
  };
  content: {
    advisor: {
      content: string;
      icon: string;
    };
    body: {
      heading: string;
      image: string;
    };
    ctas: {
      close: string;
    };
    email: {
      content: string;
      icon: string;
    };
    phone: {
      content: string;
      icon: string;
    };
    title: string;
  };
}

export const useContactAdvisorData = ({
  contentOptions,
  partyId,
  open,
  supportEmail,
  supportPhoneNumber,
}: ContactAdvisorVariables): AsyncResult<ContactAdvisorModalData> => {
  const { data: primaryAdvisorData, loading: primaryAdvisorLoading } = useGetPrimaryAdvisor({
    variables: { partyId },
    skip: !open,
  });

  const {
    data: contactAdvisorContent,
    error: contactAdvisorContentError,
    loading: contactAdvisorContentLoading,
  } = useGetContactAdvisorContent({
    variables: contentOptions,
    skip: !open,
  });

  const content = contactAdvisorContent?.all_contact_advisor_modal?.items?.[0] ?? undefined;
  const data = useMemo(() => {
    if (!content) {
      return;
    }

    // If primary advisor is present then use, otherwise use general support contact info
    const primaryAdvisor = primaryAdvisorData?.client?.party?.primaryAdvisor;
    const contactInfo = primaryAdvisorData
      ? {
          email: primaryAdvisor?.email ?? '',
          name:
            (primaryAdvisor?.givenName &&
              primaryAdvisor.familyName &&
              `${primaryAdvisor.givenName} ${primaryAdvisor.familyName}`) ??
            '',
          phoneNumber: primaryAdvisor?.phoneNumber ?? '',
        }
      : {
          email: supportEmail,
          phoneNumber: supportPhoneNumber,
        };

    return {
      contactInfo,
      content: getContactAdvisorModalContent(content),
    };
  }, [content, primaryAdvisorData, supportEmail, supportPhoneNumber]);

  return {
    data,
    error: contactAdvisorContentError,
    loading: contactAdvisorContentLoading || primaryAdvisorLoading,
  };
};
