import { isValid } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { pipe } from 'fp-ts/lib/function';
import React, { useMemo } from 'react';

import { Chart } from './Chart';
import { fillMissingData, formatNowDateString, getXAxisTicks } from './utils';

import { PerformanceCalculationInfo } from '~/components/modals/PerformanceCalculationInfo';
import { PerformanceDetails, PerformanceDetailsType } from '~/components/modals/PerformanceDetails';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Button, Card, CardContent, Grid, IconButton, InfoIcon, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { useLocale } from '~/hooks/locale';
import { ContentOptions, Locale } from '~/utils/contentstack/src/types';
import { formatCurrency, formatPercentage } from '~/utils/format';
import { formatDate } from '~/utils/format/date';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { DailyChartValue, DailyTableValue } from '~/utils/types';

interface Content {
  accessibility: { infoIcon: string };
  detailedTableCta: string;
  dialog?: string;
  disclosure?: string;
  heading?: string;
}

export interface Props {
  content: Content;
  contentOptions: ContentOptions;
  data: DailyChartValue[];
  dataQa?: string;
  displayDialog?: boolean;
  height?: number | string;
  percentageReturn?: string;
  showPerformanceCalculationInfo?: boolean;
  width?: number | string;
}

const formatBoundaryDate = (dateString: string, locale: Locale) =>
  pipe(dateString, parseISO, date => (isValid(date) ? formatDate(date, 'M/d/yy', { locale }) : 'N/A'));

export const PerformanceChart: React.FC<Props> = ({
  dataQa = 'performance-chart',
  contentOptions,
  height = 400,
  width = '100%',
  data,
  content,
  percentageReturn,
  displayDialog,
  showPerformanceCalculationInfo,
}) => {
  const { open, openModal, onClose } = useModalState();
  const {
    open: performanceCalculationInfoOpen,
    openModal: performanceCalculationInfoOpenModal,
    onClose: performanceCalculationInfoOnClose,
  } = useModalState();
  const [locale] = useLocale();
  const theme = useTheme();
  const styles = theme.sfPerformanceChart.styles;

  const xAxisTicks = getXAxisTicks(data);

  const startDate = formatBoundaryDate(xAxisTicks[0], contentOptions.locale);
  const endDate = formatBoundaryDate(xAxisTicks[xAxisTicks.length - 1], contentOptions.locale);

  // This is temporary, till the API can return data for non-trading days
  const completeData = fillMissingData(data);
  const performanceTableData = useMemo((): DailyTableValue[] => {
    return completeData.map(chartValue => {
      return {
        date: chartValue.date,
        dataPoints: [formatCurrency(chartValue.amount, { locale: contentOptions.locale })],
      };
    });
  }, [completeData, contentOptions.locale]);
  const isMediumScreen = useIsMediumScreen();
  return (
    <Box data-qa={dataQa} sx={{ overflow: 'hidden', position: 'relative' }}>
      <Box sx={{ filter: displayDialog ? 'blur(5px)' : 'none', pointerEvents: displayDialog ? 'none' : 'auto' }}>
        <Grid container pb={4} spacing={1}>
          {(content.heading || showPerformanceCalculationInfo) && (
            <Grid item md sx={isMediumScreen ? styles.mobileHeader : {}} xs={12}>
              <Grid container spacing={1}>
                {content.heading && (
                  <Grid item md="auto" xs>
                    <RteContent
                      config={{
                        percentageReturn: percentageReturn
                          ? formatPercentage(parseFloat(percentageReturn), {
                              decimals: 1,
                              removeTrailingZeroes: false,
                              locale: contentOptions.locale,
                            })
                          : '--',
                        startDate,
                        endDate,
                      }}
                      data={content.heading}
                    />
                  </Grid>
                )}
                {showPerformanceCalculationInfo && (
                  <Grid item md xs="auto">
                    <IconButton
                      aria-label={content.accessibility.infoIcon}
                      onClick={() => {
                        performanceCalculationInfoOpenModal();
                      }}
                      size="small"
                      sx={{ borderRadius: '50%', color: 'text.primary' }}
                    >
                      <InfoIcon fontSize="small" />
                    </IconButton>
                    <PerformanceCalculationInfo
                      contentOptions={contentOptions}
                      onClose={performanceCalculationInfoOnClose}
                      open={performanceCalculationInfoOpen}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {!!data.length && (
            <Grid item justifyContent="flex-start" xs={12}>
              <Button
                onClick={() => {
                  openModal();
                }}
                sx={{ textDecoration: styles.linkDecoration, p: 0 }}
              >
                <Typography sx={{ color: 'primary.main' }} variant="caption">
                  {content.detailedTableCta}
                </Typography>
              </Button>
              <PerformanceDetails
                contentOptions={contentOptions}
                onClose={onClose}
                open={open}
                performanceData={performanceTableData}
                type={PerformanceDetailsType.AccountBalance}
              />
            </Grid>
          )}
        </Grid>
        {!!completeData.length && (
          <Chart contentOptions={contentOptions} data={completeData} height={height} width={width} />
        )}
        {displayDialog && !completeData.length && <Box height={height} width={width} />}

        {content.disclosure && (
          <Box sx={{ mt: 3 }}>
            <RteContent config={{ now: formatNowDateString(locale) }} data={content.disclosure} />
          </Box>
        )}
      </Box>
      {displayDialog && (
        <Box
          alignItems="center"
          data-qa={`${dataQa}-dialog`}
          display="flex"
          justifyContent="center"
          position="absolute"
          sx={{ top: '30%', width: '100%' }}
          textAlign="center"
        >
          <Card sx={styles.displayDialog}>
            <CardContent>
              <InfoIcon sx={{ color: 'info.main', mb: 1 }} />
              <RteContent data={content.dialog || ''} variantMapping={{ subtitle2: 'p' }} />
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};
