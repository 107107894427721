import { useEffect, useState } from 'react';

import { useGetClientLedPlaidSuccessContent } from '../../contentstack';

import { ContentOptions, getImageAssetUrl } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Content {
  chaseAccountWarning: string;
  heading: string;
  modalTitle: string;
  primaryCtaText: string;
  productImgUrl: string;
  secondaryCtaText: string;
  subHeading: string;
  successImgUrl: string;
}

interface Data {
  content: Content;
}

interface Variables {
  contentOptions: ContentOptions;
}

export const useClientLedPlaidSuccessData = ({ contentOptions }: Variables): AsyncResult<Data> => {
  const [state, setState] = useState<AsyncResult<Data>>({
    loading: false,
  });

  const { data: contentData, loading: contentLoading, error: contentError } = useGetClientLedPlaidSuccessContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (contentLoading) {
      setState({ loading: true });
      return;
    }

    if (contentError) {
      setState({ loading: false, error: contentError });
      return;
    }

    const content = contentData?.all_plaid_funding?.items?.[0]?.client_led_plaid_success;

    setState({
      loading: false,
      data: {
        content: {
          chaseAccountWarning: content?.chase_account_warning ?? '',
          heading: content?.heading ?? '',
          modalTitle: content?.modal_title ?? '',
          primaryCtaText: content?.primary_cta ?? '',
          productImgUrl: getImageAssetUrl(content?.product_imageConnection),
          secondaryCtaText: content?.secondary_cta ?? '',
          subHeading: content?.sub_heading ?? '',
          successImgUrl: getImageAssetUrl(content?.success_imageConnection),
        },
      },
    });
  }, [contentData, contentLoading, contentError]);

  return state;
};
