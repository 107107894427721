import React, { ComponentProps } from 'react';

import { Box } from '~/components/ui/mui';

export const AllCashImage: React.FC<ComponentProps<typeof Box>> = ({ sx, ...rest }) => {
  return (
    <Box sx={{ height: 118, width: 118, ...sx }} {...rest}>
      <svg fill="none" height="100%" viewBox="0 0 118 118" width="100%" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M59 118C91.5848 118 118 91.5848 118 59C118 26.4152 91.5848 0 59 0C26.4152 0 0 26.4152 0 59C0 91.5848 26.4152 118 59 118Z"
          fill="#FAFAFA"
        />
        <path
          d="M36.5 57.75C33.7425 57.75 31.5 55.5075 31.5 52.75C31.5 52.06 32.0587 51.5 32.75 51.5C33.4412 51.5 34 52.06 34 52.75C34 54.1288 35.1212 55.25 36.5 55.25C37.8787 55.25 39 54.1288 39 52.75C39 52.06 39.5587 51.5 40.25 51.5C40.9412 51.5 41.5 52.06 41.5 52.75C41.5 55.5075 39.2575 57.75 36.5 57.75Z"
          fill="#F2609E"
        />
        <path
          d="M75.25 71.5L65.25 74L72.7175 83.0788C73.1975 83.6625 73.9125 84 74.6688 84C76.2625 84 77.4587 82.5413 77.1462 80.9788L75.25 71.5Z"
          fill="#F2609E"
        />
        <path
          d="M41.5002 71.5L51.5002 74L44.7014 83.065C44.2602 83.6538 43.5677 84 42.8314 84C41.4489 84 40.3689 82.8063 40.5064 81.4313L41.5002 71.5Z"
          fill="#F2609E"
        />
        <path
          d="M84 56.5H80.25L74.5625 73.1362L83.9287 67.9287C85.5162 67.0475 86.5 65.3737 86.5 63.5587V59C86.5 57.6187 85.3812 56.5 84 56.5Z"
          fill="#F2609E"
        />
        <path d="M66.5 47.75C70.25 39 77.75 39 77.75 39L76.5 50.25L66.5 47.75Z" fill="#F2609E" />
        <path
          d="M59 81.5C72.1168 81.5 82.75 72.5457 82.75 61.5C82.75 50.4543 72.1168 41.5 59 41.5C45.8832 41.5 35.25 50.4543 35.25 61.5C35.25 72.5457 45.8832 81.5 59 81.5Z"
          fill="#F2609E"
        />
        <path
          d="M74.625 61.5C76.3509 61.5 77.75 60.1009 77.75 58.375C77.75 56.6491 76.3509 55.25 74.625 55.25C72.8991 55.25 71.5 56.6491 71.5 58.375C71.5 60.1009 72.8991 61.5 74.625 61.5Z"
          fill="url(#paint0_radial)"
          opacity="0.3"
        />
        <path
          d="M74.625 56.5C73.59 56.5 72.75 57.34 72.75 58.375C72.75 59.41 73.59 60.25 74.625 60.25C75.66 60.25 76.5 59.41 76.5 58.375C76.5 57.34 75.66 56.5 74.625 56.5Z"
          fill="white"
        />
        <path
          d="M62.125 45.25H48.375C47.34 45.25 46.5 46.09 46.5 47.125C46.5 48.16 47.34 49 48.375 49H62.125C63.16 49 64 48.16 64 47.125C64 46.09 63.16 45.25 62.125 45.25Z"
          fill="#EC111A"
        />
        <path
          d="M50.76 49H59.74C62.2887 47.4712 64 44.6888 64 41.5C64 36.6675 60.0825 32.75 55.25 32.75C50.4175 32.75 46.5 36.6675 46.5 41.5C46.5 44.6888 48.2113 47.4712 50.76 49Z"
          fill="#FFD42F"
        />
        <path
          d="M55.25 47.75C58.7018 47.75 61.5 44.9518 61.5 41.5C61.5 38.0482 58.7018 35.25 55.25 35.25C51.7982 35.25 49 38.0482 49 41.5C49 44.9518 51.7982 47.75 55.25 47.75Z"
          fill="#FB6330"
        />
        <defs>
          <radialGradient
            cx="0"
            cy="0"
            gradientTransform="translate(74.625 58.375) scale(3.125)"
            gradientUnits="userSpaceOnUse"
            id="paint0_radial"
            r="1"
          >
            <stop />
            <stop offset="1" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </Box>
  );
};
