import React, { ComponentProps, useState } from 'react';

import { ClientInitiatePlaidModal } from '../ClientInitiatePlaid';

import { useGetPlaidReAuthenticationData } from './hooks/useGetPlaidReAuthenticationData';

import { PlaidProduct } from '~/__generated__';
import { FaPlaidFlow } from '~/components/FaPlaidFlow';
import { Modal } from '~/components/ui/Modal';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Button, Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { PlaidLinkType, usePlaidLinkHook } from '~/hooks/plaid-link';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  financialInstitution: string;
  onAuthenticationSuccess: () => void;
  onClose: () => void;
  partyId: string;
  partyIdFA?: string;
  plaidItemId: string;
  plaidLinkageType?: PlaidLinkType;
};

export const PlaidReAuthentication: React.FC<Props> = ({
  contentOptions,
  dataQa = 'plaid-re-authentication',
  financialInstitution,
  onClose,
  onAuthenticationSuccess,
  open,
  partyId,
  partyIdFA,
  plaidItemId,
  plaidLinkageType,
}) => {
  const { data, loading: contentLoading, error: contentError } = useGetPlaidReAuthenticationData({
    contentOptions,
    open,
  });
  const [openPlaidOtpVerification, setOpenPlaidOtpVerification] = useState(false);
  const { open: isOpenPlaidLinkModal, openModal: openPlaidLinkModal, onClose: onClosePlaidLinkModal } = useModalState();

  const handleSuccessPlaidLink = () => {
    onAuthenticationSuccess();
    onClose();
  };
  const handleCloseFaPlaidFlow = () => {
    setOpenPlaidOtpVerification(false);
  };

  const { handleOpenPlaidLink } = usePlaidLinkHook(undefined, undefined, handleSuccessPlaidLink, plaidItemId);

  const isMediumScreen = useIsMediumScreen();

  return (
    <Modal
      actions={
        <>
          <Button data-qa={`${dataQa}-close-cta`} fullWidth={isMediumScreen} onClick={onClose} variant="outlined">
            {data?.secondaryCtaLabel}
          </Button>
          <Button
            data-qa={`${dataQa}-re-auth-cta`}
            fullWidth={isMediumScreen}
            onClick={() => {
              if (plaidLinkageType === PlaidLinkType.CLIENT) {
                openPlaidLinkModal();
              } else {
                setOpenPlaidOtpVerification(true);
              }
            }}
            variant="contained"
          >
            {data?.primaryCtaLabel}
          </Button>
        </>
      }
      content={
        <>
          <Grid container spacing={2} sx={{ px: 4, py: 3 }}>
            <Grid item xs={12}>
              <Box data-qa={`${dataQa}-image`} sx={{ pb: 4 }}>
                <img alt="" src={data?.imageUrl} />
              </Box>
              <Typography variant="h5">{data?.heading}</Typography>
            </Grid>
          </Grid>
          {plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR && (
            <FaPlaidFlow
              contentOptions={contentOptions}
              financialInstitution={financialInstitution}
              isAccountLinkageSuccessful={!plaidItemId}
              isPlaidFlowLaunched={openPlaidOtpVerification}
              onCloseModalCallback={handleCloseFaPlaidFlow}
              partyId={partyId}
              partyIdFA={partyIdFA}
              plaidItemId={plaidItemId}
              plaidProducts={PlaidProduct.AUTH}
              refetchAccounts={onAuthenticationSuccess}
            />
          )}
          {plaidLinkageType === PlaidLinkType.CLIENT && (
            <>
              <ClientInitiatePlaidModal
                connectAccount={() => {
                  handleOpenPlaidLink(partyId, [PlaidProduct.AUTH]).then(_ => onClosePlaidLinkModal());
                }}
                contentOptions={contentOptions}
                onClose={onClosePlaidLinkModal}
                open={isOpenPlaidLinkModal}
              />
            </>
          )}
        </>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={contentError}
      loading={contentLoading}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      title={data?.title}
    />
  );
};
