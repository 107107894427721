import { isValid, parseISO } from 'date-fns';
import { pipe } from 'fp-ts/lib/function';
import { ComponentProps, useCallback, useMemo, useState } from 'react';

import { ConfirmDiscardPendingApplicationModal } from '../ConfirmDiscardPendingApplicationModal';
import { ConfirmNewAccountModal } from '../ConfirmNewAccountModal';
import { Account, getPartialAccount } from '../utils';

import { FinancialAccountStatus, OnboardingStates } from '~/__generated__';
import { AccountAction, AccountActionAugment, AccountActions, CustomActionArgs } from '~/components/AccountActions';
import { getAugmentedOrOverriddenAction, isCustomAction } from '~/components/AccountActions/utils';
import { useModalState } from '~/components/ui/Modal/hooks';
import { useAbandonDigitalWealth } from '~/hooks/onboarding/useAbandonDigitalWealth';
import { AccountState, isAccountVisible } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { fullDateFormat, hoursMinsDateFormat } from '~/utils/format/date';

export type AugmentableConfig = Omit<
  ComponentProps<typeof AccountActions>['config'],
  'continuePendingAccount' | 'discardPendingAccount' | 'openNewAccount'
> & {
  continuePendingAccount?: AccountAction | AccountActionAugment;
  discardPendingAccount?: AccountAction | AccountActionAugment;
  openNewAccount?: AccountAction | AccountActionAugment;
};

interface Data {
  confirmDiscardPendingApplicationModalProps?: ComponentProps<typeof ConfirmDiscardPendingApplicationModal>;
  confirmNewAccountModalProps?: ComponentProps<typeof ConfirmNewAccountModal>;
  partialAccountActionsConfig: {
    continuePendingAccount: AccountAction;
    discardPendingAccount: AccountAction;
    openNewAccount: AccountAction;
  };
}

interface Variables {
  accountsFilter?: (status: FinancialAccountStatus, onboardingState?: OnboardingStates) => boolean;
  actions: AugmentableConfig;
  allAccounts: Account[];
  contentOptions: ContentOptions;
  isPartialAccountVisible?: boolean;
  partialAccount?: Pick<Account, 'managedProductId' | 'assignedOn' | 'program'>;
  partyId: string;
  refetchAccounts: () => void;
}

export const usePartialAccountModals = ({
  accountsFilter,
  actions,
  allAccounts,
  contentOptions,
  partyId,
  refetchAccounts,
}: Variables): Data => {
  const {
    open: isConfirmOpenNewAccountModalOpen,
    openModal: openConfirmOpenNewAccountModal,
    onClose: onConfirmOpenNewAccountModalClose,
  } = useModalState();

  const [managedProductIdToDiscard, setManagedProductIdToDiscard] = useState<string | undefined>();
  const {
    open: isConfirmDiscardPendingApplicationModalOpen,
    openModal: openConfirmDiscardPendingApplicationModal,
    onClose: onConfirmDiscardPendingApplicationModalClose,
  } = useModalState();

  const [abandonDigitalWealth] = useAbandonDigitalWealth({ partyId });

  const partialAccount = useMemo(() => getPartialAccount(allAccounts), [allAccounts]);
  const isPartialAccountVisible = useMemo(
    () => partialAccount && (accountsFilter ?? isAccountVisible)(partialAccount.status, partialAccount.onboardingState),
    [accountsFilter, partialAccount],
  );

  const onFinishOpenNewAccount = useCallback(() => {
    if (isCustomAction(actions.postOpenNewAccount)) {
      actions.postOpenNewAccount.callback({ partyId });
    } else {
      console.warn('standard post action Open New Account not implemented');
    }
  }, [actions.postOpenNewAccount, partyId]);

  const handleStartOver = useCallback(async () => {
    if (partialAccount) {
      await abandonDigitalWealth(partialAccount.managedProductId);
    }
    if (isConfirmOpenNewAccountModalOpen) {
      onConfirmOpenNewAccountModalClose();
    }

    onFinishOpenNewAccount();
  }, [
    abandonDigitalWealth,
    isConfirmOpenNewAccountModalOpen,
    onConfirmOpenNewAccountModalClose,
    onFinishOpenNewAccount,
    partialAccount,
  ]);

  const handleContinuePendingAccount = useCallback(() => {
    if (isConfirmOpenNewAccountModalOpen) {
      onConfirmOpenNewAccountModalClose();
    }
    if (actions.continuePendingAccount && isCustomAction(actions.continuePendingAccount)) {
      actions.continuePendingAccount.callback({ partyId });
    }
  }, [isConfirmOpenNewAccountModalOpen, actions.continuePendingAccount, onConfirmOpenNewAccountModalClose, partyId]);

  const handleDiscardPendingAccount = useCallback(
    (customActionArgs?: CustomActionArgs) => {
      setManagedProductIdToDiscard(customActionArgs?.managedProductId);
      openConfirmDiscardPendingApplicationModal();
    },
    [openConfirmDiscardPendingApplicationModal],
  );

  const handleDiscardPendingSuccess = useCallback(() => {
    refetchAccounts();
    setManagedProductIdToDiscard(undefined);
    onConfirmDiscardPendingApplicationModalClose();
  }, [refetchAccounts, onConfirmDiscardPendingApplicationModalClose]);

  const handleDiscardPendingClose = useCallback(() => {
    setManagedProductIdToDiscard(undefined);
    onConfirmDiscardPendingApplicationModalClose();
  }, [onConfirmDiscardPendingApplicationModalClose]);

  const continuePendingAccountConfig: AccountAction = useMemo(
    () =>
      getAugmentedOrOverriddenAction(
        {
          type: 'custom',
          callback: onFinishOpenNewAccount,
          valid: ({ state }) => state === AccountState.OnboardingIncomplete,
        },
        actions.continuePendingAccount,
      ),
    [actions.continuePendingAccount, onFinishOpenNewAccount],
  );

  const discardPendingAccountConfig: AccountAction = useMemo(
    () =>
      getAugmentedOrOverriddenAction(
        {
          type: 'custom',
          callback: handleDiscardPendingAccount,
          valid: ({ state }) => state === AccountState.OnboardingIncomplete,
        },
        actions.discardPendingAccount,
      ),
    [actions.discardPendingAccount, handleDiscardPendingAccount],
  );

  const handleOpenNewAccount = useCallback(async () => {
    const canContinueCurrentPartial =
      partialAccount &&
      continuePendingAccountConfig.valid?.({
        allAccounts,
        state: AccountState.OnboardingIncomplete,
        managedProductType: partialAccount.program,
      });

    if (canContinueCurrentPartial && isPartialAccountVisible) {
      openConfirmOpenNewAccountModal();
    } else if (!canContinueCurrentPartial && isPartialAccountVisible) {
      // ideally, we should show a discard modal here,
      // but for now, it will silently discard and start over
      return handleStartOver();
    } else if (canContinueCurrentPartial && !isPartialAccountVisible) {
      handleContinuePendingAccount();
    } else {
      return handleStartOver();
    }
  }, [
    allAccounts,
    continuePendingAccountConfig,
    handleContinuePendingAccount,
    handleStartOver,
    isPartialAccountVisible,
    openConfirmOpenNewAccountModal,
    partialAccount,
  ]);

  // openNewAccount depends on the partialAccount at a client level
  const openNewAccountConfig: AccountAction = useMemo(
    () => getAugmentedOrOverriddenAction({ type: 'custom', callback: handleOpenNewAccount }, actions.openNewAccount),
    [handleOpenNewAccount, actions.openNewAccount],
  );

  return useMemo(
    () => ({
      confirmDiscardPendingApplicationModalProps: {
        contentOptions,
        managedProductId: managedProductIdToDiscard ?? '',
        onCancel: handleDiscardPendingClose,
        onClose: handleDiscardPendingClose,
        onDiscardSuccess: handleDiscardPendingSuccess,
        open: isConfirmDiscardPendingApplicationModalOpen,
        partyId,
      },
      confirmNewAccountModalProps: partialAccount && {
        contentOptions,
        dateStarted: pipe(partialAccount.assignedOn, parseISO, date =>
          isValid(date) ? fullDateFormat(date, { locale: contentOptions.locale }) : 'Unknown date',
        ),
        onClose: onConfirmOpenNewAccountModalClose,
        onContinue: handleContinuePendingAccount,
        onStartOver: handleStartOver,
        open: isConfirmOpenNewAccountModalOpen,
        timeStarted: pipe(partialAccount.assignedOn, parseISO, date =>
          isValid(date) ? hoursMinsDateFormat(date, { locale: contentOptions.locale }) : 'Unknown time',
        ),
      },
      partialAccountActionsConfig: {
        openNewAccount: openNewAccountConfig,
        continuePendingAccount: continuePendingAccountConfig,
        discardPendingAccount: discardPendingAccountConfig,
      },
    }),
    [
      contentOptions,
      managedProductIdToDiscard,
      handleDiscardPendingClose,
      handleDiscardPendingSuccess,
      isConfirmDiscardPendingApplicationModalOpen,
      partyId,
      partialAccount,
      onConfirmOpenNewAccountModalClose,
      handleContinuePendingAccount,
      handleStartOver,
      isConfirmOpenNewAccountModalOpen,
      openNewAccountConfig,
      continuePendingAccountConfig,
      discardPendingAccountConfig,
    ],
  );
};
