import { UpdateRmdData, UpdateRmdDataVariables } from './__generated__/mutation.v2';
import { GetRmdDetails, GetRmdDetailsVariables } from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export const useGetRmdDetails = (
  options?: QueryHookOptions<GetRmdDetails, GetRmdDetailsVariables>,
): QueryResult<GetRmdDetails, GetRmdDetailsVariables> => {
  return useSymphonyQuery(queries.GetRmdDetails, options);
};

export const useUpdateRmdData = (
  options?: MutationHookOptions<UpdateRmdData, UpdateRmdDataVariables>,
): MutationTuple<UpdateRmdData, UpdateRmdDataVariables> => {
  return useSymphonyMutation(mutations.UpdateRmdData, options);
};
