import React from 'react';

import { Box, SxProps, Theme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';

export interface NullStateProps {
  dataQa?: string;
  imageURL?: string;
  message?: string;
  styles?: SxProps<Theme>;
}

export const NullState: React.FC<NullStateProps> = ({ dataQa = 'null-state', imageURL, message, styles }) => {
  return (
    <Box sx={styles}>
      {imageURL && (
        <Box data-qa={`${dataQa}-image`} sx={{ height: '100px', mb: 5, textAlign: 'center' }}>
          <img alt="" src={imageURL} />
        </Box>
      )}
      <Typography align="center" color="text.secondary" variant="body2">
        <RteContent data={message || ''} data-qa={`${dataQa}-message`} />
      </Typography>
    </Box>
  );
};

export default NullState;
