import { GetBrokerageNames, GetBrokerageNamesVariables } from './__generated__/query.v2';
import {
  GetLinkBrokerageAccountContent,
  GetLinkBrokerageAccountContent_all_link_brokerage_account_items,
  GetLinkBrokerageAccountContent_all_link_brokerage_account_items_manual_entry,
  GetLinkBrokerageAccountContentVariables,
} from './__generated__/query.v2';
import { GetSupportedBrokerageAliases, GetSupportedBrokerageAliasesVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export type LinkBrokerageModalContent = GetLinkBrokerageAccountContent_all_link_brokerage_account_items;
export type LinkManualBrokerageContent = GetLinkBrokerageAccountContent_all_link_brokerage_account_items_manual_entry;

export const useGetLinkBrokerageAccountContent = (
  options?: QueryHookOptions<GetLinkBrokerageAccountContent, GetLinkBrokerageAccountContentVariables>,
) => {
  return useContentstackQuery(queries.GetLinkBrokerageAccountContent, options);
};

export const useLazyGetLinkBrokerageAccountContent = (
  options?: QueryHookOptions<GetLinkBrokerageAccountContent, GetLinkBrokerageAccountContentVariables>,
) => {
  return useLazyContentstackQuery(queries.GetLinkBrokerageAccountContent, options);
};

export const useGetSupportedBrokerageAliases = (
  options?: QueryHookOptions<GetSupportedBrokerageAliases, GetSupportedBrokerageAliasesVariables>,
) => {
  return useContentstackQuery(queries.GetLinkBrokerageAccountContent, options);
};

export const useLazyGetSupportedBrokerageAliases = (
  options?: QueryHookOptions<GetSupportedBrokerageAliases, GetSupportedBrokerageAliasesVariables>,
) => {
  return useLazyContentstackQuery(queries.GetSupportedBrokerageAliases, options);
};

export const useGetBrokeragesContentstack = (
  options?: QueryHookOptions<GetBrokerageNames, GetBrokerageNamesVariables>,
) => {
  return useContentstackQuery(queries.GetBrokerageNames, options);
};

export const useLazyGetBrokeragesContentstack = (
  options?: QueryHookOptions<GetBrokerageNames, GetBrokerageNamesVariables>,
) => {
  return useLazyContentstackQuery(queries.GetBrokerageNames, options);
};
