import React, { ComponentProps } from 'react';

import { ClientDocumentsModal } from '../modals/ClientDocumentsModal';
import { SuspendBillingModal } from '../modals/SuspendBilling';
import { SuspendTradingModal } from '../modals/SuspendTrading';

import { bnsActionNames } from './bnsAccountActions';
import { isModalAction, isStandardAction } from './utils';

import { FinancialAccountType, ManagedProductType, TransferFrequency } from '~/__generated__';
import { AddFundsModal } from '~/components/modals/AddFunds';
import { AddFundsV2Modal } from '~/components/modals/AddFundsV2';
import { CloseAccountModal } from '~/components/modals/CloseAccount';
import { EditAccountModal } from '~/components/modals/EditAccount';
import { InvestmentRestrictions } from '~/components/modals/InvestmentRestrictions';
import { OtherActionsModal } from '~/components/modals/OtherActions';
import { PricingModal } from '~/components/modals/Pricing';
import { RiskPreferenceHistoryModal } from '~/components/modals/RiskPreferenceHistory';
import { TaxLossHarvestingModal } from '~/components/modals/TaxLossHarvesting';
import { ViewDocumentsModal } from '~/components/modals/ViewDocuments';
import { ViewTransfersModal } from '~/components/modals/ViewTransfers';
import { WithdrawFundsModal } from '~/components/modals/WithdrawFunds';
import { Modal } from '~/components/ui/Modal';
import { Box } from '~/components/ui/mui';
import { AccountData } from '~/containers/AccountSummary/types';
import { ManagedProductAttributes } from '~/hooks/account-details/symphony';
import { PlaidLinkType } from '~/hooks/plaid-link';
import { AccountState, AccountStateData } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack/src/types';

export const allActionNames = [
  ...bnsActionNames,
  'addFunds',
  'addRestrictions',
  'brokerageTransfer',
  'backToMarketing',
  'closeAccount',
  'continuePendingAccount',
  'discardPendingAccount',
  'editAccount',
  'editAccountProfile',
  'finishPendingAccount',
  'generateDocuments',
  'inquiryOnly',
  'journaling',
  'launchSchedulingTool',
  'openNewAccount',
  'other',
  'postOpenNewAccount',
  'quarterlyPerformance',
  'raiseCash',
  'resendDocusign',
  'retakeQuestionnaire',
  'seeDetails',
  'suspendBilling',
  'suspendTrading',
  'toggleTlh',
  'viewActivity',
  'viewClientDocuments',
  'viewDocuments',
  'viewPricing',
  'viewRiskPreferenceHistory',
  'viewTransfers',
  'withdrawFunds',
] as const;

export type ActionName = typeof allActionNames[number];

export const isActionName = (value?: string): value is ActionName => {
  return allActionNames.includes(value as ActionName);
};

type BaseAction = {
  /**
   * The props to the respective account action Component to override with.
   */
  props?: Record<string, ComponentProps<any>>;
  type: 'standard' | 'custom' | 'modal';

  /**
   * Return true if an action should be displayed, false if an action should not be displayed.
   */
  valid?: (args: {
    allAccounts?: Pick<AccountData, 'status' | 'program'>[];
    attributes?: ManagedProductAttributes[];
    // Used to check if an action is valid at the AccountSummary level.
    isClientLevel?: boolean;
    managedProductType?: ManagedProductType;
    state?: AccountState;
    stateData?: AccountStateData;
    type?: FinancialAccountType;
  }) => boolean;
};
/**
 * Standard Action opens a Modal.
 */
export type StandardAction = BaseAction & {
  type: 'standard';
};

export type CustomActionArgs = Partial<
  Pick<
    AccountData,
    | 'accountNumber'
    | 'balance'
    | 'lastRtqDate'
    | 'managedProductId'
    | 'maskedAccountNumber'
    | 'partyId'
    | 'questionnaire'
    | 'isQuarterlyPerformance'
    | 'type'
  >
> & {
  enablePendingPUWWarning?: boolean;
  refetchAccounts?: () => Promise<any>;
};

export type CustomAction = BaseAction & {
  callback: (args?: CustomActionArgs) => Promise<any> | void;
  type: 'custom';
};
export type ModalActionArgs = CustomActionArgs & {
  openModal: () => void;
};
export type ModalAction = BaseAction & {
  callback: (args: ModalActionArgs) => void;
  modal: (args: Pick<ComponentProps<typeof Modal>, 'open' | 'onClose'>) => React.ReactNode;
  type: 'modal';
};

export type AccountAction = StandardAction | CustomAction | ModalAction;

export type StandardActionAugments = Partial<StandardAction> & Pick<StandardAction, 'type'>;

export type CustomActionAugments = Partial<CustomAction> & Pick<CustomAction, 'type'>;

export type ModalActionAugments = Partial<ModalAction> & Pick<ModalAction, 'type'>;

export type AccountActionAugments = StandardActionAugments | CustomActionAugments | ModalActionAugments;

export type AccountActionAugment = {
  augments: AccountActionAugments;
  type: 'augment';
};

type Config = {
  [key in ActionName]?: AccountAction;
};

export interface Props {
  addFunds?: Omit<ComponentProps<typeof AddFundsModal>, 'contentOptions'>;
  addFundsV2?: Omit<ComponentProps<typeof AddFundsV2Modal>, 'contentOptions'>;
  addRestrictions?: Omit<
    ComponentProps<typeof InvestmentRestrictions>,
    'contentOptions' | 'partyId' | 'managedProductId'
  >;
  allAccounts?: Pick<AccountData, 'status' | 'program'>[];
  attributes?: ManagedProductAttributes[];
  closeAccount?: Omit<ComponentProps<typeof CloseAccountModal>, 'contentOptions'>;
  config: Config;
  contentOptions: ContentOptions;
  dataQa?: string;
  editAccount?: Omit<ComponentProps<typeof EditAccountModal>, 'contentOptions'>;
  managedProductId: string;
  managedProductType?: ManagedProductType;
  other?: Omit<ComponentProps<typeof OtherActionsModal>, 'contentOptions'>;
  partyId: string;
  plaidLinkageType?: PlaidLinkType;
  raiseCash?: Omit<ComponentProps<typeof WithdrawFundsModal>, 'contentOptions'>;
  retakeQuestionnaire?: ComponentProps<typeof Modal>;
  state: AccountState;
  stateData?: AccountStateData;
  suspendBilling?: Omit<ComponentProps<typeof SuspendBillingModal>, 'contentOptions'>;
  suspendTrading?: Omit<ComponentProps<typeof SuspendTradingModal>, 'contentOptions'>;
  switchWithinAnAccount?: ComponentProps<typeof Modal>;
  toggleTlh?: Omit<ComponentProps<typeof TaxLossHarvestingModal>, 'contentOptions'>;
  type: FinancialAccountType;
  viewClientDocuments?: Omit<ComponentProps<typeof ClientDocumentsModal>, 'contentOptions'>;
  viewDocuments?: Omit<ComponentProps<typeof ViewDocumentsModal>, 'contentOptions'>;
  viewPricing?: Omit<ComponentProps<typeof PricingModal>, 'contentOptions'>;
  viewRiskPreferenceHistory?: Omit<ComponentProps<typeof RiskPreferenceHistoryModal>, 'contentOptions'>;
  viewTransfers?: Omit<ComponentProps<typeof ViewTransfersModal>, 'contentOptions'>;
  withdrawFunds?: Omit<ComponentProps<typeof WithdrawFundsModal>, 'contentOptions'>;
}

const getModalActionModal = (
  action: ModalAction,
  key: string,
  modalState?: Partial<Pick<ComponentProps<typeof Modal>, 'onClose' | 'open'>>,
) => {
  const { open, onClose } = modalState ?? {};
  if (open === undefined || onClose === undefined) {
    console.warn('expected open and onClose Modal props to be provided');
    return null;
  }
  return <Box key={key}>{action.modal({ open, onClose })}</Box>;
};

/**
 * Given the config, the component renders Modals for the applicable account actions.
 * As a result only the 'standard' and 'modal' types are applicable, while the 'custom' type would invoke callback.
 * TODO: DA2-5955 - Refactor to make it partner agnostic
 */
export const AccountActions: React.FC<Props> = ({
  allAccounts,
  dataQa = 'account-actions',
  contentOptions,
  state,
  stateData,
  partyId,
  managedProductId,
  type,
  managedProductType,
  attributes,
  config,
  addFunds,
  addFundsV2,
  addRestrictions,
  plaidLinkageType,
  retakeQuestionnaire,
  switchWithinAnAccount,
  withdrawFunds,
  viewClientDocuments,
  viewDocuments,
  viewRiskPreferenceHistory,
  viewPricing,
  viewTransfers,
  closeAccount,
  suspendBilling,
  suspendTrading,
  editAccount,
  toggleTlh,
  other,
}) => {
  const {
    featureFlags: { useAddFundsV2 },
  } = useCoreConfig();

  return (
    <div data-qa={dataQa}>
      {(Object.entries(config) as Array<[ActionName, NonNullable<Config[ActionName]>]>).map(([actionName, action]) => {
        const valid = action.valid ?? ((_: any) => true);
        if (!valid({ allAccounts, type, state, stateData, attributes, managedProductType })) {
          return null;
        }

        // Add to the useAccountActionModals hook for modal trigger and the associated states.
        switch (actionName) {
          case 'addFunds':
            if (isStandardAction(action)) {
              if (addFundsV2 && useAddFundsV2) {
                return (
                  <AddFundsV2Modal
                    contentOptions={contentOptions}
                    key={actionName}
                    plaidLinkageType={plaidLinkageType}
                    {...addFundsV2}
                    {...config.addFunds?.props}
                  />
                );
              }
              if (addFunds) {
                return (
                  <AddFundsModal
                    contentOptions={contentOptions}
                    key={actionName}
                    plaidLinkageType={plaidLinkageType}
                    {...addFunds}
                    {...config.addFunds?.props}
                  />
                );
              }
              console.warn('expected addFunds props to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, addFunds);
            }
            return null;
          case 'addRestrictions':
            if (isStandardAction(action)) {
              if (addRestrictions) {
                return (
                  <InvestmentRestrictions
                    contentOptions={contentOptions}
                    key={actionName}
                    managedProductId={managedProductId}
                    partyId={partyId}
                    {...addRestrictions}
                    {...config.addRestrictions?.props}
                  />
                );
              }
              console.warn('expected addRestrictions props to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, addRestrictions);
            }
            return null;
          case 'backToMarketing':
            return null;
          case 'closeAccount':
            if (isStandardAction(action)) {
              if (closeAccount) {
                return (
                  <CloseAccountModal
                    contentOptions={contentOptions}
                    financialAccountType={type}
                    key={actionName}
                    plaidLinkageType={plaidLinkageType}
                    {...closeAccount}
                    {...config.closeAccount?.props}
                  />
                );
              }
              console.warn('expected closeAccount prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, closeAccount);
            }
            return null;
          case 'suspendBilling':
            if (isStandardAction(action)) {
              if (suspendBilling) {
                return (
                  <SuspendBillingModal
                    contentOptions={contentOptions}
                    key={actionName}
                    {...suspendBilling}
                    {...config.suspendBilling?.props}
                  />
                );
              }
              console.warn('expected suspendBilling prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, suspendBilling);
            }
            return null;
          case 'suspendTrading':
            if (isStandardAction(action)) {
              if (suspendTrading) {
                return (
                  <SuspendTradingModal
                    contentOptions={contentOptions}
                    key={actionName}
                    {...suspendTrading}
                    {...config.suspendTrading?.props}
                  />
                );
              }
              console.warn('expected suspendTrading prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, suspendTrading);
            }
            return null;
          case 'continuePendingAccount':
            if (isStandardAction(action)) {
              console.warn('standard continue pending account not implemented');
            } else if (isModalAction(action)) {
              console.warn('continue pending account action not implemented');
            }
            return null;
          case 'discardPendingAccount':
            if (isStandardAction(action)) {
              console.warn('standard discard pending account not implemented');
            } else if (isModalAction(action)) {
              console.warn('discard pending account action not implemented');
            }
            return null;
          case 'editAccountProfile': {
            if (isStandardAction(action)) {
              console.warn('standard edit account profile not implemented');
            }
            return null;
          }
          case 'editAccount':
            if (isStandardAction(action)) {
              if (editAccount) {
                return (
                  <EditAccountModal
                    contentOptions={contentOptions}
                    key={actionName}
                    {...editAccount}
                    {...config.editAccount?.props}
                  />
                );
              }
              console.warn('expected editAccount prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, editAccount);
            }
            return null;
          case 'finishPendingAccount':
            // Different from continuePendingAccount where the rest of the onboarding step is outside of the normal flow.
            // It could be either deeplinking to partner's experience to continue onboarding, or for Docusign.
            if (isStandardAction(action) || isModalAction(action)) {
              console.warn('finish pending account not implemented');
            }
            return null;
          case 'journaling':
            if (isStandardAction(action) || isModalAction(action)) {
              // TODO: https://sigfig.atlassian.net/browse/DWCORE-6646
              console.warn('journaling standard action not implemented');
            }
            return null;
          case 'launchSchedulingTool':
            if (isStandardAction(action) || isModalAction(action)) {
              console.warn('standard launch scheduling tool not implemented');
            }
            return null;
          case 'openNewAccount':
            if (isStandardAction(action) || isModalAction(action)) {
              console.warn('standard open new account not implemented');
            }
            return null;
          case 'other':
            if (isStandardAction(action)) {
              if (other) {
                return <OtherActionsModal contentOptions={contentOptions} key={actionName} {...other} />;
              }
              console.warn('expected other prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, other);
            }
            return null;
          case 'postOpenNewAccount':
            // Special callback for optional post-action.
            // Eg: openNewAccount may launch a modal to prompt the user if they wish to discard the existing partial.
            return null;
          case 'retakeQuestionnaire':
            if (isStandardAction(action)) {
              console.warn('standard retake questionnaire not implemented');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, retakeQuestionnaire);
            }
            return null;
          case 'seeDetails':
            if (isStandardAction(action) || isModalAction(action)) {
              console.warn('standard "See Details" not implemented');
            }
            return null;
          case 'switchWithinAnAccount':
            if (isStandardAction(action)) {
              console.warn('standard "switch within an account" not implemented');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, switchWithinAnAccount);
            }
            return null;
          case 'toggleTlh':
            if (isStandardAction(action)) {
              if (toggleTlh) {
                return (
                  <TaxLossHarvestingModal
                    contentOptions={contentOptions}
                    key={actionName}
                    {...toggleTlh}
                    {...config.toggleTlh?.props}
                  />
                );
              }
              console.warn('expected toggleTlh prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, toggleTlh);
            }
            return null;
          case 'viewActivity':
            if (isStandardAction(action) || isModalAction(action)) {
              console.warn('standard "View Activity" not implemented');
            }
            return null;
          case 'viewClientDocuments':
            if (isStandardAction(action)) {
              if (viewClientDocuments) {
                return (
                  <ClientDocumentsModal
                    contentOptions={contentOptions}
                    key={actionName}
                    {...viewClientDocuments}
                    {...config.viewClientDocuments?.props}
                  />
                );
              }
              console.warn('expected viewClientDocuments prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, viewClientDocuments);
            }
            return null;
          case 'viewDocuments':
            if (isStandardAction(action)) {
              if (viewDocuments) {
                return (
                  <ViewDocumentsModal
                    contentOptions={contentOptions}
                    key={actionName}
                    {...viewDocuments}
                    {...config.viewDocuments?.props}
                  />
                );
              }
              console.warn('expected viewDocuments prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, viewDocuments);
            }
            return null;
          case 'viewRiskPreferenceHistory':
            if (isStandardAction(action)) {
              if (viewRiskPreferenceHistory) {
                return (
                  <RiskPreferenceHistoryModal
                    contentOptions={contentOptions}
                    {...viewRiskPreferenceHistory}
                    {...config.viewRiskPreferenceHistory?.props}
                  />
                );
              }
              console.warn('expected viewRiskPreferenceHistory prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, viewRiskPreferenceHistory);
            }
            return null;
          case 'viewPricing':
            if (isStandardAction(action)) {
              if (viewPricing) {
                return (
                  <PricingModal
                    contentOptions={contentOptions}
                    key={actionName}
                    {...viewPricing}
                    {...config.viewPricing?.props}
                  />
                );
              }
              console.warn('expected viewPricing prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, viewPricing);
            }
            return null;
          case 'viewTransfers':
            if (isStandardAction(action)) {
              if (viewTransfers) {
                return (
                  <ViewTransfersModal
                    contentOptions={contentOptions}
                    key={actionName}
                    {...viewTransfers}
                    {...action.props}
                  />
                );
              }
              console.warn('expected viewTransfers prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, viewTransfers);
            }
            return null;
          case 'raiseCash':
          // Only one of either raiseCash or withdrawFunds actions is expected at a time.
          case 'withdrawFunds':
            if (isStandardAction(action)) {
              if (withdrawFunds) {
                return (
                  <WithdrawFundsModal
                    availableTransferFrequencies={[TransferFrequency.MONTHLY]}
                    contentOptions={contentOptions}
                    key={actionName}
                    {...withdrawFunds}
                    financialAccountType={type}
                    isRaiseCash={actionName === 'raiseCash'}
                    plaidLinkageType={plaidLinkageType}
                    {...(config.raiseCash?.props ?? config.withdrawFunds?.props)}
                  />
                );
              }
              console.warn('expected withdrawFunds prop to be provided');
            } else if (isModalAction(action)) {
              return getModalActionModal(action, actionName, withdrawFunds);
            }
            return null;
          case 'quarterlyPerformance':
            // TODO: https://sigfig.atlassian.net/browse/DA2-4661
            return null;
          case 'brokerageTransfer':
            if (isStandardAction(action) || isModalAction(action)) {
              console.warn('standard "brokerageTransfer" not implemented');
            }
            return null;
          default:
            return null;
        }
      })}
    </div>
  );
};

export { AccountActionsMenu } from './Menu';
