import { useEffect, useState } from 'react';

import { ComparisonContent, useGetComparisonContent } from './contentstack';
import { useGetModelPortfolioDetails } from './symphony';
import {
  findRiskBandData,
  getModelPortfolioData,
  getPortfolioAssetDetailsContent,
  mergeAllocationData,
  ModelPortfolioData,
  PortfolioAssetDetailsContent,
} from './utils';

import { BalanceType, QuestionnaireFilterTypes } from '~/__generated__';
import { Allocation } from '~/components/AssetAllocationTable';
import { RiskBand } from '~/components/RiskSpectrum';
import { useGetAssetClassContent } from '~/hooks/asset-classes/contentstack';
import { useGetRecommendedProduct } from '~/hooks/managed-product/symphony';
import { useGetAllModelPortfoliosContentAcrossPages } from '~/hooks/model-portfolios/contentstack';
import { useGetRiskBands } from '~/hooks/questionnaire/useGetRiskBands';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface ComparisonVariables {
  assetClassTier?: AssetClassTier;
  contentOptions: ContentOptions;
  managedProductId: string;
  partyId: string;
  skip?: boolean;
}

export interface ComparisonData {
  accountBalance?: number;
  comparisonContent: ComparisonContent;
  currentAssetAllocationData: Allocation[];
  currentInvestmentObjective: string;
  currentModelPortfolioData: ModelPortfolioData;
  currentRiskBand?: RiskBand;
  isCurrentScoreInNewRiskBand: boolean;
  mergedAssetAllocationData: Allocation[];
  newAssetAllocationData: Allocation[];
  newModelPortfolioData: ModelPortfolioData;
  newRiskBand?: RiskBand;
  planId?: string;
  planUpdateWorkflowId?: string;
  portfolioAssetDetailsContent: PortfolioAssetDetailsContent;
  recommendationRationale?: string;
}

export const usePortfolioComparison = ({
  assetClassTier,
  contentOptions,
  managedProductId,
  partyId,
  skip,
}: ComparisonVariables): AsyncResult<ComparisonData> => {
  const [state, setState] = useState<AsyncResult<ComparisonData>>({
    loading: !skip,
    error: undefined,
    data: undefined,
  });

  const {
    data: modelPortfoliosContentData,
    loading: modelPortfoliosContentLoading,
    error: modelPortfoliosContentError,
  } = useGetAllModelPortfoliosContentAcrossPages({
    contentOptions,
    skip,
  });

  const {
    data: assetClassContentData,
    loading: assetClassContentLoading,
    error: assetClassContentError,
  } = useGetAssetClassContent({
    variables: contentOptions,
    skip,
  });

  const {
    data: comparisonContentData,
    loading: comparisonContentLoading,
    error: comparisonContentError,
  } = useGetComparisonContent({
    variables: contentOptions,
    skip,
  });

  const { data: riskBandData, loading: riskBandLoading, error: riskBandError } = useGetRiskBands(contentOptions, skip);

  const {
    data: currentProductData,
    loading: currentProductLoading,
    error: currentProductError,
  } = useGetRecommendedProduct({
    variables: { managedProductId, partyId, filter: QuestionnaireFilterTypes.ONBOARDING_COMPLETED },
    skip,
  });

  const {
    data: newRecommendedProductData,
    loading: newRecommendedProductLoading,
    error: newRecommendedProductError,
  } = useGetRecommendedProduct({
    variables: { managedProductId, partyId, filter: QuestionnaireFilterTypes.RTQ_COMPLETED },
    skip,
  });

  const currentOverriddenRiskScore = currentProductData?.managedProduct?.latestRiskPreference?.score?.value;
  const {
    data: comparisonModelPortfolioDetailsData,
    loading: comparisonModelPortfolioDetailsLoading,
    error: comparisonModelPortfolioDetailsError,
  } = useGetModelPortfolioDetails({
    variables: {
      partyId,
      managedProductId,
    },
    fetchPolicy: 'no-cache',
    skip,
  });

  const loading =
    modelPortfoliosContentLoading ||
    assetClassContentLoading ||
    comparisonContentLoading ||
    riskBandLoading ||
    newRecommendedProductLoading ||
    currentProductLoading ||
    comparisonModelPortfolioDetailsLoading;

  const error =
    modelPortfoliosContentError ||
    assetClassContentError ||
    comparisonContentError ||
    riskBandError ||
    newRecommendedProductError ||
    currentProductError ||
    comparisonModelPortfolioDetailsError;

  const financialAccount = comparisonModelPortfolioDetailsData?.client?.financialAccounts?.[0];
  const managedProduct = comparisonModelPortfolioDetailsData?.managedProduct;
  const accountBalance = parseFloat(
    financialAccount?.balances?.find(balance => balance.type === BalanceType.TOTAL_ACCOUNT)?.balance.value ?? '0',
  );

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      setState({
        loading: false,
        error,
      });
      return;
    }

    if (!managedProduct || !riskBandData) {
      return;
    }
    const assetClassContent = assetClassContentData?.all_asset_class_data?.items || [];
    const modelPortfolioContent = modelPortfoliosContentData?.all_model_portfolio_data?.items || [];

    const currentModelPortfolioData = getModelPortfolioData(
      managedProduct.id,
      managedProduct.targetModelPortfolio,
      assetClassContent,
      modelPortfolioContent,
      assetClassTier,
      managedProduct.billingDataV2?.finalizedBillingRateData?.blendedBillingRateComponent,
    );
    const newModelPortfolioData = getModelPortfolioData(
      managedProduct.id,
      managedProduct.stagedModelPortfolio,
      assetClassContent,
      modelPortfolioContent,
      assetClassTier,
      managedProduct.billingDataV2?.stagedBillingRateData?.blendedBillingRateComponent,
    );

    const comparisonContent: ComparisonContent = comparisonContentData?.all_comparison?.items?.[0];
    const portfolioAssetDetailsContent = getPortfolioAssetDetailsContent(comparisonContent);
    const newRecommendations = newRecommendedProductData?.managedProduct?.calculatedRecommendations;
    const newOverriddenRiskScore = newRecommendedProductData?.managedProduct?.planUpdateWorkflows?.[0]?.riskPreference;
    const currentAssetAllocationData = currentModelPortfolioData.assetAllocation;
    const newAssetAllocationData = newModelPortfolioData.assetAllocation;
    const currentInvestmentObjective =
      managedProduct.paperwork?.find(p => !!p.investment?.investmentObjective)?.investment?.investmentObjective ?? '';
    const currentRecommendations = currentProductData?.managedProduct?.calculatedRecommendations;
    const mergedAssetAllocationData: Allocation[] = mergeAllocationData(
      currentAssetAllocationData,
      newAssetAllocationData,
    );

    const planUpdateWorkflowId = managedProduct.planUpdateWorkflows?.[0]?.id;
    const planId = newRecommendedProductData?.managedProduct?.planId;
    const recommendationRationale = managedProduct.planUpdateWorkflows?.[0]?.recommendationRationale ?? '';
    const currentRiskBand = findRiskBandData(
      riskBandData?.riskBands,
      currentRecommendations ?? undefined,
      currentOverriddenRiskScore ?? undefined,
    );

    const newRiskBand = findRiskBandData(
      riskBandData?.riskBands,
      newRecommendations ?? undefined,
      newOverriddenRiskScore ?? undefined,
    );

    /**
     * Logic around overlapping current riskScore and new Band needs more explanation.
     * More Details: https://sigfig.atlassian.net/browse/DA2-5871?focusedCommentId=379276
     */
    const currentRiskScore = currentOverriddenRiskScore ?? currentRecommendations?.riskScore ?? 0;
    const isCurrentScoreInNewRiskBand = !!(
      newRiskBand &&
      newRiskBand.riskScoreCeiling >= currentRiskScore &&
      currentRiskScore >= newRiskBand.riskScoreFloor
    );
    setState({
      loading: false,
      data: {
        accountBalance,
        comparisonContent,
        currentAssetAllocationData,
        currentInvestmentObjective,
        currentModelPortfolioData,
        currentRiskBand,
        mergedAssetAllocationData,
        newAssetAllocationData,
        newModelPortfolioData,
        newRiskBand,
        planId,
        planUpdateWorkflowId,
        portfolioAssetDetailsContent,
        isCurrentScoreInNewRiskBand,
        recommendationRationale,
      },
    });
  }, [
    accountBalance,
    assetClassTier,
    loading,
    error,
    currentOverriddenRiskScore,
    modelPortfoliosContentData,
    assetClassContentData,
    comparisonContentData,
    riskBandData,
    newRecommendedProductData,
    currentProductData,
    managedProduct,
  ]);

  return state;
};
