import { GetViewDocumentsContent, GetViewDocumentsContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetViewDocumentsContent = (
  options?: QueryHookOptions<GetViewDocumentsContent, GetViewDocumentsContentVariables>,
): QueryResult<GetViewDocumentsContent, GetViewDocumentsContentVariables> => {
  return useContentstackQuery(queries.GetViewDocumentsContent, options);
};
