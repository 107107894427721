import React from 'react';

import { DateFilterConfig } from './types';

import { DateRange, DateRangePicker } from '~/components/ui/DateRangePicker';
import { Dropdown } from '~/components/ui/Dropdown';
import { Grid } from '~/components/ui/mui';

interface Props {
  dataQa?: string;
  dateFilterConfig: DateFilterConfig;
  onCustomDateRangeChange?: (dateRange?: DateRange) => void;
  onDateRangeOptionChange?: (option: string) => void;
  onYearOptionChange?: (option: number) => void;
}

export const DateFilters: React.FC<Props> = ({
  dataQa = 'date-filters',
  dateFilterConfig,
  onCustomDateRangeChange,
  onDateRangeOptionChange,
  onYearOptionChange,
}) => {
  return (
    <>
      {dateFilterConfig.yearOption && (
        <Grid item xs={2}>
          <Dropdown
            data-qa={`${dataQa}-${dateFilterConfig.yearOption.id}`}
            onChange={ev => onYearOptionChange?.(parseInt(ev.target.value.toString(), 10))}
            width="100%"
            {...dateFilterConfig.yearOption}
          />
        </Grid>
      )}
      {dateFilterConfig.dateRangeOption && (
        <Grid item minWidth="186px" xs={2}>
          <Dropdown
            data-qa={`${dataQa}-${dateFilterConfig.dateRangeOption.id}`}
            onChange={ev => onDateRangeOptionChange?.(ev.target.value.toString())}
            width="100%"
            {...dateFilterConfig.dateRangeOption}
          />
        </Grid>
      )}
      {dateFilterConfig.customDateRange && (
        <Grid item xs={5}>
          <DateRangePicker
            data-qa={`${dataQa}-${dateFilterConfig.customDateRange.key}`}
            fullWidth
            onChange={onCustomDateRangeChange}
            {...dateFilterConfig.customDateRange}
          />
        </Grid>
      )}
    </>
  );
};
