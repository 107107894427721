import { addYears, startOfToday } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { getV2Content } from './utils';

import { ManagedProductType, TransferFrequency } from '~/__generated__';
import { useGetContent, useGetContentV2 } from '~/components/modals/WithdrawFunds/contentstack';
import { useGetAccountBalances } from '~/components/modals/WithdrawFunds/symphony';
import { WithdrawFundsV2Content } from '~/components/modals/WithdrawFunds/types';
import {
  getWithdrawFundsContent,
  WithdrawFundsModalContent,
} from '~/components/modals/WithdrawFunds/WithdrawFundsModalContent';
import { useRmdData } from '~/components/Rmd/hooks/useRmdData';
import { RmdData } from '~/components/Rmd/types';
import { useClientInfo } from '~/hooks/client/useClientInfo';
import { GetBankAccountsForTransfer_client_financialAccounts } from '~/hooks/financial-account/symphony/__generated__/query.v2';
import { useGetMarketHolidayList } from '~/hooks/market-holidays/symphony';
import { getBankAccountId, getClientAgeFromBirthDate, isValidBankAccount } from '~/utils/account';
import { NetworkStatus } from '~/utils/apollo-client';
import { ContentOptions } from '~/utils/contentstack';
import { toSymphonyDate } from '~/utils/symphony';
import { AsyncResult } from '~/utils/types';

export interface WithdrawFundsHookVariables {
  availableTransferFrequencies: TransferFrequency[];
  contentOptions: ContentOptions;
  includeManagedProducts: boolean;
  isRaiseCash?: boolean;
  managedProductType: ManagedProductType;
  partyId: string;
  showVerifiedBankAccounts?: boolean;
  skip?: boolean;
  syncExternalBankAccounts?: boolean;
}

export interface WithdrawFundsHookData {
  clientAge: number | null;
  clientName: string;
  content: WithdrawFundsModalContent;
  contentV2: WithdrawFundsV2Content;
  financialAccounts: GetBankAccountsForTransfer_client_financialAccounts[];
  isRefetchingAccounts: boolean;
  marketHolidays: string[];
  refetchBankAccounts: () => void;
  rmdData?: RmdData;
}

export const useWithdrawFundsData = ({
  availableTransferFrequencies,
  contentOptions,
  includeManagedProducts,
  isRaiseCash,
  managedProductType,
  partyId,
  showVerifiedBankAccounts,
  skip,
  syncExternalBankAccounts,
}: WithdrawFundsHookVariables): AsyncResult<WithdrawFundsHookData> => {
  const [state, setState] = useState<AsyncResult<WithdrawFundsHookData>>({ loading: true });
  const {
    data: marketHolidayList,
    loading: marketHolidayListLoading,
    error: marketHolidayListError,
  } = useGetMarketHolidayList({
    variables: {
      from: toSymphonyDate(startOfToday(), contentOptions),
      to: toSymphonyDate(addYears(startOfToday(), 1), contentOptions),
    },
  });
  const { data: contentData, loading: contentLoading, error: contentError } = useGetContent({
    variables: contentOptions,
    skip,
  });
  const { data: contentV2Data, loading: contentV2Loading, error: contentV2Error } = useGetContentV2({
    variables: contentOptions,
    skip,
  });

  const { data: rmdData, loading: rmdLoading, error: rmdError } = useRmdData({ contentOptions, partyId });

  const contentV2 = useMemo(() => getV2Content(contentV2Data), [contentV2Data]);
  const {
    data: financialAccountData,
    loading: financialAccountLoading,
    error: financialAccountError,
    refetch: refetchFinancialAccountData,
    networkStatus: financialAccountNetworkStatus,
  } = useGetAccountBalances({
    variables: {
      partyId,
      syncExternalBankAccounts,
      isVerified: showVerifiedBankAccounts,
      includeManagedProducts,
    },
    fetchPolicy: 'no-cache',
    skip: skip || isRaiseCash,
    notifyOnNetworkStatusChange: true,
  });
  const isRefetchingAccounts = financialAccountNetworkStatus === NetworkStatus.refetch;

  const { data: clientInfoData, loading: clientInfoLoading, error: clientInfoError } = useClientInfo({
    variables: { partyId },
    skip,
  });

  useEffect(() => {
    if (
      contentError ||
      contentV2Error ||
      financialAccountError ||
      marketHolidayListError ||
      clientInfoError ||
      rmdError
    ) {
      setState({
        error:
          contentError ||
          contentV2Error ||
          financialAccountError ||
          marketHolidayListError ||
          clientInfoError ||
          rmdError,
        loading: false,
      });
    }
  }, [clientInfoError, contentError, contentV2Error, financialAccountError, marketHolidayListError, rmdError]);

  useEffect(() => {
    // Do not show a loading state if it is a refetch so that the entire modal is not re-rendered.
    const isLoading =
      !isRefetchingAccounts &&
      (contentLoading ||
        contentV2Loading ||
        (!isRaiseCash && financialAccountLoading) ||
        marketHolidayListLoading ||
        clientInfoLoading ||
        rmdLoading);
    if (isLoading) {
      setState({ loading: true });
    } else if (contentData && contentV2Data && marketHolidayList) {
      setState({
        loading: false,
        data: {
          marketHolidays: marketHolidayList.marketHolidays,
          content: getWithdrawFundsContent({
            availableTransferFrequencies,
            isRaiseCash,
            locale: contentOptions.locale,
            managedProductType,
            node: contentData,
          }),
          contentV2,
          financialAccounts:
            financialAccountData?.client?.financialAccounts
              ?.map(account => {
                return {
                  ...account,
                  id: getBankAccountId(account.id, account.isFromExternalSource, account.accountNumber),
                };
              })
              ?.filter(isValidBankAccount) ?? [],
          isRefetchingAccounts,
          clientAge: getClientAgeFromBirthDate(clientInfoData?.birthDate ?? ''),
          clientName: clientInfoData?.userName ?? '',
          refetchBankAccounts: refetchFinancialAccountData,
          rmdData,
        },
      });
    }
  }, [
    isRaiseCash,
    isRefetchingAccounts,
    financialAccountLoading,
    financialAccountData,
    contentLoading,
    marketHolidayListLoading,
    marketHolidayList,
    contentData,
    contentV2Data,
    managedProductType,
    contentOptions.locale,
    rmdData,
    rmdLoading,
    contentV2Loading,
    clientInfoLoading,
    availableTransferFrequencies,
    contentV2,
    clientInfoData?.birthDate,
    clientInfoData?.userName,
    refetchFinancialAccountData,
  ]);

  return state;
};
