import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { LinkBankAccountContent, LinkBankAccountResult } from './hooks/useGetLinkBankAccountContentData';
import { useAddFinancialAccount, useUpdateFinancialAccount } from './symphony';
import { AddFinancialAccount_addFinancialAccount } from './symphony/__generated__/mutation.v2';

import { BankAccountType } from '~/__generated__/symphonyTypes.v2';
import { Alert } from '~/components/ui/Alert';
import { Dropdown } from '~/components/ui/Dropdown';
import { Box, FormControl, Grid, Skeleton, ToggleButton, ToggleButtonGroup } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { BankAccount } from '~/utils/account';
import { isStringEmpty } from '~/utils/client';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { checkIfNotRepDigits, checkIfNumberIsNotConsecutiveAscOrDesc } from '~/utils/math';

enum InputNames {
  accountNumber = 'accountNumber',
  accountType = 'accountType',
  bankName = 'bankName',
  bankNameOption = 'bankNameOption',
  confirmAccountNumber = 'confirmAccountNumber',
  confirmRoutingNumber = 'confirmRoutingNumber',
  nameOnAccount = 'nameOnAccount',
  routingNumber = 'routingNumber',
}
const OTHER = 'Other';

type FormData = {
  accountNumber: string;
  accountType: string;
  bankName?: string;
  bankNameOption: typeof OTHER | string;
  confirmAccountNumber?: string;
  confirmRoutingNumber?: string;
  nameOnAccount?: string;
  routingNumber: string;
};

export interface Props {
  content?: LinkBankAccountContent | null;
  contentOptions: ContentOptions;
  data?: LinkBankAccountResult;
  dataQa?: string;
  isDocusignRequiredForFinancialAccountLinkageInRCE?: boolean;
  isSubmitted: boolean;
  managedProductId: string;
  onFailure?: () => void;
  onSuccess?: (data?: AddFinancialAccount_addFinancialAccount | null) => void;
  open?: boolean;
  partyId: string;
  selectedAccount?: BankAccount;
}

export const LinkBankAccountForm: React.FC<Props> = ({
  content,
  contentOptions,
  data,
  dataQa = 'link-account-form',
  isDocusignRequiredForFinancialAccountLinkageInRCE,
  isSubmitted,
  managedProductId,
  onSuccess,
  onFailure,
  open = false,
  partyId,
  selectedAccount,
}) => {
  const {
    components: {
      sfLinkBankAccount: {
        accountNumberLength,
        allowedBanksWithRoutingNumbers,
        bankAccountNameLength,
        showNameOnAccountField,
        showBankNameField,
        showConfirmAccountNumberField,
        showConfirmRoutingNumberField,
      },
    },
  } = useCoreConfig();

  const isValidRoutingNumber = (value: string) =>
    value.length === 9 &&
    !isNaN((value as unknown) as number) &&
    checkIfNotRepDigits(value) &&
    checkIfNumberIsNotConsecutiveAscOrDesc(value);

  const isValidNonEmptyNumber = (value: string) =>
    !(isNaN((value as unknown) as number) || isStringEmpty(value) || value.includes('.'));

  const allowedBanks = useMemo(() => {
    return Object.keys(allowedBanksWithRoutingNumbers).map(bankAccount => {
      return {
        label: bankAccount,
        value: bankAccount,
      };
    });
  }, [allowedBanksWithRoutingNumbers]);

  const schema = yup.object().shape({
    accountType: yup
      .string()
      .default(selectedAccount?.type ?? BankAccountType.CHEQUING)
      .required(),
    nameOnAccount: yup
      .string()
      .default(selectedAccount?.nameOnBankAccount ?? '')
      .when([], {
        is: () => showNameOnAccountField,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
    confirmAccountNumber: yup
      .string()
      .default('')
      .test((value, { parent: { accountNumber } }) => (showConfirmAccountNumberField ? value === accountNumber : true))
      .when([], {
        is: () => showConfirmAccountNumberField,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
    bankNameOption: yup
      .string()
      .default(selectedAccount?.financialInstitution ?? '')
      .when([], {
        is: () => allowedBanks.length && showBankNameField,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
    bankName: yup
      .string()
      .default(selectedAccount?.financialInstitution ?? '')
      .when('bankNameOption', {
        is: (value: string) => showBankNameField && (!allowedBanks.length || value === OTHER),
        then: yup.string().required(), // If bankNameOption has a value, bankName is not required except when bankNameOption is Other
        otherwise: yup.string().notRequired(),
      }),
    accountNumber: yup
      .string()
      .default(selectedAccount?.accountNumber ?? '')
      .test((value, _) => !!value && isValidNonEmptyNumber(value))
      .required(),
    routingNumber: yup
      .string()
      .default(selectedAccount?.routingNumber ?? '')
      .matches(/^[0-9]*$/)
      .test((value, _) => !!value && isValidRoutingNumber(value))
      .length(9)
      .required(),
    confirmRoutingNumber: yup
      .string()
      .default('')
      .test((value, { parent: { routingNumber } }) => (showConfirmRoutingNumberField ? value === routingNumber : true))
      .when([], {
        is: () => showConfirmRoutingNumberField,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
  });

  const defaultErrorMessage = content?.submitErrorMessage ?? 'MISSING SUBMIT ERROR MESSAGE';
  const openRef = useRef<boolean>(open);
  const { control, errors: formErrors, getValues, handleSubmit, register, setValue, watch } = useForm<FormData>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
  });
  const [addFinancialAccount] = useAddFinancialAccount();
  const [updateFInanacialAccount] = useUpdateFinancialAccount();
  const [errorSubmitting, setErrorSubmitting] = useState<Error | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>(defaultErrorMessage);

  const handleError = (error: any) => {
    const errMessage = content?.errorMessages?.find(
      e => e?.error_code === error?.graphQLErrors?.[0]?.errorCode?.toString(),
    );
    if (openRef.current) {
      setErrorSubmitting(error);
      setErrorMessage(errMessage?.message ? errMessage.message : defaultErrorMessage);
    }
  };

  const accountTypeValue = watch(InputNames.accountType);
  const selectedBankName = watch<string, string>(InputNames.bankNameOption);

  const isBankNameATextField = useMemo(() => !allowedBanks.length || selectedBankName === OTHER, [
    allowedBanks.length,
    selectedBankName,
  ]);

  const getFinancialInstitutionName = (formData: FormData): string => {
    return showBankNameField
      ? isBankNameATextField
        ? formData.bankName ?? ''
        : formData.bankNameOption
      : data?.financialInstitutionName
      ? data.financialInstitutionName
      : '';
  };

  const onValid = async (formData: FormData) => {
    try {
      if (selectedAccount?.id) {
        const result = await updateFInanacialAccount({
          variables: {
            bankAccount: {
              accountNumber: formData.accountNumber,
              financialInstitution: getFinancialInstitutionName(formData),
              routingNumber: formData.routingNumber,
              nameOnBankAccount: formData.nameOnAccount ?? '',
              type: formData.accountType as BankAccountType,
            },
            bankAccountId: selectedAccount.id,
            partyId,
            includeManagedProducts: !!isDocusignRequiredForFinancialAccountLinkageInRCE,
          },
          refetchQueries: ['GetBankAccountBalances'],
        });
        onSuccess?.(result.data?.updateFinancialAccount);
      } else {
        const result = await addFinancialAccount({
          variables: {
            bankAccount: {
              accountNumber: formData.accountNumber,
              financialInstitution: getFinancialInstitutionName(formData),
              routingNumber: formData.routingNumber,
              nameOnBankAccount: formData.nameOnAccount ?? '',
              type: formData.accountType as BankAccountType,
            },
            managedProductId,
            partyId,
            includeManagedProducts: !!isDocusignRequiredForFinancialAccountLinkageInRCE,
          },
          refetchQueries: ['GetBankAccountBalances'],
        });
        onSuccess?.(result.data?.addFinancialAccount);
      }
    } catch (error: any) {
      handleError(error);
      onFailure?.();
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(onValid, onFailure)();
    }
  }, [isSubmitted]);

  register(InputNames.accountType, { required: true });

  const allowedRoutingNumbers = useMemo(() => {
    if (!selectedBankName) {
      return [];
    }

    const routingNumbers = allowedBanksWithRoutingNumbers[selectedBankName];
    return (
      routingNumbers?.map(rn => {
        return {
          label: rn,
          value: rn,
        };
      }) ?? []
    );
  }, [allowedBanksWithRoutingNumbers, selectedBankName]);

  const handleAccountTypeChange = (_: any, newType: BankAccountType) => {
    if (newType !== null) {
      setValue(InputNames.accountType, newType, { shouldValidate: true, shouldDirty: true });
    }
  };

  return (
    <Box data-qa={dataQa}>
      <FormControl>
        {errorSubmitting && (
          <Alert contentOptions={contentOptions} error={errorSubmitting} severity="error" sx={{ mb: 3 }}>
            {errorMessage}
          </Alert>
        )}
        <ToggleButtonGroup
          data-qa={`${dataQa}-toggle-button-group`}
          exclusive
          fullWidth
          onChange={handleAccountTypeChange}
          sx={{ mb: 3 }}
          value={accountTypeValue}
        >
          <ToggleButton value={BankAccountType.CHEQUING}>
            {content?.accountType.checking ?? 'MISSING ACCOUNT TYPE CHECKING'}
          </ToggleButton>
          <ToggleButton value={BankAccountType.SAVINGS}>
            {content?.accountType.savings ?? 'MISSING ACCOUNT TYPE SAVINGS'}
          </ToggleButton>
        </ToggleButtonGroup>

        <Grid container spacing={2}>
          {allowedBanks.length > 0 && showBankNameField && (
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={InputNames.bankNameOption}
                render={({ onChange, value, name, ref }) => {
                  return (
                    <Dropdown
                      data-qa={`${dataQa}-dropdown`}
                      description={
                        formErrors.bankNameOption ? content?.validations.bankName ?? 'MISSING BANK NAME ERROR' : ''
                      }
                      error={!!formErrors.bankNameOption}
                      id={InputNames.bankNameOption}
                      inputRef={ref}
                      items={allowedBanks}
                      label={content?.bankName ?? 'MISSING BANK NAME LABEL'}
                      name={name}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value}
                      width="100%"
                    />
                  );
                }}
                rules={{ required: true }}
              />
            </Grid>
          )}
          {isBankNameATextField && showBankNameField && (
            <Grid item md={showNameOnAccountField ? 6 : 12} xs={12}>
              <Controller
                control={control}
                name={InputNames.bankName}
                render={({ onChange, onBlur, value, name, ref }) => {
                  return (
                    <TextField
                      InputLabelProps={{ sx: { mb: 0.5 } }}
                      error={!!formErrors.bankName}
                      fullWidth
                      helperText={!!formErrors.bankName && (content?.validations.bankName ?? 'MISSING BANK NAME ERROR')}
                      id={InputNames.bankName}
                      inputProps={{ maxLength: bankAccountNameLength }}
                      inputRef={ref}
                      label={content?.bankName ?? 'MISSING BANK NAME LABEL'}
                      name={name}
                      onBlur={onBlur}
                      onChange={e => (isStringEmpty(e.target.value) ? undefined : onChange(e))}
                      value={value}
                    />
                  );
                }}
                rules={{ required: true }}
              />
            </Grid>
          )}

          {showNameOnAccountField && (
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={InputNames.nameOnAccount}
                render={({ ref, onChange, onBlur, value, name }) => (
                  <TextField
                    InputLabelProps={{ sx: { mb: 0.5 } }}
                    error={!!formErrors.nameOnAccount}
                    fullWidth
                    helperText={
                      !!formErrors.nameOnAccount &&
                      (content?.validations.nameOnAccount ?? 'MISSING NAME ON ACCOUNT ERROR')
                    }
                    id={InputNames.nameOnAccount}
                    inputRef={ref}
                    label={content?.nameOnAccount ?? 'MISSING NAME ON ACCOUNT LABEL'}
                    name={name}
                    onBlur={onBlur}
                    onChange={e => (isStringEmpty(e.target.value) ? undefined : onChange(e))}
                    value={value}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <Controller
              control={control}
              name={InputNames.routingNumber}
              render={({ onChange, onBlur, ref, name, value }) => {
                if (isBankNameATextField) {
                  return (
                    <TextField
                      InputLabelProps={{ sx: { mb: 0.5 } }}
                      error={!!formErrors.routingNumber}
                      fullWidth
                      helperText={
                        (!!formErrors.routingNumber &&
                          (content?.validations.routingNumber ?? 'MISSING ROUTING NUMBER ERROR')) ||
                        (data?.financialInstitutionNameLoading ? (
                          <Skeleton />
                        ) : (
                          (data?.financialInstitutionName !== undefined &&
                            getValues().routingNumber !== '' &&
                            data.financialInstitutionName) ??
                          content?.bankNameNotFound
                        ))
                      }
                      id={InputNames.routingNumber}
                      inputProps={{ maxLength: 9 }}
                      inputRef={ref}
                      label={content?.routingNumber ?? 'MISSING ROUTING NUMBER LABEL'}
                      name={name}
                      onBlur={event => {
                        if (isValidRoutingNumber(event.target.value)) {
                          data?.getFinancialInstitutioNameByRoutingNumber(event.target.value);
                        }
                        onBlur();
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        const { value: inputValue } = e.target;
                        if (!isValidNonEmptyNumber(inputValue.trim())) {
                          e.preventDefault();
                          return;
                        }
                        data?.updateFinancialInstitutioName();
                        onChange(e.target.value.trim());
                      }}
                      value={value}
                    />
                  );
                }
                return (
                  <Dropdown
                    data-qa={`${dataQa}-dropdown`}
                    description={
                      formErrors.routingNumber
                        ? content?.validations.routingNumber ?? 'MISSING ROUTING NUMBER ERROR'
                        : ''
                    }
                    disabled={!allowedRoutingNumbers.length}
                    error={!!formErrors.routingNumber}
                    id={InputNames.routingNumber}
                    inputRef={ref}
                    items={allowedRoutingNumbers}
                    label={content?.routingNumber ?? 'MISSING ROUTING NUMBER LABEL'}
                    name={name}
                    onChange={e => onChange(e)}
                    value={value}
                    width="100%"
                  />
                );
              }}
              rules={{ pattern: /^[0-9]{9}$/, required: true }}
            />
          </Grid>
          {showConfirmRoutingNumberField && (
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={InputNames.confirmRoutingNumber}
                render={({ onChange, onBlur, ref, name, value }) => (
                  <TextField
                    InputLabelProps={{ sx: { mb: 0.5 } }}
                    error={!!formErrors.confirmRoutingNumber}
                    fullWidth
                    helperText={!!formErrors.confirmRoutingNumber && content?.validations.confirmRoutingNumberMismatch}
                    id={InputNames.confirmRoutingNumber}
                    inputProps={{ maxLength: 9 }}
                    inputRef={ref}
                    label={content?.confirmRoutingNumber}
                    name={name}
                    onBlur={onBlur}
                    onChange={e =>
                      isValidNonEmptyNumber(e.target.value.trim()) ? onChange(e.target.value.trim()) : undefined
                    }
                    onPaste={e => {
                      e.preventDefault();
                    }}
                    value={value}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <Controller
              control={control}
              name={InputNames.accountNumber}
              render={({ onChange, onBlur, ref, name, value }) => (
                <TextField
                  InputLabelProps={{ sx: { mb: 0.5 } }}
                  error={!!formErrors.accountNumber}
                  fullWidth
                  helperText={
                    !!formErrors.accountNumber && (content?.validations.accountNumber ?? 'MISSING ACCOUNT NUMBER ERROR')
                  }
                  id={InputNames.accountNumber}
                  inputProps={{ maxLength: accountNumberLength }}
                  inputRef={ref}
                  label={content?.accountNumber ?? 'MISSING ACCOUNT NUMBER LABEL'}
                  name={name}
                  onBlur={onBlur}
                  onChange={e =>
                    isValidNonEmptyNumber(e.target.value.trim()) ? onChange(e.target.value.trim()) : undefined
                  }
                  value={value}
                />
              )}
              rules={{ required: true }}
            />
          </Grid>
          {showConfirmAccountNumberField && (
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={InputNames.confirmAccountNumber}
                render={({ onChange, onBlur, ref, name, value }) => (
                  <TextField
                    InputLabelProps={{ sx: { mb: 0.5 } }}
                    error={!!formErrors.confirmAccountNumber}
                    fullWidth
                    helperText={!!formErrors.confirmAccountNumber && content?.validations.confirmAccountNumberMismatch}
                    id={InputNames.confirmAccountNumber}
                    inputProps={{ maxLength: 17 }}
                    inputRef={ref}
                    label={content?.confirmAccountNumber}
                    name={name}
                    onBlur={onBlur}
                    onChange={e =>
                      isValidNonEmptyNumber(e.target.value.trim()) ? onChange(e.target.value.trim()) : undefined
                    }
                    onPaste={e => {
                      e.preventDefault();
                    }}
                    value={value}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </FormControl>
    </Box>
  );
};
