import { identity } from 'fp-ts/lib/function';
import React from 'react';

import { ErrorDetails } from '~/components/ui/ErrorDetails';
import { MuiAlert, Skeleton } from '~/components/ui/mui';
import { useAutoFocus } from '~/hooks/accessibility/useAutoFocus';
import { useGetErrorMessagesData } from '~/hooks/error-messages';
import { ContentOptions } from '~/utils/contentstack';
import { getSxPropsArray } from '~/utils/theme';

export interface Props extends React.ComponentProps<typeof MuiAlert> {
  additionalDetails?: React.ReactNode;
  autoFocus?: boolean;
  contentOptions?: ContentOptions;
  error?: string | Error;
  header?: (genericHeader: string) => React.ReactNode;
}

/**
 * Backward compatible with Mui Alert component where its children will be rendered as expected.
 *
 * @param additionalDetails
 * @param autoFocus if ADA requires the Alert to receive focus and to have screen reader read it.
 * @param children
 * @param contentOptions Required for default generic error message without children, or if error object is passed in.
 * @param error error object if applicable for additional details
 * @param header
 * @param rest
 * @param sx
 * @constructor
 */
export const Alert: React.FC<Props> = ({
  additionalDetails,
  autoFocus = false,
  children,
  contentOptions,
  error,
  header = identity,
  sx,
  ...rest
}) => {
  const ref = useAutoFocus<HTMLDivElement>();
  const { data: errorMessagesData, loading: errorMessagesDataLoading } = useGetErrorMessagesData(contentOptions);
  const genericHeader = errorMessagesData?.alert_message ?? 'Something went wrong';

  if (errorMessagesDataLoading) {
    return <Skeleton sx={{ display: 'inline-block' }} width={400} />;
  }

  return (
    <MuiAlert
      ref={autoFocus ? ref : null}
      sx={[autoFocus && { '&:focus': { outline: 'none' } }, ...getSxPropsArray(sx)]}
      tabIndex={-1}
      {...rest}
    >
      {children ?? header(genericHeader)}
      {(error || additionalDetails) && (
        <ErrorDetails
          additionalDetails={additionalDetails}
          error={error}
          networkErrorMessage={errorMessagesData?.error_details_network_message}
        />
      )}
    </MuiAlert>
  );
};
