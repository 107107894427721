import {
  DeleteAssetTransfer,
  DeleteAssetTransferVariables,
  DeleteCashTransfer,
  DeleteCashTransferVariables,
  DeletePaperworkByType,
  DeletePaperworkByTypeVariables,
  DeleteTransfer,
  DeleteTransferVariables,
} from './__generated__/mutations.v2';
import {
  GetManagedProduct,
  GetManagedProduct_managedProduct_attributes,
  GetManagedProduct_managedProduct_fundingDetails_scheduledTransfers,
  GetManagedProduct_managedProduct_fundingDetails_scheduledTransfers_AnnuityTransfer,
  GetManagedProduct_managedProduct_fundingDetails_scheduledTransfers_AssetTransfer,
  GetManagedProduct_managedProduct_fundingDetails_scheduledTransfers_CashTransfer,
  GetManagedProduct_managedProduct_planUpdateWorkflows_selectedModelPortfolios,
  GetManagedProduct_managedProduct_questionnaires_collectedDataGroup_collectedData,
  GetManagedProductVariables,
  GetPortfolioTags,
  GetPortfolioTags_managedProduct_ManagedProduct_modelPortfolios_tags,
  GetPortfolioTags_managedProduct_ManagedProduct_modelPortfolios_tags_StringAttribute,
  GetPortfolioTagsVariables,
} from './__generated__/queries.v2';
import * as mutations from './mutations.gql';
import * as queries from './queries.gql';

import { BrokerageFinancialAccount } from '~/hooks/financial-account/symphony';
import {
  GetBankAccountBalances_client_financialAccounts,
  products_products as GetBrokerageAccountBalances_client_financialAccounts_products,
  products_products_ManagedProduct as GetBrokerageAccountBalances_client_financialAccounts_products_ManagedProduct,
  productsForBankAccounts_products as GetBankAccountBalances_client_financialAccounts_products,
  productsForBankAccounts_products_ManagedProduct as GetBankAccountBalances_client_financialAccounts_products_ManagedProduct,
} from '~/hooks/financial-account/symphony/__generated__/query.v2';
import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type QuestionnaireCollectedData = GetManagedProduct_managedProduct_questionnaires_collectedDataGroup_collectedData;
export type CashTransfer = GetManagedProduct_managedProduct_fundingDetails_scheduledTransfers_CashTransfer;
export type AssetTransfer = GetManagedProduct_managedProduct_fundingDetails_scheduledTransfers_AssetTransfer;
export type AnnuityTransfer = GetManagedProduct_managedProduct_fundingDetails_scheduledTransfers_AnnuityTransfer;
export type ScheduledTransfer = GetManagedProduct_managedProduct_fundingDetails_scheduledTransfers;
export type SelectedModelPortfolio = GetManagedProduct_managedProduct_planUpdateWorkflows_selectedModelPortfolios;
export type PortfolioTag = GetPortfolioTags_managedProduct_ManagedProduct_modelPortfolios_tags;
export type StringTag = GetPortfolioTags_managedProduct_ManagedProduct_modelPortfolios_tags_StringAttribute;
export type ManagedProductAttributes = GetManagedProduct_managedProduct_attributes;
type FinancialAccount = GetBankAccountBalances_client_financialAccounts;
type Product =
  | GetBankAccountBalances_client_financialAccounts_products
  | GetBrokerageAccountBalances_client_financialAccounts_products;
type ManagedProduct =
  | GetBankAccountBalances_client_financialAccounts_products_ManagedProduct
  | GetBrokerageAccountBalances_client_financialAccounts_products_ManagedProduct;

export const isCashTransfer = (transfer?: ScheduledTransfer): boolean => {
  return transfer?.__typename === 'CashTransfer';
};

export const isAnnuityTransfer = (transfer?: ScheduledTransfer): boolean => {
  return transfer?.__typename === 'AnnuityTransfer';
};

export const isStringTag = (tag?: PortfolioTag): tag is StringTag => tag?.__typename === 'StringAttribute';

export const useGetPortfolioTags = (
  options?: QueryHookOptions<GetPortfolioTags, GetPortfolioTagsVariables>,
): QueryResult<GetPortfolioTags, GetPortfolioTagsVariables> => {
  return useSymphonyQuery(queries.GetPortfolioTags, options);
};

export const useGetManagedProduct = (
  options?: QueryHookOptions<GetManagedProduct, GetManagedProductVariables>,
): QueryResult<GetManagedProduct, GetManagedProductVariables> => {
  return useSymphonyQuery(queries.GetManagedProduct, options);
};

export const isManagedProduct = (product: Product): product is ManagedProduct =>
  product.__typename === 'ManagedProduct';

export const hasManagedProduct = (financialAccount: FinancialAccount | BrokerageFinancialAccount): boolean =>
  !!financialAccount.products?.find(isManagedProduct);

export const useDeleteCashTransfer = (
  options?: MutationHookOptions<DeleteCashTransfer, DeleteCashTransferVariables>,
): MutationTuple<DeleteCashTransfer, DeleteCashTransferVariables> => {
  return useSymphonyMutation(mutations.DeleteCashTransfer, options);
};

export const useDeletePaperworkByType = (
  options?: MutationHookOptions<DeletePaperworkByType, DeletePaperworkByTypeVariables>,
): MutationTuple<DeletePaperworkByType, DeletePaperworkByTypeVariables> => {
  return useSymphonyMutation(mutations.DeletePaperworkByType, options);
};

export const useDeleteAssetTransfer = (
  options?: MutationHookOptions<DeleteAssetTransfer, DeleteAssetTransferVariables>,
): MutationTuple<DeleteAssetTransfer, DeleteAssetTransferVariables> => {
  return useSymphonyMutation(mutations.DeleteAssetTransfer, options);
};

export const useDeleteTransfer = (
  options?: MutationHookOptions<DeleteTransfer, DeleteTransferVariables>,
): MutationTuple<DeleteTransfer, DeleteTransferVariables> => useSymphonyMutation(mutations.DeleteTransfer, options);
