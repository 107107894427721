import { GetTradeConfirmationTabContent, GetTradeConfirmationTabContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type TradeConfirmationTabContent = GetTradeConfirmationTabContent;

export const useGetTradeConfirmationTabContent = (
  options?: QueryHookOptions<GetTradeConfirmationTabContent, GetTradeConfirmationTabContentVariables>,
): QueryResult<GetTradeConfirmationTabContent, GetTradeConfirmationTabContentVariables> => {
  return useContentstackQuery(queries.GetTradeConfirmationTabContent, options);
};
