import { ComponentProps, useMemo } from 'react';

import { ActionName, allActionNames } from '~/components/AccountActions';
import { AccountActionsMenu } from '~/components/AccountActions/Menu';
import { accountActionsTlhKeys } from '~/containers/AccountSummary/utils';
import { useGetAccountActionsContent } from '~/hooks/client/contentstack';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

type Data = ComponentProps<typeof AccountActionsMenu>['items'];

interface Variables {
  contentOptions: ContentOptions;
  enableViewPerformanceReport?: boolean;
  harvestLosses?: boolean;
}

/**
 * TODO: DWCORE-5955: accountActionsTlhKeys should be a part of allActionNames.
 * All the action keys from contentstack should be part of allActionNames without exception.
 */
const isAccountActionKey = (actionName: string): actionName is ActionName =>
  allActionNames.includes(actionName as ActionName) || accountActionsTlhKeys.includes(actionName);

export const useAccountActionsMenu = ({
  contentOptions,
  enableViewPerformanceReport,
  harvestLosses,
}: Variables): AsyncResult<Data> => {
  const { data: content, loading: contentLoading, error: contentError } = useGetAccountActionsContent({
    variables: contentOptions,
  });

  const data = useMemo(() => {
    if (contentLoading || contentError) {
      return undefined;
    }
    return content?.all_more_action_list?.items?.[0]?.actions
      ?.filter(item => {
        switch (item?.action?.key) {
          case 'tlhOn':
          case 'tlhOff':
            return item.action.key !== (harvestLosses ? 'tlhOn' : 'tlhOff');
          case 'quarterlyPerformance':
            // Hide the menu item if we are showing the 'View Quarterly Performance' button in Account Details
            if (!enableViewPerformanceReport) {
              return item;
            }
            return false;
          default:
            return true;
        }
      })
      .reduce<ComponentProps<typeof AccountActionsMenu>['items']>((acc, item) => {
        if (item?.action?.key && isAccountActionKey(item.action.key)) {
          acc.push({
            key: accountActionsTlhKeys.includes(item.action.key) ? 'toggleTlh' : item.action.key,
            label: item.action.label,
          });
        }
        return acc;
      }, []);
  }, [content?.all_more_action_list?.items, contentError, contentLoading, enableViewPerformanceReport, harvestLosses]);

  return { data, error: contentError, loading: contentLoading };
};
