import React, { ComponentProps, useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { useGetClientActionRequiredContent } from './contentstack';

import { Alert } from '~/components/ui/Alert';
import { Link } from '~/components/ui/Link';
import { Modal } from '~/components/ui/Modal';
import { Button, FormControl, Grid } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { isValidEmail } from '~/utils/validations';

enum InputNames {
  clientEmail = 'clientEmail',
  confirmClientEmail = 'confirmClientEmail',
}
enum ErrorCodes {
  confirmEmailMatch = 'confirm_email_match',
  invalidEmail = 'invalid_email',
  requiredEmailAddress = 'required_email_address',
}
type FormData = {
  clientEmail: string;
  confirmClientEmail: string;
};

export type Props = ComponentProps<typeof Modal> & {
  clientEmail: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  onClose: () => void;
  onConfirm: (email: string) => void;
};

export const ClientActionRequiredModal: React.FC<Props> = ({
  clientEmail,
  contentOptions,
  dataQa = 'client-action-required-modal',
  onClose,
  onConfirm,
  open,
}) => {
  const [editingEmail, setEditingEmail] = useState(false);
  const [editedEmail, setEditedEmail] = useState(clientEmail);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { handleSubmit, control, errors: fieldsErrors, watch } = useForm<FormData>();
  const formClientEmail = watch<string, string>(InputNames.clientEmail, editedEmail);
  const formClientConfirmEmail = watch<string, string>(InputNames.confirmClientEmail, '');

  const inputLabelStyles = {
    sx: {
      typography: 'body2',
      color: isFormSubmitted && editedEmail ? 'error.main' : 'text.secondary',
    },
  };

  const { data: contentData, loading: contentLoading, error: contentError } = useGetClientActionRequiredContent({
    variables: contentOptions,
    skip: !open,
  });

  useEffect(() => {
    // remove error when email and confirm email matched
    if (isFormSubmitted && formClientConfirmEmail === formClientEmail) {
      setIsFormSubmitted(false);
    }
  }, [formClientConfirmEmail, formClientEmail, isFormSubmitted]);

  const content = contentData?.all_plaid_funding?.items?.[0]?.client_action_required;

  const getCtaLabel = (actionKey: string): string => {
    return content?.ctas?.find(cta => cta?.key === actionKey)?.label ?? '';
  };

  const getErrorMessage = (fieldError?: FieldError) => {
    if (fieldError) {
      const errorCode = fieldError.message ?? '';
      return content?.errors?.find(e => e?.code === errorCode)?.message ?? '';
    }
    return '';
  };

  return (
    <Modal
      actions={
        contentLoading ? null : (
          <>
            <Button data-qa={`${dataQa}-cancel-button`} onClick={onClose} variant="outlined">
              {getCtaLabel('secondary')}
            </Button>
            <Button
              data-qa={`${dataQa}-send-button`}
              disabled={editingEmail}
              onClick={() => onConfirm(editedEmail)}
              variant="contained"
            >
              {getCtaLabel('primary')}
            </Button>
          </>
        )
      }
      content={
        <>
          <Grid container spacing={2} sx={{ px: 1, py: 3 }}>
            <Grid item sx={{ pb: 1 }} xs={12}>
              <img
                alt={`${dataQa}-image`}
                data-qa={`${dataQa}-image`}
                src={content?.imageConnection?.edges?.[0]?.node?.url ?? ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="span" variant="h5">
                {content?.heading}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="span" sx={{ color: '#000000' }} variant="body2">
                {content?.sub_heading}
              </Typography>
            </Grid>
            {isFormSubmitted && !editingEmail && (
              <Alert
                contentOptions={contentOptions}
                onClose={() => setIsFormSubmitted(false)}
                severity="success"
                sx={{ mt: 4, width: 1 }}
              >
                {content?.confirm_email_match_success}
              </Alert>
            )}
            <Grid
              container
              item
              sx={{ border: '1px solid', borderColor: 'other.divider', p: 3, ml: 2, borderRadius: '6px', mt: 3 }}
              xs={12}
            >
              {isFormSubmitted && editingEmail && (
                <Alert contentOptions={contentOptions} severity="error" sx={{ mb: 3 }}>
                  {content?.errors?.find(e => e?.code === ErrorCodes.confirmEmailMatch.toString())?.message}
                </Alert>
              )}
              <Grid item xs={6}>
                {!editingEmail ? (
                  <Typography component="span">{editedEmail}</Typography>
                ) : (
                  <>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        defaultValue={editedEmail}
                        name={InputNames.clientEmail}
                        render={({ onChange, value, name, ref }) => (
                          <TextField
                            InputLabelProps={inputLabelStyles}
                            dataQa={`${dataQa}-client-email`}
                            error={!!fieldsErrors.clientEmail || isFormSubmitted}
                            fullWidth
                            helperText={getErrorMessage(fieldsErrors.clientEmail)}
                            id={InputNames.clientEmail}
                            inputRef={ref}
                            label={content?.email_address}
                            name={name}
                            onChange={onChange}
                            size="small"
                            type="email"
                            value={value}
                          />
                        )}
                        /** The rules set here will get validated,
                           *  However only one error message will be displayed.
                           A solution to the case when validate rules need to be executed in priority is
                           to handle all validations inside a callback function passed to the validate object.
                           */
                        rules={{
                          required: ErrorCodes.requiredEmailAddress.toString(),
                          validate: {
                            isEmail: value => (isValidEmail(value) ? true : ErrorCodes.invalidEmail.toString()),
                          },
                        }}
                      />
                    </FormControl>
                  </>
                )}
              </Grid>
              <Grid alignSelf="center" item sx={{ pl: 1 }} textAlign={!editingEmail ? 'right' : 'left'} xs={6}>
                {!editingEmail ? (
                  <Link
                    component="button"
                    data-qa={`${dataQa}-change-email`}
                    onClick={() => {
                      setEditingEmail(true);
                      setIsFormSubmitted(false);
                    }}
                  >
                    {getCtaLabel('change_email')}
                  </Link>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          defaultValue=""
                          name={InputNames.confirmClientEmail}
                          render={({ onChange, value, name, ref }) => (
                            <TextField
                              InputLabelProps={inputLabelStyles}
                              autoComplete="never"
                              dataQa={`${dataQa}-client-confirm-email`}
                              error={!!fieldsErrors.confirmClientEmail || isFormSubmitted}
                              fullWidth
                              helperText={getErrorMessage(fieldsErrors.confirmClientEmail)}
                              id={InputNames.confirmClientEmail}
                              inputRef={ref}
                              label={content?.confirm_email_address}
                              name={name}
                              onChange={onChange}
                              onPaste={e => {
                                e.preventDefault();
                              }}
                              size="small"
                              type="email"
                              value={value}
                            />
                          )}
                          /** The rules set here will get validated,
                             *  However only one error message will be displayed.
                             A solution to the case when validate rules need to be executed in priority is
                             to handle all validations inside a callback function passed to the validate object.
                             */
                          rules={{
                            required: ErrorCodes.requiredEmailAddress.toString(),
                            validate: {
                              isEmail: value => (isValidEmail(value) ? true : ErrorCodes.invalidEmail.toString()),
                            },
                          }}
                        />
                      </FormControl>
                      <Grid container item justifyContent="flex-end" sx={{ mt: 3 }} xs={12}>
                        <Grid item sx={{ pr: 1 }}>
                          <Button
                            data-qa={`${dataQa}-cta-cancel-changes`}
                            onClick={() => {
                              setEditingEmail(false);
                              setIsFormSubmitted(false);
                            }}
                            size="small"
                            variant="outlined"
                          >
                            {getCtaLabel('cancel_changes')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            data-qa={`${dataQa}-cta-save-changes`}
                            onClick={() =>
                              handleSubmit(() => {
                                setEditedEmail(formClientEmail);
                                setEditingEmail(formClientEmail !== formClientConfirmEmail);
                                setIsFormSubmitted(true);
                              })()
                            }
                            size="small"
                            variant="contained"
                          >
                            {getCtaLabel('save_changes')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={contentError}
      loading={contentLoading}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      title={content?.modal_title}
    />
  );
};
