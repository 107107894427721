import React, { useMemo, useState } from 'react';

import { EntityUpdateWorkflow, LegalDocument } from '../../../../symphony';
import { PendingChangeCard, PendingChangeContent } from '../PendingChangeCard';

import { AssociatedEntityType, UpdateWorkflowStatus } from '~/__generated__';
import { DiscardPendingChange } from '~/components/modals/DiscardPendingChange';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Snackbar } from '~/components/ui/Snackbar';
import { useUpdateEntityUpdateWorkflow } from '~/containers/AccountProfile/symphony';
import { useResendEnvelopeToNextRecipient } from '~/hooks/docusign/symphony';
import {
  getLegalDocumentForPendingAccountProfileUpdate,
  getNextPendingSignee,
  isPendingEntityUpdateWorkflowStatus,
  nonPendingLegalDocumentStatuses,
} from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  content: PendingChangeContent;
  contentOptions: ContentOptions;
  dataQa?: string;
  entityUpdateWorkflow?: EntityUpdateWorkflow[];
  legalDocuments?: LegalDocument[];
  managedProductId: string;
  partyIdFA?: string;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  refetchAccounts?: () => Promise<any>;
  refetchingAccounts?: boolean;
  viewerPartyId: string;
}

export const PendingAccountProfileUpdate: React.FC<Props> = ({
  content,
  contentOptions,
  dataQa,
  managedProductId,
  partyIdFA,
  entityUpdateWorkflow,
  legalDocuments = [],
  redirectToSignDocuments,
  refetchAccounts,
  refetchingAccounts,
  viewerPartyId,
}) => {
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [resendEnvelopeToNextRecipient] = useResendEnvelopeToNextRecipient();
  const [updateEntityUpdateWorkflow] = useUpdateEntityUpdateWorkflow();

  const { open, onClose, openModal } = useModalState();

  const refetchItems = async () => {
    if (open) {
      onClose();
    }
    await refetchAccounts?.();
  };

  const resendDocusign = async (signingDocumentId: string) => {
    try {
      await resendEnvelopeToNextRecipient({
        variables: { signingDocumentId },
      });
      setShowSnackBar(true);
    } catch (err) {
      console.error(`Error Sending Docusign to Client`, err);
    }
  };

  const entityUpdateWorkflowId: string = useMemo(() => {
    return entityUpdateWorkflow?.find(entity => isPendingEntityUpdateWorkflowStatus(entity.status))?.id ?? '';
  }, [entityUpdateWorkflow]);

  const { allowDiscard, cta, ctaText, pendingEUWId, date } = useMemo(() => {
    const legalDocument = getLegalDocumentForPendingAccountProfileUpdate(legalDocuments, entityUpdateWorkflowId);
    const nextPendingSignee = getNextPendingSignee(legalDocument?.signees ?? []);

    if (
      entityUpdateWorkflow?.find(euw => euw.status === UpdateWorkflowStatus.DOCS_PREPARED) &&
      legalDocument &&
      !nonPendingLegalDocumentStatuses.includes(legalDocument.status)
    ) {
      if (nextPendingSignee?.partyId === viewerPartyId) {
        return {
          allowDiscard: true,
          cta: () => {
            if (redirectToSignDocuments) {
              redirectToSignDocuments(
                managedProductId,
                {
                  entityId: entityUpdateWorkflowId,
                  entityType: AssociatedEntityType.ENTITY_UPDATE_WORKFLOW,
                },
                true,
              );
            } else {
              console.error('Error: Redirect To Sign Docusign is missing');
            }
          },
          ctaText: content.cta.signDocuments,
          date: legalDocument.created,
          pendingEUWId: entityUpdateWorkflowId,
        };
      } else {
        return {
          allowDiscard: !!partyIdFA,
          cta: () => resendDocusign(legalDocument.signingDocumentId),
          ctaText: content.cta.resendDocusign,
          date: legalDocument.created,
          pendingEUWId: entityUpdateWorkflowId,
        };
      }
    }
    return {};
  }, [
    content.cta.resendDocusign,
    content.cta.signDocuments,
    entityUpdateWorkflow,
    entityUpdateWorkflowId,
    legalDocuments,
    managedProductId,
    partyIdFA,
    redirectToSignDocuments,
    resendDocusign,
    viewerPartyId,
  ]);

  const handleDiscard = async () => {
    await updateEntityUpdateWorkflow({
      variables: {
        id: entityUpdateWorkflowId,
        status: UpdateWorkflowStatus.CANCELLED,
      },
    });
  };

  return pendingEUWId ? (
    <PendingChangeCard
      allowDiscard={allowDiscard}
      content={content}
      cta={cta}
      ctaText={ctaText}
      dataQa={dataQa}
      date={date}
      discardCTA={openModal}
      refetchingAccounts={refetchingAccounts}
    >
      <DiscardPendingChange
        content={content.discardPendingChange}
        contentOptions={contentOptions}
        onClose={onClose}
        onDiscard={handleDiscard}
        onDiscardCallback={refetchItems}
        open={open}
      />
      {showSnackBar && (
        <Snackbar
          feedbackMessage={content.resendEmailSentMessage}
          onClose={() => {
            setShowSnackBar(false);
          }}
        />
      )}
    </PendingChangeCard>
  ) : null;
};
