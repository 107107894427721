import {
  GetConfirmOpenNewAccountModalContent,
  GetConfirmOpenNewAccountModalContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export const useGetConfirmOpenNewAccountModalContent = (
  options?: QueryHookOptions<GetConfirmOpenNewAccountModalContent, GetConfirmOpenNewAccountModalContentVariables>,
) => {
  return useContentstackQuery(queries.GetConfirmOpenNewAccountModalContent, options);
};

export const useLazyGetConfirmOpenNewAccountModalContent = (
  options?: QueryHookOptions<GetConfirmOpenNewAccountModalContent, GetConfirmOpenNewAccountModalContentVariables>,
) => {
  return useLazyContentstackQuery(queries.GetConfirmOpenNewAccountModalContent, options);
};
