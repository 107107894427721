import { format, parseISO } from 'date-fns';
import React from 'react';

import { DiscardPendingChangeContent } from '~/components/modals/DiscardPendingChange';
import { Button, Card, CloseIcon, Grid, IconButton, InfoIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';

export interface PendingChangeContent {
  cta: {
    approve: string;
    resendDocusign: string;
    signDocuments: string;
  };
  discardPendingChange: DiscardPendingChangeContent;
  genericMessage: string;
  header: string;
  message: string;
  resendEmailSentMessage: string;
}

interface Props {
  allowDiscard: boolean;
  content: PendingChangeContent;
  cta?: () => void;
  ctaText?: string;
  dataQa?: string;
  date?: string;
  discardCTA: () => void;
  refetchingAccounts?: boolean;
}

export const PendingChangeCard: React.FC<Props> = ({
  allowDiscard,
  children,
  content,
  cta,
  ctaText,
  dataQa,
  date,
  discardCTA,
  refetchingAccounts,
}) => {
  return (
    <Card data-qa={dataQa} sx={{ borderColor: 'info.main' }} variant="outlined">
      <Grid container sx={{ px: 2, py: 1 }}>
        <Grid item md={9} xs={8}>
          <Grid container direction="column">
            <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
              <InfoIcon
                data-qa={`${dataQa}-info`}
                sx={{ color: 'info.main', fontSize: 14, position: 'relative', top: 2 }}
              />
              <Typography sx={{ fontWeight: 'bold', pl: 1 }} variant="caption">
                {content.header}
              </Typography>
            </Grid>
            {date && (
              <Grid item>
                <RteContent
                  config={{ date: date ? format(parseISO(date), 'MMM dd, yyyy') : '' }}
                  data={date ? content.message : content.genericMessage}
                  sx={{ pl: 2.5 }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
          xs={4}
        >
          {cta && ctaText && (
            <Button disabled={refetchingAccounts} onClick={cta} sx={{ color: 'inherit' }}>
              {ctaText}
            </Button>
          )}
          {allowDiscard && (
            <IconButton
              aria-label="close"
              data-qa={`${dataQa}-close`}
              disabled={refetchingAccounts}
              onClick={discardCTA}
            >
              <CloseIcon fontSize="small" sx={{ pl: 1 }} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {children}
    </Card>
  );
};
