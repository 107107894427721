import { parseISO } from 'date-fns';
import React from 'react';

import { ActiveRestrictionsTabContent } from '../contentstack';
import { ActiveRestrictions } from '../symphony';

import { MultiRestrictionPlacedBy } from '~/__generated__';
import { TableData } from '~/components/ui/BasicTable';
import { Button, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getFullName } from '~/hooks/party/utils';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

enum ActiveRestrictionsTableColumns {
  Action = 'Action',
  Created = 'Created',
  CreatedBy = 'CreatedBy',
  Type = 'Type',
}

export interface RestrictionRowData {
  createdAt?: string;
  id: string;
  isPartnerOps?: boolean;
  placedBy?: string;
  placedByUser?: string;
  restrictionType: string;
}

export interface Content {
  addRestrictionButtonLabel: string;
  addRestrictionInfo: string;
  columnHeaderLabels: Record<ActiveRestrictionsTableColumns, string>;
  removeRestrictionButtonLabel: string;
  restrictionAdded: string;
  restrictionRemoved: string;
  textFields: Array<{ key: string | null; value: string | null } | null>;
}

enum RestrictionContentKeys {
  ADD_RESTRICTION_BUTTON = 'add_restriction_button',
  ADD_RESTRICTION_INFO = 'add_restriction_info',
  CREATED_AT_COLUMN = 'created_at_column',
  CREATED_BY_COLUMN = 'created_by_column',
  Others = 'others',
  REMOVE_RESTRICTION_BUTTON = 'remove_restriction_button',
  RESTRICTION_ADDED = 'restriction_added',
  RESTRICTION_REMOVED = 'restriction_removed',
  TYPE_COLUMN = 'type_column',
}

export const getActiveRestrictionsContent = (data: ActiveRestrictionsTabContent | null | undefined): Content => {
  const content = data?.items?.[0];
  const textFields = content?.fields?.text ?? [];

  return {
    addRestrictionButtonLabel: findFieldValue(textFields, RestrictionContentKeys.ADD_RESTRICTION_BUTTON),
    addRestrictionInfo: findFieldValue(textFields, RestrictionContentKeys.ADD_RESTRICTION_INFO),
    removeRestrictionButtonLabel: findFieldValue(textFields, RestrictionContentKeys.REMOVE_RESTRICTION_BUTTON),
    restrictionAdded: findFieldValue(textFields, RestrictionContentKeys.RESTRICTION_ADDED),
    restrictionRemoved: findFieldValue(textFields, RestrictionContentKeys.RESTRICTION_REMOVED),
    textFields,
    columnHeaderLabels: {
      [ActiveRestrictionsTableColumns.Created]: findFieldValue(textFields, RestrictionContentKeys.CREATED_AT_COLUMN),
      [ActiveRestrictionsTableColumns.CreatedBy]: findFieldValue(textFields, RestrictionContentKeys.CREATED_BY_COLUMN),
      [ActiveRestrictionsTableColumns.Type]: findFieldValue(textFields, RestrictionContentKeys.TYPE_COLUMN),
      [ActiveRestrictionsTableColumns.Action]: '',
    },
  };
};

export const getColumns = (content: Content) => [
  {
    key: ActiveRestrictionsTableColumns.Created,
    title: content.columnHeaderLabels[ActiveRestrictionsTableColumns.Created],
  },
  {
    key: ActiveRestrictionsTableColumns.CreatedBy,
    title: content.columnHeaderLabels[ActiveRestrictionsTableColumns.CreatedBy],
  },
  {
    key: ActiveRestrictionsTableColumns.Type,
    title: content.columnHeaderLabels[ActiveRestrictionsTableColumns.Type],
  },
  {
    key: ActiveRestrictionsTableColumns.Action,
    title: content.columnHeaderLabels[ActiveRestrictionsTableColumns.Action],
  },
];

export const getRestrictionsRowData = (
  restriction: ActiveRestrictions,
  content: Content,
  contentOptions: ContentOptions,
): RestrictionRowData => {
  const partyName = getFullName(restriction?.lastUpdatedByParty?.partyPerson);
  const validMultiRestrictionPlacedBy = [
    MultiRestrictionPlacedBy.CUSTODIAL,
    MultiRestrictionPlacedBy.SIGFIG_TRADER,
    MultiRestrictionPlacedBy.PARTNER_OPS,
  ];
  const placedBy = validMultiRestrictionPlacedBy.includes(restriction.placedBy)
    ? restriction.placedBy
    : RestrictionContentKeys.Others;

  return {
    placedBy: findFieldValue(content.textFields, placedBy.toLowerCase()),
    placedByUser: partyName,
    createdAt: formatDate(parseISO(restriction.createdAt), 'MM/dd/yyyy', {
      locale: contentOptions.locale,
    }),
    id: restriction.id,
    isPartnerOps: restriction.placedBy === MultiRestrictionPlacedBy.PARTNER_OPS,
    restrictionType: findFieldValue(content.textFields, restriction.multiRestrictionType.toLowerCase()),
  };
};

export const getIsOpsSuspensionAdded = (activeRestrictions: ActiveRestrictions[]): boolean =>
  activeRestrictions.some(restriction => restriction.placedBy === MultiRestrictionPlacedBy.PARTNER_OPS);

export const getFormattedRows = ({
  content,
  contentOptions,
  disableRemoveSuspension,
  isRemoveTradingSuspensionAllowed,
  onRemoveSuspensionActionClick,
  activeRestrictions,
}: {
  activeRestrictions: ActiveRestrictions[];
  content: Content;
  contentOptions: ContentOptions;
  disableRemoveSuspension: boolean;
  isRemoveTradingSuspensionAllowed: boolean;
  onRemoveSuspensionActionClick: ((suspension: ActiveRestrictions) => void) | undefined;
}): TableData[] | undefined =>
  activeRestrictions.map(data => {
    const restriction = getRestrictionsRowData(data, content, contentOptions);
    return {
      rowKey: restriction.id,
      [ActiveRestrictionsTableColumns.Created]: <Typography variant="body2">{restriction.createdAt}</Typography>,
      [ActiveRestrictionsTableColumns.CreatedBy]: (
        <Stack direction="column">
          <Typography>{restriction.placedBy}</Typography>
          <Typography variant="caption">{restriction.placedByUser}</Typography>
        </Stack>
      ),
      [ActiveRestrictionsTableColumns.Type]: <Typography variant="body2">{restriction.restrictionType}</Typography>,
      [ActiveRestrictionsTableColumns.Action]: restriction.isPartnerOps && (
        <Button
          data-qa={`remove-suspension-${restriction.id}`}
          disabled={!isRemoveTradingSuspensionAllowed || disableRemoveSuspension}
          onClick={() => onRemoveSuspensionActionClick?.(data)}
          size="small"
          variant="outlined"
        >
          {content.removeRestrictionButtonLabel}
        </Button>
      ),
    };
  });
