import { GetDocusignRequiredModalContent } from './contentstack/__generated__/query.v2';
import { Content } from './hooks';

import { findFieldValue } from '~/utils/contentstack';

export const getModalContent = (content: GetDocusignRequiredModalContent | undefined): Content => {
  const contentData = content?.all_docusign_required_modal?.items?.[0]?.fields;
  return {
    description: findFieldValue(contentData?.rte ?? [], 'modalDescription'),
    primaryCta: findFieldValue(contentData?.text ?? [], 'primaryCta'),
    resendDocusign: findFieldValue(contentData?.text ?? [], 'resendDocusign'),
    secondaryCta: findFieldValue(contentData?.text ?? [], 'secondaryCta'),
    title: findFieldValue(contentData?.text ?? [], 'modalTitle'),
  };
};
