import { getDaysInMonth } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { range } from 'fp-ts/lib/Array';
import { identity, pipe } from 'fp-ts/lib/function';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyGetPerformanceDetailsContent } from './contentstack';
import { useGetAdditionalDates } from './hooks/useGetAdditionalDates';
import { getPerformanceAccessibilityValue } from './utils';

import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownChangeEvent } from '~/components/ui/Dropdown/types';
import { Modal } from '~/components/ui/Modal';
import { Button, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions, Locale } from '~/utils/contentstack/src/types';
import { enumLocaleToLocaleMap } from '~/utils/format';
import { formatDate } from '~/utils/format/date';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { DailyTableValue } from '~/utils/types';

export enum PerformanceDetailsType {
  AccountBalance = 'AccountBalance',
  Returns = 'Returns',
}

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  onClose?: () => void;
  open?: boolean;
  performanceData: DailyTableValue[];
  type: PerformanceDetailsType;
}

const getMonths = (locale: Locale = Locale.en_us): Array<{ label: any; value: string }> => {
  const dateLocale = enumLocaleToLocaleMap(locale);
  return range(0, 11).map(monthIndex => {
    return {
      label: pipe(monthIndex, dateLocale.localize?.month ?? identity),
      value: (monthIndex + 1).toString(),
    };
  });
};

const getLatestYear = (data: Array<{ year: number }>) => Math.max(...data.map(d => d.year));
const getLatestMonth = (data: Array<{ month: number }>) => Math.max(...data.map(d => d.month));
const getEarliestMonth = (data: Array<{ month: number }>) => Math.min(...data.map(d => d.month));

export const PerformanceDetails: React.FC<Props> = ({
  contentOptions,
  dataQa = 'performance-detail-table-modal',
  onClose,
  open = false,
  performanceData,
  type,
}) => {
  const [
    getContent,
    { data: contentData, loading: contentLoading, error: contentError },
  ] = useLazyGetPerformanceDetailsContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (open) {
      getContent();
    }
  }, [getContent, open]);

  useEffect(() => {
    if (contentError) {
      throw new Error(contentError.message);
    }
  }, [contentError]);

  const content = contentData?.all_performance_details_modal?.items?.[0];

  const data = performanceData.map(d => {
    const dateObj = parseISO(d.date);
    return {
      dataPoints: d.dataPoints,
      date: dateObj.getDate(),
      month: dateObj.getMonth() + 1,
      year: dateObj.getFullYear(),
    };
  });

  const [selectedYear, setSelectedYear] = useState(getLatestYear(data));
  const [selectedMonth, setSelectedMonth] = useState(getLatestMonth(data.filter(d => d.year === selectedYear)));

  const { getNextDates, getPreviousDates } = useGetAdditionalDates(selectedMonth, selectedYear, data);

  const [nextMonthDataAvailable, nextMonthsYear, nextMonth] = getNextDates();
  const [previousMonthDataAvailable, previousMonthsYear, previousMonth] = getPreviousDates();

  const onPrevMonthClick = () => {
    if (previousMonthDataAvailable) {
      setSelectedYear(previousMonthsYear);
      setSelectedMonth(previousMonth);
    }
  };

  const onNextMonthClick = () => {
    if (nextMonthDataAvailable) {
      setSelectedYear(nextMonthsYear);
      setSelectedMonth(nextMonth);
    }
  };

  const hasDataForDate = useCallback(
    (updatedYear: number, updatedMonth: number): boolean => {
      return data.filter(d => d.year === updatedYear && d.month === updatedMonth).length !== 0;
    },
    [data],
  );

  const header = useMemo(() => {
    switch (type) {
      case PerformanceDetailsType.AccountBalance:
        return content?.heading;
      case PerformanceDetailsType.Returns:
        return content?.returns_heading;
      default:
        return null;
    }
  }, [content, type]);

  const sortedDates = useMemo(
    () => data.sort((a, b) => (new Date(a.year, a.month) > new Date(b.year, b.month) ? 1 : -1)),
    [data],
  );

  const isBeforeActivationDate = (updatedYear: number, updatedMonth: number): boolean => {
    const activationDate = sortedDates[0];

    return new Date(activationDate.year, activationDate.month) >= new Date(updatedYear, updatedMonth);
  };

  const onYearChange = (event: DropdownChangeEvent) => {
    const newYear = parseInt(event.target.value as string, 10);
    setSelectedYear(newYear);
    if (!hasDataForDate(newYear, selectedMonth)) {
      isBeforeActivationDate(newYear, selectedMonth)
        ? setSelectedMonth(getEarliestMonth(data.filter(d => d.year === newYear)))
        : setSelectedMonth(getLatestMonth(data.filter(d => d.year === newYear)));
    }
  };

  const onMonthChange = (event: DropdownChangeEvent) => {
    setSelectedMonth(parseInt(event.target.value as string, 10));
  };

  useEffect(() => {
    if (!hasDataForDate(selectedYear, selectedMonth)) {
      const latestYear = getLatestYear(data);
      setSelectedYear(latestYear);
      setSelectedMonth(getLatestMonth(data.filter(d => d.year === latestYear)));
    }
  }, [data, hasDataForDate, selectedMonth, selectedYear]);

  const years = useMemo(
    () =>
      pipe(
        performanceData.map(({ date }) => formatDate(parseISO(date), 'yyyy', { locale: contentOptions.locale })),
        allYears => new Set(allYears),
        yearsSet => Array.from(yearsSet),
        uniqueYears => uniqueYears.map(y => ({ label: y, value: y })),
      ),
    [contentOptions.locale, performanceData],
  );
  const months = getMonths(contentOptions.locale).filter(m =>
    data.find(d => d.year === selectedYear && d.month.toString() === m.value),
  );
  const monthData = data.filter(d => d.year === selectedYear && d.month === selectedMonth);
  const isMobileScreen = useIsMediumScreen();
  const itemsPerRow = isMobileScreen ? 3 : 7;
  const rowsArray = Array.from(Array(Math.ceil(getDaysInMonth(selectedMonth) / itemsPerRow)).keys());
  const rowItemsArray = Array.from(Array(itemsPerRow).keys());

  return (
    <Modal
      actions={
        contentLoading ? (
          <Skeleton data-qa={`${dataQa}-ctas-loading`} width="20%" />
        ) : (
          <>
            <Button id="close-btn" onClick={onClose}>
              {content?.ctas?.cancel || 'MISSING CANCEL CTA'}
            </Button>
            <Button disabled={!previousMonthDataAvailable} onClick={onPrevMonthClick} variant="contained">
              {content?.ctas?.previous_month || 'MISSING PREVIOUS MONTH CTA'}
            </Button>
            <Button disabled={!nextMonthDataAvailable} onClick={onNextMonthClick} variant="contained">
              {content?.ctas?.next_month || 'MISSING NEXT MONTH CTA'}
            </Button>
          </>
        )
      }
      content={
        <>
          <div>
            {contentLoading ? (
              <Skeleton data-qa={`${dataQa}-select-labels-loading`} width="20%" />
            ) : (
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item md={4} xs>
                  <Dropdown
                    items={years}
                    label={content?.labels?.select_year || 'MISSING SELECT YEAR LABEL'}
                    onChange={onYearChange}
                    value={selectedYear}
                    variant="outlined"
                    width="100%"
                  />
                </Grid>
                <Grid item md={4} xs>
                  <Dropdown
                    items={months}
                    label={content?.labels?.select_month || 'MISSING SELECT MONTH LABEL'}
                    onChange={onMonthChange}
                    value={selectedMonth}
                    variant="outlined"
                    width="100%"
                  />
                </Grid>
              </Grid>
            )}
          </div>

          <Typography aria-live="polite" sx={{ pt: 3 }} variant="h6">
            {months.find(m => m.value === selectedMonth.toString())?.label}
          </Typography>
          <TableContainer
            sx={{
              '& .MuiTableCell-root': {
                padding: 1,
              },
            }}
          >
            <Table sx={{ borderTop: ({ palette }) => `1px solid ${palette.divider}`, my: 2, tableLayout: 'fixed' }}>
              <TableBody>
                {rowsArray.map((row, rIndex) => {
                  return (
                    <React.Fragment key={row}>
                      <TableRow key={`${rIndex}-date`}>
                        {rowItemsArray.map(rowItem => {
                          if (monthData.find(d => d.date >= row * itemsPerRow + rowItem + 1)) {
                            return (
                              <TableCell key={rowItem} sx={{ color: 'text.secondary' }}>
                                {row * itemsPerRow + rowItem + 1}
                              </TableCell>
                            );
                          }
                          return null;
                        })}
                      </TableRow>
                      <TableRow key={`${rIndex}-value`}>
                        {rowItemsArray.map(rowItem => {
                          if (monthData.find(d => d.date >= row * itemsPerRow + rowItem + 1)) {
                            const availableDataForDate = monthData.find(
                              d => d.date === row * itemsPerRow + rowItem + 1,
                            );
                            return (
                              <TableCell key={rowItem}>
                                {availableDataForDate ? (
                                  availableDataForDate.dataPoints.map((value, index, array) => {
                                    return (
                                      <Typography
                                        key={`${rowItem}-${index}`}
                                        sx={{
                                          ...(index === 0 && { paddingTop: '1' }),
                                          ...(index === array.length - 1 && { paddingBottom: '1.5' }),
                                        }}
                                        variant="body2"
                                      >
                                        {value}
                                      </Typography>
                                    );
                                  })
                                ) : (
                                  <Typography
                                    aria-label={getPerformanceAccessibilityValue(
                                      'not-available',
                                      content?.accessibility,
                                    )}
                                    pb={1.5}
                                    pt={1}
                                    variant="body2"
                                  >
                                    {'-'}
                                  </Typography>
                                )}
                              </TableCell>
                            );
                          }
                          return null;
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
      data-qa={dataQa}
      maxWidth="md"
      onClose={onClose}
      open={open}
      title={header}
    />
  );
};
