import {
  GetCompositeModelPortfolioAllocationContent,
  GetCompositeModelPortfolioAllocationContent_all_account_details_items_sections_AccountDetailsSectionsCompositeModelPortfolioAllocation,
  GetCompositeModelPortfolioAllocationContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type CompositeModelPortfolioAllocationSection = GetCompositeModelPortfolioAllocationContent_all_account_details_items_sections_AccountDetailsSectionsCompositeModelPortfolioAllocation;

export const useGetCompositeModelPortfolioAllocationContent = (
  options?: QueryHookOptions<
    GetCompositeModelPortfolioAllocationContent,
    GetCompositeModelPortfolioAllocationContentVariables
  >,
): QueryResult<GetCompositeModelPortfolioAllocationContent, GetCompositeModelPortfolioAllocationContentVariables> => {
  return useContentstackQuery(queries.GetCompositeModelPortfolioAllocationContent, options);
};
