import React, { useMemo } from 'react';

import { Divider } from '~/components/ui/Divider';
import { Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

interface Advisor {
  email: string | null;
  familyName: string | null;
  givenName: string | null;
  middleName: string | null;
  phoneNumber: string | null;
}

const getFullName = (data: Advisor): string => {
  const first = data.givenName ? `${data.givenName} ` : '';
  const middle = data.middleName ? `${data.middleName} ` : '';
  const last = data.familyName ?? '';
  return first + middle + last;
};

export interface Props {
  advisor: Advisor;
  advisorLabel?: string;
  dataQa?: string;
}

export const AdvisorInfo: React.FC<Props> = ({ advisor, dataQa = 'advisor-info', advisorLabel }) => {
  const name = useMemo(() => getFullName(advisor), [advisor]);
  return (
    <Grid container data-qa={dataQa} justifyContent="center">
      <Grid item xs={12}>
        <Grid item>
          <Typography color="text.secondary" variant="caption">
            {advisorLabel}
          </Typography>
          <Typography sx={{ color: 'text.primary', mt: 1 }} variant="body1">
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item spacing={5} xs={12}>
        {advisor.email && (
          <Grid item>
            <Typography variant="body1">{advisor.email}</Typography>
          </Grid>
        )}
        {advisor.phoneNumber && (
          <Grid item sx={{ display: 'flex' }}>
            <Divider orientation="vertical" sx={{ mr: 3 }} />
            <Typography variant="body1">{advisor.phoneNumber}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
