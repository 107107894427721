import {
  AccountAction,
  AccountActionAugment,
  AccountActionAugments,
  ActionName,
  CustomAction,
  CustomActionArgs,
  CustomActionAugments,
  ModalAction,
  ModalActionAugments,
  StandardAction,
  StandardActionAugments,
} from '.';

export const isStandardAction = (action?: AccountAction): action is StandardAction => action?.type === 'standard';
export const isCustomAction = (action?: AccountAction | AccountActionAugment): action is CustomAction =>
  action?.type === 'custom';
export const isModalAction = (action?: AccountAction): action is ModalAction => action?.type === 'modal';
export const isAugment = (action?: AccountAction | AccountActionAugment): action is AccountActionAugment =>
  action?.type === 'augment';
export const isStandardAugments = (actionAugments: AccountActionAugments): actionAugments is StandardActionAugments =>
  actionAugments.type === 'standard';
export const isCustomAugments = (actionAugments: AccountActionAugments): actionAugments is CustomActionAugments =>
  actionAugments.type === 'custom';
export const isModalAugments = (actionAugments: AccountActionAugments): actionAugments is ModalActionAugments =>
  actionAugments.type === 'modal';

export const getAugmentedOrOverriddenAction = (
  action: AccountAction,
  augmentsOrOverride?: AccountAction | AccountActionAugment,
): AccountAction => {
  if (isAugment(augmentsOrOverride)) {
    if (isStandardAction(action) && isStandardAugments(augmentsOrOverride.augments)) {
      return { ...action, ...augmentsOrOverride.augments };
    } else if (isCustomAction(action) && isCustomAugments(augmentsOrOverride.augments)) {
      return { ...action, ...augmentsOrOverride.augments };
    } else if (isModalAction(action) && isModalAugments(augmentsOrOverride.augments)) {
      return { ...action, ...augmentsOrOverride.augments };
    } else {
      // ignore the augments if the type doesn't match
      return action;
    }
  } else {
    return augmentsOrOverride ?? action;
  }
};

export const performAccountAction = ({
  actionName,
  accountAction,
  customActionArgs,
  modalLaunchers,
  quarterlyPerformanceAction,
}: {
  accountAction?: AccountAction;
  actionName: ActionName;
  customActionArgs: CustomActionArgs;
  modalLaunchers: {
    [key in ActionName]?: () => void;
  };
  quarterlyPerformanceAction?: {
    quarterlyPerformanceViewOption: string;
    setPerformanceViewOption: (option: string) => void;
  };
}): void => {
  if (isCustomAction(accountAction)) {
    accountAction.callback(customActionArgs);
  } else if (isModalAction(accountAction)) {
    accountAction.callback({
      ...customActionArgs,
      openModal: modalLaunchers[actionName] ?? (() => console.warn(`No modal launcher for action name ${actionName}`)),
    });
  } else if (isStandardAction(accountAction)) {
    if (actionName === 'quarterlyPerformance') {
      quarterlyPerformanceAction?.setPerformanceViewOption(quarterlyPerformanceAction.quarterlyPerformanceViewOption);
      return;
    }
    const callback =
      modalLaunchers[actionName] ?? (() => console.warn(`No modal launcher for action name ${actionName}`));
    callback();
  } else {
    throw new Error(`Could not find config for action name ${actionName}`);
  }
};
