import { GetDeleteBankAccountModalContent, GetDeleteBankAccountModalContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetDeleteBankAccountModalContent = (
  options?: QueryHookOptions<GetDeleteBankAccountModalContent, GetDeleteBankAccountModalContentVariables>,
): QueryResult<GetDeleteBankAccountModalContent, GetDeleteBankAccountModalContentVariables> => {
  return useContentstackQuery(queries.GetDeleteBankAccountModalContent, options);
};
