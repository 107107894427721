import {
  differenceInDays,
  differenceInYears,
  isAfter,
  isValid,
  Locale as dateFnsLocale,
  parse,
  parseISO,
  toDate,
} from 'date-fns';
import dateFormat from 'date-fns/format';
import { enCA, enUS, frCA } from 'date-fns/locale';

import { Locale as ContentLocale } from '~/utils/contentstack';

type ValidDateInput = Date | string | number;

const isCanadian = (options?: { locale?: string }) => {
  return options && (options.locale === ContentLocale.en_ca || options.locale === ContentLocale.fr_ca);
};

export const enumLocaleToLocaleMap = (locale?: ContentLocale): dateFnsLocale => {
  switch (locale) {
    case 'en-ca':
      return enCA;
    case 'fr-ca':
      return frCA;
    case 'en-us':
    default:
      return enUS;
  }
};

/**
 * General wrapper for date-fns dateFormat
 * @param date - string | number | string representation for a date
 * @param format - string as date format
 * @param optionsIn - object, only property is locale which is a string
 * @returns - date formatted as string
 */
export const formatDate = (
  date: ValidDateInput,
  format = 'MMM d, yyyy',
  optionsIn?: {
    locale?: string;
  },
): string => {
  const options = optionsIn?.locale
    ? {
        ...optionsIn,
        locale: enumLocaleToLocaleMap(optionsIn.locale as ContentLocale),
      }
    : undefined;

  try {
    return dateFormat(typeof date === 'string' ? parseISO(date) : date, format, options);
  } catch (e) {
    // date-fns dateFormat throws RangeError exception for invalid dates.
    console.error(`Error formatDate (${date}):`, e);
    return '';
  }
};

/**
 *
 * @param date - string | number | date format representation of date
 * @param numberOfMonths - number format mentioning the number of months to add to a particulate date
 * @param format - string as date format
 * @param optionsIn - object, only property is locale which is a string
 * @returns - date formatted as string
 */
export const formatDateWithAddedMonths = (
  date: ValidDateInput,
  numberOfMonths: number,
  format = 'MMM d, yyyy',
  optionsIn?: {
    locale?: string;
  },
) => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : toDate(date);
  parsedDate.setMonth(parsedDate.getMonth() + numberOfMonths);
  return formatDate(parsedDate, format, optionsIn);
};

/**
 *
 * @param date - Date to format
 * @param options - Options, currently only locale
 * @returns - yyyy-MM-dd if Canada, else MM/dd/yyyy
 */
export const allNumericDateFormat = (
  date: ValidDateInput,
  options?: {
    locale?: string;
  },
): string => {
  if (isCanadian(options)) {
    return isoDateFormat(date);
  }
  return formatDate(date, 'MM/dd/yyyy', options);
};

/**
 * Full date in M D Y format with locale support
 * @param date - incoming date
 * @param options - {locale: string}
 * @returns - 'MMMM d, yyyy' if en, 'd MMMM yyyy' if fr
 */
export const fullDateFormat = (
  date: ValidDateInput,
  options?: {
    locale?: string;
  },
): string => {
  let format = 'MMMM d, yyyy';
  if (options && options.locale === ContentLocale.fr_ca) {
    format = 'd MMMM yyyy';
  }
  return formatDate(date, format, options);
};

/**
 * Returns fullDateFormat style but abbreviated months with a comma separating day and year
 * @param date - date | number | string
 * @param options - object with props: locale
 * @returns
 */
export const abbreviatedDateFormat = (
  date: ValidDateInput,
  options?: {
    locale?: string;
  },
  dateFormatType?: string,
): string => {
  let format = dateFormatType || 'MMM d, y';
  if (options?.locale === ContentLocale.fr_ca) {
    format = 'd MMM y';
  }
  return formatDate(date, format, options);
};

/**
 * Returns hours and mins formatted from a date
 * @param date - date | string | number
 * @param options - object with property locale
 * @returns - date formatted as string
 */
export const hoursMinsDateFormat = (
  date: ValidDateInput,
  options?: {
    locale?: string;
  },
): string => {
  const format = options?.locale === ContentLocale.fr_ca ? "HH 'h' mm" : 'HH:mm';
  return formatDate(date, format, options);
};

/**
 *
 * @param date - Incoming date
 * @param options - {locale: string}
 * @returns - yyyy-MM-dd ISO format date
 */
export const isoDateFormat = (
  date: ValidDateInput,
  options?: {
    locale?: string;
  },
): string => formatDate(date, 'yyyy-MM-dd', options);

/**
 *
 * @param dob - Incoming string of the provisioned user's date of birth.
 * @returns - Years between the two dates provided.
 * @todo - Handle different incoming date strings.
 */
export const calculateAge = (dob: string): number => {
  return differenceInYears(new Date(), parse(dob, 'yyyy-MM-dd', new Date()));
};

export const isValidDate = (date: Date | number): boolean => {
  return isValid(toDate(date)) && isAfter(toDate(date), parseISO('1899-12-31')); // By default Calendar only allows date after 1900
};

/**
 *
 * @param date - Incoming date string
 * @returns - String with the number of days relative to today
 * @todo - get display text from contentstack (DA2-4035)
 */
export const formatDateRelativeToToday = (date: string): string => {
  const diffInDays = differenceInDays(parseISO(new Date().toISOString()), parseISO(date));
  if (diffInDays === 0) {
    return 'Today';
  }
  return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
};
