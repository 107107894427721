import { ComponentProps, useCallback } from 'react';

import { AccountAction, CustomActionArgs } from '~/components/AccountActions';
import { AddFundsData } from '~/components/AccountActions/hooks';
import { isCustomAction, isModalAction, isStandardAction } from '~/components/AccountActions/utils';
import { AddFunds } from '~/components/AddFunds';
import { ModalStateData } from '~/components/ui/Modal/hooks';
import { DetailedFinancialAccount } from '~/hooks/account-details/symphony';

interface Variables {
  account?: DetailedFinancialAccount;
  addFundsAction?: AccountAction;
  addFundsActionModal: AddFundsData;
  managedProductId: string;
  partyId: string;
}

interface Data {
  addFundsModal: ModalStateData;
  onAddFundsClick: ComponentProps<typeof AddFunds>['onAddFunds'];
}

export const useAddFunds = ({
  account,
  addFundsAction,
  addFundsActionModal,
  managedProductId,
  partyId,
}: Variables): Data => {
  const {
    depositAmount: [_, setDepositAmount],
    sourceAccountId: [__, setSourceId],
    modal: addFundsModal,
  } = addFundsActionModal;

  const addFundsCallback = useCallback(() => {
    const accountNumber = account?.accountNumber ?? undefined;
    const customActionArgs: CustomActionArgs = {
      accountNumber,
      managedProductId,
      partyId,
    };
    if (isStandardAction(addFundsAction)) {
      addFundsModal.openModal();
    } else if (isCustomAction(addFundsAction)) {
      addFundsAction.callback(customActionArgs);
    } else if (isModalAction(addFundsAction)) {
      addFundsAction.callback({
        ...customActionArgs,
        openModal: addFundsModal.openModal,
      });
    }
  }, [account?.accountNumber, addFundsAction, addFundsModal, managedProductId, partyId]);

  const onAddFundsClick = useCallback(
    ({ value, account: sourceAccount }) => {
      if (sourceAccount && sourceAccount.id) {
        setSourceId(sourceAccount.id);
      }
      setDepositAmount(value);
      addFundsCallback();
    },
    [setSourceId, setDepositAmount, addFundsCallback],
  );

  return {
    addFundsModal,
    onAddFundsClick,
  };
};
