import * as CSS from 'csstype';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DiversificationBlock } from './DiversificationBlock';
import { IndicatorComponent } from './IndicatorComponent';
import { PortfolioBands } from './PortfolioBands';
import { getIndicator } from './utils';

import { Alert } from '~/components/ui/Alert';
import { Box, Grid, Skeleton, Slider, SliderThumb, useTheme } from '~/components/ui/mui';
import { SliderSelector } from '~/components/ui/SliderSelector';
import { Typography } from '~/components/ui/Typography';
import { DiversificationBlockContent } from '~/hooks/questionnaire/contentstack';
import { ContentOptions } from '~/utils/contentstack';

export interface Indicator {
  isPrimary?: boolean;
  label?: string; // CompactVariant may not use label
}

export interface IndicatorLabels {
  current: string; // previously accepted risk band
  new: string; // new risk band after onboarding or retake
  noChange: string; // no change risk band after retake
  user: string; // user selected risk band
}

export interface RiskBand {
  description: string;
  label: string;
  labelSummary?: string | null;
  riskScoreCeiling: number;
  riskScoreFloor: number;
  targetAllocations?: string;
}

export interface PortfolioBand {
  bondPercentage: string;
  description: string;
  label: string;
  labelSummary?: string | null;
  riskBandIndex: number;
  riskScoreCeiling: number;
  riskScoreFloor: number;
  stockPercentage: string;
  targetAllocations?: string;
}

export interface AllowedRiskToleranceChange {
  down: number;
  // if -1 then all up or down values are allowed
  // if any other number, that many steps are allowed
  up: number;
}

export enum CompactVariant {
  Arrow = 'ArrowMobile',
  Block = 'Block',
  Mobile = 'Mobile',
}

export interface Props {
  allowedRiskToleranceChange?: AllowedRiskToleranceChange;
  compact?: CompactVariant;
  contentOptions: ContentOptions;
  dataQa?: string;
  diversificationBlockContent?: DiversificationBlockContent | null;
  editMode?: boolean;
  fromEditAccountModal?: boolean;
  indicatorLabels?: IndicatorLabels;
  onChange?: (newBand: number) => void;
  portfolioBands?: PortfolioBand[];
  riskBandCurrent?: number;
  riskBandHeight?: number;
  riskBandHeightPrimary?: number;
  riskBandNew: number;
  riskBandUser?: number;
  riskBands: RiskBand[];
  showPortfolioBands?: boolean;
}

export const RiskSpectrum: React.FC<Props> = ({
  allowedRiskToleranceChange = {
    up: 0,
    down: -1,
  },
  compact,
  contentOptions,
  dataQa = 'risk-spectrum',
  diversificationBlockContent,
  editMode,
  indicatorLabels,
  onChange,
  portfolioBands,
  riskBands,
  riskBandCurrent,
  riskBandNew,
  riskBandHeight = 4,
  riskBandHeightPrimary = 18,
  riskBandUser,
  showPortfolioBands,
  fromEditAccountModal,
}) => {
  const isMobileVariant = compact === CompactVariant.Mobile;
  const riskSpectrumRef = useRef<HTMLElement>();
  const [sliderBand, setSliderBand] = useState(-1);
  const [sidePadding, setSidePadding] = useState(0);
  const {
    sfRiskSpectrum: { styles: sfRiskSpectrumStyles, root: sfRiskSpectrumRoot },
  } = useTheme();

  const bands: PortfolioBand[] | RiskBand[] = showPortfolioBands && portfolioBands ? portfolioBands : riskBands;

  const allowAllUpwardRiskValues = allowedRiskToleranceChange.up === -1;
  const allowAllDownwardRiskValues = allowedRiskToleranceChange.down === -1;

  const marks = bands
    ?.map((b, index) => ({ label: b.label, value: index }))
    ?.slice(
      allowAllDownwardRiskValues ? 0 : Math.max(0, riskBandNew - allowedRiskToleranceChange.down),
      allowAllUpwardRiskValues ? bands.length : riskBandNew + allowedRiskToleranceChange.up + 1,
    );

  useEffect(() => {
    // If riskBandUser is present and it lies between allowed set of values then set slider band to riskBandUser
    // else set slider band to riskBandNew
    setSliderBand(riskBandUser !== undefined && marks.find(m => m.value === riskBandUser) ? riskBandUser : riskBandNew);
  }, [riskBandNew, riskBandUser]);

  useEffect(() => {
    if (riskSpectrumRef.current) {
      // The sidePadding is exactly half the width of each spectrum block.
      // If showPortfolioBands is true number of bands is portfolioBands else riskBands
      setSidePadding(
        riskSpectrumRef.current.offsetWidth /
          ((showPortfolioBands && portfolioBands ? portfolioBands?.length : riskBands.length) * 2),
      );
    }
  }, [showPortfolioBands, portfolioBands, riskBands.length]);

  const isValidBandIndex = (index?: number) => index === undefined || (index >= 0 && index < bands.length);

  /**
   * Show all labels when in non-compact or non-editMode, or
   * in editMode, show all but sliderBand since the SliderSelector/Thumb component is present, or
   * If showPortfolioBands is true hide label if riskBandIndex is equals to index instead of the sliderBand
   */
  const shouldDisplayLabel = (labelIndex: number): boolean => {
    const selectedIndex: number =
      showPortfolioBands && portfolioBands && sliderBand > -1 ? portfolioBands[sliderBand].riskBandIndex : sliderBand;

    if (!compact && !editMode) {
      return true;
    }
    if (isMobileVariant) {
      if (editMode) {
        return false;
      }
      if (selectedIndex === labelIndex) {
        return true;
      }
    } else if (editMode) {
      return selectedIndex !== labelIndex;
    }
    return false;
  };

  const maxUpwardRiskToleranceValue = Math.min(riskBandNew + allowedRiskToleranceChange.up, bands.length - 1);
  const isRightButtonDisabled = allowAllUpwardRiskValues
    ? sliderBand === bands.length - 1
    : sliderBand === maxUpwardRiskToleranceValue;
  const isLeftButtonDisabled = allowAllDownwardRiskValues
    ? sliderBand === 0
    : sliderBand <= riskBandNew - allowedRiskToleranceChange.down;

  /**
   * Renders the spectrum background with white borders to create spectrum blocks.
   * The sidePadding is used to make the Slider component narrower by exactly half the width of each block,
   * while making the Rail wider by the same length sidePadding for background-color, so that the Slider thumb
   * is positioned exactly at the center of the spectrum block.
   */
  const SliderRailComponent = useCallback(() => {
    let background = sfRiskSpectrumStyles.background;
    if (editMode) {
      let maxAllowedRiskBandIndex = allowAllUpwardRiskValues ? bands.length - 1 : maxUpwardRiskToleranceValue;
      let minAllowedRiskBandIndex = allowAllDownwardRiskValues ? 0 : riskBandNew - allowedRiskToleranceChange.down;

      if (showPortfolioBands && portfolioBands) {
        maxAllowedRiskBandIndex = portfolioBands[maxAllowedRiskBandIndex].riskBandIndex;
        minAllowedRiskBandIndex = portfolioBands[minAllowedRiskBandIndex].riskBandIndex;
      }
      const maxDisabledPercent = ((maxAllowedRiskBandIndex + 1) * 100) / riskBands.length;
      const minDisabledPercent = (minAllowedRiskBandIndex * 100) / riskBands.length;
      background = `linear-gradient(
                      90deg, 
                      ${sfRiskSpectrumStyles.disabledBackgroundColor} 0%, 
                      ${sfRiskSpectrumStyles.disabledBackgroundColor} ${minDisabledPercent}%, 
                      transparent ${minDisabledPercent}%
                    ), 
                    linear-gradient(
                      90deg, 
                      transparent 0%, 
                      transparent ${maxDisabledPercent}%, 
                      ${sfRiskSpectrumStyles.disabledBackgroundColor} ${maxDisabledPercent}%
                    ),
                    ${background}`;
    }

    return (
      <Box
        sx={{
          alignItems: 'flex-end',
          background,
          display: 'flex',
          height: riskBandHeightPrimary,
          ml: `-${sidePadding}px`,
          width: `calc(100% + ${sidePadding * 2}px)`,
        }}
      >
        {riskBands.map((rl, index) => {
          const indicator = getIndicator({
            bandIndex: index,
            riskBandNew,
            compact,
            editMode,
            indicatorLabels,
            portfolioBands,
            riskBandCurrent,
            riskBandUser,
            showPortfolioBands,
            sliderBand,
            fromEditAccountModal,
          });
          return (
            <Box
              className={indicator.isPrimary ? 'RiskSpectrum-primary' : ''}
              data-qa={`${dataQa}-band-${index}`}
              key={index}
              sx={{
                '&:not(:last-of-type)': { borderRight: '2px solid white' },
                '&.RiskSpectrum-primary': {
                  borderBottom: `${indicator.isPrimary ? 0 : riskBandHeightPrimary - riskBandHeight}px solid white`,
                  '.RiskSpectrum-indicator': {
                    width: '100%',
                    '.MuiTypography-root': {
                      display: 'inherit',
                      alignSelf: {
                        xs: index === 0 ? 'flex-start' : index === riskBands.length - 1 ? 'flex-end' : undefined,
                        sm: 'center',
                      },
                    },
                  },
                },
                alignItems: 'center',
                borderBottom: `${riskBandHeightPrimary - riskBandHeight}px solid white`,
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                height: `${indicator.isPrimary ? riskBandHeightPrimary : riskBandHeight}px`,
                width: `${100 / riskBands.length}%`,
              }}
            >
              {/* Get portfolio bands if showPortfolioBands is true */}
              {showPortfolioBands && portfolioBands?.length && (
                <PortfolioBands
                  bandIndex={index}
                  compact={compact}
                  dataQa={dataQa}
                  indicator={indicator}
                  portfolioBands={portfolioBands}
                  riskBandHeight={riskBandHeight}
                  riskBandHeightPrimary={riskBandHeightPrimary}
                  riskBandNew={riskBandNew}
                />
              )}
              {shouldDisplayLabel(index) && (
                <Box sx={{ ...sfRiskSpectrumStyles.label, position: 'relative' }}>
                  <Typography
                    data-qa={`${dataQa}-band-label-${index}`}
                    sx={{ color: 'text.primary' }}
                    variant="caption"
                  >
                    {rl.label}
                  </Typography>
                </Box>
              )}
              {
                // This Indicator serves as marker for the recommended band in edit mode.
                !showPortfolioBands && (!editMode || (editMode && index === riskBandNew)) && indicator.label && (
                  <IndicatorComponent
                    compact={compact}
                    dataQa={dataQa}
                    editMode={editMode}
                    indicator={indicator}
                    riskBandHeightPrimary={riskBandHeightPrimary}
                  />
                )
              }
            </Box>
          );
        })}
      </Box>
    );
  }, [
    allowAllUpwardRiskValues,
    allowAllDownwardRiskValues,
    compact,
    dataQa,
    editMode,
    riskBandHeight,
    riskBandHeightPrimary,
    riskBandNew,
    riskBands,
    sfRiskSpectrumStyles,
    sidePadding,
    shouldDisplayLabel,
    sliderBand,
  ]);

  const handleSliderClick = useCallback(
    (delta: number) => {
      const newBandValue = sliderBand + delta;
      setSliderBand(newBandValue);
      onChange?.(newBandValue);
    },
    [onChange, sliderBand],
  );

  const {
    sfSliderSelector: { styles: sfSliderSelectorStyles },
  } = useTheme();

  /**
   * The Indicator is rendered as part of the thumb to make it align with SliderSelector component.
   * Renders nothing if not in editMode.
   */
  const SliderThumbComponent = useCallback(
    props => {
      const { children, ...rest } = props;
      const selectedBand = bands[sliderBand];
      const boxShadowCaret = sfSliderSelectorStyles.boxShadowCaret as CSS.Property.BoxShadow;
      return editMode ? (
        <SliderThumb
          sx={{
            '&:hover': { boxShadow: 'none' },
            backgroundColor: 'transparent',
            flexDirection: 'column',
            top: 0,
          }}
          {...rest}
        >
          {children}
          {!isMobileVariant ? (
            <Box
              sx={{
                position: 'absolute',
                top: -70,
              }}
            >
              <SliderSelector
                label={selectedBand?.label}
                leftButtonDisabled={isLeftButtonDisabled}
                onLeftClicked={() => handleSliderClick(-1)}
                onRightClicked={() => handleSliderClick(1)}
                rightButtonDisabled={isRightButtonDisabled}
              />
            </Box>
          ) : (
            <ArrowMobile boxShadowCaret={boxShadowCaret} />
          )}

          {!showPortfolioBands && selectedBand && sliderBand !== riskBandNew && (
            <Box sx={{ position: 'absolute', top: 5, ...sfRiskSpectrumStyles.indicatorPrimaryRoot }}>
              <IndicatorComponent
                compact={compact}
                dataQa={`${dataQa}-slider-thumb`}
                editMode={editMode}
                indicator={getIndicator({
                  bandIndex: sliderBand,
                  riskBandNew,
                  compact,
                  editMode,
                  indicatorLabels,
                  portfolioBands,
                  riskBandCurrent,
                  riskBandUser,
                  showPortfolioBands,
                  sliderBand,
                  fromEditAccountModal,
                })}
                riskBandHeightPrimary={riskBandHeightPrimary}
              />
            </Box>
          )}
        </SliderThumb>
      ) : null;
    },
    [
      bands,
      editMode,
      handleSliderClick,
      isLeftButtonDisabled,
      isRightButtonDisabled,
      riskBandNew,
      riskBands,
      sfRiskSpectrumStyles.indicatorPrimaryRoot,
      sliderBand,
      showPortfolioBands,
    ],
  );

  const riskSpectrumPadding = useMemo(() => {
    if (editMode) {
      return 10;
    } else if (compact && !isMobileVariant) {
      return 0;
    } else {
      return 7;
    }
  }, [editMode, compact, isMobileVariant]);

  const sliderBoxPadding = useMemo(() => {
    if (isMobileVariant && editMode) {
      return sidePadding + 60;
    } else if (showPortfolioBands && editMode) {
      return sidePadding + 80;
    } else {
      return sidePadding;
    }
  }, [isMobileVariant, editMode, sidePadding, showPortfolioBands]);

  return (
    <Box
      data-qa={dataQa}
      ref={riskSpectrumRef}
      sx={{
        pt: isMobileVariant && editMode ? 0 : riskSpectrumPadding,
        // Bottom padding in case of 'showPortfolioBands' is 5 instead of 10 because the height of
        // Mui Slider is less than visible height. In case of 'showPortfolioBands' DiversificationBlocks
        // are also present after Mui Slider, which makes the visible height and actual height same, and
        // that's why the extra padding required to compensate for the height difference is not required.
        pb: isMobileVariant && editMode ? 4 : showPortfolioBands && editMode ? 5 : riskSpectrumPadding,
        ...sfRiskSpectrumRoot,
      }}
    >
      {!riskBands ? (
        <Skeleton />
      ) : !isValidBandIndex(riskBandCurrent) || !isValidBandIndex(riskBandNew) ? (
        <Alert contentOptions={contentOptions} severity="error" />
      ) : (
        <>
          {isMobileVariant && editMode && (
            <Box pb={3} width="100%">
              <SliderSelector
                displayArrow={false}
                fullWidth
                label={bands[sliderBand]?.label}
                leftButtonDisabled={isLeftButtonDisabled}
                onLeftClicked={() => handleSliderClick(-1)}
                onRightClicked={() => handleSliderClick(1)}
                rightButtonDisabled={isRightButtonDisabled}
              />
            </Box>
          )}
          <Box sx={{ px: `${sliderBoxPadding}px` }}>
            <Slider
              components={{
                Rail: SliderRailComponent,
                Thumb: SliderThumbComponent,
              }}
              disabled={!editMode}
              marks={marks}
              max={bands.length - 1}
              min={0}
              onChange={(event, newBandIndex) => {
                // Only support dragging and not clicking, clicks will get handled by the
                // onChangeCommitted function.
                if ((event as MouseEvent).type !== 'mousedown') {
                  onChange?.(newBandIndex as number);
                  setSliderBand(newBandIndex as number);
                }
              }}
              onChangeCommitted={(event, newBandIndex) => {
                // Don't do anything if slider buttons are clicked
                if ((event.target as HTMLElement).classList.contains('slider-clickable-element')) {
                  return;
                }
                onChange?.(newBandIndex as number);
                setSliderBand(newBandIndex as number);
              }}
              step={null}
              sx={sfRiskSpectrumStyles.slider}
              track={false}
              value={sliderBand}
              valueLabelDisplay="off"
            />
          </Box>
          {/* Show diversification block only when sliderBand is not equal to riskBandNew and showPortfolioBands is true */}
          {sliderBand !== riskBandNew && showPortfolioBands && portfolioBands && sliderBand !== -1 ? (
            <Grid
              container
              direction={isMobileVariant ? 'column-reverse' : 'row'}
              spacing={isMobileVariant ? 4 : 2}
              sx={{ mt: isMobileVariant ? 7 : 10 }}
            >
              <Grid item md={6} xs={12}>
                <DiversificationBlock
                  bandIndex={riskBandNew}
                  dataQa={`${dataQa}-diversification-recommended`}
                  diversificationBlockContent={diversificationBlockContent}
                  indicatorLabels={indicatorLabels}
                  isRecommended
                  portfolioBands={portfolioBands}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DiversificationBlock
                  bandIndex={sliderBand}
                  dataQa={`${dataQa}-diversification-selection`}
                  diversificationBlockContent={diversificationBlockContent}
                  indicatorLabels={indicatorLabels}
                  portfolioBands={portfolioBands}
                />
              </Grid>
            </Grid>
          ) : null}
        </>
      )}
    </Box>
  );
};

const ArrowMobile = (props: { boxShadowCaret: CSS.Property.BoxShadow }) => (
  <Box
    sx={{
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 0,
        height: 0,
        ml: '-8px',
        bottom: '16px',
        left: '0',
        border: '8px solid black',
        borderColor: '#fff',
        boxSizing: 'border-box',
        boxShadow: `${props.boxShadowCaret}`,
        transformOrigin: '0 0',
        transform: 'rotate(-45deg)',
      },
      zIndex: 1,
    }}
  />
);
