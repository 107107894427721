import { ACATInstructions, ACATRequestStatus, ACATRequestStatusFilter } from '.';

import { FlagReason, ScheduledTransferStatus } from '~/__generated__';

export function toACATRequestStatus(status: ScheduledTransferStatus): ACATRequestStatus {
  switch (status) {
    // PENDING:
    case ScheduledTransferStatus.PENDING:
      return ACATRequestStatus.PENDING;
    // IN_PROGRESS:
    case ScheduledTransferStatus.ACTIVE:
    case ScheduledTransferStatus.ACTIVE_AT_BROKERAGE:
    case ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE:
    case ScheduledTransferStatus.CASH_GENERATED:
    case ScheduledTransferStatus.GENERAL_ERROR:
    case ScheduledTransferStatus.INSUFFICIENT_ASSETS:
    case ScheduledTransferStatus.SUBMITTED:
    case ScheduledTransferStatus.TRADES_SUBMITTED:
    case ScheduledTransferStatus.UNAUTHORIZED:
      return ACATRequestStatus.IN_PROGRESS;
    // COMPLETED/CANCELLED:
    case ScheduledTransferStatus.COMPLETED:
      return ACATRequestStatus.COMPLETED;
    case ScheduledTransferStatus.CANCELLED:
    case ScheduledTransferStatus.PENDING_CANCELLATION:
      return ACATRequestStatus.CANCELLED;
    default:
      return ACATRequestStatus.UNRECOGNIZED;
  }
}

export function toCloseAccountStatus(statusFilter: ACATRequestStatusFilter): ScheduledTransferStatus[] | null {
  switch (statusFilter) {
    case ACATRequestStatusFilter.IN_PROGRESS:
      return [
        ScheduledTransferStatus.ACTIVE,
        ScheduledTransferStatus.ACTIVE_AT_BROKERAGE,
        ScheduledTransferStatus.TRADES_SUBMITTED,
        ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE,
        ScheduledTransferStatus.CASH_GENERATED,
        ScheduledTransferStatus.GENERAL_ERROR,
        ScheduledTransferStatus.INSUFFICIENT_ASSETS,
        ScheduledTransferStatus.SUBMITTED,
        ScheduledTransferStatus.TRADES_SUBMITTED,
        ScheduledTransferStatus.UNAUTHORIZED,
      ];
    case ACATRequestStatusFilter.PENDING:
      return [ScheduledTransferStatus.PENDING];
    case ACATRequestStatusFilter.COMPLETED:
      return [ScheduledTransferStatus.COMPLETED];
    case ACATRequestStatusFilter.CANCELLED:
      return [ScheduledTransferStatus.CANCELLED, ScheduledTransferStatus.PENDING_CANCELLATION];
    case ACATRequestStatusFilter.ALL:
    default:
      return [
        ScheduledTransferStatus.ACTIVE,
        ScheduledTransferStatus.ACTIVE_AT_BROKERAGE,
        ScheduledTransferStatus.TRADES_SUBMITTED,
        ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE,
        ScheduledTransferStatus.CANCELLED,
        ScheduledTransferStatus.CASH_GENERATED,
        ScheduledTransferStatus.COMPLETED,
        ScheduledTransferStatus.GENERAL_ERROR,
        ScheduledTransferStatus.INSUFFICIENT_ASSETS,
        ScheduledTransferStatus.PENDING,
        ScheduledTransferStatus.PENDING_CANCELLATION,
        ScheduledTransferStatus.SUBMITTED,
        ScheduledTransferStatus.TRADES_SUBMITTED,
        ScheduledTransferStatus.UNAUTHORIZED,
      ];
  }
}

export const getAllowedStatusUpdatesForACATRequest = (status: ACATRequestStatus): ACATRequestStatus[] => {
  switch (status) {
    case ACATRequestStatus.PENDING:
    case ACATRequestStatus.IN_PROGRESS:
      return [ACATRequestStatus.CANCELLED, ACATRequestStatus.COMPLETED];
    default:
      return [];
  }
};

export const getCloseAccountStatusForUpdateForACATRequest = (
  status: ACATRequestStatus,
): ScheduledTransferStatus | null => {
  switch (status) {
    case ACATRequestStatus.CANCELLED:
      return ScheduledTransferStatus.CANCELLED;
    case ACATRequestStatus.COMPLETED:
      return ScheduledTransferStatus.COMPLETED;
    case ACATRequestStatus.IN_PROGRESS:
    case ACATRequestStatus.PENDING:
    case ACATRequestStatus.UNRECOGNIZED:
      return null;
  }
};

export const getACATRequestDisplayInstructions = (flagReason: FlagReason): string => {
  switch (flagReason) {
    case FlagReason.API_FAILURE:
      return ACATInstructions.ERROR;
    case FlagReason.OTHER:
      return ACATInstructions.MANUAL_INPUT_REQUIRED;
    default:
      return '';
  }
};
