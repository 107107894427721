import React, { ComponentProps, useEffect, useMemo, useState } from 'react';

import {
  isAccountDetailsBillingHistorySection,
  isAccountDetailsBillingRatesSection,
  isAccountDetailsCompositeModelPortfolioAllocationSection,
  isAddFundsSection,
  isAssetAllocationSection,
  isPerformanceChartSection,
  isSupportQuestionsSection,
  SupportQuestionsSection,
} from './contentstack';
import { useAccountDetailsOverviewContent } from './hooks/useAccountDetailsOverviewContent';
import {
  getAddFundsContent,
  getAssetAllocationContent,
  getPerformanceChartContent,
  getSupportContactContent,
} from './hooks/utils';

import {
  AssociatedEntityType,
  FinancialAccountType,
  ManagedProductStatus,
  ManagedProductType,
  PlaidProduct,
  SleeveType,
} from '~/__generated__/symphonyTypes.v2';
import { AccountActions } from '~/components/AccountActions';
import { AccountBalanceChart } from '~/components/AccountPerformance/AccountBalanceChart';
import { AddFunds } from '~/components/AddFunds';
import { AssetAllocation, Content as AssetAllocationContent } from '~/components/AssetAllocation';
import { BillingHistory } from '~/components/BillingHistory';
import { BillingRates } from '~/components/BillingRates';
import { CompositeModelPortfolioAllocation } from '~/components/CompositeModelPortfolioAllocation';
import { ErrorComponent } from '~/components/ErrorComponent';
import { FaPlaidFlow } from '~/components/FaPlaidFlow';
import { ClientInitiatePlaidModal } from '~/components/modals/ClientInitiatePlaid';
import { ClientLedPlaidSuccessModal } from '~/components/modals/ClientLedPlaidSuccess';
import { ContactAdvisorModal } from '~/components/modals/ContactAdvisor';
import { LinkBankAccountModal } from '~/components/modals/LinkBankAccount';
import { PerformanceChart } from '~/components/PerformanceChart';
import { PerformanceTimePeriodSelect } from '~/components/PerformanceTimePeriodSelect';
import { SupportContact } from '~/components/SupportContact';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Paper, Skeleton, useTheme } from '~/components/ui/mui';
import { useGetAccountPerformanceData } from '~/containers/AccountPerformance/hooks/useGetAccountPerformanceData';
import { useGetAddAccountSuccess } from '~/hooks/account/useGetAddAccountSuccess';
import { useGetPerformanceChartData } from '~/hooks/account-details/hooks/useGetPerformanceChartData';
import { DetailedFinancialAccount, ManagedProduct } from '~/hooks/account-details/symphony';
import {
  AccountDetailsGetDigitalWealthAccounts,
  AccountDetailsGetDigitalWealthAccountsVariables,
} from '~/hooks/account-details/symphony/__generated__/query.v2';
import {
  AccountDetailsSectionBlock,
  getAllocations,
  getCompositeModelPortfolioAllocationData,
  getOnLaunchSchedulingTool,
  getPaperProps,
  getPerformanceTimePeriods,
} from '~/hooks/account-details/utils';
import { useGetAssetClassContent } from '~/hooks/asset-classes/contentstack';
import { FinancialAccount as BankAccountFinancialAccount } from '~/hooks/financial-account/symphony';
import { useGetAllModelPortfoliosContentAcrossPages } from '~/hooks/model-portfolios/contentstack';
import { MeasurementName, ReturnsByPeriod } from '~/hooks/performance/types';
import { useReturns } from '~/hooks/performance/useReturns';
import { PlaidAccessTokenMetaData, PlaidLinkType, usePlaidLinkHook } from '~/hooks/plaid-link';
import {
  BankAccount,
  getAccountState,
  getPrimaryClientPartyId,
  isPendingPlanUpdateWorkflowStatus,
  isSavingsOptimizerAccount,
} from '~/utils/account';
import { ApolloQueryResult } from '~/utils/apollo-client';
import { isRecommendedPortfolio } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { CoreConfig, useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { formatPercentage } from '~/utils/format';
import { getSxPropsArray } from '~/utils/theme';

interface AccountDetailsOverviewConfig {
  defaultContributionAmount: string;
  hiddenSections: AccountDetailsSectionBlock[];
  plaidLinkageType?: PlaidLinkType;
  showVerifiedBankAccounts: boolean;
  syncExternalBankAccounts: boolean;
}

export interface Props {
  account?: DetailedFinancialAccount;
  actions: ComponentProps<typeof AccountActions>['config'];
  bankAccounts: BankAccount[];
  config: CoreConfig['components']['sfAccountDetailsOverview'] & AccountDetailsOverviewConfig;
  contentOptions: ContentOptions;
  dataQa?: string;
  digitalWealthAccountsInitialLoading: boolean;
  getAssetClassTier?: (program: ManagedProductType) => AssetClassTier;
  initialTimePeriod: MeasurementName;
  managedProduct?: ManagedProduct;
  managedProductId: string;
  onAddFundsClick: ComponentProps<typeof AddFunds>['onAddFunds'];
  onLinkAccountCallback: (newBankAccount: BankAccountFinancialAccount) => void;
  onRemoveAccount: ComponentProps<typeof AddFunds>['onRemoveAccount'];
  onSignDocumentsRedirect?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    state?: string,
  ) => void;
  partyId: string;
  refetchDigitalWealthAccountsData: (
    variables?: Partial<AccountDetailsGetDigitalWealthAccountsVariables>,
  ) => Promise<ApolloQueryResult<AccountDetailsGetDigitalWealthAccounts>>;
  returnsDataOverride?: ReturnsByPeriod;
}

export const AccountDetailsOverview: React.FC<Props> = ({
  account,
  actions,
  bankAccounts,
  contentOptions,
  config,
  dataQa = 'account-details-overview',
  digitalWealthAccountsInitialLoading,
  getAssetClassTier,
  initialTimePeriod,
  managedProduct,
  managedProductId,
  onAddFundsClick,
  onLinkAccountCallback,
  onRemoveAccount,
  onSignDocumentsRedirect,
  partyId,
  refetchDigitalWealthAccountsData,
  returnsDataOverride,
}) => {
  const { sfRce } = useTheme();

  const {
    defaultContributionAmount,
    hiddenSections,
    performanceChartTo,
    performanceDropdownVariant,
    performanceMethod,
    plaidLinkageType,
    securityIds,
    showAccountBalanceChart,
    showActualAllocationInDonut,
    showContactAdvisorCta,
    showFactSheetLinkInAssetAllocation,
    showPerformanceCalculationInfo,
    showPortfolioNameInAssetAllocation,
    showReturnsData,
    showVerifiedBankAccounts,
    syncExternalBankAccounts,
  } = config;
  const {
    featureFlags: { isPlaidLinkageForBankAccountSupported, isSuspendedAccountStateSupported },
  } = useCoreConfig();

  const [openPlaidOtpVerification, setOpenPlaidOtpVerification] = useState(false);
  const [plaidMetaData, setPlaidMetaData] = useState<PlaidAccessTokenMetaData | undefined>(undefined);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<MeasurementName>(initialTimePeriod);

  const { data: contentData, loading: contentLoading, error: contentError } = useAccountDetailsOverviewContent({
    contentOptions,
  });

  const content = contentData?.accountDetailsContent?.all_account_details?.items?.[0];

  const {
    data: modelPortfoliosContentData,
    loading: modelPortfoliosContentLoading,
    error: modelPortfoliosContentError,
  } = useGetAllModelPortfoliosContentAcrossPages({
    contentOptions,
    skip: !showFactSheetLinkInAssetAllocation && !showPortfolioNameInAssetAllocation, // skip when both needs to be hidden
  });

  const {
    data: assetClassContentData,
    loading: assetClassContentLoading,
    error: assetClassContentError,
  } = useGetAssetClassContent({
    variables: contentOptions,
  });

  const assetClassContent = assetClassContentData?.all_asset_class_data?.items;

  const {
    open: linkBankAccountOpen,
    openModal: linkBankAccountOpenModal,
    onClose: linkBankAccountOnClose,
  } = useModalState();
  const {
    open: isContactAdvisorModalOpen,
    openModal: onOpenContactAdvisorModal,
    onClose: onCloseContactAdvisorModal,
  } = useModalState();

  const { open: isOpenPlaidLinkModal, openModal: openPlaidLinkModal, onClose: onClosePlaidLinkModal } = useModalState();
  const {
    open: isOpenClientPlaidLinkSuccessModal,
    openModal: openClientPlaidLinkSuccessModal,
    onClose: onCloseClientPlaidLinkSuccessModal,
  } = useModalState();

  const handleSuccessPlaidLink = (metaData: PlaidAccessTokenMetaData) => {
    setPlaidMetaData(metaData);
    openClientPlaidLinkSuccessModal();
  };
  const { handleOpenPlaidLink } = usePlaidLinkHook(undefined, undefined, handleSuccessPlaidLink);

  const {
    isAccountLinkageSuccessful: isBankLinkageSuccessful,
    reset: resetBankLinkageSuccessful,
    set: setBankAccountsCount,
  } = useGetAddAccountSuccess(bankAccounts);

  const {
    data: accountPerformanceData,
    loading: accountPerformanceDataLoading,
    error: accountPerformanceDataError,
  } = useGetAccountPerformanceData({
    skip: !showAccountBalanceChart,
    variables: {
      contentOptions,
      managedProductId,
      partyId,
      performanceMethod,
      securityIds,
      selectedQuarter: '',
      selectedTimePeriod,
      showFactSheetLinkInAssetAllocation,
      showPortfolioNameInAssetAllocation,
      showVerifiedBankAccounts,
      syncExternalBankAccounts,
    },
  });

  const startDate = accountPerformanceData?.startDate || '';
  const endDate = accountPerformanceData?.endDate || '';
  const openingClosingBalance = accountPerformanceData?.openingClosingBalance;

  const handleRefetchAccounts = () => {
    resetBankLinkageSuccessful();
    setBankAccountsCount();
    refetchDigitalWealthAccountsData();
  };

  const handleCloseFaPlaidFlow = () => {
    setOpenPlaidOtpVerification(false);
    resetBankLinkageSuccessful();
  };

  const supportContactContent = content?.sections?.filter((section): section is SupportQuestionsSection =>
    isSupportQuestionsSection(section),
  )[0]?.questions?.support_contact_informationConnection?.edges?.[0]?.node?.content;

  useEffect(() => {
    setSelectedTimePeriod(initialTimePeriod);
  }, [initialTimePeriod]);

  const assets = managedProduct?.targetModelPortfolio?.guidance?.diversification?.assets;
  const assetClassTier =
    managedProduct && getAssetClassTier ? getAssetClassTier(managedProduct.program) : AssetClassTier.MODEL;

  const { accountPerformanceLoading, measurements, performanceChartData } = useGetPerformanceChartData({
    firstRebalancedOn: managedProduct?.firstRebalancedOn ?? '',
    accountId: account?.id ?? '',
    performanceMethod,
    selectedTimePeriod,
    syncedOn: account?.syncedOn ?? '',
    upTo: performanceChartTo,
  });

  const { data: returnsData, loading: returnsDataLoading } = useReturns({
    accounts: [account?.id as string],
    measurements,
    skip: !!returnsDataOverride || !showReturnsData || !account?.id || !managedProduct?.firstRebalancedOn,
  });
  const returns = returnsDataOverride ?? returnsData;
  const returnsValue = returns?.[selectedTimePeriod]?.value;
  const formattedPercentageReturn = useMemo(() => {
    return returnsValue
      ? formatPercentage(parseFloat(returnsValue), {
          decimals: 2,
          removeTrailingZeroes: false,
          locale: contentOptions.locale,
        })
      : '--';
  }, [contentOptions, returnsValue]);

  const handleClosePlaidLinkSuccessModal = () => {
    refetchDigitalWealthAccountsData();
    onCloseClientPlaidLinkSuccessModal();
  };

  const handleLinkPlaidAccountAction = () => {
    if (isPlaidLinkageForBankAccountSupported) {
      if (plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR) {
        return setOpenPlaidOtpVerification(true);
      } else if (plaidLinkageType === PlaidLinkType.CLIENT) {
        return openPlaidLinkModal();
      }
      return;
    } else {
      return undefined;
    }
  };

  // only used for activated account states
  const { state: accountState, data: accountStateData } = getAccountState({
    financialAccountStatus: managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS,
    hasPendingPlanUpdateWorkflow: managedProduct?.planUpdateWorkflows.some(p =>
      isPendingPlanUpdateWorkflowStatus(p?.status),
    ),
    firstRebalancedOn: managedProduct?.firstRebalancedOn ?? undefined,
    isSuspendedAccountStateSupported,
    primaryClientPartyId: getPrimaryClientPartyId(managedProduct?.relatedParties ?? []),
  });

  const accountType: FinancialAccountType =
    managedProduct?.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE;

  const accountAttributes = managedProduct?.attributes ?? [];

  // TODO: Reference it in Account Details section (as part of https://sigfig.atlassian.net/browse/DA2-6168)
  const assetAllocationSectionContent = contentData?.accountDetailsContent?.all_asset_allocation_section?.items?.[0];

  const formattedAllocationContent = useMemo((): AssetAllocationContent | undefined => {
    if (content && assetAllocationSectionContent) {
      return getAssetAllocationContent(content, assetAllocationSectionContent, assets);
    }
    return;
  }, [assetAllocationSectionContent, assets, content]);

  const showAddFundsCta =
    content?.header?.add_funds_cta &&
    actions.addFunds?.valid?.({
      state: accountState,
      stateData: accountStateData,
      type: accountType,
      attributes: accountAttributes,
    });

  const compositeModelPortfolioAllocation = useMemo(() => {
    return getCompositeModelPortfolioAllocationData(managedProduct?.targetModelPortfolio);
  }, [managedProduct?.targetModelPortfolio]);

  const targetSingleModelPortfolio = useMemo(() => {
    return modelPortfoliosContentData?.all_model_portfolio_data?.items?.find(
      mc =>
        managedProduct?.targetModelPortfolio &&
        isRecommendedPortfolio(managedProduct.targetModelPortfolio) &&
        mc?.series_name === managedProduct.targetModelPortfolio.seriesBaseName &&
        mc.internal_id === managedProduct.targetModelPortfolio.internalId?.toString(),
    );
  }, [managedProduct, modelPortfoliosContentData]);

  const strategyBrochureUrl =
    managedProduct?.targetModelPortfolio && isRecommendedPortfolio(managedProduct.targetModelPortfolio)
      ? managedProduct.targetModelPortfolio.strategyBrochureUrl
      : '';

  const loading = assetClassContentLoading || contentLoading || modelPortfoliosContentLoading;

  const error = assetClassContentError ?? accountPerformanceDataError ?? contentError ?? modelPortfoliosContentError;

  return (
    <>
      {loading && (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}
      {error && <ErrorComponent contentOptions={contentOptions} error={error} />}
      {!loading && !error && (
        <>
          <Box
            data-qa={dataQa}
            sx={{
              '& .AccountDetails-Card:not(:first-of-type)': {
                mt: 6,
              },
            }}
          >
            {content?.sections?.map((section, index) => {
              if (isPerformanceChartSection(section) && !hiddenSections.includes('performance_chart')) {
                const timePeriods = getPerformanceTimePeriods(returns, contentData?.timePeriodLabels);
                const performanceLoading =
                  digitalWealthAccountsInitialLoading || accountPerformanceLoading || accountPerformanceDataLoading;
                const showTimePeriodSelect =
                  showReturnsData &&
                  !digitalWealthAccountsInitialLoading &&
                  (returnsDataOverride || !returnsDataLoading);

                return showAccountBalanceChart ? (
                  <Box key={index}>
                    {accountPerformanceData?.performanceChartData && (
                      <AccountBalanceChart
                        content={accountPerformanceData.content}
                        contentOptions={contentOptions}
                        endDate={endDate}
                        openingClosingBalance={openingClosingBalance}
                        performanceChartData={accountPerformanceData.performanceChartData}
                        returnPercentage={formattedPercentageReturn}
                        showFullHorizontalChart
                        startDate={startDate}
                      />
                    )}
                  </Box>
                ) : (
                  <Paper
                    {...getPaperProps(sfRce)}
                    key={index}
                    sx={[...getSxPropsArray(getPaperProps(sfRce).sx), showReturnsData && { pb: 0 }]}
                  >
                    {performanceLoading && (
                      <>
                        <Skeleton height={400} variant="rectangular" />
                        <Skeleton height={50} sx={{ my: 3 }} variant="rectangular" />
                      </>
                    )}
                    {!performanceLoading && (
                      <PerformanceChart
                        content={getPerformanceChartContent(section, content)}
                        contentOptions={contentOptions}
                        data={performanceChartData}
                        displayDialog={performanceChartData.length <= 2}
                        percentageReturn={returns?.[selectedTimePeriod]?.value}
                        showPerformanceCalculationInfo={showPerformanceCalculationInfo}
                      />
                    )}
                    {showTimePeriodSelect && contentData && (
                      <PerformanceTimePeriodSelect
                        content={contentData.performanceTimePeriodSelectContent}
                        mobileDropdownVariant={performanceDropdownVariant}
                        onPeriodClick={setSelectedTimePeriod}
                        periods={timePeriods}
                        selectedPeriodId={selectedTimePeriod}
                        sx={{ width: 'auto', mx: -(sfRce.styles.card?.p ?? 0), mt: 3 }}
                      />
                    )}
                  </Paper>
                );
              }
              if (
                isAccountDetailsCompositeModelPortfolioAllocationSection(section) &&
                !hiddenSections.includes('composite_model_portfolio_allocation') &&
                compositeModelPortfolioAllocation
              ) {
                return (
                  <Paper key={index} {...getPaperProps(sfRce)}>
                    <CompositeModelPortfolioAllocation
                      allocation={compositeModelPortfolioAllocation}
                      contentOptions={contentOptions}
                      showloader={false}
                    />
                  </Paper>
                );
              }
              if (
                isAssetAllocationSection(section) &&
                !hiddenSections.includes('asset_allocation') &&
                formattedAllocationContent
              ) {
                const attributes = managedProduct?.attributes ?? [];
                const cashSleeve = assets?.sleeveAllocation?.find(sleeve => sleeve.name === SleeveType.CASH);
                return (
                  <Paper key={index} {...getPaperProps(sfRce)}>
                    {digitalWealthAccountsInitialLoading || !account ? (
                      <Skeleton height={400} variant="rectangular" />
                    ) : (
                      <AssetAllocation
                        allocations={getAllocations(
                          assetClassTier,
                          assets,
                          assetClassContent,
                          assetAllocationSectionContent?.content?.find(item => item?.key === 'other_asset_class')
                            ?.value ?? '',
                        )}
                        assetClassTier={assetClassTier}
                        cashSleeve={cashSleeve}
                        content={formattedAllocationContent}
                        contentOptions={contentOptions}
                        factSheetUrl={strategyBrochureUrl ?? ''}
                        isSavingsOptimizerEnabled={isSavingsOptimizerAccount(attributes)}
                        modelPortfolioName={targetSingleModelPortfolio?.model_portfolio_name}
                        showActualAllocationInDonut={showActualAllocationInDonut}
                        showFactSheetLinkInAssetAllocation={showFactSheetLinkInAssetAllocation}
                        showPortfolioNameInAssetAllocation={showPortfolioNameInAssetAllocation}
                      />
                    )}
                  </Paper>
                );
              }
              if (isAccountDetailsBillingRatesSection(section) && !hiddenSections.includes('billing_rates')) {
                return (
                  <Paper key={index} {...getPaperProps(sfRce)}>
                    {digitalWealthAccountsInitialLoading ? (
                      <Skeleton height={400} variant="rectangular" />
                    ) : (
                      <BillingRates contentOptions={contentOptions} managedProductId={managedProductId} />
                    )}
                  </Paper>
                );
              }
              if (isAccountDetailsBillingHistorySection(section) && !hiddenSections.includes('billing_history')) {
                return (
                  <Paper key={index} {...getPaperProps(sfRce)}>
                    {digitalWealthAccountsInitialLoading ? (
                      <Skeleton height={400} variant="rectangular" />
                    ) : (
                      <BillingHistory contentOptions={contentOptions} managedProductId={managedProductId} />
                    )}
                  </Paper>
                );
              }
              if (isAddFundsSection(section) && !hiddenSections.includes('add_funds') && showAddFundsCta) {
                return (
                  <Paper key={index} {...getPaperProps(sfRce)}>
                    {digitalWealthAccountsInitialLoading || !account ? (
                      <Skeleton height={400} variant="rectangular" />
                    ) : (
                      <>
                        <AddFunds
                          bankAccounts={bankAccounts}
                          content={getAddFundsContent(section.add_funds, contentData?.addAnotherAccountViaPlaidCta)}
                          contentOptions={contentOptions}
                          defaultContributionAmount={defaultContributionAmount}
                          // TODO DA2-1146: Make these actions configurable from the parent
                          onAddFunds={onAddFundsClick}
                          onAddManualAccount={linkBankAccountOpenModal}
                          onAddPlaidAccount={handleLinkPlaidAccountAction}
                          onRemoveAccount={onRemoveAccount}
                          partyId={partyId}
                        />
                        {isPlaidLinkageForBankAccountSupported && (
                          <>
                            {plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR && (
                              <FaPlaidFlow
                                contentOptions={contentOptions}
                                isAccountLinkageSuccessful={!!isBankLinkageSuccessful}
                                isPlaidFlowLaunched={openPlaidOtpVerification}
                                onCloseModalCallback={handleCloseFaPlaidFlow}
                                partyId={partyId}
                                plaidProducts={PlaidProduct.AUTH}
                                refetchAccounts={handleRefetchAccounts}
                              />
                            )}
                            {plaidLinkageType === PlaidLinkType.CLIENT && (
                              <>
                                <ClientInitiatePlaidModal
                                  connectAccount={() => {
                                    onClosePlaidLinkModal();
                                    handleOpenPlaidLink(partyId, [PlaidProduct.AUTH]);
                                  }}
                                  contentOptions={contentOptions}
                                  onClose={onClosePlaidLinkModal}
                                  open={isOpenPlaidLinkModal}
                                />
                                <ClientLedPlaidSuccessModal
                                  contentOptions={contentOptions}
                                  handleConnectAccount={() => handleOpenPlaidLink(partyId, [PlaidProduct.AUTH])}
                                  onClose={handleClosePlaidLinkSuccessModal}
                                  open={isOpenClientPlaidLinkSuccessModal}
                                  plaidMetaData={plaidMetaData}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Paper>
                );
              }
              if (isSupportQuestionsSection(section) && !hiddenSections.includes('questions')) {
                return (
                  <Paper key={index} {...getPaperProps(sfRce)} sx={{ ...getPaperProps(sfRce).sx, py: 6 }}>
                    <SupportContact
                      content={getSupportContactContent(supportContactContent)}
                      onLaunchSchedulingTool={getOnLaunchSchedulingTool(actions)}
                      onOpenContactAdvisorModal={onOpenContactAdvisorModal}
                      supportEmail={supportContactContent?.variables?.support_email || 'MISSING SUPPORT EMAIL'}
                      supportPhoneNumber={
                        supportContactContent?.variables?.support_phone || 'MISSING SUPPORT PHONE NUMBER'
                      }
                      supportPhoneNumberDisplayText={
                        supportContactContent?.variables?.support_phone_display_text ||
                        'MISSING SUPPORT PHONE NUMBER DISPLAY TEXT'
                      }
                    />
                  </Paper>
                );
              }
              return null;
            })}
          </Box>
          {showContactAdvisorCta && (
            <ContactAdvisorModal
              contentOptions={contentOptions}
              onCancel={onCloseContactAdvisorModal}
              onClose={onCloseContactAdvisorModal}
              open={isContactAdvisorModalOpen}
              partyId={partyId}
              supportEmail={supportContactContent?.variables?.support_email || 'MISSING SUPPORT EMAIL'}
              supportPhoneNumber={supportContactContent?.variables?.support_phone || 'MISSING SUPPORT PHONE NUMBER'}
            />
          )}
          <LinkBankAccountModal
            contentOptions={contentOptions}
            managedProductId={managedProductId}
            onClose={linkBankAccountOnClose}
            onLinkAccount={newBankAccount => onLinkAccountCallback(newBankAccount)}
            open={linkBankAccountOpen}
            partyId={partyId}
            redirectToSignDocuments={onSignDocumentsRedirect}
          />
        </>
      )}
    </>
  );
};
