import { TotalACATRequestsV2, TotalACATRequestsV2Variables, TotalRestrictions } from './__generated__/query.v2';
import { TotalAccounts } from './__generated__/query.v2';
import { TotalCashTransfersV2, TotalCashTransfersV2Variables } from './__generated__/query.v2';
import { TotalClosuresV2, TotalClosuresV2Variables } from './__generated__/query.v2';
import { TotalDocusignV2 } from './__generated__/query.v2';
import { TotalFailuresV2 } from './__generated__/query.v2';
import { TotalFinancialAccountAssociationVerificationRequestsV2 } from './__generated__/query.v2';
import { TotalFundingReviewsV2 } from './__generated__/query.v2';
import { TotalPendingModelChangesV2, TotalPendingModelChangesV2Variables } from './__generated__/query.v2';
import {
  TotalRecurringCancellationsCashTransfersV2,
  TotalRecurringCancellationsCashTransfersV2Variables,
} from './__generated__/query.v2';
import { TotalSuspensionsV2 } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useTotalCashTransfersV2 = (
  options?: QueryHookOptions<TotalCashTransfersV2, TotalCashTransfersV2Variables>,
): QueryResult<TotalCashTransfersV2> => {
  return useSymphonyQuery(queries.TotalCashTransfersV2, options);
};

export const useTotalAccounts = (options?: QueryHookOptions<TotalAccounts>): QueryResult<TotalAccounts> => {
  return useSymphonyQuery(queries.TotalAccounts, options);
};

export const useTotalRecurringCancellationsCashTransfersV2 = (
  options?: QueryHookOptions<
    TotalRecurringCancellationsCashTransfersV2,
    TotalRecurringCancellationsCashTransfersV2Variables
  >,
): QueryResult<TotalRecurringCancellationsCashTransfersV2> => {
  return useSymphonyQuery(queries.TotalRecurringCancellationsCashTransfersV2, options);
};

export const useTotalClosures = (
  options?: QueryHookOptions<TotalClosuresV2, TotalClosuresV2Variables>,
): QueryResult<TotalClosuresV2> => {
  return useSymphonyQuery(queries.TotalClosuresV2, options);
};

export const useTotalSuspensions = (
  options?: QueryHookOptions<TotalSuspensionsV2>,
): QueryResult<TotalSuspensionsV2> => {
  return useSymphonyQuery(queries.TotalSuspensionsV2, options);
};

export const useTotalRestrictions = (options?: QueryHookOptions<TotalRestrictions>): QueryResult<TotalRestrictions> => {
  return useSymphonyQuery(queries.TotalRestrictions, options);
};

export const useTotalFailures = (options?: QueryHookOptions<TotalFailuresV2>): QueryResult<TotalFailuresV2> => {
  return useSymphonyQuery(queries.TotalFailuresV2, options);
};

export const useTotalDocusign = (options?: QueryHookOptions<TotalDocusignV2>): QueryResult<TotalDocusignV2> => {
  return useSymphonyQuery(queries.TotalDocusignV2, options);
};

export const useTotalPendingModelChanges = (
  options?: QueryHookOptions<TotalPendingModelChangesV2, TotalPendingModelChangesV2Variables>,
): QueryResult<TotalPendingModelChangesV2, TotalPendingModelChangesV2Variables> => {
  return useSymphonyQuery(queries.TotalPendingModelChangesV2, options);
};

export const useTotalACATRequests = (
  options?: QueryHookOptions<TotalACATRequestsV2, TotalACATRequestsV2Variables>,
): QueryResult<TotalACATRequestsV2> => {
  return useSymphonyQuery(queries.TotalACATRequestsV2, options);
};

export const useTotalFinancialAccountAssociationVerificationRequests = (
  options?: QueryHookOptions<TotalFinancialAccountAssociationVerificationRequestsV2>,
): QueryResult<TotalFinancialAccountAssociationVerificationRequestsV2> => {
  return useSymphonyQuery(queries.TotalFinancialAccountAssociationVerificationRequestsV2, options);
};
export const useTotalFundingReviews = (
  options?: QueryHookOptions<TotalFundingReviewsV2>,
): QueryResult<TotalFundingReviewsV2> => {
  return useSymphonyQuery(queries.TotalFundingReviewsV2, options);
};
