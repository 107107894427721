import { useMemo, useState } from 'react';

import { useGetGoalReadinessStatusContent } from '../contentstack';
import { ReadinessStatus } from '../types';

import { parseGoalProjectionsStatus } from './utils';

import { useGetPartnerConfig } from '~/hooks/goals/amplify';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
  partnerConfigId: string;
}

export const useGetReadinessStatuses = ({
  contentOptions,
  partnerConfigId,
}: Variables): AsyncResult<ReadinessStatus[]> => {
  const [invalidReadinessError, setInvalidReadinessError] = useState<Error>();

  const {
    data: goalReadinessStatusContent,
    loading: goalReadinessStatusContentLoading,
    error: goalReadinessStatusContentError,
  } = useGetGoalReadinessStatusContent({
    variables: contentOptions,
  });

  const { data: partnerConfigData, loading: partnerConfigLoading, error: partnerConfigError } = useGetPartnerConfig({
    variables: { partnerConfigId },
  });

  const loading = goalReadinessStatusContentLoading || partnerConfigLoading;
  const data = useMemo(() => {
    if (loading) {
      return;
    }
    try {
      const goalProjectionsStatus = parseGoalProjectionsStatus(
        partnerConfigData?.partnerConfig?.goalProjectionsStatus ?? '',
      );

      const contentstackReadinessStatuses =
        goalReadinessStatusContent?.all_goal_readiness_status?.items?.[0]?.status?.map(readinessStatus => ({
          index: readinessStatus?.index ?? -1,
          label: readinessStatus?.label ?? '',
        })) ?? [];

      goalProjectionsStatus.readinessStatuses.sort((a, b) => a.threshold - b.threshold);

      return goalProjectionsStatus.readinessStatuses.map((readinessStatus, index) => {
        const targetContentstackReadinessStatus = contentstackReadinessStatuses.find(
          contentstackReadinessStatus => contentstackReadinessStatus.index === index,
        );

        return {
          label: targetContentstackReadinessStatus?.label ?? '',
          threshold: readinessStatus.threshold,
          color: readinessStatus.color,
        };
      });
    } catch (readinessStatusError: any) {
      setInvalidReadinessError(readinessStatusError);
    }
    return;
  }, [
    goalReadinessStatusContent?.all_goal_readiness_status?.items,
    loading,
    partnerConfigData?.partnerConfig?.goalProjectionsStatus,
  ]);

  return {
    data,
    error: goalReadinessStatusContentError ?? partnerConfigError ?? invalidReadinessError,
    loading,
  };
};
