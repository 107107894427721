import { useState } from 'react';

import { PlanUpdateWorkflowStatus } from '~/__generated__';
import { useUpdatePlanUpdateWorkflow } from '~/hooks/plan/symphony';
import { UpdatePlanUpdateWorkflow_updatePlanUpdateWorkflow } from '~/hooks/plan/symphony/__generated__/mutation.v2';

export const useApproveModelChange = (planId: string, planUpdateWorkflowId: string) => {
  const [updatePlanUpdateWorkflow] = useUpdatePlanUpdateWorkflow();
  const [loading, setLoading] = useState(false);
  const [approvalError, setApprovalError] = useState<Error | undefined>();
  const [data, setData] = useState<UpdatePlanUpdateWorkflow_updatePlanUpdateWorkflow | undefined>();

  const approveModelChange = async () => {
    try {
      setLoading(true);
      const res = await updatePlanUpdateWorkflow({
        variables: {
          planId,
          planUpdateWorkflowId,
          status: PlanUpdateWorkflowStatus.COMPLETED,
        },
      });
      setApprovalError(undefined);
      setData(res.data?.updatePlanUpdateWorkflow);
    } catch (error: any) {
      console.error(error);
      setApprovalError(error);
    } finally {
      setLoading(false);
    }
  };

  return { approveModelChange, data, loading, error: approvalError };
};
