import { parseISO } from 'date-fns';

export const findMostRecentDate = (dates: (string | undefined)[]): string | undefined =>
  dates.reduce<string | undefined>((acc, dateStringB) => {
    if (acc && dateStringB) {
      const dateA = parseISO(acc);
      const dateB = parseISO(dateStringB);
      return dateB > dateA ? dateStringB : acc;
    }
    return acc ?? dateStringB;
  }, undefined);
