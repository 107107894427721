import { addMilliseconds, isAfter, isBefore, subMilliseconds } from 'date-fns';
import { first, last } from 'lodash';

import { Moment } from '.';

/**
 * Takes a sorted list of current moments and the current unix time and produces an appropriate time bounds
 * @param sortedMoments {Moment[]} the moments for which to generate a time bounds
 * @param now {number} unix time
 * @returns undefined if the array is empty. Otherwise, returns time interval using a default buffer size of 10% or 1 day, whichever is larger
 */
export const getTimelineInterval = (
  sortedMoments: Pick<Moment, 'date'>[],
  now: Date | number,
): { end: Date; start: Date } | undefined => {
  const ONE_DAY = 86400000; // milliseconds in a day
  const firstMoment = first(sortedMoments);
  const lastMoment = last(sortedMoments);
  if (!firstMoment || !lastMoment) {
    return undefined;
  }
  const firstDate = isBefore(firstMoment.date, now) ? firstMoment.date : new Date(now);
  const lastDate = isAfter(lastMoment.date, now) ? lastMoment.date : new Date(now);
  const buffer = Math.max((lastDate.getTime() - firstDate.getTime()) * 0.1, ONE_DAY);

  return {
    start: subMilliseconds(firstDate, buffer),
    end: addMilliseconds(lastDate, buffer),
  };
};
