import React from 'react';

import { TableCell, TableRow } from '~/components/ui/mui';

export const HorizontalLines = () => (
  <TableRow>
    <TableCell sx={{ padding: 0 }}>
      <hr style={{ border: 0, backgroundColor: 'rgba(0, 0, 0, 0.12)', height: 0.5 }} />
    </TableCell>
    <TableCell sx={{ padding: 0 }}>
      <hr style={{ border: 0, backgroundColor: 'rgba(0, 0, 0, 0.12)', height: 0.5 }} />
    </TableCell>
  </TableRow>
);
