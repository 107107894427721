import React from 'react';

import { Box, DescriptionIcon, Grid, Theme, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { AccountState } from '~/utils/account';
import { SfTheme } from '~/utils/theme';

export interface Content {
  descriptions: Record<AccountState, string>;
  labels: {
    activate: string;
    fund: string;
    pending_model_change: string;
    rebalance: string;
    sign: string;
  };
}

export enum PendingStatusVariants {
  Row = 'Row',
  Stacked = 'Stacked',
}
export interface Props {
  content: Content;
  dataQa?: string;

  showBars?: boolean;
  showDescription?: boolean;
  state: AccountState;
  variables: {
    dateStarted?: string;
    hasViewerPartyFinishedSigningForOnboarding?: boolean;
    isViewerPartyASigneeForOnboarding?: boolean;
    requiredFunds?: string;
    shortfall?: string;
    timeStarted?: string;
  };
  variant?: PendingStatusVariants;
}

const inProgressBackground = (type: 'success' | 'warning') => (theme: Theme): string =>
  `repeating-linear-gradient(45deg, ${theme.palette[type].main}, ${theme.palette[type].main} 5%, ${theme.palette[type].light} 5%, ${theme.palette[type].light} 10%)`;

const completedBackground = (type: 'success' | 'warning') => (theme: Theme): string => `${theme.palette[type].main}`;

const notStartedBackground = (theme: Theme): string => `${theme.palette.grey[300]}`;

const accountStateToBarBackgroundStyles = (
  state: AccountState,
  isViewerPartyASigneeForOnboarding: boolean,
  hasViewerPartyFinishedSigningForOnboarding: boolean,
): Record<
  'sign' | 'activate' | 'fund' | 'rebalance',
  { background: (theme: Theme) => string; progressIndicator: string }
> => {
  switch (state) {
    case AccountState.DocsSigned:
      return {
        sign: { background: completedBackground('success'), progressIndicator: 'Completed' },
        activate: { background: notStartedBackground, progressIndicator: 'Pending' },
        fund: { background: notStartedBackground, progressIndicator: 'Pending' },
        rebalance: { background: notStartedBackground, progressIndicator: 'Pending' },
      };
    case AccountState.DocsReady:
    case AccountState.DocsAndAccountReady:
    case AccountState.DocsWaitingForPrimaryFinancialAdvisor:
    case AccountState.DocsWaitingForPrimaryClient:
    case AccountState.DocsWaitingForOtherClient:
      if (isViewerPartyASigneeForOnboarding && hasViewerPartyFinishedSigningForOnboarding) {
        return {
          sign: { background: completedBackground('success'), progressIndicator: 'Completed' },
          activate: { background: notStartedBackground, progressIndicator: 'Pending' },
          fund: { background: notStartedBackground, progressIndicator: 'Pending' },
          rebalance: { background: notStartedBackground, progressIndicator: 'Pending' },
        };
      } else {
        return {
          sign: { background: inProgressBackground('warning'), progressIndicator: 'Inprogress' },
          activate: { background: notStartedBackground, progressIndicator: 'Pending' },
          fund: { background: notStartedBackground, progressIndicator: 'Pending' },
          rebalance: { background: notStartedBackground, progressIndicator: 'Pending' },
        };
      }
    case AccountState.DocsSubmitted:
      return {
        sign: { background: completedBackground('success'), progressIndicator: 'Completed' },
        activate: { background: inProgressBackground('success'), progressIndicator: 'Inprogress' },
        fund: { background: notStartedBackground, progressIndicator: 'Pending' },
        rebalance: { background: notStartedBackground, progressIndicator: 'Pending' },
      };
    case AccountState.DocsError:
      return {
        sign: { background: completedBackground('success'), progressIndicator: 'Completed' },
        activate: { background: inProgressBackground('warning'), progressIndicator: 'Inprogress' },
        fund: { background: notStartedBackground, progressIndicator: 'Pending' },
        rebalance: { background: notStartedBackground, progressIndicator: 'Pending' },
      };
    case AccountState.FundingBelowMinimum:
      return {
        sign: { background: completedBackground('success'), progressIndicator: 'Completed' },
        activate: { background: completedBackground('success'), progressIndicator: 'Completed' },
        fund: { background: inProgressBackground('warning'), progressIndicator: 'Inprogress' },
        rebalance: { background: notStartedBackground, progressIndicator: 'Pending' },
      };
    case AccountState.FundingPending:
      return {
        sign: { background: completedBackground('success'), progressIndicator: 'Completed' },
        activate: { background: completedBackground('success'), progressIndicator: 'Completed' },
        fund: { background: inProgressBackground('success'), progressIndicator: 'Inprogress' },
        rebalance: { background: notStartedBackground, progressIndicator: 'Pending' },
      };
    case AccountState.FundingError:
      return {
        sign: { background: completedBackground('success'), progressIndicator: 'Completed' },
        activate: { background: completedBackground('success'), progressIndicator: 'Completed' },
        fund: { background: inProgressBackground('warning'), progressIndicator: 'Inprogress' },
        rebalance: { background: notStartedBackground, progressIndicator: 'Pending' },
      };
    case AccountState.RebalancePending:
      return {
        sign: { background: completedBackground('success'), progressIndicator: 'Completed' },
        activate: { background: completedBackground('success'), progressIndicator: 'Completed' },
        fund: { background: completedBackground('success'), progressIndicator: 'Completed' },
        rebalance: { background: inProgressBackground('success'), progressIndicator: 'Inprogress' },
      };
    default:
      return {
        sign: { background: notStartedBackground, progressIndicator: 'Pending' },
        activate: { background: notStartedBackground, progressIndicator: 'Pending' },
        fund: { background: notStartedBackground, progressIndicator: 'Pending' },
        rebalance: { background: notStartedBackground, progressIndicator: 'Pending' },
      };
  }
};

interface BarProps {
  background: (theme: Theme) => string;
  isFirst: boolean;
  isLast: boolean;
  label: string;
  progressIndicator: string;
}

const Bar = ({ progressIndicator, label, background, isFirst, isLast }: BarProps) => {
  const roundedLeftEdge = {
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
  };
  const roundedRightEdge = {
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  };

  return (
    <Box role="listitem">
      <Typography aria-label={`${label}-${progressIndicator}`} variant="caption">
        {label}
      </Typography>
      <Box
        sx={{
          width: '95%',
          height: '5px',
          mt: 0.5,
          ...(isFirst ? roundedLeftEdge : {}),
          ...(isLast ? roundedRightEdge : {}),
          background,
        }}
      >
        {/* Empty space is added as Voice over in safari announces empty tags as new line.*/}{' '}
      </Box>
    </Box>
  );
};

export const AccountPendingStatus: React.FC<Props> = ({
  content: { labels, descriptions },
  dataQa = 'pending-status',
  showBars = true,
  showDescription = true,
  state,
  variables,
  variant = PendingStatusVariants.Row,
}) => {
  const {
    sfPendingStatus: { styles },
  } = useTheme<SfTheme>();
  const {
    sign: signBackground,
    activate: activateBackground,
    fund: fundBackground,
    rebalance: rebalanceBackground,
  } = accountStateToBarBackgroundStyles(
    state,
    !!variables.isViewerPartyASigneeForOnboarding,
    !!variables.hasViewerPartyFinishedSigningForOnboarding,
  );
  const bars = [
    {
      label: labels.sign,
      background: signBackground.background,
      progressIndicator: signBackground.progressIndicator,
    },
    {
      label: labels.activate,
      background: activateBackground.background,
      progressIndicator: activateBackground.progressIndicator,
    },
    {
      label: labels.fund,
      background: fundBackground.background,
      progressIndicator: fundBackground.progressIndicator,
    },
    {
      label: labels.rebalance,
      background: rebalanceBackground.background,
      progressIndicator: rebalanceBackground.progressIndicator,
    },
  ];

  const getStatusBar = () => {
    if (state !== AccountState.OnboardingIncomplete && state !== AccountState.OnboardingWaitingForDocs) {
      return (
        <Box display="flex" justifyContent={variant === PendingStatusVariants.Row ? 'flex-end' : 'flex-start'}>
          <Grid
            aria-label="Account Activation Progress"
            container
            justifyContent={variant === PendingStatusVariants.Row ? 'flex-end' : 'flex-start'}
            maxWidth={{ md: '700px', xs: 'unset' }}
            role="list"
          >
            {bars.map((bar, index) => (
              <Grid
                item
                key={index}
                md={variant === PendingStatusVariants.Row || !showDescription ? 2 : 1}
                sx={showDescription ? undefined : styles.noDescriptionStatusBars}
                xs={3}
              >
                <Bar {...bar} isFirst={index === 0} isLast={index === bars.length - 1} />
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }
    return undefined;
  };

  if (variant === PendingStatusVariants.Row && (descriptions[state] || (showBars && getStatusBar()))) {
    return (
      <Grid
        alignItems="space-between"
        container
        data-qa={dataQa}
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing={2}
      >
        {descriptions[state] && (
          <Grid className="pending-status-description" display="flex" item md={8} xs={12}>
            {descriptions[state] && <DescriptionIcon fontSize="small" sx={{ color: 'text.primary', mr: 1 }} />}
            <RteContent config={variables} data={descriptions[state]} />
          </Grid>
        )}
        <Grid item justifyContent="flex-end" md xs={12}>
          {showBars && getStatusBar()}
        </Grid>
      </Grid>
    );
  } else if ((showBars && getStatusBar()) || (showDescription && descriptions[state])) {
    return (
      <Box data-qa={dataQa}>
        {showBars && getStatusBar()}
        {showDescription && (
          <Box alignItems="flex-start" className="pending-status-description" display="flex" mt={2}>
            {descriptions[state] && <DescriptionIcon fontSize="small" sx={{ color: 'text.primary', mr: 1 }} />}
            <RteContent config={variables} data={descriptions[state]} />
          </Box>
        )}
      </Box>
    );
  }
  return null;
};
