import {
  GetRecurringCancellationsTableContent,
  GetRecurringCancellationsTableContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetRecurringCancellationsTableContent = (
  options?: QueryHookOptions<GetRecurringCancellationsTableContent, GetRecurringCancellationsTableContentVariables>,
): QueryResult<GetRecurringCancellationsTableContent, GetRecurringCancellationsTableContentVariables> => {
  return useContentstackQuery(queries.GetRecurringCancellationsTableContent, options);
};
