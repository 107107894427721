import { DEFAULT_NUMBER_OF_OCCURRENCE } from './hooks/utils';

import { TransferFrequency } from '~/__generated__';
import { formatDateWithAddedMonths } from '~/utils/format/date';

/**
 * Generates a description based on the number of occurrences and transfer frequency.
 * Returns blank string for all Transfer frequencies except `MONTHLY`
 *
 * @param {TransferFrequency} frequency - The transfer frequency.
 * @param {number} [numberOfOccurrences=DEFAULT_NUMBER_OF_OCCURRENCE] - The number of occurrences.
 * @param {string} [defaultText] - The default text to return if numberOfOccurrences is the default.
 * @param {string} [lastDateMessage] - The Partial text added before the last date.
 * @returns {string} The generated description. If numberOfOccurrences is the default, returns defaultText or an empty string.
 */
const getNumberOfOccurrencesFieldRTEData = (
  frequency: TransferFrequency,
  numberOfOccurrences: number = DEFAULT_NUMBER_OF_OCCURRENCE,
  defaultText?: string,
  lastDateMessage?: string,
): string => {
  if (numberOfOccurrences === DEFAULT_NUMBER_OF_OCCURRENCE) {
    return defaultText ?? '';
  }

  if (frequency === TransferFrequency.MONTHLY && lastDateMessage) {
    return lastDateMessage;
  }

  return '';
};

/**
 * If the Transfer frequency is MONTHLY, it returns the current date with numberOfOccurrences added to month
 *
 * @param {TransferFrequency} frequency - The transfer frequency.
 * @param {number} [withdrawalDayOfMonth] - The day of month.
 * @param {number} [numberOfOccurrences=DEFAULT_NUMBER_OF_OCCURRENCE] - The number of occurrences.

 * @returns {Record<string, React.ReactNode>}
 */
const getNumberOfOccurrencesFieldRTEConfig = (
  frequency: TransferFrequency,
  withdrawalDayOfMonth: number,
  numberOfOccurrences: number = DEFAULT_NUMBER_OF_OCCURRENCE,
): Record<string, React.ReactNode> => {
  if (frequency === TransferFrequency.MONTHLY && numberOfOccurrences !== DEFAULT_NUMBER_OF_OCCURRENCE) {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(withdrawalDayOfMonth);

    if (withdrawalDayOfMonth <= today.getDate()) {
      startDate.setMonth(startDate.getMonth() + 1);
    }

    return {
      lastDate: formatDateWithAddedMonths(startDate, numberOfOccurrences - 1, 'MM/dd/yyyy'),
    };
  }
  return {};
};

export const getNumberOfOccurrencesFieldRTEProps = (
  frequency: TransferFrequency,
  numberOfOccurrences: number = DEFAULT_NUMBER_OF_OCCURRENCE,
  withdrawalDayOfMonth?: number,
  defaultText?: string,
  lastDateMessage?: string,
) => {
  return {
    data: getNumberOfOccurrencesFieldRTEData(frequency, numberOfOccurrences, defaultText, lastDateMessage),
    config: getNumberOfOccurrencesFieldRTEConfig(
      frequency,
      withdrawalDayOfMonth ?? new Date().getDate(),
      numberOfOccurrences,
    ),
  };
};
