import { parseISO, startOfToday } from 'date-fns';
import { useEffect, useState } from 'react';

import { useAccountDetailsGetPerformance } from '../symphony';
import { getMeasurementForChart, getPerformanceMeasurements, getStartDateEndDateOfQuarter, Quarter } from '../utils';

import { PerformanceMeasurementInput, PerformanceMethodTypes, Scalars as SymphonyScalars } from '~/__generated__';
import { MeasurementName } from '~/hooks/performance/types';
import { toSymphonyDate } from '~/utils/symphony';

interface UseGetPerformanceChartDataVariables {
  accountId?: string;
  firstRebalancedOn?: SymphonyScalars['Date'];
  performanceMethod?: PerformanceMethodTypes;
  quarter?: string;
  selectedTimePeriod?: MeasurementName;
  syncedOn?: SymphonyScalars['DateTime'];
  upTo?: 'today' | 'syncedOn';
}

interface Data {
  accountPerformanceLoading: boolean;
  measurements: PerformanceMeasurementInput[];
  performanceChartData: Array<{ amount: number; date: SymphonyScalars['Date'] }>;
}

export const useGetPerformanceChartData = ({
  accountId,
  firstRebalancedOn,
  performanceMethod,
  quarter,
  selectedTimePeriod,
  syncedOn,
  upTo = 'today',
}: UseGetPerformanceChartDataVariables): Data => {
  const [quarterly, setQuarterly] = useState<{ from: string; to: string }>();

  const today = toSymphonyDate(startOfToday());
  // TODO this is a temp fix to ignore timestamp , will revert back once backend fix(ANR-7530) is ready.
  const timeIndex = syncedOn?.indexOf('T');
  const syncedOnDay = timeIndex ? syncedOn?.slice(0, timeIndex) : syncedOn;
  const measurements = firstRebalancedOn
    ? getPerformanceMeasurements({
        accountActivationDate: firstRebalancedOn,
        performanceMethod,
        quarterly,
        to: (upTo === 'syncedOn' ? syncedOnDay : today) ?? today,
      })
    : [];

  // Default the measurement to since inception if the selected time period is not found in the calculated performance measurements.
  // This fallback case should only be needed if the returns data is overridden by the partner repo.
  const measurementForChart = getMeasurementForChart(measurements, selectedTimePeriod);

  const { data: accountPerformanceData, loading: accountPerformanceLoading } = useAccountDetailsGetPerformance({
    variables: {
      accounts: [accountId ?? ''],
      measurements: measurementForChart,
    },
    skip: !accountId || !firstRebalancedOn || !measurementForChart.length,
    errorPolicy: 'all',
  });
  const performanceChartData =
    accountPerformanceData?.performance?.[0].valueChart.map(p => ({
      date: p.date,
      amount: parseFloat(p.value),
    })) ?? [];

  useEffect(() => {
    if (quarter) {
      const [selectedQuarter, year] = quarter.split(' ');
      const { startDate: fromDate, endDate: toDate } = getStartDateEndDateOfQuarter(selectedQuarter as Quarter, year);
      setQuarterly({
        from: firstRebalancedOn && parseISO(fromDate) < parseISO(firstRebalancedOn) ? firstRebalancedOn : fromDate,
        to: toDate,
      });
    }
  }, [firstRebalancedOn, quarter]);

  return {
    accountPerformanceLoading,
    measurements,
    performanceChartData,
  };
};
