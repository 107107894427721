import React from 'react';

import { useClientLedPlaidSuccessData } from './hooks/useClientLedPlaidSuccessData';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ChasePlaidInstitutionId, PlaidAccessTokenMetaData } from '~/hooks/plaid-link';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { useIsScreenSize } from '~/utils/responsiveness';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  handleConnectAccount: () => void;
  onClose: () => void;
  open: boolean;
  plaidMetaData?: PlaidAccessTokenMetaData;
}

export const ClientLedPlaidSuccessModal: React.FC<Props> = ({
  contentOptions,
  dataQa = 'client-led-plaid-success-modal',
  handleConnectAccount,
  open,
  onClose,
  plaidMetaData,
}) => {
  const isXSmallScreen = useIsScreenSize('xs');

  const { data, loading: dataLoading, error: dataError } = useClientLedPlaidSuccessData({ contentOptions });
  const content = data?.content;

  const {
    components: {
      sfFaPlaidFlow: { showProductImage },
    },
  } = useCoreConfig();

  return (
    <Modal
      actions={
        <Stack direction="row" justifyContent="space-between" width={1}>
          <Button data-qa={`${dataQa}-connect-another-account`} onClick={handleConnectAccount}>
            {content?.primaryCtaText ?? ''}
          </Button>
          <Button data-qa={`${dataQa}-close`} onClick={onClose} variant="outlined">
            {content?.secondaryCtaText ?? ''}
          </Button>
        </Stack>
      }
      content={
        content && (
          <Stack data-qa={dataQa} justifyContent="center">
            {showProductImage && content.productImgUrl && (
              <img alt="" height="28" src={content.productImgUrl} style={{ alignSelf: 'center' }} />
            )}
            <Box sx={{ textAlign: 'center', py: 1, mt: 12 }}>
              {content.successImgUrl && (
                <Box data-qa={`${dataQa}-image`} sx={{ pb: 10 }}>
                  <img alt="" src={content.successImgUrl} />
                </Box>
              )}
              <Typography variant="h5">{content.heading}</Typography>
              <Typography color="text.primary" sx={{ py: 2.5 }}>
                {content.subHeading}
              </Typography>
              {plaidMetaData?.institution.institutionId === ChasePlaidInstitutionId && (
                <Alert contentOptions={contentOptions} severity="warning">
                  <Typography align="left" variant="body2">
                    {content.chaseAccountWarning}
                  </Typography>
                </Alert>
              )}
            </Box>
          </Stack>
        )
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={dataError}
      fullScreen={isXSmallScreen}
      loading={dataLoading}
      onClose={onClose}
      open={open}
      title={content?.modalTitle}
    />
  );
};
