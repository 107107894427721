import {
  GetConfirmDiscardPendingApplicationModalContent,
  GetConfirmDiscardPendingApplicationModalContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export const useGetConfirmDiscardPendingApplicationModalContent = (
  options?: QueryHookOptions<
    GetConfirmDiscardPendingApplicationModalContent,
    GetConfirmDiscardPendingApplicationModalContentVariables
  >,
) => {
  return useContentstackQuery(queries.GetConfirmDiscardPendingApplicationModalContent, options);
};

export const useLazyGetConfirmDiscardPendingApplicationModalContent = (
  options?: QueryHookOptions<
    GetConfirmDiscardPendingApplicationModalContent,
    GetConfirmDiscardPendingApplicationModalContentVariables
  >,
) => {
  return useLazyContentstackQuery(queries.GetConfirmDiscardPendingApplicationModalContent, options);
};
