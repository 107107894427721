import React, { ComponentProps } from 'react';

import { useRiskPreferenceHistoryData } from './hooks';

import { BasicTable, TableComponent as TableComponentType } from '~/components/ui/BasicTable';
import { Modal } from '~/components/ui/Modal';
import { Button, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export type Props = ComponentProps<typeof Modal> & {
  TableComponent?: TableComponentType;
  accountId: string | null;
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onClose: () => void;
  open: boolean;
};

export const RiskPreferenceHistoryModal: React.FC<Props> = ({
  accountId,
  contentOptions,
  dataQa,
  managedProductId,
  onClose,
  open,
  TableComponent = BasicTable,
}) => {
  const { data, loading, error } = useRiskPreferenceHistoryData({
    contentOptions,
    open,
    managedProductId,
    accountId,
  });

  const {
    sfRiskPreferenceHistoryModal: { styles: sfRiskPreferenceHistoryModalStyles },
  } = useTheme<SfTheme>();

  const tableRowsData =
    data?.formattedRiskPreferenceHistoryDataValues.map((risk, idx) => ({
      rowKey: `${idx}`,
      value: <Typography>{risk.value}</Typography>,
      date: <Typography>{risk.date}</Typography>,
      isOverride: <Typography>{risk.isOverride}</Typography>,
      raisedBy: <Typography>{risk.raisedBy}</Typography>,
    })) ?? [];

  return (
    <Modal
      actions={
        <Button onClick={onClose} sx={sfRiskPreferenceHistoryModalStyles.cancelButton} variant="outlined">
          {data?.primaryCTA}
        </Button>
      }
      content={
        tableRowsData.length > 0 &&
        data?.tableColumns && <TableComponent alignItems="left" columns={data.tableColumns} data={tableRowsData} />
      }
      contentOptions={contentOptions}
      dataQa={dataQa}
      error={error}
      loading={loading}
      onClose={onClose}
      open={open}
      title={data?.title}
    />
  );
};
