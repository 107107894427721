import uniqueId from 'lodash.uniqueid';
import React, { FC } from 'react';

import { Paper, Stack, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';
import { formatPercentage } from '~/utils/format';

interface Content {
  goalPercent: string;
}

export interface Props {
  color?: string;
  content: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  goalPercent: number;
}

export const ProgressBar: FC<Props> = ({
  color,
  content,
  contentOptions: { locale },
  dataQa = 'goals-card-progress-bar',
  goalPercent,
}) => {
  const { palette } = useTheme();

  const projectedBarId = uniqueId(dataQa);
  const height = 32;
  const heightBar = 18;
  const width = 250;
  const widthProgress = Math.max((width * goalPercent) / 100, 0);
  const paperSx = { py: 0.5, px: 1, width: 'fit-content' };

  const getStatusColor = (value: number) => {
    if (value < 25) {
      return palette.error.main;
    } else if (value < 60) {
      return palette.warning.main;
    } else if (value < 80) {
      return palette.success.main;
    }
    return palette.success.dark;
  };

  return (
    <Stack alignItems="end" data-qa={dataQa}>
      <svg height={height} viewBox={`0 0 ${width} ${height}`} width={width} xmlns="http://www.w3.org/2000/svg">
        <pattern
          height="10"
          id={projectedBarId}
          patternTransform="rotate(-35 0 0)"
          patternUnits="userSpaceOnUse"
          width="5"
        >
          <rect fill={palette.grey[200]} height="10" width="10" x="0" />
          <line stroke={palette.grey[400]} strokeWidth={2} x1="0" x2="0" y1="0" y2="10" />
        </pattern>
        <rect fill={`url(#${projectedBarId})`} height={heightBar} rx="2" width={width} x={0} />
        <rect fill={color ?? getStatusColor(goalPercent)} height={heightBar} rx="2" width={widthProgress} x={0} />
        <rect
          fill={palette.text.primary}
          height={height}
          width={2}
          x={widthProgress - (widthProgress === width ? 2 : 0)}
        />
      </svg>

      <Paper data-qa={`${dataQa}-goal-percent`} elevation={1} sx={paperSx}>
        <RteContent
          config={{ value: formatPercentage(goalPercent / 100, { decimals: 0, locale }) }}
          data={content.goalPercent}
        />
      </Paper>
    </Stack>
  );
};
