import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';

import { FaPlaidFlow } from '../../FaPlaidFlow';
import { ClientInitiatePlaidModal } from '../ClientInitiatePlaid';
import { ClientLedPlaidSuccessModal } from '../ClientLedPlaidSuccess';
import { PlaidReAuthentication } from '../PlaidReAuthentication';

import { CloseAccountModalContent } from './contentstack';
import { ClosingOptions, FormData, getClosingReasonOptions, getFooter } from './utils';

import { PlaidProduct, RequestCloseInput } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownWithEditableOptions } from '~/components/ui/Dropdown/DropdownWithEditableOptions';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Link } from '~/components/ui/Link';
import { useModalState } from '~/components/ui/Modal/hooks';
import { AlertTitle, Box, Grid, InputLabel, ToggleButton, ToggleButtonGroup, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { useGetAddAccountSuccess } from '~/hooks/account/useGetAddAccountSuccess';
import { PlaidAccessTokenMetaData, PlaidLinkType, usePlaidLinkHook } from '~/hooks/plaid-link';
import {
  BankAccount,
  getAddAccountActions,
  getIsBankAccountAssociationNonVerified,
  getPlaidItemIdIfReAuthenticateFails,
} from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';

export const eligibleDestinationBankAccountName = 'eligibleDestinationBankAccount';
export const closingReasonName = 'closingReason';
export const shouldSellAssetsName = 'shouldSellAssets';

export interface Props {
  bankAccountOptions: DropdownItem[];
  bankAccounts?: BankAccount[];
  closeRequest?: RequestCloseInput;
  content?: CloseAccountModalContent;
  contentOptions: ContentOptions;
  formHooks: UseFormMethods<FormData>;
  isRefetchingBankAccounts?: boolean;
  linkBankAccountOpen?: boolean;
  linkBankAccountOpenModal: () => void;
  linkedBankAccount?: string | null;
  managedProductId: string;
  partyId: string;
  partyIdFA?: string;
  plaidLinkageType?: PlaidLinkType;
  refetchBankAccounts?: () => void;
  selectedAccountForEdit?: BankAccount;
  selectedClosingOption?: ClosingOptions;
  setDisableButton?: Dispatch<SetStateAction<boolean>>;
  showShouldSellAssets: boolean;
  symphonySubmitError?: Error;
}

export const CloseAccountForm: React.FC<Props> = ({
  bankAccountOptions,
  bankAccounts,
  closeRequest,
  content,
  contentOptions,
  formHooks,
  isRefetchingBankAccounts = false,
  linkBankAccountOpen,
  linkBankAccountOpenModal,
  linkedBankAccount,
  managedProductId,
  partyId,
  partyIdFA,
  plaidLinkageType,
  refetchBankAccounts,
  selectedAccountForEdit,
  selectedClosingOption,
  setDisableButton,
  symphonySubmitError,
  showShouldSellAssets,
}) => {
  const {
    sfCloseAccountModal: { styles },
    palette,
  } = useTheme();
  const {
    allowEditManuallyLinkedBankAccounts,
    allowPlaidReAuthentication,
    isManualLinkageForBankAccountSupported,
    isPlaidLinkageForBankAccountSupported,
    showWarningForNonVerifiedFinancialAccountLinkages,
  } = useCoreConfig().featureFlags;
  const [sellAssets, setSellAssets] = useState<boolean>();
  const [openPlaidOtpVerification, setOpenPlaidOtpVerification] = useState(false);
  const [showVerificationWarningMessage, setShowVerificationWarningMessage] = useState(false);
  const [plaidMetaData, setPlaidMetaData] = useState<PlaidAccessTokenMetaData | undefined>(undefined);
  const [defaultBankAccountValue, setDefaultBankAccountValue] = useState(
    bankAccountOptions[0]?.value?.toString() ?? undefined,
  );

  const { open: isOpenPlaidLinkModal, openModal: openPlaidLinkModal, onClose: onClosePlaidLinkModal } = useModalState();
  const {
    open: isOpenClientPlaidLinkSuccessModal,
    openModal: openClientPlaidLinkSuccessModal,
    onClose: onCloseClientPlaidLinkSuccessModal,
  } = useModalState();
  const {
    open: isPlaidReAuthenticationModalOpen,
    openModal: openPlaidReAuthenticatioModal,
    onClose: closePlaidReAuthenticationModal,
  } = useModalState();

  const handleSuccessPlaidLink = (metaData: PlaidAccessTokenMetaData) => {
    setPlaidMetaData(metaData);
    openClientPlaidLinkSuccessModal();
  };
  const { handleOpenPlaidLink } = usePlaidLinkHook(undefined, undefined, handleSuccessPlaidLink);
  const { control, errors: fieldsErrors, setValue, watch } = formHooks;
  const selectedBankAccountValue = watch(eligibleDestinationBankAccountName);

  const handleClosePlaidLinkSuccessModal = () => {
    refetchBankAccounts?.();
    onCloseClientPlaidLinkSuccessModal();
  };
  const handleShouldSellAssetsChange = (selection: boolean) => {
    setSellAssets(selection);
    setValue(shouldSellAssetsName, selection, { shouldValidate: true, shouldDirty: true });
  };

  useEffect(() => {
    if (linkedBankAccount && !symphonySubmitError) {
      setValue(eligibleDestinationBankAccountName, linkedBankAccount);
    }
  }, [linkedBankAccount, symphonySubmitError]);

  useEffect(() => {
    handleShouldSellAssetsChange(closeRequest?.sellAssets ?? false);
    if (closeRequest?.reason) {
      setValue(closingReasonName, closeRequest.reason, { shouldValidate: true, shouldDirty: true });
    }
    if (closeRequest?.transferToFinancialAccountId && !symphonySubmitError) {
      setValue(eligibleDestinationBankAccountName, closeRequest.transferToFinancialAccountId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [closeRequest, symphonySubmitError]);

  const handleCheckAccountVerification = (accountId: string) => {
    if (bankAccounts && showWarningForNonVerifiedFinancialAccountLinkages) {
      setShowVerificationWarningMessage(
        getIsBankAccountAssociationNonVerified(accountId, bankAccounts, managedProductId),
      );
    }
  };
  useEffect(() => {
    if (selectedAccountForEdit && !linkBankAccountOpen) {
      linkBankAccountOpenModal();
    }
  }, [linkBankAccountOpen, linkBankAccountOpenModal, selectedAccountForEdit]);

  const plaidItemIdIfReAuthenticateFails = useMemo(() => {
    if (selectedBankAccountValue && bankAccounts?.length) {
      return getPlaidItemIdIfReAuthenticateFails(bankAccounts, selectedBankAccountValue);
    }
    return undefined;
  }, [bankAccounts, selectedBankAccountValue]);

  const selectedFinancialInstitution = useMemo(
    () => bankAccounts?.find(account => account.id === selectedBankAccountValue)?.financialInstitution,
    [bankAccounts, selectedBankAccountValue],
  );

  const showPlaidReAuthentication = allowPlaidReAuthentication && plaidItemIdIfReAuthenticateFails;

  const {
    isAccountLinkageSuccessful: isBankLinkageSuccessful,
    reset: resetBankLinkageSuccessful,
    set: setBankAccountsCount,
  } = useGetAddAccountSuccess(bankAccounts);

  const handleRefetchAccounts = () => {
    resetBankLinkageSuccessful();
    setBankAccountsCount();
    refetchBankAccounts?.();
  };

  // Trigger refetching list of bank accounts whenever we have a symphony submit error to remove unverified accounts.
  useEffect(() => {
    refetchBankAccounts?.();
    if (symphonySubmitError) {
      setValue(eligibleDestinationBankAccountName, undefined);
    }
  }, [symphonySubmitError]);

  const handleCloseFaPlaidFlow = () => {
    setOpenPlaidOtpVerification(false);
    resetBankLinkageSuccessful();
  };

  useEffect(() => {
    if (selectedBankAccountValue) {
      setDefaultBankAccountValue(selectedBankAccountValue);
    } else if (bankAccountOptions.length && !defaultBankAccountValue) {
      setDefaultBankAccountValue(bankAccountOptions[0].value.toString());
    }
    if (!selectedBankAccountValue && selectedClosingOption === ClosingOptions.WITHDRAW_AND_CLOSE) {
      setDisableButton?.(true);
    } else {
      setDisableButton?.(!!plaidItemIdIfReAuthenticateFails);
    }
  }, [bankAccountOptions, defaultBankAccountValue, selectedBankAccountValue, plaidItemIdIfReAuthenticateFails]);

  // This useEffect hook checks the verification status of defaultBankAccountValue everytime it changes.
  useEffect(() => {
    if (defaultBankAccountValue && bankAccounts && showWarningForNonVerifiedFinancialAccountLinkages) {
      setShowVerificationWarningMessage(
        getIsBankAccountAssociationNonVerified(defaultBankAccountValue, bankAccounts, managedProductId),
      );
    }
  }, [bankAccounts, defaultBankAccountValue, managedProductId]);

  const getClosingReasonDropdown = () => (
    <Controller
      control={control}
      defaultValue={control.getValues(closingReasonName) ?? ''}
      name={closingReasonName}
      render={({ value, name, onBlur, onChange }) => (
        <Dropdown
          description={fieldsErrors.closingReason ? content?.errors?.closing_reason_required ?? '' : undefined}
          error={!!fieldsErrors.closingReason}
          id={closingReasonName}
          inputLabelProps={{ sx: styles.inputLabel }}
          items={getClosingReasonOptions(content?.closing_reasons ?? [])}
          label={content?.closing_reason_dropdown_label}
          name={name}
          onBlur={onBlur}
          onChange={e => onChange(e.target.value)}
          placeholder={content?.closing_reason_placeholder ?? ''}
          value={value}
          width="100%"
        />
      )}
      rules={{
        required: true,
      }}
    />
  );

  const handleAccountDropdownChange = (
    eventValue: string | number | (string | number)[],
    onChange: (...value: any[]) => void,
  ) => {
    if (eventValue === 'link-account-action') {
      linkBankAccountOpenModal();
    } else if (eventValue === 'link-plaid-account-action') {
      if (plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR) {
        setOpenPlaidOtpVerification(true);
      } else if (plaidLinkageType === PlaidLinkType.CLIENT) {
        openPlaidLinkModal();
      }
    } else {
      onChange(eventValue);
      if (showWarningForNonVerifiedFinancialAccountLinkages) {
        handleCheckAccountVerification(eventValue.toString());
      }
    }
  };

  const bankAccountItems = [
    ...bankAccountOptions,
    ...getAddAccountActions({
      isManualLinkageForAccountSupported: isManualLinkageForBankAccountSupported,
      isPlaidLinkageForAccountSupported: isPlaidLinkageForBankAccountSupported,
      manualLinkText: content?.link_bank_account,
      plaidLinkText: content?.link_plaid_bank_account,
    }),
  ];

  return (
    <>
      {selectedClosingOption === ClosingOptions.WITHDRAW_AND_CLOSE ? (
        <Box>
          <RteContent data={content?.withdraw_and_close?.message ?? ''} sx={{ mb: 4 }} />
          <Controller
            control={control}
            defaultValue={defaultBankAccountValue}
            name={eligibleDestinationBankAccountName}
            render={({ value, name, onBlur, onChange }) =>
              allowEditManuallyLinkedBankAccounts ? (
                <DropdownWithEditableOptions
                  defaultValue={value}
                  disabled={isRefetchingBankAccounts}
                  error={!!fieldsErrors.eligibleDestinationBankAccount}
                  id={eligibleDestinationBankAccountName}
                  items={[
                    ...bankAccountItems,
                    {
                      label: content?.labels?.find(label => label?.keys === 'bankSourceCloseCta')?.value ?? '',
                      type: 'list-item-button',
                      variant: 'outlined',
                    },
                  ]}
                  label={content?.account_dropdown_label}
                  onChange={item => {
                    handleAccountDropdownChange(item.value, onChange);
                  }}
                />
              ) : (
                <Dropdown
                  disabled={isRefetchingBankAccounts}
                  error={!!fieldsErrors.eligibleDestinationBankAccount}
                  id={eligibleDestinationBankAccountName}
                  inputLabelProps={{ sx: styles.inputLabel }}
                  items={bankAccountItems}
                  label={content?.account_dropdown_label}
                  name={name}
                  onBlur={onBlur}
                  onChange={event => {
                    handleAccountDropdownChange(event.target.value, onChange);
                  }}
                  value={value}
                  width="100%"
                />
              )
            }
            rules={{
              required: true,
            }}
          />
          {showVerificationWarningMessage && content?.verification_warning && (
            <Alert severity="warning" sx={{ my: 2 }}>
              <RteContent data={content.verification_warning} />
            </Alert>
          )}
          {showPlaidReAuthentication && (
            <Alert data-qa="re-authentication-failed-error" severity="error">
              <RteContent
                config={{
                  reAuthenticateLink: (
                    <AlertTitle>
                      <Link
                        data-qa="re-authenticat-error-link"
                        onClick={openPlaidReAuthenticatioModal}
                        sx={{ color: 'error.main', textDecorationColor: palette.error.main }}
                        variant="subtitle2"
                      >
                        {content?.labels?.find(label => label?.keys === 'plaidReAuthenticaionLink')?.value}
                      </Link>
                    </AlertTitle>
                  ),
                }}
                data={findFieldValue(content?.rte_fields ?? [], 'plaidReAuthenticaion')}
              />
            </Alert>
          )}
          <Alert severity="warning" sx={{ mb: 6, mt: 2 }}>
            {content?.warning_message}
          </Alert>
          {getClosingReasonDropdown()}
          {symphonySubmitError && <Alert error={symphonySubmitError} severity="error" sx={{ mt: 2 }} />}
          {isPlaidLinkageForBankAccountSupported && (
            <>
              {plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR && (
                <FaPlaidFlow
                  contentOptions={contentOptions}
                  isAccountLinkageSuccessful={!!isBankLinkageSuccessful}
                  isPlaidFlowLaunched={openPlaidOtpVerification}
                  onCloseModalCallback={handleCloseFaPlaidFlow}
                  partyId={partyId}
                  plaidProducts={PlaidProduct.AUTH}
                  refetchAccounts={handleRefetchAccounts}
                />
              )}
              {plaidLinkageType === PlaidLinkType.CLIENT && (
                <>
                  <ClientInitiatePlaidModal
                    connectAccount={() =>
                      handleOpenPlaidLink(partyId, [PlaidProduct.AUTH]).then(_ => onClosePlaidLinkModal())
                    }
                    contentOptions={contentOptions}
                    onClose={onClosePlaidLinkModal}
                    open={isOpenPlaidLinkModal}
                  />
                  <ClientLedPlaidSuccessModal
                    contentOptions={contentOptions}
                    handleConnectAccount={() => handleOpenPlaidLink(partyId, [PlaidProduct.AUTH])}
                    onClose={handleClosePlaidLinkSuccessModal}
                    open={isOpenClientPlaidLinkSuccessModal}
                    plaidMetaData={plaidMetaData}
                  />
                </>
              )}
            </>
          )}
          {getFooter(content)}
        </Box>
      ) : (
        <Grid container display="block" rowSpacing={4}>
          <Grid item>
            <Alert severity="warning">
              <RteContent data={content?.end_management?.message ?? ''} />
            </Alert>
          </Grid>
          {showShouldSellAssets && (
            <Grid item sx={{ '.MuiToggleButtonGroup-root': { width: '100%' } }}>
              <InputLabel sx={styles.inputLabel}>{content?.should_sell_assets?.label}</InputLabel>
              <Controller
                control={control}
                defaultValue={false}
                name={shouldSellAssetsName}
                render={() => (
                  <ToggleButtonGroup
                    exclusive
                    id={shouldSellAssetsName}
                    onChange={(_event, selection) => handleShouldSellAssetsChange(selection)}
                    value={sellAssets}
                  >
                    <ToggleButton sx={{ flexGrow: 1 }} value={false}>
                      {content?.should_sell_assets?.no}
                    </ToggleButton>
                    <ToggleButton sx={{ flexGrow: 1 }} value>
                      {content?.should_sell_assets?.yes}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                rules={{
                  validate: value => {
                    if (value === null) {
                      return '';
                    }
                    return true;
                  },
                }}
              />
            </Grid>
          )}
          {sellAssets && content?.warning_message && (
            <Grid item>
              <Alert severity="warning">{content.warning_message}</Alert>
            </Grid>
          )}
          <Grid item>{getClosingReasonDropdown()}</Grid>
        </Grid>
      )}
      {showPlaidReAuthentication && selectedBankAccountValue && selectedFinancialInstitution && (
        <PlaidReAuthentication
          contentOptions={contentOptions}
          financialInstitution={selectedFinancialInstitution}
          onAuthenticationSuccess={handleRefetchAccounts}
          onClose={closePlaidReAuthenticationModal}
          open={isPlaidReAuthenticationModalOpen}
          partyId={partyId}
          partyIdFA={partyIdFA}
          plaidItemId={plaidItemIdIfReAuthenticateFails}
          plaidLinkageType={plaidLinkageType}
        />
      )}
    </>
  );
};
