import { useMemo } from 'react';

import { useGetPlaidReAuthenticateContent } from '../../contentstack';

import { ContentOptions, findFieldValue, getImageAssetUrl } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
  open: boolean;
}

interface Data {
  heading: string;
  imageUrl: string;
  primaryCtaLabel: string;
  secondaryCtaLabel: string;
  title: string;
}

export const useGetPlaidReAuthenticationData = ({ contentOptions, open }: Variables): AsyncResult<Data> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetPlaidReAuthenticateContent({
    variables: contentOptions,
    skip: !open,
  });

  const data = useMemo(() => {
    if (contentLoading || contentError) {
      return;
    }

    const fieldContent = contentData?.all_plaid_link_launch_container?.items?.[0]?.fields;

    const textContent = fieldContent?.text ?? [];
    return {
      heading: findFieldValue(textContent, 'ReAuthenticationHeading'),
      imageUrl: getImageAssetUrl(
        fieldContent?.image?.find(img => img?.key === 'ReAuthenticationImage')?.imageConnection,
      ),
      primaryCtaLabel: findFieldValue(textContent, 'ReAuthenticationPrimaryCta'),
      secondaryCtaLabel: findFieldValue(textContent, 'ReAuthenticationSecondaryCta'),
      title: findFieldValue(textContent, 'ReAuthenticationTitle'),
    };
  }, [contentData?.all_plaid_link_launch_container?.items, contentError, contentLoading]);

  return { data, loading: contentLoading, error: contentError };
};
