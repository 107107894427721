import React from 'react';

import { CircularProgress } from '~/components/ui/CircularProgress';
import { Stack } from '~/components/ui/mui';

export const LoadingPage: React.FC = () => {
  return (
    <Stack sx={{ alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
      <CircularProgress disableShrink />
    </Stack>
  );
};
