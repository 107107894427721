import { CancelTransfer, CancelTransferVariables } from './__generated__/mutation.v2';
import {
  GetTransfers,
  GetTransfers_client_financialAccounts_products,
  GetTransfers_client_financialAccounts_products_ManagedProduct,
  GetTransfers_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers,
  GetTransfers_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers_CashTransfer,
  GetTransfers_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers_CashTransfer_attributes,
  GetTransfers_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers_CashTransfer_bankAccount,
  GetTransfersVariables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, QueryHookOptions } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ScheduledTransfer = GetTransfers_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers;
export type CashTransfer = GetTransfers_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers_CashTransfer;
export type TransferAttribute = GetTransfers_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers_CashTransfer_attributes;

export const isCashTransfer = (transfer: ScheduledTransfer): transfer is CashTransfer =>
  transfer.__typename === 'CashTransfer';

export type Product = GetTransfers_client_financialAccounts_products;
export type ManagedProduct = GetTransfers_client_financialAccounts_products_ManagedProduct;

export const isManagedProduct = (product: Product): product is ManagedProduct =>
  product.__typename === 'ManagedProduct';

export const useGetTransfers = (options?: QueryHookOptions<GetTransfers, GetTransfersVariables>) => {
  return useSymphonyQuery(queries.GetTransfers, options);
};

export const useLazyGetTransfers = (options?: QueryHookOptions<GetTransfers, GetTransfersVariables>) => {
  return useLazySymphonyQuery(queries.GetTransfers, options);
};

export const useCancelTransfer = (options?: MutationHookOptions<CancelTransfer, CancelTransferVariables>) => {
  return useSymphonyMutation(mutations.CancelTransfer, options);
};

export type BankAccount = GetTransfers_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers_CashTransfer_bankAccount;
