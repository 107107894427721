import React, { ComponentProps, useCallback } from 'react';

import { useGetConfirmDiscardPendingApplicationModalContent } from './contentstack';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Button, LoadingButton } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { useAbandonDigitalWealth } from '~/hooks/onboarding/useAbandonDigitalWealth';
import { ContentOptions } from '~/utils/contentstack';

export interface Props extends Omit<ComponentProps<typeof Modal>, 'title' | 'content'> {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onCancel: () => void;
  onDiscardSuccess: () => void;
  partyId: string;
}

export const ConfirmDiscardPendingApplicationModal: React.FC<Props> = ({
  contentOptions,
  dataQa = 'confirm-discard-pending-application-modal',
  managedProductId,
  open,
  onCancel,
  onDiscardSuccess,
  partyId,
  ...modalProps
}) => {
  const { data, loading, error } = useGetConfirmDiscardPendingApplicationModalContent({
    variables: contentOptions,
  });
  const content = data?.all_discard_pending_application_modal?.items?.[0];

  // TODO: update cache instead of refetching DA2-5405
  const [abandonDigitalWealth, { error: abandonError, loading: abandonLoading }] = useAbandonDigitalWealth({
    partyId,
    refetchQueries: ['AccountSummaryGetDigitalWealthAccounts', 'GetClientDigitalWealthAccounts'],
  });

  const onDiscard = useCallback(async () => {
    try {
      await abandonDigitalWealth(managedProductId);
      onDiscardSuccess();
    } catch (err: any) {
      console.error('Error occurred discarding application:', managedProductId, err);
    }
  }, [managedProductId, abandonDigitalWealth, onDiscardSuccess]);

  return (
    <Modal
      {...modalProps}
      actions={
        content?.ctas && (
          <>
            <Button disabled={abandonLoading} onClick={onCancel} variant="outlined">
              {content.ctas.cancel}
            </Button>
            <LoadingButton loading={abandonLoading} onClick={onDiscard} variant="contained">
              {content.ctas.discard}
            </LoadingButton>
          </>
        )
      }
      content={
        <>
          <RteContent
            data={content?.body || 'MISSING CONFIRM NEW ACCOUNT MODAL BODY'}
            sx={{ '& h1, h2, h3, h4, h5, h6': { mb: 3 } }}
          />
          {abandonError && <Alert contentOptions={contentOptions} error={abandonError} severity="error" />}
        </>
      }
      contentOptions={contentOptions}
      dataQa={dataQa}
      error={error}
      loading={loading}
      open={open}
      title={!loading && !error && content?.heading}
    />
  );
};
