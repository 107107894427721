import { parseISO } from 'date-fns';
import React from 'react';

import { SuspensionsHistoryTabContent } from '../contentstack';
import { TradingSuspensionsHistory } from '../symphony';

import { Field } from '~/__generated__';
import { TableData } from '~/components/ui/BasicTable';
import { Chip } from '~/components/ui/Chip';
import { Typography } from '~/components/ui/Typography';
import { getFullName } from '~/hooks/party/utils';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

export enum SuspensionHistoryTableColumns {
  AddedBy = 'AddedBy',
  DateAdded = 'DateAdded',
  DateRemoved = 'DateRemoved',
  RemovedBy = 'RemovedBy',
  SuspensionTagColumn = 'SuspensionTag',
  SuspensionTypeColumn = 'SuspensionType',
}

export const SortFields: Record<string, Field> = {
  [SuspensionHistoryTableColumns.DateAdded]: Field.DATE_ADDED,
  [SuspensionHistoryTableColumns.DateRemoved]: Field.DATE_REMOVED,
};

export interface SuspensionRowData {
  addedBy?: string;
  dateAdded?: string;
  dateRemoved?: string;
  id: string;
  removedBy?: string;
  suspensionTag?: string;
  suspensionTagColor?: string;
  suspensionType?: string;
}

export interface Content {
  columnHeaderLabels: Record<SuspensionHistoryTableColumns, string>;
  otherSuspensionTag: string;
}

export const getSuspensionHistoryContent = (data: SuspensionsHistoryTabContent | null | undefined): Content => {
  const content = data?.all_trading_suspensions_history?.items?.[0];
  const textFields = content?.fields?.text ?? [];

  return {
    columnHeaderLabels: {
      [SuspensionHistoryTableColumns.AddedBy]: findFieldValue(textFields, 'added_by_column'),
      [SuspensionHistoryTableColumns.DateAdded]: findFieldValue(textFields, 'date_added_column'),
      [SuspensionHistoryTableColumns.RemovedBy]: findFieldValue(textFields, 'removed_by_column'),
      [SuspensionHistoryTableColumns.DateRemoved]: findFieldValue(textFields, 'date_removed_column'),
      [SuspensionHistoryTableColumns.SuspensionTypeColumn]: findFieldValue(textFields, 'suspension_type_column'),
      [SuspensionHistoryTableColumns.SuspensionTagColumn]: findFieldValue(textFields, 'suspension_tag_column'),
    },
    otherSuspensionTag: findFieldValue(textFields, 'other_suspension_tag'),
  };
};

export const getColumns = (content: Content, onSort: (field: string) => () => void) => [
  {
    key: SuspensionHistoryTableColumns.DateAdded,
    onSort,
    title: content.columnHeaderLabels[SuspensionHistoryTableColumns.DateAdded],
  },
  {
    key: SuspensionHistoryTableColumns.AddedBy,
    title: content.columnHeaderLabels[SuspensionHistoryTableColumns.AddedBy],
  },
  {
    key: SuspensionHistoryTableColumns.DateRemoved,
    onSort,
    title: content.columnHeaderLabels[SuspensionHistoryTableColumns.DateRemoved],
  },
  {
    key: SuspensionHistoryTableColumns.RemovedBy,
    title: content.columnHeaderLabels[SuspensionHistoryTableColumns.RemovedBy],
  },
  {
    key: SuspensionHistoryTableColumns.SuspensionTypeColumn,
    title: content.columnHeaderLabels[SuspensionHistoryTableColumns.SuspensionTypeColumn],
  },
  {
    key: SuspensionHistoryTableColumns.SuspensionTagColumn,
    title: content.columnHeaderLabels[SuspensionHistoryTableColumns.SuspensionTagColumn],
  },
];

export const getSuspensionRowData = (
  suspension: TradingSuspensionsHistory,
  content: Content,
  contentOptions: ContentOptions,
  suspensionTagStyleMap: Record<string, string> | undefined,
): SuspensionRowData => {
  return {
    addedBy: getFullName(suspension.addedBy?.partyPerson),
    dateAdded: formatDate(parseISO(suspension.dateAdded), 'MM/dd/yyyy', {
      locale: contentOptions.locale,
    }),
    removedBy: getFullName(suspension.removedBy?.partyPerson) ?? '-',
    dateRemoved: suspension.dateRemoved
      ? formatDate(parseISO(suspension.dateRemoved), 'MM/dd/yyyy', {
          locale: contentOptions.locale,
        })
      : '-',
    id: `${suspension.addedSuspensionId}-${suspension.removedSuspensionId}`,
    suspensionTag: suspension.suspensionTag ? suspension.suspensionTag : content.otherSuspensionTag,
    suspensionTagColor: suspension.suspensionTag
      ? suspensionTagStyleMap?.[suspension.suspensionTag.toLowerCase()]
      : undefined,
    suspensionType: suspension.suspensionType,
  };
};

export const getFormattedRows = ({
  content,
  contentOptions,
  suspensionData,
  suspensionTagStyleMap,
}: {
  content: Content;
  contentOptions: ContentOptions;
  suspensionData: TradingSuspensionsHistory[];
  suspensionTagStyleMap: Record<string, string> | undefined;
}): TableData[] | undefined =>
  suspensionData.map(data => {
    const suspension = getSuspensionRowData(data, content, contentOptions, suspensionTagStyleMap);

    return {
      rowKey: suspension.id,
      [SuspensionHistoryTableColumns.DateAdded]: <Typography variant="body2">{suspension.dateAdded}</Typography>,
      [SuspensionHistoryTableColumns.AddedBy]: <Typography variant="body2">{suspension.addedBy}</Typography>,
      [SuspensionHistoryTableColumns.DateRemoved]: <Typography variant="body2">{suspension.dateRemoved}</Typography>,
      [SuspensionHistoryTableColumns.RemovedBy]: <Typography variant="body2">{suspension.removedBy}</Typography>,
      [SuspensionHistoryTableColumns.SuspensionTypeColumn]: (
        <Typography variant="body2">{suspension.suspensionType}</Typography>
      ),
      [SuspensionHistoryTableColumns.SuspensionTagColumn]: suspension.suspensionTag && (
        <Chip
          label={suspension.suspensionTag}
          sx={{ backgroundColor: suspension.suspensionTagColor, borderRadius: 1, width: 104 }}
          variant="filled"
        />
      ),
    };
  });
