import React, { useContext } from 'react';

import OpsDashboardContext from '../../OpsDashboardContext';

import { FlagEntity, FlagReason, FlagStatus, Scalars as SymphonyScalars } from '~/__generated__';
import { CreateFlagModal } from '~/components/modals/CreateFlag';
import { ResolveFlagModal } from '~/components/modals/ResolveFlag';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, useTheme } from '~/components/ui/mui';
import { FlagsReferenceContent } from '~/containers/OpsDashboard/WithdrawalsTab/contentstack';
import { ContentOptions } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export interface FlagAbstract {
  created: SymphonyScalars['DateTime'];
  description: string | null;
  entityIdentifier: string;
  id: number;
  lastUpdated: SymphonyScalars['DateTime'];
  reason: FlagReason;
  status: FlagStatus;
}

interface FlagActionProps {
  accountId: string;
  accountTypeText: string;
  clientName: string;
  contentOptions: ContentOptions;
  entity: FlagEntity;
  entityId: string;
  flagReasons: FlagReason[];
  flags: FlagAbstract[];
  flagsContent?: FlagsReferenceContent;
  onCreate: () => void;
  onResolve: () => void;
}

export const FlagAction: React.FC<FlagActionProps> = ({
  flags,
  flagsContent,
  accountId,
  accountTypeText,
  clientName,
  contentOptions,
  entity,
  entityId,
  flagReasons,
  onCreate,
  onResolve,
}) => {
  const { hiddenFlagReasons } = useContext(OpsDashboardContext);
  const openFlags = flags.filter(flag => flag.status === FlagStatus.OPEN);
  const {
    sfOpsDashboard: { styles: sfOpsDashboardStyles },
  } = useTheme<SfTheme>();

  const createFlagModal = useModalState();
  const resolveFlagModal = useModalState();
  const handleClick = () => {
    if (openFlags.length > 0) {
      resolveFlagModal.openModal();
    } else {
      createFlagModal.openModal();
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}>
        <img
          onClick={handleClick}
          src={
            openFlags.length > 0
              ? (flagsContent?.flag_icons?.flag_filledConnection?.edges?.[0]?.node?.url as string)
              : (flagsContent?.flag_icons?.flag_outlinedConnection?.edges?.[0]?.node?.url as string)
          }
          style={sfOpsDashboardStyles.actionIcons}
        />
      </Box>
      <CreateFlagModal
        accountId={accountId}
        accountTypeText={accountTypeText}
        clientName={clientName}
        contentOptions={contentOptions}
        entity={entity}
        entityId={entityId}
        flagReasons={flagReasons.filter(r => !hiddenFlagReasons.includes(r))}
        onClose={() => createFlagModal.onClose()}
        onCreate={onCreate}
        open={createFlagModal.open}
      />
      <ResolveFlagModal
        accountId={accountId}
        accountTypeText={accountTypeText}
        clientName={clientName}
        contentOptions={contentOptions}
        flags={openFlags}
        onClose={() => resolveFlagModal.onClose()}
        onResolve={onResolve}
        open={resolveFlagModal.open}
      />
    </>
  );
};
