import { GetAllStatusChangeContent, GetAllStatusChangeContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetAllStatusChangeContent = (
  options?: QueryHookOptions<GetAllStatusChangeContent, GetAllStatusChangeContentVariables>,
): QueryResult<GetAllStatusChangeContent, GetAllStatusChangeContentVariables> => {
  return useContentstackQuery(queries.GetAllStatusChangeContent, options);
};
