import { useMemo } from 'react';

import { useGetClientInfo } from './symphony';

import { Scalars as SymphonyScalars } from '~/__generated__';
import { getEmail, getFullName, getPhone, getUserName } from '~/utils/client';
import { AsyncResult } from '~/utils/types';

interface Data {
  birthDate?: SymphonyScalars['Date'];
  email: string;
  fullName?: string;
  phone: string;
  userName: string;
}

export const useClientInfo = (options?: Parameters<typeof useGetClientInfo>[0]): AsyncResult<Data> => {
  const { data: clientInfoData, error, loading } = useGetClientInfo(options);
  const party = clientInfoData?.client?.party;

  const data: Data | undefined = useMemo(() => {
    if (error || loading || !party) {
      return;
    }

    return {
      birthDate: party.partyPerson?.birthDate ?? undefined,
      email: getEmail(party),
      fullName: getFullName(party.partyPerson),
      phone: getPhone(party),
      userName: getUserName(clientInfoData),
    };
  }, [clientInfoData, error, loading, party]);

  return { data, error, loading };
};
