import { GetRecommendedGoalsContent, GetRecommendedGoalsContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetRecommendedGoalsContent = (
  options?: QueryHookOptions<GetRecommendedGoalsContent, GetRecommendedGoalsContentVariables>,
): QueryResult<GetRecommendedGoalsContent, GetRecommendedGoalsContentVariables> => {
  return useContentstackQuery(queries.GetRecommendedGoalsContent, options);
};
