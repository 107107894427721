import { Dispatch, SetStateAction, useState } from 'react';

import { ModalStateData, useModalState } from '~/components/ui/Modal/hooks';

export interface AddFundsData {
  depositAmount: [string, Dispatch<SetStateAction<string>>];
  modal: ModalStateData;
  sourceAccountId: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
}

export const useAddFunds = (): AddFundsData => {
  const sourceAccountId = useState<string | undefined>();
  const depositAmount = useState<string>('');
  const modal = useModalState();

  return {
    sourceAccountId,
    depositAmount,
    modal,
  };
};

export const useAccountActionModals = () => {
  const addFunds = useAddFunds();
  const addRestrictionsModal = useModalState();
  const viewClientDocumentsModal = useModalState();
  const viewDocumentsModal = useModalState();
  const viewRiskPreferenceHistoryModal = useModalState();
  const viewPricingModal = useModalState();
  const viewTransfersModal = useModalState();
  const withdrawFundsModal = useModalState();
  const closeAccountModal = useModalState();
  const suspendBillingModal = useModalState();
  const suspendTradingModal = useModalState();
  const editAccountModal = useModalState();
  const tlhModal = useModalState();
  const otherActionsModal = useModalState();
  const retakeQuestionnaireModal = useModalState();
  const switchWithinAnAccountModal = useModalState();

  return {
    addFunds,
    viewClientDocuments: {
      modal: viewClientDocumentsModal,
    },
    viewDocuments: {
      modal: viewDocumentsModal,
    },
    viewPricing: {
      modal: viewPricingModal,
    },
    viewRiskPreferenceHistory: {
      modal: viewRiskPreferenceHistoryModal,
    },
    viewTransfers: {
      modal: viewTransfersModal,
    },
    withdrawFunds: {
      modal: withdrawFundsModal,
    },
    addRestrictions: {
      modal: addRestrictionsModal,
    },
    closeAccount: {
      modal: closeAccountModal,
    },
    suspendBilling: {
      modal: suspendBillingModal,
    },
    suspendTrading: {
      modal: suspendTradingModal,
    },
    editAccount: {
      modal: editAccountModal,
    },
    toggleTlh: {
      modal: tlhModal,
    },
    other: {
      modal: otherActionsModal,
    },
    retakeQuestionnaire: {
      modal: retakeQuestionnaireModal,
    },
    switchWithinAnAccount: {
      modal: switchWithinAnAccountModal,
    },
  };
};
