import {
  GetFinancialAccountAssociationVerificationRequestsV2,
  GetFinancialAccountAssociationVerificationRequestsV2_financialAccountAssociationVerificationRequests_filterContext,
  GetFinancialAccountAssociationVerificationRequestsV2_financialAccountAssociationVerificationRequests_requests_financialAccount,
  GetFinancialAccountAssociationVerificationRequestsV2_financialAccountAssociationVerificationRequests_requests_financialAccount_products,
  GetFinancialAccountAssociationVerificationRequestsV2_financialAccountAssociationVerificationRequests_requests_financialAccount_products_ManagedProduct,
  GetFinancialAccountAssociationVerificationRequestsV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

type Product = GetFinancialAccountAssociationVerificationRequestsV2_financialAccountAssociationVerificationRequests_requests_financialAccount_products;
type ManagedProduct = GetFinancialAccountAssociationVerificationRequestsV2_financialAccountAssociationVerificationRequests_requests_financialAccount_products_ManagedProduct;
type FinancialAccount = GetFinancialAccountAssociationVerificationRequestsV2_financialAccountAssociationVerificationRequests_requests_financialAccount;
export type QueryVariables = GetFinancialAccountAssociationVerificationRequestsV2Variables;

export type FilterContext = GetFinancialAccountAssociationVerificationRequestsV2_financialAccountAssociationVerificationRequests_filterContext;

export const isManagedProduct = (product: Product): product is ManagedProduct =>
  product.__typename === 'ManagedProduct';

export const findManagedProduct = (financialAccount: FinancialAccount | null): ManagedProduct | undefined =>
  financialAccount?.products?.find(isManagedProduct);

export const useGetBankVerificationRequests = (
  options?: QueryHookOptions<
    GetFinancialAccountAssociationVerificationRequestsV2,
    GetFinancialAccountAssociationVerificationRequestsV2Variables
  >,
): QueryResult<
  GetFinancialAccountAssociationVerificationRequestsV2,
  GetFinancialAccountAssociationVerificationRequestsV2Variables
> => {
  return useSymphonyQuery(queries.GetFinancialAccountAssociationVerificationRequestsV2, options);
};
