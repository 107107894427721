import React from 'react';

import { CopyField } from '~/components/ui/CopyField';
import { Link } from '~/components/ui/Link';
import { Box } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

type LabeledValueFieldProps = {
  copyLabel?: string;
  label?: string | null;
  onClick?: () => void;
  redirectUrl?: string;
  showCopyField?: boolean;
  successLabel?: string;
  textToCopy?: string;
  value?: string | null;
};
export const LabeledValueField: React.FC<LabeledValueFieldProps> = ({
  label = '',
  value = '',
  onClick,
  showCopyField,
  textToCopy,
  copyLabel,
  successLabel,
  redirectUrl,
}) => {
  const displayValue = !showCopyField ? (
    value
  ) : (
    <CopyField copyLabel={copyLabel ?? ''} successLabel={successLabel} textToCopy={textToCopy ?? ''}>
      {value}
    </CopyField>
  );

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box sx={{ '&+&': { mt: 2 } }} whiteSpace="pre-wrap">
      {onClick || redirectUrl ? (
        <Link color="inherit" href={redirectUrl} onClick={handleOnClick} sx={{ color: 'text.primary', pt: 1 }}>
          {displayValue}
        </Link>
      ) : (
        <Typography sx={{ color: 'text.primary' }}>{displayValue}</Typography>
      )}
      {label && (
        <Typography sx={{ color: 'text.secondary' }} variant="body2">
          {label}
        </Typography>
      )}
    </Box>
  );
};
