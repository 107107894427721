import { ComponentProps, useCallback, useContext, useMemo, useState } from 'react';

import OpsDashboardContext from '../../OpsDashboardContext';
import {
  RemoveSuspensionData,
  RestrictionsContent,
  RestrictionsTableContent,
  SearchContextLiterals,
  TOO_MANY_SEARCH_RECORDS_ERROR,
} from '../../types';
import { useGetRestrictionsContent } from '../contentstack';
import { GroupedRestrictionsManagedProduct, useGetGroupedRestrictions, useMultiRestrictions } from '../symphony';

import {
  defaultFilters,
  geSelectedFilterManagedProductStatus,
  geSelectedFilterTypes,
  geSelectedPlacedBy,
  getFilterCountMap,
  getOffset,
  getRestrictionsContent,
  getTableContent,
  ManagedProductStatusesForRestrictions,
  RestrictionFilters,
  RestrictionsData,
} from './utils';

import { Field, OrderType } from '~/__generated__';
import { Filters } from '~/components/Filters';
import { FilterCountMap, SelectedFilters } from '~/components/Filters/types';
import { getSelectedOptions } from '~/components/Filters/utils';
import { SortConfig, TableColumn } from '~/components/ui/BasicTable';
import { getSearchContext } from '~/containers/OpsDashboardV2/utils';
import { useCoreConfig } from '~/utils/config';
import { ContentstackTableColumn, getPaginationContext, sortColumnsByColumnOrder } from '~/utils/table';
import { AsyncResult, PaginationContext, SearchContext } from '~/utils/types';

export interface RestrictionsVariable {
  onRemoveSuspensionClick: (data: RemoveSuspensionData) => void;
}

interface RestrictionsTabData {
  appliedFilters: ComponentProps<typeof Filters>['appliedFilters'];
  content: RestrictionsContent;
  currentPage: number;
  currentSearchFilter: string;
  filterCountMap: FilterCountMap[];
  filtersPopupOpen: boolean;
  handleGroupAccountToggle: () => void;
  isGroupAccountToggleChecked: boolean;
  onAppliedFiltersSelect: () => void;
  onFilterChange: (values: ComponentProps<typeof Filters>['appliedFilters']) => void;
  onPageChange: (pageNumber: number) => void;
  onRowHover: (rowId: string | undefined) => void;
  onSearchChange: (value: string | null) => void;
  paginationContext: PaginationContext;
  refetchRestrictionsData: () => void;
  restrictionTableContent: RestrictionsTableContent[];
  searchContext: SearchContext;
  searchError?: boolean;
  sortConfig: SortConfig;
  tableColumns: TableColumn[];
  totalPages: number;
  updateOpenFiltersPopup: (value: boolean) => void;
}

// Set 10 as constant first until we have requirements to change it
const initialRecordsPerPage = 10;

export const useRestrictionsData = ({
  onRemoveSuspensionClick,
}: RestrictionsVariable): AsyncResult<RestrictionsTabData> => {
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  const {
    contentOptions,
    commentColumn,
    onAccountClick,
    onClientClick,
    getClientNameRedirectUrl,
    getAccountNumberRedirectUrl,
    onParamsUpdate,
    params,
    opsContentData,
    currentUser,
  } = useContext(OpsDashboardContext);

  const state = useMemo(() => {
    try {
      return JSON.parse(params.state || '{}');
    } catch (e) {
      return defaultFilters;
    }
  }, [params]);

  const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
  const [activeRowId, setActiveRowId] = useState<string | undefined>();
  const appliedFilters: SelectedFilters = state.filters || defaultFilters;
  const currentPage: number = state.currentPage || 1;
  const recordsPerPage: number = state.recordsPerPage || initialRecordsPerPage;
  const searchFilter = state.searchFilter ?? null;
  const isGroupAccountToggleChecked = state.isGroupAccountToggleChecked ?? false;
  const setCurrentPage = useCallback(
    (page: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: page }) });
    },
    [onParamsUpdate, params, state],
  );

  const setRecordsPerPage = useCallback(
    (value: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, recordsPerPage: value }) });
    },
    [onParamsUpdate, params, state],
  );

  const setIsGroupAccountToggleChecked = useCallback(
    (value: boolean) => {
      onParamsUpdate({
        ...params,
        state: JSON.stringify({ ...state, currentPage: 1, isGroupAccountToggleChecked: value }),
      });
    },
    [onParamsUpdate, params, state],
  );
  const setSearchFilter = useCallback(
    (value: string | null) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, searchFilter: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const sortConfig: SortConfig = useMemo(
    () =>
      state.sortConfig || {
        order: OrderType.DESCENDING,
        field: 'createdAt',
      },
    [state],
  );

  /**
   * The reason for no-cache is due to the updating the list when ever we create or resolve flag for a docusign,
   * the flagged and unflagged docusign table should be updated.
   * Otherwise, instead of the table showing the current flagged and unflagged docusign data it shows the previous cached data.
   * There is no easy way to update the cache to reflect the correct state right now.
   */
  const managedProductStatuses = geSelectedFilterManagedProductStatus(
    getSelectedOptions(appliedFilters[RestrictionFilters.ACCOUNT_STATUS]) ?? [],
  );
  const statuses = managedProductStatuses.length ? managedProductStatuses : ManagedProductStatusesForRestrictions;

  const restrictionTypes = geSelectedFilterTypes(appliedFilters);
  const creatorFitlers = geSelectedPlacedBy(appliedFilters);

  const {
    data: restrictionsData,
    error: restrictionsDataError,
    loading: restrictionsDataLoading,
    refetch,
  } = useMultiRestrictions({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: {
      searchFilter,
      fetchLatestNote: true,
      managedProductStatuses: statuses,
      multiRestrictionTypes: restrictionTypes,
      placedBy: creatorFitlers,
      pagination: {
        limit: recordsPerPage,
        offset: getOffset(currentPage, recordsPerPage),
      },
      sort: {
        field: Field.CREATED,
        order: sortConfig.order,
      },
    },
    skip: isGroupAccountToggleChecked,
  });

  const {
    data: groupedRestrictions,
    error: groupedRestrictionsError,
    loading: groupedRestrictionsLoading,
    refetch: refecthGroupedRestrictions,
  } = useGetGroupedRestrictions({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: {
      closeRequestStatus: [],
      fetchLatestNote: true,
      searchFilter,
      includedMultiRestrictionPlacedBy: creatorFitlers,
      sortByMultiRestrictionCreatedDate: true,
      includedMultiRestrictionTypes: restrictionTypes,
      pagination: {
        limit: recordsPerPage,
        offset: getOffset(currentPage, recordsPerPage),
      },
      managedProductStatuses: statuses,
    },
    skip: !isGroupAccountToggleChecked,
  });

  const { data: contentData, error: contentStackError, loading: contentLoading } = useGetRestrictionsContent({
    variables: contentOptions,
  });

  const onPageChange = useCallback(
    (selectedPage: number) => {
      setCurrentPage(selectedPage);
    },
    [setCurrentPage],
  );

  const onRowHover = useCallback(
    (rowId: string | undefined) => {
      setActiveRowId(rowId);
    },
    [setActiveRowId],
  );

  const updateRecordsPerPage = useCallback(
    (value: number) => {
      setRecordsPerPage(value);
    },
    [setRecordsPerPage],
  );

  const onSearchChange = useCallback(
    (searchText: string | null) => {
      setSearchFilter(searchText);
    },
    [setSearchFilter],
  );

  const onFilterChange = useCallback(
    (filters: SelectedFilters) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, filters }) });
    },
    [onParamsUpdate, params, state],
  );

  const onAppliedFiltersSelect = useCallback(() => {
    setFiltersPopupOpen(true);
  }, []);

  const updateOpenFiltersPopup = useCallback((value: boolean) => {
    setFiltersPopupOpen(value);
  }, []);

  const handleGroupAccountToggle = useCallback(() => {
    setIsGroupAccountToggleChecked(!isGroupAccountToggleChecked);
  }, [isGroupAccountToggleChecked, setIsGroupAccountToggleChecked]);

  const tradingTableContent = useMemo(() => getRestrictionsContent(contentData), [contentData]);

  const tableColumns: TableColumn[] = useMemo(() => {
    // Filter empty columns
    const contentstackTableColumn =
      tradingTableContent.columns?.reduce<ContentstackTableColumn[]>((acc, column: ContentstackTableColumn | null) => {
        if (column) {
          acc.push(column);
        }

        return acc;
      }, []) ?? [];

    const sortedColumns = sortColumnsByColumnOrder(contentstackTableColumn);

    return sortedColumns.map(
      (col): TableColumn => ({
        title: col.column_value,
        key: col.column_id ?? '',
        ...(col.column_id === 'createdAt' && !isGroupAccountToggleChecked
          ? {
              onSort: (_: string) => () => {
                const toggleOrder =
                  sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
                onParamsUpdate({
                  ...params,
                  state: JSON.stringify({
                    ...state,
                    currentPage: 1,
                    sortConfig: { field: 'createdAt', order: toggleOrder },
                  }),
                });
              },
            }
          : {}),
      }),
    );
  }, [isGroupAccountToggleChecked, onParamsUpdate, params, sortConfig.order, state, tradingTableContent.columns]);

  const data: RestrictionsTabData | undefined = useMemo(() => {
    const fields = opsContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const paginationContext = getPaginationContext(fields, currentPage, recordsPerPage, 0, updateRecordsPerPage);
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_RESTRICTIONS);

    const commonData = {
      appliedFilters,
      filtersPopupOpen,
      content: tradingTableContent,
      contentLoading,
      handleGroupAccountToggle,
      isGroupAccountToggleChecked,
      onPageChange,
      onRowHover,
      onSearchChange,
      onAppliedFiltersSelect,
      currentSearchFilter: searchFilter,
      onFilterChange,
      currentPage,
      refetchRestrictionsData: isGroupAccountToggleChecked ? refecthGroupedRestrictions : refetch,
      sortConfig,
      tableColumns,
      searchContext,
      totalPages: 1,
      restrictionTableContent: [],
      filterCountMap: [],
      updateOpenFiltersPopup,
      paginationContext,
    };

    if (isGroupAccountToggleChecked) {
      const groupedRestrictionsManagedProducts = groupedRestrictions?.managedProducts;
      const restrictions: RestrictionsData[] | undefined = groupedRestrictionsManagedProducts?.products.reduce(
        (acc: RestrictionsData[], mp: GroupedRestrictionsManagedProduct) => {
          mp.multiRestrictions.forEach((restriction, index) => {
            acc.push({
              ...restriction,
              managedProductId: mp.id,
              updatedAt: restriction.createdAt,
              groupingIndex: index,
              managedProduct: {
                ...mp,
                clientParty: restriction.managedProduct.clientParty,
              },
            });
          });
          return acc;
        },
        [],
      );

      const restrictionTableContent = getTableContent({
        commentProps: {
          commentColumn,
          contentOptions,
          currentUser,
          refetchData: refecthGroupedRestrictions,
        },
        restrictionContent: contentData,
        content: tradingTableContent,
        onClientClick,
        onAccountClick,
        getAccountNumberRedirectUrl,
        getClientNameRedirectUrl,
        onRemoveSuspensionClick,
        showProductName,
        opsDashboardContentData: opsContentData,
        restrictions,
        activeRowId,
      });
      const total = groupedRestrictionsManagedProducts?.paginationContext.total ?? 0;
      const filterContext = groupedRestrictions?.multiRestrictions.filterContext;

      return {
        ...commonData,
        totalPages: Math.ceil(total / recordsPerPage),
        searchError: groupedRestrictionsError?.message === TOO_MANY_SEARCH_RECORDS_ERROR,
        paginationContext: {
          ...paginationContext,
          totalItems: total,
        },
        filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
        restrictionTableContent,
      };
    } else {
      const filterContext = restrictionsData?.multiRestrictions.filterContext;
      const restrictionTableContent = getTableContent({
        commentProps: {
          commentColumn,
          contentOptions,
          currentUser,
          refetchData: refetch,
        },
        restrictionContent: contentData,
        content: tradingTableContent,
        onClientClick,
        onAccountClick,
        getAccountNumberRedirectUrl,
        getClientNameRedirectUrl,
        onRemoveSuspensionClick,
        showProductName,
        opsDashboardContentData: opsContentData,
        restrictions: restrictionsData?.multiRestrictions.multiRestrictions,
        activeRowId,
      });
      const total = restrictionsData?.multiRestrictions.paginationContext.total ?? 0;

      return {
        ...commonData,
        totalPages: Math.ceil(total / recordsPerPage),
        paginationContext: {
          ...paginationContext,
          totalItems: total,
        },
        filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
        restrictionTableContent,
        searchError: restrictionsDataError?.message === TOO_MANY_SEARCH_RECORDS_ERROR,
      };
    }
  }, [
    opsContentData,
    currentPage,
    recordsPerPage,
    updateRecordsPerPage,
    appliedFilters,
    filtersPopupOpen,
    tradingTableContent,
    contentLoading,
    handleGroupAccountToggle,
    isGroupAccountToggleChecked,
    onPageChange,
    onRowHover,
    onSearchChange,
    onAppliedFiltersSelect,
    searchFilter,
    onFilterChange,
    refecthGroupedRestrictions,
    refetch,
    sortConfig,
    tableColumns,
    updateOpenFiltersPopup,
    groupedRestrictions,
    contentData,
    onClientClick,
    onAccountClick,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
    onRemoveSuspensionClick,
    showProductName,
    activeRowId,
    groupedRestrictionsError?.message,
    restrictionsData,
    restrictionsDataError?.message,
  ]);

  const error: Error | undefined = useMemo(() => {
    const dataError = isGroupAccountToggleChecked ? restrictionsDataError : groupedRestrictionsError;

    return dataError ?? contentStackError;
  }, [contentStackError, groupedRestrictionsError, isGroupAccountToggleChecked, restrictionsDataError]);

  return {
    data,
    error,
    loading: contentLoading || restrictionsDataLoading || groupedRestrictionsLoading,
  };
};
