import React, { ComponentProps } from 'react';

import { useGetAllStatusChangeContent } from './contentstack';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, LoadingButton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  errorSaving?: any;
  isBusy: boolean;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  showDescriptionForCompleteStatus?: boolean;
};

export const StatusChangeModal: React.FC<Props> = ({
  contentOptions,
  dataQa = 'status-change-modal',
  errorSaving,
  isBusy,
  onClose,
  onConfirm,
  open,
  showDescriptionForCompleteStatus,
}: Props) => {
  const { data, loading, error } = useGetAllStatusChangeContent({
    variables: contentOptions,
    skip: !open,
  });
  const {
    sfStatusChangeModal: { styles: sfStatusChangeModalStyles, root: sfStatusChangeModalRoot },
  } = useTheme<SfTheme>();

  const content = data?.all_status_change_modal?.items?.[0];
  const confirmation_modal_content = content?.confirmation_modal;
  const descriptionForCompleteStatus = findFieldValue(content?.fields?.text ?? [], 'description_for_complete_status');
  const confirmationLogo = confirmation_modal_content?.imageConnection?.edges?.[0]?.node?.url ?? '';

  return (
    <Modal
      actions={
        <>
          <Button data-qa={`${dataQa}-close-cta`} onClick={onClose} variant="outlined">
            {confirmation_modal_content?.secondary_cta ?? ''}
          </Button>
          <LoadingButton
            data-qa={`${dataQa}-status-change-cta`}
            loading={isBusy}
            onClick={onConfirm}
            variant="contained"
          >
            {confirmation_modal_content?.primary_cta ?? ''}
          </LoadingButton>
        </>
      }
      content={
        <>
          {confirmation_modal_content?.description && (
            <>
              <Box sx={{ ...sfStatusChangeModalStyles.confirmationLogo }}>
                <img alt="" src={confirmationLogo} />
              </Box>
              <RteContent data={confirmation_modal_content.description} />
              {showDescriptionForCompleteStatus && (
                <Typography sx={{ mt: 2 }} variant="body2">
                  {descriptionForCompleteStatus}
                </Typography>
              )}
            </>
          )}
          {errorSaving && (
            <Alert
              contentOptions={contentOptions}
              error={errorSaving}
              severity="error"
              sx={{ mt: 2, alignItems: 'center' }}
            />
          )}
        </>
      }
      contentOptions={contentOptions}
      dataQa={dataQa}
      error={error}
      hideHeader
      loading={loading}
      onClose={onClose}
      open={open}
      sx={{ ...sfStatusChangeModalRoot }}
    />
  );
};
