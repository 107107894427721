import React, { ComponentProps, useEffect } from 'react';

import { useLazyGetConfirmOpenNewAccountModalContent } from './contentstack';

import { useLoadingButtonState } from '~/components/ui/Button/LoadingButton/hooks';
import { Modal } from '~/components/ui/Modal';
import { Button, LoadingButton } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';

export interface Props extends Omit<ComponentProps<typeof Modal>, 'title' | 'content'> {
  contentOptions: ContentOptions;
  dataQa?: string;
  dateStarted: string;
  onContinue: () => void;
  onStartOver: () => Promise<void> | void;
  timeStarted: string;
}

export const ConfirmNewAccountModal: React.FC<Props> = ({
  dataQa = 'confirm-new-account-modal',
  open,
  contentOptions,
  dateStarted,
  timeStarted,
  onContinue,
  onStartOver,
  ...modalProps
}) => {
  const [getContent, { data, loading, error: getContentError }] = useLazyGetConfirmOpenNewAccountModalContent({
    variables: contentOptions,
  });
  const content = data?.all_confirm_open_new_account_modal?.items?.[0];

  useEffect(() => {
    if (open) {
      getContent();
    }
  }, [open]);

  const [onStartOverLoading, onStartOverClick] = useLoadingButtonState(onStartOver);

  return (
    <Modal
      {...modalProps}
      actions={
        <>
          <LoadingButton loading={onStartOverLoading} onClick={onStartOverClick} variant="outlined">
            {content?.ctas?.start_over}
          </LoadingButton>
          <Button onClick={onContinue} variant="contained">
            {content?.ctas?.continue}
          </Button>
        </>
      }
      content={
        <RteContent
          config={{
            dateStarted,
            timeStarted,
          }}
          data={content?.body ?? ''}
        />
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={getContentError}
      loading={loading}
      open={open}
      title={content?.heading}
    />
  );
};
