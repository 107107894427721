import { CreateOneTimePassword, CreateOneTimePasswordVariables } from '~/hooks/otp/symphony/__generated__/mutation.v2';
import * as mutations from '~/hooks/otp/symphony/mutation.gql';
import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useCreateOneTimePassword = (
  options?: MutationHookOptions<CreateOneTimePassword, CreateOneTimePasswordVariables>,
): MutationTuple<CreateOneTimePassword, CreateOneTimePasswordVariables> => {
  return useSymphonyMutation(mutations.CreateOneTimePassword, options);
};
