import { ApolloCache } from '@apollo/client';
import { produce } from 'immer';

import { FinancialAccount, ManagedProduct, Product } from '.';

import { FinancialAccountStatus } from '~/__generated__/symphonyTypes.v2';
import { AbandonDigitalWealth } from '~/hooks/onboarding/symphony/__generated__/abandonDigitalWealth.v2';
import {
  GetDigitalWealthAccounts,
  GetDigitalWealthAccountsVariables,
} from '~/hooks/onboarding/symphony/__generated__/getDigitalWealthAccounts.v2';
import { GetDigitalWealthAccounts as digitalWealthQuery } from '~/hooks/onboarding/symphony/getDigitalWealthAccounts.gql';

export const isManagedProduct = (product: Product): product is ManagedProduct =>
  product.__typename === 'ManagedProduct';

export const hasManagedProduct = (financialAccount: FinancialAccount): boolean =>
  !!financialAccount.products?.find(isManagedProduct);

export const findManagedProduct = (financialAccount: FinancialAccount): ManagedProduct | undefined =>
  financialAccount.products?.find(isManagedProduct);

export const updateGetDigitalWealthAccountsCache = ({
  cache,
  data,
  managedProductId,
  partyId,
}: {
  cache: ApolloCache<AbandonDigitalWealth>;
  data: AbandonDigitalWealth;
  managedProductId: string;
  partyId: string;
}): void => {
  if (data.abandonDigitalWealth?.success) {
    const queryOptions = { query: digitalWealthQuery, variables: { partyId } };
    const digitalWealthCache = cache.readQuery<GetDigitalWealthAccounts, GetDigitalWealthAccountsVariables>(
      queryOptions,
    );

    if (digitalWealthCache) {
      cache.writeQuery<GetDigitalWealthAccounts>({
        ...queryOptions,
        data: produce(digitalWealthCache, draft => {
          if (draft.client?.financialAccounts) {
            const partialAccountIndex = draft.client.financialAccounts.findIndex(
              account =>
                account.status === FinancialAccountStatus.PARTIAL &&
                !!account.products?.find(
                  product => product.__typename === 'ManagedProduct' && product.id === managedProductId,
                ),
            );

            if (partialAccountIndex !== -1) {
              draft.client.financialAccounts.splice(partialAccountIndex, 1);
            }
          }
        }),
      });
    }
  }
};
