/**
 * @param {string} labels - a list of labels delimited by '/' example: 'Equity / Fixed Income / Cash'
 * @param {string} values - a list of values delimited by '/' example: '75/25/0'
 * @returns {string} a list of zipped labels and value, delimited by ', ' example: Equity 75, Fixed Income 25, Cash 0'
 */

export const zipperStrings = (labels: string, values: string): string => {
  const labelsArr = labels.split('/');
  const valuesArr = values.split('/');

  return labelsArr.map((d, i) => `${d} ${valuesArr[i] ?? ''}`).join(', ');
};
