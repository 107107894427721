import React from 'react';

import { AssetClassLabel } from './AssetClassLabel';
import { useGetAssetAllocationTableContent } from './contentstack';
import { PercentBar } from './PercentBar';

import { Alert } from '~/components/ui/Alert';
import { Link } from '~/components/ui/Link';
import {
  alpha,
  Box,
  FiberManualRecordIcon,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '~/components/ui/mui';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';
import { sortBySpecifiedOrder } from '~/utils/asset-allocation';
import { AssetAllocationTableColumns } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { formatCurrency, formatPercentage } from '~/utils/format';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface AssetDetails {
  key: string;
  label: string;
  tooltip?: React.ReactNode;
}

export interface Allocation {
  /**
   * maps to ActualAllocation column
   */
  actualPercent?: number;
  /**
   * maps to AssetClass column
   */
  assetClass: AssetDetails;
  color: string;
  opacity?: string;
  order?: number | null;
  /**
   * maps to Allocation column
   */
  percent: number;
  /**
   * maps to TargetAllocation column
   */
  targetPercent?: number;
  /**
   * maps to Asset column
   */
  tickers: AssetDetails[];
  /**
   * maps to Value column
   */
  value: number;
}
export interface Props {
  columns?: AssetAllocationTableColumns[];
  contentOptions: ContentOptions;
  data: Allocation[];
  dataQa?: string;
  isComparison?: boolean;
}

export const AssetAllocationTable: React.FC<Props> = ({
  columns = [
    AssetAllocationTableColumns.AssetClass,
    AssetAllocationTableColumns.Asset,
    AssetAllocationTableColumns.Allocation,
  ],
  data,
  dataQa = 'asset-allocation-table',
  contentOptions,
  isComparison = false,
}) => {
  const isMobile = useIsMediumScreen();
  const {
    sfAssetAllocationTable: { styles: sfAssetAllocationTableStyles, root: sfTableStyle },
  } = useTheme();

  const { data: contentData, loading: contentLoading, error: contentError } = useGetAssetAllocationTableContent({
    variables: contentOptions,
  });

  const content = contentData?.all_asset_allocation_table?.items?.[0];

  const hasPopover = !columns.includes(AssetAllocationTableColumns.Asset);

  const largestAllocation = Math.max(...data.map(allocation => allocation.percent));
  const largestActualAllocation = Math.max(...data.map(allocation => allocation.actualPercent ?? 0));

  return (
    <Box data-qa={dataQa}>
      {contentLoading ? (
        <Skeleton />
      ) : contentError ? (
        <Alert contentOptions={contentOptions} error={contentError} severity="error" />
      ) : (
        <TableContainer sx={{ mt: 2 }}>
          <Table sx={sfTableStyle}>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  switch (column) {
                    case AssetAllocationTableColumns.AssetClass:
                      return (
                        <TableCell key={index}>
                          <Typography component="div" variant="subtitle2">
                            {content?.asset_class}
                          </Typography>
                        </TableCell>
                      );
                    case AssetAllocationTableColumns.Asset:
                      return (
                        <TableCell key={index}>
                          <Typography component="div" variant="subtitle2">
                            {content?.asset}
                          </Typography>
                        </TableCell>
                      );
                    case AssetAllocationTableColumns.Allocation:
                      return (
                        <TableCell align="left" key={index}>
                          <Typography component="div" variant="subtitle2">
                            {content?.allocation}
                          </Typography>
                        </TableCell>
                      );
                    case AssetAllocationTableColumns.TargetAllocation:
                      return (
                        <TableCell key={index}>
                          <Typography
                            sx={{
                              // TODO: Refactor styles DA2:2666
                              fontSize: isComparison
                                ? sfAssetAllocationTableStyles.comparisonTableFontSize
                                : sfAssetAllocationTableStyles.standardTableFontSize,
                              fontWeight: isComparison
                                ? sfAssetAllocationTableStyles.comparisonTableHeaderFontWeight
                                : sfAssetAllocationTableStyles.standardFontWeight,
                            }}
                            variant="subtitle2"
                          >
                            {isComparison ? content?.new_allocation : content?.target_allocation}
                          </Typography>
                        </TableCell>
                      );
                    case AssetAllocationTableColumns.ActualAllocation:
                      return (
                        <TableCell key={index}>
                          <Typography
                            sx={{
                              // TODO: Refactor styles DA2:2666
                              fontSize: isComparison
                                ? sfAssetAllocationTableStyles.comparisonTableFontSize
                                : sfAssetAllocationTableStyles.standardTableFontSize,
                              fontWeight: isComparison
                                ? sfAssetAllocationTableStyles.comparisonTableHeaderFontWeight
                                : sfAssetAllocationTableStyles.standardFontWeight,
                            }}
                            variant="subtitle2"
                          >
                            {isComparison ? content?.current_allocation : content?.actual_allocation}
                          </Typography>
                        </TableCell>
                      );
                    case AssetAllocationTableColumns.Value:
                      return (
                        <TableCell key={index}>
                          <Typography variant="subtitle2">{content?.value}</Typography>
                        </TableCell>
                      );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortBySpecifiedOrder(data).map(
                ({ color, opacity, assetClass, tickers, percent, actualPercent, targetPercent, value }, index) => {
                  return (
                    <TableRow data-qa={`${dataQa}-allocation-row`} key={index}>
                      {columns.map((column, columnIndex) => {
                        switch (column) {
                          case AssetAllocationTableColumns.AssetClass:
                            return (
                              <TableCell key={columnIndex}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <FiberManualRecordIcon
                                    sx={{ color: alpha(color, opacity ? parseInt(opacity, 10) / 100 : 1), mr: 2 }}
                                  />
                                  <AssetClassLabel assetClass={assetClass} hasPopover={hasPopover} tickers={tickers} />
                                </Box>
                              </TableCell>
                            );
                          case AssetAllocationTableColumns.Asset:
                            return (
                              <TableCell key={columnIndex}>
                                {tickers.map((ticker, indexTicker) =>
                                  ticker.tooltip ? (
                                    <Tooltip key={indexTicker} tooltipContent={ticker.tooltip}>
                                      <Link sx={{ display: 'flex' }} variant="body2">
                                        {ticker.label}
                                      </Link>
                                    </Tooltip>
                                  ) : (
                                    <Typography key={indexTicker} sx={{ display: 'flex' }} variant="body2">
                                      {ticker.label}
                                    </Typography>
                                  ),
                                )}
                              </TableCell>
                            );
                          case AssetAllocationTableColumns.Allocation:
                            return (
                              <TableCell align="left" key={columnIndex}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'left',
                                  }}
                                >
                                  <Typography mr={2}>
                                    {formatPercentage(percent / 100, {
                                      decimals: 1,
                                      removeTrailingZeroes: false,
                                      locale: contentOptions.locale,
                                    })}
                                  </Typography>
                                  {!isMobile && (
                                    <PercentBar
                                      color={alpha(color, opacity ? parseInt(opacity, 10) / 100 : 1)}
                                      width={`${(percent * 100) / largestAllocation}%`}
                                    />
                                  )}
                                </Box>
                              </TableCell>
                            );
                          case AssetAllocationTableColumns.TargetAllocation:
                            return (
                              <TableCell key={columnIndex}>
                                {/* cases where targetPercent === 0 will be falsy, so we check using this case*/}
                                {targetPercent !== undefined && (
                                  <>
                                    {formatPercentage(targetPercent / 100, {
                                      decimals: 1,
                                      removeTrailingZeroes: false,
                                      locale: contentOptions.locale,
                                    })}
                                  </>
                                )}
                              </TableCell>
                            );
                          case AssetAllocationTableColumns.ActualAllocation:
                            return (
                              <TableCell key={columnIndex}>
                                {/* cases where actualPercent === 0 will be falsy, so we check using this case*/}
                                {actualPercent !== undefined && (
                                  <Stack
                                    sx={{
                                      alignItems: 'center',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'left',
                                    }}
                                  >
                                    <Box sx={{ mr: 3, width: '20%', display: 'inline-block' }}>
                                      {formatPercentage(actualPercent / 100, {
                                        decimals: 1,
                                        removeTrailingZeroes: false,
                                        locale: contentOptions.locale,
                                      })}
                                    </Box>
                                    {!isComparison && !isMobile && (
                                      <PercentBar
                                        color={alpha(color, opacity ? parseInt(opacity, 10) / 100 : 1)}
                                        width={`${(actualPercent * 100) / largestActualAllocation}%`}
                                      />
                                    )}
                                  </Stack>
                                )}
                              </TableCell>
                            );
                          case AssetAllocationTableColumns.Value:
                            return (
                              <TableCell key={columnIndex}>
                                {formatCurrency(value, { locale: contentOptions.locale })}
                              </TableCell>
                            );
                        }
                      })}
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
