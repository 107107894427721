import { QueryHookOptions, QueryResult, QueryTuple } from '@apollo/client';

import {
  GetClientDocumentList,
  GetClientDocumentList_clientDocumentList_documentsData,
  GetClientDocumentList_clientDocumentList_paginationContext_offset,
  GetClientDocumentListVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { useLazySymphonyQuery, useSymphonyQuery } from '~/utils/symphony';

export type ClientDocumentsData = GetClientDocumentList;
export type ClientDocument = GetClientDocumentList_clientDocumentList_documentsData;
export type PaginationOffset = GetClientDocumentList_clientDocumentList_paginationContext_offset;

export const useGetClientDocumentsData = (
  options?: QueryHookOptions<GetClientDocumentList, GetClientDocumentListVariables>,
): QueryResult<GetClientDocumentList, GetClientDocumentListVariables> => {
  return useSymphonyQuery(queries.GetClientDocumentList, options);
};

export const useLazyGetClientDocumentsData = (
  options?: QueryHookOptions<GetClientDocumentList, GetClientDocumentListVariables>,
): QueryTuple<GetClientDocumentList, GetClientDocumentListVariables> => {
  return useLazySymphonyQuery(queries.GetClientDocumentList, options);
};
