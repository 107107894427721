import { DeleteTradingSuspension, DeleteTradingSuspensionVariables } from './__generated__/mutation.v2';
import { UpdateTradingSuspension, UpdateTradingSuspensionVariables } from './__generated__/mutation.v2';
import {
  GetManagedProductTradingSuspensions,
  GetManagedProductTradingSuspensions_managedProduct_tradingSuspensions,
  GetManagedProductTradingSuspensionsVariables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, QueryHookOptions } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ManagedProductTradingSuspensions = GetManagedProductTradingSuspensions_managedProduct_tradingSuspensions;

export const useGetManagedProductTradingSuspensions = (
  options?: QueryHookOptions<GetManagedProductTradingSuspensions, GetManagedProductTradingSuspensionsVariables>,
) => {
  return useSymphonyQuery(queries.GetManagedProductTradingSuspensions, options);
};

export const useLazyGetManagedProductTradingSuspensions = (
  options?: QueryHookOptions<GetManagedProductTradingSuspensions, GetManagedProductTradingSuspensionsVariables>,
) => {
  return useLazySymphonyQuery(queries.GetManagedProductTradingSuspensions, options);
};

export const useUpdateTradingSuspension = (
  options?: MutationHookOptions<UpdateTradingSuspension, UpdateTradingSuspensionVariables>,
) => {
  return useSymphonyMutation(mutations.UpdateTradingSuspension, options);
};

export const useDeleteTradingSuspension = (
  options?: MutationHookOptions<DeleteTradingSuspension, DeleteTradingSuspensionVariables>,
) => {
  return useSymphonyMutation(mutations.DeleteTradingSuspension, options);
};
