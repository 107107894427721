import React, { useState } from 'react';

import { Stack } from '~/components/ui/mui';
import { NumberRange } from '~/components/ui/NumberFormatRange';
import { CurrencyTextField, Props as CurrencyTextFieldProps } from '~/components/ui/TextField/CurrencyTextField';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface Props extends Omit<CurrencyTextFieldProps, 'variant' | 'onChange' | 'onError'> {
  contentOptions: ContentOptions;
  dataQa?: string;
  defaultValues?: NumberRange;
  labels?: { maxValue: string; minValue: string };
  maxInputValue?: number;
  onChange: (currencyRange: NumberRange) => void;
  onError: (currencyRange?: NumberRange) => void;
  placeholders?: { maxValue: string; minValue: string };
}

export const CurrencyAmountRange: React.FC<Props> = ({
  contentOptions,
  dataQa = 'currency-amount-range',
  defaultValues,
  labels,
  maxInputValue,
  onChange,
  onError,
  placeholders,
}) => {
  const [maxValue, setMaxValue] = useState(defaultValues?.maxValue);
  const [minValue, setMinValue] = useState(defaultValues?.minValue);
  const [error, setError] = useState(false);

  const isAllowed = (value: string) => (maxInputValue ? maxInputValue >= parseInt(value, 10) : true);

  const handleValueChange = (minVal: number, maxVal: number) => {
    const currencyRange = { maxValue: maxVal, minValue: minVal };

    setMinValue(currencyRange.minValue);
    setMaxValue(currencyRange.maxValue);

    if (currencyRange.maxValue < currencyRange.minValue) {
      setError(true);
      onError(currencyRange);
      return;
    }
    setError(false);
    onChange(currencyRange);
  };

  return (
    <Stack alignItems="center" data-qa={dataQa} direction="row" spacing={0}>
      <Stack display="inline-flex">
        <CurrencyTextField
          contentOptions={contentOptions}
          defaultValue={minValue}
          error={error}
          isAllowed={values => isAllowed(values.value)}
          label={labels?.minValue}
          onValueChange={values => handleValueChange(parseInt(values.value || '0', 10), maxValue ?? 0)}
          placeholder={placeholders?.minValue}
        />
      </Stack>
      <Typography sx={{ mx: 2 }} variant="body1">
        To
      </Typography>
      <Stack display="inline-flex">
        <CurrencyTextField
          contentOptions={contentOptions}
          defaultValue={maxValue}
          error={error}
          isAllowed={values => isAllowed(values.value)}
          label={labels?.maxValue}
          onValueChange={values => handleValueChange(minValue ?? 0, parseInt(values.value || '0', 10))}
          placeholder={placeholders?.maxValue}
        />
      </Stack>
    </Stack>
  );
};
