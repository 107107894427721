import React from 'react';

import { OpsAlertAndLoading } from '../common/OpsAlertAndLoading';
import { SearchBar } from '../common/SearchBar';

import { defaultAppliedFilters, useAccountsTabData } from './hooks/useAccountsTabData';

import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { NullStateProps } from '~/components/NullState';
import { BasicTable } from '~/components/ui/BasicTable';
import { ItemsPerPage } from '~/components/ui/BasicTable/ItemsPerPage';
import { TotalItems } from '~/components/ui/BasicTable/TotalItems';
import { Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  nullStateConfig?: NullStateProps;
}

export const AllAccountsTab: React.FC<Props> = ({ contentOptions, dataQa = 'all-accounts-table', nullStateConfig }) => {
  const { data, loading, error } = useAccountsTabData();

  return (
    <Stack data-qa={`${dataQa}-container`}>
      {(error || loading) && (
        <OpsAlertAndLoading
          ariaLabel="Loading accounts data"
          contentOptions={contentOptions}
          error={error}
          loading={loading}
        />
      )}

      {(data?.accountsTabContent || data?.searchError) && !loading && (
        <>
          <Stack alignItems="center" data-qa="filter-container" flexDirection="row" justifyContent="space-between">
            <SearchBar
              onSearchChange={value => data.onSearchChange(value)}
              searchContext={data.searchContext}
              searchError={data.searchError}
              value={data.currentSearchFilter}
            />
            <Filters
              appliedFilters={data.appliedFilters}
              content={data.filtersContent}
              contentOptions={contentOptions}
              defaultAppliedFilters={defaultAppliedFilters}
              filterCountMap={data.filterCountMap}
              filters={data.accountsFilterConfig}
              onSubmit={data.onFilterChange}
              openFiltersPopup={data.filtersPopupOpen}
              updateOpenFiltersPopup={data.updateOpenFiltersPopup}
            />
          </Stack>
          <AppliedFilters
            appliedFilters={data.appliedFilters}
            content={data.appliedFiltersContent}
            filters={data.accountsFilterConfig}
            onAppliedFilterSelect={data.onAppliedFiltersSelect}
            searchResults={
              data.currentSearchFilter &&
              !data.searchError && (
                <RteContent
                  config={{
                    searchCount: data.paginationContext.totalItems,
                    searchTerm: data.currentSearchFilter,
                  }}
                  data={data.searchContext.totalSearchItemsLabel}
                />
              )
            }
          />
          <Stack data-qa="withdrawal-table-container">
            <BasicTable
              BottomLeftSection={<TotalItems paginationContext={data.paginationContext} />}
              BottomRightSection={<ItemsPerPage paginationContext={data.paginationContext} />}
              alignItems="left"
              columns={data.tableColumns}
              currentPage={data.currentPage}
              data={data.accountsTabContent}
              enableRowHover
              nullStateConfig={data.searchError ? undefined : nullStateConfig}
              onPageChange={data.onPageChange}
              onRowHover={data.onRowHover}
              showPagination
              sortConfig={data.sortConfig}
              totalPages={data.totalPages}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
