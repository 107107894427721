import React from 'react';

import { LabeledValueField } from '../../common/LabeledValueField';
import { StatusChip } from '../../common/StatusChip';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { GetOpsDashboardContent } from '../../contentstack/__generated__/query.v2';
import {
  getAccountNumberOrAccountTypeString,
  getLastEditDateAndTime,
  getSortedPartnerOpsTradingSuspensions,
  isAccountNumberClickable,
} from '../../utils';
import { findManagedProduct } from '../symphony';
import {
  GetFinancialAccountAssociationVerificationRequests,
  GetFinancialAccountAssociationVerificationRequests_financialAccountAssociationVerificationRequests_requests,
} from '../symphony/__generated__/query.v2';
import { getBankVerificationStatusValue } from '../utils/mappers';

import { BankVerificationItem, FormattedItemsProps } from './types';

import {
  FinancialAccountAssociationVerificationStatus,
  FinancialAccountType,
  ManagedProductStatus,
  UserNoteEntityType,
} from '~/__generated__';
import { TableData } from '~/components/ui/BasicTable';
import { Button } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { Comment } from '~/containers/OpsDashboard/common/Comment';
import { getAccountProgramText, getAccountState, getUpdatedFinancialInstituion } from '~/utils/account';
import { formatDate, formatDateRelativeToToday } from '~/utils/format/date';

/**
 * @param {GetFinancialAccountAssociationVerificationRequests}  [result] - List of bank Account Association Requests.
 * @param {GetOpsDashboardContent} [opsDashboardContentData] - Ops Dashboard Content Object fetched from Content Stack.
 * @returns {BankVerificationItem[]} List of objects containing data needed to populate Bank Verification Table
 */
export const bankVerificationTableFactory = (
  result?: GetFinancialAccountAssociationVerificationRequests,
  opsDashboardContentData?: GetOpsDashboardContent,
): BankVerificationItem[] => {
  const bankVerificationResult: BankVerificationItem[] = [];
  const accountTypeContent = opsDashboardContentData?.all_account_type?.items || [];
  const productNameContent = opsDashboardContentData?.all_product_name?.items || [];
  result?.financialAccountAssociationVerificationRequests.requests.map(
    (
      item: GetFinancialAccountAssociationVerificationRequests_financialAccountAssociationVerificationRequests_requests,
    ) => {
      const managedProduct = findManagedProduct(item.financialAccount);
      const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(managedProduct?.tradingSuspensions)[0]
        ?.createdAt;
      const managedProductStatus = managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
      const clientParty = managedProduct?.relatedPartiesV2?.filter(party => party.isInitialParty === true)?.[0]?.party;
      // only used for activated account states
      const accountState = getAccountState({
        firstRebalancedOn: managedProduct?.firstRebalancedOn ?? undefined,
        financialAccountStatus: managedProductStatus,
        suspendedOn: latestOpsTradingSuspension,
      }).state;

      bankVerificationResult.push({
        id: item.id || '',
        partyId: clientParty?.id ?? '',
        accountState,
        bankAccountNumber: item.financialAccount?.accountNumber || '',
        financialAccountNumber: managedProduct?.financialAccountNumber || '',
        accountType: getAccountTypeText(
          managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
          accountTypeContent,
        ),
        clientName: `${clientParty?.partyPerson?.givenName ?? ''} ${clientParty?.partyPerson?.familyName ?? ''}`,
        dateCreated: item.createdOn || '',
        financialInstitution: getUpdatedFinancialInstituion(
          item.financialAccount?.financialInstitution,
          item.financialAccount?.type,
        ),
        managedProductId: managedProduct?.id || '',
        productName: getAccountProgramText(managedProduct?.program, managedProduct?.attributes, productNameContent),
        status: item.status,
        notes: item.entityNotes.length ? item.entityNotes[0] : null,
      });
    },
  );
  return bankVerificationResult;
};

/**
 * @param {StatusContentValue[]}  statusContent - A List of objects containing key-value pair of Bank Account Status fetched from Content Stack.
 * @param {BankVerificationItem[]} items - List of objects where each object holds data for a single row fetched from symphony.
 * @param {boolean} showProductName - boolean field to determine whether the product name should be visible.
 * @param {(partyId: string, managedProductId: string) => void}  onAccountClick - Callback function when account number is clicked.
 * @param {(partyId: string) => void} onClientClick - Callback function when Client Name is clicked.
 * @param {() => void} handleReviewClick - Callback function when Review Details Button is clicked.
 * @param {{ reviewButtonLabel: string, statuses: StatusContentValue[] }} content - Object holding Account Number Prefix and Text for Review Button.
 * @returns {TableData[]} A list of objects. Key is mapped to the column key/ID and holds respective React Nodes. The rowKey is the unique id for each row.
 */
export const getFormattedItems = ({
  items,
  showProductName,
  onAccountClick,
  onClientClick,
  handleReviewClick,
  content,
  commentProps,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
}: FormattedItemsProps): TableData[] => {
  return items.map(item => ({
    ...item,
    rowKey: item.id,
    accountNumber: (
      <AccountNumber
        accountNumber={item.financialAccountNumber}
        accountNumberText={getAccountNumberOrAccountTypeString(item.financialAccountNumber || null, item.accountType)}
        label={showProductName ? item.productName : null}
        onClick={
          isAccountNumberClickable(item.accountState)
            ? () => onAccountClick(item.partyId, item.managedProductId)
            : undefined
        }
        redirectUrl={getAccountNumberRedirectUrl(item.partyId, item.managedProductId)}
      />
    ),
    clientName: (
      <ClientName
        clientName={item.clientName}
        onClick={() => onClientClick(item.partyId)}
        redirectUrl={getClientNameRedirectUrl(item.partyId)}
      />
    ),
    dateCreated: (
      <LabeledValueField label={formatDateRelativeToToday(item.dateCreated)} value={formatDate(item.dateCreated)} />
    ),
    status: <StatusChip label={getBankVerificationStatusValue(item.status, content.statuses)} />,
    bankInformation: (
      <Typography sx={{ color: 'text.primary' }}>{`${item.financialInstitution} ${item.bankAccountNumber}`}</Typography>
    ),
    actions:
      item.status !== FinancialAccountAssociationVerificationStatus.IN_REVIEW ? (
        <></>
      ) : (
        <Button onClick={() => handleReviewClick(item.id)} sx={{ borderRadius: '100px' }} variant="outlined">
          {content.reviewButtonLabel}
        </Button>
      ),
    notes: (
      <Comment
        comment={
          item.notes
            ? {
                lastValue: item.notes.note,
                ...getLastEditDateAndTime(new Date(item.notes.created), commentProps.contentOptions),
              }
            : undefined
        }
        content={commentProps.commentColumn}
        contentOptions={commentProps.contentOptions}
        currentUser={commentProps.currentUser}
        entity={UserNoteEntityType.BANK_ACCOUNT_ASSOCIATION_VERIFICATION_REQUEST}
        entityId={item.id}
        key={item.id}
        lastCommentPartyId={item.notes?.createdByPartyId}
        refetchData={commentProps.refetchBankVerificationData}
      />
    ),
  }));
};
