export const roundDown = (num: number, precision: number): number => {
  if (!precision) {
    return num;
  }
  return Math.floor(num / precision) * precision;
};

export const roundUp = (num: number, precision: number): number => {
  if (!precision) {
    return num;
  }
  return Math.ceil(num / precision) * precision;
};

/**
 * https://stackoverflow.com/a/18230432
 * Method created to use Cryptographically Secure Pseudo-Random Number Generator (CSPRNG)
 * ... over Pseudo-Random Number Generator (PRNG)
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/crypto
 * Note - IE11 syntax throwing type-check error when set as `window.msCrypto`.
 * @param min - Minimum range, inclusive.
 * @param max - Maximum range, inclusive.
 * @param bytes - Bytes/element to determine which ArrayType to use (Uint8 | Uint16 | Uint 32).
 * @returns Random number within range of the unsigned bit integers.
 */
export const randomIntegerWithRange = (min: number, max: number, bytes: 1 | 2 | 4): number => {
  const cryptoObj = window.crypto || (window as any).msCrypto;
  let array;
  let max_range;
  const range = max - min + 1;
  if (bytes === 1) {
    array = new Uint8Array(1);
    max_range = 256;
  } else if (bytes === 2) {
    array = new Uint16Array(1);
    max_range = 65536;
  } else {
    array = new Uint32Array(1);
    max_range = 4294967296;
  }
  array = cryptoObj.getRandomValues(array);

  if (array[0] >= Math.floor(max_range / range) * range || min + (array[0] % range) >= max_range) {
    return randomIntegerWithRange(min, max, bytes);
  }
  return min + (array[0] % range);
};

const checkIfArrayIsConsecutiveAscOrDesc = (valueArray: (number | null)[], ascending: boolean) =>
  !!valueArray.reduce((last: number | null, current: number | null) =>
    last !== null && (ascending ? current === last + 1 : current === last - 1) ? current : null,
  );

// Check if number is consecutively ascending or decending, https://stackoverflow.com/a/62672935
export const checkIfNumberIsNotConsecutiveAscOrDesc = (value: string) => {
  const valueArray = Array.from(value, Number);
  return !(
    checkIfArrayIsConsecutiveAscOrDesc(valueArray, true) || checkIfArrayIsConsecutiveAscOrDesc(valueArray, false)
  );
};

// Check if all the digits are same, https://stackoverflow.com/a/28095535
export const checkIfNotRepDigits = (value: string) => !/^([0-9])\1*$/.test(value);
