import {
  GetAddFundsV2Content,
  GetAddFundsV2Content_all_add_funds_modal_items_fields,
  GetAddFundsV2Content_all_add_funds_modal_items_fields_AddFundsModalFieldsAmount,
  GetAddFundsV2Content_all_add_funds_modal_items_fields_AddFundsModalFieldsBankAccount,
  GetAddFundsV2Content_all_add_funds_modal_items_fields_AddFundsModalFieldsContributionYear,
  GetAddFundsV2Content_all_add_funds_modal_items_fields_AddFundsModalFieldsFrequency,
  GetAddFundsV2ContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export type Field = GetAddFundsV2Content_all_add_funds_modal_items_fields;
export type AmountField = GetAddFundsV2Content_all_add_funds_modal_items_fields_AddFundsModalFieldsAmount;
export type FrequencyField = GetAddFundsV2Content_all_add_funds_modal_items_fields_AddFundsModalFieldsFrequency;
export type BankAccountField = GetAddFundsV2Content_all_add_funds_modal_items_fields_AddFundsModalFieldsBankAccount;
export type ContributionYearField = GetAddFundsV2Content_all_add_funds_modal_items_fields_AddFundsModalFieldsContributionYear;

export const isAmountField = (field: Field | null): field is AmountField =>
  field?.__typename === 'AddFundsModalFieldsAmount';

export const isFrequencyField = (field: Field | null): field is FrequencyField =>
  field?.__typename === 'AddFundsModalFieldsFrequency';

export const isBankAccountField = (field: Field | null): field is BankAccountField =>
  field?.__typename === 'AddFundsModalFieldsBankAccount';

export const isContributionYearField = (field: Field | null): field is ContributionYearField =>
  field?.__typename === 'AddFundsModalFieldsContributionYear';

export const useGetAddFundsV2Content = (
  options?: QueryHookOptions<GetAddFundsV2Content, GetAddFundsV2ContentVariables>,
) => {
  return useContentstackQuery(queries.GetAddFundsV2Content, options);
};

export const useLazyGetAddFundsV2Content = (
  options?: QueryHookOptions<GetAddFundsV2Content, GetAddFundsV2ContentVariables>,
) => {
  return useLazyContentstackQuery(queries.GetAddFundsV2Content, options);
};
