import React, { FC } from 'react';

import { Props as GoalsCardProps } from '.';

import { Divider } from '~/components/ui/Divider';
import { Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useIsMediumScreen } from '~/utils/responsiveness';

type Props = Pick<GoalsCardProps, 'attributes' | 'dataQa'>;

export const Attributes: FC<Props> = ({ attributes, dataQa = 'goals-card-attributes' }) => {
  const isMobile = useIsMediumScreen();

  if (!attributes?.length) {
    return null;
  }

  return (
    <Stack
      data-qa={dataQa}
      direction={{ xs: 'column', md: 'row' }}
      divider={
        <Divider
          flexItem
          orientation={isMobile ? 'horizontal' : 'vertical'}
          sx={{ width: { xs: '50%', md: 'auto' } }}
        />
      }
      spacing={{ xs: 1, md: 3 }}
    >
      {attributes.map(({ label, value }) => (
        <Stack key={label} textAlign={{ xs: 'left', md: 'center' }}>
          <Typography component="div" data-qa={`${dataQa}-label`} variant="subtitle2">
            {label}
          </Typography>

          {typeof value === 'string' && (
            <Typography data-qa={`${dataQa}-value`} variant="body2">
              {value}
            </Typography>
          )}
          {typeof value !== 'string' && value}
        </Stack>
      ))}
    </Stack>
  );
};
