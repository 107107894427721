import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ACATRequestsTab } from './ACATRequestsTab';
import { AllAccountsTab } from './AllAccountsTab';
import { BankVerificationTab } from './BankVerificationTab';
import { ClosuresTab } from './ClosuresTab';
import { useGetOpsDashboardContentV2 } from './contentstack';
import { DepositsTab } from './DepositsTab';
import { DocusignTab } from './DocusignTab';
import { ErrorsTable } from './ErrorsTable';
import { FundingTab } from './FundingTab';
import { useTotals } from './hooks';
import { OpsDashboardProvider } from './OpsDashboardContext';
import { PendingModelChangesTab } from './PendingModelChangesTab';
import { RecurringCancellationsTab } from './RecurringCancellationsTab';
import { RestrictionsTable } from './RestrictionsTable';
import { TradingSuspendedTable } from './TradingSuspendedTable';
import { SearchContextLiterals, SidebarState } from './types';
import { commentColumnFactory, OpsDashboardUser } from './utils';
import { WithdrawalsTab } from './WithdrawalsTab';

import { FlagReason, FundingReviewStatus, TransferFrequency } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { MenuSideBar } from '~/components/ui/MenuSidebar';
import { Box, Breadcrumbs, Skeleton, Stack, Tab, TabContext, TabPanel, useTheme } from '~/components/ui/mui';
import { Tabs } from '~/components/ui/Tabs';
import { Typography } from '~/components/ui/Typography';
import { AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { useClientInfo } from '~/hooks/client/useClientInfo';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export enum OpsDashboardTabs {
  ACATRequests = 'ACATRequests',
  AllAccounts = 'AllAccounts',
  BankVerification = 'BankVerification',
  Closures = 'Closures',
  Deposits = 'Deposits',
  Docusign = 'Docusign',
  Errors = 'Errors',
  Funding = 'Funding',
  PendingModelChanges = 'ModelChanges',
  RecurringCancellations = 'RecurringCancellations',
  Restrictions = 'Restrictions',
  TradingSuspensions = 'TradingSuspensions',
  Withdrawals = 'Withdrawals',
}

export interface OpsDashboardParams {
  state?: string;
  tab?: OpsDashboardTabs;
}

export interface Props {
  acceptedStatusUpdatesForFundingTab?: FundingReviewStatus[];
  assetClassTier?: AssetClassTier;
  contentOptions: ContentOptions;
  dataQa?: string;
  getAccountNumberRedirectUrl: (partyId: string, managedProductId: string) => string;
  getClientNameRedirectUrl: (partyId: string) => string;
  hiddenFlagReasons?: FlagReason[];
  hiddenTabs?: OpsDashboardTabs[];
  inceptionDayForFundingTab?: number;
  onAccountClick: (partyId: string, managedProductId: string, initialTab?: AccountDetailsTabsEnum) => void;
  onClientClick: (partyId: string) => void;
  onParamsUpdate: (params: OpsDashboardParams, isReplace?: boolean) => void;
  params: OpsDashboardParams;
  partyId: string;
}

export const OpsDashboardV2: React.FC<Props> = ({
  acceptedStatusUpdatesForFundingTab = [],
  assetClassTier,
  dataQa = 'ops-dashboard',
  contentOptions,
  getAccountNumberRedirectUrl,
  getClientNameRedirectUrl,
  hiddenFlagReasons = [],
  hiddenTabs = [],
  inceptionDayForFundingTab = 0,
  onAccountClick,
  onClientClick,
  onParamsUpdate,
  params,
  partyId,
}) => {
  const {
    sfOpsDashboard: { styles: sfOpsDashboardStyles, typographyVariants: sfOpsDashboardTypographyVariants },
  } = useTheme<SfTheme>();

  const [loading, setLoading] = useState<boolean>(true);
  const [isSidebarClosed, setIsSideBarClosed] = useState<boolean>();

  const handleToggleSideBar = (value: SidebarState) => {
    setIsSideBarClosed(value === SidebarState.COLLAPSED);
  };

  const enabledTabs: OpsDashboardTabs[] = useMemo(
    () =>
      [
        OpsDashboardTabs.AllAccounts,
        OpsDashboardTabs.Withdrawals,
        OpsDashboardTabs.Deposits,
        OpsDashboardTabs.RecurringCancellations,
        OpsDashboardTabs.Closures,
        OpsDashboardTabs.ACATRequests,
        OpsDashboardTabs.TradingSuspensions,
        OpsDashboardTabs.Errors,
        OpsDashboardTabs.Funding,
        OpsDashboardTabs.Docusign,
        OpsDashboardTabs.PendingModelChanges,
        OpsDashboardTabs.BankVerification,
        OpsDashboardTabs.Restrictions,
      ].filter(tab => !hiddenTabs.includes(tab)),
    [hiddenTabs],
  );

  useEffect(() => {
    if (!params.tab) {
      onParamsUpdate({ tab: enabledTabs[0] }, true);
    }
  }, [enabledTabs, params, onParamsUpdate]);

  const { data: contentData, loading: contentLoading, error: contentError } = useGetOpsDashboardContentV2({
    variables: contentOptions,
  });

  const { data: clientData, loading: clientDataLoading, error: clientDataError } = useClientInfo({
    variables: { partyId },
  });

  const getTabLabel = (label: string | null | undefined, totalCount: number | null) => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography noWrap variant="body2">
          {label}
        </Typography>
        {totalCount !== null && <Typography variant="body2">{totalCount}</Typography>}
      </Box>
    );
  };

  const {
    totalAccounts,
    totalAccountsCountRefetch,
    totalACATRequests,
    totalDeposits,
    totalDepositsCountRefetch,
    totalWithdrawals,
    totalWithdrawalsCountRefetch,
    totalBankVerificationRequests,
    totalClosures,
    totalClosuresCountRefetch,
    totalSuspensions,
    totalSuspensionsCountRefetch,
    totalRestrictions,
    totalRestrictionsCountRefetch,
    totalFailures,
    totalDocusign,
    totalPendingModelChanges,
    totalRecurringCancellations,
    totalFailureRefetch,
    totalRecurringCancellationsRefetch,
    totalPendingModelChangeRefetch,
    totalFundingReviews,
    totalFundingCountRefetch,
  } = useTotals(inceptionDayForFundingTab, enabledTabs);
  const loadingStates = [contentLoading, clientDataLoading];

  useEffect(() => {
    if (!loadingStates.includes(true)) {
      setLoading(false);
    }
  }, loadingStates);

  const opsDashboardContent = contentData?.all_ops_dashboard?.items?.[0];
  const currentUser: OpsDashboardUser = { name: clientData?.userName ?? '', partyId };
  const commentColumn = commentColumnFactory(opsDashboardContent?.comment_column ?? undefined);
  const nullStateConfig = {
    message: findFieldValue(opsDashboardContent?.fields?.text, SearchContextLiterals.NO_ENTRIES),
    styles: sfOpsDashboardStyles.nullStateContent,
  };

  const currentTab = params.tab;
  const setCurrentTab = useCallback(
    tab => {
      onParamsUpdate({ tab });
    },
    [onParamsUpdate],
  );

  const handleTabChange = useCallback(
    (tab: OpsDashboardTabs) => {
      setCurrentTab(tab);
      switch (tab) {
        case OpsDashboardTabs.AllAccounts:
          return totalAccountsCountRefetch();
        case OpsDashboardTabs.Closures:
          return totalClosuresCountRefetch();
        case OpsDashboardTabs.Deposits:
          return totalDepositsCountRefetch();
        case OpsDashboardTabs.Errors:
          return totalFailureRefetch();
        case OpsDashboardTabs.Funding:
          return totalFundingCountRefetch();
        case OpsDashboardTabs.PendingModelChanges:
          return totalPendingModelChangeRefetch();
        case OpsDashboardTabs.RecurringCancellations:
          return totalRecurringCancellationsRefetch();
        case OpsDashboardTabs.TradingSuspensions:
          return totalSuspensionsCountRefetch();
        case OpsDashboardTabs.Restrictions:
          return totalRestrictionsCountRefetch();
        case OpsDashboardTabs.Withdrawals:
          return totalWithdrawalsCountRefetch();
        default:
          return;
      }
    },
    [
      totalAccountsCountRefetch,
      setCurrentTab,
      totalClosuresCountRefetch,
      totalDepositsCountRefetch,
      totalFailureRefetch,
      totalFundingCountRefetch,
      totalPendingModelChangeRefetch,
      totalRecurringCancellationsRefetch,
      totalSuspensionsCountRefetch,
      totalRestrictionsCountRefetch,
      totalWithdrawalsCountRefetch,
    ],
  );

  const tabStyles = { ...sfOpsDashboardStyles.tab, padding: '5px 10px', marginBottom: '5px', borderRadius: '6px' };

  const getCurrentTabLabel = () => {
    const formattedCurrentTab = currentTab?.toLowerCase();
    return findFieldValue(opsDashboardContent?.fields?.text, `${formattedCurrentTab}`);
  };

  return (
    <OpsDashboardProvider
      value={{
        commentColumn,
        opsContentData: contentData,
        contentOptions,
        currentUser,
        hiddenFlagReasons,
        onAccountClick,
        onClientClick,
        onParamsUpdate,
        getAccountNumberRedirectUrl,
        getClientNameRedirectUrl,
        params,
      }}
    >
      <Box data-qa={dataQa}>
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : contentError || clientDataError ? (
          <Alert contentOptions={contentOptions} error={contentError || clientDataError} severity="error" />
        ) : currentTab ? (
          <TabContext value={currentTab}>
            <Stack flexDirection="row" height="calc(100vh - 100px)">
              <MenuSideBar onSidebarToggle={handleToggleSideBar}>
                <Typography
                  sx={{ display: ' flex', alignItems: 'center', padding: '10px' }}
                  variant={sfOpsDashboardTypographyVariants?.heading ?? 'h3'}
                >
                  {opsDashboardContent?.header}
                </Typography>
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                  data-qa={`${dataQa}-tabs`}
                  onChange={handleTabChange}
                  orientation="vertical"
                  sx={{
                    paddingTop: '24px',
                    '& .MuiTabs-scroller': {
                      overflowY: 'auto!important',
                      height: 'calc(100vh - 220px)',
                    },
                    ...sfOpsDashboardStyles.tabs,
                  }}
                  value={currentTab}
                  visibleScrollbar
                >
                  {enabledTabs.includes(OpsDashboardTabs.AllAccounts) && (
                    <Tab
                      data-qa={`${dataQa}-tab-all-accounts`}
                      label={getTabLabel(opsDashboardContent?.tabs?.all_accounts, totalAccounts)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.AllAccounts}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.Withdrawals) && (
                    <Tab
                      data-qa={`${dataQa}-tab-withdrawals`}
                      label={getTabLabel(opsDashboardContent?.tabs?.withdrawals, totalWithdrawals)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.Withdrawals}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.Deposits) && (
                    <Tab
                      data-qa={`${dataQa}-tab-deposits`}
                      label={getTabLabel(opsDashboardContent?.tabs?.deposits, totalDeposits)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.Deposits}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.RecurringCancellations) && (
                    <Tab
                      data-qa={`${dataQa}-tab-recurring-cancellations`}
                      label={getTabLabel(
                        opsDashboardContent?.tabs?.recurring_cancellations,
                        totalRecurringCancellations,
                      )}
                      sx={tabStyles}
                      value={OpsDashboardTabs.RecurringCancellations}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.Closures) && (
                    <Tab
                      data-qa={`${dataQa}-tab-closure`}
                      label={getTabLabel(opsDashboardContent?.tabs?.closures, totalClosures)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.Closures}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.ACATRequests) && (
                    <Tab
                      data-qa={`${dataQa}-tab-acatRequests`}
                      label={getTabLabel(opsDashboardContent?.tabs?.acat_requests, totalACATRequests)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.ACATRequests}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.TradingSuspensions) && (
                    <Tab
                      data-qa={`${dataQa}-tab-trading-suspensions`}
                      label={getTabLabel(opsDashboardContent?.tabs?.suspensions, totalSuspensions)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.TradingSuspensions}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.Restrictions) && (
                    <Tab
                      data-qa={`${dataQa}-restrictions`}
                      label={getTabLabel(opsDashboardContent?.tabs?.restrictions, totalRestrictions)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.Restrictions}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.Errors) && (
                    <Tab
                      data-qa={`${dataQa}-tab-failures`}
                      label={getTabLabel(opsDashboardContent?.tabs?.failures, totalFailures)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.Errors}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.Funding) && (
                    <Tab
                      data-qa={`${dataQa}-tab-funding`}
                      label={getTabLabel(opsDashboardContent?.tabs?.funding, totalFundingReviews)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.Funding}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.Docusign) && (
                    <Tab
                      data-qa={`${dataQa}-tab-docusign`}
                      label={getTabLabel(opsDashboardContent?.tabs?.docusign, totalDocusign)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.Docusign}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.PendingModelChanges) && (
                    <Tab
                      data-qa={`${dataQa}-tab-model-change`}
                      label={getTabLabel(opsDashboardContent?.tabs?.pending_model_changes, totalPendingModelChanges)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.PendingModelChanges}
                    />
                  )}
                  {enabledTabs.includes(OpsDashboardTabs.BankVerification) && (
                    <Tab
                      data-qa={`${dataQa}-tab-bank-verification`}
                      label={getTabLabel(opsDashboardContent?.tabs?.bank_verification, totalBankVerificationRequests)}
                      sx={tabStyles}
                      value={OpsDashboardTabs.BankVerification}
                    />
                  )}
                </Tabs>
              </MenuSideBar>
              <Box overflow="scroll" sx={{ px: 2, bgcolor: 'grey.50' }} width="100%">
                {isSidebarClosed && (
                  <Breadcrumbs aria-label="breadcrumb" sx={{ pt: 2.5, pl: 4.5 }}>
                    <Typography color="text.primary">{opsDashboardContent?.header}</Typography>
                    <Typography color="text.primary">{getCurrentTabLabel()}</Typography>
                  </Breadcrumbs>
                )}
                <TabPanel value={OpsDashboardTabs.AllAccounts}>
                  <AllAccountsTab contentOptions={contentOptions} nullStateConfig={nullStateConfig} />
                </TabPanel>
                <TabPanel value={OpsDashboardTabs.Withdrawals}>
                  <WithdrawalsTab
                    commentColumn={commentColumn}
                    contentOptions={contentOptions}
                    currentUser={currentUser}
                    getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
                    getClientNameRedirectUrl={getClientNameRedirectUrl}
                    nullStateConfig={nullStateConfig}
                    onAccountClick={onAccountClick}
                    onClientClick={onClientClick}
                    onUpdate={item => {
                      totalWithdrawalsCountRefetch();
                      if (item.frequency !== TransferFrequency.ONE_TIME) {
                        totalRecurringCancellationsRefetch();
                      }
                    }}
                  />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.Deposits}>
                  <DepositsTab
                    commentColumn={commentColumn}
                    contentOptions={contentOptions}
                    currentUser={currentUser}
                    getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
                    getClientNameRedirectUrl={getClientNameRedirectUrl}
                    nullStateConfig={nullStateConfig}
                    onAccountClick={onAccountClick}
                    onClientClick={onClientClick}
                    onUpdate={item => {
                      totalDepositsCountRefetch();
                      if (item.frequency !== TransferFrequency.ONE_TIME) {
                        totalRecurringCancellationsRefetch();
                      }
                    }}
                  />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.RecurringCancellations}>
                  <RecurringCancellationsTab
                    commentColumn={commentColumn}
                    contentOptions={contentOptions}
                    currentUser={currentUser}
                    getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
                    getClientNameRedirectUrl={getClientNameRedirectUrl}
                    nullStateConfig={nullStateConfig}
                    onAccountClick={onAccountClick}
                    onClientClick={onClientClick}
                    onUpdate={() => totalRecurringCancellationsRefetch()}
                  />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.Closures}>
                  <ClosuresTab
                    commentColumn={commentColumn}
                    contentOptions={contentOptions}
                    currentUser={currentUser}
                    getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
                    getClientNameRedirectUrl={getClientNameRedirectUrl}
                    nullStateConfig={nullStateConfig}
                    onAccountClick={onAccountClick}
                    onClientClick={onClientClick}
                  />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.ACATRequests}>
                  <ACATRequestsTab
                    commentColumn={commentColumn}
                    contentOptions={contentOptions}
                    currentUser={currentUser}
                    getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
                    getClientNameRedirectUrl={getClientNameRedirectUrl}
                    nullStateConfig={nullStateConfig}
                    onAccountClick={onAccountClick}
                    onClientClick={onClientClick}
                  />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.TradingSuspensions}>
                  <TradingSuspendedTable contentOptions={contentOptions} nullStateConfig={nullStateConfig} />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.Restrictions}>
                  <RestrictionsTable contentOptions={contentOptions} nullStateConfig={nullStateConfig} />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.Errors}>
                  <ErrorsTable
                    commentColumn={commentColumn}
                    contentOptions={contentOptions}
                    currentUser={currentUser}
                    getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
                    getClientNameRedirectUrl={getClientNameRedirectUrl}
                    nullStateConfig={nullStateConfig}
                    onAccountClick={onAccountClick}
                    onClientClick={onClientClick}
                    onUpdate={() => totalFailureRefetch()}
                  />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.Funding}>
                  <FundingTab
                    acceptedStatusUpdations={acceptedStatusUpdatesForFundingTab}
                    dataQa="oped-dashboard-funding-tab"
                    fetchDataFromDay={inceptionDayForFundingTab}
                    nullStateConfig={nullStateConfig}
                  />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.Docusign}>
                  <DocusignTab contentOptions={contentOptions} nullStateConfig={nullStateConfig} />
                </TabPanel>

                <TabPanel value={OpsDashboardTabs.PendingModelChanges}>
                  <PendingModelChangesTab
                    assetClassTier={assetClassTier}
                    commentColumn={commentColumn}
                    contentOptions={contentOptions}
                    currentUser={currentUser}
                    getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
                    getClientNameRedirectUrl={getClientNameRedirectUrl}
                    nullStateConfig={nullStateConfig}
                    onAccountClick={onAccountClick}
                    onClientClick={onClientClick}
                    onUpdate={() => totalPendingModelChangeRefetch()}
                  />
                </TabPanel>

                {enabledTabs.includes(OpsDashboardTabs.BankVerification) && (
                  <TabPanel value={OpsDashboardTabs.BankVerification}>
                    <BankVerificationTab contentOptions={contentOptions} nullStateConfig={nullStateConfig} />
                  </TabPanel>
                )}
              </Box>
            </Stack>
          </TabContext>
        ) : (
          <></>
        )}
      </Box>
    </OpsDashboardProvider>
  );
};
