import { RecommendedProductContentBands } from './contentstack';

import { RiskBand } from '~/components/RiskSpectrum';

/**
 * Creates an object containing an array of risk bands. The risk band is derived from the data received from the CMS.
 * @param contentBands - The CMS risk bands data.
 * @returns - The array of risk bands.
 */
export const getRiskBands = (contentBands: (RecommendedProductContentBands | null)[]): RiskBand[] =>
  contentBands.map(b => ({
    riskScoreFloor: b?.band?.risk_score_floor ?? -1,
    riskScoreCeiling: b?.band?.risk_score_ceiling ?? -1,
    description: b?.band?.description ?? '',
    label: b?.band?.label ?? '',
    labelSummary: b?.band?.label_summary,
    targetAllocations: b?.band?.target_allocations ?? '',
  }));
