import * as queries from './query.gql';

import {
  GetBankVerificationModalContent,
  GetBankVerificationModalContentVariables,
} from '~/components/modals/BankVerification/contentstack/__generated__/query.v2';
import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetBankVerificationModalContent = (
  options?: QueryHookOptions<GetBankVerificationModalContent, GetBankVerificationModalContentVariables>,
) => {
  return useContentstackQuery(queries.GetBankVerificationModalContent, options);
};
