import { ComponentProps, useMemo, useState } from 'react';

import { ControlFieldSection } from '../components/ControlFieldSection';

import { useDateFilterData } from '~/components/ControlFields/DateFilters/hooks/useDateFilterData';
import { DateFilterValues } from '~/components/ControlFields/DateFilters/types';

type ControlFieldConfig = ComponentProps<typeof ControlFieldSection>['data']['config'];
type ControlFieldValues = ComponentProps<typeof ControlFieldSection>['data']['values'];
type ControlFieldSectionData = ComponentProps<typeof ControlFieldSection>['data'];

export const useControlFieldSectionData = (
  defaultControlFieldConfig: ControlFieldConfig,
  defaultControlFieldValues: ControlFieldValues,
  onValueChange?: (values: ControlFieldValues) => void,
): ControlFieldSectionData => {
  const [values, setValues] = useState<ControlFieldValues>(defaultControlFieldValues);

  const onDateFilterValueChange = (value: DateFilterValues) => {
    setValues(prev => {
      onValueChange?.({ ...prev, ...value });
      return {
        ...prev,
        ...value,
      };
    });
  };

  const {
    config: dateFilterConfig,
    onCustomDateRangeChange,
    onDateRangeOptionChange,
    onYearOptionChange,
  } = useDateFilterData(defaultControlFieldConfig, defaultControlFieldValues, onDateFilterValueChange);

  const config = useMemo((): ControlFieldConfig => {
    return { ...dateFilterConfig };
  }, [dateFilterConfig]);

  return useMemo(
    (): ControlFieldSectionData => ({
      config,
      onCustomDateRangeChange,
      onDateRangeOptionChange,
      onYearOptionChange,
      values,
    }),
    [config, onCustomDateRangeChange, onDateRangeOptionChange, onYearOptionChange, values],
  );
};
