import { MutationResult } from '@apollo/client';
import { useCallback } from 'react';

import { AbandonDigitalWealth, AbandonDigitalWealthVariables } from './symphony/__generated__/abandonDigitalWealth.v2';
import { AbandonDigitalWealth as AbandonDigitalWealthMutation } from './symphony/abandonDigitalWealth.gql';

import { updateGetDigitalWealthAccountsCache } from '~/containers/AccountSummary/symphony/utils';
import { useUnassociatePartial } from '~/hooks/goals/useUnassociatePartial';
import { MutationHookOptions } from '~/utils/apollo-client';
import { useCoreConfig } from '~/utils/config';
import { useSymphonyMutation } from '~/utils/symphony';

type Variables = MutationHookOptions<AbandonDigitalWealth, AbandonDigitalWealthVariables> & {
  partyId: string;
};
type Data = [(managedProductId: string) => Promise<void>, Pick<MutationResult, 'error' | 'loading'>];

export const useAbandonDigitalWealth = ({ partyId, ...rest }: Variables): Data => {
  const {
    featureFlags: {
      goals: { enableGoalsFeature },
    },
  } = useCoreConfig();
  const [unassociatePartialFromGoal, unassociatePartialFromGoalResult] = useUnassociatePartial();
  const [abandonDigitalWealth, abandonDigitalWealthResult] = useSymphonyMutation(AbandonDigitalWealthMutation, rest);

  const callback = useCallback(
    async managedProductId => {
      if (enableGoalsFeature) {
        await unassociatePartialFromGoal([partyId], managedProductId);
      }
      await abandonDigitalWealth({
        variables: { managedProductId },
        update: (cache, { data }) => {
          if (data) {
            updateGetDigitalWealthAccountsCache({
              cache,
              data,
              partyId,
              managedProductId,
            });
          }
        },
      });
    },
    [abandonDigitalWealth, enableGoalsFeature, partyId, unassociatePartialFromGoal],
  );

  return [
    callback,
    {
      error: unassociatePartialFromGoalResult.error ?? abandonDigitalWealthResult.error,
      loading: unassociatePartialFromGoalResult.loading || abandonDigitalWealthResult.loading,
    },
  ];
};
