import React, { FC } from 'react';

import { useTaxWitholdingAllocation } from '../TaxWithholdingForm/hooks';

import { FinancialAccountType, TransferFrequency } from '~/__generated__';
import {
  withdrawalAmountName,
  withdrawalDateName,
  withdrawalDayOfMonthName,
  withdrawalDayOfWeekName,
  withdrawalFrequencyName,
} from '~/components/modals/WithdrawFunds/WithdrawFundsForm';
import { TaxWithholdingPlaybackContent } from '~/components/modals/WithdrawFunds/WithdrawFundsModalContent';
import {
  DISTRIBUTION_REASON_CODES,
  federalTaxWithholdingName,
  federalTaxWithholdingPercentageName,
  grossUpFlagName,
  stateTaxWithholdingName,
  stateTaxWithholdingPercentageName,
} from '~/components/TaxWithholdingForm';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Box, Grid, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { Weekday } from '~/hooks/financial-account/symphony';
import { BankAccount, isInheritedRetirementAccount } from '~/utils/account';
import { formatCurrencyPrecise } from '~/utils/format';
import { getOrdinalNumber } from '~/utils/format/currency';
import { formatDate } from '~/utils/format/date';
import { SfTheme } from '~/utils/theme';

export interface Props {
  bankAccounts?: BankAccount[];
  clientAge: number | null;
  content: TaxWithholdingPlaybackContent;
  dataQa?: string;
  distributionReason?: string | null;
  financialAccountType?: FinancialAccountType;
  formData: any;
  frequencyDropdownOptions?: DropdownItem[];
  isCloseAccount?: boolean;
  prematureAgeContent?: number | null;
  symphonySubmitErrorMessage?: string;
}

export interface DistributionReason {
  death: string | null;
  normal: string | null;
  premature: string | null;
}

export const getDistributionReason = (
  content: DistributionReason | null | undefined,
  financialAccountType: FinancialAccountType | undefined,
  distributionReason?: string,
) =>
  isInheritedRetirementAccount(financialAccountType as FinancialAccountType)
    ? content?.death
    : distributionReason === DISTRIBUTION_REASON_CODES.PREMATURE
    ? content?.premature
    : content?.normal;

export const TaxWithholdingPlayback: FC<Props> = ({
  dataQa = 'withdraw-funds-playback',
  bankAccounts,
  clientAge,
  prematureAgeContent,
  content,
  distributionReason,
  financialAccountType,
  formData,
  frequencyDropdownOptions,
  isCloseAccount = false,
  symphonySubmitErrorMessage,
}) => {
  const {
    sfTaxWithholdingPlayback: { root },
  } = useTheme<SfTheme>();
  const { moneyYouGet, totalWithdrawalAmount } = useTaxWitholdingAllocation({
    formData,
    federalTaxPercent: parseFloat(formData?.federalTaxWithholdingPercentage),
    stateTaxPercent: parseFloat(formData?.stateTaxWithholdingPercentage),
    coverTaxWithholding: formData?.[grossUpFlagName],
  });
  const frequency = formData?.withdrawalFrequency;

  const getWithdrawalInfoValues = (formKey: any) => {
    if (formKey === withdrawalAmountName) {
      return formatCurrencyPrecise(totalWithdrawalAmount);
    } else if (Object.values(TransferFrequency).includes(formData?.[formKey])) {
      return frequencyDropdownOptions?.find(option => option.value === formData?.[formKey])?.label;
    } else if (formKey === withdrawalDateName) {
      return formData?.[formKey] && frequency === TransferFrequency.ONE_TIME
        ? formatDate(formData?.[formKey])
        : frequency === TransferFrequency.WEEKLY
        ? Weekday[formData[withdrawalDayOfWeekName]]
        : frequency === TransferFrequency.MONTHLY
        ? `On the ${getOrdinalNumber(formData[withdrawalDayOfMonthName])}`
        : content?.other_labels?.asap;
    } else {
      return `Account ${
        bankAccounts?.find(acc => acc.id === formData?.eligibleDestinationBankAccount)?.maskedAccountNumber
      }`;
    }
  };

  const getTaxWithholdingValues = (formKey: string | null | undefined) => {
    if (formKey === federalTaxWithholdingPercentageName || formKey === stateTaxWithholdingPercentageName) {
      return formData?.[formKey] && formData?.[formKey].toString().includes('%')
        ? formData?.[formKey]
        : `${formData?.[formKey]}%`;
    } else if (formKey === federalTaxWithholdingName) {
      return content?.federal_withholding_options?.find(option => formData?.[formKey] === option?.key)?.value;
    } else if (formKey === stateTaxWithholdingName) {
      return content?.state_withholding_options?.find(option => formData?.[formKey] === option?.key)?.value;
    } else if (formKey === grossUpFlagName) {
      return formData?.[formKey] ? content?.gross_up_labels?.yes : content?.gross_up_labels?.no;
    } else {
      if (distributionReason) {
        return getDistributionReason(content?.distribution_labels, financialAccountType, distributionReason);
      } else if (clientAge && prematureAgeContent) {
        return clientAge < prematureAgeContent
          ? content?.distribution_labels?.premature
          : content?.distribution_labels?.normal;
      }
    }
  };

  /*
  Returns string/text based on the formkey and these formkeys are mapped in content-stack
  to have the rows as loop instead of separate row for every formData key-value pair
  Eg: We have 'withdrawalAmount' as key value in *withdrawal_info* content stack entry
  and we have the same key name in formData we receive from parent component. Based on
  that we're mapping 'label' and 'value' here. Similarly we're doing in all of the three sections.
   */
  const getWithdrawalAmountValues = (formKey: any) => {
    if (formKey === withdrawalAmountName) {
      return formatCurrencyPrecise(totalWithdrawalAmount);
    } else if (formData && formData[formKey]) {
      return `${formatCurrencyPrecise((parseFloat(formData[formKey]) / 100) * totalWithdrawalAmount)}`;
    } else {
      return formatCurrencyPrecise(0);
    }
  };

  const fieldsHiddenForCloseAccount = [withdrawalDateName, withdrawalFrequencyName, grossUpFlagName];

  return (
    <>
      {symphonySubmitErrorMessage && <Alert severity="error">{symphonySubmitErrorMessage}</Alert>}
      <Divider />
      <Box data-qa={`${dataQa}-playback-container`} id="withdraw-funds-playback" sx={{ px: 2, py: 2, ...root }}>
        <Grid container data-qa={`${dataQa}-withdrawal-info`} spacing={2} sx={{ pt: 1, pb: 2 }}>
          {content?.withdrawal_info?.map((field, index) => (
            <Grid data-qa={`${dataQa}-info-item-${index}`} item key={field?.key} sm={6} xs={12}>
              {((isCloseAccount && !fieldsHiddenForCloseAccount.includes(field?.key ?? '')) || !isCloseAccount) && (
                <>
                  <Typography
                    component="label"
                    data-qa={`${dataQa}-withdrawal-info-item-${index}-label`}
                    htmlFor={`${dataQa}-withdrawal-info-item-${index}-value`}
                    id={`${dataQa}-withdrawal-info-item-${index}-label`}
                    sx={{ color: 'text.secondary' }}
                    variant="subtitle2"
                  >
                    <RteContent data={field?.label || 'MISSING LABEL'} />
                  </Typography>
                  <Typography
                    aria-labelledby={`${dataQa}-withdrawal-info-item-${index}-label`}
                    data-qa={`${dataQa}-withdrawal-info-item-${index}-value`}
                    id={`${dataQa}-withdrawal-info-item-${index}-value`}
                  >
                    {getWithdrawalInfoValues(field?.key)}
                  </Typography>
                </>
              )}
            </Grid>
          ))}
        </Grid>
        <Divider />
        <Grid container data-qa={`${dataQa}-tax-withholding`} spacing={2} sx={{ pt: 2, pb: 2 }}>
          {content?.tax_withholding?.map((field, index) => (
            <Grid data-qa={`${dataQa}-tax-withholding-item-${index}`} item key={field?.key} sm={6} xs={12}>
              {((isCloseAccount && !fieldsHiddenForCloseAccount.includes(field?.key ?? '')) || !isCloseAccount) && (
                <>
                  <Typography
                    component="label"
                    data-qa={`${dataQa}-tax-withholding-item-${index}-label`}
                    htmlFor={`${dataQa}-tax-withholding-item-${index}-value`}
                    id={`${dataQa}-tax-withholding-item-${index}-label`}
                    sx={{ color: 'text.secondary' }}
                    variant="subtitle2"
                  >
                    <RteContent data={field?.label || 'MISSING LABEL'} />
                  </Typography>
                  <Typography
                    aria-labelledby={`${dataQa}-tax-withholding-item-${index}-label`}
                    data-qa={`${dataQa}-tax-withholding-item-${index}-value`}
                    id={`${dataQa}-tax-withholding-item-${index}-value`}
                  >
                    {getTaxWithholdingValues(field?.key)}
                  </Typography>
                </>
              )}
            </Grid>
          ))}
        </Grid>
        <Divider />
        <Grid data-qa={`${dataQa}-withdrawal-details`} sx={{ pt: 2, pb: 2 }}>
          <Typography
            component="h3"
            data-qa={`${dataQa}-withdrawal-details-label`}
            sx={{ color: 'text.secondary', mb: 1 }}
            variant="subtitle2"
          >
            {content?.other_labels?.withdrawal_details_label}
          </Typography>
          {content?.withdrawal_details?.map((field, index) => (
            <Grid
              container
              data-qa={`${dataQa}-withdrawal-details-items`}
              key={field?.key}
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
            >
              <Typography
                component="label"
                data-qa={`${dataQa}-withdrawal-details-item-${index}-label`}
                htmlFor={`${dataQa}-withdrawal-details-item-${index}-value`}
                id={`${dataQa}-withdrawal-details-item-${index}-label`}
                variant="body2"
              >
                <RteContent data={field?.label || 'MISSING LABEL'} />
              </Typography>
              <Typography
                aria-labelledby={`${dataQa}-withdrawal-details-item-${index}-label`}
                data-qa={`${dataQa}-withdrawal-details-item-${index}-value`}
                id={`${dataQa}-withdrawal-details-item-${index}-value`}
                variant="body2"
              >
                {getWithdrawalAmountValues(field?.key)}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ marginBottom: 0.5 }} />
        <Divider />
        <Grid
          data-qa={`${dataQa}-money-you-get`}
          sx={{ pt: 2, pb: 2, display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography
            component="label"
            data-qa={`${dataQa}-money-you-get-label`}
            htmlFor={`${dataQa}-money-you-get-value`}
            id={`${dataQa}-money-you-get-label`}
            sx={{ color: 'text.primary' }}
            variant="subtitle1"
          >
            <RteContent data={content?.other_labels?.money_you_get_label || 'MISSING LABEL'} />
          </Typography>
          <Typography
            aria-labelledby={`${dataQa}-money-you-get-label`}
            data-qa={`${dataQa}-money-you-get-value`}
            id={`${dataQa}-money-you-get-value`}
            sx={{ color: 'text.primary' }}
            variant="subtitle1"
          >
            {formatCurrencyPrecise(moneyYouGet)}
          </Typography>
        </Grid>
        <RteContent
          color="text.secondary"
          data={
            isCloseAccount
              ? content?.footer_disclaimers?.close_modal ?? ''
              : content?.footer_disclaimers?.withdraw_modal ?? ''
          }
          data-qa={`${dataQa}-footer-disclaimer`}
        />
      </Box>
    </>
  );
};
