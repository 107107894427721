import omit from 'lodash.omit';
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { ControlFieldSection } from '../components/ControlFieldSection';
import { TableSection } from '../components/TableSection';
import { useLazyGetClientDocumentsData } from '../symphony';

import { getFormattedRows } from './utils/tableSection';

import { DocumentCategoryEnum, OffsetInput, SortOrder } from '~/__generated__';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { toSymphonyDate } from '~/utils/symphony';
import { AsyncResult } from '~/utils/types';

type ControlFieldValues = ComponentProps<typeof ControlFieldSection>['data']['values'];
type TableSectionData = ComponentProps<typeof TableSection>['data'];
type TableSectionContent = ComponentProps<typeof TableSection>['content'];

interface Variables {
  clientDocumentsPerPage: number;
  content?: TableSectionContent;
  contentOptions: ContentOptions;
  documentCategory: DocumentCategoryEnum;
  fieldValues: ControlFieldValues;
  managedProductId: string;
  onDownloadError?: (error: string) => void;
}

const defaultPaginationOffset = { 1: [] };

export const useTableSectionData = ({
  clientDocumentsPerPage,
  content,
  contentOptions,
  documentCategory,
  fieldValues,
  managedProductId,
  onDownloadError,
}: Variables): AsyncResult<TableSectionData> => {
  const [currentPage, setCurrentPage] = useState(1);
  // Map of page number to pagination offset to be used to navigate to next page and any visited pages smoothly
  const [paginationOffset, setPaginationOffset] = useState<Record<number, OffsetInput[]>>(defaultPaginationOffset);

  const {
    components: {
      sfClientDocuments: { downloadDocumentBaseUrl },
    },
  } = useCoreConfig();

  const [getClientDocumentsData, { data, error, loading }] = useLazyGetClientDocumentsData({
    variables: {
      dateSortingOrder: SortOrder.DESCENDING,
      documentCategory,
      fromDate: toSymphonyDate(fieldValues.dateRange?.startDate ?? ''),
      managedProductId,
      paginationContext: {
        limit: clientDocumentsPerPage,
        offset: paginationOffset[currentPage],
      },
      toDate: toSymphonyDate(fieldValues.dateRange?.endDate ?? ''),
    },
    // Required to refetch the response on documentCategory and/or dateRange updates because pagination depends on the response
    fetchPolicy: 'no-cache',
  });

  const onPageChange = useCallback((_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    if (!loading) {
      const hasMoreDocuments = !!data?.clientDocumentList?.paginationContext.hasMoreDocuments;
      // Update the pagination offset if there are more documents, it will be used for the next page call
      if (hasMoreDocuments) {
        setPaginationOffset(prev => ({
          ...prev,
          [currentPage + 1]:
            data.clientDocumentList?.paginationContext.offset?.map(item => omit(item, '__typename')) ?? [],
        }));
      }
    }
  }, [currentPage, data?.clientDocumentList, loading]);

  useEffect(() => {
    setCurrentPage(1);
    setPaginationOffset(defaultPaginationOffset);
    getClientDocumentsData();
  }, [fieldValues, getClientDocumentsData]);

  return useMemo((): AsyncResult<TableSectionData> => {
    return {
      data: {
        paginationProps: {
          count: Object.keys(paginationOffset).length, // Show visited pages and +1 page if more rows are available
          onChange: onPageChange,
          page: currentPage,
        },
        tableData: getFormattedRows({
          content,
          contentOptions,
          downloadDocumentBaseUrl,
          onDownloadError,
          unformattedData: data?.clientDocumentList?.documentsData,
        }),
      },
      error,
      loading,
    };
  }, [
    content,
    contentOptions,
    currentPage,
    data,
    downloadDocumentBaseUrl,
    error,
    loading,
    onDownloadError,
    onPageChange,
    paginationOffset,
  ]);
};
