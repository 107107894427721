import React from 'react';

import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { ClosureStatusFilter } from '~/containers/OpsDashboard/ClosuresTab/types';

export type ClosuresStatusFilterProps = {
  currentStatusFilter: ClosureStatusFilter;
  displayStrings: {
    statusFilter: Record<ClosureStatusFilter, string>;
  };
  onChange: (newStatusFilter: ClosureStatusFilter) => void;
};

export const ClosuresStatusFilter: React.FC<ClosuresStatusFilterProps> = ({
  currentStatusFilter,
  displayStrings: { statusFilter },
  onChange,
}) => {
  const items: DropdownItem[] = [
    {
      value: ClosureStatusFilter.ALL,
      label: statusFilter[ClosureStatusFilter.ALL],
    },
    {
      value: ClosureStatusFilter.PENDING,
      label: statusFilter[ClosureStatusFilter.PENDING],
    },
    {
      value: ClosureStatusFilter.IN_PROGRESS,
      label: statusFilter[ClosureStatusFilter.IN_PROGRESS],
    },
    {
      value: ClosureStatusFilter.COMPLETED,
      label: statusFilter[ClosureStatusFilter.COMPLETED],
    },
    {
      value: ClosureStatusFilter.CANCELLED,
      label: statusFilter[ClosureStatusFilter.CANCELLED],
    },
  ];

  return (
    <Dropdown
      dataQa="closures-status-filter"
      items={items}
      onChange={e => onChange(e.target.value as ClosureStatusFilter)}
      value={currentStatusFilter}
      width="130px"
    />
  );
};
