import { compareDesc, parseISO } from 'date-fns';
import { ComponentProps } from 'react';

import { AccountTypeContent } from '../OpsDashboard/ErrorsTable/types';

import { AccountListAccount } from './AccountList';
import { AccountTotalSection } from './AccountTotalSection';
import { AccountPendingStates } from './contentstack';
import { AccountSummaryTradingSuspensions, isEntityUpdateWorkflow, isPlanUpdateWorkflow } from './symphony';
import {
  AccountSummaryGetDigitalWealthAccounts,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_accountRestrictions,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_stagedModelPortfolio,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_stagedModelPortfolio_CompositeRecommendedPortfolio,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_stagedModelPortfolio_RecommendedPortfolio,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio_CompositeRecommendedPortfolio,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio_RecommendedPortfolio,
} from './symphony/__generated__/query.v2';
import { findManagedProduct } from './symphony/utils';
import { AccountRestriction } from './types';

import {
  AccountRestrictionType,
  BalanceType,
  FinancialAccountStatus,
  FinancialAccountType,
  OnboardingStates,
  Scalars as SymphonyScalars,
  SleeveType,
} from '~/__generated__';
import { DiscardPendingChangeContent } from '~/components/modals/DiscardPendingChange';
import { GetClientInfo_client_party_partyPerson } from '~/hooks/client/symphony/__generated__/query.v2';
import {
  GetBankAccounts,
  GetBankAccounts_client_financialAccounts,
} from '~/hooks/financial-account/symphony/__generated__/query.v2';
import {
  AccountState,
  BankAccount,
  getBankAccountId,
  getPlaidMaskedAccountNumber,
  getPrimaryClientPartyId,
  getUpdatedFinancialInstituion,
  isValidBankAccount,
  sortBankAccounts,
} from '~/utils/account';
import { getFullName } from '~/utils/client';
import { Locale, Product } from '~/utils/contentstack';
import { findMostRecentDate } from '~/utils/date';
import { formatMaskedAccountNumber, toPascalCase } from '~/utils/format';
import { abbreviatedDateFormat, allNumericDateFormat } from '~/utils/format/date';

export interface PendingChangeCardContent {
  cta: {
    approve: string;
    resendDocusign: string;
    signDocuments: string;
  };
  discardPendingChange: DiscardPendingChangeContent;
  genericMessage: string;
  header: string;
  message: string;
  resendEmailSentMessage: string;
}

export interface AccountSummaryFeatureFlags {
  defaultContributionAmount?: string;
  ignoreInsufficientFunds?: boolean;
  performanceChartTo?: 'today' | 'syncedOn';
  redirectToAccountDetailsIfSingleAccount?: boolean;
  showVerifiedBankAccounts?: boolean;
  syncExternalBankAccounts?: boolean;
}

export interface Account extends AccountListAccount {
  availableWithdrawalBalance?: number;
  effectiveDate?: string;
  onboardingState?: OnboardingStates;
  suspendedOn?: string;
  syncedOn: string;
}

export interface RebalancedAccount extends Omit<Account, 'firstRebalancedOn' | 'id' | 'status'> {
  firstRebalancedOn: SymphonyScalars['Date'];
  id: string;
  status: FinancialAccountStatus.ACTIVE;
}

const isBankAccount = (account: GetBankAccounts_client_financialAccounts): boolean =>
  (!!account.id || !!account.isFromExternalSource) &&
  new Set([FinancialAccountType.CHEQUING, FinancialAccountType.SAVINGS]).has(account.type);

export const accountActionsTlhKeys = ['tlhOn', 'tlhOff'];

export const getAccounts = (
  locale: Locale,
  accountsData: AccountSummaryGetDigitalWealthAccounts,
  partyId: string,
  modelPortfolioNameParser: (seriesBaseName: string, internalId: string) => string,
  tradingSuspensionsFilter?: (suspension: AccountSummaryTradingSuspensions) => boolean,
): Account[] =>
  accountsData.client?.financialAccounts
    ?.reduce<Account[]>((acc, financialAccount) => {
      const managedProduct = findManagedProduct(financialAccount);
      if (!managedProduct) {
        return acc;
      }

      const {
        accountMinimums,
        accountRestrictions,
        accountType,
        assignedOn,
        attributes,
        createWorkflow,
        entityUpdateWorkflow,
        firstRebalancedOn,
        fundingDetails,
        funds,
        id: managedProductId,
        latestRiskPreference,
        legalDocuments,
        planId,
        planUpdateWorkflows,
        program,
        relatedParties,
        questionnaires,
        stagedModelPortfolio,
        targetModelPortfolio,
        tradingSuspensions,
        isFinancialAccountAssociationPending,
      } = managedProduct;

      const sortedTradingSuspensions = tradingSuspensions
        .filter(suspension => tradingSuspensionsFilter?.(suspension) ?? true)
        .sort((a, b) => {
          return compareDesc(parseISO(a.createdAt), parseISO(b.createdAt));
        });

      const hasCompositeModelPortfolio = targetModelPortfolio
        ? !!getTargetCompositeModelPortfolioData(targetModelPortfolio)
        : !!getStagedCompositeModelPortfolioData(stagedModelPortfolio);

      const targetModelPortfolioData = getTargetModelPortfolioData(targetModelPortfolio);
      const stagedModelPortfolioData = getStagedModelPortfolioData(stagedModelPortfolio);
      const modelPortfolioSeriesBaseName = targetModelPortfolio
        ? targetModelPortfolioData?.seriesBaseName
        : stagedModelPortfolioData?.seriesBaseName;
      const modelPortfolioInternalId = targetModelPortfolio
        ? targetModelPortfolioData?.internalId
        : stagedModelPortfolioData?.internalId;
      const allocations = targetModelPortfolio
        ? targetModelPortfolio.guidance?.diversification?.assets.allocations
        : stagedModelPortfolio?.guidance?.diversification?.assets.allocations;

      const accountBalance = financialAccount.balances?.find(balance => balance.type === BalanceType.TOTAL_ACCOUNT);

      if (!acc.find(a => a.managedProductId === managedProductId)) {
        acc.push({
          accountMinimum: accountMinimums?.minimumBalanceForAccountOpening,
          accountMinimumForRebalance: accountMinimums?.minimumBalanceForRebalance,
          accountNumber: financialAccount.accountNumber ?? '',
          accountRestrictions,
          assignedOn: assignedOn ?? 'Unknown date time',
          donutAllocations:
            allocations?.map(allocation => {
              return {
                asset: {
                  name: allocation.asset.name,
                },
                broadAssetClass: allocation.broadAssetClass ?? '',
                enclosingAssetClass: allocation.enclosingAssetClass ?? '',
                targetAllocation: allocation.targetAllocation,
              };
            }) || [],
          attributes,
          availableWithdrawalBalance: parseFloat(
            funds?.availableWithdrawalValueBySleeve?.find(sleeve => sleeve.sleeveType === SleeveType.INVESTMENT)
              ?.availableValue ?? '0',
          ),
          balance: accountBalance?.balance.value,
          billingRestriction: getBillingRestriction(
            locale,
            accountRestrictions.find(restriction => restriction.restrictionType === AccountRestrictionType.BILLING),
          ),
          effectiveDate: accountBalance?.effectiveDate ?? undefined,
          entityUpdateWorkflow: entityUpdateWorkflow?.filter(isEntityUpdateWorkflow),
          firstRebalancedOn: firstRebalancedOn ?? undefined,
          id: financialAccount.id ?? undefined,
          hasCompositeModelPortfolio,
          lastRtqDate: latestRiskPreference?.date ?? undefined,
          legalDocuments: legalDocuments ?? undefined,
          managedProductId,
          maskedAccountNumber: financialAccount.maskedAccountNumber ?? '',
          modelPortfolioName: modelPortfolioNameParser(
            modelPortfolioSeriesBaseName ?? '',
            modelPortfolioInternalId?.toString() ?? '',
          ),
          onboardingState: createWorkflow?.onboardingState,
          partyId,
          planId,
          planUpdateWorkflows: planUpdateWorkflows.filter(isPlanUpdateWorkflow),
          program,
          questionnaire: {
            // The questionnaires may be null when includeMigrated is false in AccountSummaryGetDigitalWealthAccounts query
            completedOn: questionnaires?.[0]?.completedOn ?? undefined,
            isMigrated: questionnaires?.[0]?.isMigrated ?? undefined,
          },
          relationshipNames: financialAccount.relationships ? financialAccount.relationships.map(r => r.name) : [],
          primaryClientPartyId: getPrimaryClientPartyId(relatedParties ?? undefined),
          primaryOnboardingRepPartyId: createWorkflow?.initiatingAdvisorPartyId ?? undefined,
          scheduledTransfers: fundingDetails.scheduledTransfers,
          status: financialAccount.status,
          tradingSuspensions,
          suspendedOn:
            sortedTradingSuspensions.length > 0
              ? allNumericDateFormat(parseISO(sortedTradingSuspensions[0].createdAt), { locale })
              : undefined,
          syncedOn: financialAccount.syncedOn ?? '',
          type: accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
          isFinancialAccountAssociationPending: isFinancialAccountAssociationPending ?? false,
        });
      }

      return acc;
    }, [])
    .sort((a, b) => {
      return a.maskedAccountNumber > b.maskedAccountNumber ? 1 : a.maskedAccountNumber < b.maskedAccountNumber ? -1 : 0;
    }) ?? [];

export const getBankAccounts = (
  bankAccountsData?: GetBankAccounts,
  linkedBankAccounts?: BankAccount[],
): BankAccount[] =>
  sortBankAccounts([
    ...(bankAccountsData?.client?.financialAccounts
      ?.filter(isBankAccount)
      ?.filter(isValidBankAccount)
      ?.map(params => {
        const {
          attributes,
          balances,
          financialInstitution,
          accountNumber,
          id,
          isFromExternalSource,
          maskedAccountNumber,
          type,
        } = params;
        const plaidMaskedAccountNumber = getPlaidMaskedAccountNumber(attributes);
        return {
          ...params,
          balances: balances ?? [],
          financialInstitution: getUpdatedFinancialInstituion(financialInstitution, type),
          id: getBankAccountId(id, isFromExternalSource, accountNumber) || 'Unknown',
          isFromExternalSource,
          maskedAccountNumber: formatMaskedAccountNumber(undefined, plaidMaskedAccountNumber ?? maskedAccountNumber),
        };
      }) ?? []),
    ...(linkedBankAccounts ?? []),
  ]);

export const getRebalancedAccounts = (accounts: Account[]): RebalancedAccount[] =>
  accounts.filter(
    (account): account is RebalancedAccount =>
      account.status === FinancialAccountStatus.ACTIVE && !!account.firstRebalancedOn && !!account.id,
  );

export const getPartialAccount = (accounts: Account[]): Account | undefined =>
  accounts.find(({ status }) => status === FinancialAccountStatus.PARTIAL);

export const getAccountTypeText = (type: FinancialAccountType, content: (AccountTypeContent | null)[]): string =>
  content.find(item => item?.key === type)?.text || 'Unknown';

// Ideally, this should not be used - we should allow Contentstack to decide fullName vs firstName in RTEContent
export const getClientNameToDisplay = (
  partyPerson: GetClientInfo_client_party_partyPerson | null | undefined,
  product: Product,
): string | undefined => {
  if (partyPerson) {
    switch (product) {
      case Product.DigitalWealth:
        return partyPerson.givenName ?? undefined;
      case Product.DigitalWealthPro:
      case Product.OpsDashboard:
        return getFullName(partyPerson);
      default:
        return undefined;
    }
  } else {
    return undefined;
  }
};

export const getPendingStateDescriptions = (content: AccountPendingStates | null | undefined): any =>
  Object.entries(content ?? {}).reduce((acc: Record<string, string>, [key, value]) => {
    if (key !== '__typename') {
      acc[toPascalCase(key)] = value ?? '';
    }
    return acc;
  }, {});

export const findMostRecentEffectiveDate = (accounts: Account[]): string | undefined =>
  findMostRecentDate(accounts.map(a => a.effectiveDate));

export const getTargetModelPortfolioData = (
  targetModelPortfolio?: AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio | null,
): AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio_RecommendedPortfolio | null => {
  return targetModelPortfolio?.__typename === 'RecommendedPortfolio' ? targetModelPortfolio : null;
};

export const getTargetCompositeModelPortfolioData = (
  targetModelPortfolio: AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio | null,
): AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio_CompositeRecommendedPortfolio | null => {
  return targetModelPortfolio?.__typename === 'CompositeRecommendedPortfolio' ? targetModelPortfolio : null;
};

export const getStagedModelPortfolioData = (
  stagedModelPortfolio: AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_stagedModelPortfolio | null,
): AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_stagedModelPortfolio_RecommendedPortfolio | null => {
  return stagedModelPortfolio?.__typename === 'RecommendedPortfolio' ? stagedModelPortfolio : null;
};

export const getStagedCompositeModelPortfolioData = (
  stagedModelPortfolio: AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_stagedModelPortfolio | null,
): AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_stagedModelPortfolio_CompositeRecommendedPortfolio | null => {
  return stagedModelPortfolio?.__typename === 'CompositeRecommendedPortfolio' ? stagedModelPortfolio : null;
};

/**
 * AccountStates for general Core use cases.  Partner specifics may vary.
 */
// An activated account viewable on details page (ie: seeDetails action)
export const getActivatedAccountStates = (): AccountState[] => [
  AccountState.PendingClosed,
  AccountState.RebalanceCompleted,
  AccountState.Suspended,
  AccountState.PendingModelPortfolioChange,
];

// For RCE account actions like Edit, Transfers, etc.
export const getActiveAccountStates = (): AccountState[] => [
  AccountState.RebalancePending,
  AccountState.RebalanceCompleted,
  AccountState.Suspended,
];

// For Add Funds cta
export const getFundableAccountStates = (): AccountState[] => [
  AccountState.FundingBelowMinimum,
  AccountState.FundingError,
  AccountState.FundingPending,
  ...getActivatedAccountStates(),
];

const getBillingRestriction = (
  locale: Locale,
  accountRestriction?: AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_accountRestrictions,
): AccountRestriction | undefined => {
  if (!accountRestriction) {
    return undefined;
  }
  return {
    id: accountRestriction.id,
    createdAt: abbreviatedDateFormat(parseISO(accountRestriction.createdAt), { locale }),
    lastUpdatedByPartyId: accountRestriction.lastUpdatedByPartyId ?? undefined,
    restrictionType: accountRestriction.restrictionType,
    updatedAt: abbreviatedDateFormat(parseISO(accountRestriction.updatedAt), { locale }),
  };
};

export const getFinancialAccountsForGoalSummary = (data?: AccountSummaryGetDigitalWealthAccounts) =>
  (data?.client?.financialAccounts ?? []).reduce<
    NonNullable<ComponentProps<typeof AccountTotalSection>['goalSummaryProps']>['financialAccounts']
  >((acc, account) => {
    const managedProduct = findManagedProduct(account);
    const balance = account.balances?.find(({ type }) => type === BalanceType.TOTAL_ACCOUNT)?.balance.value;
    acc.push({
      accountType: managedProduct?.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
      id: account.id ?? undefined,
      balance: balance ? parseFloat(balance) : undefined,
      managedProductId: managedProduct?.id,
      maskedAccountNumber: account.maskedAccountNumber ?? undefined,
    });
    return acc;
  }, []);
