import {
  GetComparisonContent,
  GetComparisonContent_all_comparison_items,
  GetComparisonContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ComparisonContent = GetComparisonContent_all_comparison_items | null | undefined;
export type ComparisonContentConfirmationModalType = 'Keep' | 'Change';

export const useGetComparisonContent = (
  options?: QueryHookOptions<GetComparisonContent, GetComparisonContentVariables>,
): QueryResult<GetComparisonContent, GetComparisonContentVariables> => {
  return useContentstackQuery(queries.GetComparisonContent, options);
};
