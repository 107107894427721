import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { checkForInitialInvestmentAmount, getFundingAmountHelperText } from '../utils';

import { HoldingsTableField } from './HoldingsTableField';
import { calulateTotalEstimatedValue, Props } from './utils';

import { Divider } from '~/components/ui/Divider';
import { Box } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { RowSection } from '~/containers/Funding/RowSection';
import { getAlert } from '~/containers/Funding/utils';
import { useCoreConfig } from '~/utils/config';
import { formatCurrency } from '~/utils/format';

export const HoldingsTable: React.FC<Props> = ({
  defaultValues,
  initialInvestmentAmountCheck,
  holdingSourceFieldName,
  maximumInvestmentAmount,
  content,
  holdings,
  financialInstitution,
  maskedAccountNumber,
  optionalMessageFieldName,
  optionalMessageLabel,
  optionalMessageMaxLength,
  showDividerBelowLabel = true,
  validations,
  ...rest
}) => {
  const [errorInputs, setErrorInputs] = useState<string[]>([]);
  const { control, errors, watch } = useFormContext();
  const selectedItems = watch(holdingSourceFieldName);
  const totalEstimatedValue = calulateTotalEstimatedValue(selectedItems ?? {});
  const optionalMessage: string | undefined =
    optionalMessageMaxLength && optionalMessageFieldName && watch(optionalMessageFieldName);
  const {
    components: {
      sfFunding: { custodianMaximumDailyLimit },
    },
  } = useCoreConfig();
  const getHelperText = useCallback(() => {
    if (content.investmentAmountFieldContent) {
      const {
        errors: contentErrors,
        significantAmount,
        minimumAmount,
        onBackCTAText,
      } = content.investmentAmountFieldContent;
      return getFundingAmountHelperText(
        totalEstimatedValue.toString(),
        custodianMaximumDailyLimit ?? 0,
        holdingSourceFieldName,
        errors,
        contentErrors,
        minimumAmount,
        initialInvestmentAmountCheck,
        onBackCTAText,
        significantAmount,
      );
    } else {
      return null;
    }
  }, [
    custodianMaximumDailyLimit,
    content.investmentAmountFieldContent,
    totalEstimatedValue,
    errors,
    holdingSourceFieldName,
    initialInvestmentAmountCheck,
  ]);
  return (
    <>
      {content.customSectionLabel ? (
        <RteContent
          config={{ account: `${financialInstitution} ${maskedAccountNumber}` }}
          data={content.customSectionLabel}
          sx={{ mb: 4 }}
        />
      ) : (
        <Box sx={{ mb: 4 }}>
          <RowSection heading={content.sectionLabel} />
        </Box>
      )}
      {showDividerBelowLabel && <Divider />}
      <Controller
        control={control}
        defaultValue={defaultValues ?? {}}
        name={holdingSourceFieldName}
        render={({ onChange, value }) => (
          <HoldingsTableField
            {...rest}
            content={content}
            errorInputs={errorInputs}
            financialInstitution={financialInstitution}
            holdingSourceFieldName={holdingSourceFieldName}
            holdings={holdings}
            maskedAccountNumber={maskedAccountNumber}
            onChange={onChange}
            setErrorInputs={setErrorInputs}
            totalEstimatedValue={formatCurrency(totalEstimatedValue)}
            value={value}
          />
        )}
        rules={{
          validate: {
            aboveInitialInvestmentAmount: input =>
              !validations.includes('aboveInitialInvestmentAmount') ||
              checkForInitialInvestmentAmount(
                calulateTotalEstimatedValue(input).toString(),
                0,
                initialInvestmentAmountCheck,
              ),
            belowInitialInvestmentAmount: input =>
              !validations.includes('belowInitialInvestmentAmount') ||
              checkForInitialInvestmentAmount(
                calulateTotalEstimatedValue(input).toString(),
                0,
                initialInvestmentAmountCheck,
                true,
              ),
            maximumAmount: input =>
              !validations.includes('belowInitialInvestmentAmount') ||
              !maximumInvestmentAmount ||
              calulateTotalEstimatedValue(input) <= maximumInvestmentAmount,
            noItemSelected: input =>
              !validations.includes('noItemSelected') || (!!input && !!Object.keys(input).length),
          },
        }}
      />
      {!errorInputs.length &&
        (errors[holdingSourceFieldName]?.type === 'noItemSelected'
          ? getAlert(content.noItemSelected, holdingSourceFieldName, 'error')
          : getHelperText())}
      {optionalMessageFieldName && (
        <Box alignItems="flex-end" display="flex" flexDirection="column" mt={2}>
          <Box width="30%">
            <Controller
              control={control}
              name={optionalMessageFieldName}
              render={({ onChange, value }) => (
                <TextField
                  fullWidth
                  label={optionalMessageLabel}
                  onChange={e => {
                    if (optionalMessageMaxLength && e.target.value.length > optionalMessageMaxLength) {
                      return;
                    }
                    onChange(e);
                  }}
                  value={value}
                />
              )}
            />
          </Box>
          {optionalMessageMaxLength && (
            <Typography variant="caption">{`${optionalMessage?.length ?? 0}/${optionalMessageMaxLength}`}</Typography>
          )}
        </Box>
      )}
    </>
  );
};
