import { UpdatePlanUpdateWorkflow, UpdatePlanUpdateWorkflowVariables } from './__generated__/mutation.v2';
import * as mutations from './mutation.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useUpdatePlanUpdateWorkflow = (
  options?: MutationHookOptions<UpdatePlanUpdateWorkflow, UpdatePlanUpdateWorkflowVariables>,
): MutationTuple<UpdatePlanUpdateWorkflow, UpdatePlanUpdateWorkflowVariables> => {
  return useSymphonyMutation(mutations.UpdatePlanUpdateWorkflow, options);
};
