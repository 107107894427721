import { GetBankVerificationContentV2, GetBankVerificationContentV2Variables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetBankVerificationContentV2 = (
  options?: QueryHookOptions<GetBankVerificationContentV2, GetBankVerificationContentV2Variables>,
): QueryResult<GetBankVerificationContentV2, GetBankVerificationContentV2Variables> => {
  return useContentstackQuery(queries.GetBankVerificationContentV2, options);
};
