import {
  GetAssetClassContent,
  GetAssetClassContent_all_asset_class_data_items,
  GetAssetClassContentVariables,
} from './__generated__/query.v2';
import * as assetClassContentQuery from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type AssetClassContentItem = GetAssetClassContent_all_asset_class_data_items;
export const useGetAssetClassContent = (
  options?: QueryHookOptions<GetAssetClassContent, GetAssetClassContentVariables>,
): QueryResult<GetAssetClassContent, GetAssetClassContentVariables> => {
  return useContentstackQuery(assetClassContentQuery.GetAssetClassContent, options);
};
