import {
  GetTradingSuspensionsContent,
  GetTradingSuspensionsContent_all_trading_suspensions_table_items_columns_column,
  GetTradingSuspensionsContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export interface TradingSuspensionTableColumn {
  column_id: string | null;
  column_order: number | null;
  column_value: string | null;
}
export type tableItemsColumns =
  | (GetTradingSuspensionsContent_all_trading_suspensions_table_items_columns_column | null)[]
  | null;
export const useGetTradingSuspensionsContent = (
  options?: QueryHookOptions<GetTradingSuspensionsContent, GetTradingSuspensionsContentVariables>,
): QueryResult<GetTradingSuspensionsContent, GetTradingSuspensionsContentVariables> => {
  return useContentstackQuery(queries.GetTradingSuspensionsContent, options);
};
