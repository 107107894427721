import React from 'react';

import { IndicatorLabels, PortfolioBand } from '.';

import { Chip } from '~/components/ui/Chip';
import { Card, FiberManualRecordIcon, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { DiversificationBlockContent } from '~/hooks/questionnaire/contentstack';
import { formatPercentageString } from '~/utils/format/string';

export interface Props {
  bandIndex: number;
  dataQa?: string;
  diversificationBlockContent?: DiversificationBlockContent | null;
  indicatorLabels?: IndicatorLabels;
  isRecommended?: boolean;
  portfolioBands?: PortfolioBand[];
}

export const DiversificationBlock: React.FC<Props> = ({
  dataQa,
  bandIndex,
  diversificationBlockContent,
  indicatorLabels,
  isRecommended,
  portfolioBands,
}) => {
  const {
    sfRiskSpectrum: { styles: sfRiskSpectrumStyles, typographyVariants },
    palette,
  } = useTheme();

  if (portfolioBands) {
    return (
      <Card
        data-qa={dataQa}
        sx={{
          borderColor: `${isRecommended ? sfRiskSpectrumStyles.borderLighter : palette.primary.main}`,
          position: 'relative',
          overflow: 'visible',
          py: 5,
          textAlign: 'center',
        }}
        variant="outlined"
      >
        <Chip
          label={isRecommended ? indicatorLabels?.new : indicatorLabels?.user}
          sx={{
            background: isRecommended ? 'white' : palette.primary.main,
            border: `1px solid ${isRecommended ? sfRiskSpectrumStyles.borderLight : palette.primary.main}`,
            color: isRecommended ? 'inherit' : 'white',
            left: 'calc(50% - 50px)',
            position: 'absolute',
            top: -16,
          }}
        />
        <Typography pr={0.5} sx={{ display: 'inline' }} variant={typographyVariants?.percent}>
          {formatPercentageString(portfolioBands[bandIndex].stockPercentage)}
        </Typography>
        <Typography sx={{ color: 'text.secondary', display: 'inline' }} variant="caption">
          {diversificationBlockContent?.equities}
        </Typography>
        <FiberManualRecordIcon sx={{ color: palette.primary.main, mx: 2, height: 10 }} />
        <Typography pr={0.5} sx={{ display: 'inline' }} variant={typographyVariants?.percent}>
          {formatPercentageString(portfolioBands[bandIndex].bondPercentage)}
        </Typography>
        <Typography sx={{ color: 'text.secondary', display: 'inline' }} variant="caption">
          {diversificationBlockContent?.fixed_income}
        </Typography>
      </Card>
    );
  } else {
    return null;
  }
};
