import { useGetAccountDetailsOverviewContent } from '../contentstack';

import { Content, getContent } from './utils';

import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

export const useAccountDetailsOverviewContent = ({ contentOptions }: Variables): AsyncResult<Content> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetAccountDetailsOverviewContent({
    variables: contentOptions,
  });

  return {
    loading: contentLoading,
    error: contentError,
    data: getContent(contentData),
  };
};
