import { GetPricingContent, GetPricingContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetPricingContent = (
  options?: QueryHookOptions<GetPricingContent, GetPricingContentVariables>,
): QueryResult<GetPricingContent, GetPricingContentVariables> => {
  return useContentstackQuery(queries.GetPricingContent, options);
};
