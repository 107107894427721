import React, { ComponentProps, useMemo, useState } from 'react';

import { useContent } from './hooks/useContent';
import { useTabComponent } from './hooks/useTabComponent';
import { InitialAccountDetailsState } from './types';

import { AssociatedEntityType, ManagedProductStatus, ManagedProductType } from '~/__generated__/symphonyTypes.v2';
import { AccountActions } from '~/components/AccountActions';
import { useAccountActionModals } from '~/components/AccountActions/hooks';
import { AccountHeader } from '~/components/AccountHeader';
import { AccountRestrictions } from '~/components/AccountRestrictions';
import { AdvisorInfo } from '~/components/AdvisorInfo';
import { ClientInfo } from '~/components/ClientInfo';
import { ErrorComponent } from '~/components/ErrorComponent';
import { Box, Skeleton, Stack, useTheme } from '~/components/ui/mui';
import { FeedbackMessageType, Snackbar } from '~/components/ui/Snackbar';
import { TabsContainer } from '~/components/ui/TabsContainer';
import { useAccountDetailsData } from '~/hooks/account-details/hooks/useAccountDetailsData';
import { useAddFunds } from '~/hooks/account-details/hooks/useAddFunds';
import { FinancialAccountManagedProductTradingSuspensions } from '~/hooks/account-details/symphony';
import { AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { MeasurementName, ReturnsByPeriod } from '~/hooks/performance/types';
import { getAccountState, getPrimaryClientPartyId, isPendingPlanUpdateWorkflowStatus } from '~/utils/account';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  actions: ComponentProps<typeof AccountActions>['config'];
  contentOptions: ContentOptions;
  dataQa?: string;
  feedbackMessageType?: string;
  getAssetClassTier?: (program: ManagedProductType) => AssetClassTier;
  initialState?: InitialAccountDetailsState;
  initialTimePeriod?: MeasurementName;
  managedProductId: string;
  onDocumentSigningTriggered?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    state?: string,
  ) => void;
  onRetakeQuestionnaire?: (managedProductId: string) => void;
  onViewPerformanceReport?: (managedProductId: string) => void;
  partyId: string;
  partyIdFA?: string;
  returnsDataOverride?: ReturnsByPeriod;
  tradingSuspensionsFilter?: (suspension: FinancialAccountManagedProductTradingSuspensions) => boolean;
  viewerPartyId?: string;
}

export const AccountDetailsV2: React.FC<Props> = ({
  actions,
  contentOptions,
  dataQa = 'account-details-v2',
  feedbackMessageType,
  getAssetClassTier,
  initialState,
  initialTimePeriod = MeasurementName.QTD,
  managedProductId,
  onDocumentSigningTriggered,
  onRetakeQuestionnaire,
  onViewPerformanceReport,
  partyId,
  partyIdFA,
  tradingSuspensionsFilter,
  returnsDataOverride,
  viewerPartyId,
}) => {
  const { sfAccountDetails } = useTheme();
  const {
    components: {
      sfAccountDetailsV2: {
        defaultContributionAmount,
        hiddenSections,
        ignoreInsufficientFunds,
        plaidLinkageType,
        showNameAsText,
        showPhoneNumber,
        showVerifiedBankAccounts,
        skipAccountMinimums,
        syncExternalBankAccounts,
        skipAccountFundsInformation,
        tabs,
      },
    },
    featureFlags: { isSuspendedAccountStateSupported, showErrorComponentOnInvalidPartyId },
  } = useCoreConfig();
  const showClientInfo = !hiddenSections.includes('clientInfo');

  const [performanceViewOption, setPerformanceViewOption] = useState<string>();

  const { data: content, loading: contentLoading, error: contentError } = useContent({
    contentOptions,
  });

  const {
    account,
    bankAccounts,
    clientError,
    digitalWealthAccountsData,
    digitalWealthAccountsDataError,
    digitalWealthAccountsInitialLoading,
    isRefetchingAccounts,
    managedProduct,
    onLinkAccountCallback,
    onRemoveAccount,
    refetchDigitalWealthAccountsData,
  } = useAccountDetailsData({
    accountNumberFormat: content?.accountNumberFormat,
    detailedAccounts: [managedProductId],
    managedProductId,
    partyId,
    isVerified: showVerifiedBankAccounts,
    skipAccountFundsInformation,
    skipAccountMinimums,
    skipTradingSuspensions: false,
    skipRestrictions: !tabs.includes(AccountDetailsTabsEnum.restrictions),
    syncExternalBankAccounts,
  });

  const primaryAdvisor = digitalWealthAccountsData?.client?.party?.primaryAdvisor;
  // only used for activated account states
  const { state: accountState, data: accountStateData } = getAccountState({
    financialAccountStatus: managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS,
    hasPendingPlanUpdateWorkflow: managedProduct?.planUpdateWorkflows.some(p =>
      isPendingPlanUpdateWorkflowStatus(p?.status),
    ),
    firstRebalancedOn: managedProduct?.firstRebalancedOn ?? undefined,
    isSuspendedAccountStateSupported,
    primaryClientPartyId: getPrimaryClientPartyId(managedProduct?.relatedParties ?? []),
  });

  const {
    addFunds: addFundsActionModal,
    withdrawFunds: { modal: withdrawFundsModal },
    closeAccount: { modal: closeAccountModal },
  } = useAccountActionModals();

  const { addFundsModal, onAddFundsClick } = useAddFunds({
    account,
    addFundsAction: actions.addFunds,
    addFundsActionModal,
    managedProductId,
    partyId,
  });

  const { getTabComponent, startingTab, tabsData } = useTabComponent({
    account,
    accountState,
    actions,
    bankAccounts,
    contentOptions,
    digitalWealthAccountsInitialLoading,
    feedbackMessageType,
    getAssetClassTier,
    initialState,
    initialTimePeriod,
    managedProduct,
    managedProductId,
    onAddFundsClick,
    onLinkAccountCallback,
    onRemoveAccount,
    onDocumentSigningTriggered,
    partyId,
    refetchDigitalWealthAccountsData,
    returnsDataOverride,
    tabs,
    tabsContent: content?.tabHeader,
  });

  const accountHeaderFeatureFlags = useMemo(
    () => ({
      defaultContributionAmount,
      ignoreInsufficientFunds,
      showVerifiedBankAccounts,
      syncExternalBankAccounts,
    }),
    [defaultContributionAmount, ignoreInsufficientFunds, showVerifiedBankAccounts, syncExternalBankAccounts],
  );

  const feedbackMessage = useMemo(() => {
    const feedbackMessageFromModal =
      addFundsModal.feedbackMessage || withdrawFundsModal.feedbackMessage || closeAccountModal.feedbackMessage;

    if (feedbackMessageFromModal) {
      return feedbackMessageFromModal;
    }

    if (feedbackMessageType && content && feedbackMessageType === FeedbackMessageType.EDIT_PORTFOLIO) {
      return content.editPortfolioFeedbackMessage;
    }

    return undefined;
  }, [addFundsModal, closeAccountModal, content, feedbackMessageType, withdrawFundsModal]);

  const loading = contentLoading || digitalWealthAccountsInitialLoading;
  const error = clientError ?? contentError ?? digitalWealthAccountsDataError;

  return (
    <Box data-qa={dataQa} sx={{ ...sfAccountDetails.root }}>
      {loading && (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}
      {error && (
        <ErrorComponent
          contentOptions={contentOptions}
          error={clientError ?? error}
          isAuthorizationError={showErrorComponentOnInvalidPartyId && !!clientError}
        />
      )}
      {!loading && !error && (
        <>
          {content?.accountDetailsHeader && (
            <AccountHeader
              account={account}
              accountState={accountState}
              accountStateData={accountStateData}
              actions={actions}
              addFundsActionModal={addFundsActionModal}
              bankAccounts={bankAccounts}
              content={content.accountDetailsHeader}
              contentOptions={contentOptions}
              digitalWealthAccountsData={digitalWealthAccountsData}
              digitalWealthAccountsInitialLoading={digitalWealthAccountsInitialLoading}
              featureFlags={accountHeaderFeatureFlags}
              isRefetchingAccounts={isRefetchingAccounts}
              managedProductId={managedProductId}
              onAddFundsClick={onAddFundsClick}
              onLinkAccountCallback={onLinkAccountCallback}
              onRetakeQuestionnaire={onRetakeQuestionnaire}
              onViewPerformanceReport={onViewPerformanceReport}
              partyId={partyId}
              partyIdFA={partyIdFA}
              performanceViewOption={performanceViewOption}
              plaidLinkageType={plaidLinkageType}
              redirectToSignDocuments={onDocumentSigningTriggered}
              refetchDigitalWealthAccountsData={refetchDigitalWealthAccountsData}
              setPerformanceViewOption={setPerformanceViewOption}
              tradingSuspensionsFilter={tradingSuspensionsFilter}
              viewerPartyId={viewerPartyId}
            />
          )}
          <Stack direction="row" sx={{ justifyContent: 'space-between', my: 4 }}>
            {showClientInfo && (
              <ClientInfo
                clientLabel={content?.clientLabel}
                contentOptions={contentOptions}
                hideIcons
                partyId={partyId}
                showDivider
                showLabel
                showNameAsText={showNameAsText}
                showPhoneNumber={showPhoneNumber}
              />
            )}
            {primaryAdvisor && <AdvisorInfo advisor={primaryAdvisor} advisorLabel={content?.advisorLabel} />}
          </Stack>
          {(!!managedProduct?.accountRestrictions.length || !!managedProduct?.tradingSuspensions?.length) &&
            content?.textFields && (
              <AccountRestrictions
                restrictions={managedProduct.accountRestrictions}
                textFields={content.textFields}
                tradingSuspensions={managedProduct.tradingSuspensions ?? []}
              />
            )}
          <TabsContainer
            getTabComponent={getTabComponent}
            initialTab={startingTab}
            tabSx={{ ml: 5, mr: 1 }}
            tabs={tabsData}
            variant="standard"
          />
          {feedbackMessage && <Snackbar feedbackMessage={feedbackMessage} />}
        </>
      )}
    </Box>
  );
};
