import { GetAccountErrorsModalContent_all_account_errors_modal_items } from '../contentstack/__generated__/query.v2';
import {
  AccountErrorsAssetTransfers,
  AccountErrorsCashTransfers,
  AccountErrorsFinancialAccounts,
  AccountErrorsFinancialAccountsBalances,
} from '../symphony';
import { GetAccountErrors, GetClientBrokerages } from '../symphony/__generated__/query.v2';

import { BalanceType } from '~/__generated__';
import { formatBalance } from '~/components/AccountPerformance/utils';
import { TableColumn, TableData } from '~/components/ui/BasicTable';
import { ErrorsFailureContent } from '~/containers/OpsDashboardV2/ErrorsTable/types';
import { ContentOptions } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';
import { ContentstackTableColumn, sortColumnsByColumnOrder } from '~/utils/table';

export enum TableType {
  MONEY_MOVEMENT_TABLE = 'MONEY_MOVEMENT_TABLE',
  TOA_TABLE = 'TOA_TABLE',
}

export const getTableColumns = (columns: ContentstackTableColumn[]) => {
  const sortedColumns = sortColumnsByColumnOrder(columns);
  return sortedColumns.map(
    (col): TableColumn => ({
      title: col.column_value,
      key: col.column_id ?? '',
    }),
  );
};

export const getScheduledAssetTransfersTableData = (
  accountErrorsData: GetAccountErrors | undefined,
  clientBrokeragesData: GetClientBrokerages | undefined,
  errorsRowData: ErrorsFailureContent | null | undefined,
  contentOptions: ContentOptions,
) => {
  const scheduledAssetTransfersData = accountErrorsData?.managedProduct?.fundingDetails.scheduledTransfers.filter(
    transfer => transfer.__typename === 'AssetTransfer',
  ) as AccountErrorsAssetTransfers[];

  const scheduledAssetTransferRowData =
    scheduledAssetTransfersData && scheduledAssetTransfersData.find(item => item.id === errorsRowData?.entityId);
  const isManualBrokerageEntry = !!scheduledAssetTransferRowData?.sourceFinancialAccountId;
  const manualBrokerageEntryRowData =
    clientBrokeragesData &&
    clientBrokeragesData.client?.financialAccounts?.find(
      (item: AccountErrorsFinancialAccounts) => item.id === scheduledAssetTransferRowData?.sourceFinancialAccountId,
    );
  const amount = isManualBrokerageEntry
    ? manualBrokerageEntryRowData?.balances?.find(
        (bal: AccountErrorsFinancialAccountsBalances) => bal.type === BalanceType.TOTAL_ACCOUNT,
      )?.balance.value
    : scheduledAssetTransferRowData?.total.value;
  return {
    rowKey: manualBrokerageEntryRowData?.id,
    brokerageName: isManualBrokerageEntry
      ? manualBrokerageEntryRowData?.financialInstitution
      : scheduledAssetTransferRowData?.sourceFinancialAccountBrokerageName,
    accountNumber: isManualBrokerageEntry
      ? manualBrokerageEntryRowData?.accountNumber
      : scheduledAssetTransferRowData?.sourceFinancialAccountAccountNumber,
    amount: formatBalance(`${amount}`, contentOptions),
  };
};

export const getScheduledCashTransfersTableData = (
  accountErrorsData: GetAccountErrors | undefined,
  errorsRowData: ErrorsFailureContent | null | undefined,
  contentOptions: ContentOptions,
) => {
  const scheduledCashTransfersData = accountErrorsData?.managedProduct?.fundingDetails.scheduledTransfers.filter(
    transfer => transfer.__typename === 'CashTransfer',
  ) as AccountErrorsCashTransfers[];
  let scheduledCashTransferRowData =
    scheduledCashTransfersData && scheduledCashTransfersData.find(item => item.id === errorsRowData?.entityId);
  scheduledCashTransferRowData = scheduledCashTransferRowData ?? scheduledCashTransfersData[0];
  return {
    rowKey: scheduledCashTransferRowData.id,
    bankAcctInfo: scheduledCashTransferRowData.bankAccount?.accountNumber,
    frequency: scheduledCashTransferRowData.frequency,
    scheduledDate: formatDate(`${scheduledCashTransferRowData.scheduledAt}`),
    amount: formatBalance(scheduledCashTransferRowData.total.value, contentOptions),
    type: scheduledCashTransferRowData.type,
  };
};

export const getTableData = (
  isTOA: boolean,
  item: GetAccountErrorsModalContent_all_account_errors_modal_items | null | undefined,
  accountErrorsData: GetAccountErrors | undefined,
  clientBrokeragesData: GetClientBrokerages | undefined,
  errorsRowData: ErrorsFailureContent | null | undefined,
  contentOptions: ContentOptions,
) => {
  if (!item || !accountErrorsData || !clientBrokeragesData) {
    return undefined;
  }
  if (isTOA) {
    const TOATableContent = item.table_columns?.table?.find(table => table?.type === TableType.TOA_TABLE);
    return {
      tableData: getScheduledAssetTransfersTableData(
        accountErrorsData,
        clientBrokeragesData,
        errorsRowData,
        contentOptions,
      ) as TableData,
      tableColumns: getTableColumns(TOATableContent?.columns as ContentstackTableColumn[]),
      header: TOATableContent?.header,
    };
  }
  const moneyMovementTableContent = item.table_columns?.table?.find(
    table => table?.type === TableType.MONEY_MOVEMENT_TABLE,
  );
  return {
    tableData: getScheduledCashTransfersTableData(accountErrorsData, errorsRowData, contentOptions) as TableData,
    tableColumns: getTableColumns(moneyMovementTableContent?.columns as ContentstackTableColumn[]),
    header: moneyMovementTableContent?.header,
  };
};
