import React, { ComponentProps } from 'react';

import { FilterSection } from './FilterSection';
import { useGetAccountActivityData } from './hooks/useGetAccountActivityData';
import { MobileFilter } from './MobileFilter';
import { TabState } from './types';

import { AddFunds } from '~/components/AddFunds';
import { DateRangeOptions } from '~/components/ControlFields/DateFilters/types';
import { ErrorComponent } from '~/components/ErrorComponent';
import { LoadingPage } from '~/components/LoadingPage';
import { Alert } from '~/components/ui/Alert';
import { BasicTable } from '~/components/ui/BasicTable';
import { Divider } from '~/components/ui/Divider';
import { Link } from '~/components/ui/Link';
import { Box, Button, DownloadIcon, FilterListIcon, Grid, Pagination, Skeleton, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Props {
  accountCreatedDate?: Date;
  contentOptions: ContentOptions;
  dataQa?: string;
  financialAccountId: string;
  initialTabState?: TabState;
  managedProductId: string;
  onAddFunds: ComponentProps<typeof AddFunds>['onAddFunds'];
  onUpdateTabState: (state: TabState) => void;
  partyId: string;
}

export const AccountDetailsAccountActivity: React.FC<Props> = ({
  accountCreatedDate,
  contentOptions,
  dataQa = 'account-activity',
  financialAccountId,
  initialTabState,
  managedProductId,
  onAddFunds,
  partyId,
  onUpdateTabState,
}) => {
  const { dateOptions } = useCoreConfig().components.sfAccountActivity;
  const isMediumScreen = useIsMediumScreen();
  const { data, error, loading } = useGetAccountActivityData({
    accountCreatedDate,
    contentOptions,
    dateOptions,
    financialAccountId,
    initialTabState,
    managedProductId,
    partyId,
    onUpdateTabState,
  });

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorComponent contentOptions={contentOptions} error={error} />;
  }

  if (data && isMediumScreen) {
    return (
      <>
        <Stack alignItems="center" divider={<Divider flexItem />} spacing={4}>
          <Button endIcon={<FilterListIcon />} fullWidth onClick={data.onOpenFilterModal} variant="outlined">
            {data.content.filter.filter}
          </Button>
          {data.dataLoading ? (
            <>
              <Skeleton width="100%" />
              <Skeleton width="100%" />
              <Skeleton width="100%" />
              <Skeleton width="100%" />
            </>
          ) : (
            data.tableData.map((row, index) => (
              <Stack key={index} spacing={1} width="100%">
                <Typography variant="subtitle2">{row.date}</Typography>
                {row.description}
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2">{row.symbol}</Typography>
                  <Typography variant="body2">{row.amount}</Typography>
                </Stack>
              </Stack>
            ))
          )}
          <Pagination
            count={data.totalPages}
            onChange={(_event: React.ChangeEvent<unknown>, newPage: number) => data.onUpdatePage(newPage)}
            page={data.currentPage}
          />
          <Typography variant="body2" width="100%">
            {data.content.disclaimer}
          </Typography>
        </Stack>
        <MobileFilter
          content={data.content.filter}
          filterData={data.filterData}
          onClose={data.onCloseFilterModal}
          open={data.isFilterModalOpen}
          sortData={data.mobileSortData}
        />
      </>
    );
  }

  return (
    <Stack divider={<Divider />} spacing={2}>
      {data?.downloadError && <Alert error={data.downloadError} severity="error" />}
      {data && (
        <Stack>
          <Stack direction="row">
            <Grid container justifyContent="flex-start" spacing={2}>
              <Grid item>
                <FilterSection data={data.filterData} />
              </Grid>
            </Grid>
            <Button
              disabled={data.tableData.length === 0 || data.downloadLoading}
              endIcon={<DownloadIcon />}
              onClick={data.onDownloadCSV}
              sx={{ minWidth: '220px' }}
              variant="text"
            >
              {data.content.download}
            </Button>
          </Stack>
          {data.dataLoading ? (
            <>
              <Skeleton sx={{ mt: 4 }} />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <BasicTable
              NullStateComponent={
                data.filterData.values.dateRangeOption === DateRangeOptions.Custom ? (
                  <Typography align="center" component="span" variant="subtitle1">
                    {data.content.table.emptyMessage}
                  </Typography>
                ) : (
                  <Box display="flex" justifyContent="center">
                    <Typography
                      component="span"
                      variant="subtitle1"
                    >{`${data.content.table.emptyMessage} ${data.content.table.emptyMessageWithCTA.before} `}</Typography>
                    <Link onClick={() => onAddFunds({ account: {}, value: '' })} sx={{ px: '4px' }} variant="subtitle1">
                      {data.content.table.emptyMessageWithCTA.cta}
                    </Link>
                    <Typography component="span" variant="subtitle1">
                      {data.content.table.emptyMessageWithCTA.after}
                    </Typography>
                  </Box>
                )
              }
              alignItems="left"
              columns={data.content.table.tableColumns}
              currentPage={data.currentPage}
              data={data.tableData}
              dataQa={`${dataQa}-table`}
              onPageChange={data.onUpdatePage}
              showPagination
              sortConfig={data.sortConfig}
              sx={{
                '&': {
                  px: 0,
                  pb: 4,
                  backgroundColor: '#FFFFFF',
                  borderCollapse: 'separate',
                },
              }}
              totalPages={data.totalPages}
            />
          )}
        </Stack>
      )}
      <Typography variant="body2">{data?.content.disclaimer}</Typography>
    </Stack>
  );
};
