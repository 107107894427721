import { ComponentProps } from 'react';

import { Account } from '.';

import { AccountInfo } from '~/components/AccountSummary/AccountInfo';
import { getAccountNumberText } from '~/utils/account';

type Variables = Pick<ComponentProps<typeof Account>, 'accountDetailsToShow' | 'accountInfoVariant'> & {
  content: Pick<
    ComponentProps<typeof Account>['content'],
    | 'accountNumberFormat'
    | 'accountNumberPrefix'
    | 'accountProgram'
    | 'accountType'
    | 'ownershipType'
    | 'shouldMaskAccountNumber'
  > & {
    partialAccount: string;
  };
  isOnboardingInProgress: boolean;
  maskedAccountNumber: string;
  onSeeDetailsClick?: () => void;
};
type Data = ComponentProps<typeof AccountInfo>;

export const getAccountInfoProps = ({
  accountDetailsToShow,
  accountInfoVariant,
  content: {
    accountNumberPrefix,
    accountNumberFormat,
    accountType,
    accountProgram,
    ownershipType,
    partialAccount,
    shouldMaskAccountNumber,
  },
  isOnboardingInProgress,
  maskedAccountNumber,
  onSeeDetailsClick,
}: Variables): Data | null => {
  const accountNumberText = getAccountNumberText(
    shouldMaskAccountNumber,
    maskedAccountNumber,
    accountNumberPrefix,
    accountNumberFormat,
  );

  const baseValues = {
    chips: [],
    onSeeDetailsClick,
    variant: accountInfoVariant,
  };

  switch (accountInfoVariant) {
    case 'infoChip':
      const subtitle = accountDetailsToShow?.showAccountProgram ? accountProgram : undefined;
      if (isOnboardingInProgress) {
        return { ...baseValues, name: partialAccount, subtitle };
      }

      const chips: Data['chips'] = [];
      if (accountDetailsToShow?.showAccountType) {
        chips.push({ label: accountType, variant: 'outlined' });
      }
      if (accountDetailsToShow?.showOwnershipType) {
        chips.push({ label: ownershipType, variant: 'filled' });
      }
      return { ...baseValues, chips, name: accountNumberText, subtitle };
    case 'standard':
      return {
        ...baseValues,
        name: accountProgram,
        subtitle: isOnboardingInProgress ? accountType : `${accountType} ${accountNumberText}`,
      };
    default:
      return null;
  }
};
