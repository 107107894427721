import { useMemo } from 'react';

import { useGetClientDocumentsContent } from '../contentstack';

import { getClientDocumentsContent } from './utils/clientDocumentsContent';

import { DocumentCategoryEnum } from '~/__generated__';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
  tabs: Partial<DocumentCategoryEnum>[];
}

export interface ClientDocumentsContent {
  disclaimer: string;
  tabHeader: Partial<Record<DocumentCategoryEnum, string>>;
}

interface Data {
  content: ClientDocumentsContent;
}

export const useClientDocumentsContent = ({ tabs, contentOptions }: Variables): AsyncResult<Data> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetClientDocumentsContent({
    variables: contentOptions,
  });

  const data: Data | undefined = useMemo(() => {
    if (contentLoading || contentError) {
      return;
    }

    return {
      content: getClientDocumentsContent(tabs, contentData),
    };
  }, [contentData, contentError, contentLoading, tabs]);

  return {
    data,
    error: contentError,
    loading: contentLoading,
  };
};
