import { useEffect, useMemo, useState } from 'react';

import { AccountClosuresV2_closeWorkflows_closeWorkflows_managedProduct_relatedParties } from '../../ClosuresTab/symphony/__generated__/query.v2';
import { useGetOpsDashboardContentV2 } from '../../contentstack';
import { GetOpsDashboardContentV2 } from '../../contentstack/__generated__/query.v2';
import { getCurrentPage, getLastEditDateAndTime, getTotalPages } from '../../utils';
import { useGetACATRequestTableContentV2 } from '../contentstack';
import { GetACATRequestTableContentV2 } from '../contentstack/__generated__/query.v2';
import { ACATRequestFlags, useAccountACATRequests } from '../symphony';
import {
  AccountAssetTransfersRequestV2,
  AccountAssetTransfersRequestV2Variables,
} from '../symphony/__generated__/query.v2';

import { getACATRequestDisplayInstructions, toACATRequestStatus, toCloseAccountStatus } from './mappers';

import {
  AssetDepositField,
  FinancialAccountType,
  ManagedProductStatus,
  OrderType,
  TransferType,
} from '~/__generated__';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { AsyncResult } from '~/utils/types';

export type ACATRequestsTabVariables = {
  contentOptions: ContentOptions;
  fetchLatestNote: boolean;
  field?: AssetDepositField;
  order?: OrderType;
  page: number;
  statusFilter: ACATRequestStatusFilter;
};

export enum ACATRequestStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum ACATRequestStatusFilter {
  ALL = 'ALL',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
}

export enum ACATInstructions {
  DOCUMENT_UPLOAD = 'Document Upload',
  ERROR = 'Error',
  MANUAL_INPUT_REQUIRED = 'Manual Input Required',
}

export type ACATRequestItem = {
  acatRequestType: TransferType;
  accountNumber: string;
  accountTypeText: string;
  clientName: string;
  comment?: {
    lastEditDate: string;
    lastEditTime: string;
    lastValue: string;
  };
  createdOn: string;
  destinationBankAccount?: string;
  flags: ACATRequestFlags[];
  id: string;
  instructions: string;
  lastCommentPartyId?: string;
  managedProductId: string;
  partyId: string;
  productName: string;
  sourceFinancialAccountAccountNumber?: string;
  status: ACATRequestStatus;
};

export type ACATRequestsTabData = {
  acatRequestItems: ACATRequestItem[];
  content: GetACATRequestTableContentV2;
  currentPage: number;
  refetchAccountACATRequestsData: () => void;
  totalPages: number;
};

export const useACATRequestsTabData = ({
  field,
  fetchLatestNote,
  order,
  page,
  statusFilter,
  contentOptions,
}: ACATRequestsTabVariables): AsyncResult<ACATRequestsTabData> => {
  const [state, setState] = useState<AsyncResult<ACATRequestsTabData>>({ loading: true });
  const variables: AccountAssetTransfersRequestV2Variables = useMemo(
    () => ({
      pagination: {
        limit: 10,
        offset: (page - 1) * 10,
      },
      statuses: toCloseAccountStatus(statusFilter),
      fetchLatestNote,
      managedProductStatuses: [ManagedProductStatus.ACTIVE],
      ...(field && order && { sort: { field, order } }),
    }),
    [field, order, page, statusFilter, fetchLatestNote],
  );

  // The reason for no-cache is due to the updating the list when ever we create or resolve flag for a acatRequest, the flagged and unflagged acatRequests table should be updated.
  // Otherwise, instead of the table showing the current flagged and unflagged acatRequests data it shows the previous cached data. There is no easy way to update the cache to reflect the correct state right now.
  const {
    data: accountACATRequestsData,
    loading: accountACATRequestsLoading,
    error: accountACATRequestsError,
    refetch: refetchAccountACATRequestsData,
  } = useAccountACATRequests({ variables, errorPolicy: 'all', fetchPolicy: 'no-cache' });

  const {
    data: contentstackData,
    loading: contentstackLoading,
    error: contentstackError,
  } = useGetACATRequestTableContentV2({
    variables: contentOptions,
  });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContentV2({
    variables: contentOptions,
  });

  useEffect(() => {
    if (accountACATRequestsError || contentstackError || opsDashboardContentError) {
      setState({
        error: accountACATRequestsError || contentstackError || opsDashboardContentError,
        loading: false,
      });
    }
  }, [accountACATRequestsError, contentstackError, opsDashboardContentError]);

  useEffect(() => {
    if (
      !accountACATRequestsLoading &&
      accountACATRequestsData &&
      !contentstackLoading &&
      contentstackData &&
      !opsDashboardContentLoading &&
      opsDashboardContentData
    ) {
      const acatRequestItems: ACATRequestItem[] = getACATRequestItems(
        contentOptions,
        accountACATRequestsData,
        opsDashboardContentData,
      );
      const { limit, offset, total } = accountACATRequestsData.assetDeposits.paginationContext;
      const currentPage = getCurrentPage({ limit, offset });
      const totalPages = getTotalPages({ limit, total });
      setState({
        data: {
          acatRequestItems,
          currentPage,
          totalPages,
          refetchAccountACATRequestsData,
          content: contentstackData,
        },
        loading: false,
      });
    }
  }, [
    accountACATRequestsData,
    accountACATRequestsLoading,
    contentstackLoading,
    contentstackData,
    opsDashboardContentLoading,
    opsDashboardContentData,
  ]);

  return state;
};

const getInitialParty = (
  relatedParties: (AccountClosuresV2_closeWorkflows_closeWorkflows_managedProduct_relatedParties | null)[],
) => relatedParties.find(party => party?.isInitialParty === true);

const getACATRequestItems = (
  _contentOptions: ContentOptions,
  data: AccountAssetTransfersRequestV2,
  opsDashboardContentData?: GetOpsDashboardContentV2,
): ACATRequestItem[] => {
  const programNames = opsDashboardContentData?.all_product_name?.items || [];
  return data.assetDeposits.transfers.map(item => {
    const initialParty = getInitialParty(item.managedProduct?.relatedParties ?? []);
    const partyId = initialParty?.party?.id || '';
    const entityNotes = item.entityNotes.length ? item.entityNotes[0] : null;
    return {
      acatRequestType: item.type,
      createdOn: item.createdOn,
      status: toACATRequestStatus(item.status),
      accountNumber: item.managedProduct?.financialAccountNumber || '',
      accountTypeText: getAccountTypeText(
        item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
        opsDashboardContentData?.all_account_type?.items || [],
      ),
      clientName: `${initialParty?.party?.partyPerson?.givenName ?? ''} ${
        initialParty?.party?.partyPerson?.familyName ?? ''
      }`,
      flags: item.flags,
      destinationBankAccount: item.sourceFinancialAccountAccountNumber || undefined,
      managedProductId: item.managedProductId,
      partyId,
      productName: getAccountProgramText(item.managedProduct?.program, item.managedProduct?.attributes, programNames),
      instructions:
        item.flags.length === 0
          ? ACATInstructions.DOCUMENT_UPLOAD
          : getACATRequestDisplayInstructions(item.flags[0].reason),
      lastCommentPartyId: entityNotes?.createdByPartyId,
      comment: entityNotes
        ? {
            lastValue: entityNotes.note,
            ...getLastEditDateAndTime(new Date(entityNotes.created), _contentOptions),
          }
        : undefined,
      id: item.id ?? '',
    };
  });
};
