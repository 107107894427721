import { GetPerformanceCalculationInfoModal, GetPerformanceCalculationInfoModalVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult, QueryTuple } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export const useGetPerformanceCalculationInfoModal = (
  options?: QueryHookOptions<GetPerformanceCalculationInfoModal, GetPerformanceCalculationInfoModalVariables>,
): QueryResult<GetPerformanceCalculationInfoModal, GetPerformanceCalculationInfoModalVariables> => {
  return useContentstackQuery(queries.GetPerformanceCalculationInfoModal, options);
};
export const useLazyGetPerformanceCalculationInfoModal = (
  options?: QueryHookOptions<GetPerformanceCalculationInfoModal, GetPerformanceCalculationInfoModalVariables>,
): QueryTuple<GetPerformanceCalculationInfoModal, GetPerformanceCalculationInfoModalVariables> => {
  return useLazyContentstackQuery(queries.GetPerformanceCalculationInfoModal, options);
};
