import { parseISO } from 'date-fns';
import React from 'react';

import { ActiveTradingSuspensionsTabContent } from '../contentstack';
import { TradingSuspensions } from '../symphony';

import { SuspensionType } from '~/__generated__';
import { TableData } from '~/components/ui/BasicTable';
import { Chip } from '~/components/ui/Chip';
import { Button } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getFullName } from '~/hooks/party/utils';
import { SuspensionTag } from '~/utils/account';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

enum ActiveSuspensionsTableColumns {
  Action = 'Action',
  AddedBy = 'AddedBy',
  DateAdded = 'DateAdded',
  SuspensionTagColumn = 'SuspensionTag',
  SuspensionTypeColumn = 'SuspensionType',
}

export interface SuspensionRowData {
  addedBy?: string;
  created?: string;
  dateAdded?: string;
  id: string;
  isPartnerOps?: boolean;
  restrictionPlacedBy?: string;
  restrictionPlacedByUser?: string;
  suspensionTag?: string;
  suspensionTagColor?: string;
  suspensionType?: string;
}

export interface Content {
  addSuspensionButtonLabel: string;
  addSuspensionInfo: string;
  columnHeaderLabels: Record<ActiveSuspensionsTableColumns, string>;
  otherSuspensionTag: string;
  removeSuspensionButtonLabel: string;
  suspensionAdded: string;
  suspensionRemoved: string;
}

export const getActiveTradingSuspensionsContent = (
  data: ActiveTradingSuspensionsTabContent | null | undefined,
): Content => {
  const content = data?.all_active_trading_suspensions?.items?.[0];
  const textFields = content?.fields?.text ?? [];

  return {
    addSuspensionButtonLabel: findFieldValue(textFields, 'add_suspension_button'),
    addSuspensionInfo: findFieldValue(textFields, 'add_suspension_info'),
    columnHeaderLabels: {
      [ActiveSuspensionsTableColumns.DateAdded]: findFieldValue(textFields, 'date_added_column'),
      [ActiveSuspensionsTableColumns.AddedBy]: findFieldValue(textFields, 'added_by_column'),
      [ActiveSuspensionsTableColumns.SuspensionTypeColumn]: findFieldValue(textFields, 'suspension_type_column'),
      [ActiveSuspensionsTableColumns.SuspensionTagColumn]: findFieldValue(textFields, 'suspension_tag_column'),
      [ActiveSuspensionsTableColumns.Action]: '',
    },
    removeSuspensionButtonLabel: findFieldValue(textFields, 'remove_suspension_button'),
    otherSuspensionTag: findFieldValue(textFields, 'other_suspension_tag'),
    suspensionAdded: findFieldValue(textFields, 'trading_suspension_added'),
    suspensionRemoved: findFieldValue(textFields, 'trading_suspension_removed'),
  };
};

export const getColumns = (content: Content) => [
  {
    key: ActiveSuspensionsTableColumns.DateAdded,
    title: content.columnHeaderLabels[ActiveSuspensionsTableColumns.DateAdded],
  },
  {
    key: ActiveSuspensionsTableColumns.AddedBy,
    title: content.columnHeaderLabels[ActiveSuspensionsTableColumns.AddedBy],
  },
  {
    key: ActiveSuspensionsTableColumns.SuspensionTypeColumn,
    title: content.columnHeaderLabels[ActiveSuspensionsTableColumns.SuspensionTypeColumn],
  },
  {
    key: ActiveSuspensionsTableColumns.SuspensionTagColumn,
    title: content.columnHeaderLabels[ActiveSuspensionsTableColumns.SuspensionTagColumn],
  },
  {
    key: ActiveSuspensionsTableColumns.Action,
    title: content.columnHeaderLabels[ActiveSuspensionsTableColumns.Action],
  },
];

export const getSuspensionRowData = (
  suspension: TradingSuspensions,
  content: Content,
  contentOptions: ContentOptions,
  suspensionTagStyleMap: Record<string, string> | undefined,
): SuspensionRowData => {
  const partyName = getFullName(suspension.createdByParty?.partyPerson);

  return {
    addedBy: partyName,
    dateAdded: formatDate(parseISO(suspension.createdAt), 'MM/dd/yyyy', {
      locale: contentOptions.locale,
    }),
    id: suspension.id,
    isPartnerOps:
      suspension.suspensionTag === SuspensionTag.OPS && suspension.suspensionType === SuspensionType.PARTNER,
    suspensionTag: suspension.suspensionTag ? suspension.suspensionTag : content.otherSuspensionTag,
    suspensionTagColor: suspension.suspensionTag
      ? suspensionTagStyleMap?.[suspension.suspensionTag.toLowerCase()]
      : undefined,
    suspensionType: suspension.suspensionType,
  };
};

export const getIsOpsSuspensionAdded = (tradingSuspensions: TradingSuspensions[]): boolean =>
  tradingSuspensions.some(
    suspension =>
      suspension.suspensionTag === SuspensionTag.OPS && suspension.suspensionType === SuspensionType.PARTNER,
  );

export const getFormattedRows = ({
  content,
  contentOptions,
  disableRemoveSuspension,
  isRemoveTradingSuspensionAllowed,
  onRemoveSuspensionActionClick,
  suspensionData,
  suspensionTagStyleMap,
}: {
  content: Content;
  contentOptions: ContentOptions;
  disableRemoveSuspension: boolean;
  isRemoveTradingSuspensionAllowed: boolean;
  onRemoveSuspensionActionClick: ((suspension: SuspensionRowData) => void) | undefined;
  suspensionData: TradingSuspensions[];
  suspensionTagStyleMap: Record<string, string> | undefined;
}): TableData[] | undefined =>
  suspensionData.map(data => {
    const suspension = getSuspensionRowData(data, content, contentOptions, suspensionTagStyleMap);
    return {
      rowKey: suspension.id,
      [ActiveSuspensionsTableColumns.DateAdded]: <Typography variant="body2">{suspension.dateAdded}</Typography>,
      [ActiveSuspensionsTableColumns.AddedBy]: <Typography variant="body2">{suspension.addedBy}</Typography>,
      [ActiveSuspensionsTableColumns.SuspensionTypeColumn]: (
        <Typography variant="body2">{suspension.suspensionType}</Typography>
      ),
      [ActiveSuspensionsTableColumns.SuspensionTagColumn]: suspension.suspensionTag && (
        <Chip
          label={suspension.suspensionTag}
          sx={{ backgroundColor: suspension.suspensionTagColor, borderRadius: 1, width: 104 }}
          variant="filled"
        />
      ),
      [ActiveSuspensionsTableColumns.Action]: suspension.isPartnerOps && (
        <Button
          data-qa={`remove-suspension-${suspension.id}`}
          disabled={!isRemoveTradingSuspensionAllowed || disableRemoveSuspension}
          onClick={() => onRemoveSuspensionActionClick?.(suspension)}
          size="small"
          variant="outlined"
        >
          {content.removeSuspensionButtonLabel}
        </Button>
      ),
    };
  });
