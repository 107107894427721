import React from 'react';

import { ACATRequestStatusFilter } from '../hooks';

import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';

export type ACATRequestsStatusFilterProps = {
  currentStatusFilter: ACATRequestStatusFilter;
  displayStrings: {
    statusFilter: Record<ACATRequestStatusFilter, string>;
  };
  onChange: (newStatusFilter: ACATRequestStatusFilter) => void;
};

export const ACATRequestsStatusFilter: React.FC<ACATRequestsStatusFilterProps> = ({
  currentStatusFilter,
  displayStrings: { statusFilter },
  onChange,
}) => {
  const items: DropdownItem[] = [
    {
      value: ACATRequestStatusFilter.ALL,
      label: statusFilter[ACATRequestStatusFilter.ALL],
    },
    {
      value: ACATRequestStatusFilter.PENDING,
      label: statusFilter[ACATRequestStatusFilter.PENDING],
    },
    {
      value: ACATRequestStatusFilter.IN_PROGRESS,
      label: statusFilter[ACATRequestStatusFilter.IN_PROGRESS],
    },
    {
      value: ACATRequestStatusFilter.COMPLETED,
      label: statusFilter[ACATRequestStatusFilter.COMPLETED],
    },
    {
      value: ACATRequestStatusFilter.CANCELLED,
      label: statusFilter[ACATRequestStatusFilter.CANCELLED],
    },
  ];

  return (
    <Dropdown
      dataQa="request-status-filter"
      items={items}
      onChange={e => onChange(e.target.value as ACATRequestStatusFilter)}
      value={currentStatusFilter}
      width="130px"
    />
  );
};
