import React from 'react';

import { useRemoveTradingSuspensionModalData } from './hooks/useRemoveTradingSuspensionData';
import { ContentKeys } from './hooks/utils';

import { MultiRestrictionPlacedBy, SuspensionType } from '~/__generated__';
import { Modal } from '~/components/ui/Modal';
import { Button, Grid, Stack, WarningIcon } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';

interface SuspensionDisplayData {
  created?: string;
  restrictionPlacedBy?: string;
  restrictionPlacedByUser?: string;
}

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onClose: () => void;
  onTradingSuspensionRemoveFailure?: () => void;
  onTradingSuspensionRemoveSuccess?: () => void;
  open: boolean;
  suspensionDisplayData?: SuspensionDisplayData;
  suspensionTag: string;
  suspensionType: SuspensionType;
}

export const RemoveTradingSuspension: React.FC<Props> = ({
  dataQa = 'remove-trading-suspension-modal',
  managedProductId,
  contentOptions,
  onClose,
  onTradingSuspensionRemoveFailure,
  onTradingSuspensionRemoveSuccess,
  open,
  suspensionDisplayData,
  suspensionTag,
  suspensionType,
}) => {
  const { data, loading, error } = useRemoveTradingSuspensionModalData({
    contentOptions,
    managedProductId,
    onTradingSuspensionRemoveFailure,
    onTradingSuspensionRemoveSuccess,
    open,
    suspensionTag,
    suspensionType,
  });
  const content = data?.content;
  const validMultiRestrictionPlacedBy = [
    MultiRestrictionPlacedBy.CUSTODIAL,
    MultiRestrictionPlacedBy.SIGFIG_TRADER,
    MultiRestrictionPlacedBy.PARTNER_OPS,
  ];
  const placedBy =
    suspensionDisplayData?.restrictionPlacedBy &&
    validMultiRestrictionPlacedBy.includes(suspensionDisplayData.restrictionPlacedBy as MultiRestrictionPlacedBy)
      ? suspensionDisplayData.restrictionPlacedBy
      : ContentKeys.Others;

  return (
    <Modal
      actions={
        <>
          <Button
            data-qa={`${dataQa}-cancel`}
            disabled={data?.isBusy}
            onClick={onClose}
            sx={{ textDecoration: 'underline' }}
            variant="text"
          >
            {content?.secondaryCta}
          </Button>
          <Button
            data-qa={`${dataQa}-cta-primary`}
            disabled={data?.isBusy}
            onClick={data?.onConfirmRemoveSuspension}
            variant="contained"
          >
            {content?.primaryCta}
          </Button>
        </>
      }
      content={
        <>
          <WarningIcon fontSize="large" sx={{ color: 'warning.main', my: 3 }} />
          <Typography data-qa={`${dataQa}-text`} sx={{ mb: 1 }} variant="subtitle1">
            {content?.description}
          </Typography>
          <Typography data-qa={`${dataQa}-text`} sx={{ mb: 3 }} variant="body2">
            {content?.subDescription}
          </Typography>
          {suspensionDisplayData && (
            <Grid container sx={{ pt: 3, pb: 3, borderTop: ({ palette }) => `1px solid ${palette.divider}` }} xs={12}>
              <Grid sx={{ width: '50%', padding: 1 }}>
                <Stack>
                  <Typography variant="subtitle2">{content?.createdLabel}</Typography>
                  <Typography variant="caption">{suspensionDisplayData.created}</Typography>
                </Stack>
              </Grid>
              <Grid sx={{ width: '50%', padding: 1 }}>
                <Stack>
                  <Typography variant="subtitle2">{content?.createdByLabel}</Typography>
                  <Stack direction="column">
                    <Typography>{findFieldValue(content?.textFields, placedBy.toLowerCase())}</Typography>
                    <Typography variant="caption">{suspensionDisplayData.restrictionPlacedByUser}</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
        </>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={error}
      loading={loading}
      onClose={onClose}
      open={open}
      title={content?.title}
    />
  );
};
