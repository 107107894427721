import { Brokerage } from '~/hooks/brokerage/symphony';

// We need this method for the custom brokerage when brokerage select is disabled
export const getBrokerageFromBrokerageName = (brokerageName: string): Brokerage => {
  return {
    alias: brokerageName,
    name: brokerageName,
    isActive: true,
    id: brokerageName,
    oauthClientInfo: [],
    oauthEnabled: false,
    passwordAlias: '',
    passwordRecoveryPage: null,
    userAndPasswordRecoveryPage: [],
    usernameAlias: '',
    userRecoveryPage: null,
    website: '',
    __typename: 'Brokerage',
  };
};
