/**
 * PACKAGE ENTRY
 * export public methods and classes from this file
 *
 * For example:
 * export { default, NamedExport1, NamedExport2 } from './some-file';
 */
import { ApolloClient, ApolloClientOptions, HttpLink, InMemoryCache } from '@apollo/client';
import { NormalizedCacheObject } from '@apollo/client/cache';
import fetch from 'cross-fetch';

import { HttpLinkConfig } from './src/types';
import { combineLinks } from './src/utils';

export { setContext } from '@apollo/client/link/context';
export { onError } from '@apollo/client/link/error';
export type { ErrorResponse } from '@apollo/client/link/error';
export {
  ApolloCache,
  ApolloClient,
  ApolloError,
  ApolloProvider,
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription,
  useApolloClient,
  from,
  InMemoryCache,
  makeVar,
  NetworkStatus,
  ApolloLink,
  HttpLink,
  split,
  isApolloError,
} from '@apollo/client';
export type {
  FetchResult,
  QueryHookOptions,
  QueryOptions,
  MutationHookOptions,
  TypePolicy,
  TypePolicies,
  ApolloQueryResult,
  LazyQueryHookOptions,
  ServerError,
  ServerParseError,
} from '@apollo/client';
export { MockLink, MockedProvider as MockedApolloProvider } from '@apollo/client/testing';
export type { MockedResponse } from '@apollo/client/testing';
export type { QueryResult, QueryTuple, LazyQueryResult, MutationTuple, MutationResult } from '@apollo/client/react';
export { GraphQLError } from 'graphql';

export type ReactiveVarRead<T> = T extends ReactiveVarWrite<infer X> ? () => X : never;
export type ReactiveVarWrite<T> = (newValue: T) => T;

export * from './src/hooks';
export * from './src/types';
export * from './src/utils';

export const getApolloClient = ({
  cache,
  uri,
  links,
  ...otherOptions
}: Partial<
  ApolloClientOptions<NormalizedCacheObject> & { links: HttpLinkConfig[] }
>): ApolloClient<NormalizedCacheObject> => {
  if (links && uri) {
    console.warn('uri has no effect when links is supplied');
  }
  return new ApolloClient({
    cache: cache ?? new InMemoryCache(),
    link: links ? combineLinks(links) : new HttpLink({ uri, fetch }),
    ...otherOptions,
  });
};
