import {
  GetACATRequestTableContent,
  GetACATRequestTableContent_all_acatrequests_table_items_flag_referenceConnection_edges_node,
  GetACATRequestTableContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ACATRequestsFlagReferenceContent = GetACATRequestTableContent_all_acatrequests_table_items_flag_referenceConnection_edges_node;

export const useGetACATRequestTableContent = (
  options?: QueryHookOptions<GetACATRequestTableContent, GetACATRequestTableContentVariables>,
): QueryResult<GetACATRequestTableContent, GetACATRequestTableContentVariables> => {
  return useContentstackQuery(queries.GetACATRequestTableContent, options);
};
