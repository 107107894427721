import {
  AccountDetailsClosedManagedAccounts,
  AccountDetailsClosedManagedAccountsVariables,
} from './__generated__/accountDetailsClosedManagedAccounts.v2';
import {
  AccountDetailsManagedAccounts,
  AccountDetailsManagedAccounts_client_financialAccounts_products_ManagedProduct_tradingSuspensions,
  AccountDetailsManagedAccountsVariables,
} from './__generated__/accountDetailsManagedAccounts.v2';
import {
  AccountDetailsGetAccountPerformance,
  AccountDetailsGetAccountPerformanceVariables,
} from './__generated__/query.v2';
import {
  AccountDetailsGetDigitalWealthAccounts,
  AccountDetailsGetDigitalWealthAccounts_client_bankAccounts,
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts,
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products,
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct,
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_attributes,
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_funds_availableWithdrawalValueBySleeve,
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets_allocations,
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets_cashAllocation,
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets_sleeveAllocation,
  AccountDetailsGetDigitalWealthAccountsVariables,
} from './__generated__/query.v2';
import * as accountDetailsClosedManagedAccountsQuery from './accountDetailsClosedManagedAccounts.gql';
import * as accountDetailsManagedAccountsQuery from './accountDetailsManagedAccounts.gql';
import * as queries from './query.gql';

import { FinancialAccountType } from '~/__generated__/symphonyTypes.v2';
// TODO: This need to be moved to common, to avoid cross refrencing and circular dependencies
import { AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio_CompositeRecommendedPortfolio_compositeModelPortfolioAllocation } from '~/containers/AccountSummary/symphony/__generated__/query.v2';
import { GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_allocations_guidance_diversification_assets_sleeveAllocation } from '~/hooks/qpr/symphony/__generated__/query.v2';
import { QueryHookOptions } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type FinancialAccountManagedProductTradingSuspensions = AccountDetailsManagedAccounts_client_financialAccounts_products_ManagedProduct_tradingSuspensions;
export type DetailedFinancialAccount = AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts;
export type BankAccount = AccountDetailsGetDigitalWealthAccounts_client_bankAccounts;
export type Product = AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products;
export type ManagedProduct = AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct;
export type AvailableWithdrawalValueBySleeve =
  | AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_funds_availableWithdrawalValueBySleeve[]
  | null;
export type ManagedProductAttributes = AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_attributes;
export type ManagedProductCompositeRecommendedPortfolioAllocation = AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio_CompositeRecommendedPortfolio_compositeModelPortfolioAllocation;

export const isManagedProduct = (product: Product): product is ManagedProduct =>
  product.__typename === 'ManagedProduct';

export type CashAllocation = AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets_cashAllocation;
export type AssetClassAllocation = AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets_allocations;
export type SleeveAllocation =
  | AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets_sleeveAllocation
  | GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_allocations_guidance_diversification_assets_sleeveAllocation;

export const isBankAccount = (account: BankAccount): boolean =>
  (!!account.id || !!account.isFromExternalSource) &&
  new Set([FinancialAccountType.CHEQUING, FinancialAccountType.SAVINGS]).has(account.type);

export const useAccountDetailsGetDigitalWealthAccounts = (
  options?: QueryHookOptions<AccountDetailsGetDigitalWealthAccounts, AccountDetailsGetDigitalWealthAccountsVariables>,
) => {
  return useSymphonyQuery(queries.AccountDetailsGetDigitalWealthAccounts, options);
};

export const useAccountDetailsClosedManagedAccounts = (
  options?: QueryHookOptions<AccountDetailsClosedManagedAccounts, AccountDetailsClosedManagedAccountsVariables>,
) => {
  return useSymphonyQuery(accountDetailsClosedManagedAccountsQuery.AccountDetailsClosedManagedAccounts, options);
};

export const useAccountDetailsManagedAccounts = (
  options?: QueryHookOptions<AccountDetailsManagedAccounts, AccountDetailsManagedAccountsVariables>,
) => useSymphonyQuery(accountDetailsManagedAccountsQuery.AccountDetailsManagedAccounts, options);

export const useAccountDetailsGetPerformance = (
  options?: QueryHookOptions<AccountDetailsGetAccountPerformance, AccountDetailsGetAccountPerformanceVariables>,
) => {
  return useSymphonyQuery(queries.AccountDetailsGetAccountPerformance, options);
};
