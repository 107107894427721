import {
  GetFinancialAccountTransactions,
  GetFinancialAccountTransactions_client_financialAccounts_transactions_transactions,
  GetFinancialAccountTransactionsVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { LazyQueryHookOptions, QueryHookOptions, QueryResult, QueryTuple } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyQuery } from '~/utils/symphony';

export type Transactions = GetFinancialAccountTransactions_client_financialAccounts_transactions_transactions;

export const useGetFinancialAccountTransactions = (
  options?: QueryHookOptions<GetFinancialAccountTransactions, GetFinancialAccountTransactionsVariables>,
): QueryResult<GetFinancialAccountTransactions, GetFinancialAccountTransactionsVariables> => {
  return useSymphonyQuery(queries.GetFinancialAccountTransactions, options);
};

export const useLazyGetFinancialAccountTransactions = (
  options?: LazyQueryHookOptions<GetFinancialAccountTransactions, GetFinancialAccountTransactionsVariables>,
): QueryTuple<GetFinancialAccountTransactions, GetFinancialAccountTransactionsVariables> => {
  return useLazySymphonyQuery(queries.GetFinancialAccountTransactions, options);
};
