import { isValid, min, parseISO } from 'date-fns';
import { pipe } from 'fp-ts/function';
import { useMemo } from 'react';

import { useGetBalanceOverTime } from './symphony';

import { PerformanceMethodTypes, Scalars as SymphonyScalars } from '~/__generated__';
import { ContentOptions } from '~/utils/contentstack';
import { findMostRecentDate } from '~/utils/date';
import { toSymphonyDate } from '~/utils/symphony';
import { AsyncResult, DailyChartValue } from '~/utils/types';

type Variables = {
  contentOptions: ContentOptions;
  performanceChartTo?: 'syncedOn' | 'today';
  rebalancedAccounts: Array<{
    balance?: string;
    firstRebalancedOn: SymphonyScalars['Date'];
    id: string;
    syncedOn: SymphonyScalars['DateTime'];
  }>;
  skip?: boolean;
};
type Data = {
  performance: DailyChartValue[];
  totalBalance: number;
};

export const findMostRecentSyncedOnDate = (syncedOnDates: string[]): string | undefined => {
  const mostRecentSyncedOn = findMostRecentDate(syncedOnDates);

  // TODO this is a temp fix to ignore timestamp , will revert back once backend fix(ANR-7530) is ready.
  const indexOfTime = mostRecentSyncedOn?.indexOf('T');
  return indexOfTime !== -1 ? mostRecentSyncedOn?.slice(0, indexOfTime) : mostRecentSyncedOn;
};

export const useBalanceOverTime = ({
  contentOptions,
  performanceChartTo,
  rebalancedAccounts,
  skip: baseSkip,
}: Variables): AsyncResult<Data> => {
  const skip = baseSkip || rebalancedAccounts.length === 0;

  const syncedOnDate = useMemo(() => findMostRecentSyncedOnDate(rebalancedAccounts.map(a => a.syncedOn)), [
    rebalancedAccounts,
  ]);

  const {
    data: balanceOverTimeData,
    error: balanceOverTimeError,
    loading: balanceOverTimeLoading,
  } = useGetBalanceOverTime({
    variables: {
      accounts: rebalancedAccounts.map(({ id }) => id),
      measurements: [
        {
          /* earliest rebalance date */
          from: pipe(
            rebalancedAccounts.map(({ firstRebalancedOn }) => parseISO(firstRebalancedOn)),
            min,
            date => (isValid(date) ? toSymphonyDate(date, { locale: contentOptions.locale }) : ''),
          ),
          to: toSymphonyDate(performanceChartTo === 'syncedOn' && syncedOnDate ? syncedOnDate : new Date(), {
            locale: contentOptions.locale,
          }),
          performanceMethod: PerformanceMethodTypes.FROM_END_OF_DAY_VALUES,
        },
      ],
    },
    skip,
  });

  const performance = useMemo(
    () =>
      (balanceOverTimeData?.performance?.[0].valueChart ?? []).map(({ date, value }) => ({
        date,
        amount: parseFloat(value),
      })),
    [balanceOverTimeData],
  );
  const totalBalance = useMemo(
    () =>
      rebalancedAccounts
        .map(account => parseFloat(account.balance || '0'))
        .reduce<number>((acc, balance) => acc + balance, 0),
    [rebalancedAccounts],
  );

  return {
    data: { performance, totalBalance },
    error: balanceOverTimeError,
    loading: balanceOverTimeLoading,
  };
};
