import React, { ComponentProps, useEffect, useMemo } from 'react';

import { useLazyGetAccountErrorsModalContent } from './contentstack';
import { useLazyGetAccountErrors, useLazyGetClientBrokerages } from './symphony';
import { getTableData } from './utils';

import { EntityType } from '~/__generated__';
import { BasicTable, TableColumn } from '~/components/ui/BasicTable';
import { Modal } from '~/components/ui/Modal';
import { Box, Button } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ErrorsFailureContent } from '~/containers/OpsDashboard/ErrorsTable/types';
import { ContentOptions } from '~/utils/contentstack';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  errorsRowData?: ErrorsFailureContent | null;
  onClose?: () => void;
  open: boolean;
};

export enum AccountErrorType {
  AccountError = 'Account Error',
  MoneyMovementError = 'Money Movement Error',
  OtherError = 'Other Error',
  TOAError = 'TOA Error',
}

export const AccountErrorsModal: React.FC<Props> = ({
  dataQa = 'account-errors-modal',
  errorsRowData,
  contentOptions,
  maxWidth = 'lg',
  open = false,
  onClose,
  ...modalProps
}) => {
  const managedProductId = errorsRowData?.managedProductId ?? '';
  const entityType = errorsRowData?.entity;
  const isTOA = entityType && EntityType.ASSET_DEPOSIT === entityType;
  const [
    getContent,
    { data: contentstackData, loading: contentstackLoading, error: contentstackError },
  ] = useLazyGetAccountErrorsModalContent({
    variables: contentOptions,
  });

  const [
    getAccountErrors,
    { data: accountErrorsData, loading: accountErrorsLoading, error: accountErrorsError },
  ] = useLazyGetAccountErrors({
    variables: { managedProductId },
    fetchPolicy: 'no-cache',
  });

  const [
    getClientBrokerages,
    { data: clientBrokeragesData, loading: clientBrokeragesLoading, error: clientBrokeragesError },
  ] = useLazyGetClientBrokerages({
    variables: { partyId: errorsRowData?.managedProduct.clientParty.id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (open) {
      getContent();
      getAccountErrors();
      getClientBrokerages();
    }
  }, [getAccountErrors, getClientBrokerages, getContent, isTOA, open]);

  const content = contentstackData?.all_account_errors_modal?.items?.[0];
  const loading = contentstackLoading || accountErrorsLoading || clientBrokeragesLoading || !errorsRowData;
  const data = useMemo(() => {
    if (loading) {
      return undefined;
    }

    return getTableData(!!isTOA, content, accountErrorsData, clientBrokeragesData, errorsRowData, contentOptions);
  }, [loading, isTOA, content, accountErrorsData, clientBrokeragesData, errorsRowData, contentOptions]);

  return (
    <Modal
      {...modalProps}
      actions={
        <Button onClick={onClose} variant="contained">
          {content?.primary_cta_text || ''}
        </Button>
      }
      content={
        <Box sx={{ p: 2, px: 4 }}>
          {data && (
            <>
              <Typography sx={{ py: 2 }} variant="subtitle1">
                {data.header}
              </Typography>
              <BasicTable
                alignItems="left"
                columns={data.tableColumns as TableColumn[]}
                data={[data.tableData]}
                showPagination={false}
              />
            </>
          )}
        </Box>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={contentstackError ?? accountErrorsError ?? clientBrokeragesError}
      loading={contentstackLoading || accountErrorsLoading || clientBrokeragesLoading || !errorsRowData}
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
    />
  );
};
