import React, { FC, useEffect, useState } from 'react';

import { Drawer } from '~/components/ui/Drawer';
import { Box, ChevronLeftIcon, ChevronRightIcon, IconButton } from '~/components/ui/mui';
import { SidebarState } from '~/containers/OpsDashboard/types';

export interface Props {
  children: React.ReactNode;
  dataQa?: string;
  onSidebarToggle: (state: SidebarState) => void;
}

export const MenuSideBar: FC<Props> = ({ dataQa = 'ops-dashboard-sidebar', children, onSidebarToggle }) => {
  const [sideBarState, setSidebarState] = useState(SidebarState.OPEN);

  useEffect(() => {
    // Create a style element
    const styleElement = document.createElement('style');

    // Add CSS rule to hide overflow on the html element
    styleElement.innerHTML = 'html { overflow: hidden; }';

    // Append the style element to the document's head
    document.head.appendChild(styleElement);

    // Clean up function to remove the style element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const toggleDrawer = (previousValue: SidebarState) => {
    const newSideBarState = previousValue === SidebarState.COLLAPSED ? SidebarState.OPEN : SidebarState.COLLAPSED;
    setSidebarState(newSideBarState);
    onSidebarToggle(newSideBarState);
  };

  return (
    <Box
      data-qa={dataQa}
      minWidth={sideBarState === SidebarState.COLLAPSED ? '20px' : '240px'}
      sx={{
        display: 'flex',
        position: 'relative',
        borderRight: '1px solid',
        borderColor: 'other.divider',
        padding: '10px',
      }}
    >
      <Drawer
        anchor="left"
        open={sideBarState !== SidebarState.COLLAPSED}
        sx={{
          position: 'relative',
          '& .MuiDrawer-paper': {
            width: 220,
            position: 'absolute',
            boxSizing: 'border-box',
            borderWidth: 0,
            '& .MuiBox-root': {
              overflowY: 'hidden',
            },
          },
          flexShrink: 0,
        }}
        variant="persistent"
      >
        {children}
      </Drawer>
      <Box
        sx={{
          position: 'absolute',
          top: '15px',
          right: '-19px',
          border: '1px solid',
          borderColor: 'other.divider',
          borderRadius: '50%',
        }}
      >
        <IconButton
          disableRipple
          onClick={() => toggleDrawer(sideBarState)}
          size="small"
          sx={{ padding: '5px', bgcolor: 'white' }}
        >
          {sideBarState !== SidebarState.COLLAPSED ? (
            <ChevronLeftIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};
