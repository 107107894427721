import React, { useMemo, useState } from 'react';

import { CommentComponentColumn } from '../common/Comment';
import { OpsDashboardUser } from '../utils';

import { ACATRequestsStatusFilter } from './ACATRequestsStatusFilter';
import { ACATRequestsTable } from './ACATRequestsTable';
import { generateACATRequestStaticDisplayStrings } from './content';
import { ACATRequestItem, ACATRequestStatus, ACATRequestStatusFilter, useACATRequestsTabData } from './hooks';
import { getCloseAccountStatusForUpdateForACATRequest } from './hooks/mappers';
import { useUpdateAssetDepositV2 } from './symphony';

import { AssetDepositField, OrderType } from '~/__generated__';
import { StatusChangeModal } from '~/components/modals/StatusChange';
import { NullStateProps } from '~/components/NullState';
import { Alert } from '~/components/ui/Alert';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable, SortConfig } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

type ACATRequestsTabProps = {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  getAccountNumberRedirectUrl: (partyId: string, managedProductId: string) => string;
  getClientNameRedirectUrl: (partyId: string) => string;
  nullStateConfig?: NullStateProps;
  onAccountClick: (partyId: string, managedProductId: string) => void;
  onClientClick: (client: string) => void;
};

const sortFieldToSymphony = new Map([['createdOn', AssetDepositField.CREATED]]);

interface AssetDepositUpdateItem {
  item: ACATRequestItem;
  newStatus: ACATRequestStatus;
}

export const ACATRequestsTab: React.FC<ACATRequestsTabProps> = ({
  contentOptions,
  commentColumn,
  nullStateConfig,
  onClientClick,
  onAccountClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  currentUser,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isBusy, setIsBusy] = useState(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [statusFilter, setStatusFilter] = useState<ACATRequestStatusFilter>(ACATRequestStatusFilter.ALL);
  const [selectedAssetDepositUpdateItem, setSelectedAssetDepositUpdateItem] = useState<AssetDepositUpdateItem>();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    order: OrderType.DESCENDING,
    field: 'createdOn',
  });

  const [UpdateAssetDepositV2] = useUpdateAssetDepositV2();
  const { loading, data, error } = useACATRequestsTabData({
    page: currentPage,
    statusFilter,
    contentOptions,
    fetchLatestNote: true,
    ...(sortConfig && { ...sortConfig, field: sortFieldToSymphony.get(sortConfig.field) }),
  });
  const statusFilterHandler = (newStatusFilter: ACATRequestStatusFilter) => {
    setStatusFilter(newStatusFilter);
    setCurrentPage(1);
  };

  const onSort = (field: string) => () => {
    const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
    setSortConfig({ field, order: toggleOrder });
    setCurrentPage(1);
  };
  const { open: statusModalOpen, openModal: openStatusModal, onClose: statusModalOnClose } = useModalState();

  const onSelectNewStatusForItem = (item: ACATRequestItem, selectedStatus: ACATRequestStatus) => {
    setSelectedAssetDepositUpdateItem({
      item,
      newStatus: selectedStatus,
    });
    openStatusModal();
  };

  const onConfirmStatusChange = async () => {
    if (selectedAssetDepositUpdateItem?.item && selectedAssetDepositUpdateItem.newStatus) {
      setIsBusy(true);
      const { newStatus } = selectedAssetDepositUpdateItem;
      const { id, managedProductId } = selectedAssetDepositUpdateItem.item;
      const selectedCloseWorkflowStatus = getCloseAccountStatusForUpdateForACATRequest(newStatus);
      if (selectedCloseWorkflowStatus) {
        try {
          await UpdateAssetDepositV2({
            variables: {
              financialAccountId: managedProductId,
              assetDepositId: id,
              status: selectedCloseWorkflowStatus,
            },
          });
          data?.refetchAccountACATRequestsData();
        } catch (e: any) {
          setErrorSaving(e);
        } finally {
          setIsBusy(false);
          statusModalOnClose();
        }
      }
    }
  };
  const displayStrings = useMemo(() => {
    if (data) {
      return generateACATRequestStaticDisplayStrings(data.content);
    }
    return null;
  }, [data]);

  const acatRequestsTable = useMemo(() => {
    if (data && displayStrings) {
      return (
        <ACATRequestsTable
          TableComponent={BasicTable}
          commentColumn={commentColumn}
          contentOptions={contentOptions}
          currentPage={currentPage}
          currentUser={currentUser}
          displayStrings={displayStrings}
          getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
          getClientNameRedirectUrl={getClientNameRedirectUrl}
          items={data.acatRequestItems}
          nullStateConfig={nullStateConfig}
          onAccountClick={onAccountClick}
          onClientClick={onClientClick}
          onPageChange={setCurrentPage}
          onSelectNewStatusForItem={onSelectNewStatusForItem}
          onSort={onSort}
          refetchData={() => data.refetchAccountACATRequestsData()}
          sortConfig={sortConfig}
          totalPages={data.totalPages}
        />
      );
    }
    return null;
  }, [
    commentColumn,
    contentOptions,
    currentPage,
    currentUser,
    data,
    onAccountClick,
    onClientClick,
    onSort,
    sortConfig,
    statusFilter,
  ]);

  return (
    <Grid container direction="column" spacing={3}>
      {(loading || error) && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading ACAT Requests items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      )}
      <Grid item>
        {errorSaving && <Alert contentOptions={contentOptions} error={errorSaving} severity="error" />}
        {data && displayStrings && (
          <>
            <Grid data-qa="filter-container" item sx={{ display: 'flex', justifyContent: 'end' }} xs={12}>
              <Typography sx={{ marginRight: 2, alignSelf: 'center' }} variant="subtitle2">
                Filter by Status:
              </Typography>
              <ACATRequestsStatusFilter
                currentStatusFilter={statusFilter}
                displayStrings={displayStrings}
                onChange={statusFilterHandler}
              />
            </Grid>
            <Grid item>
              <br />
              {acatRequestsTable}
            </Grid>
            <StatusChangeModal
              contentOptions={contentOptions}
              isBusy={isBusy}
              onClose={statusModalOnClose}
              onConfirm={onConfirmStatusChange}
              open={statusModalOpen}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
