import { ComponentProps } from 'react';

import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { FilterConfig, FilterCountMap, FilterTypes, SelectedFilters } from '~/components/Filters/types';
import { SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { AccountState } from '~/utils/account';
import { PaginationContext, SearchContext } from '~/utils/types';

export const initialRecordsPerPage = 10;

export interface DocusignTabData {
  appliedFilters: SelectedFilters;
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  currentPage: number;
  currentSearchFilter: string;
  docusignFilterConfig: FilterConfig[];
  docusignTabData: TableData[];
  filterCountMap: FilterCountMap[];
  filtersContent: ComponentProps<typeof Filters>['content'];
  filtersPopupOpen: boolean;
  onAppliedFiltersSelect: (filterKey?: string, selectedOption?: string) => void;
  onFilterChange: (selectedFilters: SelectedFilters) => void;
  onPageChange: (pageNumber: number) => void;
  onRowHover: (rowId: string | undefined) => void;
  onSearchChange: (value: string | null) => void;
  paginationContext: PaginationContext;
  refetchData: () => void;
  searchContext: SearchContext;
  searchError: boolean;
  sortConfig: SortConfig;
  tableColumns: TableColumn[];
  totalPages: number;
  updateOpenFiltersPopup: (value: boolean) => void;
}
export enum DocusignStatus {
  ALL = 'ALL',
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
}

export enum DocusignFilters {
  STATUS = 'STATUS',
}

export const docusignFilterConfig = [
  {
    key: DocusignFilters.STATUS,
    options: [
      {
        id: DocusignStatus.SUCCEEDED,
      },
      {
        id: DocusignStatus.PENDING,
      },
      {
        id: DocusignStatus.DECLINED,
      },
      {
        id: DocusignStatus.FAILED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
];

export const defaultAppliedFilters: SelectedFilters = {
  [DocusignFilters.STATUS]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [DocusignStatus.SUCCEEDED, DocusignStatus.PENDING, DocusignStatus.FAILED, DocusignStatus.DECLINED],
  },
};

export type emailRecipient = {
  email: string;
  recipientId: number;
};
export type DocusignItem = {
  accountNumber: string;
  accountNumberText: string;
  accountState: AccountState;
  accountType: string;
  clientName: string;
  comment?: {
    lastEditDate: string;
    lastEditTime: string;
    lastValue: string;
  };
  dateGenerated: string;
  emailRecipients: emailRecipient[];
  id: string;
  lastCommentPartyId: string;
  managedProductId: string;
  partyId: string;
  productName: string;
  status: DocusignStatus;
};
