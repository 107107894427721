import React, { FC } from 'react';

import { Props as GoalsCardProps } from '.';

import { Box } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { formatDate } from '~/utils/format/date';

type Props = Pick<GoalsCardProps, 'content' | 'dates' | 'contentOptions'>;

export const Heading: FC<Props> = ({ content, contentOptions, dates }) => {
  const dataQa = 'goals-card-heading';
  return (
    <Box>
      <Typography component="h3" data-qa={`${dataQa}-title`} variant="h4">
        {content.title}
      </Typography>
      {dates.lastUpdated && (
        <RteContent
          config={{ date: formatDate(dates.lastUpdated, 'MMMM d, yyyy', { locale: contentOptions.locale }) }}
          data={content.lastUpdated}
          dataQa={`${dataQa}-last-updated`}
        />
      )}
    </Box>
  );
};
