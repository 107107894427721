import { DeleteBankAccount, DeleteBankAccountVariables } from './__generated__/mutation.v2';
import * as mutations from './mutation.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useDeleteBankAccount = (
  options?: MutationHookOptions<DeleteBankAccount, DeleteBankAccountVariables>,
): MutationTuple<DeleteBankAccount, DeleteBankAccountVariables> => {
  return useSymphonyMutation(mutations.DeleteBankAccount, options);
};
