import React, { FC } from 'react';

import { Box, Stack, useTheme, WarningIcon } from '~/components/ui/mui';
import { SfTheme } from '~/utils/theme';

interface Props {
  dataQa?: string;
}

export const AccountAlert: FC<Props> = ({ children, dataQa }) => {
  const {
    sfAccount: { styles: sfAccountStyles },
  } = useTheme<SfTheme>();

  return (
    <Stack data-qa={dataQa} direction="row">
      <WarningIcon sx={sfAccountStyles.warningIcon} />
      <Box sx={sfAccountStyles.accountAlertMessage}>{children}</Box>
    </Stack>
  );
};
