import {
  GetErrorsTableContentV2,
  GetErrorsTableContentV2_all_errors_table_items_fields_text,
  GetErrorsTableContentV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type TextFields = (GetErrorsTableContentV2_all_errors_table_items_fields_text | null)[] | null;

export const useGetErrorsTableContentV2 = (
  options?: QueryHookOptions<GetErrorsTableContentV2, GetErrorsTableContentV2Variables>,
): QueryResult<GetErrorsTableContentV2, GetErrorsTableContentV2Variables> => {
  return useContentstackQuery(queries.GetErrorsTableContentV2, options);
};
