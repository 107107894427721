import * as queries from './query.gql';

import {
  GetResolveFlagModalContent,
  GetResolveFlagModalContent_all_flags_items_reasons,
  GetResolveFlagModalContentVariables,
} from '~/components/modals/ResolveFlag/contentstack/__generated__/query.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type FlagReasonsContent = GetResolveFlagModalContent_all_flags_items_reasons;

export const useGetResolveFlagModalContent = (
  options?: QueryHookOptions<GetResolveFlagModalContent, GetResolveFlagModalContentVariables>,
): QueryResult<GetResolveFlagModalContent, GetResolveFlagModalContentVariables> => {
  return useContentstackQuery(queries.GetResolveFlagModalContent, options);
};
