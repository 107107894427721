import {
  GetWithdrawalTableContentV2,
  GetWithdrawalTableContentV2_all_withdrawals_table_items_flag_referenceConnection_edges_node,
  GetWithdrawalTableContentV2_all_withdrawals_table_items_tax_withholding_infoConnection_edges_node,
  GetWithdrawalTableContentV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type FlagsReferenceContent = GetWithdrawalTableContentV2_all_withdrawals_table_items_flag_referenceConnection_edges_node;
export type TaxWithholdingContent = GetWithdrawalTableContentV2_all_withdrawals_table_items_tax_withholding_infoConnection_edges_node;

export const useGetWithdrawalTableContent = (
  options?: QueryHookOptions<GetWithdrawalTableContentV2, GetWithdrawalTableContentV2Variables>,
): QueryResult<GetWithdrawalTableContentV2, GetWithdrawalTableContentV2Variables> => {
  return useContentstackQuery(queries.GetWithdrawalTableContentV2, options);
};
