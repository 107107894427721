import { addYears, startOfToday } from 'date-fns';
import React, { ComponentProps, useEffect, useState } from 'react';

import { DocusignRequired } from '../DocusignRequired';

import { AddFundsForm } from './AddFundsForm';
import { useGetAddFundsContent } from './contentstack';

import {
  AssociatedEntityInput,
  AssociatedEntityType,
  BankAccountInput,
  CreateCashTransferInput,
  FinancialAccountAssociationVerificationStatus,
} from '~/__generated__';
import { Modal } from '~/components/ui/Modal';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Button, LoadingButton, WarningIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { getBankAccounts } from '~/containers/AccountSummary/utils';
import { useClientInfo } from '~/hooks/client/useClientInfo';
import { useResendEnvelopeToNextRecipient } from '~/hooks/docusign/symphony';
import {
  FinancialAccount,
  useCreateCashTransfer,
  useLazyGetBankAccounts,
  useSaveFinancialAccountAssociation,
} from '~/hooks/financial-account/symphony';
import { useLazyGetMarketHolidayList } from '~/hooks/market-holidays/symphony';
import { PlaidLinkType } from '~/hooks/plaid-link';
import {
  getLegalDocumentsForPendingBankAccountAssociation,
  getNextPendingSignee,
  LegalDocument,
} from '~/utils/account';
import { NetworkStatus } from '~/utils/apollo-client';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { toSymphonyDate } from '~/utils/symphony';

export interface Props
  extends Omit<
    ComponentProps<typeof AddFundsForm>,
    | 'content'
    | 'isRefetchingAccounts'
    | 'isSubmitted'
    | 'onLinkAccount'
    | 'onSuccessCallback'
    | 'onUnsuccessfulCallback'
    | 'marketHolidays'
    | 'refetchBankAccountsData'
    | 'userName'
  > {
  contentOptions: ContentOptions;
  dataQa?: string;
  enablePendingPUWWarning?: boolean;
  ignoreInsufficientFunds?: boolean;
  legalDocuments?: LegalDocument[] | undefined;
  managedProductId: string;
  onClose?: (feedbackMessage?: string) => void;
  onLinkAccount?: (account: FinancialAccount) => void;
  open?: boolean;
  partyId: string;
  partyIdFA?: string;
  plaidLinkageType?: PlaidLinkType;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntity?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  refetchAccounts?: () => void;
  showVerifiedBankAccounts?: boolean;
  syncExternalBankAccounts?: boolean;
  viewerPartyId?: string;
}

export const AddFundsModal: React.FC<Props> = ({
  dataQa = 'add-funds-modal',
  enablePendingPUWWarning = false,
  open = false,
  contentOptions,
  ignoreInsufficientFunds = false,
  managedProductId,
  onLinkAccount,
  onClose,
  partyId,
  partyIdFA,
  plaidLinkageType,
  redirectToSignDocuments,
  refetchAccounts,
  showVerifiedBankAccounts,
  syncExternalBankAccounts,
  ...addFundsFormProps
}) => {
  const {
    featureFlags: {
      isDocusignRequiredForFinancialAccountLinkageInRCE,
      showWarningForNonVerifiedFinancialAccountLinkages,
    },
  } = useCoreConfig();
  const {
    open: isDocusignRequiredModalOpen,
    openModal: openDocusignRequiredModal,
    onClose: closeDocusignRequiredlModal,
  } = useModalState();
  const [createCashTransfer] = useCreateCashTransfer();
  const [saveFinancialAccountAssociation] = useSaveFinancialAccountAssociation();
  const [resendEnvelopeToNextRecipient] = useResendEnvelopeToNextRecipient();
  const [showWarningForPendingPUW, setShowWarningForPendingPUW] = useState<boolean>(enablePendingPUWWarning);
  const [disableButton, setDisableButton] = useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [createCashTransferInput, setCreateCashTransferInput] = useState<CreateCashTransferInput>();
  const [symphonyError, setSymphonyError] = useState<Error | undefined>();
  const [isResendDocusignCtaOnDocusignRequiredModal, setIsResendDocusignCtaOnDocusignRequiredModal] = useState<boolean>(
    false,
  );
  const [pendingEntitySigningDocumentId, setPendingSigningDocumentIdForEntity] = useState<string>();
  const [entityForDocusign, setEntityForDocusign] = useState<AssociatedEntityInput>();
  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  const includeManagedProducts =
    isDocusignRequiredForFinancialAccountLinkageInRCE || showWarningForNonVerifiedFinancialAccountLinkages;

  const [
    getBankAccountsData,
    {
      data: bankAccountsData,
      loading: bankAccountsLoading,
      error: bankAccountsError,
      refetch: refetchBankAccountsData,
      networkStatus: bankAccountsNetworkStatus,
    },
  ] = useLazyGetBankAccounts({
    variables: {
      partyId,
      syncExternalBankAccounts,
      isVerified: showVerifiedBankAccounts,
      includeManagedProducts,
      managedProductIds: [managedProductId],
    },
    fetchPolicy: 'no-cache',
    skip: showWarningForPendingPUW,
    notifyOnNetworkStatusChange: true,
  });

  const [
    getMarketHolidayList,
    { data: marketHolidayList, loading: marketHolidayListLoading, error: marketHolidayListError },
  ] = useLazyGetMarketHolidayList({
    variables: {
      from: toSymphonyDate(startOfToday(), contentOptions),
      to: toSymphonyDate(addYears(startOfToday(), 1), contentOptions),
    },
    skip: showWarningForPendingPUW,
  });

  const onInavlidInput = () => {
    setIsSubmitted(false);
  };

  const { data: clientInfoData, loading: clientInfoLoading, error: clientInfoError } = useClientInfo({
    variables: { partyId },
    skip: !syncExternalBankAccounts || !open || showWarningForPendingPUW,
  });

  const { data: contentstackData, loading: contentstackLoading, error: contentError } = useGetAddFundsContent({
    variables: contentOptions,
    skip: !open,
  });
  useEffect(() => {
    if (open) {
      getBankAccountsData();
      if (!marketHolidayList && !contentstackData) {
        getMarketHolidayList();
      }
    }
  }, [open]);

  const isRefetchingAccounts = bankAccountsNetworkStatus === NetworkStatus.refetch;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const loadingStates = [contentstackLoading, marketHolidayListLoading, bankAccountsLoading, clientInfoLoading];
  useEffect(() => {
    // Do not show a loading state if it is a refetch so that the entire modal is not re-rendered.
    if (!isRefetchingAccounts) {
      setLoading(loadingStates.includes(true));
    }
  }, [...loadingStates, isRefetchingAccounts]);

  useEffect(() => {
    const logError = (err?: Error) => {
      if (err) {
        console.error(err);
        setError(err);
      }
    };
    if (!loadingStates.includes(true)) {
      // To log error only after all the requests have completed
      logError(bankAccountsError);
      logError(marketHolidayListError);
      logError(contentError);
      logError(clientInfoError);
    }
  }, [marketHolidayListError, contentError, bankAccountsError, clientInfoError]);

  const content = contentstackData?.all_add_funds_modal?.items?.[0];

  const accounts = getBankAccounts(bankAccountsData);
  const onSubmitCallback = async (
    createCashTransferInputVars: CreateCashTransferInput,
    bankAccountId: string | null,
    bankAccountInput?: BankAccountInput | null,
  ) => {
    setCreateCashTransferInput(createCashTransferInputVars);
    try {
      const financialAccountAssociationInput = {
        ...(bankAccountInput ? { bankAccount: bankAccountInput } : { bankAccountId }),
      };
      const bankAccountAssociationResult = await saveFinancialAccountAssociation({
        variables: {
          managedProductId,
          partyId,
          financialAccountAssociation: financialAccountAssociationInput,
        },
      });
      const associationData = bankAccountAssociationResult.data?.saveFinancialAccountAssociation;
      setEntityForDocusign({
        entityId: associationData?.id ?? '',
        entityType: AssociatedEntityType.BANK_ACCOUNT_ASSOCIATION,
      });
      const isDocusignRequired =
        // Check for null to maintain backwards compatibility
        associationData?.verificationStatus === null ||
        associationData?.verificationStatus === FinancialAccountAssociationVerificationStatus.NEEDS_DOCUSIGN;

      if (isDocusignRequiredForFinancialAccountLinkageInRCE && isDocusignRequired) {
        const documents = getLegalDocumentsForPendingBankAccountAssociation(addFundsFormProps.legalDocuments ?? []);
        const pendingDocusignForEntity = documents?.find(doc =>
          doc.associatedEntities?.bankAccountAssociationIds.includes(associationData.id),
        );
        if (pendingDocusignForEntity) {
          setPendingSigningDocumentIdForEntity(pendingDocusignForEntity.signingDocumentId);

          const nextPendingSignee = getNextPendingSignee(pendingDocusignForEntity.signees);
          setIsResendDocusignCtaOnDocusignRequiredModal(nextPendingSignee?.partyId !== addFundsFormProps.viewerPartyId);
        }
        openDocusignRequiredModal();
      } else {
        await createCashTransfer({ variables: { createCashTransferInput: createCashTransferInputVars } });
        handleOnClose(content?.feedback_message ?? '');
        refetchAccounts?.();
      }
    } catch (err: any) {
      setSymphonyError(err);
      setDisableButton(true);
    } finally {
      setIsSubmitted(false);
    }
  };

  const resendDocusign = async (signingDocumentId: string | undefined) => {
    try {
      await resendEnvelopeToNextRecipient({
        variables: { signingDocumentId: signingDocumentId || '' },
      });
      handleOnClose(content?.feedback_message ?? '');
      refetchAccounts?.();
    } catch (err) {
      console.error(`Error Sending Docusign to Client`, err);
    }
  };

  const handlePrimaryClickOnDocusignRequired = async () => {
    if (createCashTransferInput) {
      try {
        await createCashTransfer({ variables: { createCashTransferInput } });

        if (isResendDocusignCtaOnDocusignRequiredModal) {
          resendDocusign(pendingEntitySigningDocumentId);
        } else {
          redirectToSignDocuments?.(
            managedProductId,
            entityForDocusign?.entityId ? entityForDocusign : undefined,
            !!pendingEntitySigningDocumentId,
          );
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleOnClose = (message?: string) => {
    setShowWarningForPendingPUW(enablePendingPUWWarning);
    onClose?.(message);
  };

  return (
    <>
      <Modal
        actions={
          <>
            <Button id="close-btn" onClick={() => handleOnClose()} variant="outlined">
              {(showWarningForPendingPUW
                ? content?.pending_modal_change_warning?.cancel
                : content?.modal_fields?.secondary_cta) ?? ''}
            </Button>
            {showWarningForPendingPUW ? (
              <Button id="continue-btn" onClick={() => setShowWarningForPendingPUW(false)} variant="contained">
                {content?.pending_modal_change_warning?.continue ?? ''}
              </Button>
            ) : (
              <LoadingButton
                disabled={disableButton}
                id="add-btn"
                loading={isSubmitted || isRefetchingAccounts}
                onClick={handleSubmit}
                variant="contained"
              >
                {content?.modal_fields?.primary_cta ?? ''}
              </LoadingButton>
            )}
          </>
        }
        content={
          showWarningForPendingPUW ? (
            <>
              <WarningIcon fontSize="large" sx={{ color: 'warning.main', mb: 2 }} />
              <RteContent
                data={content?.pending_modal_change_warning?.message ?? ''}
                sx={{ '& h1, h2, h3, h4, h5, h6': { mb: 3 } }}
              />
            </>
          ) : (
            <AddFundsForm
              accounts={accounts}
              content={content}
              contentOptions={contentOptions}
              ignoreInsufficientFunds={ignoreInsufficientFunds}
              isRefetchingAccounts={isRefetchingAccounts}
              isSubmitted={isSubmitted}
              managedProductId={managedProductId}
              marketHolidays={marketHolidayList?.marketHolidays ?? []}
              onBankAccountSelected={() => setSymphonyError(undefined)}
              onInavlidInput={onInavlidInput}
              onLinkAccount={onLinkAccount}
              onSubmitCallback={onSubmitCallback}
              partyId={partyId}
              partyIdFA={partyIdFA}
              plaidLinkageType={plaidLinkageType}
              refetchBankAccounts={refetchBankAccountsData}
              refetchParentContainerAccounts={refetchAccounts}
              setDisableButton={setDisableButton}
              symphonyError={symphonyError}
              userBirthDate={clientInfoData?.birthDate}
              userName={clientInfoData?.userName}
              {...addFundsFormProps}
            />
          )
        }
        contentOptions={contentOptions}
        data-qa={dataQa}
        error={error}
        loading={loading}
        onClose={() => handleOnClose()}
        open={open}
        title={
          (showWarningForPendingPUW ? content?.pending_modal_change_warning?.heading : content?.modal_fields?.title) ??
          ''
        }
      />
      {isDocusignRequiredForFinancialAccountLinkageInRCE && (
        <DocusignRequired
          contentOptions={contentOptions}
          isResendDocusign={isResendDocusignCtaOnDocusignRequiredModal}
          onClose={closeDocusignRequiredlModal}
          onPrimaryClick={handlePrimaryClickOnDocusignRequired}
          open={isDocusignRequiredModalOpen}
        />
      )}
    </>
  );
};
