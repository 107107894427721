import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { DocusignRequired } from '../DocusignRequired';

import { AddFundsV2Form } from './AddFundsV2Form';
import { useGetAddFundsData } from './hooks/useGetAddFundsData';
import { FormData, OnSubmitCallback, Props, Step } from './types';

import {
  AssociatedEntityInput,
  AssociatedEntityType,
  CreateCashTransferInput,
  FinancialAccountAssociationVerificationStatus,
  TransferFrequency,
  TransferFrequencyType,
} from '~/__generated__';
import { Modal } from '~/components/ui/Modal';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Button, LoadingButton, WarningIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { useResendEnvelopeToNextRecipient } from '~/hooks/docusign/symphony';
import {
  useCreateAssetTransfer,
  useCreateCashTransfer,
  useSaveFinancialAccountAssociation,
} from '~/hooks/financial-account/symphony';
import { getLegalDocumentsForPendingBankAccountAssociation, getNextPendingSignee } from '~/utils/account';
import { NetworkStatus } from '~/utils/apollo-client';
import { getUserName } from '~/utils/client';
import { FundingSources, useCoreConfig } from '~/utils/config';

export const AddFundsV2Modal: React.FC<Props> = ({
  accountHasRecurringDeposit,
  dataQa = 'add-funds-modal',
  enablePendingPUWWarning = false,
  open = false,
  contentOptions,
  ignoreInsufficientFunds = false,
  managedProductId,
  onLinkAccount,
  onClose,
  partyId,
  partyIdFA,
  plaidLinkageType,
  redirectToSignDocuments,
  refetchAccounts,
  showVerifiedBankAccounts,
  syncExternalBankAccounts,
  ...addFundsFormProps
}) => {
  const {
    featureFlags: {
      isDocusignRequiredForFinancialAccountLinkageInRCE,
      showWarningForNonVerifiedFinancialAccountLinkages,
    },
  } = useCoreConfig();
  const {
    open: isDocusignRequiredModalOpen,
    openModal: openDocusignRequiredModal,
    onClose: closeDocusignRequiredlModal,
  } = useModalState();
  const [createCashTransfer] = useCreateCashTransfer();
  const [createAssetTransfer] = useCreateAssetTransfer();
  const [saveFinancialAccountAssociation] = useSaveFinancialAccountAssociation();
  const [resendEnvelopeToNextRecipient] = useResendEnvelopeToNextRecipient();

  const methods = useForm<FormData>({ shouldUnregister: false });
  const { getValues, reset, clearErrors } = methods;
  const [fundingSourceToFetch, setFundingSourceToFetch] = useState<FundingSources>(FundingSources.bankAccount);
  const [showWarningForPendingPUW, setShowWarningForPendingPUW] = useState<boolean>(enablePendingPUWWarning);
  const [disableButton, setDisableButton] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [currentStep, setCurrentStep] = useState<Step>('collectDetails');
  const [defaultTransferFrequencyType, setDefaultTransferFrequencyType] = useState<TransferFrequencyType>();
  const additionFrequency = getValues('additionFrequency');
  const [createCashTransferInput, setCreateCashTransferInput] = useState<CreateCashTransferInput[]>();
  const [symphonyError, setSymphonyError] = useState<Error | undefined>();
  const [isResendDocusignCtaOnDocusignRequiredModal, setIsResendDocusignCtaOnDocusignRequiredModal] = useState<boolean>(
    false,
  );
  const [pendingEntitySigningDocumentId, setPendingSigningDocumentIdForEntity] = useState<string>();
  const [entityForDocusign, setEntityForDocusign] = useState<AssociatedEntityInput>();

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  const includeManagedProducts =
    isDocusignRequiredForFinancialAccountLinkageInRCE || showWarningForNonVerifiedFinancialAccountLinkages;
  const { data, error: dataError, loading: dataLoading } = useGetAddFundsData({
    contentOptions,
    bankAccountsFetchingVariables: {
      includeManagedProducts,
      syncExternalBankAccounts,
      showWarningForPendingPUW,
      showVerifiedBankAccounts,
    },
    open,
    partyId,
    fundingSourceToFetch,
    managedProductId,
  });
  const content = data?.content;
  useEffect(() => {
    if (open) {
      setCurrentStep('collectDetails');
      setDefaultTransferFrequencyType(TransferFrequencyType.ONE_TIME);
    }
  }, [open]);

  const isRefetchingAccounts = data?.accountsData?.accountsNetworkStatus === NetworkStatus.refetch;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  useEffect(() => {
    // Do not show a loading state if it is a refetch so that the entire modal is not re-rendered.
    if (!isRefetchingAccounts) {
      setLoading(dataLoading);
    }
  }, [dataLoading, isRefetchingAccounts]);

  useEffect(() => {
    if (!dataLoading && dataError) {
      // To log error only after all the requests have completed
      console.error(dataError);
      setError(dataError);
    }
  }, [dataError, dataLoading]);

  const onSubmitCallback = async ({
    assetTransferInput,
    bankAccountId,
    bankAccountInput,
    createCashTransferInputVars,
  }: OnSubmitCallback) => {
    try {
      if (assetTransferInput) {
        const result = await createAssetTransfer({ variables: { createAssetTransferInput: assetTransferInput } });
        redirectToSignDocuments?.(
          managedProductId,
          {
            entityId: result.data?.createAssetTransfer?.id ?? '',
            entityType: AssociatedEntityType.ASSET_DEPOSIT,
          },
          false,
        );
      } else if (createCashTransferInputVars) {
        setCreateCashTransferInput(createCashTransferInputVars);
        const financialAccountAssociationInput = {
          ...(bankAccountInput ? { bankAccount: bankAccountInput } : { bankAccountId }),
        };
        const bankAccountAssociationResult = await saveFinancialAccountAssociation({
          variables: {
            managedProductId,
            partyId,
            financialAccountAssociation: financialAccountAssociationInput,
          },
        });
        const associationData = bankAccountAssociationResult.data?.saveFinancialAccountAssociation;
        setEntityForDocusign({
          entityId: associationData?.id ?? '',
          entityType: AssociatedEntityType.BANK_ACCOUNT_ASSOCIATION,
        });
        const isDocusignRequired =
          // Check for null to maintain backwards compatibility
          associationData?.verificationStatus === null ||
          associationData?.verificationStatus === FinancialAccountAssociationVerificationStatus.NEEDS_DOCUSIGN;

        if (isDocusignRequiredForFinancialAccountLinkageInRCE && isDocusignRequired) {
          const documents = getLegalDocumentsForPendingBankAccountAssociation(addFundsFormProps.legalDocuments ?? []);
          const pendingDocusignForEntity = documents?.find(doc =>
            doc.associatedEntities?.bankAccountAssociationIds.includes(entityForDocusign?.entityId ?? ''),
          );
          if (pendingDocusignForEntity) {
            setPendingSigningDocumentIdForEntity(pendingDocusignForEntity.signingDocumentId);

            const nextPendingSignee = getNextPendingSignee(pendingDocusignForEntity.signees);
            setIsResendDocusignCtaOnDocusignRequiredModal(
              nextPendingSignee?.partyId !== addFundsFormProps.viewerPartyId,
            );
          }
          openDocusignRequiredModal();
        } else {
          await Promise.all(
            createCashTransferInputVars.map(cashTransfer => {
              createCashTransfer({ variables: { createCashTransferInput: cashTransfer } });
            }),
          );
          setIsSubmitted(false);
          setCurrentStep('confirmation');
        }
      }
    } catch (err: any) {
      setSymphonyError(err);
      setDisableButton(true);
    }
  };

  const resendDocusign = async (signingDocumentId: string | undefined) => {
    try {
      await resendEnvelopeToNextRecipient({
        variables: { signingDocumentId: signingDocumentId || '' },
      });
      handleOnClose();
      refetchAccounts?.();
    } catch (err) {
      console.error(`Error Sending Docusign to Client`, err);
    }
  };

  const handleDocusignRedirection = async () => {
    try {
      if (createCashTransferInput) {
        await Promise.all(
          createCashTransferInput.map(cashTransfer => {
            createCashTransfer({ variables: { createCashTransferInput: cashTransfer } });
          }),
        );
      }

      if (isResendDocusignCtaOnDocusignRequiredModal) {
        resendDocusign(pendingEntitySigningDocumentId);
      } else {
        redirectToSignDocuments?.(
          managedProductId,
          entityForDocusign?.entityId ? entityForDocusign : undefined,
          !!pendingEntitySigningDocumentId,
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnClose = (message?: string) => {
    setShowWarningForPendingPUW(enablePendingPUWWarning);
    reset({
      fundingSource: FundingSources.bankAccount,
      eligibleSourceAccount: undefined,
    });
    clearErrors();
    onClose?.(message);
  };

  return (
    <>
      <Modal
        actions={
          <>
            {currentStep === 'collectDetails' ? (
              <Button id="close-btn" onClick={() => handleOnClose()} variant="outlined">
                {(showWarningForPendingPUW ? content?.pendingModalChangeWarning.cancel : content?.ctas.secondary) ?? ''}
              </Button>
            ) : currentStep === 'playback' ? (
              <Button id="back-btn" onClick={() => setCurrentStep('collectDetails')} variant="outlined">
                {content?.ctas.back}
              </Button>
            ) : null}

            {showWarningForPendingPUW ? (
              <Button id="continue-btn" onClick={() => setShowWarningForPendingPUW(false)} variant="contained">
                {content?.pendingModalChangeWarning.continue ?? ''}
              </Button>
            ) : currentStep === 'collectDetails' ? (
              <Button id="continue-btn" onClick={() => setTriggerValidation(true)} variant="contained">
                {content?.ctas.continue}
              </Button>
            ) : currentStep === 'playback' ? (
              <LoadingButton
                disabled={disableButton}
                id="add-btn"
                loading={isSubmitted || isRefetchingAccounts}
                onClick={handleSubmit}
                variant="contained"
              >
                {content?.ctas.confirm}
              </LoadingButton>
            ) : (
              <>
                <Box flexGrow={1} justifyContent="flex-start">
                  {accountHasRecurringDeposit ||
                  (additionFrequency && additionFrequency !== TransferFrequency.ONE_TIME) ? (
                    <Button
                      id="add-more-funds"
                      onClick={() => {
                        reset();
                        setDefaultTransferFrequencyType(TransferFrequencyType.ONE_TIME);
                        setCurrentStep('collectDetails');
                      }}
                      variant="text"
                    >
                      {content?.ctas.addMoreFunds}
                    </Button>
                  ) : (
                    <Button
                      id="setup-recurring-deposit"
                      onClick={() => {
                        reset();
                        setDefaultTransferFrequencyType(TransferFrequencyType.RECURRING);
                        setCurrentStep('collectDetails');
                      }}
                      variant="text"
                    >
                      {content?.ctas.setupRecurringDeposit}
                    </Button>
                  )}
                </Box>
                <Button id="close-btn" onClick={() => handleOnClose()} variant="outlined">
                  {content?.ctas.done}
                </Button>
              </>
            )}
          </>
        }
        content={
          showWarningForPendingPUW ? (
            <>
              <WarningIcon fontSize="large" sx={{ color: 'warning.main', mb: 2 }} />
              <RteContent
                data={content?.pendingModalChangeWarning.message ?? ''}
                sx={{ '& h1, h2, h3, h4, h5, h6': { mb: 3 } }}
              />
            </>
          ) : content ? (
            <FormProvider {...methods}>
              <AddFundsV2Form
                accountHasRecurringDeposit={accountHasRecurringDeposit}
                accountsData={data.accountsData}
                content={content}
                contentOptions={contentOptions}
                currentStep={currentStep}
                defaultFundingSource={fundingSourceToFetch}
                defaultTransferFrequencyType={defaultTransferFrequencyType}
                ignoreInsufficientFunds={ignoreInsufficientFunds}
                isRefetchingAccounts={isRefetchingAccounts}
                isSubmitted={isSubmitted}
                managedProductId={managedProductId}
                marketHolidays={data.marketHolidayList?.marketHolidays ?? []}
                onAccountSelected={() => setSymphonyError(undefined)}
                onLinkAccount={onLinkAccount}
                onSubmitCallback={onSubmitCallback}
                onValidationSuccessful={() => {
                  setTriggerValidation(false);
                  setCurrentStep('playback');
                }}
                onValidationUnsuccessful={() => setTriggerValidation(false)}
                partyId={partyId}
                partyIdFA={partyIdFA}
                plaidLinkageType={plaidLinkageType}
                refetchParentContainerAccounts={refetchAccounts}
                setDisableButton={setDisableButton}
                setFundingSourceToFetch={setFundingSourceToFetch}
                symphonyError={symphonyError}
                triggerValidation={triggerValidation}
                userBirthDate={data.clientInfoData?.client?.party?.partyPerson?.birthDate}
                userName={getUserName(data.clientInfoData)}
                {...addFundsFormProps}
              />
            </FormProvider>
          ) : null
        }
        contentOptions={contentOptions}
        data-qa={dataQa}
        error={error}
        loading={loading}
        onClose={() => handleOnClose()}
        open={open}
        title={
          showWarningForPendingPUW
            ? content?.pendingModalChangeWarning.heading ?? ''
            : currentStep === 'collectDetails'
            ? content?.title ?? ''
            : currentStep === 'playback'
            ? content?.labels.playbackTitle
            : content?.labels.success
        }
      />
      {isDocusignRequiredForFinancialAccountLinkageInRCE && (
        <DocusignRequired
          contentOptions={contentOptions}
          isResendDocusign={isResendDocusignCtaOnDocusignRequiredModal}
          onClose={closeDocusignRequiredlModal}
          onPrimaryClick={handleDocusignRedirection}
          open={isDocusignRequiredModalOpen}
        />
      )}
    </>
  );
};
