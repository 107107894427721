import React from 'react';

import { ControlFieldConfig, ControlFieldValues } from './types';

import { DateFilters } from '~/components/ControlFields/DateFilters';
import { DateFilterData } from '~/components/ControlFields/DateFilters/types';
import { Grid } from '~/components/ui/mui';

export interface ControlFieldData extends DateFilterData {
  config: ControlFieldConfig;
  values: ControlFieldValues;
}

interface Props {
  data: ControlFieldData;
  dataQa?: string;
}

export const ControlFieldSection: React.FC<Props> = ({ dataQa = 'control-field-section', data }) => (
  <Grid columns={12} container data-qa={dataQa} direction="row" spacing={4}>
    <DateFilters
      dateFilterConfig={data.config}
      onCustomDateRangeChange={data.onCustomDateRangeChange}
      onDateRangeOptionChange={data.onDateRangeOptionChange}
      onYearOptionChange={data.onYearOptionChange}
    />
  </Grid>
);
