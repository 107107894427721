import { GetAccountDetailsV2Content, GetAccountDetailsV2ContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetAccountDetailsV2Content = (
  options?: QueryHookOptions<GetAccountDetailsV2Content, GetAccountDetailsV2ContentVariables>,
): QueryResult<GetAccountDetailsV2Content, GetAccountDetailsV2ContentVariables> => {
  return useContentstackQuery(queries.GetAccountDetailsV2Content, options);
};
