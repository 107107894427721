import { CreateEntityUpdateWorkflow, CreateEntityUpdateWorkflowVariables } from './__generated__/mutations.v2';
import { UpdateEntityUpdateWorkflow, UpdateEntityUpdateWorkflowVariables } from './__generated__/mutations.v2';
import * as mutations from './mutations.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useCreateEntityUpdateWorkflow = (
  options?: MutationHookOptions<CreateEntityUpdateWorkflow, CreateEntityUpdateWorkflowVariables>,
): MutationTuple<CreateEntityUpdateWorkflow, CreateEntityUpdateWorkflowVariables> => {
  return useSymphonyMutation(mutations.CreateEntityUpdateWorkflow, options);
};

export const useUpdateEntityUpdateWorkflow = (
  options?: MutationHookOptions<UpdateEntityUpdateWorkflow, UpdateEntityUpdateWorkflowVariables>,
): MutationTuple<UpdateEntityUpdateWorkflow, UpdateEntityUpdateWorkflowVariables> => {
  return useSymphonyMutation(mutations.UpdateEntityUpdateWorkflow, options);
};
