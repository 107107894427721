import { endOfYear, getYear, startOfYear } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

import { DateFilterConfig, DateFilterData, DateFilterValues, DateRangeOptions } from '../types';
import { getDateRangeValue } from '../utils';

import { DateRange } from '~/components/ui/DateRangePicker';

export const useDateFilterData = (
  defaultFilterConfig: DateFilterConfig,
  defaultFilterValues: DateFilterValues,
  onValueChange?: (values: DateFilterValues) => void,
): DateFilterData => {
  const [values, setValues] = useState<DateFilterValues>(defaultFilterValues);

  const onCustomDateRangeChange = useCallback((dateRange?: DateRange) => {
    setValues(prev => {
      onValueChange?.({ ...prev, dateRange });
      return {
        ...prev,
        dateRange,
      };
    });
  }, []);

  const onDateRangeOptionChange = useCallback((value: string) => {
    const dateRangeOption = Object.keys(DateRangeOptions).find(
      (option): option is DateRangeOptions => option === value,
    );
    const newDateRange = getDateRangeValue(
      dateRangeOption,
      defaultFilterConfig.dateRangeOption?.minDate,
      defaultFilterConfig.dateRangeOption?.maxDate,
    );

    // To prefill the current date range values in Custom Date Range field
    setValues(prev => {
      onValueChange?.({ ...prev, dateRangeOption, dateRange: newDateRange ?? prev.dateRange });
      return {
        ...prev,
        dateRangeOption,
        dateRange: newDateRange ?? prev.dateRange,
      };
    });
  }, []);

  const onYearOptionChange = useCallback((year: number) => {
    // To prefill the current date range values in Custom Date Range field
    const dateRange = {
      endDate: year === getYear(new Date()) ? new Date() : endOfYear(new Date(year, 0, 1)),
      startDate: startOfYear(new Date(year, 0, 1)),
    };

    setValues({
      dateRange,
      yearOption: year,
    });
    onValueChange?.({ dateRange, yearOption: year });
  }, []);

  const config = useMemo((): DateFilterConfig => {
    const dateRangeOptionValue = Object.keys(DateRangeOptions).find(
      (option): option is DateRangeOptions => option === values.dateRangeOption,
    );

    return {
      customDateRange:
        defaultFilterConfig.customDateRange && dateRangeOptionValue === DateRangeOptions.Custom
          ? {
              ...defaultFilterConfig.customDateRange,
              // To prefill the selected date range of the dropdown
              defaultValues: values.dateRange,
            }
          : undefined,
      dateRangeOption: defaultFilterConfig.dateRangeOption
        ? {
            ...defaultFilterConfig.dateRangeOption,
            value: values.dateRangeOption,
          }
        : undefined,
      yearOption: defaultFilterConfig.yearOption
        ? {
            ...defaultFilterConfig.yearOption,
            value: values.yearOption,
          }
        : undefined,
    };
  }, [defaultFilterConfig, values]);

  return useMemo(
    (): DateFilterData => ({
      config,
      onCustomDateRangeChange,
      onDateRangeOptionChange,
      onYearOptionChange,
      values,
    }),
    [config, onCustomDateRangeChange, onDateRangeOptionChange, onYearOptionChange, values],
  );
};
