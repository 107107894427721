import React, { useContext } from 'react';

import OpsDashboardContext from '../../../OpsDashboardContext';
import { LabeledValueField } from '../../LabeledValueField';
import { useAccountNumberContent } from '../hooks';

export interface Props {
  accountNumber?: string | null;
  accountNumberText?: string;
  label?: string | null;
  onClick?: () => void;
  redirectUrl?: string;
}

export const AccountNumber: React.FC<Props> = ({ accountNumber, onClick, accountNumberText, label, redirectUrl }) => {
  const { contentOptions } = useContext(OpsDashboardContext);
  const { data } = useAccountNumberContent({ contentOptions });
  return (
    <LabeledValueField
      copyLabel={data?.copyLabel}
      label={label}
      onClick={onClick}
      redirectUrl={redirectUrl}
      showCopyField={!!accountNumber}
      successLabel={data?.successfullyCopied}
      textToCopy={accountNumber ?? ''}
      value={accountNumberText}
    />
  );
};
