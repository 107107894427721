import React, { ComponentProps } from 'react';

import { OpsDashboard } from '.';
import { CommentComponentColumn } from './common/Comment';
import { GetOpsDashboardContent } from './contentstack/__generated__/query.v2';
import { OpsDashboardUser } from './utils';

import { FlagReason } from '~/__generated__';
import { ContentOptions } from '~/utils/contentstack';

export interface OpsDashboardContextValue {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  hiddenFlagReasons: FlagReason[];
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  opsContentData?: GetOpsDashboardContent;
}

const OpsDashboardContext = React.createContext<OpsDashboardContextValue>({} as OpsDashboardContextValue);

export const OpsDashboardProvider = OpsDashboardContext.Provider;
export default OpsDashboardContext;
