import {
  AccountClosures,
  AccountClosures_closeWorkflows_closeWorkflows_flags,
  AccountClosuresVariables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import {
  UpdateCloseWorkflow,
  UpdateCloseWorkflowVariables,
} from '~/containers/OpsDashboard/ClosuresTab/symphony/__generated__/mutation.v2';
import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ClosureFlags = AccountClosures_closeWorkflows_closeWorkflows_flags;

export const useAccountClosures = (
  options?: QueryHookOptions<AccountClosures, AccountClosuresVariables>,
): QueryResult<AccountClosures, AccountClosuresVariables> => {
  return useSymphonyQuery(queries.AccountClosures, options);
};

export const useUpdateCloseWorkflow = (
  options?: MutationHookOptions<UpdateCloseWorkflow, UpdateCloseWorkflowVariables>,
): MutationTuple<UpdateCloseWorkflow, UpdateCloseWorkflowVariables> => {
  return useSymphonyMutation(mutations.UpdateCloseWorkflow, options);
};
