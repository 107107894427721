import {
  GetAccountSummaryContent,
  GetAccountSummaryContent_all_account_summary_items_accessibility,
  GetAccountSummaryContent_all_account_summary_items_sections,
  GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsAccountList,
  GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsAccountList_account_list_pending_states,
  GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsBalance,
  GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsMarketing,
  GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsQuestions,
  GetAccountSummaryContentV2,
  GetAccountSummaryContentV2_all_account_summary_v2_items_fields,
  GetAccountSummaryContentV2_all_account_summary_v2_items_sections_AccountSummaryV2SectionsAccountList_account_list,
  GetAccountSummaryContentV2_all_account_summary_v2_items_sections_AccountSummaryV2SectionsAccountList_account_list_labels,
  GetAccountSummaryContentV2Variables,
  GetAccountSummaryContentVariables,
} from './__generated__/queries.v2';
import { GetAccountSummaryGlobalContent, GetAccountSummaryGlobalContentVariables } from './__generated__/queries.v2';
import {
  GetAccountSummaryMoreGlobalContent,
  GetAccountSummaryMoreGlobalContentVariables,
} from './__generated__/queries.v2';
import * as queries from './queries.gql';

import type { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type AccountSummaryAccessibility = GetAccountSummaryContent_all_account_summary_items_accessibility;
export type AccountSummarySection = GetAccountSummaryContent_all_account_summary_items_sections;
export type AccountListSection = GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsAccountList;
export type AccountTotalSection = GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsBalance;
export type SupportQuestionsSection = GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsQuestions;
export type AccountPendingStates = GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsAccountList_account_list_pending_states;
export type MarketingSection = GetAccountSummaryContent_all_account_summary_items_sections_AccountSummarySectionsMarketing;
export type AccountListSectionV2 = GetAccountSummaryContentV2_all_account_summary_v2_items_sections_AccountSummaryV2SectionsAccountList_account_list;
export type AccountListLabelsV2 = GetAccountSummaryContentV2_all_account_summary_v2_items_sections_AccountSummaryV2SectionsAccountList_account_list_labels;
export type AccountSummaryV2ContentFields = GetAccountSummaryContentV2_all_account_summary_v2_items_fields;
export const isAccountListSection = (section: AccountSummarySection | null): section is AccountListSection =>
  section?.__typename === 'AccountSummarySectionsAccountList';

export const isAccountTotalSection = (section: AccountSummarySection | null): section is AccountTotalSection =>
  section?.__typename === 'AccountSummarySectionsBalance';

export const isMarketingSection = (section: AccountSummarySection | null): section is MarketingSection =>
  section?.__typename === 'AccountSummarySectionsMarketing';

export const isSupportQuestions = (section: AccountSummarySection | null): section is SupportQuestionsSection =>
  section?.__typename === 'AccountSummarySectionsQuestions';

export const useGetAccountSummaryContent = (
  options?: QueryHookOptions<GetAccountSummaryContent, GetAccountSummaryContentVariables>,
): QueryResult<GetAccountSummaryContent, GetAccountSummaryContentVariables> => {
  return useContentstackQuery(queries.GetAccountSummaryContent, options);
};

export const useGetAccountSummaryContentV2 = (
  options?: QueryHookOptions<GetAccountSummaryContentV2, GetAccountSummaryContentV2Variables>,
): QueryResult<GetAccountSummaryContentV2, GetAccountSummaryContentV2Variables> => {
  return useContentstackQuery(queries.GetAccountSummaryContentV2, options);
};

export const useGetAccountSummaryGlobalContent = (
  options?: QueryHookOptions<GetAccountSummaryGlobalContent, GetAccountSummaryGlobalContentVariables>,
): QueryResult<GetAccountSummaryGlobalContent, GetAccountSummaryGlobalContentVariables> => {
  return useContentstackQuery(queries.GetAccountSummaryGlobalContent, options);
};

// Apparently Max concurrent content types per query for our organization is '3' so we can't put the
// query to get the ownership type content from contentstack into the existing GetAccountSummaryGlobalContent query.
// If we do we get an error from our call to content stack.  So we created the GetAccountSummaryMoreGlobalContent
// query.
export const useGetAccountSummaryMoreGlobalContent = (
  options?: QueryHookOptions<GetAccountSummaryMoreGlobalContent, GetAccountSummaryMoreGlobalContentVariables>,
): QueryResult<GetAccountSummaryMoreGlobalContent, GetAccountSummaryMoreGlobalContentVariables> => {
  return useContentstackQuery(queries.GetAccountSummaryMoreGlobalContent, options);
};
