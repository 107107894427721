import React from 'react';

import { CloseAccountModalContent } from './contentstack';

import { ClosingOptions, getFooter, getHeaderText } from '~/components/modals/CloseAccount/utils';
import { Box, Button, Grid, useTheme, WarningIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';

export interface Props {
  activeClosingOptions: ClosingOptions[];
  content?: CloseAccountModalContent;
  handleSelectedClosingOption: (activeClosingOption: ClosingOptions) => void;
  maskedAccountNumber: string;
  selectedClosingOption: ClosingOptions | undefined;
  showStaticModal: boolean;
}

export const CloseAccountOptions: React.FC<Props> = ({
  activeClosingOptions,
  content,
  handleSelectedClosingOption,
  maskedAccountNumber,
  selectedClosingOption,
  showStaticModal,
}) => {
  const {
    sfCloseAccountModal: { styles },
    palette,
  } = useTheme();

  return (
    <Box>
      <Box sx={{ textAlign: 'center', mb: 5 }}>
        <WarningIcon fontSize="large" htmlColor={palette.warning.main} sx={{ pb: 2 }} />
        <RteContent
          config={{
            accountNumberPrefix: content?.close_account?.message?.account_number_prefix,
            maskedAccountNumber,
          }}
          data={getHeaderText(showStaticModal, content)}
          sx={{ px: { xs: 0, md: 4 } }}
        />
      </Box>
      <Grid container justifyContent="center" spacing={3}>
        {activeClosingOptions.includes(ClosingOptions.WITHDRAW_AND_CLOSE) && (
          <Grid item md={(12 / activeClosingOptions.length) as 1 | 2} xs={10}>
            <Button
              fullWidth
              id="select-withdraw-and-close"
              onClick={() => handleSelectedClosingOption(ClosingOptions.WITHDRAW_AND_CLOSE)}
              sx={{
                minHeight: '155px',
                alignItems: 'flex-start',
                border: `1px solid ${palette.divider}`,
                borderRadius: 1,
                ...(selectedClosingOption === ClosingOptions.WITHDRAW_AND_CLOSE && styles?.closeAccountSelectedButton),
              }}
              variant="outlined"
            >
              <Box flexDirection="column">
                <Typography component="div" sx={{ my: 2, color: 'text.primary' }} variant="h6">
                  {content?.close_account?.withdraw_and_close?.label}
                </Typography>
                <Typography component="div" sx={{ color: 'text.primary' }} variant="body2">
                  {content?.close_account?.withdraw_and_close?.description}
                </Typography>
              </Box>
            </Button>
          </Grid>
        )}
        {activeClosingOptions.includes(ClosingOptions.END_MANAGEMENT) && (
          <Grid item md={(12 / activeClosingOptions.length) as 1 | 2} xs={10}>
            <Button
              fullWidth
              id="select-end-management"
              onClick={() => handleSelectedClosingOption(ClosingOptions.END_MANAGEMENT)}
              sx={{
                minHeight: '155px',
                alignItems: 'flex-start',
                border: `1px solid ${palette.divider}`,
                borderRadius: 1,
                ...(selectedClosingOption === ClosingOptions.END_MANAGEMENT && styles?.closeAccountSelectedButton),
              }}
              variant="outlined"
            >
              <Box flexDirection="column">
                <Typography component="div" sx={{ my: 2, color: 'text.primary' }} variant="h6">
                  {content?.close_account?.end_management?.label}
                </Typography>
                <Typography component="div" sx={{ color: 'text.primary' }} variant="body2">
                  {content?.close_account?.end_management?.description}
                </Typography>
              </Box>
            </Button>
          </Grid>
        )}
      </Grid>
      {getFooter(content)}
    </Box>
  );
};
