import {
  GetGoalReadinessStatusContent,
  GetGoalReadinessStatusContentVariables,
} from './__generated__/getGoalReadinessStatus.v2';
import * as queries from './getGoalReadinessStatus.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetGoalReadinessStatusContent = (
  options?: QueryHookOptions<GetGoalReadinessStatusContent, GetGoalReadinessStatusContentVariables>,
): QueryResult<GetGoalReadinessStatusContent, GetGoalReadinessStatusContentVariables> =>
  useContentstackQuery(queries.GetGoalReadinessStatusContent, options);
