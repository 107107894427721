import { useMemo } from 'react';

import { GoalsSummaryTab, GoalSummaryContent } from '..';
import {
  GetGoalCardSummaryContent,
  GetGoalCardSummaryContentVariables,
} from '../contentstack/__generated__/getGoalCardSummaryContent.v2';
import { GetGoalCardSummaryContent as GetGoalCardSummaryContentQuery } from '../contentstack/getGoalCardSummaryContent.gql';

import { getGoalModalContent } from './utils';

import { FinancialAccountType, GoalStatus } from '~/__generated__';
import { ContentOptions, findFieldValue, getImageAssetUrl, useContentstackQuery } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

type AttributeTextKey =
  | 'amountSaved'
  | 'projectedAmount'
  | 'retirementAge'
  | 'targetAmount'
  | 'targetDate'
  | 'targetSpending'
  | 'valueInputNeeded'
  | 'valuePerMonth';
type AttributeRteKey = 'valueAmountSaved';
type CtaKey =
  | 'deleteGoal'
  | 'markGoalCompleted'
  | 'projectionGap'
  | 'projectionGapFinished'
  | 'projectionGapPending'
  | 'viewActiveGoal'
  | 'viewPendingGoal';
type LabelRteKey = 'accountsAssociated' | 'completedDate' | 'lastUpdated' | 'successRate';
type LabelTextKey = 'accountsInProgress' | 'showLess' | 'showMore';

type GetValueCallback<TKey = string> = (key: TKey) => string;

interface Data {
  content: GoalSummaryContent;
  goalCard: {
    accountNumberFormat: string;
    getAttributeRte: GetValueCallback<AttributeRteKey>;
    getAttributeText: GetValueCallback<AttributeTextKey>;
    getCta: (key: CtaKey) => { icon?: string; label: string };
    getDescription: (goalStatus: GoalStatus) => string;
    getFinancialAccountTypeLabel: (financialAccountType: FinancialAccountType) => string;
    getImage: (partnerGoalExternalRef: string) => string;
    getLabelRte: GetValueCallback<LabelRteKey>;
    getLabelText: GetValueCallback<LabelTextKey>;
  };
}

export const useGoalCardSummaryContent = ({ contentOptions }: Variables): AsyncResult<Data> => {
  const goalCardSummaryContent = useContentstackQuery<GetGoalCardSummaryContent, GetGoalCardSummaryContentVariables>(
    GetGoalCardSummaryContentQuery,
    { variables: contentOptions },
  );

  const accountTypeItems = goalCardSummaryContent.data?.all_account_type?.items;
  const goalPageContentItem = goalCardSummaryContent.data?.all_goal_page?.items?.[0];
  const goalSummaryContentItem = goalCardSummaryContent.data?.all_goal_summary?.items?.[0];
  const goalCardContentItem = goalSummaryContentItem?.goal_cardConnection?.edges?.[0]?.node;
  const accountNumberFormatItem = goalCardContentItem?.account_number_formatConnection?.edges?.[0]?.node;

  const data: Data | undefined = useMemo(() => {
    if (goalCardSummaryContent.error || goalCardSummaryContent.loading || !goalCardContentItem) {
      return;
    }

    const goalObjectives = goalCardContentItem.objectivesConnection?.edges?.[0]?.node?.objectives;

    const birthdateError = goalSummaryContentItem.errors?.find(e => e?.key === 'birthdate');
    return {
      content: {
        ...getGoalModalContent(goalPageContentItem),
        ctas: { addGoal: findFieldValue(goalSummaryContentItem.ctas?.text ?? [], 'addGoal') },
        errors: {
          birthdate: { description: birthdateError?.description ?? '', heading: birthdateError?.heading ?? '' },
        },
        heading: findFieldValue(goalSummaryContentItem.label?.text, 'heading'),
        nullState: {
          myGoals: findFieldValue(goalSummaryContentItem.label?.rte ?? [], 'nullStateMyGoals'),
          completedGoals: findFieldValue(goalSummaryContentItem.label?.rte ?? [], 'nullStateCompletedGoals'),
        },
        tabs: {
          [GoalsSummaryTab.MY_GOALS]: findFieldValue(goalSummaryContentItem.tabs?.text ?? [], 'myGoals'),
          [GoalsSummaryTab.COMPLETED_GOALS]: findFieldValue(goalSummaryContentItem.tabs?.text ?? [], 'completedGoals'),
        },
        timeline: {
          addGoal: findFieldValue(goalSummaryContentItem.ctas?.text ?? [], 'addAGoal'),
          completedGoal: findFieldValue(goalSummaryContentItem.label?.text, 'completedGoal'),
          myGoal: findFieldValue(goalSummaryContentItem.label?.text, 'myGoal'),
          today: findFieldValue(goalSummaryContentItem.label?.text, 'today'),
        },
      },
      goalCard: {
        accountNumberFormat: accountNumberFormatItem?.pattern || '(${accountNumber})',
        getAttributeRte: key => findFieldValue(goalCardContentItem.attributes?.rte ?? [], key),
        getAttributeText: key => findFieldValue(goalCardContentItem.attributes?.text ?? [], key),
        getCta: (key: CtaKey) => {
          const cta = goalCardContentItem.ctas?.find(c => c?.key === key);
          return { icon: getImageAssetUrl(cta?.iconConnection), label: cta?.label ?? '' };
        },
        getDescription: (goalStatus: GoalStatus) =>
          findFieldValue(
            goalCardContentItem.labels?.rte,
            goalStatus === GoalStatus.ACTIVE_INCOMPLETE ? 'descriptionGoalPending' : 'descriptionGoalActive',
          ),
        getFinancialAccountTypeLabel: (financialAccountType: FinancialAccountType) =>
          accountTypeItems?.find(item => item?.key === financialAccountType)?.text ?? 'Unknown',
        getImage: (partnerGoalExternalRef: string) =>
          getImageAssetUrl(goalObjectives?.find(o => o?.key === partnerGoalExternalRef)?.icon?.secondaryConnection),
        getLabelRte: key => findFieldValue(goalCardContentItem.labels?.rte ?? [], key),
        getLabelText: key => findFieldValue(goalCardContentItem.labels?.text ?? [], key),
      },
    };
  }, [
    accountNumberFormatItem?.pattern,
    accountTypeItems,
    goalCardContentItem,
    goalCardSummaryContent.error,
    goalCardSummaryContent.loading,
    goalPageContentItem,
    goalSummaryContentItem?.ctas?.text,
    goalSummaryContentItem?.errors,
    goalSummaryContentItem?.label?.rte,
    goalSummaryContentItem?.label?.text,
    goalSummaryContentItem?.tabs?.text,
  ]);

  return {
    data,
    error:
      goalCardSummaryContent.error ?? (goalCardContentItem ? undefined : Error('Failed to get content for Goal Card')),
    loading: goalCardSummaryContent.loading,
  };
};
