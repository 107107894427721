import { ClientDocumentscontent as GetClientDocumentsContent } from '../../contentstack';
import { ClientDocumentsContent } from '../useClientDocumentsContent';

import { DocumentCategoryEnum } from '~/__generated__';

export const getClientDocumentsContent = (
  tabs: Partial<DocumentCategoryEnum>[],
  contentData?: GetClientDocumentsContent,
): ClientDocumentsContent => {
  const content = contentData?.all_client_documents?.items?.[0];

  return {
    disclaimer: content?.disclaimer ?? '',
    tabHeader: tabs.reduce<ClientDocumentsContent['tabHeader']>(
      (acc: ClientDocumentsContent['tabHeader'], tab: DocumentCategoryEnum): ClientDocumentsContent['tabHeader'] => {
        acc[tab] = content?.tab_header?.find(header => tab.toString() === header?.tab_key)?.tab_label ?? '';

        return acc;
      },
      {},
    ),
  };
};
