export enum Partner {
  AlliantCU = 'AlliantCU',
  BNS = 'BNS',
  CSB = 'CSB',
  Citizens = 'Citizens',
  Core = 'Core',
  EDJ = 'EDJ',
  FRC = 'FRC',
  NYL = 'NYL',
  SAN = 'SAN',
  SigFig = 'SigFig',
  USB = 'UBS',
  WellsFargo = 'WellsFargo',
}

export enum Product {
  DigitalWealth = 'DigitalWealth',
  DigitalWealthPro = 'DigitalWealthPro',
  OpsDashboard = 'OpsDashboard',
}

export enum Locale {
  en_ca = 'en-ca',
  en_us = 'en-us',
  fr_ca = 'fr-ca',
}

/**
 * The environment and token are optional properties that are part of temporary solution to indicate to
 * Symphony's continueQuestionnaire mutation which environment the FE call is coming from.
 * BNS currently requires Symphony to make contentstack call to resolve the mutation to fetch the partner_text field
 * for them to record as part of their paperwork api.
 */
export interface ContentOptions {
  environment?: string;
  locale: Locale;
  partner: Partner;
  product: Product;
  token?: string;
}

export type SysAsset = {
  url: string | null;
};
export type SysAssetEdge = {
  node: SysAsset | null;
};
export type SysAssetConnection = {
  edges: (SysAssetEdge | null)[] | null;
};

export type ContentstackField = {
  key: string | null;
  value: string | null;
};

export interface FieldsGroup {
  rte: (ContentstackField | null)[] | null | undefined;
  text: (ContentstackField | null)[] | null | undefined;
}
