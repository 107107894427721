import {
  GetRestrictionsTabContent_all_restrictions_tab,
  GetRestrictionsTabContentQuery,
  GetRestrictionsTabContentQueryVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type RestrictionsTabContent = GetRestrictionsTabContent_all_restrictions_tab;

export const useGetTradingSuspensionsTabContent = (
  options?: QueryHookOptions<GetRestrictionsTabContentQuery, GetRestrictionsTabContentQueryVariables>,
): QueryResult<GetRestrictionsTabContentQuery, GetRestrictionsTabContentQueryVariables> => {
  return useContentstackQuery(queries.GetRestrictionsTabContent, options);
};
