import React, { FC, useMemo } from 'react';

import { useAccountPropertyContent } from '../hooks';
import { AccountListContentV2 } from '../hooks/useAccountSummaryContentV2';
import {
  AccountActionToInvoke,
  AccountData,
  AccountDetailsToShow,
  AccountProps,
  Content as AccountContent,
  Labels,
} from '../types';

import { Account } from './Account';

import {
  AssociatedEntityType,
  ManagedProductType,
  PerformanceMethodTypes,
  RelationshipName,
} from '~/__generated__/symphonyTypes.v2';
import { isCustomAction } from '~/components/AccountActions/utils';
import { AccountList as BaseAccountList } from '~/components/AccountSummary/AccountList';
import { Content as PendingStatusContent } from '~/components/AccountSummary/AccountPendingStatus';
import { useLoadingButtonState } from '~/components/ui/Button/LoadingButton/hooks';
import { Box, Skeleton } from '~/components/ui/mui';
import { PlaidLinkType } from '~/hooks/plaid-link';
import { AssetClassTier, DonutAllocation } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import { allNumericDateFormat } from '~/utils/format/date';

export interface Content {
  accessibility: { accountMenuAriaLabel: string };
  accountNumberFormat: string;
  accountNumberPrefix: string;
  actionToInvoke?: AccountActionToInvoke;
  actions: AccountContent['actions'];
  ctas: AccountContent['ctas'];
  ctasMoreAction: AccountContent['ctasMoreAction'];
  editPortfolioFeedbackMessage?: string;
  emptyState: string;
  emptyStateImgUrl: string;
  errorStates: {
    needsAttention: string;
    pendingClosed: string;
    suspended: string;
  };
  labels: Labels;
  modelPortfolioDonutDimensions: {
    innerRadius: number;
    size: number;
  };
  pendingStatus: PendingStatusContent;
  shouldMaskAccountNumber: boolean;
  title: string;
}

export interface AccountListAccount extends AccountData {
  donutAllocations?: Array<DonutAllocation>;
  openedOn?: string;
  relationshipNames: RelationshipName[];
}

export interface Props {
  /**
   * Function used to sort list of accounts
   */
  accountCompareFunction?: (a: AccountListAccount, b: AccountListAccount) => number;
  accountDetailsToShow?: AccountDetailsToShow;
  accountProps?: Partial<AccountProps>;
  accounts: AccountListAccount[];
  assetClassTier?: (program: ManagedProductType) => AssetClassTier;
  content: Content;
  contentOptions: ContentOptions;
  contentV2?: AccountListContentV2;
  dataQa?: string;
  defaultContributionAmount?: string;
  effectiveDate?: string;
  ignoreInsufficientFunds?: boolean;
  onRetakeQuestionnaire?: (managedProductId: string) => void;
  partyId: string;
  performanceMethod?: PerformanceMethodTypes;
  plaidLinkageType?: PlaidLinkType;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  refetchAccounts?: () => Promise<any>;
  refetchingAccounts?: boolean;
  showVerifiedBankAccounts?: boolean;
  syncExternalBankAccounts?: boolean;
}

export const AccountList: FC<Props> = ({
  dataQa = 'account-list',
  accountDetailsToShow,
  accounts,
  accountProps,
  assetClassTier: getAssetClassTier,
  content: { actions, actionToInvoke, ...content },
  contentOptions,
  contentV2,
  defaultContributionAmount,
  effectiveDate,
  ignoreInsufficientFunds = false,
  partyId,
  performanceMethod,
  plaidLinkageType,
  accountCompareFunction,
  onRetakeQuestionnaire,
  refetchAccounts,
  redirectToSignDocuments,
  refetchingAccounts,
  showVerifiedBankAccounts,
  syncExternalBankAccounts = false,
}) => {
  const { data: accountPropertyContent, loading: accountPropertyContentLoading } = useAccountPropertyContent({
    contentOptions,
  });

  const [openNewAccountLoading, onOpenNewAccount] = useLoadingButtonState(() => {
    if (isCustomAction(actions.openNewAccount)) {
      actions.openNewAccount.callback({ partyId });
    } else {
      console.warn('standard Open New Account not implemented');
    }
  });

  const sortedAccounts = useMemo(
    () => (accountCompareFunction ? [...accounts].sort(accountCompareFunction) : accounts),
    [accounts, accountCompareFunction],
  );

  const showOpenNewAccountCta =
    !actions.openNewAccount?.valid || actions.openNewAccount.valid({ allAccounts: sortedAccounts });

  if (accountPropertyContentLoading || !accountPropertyContent) {
    return <Skeleton data-qa={`${dataQa}-content-loading`} height={400} variant="rectangular" />;
  }

  return (
    <BaseAccountList
      dataQa={dataQa}
      effectiveDate={
        accountDetailsToShow?.showEffectiveDate && effectiveDate
          ? {
              data: content.labels.effectiveDate,
              config: { date: allNumericDateFormat(effectiveDate, { locale: contentOptions.locale }) },
            }
          : undefined
      }
      emptyState={{ img: content.emptyStateImgUrl, label: content.emptyState }}
      heading={content.title}
      primaryCta={
        showOpenNewAccountCta
          ? {
              dataHeap: `openNewAccount-${partyId}`,
              disabled: refetchingAccounts,
              label: content.ctas.openNewAccount,
              loading: refetchingAccounts || openNewAccountLoading,
              onClick: onOpenNewAccount,
            }
          : undefined
      }
    >
      {sortedAccounts.map((account, index) => {
        const assetClassTier = getAssetClassTier ? getAssetClassTier(account.program) : AssetClassTier.MODEL;
        const ariaLabel = account.accountNumber
          ? `${contentV2?.accessibility.accountMenuAccount} ${account.maskedAccountNumber} ${content.accessibility.accountMenuAriaLabel}`
          : `${contentV2?.accessibility.accountMenuAccountInProgress} ${content.accessibility.accountMenuAriaLabel}`;
        return (
          <Box key={index}>
            <Account
              {...accountProps}
              accountData={account}
              accountDetailsToShow={accountDetailsToShow}
              allAccounts={sortedAccounts}
              content={{
                accessibility: { accountMenuAriaLabel: ariaLabel },
                accountNumberPrefix: content.accountNumberPrefix,
                shouldMaskAccountNumber: content.shouldMaskAccountNumber,
                accountNumberFormat: content.accountNumberFormat,
                accountType: accountPropertyContent.getAccountTypeText(account.type),
                editPortfolioFeedbackMessage: content.editPortfolioFeedbackMessage,
                ownershipType: accountPropertyContent.getRelationshipNamesText(account.relationshipNames),
                accountProgram: accountPropertyContent.getAccountProgramText(account.program, account.attributes),
                ctas: content.ctas,
                ctasMoreAction: content.ctasMoreAction,
                modelPortfolio: {
                  donutSize: content.modelPortfolioDonutDimensions.size,
                  donutInnerRadius: content.modelPortfolioDonutDimensions.innerRadius,
                  donutShowTooltip: false,
                  donutAllocations: accountPropertyContent.getDonutAllocations(
                    account.donutAllocations ?? [],
                    assetClassTier,
                  ),
                },
                labels: content.labels,
                pendingStatus: content.pendingStatus,
                actionToInvoke,
                actions,
                errorStates: content.errorStates,
              }}
              contentOptions={contentOptions}
              contentV2={contentV2}
              contributionAmount={defaultContributionAmount}
              getAccountTypeLabel={accountPropertyContent.getAccountTypeText}
              ignoreInsufficientFunds={ignoreInsufficientFunds}
              onRetakeQuestionnaire={onRetakeQuestionnaire}
              performanceMethod={performanceMethod}
              plaidLinkageType={plaidLinkageType}
              redirectToSignDocuments={redirectToSignDocuments}
              refetchAccounts={refetchAccounts}
              refetchingAccounts={refetchingAccounts}
              showVerifiedBankAccounts={showVerifiedBankAccounts}
              syncExternalBankAccounts={syncExternalBankAccounts}
            />
          </Box>
        );
      })}
    </BaseAccountList>
  );
};
