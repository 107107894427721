import { useCallback, useMemo, useState } from 'react';

import { AssociatedEntityType, ManagedProductType, TransferFrequency } from '~/__generated__';
import { Props as WithdrawFundsModalProps } from '~/components/modals/WithdrawFunds';
import { useModalState } from '~/components/ui/Modal/hooks';
import { useGetAccountSummaryGlobalContent } from '~/containers/AccountSummary/contentstack';
import { Account } from '~/containers/AccountSummary/utils';
import { PlaidLinkType } from '~/hooks/plaid-link';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Props {
  accounts: Account[];
  contentOptions: ContentOptions;
  defaultWithdrawalAmount?: string;
  onWithdrawSuccess?: () => void;
  partyId: string;
  partyIdFA?: string;
  plaidLinkageType?: PlaidLinkType;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  showVerifiedBankAccounts?: boolean;
  syncExternalBankAccounts?: boolean;
}

interface Data {
  handleOpenRmdWithdrawFundsModal: (eligibleIraAccounts: string[]) => void;
  isWithdrawalModalOpen: boolean;
  onSourceAccountChange: (managedProductId: string) => void;
  withdrawFundsModalProps: WithdrawFundsModalProps;
}

export const useRmdWithdrawFundsModal = ({
  accounts,
  contentOptions,
  defaultWithdrawalAmount,
  partyId,
  partyIdFA,
  redirectToSignDocuments,
  onWithdrawSuccess,
  plaidLinkageType,
  showVerifiedBankAccounts,
  syncExternalBankAccounts,
}: Props): AsyncResult<Data> => {
  const { open, openModal, onClose } = useModalState();

  const [withdrawFundsModalProps, setWithdrawFundsModalProps] = useState<WithdrawFundsModalProps>({
    accountLabels: [],
    accountIdRestrictionsMap: {},
    accountName: '',
    accountMinimum: '',
    availableFunds: 0,
    availableTransferFrequencies: [TransferFrequency.MONTHLY],
    contentOptions,
    defaultWithdrawalAmount,
    isRaiseCash: false,
    managedProductType: ManagedProductType.DIGITAL_ADVICE,
    onClose,
    partyId,
    partyIdFA,
    redirectToSignDocuments,
    onWithdrawSuccess,
    managedProductId: '',
    sourceAccounts: [],
    plaidLinkageType,
    showVerifiedBankAccounts,
    syncExternalBankAccounts,
  });

  const {
    data: globalContentData,
    error: globalContentError,
    loading: globalContentLoading,
  } = useGetAccountSummaryGlobalContent({ variables: contentOptions });

  const getUpdatedWithdrawFundsModalProps = useMemo(
    () => (managedProductId: string) => {
      const selectedAccount = accounts.find(account => account.managedProductId === managedProductId);
      if (selectedAccount) {
        return {
          ...withdrawFundsModalProps,
          financialAccountType: selectedAccount.type,
          accountMinimum: selectedAccount.accountMinimumForRebalance,
          accountName: selectedAccount.maskedAccountNumber || 'unknown',
          accountId: selectedAccount.id,
          availableFunds: selectedAccount.availableWithdrawalBalance ?? 0,
          managedProductId: selectedAccount.managedProductId,
          managedProductType: selectedAccount.program,
        };
      }
      return withdrawFundsModalProps;
    },
    [accounts, withdrawFundsModalProps],
  );

  const handleOpenRmdWithdrawFundsModal = useCallback(
    (eligibleIraAccountIds: string[]) => {
      const sourceAccounts = eligibleIraAccountIds.flatMap(iraAccountId => {
        const eligibleAccount = accounts.find(account => account.managedProductId === iraAccountId);
        if (eligibleAccount) {
          const { type, maskedAccountNumber, managedProductId } = eligibleAccount;

          const label = `${
            globalContentData?.all_account_type?.items?.find(item => item?.key === type)?.text ?? type
          } ${maskedAccountNumber}`;

          return [
            {
              label,
              value: managedProductId,
            },
          ];
        }
        return [];
      });
      const updatedWithdrawFundsProps = getUpdatedWithdrawFundsModalProps(sourceAccounts[0].value);

      const accountIdRestrictionsMap = Object.assign(
        {},
        ...accounts.map(account => ({ [`${account.managedProductId}`]: account.accountRestrictions })),
      );

      if (sourceAccounts.length > 0) {
        setWithdrawFundsModalProps({
          ...updatedWithdrawFundsProps,
          accountIdRestrictionsMap,
          accountLabels: sourceAccounts.map(account => ({ label: account.label, managedProductId: account.value })),
          sourceAccounts,
        });
      }
      openModal();
    },
    [accounts, getUpdatedWithdrawFundsModalProps, globalContentData?.all_account_type?.items, openModal],
  );

  const onSourceAccountChange = useCallback(
    (managedProductId: string) => setWithdrawFundsModalProps(getUpdatedWithdrawFundsModalProps(managedProductId)),
    [getUpdatedWithdrawFundsModalProps],
  );

  return {
    data: {
      handleOpenRmdWithdrawFundsModal,
      onSourceAccountChange,
      isWithdrawalModalOpen: open,
      withdrawFundsModalProps,
    },
    loading: globalContentLoading,
    error: globalContentError,
  };
};
