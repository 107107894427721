import React, { FC, useCallback, useState } from 'react';

import { useGoalsEvent } from '../../hooks/useGoalsEvent';
import { useCompleteGoal } from '../amplify';
import { CompleteGoal } from '../amplify/__generated__/completeGoal.v2';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { ModalActionsLayout } from '~/components/ui/Modal/ModalActionsLayout';
import { Button, Collapse, Grid, LoadingButton } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { GetGoals, GetGoalsVariables } from '~/hooks/goals/amplify/__generated__/getGoals.v2';
import * as queries from '~/hooks/goals/amplify/getGoals.gql';
import { ApolloCache, FetchResult } from '~/utils/apollo-client';
import { ContentOptions } from '~/utils/contentstack';
import produce from '~/utils/immer';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Props {
  content: {
    description: string;
    image: string;
    primaryCta: string;
    secondaryCta: string;
    title: string;
  };
  contentOptions: ContentOptions;
  dataQa?: string;
  goalId: string;
  onClose: () => void;
  open: boolean;
  partyId: string;
}

export const CompleteGoalModal: FC<Props> = ({
  content,
  contentOptions,
  dataQa = 'goal-complete-modal',
  goalId,
  onClose,
  open,
  partyId,
}) => {
  const [busy, setBusy] = useState(false);
  const [errorCompleting, setErrorCompleting] = useState<Error>();
  const [completeGoal] = useCompleteGoal();
  const isMobile = useIsMediumScreen();
  const emitEvent = useGoalsEvent();

  const updateGoalsCache = useCallback(
    (cache: ApolloCache<CompleteGoal>, { data }: FetchResult<CompleteGoal>) => {
      const queryOptions = { query: queries.GetGoals, variables: { partyId } };
      const goalsCache = cache.readQuery<GetGoals, GetGoalsVariables>(queryOptions);

      if (goalsCache) {
        cache.writeQuery<GetGoals>({
          ...queryOptions,
          data: produce(goalsCache, draft => {
            const result = data?.completeGoalById;
            const completedGoal = draft.getUserByPartyId?.goals?.items.find(g => g?.id === result?.id);
            if (completedGoal && result) {
              completedGoal.goalStatus = result.goalStatus;
              completedGoal.balanceOnCompletion = result.balanceOnCompletion;
              completedGoal.completedEndDate = result.completedEndDate;
            }
          }),
        });
      }
    },
    [partyId],
  );

  const handleCompleteGoal = async () => {
    try {
      setBusy(true);
      emitEvent({ componentName: 'completeGoal', name: 'confirmCompleteGoal' });

      await completeGoal({ variables: { clientPartyIds: [partyId], goalId }, update: updateGoalsCache });

      onClose();
    } catch (error: any) {
      setErrorCompleting(error);
    }
    setBusy(false);
  };

  const handleClose = () => {
    emitEvent({ componentName: 'completeGoal', name: 'cancelCompleteGoal' });
    setErrorCompleting(undefined);
    onClose();
  };

  return (
    <Modal
      actions={
        <ModalActionsLayout
          justifyContent={{ md: 'flex-end', xs: undefined }}
          primaryActions={
            <LoadingButton fullWidth={isMobile} loading={busy} onClick={handleCompleteGoal} variant="contained">
              {content.primaryCta}
            </LoadingButton>
          }
          secondaryActions={
            <Button disabled={busy} fullWidth={isMobile} onClick={handleClose} variant="outlined">
              {content.secondaryCta}
            </Button>
          }
        />
      }
      content={
        <Grid container direction="column" sx={{ textAlign: 'center' }}>
          {content.image && (
            <Grid item mb={2}>
              <img alt="" src={content.image} width="72px" />
            </Grid>
          )}
          <Grid item>
            <RteContent data={content.description} gutterBottom={isMobile} />
          </Grid>

          <Grid item>
            <Collapse in={!!errorCompleting} sx={{ mt: 2, textAlign: 'left' }}>
              {errorCompleting && <Alert contentOptions={contentOptions} error={errorCompleting} severity="error" />}
            </Collapse>
          </Grid>
        </Grid>
      }
      dataQa={dataQa}
      onClose={handleClose}
      open={open}
      title={content.title}
    />
  );
};
