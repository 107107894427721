import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useState } from 'react';

import {
  isAttributeObjective,
  isAttributeRiskProfile,
  isAttributeTimeHorizon,
  RecommendedProductContent,
  RecommendedProductContentBand,
  useGetRecommendedProductContent,
} from './contentstack';
import { LatestQuestionnaireRecommendationSet, useGetModelPortfolios } from './symphony';
import { getRiskBands } from './utils';

import { IndicatorLabels, PortfolioBand, RiskBand } from '~/components/RiskSpectrum';
import { getNormalizedStockBondPercentage } from '~/containers/Plan/utils';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface RecommendedProductAttribute {
  ariaLabel?: string;
  label?: string;
  value?: string;
}
interface RecommendedProductAttributes {
  objective?: RecommendedProductAttribute;
  riskProfile?: RecommendedProductAttribute;
  timeHorizon?: RecommendedProductAttribute;
}
export interface RecommendedProductData {
  attributes?: RecommendedProductAttributes;
  cms: RecommendedProductContent;
  indicatorLabels: IndicatorLabels;
  portfolioBands: PortfolioBand[];
  riskBandCurrent?: number;
  riskBandDetails?: RecommendedProductContentBand;
  riskBandNew: number;
  riskBandUser?: number;
  riskBands: RiskBand[];
  showPortfolioBands?: boolean;
}

interface RecommendedProductHookOptions {
  contentOptions: ContentOptions;
  currentRiskScore?: number;
  managedProductId?: string;
  partyId?: string;
  recommendedProduct?: LatestQuestionnaireRecommendationSet;
  riskScoreUser?: number;
  showPortfolioBands?: boolean;
  skip?: boolean;
}

export const useRecommendedProduct = ({
  contentOptions,
  currentRiskScore,
  managedProductId,
  partyId,
  recommendedProduct,
  riskScoreUser,
  showPortfolioBands,
  skip,
}: RecommendedProductHookOptions): AsyncResult<RecommendedProductData> => {
  const [data, setData] = useState<RecommendedProductData>();
  const { data: contentData, loading: contentLoading, error: contentError } = useGetRecommendedProductContent({
    variables: contentOptions,
    skip,
  });

  const { data: modelPortfolioData, loading: modelPortfolioLoading } = useGetModelPortfolios({
    variables: { managedProductId: managedProductId || '', partyId: partyId || '', isTaxSheltered: false },
    skip: skip || !showPortfolioBands,
  });
  const contentRecommendedProduct = contentData?.all_recommended_product?.items?.[0] as RecommendedProductContent;

  const getRecommendedProductAttributes = useCallback((): RecommendedProductAttributes => {
    return (contentRecommendedProduct.attributes ?? []).reduce((acc, attribute) => {
      if (isAttributeObjective(attribute) && attribute.objective?.attribute_key === recommendedProduct?.objective) {
        acc.objective = {
          label: contentRecommendedProduct.headings?.objective ?? '',
          value: attribute.objective?.label ?? '',
        };
      } else if (
        isAttributeRiskProfile(attribute) &&
        attribute.risk_profile?.attribute_key === recommendedProduct?.riskProfile
      ) {
        acc.riskProfile = {
          label: contentRecommendedProduct.headings?.risk_profile ?? '',
          value: attribute.risk_profile?.label ?? '',
        };
      } else if (
        isAttributeTimeHorizon(attribute) &&
        attribute.time_horizon?.attribute_key === recommendedProduct?.timeHorizon
      ) {
        acc.timeHorizon = {
          ariaLabel: attribute.time_horizon?.aria_label ?? '',
          label: contentRecommendedProduct.headings?.time_horizon ?? '',
          value: attribute.time_horizon?.label ?? '',
        };
      }
      return acc;
    }, {} as RecommendedProductAttributes);
  }, [contentRecommendedProduct, recommendedProduct]);

  const getBandIndex = (score: number | null, bands: PortfolioBand[] | RiskBand[]) => {
    if (score !== null) {
      return bands.findIndex(
        band =>
          band.riskScoreFloor >= 0 &&
          band.riskScoreCeiling >= 0 &&
          score >= band.riskScoreFloor &&
          score <= band.riskScoreCeiling,
      );
    }
    return -1;
  };

  useEffect(() => {
    if (!skip && !contentLoading && contentRecommendedProduct && recommendedProduct) {
      const attributes = getRecommendedProductAttributes();
      const contentBands = contentRecommendedProduct.bands ?? [];
      const riskBands: RiskBand[] = getRiskBands(contentBands);

      let portfolioBands: PortfolioBand[] = [];
      if (showPortfolioBands && modelPortfolioData) {
        const modelPortfolios = modelPortfolioData.managedProduct?.modelPortfolios;
        if (modelPortfolios?.length) {
          portfolioBands = [...modelPortfolios]
            .sort((a, b) => (a.riskRange?.min || 0) - (b.riskRange?.min || 0))
            .map(mp => {
              const riskBandIndex = riskBands.findIndex(
                rb => rb.riskScoreCeiling >= (mp.riskRange?.min || 0) && rb.riskScoreFloor <= (mp.riskRange?.max || 0),
              );
              const riskBand = riskBands[riskBandIndex || 0];
              const { bondPercentage, stockPercentage } = mp.guidance?.diversification?.assets.stockBondDiversification
                ? getNormalizedStockBondPercentage(mp.guidance.diversification.assets.stockBondDiversification)
                : { bondPercentage: '', stockPercentage: '' };
              return {
                label: riskBand.label || '',
                description: riskBand.description || '',
                riskScoreFloor: mp.riskRange?.min || 0,
                riskScoreCeiling: mp.riskRange?.max || 0,
                riskBandIndex,
                bondPercentage,
                stockPercentage,
              };
            });
        }
      }

      const bands = showPortfolioBands ? portfolioBands : riskBands;
      const dataObj: RecommendedProductData = {
        attributes,
        cms: contentRecommendedProduct,
        riskBands,
        portfolioBands,
        showPortfolioBands,
        riskBandNew: getBandIndex(recommendedProduct.riskScore, bands),
        riskBandCurrent: currentRiskScore !== undefined ? getBandIndex(currentRiskScore, bands) : undefined,
        riskBandUser: riskScoreUser === undefined ? undefined : getBandIndex(riskScoreUser, bands),
        indicatorLabels: {
          current: contentRecommendedProduct.indicators?.current ?? '',
          new:
            (currentRiskScore !== undefined
              ? contentRecommendedProduct.indicators?.new
              : contentRecommendedProduct.indicators?.result) ?? '',
          noChange: contentRecommendedProduct.indicators?.no_change ?? '',
          user: contentRecommendedProduct.indicators?.user ?? '',
        },
      };
      if (!isEqual(data, dataObj)) {
        setData(dataObj);
      }
    }
  }, [
    contentLoading,
    contentRecommendedProduct,
    currentRiskScore,
    data,
    getRecommendedProductAttributes,
    modelPortfolioData,
    recommendedProduct,
    riskScoreUser,
    showPortfolioBands,
    skip,
  ]);

  return { data, loading: contentLoading || modelPortfolioLoading, error: contentError };
};
