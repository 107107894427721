import { GetGoal_getGoal_goalAccounts_items } from './amplify/__generated__/getGoal.v2';
import {
  CliffProjectionAmplifyData,
  CliffProjectionData,
  ReadinessStatus,
  RetirementProjectionAmplifyData,
  RetirementProjectionData,
} from './types';

import { AccountType, CashFlowSourceFrequency } from '~/__generated__/amplifyTypes.v2';
import { GetGoals_getUserByPartyId_goals_items_goalAccounts_items } from '~/hooks/goals/amplify/__generated__/getGoals.v2';
import { Locale } from '~/utils/contentstack';
import { formatPercentage } from '~/utils/format';

/**
 * Returns the status that's greater than the currentLevel with the highest threshold.
 */
export const getReadinessStatus = (
  readinessStatuses: ReadinessStatus[],
  currentLevel: number,
): ReadinessStatus | undefined => {
  const targetRatio = Math.max(0, currentLevel);
  const targetStatuses = readinessStatuses.filter(s => targetRatio >= s.threshold);
  if (targetStatuses.length) {
    return targetStatuses.reduce((target, status) => (target.threshold > status.threshold ? target : status));
  }
  return undefined;
};

/**
 * Returns the sum of all of the contributions based on the frequency for USER accounts.
 * HOUSE account for Retirement is not included in this.
 */
export const getContributionFrequencyTotal = (
  goalAccounts: (GetGoal_getGoal_goalAccounts_items | GetGoals_getUserByPartyId_goals_items_goalAccounts_items)[],
): number => {
  return goalAccounts.reduce((prev, curr) => {
    const isHouseAccount = curr.account.accountType === AccountType.HOUSE;
    const source = curr.cashFlowSources?.items[0];
    if (!source || isHouseAccount) {
      return prev;
    }
    switch (source.frequency) {
      case CashFlowSourceFrequency.WEEKLY:
        return prev + source.amount * 4;
      case CashFlowSourceFrequency.BIWEEKLY:
        return prev + source.amount * 2;
      case CashFlowSourceFrequency.MONTHLY:
        return prev + source.amount;
      default:
        return prev;
    }
  }, 0);
};

/**
 * Returns the sum of all of the balances for the associated accounts.
 */
export const getAccountBalance = (
  goalAccounts: (GetGoal_getGoal_goalAccounts_items | GetGoals_getUserByPartyId_goals_items_goalAccounts_items)[],
): number =>
  goalAccounts.reduce(
    (prev, curr) => (curr.account.accountType === AccountType.HOUSE ? prev : prev + (curr.account.balance ?? 0)),
    0,
  );

export const cliffProjectionKeys = ['goal', 'projected', 'balance', 'readinessFraction'];

export const retirementProjectionKeys = [
  'goalSaving',
  'projectedSaving',
  'goalSpend',
  'projectedSpend',
  'data',
  'retirementDate',
  'readinessFraction',
];

// searches interface keys for ones missing in the object, if any missing then type is not matching
export const missingInterfaceKeys = (object: Record<string, any>, interfaceKeys: string[]): string[] => {
  return interfaceKeys.filter(interfaceKey => !Object.keys(object).includes(interfaceKey));
};

export const isCliffProjectionAmplifyData = (object: Record<string, any>): object is CliffProjectionAmplifyData => {
  return missingInterfaceKeys(object, cliffProjectionKeys).length === 0;
};

export const isRetirementProjectionAmplifyData = (
  object: Record<string, any>,
): object is RetirementProjectionAmplifyData => {
  return missingInterfaceKeys(object, retirementProjectionKeys).length === 0;
};

export const isRetirementProjectionData = (object: Record<string, any>): object is RetirementProjectionData => {
  return Object.keys(object).includes('retirementDate');
};

/**
 * Parses a string that can be mapped to a Cliff goalProjection.
 * Wrap this function in a try-catch, it will throw an Error on malformed json or missing fields
 * @param goalProjection - stringified projection data from Amplify
 * @returns CliffProjectionData type
 */
export const getCliffGoalProjection = (goalProjection: string): CliffProjectionData => {
  const goalProjectionParsed: CliffProjectionAmplifyData = JSON.parse(goalProjection);

  if (isCliffProjectionAmplifyData(goalProjectionParsed)) {
    return {
      spending: {
        goal: goalProjectionParsed.goal,
        projected: goalProjectionParsed.projected,
      },
      readinessFraction: goalProjectionParsed.readinessFraction,
      currentBalance: goalProjectionParsed.balance,
    };
  }

  throw new Error(
    `goalProjection missing cliff keys ${missingInterfaceKeys(goalProjectionParsed, cliffProjectionKeys)}`,
  );
};

/**
 * Parses a string that can be mapped to a Retirement goalProjection.
 * Wrap this function in a try-catch, it will throw an Error on malformed json or missing fields
 * @param goalProjection - stringified projection data from Amplify
 * @returns RetirementProjectionData type
 */
export const getRetirementGoalProjection = (goalProjection: string): RetirementProjectionData => {
  const goalProjectionParsed: RetirementProjectionAmplifyData = JSON.parse(goalProjection);

  if (!isRetirementProjectionAmplifyData(goalProjectionParsed)) {
    throw new Error(
      `goalProjection missing retirement keys ${missingInterfaceKeys(goalProjectionParsed, retirementProjectionKeys)}`,
    );
  }
  if (goalProjectionParsed.data.length === 0) {
    throw new Error('No savingViewData returned from amplify');
  }

  return {
    saving: {
      goal: goalProjectionParsed.goalSaving,
      projected: goalProjectionParsed.projectedSaving,
    },
    spending: {
      goal: goalProjectionParsed.goalSpend,
      projected: goalProjectionParsed.projectedSpend,
    },
    readinessFraction: goalProjectionParsed.readinessFraction,
    savingViewData: [...goalProjectionParsed.data],
    retirementDate: goalProjectionParsed.retirementDate,
  };
};

export const getRetirementIncomeSpendingProportion = (
  annualIncome: number | undefined,
  targetSpending: number,
  locale?: Locale,
) => {
  const incomeProportion = (12 * (isNaN(targetSpending) ? 0 : targetSpending)) / (annualIncome || 1);
  return formatPercentage(incomeProportion, { decimals: 1, locale });
};

export const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>, value: any): void => {
  if ((typeof value === 'number' && value === 0) || (typeof value === 'string' && value === '0')) {
    e.target.value = '';
  }
};

interface GoalAccount {
  copilotPortfolioUlid: string | null;
  externalId: string | null;
}
interface PartialAccount {
  copilotPortfolioUlid: string;
  externalId: null;
}
/**
 * A type guard that returns true if a goal account is a partial
 * @param {GoalAccount} goalAccount - A goal account with externalId and copilotPortfolioUlid properties
 * @returns {boolean}
 */
export const isPartialAccount = (goalAccount: GoalAccount): goalAccount is PartialAccount =>
  !goalAccount.externalId && !!goalAccount.copilotPortfolioUlid;
