import { useCallback, useMemo } from 'react';

import { useGetAccountSummaryGlobalContent, useGetAccountSummaryMoreGlobalContent } from '../contentstack';

import { FinancialAccountType, ManagedProductType } from '~/__generated__';
import { DonutSlice } from '~/components/ui/Donut';
import { useGetAssetClassContent } from '~/hooks/asset-classes/contentstack';
import { getAccountProgramText as baseGetAccountProgramText } from '~/utils/account';
import { getDonutSlicesFromDonutAllocations } from '~/utils/asset-allocation';
import { AssetClassTier, DonutAllocation } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

type Variables = {
  contentOptions: ContentOptions;
};
type Data = {
  getAccountProgramText: (
    type?: ManagedProductType,
    attributes?: Array<{ booleanValue?: boolean; name: string }>,
    useLongText?: boolean,
  ) => string;
  getAccountTypeText: (type: FinancialAccountType) => string;
  getDonutAllocations: (donutAllocation: Array<DonutAllocation>, assetClassTier?: AssetClassTier) => DonutSlice[];
  getRelationshipNamesText: (relationshipNames: string[]) => string;
};

export const useAccountPropertyContent = ({ contentOptions }: Variables): AsyncResult<Data> => {
  const variables = { variables: contentOptions };
  const {
    data: globalContentData,
    error: globalContentError,
    loading: globalContentLoading,
  } = useGetAccountSummaryGlobalContent(variables);
  const {
    data: assetClassContentData,
    error: assetClassContentError,
    loading: assetClassContentLoading,
  } = useGetAssetClassContent(variables);
  const {
    data: moreGlobalContentData,
    error: moreGlobalContentError,
    loading: moreGlobalContentLoading,
  } = useGetAccountSummaryMoreGlobalContent(variables);

  const getAccountProgramText: Data['getAccountProgramText'] = useCallback(
    (type, attributes, useLongText) =>
      baseGetAccountProgramText(type, attributes, globalContentData?.all_product_name?.items, useLongText),
    [globalContentData?.all_product_name?.items],
  );

  const getAccountTypeText: Data['getAccountTypeText'] = useCallback(
    type => globalContentData?.all_account_type?.items?.find(item => item?.key === type)?.text || 'Unknown',
    [globalContentData?.all_account_type?.items],
  );

  const getDonutAllocations: Data['getDonutAllocations'] = useCallback(
    (donutAllocation, assetClassTier) =>
      getDonutSlicesFromDonutAllocations(
        donutAllocation,
        assetClassContentData?.all_asset_class_data?.items ?? undefined,
        assetClassTier,
      ),
    [assetClassContentData?.all_asset_class_data?.items],
  );

  const getRelationshipNamesText: Data['getRelationshipNamesText'] = useCallback(
    relationshipNames => {
      const names = relationshipNames.map(
        name => moreGlobalContentData?.all_ownership_type?.items?.find(item => item?.key === name)?.text || name,
      );
      if (names.length > 1) {
        const jointRelationship = names.find(name => name.toLowerCase().match(/joint/g));
        return jointRelationship ? jointRelationship : names[0];
      }
      return names[0];
    },
    [moreGlobalContentData?.all_ownership_type?.items],
  );

  const data = useMemo(
    () => ({
      getAccountProgramText,
      getAccountTypeText,
      getDonutAllocations,
      getRelationshipNamesText,
    }),
    [getAccountProgramText, getAccountTypeText, getDonutAllocations, getRelationshipNamesText],
  );

  return {
    data,
    error: assetClassContentError ?? globalContentError ?? moreGlobalContentError,
    loading: assetClassContentLoading || globalContentLoading || moreGlobalContentLoading,
  };
};
