import { parseISO } from 'date-fns';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';

import { useGetResolveFlagModalContent } from './contentstack';
import { useUpdateFlagStatus } from './symphony';

import { FlagReason, FlagStatus } from '~/__generated__';
import { Modal } from '~/components/ui/Modal';
import { Button, Card, Grid, LoadingButton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { FlagAbstract } from '~/containers/OpsDashboard/common/FlagAction';
import { getAccountNumberOrAccountTypeString, getFlagReasonText } from '~/containers/OpsDashboard/utils';
import { ContentOptions } from '~/utils/contentstack';
import { toDateString } from '~/utils/symphony';
import { SfTheme } from '~/utils/theme';

export type Props = ComponentProps<typeof Modal> & {
  accountId: string;
  accountTypeText: string;
  clientName: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  flags: FlagAbstract[];
  onClose: () => void;
  onResolve: () => void;
  open: boolean;
};

export const ResolveFlagModal: React.FC<Props> = ({
  accountId,
  accountTypeText,
  clientName,
  contentOptions,
  dataQa,
  flags,
  onClose,
  open,
  onResolve,
}) => {
  const {
    data: modalContentData,
    loading: modalContentLoading,
    error: modalContentError,
  } = useGetResolveFlagModalContent({
    variables: contentOptions,
    skip: !open,
  });

  const [updateFlagStatus] = useUpdateFlagStatus();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [selected, setSelected] = useState<number[]>([]);
  const [flagIdToStatusMap, setFlagIdToStatusMap] = useState<Record<number, FlagStatus>>({});
  const {
    sfResolveFlagModal: { styles: sfResolveFlagModalStyles },
  } = useTheme<SfTheme>();

  useEffect(() => {
    const flagState: Record<number, FlagStatus> = flags.reduce((acc, flag) => {
      return {
        ...acc,
        [flag.id]: flag.status,
      };
    }, {});
    setFlagIdToStatusMap(flagState);
  }, [flags]);

  const hasOpenFlags = useMemo(() => {
    return Object.values(flagIdToStatusMap).includes(FlagStatus.OPEN);
  }, [flagIdToStatusMap]);

  useEffect(() => {
    if (!open) {
      setSelected([]);
      setErrorSaving(undefined);
      if (Object.values(flagIdToStatusMap).includes(FlagStatus.RESOLVED)) {
        onResolve();
        setFlagIdToStatusMap({});
      }
    }
  }, [open]);

  const handleSubmit = async (flagIds: number[]) => {
    setIsSubmitted(true);
    try {
      await updateFlagStatus({
        variables: {
          requests: flagIds.map(flagId => ({
            id: flagId,
            status: FlagStatus.RESOLVED,
          })),
        },
      });
      const flagState: Record<number, FlagStatus> = flagIds.reduce((acc, flagId) => {
        return {
          ...acc,
          [flagId]: FlagStatus.RESOLVED,
        };
      }, flagIdToStatusMap);
      setFlagIdToStatusMap(flagState);
      if (Object.values(flagState).includes(FlagStatus.OPEN)) {
        setSelected([]);
      } else {
        onClose();
      }
    } catch (error: any) {
      setErrorSaving(error);
      console.error(error);
    } finally {
      setIsSubmitted(false);
    }
  };

  const onClickSingleResolve = (flagId: number) => {
    setSelected([flagId]);
  };

  const onClickPrimary = () => {
    const openFlagIds: number[] = [];
    Object.keys(flagIdToStatusMap).map(flagIdString => {
      const flagIdInt = parseInt(flagIdString, 10);
      if (flagIdToStatusMap[flagIdInt] === FlagStatus.OPEN) {
        openFlagIds.push(flagIdInt);
      }
    });
    return selected.length > 0 ? handleSubmit(selected) : setSelected(openFlagIds);
  };

  const resolveFlagModalContent = modalContentData?.all_flags?.items?.[0]?.display_flags_modal;
  const confirmationModalContent = modalContentData?.all_flags?.items?.[0]?.confirm_resolve_flag_modal;
  const extraContent = modalContentData?.all_flags?.items?.[0]?.display_modal_extra_content;
  const reasons = modalContentData?.all_flags?.items?.[0]?.reasons;
  const modalTitle = confirmationModalContent?.title ?? '';
  const checkIconUrl = extraContent?.check_iconConnection?.edges?.[0]?.node?.url ?? '';
  const messageIconUrl = resolveFlagModalContent?.imageConnection?.edges?.[0]?.node?.url ?? '';
  const titleImgUrl = confirmationModalContent?.imageConnection?.edges?.[0]?.node?.url ?? '';
  const warningIconUrl = extraContent?.warning_iconConnection?.edges?.[0]?.node?.url ?? '';

  return (
    <Modal
      actions={
        <>
          <Button onClick={onClose} sx={sfResolveFlagModalStyles.secondaryButton} variant="outlined">
            {selected.length > 0 ? confirmationModalContent?.secondary_cta : resolveFlagModalContent?.secondary_cta}
          </Button>
          <LoadingButton
            disabled={!!modalContentError || !!errorSaving || !hasOpenFlags}
            loading={isSubmitted}
            onClick={onClickPrimary}
            sx={sfResolveFlagModalStyles.primaryButton}
            variant="contained"
          >
            {selected.length > 0 ? confirmationModalContent?.primary_cta : resolveFlagModalContent?.primary_cta}
          </LoadingButton>
        </>
      }
      content={
        selected.length > 0 ? (
          <Typography sx={{ mt: 4 }} variant="body2">
            <RteContent data={confirmationModalContent?.description ?? ''} />
          </Typography>
        ) : (
          <>
            <Typography sx={{ my: 1 }} variant="subtitle2">{`${
              extraContent?.account_text
            } ${getAccountNumberOrAccountTypeString(accountId, accountTypeText)}`}</Typography>
            <Typography sx={{ mb: 3 }} variant="subtitle2">{`${extraContent?.client_text} ${clientName}`}</Typography>
            {reasons &&
              flags.map(flag => (
                <Grid alignItems="center" container key={flag.id}>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        ...sfResolveFlagModalStyles.reasonCard,
                        ...(flagIdToStatusMap[flag.id] === FlagStatus.RESOLVED && { opacity: 0.5 }),
                      }}
                    >
                      <Grid container>
                        <Grid item xs={9}>
                          <Typography sx={sfResolveFlagModalStyles.reasonTitle}>
                            {getFlagReasonText(reasons, flag.reason)}
                            {flag.reason === FlagReason.OTHER && (
                              <img alt="flag icon" src={messageIconUrl} style={sfResolveFlagModalStyles.reasonIcon} />
                            )}
                          </Typography>
                          <Typography sx={sfResolveFlagModalStyles.reasonDate}>
                            {toDateString(parseISO(flag.lastUpdated))}
                          </Typography>
                          {flag.reason === FlagReason.OTHER && (
                            <Typography sx={{ mt: 1.5 }} variant="body2">
                              {flag.description}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item sx={{ ml: 'auto' }}>
                          {flags.length > 1 ? (
                            flagIdToStatusMap[flag.id] === FlagStatus.RESOLVED ? (
                              <Grid item>
                                <Grid container>
                                  <Grid item sx={sfResolveFlagModalStyles.resolvedIcon}>
                                    <img alt="check icon" src={checkIconUrl} />
                                  </Grid>
                                  <Grid item>
                                    <Typography>{extraContent?.resolved_text}</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid item>
                                <Button
                                  onClick={() => onClickSingleResolve(flag.id)}
                                  sx={sfResolveFlagModalStyles.resolveButton}
                                  variant="outlined"
                                >
                                  {extraContent?.resolve_text}
                                </Button>
                              </Grid>
                            )
                          ) : null}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              ))}
            <Grid container sx={{ my: 3 }}>
              <img alt="check icon" src={warningIconUrl} />
              <Typography sx={{ ml: 1 }} variant="body2">
                {extraContent?.warning_text}
              </Typography>
            </Grid>
          </>
        )
      }
      contentOptions={contentOptions}
      dataQa={dataQa}
      error={modalContentError ?? errorSaving}
      loading={modalContentLoading}
      onClose={onClose}
      open={open}
      title={selected.length > 0 ? <img alt={modalTitle} src={titleImgUrl} /> : resolveFlagModalContent?.title}
    />
  );
};
