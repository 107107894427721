import {
  GetDepositsTableContent,
  GetDepositsTableContent_all_deposits_table_items_flag_referenceConnection_edges_node,
  GetDepositsTableContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type FlagsReferenceContent = GetDepositsTableContent_all_deposits_table_items_flag_referenceConnection_edges_node;

export const useGetDepositsTableContent = (
  options?: QueryHookOptions<GetDepositsTableContent, GetDepositsTableContentVariables>,
): QueryResult<GetDepositsTableContent, GetDepositsTableContentVariables> => {
  return useContentstackQuery(queries.GetDepositsTableContent, options);
};
