import { UpdateAssetDepositV2, UpdateAssetDepositV2Variables } from './__generated__/mutation.v2';
import {
  AccountAssetTransfersRequestV2,
  AccountAssetTransfersRequestV2_assetDeposits_transfers_flags,
  AccountAssetTransfersRequestV2Variables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ACATRequestFlags = AccountAssetTransfersRequestV2_assetDeposits_transfers_flags;

export const useAccountACATRequests = (
  options?: QueryHookOptions<AccountAssetTransfersRequestV2, AccountAssetTransfersRequestV2Variables>,
): QueryResult<AccountAssetTransfersRequestV2, AccountAssetTransfersRequestV2Variables> => {
  return useSymphonyQuery(queries.AccountAssetTransfersRequestV2, options);
};

export const useUpdateAssetDepositV2 = (
  options?: MutationHookOptions<UpdateAssetDepositV2, UpdateAssetDepositV2Variables>,
): MutationTuple<UpdateAssetDepositV2, UpdateAssetDepositV2Variables> => {
  return useSymphonyMutation(mutations.UpdateAssetDepositV2, options);
};
