import React, { useCallback, useMemo } from 'react';

import ActiveSuspensions from './ActiveSuspensions';
import useTradingSuspensionsData from './hooks/useTradingSuspensionsData';
import { SuspensionsTabEnum } from './hooks/utils';
import SuspensionsHistory from './SuspensionsHistory';

import { Alert } from '~/components/ui/Alert';
import { Box, Skeleton, useTheme } from '~/components/ui/mui';
import { TabData, TabsContainer } from '~/components/ui/TabsContainer';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onCountUpdate: (count: number | undefined) => void;
}

export const TradingSuspensions: React.FC<Props> = ({
  onCountUpdate,
  contentOptions,
  dataQa = 'trading-suspensions',
  managedProductId,
}) => {
  const { palette } = useTheme();
  const { data, error, loading } = useTradingSuspensionsData(contentOptions);

  const tabsData: TabData[] = useMemo(
    () =>
      Object.values(SuspensionsTabEnum).map(tab => ({
        label: <Typography variant="subtitle2">{data?.content.tabHeader[tab] ?? ''}</Typography>,
        id: tab,
      })),
    [data],
  );

  const getTabComponent = useCallback(
    (currentTab: string): React.ReactNode => {
      switch (currentTab) {
        case SuspensionsTabEnum.Active: {
          return (
            <ActiveSuspensions
              contentOptions={contentOptions}
              managedProductId={managedProductId}
              onCountUpdate={onCountUpdate}
            />
          );
        }
        case SuspensionsTabEnum.History: {
          return <SuspensionsHistory contentOptions={contentOptions} managedProductId={managedProductId} />;
        }
        default: {
          return null;
        }
      }
    },
    [contentOptions, managedProductId, onCountUpdate],
  );

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <Alert contentOptions={contentOptions} error={error} severity="error" />;
  }

  return (
    <Box data-qa={dataQa}>
      <TabsContainer
        getTabComponent={getTabComponent}
        tabSx={{ borderBottom: `2px solid ${palette.divider}` }}
        tabs={tabsData}
        variant="fullWidth"
      />
    </Box>
  );
};
