import { isSameYear, parseISO } from 'date-fns';

import { getCliffGoalProjection, getRetirementGoalProjection } from '../../utils';

import { GoalStatus, GoalType } from '~/__generated__';
import { GoalItem } from '~/hooks/goals/amplify';

type Goal = {
  amount: number;
  date: Date;
  id: string;
  isComplete: boolean;
  key: string;
  readinessFraction?: number;
  title: string;
};

export const parseGoals = (goalItems: (GoalItem | null)[]): Goal[] =>
  goalItems.reduce<Goal[]>((acc, item) => {
    if (!item) {
      return acc;
    }
    const date = getDate(item);
    const amount = getAmount(item);
    if (date && amount !== undefined) {
      acc.push({
        amount,
        date,
        id: item.id,
        isComplete: !!item.completedEndDate,
        key: item.partnerGoalExternalRef,
        title: item.title,
        readinessFraction: getReadinessFraction(item),
      });
    }
    return acc;
  }, []);

export const groupGoals = (sortedGoals: Goal[]) => {
  const groupedGoals: Goal[][] = [];
  let currentGroup: Goal[] = [];
  sortedGoals.forEach((g, i, arr) => {
    currentGroup.push(g);
    const next = i + 1 < arr.length ? arr[i + 1] : undefined;
    if (currentGroup.length && (!next || !isSameYear(next.date, g.date))) {
      currentGroup.sort((a, b) => {
        if (a.isComplete === b.isComplete) {
          return 0;
        }
        return b.isComplete && !a.isComplete ? -1 : 1;
      });
      groupedGoals.push(currentGroup);
      currentGroup = [];
    }
  });

  return groupedGoals;
};

const getDate = (goal: GoalItem) => {
  const date =
    goal.completedEndDate ??
    (goal.goalType === GoalType.RETIREMENT && goal.goalProjection
      ? getRetirementGoalProjection(goal.goalProjection).retirementDate
      : goal.targetEndDate);
  return date ? parseISO(date) : undefined;
};

const getAmount = (goal: GoalItem): number | undefined => {
  if (goal.balanceOnCompletion !== null) {
    return goal.balanceOnCompletion;
  }
  if (goal.targetBalance !== null) {
    return goal.targetBalance;
  }
  if (goal.goalProjection && goal.goalType === GoalType.RETIREMENT) {
    return getRetirementGoalProjection(goal.goalProjection).saving.goal;
  }
  return undefined;
};

const getReadinessFraction = (goal: GoalItem) => {
  if (goal.goalStatus !== GoalStatus.ACTIVE_COMPLETE || !goal.goalProjection) {
    return undefined;
  }
  const projection =
    goal.goalType === GoalType.CLIFF
      ? getCliffGoalProjection(goal.goalProjection)
      : getRetirementGoalProjection(goal.goalProjection);
  return projection.readinessFraction;
};
