import { useEffect, useState } from 'react';

import { getRiskBands } from './utils';

import { RiskBand } from '~/components/RiskSpectrum';
import { RiskBandData } from '~/hooks/questionnaire';
import { useGetRecommendedProductContent } from '~/hooks/questionnaire/contentstack';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export const useGetRiskBands = (contentOptions: ContentOptions, skip?: boolean): AsyncResult<RiskBandData> => {
  const [data, setData] = useState<RiskBandData>();

  const { data: contentData, loading: contentLoading, error: contentError } = useGetRecommendedProductContent({
    variables: contentOptions,
    skip,
  });
  const contentRecommendedProduct = contentData?.all_recommended_product?.items?.[0];

  useEffect(() => {
    if (!contentLoading && contentRecommendedProduct) {
      const contentBands = contentRecommendedProduct.bands ?? [];
      const riskBands: RiskBand[] = getRiskBands(contentBands);

      setData({
        riskBands,
      });
    }
  }, [contentLoading, contentRecommendedProduct]);

  return { data, loading: contentLoading, error: contentError };
};
