import React, { ComponentProps, useState } from 'react';

import { useGetDocusignRequiredData } from './hooks';

import { Modal } from '~/components/ui/Modal';
import { Button, Grid, LoadingButton } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  isResendDocusign?: boolean;
  onClose: () => void;
  onPrimaryClick: () => Promise<void>;
};

export const DocusignRequired: React.FC<Props> = ({
  contentOptions,
  dataQa = 'docusign-required-modal',
  isResendDocusign,
  onClose,
  onPrimaryClick,
  open,
}) => {
  const isMediumScreen = useIsMediumScreen();
  const { data, error, loading } = useGetDocusignRequiredData({ contentOptions });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleClickPrimary = async () => {
    setIsSubmitted(true);
    await onPrimaryClick();
    setIsSubmitted(false);
    onClose();
  };

  return (
    <Modal
      actions={
        <Grid container direction={{ xs: 'column-reverse', md: 'row' }} justifyContent="right" spacing={2}>
          <Grid item md="auto" xs={12}>
            <Button
              color="primary"
              data-qa={`${dataQa}-cancel`}
              fullWidth={isMediumScreen}
              onClick={onClose}
              variant="outlined"
            >
              {data?.content.secondaryCta}
            </Button>
          </Grid>
          <Grid item md="auto" xs={12}>
            <LoadingButton
              data-qa={`${dataQa}-view-or-resend-docusign`}
              disabled={isSubmitted}
              fullWidth={isMediumScreen}
              loading={isSubmitted}
              onClick={handleClickPrimary}
              variant="contained"
            >
              {isResendDocusign ? data?.content.resendDocusign : data?.content.primaryCta}
            </LoadingButton>
          </Grid>
        </Grid>
      }
      content={
        <Grid container spacing={2} sx={{ px: 4, py: 3 }}>
          <RteContent data={data?.content.description ?? ''} />
        </Grid>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={error}
      loading={loading}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      title={data?.content.title}
    />
  );
};
