import React, { useState } from 'react';

import { AssetDetails } from '..';

import { Link } from '~/components/ui/Link';
import { Popover } from '~/components/ui/mui';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';

export interface AssetClassLabelProps {
  assetClass: AssetDetails;
  hasPopover: boolean;
  tickers: AssetDetails[];
}

export const AssetClassLabel: React.FC<AssetClassLabelProps> = ({ assetClass, hasPopover, tickers }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {hasPopover && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          disableRestoreFocus
          onClose={handlePopoverClose}
          open={open}
          sx={{
            pointerEvents: 'none',
          }}
        >
          <Typography sx={{ p: 1, whiteSpace: 'pre-wrap' }}>
            {tickers.map(ticker => `${ticker.label} ${ticker.tooltip}`).join('\n')}
          </Typography>
        </Popover>
      )}
      {assetClass.tooltip ? (
        <Tooltip tooltipContent={assetClass.tooltip}>
          <Link
            onClick={handlePopoverClose}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{ textAlign: 'left' }}
            variant="body2"
          >
            {assetClass.label}
          </Link>
        </Tooltip>
      ) : (
        <Typography variant="body2">{assetClass.label}</Typography>
      )}
    </>
  );
};
