import React, { ComponentProps } from 'react';

import { useGetFAClientLinkSuccessData } from './hooks/useFAClientLinkSuccessData';

import { Modal } from '~/components/ui/Modal';
import { Button, Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  onClose: () => void;
};

export const FAClientLinkSuccessModal: React.FC<Props> = ({
  contentOptions,
  dataQa = 'client-success-modal',
  onClose,
  open,
}) => {
  const { data: data, loading: dataLoading, error: dataError } = useGetFAClientLinkSuccessData({ contentOptions });
  const content = data?.content;

  return (
    <Modal
      actions={
        <Button data-qa={`${dataQa}-confirm-button`} onClick={() => onClose()} variant="contained">
          {content?.primaryCtaText}
        </Button>
      }
      content={
        content && (
          <Grid container spacing={2} sx={{ px: 4, py: 3 }}>
            {content.imgUrl && (
              <Grid item xs={12}>
                <img alt="" data-qa={`${dataQa}-image`} src={content.imgUrl} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography component="span" variant="h5">
                {content.heading}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="span" sx={{ color: '#000000' }} variant="body2">
                {content.subHeading}
              </Typography>
            </Grid>
          </Grid>
        )
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={dataError}
      loading={dataLoading}
      onClose={onClose}
      open={open}
      title={content?.modalTitle}
    />
  );
};
