import { useContext, useEffect, useMemo, useState } from 'react';

import {
  getAccountNumberOrAccountTypeString,
  getCurrentPage,
  getLastEditDateAndTime,
  getSortedPartnerOpsTradingSuspensions,
  getTotalPages,
} from '../../utils';
import { useGetSigningDocuments } from '../symphony';
import {
  GetSigningDocuments,
  GetSigningDocuments_signingDocuments_legalDocuments,
  GetSigningDocuments_signingDocuments_legalDocuments_signees,
  GetSigningDocumentsVariables,
} from '../symphony/__generated__/query.v2';
import { DocusignItem, DocusignTabData, DocusignTabVariables, emailRecipient } from '../types';

import {
  fromDocusignStatusToLegalDocumentStatus,
  fromLegalDocumentStatustoDocusignStatus,
  generateDocusignStaticDisplayStrings,
} from './mappers';

import { FinancialAccountType, ManagedProductStatus } from '~/__generated__';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { useGetOpsDashboardContent } from '~/containers/OpsDashboard/contentstack';
import { GetOpsDashboardContent } from '~/containers/OpsDashboard/contentstack/__generated__/query.v2';
import OpsDashboardContext from '~/containers/OpsDashboard/OpsDashboardContext';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export const useDocusignTabData = ({
  fetchLatestNote,
  statusFilters,
  field,
  order,
  page,
}: DocusignTabVariables): AsyncResult<DocusignTabData> => {
  const { contentOptions } = useContext(OpsDashboardContext);
  const variables: GetSigningDocumentsVariables = useMemo(
    () => ({
      fetchLatestNote,
      status: fromDocusignStatusToLegalDocumentStatus(statusFilters),
      field,
      order,
      limit: 10,
      offset: (page - 1) * 10,
    }),
    [statusFilters, fetchLatestNote, field, order, page],
  );

  /**
   * The reason for no-cache is due to the updating the list when ever we create or resolve flag for a docusign,
   * the flagged and unflagged docusign table should be updated.
   * Otherwise, instead of the table showing the current flagged and unflagged docusign data it shows the previous cached data.
   * There is no easy way to update the cache to reflect the correct state right now.
   */
  const {
    data: docusignData,
    loading: docusignLoading,
    error: docusignError,
    refetch: refetchDocusignData,
  } = useGetSigningDocuments({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables,
  });
  const [state, setState] = useState<AsyncResult<DocusignTabData>>({ loading: true });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (docusignError || opsDashboardContentError) {
      setState({
        error: docusignError || opsDashboardContentError,
        loading: false,
      });
    }
  }, [docusignError, opsDashboardContentError]);

  useEffect(() => {
    if (!opsDashboardContentLoading && !docusignLoading && docusignData) {
      const { limit, total, offset } = docusignData?.signingDocuments.paginationContext;
      const totalPages = getTotalPages({ limit, total });
      const currentPage = getCurrentPage({ limit, offset });
      const docusignItems = getDocusignItems(docusignData, contentOptions, opsDashboardContentData);
      setState({
        data: {
          docusignItems,
          currentPage,
          totalPages,
          content: generateDocusignStaticDisplayStrings,
          refetchData: refetchDocusignData,
        },
        loading: false,
        error: docusignError,
      });
    }
  }, [docusignLoading, docusignData, docusignError, opsDashboardContentLoading, refetchDocusignData]);

  return state;
};

const getSigneesEmail = (signees: GetSigningDocuments_signingDocuments_legalDocuments_signees[]): emailRecipient[] => {
  const filteredSignees = signees
    .sort((a, b) => (a.recipientId ?? 0) - (b.recipientId ?? 0))
    .map(signeeEmailRecipient => ({
      email: signeeEmailRecipient.email || '',
      recipientId: signeeEmailRecipient.recipientId || 1,
    }));

  return filteredSignees;
};

export const getDocusignItems = (
  result: GetSigningDocuments,
  contentOptions: ContentOptions,
  opsDashboardContentData: GetOpsDashboardContent | undefined,
): DocusignItem[] => {
  const docusignResult: DocusignItem[] = [];
  const productNameContent = opsDashboardContentData?.all_product_name?.items || [];
  result?.signingDocuments?.legalDocuments?.map((item: GetSigningDocuments_signingDocuments_legalDocuments) => {
    const entityNotes = item.entityNotes.length ? item.entityNotes[0] : null;
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(
      item.managedProduct?.tradingSuspensions,
    )?.[0]?.createdAt;
    const managedProductStatus = item.managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = item.managedProduct?.clientParty?.id || '';
    // only used for activated account states
    const accountState = getAccountState({
      firstRebalancedOn: item.managedProduct?.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;
    docusignResult.push({
      id: item.signingDocumentId || '',
      accountNumber: `${getAccountNumberOrAccountTypeString(
        item.managedProduct?.financialAccountNumber || null,
        getAccountTypeText(
          item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
          opsDashboardContentData?.all_account_type?.items || [],
        ),
      )}`,
      accountState,
      accountType: item.managedProduct?.accountType || '',
      clientName: `${item.managedProduct?.clientParty?.partyPerson?.givenName ?? ''} ${
        item.managedProduct?.clientParty?.partyPerson?.familyName ?? ''
      }`,
      comment: entityNotes
        ? {
            lastValue: entityNotes.note,
            ...getLastEditDateAndTime(new Date(entityNotes.created), contentOptions),
          }
        : undefined,
      dateGenerated: item.created || '',
      lastCommentPartyId: entityNotes?.createdByPartyId || '',
      managedProductId: item.managedProduct?.id || '',
      emailRecipients: getSigneesEmail(item.signees),
      partyId,
      productName: getAccountProgramText(
        item.managedProduct?.program,
        item.managedProduct?.attributes,
        productNameContent,
      ),
      status: fromLegalDocumentStatustoDocusignStatus(item.status),
    });
  });
  return docusignResult;
};
