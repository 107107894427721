import { useEffect, useState } from 'react';

import { useGetOpsDashboardContent } from '../../contentstack';
import { getCurrentPage, getTotalPages } from '../../utils';
import { useGetBankVerificationRequests } from '../symphony';
import { GetFinancialAccountAssociationVerificationRequestsVariables } from '../symphony/__generated__/query.v2';
import { bankVerificationTableFactory } from '../utils';
import { getFinancialAccountAssociationVerificationStatusFromVerificationStatusFilter } from '../utils/mappers';
import { BankVerificationDataTabVariables, BankVerificationTabData, sortFieldToSymphony } from '../utils/types';

import { FinancialAccountAssociationVerificationRequestSortField } from '~/__generated__';
import { AsyncResult } from '~/utils/types';

export const useGetBankVerificationTabData = ({
  contentOptions,
  statusFilter,
  page,
  sortConfig,
}: BankVerificationDataTabVariables): AsyncResult<BankVerificationTabData> => {
  const [state, setState] = useState<AsyncResult<BankVerificationTabData>>({ loading: true });
  const variables: GetFinancialAccountAssociationVerificationRequestsVariables = {
    statuses: getFinancialAccountAssociationVerificationStatusFromVerificationStatusFilter(statusFilter),
    pagination: {
      limit: 10,
      offset: (page - 1) * 10,
    },
    order: sortConfig.order,
    fetchLatestNote: true,
    field:
      sortFieldToSymphony.get(sortConfig.field) || FinancialAccountAssociationVerificationRequestSortField.CREATED_ON,
  };

  const {
    data: bankVerificationData,
    loading: bankVerificationLoading,
    error: bankVerificationError,
    refetch: refetchBankVerificationData,
  } = useGetBankVerificationRequests({
    fetchPolicy: 'no-cache',
    variables,
  });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (bankVerificationError || opsDashboardContentError) {
      setState({
        error: bankVerificationError || opsDashboardContentError,
        loading: false,
      });
    }
  }, [bankVerificationError, opsDashboardContentError]);

  useEffect(() => {
    if (!state.loading && !state.error) {
      refetchBankVerificationData();
    }
  }, [page]);

  useEffect(() => {
    if (
      !opsDashboardContentLoading &&
      !bankVerificationLoading &&
      !bankVerificationError &&
      !opsDashboardContentError &&
      bankVerificationData
    ) {
      const {
        limit,
        total,
        offset,
      } = bankVerificationData.financialAccountAssociationVerificationRequests.paginationContext;
      const totalPages = getTotalPages({ limit, total });
      const currentPage = getCurrentPage({ limit, offset });
      const bankVerificationItems = bankVerificationTableFactory(bankVerificationData, opsDashboardContentData);

      setState({
        data: {
          bankVerificationItems,
          currentPage,
          totalPages,
          refetchData: refetchBankVerificationData,
        },
        loading: false,
      });
    }
  }, [
    opsDashboardContentLoading,
    bankVerificationLoading,
    bankVerificationError,
    opsDashboardContentError,
    bankVerificationData,
    opsDashboardContentData,
    refetchBankVerificationData,
  ]);

  return state;
};
