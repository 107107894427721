import React, { ComponentProps, useMemo, useState } from 'react';

import { CommentComponentColumn } from '../common/Comment';
import { OpsDashboardUser } from '../utils';

import { ClosuresStatusFilter } from './ClosuresStatusFilter';
import { ClosuresTable } from './ClosuresTable';
import { generateClosureStaticDisplayStrings } from './content';
import { ClosureItem, useClosuresTabData } from './hooks';

import { CloseWorkflowField, OrderType } from '~/__generated__';
import { StatusChangeModal } from '~/components/modals/StatusChange';
import { NullStateProps } from '~/components/NullState';
import { Alert } from '~/components/ui/Alert';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable, SortConfig } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Grid, Tab, TabContext, TabPanel, useTheme } from '~/components/ui/mui';
import { Tabs } from '~/components/ui/Tabs';
import { Typography } from '~/components/ui/Typography';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { closureStatusToCloseAccountStatusForUpdate } from '~/containers/OpsDashboard/ClosuresTab/hooks/mappers';
import { useUpdateCloseWorkflow } from '~/containers/OpsDashboard/ClosuresTab/symphony';
import { ClosureStatus, ClosureStatusFilter, RequestTabs } from '~/containers/OpsDashboard/ClosuresTab/types';
import { ContentOptions } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

type ClosuresTabProps = {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
};

const sortFieldToSymphony = new Map([['createdAt', CloseWorkflowField.CREATED]]);

interface CloseWorkflowUpdateItem {
  item: ClosureItem;
  newStatus: ClosureStatus;
}

export const ClosuresTab: React.FC<ClosuresTabProps> = ({
  contentOptions,
  commentColumn,
  nullStateConfig,
  onClientClick,
  onAccountClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  currentUser,
}) => {
  const {
    sfOpsDashboard: { styles: sfOpsDashboardStyles },
  } = useTheme<SfTheme>();
  const [currentPage, setCurrentPage] = useState(1);
  const [isBusy, setIsBusy] = useState(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [statusFilter, setStatusFilter] = useState<ClosureStatusFilter>(ClosureStatusFilter.ALL);
  const [selectedCloseWorkflowUpdateItem, setSelectedCloseWorkflowUpdateItem] = useState<CloseWorkflowUpdateItem>();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    order: OrderType.DESCENDING,
    field: 'createdAt',
  });

  const [currentRequestTab, setCurrentRequestTab] = useState(RequestTabs.FlaggedRequests);
  const [updateCloseWorkflow] = useUpdateCloseWorkflow();
  const { loading, data, error } = useClosuresTabData({
    page: currentPage,
    statusFilter,
    contentOptions,
    hasOpenFlags: currentRequestTab === RequestTabs.FlaggedRequests,
    ...(sortConfig && { ...sortConfig, field: sortFieldToSymphony.get(sortConfig.field) }),
  });
  const statusFilterHandler = (newStatusFilter: ClosureStatusFilter) => {
    setStatusFilter(newStatusFilter);
    setCurrentPage(1);
  };

  const onSort = (field: string) => () => {
    const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
    setSortConfig({ field, order: toggleOrder });
    setCurrentPage(1);
  };
  const { open: statusModalOpen, openModal: openStatusModal, onClose: statusModalOnClose } = useModalState();

  const onSelectNewStatusForItem = (item: ClosureItem, selectedStatus: ClosureStatus) => {
    setSelectedCloseWorkflowUpdateItem({
      item,
      newStatus: selectedStatus,
    });
    openStatusModal();
  };

  const onConfirmStatusChange = async () => {
    if (selectedCloseWorkflowUpdateItem?.item && selectedCloseWorkflowUpdateItem.newStatus) {
      setIsBusy(true);
      const { newStatus } = selectedCloseWorkflowUpdateItem;
      const { id, managedProductId } = selectedCloseWorkflowUpdateItem.item;
      const selectedCloseWorkflowStatus = closureStatusToCloseAccountStatusForUpdate(newStatus);
      if (selectedCloseWorkflowStatus) {
        try {
          await updateCloseWorkflow({
            variables: {
              managedProductId,
              closeWorkflowId: id,
              status: selectedCloseWorkflowStatus,
            },
          });
          data?.refetchAccountClosuresData();
        } catch (e: any) {
          setErrorSaving(e);
        } finally {
          setIsBusy(false);
          statusModalOnClose();
        }
      }
    }
  };

  const handleRequestTabChange: ComponentProps<typeof Tabs>['onChange'] = option => {
    setCurrentRequestTab(option);
    setCurrentPage(1);
  };

  const displayStrings = useMemo(() => {
    if (data) {
      return generateClosureStaticDisplayStrings(data.content);
    }
    return null;
  }, [data]);

  const closuresTable = useMemo(() => {
    if (data && displayStrings) {
      return (
        <ClosuresTable
          TableComponent={BasicTable}
          commentColumn={commentColumn}
          contentOptions={contentOptions}
          currentPage={currentPage}
          currentUser={currentUser}
          displayStrings={displayStrings}
          getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
          getClientNameRedirectUrl={getClientNameRedirectUrl}
          items={data.closureItems}
          nullStateConfig={nullStateConfig}
          onAccountClick={onAccountClick}
          onClientClick={onClientClick}
          onPageChange={setCurrentPage}
          onSelectNewStatusForItem={onSelectNewStatusForItem}
          onSort={onSort}
          refetchData={() => data.refetchAccountClosuresData()}
          sortConfig={sortConfig}
          totalPages={data.totalPages}
        />
      );
    }
    return null;
  }, [
    commentColumn,
    contentOptions,
    currentPage,
    currentUser,
    data,
    onAccountClick,
    onClientClick,
    onSort,
    sortConfig,
    statusFilter,
  ]);

  return (
    <Grid container direction="column" spacing={3}>
      {(loading || error) && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading closure items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      )}
      <Grid item>
        {errorSaving && <Alert contentOptions={contentOptions} error={errorSaving} severity="error" />}
        {data && displayStrings && (
          <>
            <TabContext value={currentRequestTab}>
              <Grid container sx={{ pt: 0, ml: 2 }}>
                <Grid item xs={12}>
                  <Tabs
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: sfOpsDashboardStyles.tabsIndicatorColor,
                      },
                    }}
                    data-qa="partition-tabs"
                    onChange={handleRequestTabChange}
                    value={currentRequestTab}
                  >
                    <Tab
                      data-qa="closures-flagged-requests-tab"
                      label={displayStrings.partitionLabels[RequestTabs.FlaggedRequests]}
                      sx={{
                        ...sfOpsDashboardStyles.partitionTabs,
                      }}
                      value={RequestTabs.FlaggedRequests}
                    />
                    <Tab
                      data-qa="closures-all-other-requests-tab"
                      label={displayStrings.partitionLabels[RequestTabs.AllOtherRequests]}
                      sx={{
                        ...sfOpsDashboardStyles.partitionTabs,
                      }}
                      value={RequestTabs.AllOtherRequests}
                    />
                  </Tabs>
                </Grid>
              </Grid>
              <Grid data-qa="filter-container" item sx={{ display: 'flex', justifyContent: 'end' }} xs={12}>
                <Typography sx={{ marginRight: 2, alignSelf: 'center' }} variant="subtitle2">
                  Filter by Status:
                </Typography>
                <ClosuresStatusFilter
                  currentStatusFilter={statusFilter}
                  displayStrings={displayStrings}
                  onChange={statusFilterHandler}
                />
                <br />
              </Grid>
              <Grid container sx={{ py: 5 }}>
                <TabPanel sx={{ width: 1, mx: -1 }} value={RequestTabs.FlaggedRequests}>
                  {closuresTable}
                </TabPanel>
                <TabPanel sx={{ width: 1, mx: -1 }} value={RequestTabs.AllOtherRequests}>
                  {closuresTable}
                </TabPanel>
                <StatusChangeModal
                  contentOptions={contentOptions}
                  isBusy={isBusy}
                  onClose={statusModalOnClose}
                  onConfirm={onConfirmStatusChange}
                  open={statusModalOpen}
                  showDescriptionForCompleteStatus={
                    selectedCloseWorkflowUpdateItem?.newStatus === ClosureStatus.COMPLETED
                  }
                />
              </Grid>
            </TabContext>
          </>
        )}
      </Grid>
    </Grid>
  );
};
