import { CLIENT_NAME, SYMPHONY_UPLOAD_CLIENT_NAME } from '../constants';

import {
  makeUseLazyQueryWithClientName,
  makeUseMutationWithClientName,
  makeUseQueryWithClientName,
  useLazyQuery,
  useMutation,
  useQuery,
} from '~/utils/apollo-client';

export const useSymphonyQuery: typeof useQuery = makeUseQueryWithClientName(CLIENT_NAME);
export const useLazySymphonyQuery: typeof useLazyQuery = makeUseLazyQueryWithClientName(CLIENT_NAME);
export const useSymphonyMutation: typeof useMutation = makeUseMutationWithClientName(CLIENT_NAME);
export const useSymphonyUploadMutation: typeof useMutation = makeUseMutationWithClientName(SYMPHONY_UPLOAD_CLIENT_NAME);
