import React, { ComponentProps, FC } from 'react';

import { Box, Grid, Skeleton, SxProps, Theme, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { SfTheme } from '~/utils/theme';

interface Attribute {
  dataQa?: string;
  label?: string;
  loading?: boolean;
  sx?: SxProps<Theme>;
  value: string;
}

interface Props extends ComponentProps<typeof Grid> {
  data: Array<Attribute | boolean>;
}

export const AccountAttributes: FC<Props> = ({ data, ...rest }) => {
  const {
    sfAccount: { typographyVariants },
  } = useTheme<SfTheme>();

  if (!data.length) {
    return null;
  }

  /**
   * To make the borders align vertically with other rows in Desktop view,
   * the array of attributes is put into a single Grid container,
   * so that the column width can be set respective to its parent container easier.
   *
   * Each attribute's column value should be set, while the last column (or when there is only one attribute)
   * should be set with true to allow filling up the rest of the available space.
   *
   * In mobile view, the column is set auto, since vertical alignment is not relevant are in its own row
   */
  return (
    <Grid container item spacing={2} sx={{ justifyContent: { xs: 'left', md: 'right' } }} {...rest}>
      {data.map((attr, index) => {
        if (typeof attr === 'boolean') {
          return null;
        }
        const { dataQa, label, loading, value, ...attrs } = attr;
        const isLastItem = index === data.length - 1;
        return (
          <Grid data-qa={dataQa} item key={index} md={isLastItem ? true : 5} xs="auto" {...attrs}>
            <Box
              sx={[
                index > 0 && {
                  borderLeft: ({ palette }) => `1px solid ${palette.divider}`,
                  pl: 2,
                },
              ]}
            >
              {label && (
                <Typography color="text.secondary" data-qa={`${dataQa}-label`} variant="caption">
                  {label}
                </Typography>
              )}
              {loading && <Skeleton data-qa={`${dataQa}-skeleton`} />}
              {!loading && (
                <Typography component="div" data-qa={`${dataQa}-value`} variant={typographyVariants?.balance}>
                  {value}
                </Typography>
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
