import { useCallback } from 'react';

import { Emitter, useAnalyticsEvent } from '~/hooks/analytics';

type OnboardingGoalSelection =
  | ({ name: 'continue' } & { goalObjective: string; goalTitle: string; isExistingGoal: boolean })
  | { name: 'skipForLater' };

export type GoalsEvent =
  | { componentName: 'actions'; name: 'deleteGoal' }
  | { accountType: 'existing' | 'other'; componentName: 'associateAccount'; name: 'complete' | 'start' }
  | {
      componentName: 'associatedAccounts';
      name: 'associateExistingAccount' | 'openNewAccount' | 'editAccount' | 'unassociateAccount';
    }
  | { componentName: 'cardInactive'; name: 'editGoal' | 'finishEditGoal' }
  | { componentName: 'cardActive'; name: 'editGoal' }
  | { componentName: 'changeTarget'; name: 'saveTarget' }
  | { componentName: 'completeGoal'; name: 'cancelCompleteGoal' | 'confirmCompleteGoal' }
  | { componentName: 'createGoal'; name: 'cancelCreateGoal' }
  | { componentName: 'createGoal'; name: 'confirmCreateGoal'; title: string }
  | { componentName: 'editGoalName'; name: 'saveGoalName' }
  | { componentName: 'deleteGoal'; name: 'cancelDeleteGoal' | 'confirmDeleteGoal' }
  | { componentName: 'details'; name: 'editGoal' | 'saveGoal' }
  | { componentName: 'graphs'; name: 'changeTarget' | 'viewSavings' | 'viewSpending' }
  | { componentName: 'header'; name: 'editGoalName' }
  | {
      componentName: 'summary';
      name: 'createFirstGoal' | 'createGoal' | 'deleteGoal' | 'deleteCompletedGoal' | 'editGoal' | 'markGoalCompleted';
    }
  | ({ componentName: 'onboardingGoalSelection' } & OnboardingGoalSelection)
  | { componentName: 'onboardingGoalDetails'; name: 'cancel' | 'open' | 'save' | 'skipForLater' };
export const useGoalsEvent = (): Emitter<GoalsEvent> => {
  const emit = useAnalyticsEvent<GoalsEvent>('goals');

  return useCallback(
    args => {
      emit(args);
    },
    [emit],
  );
};
