import React, { ComponentProps } from 'react';

import { Comment } from '../../common/Comment';
import { LabeledValueField } from '../../common/LabeledValueField';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { GetOpsDashboardContentV2 } from '../../contentstack/__generated__/query.v2';
import { isAccountNumberClickable, OpsDashboardUser } from '../../utils';
import { DocusignItem } from '../types';

import { UserNoteEntityType } from '~/__generated__';
import { TableData } from '~/components/ui/BasicTable';
import { Chip } from '~/components/ui/Chip';
import { CopyField } from '~/components/ui/CopyField';
import { Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { commentColumnFactory } from '~/containers/OpsDashboard/utils';
import { ContentOptions, ContentstackField, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

enum PartyEmailType {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
}

const getEmailType = (recipientId: number): string => {
  const PRIMARY_RECIPIENT_ID = 1;
  return recipientId === PRIMARY_RECIPIENT_ID ? PartyEmailType.PRIMARY : PartyEmailType.SECONDARY;
};

const emailRecipientsColumnValue = (item: DocusignItem, emailType: PartyEmailType) => {
  const emailRecipients = item.emailRecipients.filter(v => getEmailType(v.recipientId) === emailType);
  return emailRecipients.map((emailRecipient, index) => (
    <Typography key={index} sx={{ color: 'text.primary' }} variant="body2">
      {emailRecipient.email}
    </Typography>
  ));
};

interface Props {
  activeRowId?: string | undefined;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  fields: (ContentstackField | null)[] | null | undefined;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  items: DocusignItem[];
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  opsContentData?: GetOpsDashboardContentV2;
  refetchData: () => void;
  showProductName: boolean;
}

export const getFormattedItems = ({
  activeRowId,
  items,
  refetchData,
  contentOptions,
  currentUser,
  getAccountNumberRedirectUrl,
  getClientNameRedirectUrl,
  onAccountClick,
  onClientClick,
  opsContentData,
  fields,
  showProductName,
}: Props): TableData[] => {
  const opsDashboardContent = opsContentData?.all_ops_dashboard?.items?.[0];
  const copyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_ulid_label');
  const successCopyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_success_label');
  const commentColumn = commentColumnFactory(opsDashboardContent?.comment_column ?? undefined);
  return items.map(item => ({
    ...item,
    rowKey: item.id,
    accountNumber: (
      <AccountNumber
        accountNumber={item.accountNumber}
        accountNumberText={item.accountNumberText}
        label={showProductName ? item.productName : null}
        onClick={
          isAccountNumberClickable(item.accountState)
            ? () => onAccountClick(item.partyId, item.managedProductId)
            : undefined
        }
        redirectUrl={
          isAccountNumberClickable(item.accountState)
            ? getAccountNumberRedirectUrl(item.partyId, item.managedProductId)
            : undefined
        }
      />
    ),
    clientName: (
      <ClientName
        clientName={item.clientName}
        onClick={() => onClientClick(item.partyId)}
        redirectUrl={getClientNameRedirectUrl(item.partyId)}
      />
    ),
    created: <LabeledValueField value={formatDate(item.dateGenerated)} />,
    status: (
      <Stack direction="row">
        <Chip
          label={findFieldValue(fields || [], item.status.toLowerCase())}
          sx={{
            backgroundColor: 'primary.selected',
            color: 'primary.main',
            borderRadius: 1,
            width: '100%',
          }}
          variant="filled"
        />
        <Stack justifyContent="center" sx={{ width: '30px', px: 1, fontSize: 16 }}>
          {activeRowId === item.id && (
            <CopyField
              copyLabel={copyLabel}
              defaultState
              disableToggling
              successLabel={successCopyLabel}
              textToCopy={item.id}
            />
          )}
        </Stack>
      </Stack>
    ),
    primaryRecipient: emailRecipientsColumnValue(item, PartyEmailType.PRIMARY),
    secondaryRecipient: emailRecipientsColumnValue(item, PartyEmailType.SECONDARY),
    actions: (
      <Comment
        comment={item.comment}
        content={commentColumn}
        contentOptions={contentOptions}
        currentUser={currentUser}
        entity={UserNoteEntityType.SIGNING_DOCUMENT}
        entityId={item.id}
        key={item.id}
        lastCommentPartyId={item.lastCommentPartyId}
        refetchData={refetchData}
      />
    ),
  }));
};
