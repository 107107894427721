import { endOfYesterday } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { useGetAccountPerformanceContent } from '../contentstack';
import {
  AccountPerformanceContent,
  getFormattedContentData,
  getMonthlyMarketReviewUrl,
  getPerformanceFilterDropdownOptions,
} from '../utils';

import { PerformanceMeasurementInput, PerformanceMethodTypes } from '~/__generated__';
import { PerformanceFilterDropdowns, SecurityReturns } from '~/components/AccountPerformance/types';
import { fillMissingData } from '~/components/PerformanceChart/utils';
import {
  DetailedFinancialAccount,
  isManagedProduct,
  useAccountDetailsGetDigitalWealthAccounts,
  useAccountDetailsGetPerformance,
} from '~/hooks/account-details/symphony';
import { AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct } from '~/hooks/account-details/symphony/__generated__/query.v2';
import {
  getMeasurementForChart,
  getPastQuartersDropdownOptions,
  getPerformanceMeasurements,
  getStartDateEndDateOfQuarter,
  Quarter,
} from '~/hooks/account-details/utils';
import { useGetAssetClassContent } from '~/hooks/asset-classes/contentstack';
import { useGetAllModelPortfoliosContentAcrossPages } from '~/hooks/model-portfolios/contentstack';
import { MeasurementName } from '~/hooks/performance/types';
import { QuarterlyAssets, useGetQuarterlyPerformanceReport } from '~/hooks/qpr/symphony';
import {
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_portfolioSummary,
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_sleevesAllocation,
} from '~/hooks/qpr/symphony/__generated__/query.v2';
import { getOpeningClosingBalance, OpeningClosingBalance } from '~/hooks/qpr/useGetDataForQPR';
import { AssetClassContent, isRecommendedPortfolio } from '~/utils/asset-allocation';
import { ContentOptions } from '~/utils/contentstack';
import { formatPercentage } from '~/utils/format/number';
import { toSymphonyDate } from '~/utils/symphony';
import { AsyncResult, DailyChartValue } from '~/utils/types';

export interface AccountPerformanceVariables {
  contentOptions: ContentOptions;
  managedProductId: string;
  partyId: string;
  performanceMethod?: PerformanceMethodTypes;
  securityIds: string[];
  selectedQuarter: string;
  selectedTimePeriod: MeasurementName;
  showFactSheetLinkInAssetAllocation?: boolean;
  showPortfolioNameInAssetAllocation?: boolean;
  showVerifiedBankAccounts?: boolean;
  syncExternalBankAccounts?: boolean;
}

export interface AccountPerformanceData {
  account?: DetailedFinancialAccount;
  accountActivityData?: GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_portfolioSummary | null;
  assetClassContent?: AssetClassContent | null;
  assets?: QuarterlyAssets;
  content: AccountPerformanceContent;
  endDate: string;
  managedProduct?: AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct;
  measurements: PerformanceMeasurementInput[];
  modelPortfolioName?: string | null;
  modelPortfolioSeriesBaseName?: string | null;
  monthlyReviewURL: string;
  openingClosingBalance?: OpeningClosingBalance;
  performanceChartData: {
    amount: number;
    date: string;
  }[];
  performanceFilterDropdowns: PerformanceFilterDropdowns;
  returns: {
    accountReturnsData: DailyChartValue[];
    securitiesReturnsData: SecurityReturns[];
  };
  sleeves?: GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_sleevesAllocation[] | null;
  startDate: string;
  strategyBrochureUrl: string | null;
}

export const useGetAccountPerformanceData = ({
  variables: {
    contentOptions,
    managedProductId,
    partyId,
    performanceMethod,
    securityIds,
    selectedQuarter,
    selectedTimePeriod,
    showFactSheetLinkInAssetAllocation,
    showPortfolioNameInAssetAllocation,
    showVerifiedBankAccounts,
    syncExternalBankAccounts,
  },
}: {
  skip?: boolean;
  variables: AccountPerformanceVariables;
}): AsyncResult<AccountPerformanceData> => {
  const [state, setState] = useState<AsyncResult<AccountPerformanceData>>({
    loading: true,
  });

  const { data: contentData, loading: contentLoading, error: contentError } = useGetAccountPerformanceContent({
    variables: contentOptions,
  });

  const {
    data: modelPortfoliosContentData,
    loading: modelPortfoliosContentLoading,
    error: modelPortfoliosContentError,
  } = useGetAllModelPortfoliosContentAcrossPages({
    contentOptions,
    skip: !showFactSheetLinkInAssetAllocation && !showPortfolioNameInAssetAllocation,
  });

  const content = useMemo(() => {
    return contentData ? getFormattedContentData(contentData) : undefined;
  }, [contentData]);

  const {
    data: digitalWealthAccountsData,
    loading: digitalWealthAccountsLoading,
    error: digitalWealthAccountsError,
  } = useAccountDetailsGetDigitalWealthAccounts({
    variables: {
      partyId,
      detailedAccounts: [managedProductId],
      isVerified: showVerifiedBankAccounts,
      syncExternalBankAccounts,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const account = digitalWealthAccountsData?.client?.detailedAccounts?.find(a => {
    return a.products?.filter(isManagedProduct).find(product => product.id === managedProductId);
  });

  const managedProduct = account?.products?.find(isManagedProduct);

  const firstRebalancedOn = managedProduct?.firstRebalancedOn ?? '';

  const measurements = useMemo(() => {
    if (!firstRebalancedOn) {
      return [];
    }

    const pastQuarterDropdownOptions = getPastQuartersDropdownOptions(firstRebalancedOn);
    let defaultQuarter = selectedQuarter;
    // Default selectedQuarter to first available value
    if (!selectedQuarter && selectedTimePeriod === MeasurementName.QuarterlyPerformance) {
      defaultQuarter = pastQuarterDropdownOptions?.[0].value ?? '';
    }
    const [quarter, year] = defaultQuarter.split(' ');
    const { startDate: fromDate, endDate: toDate } = getStartDateEndDateOfQuarter(quarter as Quarter | undefined, year);

    return getPerformanceMeasurements({
      accountActivationDate: firstRebalancedOn,
      performanceMethod,
      quarterly:
        fromDate && toDate
          ? { from: firstRebalancedOn > fromDate ? firstRebalancedOn : fromDate, to: toDate }
          : undefined,
      to: toSymphonyDate(endOfYesterday()),
    });
  }, [firstRebalancedOn, performanceMethod, selectedQuarter, selectedTimePeriod]);

  const measurementForChart = getMeasurementForChart(measurements, selectedTimePeriod);

  const startDate = measurementForChart[0]?.from || '';

  const endDate = measurementForChart[0]?.to || '';

  const skipGetAccountDetailsPerformance = !account?.id || !firstRebalancedOn || !measurementForChart.length;

  const strategyBrochureUrl = useMemo(() => {
    return managedProduct?.targetModelPortfolio && isRecommendedPortfolio(managedProduct.targetModelPortfolio)
      ? managedProduct.targetModelPortfolio.strategyBrochureUrl
      : '';
  }, [managedProduct]);

  const {
    data: accountPerformanceData,
    loading: accountPerformanceLoading,
    error: accountPerformanceError,
  } = useAccountDetailsGetPerformance({
    variables: {
      accounts: [account?.id as string],
      measurements: measurementForChart,
    },
    skip: skipGetAccountDetailsPerformance,
    errorPolicy: 'all',
  });

  const mapChartData = (chartData: { date: string; value: string }[]): { amount: number; date: string }[] => {
    return chartData.map(dataPoint => ({ amount: parseFloat(dataPoint.value), date: dataPoint.date }));
  };

  const performanceChartData = useMemo(() => {
    return mapChartData(accountPerformanceData?.performance?.[0].valueChart ?? []);
  }, [accountPerformanceData?.performance]);

  const {
    data: financialAccountData,
    loading: financialAccountDataLoading,
    error: financialAccountDataError,
  } = useGetQuarterlyPerformanceReport({
    variables: { managedProductId, startDate, endDate, securityIds },
    skip: !startDate || !endDate,
    fetchPolicy: 'no-cache',
  });

  // We find the sleeve with sleeve percentage 0 as that means that it is a Single MP. We do not want to take into account composite MPs
  const recommendedModelPortfolio = financialAccountData?.managedProduct?.quarterlyPerformanceReport?.sleevesAllocation?.find(
    sleeve => sleeve.percentage === '0',
  );

  const modelPortfolioName = useMemo(() => {
    return modelPortfoliosContentData?.all_model_portfolio_data?.items?.find(
      mc =>
        mc?.series_name === recommendedModelPortfolio?.modelPortfolioSeriesBaseName &&
        mc?.internal_id === recommendedModelPortfolio?.modelPortfolioIdentifier?.toString(),
    )?.model_portfolio_name;
  }, [modelPortfoliosContentData, recommendedModelPortfolio]);

  const accountReturnsData = useMemo(() => {
    const chartData = mapChartData(
      financialAccountData?.managedProduct?.quarterlyPerformanceReport?.portfolioSummary?.dailyCumulativeReturns?.flatMap(
        dailyCumulativeReturns => dailyCumulativeReturns ?? [],
      ) ?? [],
    );

    return fillMissingData(chartData, { startDate, endDate });
  }, [
    endDate,
    financialAccountData?.managedProduct?.quarterlyPerformanceReport?.portfolioSummary?.dailyCumulativeReturns,
    startDate,
  ]);

  const securitiesReturnsData = useMemo(() => {
    return (
      financialAccountData?.managedProduct?.quarterlyPerformanceReport?.securitySummaries.map(securitySummary => {
        const dailyChartData = mapChartData(securitySummary.dailyCumulativeReturns ?? []);
        const returnValue =
          dailyChartData.length > 0
            ? formatPercentage(dailyChartData[dailyChartData.length - 1].amount, {
                convertRatioToPercentage: false,
                decimals: 2,
                removeTrailingZeroes: false,
                locale: contentOptions.locale,
              })
            : undefined;

        return {
          dailyChartData: fillMissingData(dailyChartData, { startDate, endDate }),
          returnValue,
          securityId: securitySummary.securityId,
          securityName: securitySummary.name,
        };
      }) ?? []
    );
  }, [
    contentOptions.locale,
    endDate,
    financialAccountData?.managedProduct?.quarterlyPerformanceReport?.securitySummaries,
    startDate,
  ]);
  const {
    data: assetClassContentData,
    loading: assetClassContentLoading,
    error: assetClassContentError,
  } = useGetAssetClassContent({
    variables: contentOptions,
  });

  const assetClassContent = assetClassContentData?.all_asset_class_data?.items;

  const loading = useMemo(() => {
    return (
      accountPerformanceLoading ||
      financialAccountDataLoading ||
      digitalWealthAccountsLoading ||
      assetClassContentLoading ||
      modelPortfoliosContentLoading ||
      contentLoading
    );
  }, [
    accountPerformanceLoading,
    financialAccountDataLoading,
    digitalWealthAccountsLoading,
    assetClassContentLoading,
    modelPortfoliosContentLoading,
    contentLoading,
  ]);

  useEffect(() => {
    const error =
      digitalWealthAccountsError ||
      accountPerformanceError ||
      financialAccountDataError ||
      assetClassContentError ||
      modelPortfoliosContentError ||
      contentError;

    if (error) {
      setState({
        error,
        loading: false,
      });
    }

    if (loading) {
      setState({
        loading: true,
      });
    } else {
      setState({
        loading: false,
      });
    }
    if (digitalWealthAccountsData && financialAccountData && content && !loading) {
      const quarterlyPerformanceReport = financialAccountData.managedProduct?.quarterlyPerformanceReport;
      const accountActivityData = financialAccountData.managedProduct?.quarterlyPerformanceReport?.portfolioSummary;

      const monthlyReviewURL = getMonthlyMarketReviewUrl(
        quarterlyPerformanceReport?.marketCommentary ?? [],
        selectedQuarter,
        selectedTimePeriod,
        endDate,
      );
      setState({
        data: {
          account,
          accountActivityData,
          assetClassContent,
          assets: quarterlyPerformanceReport?.allocations?.guidance?.diversification?.assets,
          content,
          endDate,
          managedProduct,
          measurements,
          modelPortfolioSeriesBaseName:
            quarterlyPerformanceReport?.sleevesAllocation?.[0]?.modelPortfolioSeriesBaseName,
          openingClosingBalance: getOpeningClosingBalance(accountActivityData),
          performanceChartData,
          startDate,
          sleeves: quarterlyPerformanceReport?.sleevesAllocation,
          modelPortfolioName,
          strategyBrochureUrl,
          monthlyReviewURL,
          performanceFilterDropdowns: getPerformanceFilterDropdownOptions(
            content.performanceFilters.filters,
            firstRebalancedOn,
            measurements,
          ),
          returns: {
            accountReturnsData,
            securitiesReturnsData,
          },
        },
        loading: false,
      });
    }
  }, [
    account,
    accountPerformanceError,
    accountReturnsData,
    assetClassContent,
    assetClassContentError,
    content,
    contentError,
    digitalWealthAccountsData,
    digitalWealthAccountsError,
    endDate,
    financialAccountData,
    financialAccountDataError,
    firstRebalancedOn,
    loading,
    managedProduct,
    measurements,
    modelPortfolioName,
    modelPortfoliosContentError,
    performanceChartData,
    securitiesReturnsData,
    selectedQuarter,
    selectedTimePeriod,
    startDate,
    strategyBrochureUrl,
  ]);

  return state;
};
