import { addSeconds, differenceInSeconds, differenceInYears, format, getUnixTime, isWithinInterval } from 'date-fns';
import React, { ComponentProps, FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import { getTimelineInterval } from './util';

import { Box } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useRefDimensions } from '~/hooks/layout';

export interface Props {
  content: {
    today: string;
  };
  dataQa?: string;
  end?: Date;
  moments: Moment[];
  start?: Date;
}

export interface Moment {
  date: Date;
  key: string | number;
  tickStyle?: ComponentProps<typeof Tick>['tickStyle'];
  tooltip?: ReactElement;
}

const END_CAP_WIDTH = 2 as const;
const END_CAP_HEIGHT = 12 as const;

const LABEL_OFFSET = -50 as const;
const LABEL_MARGIN = 150 as const;

const MAIN_LINE_HEIGHT = 2 as const;

const TICK_BORDER_RADIUS = 4 as const;
const TICK_BORDER_SIZE = 2 as const;

const TICK_CIRCLE_RADIUS = 12 as const;

const TICK_OVAL_WIDTH = 6 as const;
const TICK_OVAL_HEIGHT = 12 as const;

const TICK_LINE_WIDTH = 2 as const;
const TICK_LINE_HEIGHT = 12 as const;

const TOOLTIP_LINE_WIDTH = 2 as const;
const TOOLTIP_BOTTOM_PADDING = 20 as const;

const TODAY_LABEL_OFFSET = 40 as const;

export const Timeline: FC<Props> = ({
  content,
  dataQa = 'timeline',
  end: specifiedEnd,
  start: specifiedStart,
  moments,
}) => {
  const now = new Date();
  const [tickHeights, setTickHeights] = useState<{ height: number; key: string | number }[]>([]);
  const container = useRef<HTMLDivElement>(null);
  const { width } = useRefDimensions(container);

  if (!moments.length) {
    return null;
  }

  const sortedMoments = [...moments].sort((a, b) => getUnixTime(a.date) - getUnixTime(b.date));
  const calculatedInterval = !specifiedStart || !specifiedEnd ? getTimelineInterval(sortedMoments, now) : undefined;
  const start = specifiedStart ?? calculatedInterval?.start;
  const end = specifiedEnd ?? calculatedInterval?.end;
  if (!start || !end) {
    return null;
  }

  const usableWidth = width !== undefined ? width - 2 * END_CAP_WIDTH : undefined;
  const xScalar = (usableWidth ?? 0) / (getUnixTime(end) - getUnixTime(start));
  const xPos = (date: Date | number) => (getUnixTime(date) - getUnixTime(start)) * xScalar;
  const validMoments = sortedMoments.filter(m => isWithinInterval(m.date, { start, end }));
  const containerHeight = Math.max(...tickHeights.map(t => t.height)) + TODAY_LABEL_OFFSET;

  const labelCount = Math.min(
    Math.max(differenceInYears(end, start), 1),
    Math.floor((usableWidth ?? 0) / LABEL_MARGIN) - 1,
  );
  const labelInterval = differenceInSeconds(end, start) / (labelCount + 1);
  const labels = Array.from({ length: labelCount }, (_, i) => ({ date: addSeconds(start, labelInterval * (i + 1)) }));

  return (
    <Box data-qa={dataQa} visibility={usableWidth ? 'visible' : 'hidden'}>
      <Box alignItems="end" display="flex" height={containerHeight} position="relative" ref={container}>
        <MainLine dataQa={`${dataQa}-main-line`} xNow={xPos(now)} />
        {isWithinInterval(now, { start, end }) && (
          <TodayMark
            containerHeight={containerHeight}
            content={content}
            dataQa={`${dataQa}-today-mark`}
            x={xPos(now)}
          />
        )}
        {labels.map(l => (
          <Tick
            containerHeight={containerHeight}
            key={l.date.getTime()}
            label={format(l.date, 'yyyy')}
            tickShape="line"
            x={xPos(l.date)}
          />
        ))}
        {validMoments.map(m => (
          <Tick
            containerHeight={containerHeight}
            dataQa={`${dataQa}-group-tick`}
            key={m.key}
            onDimensionChange={({ height }) =>
              setTickHeights(currHeights => {
                if (currHeights.find(t => t.key === m.key)?.height !== height) {
                  return [...currHeights.filter(t => t.key !== m.key), { key: m.key, height }];
                }
                return currHeights;
              })
            }
            tickStyle={m.tickStyle ?? 'filled'}
            tooltip={{
              align: 'bottom',
              node: m.tooltip,
            }}
            x={xPos(m.date)}
          />
        ))}
      </Box>
    </Box>
  );
};

const EndCap = ({ left, right }: { left?: boolean; right?: boolean }) => (
  <VerticalLine
    height={END_CAP_HEIGHT}
    left={left ? 0 : undefined}
    right={right ? 0 : undefined}
    width={END_CAP_WIDTH}
  />
);

const MainLine: FC<{ dataQa?: string; xNow: number }> = ({ dataQa = 'main-line', xNow }) => (
  <Box data-qa={dataQa}>
    <EndCap left />
    <Box
      border="none"
      borderBottom={`${MAIN_LINE_HEIGHT}px dashed black`}
      bottom={0 - MAIN_LINE_HEIGHT / 2}
      left={0}
      position="absolute"
      width="100%"
    />
    <Box
      bgcolor="common.black"
      bottom={0 - MAIN_LINE_HEIGHT / 2}
      height={`${MAIN_LINE_HEIGHT}px`}
      left={0}
      position="absolute"
      sx={{ transition: 'width 1s ease' }}
      width={xNow}
    />
    <EndCap right />
  </Box>
);

const TodayMark: FC<{ containerHeight: number; content: { today: string }; dataQa?: string; x: number }> = ({
  containerHeight,
  content,
  dataQa = 'today-mark',
  x,
}) => (
  <Tick
    containerHeight={containerHeight}
    dataQa={`${dataQa}-tick`}
    tickShape="oval"
    tooltip={{
      align: 'top',
      node: (
        <Box bgcolor="grey.100" border="solid 2px white" borderRadius={8} px={1} py={0.5}>
          <Typography variant="body2">{content.today}</Typography>
        </Box>
      ),
    }}
    x={x}
  />
);

const Tick: FC<{
  containerHeight: number;
  dataQa?: string;
  label?: string | number;
  onDimensionChange?: (newDimensions: { height: number; width: number }) => void;
  tickShape?: 'circle' | 'oval' | 'line';
  tickStyle?: 'filled' | 'outlined';
  tooltip?: { align?: 'top' | 'bottom'; node: ReactNode };
  x: number;
}> = ({
  containerHeight,
  dataQa = 'tick',
  label,
  onDimensionChange,
  tickShape = 'circle',
  tickStyle = 'filled',
  tooltip,
  x,
}) => {
  const tooltipBox = useRef<HTMLDivElement>(null);
  const { width, height } = useRefDimensions(tooltipBox);

  useEffect(() => {
    onDimensionChange?.({ height: height ? height + TOOLTIP_BOTTOM_PADDING : 0, width: width ?? 0 });
  }, [height, onDimensionChange, width]);

  return (
    <Box data-qa={dataQa} visibility={tooltip && !width ? 'hidden' : undefined}>
      {label && (
        <Typography bottom={LABEL_OFFSET} left={x - 20} position="absolute" sx={{ transition: 'left 1s ease' }}>
          {label}
        </Typography>
      )}
      {tooltip && (
        <>
          <Box
            bgcolor="grey.400"
            bottom={0}
            height={tooltip.align === 'top' ? containerHeight : TOOLTIP_BOTTOM_PADDING + (height ?? 0)}
            left={x - TOOLTIP_LINE_WIDTH / 2}
            position="absolute"
            sx={{ transition: 'left 1s ease' }}
            width={`${TOOLTIP_LINE_WIDTH}px`}
          />
          <Box
            bottom={tooltip.align === 'bottom' ? TOOLTIP_BOTTOM_PADDING : undefined}
            left={x - (width ?? 0) / 2}
            position="absolute"
            ref={tooltipBox}
            sx={{ transition: 'left 1s ease' }}
            top={tooltip.align === 'top' ? 0 : undefined}
          >
            {tooltip.node}
          </Box>
        </>
      )}
      {tickShape === 'circle' && tickStyle === 'filled' && <FilledCircle radius={TICK_CIRCLE_RADIUS - 4} x={x} />}
      {tickShape === 'circle' && tickStyle === 'outlined' && <OutlinedCircle radius={TICK_CIRCLE_RADIUS} x={x} />}
      {tickShape === 'oval' && (
        <Box
          bgcolor="common.black"
          border={`${TICK_BORDER_SIZE}px solid white`}
          borderRadius={TICK_BORDER_RADIUS}
          bottom={0 - (TICK_OVAL_HEIGHT + TICK_BORDER_SIZE + TICK_BORDER_SIZE) / 2}
          height={`${TICK_OVAL_HEIGHT}px`}
          left={x - (TICK_OVAL_WIDTH + TICK_BORDER_SIZE + TICK_BORDER_SIZE) / 2}
          position="absolute"
          sx={{ transition: 'left 1s ease' }}
          width={`${TICK_OVAL_WIDTH}px`}
        />
      )}
      {tickShape === 'line' && <VerticalLine height={TICK_LINE_HEIGHT} width={TICK_LINE_WIDTH} x={x} />}
    </Box>
  );
};

const FilledCircle = ({ radius, x }: { radius: number; x: number }) => (
  <Box
    bgcolor="common.black"
    border={`${TICK_BORDER_SIZE}px solid`}
    borderColor="common.white"
    borderRadius={TICK_BORDER_RADIUS}
    bottom={x ? 0 - (radius + TICK_BORDER_SIZE + TICK_BORDER_SIZE) / 2 : undefined}
    height={`${radius}px`}
    left={x ? x - (radius + TICK_BORDER_SIZE + TICK_BORDER_SIZE) / 2 : undefined}
    position={x ? 'absolute' : undefined}
    sx={{ transition: 'left 1s ease' }}
    width={`${radius}px`}
  />
);

const OutlinedCircle = ({ radius, x }: { radius: number; x: number }) => (
  <>
    <FilledCircle radius={radius} x={x} />
    <Box
      bgcolor="common.white"
      borderRadius={TICK_BORDER_RADIUS}
      bottom={x ? 0 - radius / 2 / 2 : undefined}
      height={`${radius / 2}px`}
      left={x ? x - radius / 2 / 2 : undefined}
      position={x ? 'absolute' : undefined}
      sx={{ transition: 'left 1s ease' }}
      width={`${radius / 2}px`}
    />
  </>
);

const VerticalLine = ({
  height,
  left,
  right,
  width,
  x,
}: {
  height: number;
  left?: number;
  right?: number;
  width: number;
  x?: number;
}) => (
  <Box
    bgcolor="common.black"
    bottom={0 - height / 2}
    height={`${height}px`}
    left={left ?? x}
    position="absolute"
    right={right}
    sx={{ transitionProperty: 'left, right', transitionTiming: 'ease', transitionDuration: '1s' }}
    width={`${width}px`}
  />
);
