import { GetPerformanceDetailsContent, GetPerformanceDetailsContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult, QueryTuple } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export const useGetPerformanceDetailsContent = (
  options?: QueryHookOptions<GetPerformanceDetailsContent, GetPerformanceDetailsContentVariables>,
): QueryResult<GetPerformanceDetailsContent, GetPerformanceDetailsContentVariables> => {
  return useContentstackQuery(queries.GetPerformanceDetailsContent, options);
};

export const useLazyGetPerformanceDetailsContent = (
  options?: QueryHookOptions<GetPerformanceDetailsContent, GetPerformanceDetailsContentVariables>,
): QueryTuple<GetPerformanceDetailsContent, GetPerformanceDetailsContentVariables> => {
  return useLazyContentstackQuery(queries.GetPerformanceDetailsContent, options);
};
