import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import {
  AccountDetailsGetDigitalWealthAccounts,
  AccountDetailsGetDigitalWealthAccountsVariables,
} from '../symphony/__generated__/query.v2';

import { getBankAccount } from './utils';

import { AddFunds } from '~/components/AddFunds';
import {
  DetailedFinancialAccount,
  isBankAccount,
  isManagedProduct,
  ManagedProduct,
  useAccountDetailsGetDigitalWealthAccounts,
} from '~/hooks/account-details/symphony';
import { useGetClientInfo } from '~/hooks/client/symphony';
import { GetClientInfo } from '~/hooks/client/symphony/__generated__/query.v2';
import { FinancialAccount as BankAccountFinancialAccount } from '~/hooks/financial-account/symphony';
import { BankAccount, getPlaidMaskedAccountNumber, isValidBankAccount, sortBankAccounts } from '~/utils/account';
import { ApolloError, ApolloQueryResult, NetworkStatus } from '~/utils/apollo-client';
import { useCoreConfig } from '~/utils/config';
import { formatMaskedAccountNumber } from '~/utils/format';

interface Variables {
  accountNumberFormat?: string;
  detailedAccounts: string[] | null;
  isVerified?: boolean | null;
  managedProductId: string;
  partyId: string;
  skipAccountFundsInformation?: boolean | null;
  skipAccountMinimums?: boolean | null;
  skipRestrictions?: boolean | null;
  skipTradingSuspensions?: boolean | null;
  syncExternalBankAccounts?: boolean | null;
}

interface Data {
  account?: DetailedFinancialAccount;
  bankAccounts: BankAccount[];
  clientData?: GetClientInfo;
  clientError?: ApolloError;
  digitalWealthAccountsData?: AccountDetailsGetDigitalWealthAccounts;
  digitalWealthAccountsDataError: Error | undefined;
  digitalWealthAccountsInitialLoading: boolean;
  isRefetchingAccounts: boolean;
  managedProduct?: ManagedProduct;
  onLinkAccountCallback: (newBankAccount: BankAccountFinancialAccount) => void;
  onRemoveAccount: ComponentProps<typeof AddFunds>['onRemoveAccount'];
  refetchDigitalWealthAccountsData: (
    variables?: Partial<AccountDetailsGetDigitalWealthAccountsVariables>,
  ) => Promise<ApolloQueryResult<AccountDetailsGetDigitalWealthAccounts>>;
}

export const useAccountDetailsData = ({
  accountNumberFormat,
  detailedAccounts,
  isVerified,
  managedProductId,
  partyId,
  skipAccountFundsInformation,
  skipAccountMinimums,
  skipTradingSuspensions,
  skipRestrictions,
  syncExternalBankAccounts,
}: Variables): Data => {
  const {
    featureFlags: { isDocusignRequiredForFinancialAccountLinkageInRCE },
  } = useCoreConfig();
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);

  const { data: clientData, error: clientError } = useGetClientInfo({
    variables: { partyId },
    skip: !partyId,
  });

  const {
    data: digitalWealthAccountsData,
    loading: digitalWealthAccountsLoading,
    error: digitalWealthAccountsError,
    refetch: refetchDigitalWealthAccountsData,
    networkStatus: digitalWealthAccountsNetworkStatus,
  } = useAccountDetailsGetDigitalWealthAccounts({
    variables: {
      partyId,
      detailedAccounts,
      syncExternalBankAccounts,
      isVerified,
      skipAccountMinimums,
      skipRestrictions,
      skipTradingSuspensions,
      withFunds: !skipAccountFundsInformation,
      withLegalDocuments: isDocusignRequiredForFinancialAccountLinkageInRCE,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const isRefetchingAccounts = digitalWealthAccountsNetworkStatus === NetworkStatus.refetch;
  const digitalWealthAccountsInitialLoading = digitalWealthAccountsLoading && !isRefetchingAccounts;

  const onLinkAccountCallback = useCallback(
    (newBankAccount: BankAccountFinancialAccount) => {
      if (!bankAccounts.find(a => a.id === newBankAccount.id) && accountNumberFormat) {
        const maskedAccountNumber =
          getPlaidMaskedAccountNumber(newBankAccount.attributes) ?? newBankAccount.maskedAccountNumber;
        if (newBankAccount.id && newBankAccount.financialInstitution && maskedAccountNumber) {
          setBankAccounts([
            ...bankAccounts,
            {
              id: newBankAccount.id,
              financialInstitution: newBankAccount.financialInstitution,
              maskedAccountNumber: formatMaskedAccountNumber(accountNumberFormat, newBankAccount.maskedAccountNumber),
              balances: newBankAccount.balances,
              type: newBankAccount.type,
            },
          ]);
        }
      }
      refetchDigitalWealthAccountsData();
    },
    [accountNumberFormat, bankAccounts, refetchDigitalWealthAccountsData],
  );

  const onRemoveAccount = useCallback(
    (accountId: string) => {
      setBankAccounts(bankAccounts.filter(acc => acc.id !== accountId));
    },
    [bankAccounts],
  );

  useEffect(() => {
    if (!digitalWealthAccountsInitialLoading && digitalWealthAccountsData) {
      setBankAccounts(
        sortBankAccounts(
          digitalWealthAccountsData.client?.bankAccounts
            ?.filter(isBankAccount)
            ?.filter(isValidBankAccount)
            .map(bankAccount => getBankAccount({ accountNumberFormat, bankAccount })) ?? [],
        ),
      );
    }
  }, [accountNumberFormat, digitalWealthAccountsData, digitalWealthAccountsInitialLoading]);

  const account = useMemo(
    () =>
      digitalWealthAccountsData?.client?.detailedAccounts?.find(a =>
        a.products?.filter(isManagedProduct).find(product => product.id === managedProductId),
      ),
    [digitalWealthAccountsData?.client?.detailedAccounts, managedProductId],
  );

  const digitalWealthAccountsDataError = useMemo(() => {
    if (digitalWealthAccountsError) {
      return digitalWealthAccountsError;
    }
    if (!digitalWealthAccountsInitialLoading && !account) {
      return Error(`Could not find managed account with id ${managedProductId}`);
    }

    return undefined;
  }, [account, digitalWealthAccountsError, digitalWealthAccountsInitialLoading, managedProductId]);

  const managedProduct = useMemo(() => account?.products?.find(isManagedProduct), [account]);

  return {
    account,
    bankAccounts,
    clientData,
    clientError,
    digitalWealthAccountsData,
    digitalWealthAccountsDataError,
    digitalWealthAccountsInitialLoading,
    isRefetchingAccounts,
    managedProduct,
    onLinkAccountCallback,
    onRemoveAccount,
    refetchDigitalWealthAccountsData,
  };
};
