import { FormData } from '~/components/modals/WithdrawFunds/types';

export enum AllocationBarSections {
  FEDERAL_TAX = 'FEDERAL',
  MONEY_YOU_GET = 'MONEY_YOU_GET',
  STATE_TAX = 'STATE',
}

export interface TaxWithholdingAllocationHookData {
  federalTaxColor: string;
  federalTaxValue: number;
  isValidTaxPercent: boolean;
  moneyYouGet: number;
  moneyYouGetColor: string;
  stateTaxColor: string;
  stateTaxValue: number;
  totalWithdrawalAmount: number;
}

export interface TaxWithholdingAllocationHookVariables {
  coverTaxWithholding: boolean;
  federalTaxPercent: number;
  formData?: FormData;
  stateTaxPercent: number;
}

export const useTaxWitholdingAllocation = ({
  formData,
  federalTaxPercent,
  stateTaxPercent,
  coverTaxWithholding,
}: TaxWithholdingAllocationHookVariables): TaxWithholdingAllocationHookData => {
  const withdrawalAmount = parseFloat(formData?.withdrawalAmount ?? '');
  const moneyYouGetPercent = 100 - (federalTaxPercent + stateTaxPercent);

  const totalWithdrawalAmount = coverTaxWithholding ? (withdrawalAmount / moneyYouGetPercent) * 100 : withdrawalAmount;

  const federalTaxValue = parseFloat(((federalTaxPercent / 100) * totalWithdrawalAmount).toFixed(2));
  const stateTaxValue = parseFloat(((stateTaxPercent / 100) * totalWithdrawalAmount).toFixed(2));

  const moneyYouGet = coverTaxWithholding ? withdrawalAmount : withdrawalAmount - (federalTaxValue + stateTaxValue);

  const getBarColor = (allocationtype: string) => {
    const colorMapping = [
      { type: AllocationBarSections.MONEY_YOU_GET, value: moneyYouGet },
      { type: AllocationBarSections.FEDERAL_TAX, value: federalTaxValue },
      { type: AllocationBarSections.STATE_TAX, value: stateTaxValue },
    ]
      .sort((a, b) => b.value - a.value)
      .map((el, index) => {
        if (index === 0) {
          return { ...el, color: 'info.dark' };
        } else if (index === 1) {
          return { ...el, color: 'info.main' };
        } else {
          return { ...el, color: 'info.border' };
        }
      });
    return colorMapping.filter(el => el.type === allocationtype)[0].color;
  };

  const moneyYouGetColor = getBarColor(AllocationBarSections.MONEY_YOU_GET);
  const federalTaxColor = getBarColor(AllocationBarSections.FEDERAL_TAX);
  const stateTaxColor = getBarColor(AllocationBarSections.STATE_TAX);

  const isValidTaxPercent = stateTaxPercent >= 0 && federalTaxPercent >= 0 && stateTaxPercent + federalTaxPercent < 100;

  return {
    federalTaxColor,
    federalTaxValue,
    isValidTaxPercent,
    moneyYouGet,
    moneyYouGetColor,
    stateTaxColor,
    stateTaxValue,
    totalWithdrawalAmount: coverTaxWithholding ? moneyYouGet + federalTaxValue + stateTaxValue : totalWithdrawalAmount,
  };
};
