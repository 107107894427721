import React, { useCallback, useState } from 'react';

import { OpsAlertAndLoading } from '../common/OpsAlertAndLoading';
import { SearchBar } from '../common/SearchBar';
import { RemoveSuspensionData } from '../types';

import { useTradingSuspensionsData } from './hooks/useTradingSuspensionsData';
import { defaultFilters } from './hooks/utils';

import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { RemoveTradingSuspension } from '~/components/modals/RemoveTradingSuspension';
import { NullStateProps } from '~/components/NullState';
import { BasicTable } from '~/components/ui/BasicTable';
import { ItemsPerPage } from '~/components/ui/BasicTable/ItemsPerPage';
import { TotalItems } from '~/components/ui/BasicTable/TotalItems';
import { useModalState } from '~/components/ui/Modal/hooks';
import { CheckIcon, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Snackbar, SnackbarPositionType } from '~/components/ui/Snackbar';
import { Switch } from '~/components/ui/Switch';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  nullStateConfig?: NullStateProps;
}

export const TradingSuspendedTable: React.FC<Props> = ({
  contentOptions,
  dataQa = 'trading-suspended-table',
  nullStateConfig,
}) => {
  const [suspensionData, setSuspensionData] = useState<RemoveSuspensionData>();
  const [snackbarMessage, setSnackbarMessaage] = useState<string>();
  const [showSnackBar, setShowSnackbar] = useState(false);

  const { open, openModal, onClose } = useModalState();

  const onRemoveSuspensionClick = (data: RemoveSuspensionData) => {
    setSuspensionData(data);
    openModal();
  };

  const { data, loading, error } = useTradingSuspensionsData({
    onRemoveSuspensionClick,
  });

  const onTradingSuspensionRemoveFailure = useCallback(() => {
    setShowSnackbar(false); // To close the existing opened snackbar, this will restart the time of new snackbar
    setSnackbarMessaage(data?.content.removeSuspensionFailure);
    setShowSnackbar(true);
  }, [data]);

  const onTradingSuspensionRemoveSuccess = useCallback(() => {
    data?.refetchTradingSuspendedData();
    setShowSnackbar(false); // To close the existing opened snackbar, this will restart the time of new snackbar
    setSnackbarMessaage(data?.content.removeSuspensionSuccess);
    setShowSnackbar(true);
    onClose();
  }, [data, onClose]);

  const handleCloseSnackbar = useCallback(() => {
    setShowSnackbar(false);
  }, []);

  return (
    <>
      <Stack data-qa={`${dataQa}-container`}>
        {(loading || error) && (
          <OpsAlertAndLoading
            ariaLabel="Loading trading suspended items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        )}

        {data?.content && !loading && (
          <Stack flexDirection="column" sx={{ mb: 4 }}>
            <Stack alignItems="center" data-qa="filter-container" flexDirection="row" justifyContent="space-between">
              <SearchBar
                onSearchChange={value => data.onSearchChange(value)}
                searchContext={data.searchContext}
                searchError={!!data.searchError}
                value={data.currentSearchFilter}
              />
              <Filters
                appliedFilters={data.appliedFilters}
                content={data.content.filtersContent}
                contentOptions={contentOptions}
                defaultAppliedFilters={defaultFilters}
                filterCountMap={data.filterCountMap}
                filters={data.content.filterConfig}
                onSubmit={data.onFilterChange}
                openFiltersPopup={data.filtersPopupOpen}
                updateOpenFiltersPopup={data.updateOpenFiltersPopup}
              />
            </Stack>
            <AppliedFilters
              appliedFilters={data.appliedFilters}
              content={data.content.appliedFiltersContent}
              filters={data.content.filterConfig}
              onAppliedFilterSelect={data.onAppliedFiltersSelect}
              searchResults={
                <Stack alignItems="center" flexDirection="row">
                  <Switch
                    checked={data.isGroupAccountToggleChecked}
                    color="primary"
                    data-qa={`${dataQa}-group-account-switch`}
                    onChange={data.handleGroupAccountToggle}
                  />
                  <Typography variant="body1">{data.content.groupSuspensionToggleLabel}</Typography>
                </Stack>
              }
            />
          </Stack>
        )}
        {data?.currentSearchFilter && !data.searchError && !loading && (
          <RteContent
            config={{
              searchCount: data.paginationContext.totalItems,
              searchTerm: data.currentSearchFilter,
            }}
            data={data.searchContext.totalSearchItemsLabel}
          />
        )}
        {data?.tradingSuspendedTableContent && !loading && (
          <BasicTable
            BottomLeftSection={<TotalItems paginationContext={data.paginationContext} />}
            BottomRightSection={<ItemsPerPage paginationContext={data.paginationContext} />}
            alignItems="left"
            columns={data.tableColumns}
            currentPage={data.currentPage}
            data={data.tradingSuspendedTableContent}
            enableRowHover
            nullStateConfig={nullStateConfig}
            onPageChange={data.onPageChange}
            onRowHover={data.onRowHover}
            showPagination
            sortConfig={data.sortConfig}
            totalPages={data.totalPages}
          />
        )}
      </Stack>

      {suspensionData && suspensionData.suspensionType && suspensionData.suspensionTag && !loading && (
        <RemoveTradingSuspension
          contentOptions={contentOptions}
          managedProductId={suspensionData.managedProductId}
          onClose={onClose}
          onTradingSuspensionRemoveFailure={onTradingSuspensionRemoveFailure}
          onTradingSuspensionRemoveSuccess={onTradingSuspensionRemoveSuccess}
          open={open}
          suspensionDisplayData={suspensionData.data}
          suspensionTag={suspensionData.suspensionTag}
          suspensionType={suspensionData.suspensionType}
        />
      )}

      <Snackbar
        action={undefined} // To override the actions defined in Snackbar
        feedbackMessage="" // To override the feedback message in Snackar
        message={
          <Stack flexDirection="row">
            <CheckIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2">{snackbarMessage}</Typography>
          </Stack>
        }
        onClose={handleCloseSnackbar}
        open={showSnackBar}
        snackbarPosition={SnackbarPositionType.BOTTOM_LEFT}
      />
    </>
  );
};
