import {
  GetViewTransfersContent,
  GetViewTransfersContent_all_view_transfers_modal_items_body_ViewTransfersModalBodyTable_table_bodyConnection_edges_node,
  GetViewTransfersContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export type ContentstackTransfersTable = GetViewTransfersContent_all_view_transfers_modal_items_body_ViewTransfersModalBodyTable_table_bodyConnection_edges_node;

// corresponding Contentstack field: GetViewTransfersContent_all_view_transfers_modal_items_body_table['data_type']
export enum TransfersTableType {
  Past = 'Past',
  Upcoming = 'Upcoming',
}

export const useGetViewTransfersContent = (
  options?: QueryHookOptions<GetViewTransfersContent, GetViewTransfersContentVariables>,
) => {
  return useContentstackQuery(queries.GetViewTransfersContent, options);
};

export const useLazyGetViewTransfersContent = (
  options?: QueryHookOptions<GetViewTransfersContent, GetViewTransfersContentVariables>,
) => {
  return useLazyContentstackQuery(queries.GetViewTransfersContent, options);
};
