import React, { FC } from 'react';

import { SuspensionType } from '~/__generated__';
import { Divider } from '~/components/ui/Divider';
import { Grid, Stack, useTheme, WarningIcon } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { AccountRestriction } from '~/containers/AccountSummary/types';
import { findFieldValue } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';
import { TextField } from '~/utils/types';

interface TradingSuspension {
  suspensionTag: string | null;
  suspensionType?: SuspensionType;
}

interface MultiRestrictions {
  restrictionType: string;
}

interface Props {
  restrictions?: AccountRestriction[];
  textFields: (TextField | null)[] | null;
  tradingSuspensions?: TradingSuspension[];
}

export const AccountRestrictions: FC<Props> = ({ restrictions, textFields, tradingSuspensions }) => {
  const {
    sfAccount: { styles: sfAccountStyles },
  } = useTheme<SfTheme>();

  const multiRestrictions: MultiRestrictions[] =
    restrictions?.map(v => {
      return { restrictionType: v.restrictionType };
    }) ?? [];

  if (tradingSuspensions?.length) {
    multiRestrictions.push({ restrictionType: 'TRADING_RESTRICTION' });
  }

  return (
    <Grid item xs={12}>
      <Stack direction="row">
        {multiRestrictions.map((restriction, index) => (
          <Stack data-qa="account-restrictions" direction="row" key={index}>
            {index === 0 && <WarningIcon sx={{ ...sfAccountStyles.warningIcon, color: 'warning.light' }} />}
            <Typography sx={{ pr: 2 }} variant="caption">
              {findFieldValue(textFields, restriction.restrictionType)}
            </Typography>
            {index !== multiRestrictions.length - 1 && <Divider orientation="vertical" sx={{ mr: 2 }} />}
          </Stack>
        ))}
      </Stack>
    </Grid>
  );
};
