import React, { useState } from 'react';

import { DocusignStatusFilter } from './DocusignStatusFilter';
import { useColumnHeaders } from './hooks/useColumnHeaders';
import { useDocusignTabData } from './hooks/useDocusignTabData';
import { useFormattedItems } from './hooks/useFormattedItems';

import { OrderType, SigningDocumentsField } from '~/__generated__';
import { NullStateProps } from '~/components/NullState';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable, SortConfig } from '~/components/ui/BasicTable';
import { Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { DocusignStatus } from '~/containers/OpsDashboard/DocusignTab/types';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  nullStateConfig?: NullStateProps;
}

export const DocusignTab: React.FC<Props> = ({ contentOptions, nullStateConfig }) => {
  const [statusFilters, setStatusFilters] = useState<DocusignStatus[]>([
    DocusignStatus.DECLINED,
    DocusignStatus.FAILED,
    DocusignStatus.PENDING,
  ]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    order: OrderType.DESCENDING,
    field: 'dateGenerated',
  });

  const { showProductName } = useCoreConfig().components.sfOpsDashboard;

  const { data, error, loading } = useDocusignTabData({
    fetchLatestNote: true,
    ...sortConfig,
    field: SigningDocumentsField.CREATED,
    statusFilters,
    page: currentPage,
  });

  const statusFilterHandler = (status: DocusignStatus[]) => {
    setStatusFilters(prevState => {
      if (prevState.includes(DocusignStatus.ALL) && status.includes(DocusignStatus.ALL)) {
        status.splice(status.indexOf(DocusignStatus.ALL), 1);
        return status;
      }
      if ((!prevState.includes(DocusignStatus.ALL) && status.includes(DocusignStatus.ALL)) || !status.length) {
        return [DocusignStatus.ALL];
      }
      return status;
    });
    setCurrentPage(1);
  };

  const onSort = (field: string) => () => {
    const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
    setSortConfig({ order: toggleOrder, field });
    setCurrentPage(1);
  };

  const formattedItems = useFormattedItems({
    items: data?.docusignItems || [],
    refetchData: data?.refetchData || (() => null),
    status: data?.content.status,
    showProductName,
  });

  const columns = useColumnHeaders(data?.content, onSort);

  return (
    <Grid container direction="column" spacing={3}>
      {(loading || error) && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading docusign items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      )}
      {data?.content && (
        <Grid data-qa="filter-container" item sx={{ display: 'flex', justifyContent: 'end' }} xs={12}>
          <Typography sx={{ marginRight: 2, alignSelf: 'center' }} variant="subtitle2">
            Filter by Status:
          </Typography>
          <DocusignStatusFilter
            currentStatus={statusFilters}
            displayStrings={data.content}
            onChange={statusFilterHandler}
          />
        </Grid>
      )}
      {data && (
        <Grid item>
          <BasicTable
            alignItems="left"
            columns={columns}
            currentPage={currentPage}
            data={formattedItems}
            nullStateConfig={nullStateConfig}
            onPageChange={setCurrentPage}
            showPagination
            sortConfig={sortConfig}
            totalPages={data.totalPages}
          />
        </Grid>
      )}
    </Grid>
  );
};
