import React, { FC } from 'react';

import { Box, Paper, Skeleton } from '~/components/ui/mui';

interface Props {
  dataQa?: string;
  loading?: boolean;
}

export const AccountSectionCard: FC<Props> = ({ children, dataQa = 'account-summary-section-card', loading }) => {
  return (
    <Paper data-qa={dataQa} square={false} sx={{ px: { xs: 2, md: 4 }, py: { xs: 2, md: 3 } }} variant="outlined">
      {loading && (
        <Box data-qa={`${dataQa}-loading`}>
          <Skeleton sx={{ mb: 2 }} width="10%" />
          <Skeleton height={300} variant="rectangular" />
        </Box>
      )}
      {!loading && children}
    </Paper>
  );
};
