import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useRmdData } from '../hooks/useRmdData';
import { RmdNotificationContent, RmdStatus } from '../types';

import { ConfirmationModal } from '~/components/modals/Confirmation';
import { Alert } from '~/components/ui/Alert';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Button, Skeleton, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrencyPrecise } from '~/utils/format';

enum ConfirmationModalType {
  DISMISS = 'DISMISS',
  REQUIREMENT_FULFILLED = 'REQUIREMENT_FULFILLED',
}

export interface Props {
  content: RmdNotificationContent;
  contentOptions: ContentOptions;
  hideWithdrawCta: boolean;
  openWithdrawFundsModal: (eligibleIraAccountIds: string[]) => void;
  partyId: string;
  reload: boolean;
}

export const RmdNotification: React.FC<Props> = ({
  content,
  contentOptions,
  hideWithdrawCta,
  openWithdrawFundsModal,
  partyId,
  reload,
}) => {
  const { data, error } = useRmdData({ contentOptions, partyId });

  const { open, openModal: openConfirmationModal, onClose: onCloseConfirmationModal } = useModalState();
  const [confirmationModalType, setConfirmationModalType] = useState<ConfirmationModalType>(
    ConfirmationModalType.REQUIREMENT_FULFILLED,
  );

  const onOpenModal = useCallback(
    (type: ConfirmationModalType) => {
      setConfirmationModalType(type);
      openConfirmationModal();
    },
    [openConfirmationModal],
  );

  useEffect(() => {
    if (reload) {
      data?.refetch();
    }
  }, [reload]);

  const getAlertBody = useCallback(() => {
    if (data?.isRefetchingRmdData) {
      return (
        <Stack>
          <Skeleton width="200px" />
          <Skeleton width="400px" />
        </Stack>
      );
    }
    switch (data?.rmdStatus) {
      case RmdStatus.COMPLETED:
        return (
          <>
            <Typography sx={{ fontWeight: 'bold' }} variant="body1">
              {content.completedHeader}
            </Typography>
            <Typography variant="body2">{content.completedText}</Typography>
          </>
        );
      case RmdStatus.INELIGIBLE_IRA:
        return (
          <>
            <Typography sx={{ fontWeight: 'bold' }} variant="body1">
              {content.ineligibleIRAHeader}
            </Typography>
            <RteContent config={{ rmdDeadline: data.rmdAmounts[0]?.deadline }} data={content.ineligibleIRAText} />
          </>
        );
      case RmdStatus.LESS_THAN_31_DAYS:
        return (
          <>
            <RteContent
              config={{ rmdNumberOfDaysRemaining: data.rmdAmounts[0].daysTillDeadline }}
              data={content.lessThan31DaysHeader}
            />
            <RteContent
              config={{
                rmdDeadline: data.rmdAmounts[0]?.deadline,
                rmdBalance: formatCurrencyPrecise(data.rmdAmounts[0]?.remainingRmdAmountToBeWithdrawn),
              }}
              data={content.lessThan31DaysText}
            />
          </>
        );
      case RmdStatus.LESS_THAN_31_DAYS_PARTIAL:
        return (
          <>
            <RteContent
              config={{ rmdNumberOfDaysRemaining: data.rmdAmounts[0].daysTillDeadline }}
              data={content.lessThan31DaysHeader}
            />
            <RteContent
              config={{
                rmdDeadline: data.rmdAmounts[0]?.deadline,
                rmdBalance: formatCurrencyPrecise(data.rmdAmounts[0]?.remainingRmdAmountToBeWithdrawn),
              }}
              data={content.partialDataAvailableText}
            />
          </>
        );
      case RmdStatus.MORE_THAN_30_DAYS:
        return (
          <>
            <Typography sx={{ fontWeight: 'bold' }} variant="body1">
              {content.header}
            </Typography>
            <RteContent
              config={{
                rmdDeadline: data.rmdAmounts[0]?.deadline,
                rmdBalance: formatCurrencyPrecise(data.rmdAmounts[0]?.remainingRmdAmountToBeWithdrawn),
              }}
              data={content.moreThan30DaysText}
            />
          </>
        );
      case RmdStatus.MORE_THAN_30_DAYS_MULTIPLE:
        return (
          <>
            <Typography sx={{ fontWeight: 'bold' }} variant="body1">
              {content.extendedHeader}
            </Typography>
            <RteContent
              config={{
                rmdDeadline1: data.rmdAmounts[0]?.deadline,
                rmdBalance1: formatCurrencyPrecise(data.rmdAmounts[0]?.remainingRmdAmountToBeWithdrawn),
                rmdDeadline2: data.rmdAmounts[1]?.deadline,
                rmdBalance2: formatCurrencyPrecise(data.rmdAmounts[1]?.remainingRmdAmountToBeWithdrawn),
              }}
              data={content.extendedText}
            />
          </>
        );
      case RmdStatus.NO_DATA:
        return (
          <>
            <Typography sx={{ fontWeight: 'bold' }} variant="body1">
              {content.header}
            </Typography>
            <RteContent config={{ rmdDeadline: data.rmdAmounts[0]?.deadline }} data={content.noDataText} />
          </>
        );
      case RmdStatus.PARTIAL_DATA_AVAILABLE:
        return (
          <>
            <Typography sx={{ fontWeight: 'bold' }} variant="body1">
              {content.header}
            </Typography>
            <RteContent
              config={{
                rmdDeadline: data.rmdAmounts[0]?.deadline,
                rmdBalance: formatCurrencyPrecise(data.rmdAmounts[0]?.remainingRmdAmountToBeWithdrawn),
              }}
              data={content.partialDataAvailableText}
            />
          </>
        );
      case RmdStatus.PARTIAL_DATA_COMPLETED:
        return (
          <>
            <Typography sx={{ fontWeight: 'bold' }} variant="body1">
              {content.header}
            </Typography>
            <RteContent config={{ rmdDeadline: data.rmdAmounts[0].deadline }} data={content.partialDataCompletedText} />
          </>
        );
      default:
        return null;
    }
  }, [content, data]);

  const alertActions = useMemo(() => {
    if (data?.isRefetchingRmdData) {
      return undefined;
    }
    switch (data?.rmdStatus) {
      case RmdStatus.COMPLETED:
      case RmdStatus.NO_DATA:
      case RmdStatus.PARTIAL_DATA_COMPLETED:
      case RmdStatus.INELIGIBLE_IRA:
        return (
          <Button color="inherit" onClick={() => onOpenModal(ConfirmationModalType.DISMISS)}>
            {content.ctas.dismissRmdNotification}
          </Button>
        );
      default:
        return (
          <>
            {!hideWithdrawCta && data?.eligibleIraAccountIds.length !== 0 && (
              <Button
                color="inherit"
                onClick={() => openWithdrawFundsModal(data?.eligibleIraAccountIds || [])}
                variant="outlined"
              >
                {content.ctas.setupWithdrawal}
              </Button>
            )}
            <Button color="inherit" onClick={() => onOpenModal(ConfirmationModalType.REQUIREMENT_FULFILLED)}>
              {content.ctas.requirementFulfilled}
            </Button>
          </>
        );
    }
  }, [
    content.ctas.dismissRmdNotification,
    content.ctas.requirementFulfilled,
    content.ctas.setupWithdrawal,
    data?.eligibleIraAccountIds,
    data?.isRefetchingRmdData,
    data?.rmdStatus,
    hideWithdrawCta,
    onOpenModal,
    openWithdrawFundsModal,
  ]);

  return error ? (
    <Alert contentOptions={contentOptions} error={error} severity="error" />
  ) : data && data.rmdStatus !== RmdStatus.NO_RMD ? (
    <>
      <Alert
        action={alertActions}
        severity={
          data.rmdStatus === RmdStatus.LESS_THAN_31_DAYS || data.rmdStatus === RmdStatus.LESS_THAN_31_DAYS_PARTIAL
            ? 'warning'
            : 'info'
        }
        sx={{ '& .MuiAlert-action': { alignItems: 'center' } }}
      >
        {getAlertBody()}
      </Alert>
      <ConfirmationModal
        content={
          confirmationModalType === ConfirmationModalType.DISMISS
            ? content.dismissRmdModal
            : content.requirementFulfilledRmdModal
        }
        onClose={onCloseConfirmationModal}
        onPrimaryClick={data.onDismissRmd}
        open={open}
      />
    </>
  ) : (
    <></>
  );
};
