import {
  GetAccountNumberLabels,
  GetAccountNumberLabelsVariables,
  GetClientNameLabels,
  GetClientNameLabelsVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useAccountNumberLabels = (
  options?: QueryHookOptions<GetAccountNumberLabels, GetAccountNumberLabelsVariables>,
): QueryResult<GetAccountNumberLabels, GetAccountNumberLabelsVariables> => {
  return useContentstackQuery(queries.GetAccountNumberLabels, options);
};

export const useClientNameLabels = (
  options?: QueryHookOptions<GetClientNameLabels, GetClientNameLabelsVariables>,
): QueryResult<GetClientNameLabels, GetClientNameLabelsVariables> => {
  return useContentstackQuery(queries.GetClientNameLabels, options);
};
