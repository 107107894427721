import { UpdateFundingReview, UpdateFundingReviewVariables } from './__generated__/mutation.v2';
import {
  GetFundingReviews,
  GetFundingReviews_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers,
  GetFundingReviews_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers_AssetTransfer,
  GetFundingReviews_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers_CashTransfer,
  GetFundingReviewsVariables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ScheduledTransfer = GetFundingReviews_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers;
export type CashTransfer = GetFundingReviews_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers_CashTransfer;
export type AssetTransfer = GetFundingReviews_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers_AssetTransfer;

export const useGetOpsDashboardFundingTableData = (
  options?: QueryHookOptions<GetFundingReviews, GetFundingReviewsVariables>,
): QueryResult<GetFundingReviews, GetFundingReviewsVariables> => {
  return useSymphonyQuery(queries.GetFundingReviews, options);
};

export const useUpdateFundingReview = (
  options?: MutationHookOptions<UpdateFundingReview, UpdateFundingReviewVariables>,
): MutationTuple<UpdateFundingReview, UpdateFundingReviewVariables> => {
  return useSymphonyMutation(mutations.UpdateFundingReview, options);
};
