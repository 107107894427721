import { UpdateFailuresV2, UpdateFailuresV2Variables } from './__generated__/mutation.v2';
import { GetErrorsV2, GetErrorsV2_failures_failures, GetErrorsV2Variables } from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ErrorsFailure = GetErrorsV2_failures_failures;

export const useGetErrorsV2 = (
  options?: QueryHookOptions<GetErrorsV2, GetErrorsV2Variables>,
): QueryResult<GetErrorsV2, GetErrorsV2Variables> => {
  return useSymphonyQuery(queries.GetErrorsV2, options);
};

export const useUpdateFailuresV2 = (options?: MutationHookOptions<UpdateFailuresV2, UpdateFailuresV2Variables>) => {
  return useSymphonyMutation(mutations.UpdateFailuresV2, options);
};
