import {
  GetRemoveTradingSuspensionsModalContent,
  GetRemoveTradingSuspensionsModalContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type RemoveTradingSuspensionModalContent = GetRemoveTradingSuspensionsModalContent;

export const useGetRemoveTradingSuspensionsModalContent = (
  options?: QueryHookOptions<GetRemoveTradingSuspensionsModalContent, GetRemoveTradingSuspensionsModalContentVariables>,
): QueryResult<GetRemoveTradingSuspensionsModalContent, GetRemoveTradingSuspensionsModalContentVariables> => {
  return useContentstackQuery(queries.GetRemoveTradingSuspensionsModalContent, options);
};
