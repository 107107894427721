import React, { ComponentProps } from 'react';

import { alpha, Button, MuiChip, useTheme } from '~/components/ui/mui';
import { Tooltip } from '~/components/ui/Tooltip';
import { getSxPropsArray } from '~/utils/theme';

interface CustomProps {
  maxLabelLength?: number;
  statusColor?: string;
  variant?: 'status' | ComponentProps<typeof MuiChip>['variant'];
}

export type Props = Omit<ComponentProps<typeof MuiChip>, keyof CustomProps> & CustomProps;

export const Chip: React.FC<Props> = ({ statusColor: statusColor, variant, label, maxLabelLength, ...props }) => {
  const {
    sfChip: { styles },
  } = useTheme();

  const showTooltip = maxLabelLength && typeof label === 'string' && label.length > maxLabelLength;
  const isVariantStatus = variant === 'status';

  const chipProps: ComponentProps<typeof MuiChip> = {
    label: showTooltip ? label.substring(0, maxLabelLength) + '...' : label,
    sx: isVariantStatus
      ? [
          ...getSxPropsArray(styles.statusChip),
          !!statusColor && { color: statusColor, backgroundColor: alpha(statusColor, 0.1) },
        ]
      : [],
    variant: isVariantStatus ? 'filled' : variant,
    ...props,
  };

  if (showTooltip) {
    return (
      <Tooltip disableHoverListener={false} tooltipContent={`${label}`} variant="popup">
        <Button disableRipple sx={{ ...styles.tooltipButton }}>
          <MuiChip {...chipProps} />
        </Button>
      </Tooltip>
    );
  }

  return <MuiChip {...chipProps} />;
};
