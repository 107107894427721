import { useEffect, useState } from 'react';

import { Content } from '..';
import { useGetPaperworkInputs } from '../symphony';
import { GetPaperworkInputs_managedProduct_paperwork } from '../symphony/__generated__/query.v2';

import { OrderSubset, PaperworkType, QuestionnaireFilterTypes } from '~/__generated__';
import { ComparisonData, usePortfolioComparison } from '~/hooks/comparison/usePortfolioComparison';
import {
  isCollectedDataCurrency,
  isCollectedDataInteger,
  isCollectedDataSingleOption,
  QuestionnaireCollectedData,
  useGetLatestCompletedQuestionnaire,
} from '~/hooks/questionnaire/symphony';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency } from '~/utils/format';
import { AsyncResult } from '~/utils/types';

export interface ModelChangeDetailsVariable {
  assetClassTier?: AssetClassTier;
  clientName: string;
  content: Content;
  contentOptions: ContentOptions;
  managedProductId: string;
  partyId: string;
}

export interface ModelChangeDetailsData extends Partial<ComparisonData> {
  detailsLabel?: string;
  fields?: { key: string; label: string; value: string }[];
  recommendationRationaleField?: { label: string; value: string };
}

export const useModelChangeDetails = ({
  assetClassTier,
  content,
  contentOptions,
  clientName,
  managedProductId,
  partyId,
}: ModelChangeDetailsVariable): AsyncResult<ModelChangeDetailsData> => {
  const [state, setState] = useState<AsyncResult<ModelChangeDetailsData>>({ loading: true });
  const { data: comparisonData, loading: comparisonDataLoading, error: comparisonDataError } = usePortfolioComparison({
    assetClassTier,
    contentOptions,
    managedProductId,
    partyId,
  });

  const {
    data: latestQuestionnaireData,
    loading: latestQuestionnaireLoading,
    error: latestQuestionnaireError,
  } = useGetLatestCompletedQuestionnaire({
    variables: {
      managedProductId,
      partyId,
      filter: QuestionnaireFilterTypes.RTQ_COMPLETED,
      orderSubSet: OrderSubset.MADLIBS,
    },
  });

  const {
    data: paperworkInputsData,
    loading: paperworkInputsLoading,
    error: paperworkInputsError,
  } = useGetPaperworkInputs({
    variables: {
      managedProductId,
      partyId,
    },
  });

  useEffect(() => {
    if (comparisonDataLoading || latestQuestionnaireLoading || paperworkInputsLoading) {
      setState({ loading: true });
      return;
    }

    if (comparisonDataError || latestQuestionnaireError || paperworkInputsError) {
      setState({ loading: false, error: comparisonDataError || latestQuestionnaireError || paperworkInputsError });
      return;
    }
    const collectedAnswers =
      latestQuestionnaireData?.managedProduct?.questionnaires?.[0].collectedDataGroup.collectedData ?? [];
    const orderSteps = latestQuestionnaireData?.managedProduct?.questionnaires?.[0].order?.orderSteps ?? [];
    const recommendationRationale = content.fields.find(e => e.key === 'recommendationRationale');
    const detailsLabel = content.fields.find(e => e.key === 'detailsLabel')?.label ?? '';
    const name = content.fields.find(e => e.key === 'name');
    const associatedPrimaryPaperwork = paperworkInputsData?.managedProduct?.paperwork?.find(
      paperworkObj => paperworkObj.paperworkType === PaperworkType.PRIMARY,
    );
    const associatedSecondaryPaperwork = paperworkInputsData?.managedProduct?.paperwork?.find(
      paperworkObj => paperworkObj.paperworkType === PaperworkType.SECONDARY,
    );
    const madlibsAnswers = content.fields
      .filter(e => e.isMadlibs)
      .map(e => {
        const dataPointKey = orderSteps.find(step => step.questionKey === e.key)?.dataPointKey;
        const item = collectedAnswers.find(answer => answer.dataPointKey === dataPointKey);
        return { key: e.key, value: getValue(content, dataPointKey, item), label: e.label };
      });
    const paperworkInputs = content.fields
      .filter(field => !field.isMadlibs && !['name', 'detailsLabel', 'recommendationRationale'].includes(field.key))
      .map(paperworkField =>
        handlePaperworkInputValue(associatedPrimaryPaperwork, associatedSecondaryPaperwork, paperworkField),
      );
    setState({
      loading: false,
      data: {
        recommendationRationaleField: {
          value: comparisonData?.recommendationRationale ?? '',
          label: recommendationRationale?.label ?? '',
        },
        detailsLabel,
        fields: [
          { key: name?.key ?? '', label: name?.label ?? '', value: clientName },
          ...madlibsAnswers,
          ...paperworkInputs,
        ],
        ...comparisonData,
      },
    });
  }, [
    comparisonData,
    comparisonDataError,
    comparisonDataLoading,
    latestQuestionnaireData,
    latestQuestionnaireError,
    latestQuestionnaireLoading,
    paperworkInputsData,
    paperworkInputsError,
    paperworkInputsLoading,
  ]);

  return state;
};

const handlePaperworkInputValue = (
  associatedPrimaryPaperwork: GetPaperworkInputs_managedProduct_paperwork | undefined,
  associatedSecondaryPaperwork: GetPaperworkInputs_managedProduct_paperwork | undefined,
  dataPoint: { isMadlibs: boolean; key: string; label: string },
): {
  key: string;
  label: string;
  value: string;
} => {
  const returnedValue = {
    key: dataPoint.key ?? '',
    label: dataPoint.label ?? '',
    value: '',
  };

  switch (dataPoint.key) {
    case 'data-point:investment-duration:single-option':
      returnedValue.value =
        associatedPrimaryPaperwork?.investment?.investmentObjective ??
        associatedSecondaryPaperwork?.investment?.investmentObjective ??
        '';
      break;
    case 'data-point:annual-income:currency':
      returnedValue.value =
        associatedPrimaryPaperwork?.wealthInformation?.annualIncome ??
        associatedSecondaryPaperwork?.wealthInformation?.annualIncome ??
        '';
      break;
    case 'data-point:liquid-assets:currency':
      returnedValue.value =
        associatedPrimaryPaperwork?.wealthInformation?.liquidAssets ??
        associatedSecondaryPaperwork?.wealthInformation?.liquidAssets ??
        '';
      break;
    default:
      break;
  }

  return returnedValue;
};

const getValue = (content: Content, questionKey?: string, dataPoint?: QuestionnaireCollectedData) => {
  if (dataPoint) {
    if (isCollectedDataCurrency(dataPoint)) {
      return formatCurrency(parseFloat(dataPoint.value.currencyValue));
    } else if (isCollectedDataInteger(dataPoint)) {
      return dataPoint.integerValue.toString();
    } else if (isCollectedDataSingleOption(dataPoint)) {
      const options = content.singleOptionsList?.find(e => e.key === questionKey)?.options ?? [];
      return options.find(e => e.key === dataPoint.singleOptionValue)?.value ?? '';
    }
  }
  return '';
};
