import React, { ComponentProps, FC, ReactNode, useEffect, useState } from 'react';
import { Controller, UseFormMethods, Validate } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { TaxWithholdingAllocationBar } from './TaxWithholdingAllocationBar';
import { getDropdownItems, getStateTaxWithholdingOptions } from './utils';

import { FinancialAccountType, TransferFrequency } from '~/__generated__/symphonyTypes.v2';
import { ValidateCashTransferResponse } from '~/components/modals/WithdrawFunds/symphony';
import { FormData } from '~/components/modals/WithdrawFunds/types';
import { TaxWithholdingFormContent } from '~/components/modals/WithdrawFunds/WithdrawFundsModalContent';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownChangeEvent } from '~/components/ui/Dropdown/types';
import { Checkbox, FormControl, FormControlLabel, Grid, SxProps, Theme, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { formatCurrencyPrecise } from '~/utils/format';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Props {
  availableFunds?: number;
  clientAge?: number | null;
  content: TaxWithholdingFormContent;
  dataQa?: string;
  financialAccountType?: FinancialAccountType;
  formData?: FormData;
  formHooks: UseFormMethods<FormData>;
  isCloseAccount?: boolean;
  maxAllowedAmount?: number;
  setTotalWithdrawalAmount?: (amount: string) => void;
  validateCashTransferResponse?: ValidateCashTransferResponse;
}

const TaxInputWrapper: FC<ComponentProps<typeof Grid>> = ({ children, sx }) => (
  <Grid item sx={{ width: ({ spacing }) => `calc(50% - ${spacing(1.5)})`, ...sx }} xs>
    {children}
  </Grid>
);

const ErrorMessage: FC<{ dataQa?: string; errorMessage: string | ReactNode; sx?: SxProps<Theme> }> = ({
  dataQa = 'generic',
  errorMessage,
  sx,
}) => (
  <Typography data-qa={`${dataQa}-error-message`} role="alert" sx={{ color: 'error.main', ...sx }} variant="caption">
    {errorMessage}
  </Typography>
);

export enum WITHHOLD_OPTIONS {
  DO_NOT_WITHHOLD = 'N',
  WITHHOLD = 'W',
  WITHHOLD_MINIMUM = 'M',
}

export enum DISTRIBUTION_REASON_CODES {
  NORMAL = '7',
  PREMATURE = '1',
}

export enum STATE_TAX_ELIGIBILITY_INDICATORS {
  Mandatory = 'M',
  NoStateTax = 'N',
  OptIn = 'I',
  OptOut = 'O',
  Voluntary = 'V',
}

// These values get used when there is no value for age in contentstack
export const DEFAULT_PREMATURE_AGE = 59.5;
export const DEFAULT_RETIREMENT_OLD_AGE = 72;

export const federalTaxWithholdingName = 'federalTaxWithholding';
export const federalTaxWithholdingPercentageName = 'federalTaxWithholdingPercentage';
export const stateTaxWithholdingName = 'stateTaxWithholding';
export const stateTaxWithholdingPercentageName = 'stateTaxWithholdingPercentage';
export const grossUpFlagName = 'grossUpFlag';

const TaxPercentageValidations = {
  belowMinimumTax: 'belowMinimumTax',
  belowMinimumFederalTax: 'belowMinimumFederalTax',
  greaterThanAllowed: 'greaterThanAllowed',
  amountGreaterThanAllowed: 'amountGreaterThanAllowed',
  required: 'required',
};

export const TaxWithholdingForm: FC<Props> = ({
  availableFunds,
  clientAge,
  content,
  dataQa = 'withdraw-funds-form',
  isCloseAccount = false,
  financialAccountType,
  formData,
  formHooks,
  maxAllowedAmount,
  setTotalWithdrawalAmount,
  validateCashTransferResponse,
}) => {
  const [showOverdrawnWithdrawalError, setShowOverdrawnWithdrawalError] = useState(false);
  const [showMaximumAllowedAmountError, setShowMaximumAllowedAmountError] = useState(false);
  const { control, errors: fieldsErrors, setValue, trigger, watch } = formHooks;
  const isMobile = useIsMediumScreen();
  const {
    sfWithdrawalModal: {
      styles: { taxWithholdingDropdown: dropdownStyle },
    },
  } = useTheme();

  useEffect(() => {
    if (formData?.federalTaxWithholding) {
      setValue(federalTaxWithholdingName, formData.federalTaxWithholding, { shouldValidate: true, shouldDirty: true });
    }
    if (formData?.federalTaxWithholdingPercentage !== undefined) {
      setValue(federalTaxWithholdingPercentageName, formData.federalTaxWithholdingPercentage, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    if (formData?.stateTaxWithholding) {
      setValue(stateTaxWithholdingName, formData.stateTaxWithholding, { shouldValidate: true, shouldDirty: true });
    }
    if (formData?.stateTaxWithholdingPercentage !== undefined) {
      setValue(stateTaxWithholdingPercentageName, formData.stateTaxWithholdingPercentage, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    if (formData?.grossUpFlag) {
      setValue(grossUpFlagName, formData.grossUpFlag, { shouldValidate: true, shouldDirty: true });
    }
  }, [formData]);

  const federalTaxPercent = watch(federalTaxWithholdingPercentageName);
  const stateTaxPercent = watch(stateTaxWithholdingPercentageName);
  const federalTaxWithholding = watch(federalTaxWithholdingName);
  const stateTaxWithholding = watch(stateTaxWithholdingName);
  const grossUpFlag = watch(grossUpFlagName);

  const formatStateMinimumTax = (value: number) => parseFloat(value.toFixed(2).replace(/[.,]00/, ''));

  const stateMinimumTax = validateCashTransferResponse?.stateTaxMinPercentage
    ? formatStateMinimumTax(parseFloat(validateCashTransferResponse.stateTaxMinPercentage))
    : formatStateMinimumTax(content?.form_defaults?.state_tax_percent ?? 0);

  const federalTaxWithholdingOptions =
    content?.federal_tax_withholding_options?.map(item => ({ label: item?.label ?? '', value: item?.value ?? '' })) ??
    [];

  const stateTaxWithholdingOptions = getStateTaxWithholdingOptions({
    federalTaxWithholding,
    stateTaxWithholdingOptions: getDropdownItems(content?.state_tax_withholding_options ?? []),
    validateCashTransferResponse,
  });

  const handleFederalTaxWithholdingPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event?.target?.value);
    setValue(federalTaxWithholdingPercentageName, value, { shouldValidate: true, shouldDirty: true });
  };

  const handleStateTaxWithholdingPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event?.target?.value);
    setValue(stateTaxWithholdingPercentageName, value, { shouldValidate: true, shouldDirty: true });
  };

  const handleStateTaxWithholdingPercentageFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (parseFloat(event.target.value.toString()).toFixed(2) === stateMinimumTax.toFixed(2)) {
      event.target.select();
    }
  };

  useEffect(() => {
    trigger(federalTaxWithholdingPercentageName);
    trigger(stateTaxWithholdingPercentageName);
  }, [stateTaxPercent, federalTaxPercent, showMaximumAllowedAmountError, showOverdrawnWithdrawalError]);

  const restrictPercentageInput = ({ value }: NumberFormatValues): boolean => {
    return (
      (parseInt(value, 10) >= 1 &&
        parseInt(value, 10) < 100 &&
        Number.isInteger(parseFloat(value)) &&
        !value.includes('.')) ||
      value === ''
    );
  };

  const isRothIRAAccount: boolean =
    financialAccountType === FinancialAccountType.ROTH_IRA ||
    financialAccountType === FinancialAccountType.INHERITED_ROTH_IRA;

  const federalWithHoldingDefaultValue = isRothIRAAccount
    ? WITHHOLD_OPTIONS.DO_NOT_WITHHOLD
    : WITHHOLD_OPTIONS.WITHHOLD;

  const stateWithHoldingDefaultValue = isRothIRAAccount
    ? federalTaxWithholding === WITHHOLD_OPTIONS.WITHHOLD
      ? WITHHOLD_OPTIONS.WITHHOLD
      : WITHHOLD_OPTIONS.DO_NOT_WITHHOLD
    : [
        STATE_TAX_ELIGIBILITY_INDICATORS.Mandatory,
        STATE_TAX_ELIGIBILITY_INDICATORS.OptOut,
        STATE_TAX_ELIGIBILITY_INDICATORS.Voluntary,
      ].includes(validateCashTransferResponse?.stateTaxEligibilityCode as STATE_TAX_ELIGIBILITY_INDICATORS)
    ? validateCashTransferResponse?.stateWithholdingTaxMinimumIndicator
      ? WITHHOLD_OPTIONS.WITHHOLD_MINIMUM
      : WITHHOLD_OPTIONS.WITHHOLD
    : WITHHOLD_OPTIONS.DO_NOT_WITHHOLD;

  const defaultFederalTaxPercent = isRothIRAAccount ? 0 : content?.form_defaults?.federal_tax_percent ?? '';
  const defaultStateTaxPercent =
    stateWithHoldingDefaultValue === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD ? 0 : stateMinimumTax;

  const handleFederalTaxWithholdingChange = (event: DropdownChangeEvent) => {
    const value = event?.target?.value;
    setValue(federalTaxWithholdingName, value, { shouldValidate: true, shouldDirty: true });
    setValue(
      federalTaxWithholdingPercentageName,
      value === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD ? 0 : content?.form_defaults?.federal_tax_percent ?? '',
      { shouldValidate: true, shouldDirty: true },
    );
  };

  const handleStateTaxWithholdingChange = (event: DropdownChangeEvent) => {
    const value = event?.target?.value;
    setValue(stateTaxWithholdingName, value, { shouldValidate: true, shouldDirty: true });
    setValue(stateTaxWithholdingPercentageName, value === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD ? 0 : stateMinimumTax || 1, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (
      stateTaxWithholding === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD &&
      federalTaxWithholding === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD
    ) {
      setValue(grossUpFlagName, false, { shouldValidate: true, shouldDirty: true });
    }
    if (
      stateTaxWithholding === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD &&
      federalTaxWithholding === WITHHOLD_OPTIONS.WITHHOLD &&
      validateCashTransferResponse?.stateTaxEligibilityCode === STATE_TAX_ELIGIBILITY_INDICATORS.Mandatory
    ) {
      setValue(stateTaxWithholdingName, stateWithHoldingDefaultValue, { shouldValidate: true, shouldDirty: true });
      setValue(stateTaxWithholdingPercentageName, stateMinimumTax || 1, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [stateTaxWithholding, federalTaxWithholding]);

  const getTaxPercentErrorMessage = (type: string): ReactNode => {
    switch (type) {
      case TaxPercentageValidations.required:
        return (
          <ErrorMessage
            dataQa="tax-percentage-required"
            errorMessage={content?.error_messages?.tax_percentage_required ?? ''}
          />
        );
      case TaxPercentageValidations.belowMinimumTax:
        return (
          <ErrorMessage
            dataQa="below-minimum-tax"
            errorMessage={
              <RteContent config={{ stateMinimumTax }} data={content?.error_messages?.below_minimum_tax_error ?? ''} />
            }
          />
        );
      case TaxPercentageValidations.belowMinimumFederalTax:
        return (
          <ErrorMessage
            dataQa="below-minimum-federal-tax"
            errorMessage={content?.error_messages?.minimum_federal_tax_error}
          />
        );
      default:
        return null;
    }
  };

  const getTaxPercentageValidations = (taxPercent: number | undefined): Record<string, Validate> => ({
    [TaxPercentageValidations.greaterThanAllowed]: value =>
      parseFloat(value) + parseFloat(taxPercent ? taxPercent.toString() : '0') < 100,
    [TaxPercentageValidations.amountGreaterThanAllowed]: _ =>
      !(showOverdrawnWithdrawalError || showMaximumAllowedAmountError),
  });

  const stateTaxValidations = [
    TaxPercentageValidations.required,
    TaxPercentageValidations.belowMinimumTax,
    TaxPercentageValidations.greaterThanAllowed,
  ];
  const federalTaxValidations = [TaxPercentageValidations.required, TaxPercentageValidations.greaterThanAllowed];

  const getHelplinePhoneNumber = () => {
    return (
      <Typography color="primary.main" variant="caption">
        {content?.soft_blocks?.phone_number}
      </Typography>
    );
  };

  return (
    <Grid data-qa={dataQa} id="tax-withholding-form">
      <RteContent
        color="text.secondary"
        data={content?.disclaimers?.header_disclaimer ?? ''}
        dataQa={`${dataQa}-header-disclaimer`}
        sx={{ mb: 2 }}
      />
      {validateCashTransferResponse?.distributionCode === DISTRIBUTION_REASON_CODES.PREMATURE && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <RteContent data={content?.soft_blocks?.age_less_than_59_5 ?? ''} data-qa="premature-soft-block" />
        </Alert>
      )}
      <TaxWithholdingAllocationBar
        availableFunds={availableFunds}
        content={content?.allocation_bar_labels}
        coverTaxWithholding={grossUpFlag}
        federalTaxPercent={parseFloat(federalTaxPercent?.toString() ?? '')}
        formData={formData}
        isCloseAccount={isCloseAccount}
        maxAllowedAmount={maxAllowedAmount}
        setShowMaximumAllowedAmountError={setShowMaximumAllowedAmountError}
        setShowOverdrawnWithdrawalError={setShowOverdrawnWithdrawalError}
        setTotalWithdrawalAmount={setTotalWithdrawalAmount}
        stateTaxPercent={parseFloat(stateTaxPercent?.toString() ?? '')}
      />
      <RteContent
        color="text.secondary"
        data={content?.disclaimers?.bar_chart_disclaimer ?? ''}
        dataQa={`${dataQa}-bar-chart-disclaimer`}
        sx={{ mt: 2 }}
      />
      {!isMobile && <Divider sx={{ mt: 2, mb: 2.5 }} />}
      <FormControl fullWidth sx={{ display: 'flex' }}>
        <Grid container sx={{ my: 2, flexDirection: { xs: 'column', md: 'row' } }}>
          <TaxInputWrapper sx={isMobile ? { pb: 2.5, width: '100%' } : { pr: 2.5 }}>
            <Controller
              control={control}
              defaultValue={federalWithHoldingDefaultValue}
              name={federalTaxWithholdingName}
              onChange={handleFederalTaxWithholdingChange}
              render={({ value, name, onBlur }) => (
                <Dropdown
                  error={!!fieldsErrors.federalTaxWithholding}
                  id={federalTaxWithholdingName}
                  inputProps={{
                    sx: dropdownStyle,
                  }}
                  items={federalTaxWithholdingOptions}
                  label={content?.form_labels?.federal_tax_withholding}
                  name={name}
                  onBlur={onBlur}
                  onChange={handleFederalTaxWithholdingChange}
                  placeholder={content?.dropdown_placeholder ?? ''}
                  value={value}
                  width="100%"
                />
              )}
              rules={{
                required: true,
              }}
            />
            {!!fieldsErrors.federalTaxWithholding && (
              <ErrorMessage
                dataQa="federal-tax-wthholding-required"
                errorMessage={content?.error_messages?.federal_tax_withholding_required ?? ''}
              />
            )}
            <RteContent
              data={content?.form_labels?.federal_tax_withholding_percentage ?? ''}
              dataQa={`${dataQa}-federal-percentage`}
              sx={{ mt: 2 }}
            />
            <Controller
              control={control}
              defaultValue={defaultFederalTaxPercent}
              name={federalTaxWithholdingPercentageName}
              onChange={handleFederalTaxWithholdingPercentageChange}
              render={({ value, name }) => (
                <NumberFormat
                  customInput={TextField}
                  data-qa={name}
                  decimalScale={0}
                  disabled={federalTaxWithholding === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD}
                  error={!!fieldsErrors.federalTaxWithholdingPercentage}
                  fullWidth
                  isAllowed={values => restrictPercentageInput(values)}
                  name={name}
                  onChange={handleFederalTaxWithholdingPercentageChange}
                  suffix="%"
                  value={value}
                  variant="outlined"
                />
              )}
              rules={{
                required: true,
                validate: {
                  ...getTaxPercentageValidations(stateTaxPercent),
                  [TaxPercentageValidations.belowMinimumFederalTax]: value =>
                    federalTaxWithholding === WITHHOLD_OPTIONS.WITHHOLD
                      ? parseInt(value, 10) >= (content?.form_defaults?.federal_tax_percent ?? 0)
                      : true,
                },
              }}
            />
            {!!fieldsErrors.federalTaxWithholdingPercentage &&
              getTaxPercentErrorMessage(fieldsErrors.federalTaxWithholdingPercentage.type)}
          </TaxInputWrapper>
          <Divider flexItem orientation={isMobile ? 'horizontal' : 'vertical'} />
          <TaxInputWrapper sx={isMobile ? { pt: 2.5, width: '100%' } : { pl: 2.5 }}>
            <Controller
              control={control}
              defaultValue={stateWithHoldingDefaultValue}
              name={stateTaxWithholdingName}
              onChange={handleStateTaxWithholdingChange}
              render={({ value, name, onBlur }) => (
                <Dropdown
                  error={!!fieldsErrors.stateTaxWithholding}
                  id={stateTaxWithholdingName}
                  inputProps={{
                    sx: dropdownStyle,
                  }}
                  items={stateTaxWithholdingOptions}
                  label={content?.form_labels?.state_tax_withholding}
                  name={name}
                  onBlur={onBlur}
                  onChange={handleStateTaxWithholdingChange}
                  placeholder={content?.dropdown_placeholder ?? ''}
                  value={value}
                  width="100%"
                />
              )}
              rules={{
                required: true,
              }}
            />
            {!!fieldsErrors.stateTaxWithholding && (
              <ErrorMessage
                dataQa="state-tax-withholding-required"
                errorMessage={content?.error_messages?.state_tax_withholding_required ?? ''}
              />
            )}
            <RteContent
              data={content?.form_labels?.state_tax_withholding_percentage ?? ''}
              dataQa={`${dataQa}-state-percentage`}
              sx={{ mt: 2 }}
            />
            <Controller
              control={control}
              defaultValue={defaultStateTaxPercent}
              name={stateTaxWithholdingPercentageName}
              onChange={handleStateTaxWithholdingPercentageChange}
              render={({ value, name }) => (
                <NumberFormat
                  customInput={TextField}
                  data-qa={name}
                  decimalScale={2}
                  disabled={[WITHHOLD_OPTIONS.DO_NOT_WITHHOLD, WITHHOLD_OPTIONS.WITHHOLD_MINIMUM].includes(
                    stateTaxWithholding as WITHHOLD_OPTIONS,
                  )}
                  error={!!fieldsErrors.stateTaxWithholdingPercentage}
                  fullWidth
                  isAllowed={values => restrictPercentageInput(values)}
                  name={name}
                  onChange={handleStateTaxWithholdingPercentageChange}
                  onFocus={handleStateTaxWithholdingPercentageFocus}
                  onSelect={handleStateTaxWithholdingPercentageFocus}
                  suffix="%"
                  value={value}
                  variant="outlined"
                />
              )}
              rules={{
                required: true,
                validate: {
                  ...getTaxPercentageValidations(federalTaxPercent),
                  [TaxPercentageValidations.belowMinimumTax]: value =>
                    stateTaxWithholding === WITHHOLD_OPTIONS.WITHHOLD ? parseFloat(value) >= stateMinimumTax : true,
                },
              }}
            />
            {!!fieldsErrors.stateTaxWithholdingPercentage &&
              getTaxPercentErrorMessage(fieldsErrors.stateTaxWithholdingPercentage.type)}
            {federalTaxWithholding === WITHHOLD_OPTIONS.WITHHOLD &&
              validateCashTransferResponse?.stateTaxEligibilityCode === STATE_TAX_ELIGIBILITY_INDICATORS.Mandatory && (
                <Alert severity="info" sx={{ mt: 1 }}>
                  {content?.info_messages?.mandatory_state_tax}
                </Alert>
              )}
          </TaxInputWrapper>
        </Grid>
        {formData?.withdrawalFrequency === TransferFrequency.ONE_TIME && !isCloseAccount && (
          <Controller
            control={control}
            defaultValue={false}
            name={grossUpFlagName}
            render={({ value, name }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    data-qa="gross-up-flag"
                    disabled={
                      stateTaxWithholding === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD &&
                      federalTaxWithholding === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD
                    }
                    name={name}
                    onChange={e => {
                      setValue(grossUpFlagName, e.target.checked, { shouldValidate: false, shouldDirty: true });
                    }}
                  />
                }
                label={<RteContent data={content?.form_labels?.gross_up_flag ?? ''} />}
                sx={{ mt: 2 }}
              />
            )}
          />
        )}
        {parseInt(federalTaxPercent?.toString() ?? '', 10) + parseInt(stateTaxPercent?.toString() ?? '', 10) >= 100 && (
          <Alert severity="error" sx={{ mt: 1 }}>
            <ErrorMessage
              dataQa="sum-greater-than-allowed"
              errorMessage={content?.error_messages?.max_allowed_percent_error ?? ''}
            />
          </Alert>
        )}
        {(showOverdrawnWithdrawalError || showMaximumAllowedAmountError) &&
          // Show overdrawn or maximum allowed error when there are no other errors in form
          !stateTaxValidations.includes(fieldsErrors.stateTaxWithholdingPercentage?.type ?? '') &&
          !federalTaxValidations.includes(fieldsErrors.federalTaxWithholdingPercentage?.type ?? '') && (
            <Alert severity="error" sx={{ mb: 2, mt: 1 }}>
              <RteContent
                config={{
                  ...(showOverdrawnWithdrawalError
                    ? { availableFunds: formatCurrencyPrecise(availableFunds ?? 0) }
                    : { maxAllowedAmount: formatCurrencyPrecise(maxAllowedAmount ?? 0) }),
                }}
                data={
                  showOverdrawnWithdrawalError
                    ? content?.error_messages?.overdrawn_withdrawal_amount ?? ''
                    : content?.error_messages?.maximum_allowed_amount ?? ''
                }
                dataQa={`${
                  showOverdrawnWithdrawalError ? 'overdrawn-withdrawal-amount-error' : 'maximum-allowed-amount-error'
                }`}
              />
            </Alert>
          )}
        {(formData?.withdrawalFrequency !== TransferFrequency.ONE_TIME ||
          (clientAge && clientAge > (content?.soft_block_client_age?.old_age ?? DEFAULT_RETIREMENT_OLD_AGE))) && (
          <RteContent
            config={{ phoneNumber: getHelplinePhoneNumber() ?? '' }}
            data={content?.soft_blocks?.age_greater_than_72 ?? ''}
            data-qa="recurring-soft-block"
            sx={{ mt: 2 }}
          />
        )}
      </FormControl>
      <Divider sx={{ my: 2 }} />
      <RteContent color="text.secondary" data={content?.disclaimers?.footer_disclaimer ?? ''} />
    </Grid>
  );
};
