import { CustomBrokerageEntryContent, CustomBrokerageFundingSource, FundingFeatureFlags } from '../Funding/types';

import { OnboardingStates } from '~/__generated__';
import { Props as AccountTypeSelectionFormWrapperProps } from '~/components/Funding/AccountTypeSelection/AccountTypeSelectionFormWrapper';
import { BannerContent } from '~/components/Funding/Banner';
import { FundingFormContent } from '~/components/Funding/FundingSourceBase/types';
import { RecurringDepositWrapperProps } from '~/components/Funding/RecurringDeposit/RecurringDepositWrapper';
import { Props as ConfirmOverrideFundingDetailsProps } from '~/components/modals/ConfirmFundingDetailsOverride';
import { WrapperButtonProps as PricingSummaryModalButtonProps } from '~/components/modals/PricingSummary';
import { OnboardingWrapperOnNextArgs } from '~/components/OnboardingWrapper/types';
import { PlaidLinkType } from '~/hooks/plaid-link';
import { ContentOptions } from '~/utils/contentstack';

export interface AccordionContent {
  accountType: string;
  from: string;
  fromAnnuity?: string;
  fundLater: string;
  fundingSource: string;
  inProgress: string;
  recurringSource: string;
  recurringSourceDescription: {
    biMonthly: string;
    biWeekly: string;
    monthly: string;
    weekly: string;
  };
  skipped: string;
}

export interface PageContent {
  amountCrossedCustodianDailyLimit: string;
  back: string;
  completeTextSubtitle: string;
  completeTextTitle: string;
  heading: string;
  headingImage?: string;
  next: string;
  significantAmount: number;
  significantAmountWarning: string;
}
export interface Content {
  accordionContent: AccordionContent;
  accountTypeSelectionBaseContent: AccountTypeSelectionFormWrapperProps['formContent'];
  banner: { banner: BannerContent; index: number }[];
  fundingSourceBaseContent: FundingFormContent;
  modals: {
    saveFundingDetails: ConfirmOverrideFundingDetailsProps['content'];
  };
  pageContent: PageContent;
  recurringDepositBaseContent: RecurringDepositWrapperProps['formContent'];
}

export interface Props {
  allowManualBrokerageEntry?: boolean;
  brokerageAlias: string;
  contentOptions: ContentOptions;
  customBrokerageEntryContent?: CustomBrokerageEntryContent;
  customBrokerageFundingSource?: CustomBrokerageFundingSource;
  dataQa?: string;
  featureFlags?: FundingFeatureFlags;
  ignoreInsufficientFunds?: boolean;
  initiatingAdvisorPartyId?: string;
  isDigitalAdvice: boolean;
  managedProductId: string;
  onBack: (
    newState?: OnboardingStates,
    shouldUpdateState?: boolean,
    onboardingWrapperOnBackArgs?: OnboardingWrapperOnNextArgs,
  ) => void;
  onNext: (onboardingWrapperOnNextArgs: OnboardingWrapperOnNextArgs) => void;
  onSaveExit?: () => void;
  onboardingStatesOrder?: OnboardingStates[];
  partyId: string;
  plaidLinkageType?: PlaidLinkType;
  pricingSummaryModalProps?: PricingSummaryModalButtonProps['pricingSummaryModalProps'];
  useTaxStatusBasedPortfolioDetails?: boolean;
}

export enum InvestmentMethods {
  DCA = 'DCA',
  LUMP_SUM = 'LUMP_SUM',
}
