import React, { ComponentProps, useContext, useMemo, useState } from 'react';

import { cashTransferToAllowedStatusUpdates } from '../../mappers';
import { TaxWithholdingInfoContent, TransferItem, TransferStatus, TransferStatusFilter } from '../../types';
import { getAccountNumberOrAccountTypeString, isAccountNumberClickable, OpsDashboardUser } from '../../utils';
import { FlagsReferenceContent } from '../../WithdrawalsTab/contentstack';
import { Comment, CommentComponentColumn } from '../Comment';
import { FlagAction } from '../FlagAction';
import { AccountNumber } from '../ui/AccountNumber';
import { ClientName } from '../ui/ClientName';

import { FlagEntity, FlagReason, TransferFrequency, TransferType, UserNoteEntityType } from '~/__generated__';
import { NullStateProps } from '~/components/NullState';
import { StatusButton } from '~/components/StatusButton';
import { DISTRIBUTION_REASON_CODES } from '~/components/TaxWithholdingForm';
import {
  BasicTable,
  SortConfig,
  TableColumn,
  TableComponent as TableComponentType,
  TableData,
} from '~/components/ui/BasicTable';
import { ItemsPerPage } from '~/components/ui/BasicTable/ItemsPerPage';
import { TotalItems } from '~/components/ui/BasicTable/TotalItems';
import { CopyField } from '~/components/ui/CopyField';
import { Box, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { OpsDashboard } from '~/containers/OpsDashboard';
import OpsDashboardContext from '~/containers/OpsDashboardV2/OpsDashboardContext';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatPercentageString } from '~/utils/format';
import { precise } from '~/utils/format/currency';
import { formatDate, formatDateWithAddedMonths } from '~/utils/format/date';
import { PaginationContext } from '~/utils/types';

export interface Content {
  disclaimer?: string;
  endDate?: string;
  flags?: FlagsReferenceContent;
  nonRecurringtableHeaders?: TableColumn[];
  nonWrappableNonRecurring?: string[];
  nonWrappableRecurring: string[];
  partitionTabLabels?: { allOtherRequests: string; flaggedRequests: string };
  partitionedByFlaggedStatus?: boolean;
  recurringButton?: { nonRecurring: string; recurring: string };
  recurringtableHeaders: TableColumn[];
  rmdNote?: string;
  status: Record<TransferStatus, string>;
  statusFilter?: Record<TransferStatusFilter, string>;
  taxWithholdingInfo?: TaxWithholdingInfoContent;
  transferToAccountNote: (transferType: TransferType) => string;
  transferTypeButton?: { deposits: string; withdrawals: string };
}

export interface RecurringCashTransferTableProps {
  TableComponent?: TableComponentType;
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentPage: number;
  currentStatusFilter?: TransferStatusFilter;
  currentUser: OpsDashboardUser;
  displayStrings: Content;
  flagEntity?: FlagEntity;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  isRecurringCancellationsTab?: boolean;
  items: TransferItem[];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onChangeStatusFilter?: (newStatusFilter: TransferStatusFilter) => void;
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onPageChange: (p: number) => void;
  onSelectNewStatusForItem: (item: TransferItem, selectedStatus: TransferStatus) => void;
  onSort: (field: string) => () => void;
  paginationContext: PaginationContext;
  refetchData: () => void;
  sortConfig: SortConfig;
  totalPages: number;
}

export const RecurringSortableFields = ['amount', 'createdAt'];

export const RecurringCashTransferFlagReasons = [
  FlagReason.HIGH_TRANSACTION_VALUE,
  FlagReason.API_FAILURE,
  FlagReason.OVERDUE,
  FlagReason.POSSIBLE_DUPLICATE,
  FlagReason.OTHER,
];

export const RecurringCashTransferTable: React.FC<RecurringCashTransferTableProps> = ({
  contentOptions,
  currentPage,
  currentUser,
  currentStatusFilter,
  displayStrings,
  flagEntity,
  items,
  nullStateConfig,
  commentColumn,
  onChangeStatusFilter,
  onPageChange,
  onSort,
  onSelectNewStatusForItem,
  sortConfig,
  totalPages,
  TableComponent = BasicTable,
  onAccountClick,
  onClientClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  paginationContext,
  refetchData,
  isRecurringCancellationsTab,
}) => {
  const columns: TableColumn[] = useColumns(displayStrings, currentStatusFilter, onChangeStatusFilter, onSort);
  const [activeRowId, setActiveRowId] = useState<string | undefined>();

  const onRowHover = (id: string | undefined) => {
    setActiveRowId(id);
  };
  const formattedData: TableData[] = useFormattedData(
    items,
    currentUser,
    contentOptions,
    displayStrings,
    {
      onAccountClick,
      onClientClick,
      refetchData,
      getAccountNumberRedirectUrl,
      getClientNameRedirectUrl,
    },
    onSelectNewStatusForItem,
    flagEntity,
    commentColumn,
    isRecurringCancellationsTab,
    activeRowId,
  );
  return (
    <TableComponent
      BottomLeftSection={<TotalItems paginationContext={paginationContext} />}
      BottomRightSection={<ItemsPerPage paginationContext={paginationContext} />}
      alignItems="left"
      columns={columns}
      currentPage={currentPage}
      data={formattedData}
      enableRowHover
      nullStateConfig={nullStateConfig}
      onPageChange={onPageChange}
      onRowHover={onRowHover}
      showPagination
      sortConfig={sortConfig}
      totalPages={totalPages}
    />
  );
};

function getFrequencyString(frequency: TransferFrequency) {
  switch (frequency) {
    case TransferFrequency.BIMONTHLY:
      return 'Bimonthly';
    case TransferFrequency.BIWEEKLY:
      return 'Biweekly';
    case TransferFrequency.MONTHLY:
      return 'Monthly';
    case TransferFrequency.ONE_TIME:
      return 'One-time';
    case TransferFrequency.WEEKLY:
      return 'Weekly';
  }
}

function useFormattedData(
  items: TransferItem[],
  currentUser: OpsDashboardUser,
  contentOptions: ContentOptions,
  displayStrings: RecurringCashTransferTableProps['displayStrings'],
  callbacks: {
    getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
    getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
    onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
    onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
    refetchData: () => void;
  },
  onSelectNewStatusForItem: (item: TransferItem, selectedStatus: TransferStatus) => void,
  flagEntity?: FlagEntity,
  commentColumn?: CommentComponentColumn,
  isRecurringCancellationsTab?: boolean,
  activeRowId?: string | undefined,
): TableData[] {
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  const { opsContentData } = useContext(OpsDashboardContext);
  const opsDashboardContent = opsContentData?.all_ops_dashboard?.items?.[0];
  const copyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_ulid_label');
  const successCopyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_success_label');
  return useMemo(
    () =>
      items.map(item => ({
        ...item,
        rowKey: item.id,
        clientName: (
          <ClientName
            clientName={item.clientName}
            onClick={() => callbacks.onClientClick(item.partyId)}
            redirectUrl={callbacks.getClientNameRedirectUrl(item.partyId)}
          />
        ),
        accountNumber: (
          <AccountNumber
            accountNumber={item.accountNumber}
            accountNumberText={getAccountNumberOrAccountTypeString(item.accountNumber, item.accountTypeText)}
            label={showProductName ? item.productName : null}
            onClick={
              isAccountNumberClickable(item.accountState)
                ? () => callbacks.onAccountClick(item.partyId, item.managedProductId)
                : undefined
            }
            redirectUrl={
              isAccountNumberClickable(item.accountState)
                ? callbacks.getAccountNumberRedirectUrl(item.partyId, item.managedProductId)
                : undefined
            }
          />
        ),
        notes: (
          <Typography sx={{ color: 'text.secondary', textAlign: 'left' }} variant="body2">
            {isRecurringCancellationsTab ? (
              <RteContent
                config={{
                  cancellationDate: formatDate(item.createdAt, 'MM/dd/yyyy'),
                  accountNumber: item.destinationBankAccount,
                }}
                data={displayStrings.transferToAccountNote(item.transferType)}
              />
            ) : (
              item.notes || (
                <Stack>
                  <Typography sx={{ color: 'text.secondary', textAlign: 'left' }} variant="body2">
                    <Box>
                      {item.numberOfOccurrences && (
                        <Box sx={{ mb: 1 }}>
                          {displayStrings.endDate} :
                          {formatDateWithAddedMonths(
                            item.scheduledDate ?? '',
                            item.numberOfOccurrences ? item.numberOfOccurrences - 1 : 0,
                          )}
                        </Box>
                      )}
                      {displayStrings.transferToAccountNote(item.transferType)} {item.destinationBankAccount}
                    </Box>
                  </Typography>
                  {item.isManagedProductTaxSheltered && item.taxInformation && (
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="body2">
                        {`${displayStrings.taxWithholdingInfo?.federalTax}: ${formatPercentageString(
                          item.taxInformation.federalTax ?? '0',
                          { decimals: 0, locale: contentOptions.locale },
                        )}`}
                      </Typography>
                      <Typography variant="body2">
                        {`${displayStrings.taxWithholdingInfo?.stateTax}: ${formatPercentageString(
                          item.taxInformation.stateTax ?? '0',
                          { decimals: 0, locale: contentOptions.locale },
                        )}`}
                      </Typography>
                      {item.taxInformation.distributionReason && (
                        <Typography variant="body2">
                          {`${displayStrings.taxWithholdingInfo?.distributionReason}: ${
                            item.taxInformation.distributionReason === DISTRIBUTION_REASON_CODES.NORMAL
                              ? displayStrings.taxWithholdingInfo?.distributionReasonValues.normal
                              : displayStrings.taxWithholdingInfo?.distributionReasonValues.premature
                          }`}
                        </Typography>
                      )}
                      {item.taxInformation.grossUp !== undefined && (
                        <Typography variant="body2">
                          {`${displayStrings.taxWithholdingInfo?.grossUp}: ${
                            item.taxInformation.grossUp
                              ? displayStrings.taxWithholdingInfo?.grossUpValues.yes
                              : displayStrings.taxWithholdingInfo?.grossUpValues.no
                          }`}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Stack>
              )
            )}
          </Typography>
        ),
        amount: precise(item.amount),
        createdAt: formatDate(item.createdAt),
        nextOccurrence: item.scheduledDate ? formatDate(item.scheduledDate) : '-',
        frequency: getFrequencyString(item.frequency),
        status: (
          <Stack direction="row">
            <StatusButton
              currentStatus={item.status}
              onMenuItemClick={
                item.rmdPlanId ? undefined : newStatus => onSelectNewStatusForItem(item, newStatus as TransferStatus)
              }
              showDefaultButton
              statusLabels={displayStrings.status}
              statusUpdateItems={cashTransferToAllowedStatusUpdates(item.status, true)}
            />
            <Stack justifyContent="center" sx={{ width: '30px', px: 1, fontSize: 16 }}>
              {activeRowId === item.id && (
                <CopyField
                  copyLabel={copyLabel}
                  defaultState
                  disableToggling
                  successLabel={successCopyLabel}
                  textToCopy={item.id}
                />
              )}
            </Stack>
          </Stack>
        ),
        actions: (
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Comment
              comment={item.lastCommentData}
              content={commentColumn}
              contentOptions={contentOptions}
              currentUser={currentUser}
              entity={
                item.transferType === TransferType.DEPOSIT
                  ? UserNoteEntityType.RECURRING_CASH_DEPOSIT
                  : UserNoteEntityType.RECURRING_CASH_WITHDRAWAL
              }
              entityId={item.id}
              key={item.id}
              lastCommentPartyId={item.lastCommentPartyId}
              refetchData={() => callbacks.refetchData()}
            />
            {flagEntity && (
              <FlagAction
                accountId={item.accountNumber}
                accountTypeText={item.accountTypeText}
                clientName={item.clientName}
                contentOptions={contentOptions}
                entity={flagEntity}
                entityId={item.id}
                flagReasons={RecurringCashTransferFlagReasons}
                flags={item.flags}
                flagsContent={displayStrings.flags}
                key={`flags-${item.id}`}
                onCreate={() => callbacks.refetchData()}
                onResolve={() => callbacks.refetchData()}
              />
            )}
          </Box>
        ),
      })),
    [
      callbacks,
      commentColumn,
      contentOptions,
      currentUser,
      displayStrings,
      flagEntity,
      items,
      isRecurringCancellationsTab,
      onSelectNewStatusForItem,
      showProductName,
      activeRowId,
      successCopyLabel,
      copyLabel,
    ],
  );
}

function useColumns(
  displayStrings: RecurringCashTransferTableProps['displayStrings'],
  currentStatusFilter?: TransferStatusFilter,
  onChangeStatusFilter?: (newStatusFilter: TransferStatusFilter) => void,
  onSort?: (field: string) => () => void,
) {
  return useMemo(() => {
    return displayStrings.recurringtableHeaders.map(({ key, title }) => {
      return {
        key,
        title,
        wrappable: !displayStrings.nonWrappableRecurring.includes(key),
        onSort: onSort && RecurringSortableFields.includes(key) ? onSort : undefined,
      };
    });
  }, [currentStatusFilter, displayStrings, onChangeStatusFilter, onSort]);
}
