import { UpdateAssetDeposit, UpdateAssetDepositVariables } from './__generated__/mutation.v2';
import {
  AccountAssetTransfersRequest,
  AccountAssetTransfersRequest_assetDeposits_transfers_flags,
  AccountAssetTransfersRequestVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import * as mutations from '~/containers/OpsDashboard/ACATRequestsTab/symphony/mutation.gql';
import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ACATRequestFlags = AccountAssetTransfersRequest_assetDeposits_transfers_flags;

export const useAccountACATRequests = (
  options?: QueryHookOptions<AccountAssetTransfersRequest, AccountAssetTransfersRequestVariables>,
): QueryResult<AccountAssetTransfersRequest, AccountAssetTransfersRequestVariables> => {
  return useSymphonyQuery(queries.AccountAssetTransfersRequest, options);
};

export const useUpdateAssetDeposit = (
  options?: MutationHookOptions<UpdateAssetDeposit, UpdateAssetDepositVariables>,
): MutationTuple<UpdateAssetDeposit, UpdateAssetDepositVariables> => {
  return useSymphonyMutation(mutations.UpdateAssetDeposit, options);
};
