import { ComponentProps } from 'react';

import { CashTransfersV2_multiCashTransfers_filterContext } from '../../common/CashTransfer/symphony/__generated__/query.v2';
import { TransferStatus } from '../../types';
import { getColumnKeyValuePair } from '../../utils';
import {
  GetRecurringCancellationsTableContentV2,
  GetRecurringCancellationsTableContentV2_all_recurring_cancellations_table_items,
  GetRecurringCancellationsTableContentV2_all_recurring_cancellations_table_items_statuses,
} from '../contentstack/__generated__/query.v2';

import { MultiCashTransferType, TransferType } from '~/__generated__';
import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { FilterConfig, FilterTypes, SelectedFilters } from '~/components/Filters/types';
import { TableColumn } from '~/components/ui/BasicTable';
import { findFieldValue } from '~/utils/contentstack';
import { ContentstackTableColumn } from '~/utils/table';

export type RecurringCancellationsDisplayStrings = {
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  disclaimer: string;
  endDate?: string;
  filtersContent: ComponentProps<typeof Filters>['content'];
  flags?: undefined;
  nonWrappableRecurring: string[];
  recurringCancellationsFilterConfig: FilterConfig[];
  recurringtableHeaders: TableColumn[];
  status: Record<TransferStatus, string>;
  transferToAccountNote: (type: TransferType) => string;
  transferTypeButton: { deposits: string; withdrawals: string };
};

export enum RecurringCancellationsFilters {
  STATUS = 'STATUS',
  TRANSFER_TYPE = 'TRANSFER_TYPE',
}

const recurringCancellationsFilterConfig = [
  {
    key: RecurringCancellationsFilters.TRANSFER_TYPE,
    options: [
      {
        id: TransferType.DEPOSIT,
      },
      {
        id: TransferType.WITHDRAWAL,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
];

export const defaultAppliedFilters: SelectedFilters = {
  [RecurringCancellationsFilters.TRANSFER_TYPE]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [TransferType.DEPOSIT, TransferType.WITHDRAWAL],
  },
};

export const generateStaticDisplayStrings = (
  content: GetRecurringCancellationsTableContentV2,
): RecurringCancellationsDisplayStrings => {
  const items = content.all_recurring_cancellations_table?.items?.[0];

  const columns = getColumnKeyValuePair(items?.columns?.column as ContentstackTableColumn[], []);
  const textFields = items?.fields?.text ?? [];

  return {
    disclaimer: items?.disclaimer || '',
    transferTypeButton: { withdrawals: 'Withdrawals', deposits: 'Deposits' },
    recurringtableHeaders: columns,
    status: {
      [TransferStatus.IN_PROGRESS]: getStatusLabel(TransferStatus.IN_PROGRESS, items),
      [TransferStatus.CANCELLED]: getStatusLabel(TransferStatus.CANCELLED, items),
      [TransferStatus.COMPLETED]: getStatusLabel(TransferStatus.COMPLETED, items),
      [TransferStatus.PENDING]: getStatusLabel(TransferStatus.PENDING, items),
      [TransferStatus.UNRECOGNIZED]: getStatusLabel(TransferStatus.UNRECOGNIZED, items),
      [TransferStatus.PENDING_CANCELLATION]: getStatusLabel(TransferStatus.PENDING_CANCELLATION, items),
    },
    transferToAccountNote: (type: TransferType) => getInstructionsString(items, type),
    nonWrappableRecurring: items?.columns?.non_wrappable?.flatMap(f => (f ? [f] : [])) ?? [],
    recurringCancellationsFilterConfig: recurringCancellationsFilterConfig.map(filter => {
      return {
        ...filter,
        label: findFieldValue(textFields, `${filter.key.toLowerCase()}_filter`),
        options: filter.options.map(option => {
          return {
            ...option,
            label: findFieldValue(textFields, `${option.id.toLowerCase()}`),
          };
        }),
      };
    }),
    appliedFiltersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      appliedFilters: findFieldValue(textFields, 'applied_filters'),
    },
    filtersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      apply: findFieldValue(textFields, 'apply_cta'),
      filters: findFieldValue(textFields, 'filters_cta'),
      resetAll: findFieldValue(textFields, 'reset_all_cta'),
    },
  };
};

const getStatusLabel = (
  status: TransferStatus,
  items?: GetRecurringCancellationsTableContentV2_all_recurring_cancellations_table_items | null | undefined,
): string => {
  const key = status.toLowerCase() as keyof GetRecurringCancellationsTableContentV2_all_recurring_cancellations_table_items_statuses;
  return items?.statuses?.[key] ?? '';
};

function getInstructionsString(
  items?: GetRecurringCancellationsTableContentV2_all_recurring_cancellations_table_items | null | undefined,
  transferType?: TransferType,
): string {
  switch (transferType) {
    case TransferType.DEPOSIT:
      return items?.deposit_cancellation_instructions ?? '';
    case TransferType.WITHDRAWAL:
      return items?.withdrawal_cancellation_instructions ?? '';
    default:
      return '';
  }
}

export const getFilterCountMap = (filterContexts: CashTransfersV2_multiCashTransfers_filterContext[]) => {
  const CancellationStatuses = ['PENDING_CANCELLATION'];
  const transferType = [MultiCashTransferType.RECURRING_CASH_WITHDRAWAL, MultiCashTransferType.RECURRING_CASH_DEPOSIT];
  return filterContexts
    .filter(v => CancellationStatuses.includes(`${v.filtersKey.status}`))
    .filter(v => !!v.filtersKey.multiCashTransferType && transferType.includes(v.filtersKey.multiCashTransferType))
    .map(v => {
      return {
        count: v.count,
        filters: {
          [RecurringCancellationsFilters.TRANSFER_TYPE]:
            v.filtersKey.multiCashTransferType === MultiCashTransferType.RECURRING_CASH_DEPOSIT
              ? TransferType.DEPOSIT
              : TransferType.WITHDRAWAL,
        },
      };
    });
};
