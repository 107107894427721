import { isBefore, parseISO } from 'date-fns';
import React, { ComponentProps, useEffect, useState } from 'react';

import { ContentstackTransfersTable, TransfersTableType, useLazyGetViewTransfersContent } from './contentstack';
import { CashTransfer, isCashTransfer, isManagedProduct, useCancelTransfer, useLazyGetTransfers } from './symphony';

import {
  CashTransferInstanceStatus,
  FinancialAccountType,
  ScheduledTransferStatus,
  TransferFrequency,
} from '~/__generated__/symphonyTypes.v2';
import { Content as TransfersTableContent, TransfersTable } from '~/components/TransfersTable';
import { Link } from '~/components/ui/Link';
import { Modal } from '~/components/ui/Modal';
import { ArrowBackIcon, ArrowForwardIcon, Box, Button, Pagination } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { isRetirementAccountType } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  accountId: string;
  accountNumberFormat: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  defaultLoadingState?: boolean;
  managedProductId: string;
  onAddFundsClick?: () => void;
  onWithdrawalCancelled?: () => void;
  partyId: string;
};

export const getTableContent = (
  accountNumberFormat: string,
  node?: ContentstackTransfersTable,
  financialAccountType?: FinancialAccountType | null,
): TransfersTableContent => {
  const cancelRow = node?.cancel_row_valueConnection?.edges?.[0]?.node;
  let columns = node?.columns;
  if (financialAccountType && !isRetirementAccountType(financialAccountType)) {
    columns = columns?.filter(col => col?.config?.data_type !== 'Contribution Year');
  }

  return {
    columns: columns?.map(col => ({
      data: (col?.config?.data_type || 'UNKNOWN') as TransfersTableContent['columns'][number]['data'],
      header: col?.config?.header || 'MISSING COLUMN HEADER',
      showTooltip: col?.config?.show_tooltip || false,
      tooltip: col?.config?.tooltip || '',
    })) ?? [
      {
        data: 'UNKNOWN',
        header: 'MISSING COLUMN HEADER',
        showTooltip: false,
        tooltip: '',
      },
    ],
    accountNumberFormat,
    action: {
      cancel: node?.action?.cancel ?? undefined,
      pending: node?.action?.pending ?? undefined,
      pendingCancel: node?.action?.pending_cancel ?? undefined,
      inProgress: node?.action?.in_progress ?? undefined,
    },
    amount: {
      format: (node?.amount?.format?.type as TransfersTableContent['amount']['format']) ?? undefined,
    },
    date: {
      format: node?.date?.formatConnection?.edges?.[0]?.node?.value ?? undefined,
    },
    description: {
      oneTime: {
        deposit: node?.description?.one_time?.deposit ?? undefined,
        withdrawal: node?.description?.one_time?.withdrawal ?? undefined,
        rmd: node?.description?.one_time?.rmd ?? undefined,
      },
      weekly: {
        deposit: node?.description?.weekly?.deposit ?? undefined,
        withdrawal: node?.description?.weekly?.withdrawal ?? undefined,
      },
      monthly: {
        deposit: node?.description?.monthly?.deposit ?? undefined,
        withdrawal: node?.description?.monthly?.withdrawal ?? undefined,
      },
    },
    frequency: {
      monthly: node?.frequency?.monthly ?? undefined,
      weekly: node?.frequency?.weekly ?? undefined,
      oneTime: node?.frequency?.one_time ?? undefined,
    },
    status: {
      cancelled: node?.status?.cancelled ?? undefined,
      completed: node?.status?.completed ?? undefined,
      in_progress: node?.status?.in_progress ?? undefined,
      pending: node?.status?.pending ?? undefined,
    },
    cancelRow: {
      bodyDeposit: cancelRow?.body_deposit || 'MISSING DEPOSIT CANCEL ROW BODY',
      bodyWithdrawal: cancelRow?.body_withdrawal || 'MISSING WITHDRAWAL CANCEL ROW BODY',
      errorMessage: cancelRow?.error_message || 'MISSING CANCEL ROW ERROR MESSAGE',
      ctas: {
        cancel: cancelRow?.ctas?.cancel || 'MISSING CANCEL ROW CANCEL CTA',
        confirm: cancelRow?.ctas?.confirm || 'MISSING CANCEL ROW CONFIRM CTA',
      },
    },
  };
};

const sortTransfers = (transfers: CashTransfer[]): CashTransfer[] => {
  return transfers.sort((a, b) => (parseISO(a.scheduledAt ?? '') > parseISO(b.scheduledAt ?? '') ? 1 : -1));
};

const toScheduledTransferStatus = (status: CashTransferInstanceStatus): ScheduledTransferStatus => {
  switch (status) {
    case CashTransferInstanceStatus.COMPLETED:
      return ScheduledTransferStatus.COMPLETED;
    case CashTransferInstanceStatus.CASH_GENERATED:
      return ScheduledTransferStatus.CASH_GENERATED;
    case CashTransferInstanceStatus.PENDING:
      return ScheduledTransferStatus.PENDING;
    case CashTransferInstanceStatus.TRADES_SUBMITTED:
      return ScheduledTransferStatus.TRADES_SUBMITTED;
    default:
      return ScheduledTransferStatus.CANCELLED;
  }
};

const retrievePastTransferInstances = (transfers: CashTransfer[]): CashTransfer[] => {
  const pastTransfersInstances: CashTransfer[] = [];
  transfers.forEach(transfer => {
    if (!transfer.isCancellable && !transfer.instances?.length) {
      pastTransfersInstances.push(transfer);
    }
    if (transfer.instances) {
      transfer.instances.forEach(instance => {
        if (isBefore(new Date(instance.scheduledAt), new Date()) && instance.scheduledAt !== transfer.scheduledAt) {
          pastTransfersInstances.push({
            ...transfer,
            instances: [],
            isCancellable: false,
            scheduledAt: instance.scheduledAt,
            status: toScheduledTransferStatus(instance.status),
          });
        }
      });
    }
  });
  return pastTransfersInstances;
};

export const ViewTransfersModal: React.FC<Props> = ({
  accountNumberFormat,
  dataQa = 'view-transfers-modal',
  partyId,
  accountId,
  managedProductId,
  onAddFundsClick,
  onWithdrawalCancelled,
  contentOptions,
  maxWidth = 'md',
  defaultLoadingState = true,
  open,
  ...modalProps
}) => {
  const isMobile = useIsMediumScreen();
  const { showPastTransfers } = useCoreConfig().components.sfViewTransfers;
  // TODO DA2-512: Log error to sentry
  const [getContent, { data: contentstackData, loading: contentstackLoading }] = useLazyGetViewTransfersContent({
    variables: contentOptions,
  });
  useEffect(() => {
    if (open && !contentstackData) {
      getContent();
    }
  }, [contentstackData, getContent, open]);

  // TODO DA2-512: Log error to sentry
  const [getTransfers, { data: transfersData, loading: transfersLoading }] = useLazyGetTransfers({
    variables: { partyId },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (open) {
      getTransfers();
    } else {
      setUpcomingTransfersActivePage(1);
      setPastTransfersActivePage(1);
    }
  }, [getTransfers, open]);
  const [cancelTransfer] = useCancelTransfer();

  const [loading, setLoading] = useState(defaultLoadingState);
  const [upcomingTransfersActivePage, setUpcomingTransfersActivePage] = useState(1);
  const [pastTransfersActivePage, setPastTransfersActivePage] = useState(1);
  const [cancelTransferStatusMap, setCancelTransferStatusMap] = useState<{ [key: string]: ScheduledTransferStatus }>(
    {},
  );
  useEffect(() => {
    setLoading(contentstackLoading || transfersLoading);
  }, [contentstackLoading, transfersLoading]);

  const content = contentstackData?.all_view_transfers_modal?.items?.[0];
  const financialAccount = transfersData?.client?.financialAccounts?.filter(account => account.id === accountId)[0];
  const managedProduct = financialAccount?.products
    ?.filter(isManagedProduct)
    .filter(p => p.id === managedProductId)?.[0];
  const cashTransfers =
    managedProduct?.fundingDetails.scheduledTransfers.filter(isCashTransfer).map(t => t as CashTransfer) ?? [];
  const upcomingTransfers = cashTransfers.filter(transfer => transfer.isCancellable);
  const pastTransfers = showPastTransfers
    ? cashTransfers.filter(
        (transfer: CashTransfer) => !transfer.isCancellable || transfer.frequency !== TransferFrequency.ONE_TIME,
      )
    : [];
  const sortedUpcomingTransfers = sortTransfers(upcomingTransfers);
  const sortedPastTransfers = sortTransfers(retrievePastTransferInstances(pastTransfers));

  const setCancelTransferStatus = (rowId: string, cancelTransferStatus?: ScheduledTransferStatus) => {
    setCancelTransferStatusMap(prevCancelTransferStatusMap => {
      if (cancelTransferStatus) {
        prevCancelTransferStatusMap[rowId] = cancelTransferStatus;
      }
      return prevCancelTransferStatusMap;
    });
  };

  const onBack = (transfersTableType?: string | null) => {
    if (transfersTableType === TransfersTableType.Upcoming) {
      setUpcomingTransfersActivePage(upcomingTransfersActivePage - 1);
    } else {
      setPastTransfersActivePage(pastTransfersActivePage - 1);
    }
  };

  const onNext = (transfersTableType?: string | null) => {
    if (transfersTableType === TransfersTableType.Upcoming) {
      setUpcomingTransfersActivePage(upcomingTransfersActivePage + 1);
    } else {
      setPastTransfersActivePage(pastTransfersActivePage + 1);
    }
  };

  const onPageChange = (transfersTableType: TransfersTableType, page: number) => {
    if (transfersTableType === TransfersTableType.Upcoming) {
      setUpcomingTransfersActivePage(page);
    } else {
      setPastTransfersActivePage(page);
    }
  };

  return (
    <Modal
      {...modalProps}
      actions={
        <Button onClick={modalProps.onClose as () => void} variant="contained">
          {content?.ctas?.close || 'MISSING VIEW TRANSFERS CLOSE CTA'}
        </Button>
      }
      content={
        <>
          {content?.body
            ?.filter(item => showPastTransfers || item?.table?.data_type !== TransfersTableType.Past)
            .map((item, index) => {
              const table = item?.table;
              const emptyState = table?.empty_state;
              const tableContent = getTableContent(
                accountNumberFormat,
                table?.bodyConnection?.edges?.[0]?.node ?? undefined,
                managedProduct?.accountType,
              );
              const rows =
                table?.data_type === TransfersTableType.Upcoming ? sortedUpcomingTransfers : sortedPastTransfers;
              const activePage =
                table?.data_type === TransfersTableType.Upcoming
                  ? upcomingTransfersActivePage
                  : pastTransfersActivePage;
              const numberOfItemsPerPage = table?.data_type === TransfersTableType.Upcoming ? 5 : 3;
              const totalPages = Math.ceil(rows.length / numberOfItemsPerPage);
              const currentPageRows = rows.slice(
                (activePage - 1) * numberOfItemsPerPage,
                activePage * numberOfItemsPerPage,
              );

              return (
                <Box key={index} sx={{ ':not(:last-of-type)': { mb: 4 } }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography component="h3" sx={{ flex: 1 }} variant="subtitle2">
                      {table?.title}
                    </Typography>
                    {totalPages > 1 && !isMobile ? (
                      <>
                        <Button
                          data-qa={`${dataQa}-${table?.data_type}-back-btn`}
                          disabled={activePage === 1}
                          onClick={() => onBack(table?.data_type)}
                          startIcon={<ArrowBackIcon fontSize="small" />}
                          sx={{ color: 'primary.main', mr: 1 }}
                        >
                          {content.ctas?.back || 'Back'}
                        </Button>
                        <Typography sx={{ pt: 1 }}>
                          {activePage}/{totalPages}
                        </Typography>
                        <Button
                          data-qa={`${dataQa}-${table?.data_type}-next-btn`}
                          disabled={activePage === totalPages}
                          endIcon={<ArrowForwardIcon fontSize="small" />}
                          onClick={() => onNext(table?.data_type)}
                          sx={{ color: 'primary.main', ml: 1 }}
                        >
                          {content.ctas?.next || 'Next'}
                        </Button>
                      </>
                    ) : null}
                  </Box>
                  {currentPageRows.length > 0 ? (
                    <TransfersTable
                      cancelTransferStatusMap={cancelTransferStatusMap}
                      content={tableContent}
                      contentOptions={contentOptions}
                      managedProductId={managedProductId}
                      onConfirmCancel={cancelTransfer}
                      onWithdrawalCancelled={onWithdrawalCancelled}
                      rows={currentPageRows}
                      setCancelTransferStatus={setCancelTransferStatus}
                    />
                  ) : (
                    <Box data-qa={`${dataQa}-empty-state`} sx={{ textAlign: 'center' }}>
                      <RteContent
                        config={{
                          addFundsCta:
                            emptyState?.add_funds_cta && onAddFundsClick ? (
                              <Link component="button" data-qa={`${dataQa}-add-funds-button`} onClick={onAddFundsClick}>
                                {emptyState.add_funds_cta}
                              </Link>
                            ) : (
                              ''
                            ),
                        }}
                        data={emptyState?.body ?? 'MISSING EMPTY STATE BODY'}
                      />
                    </Box>
                  )}
                  {isMobile && table && (
                    <Box mt={4}>
                      <Pagination
                        count={totalPages}
                        onChange={(_event, page) => onPageChange(table.data_type as TransfersTableType, page)}
                        page={activePage}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
        </>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      loading={loading}
      maxWidth={maxWidth}
      open={open}
      title={
        <RteContent
          component="span"
          config={{
            accountNumberPrefix: content?.heading?.account_number_prefix ?? '',
            maskedAccountNumber: financialAccount?.maskedAccountNumber ?? '',
          }}
          data={content?.heading?.body ?? ''}
        />
      }
    />
  );
};
