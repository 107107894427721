import { ComponentProps } from 'react';

import { UrgencyFilter } from '../../types';
import { getColumnKeyValuePair } from '../../utils';
import { ClosuresFlagReferenceContent } from '../contentstack';
import {
  GetClosureTableContentV2,
  GetClosureTableContentV2_all_closures_table_items,
} from '../contentstack/__generated__/query.v2';
import { toClosureStatus } from '../hooks/mappers';
import { AccountClosuresV2_closeWorkflows_filterContext } from '../symphony/__generated__/query.v2';

import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { FilterTypes } from '~/components/Filters/types';
import { TableColumn } from '~/components/ui/BasicTable';
import {
  ClosureStatus,
  ClosureStatusFilter,
  ClosureType,
  RequestTabs,
} from '~/containers/OpsDashboardV2/ClosuresTab/types';
import { findFieldValue } from '~/utils/contentstack';
import { ContentstackTableColumn } from '~/utils/table';

export type ClosureDisplayStrings = {
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  bankVerificationFailedNote: string;
  closureTypes: Record<ClosureType, string>;
  closuresFilterConfig: ComponentProps<typeof Filters>['filters'];
  filtersContent: ComponentProps<typeof Filters>['content'];
  flags: ClosuresFlagReferenceContent;
  partitionLabels: Record<RequestTabs, string>;
  status: Record<ClosureStatus, string>;
  statusFilter: Record<ClosureStatusFilter, string>;
  tableHeaders: TableColumn[];
  transferToAccountNote: string;
};

export const generateClosureStaticDisplayStrings = (content: GetClosureTableContentV2): ClosureDisplayStrings => {
  const items = content.all_closures_table?.items?.[0];
  const textFields = items?.fields?.text ?? [];
  const flagsContent = items?.flag_referenceConnection?.edges?.[0]?.node ?? ({} as ClosuresFlagReferenceContent);
  return {
    flags: flagsContent,
    status: {
      [ClosureStatus.IN_PROGRESS]: items?.statuses?.in_progress ?? '',
      [ClosureStatus.COMPLETED]: items?.statuses?.completed ?? '',
      [ClosureStatus.CANCELLED]: items?.statuses?.cancelled ?? '',
      [ClosureStatus.PENDING]: items?.statuses?.pending ?? '',
      [ClosureStatus.UNRECOGNIZED]: items?.statuses?.unrecognized_status ?? '',
    },
    statusFilter: {
      [ClosureStatusFilter.ALL]: items?.status_filters?.all ?? '',
      [ClosureStatusFilter.PENDING]: items?.status_filters?.pending ?? '',
      [ClosureStatusFilter.IN_PROGRESS]: items?.status_filters?.in_progress ?? '',
      [ClosureStatusFilter.COMPLETED]: items?.status_filters?.completed ?? '',
      [ClosureStatusFilter.CANCELLED]: items?.status_filters?.cancelled ?? '',
    },
    partitionLabels: getPartitionLabels(items),
    tableHeaders: getColumnKeyValuePair(items?.columns?.column as ContentstackTableColumn[], []),
    transferToAccountNote: items?.transfer_to_account_note ?? '',
    bankVerificationFailedNote: findFieldValue(items?.fields?.text ?? [], 'BankVerificationFailedNote'),
    closureTypes: {
      [ClosureType.END_MANAGEMENT]: items?.closure_types?.end_management ?? '',
      [ClosureType.LIQUIDATION]: items?.closure_types?.liquidation ?? '',
    },
    closuresFilterConfig: closureFilterConfig.map(filter => {
      return {
        ...filter,
        label: findFieldValue(textFields, `${filter.key.toLowerCase()}_filter`),
        options: filter.options.map(option => {
          return {
            ...option,
            label: findFieldValue(textFields, `${option.id.toLowerCase()}`),
          };
        }),
      };
    }),
    appliedFiltersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      appliedFilters: findFieldValue(textFields, 'applied_filters'),
    },
    filtersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      apply: findFieldValue(textFields, 'apply_cta'),
      filters: findFieldValue(textFields, 'filters_cta'),
      resetAll: findFieldValue(textFields, 'reset_all_cta'),
    },
  };
};

const getPartitionLabels = (
  item: GetClosureTableContentV2_all_closures_table_items | null | undefined,
): Record<RequestTabs, string> => {
  const partitionTabsContent = item?.partition_tabs ?? [];
  return {
    [RequestTabs.FlaggedRequests]:
      partitionTabsContent.find(tab => tab?.value === RequestTabs.FlaggedRequests)?.label ?? '',
    [RequestTabs.AllOtherRequests]:
      partitionTabsContent.find(tab => tab?.value === RequestTabs.AllOtherRequests)?.label ?? '',
  };
};

export enum ClosureFilters {
  STATUS = 'STATUS',
  URGENCY = 'URGENCY',
}

const closureFilterConfig = [
  {
    key: ClosureFilters.STATUS,
    options: [
      {
        id: ClosureStatusFilter.PENDING,
      },
      {
        id: ClosureStatusFilter.IN_PROGRESS,
      },
      {
        id: ClosureStatusFilter.CANCELLED,
      },
      {
        id: ClosureStatusFilter.COMPLETED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
  {
    key: ClosureFilters.URGENCY,
    options: [
      {
        id: UrgencyFilter.ESCALATED,
      },
      {
        id: UrgencyFilter.NOT_ESCALATED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
];

export const closureDefaultAppliedFilters: ComponentProps<typeof Filters>['defaultAppliedFilters'] = {
  [ClosureFilters.STATUS]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      ClosureStatusFilter.PENDING,
      ClosureStatusFilter.IN_PROGRESS,
      ClosureStatusFilter.CANCELLED,
      ClosureStatusFilter.COMPLETED,
    ],
  },
  [ClosureFilters.URGENCY]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [UrgencyFilter.ESCALATED, UrgencyFilter.NOT_ESCALATED],
  },
};

export const getFilterCountMap = (filterContext: AccountClosuresV2_closeWorkflows_filterContext[]) => {
  return filterContext.map(v => {
    return {
      count: v.count,
      filters: {
        [ClosureFilters.STATUS]: v.filtersKey.status ? toClosureStatus(v.filtersKey.status) : '',
        [ClosureFilters.URGENCY]: v.filtersKey.escalated ? UrgencyFilter.ESCALATED : UrgencyFilter.NOT_ESCALATED,
      },
    };
  });
};
