import { GetClientDocumentsModalContent, GetClientDocumentsModalContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetClientDocumentsModalContent = (
  options?: QueryHookOptions<GetClientDocumentsModalContent, GetClientDocumentsModalContentVariables>,
): QueryResult<GetClientDocumentsModalContent, GetClientDocumentsModalContentVariables> => {
  return useContentstackQuery(queries.GetClientDocumentsModalContent, options);
};
