import * as React from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import { alpha, Grid, Paper, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { sortBySpecifiedOrder } from '~/utils/asset-allocation';
import { getTextProps, SfVariant } from '~/utils/theme';

export interface DonutSlice<T = Record<string, any>> {
  color: string;
  key: string;
  label: string;
  /**
   * Extra data not directly related to rendering a donut slice. Can be used to render extra data
   * in a tooltip, for example.
   */
  meta?: T;
  opacity?: string;
  order?: number | null;
  value: number;
}

export interface DonutSliceMeta {
  actualLabel: string;
  actualPercent: number;
  targetLabel: string;
  targetPercent: number;
}

export interface Props {
  centerSubtext?: string;
  centerSubtextVariant?: SfVariant;
  centerText?: string;
  centerTextVariant?: SfVariant;
  data: DonutSlice[];
  dataQa?: string;
  innerRadius?: number;
  justifyContentGraph?: string;
  label?: string;
  showTooltip?: boolean;
  size?: number;
  tooltipContent?: React.ComponentProps<typeof Tooltip>['content'];
}

export const DonutTooltipWrapper: React.FC<React.ComponentProps<typeof Paper>> = ({ children, sx }) => {
  return <Paper sx={{ py: 2, px: 3, ...sx }}>{children}</Paper>;
};

const CustomTooltip: React.ComponentProps<typeof Tooltip>['content'] = ({ active, payload }) => {
  if (active) {
    const name = payload?.[0]?.name;
    const value = payload?.[0]?.value;
    return (
      <DonutTooltipWrapper>
        <Typography component="span">
          {name}: {value}
        </Typography>
      </DonutTooltipWrapper>
    );
  }
  return null;
};

// TODO: Figure out a way to get the center labels as an array, and the positioning to be determined by a math formula
export const Donut: React.FC<Props> = ({
  dataQa = 'donut',
  centerText,
  centerTextVariant = 'body1',
  centerSubtext,
  centerSubtextVariant = 'body1',
  data,
  innerRadius = 50,
  label,
  size = 200,
  showTooltip = false,
  tooltipContent = CustomTooltip,
  justifyContentGraph = 'flex-start',
}) => {
  const theme = useTheme();
  // If the subtext is longer than 12 characters and has /, break it into 2 lines. eg: Equity/Fixed Income
  // This is being done for use cases where we want Fixed Income to be displayed instead of FI inside the donut
  const isMultilineCenterSubtext = centerSubtext && centerSubtext.includes('/') && centerSubtext.length > 12;
  const centerSubtextSplit = centerSubtext && isMultilineCenterSubtext ? centerSubtext.split('/') : [];
  // Need to reverse, as the PieChart does not allow for setting the direction of display
  const sortedData = sortBySpecifiedOrder(data).reverse();
  return (
    <Grid alignItems="center" container flexDirection="column" justifyContent={justifyContentGraph}>
      <Grid aria-hidden={!centerText} data-qa={dataQa} item>
        <PieChart height={size} width={size}>
          <Pie
            data={sortedData}
            dataKey="value"
            endAngle={450}
            innerRadius={innerRadius}
            nameKey="label"
            startAngle={90}
          >
            {sortedData.map((slice, index) => (
              <Cell
                fill={alpha(slice.color, slice.opacity ? parseInt(slice.opacity, 10) / 100 : 1)}
                key={`cell-${index}`}
              />
            ))}
          </Pie>
          {/* TODO: How to handle long center text and subText? */}
          {centerText && (
            <text
              data-qa={`${dataQa}-centerText`}
              dy={8}
              textAnchor="middle"
              x={size / 2}
              y={centerSubtext ? (size * (isMultilineCenterSubtext ? 0.8 : 0.9)) / 2 : size / 2}
              {...getTextProps(theme, centerTextVariant)}
            >
              {centerText}
            </text>
          )}
          {centerSubtext && (
            <text
              data-qa={`${dataQa}-centerSubtext`}
              dy={8}
              textAnchor="middle"
              x={size / 2}
              y={
                centerText
                  ? (size * (isMultilineCenterSubtext ? 1 : 1.1)) / 2
                  : (size * (isMultilineCenterSubtext ? 0.9 : 1)) / 2
              }
              {...getTextProps(theme, centerSubtextVariant)}
            >
              {isMultilineCenterSubtext ? `${centerSubtextSplit[0].trim()}/` : centerSubtext}
            </text>
          )}
          {isMultilineCenterSubtext && (
            <text
              data-qa={`${dataQa}-centerSubtext`}
              dy={8}
              textAnchor="middle"
              x={size / 2}
              y={(size * (centerText ? 1.15 : 1.1)) / 2}
              {...getTextProps(theme, centerSubtextVariant)}
            >
              {centerSubtextSplit[1].trim()}
            </text>
          )}
          {showTooltip && <Tooltip content={tooltipContent} />}
        </PieChart>
      </Grid>
      {label && (
        <Grid item>
          <Typography color="text.secondary" variant="body2">
            {label}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
