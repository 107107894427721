import React from 'react';

import { Alert } from '~/components/ui/Alert';
import { Skeleton, Stack } from '~/components/ui/mui';
import { ContentOptions } from '~/utils/contentstack';

interface OpsAlertAndLoadingProps {
  ariaLabel: string;
  contentOptions: ContentOptions;
  error?: Error;
  loading: boolean;
}

const ChipsLoadingSkeleton = () => <Skeleton aria-hidden="true" height={20} variant="rounded" width={60} />;

const RowLoadingSkeleton = () => <Skeleton aria-hidden="true" height={40} variant="rounded" width="100%" />;

export const OpsAlertAndLoading: React.FC<OpsAlertAndLoadingProps> = ({
  ariaLabel,
  error,
  loading,
  contentOptions,
}) => {
  return (
    <>
      {error && <Alert contentOptions={contentOptions} error={error} severity="error" />}
      {loading && (
        <Stack aria-label={ariaLabel}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Skeleton aria-hidden="true" height={40} variant="rounded" width={400} />
            <Stack alignItems="center" direction="row" justifyContent="center" spacing={2}>
              <Skeleton aria-hidden="true" height={40} variant="rounded" width={150} />
              <Skeleton aria-hidden="true" height={40} variant="rounded" width={150} />
            </Stack>
          </Stack>

          <Stack alignItems="center" direction="row" justifyContent="flex-end" spacing={3} sx={{ pt: 2 }}>
            <Skeleton aria-hidden="true" height={20} variant="rounded" width={40} />
            <Skeleton aria-hidden="true" height={20} variant="rounded" width={40} />
            <Skeleton aria-hidden="true" height={20} variant="circular" width={20} />
          </Stack>

          <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={3} sx={{ pt: 2 }}>
            <ChipsLoadingSkeleton />
            <ChipsLoadingSkeleton />
            <ChipsLoadingSkeleton />
            <ChipsLoadingSkeleton />
            <ChipsLoadingSkeleton />
          </Stack>

          <Stack alignItems="center" justifyContent="flex-start" spacing={3} sx={{ pt: 4 }}>
            <RowLoadingSkeleton />
            <RowLoadingSkeleton />
            <RowLoadingSkeleton />
            <RowLoadingSkeleton />
          </Stack>
        </Stack>
      )}
    </>
  );
};
