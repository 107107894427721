import React, { ComponentProps, useState } from 'react';

import { useGetRecommendedGoalsContent } from './contentstack';

import { Alert } from '~/components/ui/Alert';
import { Carousel } from '~/components/ui/Carousel';
import { useCarousel } from '~/components/ui/Carousel/hooks';
import { Link } from '~/components/ui/Link';
import {
  Box,
  Collapse,
  ExpandLessIcon,
  ExpandMoreIcon,
  IconButton,
  NavigateBeforeIcon,
  NavigateNextIcon,
  Paper,
  Skeleton,
  Stack,
} from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useGetGoals } from '~/hooks/goals';
import { ContentOptions, getImageAssetUrl } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

type RecommendedGoalLabelKey = 'add-goal' | 'heading';

interface Props extends ComponentProps<typeof Box> {
  contentOptions: ContentOptions;
  onCreateGoal?: (goalObjectiveKey: string) => void;
  partyId: string;
}

const CARD_DIMENSIONS = {
  width: 442,
} as const;
const MOBILE_CARD_DIMENSIONS = {
  height: 200,
  width: 246,
} as const;

export const Recommended: React.FC<Props> = ({ contentOptions, onCreateGoal, partyId, ...rootProps }) => {
  const isMediumScreen = useIsMediumScreen();
  const getGoalsResult = useGetGoals({ variables: { partyId } });
  const contentResult = useGetRecommendedGoalsContent({ variables: contentOptions });
  const [expanded, setExpanded] = useState(true);

  const content = contentResult.data?.all_recommended_goals?.items?.[0];
  const recommendedGoalObjectives = content?.recommended_objective_keys
    ?.map(key => content.objectivesConnection?.edges?.[0]?.node?.objectives?.find(o => o?.key === key))
    ?.filter(
      objective =>
        !getGoalsResult.data?.getUserByPartyId?.goals?.items.some(g => g?.partnerGoalExternalRef === objective?.key),
    );

  const carousel = useCarousel(recommendedGoalObjectives?.length ?? 0);
  const getLabel = (key: RecommendedGoalLabelKey) => content?.fields?.text?.find(t => t?.key === key)?.value ?? '';

  if (getGoalsResult.error || contentResult.error) {
    return <Alert contentOptions={contentOptions} error={getGoalsResult.error || contentResult.error} />;
  }

  if (getGoalsResult.loading || contentResult.loading) {
    return (
      <Box {...rootProps}>
        <Typography variant="h4">
          <Skeleton />
        </Typography>
        <Box overflow="hidden" width="100%">
          <Carousel {...carousel}>
            <Stack direction="row" spacing={2}>
              <Skeleton {...CARD_DIMENSIONS} />
              <Skeleton {...CARD_DIMENSIONS} />
              <Skeleton {...CARD_DIMENSIONS} />
            </Stack>
          </Carousel>
        </Box>
      </Box>
    );
  }

  return recommendedGoalObjectives?.length ? (
    <Box {...rootProps}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography component="h2" variant="h4">
          {getLabel('heading')}
        </Typography>
        <Stack direction="row" spacing={4}>
          {!isMediumScreen && expanded && (
            <CarouselControls {...carousel} itemCount={recommendedGoalObjectives.length - 1} />
          )}
          <IconButton onClick={() => setExpanded(curr => !curr)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Stack>
      </Stack>
      <Collapse in={expanded}>
        <Box overflow="hidden" pt={2} width="100%">
          <Carousel {...carousel}>
            {recommendedGoalObjectives.map(objective =>
              objective ? (
                <RecommendedGoalCard
                  action={{
                    label: getLabel('add-goal'),
                  }}
                  description={objective.description ?? ''}
                  imageSrc={objective.icon ? getImageAssetUrl(objective.icon.secondaryConnection) : ''}
                  key={objective.key}
                  label={objective.label ?? ''}
                  onAddGoal={() => (objective.key ? onCreateGoal?.(objective.key) : undefined)}
                />
              ) : null,
            )}
          </Carousel>
        </Box>
        {isMediumScreen && (
          <Stack alignItems="center" pt={2}>
            <CarouselControls {...carousel} itemCount={recommendedGoalObjectives.length - 1} />
          </Stack>
        )}
      </Collapse>
    </Box>
  ) : null;
};

interface RecommendedGoalCardProps {
  action: {
    label: string;
  };
  description: string;
  imageSrc: string;
  label: string;
  onAddGoal: () => void;
}
const RecommendedGoalCard: React.FC<RecommendedGoalCardProps> = ({
  action,
  description,
  imageSrc,
  label,
  onAddGoal,
}) => {
  const isMediumScreen = useIsMediumScreen();
  const dimensions = isMediumScreen ? MOBILE_CARD_DIMENSIONS : CARD_DIMENSIONS;
  return (
    <Paper
      sx={{
        borderStyle: 'dashed',
      }}
      variant="outlined"
    >
      <Box p={3} {...dimensions} boxSizing="border-box">
        {!isMediumScreen ? (
          <Stack direction="row" spacing={3} width="100%">
            <GoalIcon alt={label} size={90} src={imageSrc} />
            <Stack alignItems="start" justifyContent="space-between">
              <Box>
                <Typography component="h3" variant="h6">
                  {label}
                </Typography>
                <Typography variant="body2">{description}</Typography>
              </Box>
              <Link color="text.primary" onClick={onAddGoal}>
                {action.label}
              </Link>
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems="start" height="100%" justifyContent="space-between">
            <Box>
              <GoalIcon alt={label} size={48} src={imageSrc} />
              <Typography variant="h6">{label}</Typography>
            </Box>
            <Typography variant="body2">{description}</Typography>
            <Link color="text.primary" onClick={onAddGoal}>
              {action.label}
            </Link>
          </Stack>
        )}
      </Box>
    </Paper>
  );
};

const GoalIcon = ({ size, src, alt }: { alt: string; size: number; src: string }) => (
  <Box
    alt={alt}
    bgcolor="grey.200"
    borderRadius={1}
    boxSizing="border-box"
    component="img"
    height={size}
    p={1}
    src={src}
    sx={{ objectFit: 'contain' }}
    width={size}
  />
);

const CarouselControls = ({
  focused,
  next,
  previous,
  itemCount,
}: ReturnType<typeof useCarousel> & { itemCount: number }) => (
  <Stack direction="row" spacing={2}>
    <IconButton disabled={focused === 0} onClick={previous} sx={{ border: 'solid 1px' }}>
      <NavigateBeforeIcon />
    </IconButton>
    <IconButton disabled={focused === itemCount} onClick={next} sx={{ border: 'solid 1px' }}>
      <NavigateNextIcon />
    </IconButton>
  </Stack>
);
