import { ComponentProps, ReactNode } from 'react';

import { AccountListContentV2 } from './hooks/useAccountSummaryContentV2';
import { EntityUpdateWorkflow, LegalDocument, PlanUpdateWorkflow, ScheduledTransfer } from './symphony';

import {
  AccountRestrictionType,
  AssociatedEntityType,
  FinancialAccountStatus,
  FinancialAccountType,
  ManagedProductType,
  PerformanceMethodTypes,
  Scalars as SymphonyScalars,
} from '~/__generated__/symphonyTypes.v2';
import { AccountActions, AccountActionsMenu } from '~/components/AccountActions';
import { AccountInfoVariants } from '~/components/AccountSummary/AccountInfo';
import {
  Content as PendingStatusContent,
  PendingStatusVariants,
} from '~/components/AccountSummary/AccountPendingStatus';
import { DonutSlice } from '~/components/ui/Donut';
import { PlaidLinkType } from '~/hooks/plaid-link';
import { BankAccount, TradingSuspension } from '~/utils/account';
import { AccountAttribute } from '~/utils/account/types';
import { ContentOptions } from '~/utils/contentstack/src/types';

export interface Ctas {
  addFunds: string;
  approve: string;
  continuePendingAccount: string;
  finishPendingAccount?: string;
  generateDocuments: string;
  openNewAccount: string;
  pendingModelChangeModalCancel: string;
  pendingModelChangeModalClose: string;
  pendingModelChangeModalDiscard: string;
  raiseCash?: string;
  resendDocusign: string;
  signDocuments: string;
}

export interface Labels {
  balance: string;
  compositeModelPortfolio: string;
  effectiveDate: string;
  modelPortfolio: string;
  partialAccount: string;
  pendingModelChange: string;
  pendingModelChangeGenericText: string;
  pendingModelChangeModalDiscardMessage: string;
  pendingModelChangeModalSuccessfulDiscard: string;
  pendingModelChangeModalTitle: string;
  resendEmailSentMessage: string;
  ytdReturn: string;
}
export interface Content {
  accessibility: { accountMenuAriaLabel: string };
  accountNumberFormat: string;
  accountNumberPrefix: string;
  accountProgram: string;
  accountType: string;
  actionToInvoke?: AccountActionToInvoke;
  actions: ComponentProps<typeof AccountActions>['config'];
  ctas: Ctas;
  ctasMoreAction: ComponentProps<typeof AccountActionsMenu>['items'];
  editPortfolioFeedbackMessage?: string;
  errorStates: {
    needsAttention: string;
    pendingClosed: string;
    suspended: string;
  };
  labels: Labels;
  modelPortfolio: {
    donutAllocations: DonutSlice[];
    donutInnerRadius: number;
    donutShowTooltip: boolean;
    donutSize: number;
  };
  ownershipType: string;
  pendingStatus: PendingStatusContent;
  shouldMaskAccountNumber: boolean;
}

// Action to invoke automatically
export interface AccountActionToInvoke {
  accountNumber: string;
  actionName: string;
}

export interface AccountDetailsToShow {
  showAccountProgram?: boolean;
  showAccountType?: boolean;
  showEffectiveDate?: boolean;
  showLabels?: boolean;
  showModelPortfolioInfo?: boolean;
  showOwnershipType?: boolean;
  showPendingBars?: boolean;
  showYtdReturn?: boolean;
}

export interface AccountRestriction {
  createdAt: string;
  id: string;
  lastUpdatedByPartyId?: string | null;
  restrictionType: AccountRestrictionType;
  updatedAt: string;
}

export interface AccountData {
  accountMinimum?: string;
  accountMinimumForRebalance?: string;
  accountNumber: string;
  accountRestrictions?: AccountRestriction[];
  assignedOn: string;
  attributes?: AccountAttribute[];
  availableWithdrawalBalance?: number;
  balance?: string;
  billingRestriction?: AccountRestriction;
  entityUpdateWorkflow?: EntityUpdateWorkflow[];
  firstRebalancedOn?: string;
  hasCompositeModelPortfolio?: boolean;
  id?: string;
  isFinancialAccountAssociationPending?: boolean;
  isQuarterlyPerformance?: boolean;
  label?: string;
  lastRtqDate?: string;
  legalDocuments?: LegalDocument[];
  managedProductId: string;
  maskedAccountNumber: string;
  modelPortfolioName?: string;
  partyId: string;
  planId: string;
  planUpdateWorkflows?: PlanUpdateWorkflow[];
  primaryClientPartyId?: string;
  primaryOnboardingRepPartyId?: string;
  program: ManagedProductType;
  questionnaire?: {
    completedOn?: SymphonyScalars['DateTime'];
    isMigrated?: boolean;
  };
  scheduledTransfers?: ScheduledTransfer[];
  status: FinancialAccountStatus;
  suspendedOn?: string;
  tradingSuspensions?: TradingSuspension[];
  type: FinancialAccountType;
}

export interface AccountProps {
  accountData: AccountData;
  accountDetailsToShow?: AccountDetailsToShow;
  accountFlairBuilder?: (accountData: AccountData) => ReactNode;
  accountInfoVariant?: AccountInfoVariants;
  allAccounts?: AccountData[];
  availableWithdrawalBalance?: number;
  bankAccounts?: BankAccount[];
  content: Content;
  contentOptions: ContentOptions;
  contentV2?: AccountListContentV2;
  contributionAmount?: string;
  dataQa?: string;
  getAccountTypeLabel?: (accountType: FinancialAccountType) => string;
  ignoreInsufficientFunds?: boolean;
  isPendingModelChangeApproveEnabled?: boolean;
  onRetakeQuestionnaire?: (managedProductId: string) => void;
  partyIdFA?: string;
  pendingBarsVariant?: PendingStatusVariants;
  performanceMethod?: PerformanceMethodTypes;
  plaidLinkageType?: PlaidLinkType;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  refetchAccounts?: () => Promise<any>;
  refetchingAccounts?: boolean;
  showVerifiedBankAccounts?: boolean;
  syncExternalBankAccounts?: boolean;
}

export type AllowedAddFundsSourcesType = 'bankAccount' | 'journaling';

export enum AccountSummaryV2ContentKeys {
  ACCESSIBILITY_ACCOUNT_LIST_ACCOUNT_MENU_ACCOUNT = 'accessibility_account_list_account_menu_account',
  ACCESSIBILITY_ACCOUNT_LIST_ACCOUNT_MENU_ACCOUNT_IN_PROGRESS = 'accessibility_account_list_account_menu_account_in_progress',
  RMD_NOTIFICATION_COMPLETED = 'rmdNotificationCompleted',
  RMD_NOTIFICATION_COMPLETED_HEADER = 'rmdNotificationCompletedHeader',
  RMD_NOTIFICATION_DISMISS_CTA = 'rmdNotificationDismissCTA',
  RMD_NOTIFICATION_EXTENDED = 'rmdNotificationExtended',
  RMD_NOTIFICATION_EXTENDED_HEADER = 'rmdNotificationExtendedHeader',
  RMD_NOTIFICATION_HEADER = 'rmdNotificationHeader',
  RMD_NOTIFICATION_INELIGIBLE_IRA = 'rmdNotificationIneligibleIRA',
  RMD_NOTIFICATION_INELIGIBLE_IRA_HEADER = 'rmdNotificationIneligibleIRAHeader',
  RMD_NOTIFICATION_LESS_THAN_31_DAYS = 'rmdNotificationLessThan31Days',
  RMD_NOTIFICATION_LESS_THAN_31_DAYS_HEADER = 'rmdNotificationLessThan31DaysHeader',
  RMD_NOTIFICATION_MORE_THAN_30_DAYS = 'rmdNotificationMoreThan30Days',
  RMD_NOTIFICATION_NO_DATA = 'rmdNotificationNoData',
  RMD_NOTIFICATION_PARTIAL_DATA = 'rmdNotificationPartialData',
  RMD_NOTIFICATION_PARTIAL_DATA_COMPLETED = 'rmdNotificationPartialDataCompleted',
  RMD_NOTIFICATION_REQUIREMENT_FULFILLED_CTA = 'rmdNotificationRequirementFulfilledCTA',
  RMD_NOTIFICATION_SETUP_WITHDRAWAL_CTA = 'rmdNotificationSetupWithdrawalCTA',
}
