import * as queries from './query.gql';

import {
  GetAccountErrors,
  GetAccountErrors_managedProduct_fundingDetails_scheduledTransfers_AssetTransfer,
  GetAccountErrors_managedProduct_fundingDetails_scheduledTransfers_CashTransfer,
  GetAccountErrorsVariables,
  GetClientBrokerages,
  GetClientBrokerages_client_financialAccounts,
  GetClientBrokerages_client_financialAccounts_balances,
  GetClientBrokeragesVariables,
} from '~/components/modals/AccountErrors/symphony/__generated__/query.v2';
import { QueryHookOptions } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyQuery } from '~/utils/symphony';

export type AccountErrorsCashTransfers = GetAccountErrors_managedProduct_fundingDetails_scheduledTransfers_CashTransfer;
export type AccountErrorsAssetTransfers = GetAccountErrors_managedProduct_fundingDetails_scheduledTransfers_AssetTransfer;
export type AccountErrorsFinancialAccounts = GetClientBrokerages_client_financialAccounts;
export type AccountErrorsFinancialAccountsBalances = GetClientBrokerages_client_financialAccounts_balances;

export const useGetAccountErrors = (options?: QueryHookOptions<GetAccountErrors, GetAccountErrorsVariables>) => {
  return useSymphonyQuery(queries.GetAccountErrors, options);
};

export const useLazyGetAccountErrors = (options?: QueryHookOptions<GetAccountErrors, GetAccountErrorsVariables>) => {
  return useLazySymphonyQuery(queries.GetAccountErrors, options);
};

export const useGetClientBrokerages = (
  options?: QueryHookOptions<GetClientBrokerages, GetClientBrokeragesVariables>,
) => {
  return useSymphonyQuery(queries.GetClientBrokerages, options);
};

export const useLazyGetClientBrokerages = (
  options?: QueryHookOptions<GetClientBrokerages, GetClientBrokeragesVariables>,
) => {
  return useLazySymphonyQuery(queries.GetClientBrokerages, options);
};
