import React, { FC, ReactNode } from 'react';

import { Box, Stack, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrencyPrecise } from '~/utils/format';
import { allNumericDateFormat, formatDate } from '~/utils/format/date';
import { SfTheme } from '~/utils/theme';

export interface Content {
  balance: string;
  balanceRedactor: string;
  dataAsOf: string;
  dateTimeFormat: string;
  title: string;
}

export interface Props {
  balance: number;
  content: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  effectiveDate?: string;
  headingCta?: ReactNode;
}

export const AccountTotal: FC<Props> = ({
  dataQa = 'account-total',
  balance,
  content,
  contentOptions,
  effectiveDate,
  headingCta,
}) => {
  const {
    sfAccountTotal: { typographyVariants },
  } = useTheme<SfTheme>();

  return (
    <Stack data-qa={dataQa} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={1}>
      <Box>
        <Typography component="h2" data-qa={`${dataQa}-title`} variant={typographyVariants?.heading}>
          {content.title}
        </Typography>
        {content.dataAsOf && effectiveDate && (
          <Typography data-qa={`${dataQa}-data-as-of`} sx={{ color: 'text.primary' }} variant="caption">
            {content.dataAsOf} {formatDate(effectiveDate, content.dateTimeFormat, { locale: contentOptions.locale })}
          </Typography>
        )}
      </Box>
      <Stack data-qa={`${dataQa}-balance`} spacing={{ xs: 1.5, md: 0 }} textAlign={{ xs: 'left', md: 'right' }}>
        {headingCta}

        <Typography component="p" pb={0.5} variant={typographyVariants?.balance}>
          {formatCurrencyPrecise(balance, { locale: contentOptions.locale })}
        </Typography>
        {content.balance && (
          <Typography sx={{ color: 'text.secondary' }} variant="caption">
            {content.balance}
          </Typography>
        )}
        {content.balanceRedactor && effectiveDate && (
          <RteContent
            config={{ date: allNumericDateFormat(effectiveDate, { locale: contentOptions.locale }) }}
            data={content.balanceRedactor}
            sx={{ '.MuiTypography-root': { color: 'text.secondary' } }}
          />
        )}
      </Stack>
    </Stack>
  );
};
