import { differenceInCalendarQuarters, isBefore, isValid, parseISO, startOfToday, startOfYear } from 'date-fns';
import { pipe } from 'fp-ts/lib/function';
import { fromNullable, map, toUndefined } from 'fp-ts/lib/Option';
import React, { ComponentProps, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ViewerPartyIdContext } from '../..';
import { AccountProps } from '../../types';
import { getActivatedAccountStates, getFundableAccountStates } from '../../utils';
import { getPendingModelChangeContent } from '../utils';

import { ModelPortfolioInfo } from './ModelPortfolioInfo';
import {
  PendingAccountProfileUpdate,
  PendingAssetTransfersDocusign,
  PendingBankAccountAssociation,
  PendingModelChange,
} from './PendingChangeCards';
import { PrimaryCta } from './PrimaryCta';
import { useGetYtdReturn } from './symphony';
import { getAccountInfoProps } from './utils';

import { UpdateWorkflowStatus } from '~/__generated__/symphonyTypes.v2';
import { AccountActions, AccountActionsMenu, ActionName } from '~/components/AccountActions';
import { useAccountActionModals } from '~/components/AccountActions/hooks';
import { isCustomAction } from '~/components/AccountActions/utils';
import { AccountRestrictions } from '~/components/AccountRestrictions';
import { AccountAlert } from '~/components/AccountSummary/AccountAlert';
import { AccountAttributes } from '~/components/AccountSummary/AccountAttributes';
import { AccountInfo } from '~/components/AccountSummary/AccountInfo';
import { AccountPendingStatus } from '~/components/AccountSummary/AccountPendingStatus';
import { Grid, Stack, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { FeedbackMessageType, Snackbar } from '~/components/ui/Snackbar';
import { FeedbackMessageContext } from '~/contexts/FeedbackMessageContext';
import {
  AccountState,
  getAccountNumberText,
  getAccountState,
  getRequiredFunds,
  hasPartyFinishedSigning,
  isPartyASignee,
  isPendingEntityUpdateWorkflowStatus,
  isPendingPlanUpdateWorkflowStatus,
  shouldEnablePendingPUWWarning,
} from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { formatCurrency, formatCurrencyPrecise, formatPercentage } from '~/utils/format';
import { fullDateFormat, hoursMinsDateFormat } from '~/utils/format/date';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { toSymphonyDate } from '~/utils/symphony';
import { SfTheme } from '~/utils/theme';

export const Account: FC<AccountProps> = ({
  accountData,
  allAccounts,
  accountDetailsToShow,
  accountInfoVariant = 'infoChip',
  accountFlairBuilder,
  bankAccounts = [],
  content,
  contentOptions,
  contentV2,
  dataQa = 'account',
  contributionAmount = '100',
  getAccountTypeLabel,
  ignoreInsufficientFunds = false,
  partyIdFA,
  plaidLinkageType,
  isPendingModelChangeApproveEnabled,
  pendingBarsVariant,
  performanceMethod,
  redirectToSignDocuments,
  refetchAccounts,
  refetchingAccounts,
  onRetakeQuestionnaire,
  showVerifiedBankAccounts,
  syncExternalBankAccounts,
}) => {
  const [isRefetching, setIsRefetching] = useState<boolean | undefined>();
  const viewerPartyId = useContext(ViewerPartyIdContext);
  const { feedbackMessageType, setFeedbackMessageType } = useContext(FeedbackMessageContext);
  const isMediumScreen = useIsMediumScreen();
  const {
    attributes,
    availableWithdrawalBalance,
    accountMinimum,
    accountMinimumForRebalance,
    accountNumber,
    assignedOn,
    balance,
    entityUpdateWorkflow,
    firstRebalancedOn,
    id,
    lastRtqDate,
    managedProductId,
    maskedAccountNumber,
    modelPortfolioName,
    legalDocuments,
    partyId,
    planId,
    planUpdateWorkflows,
    primaryClientPartyId,
    primaryOnboardingRepPartyId,
    program,
    questionnaire,
    scheduledTransfers,
    status,
    suspendedOn,
    type,
    hasCompositeModelPortfolio,
  } = accountData;
  const requiredFunds = getRequiredFunds(accountMinimum);
  const {
    featureFlags: { isSuspendedAccountStateSupported },
    components: {
      sfWithdrawFunds: { soonestPossibleDays },
    },
  } = useCoreConfig();
  const {
    sfAccount: { styles: sfAccountStyles },
  } = useTheme<SfTheme>();
  const { state: accountState, data: accountStateData } = useMemo(
    () =>
      getAccountState({
        accountMinimum: toUndefined(requiredFunds),
        accountNumber,
        financialAccountStatus: status,
        hasPendingPlanUpdateWorkflow: planUpdateWorkflows?.some(p => isPendingPlanUpdateWorkflowStatus(p.status)),
        firstRebalancedOn,
        isSuspendedAccountStateSupported,
        legalDocuments,
        primaryAdvisorPartyId: primaryOnboardingRepPartyId,
        primaryClientPartyId,
        suspendedOn,
        transfers: scheduledTransfers,
      }),
    [
      accountNumber,
      firstRebalancedOn,
      isSuspendedAccountStateSupported,
      legalDocuments,
      planUpdateWorkflows,
      primaryClientPartyId,
      primaryOnboardingRepPartyId,
      requiredFunds,
      scheduledTransfers,
      status,
      suspendedOn,
    ],
  );
  const [enablePendingPUWWarning, planUpdateWorkflowId] = useMemo(() => {
    return [
      shouldEnablePendingPUWWarning(partyIdFA, accountState),
      planUpdateWorkflows?.find(p => isPendingPlanUpdateWorkflowStatus(p.status))?.id,
    ];
  }, [accountState, partyIdFA, planUpdateWorkflows]);

  const rightNow = new Date();
  const {
    balance: balanceLabel,
    ytdReturn: ytdReturnLabel,
    modelPortfolio: modelPortfolioLabel,
    compositeModelPortfolio: compositeModelPortfolioLabel,
  } = content.labels;
  const defaultAccountDetailsToShow = {
    showAccountProgram: true,
    showAccountType: true,
    showLabels: true,
    showModelPortfolioInfo: false, // requires modelPortfolioName and content.modelPortfolio prop values
    showOwnershipType: true,
    showPendingBars: true,
    showYtdReturn: true,
  };

  const {
    showAccountType,
    showAccountProgram,
    showLabels,
    showModelPortfolioInfo,
    showPendingBars,
    showYtdReturn,
    showOwnershipType,
  } = {
    ...defaultAccountDetailsToShow,
    ...accountDetailsToShow,
  };

  const ytdStart = startOfYear(rightNow);
  const firstRebalanceDate = firstRebalancedOn ? parseISO(firstRebalancedOn) : undefined;
  const { data: ytdData, loading: ytdLoading, error: ytdError } = useGetYtdReturn({
    variables: {
      accounts: [id as string],
      measurements: [
        {
          from: toSymphonyDate(
            firstRebalanceDate && isBefore(ytdStart, firstRebalanceDate) ? firstRebalanceDate : ytdStart,
          ),
          to: toSymphonyDate(rightNow),
          performanceMethod,
        },
      ],
    },
    skip: !id || !showYtdReturn,
  });
  const onboardingAccountStates = new Set([AccountState.OnboardingIncomplete]);
  const nonPendingStates = [...getActivatedAccountStates(), AccountState.Unknown];

  const showModelPortfolioSection = !onboardingAccountStates.has(accountState) && showModelPortfolioInfo;
  const showBalance = nonPendingStates.includes(accountState) && !!id && !!balance;
  const ytdReturn = ytdData?.performance?.[0].rateOfReturns?.cumulative;
  const showYtdSection = showYtdReturn && showBalance && (ytdLoading || (!!ytdReturn && !ytdError));

  const showPendingEntityUpdateWorkflow = entityUpdateWorkflow?.some(
    (entity: { status: UpdateWorkflowStatus | undefined }) => isPendingEntityUpdateWorkflowStatus(entity.status),
  );
  const onActionClick = useCallback(
    (actionName: ActionName) => {
      const accountAction = content.actions[actionName];
      if (accountAction && isCustomAction(accountAction)) {
        accountAction.callback({
          accountNumber,
          enablePendingPUWWarning,
          lastRtqDate,
          maskedAccountNumber,
          managedProductId,
          partyId,
          questionnaire,
          refetchAccounts,
          type,
        });
      } else {
        console.warn(`standard ${actionName} not implemented`);
      }
    },
    [
      accountNumber,
      content.actions,
      enablePendingPUWWarning,
      lastRtqDate,
      maskedAccountNumber,
      managedProductId,
      partyId,
      questionnaire,
      refetchAccounts,
      type,
    ],
  );

  const showSeeDetailsCta = useMemo(
    () =>
      content.actions.seeDetails?.valid
        ? content.actions.seeDetails.valid({ state: accountState, stateData: accountStateData })
        : getActivatedAccountStates().includes(accountState),
    [accountState, accountStateData, content.actions.seeDetails],
  );
  const accountInfoProps = useMemo(
    () =>
      getAccountInfoProps({
        accountDetailsToShow: { showAccountProgram, showAccountType, showOwnershipType },
        accountInfoVariant,
        content: { ...content, partialAccount: content.labels.partialAccount },
        isOnboardingInProgress: onboardingAccountStates.has(accountState),
        maskedAccountNumber,
        onSeeDetailsClick: showSeeDetailsCta ? () => onActionClick('seeDetails') : undefined,
      }),
    [
      accountInfoVariant,
      accountState,
      content,
      maskedAccountNumber,
      onActionClick,
      onboardingAccountStates,
      showAccountProgram,
      showAccountType,
      showOwnershipType,
      showSeeDetailsCta,
    ],
  );
  const {
    addFunds,
    addRestrictions: { modal: addRestrictionsModal },
    viewDocuments: { modal: viewDocumentsModal },
    viewRiskPreferenceHistory: { modal: viewRiskPreferenceHistoryModal },
    viewPricing: { modal: viewPricingModal },
    viewTransfers: { modal: viewTransfersModal },
    withdrawFunds: { modal: withdrawFundsModal },
    closeAccount: { modal: closeAccountModal },
    suspendBilling: { modal: suspendBillingModal },
    suspendTrading: { modal: suspendTradingModal },
    editAccount: { modal: editAccountModal },
    toggleTlh: { modal: tlhModal },
    other: { modal: otherActionsModal },
    retakeQuestionnaire: { modal: retakeQuestionnaireModal },
    switchWithinAnAccount: { modal: switchWithinAnAccountModal },
  } = useAccountActionModals();

  const {
    sourceAccountId: [sourceId],
    depositAmount: [depositAmount],
    modal: addFundsModal,
  } = addFunds;

  const [feedbackMessage, setFeedbackMessage] = useState('');
  const feedbackMessageFromModal =
    addFundsModal.feedbackMessage || withdrawFundsModal.feedbackMessage || closeAccountModal.feedbackMessage;

  useEffect(() => {
    setFeedbackMessage(feedbackMessageFromModal);
  }, [feedbackMessageFromModal]);

  useEffect(() => {
    if (feedbackMessageType && feedbackMessageType === FeedbackMessageType.EDIT_PORTFOLIO) {
      setFeedbackMessage(content.editPortfolioFeedbackMessage ?? '');
      // The FeedbackMessageContext's value will persist even after a page refresh; to prevent this, we are deliberately setting it to undefined when the pop-up message is displayed.
      setFeedbackMessageType?.();
    }
  }, [feedbackMessageType, content.editPortfolioFeedbackMessage, setFeedbackMessageType]);

  const tlhModalOnClose = async () => {
    setIsRefetching(true);
    await refetchAccounts?.();
    setIsRefetching(false);
  };

  const accountActionsItems = useMemo((): ComponentProps<typeof AccountActionsMenu>['items'] => {
    return content.ctasMoreAction.filter(item => {
      if (item.key === 'quarterlyPerformance' && firstRebalanceDate) {
        if (differenceInCalendarQuarters(startOfToday(), firstRebalanceDate) > 0) {
          return item;
        }
        return false;
      }
      return item;
    });
  }, [content.ctasMoreAction, firstRebalanceDate]);

  const addFundsV2DestinationAccounts = useMemo(() => {
    return (
      allAccounts?.map(a => {
        return {
          accountId: a.managedProductId,
          accountName: getAccountNumberText(
            content.shouldMaskAccountNumber,
            a.maskedAccountNumber,
            content.accountNumberPrefix,
            content.accountNumberFormat,
          ),
          accountType: a.type,
        };
      }) ?? []
    );
  }, [allAccounts, content.shouldMaskAccountNumber, content.accountNumberPrefix, content.accountNumberFormat]);

  const commonAddFundsProps = {
    open: addFundsModal.open,
    onClose: (successMessage?: string) => {
      setFeedbackMessage(successMessage ?? '');
      addFundsModal.onClose(successMessage);
    },
    enablePendingPUWWarning,
    accountName: maskedAccountNumber,
    bankAccountId: sourceId,
    additionAmount: depositAmount,
    partyId,
    managedProductId,
    ignoreInsufficientFunds,
    redirectToSignDocuments,
    refetchAccounts,
    showVerifiedBankAccounts,
    syncExternalBankAccounts,
    legalDocuments,
    viewerPartyId,
  };
  const accountActionsMenuProps = {
    accountAttributes: attributes ?? [],
    accountState,
    accountStateData,
    accountType: type,
    allAccounts: allAccounts ?? [],
    ariaLabel: content.accessibility.accountMenuAriaLabel,
    config: content.actions,
    customActionArgs: accountData,
    items: accountActionsItems,
    managedProductType: program,
    menuButtonDisabled: refetchingAccounts,
    modalLaunchers: {
      addFunds: addFundsModal.openModal,
      addRestrictions: addRestrictionsModal.openModal,
      viewDocuments: viewDocumentsModal.openModal,
      viewRiskPreferenceHistory: viewRiskPreferenceHistoryModal.openModal,
      viewPricing: viewPricingModal.openModal,
      viewTransfers: viewTransfersModal.openModal,
      withdrawFunds: withdrawFundsModal.openModal,
      closeAccount: closeAccountModal.openModal,
      suspendBilling: suspendBillingModal.openModal,
      suspendTrading: suspendTradingModal.openModal,
      editAccount: editAccountModal.openModal,
      toggleTlh: tlhModal.openModal,
      other: otherActionsModal.openModal,
      retakeQuestionnaire: retakeQuestionnaireModal.openModal,
      switchWithinAnAccount: switchWithinAnAccountModal.openModal,
    },
    partyId,
  };

  return (
    <Grid container data-qa={dataQa} justifyContent="space-between" spacing={1}>
      {accountInfoProps && (
        <Grid item md={4} xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <AccountInfo {...accountInfoProps} />
            <Stack alignItems="center" direction="row">
              {accountFlairBuilder?.(accountData)}
              {isMediumScreen && <AccountActionsMenu {...accountActionsMenuProps} />}
            </Stack>
          </Stack>
        </Grid>
      )}
      {showModelPortfolioSection && (
        <Grid item md={3} xs={12}>
          <ModelPortfolioInfo
            modelPortfolio={content.modelPortfolio}
            modelPortfolioLabel={modelPortfolioLabel}
            modelPortfolioName={!hasCompositeModelPortfolio ? modelPortfolioName ?? '' : compositeModelPortfolioLabel}
            showLabels={showLabels}
          />
        </Grid>
      )}
      <AccountAttributes
        data={[
          showBalance && {
            dataQa: `${dataQa}-account-balance`,
            label: showLabels ? balanceLabel : undefined,
            sx: sfAccountStyles.showBalance,
            value: formatCurrencyPrecise(parseFloat(balance), { locale: contentOptions.locale }),
          },
          showYtdSection && {
            dataQa: `${dataQa}-ytd-return`,
            label: showLabels ? ytdReturnLabel : undefined,
            loading: ytdLoading,
            value: ytdReturn ? formatPercentage(parseFloat(ytdReturn), { locale: contentOptions.locale }) : '',
          },
        ]}
        md
        xs={12}
      />
      <Grid item md="auto" xs={12}>
        <Stack direction="row" justifyContent="right">
          <PrimaryCta
            accountData={accountData}
            accountState={accountState}
            accountStateData={accountStateData}
            addFundsData={addFunds}
            allAccounts={allAccounts}
            bankAccounts={bankAccounts}
            content={content}
            contentV2={contentV2}
            contributionAmount={contributionAmount}
            onActionClick={onActionClick}
            onRaiseCash={withdrawFundsModal.openModal}
            refetchingAccounts={refetchingAccounts}
          />
          {!isMediumScreen && <AccountActionsMenu {...accountActionsMenuProps} />}
          <AccountActions
            addFunds={{
              ...commonAddFundsProps,
              destinationAccountId: managedProductId,
              accountRestrictions: accountData.accountRestrictions,
              destinationAccountType: type,
              partyIdFA,
            }}
            addFundsV2={{
              ...commonAddFundsProps,
              accountType: type,
              accountHasRecurringDeposit: false, // TODO: determine this after https://sigfig.atlassian.net/browse/DWCORE-8004
              accountRestrictions: accountData.accountRestrictions,
              defaultDestinationAccountId: managedProductId,
              destinationAccounts: addFundsV2DestinationAccounts,
              partyIdFA,
            }}
            addRestrictions={{
              open: addRestrictionsModal.open,
              onClose: addRestrictionsModal.onClose,
            }}
            allAccounts={allAccounts ?? []}
            closeAccount={{
              accountId: id,
              open: closeAccountModal.open,
              partyId,
              managedProductId,
              managedProductType: program,
              maskedAccountNumber,
              onClose: (successMessage?: string) => {
                setFeedbackMessage(successMessage ?? '');
                closeAccountModal.onClose(successMessage);
              },
              refetchAccounts,
              currentBalance: availableWithdrawalBalance,
              partyIdFA,
              redirectToSignDocuments,
              showVerifiedBankAccounts,
              syncExternalBankAccounts,
            }}
            config={content.actions}
            contentOptions={contentOptions}
            editAccount={{
              enablePendingPUWWarning,
              open: editAccountModal.open,
              onClose: editAccountModal.onClose,
              accountEditDisabled: true,
              managedProductId,
              onRetakeQuestionnaire,
              // TODO: add isEditAccountDisabled here
              partyId,
              planId,
              planUpdateWorkflowId,
              refetchAccounts,
            }}
            managedProductId={managedProductId}
            managedProductType={program}
            other={{
              open: otherActionsModal.open,
              onClose: otherActionsModal.onClose,
            }}
            partyId={partyId}
            plaidLinkageType={plaidLinkageType}
            retakeQuestionnaire={{
              open: retakeQuestionnaireModal.open,
              onClose: retakeQuestionnaireModal.onClose,
            }}
            state={accountState}
            stateData={accountStateData}
            suspendBilling={{
              open: suspendBillingModal.open,
              managedProductId,
              maskedAccountNumber,
              onClose: suspendBillingModal.onClose,
            }}
            suspendTrading={{
              open: suspendTradingModal.open,
              managedProductId,
              onClose: suspendTradingModal.onClose,
              refetchAccounts,
            }}
            switchWithinAnAccount={{
              open: switchWithinAnAccountModal.open,
              onClose: switchWithinAnAccountModal.onClose,
            }}
            toggleTlh={{
              open: tlhModal.open,
              onClose: (shouldRefetchAccounts?: boolean) => {
                if (shouldRefetchAccounts) {
                  tlhModalOnClose();
                }
                tlhModal.onClose();
              },
              isRefetching,
              partyId,
            }}
            type={type}
            viewDocuments={{
              open: viewDocumentsModal.open,
              onClose: viewDocumentsModal.onClose,
              partyId,
            }}
            viewPricing={{
              open: viewPricingModal.open,
              onClose: viewPricingModal.onClose,
              accountValue: parseFloat(balance ?? '0'),
              maskedAccountNumber,
            }}
            viewRiskPreferenceHistory={{
              accountId: accountNumber,
              managedProductId,
              open: viewRiskPreferenceHistoryModal.open,
              onClose: viewRiskPreferenceHistoryModal.onClose,
            }}
            viewTransfers={{
              open: viewTransfersModal.open,
              onClose: viewTransfersModal.onClose,
              onWithdrawalCancelled: refetchAccounts,
              partyId,
              accountId: id as string,
              managedProductId,
              accountNumberFormat: content.accountNumberFormat,
            }}
            withdrawFunds={{
              accountLabels: allAccounts?.map(acc => ({
                label: `${getAccountTypeLabel?.(acc.type)} ${acc.maskedAccountNumber}`,
                managedProductId: acc.managedProductId,
              })),
              accountIdRestrictionsMap: { [`${managedProductId}`]: accountData.accountRestrictions ?? [] },
              accountMinimum: accountMinimumForRebalance,
              accountName: maskedAccountNumber || 'unknown',
              availableFunds: availableWithdrawalBalance ?? 0,
              defaultWithdrawalAmount: contributionAmount,
              accountId: id,
              managedProductId,
              managedProductType: program,
              open: withdrawFundsModal.open,
              onClose: (successMessage?: string) => {
                setFeedbackMessage(successMessage ?? '');
                withdrawFundsModal.onClose(successMessage);
              },
              onWithdrawSuccess: refetchAccounts,
              partyId,
              partyIdFA,
              redirectToSignDocuments,
              sourceAccounts: [
                {
                  value: managedProductId,
                  label: `${content.accountType} ${maskedAccountNumber}` || 'unknown',
                },
              ],
              soonestPossibleDays,
              showVerifiedBankAccounts,
              syncExternalBankAccounts,
            }}
          />
        </Stack>
      </Grid>

      {(!!accountData.accountRestrictions?.length || !!accountData.tradingSuspensions?.length) &&
        contentV2?.textFields && (
          <AccountRestrictions
            restrictions={accountData.accountRestrictions}
            textFields={contentV2.textFields}
            tradingSuspensions={accountData.tradingSuspensions}
          />
        )}

      {accountState !== AccountState.RebalanceCompleted && accountState !== AccountState.Unknown && (
        <Grid item xs={12}>
          {accountState === AccountState.PendingClosed && (
            <AccountAlert dataQa={`${dataQa}-pendingclosed`}>
              <RteContent data={content.errorStates.pendingClosed} />
            </AccountAlert>
          )}
          {accountState === AccountState.PendingModelPortfolioChange && (
            <PendingModelChange
              content={getPendingModelChangeContent({
                ctas: content.ctas,
                labels: content.labels,
                message: content.pendingStatus.descriptions.PendingModelPortfolioChange,
              })}
              contentOptions={contentOptions}
              isApproveEnabled={isPendingModelChangeApproveEnabled}
              legalDocuments={legalDocuments}
              managedProductId={managedProductId}
              partyIdFA={partyIdFA}
              planId={planId}
              planUpdateWorkflows={planUpdateWorkflows}
              redirectToSignDocuments={redirectToSignDocuments}
              refetchAccounts={refetchAccounts}
              refetchingAccounts={refetchingAccounts}
              viewerPartyId={viewerPartyId}
            />
          )}
          {!nonPendingStates.includes(accountState) && (
            <AccountPendingStatus
              content={content.pendingStatus}
              showBars={showPendingBars}
              state={accountState}
              variables={{
                dateStarted: pipe(assignedOn, parseISO, date =>
                  isValid(date) ? fullDateFormat(date, { locale: contentOptions.locale }) : 'Unknown date',
                ),
                timeStarted: pipe(assignedOn, parseISO, date =>
                  isValid(date)
                    ? hoursMinsDateFormat(date, {
                        locale: contentOptions.locale,
                      })
                    : 'Unknown time',
                ),
                requiredFunds: pipe(
                  requiredFunds,
                  map(x => formatCurrency(x, { locale: contentOptions.locale })),
                  toUndefined,
                ),
                shortfall: pipe(
                  fromNullable(accountStateData?.shortfall),
                  map(x => formatCurrency(x, { locale: contentOptions.locale })),
                  toUndefined,
                ),
                isViewerPartyASigneeForOnboarding:
                  accountStateData?.onboardingSignees &&
                  isPartyASignee(accountStateData.onboardingSignees, viewerPartyId),
                hasViewerPartyFinishedSigningForOnboarding:
                  accountStateData?.onboardingSignees &&
                  hasPartyFinishedSigning(accountStateData.onboardingSignees, viewerPartyId),
              }}
              variant={pendingBarsVariant}
            />
          )}
        </Grid>
      )}
      {showPendingEntityUpdateWorkflow && contentV2 && (
        <Grid item xs={12}>
          <PendingAccountProfileUpdate
            content={contentV2.pendingAccountProfileUpdateContent}
            contentOptions={contentOptions}
            entityUpdateWorkflow={entityUpdateWorkflow}
            legalDocuments={legalDocuments}
            managedProductId={managedProductId}
            partyIdFA={partyIdFA}
            redirectToSignDocuments={redirectToSignDocuments}
            refetchAccounts={refetchAccounts}
            refetchingAccounts={refetchingAccounts}
            viewerPartyId={viewerPartyId}
          />
        </Grid>
      )}
      {[...getFundableAccountStates(), AccountState.DocsSigned].includes(accountState) && contentV2 && (
        <PendingBankAccountAssociation
          content={contentV2.pendingBankAccountAssociationContent}
          contentOptions={contentOptions}
          legalDocuments={legalDocuments}
          managedProductId={managedProductId}
          partyId={partyId}
          redirectToSignDocuments={redirectToSignDocuments}
          refetchAccounts={refetchAccounts}
          refetchingAccounts={refetchingAccounts}
          viewerPartyId={viewerPartyId}
        />
      )}
      {[...getFundableAccountStates(), AccountState.DocsSigned].includes(accountState) && contentV2 && (
        <PendingAssetTransfersDocusign
          content={{
            journaling: contentV2.pendingJournalingUpdateContent,
            regularTransfers: contentV2.pendingAssetTransfersContent,
          }}
          contentOptions={contentOptions}
          legalDocuments={legalDocuments}
          managedProductId={managedProductId}
          partyIdFA={partyIdFA}
          redirectToSignDocuments={redirectToSignDocuments}
          refetchAccounts={refetchAccounts}
          refetchingAccounts={refetchingAccounts}
          scheduledTransfer={scheduledTransfers}
          viewerPartyId={viewerPartyId}
        />
      )}

      {!!feedbackMessage && (
        <Snackbar
          feedbackMessage={feedbackMessage}
          onClose={() => {
            setFeedbackMessage('');
          }}
        />
      )}
    </Grid>
  );
};
