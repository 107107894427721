import { endOfYear, getYear, startOfYear, subYears } from 'date-fns';

import { ControlFieldConfig, ControlFieldContent, ControlFieldValues } from './components/ControlFieldSection/types';

import { DocumentCategoryEnum } from '~/__generated__';
import { DateRangeOptions } from '~/components/ControlFields/DateFilters/types';
import { getDateRangeValue } from '~/components/ControlFields/DateFilters/utils';
import { DateRange } from '~/components/ui/DateRangePicker';

const DEFAULT_YEAR_RANGE = 7;

const getCustomDateRangeFieldConfig = (
  customDateRangeFieldContent?: ControlFieldContent,
  defaultFieldValue?: DateRange,
): ControlFieldConfig['customDateRange'] => ({
  defaultValues: defaultFieldValue,
  disableFuture: true,
  labels: {
    endDate: customDateRangeFieldContent?.controlFieldLabels.find(label => label.key === 'endDate')?.value ?? '',
    startDate: customDateRangeFieldContent?.controlFieldLabels.find(label => label.key === 'startDate')?.value ?? '',
    invalidDateRangeErrorLabel:
      customDateRangeFieldContent?.controlFieldLabels.find(label => label.key === 'invalidDateRangeErrorLabel')
        ?.value ?? '',
    startDateErrorLabel:
      customDateRangeFieldContent?.controlFieldLabels.find(label => label.key === 'startDateErrorLabel')?.value ?? '',
    endDateErrorLabel:
      customDateRangeFieldContent?.controlFieldLabels.find(label => label.key === 'endDateErrorLabel')?.value ?? '',
    minDateErrorLabel:
      customDateRangeFieldContent?.controlFieldLabels.find(label => label.key === 'minDateErrorLabel')?.value ?? '',
  },
});

const getDateRangeFieldConfig = (
  dateRangeFieldContent?: ControlFieldContent,
  defaultFieldValue?: string,
): ControlFieldConfig['dateRangeOption'] => ({
  defaultValue: defaultFieldValue,
  items:
    dateRangeFieldContent?.controlFieldOptions?.map(item => ({
      label: item.label,
      value: item.value,
    })) ?? [],
  label: dateRangeFieldContent?.controlFieldLabels.find(label => label.key === 'dateRange')?.value ?? '',
});

const getYearFieldConfig = (
  yearFieldContent?: ControlFieldContent,
  defaultFieldValue?: number,
  yearRange?: number,
  isCurrentYearIncluded = true,
): ControlFieldConfig['yearOption'] => ({
  defaultValue: defaultFieldValue,
  // year as option for last 7 years
  items: Array(yearRange || DEFAULT_YEAR_RANGE)
    .fill(0)
    .map((_, index) => ({
      label: getYear(subYears(new Date(), index + (isCurrentYearIncluded ? 0 : 1))),
      value: getYear(subYears(new Date(), index + (isCurrentYearIncluded ? 0 : 1))),
    })),
  label: yearFieldContent?.controlFieldLabels.find(label => label.key === 'yearOption')?.value ?? '',
});

export const getDefaultFieldValues = (documentCategoryEnum: DocumentCategoryEnum): ControlFieldValues => {
  switch (documentCategoryEnum) {
    case DocumentCategoryEnum.OTHERS:
    case DocumentCategoryEnum.TRADE_CONFIRMATIONS:
      return {
        dateRange: getDateRangeValue(DateRangeOptions.Last30Days),
        dateRangeOption: DateRangeOptions.Last30Days,
      };
    case DocumentCategoryEnum.TAX_REPORTS:
      return {
        dateRange: {
          endDate: endOfYear(subYears(new Date(), 1)),
          startDate: startOfYear(subYears(new Date(), 1)),
        },
        yearOption: getYear(subYears(new Date(), 1)),
      };
    case DocumentCategoryEnum.STATEMENTS:
      return {
        dateRange: {
          endDate: new Date(),
          startDate: startOfYear(new Date()),
        },
        yearOption: getYear(new Date()),
      };
  }
};

export const getDefaultFieldConfig = (
  documentCategoryEnum: DocumentCategoryEnum,
  content?: ControlFieldContent[],
  yearRange?: number,
  isCurrentYearIncluded?: boolean,
): ControlFieldConfig => {
  const customDateRangeFieldContent = content?.find(field => field.key === 'customDateRange');
  const dateRangeFieldContent = content?.find(field => field.key === 'dateRangeOption');
  const yearFieldContent = content?.find(field => field.key === 'yearOption');
  const defaultFieldValues = getDefaultFieldValues(documentCategoryEnum);

  switch (documentCategoryEnum) {
    case DocumentCategoryEnum.OTHERS:
    case DocumentCategoryEnum.TRADE_CONFIRMATIONS:
      return {
        customDateRange: getCustomDateRangeFieldConfig(customDateRangeFieldContent, defaultFieldValues.dateRange),
        dateRangeOption: getDateRangeFieldConfig(dateRangeFieldContent, defaultFieldValues.dateRangeOption),
      };
    case DocumentCategoryEnum.TAX_REPORTS:
    case DocumentCategoryEnum.STATEMENTS:
      return {
        yearOption: getYearFieldConfig(
          yearFieldContent,
          defaultFieldValues.yearOption,
          yearRange,
          isCurrentYearIncluded,
        ),
      };
  }
};
