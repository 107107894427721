import { parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { CashTransfersV2, CashTransfersV2Variables, useCashTransfersV2 } from '../../common/CashTransfer/symphony';
import { getIntegerAttributeValue, ManagedProductStatusesForCashTransfersV2 } from '../../common/CashTransfer/utils';
import { useGetOpsDashboardContentV2 } from '../../contentstack';
import { GetOpsDashboardContentV2 } from '../../contentstack/__generated__/query.v2';
import { toCashTransferStatus } from '../../mappers';
import { SearchContextLiterals, TOO_MANY_SEARCH_RECORDS_ERROR, TransferItem } from '../../types';
import {
  getCurrentPage,
  getLastEditDateAndTime,
  getSearchContext,
  getSortedPartnerOpsTradingSuspensions,
  getTotalPages,
} from '../../utils';
import { getFilterCountMap } from '../content';
import { useGetRecurringCancellationsTableContentV2 } from '../contentstack';

import {
  Field,
  FinancialAccountType,
  ManagedProductStatus,
  MultiCashTransferType,
  OrderType,
  ScheduledTransferStatus,
  TransferFrequencyType,
  TransferType,
} from '~/__generated__';
import { FilterCountMap } from '~/components/Filters/types';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { getPaginationContext } from '~/utils/table';
import { AsyncResult, PaginationContext, SearchContext } from '~/utils/types';

const initialRecordsPerPage = 10;

export interface RecurringCancellationsTabVariables {
  contentOptions: ContentOptions;
  field: Field;
  order: OrderType;
  page: number;
  recordsPerPage: number;
  searchFilter: string | null;
  transferTypes: TransferType[];
  updateRecordsPerPage: (value: number) => void;
}

export interface RecurringCancellationsTabData {
  content: any;
  currentPage: number;
  filterCountMap: FilterCountMap[];
  paginationContext: PaginationContext;
  recurringCancelledItems: TransferItem[];
  refetchRecurringCancellationsData: () => void;
  searchContext: SearchContext;
  searchError: boolean;
  totalPages: number;
}

export const useRecurringCancellationsTabData = ({
  page,
  searchFilter,
  contentOptions,
  field,
  order,
  transferTypes,
  updateRecordsPerPage,
  recordsPerPage,
}: RecurringCancellationsTabVariables): AsyncResult<RecurringCancellationsTabData> => {
  const [state, setState] = useState<AsyncResult<RecurringCancellationsTabData>>({ loading: true });

  const variables: CashTransfersV2Variables = useMemo(
    () => ({
      limit: recordsPerPage || initialRecordsPerPage,
      offset: (page - 1) * (recordsPerPage || initialRecordsPerPage),
      frequency: TransferFrequencyType.RECURRING,
      searchFilter,
      statuses: [ScheduledTransferStatus.PENDING_CANCELLATION],
      field,
      order,
      fetchLatestNote: true,
      transferTypes: transferTypes.map<MultiCashTransferType>(
        (transferType: TransferType): MultiCashTransferType =>
          transferType === TransferType.DEPOSIT
            ? MultiCashTransferType.RECURRING_CASH_DEPOSIT
            : MultiCashTransferType.RECURRING_CASH_WITHDRAWAL,
      ),
      managedProductStatuses: ManagedProductStatusesForCashTransfersV2,
    }),
    [recordsPerPage, page, searchFilter, field, order, transferTypes],
  );

  const {
    data: recurringCancellationsData,
    loading: recurringCancellationsLoading,
    error: recurringCancellationsError,
    refetch: refetchRecurringCancellationsData,
  } = useCashTransfersV2({ variables, errorPolicy: 'all', fetchPolicy: 'no-cache' });

  const {
    data: contentstackData,
    loading: contentstackLoading,
    error: contentstackError,
  } = useGetRecurringCancellationsTableContentV2({
    variables: contentOptions,
  });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContentV2({
    variables: contentOptions,
  });

  useEffect(() => {
    const error = recurringCancellationsError || contentstackError || opsDashboardContentError;
    if (error) {
      setState({
        error,
        loading: false,
      });
    }
  }, [contentstackError, opsDashboardContentError, recurringCancellationsError]);

  useEffect(() => {
    const recurringCancelledItems: TransferItem[] = getRecurringCancellationsItems(
      contentOptions,
      recurringCancellationsData,
      opsDashboardContentData,
    );
    const pageContext = recurringCancellationsData?.multiCashTransfers.paginationContext;
    const limit = pageContext?.limit || null;
    const offset = pageContext?.offset || null;
    const total = pageContext?.total || 0;
    const currentPage = getCurrentPage({ limit, offset });
    const totalPages = getTotalPages({ limit, total });
    const fields = opsDashboardContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const filterContext = recurringCancellationsData?.multiCashTransfers.filterContext;
    const paginationContext = getPaginationContext(
      fields,
      currentPage,
      recordsPerPage || initialRecordsPerPage,
      total,
      updateRecordsPerPage,
    );
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_RECURRING_CANCELLATIONS);
    setState({
      data: {
        recurringCancelledItems,
        currentPage,
        totalPages,
        content: contentstackData,
        paginationContext,
        refetchRecurringCancellationsData,
        filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
        searchContext,
        searchError: recurringCancellationsError?.message === TOO_MANY_SEARCH_RECORDS_ERROR,
      },
      loading: recurringCancellationsLoading || contentstackLoading || opsDashboardContentLoading,
    });
  }, [
    contentstackLoading,
    contentstackData,
    opsDashboardContentLoading,
    opsDashboardContentData,
    contentOptions,
    recurringCancellationsLoading,
    recurringCancellationsData,
    refetchRecurringCancellationsData,
    recurringCancellationsError?.message,
  ]);

  return state;
};

const getRecurringCancellationsItems = (
  contentOptions: ContentOptions,
  data?: CashTransfersV2,
  opsDashboardContent?: GetOpsDashboardContentV2,
): TransferItem[] => {
  if (!data) {
    return [];
  }
  const programeNames = opsDashboardContent?.all_product_name?.items || [];

  return data.multiCashTransfers.transfers.map(item => {
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(item.managedProduct?.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = item.managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = item.managedProduct?.clientParty?.id || '';
    const accountState = getAccountState({
      firstRebalancedOn: item.managedProduct?.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;
    return {
      id: item.id,
      accountNumber: item.managedProduct?.financialAccountNumber || '',
      accountState,
      accountTypeText: getAccountTypeText(
        item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
        opsDashboardContent?.all_account_type?.items || [],
      ),
      amount: parseFloat(item.total.value),
      clientName: `${item.managedProduct?.clientParty?.partyPerson?.givenName ?? ''} ${
        item.managedProduct?.clientParty?.partyPerson?.familyName ?? ''
      }`,
      createdAt: item.createdOn,
      destinationBankAccount: item.bankAccount?.accountNumber || '',
      flags: item.flags,
      frequency: item.frequency,
      managedProductId: item.managedProduct?.id || '',
      notes: item.notes || undefined,
      partyId,
      productName: getAccountProgramText(item.managedProduct?.program, item.managedProduct?.attributes, programeNames),
      rmdPlanId: getIntegerAttributeValue(item.attributes, 'RMD_PLAN_ID'),
      scheduledDate: item.scheduledAt || undefined,
      settlementDate: item.settlementDate || undefined,
      status: toCashTransferStatus(item.status),
      transferType: item.type,
      lastCommentData: item.entityNotes[0]
        ? {
            ...getLastEditDateAndTime(parseISO(item.entityNotes[0].created), contentOptions),
            lastValue: item.entityNotes[0].note,
          }
        : undefined,
      lastCommentPartyId: item.entityNotes[0]?.createdByPartyId ?? undefined,
    };
  });
};
