import React, { ComponentProps } from 'react';

import { OpsDashboardV2 } from '../..';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { OpsDashboardContentV2 } from '../../contentstack';
import { AccountsTabContent, AllAccountsTabContent } from '../../types';
import {
  getAccountNumberOrAccountTypeString,
  getSortedPartnerOpsTradingSuspensions,
  isAccountNumberClickable,
} from '../../utils';
import { GetAccountsContent, TextFields } from '../contentstack';
import { AccountManagedProducts } from '../symphony';

import { BalanceType, FinancialAccountType, ManagedProductStatus } from '~/__generated__';
import { formatBalance } from '~/components/AccountPerformance/utils';
import { TableColumn } from '~/components/ui/BasicTable';
import { Chip } from '~/components/ui/Chip';
import { CopyField } from '~/components/ui/CopyField';
import { Stack } from '~/components/ui/mui';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { getEmail, getFullName } from '~/utils/client';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';
import { ContentstackTableColumn, sortColumnsByColumnOrder } from '~/utils/table';

export const getAccountTabContent = (content: GetAccountsContent | undefined): AccountsTabContent => {
  const item = content?.all_all_accounts_tab?.items?.[0];
  return {
    columns: item?.columns?.column,
  };
};

export const getTableContent = (
  accountsData: AccountManagedProducts[],
  onClientClick: ComponentProps<typeof OpsDashboardV2>['onClientClick'],
  onAccountClick: ComponentProps<typeof OpsDashboardV2>['onAccountClick'],
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboardV2>['getAccountNumberRedirectUrl'],
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboardV2>['getClientNameRedirectUrl'],
  showProductName: boolean,
  textFields: TextFields,
  contentOptions: ContentOptions,
  opsDashboardContentData?: OpsDashboardContentV2,
  activeRowId?: string | undefined,
): AllAccountsTabContent[] => {
  return accountsData.map(account => {
    const clientParty = account.relatedPartiesV2?.filter(v => v.isInitialParty)?.[0].party;
    const programNames = opsDashboardContentData?.all_product_name?.items || [];
    const accountTypeContent = opsDashboardContentData?.all_account_type?.items || [];
    const opsDashboardContent = opsDashboardContentData?.all_ops_dashboard?.items?.[0];
    const copyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_ulid_label');
    const successCopyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_success_label');

    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(account.tradingSuspensions)[0]?.createdAt;
    const accountState = getAccountState({
      firstRebalancedOn: account.firstRebalancedOn ?? undefined,
      financialAccountStatus: account.status,
      suspendedOn: latestOpsTradingSuspension,
    }).state;

    const accountBalance = `${parseFloat(
      account.balances?.find(item => item.type === BalanceType.TOTAL_ACCOUNT)?.balance.value ?? '0',
    )}`;
    const accountNumber = account.closedAccountNumber || account.financialAccountNumber;
    return {
      rowKey: account.id,
      accountNumber: (
        <AccountNumber
          accountNumber={accountNumber}
          accountNumberText={getAccountNumberOrAccountTypeString(
            accountNumber,
            getAccountTypeText(
              account.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
              accountTypeContent,
            ),
          )}
          label={showProductName ? getAccountProgramText(account.program, account.attributes, programNames) : null}
          onClick={
            clientParty?.id && isAccountNumberClickable(accountState)
              ? () => onAccountClick(clientParty.id ?? '', account.id, AccountDetailsTabsEnum.overview)
              : undefined
          }
          redirectUrl={
            clientParty?.id && isAccountNumberClickable(accountState)
              ? getAccountNumberRedirectUrl(clientParty.id, account.id)
              : undefined
          }
        />
      ),
      clientName: (
        <ClientName
          clientName={getFullName(clientParty?.partyPerson)}
          onClick={() => onClientClick(clientParty?.id ?? '')}
          redirectUrl={clientParty?.id ? getClientNameRedirectUrl(clientParty.id) : undefined}
        />
      ),
      createdAt: account.assignedOn ? formatDate(account.assignedOn) : '-',
      accountValue: formatBalance(accountBalance, contentOptions),
      email: getEmail(clientParty),
      status: (
        <Stack direction="row">
          <Chip
            label={findFieldValue(textFields || [], account.status.toLowerCase())}
            sx={{
              backgroundColor: 'primary.selected',
              color: 'primary.main',
              borderRadius: 1,
              width: '100%',
              '&:hover': { backgroundColor: 'primary.selected', color: 'primary.main' },
            }}
            variant="filled"
          />
          <Stack justifyContent="center" sx={{ width: '30px', px: 1, fontSize: 16 }}>
            {activeRowId === account.id && (
              <CopyField
                copyLabel={copyLabel}
                defaultState
                disableToggling
                successLabel={successCopyLabel}
                textToCopy={account.id}
              />
            )}
          </Stack>
        </Stack>
      ),
    };
  });
};

export const getTableColumns = (contentData: GetAccountsContent | undefined, onSort: () => void) => {
  const accountsTableContent = getAccountTabContent(contentData);
  // Filter empty columns
  const contentstackTableColumn =
    accountsTableContent.columns?.reduce<ContentstackTableColumn[]>((acc, column: ContentstackTableColumn | null) => {
      if (column) {
        acc.push(column);
      }

      return acc;
    }, []) ?? [];

  const sortedColumns = sortColumnsByColumnOrder(contentstackTableColumn);

  return sortedColumns.map(
    (col): TableColumn => ({
      title: col.column_value,
      key: col.column_id ?? '',
      ...(col.column_id === 'createdAt'
        ? {
            onSort: (_: string) => () => {
              onSort();
            },
          }
        : {}),
    }),
  );
};

export const statusFilterToManagedProductStatus = (appliedFilters: string[]): ManagedProductStatus[] => {
  return appliedFilters as ManagedProductStatus[];
};
