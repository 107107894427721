import React, { ComponentProps, useCallback, useMemo } from 'react';

import { AccountActions, ActionName, CustomActionArgs } from '.';
import { performAccountAction } from './utils';

import { FinancialAccountType, ManagedProductType } from '~/__generated__';
import { KebabMenu, KebabMenuItem } from '~/components/KebabMenu';
import { Box } from '~/components/ui/mui';
import { AccountData } from '~/containers/AccountSummary/types';
import { ManagedProductAttributes } from '~/hooks/account-details/symphony';
import { AccountState, AccountStateData } from '~/utils/account';

export interface Props {
  accountAttributes: ManagedProductAttributes[];
  accountState: AccountState;
  accountStateData?: AccountStateData;
  accountType: FinancialAccountType;
  allAccounts?: Pick<AccountData, 'status' | 'program'>[];
  ariaLabel?: string;
  config: ComponentProps<typeof AccountActions>['config'];
  customActionArgs: CustomActionArgs;
  dataQa?: string;
  items: Array<{
    key?: ActionName | null;
    label?: string | null;
  }>;
  managedProductType?: ManagedProductType;
  menuButtonDisabled?: boolean;
  modalLaunchers: {
    [key in ActionName]?: () => void;
  };
  partyId: string;
  quarterlyPerformanceAction?: {
    quarterlyPerformanceViewOption: string;
    setPerformanceViewOption: (option: string) => void;
  };
}

export const AccountActionsMenu: (props: Props) => JSX.Element = ({
  allAccounts,
  ariaLabel,
  dataQa = 'account-actions-menu',
  accountState,
  accountStateData,
  accountType,
  accountAttributes,
  items,
  config,
  customActionArgs,
  managedProductType,
  modalLaunchers,
  menuButtonDisabled,
  partyId,
  quarterlyPerformanceAction,
}) => {
  const onMenuItemClick = useCallback(
    (actionName: ActionName) => {
      const accountAction = config[actionName];
      performAccountAction({
        actionName,
        accountAction,
        customActionArgs,
        modalLaunchers,
        quarterlyPerformanceAction,
      });
    },
    [config, customActionArgs, modalLaunchers, quarterlyPerformanceAction],
  );

  const menuItems = useMemo(
    () =>
      items.reduce<KebabMenuItem[]>((acc, { key, label }) => {
        const accountAction = config[key as ActionName];
        if (!key || !accountAction) {
          console.warn(
            `No account action found with key ${key}\nFound these keys instead: ${Object.keys(config).join(', ')}`,
          );
          return acc;
        }
        if (!label) {
          console.warn(`Invalid label value '${label}' for key ${key}`);
          return acc;
        }
        const valid = accountAction.valid ?? (() => true);
        if (
          valid({
            type: accountType,
            state: accountState,
            stateData: accountStateData,
            attributes: accountAttributes,
            managedProductType,
            allAccounts,
          })
        ) {
          acc.push({
            callback: () => onMenuItemClick(key),
            dataHeap: `${key}-${partyId}`,
            text: label,
          });
        }
        return acc;
      }, []),
    [
      accountAttributes,
      accountState,
      accountStateData,
      accountType,
      allAccounts,
      config,
      items,
      managedProductType,
      onMenuItemClick,
      partyId,
    ],
  );

  return (
    <Box data-qa={dataQa}>
      {menuItems.length > 0 && (
        <KebabMenu
          ariaLabel={ariaLabel}
          buttonProps={{ disabled: menuButtonDisabled, sx: { ml: 1 }, variant: 'outlined' }}
          dataQa={dataQa}
          items={menuItems}
        />
      )}
    </Box>
  );
};
