import { useEffect, useRef } from 'react';

// This hook allows useInterval to be used in components. Without it, the NodeJS.Timeout reference returned by useInterval
// gets reset during each render, so you can't tell if the timer is still running or cancel it.
export const useInterval = (callback: (...args: any[]) => void, delay: number | null) => {
  const savedCallback = useRef<(...args: any[]) => void>();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
      savedCallback?.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [delay]);
};
