import React, { ComponentProps, useEffect, useMemo, useState } from 'react';

import { OpsDashboard } from '..';
import { CommentComponentColumn } from '../common/Comment';
import { OpsDashboardUser } from '../utils';

import { ErrorsStatusFilter, ErrorsStatusFilters } from './ErrorsStatusFilter';
import { recordsPerPage, sortFieldToSymphony, useGetErrorsTable } from './hooks/useGetErrorsTable';
import { useUpdateFailures } from './symphony';
import { ErrorsFailureContent } from './types';
import { getTableContent, tableErrorsFactory } from './utils';

import { EntityType, OrderType } from '~/__generated__';
import { AccountErrorsModal } from '~/components/modals/AccountErrors';
import { NullStateProps } from '~/components/NullState';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable, SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Grid } from '~/components/ui/mui';
import { Snackbar, SnackbarPositionType } from '~/components/ui/Snackbar';
import { Typography } from '~/components/ui/Typography';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { ContentstackTableColumn, sortColumnsByColumnOrder } from '~/utils/table';

export interface Props {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  dataQa?: string;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onUpdate: () => void;
}

export const ErrorsTable: React.FC<Props> = ({
  contentOptions,
  currentUser,
  commentColumn,
  dataQa = 'errors-table',
  nullStateConfig,
  onAccountClick,
  onClientClick,
  getAccountNumberRedirectUrl,
  getClientNameRedirectUrl,
  onUpdate,
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [tableData, setTableData] = useState<TableData[] | null>();
  const [modalRowData, setModalRowData] = useState<ErrorsFailureContent | null>();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [errorEntity, setErrorEntity] = useState<string | null>();
  const [errorId, setErrorId] = useState<string | null>();
  const [statusFilter, setStatusFilter] = useState<ErrorsStatusFilters>(ErrorsStatusFilters.ALL);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    order: OrderType.DESCENDING,
    field: 'dateCreated',
  });
  const [tableColumns, setTableColumns] = useState<TableColumn[] | null>();
  const [updateFailures, { loading: updateFailuresLoading }] = useUpdateFailures();

  const { loading, data, error } = useGetErrorsTable({
    contentOptions,
    pageNumber,
    sortConfig,
    statusFilter,
  });

  const {
    open: isOpenAccountErrorsModal,
    openModal: openAccountErrorsModal,
    onClose: closeAccountErrorsModal,
  } = useModalState();

  const handleOpenModal = (row: ErrorsFailureContent) => {
    setModalRowData(row);
    openAccountErrorsModal();
  };

  const onSuccessCallback = () => {
    onUpdate();
    data?.refetchErrorsData?.();
  };

  const toggleErrorResolve = async (entity: EntityType, failureId: string, isResolved: boolean) => {
    try {
      await updateFailures({
        variables: {
          entity,
          failureId,
          isResolved,
        },
      });
      onSuccessCallback();
    } catch (err) {
      console.error(err);
    }
  };

  const handleResolveClick = async (entity: EntityType, failureId: string) => {
    setErrorEntity(entity);
    setErrorId(failureId);
    toggleErrorResolve(entity, failureId, true);
    setShowSnackbar(true);
  };

  const handleUndo = async () => {
    toggleErrorResolve(errorEntity as EntityType, `${errorId}`, false);
    setShowSnackbar(false);
  };

  const onSort = (field: string) => () => {
    const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
    setSortConfig({ order: toggleOrder, field });
    setPageNumber(1);
  };

  const handleStatusFilterChange = (newStatusFilter: ErrorsStatusFilters) => {
    setStatusFilter(newStatusFilter);
    setPageNumber(1);
  };

  const treatedColumns = (sortedColumns: ContentstackTableColumn[]) => {
    return sortedColumns.map((col: { column_id: any; column_value: any }) => ({
      title: col?.column_value,
      key: col?.column_id,
      onSort: sortFieldToSymphony.get(col?.column_id) ? onSort : undefined,
    }));
  };

  useEffect(() => {
    if (data?.contentData?.columns) {
      const sortedColumns = sortColumnsByColumnOrder(data.contentData.columns);
      const columns = treatedColumns(sortedColumns);
      setTableColumns(columns as TableColumn[] | undefined);
    }
  }, [data?.contentData?.columns, sortConfig]);

  const errorsDataTreated = useMemo(
    () =>
      tableErrorsFactory(
        data?.errorsData?.failures.failures || [],
        contentOptions,
        data?.contentData?.failure_types ?? [],
      ),
    [data?.errorsData, data?.contentData],
  );
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;

  useEffect(() => {
    if (data?.errorsData?.failures?.failures) {
      const errorsTableContent = getTableContent({
        contentOptions,
        currentUser,
        data,
        handleOpenModal,
        handleResolveClick,
        mpData: errorsDataTreated,
        onAccountClick,
        onClientClick,
        getClientNameRedirectUrl,
        getAccountNumberRedirectUrl,
        updateFailuresLoading,
        commentColumn,
        errorId,
        showProductName,
      });
      setTableData(errorsTableContent);
      if (data?.errorsData?.failures?.paginationContext?.total) {
        const numberOfPages = Math.ceil((data?.errorsData?.failures.paginationContext.total ?? 0) / recordsPerPage);
        setTotalPages(numberOfPages);
      }
    }
  }, [data?.errorsData, sortConfig, updateFailuresLoading, data?.isRefetchingErrorsData]);

  return (
    <Grid container data-qa={dataQa} direction="column" spacing={3}>
      {(loading || error) && (!data?.errorsData || !data?.contentData) ? (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading error items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      ) : (
        <>
          <Grid data-qa={`${dataQa}-filter-container`} item sx={{ display: 'flex', justifyContent: 'end' }} xs={5}>
            <Typography sx={{ marginRight: 2, alignSelf: 'center' }} variant="subtitle2">
              Filter by Status:
            </Typography>
            <ErrorsStatusFilter
              currentStatusFilter={statusFilter}
              displayStrings={{
                statusFilter: {
                  ALL: data?.contentData?.status_filter?.all ?? '',
                  OPEN: data?.contentData?.status_filter?.open ?? '',
                  RESOLVED: data?.contentData?.status_filter?.resolved ?? '',
                },
              }}
              onChange={handleStatusFilterChange}
            />
            <br />
          </Grid>
          <Grid data-qa={`${dataQa}-container`} item xs={5}>
            {tableData && tableColumns && (
              <BasicTable
                alignItems="left"
                columns={tableColumns}
                currentPage={pageNumber}
                data={tableData}
                nullStateConfig={nullStateConfig}
                onPageChange={setPageNumber}
                showPagination
                sortConfig={sortConfig}
                totalPages={totalPages}
              />
            )}
            <AccountErrorsModal
              contentOptions={contentOptions}
              errorsRowData={modalRowData}
              onClose={closeAccountErrorsModal}
              open={isOpenAccountErrorsModal}
            />
          </Grid>
        </>
      )}
      {showSnackbar && !loading && !updateFailuresLoading && (
        <Snackbar
          autoHideDuration={10000}
          feedbackMessage={data?.contentData?.feedback_message ?? ''}
          handleUndo={handleUndo}
          onClose={() => setShowSnackbar(false)}
          snackbarPosition={SnackbarPositionType.BOTTOM_LEFT}
          undoButtonLabel="Undo"
        />
      )}
    </Grid>
  );
};
