import * as React from 'react';
import { ComponentProps, useCallback, useEffect, useState } from 'react';

import { useGetTaxLossHarvestingContent } from './contentstack';
import { useGetHarvestLosses, useSaveHarvestLosses } from './symphony';

import { Alert } from '~/components/ui/Alert';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { Modal } from '~/components/ui/Modal';
import { ModalActionsLayout } from '~/components/ui/Modal/ModalActionsLayout';
import { Box, Checkbox, FormControlLabel, LoadingButton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  isRefetching?: boolean;
  onClose?: (shouldRefetchAccounts?: boolean) => void;
  onFinish?: () => void;
  partyId: string;
};

export const TaxLossHarvestingModal: React.FC<Props> = ({
  contentOptions,
  dataQa = 'tax-loss-harvesting-modal',
  isRefetching = false,
  onClose,
  onFinish,
  open,
  partyId,
}) => {
  const {
    sfTlhModal: { styles: sfTlhModalStyles, typographyVariants },
  } = useTheme();
  const isMediumScreen = useIsMediumScreen();

  const { data: contentstackData, loading: loadingContent, error: contentError } = useGetTaxLossHarvestingContent({
    skip: !open,
    variables: contentOptions,
  });

  const { data: harvestLossesData, loading: harvestLossesLoading, error: harvestLossesError } = useGetHarvestLosses({
    variables: {
      partyId,
    },
    fetchPolicy: 'no-cache', // Required to prevent all graphql queries on the page from running again when lazy query is invoked
    skip: !open,
  });

  const [isBusy, setIsBusy] = useState(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [TLHStep, setTLHStep] = useState(0);
  const [saveHarvestLosses] = useSaveHarvestLosses();

  const contentAllTLHModal = contentstackData?.all_tax_loss_harvesting_modal?.items?.[0];
  const contentTurnOnModal = contentAllTLHModal?.turn_on_tlh_modal;
  const contentTurnOffModal = contentAllTLHModal?.turn_off_tlh_modal;
  const isTLHEnabled = harvestLossesData?.client?.kyc?.harvestLosses === true;

  const isLastPage =
    (isTLHEnabled && TLHStep + 1 === contentTurnOffModal?.length) ||
    (!isTLHEnabled && TLHStep + 1 === contentTurnOnModal?.length);

  useEffect(() => {
    // Only trigger this action when modal is closed
    if (open === false) {
      // timeout is to prevent the split second display of step 0 when modal is closing
      setTimeout(() => setTLHStep(0), 1000);
    }
  }, [open]);

  const onCloseCallback = (isBack?: boolean | null) => {
    setIsCheckboxChecked(false);
    if (isBack) {
      setTLHStep(TLHStep - 1);
    } else {
      onClose?.(isLastPage);
    }
  };

  const onSubmit = useCallback(async () => {
    setIsBusy(true);
    try {
      const saveHarvestLossesResult = await saveHarvestLosses({
        variables: { harvestLosses: !isTLHEnabled, partyId },
      });
      if (!saveHarvestLossesResult.data?.saveHarvestLosses?.success) {
        setErrorSaving(Error('Error saving'));
      } else {
        onFinish?.();
      }
    } catch (err: any) {
      // TODO DA2-512: Log error to sentry
      setErrorSaving(err);
      throw new Error('Unable to save harvestLosses with saveHarvestLoss mutation');
    }
    setIsBusy(false);
  }, [saveHarvestLosses, isTLHEnabled]);

  const onNext = () => {
    setIsBusy(true);
    if (
      // We use +2 here as we want to trigger the submission on the 2nd last page of the modal before confirmation
      (!isTLHEnabled && TLHStep + 2 === contentTurnOnModal?.length) ||
      (isTLHEnabled && TLHStep + 2 === contentTurnOffModal?.length)
    ) {
      onSubmit().then(() => setTLHStep(tlhStep => tlhStep + 1));
    } else {
      setTLHStep(tlhStep => tlhStep + 1);
    }
    setIsBusy(false);
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const currentModalContent = isTLHEnabled ? contentTurnOffModal?.[TLHStep] : contentTurnOnModal?.[TLHStep];
  const otherButtons = contentAllTLHModal?.footer && (
    <RteContent
      data={contentAllTLHModal.footer}
      data-qa={`${dataQa}-footer`}
      sx={{
        ...sfTlhModalStyles.links,
      }}
    />
  );
  const secondaryButtons = currentModalContent?.cta_secondary && (
    <LoadingButton
      data-qa={`${dataQa}-cta-secondary`}
      fullWidth={isMediumScreen}
      loading={isRefetching}
      onClick={() => {
        onCloseCallback(currentModalContent.is_back_button);
      }}
      variant="outlined"
    >
      {currentModalContent.cta_secondary}
    </LoadingButton>
  );
  const primaryButtons = currentModalContent?.cta_primary && (
    <LoadingButton
      data-qa={`${dataQa}-cta-primary`}
      disabled={currentModalContent.checkbox_text !== '' && !isCheckboxChecked}
      fullWidth={isMediumScreen}
      loading={isBusy}
      onClick={onNext}
      variant="contained"
    >
      {currentModalContent.cta_primary}
    </LoadingButton>
  );
  return (
    <Modal
      actions={
        <ModalActionsLayout
          otherActions={otherButtons}
          primaryActions={primaryButtons}
          secondaryActions={secondaryButtons}
        />
      }
      content={
        loadingContent || harvestLossesLoading ? (
          <CircularProgress />
        ) : contentError || harvestLossesError ? (
          <Alert contentOptions={contentOptions} error={contentError || harvestLossesError} severity="error" />
        ) : (
          <Box sx={sfTlhModalStyles.links}>
            {currentModalContent?.iconConnection?.edges?.[0]?.node?.url && (
              <img src={currentModalContent.iconConnection.edges[0].node.url} />
            )}
            <Typography
              component="p"
              data-qa={`${dataQa}-heading`}
              sx={{ py: 2 }}
              variant={isLastPage ? typographyVariants?.lastPageHeading : typographyVariants?.modalHeading}
            >
              {currentModalContent?.heading}
            </Typography>
            {currentModalContent?.body && (
              <RteContent data={currentModalContent.body} data-qa={`${dataQa}-footer`} sx={sfTlhModalStyles.body} />
            )}
            {currentModalContent?.checkbox_text && (
              <FormControlLabel
                control={<Checkbox onChange={handleCheckBoxChange} />}
                data-qa={`${dataQa}-checkbox`}
                label={<RteContent data={currentModalContent.checkbox_text} />}
                sx={{ my: 3 }}
              />
            )}
            {errorSaving && (
              <Alert contentOptions={contentOptions} error={errorSaving} severity="error">
                There was an error saving the Tax Loss Harvesting choice.
              </Alert>
            )}
            {contentAllTLHModal?.disclaimer && (
              <Typography
                data-qa={`${dataQa}-disclaimer`}
                sx={sfTlhModalStyles.disclosure}
                variant={typographyVariants?.modalDisclosure}
              >
                {contentAllTLHModal.disclaimer}
              </Typography>
            )}
          </Box>
        )
      }
      data-qa={dataQa}
      onClose={() => onCloseCallback()}
      open={open}
      title={contentAllTLHModal?.modal_title}
    />
  );
};
