import { UpdateFailures, UpdateFailuresVariables } from './__generated__/mutation.v2';
import { GetErrors, GetErrors_failures_failures, GetErrorsVariables } from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ErrorsFailure = GetErrors_failures_failures;

export const useGetErrors = (
  options?: QueryHookOptions<GetErrors, GetErrorsVariables>,
): QueryResult<GetErrors, GetErrorsVariables> => {
  return useSymphonyQuery(queries.GetErrors, options);
};

export const useUpdateFailures = (options?: MutationHookOptions<UpdateFailures, UpdateFailuresVariables>) => {
  return useSymphonyMutation(mutations.UpdateFailures, options);
};
