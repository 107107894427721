import axios, { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import React, { ComponentProps } from 'react';

import { TableSection } from '../../components/TableSection';
import { TableColumns } from '../../components/TableSection/types';
import { ClientDocument } from '../../symphony';

import { TableData } from '~/components/ui/BasicTable';
import { Button } from '~/components/ui/mui';
import { ContentOptions } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

type TableSectionContent = ComponentProps<typeof TableSection>['content'];

const styles = { downloadButtonSx: { display: 'flex', ml: 'auto' } };

const onDownloadClick = async (url: string, name: string, onDownloadError?: (error: string) => void) => {
  try {
    const response: AxiosResponse<ArrayBuffer> = await axios.get(url, {
      responseType: 'arraybuffer',
    });

    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create an anchor element and click it to trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(pdfBlob); // Create a URL for the Blob
    link.setAttribute('download', name);

    link.click();
  } catch (error) {
    onDownloadError?.(error as string);
  }
};

export const getFormattedRows = ({
  content,
  contentOptions,
  downloadDocumentBaseUrl,
  onDownloadError,
  unformattedData,
}: {
  content?: TableSectionContent;
  contentOptions: ContentOptions;
  downloadDocumentBaseUrl: string;
  onDownloadError?: (error: string) => void;
  unformattedData?: ClientDocument[] | null;
}): TableData[] | undefined =>
  unformattedData?.map(document => {
    return {
      rowKey: document.documentId,
      [TableColumns.Date]: formatDate(parseISO(document.created), 'MM/dd/yyyy', {
        locale: contentOptions.locale,
      }),
      [TableColumns.Description]: document.displayName,
      [TableColumns.Ticker]: document.ticker,
      [TableColumns.Download]: (
        <Button
          data-qa={`download-button-${document.displayName}`}
          endIcon={<img alt="download document" src={content?.downloadIconContent.downloadIconUrl} />}
          onClick={() =>
            onDownloadClick(`${downloadDocumentBaseUrl}${document.downloadPath}`, document.displayName, onDownloadError)
          }
          sx={styles.downloadButtonSx}
          variant="text"
        >
          {content?.downloadIconContent.downloadAction}
        </Button>
      ),
    };
  }) ?? [];
