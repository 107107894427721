import {
  CreatePlaidAccessToken,
  CreatePlaidAccessTokenVariables,
  CreatePlaidLinkToken,
  CreatePlaidLinkTokenVariables,
  RefreshPlaidAccessToken,
  RefreshPlaidAccessTokenVariables,
} from './__generated__/mutation.v2';
import * as mutations from './mutation.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useCreatePlaidLinkToken = (
  options?: MutationHookOptions<CreatePlaidLinkToken, CreatePlaidLinkTokenVariables>,
): MutationTuple<CreatePlaidLinkToken, CreatePlaidLinkTokenVariables> => {
  return useSymphonyMutation(mutations.CreatePlaidLinkToken, options);
};

export const useCreatePlaidAccessToken = (
  options?: MutationHookOptions<CreatePlaidAccessToken, CreatePlaidAccessTokenVariables>,
): MutationTuple<CreatePlaidAccessToken, CreatePlaidAccessTokenVariables> => {
  return useSymphonyMutation(mutations.CreatePlaidAccessToken, options);
};

export const useRefreshPlaidAccessToken = (
  options?: MutationHookOptions<RefreshPlaidAccessToken, RefreshPlaidAccessTokenVariables>,
): MutationTuple<RefreshPlaidAccessToken, RefreshPlaidAccessTokenVariables> => {
  return useSymphonyMutation(mutations.RefreshPlaidAccessToken, options);
};
