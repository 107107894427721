import { Ctas, Labels } from '../types';

import { PendingChangeContent } from './Account/PendingChangeCards/PendingChangeCard';

export const getPendingModelChangeContent = ({
  ctas,
  labels,
  message,
}: {
  ctas: Ctas;
  labels: Labels;
  message: string;
}): PendingChangeContent => ({
  header: labels.pendingModelChange,
  message,
  genericMessage: labels.pendingModelChangeGenericText,
  cta: {
    approve: ctas.approve,
    resendDocusign: ctas.resendDocusign,
    signDocuments: ctas.signDocuments,
  },
  discardPendingChange: {
    title: labels.pendingModelChangeModalTitle,
    discardMessage: labels.pendingModelChangeModalDiscardMessage,
    successfulDiscardMessage: labels.pendingModelChangeModalSuccessfulDiscard,
    ctas: {
      cancel: ctas.pendingModelChangeModalCancel,
      close: ctas.pendingModelChangeModalClose,
      discard: ctas.pendingModelChangeModalDiscard,
    },
  },
  resendEmailSentMessage: labels.resendEmailSentMessage,
});
