import { GetChangeInvestmentMethodModalContent } from './contentstack/__generated__/query.v2';
import { Content } from './hooks/useChangeInvestmentMethodModalContent';

import { InvestmentMethods } from '~/containers/FundingV2/types';

export const getModalContent = (contentData?: GetChangeInvestmentMethodModalContent): Content => {
  const content = contentData?.all_change_investment_method_modal?.items?.[0];
  const labels = content?.labels ?? [];

  const getLabel = (key: string) => {
    return labels.find(l => l?.key === key)?.label ?? '';
  };

  return {
    errorMessages: {
      maxInitialAmount: getLabel('max_initial_amount_error') ?? '',
    },
    heading: getLabel('heading'),
    imageUrl: content?.imageConnection?.edges?.[0]?.node?.url ?? '',
    title: getLabel('modal_title'),
    save: getLabel('save'),
    cancel: getLabel('cancel'),
    labels: {
      initialAmount: getLabel('initial_amount'),
      investmentDuration: getLabel('investment_duration'),
      investmentFrequency: getLabel('investment_frequency'),
      investmentMethod: getLabel('investment_method'),
      totalAmount: getLabel('total_amount'),
    },
    infoMessages:
      content?.info_messages?.reduce((acc, curr) => {
        return { ...acc, [curr?.key ?? '']: curr?.label ?? '' };
      }, {}) ?? {},
    investmentMethods: Object.keys(InvestmentMethods).map(k => ({ label: getLabel(k), value: k })),
    investmentFrequencies:
      content?.investment_frequency?.map(f => ({ label: f?.label ?? '', value: f?.value ?? '' })) ?? [],
    investmentDurations:
      content?.investment_duration?.reduce((acc, d) => {
        const options = d?.dropdown_options?.map(o => ({ label: o?.label ?? '', value: o?.value ?? '' }));
        return { ...acc, [d?.investment_frequency ?? '']: options };
      }, {}) ?? {},
  };
};
