import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ErrorComponent } from '~/components/ErrorComponent';
import {
  LinkManualBrokerageContent,
  useLazyGetBrokeragesContentstack,
  useLazyGetLinkBrokerageAccountContent,
  useLazyGetSupportedBrokerageAliases,
} from '~/components/modals/LinkBrokerageAccount/contentstack';
import { useAddBrokerage } from '~/components/modals/LinkBrokerageAccount/hooks';
import { getBrokerageFromBrokerageName } from '~/components/modals/LinkBrokerageAccount/utils';
import { Alert } from '~/components/ui/Alert';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { Link } from '~/components/ui/Link';
import { Modal } from '~/components/ui/Modal';
import { Autocomplete, Box, Button, Grid, GridProps, Skeleton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { Brokerage, useGetBrokerages } from '~/hooks/brokerage/symphony';
import { requiresExternalAuth } from '~/utils/brokerage';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Props {
  allowManualEntry?: boolean;
  brokerageAlias?: string | null;
  contentOptions: ContentOptions;
  customBrokerageName?: string;
  dataQa?: string;
  isManualBrokerageLookupDisabled?: boolean;
  manualBrokerageAccountName?: string;
  manualBrokerageAccountNumber?: string;
  manualBrokerageEntryContent?: Partial<LinkManualBrokerageContent>;
  onClose?: (newSourceId?: number) => void;
  onManualBrokerageEntry?: (name: string, alias: string, accountNumber: string) => void;
  onManualEntryStartOver?: () => void;
  open?: boolean;
  partyId: string;
  useManualEntry?: boolean;
}

const GridItem = ({ children }: GridProps) => (
  <Grid display="flex" item justifyContent="center" md={4} xs={6}>
    {children}
  </Grid>
);

interface BrokerageAccountFormData {
  password: string;
  sourceBrokerageAccountNumber: string;
  username: string;
}

const usernameFieldName: keyof BrokerageAccountFormData = 'username';
const passwordFieldName: keyof BrokerageAccountFormData = 'password';
const brokerageAccountNameFieldName = 'brokerageAccountName';
const brokerageAccountNumberFieldName: keyof BrokerageAccountFormData = 'sourceBrokerageAccountNumber';
const confirmBrokerageAccountNumberFieldName = 'confirmBrokerageAccountNumber';

export const LinkBrokerageAccountModal: React.FC<Props> = ({
  allowManualEntry = false,
  brokerageAlias = '',
  contentOptions,
  customBrokerageName,
  dataQa = 'link-brokerage-account-modal',
  isManualBrokerageLookupDisabled,
  manualBrokerageAccountName = '',
  manualBrokerageAccountNumber,
  manualBrokerageEntryContent,
  onClose,
  onManualBrokerageEntry,
  onManualEntryStartOver,
  open = false,
  partyId,
  useManualEntry = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [resetManualBrokerageEntry, setResetManualBrokerageEntry] = useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [selectedBrokerageAlias, setSelectedBrokerageAlias] = useState<string | null>(brokerageAlias);
  const [selectedBrokerageName, setSelectedBrokerageName] = useState<string | null>(manualBrokerageAccountName);
  const [brokerageLookupValue, setBrokerageLookupValue] = useState<Brokerage | null>(null);
  const isMediumOrBelowScreen = useIsMediumScreen();
  const [
    getContent,
    { data: contentstackData, loading: contentstackLoading, error: getContentError },
  ] = useLazyGetLinkBrokerageAccountContent({
    variables: contentOptions,
  });
  const {
    components: {
      sfFunding: { brokerageAccountNumberValidationRegex },
    },
  } = useCoreConfig();

  const [
    getSupportedBrokerageAliases,
    {
      data: supportedBrokerageAliasesData,
      loading: supportedBrokerageAliasesLoading,
      error: supportedBrokerageAliasesError,
    },
  ] = useLazyGetSupportedBrokerageAliases({
    variables: contentOptions,
  });

  const { data: brokeragesData, loading: brokeragesLoading, error: getBrokeragesError } = useGetBrokerages({
    skip: !open || isManualBrokerageLookupDisabled,
  });

  const content = contentstackData?.all_link_brokerage_account?.items?.[0];

  const [
    getBrokerageNames,
    { data: brokeragesDataContentStack, loading: brokerageContentStacksLoading, error: getBrokeragesContentStackError },
  ] = useLazyGetBrokeragesContentstack({
    variables: contentOptions,
  });

  const brokerageNames = brokeragesDataContentStack?.all_brokerage_names?.items?.[0]?.brokerages;

  const loadingStates = [
    contentstackLoading,
    brokeragesLoading,
    supportedBrokerageAliasesLoading,
    brokerageContentStacksLoading,
  ];

  const brokeragesList: Brokerage[] = [...(brokeragesData?.brokerages ?? [])].sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
  );

  const brokerages: Brokerage[] = brokeragesList.map(brokerage => {
    const brokerageName = brokerageNames?.find(bn => brokerage.alias === bn?.alias)?.name;
    return brokerageName ? { ...brokerage, name: brokerageName } : brokerage;
  });

  useEffect(() => {
    setBrokerageLookupValue(brokerages.find(b => b.name === selectedBrokerageName) ?? null);
  }, [brokerages, selectedBrokerageName]);

  const theme = useTheme();
  const manualBrokerageNumberFieldDefaultValue =
    resetManualBrokerageEntry || selectedBrokerageName !== manualBrokerageAccountName
      ? ''
      : manualBrokerageAccountNumber;

  const schema = yup.object().shape({
    username: yup.string().default(''),
    password: yup.string().default(''),
    [brokerageAccountNumberFieldName]: yup.string().test(value => {
      return (
        (!!value && !brokerageAccountNumberValidationRegex.test(value)) ||
        !(
          useManualEntry ||
          (((manualBrokerageAccountNumber && !resetManualBrokerageEntry) || loginFormError) && allowManualEntry)
        )
      );
    }),
  });

  const { handleSubmit, errors: formErrors, control, watch } = useForm<BrokerageAccountFormData>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const username = watch(usernameFieldName);
  const password = watch(passwordFieldName);

  const brokerageAccountNumber = watch(brokerageAccountNumberFieldName, manualBrokerageAccountNumber);
  const confirmBrokerageAccountNumber = watch(confirmBrokerageAccountNumberFieldName, manualBrokerageAccountNumber);

  const {
    addBrokerage,
    cancel: cancelAddBrokerage,
    loading: syncing,
    error: loginFormError,
    syncedSourceId,
  } = useAddBrokerage({
    content,
    username,
    password,
    selectedBrokerageAlias: selectedBrokerageAlias ?? '',
    setSelectedBrokerageAlias,
    partyId,
  });

  const isAccountNumberMismatch =
    !!confirmBrokerageAccountNumber && brokerageAccountNumber !== confirmBrokerageAccountNumber;

  const confirmAccountNumberError =
    isAccountNumberMismatch || !!(confirmBrokerageAccountNumber && formErrors.sourceBrokerageAccountNumber);
  const border = `1px solid ${theme.palette.grey[300]}`;

  // Close modal when a source has been successfully synced
  useEffect(() => {
    if (syncedSourceId) {
      onClose?.(syncedSourceId);
    }
  }, [syncedSourceId, onClose]);

  useEffect(() => {
    if (open && !contentstackData) {
      getContent();
      getBrokerageNames();
      getSupportedBrokerageAliases();
    }
  }, [open]);

  useEffect(() => {
    if (getContentError || supportedBrokerageAliasesError) {
      throw getContentError || supportedBrokerageAliasesError;
    }
  }, [getContentError, supportedBrokerageAliasesError]);

  useEffect(() => {
    setLoading(loadingStates.includes(true));
  }, loadingStates);

  const Line = () => <Box sx={{ borderBottom: `1px solid ${theme.palette.text.secondary}`, width: '60px', mx: 1 }} />;

  const showOnlyCustomBrokerage = isManualBrokerageLookupDisabled && !!customBrokerageName;
  const customBrokerage = getBrokerageFromBrokerageName(customBrokerageName ?? '');

  const handleBrokerageButtonClick = (brokerage: string) => {
    setSelectedBrokerageAlias(brokerage);
    setSelectedBrokerageName(brokerages.find(b => b.alias === brokerage)?.name ?? '');
  };

  const onBack = (startOver?: boolean) => {
    if (startOver) {
      setSelectedBrokerageAlias(null);
      setSelectedBrokerageName(null);
      onManualEntryStartOver?.();
      setResetManualBrokerageEntry(true);
    }
    cancelAddBrokerage();
  };

  const onManualEntryCancel = () => {
    const accountNumberExists = brokerageAccountNumber || manualBrokerageAccountNumber;
    if (!accountNumberExists || (accountNumberExists && manualBrokerageAccountName !== selectedBrokerageName)) {
      setBrokerageLookupValue(null);
      setSelectedBrokerageName(null);
      cancelAddBrokerage();
      if (!showOnlyCustomBrokerage) {
        setResetManualBrokerageEntry(true);
      }
    }
    onClose?.();
  };

  const getBrokerageNode = (index: number) => {
    switch (index) {
      case 1:
        return content?.popular_brokerages?.brokerage_1Connection?.edges?.[0]?.node;
      case 2:
        return content?.popular_brokerages?.brokerage_2Connection?.edges?.[0]?.node;
      case 3:
        return content?.popular_brokerages?.brokerage_3Connection?.edges?.[0]?.node;
      case 4:
        return content?.popular_brokerages?.brokerage_4Connection?.edges?.[0]?.node;
      case 5:
        return content?.popular_brokerages?.brokerage_5Connection?.edges?.[0]?.node;
      case 6:
      default:
        return content?.popular_brokerages?.brokerage_6Connection?.edges?.[0]?.node;
    }
  };

  const getBrokerageImageUrl = (alias: string | undefined) => {
    const b = content?.popular_brokerages;

    if (b?.brokerage_1Connection?.edges?.[0]?.node?.description === alias) {
      return b?.brokerage_1Connection?.edges?.[0]?.node?.url;
    }

    if (b?.brokerage_2Connection?.edges?.[0]?.node?.description === alias) {
      return b?.brokerage_2Connection?.edges?.[0]?.node?.url;
    }

    if (b?.brokerage_3Connection?.edges?.[0]?.node?.description === alias) {
      return b?.brokerage_3Connection?.edges?.[0]?.node?.url;
    }

    if (b?.brokerage_4Connection?.edges?.[0]?.node?.description === alias) {
      return b?.brokerage_4Connection?.edges?.[0]?.node?.url;
    }

    if (b?.brokerage_5Connection?.edges?.[0]?.node?.description === alias) {
      return b?.brokerage_5Connection?.edges?.[0]?.node?.url;
    }

    if (b?.brokerage_6Connection?.edges?.[0]?.node?.description === alias) {
      return b?.brokerage_6Connection?.edges?.[0]?.node?.url;
    }

    return null;
  };

  const handleManualBrokerageClick = () => {
    const selectedBrokerageNameToUse = showOnlyCustomBrokerage ? customBrokerage.name : selectedBrokerageName;
    const selectedBrokerageAliasToUse = showOnlyCustomBrokerage ? customBrokerage.alias : selectedBrokerageAlias;

    if (selectedBrokerageNameToUse && selectedBrokerageAliasToUse && brokerageAccountNumber) {
      setSelectedBrokerageName(selectedBrokerageNameToUse);
      setResetManualBrokerageEntry(false);
      onManualBrokerageEntry?.(
        selectedBrokerageNameToUse,
        selectedBrokerageAliasToUse,
        brokerageAccountNumber as string,
      );
    }
    onClose?.();
  };

  const handleSyncClick = () => {
    setIsSubmitted(true);
  };

  const onSuccessCallback = async () => {
    setIsSubmitted(false);

    addBrokerage();
  };

  const onUnsuccessfulCallback = () => {
    setIsSubmitted(false);
  };

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(onSuccessCallback, onUnsuccessfulCallback)();
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (loginFormError) {
      setResetManualBrokerageEntry(false);
    }
  }, [loginFormError]);

  useEffect(() => {
    if (isManualBrokerageLookupDisabled && selectedBrokerageAlias && selectedBrokerageAlias !== customBrokerage.alias) {
      setSelectedBrokerageAlias(null);
      setBrokerageLookupValue(null);
      if (loginFormError) {
        cancelAddBrokerage();
      }
    }
  }, [
    cancelAddBrokerage,
    customBrokerage,
    isManualBrokerageLookupDisabled,
    loginFormError,
    selectedBrokerageAlias,
    setBrokerageLookupValue,
    setSelectedBrokerageAlias,
  ]);

  const credentialsRecoveryUrl =
    brokerages.find(brokerage => brokerage.alias === selectedBrokerageAlias)?.userAndPasswordRecoveryPage[0]?.value ??
    '';

  const showManualEntry =
    useManualEntry ||
    (((manualBrokerageAccountNumber && !resetManualBrokerageEntry) || loginFormError) && allowManualEntry);

  const supportedBrokerageAliases: (string | null)[] =
    supportedBrokerageAliasesData?.all_supported_brokerages?.items?.[0]?.brokerage_alias ?? [];

  const onCloseCallback = selectedBrokerageName && showManualEntry ? onManualEntryCancel : onClose;

  // TODO: Re-use BrokerageLookup from ~/components/BrokerageLookup
  const BrokerageLookup = () => (
    <Autocomplete
      filterOptions={(options, state) => {
        return !state.inputValue
          ? options
          : options.filter(el => el.name.toLowerCase().includes(state.inputValue.toLowerCase()));
      }}
      getOptionLabel={({ name }) => name}
      onChange={(_event: SyntheticEvent, newValue: Brokerage | null) => {
        setSelectedBrokerageAlias(newValue?.alias ?? '');
        setSelectedBrokerageName(newValue?.name ?? '');
        setBrokerageLookupValue(newValue);
      }}
      options={
        supportedBrokerageAliases.length
          ? brokerages.filter(b => supportedBrokerageAliases.includes(b.alias))
          : brokerages // If the list from contentstack is empty, we will use the full list of brokerages
      }
      renderInput={params => (
        <TextField
          {...params}
          data-qa={`${dataQa}-search-input`}
          fullWidth
          placeholder={content?.search_placeholder ?? 'MISSING_SEARCH_PLACEHOLDER'}
          variant="outlined"
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      style={{ width: '100%' }}
      value={brokerageLookupValue}
    />
  );

  const getInputError = (errorDataQa: string, validationMessage?: string | null) => {
    return (
      <Box data-qa={errorDataQa} sx={{ width: 1, position: 'absolute' }}>
        <Typography role="alert" sx={{ color: 'error.main' }} variant="caption">
          {validationMessage ?? ''}
        </Typography>
      </Box>
    );
  };

  return (
    <Modal
      actions={
        (selectedBrokerageName && showManualEntry) || useManualEntry ? (
          <Grid container direction={isMediumOrBelowScreen ? 'column-reverse' : 'row'} justifyContent="end" spacing={2}>
            {!showOnlyCustomBrokerage && (
              <Grid alignSelf="center" item pl={1} sm>
                <Box data-qa={`${dataQa}-start-over`} sx={{ display: 'flex' }}>
                  <Typography variant="body2">{content?.manual_entry?.sync_different_account}</Typography>
                  <Link onClick={() => onBack(true)} sx={{ pl: 0.5 }} variant="body2">
                    {content?.manual_entry?.start_over}
                  </Link>
                </Box>
              </Grid>
            )}

            <Grid item>
              <Button
                data-qa={`${dataQa}-back-button`}
                fullWidth={isMediumOrBelowScreen}
                onClick={onManualEntryCancel}
                variant="outlined"
              >
                {content?.ctas?.cancel ?? 'MISSING_CANCEL_CTA'}
              </Button>
            </Grid>
            {/* TODO: DA2-1338 - Open brokerage sync popup on click of the "Sync Brokerage" button */}
            <Grid item>
              <Button
                data-qa={`${dataQa}-save-and-close`}
                disabled={
                  ((!selectedBrokerageName || !selectedBrokerageAlias || !brokerageAccountNumber) &&
                    !showOnlyCustomBrokerage) ||
                  brokerageAccountNumber !== confirmBrokerageAccountNumber
                }
                fullWidth={isMediumOrBelowScreen}
                onClick={handleManualBrokerageClick}
                variant="contained"
              >
                {content?.manual_entry?.save_close}
              </Button>
            </Grid>
          </Grid>
        ) : selectedBrokerageAlias ? (
          <Grid container direction={isMediumOrBelowScreen ? 'column-reverse' : 'row'} justifyContent="end" spacing={2}>
            <Grid item>
              <Button
                data-qa={`${dataQa}-back-button`}
                fullWidth={isMediumOrBelowScreen}
                onClick={() => onBack()}
                variant="outlined"
              >
                {content?.ctas?.back ?? 'MISSING_BACK_CTA'}
              </Button>
            </Grid>
            {/* TODO: DA2-1338 - Open brokerage sync popup on click of the "Sync Brokerage" button */}
            <Grid item>
              <Button
                data-qa={`${dataQa}-sync-brokerage-button`}
                disabled={!selectedBrokerageAlias || !username || !password}
                fullWidth={isMediumOrBelowScreen}
                onClick={handleSyncClick}
                variant="contained"
              >
                {content?.ctas?.sync_brokerage ?? 'MISSING_SYNC_CTA'}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction={isMediumOrBelowScreen ? 'column-reverse' : 'row'} justifyContent="end" spacing={2}>
            <Grid item>
              <Button
                data-qa={`${dataQa}-cancel-button`}
                fullWidth={isMediumOrBelowScreen}
                onClick={() => onClose?.()}
                variant="outlined"
              >
                {content?.ctas?.cancel ?? 'MISSING_CANCEL_CTA'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                data-qa={`${dataQa}-next-button`}
                disabled={!selectedBrokerageAlias}
                fullWidth={isMediumOrBelowScreen}
                variant="contained"
              >
                {content?.ctas?.next ?? 'MISSING_NEXT_CTA'}
              </Button>
            </Grid>
          </Grid>
        )
      }
      content={
        <Box
          sx={{
            py: isMediumOrBelowScreen ? 1 : 2,
            px: isMediumOrBelowScreen ? 0 : 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {loading ? (
            <>
              <Skeleton height={56} variant="rectangular" width="100%" />
              <Skeleton sx={{ my: 4 }} variant="text" width={180} />
              <Skeleton variant="text" width={250} />
              <Skeleton height={124} sx={{ mt: 2 }} variant="rectangular" width="100%" />
            </>
          ) : syncing ? (
            <Box alignItems="center" display="flex" flexDirection="column" flexGrow={1}>
              <CircularProgress />
              <Box mt={4}>
                <Typography variant="body2">{content?.sync_in_progress}</Typography>
              </Box>
            </Box>
          ) : getBrokeragesError || getBrokeragesContentStackError ? (
            // Test to make sure this looks good
            <ErrorComponent contentOptions={contentOptions} error={getBrokeragesError}>
              {content?.errors?.load_brokerages}
            </ErrorComponent>
          ) : selectedBrokerageAlias && selectedBrokerageAlias !== customBrokerage.alias && !showManualEntry ? (
            requiresExternalAuth(selectedBrokerageAlias) ? (
              // External auth sync page
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <img
                    data-qa={`${dataQa}-external-auth-logo`}
                    height="auto"
                    src={getBrokerageImageUrl('schwab') ?? ''}
                    width={160}
                  />
                  {/* Schwab is the only brokerage that requires this. If that changes in the future, this should be dynamic */}
                  <Link href="https://schwab.com" sx={{ my: 2 }} variant="body2">
                    https://schwab.com
                  </Link>
                </Box>
                <RteContent
                  config={{
                    // Schwab is the only brokerage that requires this. If that changes in the future, this should be dynamic
                    brokerageName: 'Schwab',
                    brokerageServiceName: 'Schwab Security Service',
                  }}
                  data={content?.external_auth_body ?? ''}
                  data-qa={`${dataQa}-security`}
                  gutterBottom
                  sx={{ mt: 2 }}
                />
              </Box>
            ) : (
              // Standard brokerage login form
              <form>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    {getBrokerageImageUrl(selectedBrokerageAlias) ? (
                      <img
                        alt={selectedBrokerageAlias}
                        data-qa={`${dataQa}-security-logo`}
                        height="auto"
                        src={getBrokerageImageUrl(selectedBrokerageAlias) ?? ''}
                        width={160}
                      />
                    ) : null}

                    <Box sx={{ mt: 2 }}>
                      <Controller
                        control={control}
                        name={usernameFieldName}
                        render={({ name, onChange, onBlur, ref, value }) => (
                          <TextField
                            data-qa={`${dataQa}-username-input`}
                            error={!!formErrors.username}
                            fullWidth={isMediumOrBelowScreen}
                            id={name}
                            inputRef={ref}
                            label={content?.username_input_label ?? 'MISSING USERNAME LABEL'}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>

                    <Box sx={{ mt: isMediumOrBelowScreen ? 3 : 2 }}>
                      <Controller
                        control={control}
                        name={passwordFieldName}
                        render={({ name, onChange, onBlur, ref, value }) => (
                          <TextField
                            data-qa={`${dataQa}-password-input`}
                            error={!!formErrors.password}
                            fullWidth={isMediumOrBelowScreen}
                            id={name}
                            inputRef={ref}
                            label={content?.password_input_label ?? 'MISSING PASSWORD LABEL'}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            type="password"
                            value={value}
                          />
                        )}
                      />
                    </Box>

                    {credentialsRecoveryUrl && (
                      <Link href={credentialsRecoveryUrl} sx={{ mt: 1, display: 'block' }} variant="body2">
                        {content?.forgot_credentials_label}
                      </Link>
                    )}

                    <Typography sx={{ mt: 2 }} variant="body2">
                      {content?.encrypted_credentials}
                    </Typography>
                  </Grid>
                  <Grid
                    borderLeft={isMediumOrBelowScreen ? 'none' : border}
                    borderTop={isMediumOrBelowScreen ? border : 'none'}
                    item
                    md={6}
                    ml={isMediumOrBelowScreen ? 3 : 0}
                    mt={isMediumOrBelowScreen ? 3 : 2}
                    xs={12}
                  >
                    <Typography variant="h6">{content?.bank_security_header}</Typography>
                    <Typography sx={{ my: 2 }} variant="body2">
                      {content?.bank_security_body}
                    </Typography>
                    <Typography sx={{ mb: 1 }} variant="body2">
                      {content?.independently_verified}
                    </Typography>
                    <img
                      alt={content?.bank_security_logo_alt ?? ''}
                      data-qa={`${dataQa}-security-logo`}
                      height={60}
                      src={content?.bank_security_logoConnection?.edges?.[0]?.node?.url ?? ''}
                    />
                  </Grid>
                </Grid>

                {loginFormError && (
                  <Alert contentOptions={contentOptions} error={loginFormError} severity="error" sx={{ mt: 2 }} />
                )}
              </form>
            )
          ) : showManualEntry ? (
            <Grid sx={{ textAlign: 'left' }}>
              {!useManualEntry && !manualBrokerageAccountNumber && (
                <Alert severity="error" sx={{ pb: 1 }}>
                  {content?.manual_entry?.error_message}
                </Alert>
              )}
              <Typography sx={{ py: 2 }} variant="h3">
                {showOnlyCustomBrokerage && manualBrokerageEntryContent?.header
                  ? manualBrokerageEntryContent.header
                  : content?.manual_entry?.header}
              </Typography>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  {useManualEntry && !isManualBrokerageLookupDisabled ? (
                    <BrokerageLookup />
                  ) : (
                    <Controller
                      control={control}
                      defaultValue={showOnlyCustomBrokerage ? customBrokerage.name : selectedBrokerageName}
                      name={brokerageAccountNameFieldName}
                      render={({ name, onChange, onBlur, ref, value }) => (
                        <TextField
                          data-qa={`${dataQa}-brokerage-account-name-input`}
                          disabled
                          fullWidth
                          id={name}
                          inputRef={ref}
                          label={
                            showOnlyCustomBrokerage && manualBrokerageEntryContent?.brokerage_account_name
                              ? manualBrokerageEntryContent.brokerage_account_name
                              : content?.manual_entry?.brokerage_account_name
                          }
                          name={name}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ pt: 2, alignItems: 'flex-end' }}>
                <Grid item md={6} xs={12}>
                  <Controller
                    control={control}
                    defaultValue={manualBrokerageNumberFieldDefaultValue}
                    name={brokerageAccountNumberFieldName}
                    render={({ name, onChange, onBlur, ref, value }) => (
                      <TextField
                        data-qa={`${dataQa}-brokerage-account-number-input`}
                        error={!!formErrors.sourceBrokerageAccountNumber}
                        fullWidth
                        id={name}
                        inputRef={ref}
                        label={
                          showOnlyCustomBrokerage && manualBrokerageEntryContent?.brokerage_account_number
                            ? manualBrokerageEntryContent.brokerage_account_number
                            : content?.manual_entry?.brokerage_account_number
                        }
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        type="text"
                        value={value}
                      />
                    )}
                  />
                  {formErrors.sourceBrokerageAccountNumber &&
                    getInputError(
                      `error-${!!formErrors.sourceBrokerageAccountNumber.ref?.name}`,
                      content?.manual_entry?.account_number_invalid_error,
                    )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    control={control}
                    defaultValue={manualBrokerageNumberFieldDefaultValue}
                    name={confirmBrokerageAccountNumberFieldName}
                    render={({ name, onChange, onBlur, ref, value }) => (
                      <TextField
                        data-qa={`${dataQa}-confirm-brokerage-account-number-input`}
                        error={confirmAccountNumberError}
                        fullWidth
                        id={name}
                        inputRef={ref}
                        label={
                          showOnlyCustomBrokerage && manualBrokerageEntryContent?.confirm_brokerage_account_number
                            ? manualBrokerageEntryContent.confirm_brokerage_account_number
                            : content?.manual_entry?.confirm_brokerage_account_number
                        }
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        onPaste={e => {
                          e.preventDefault();
                          return;
                        }}
                        type="text"
                        value={value}
                      />
                    )}
                  />
                  {confirmAccountNumberError &&
                    getInputError(
                      isAccountNumberMismatch
                        ? `${dataQa}-brokerage-account-number-mismatch`
                        : `error-${!!formErrors.sourceBrokerageAccountNumber?.ref?.name}`,
                      isAccountNumberMismatch
                        ? showOnlyCustomBrokerage && manualBrokerageEntryContent?.account_number_mismatch_error
                          ? manualBrokerageEntryContent.account_number_mismatch_error
                          : content?.manual_entry?.account_number_mismatch_error
                        : content?.manual_entry?.account_number_invalid_error,
                    )}
                </Grid>
              </Grid>
              <Typography sx={{ pt: 4 }} variant="body2">
                {content?.manual_entry?.disclaimer}
              </Typography>
            </Grid>
          ) : (
            // Brokerage search page
            <>
              <BrokerageLookup />
              <Box sx={{ my: 4, display: 'flex', alignItems: 'center' }}>
                <Line />
                <Typography>{content?.or_separator_label ?? 'MISSING_OR_LABEL'}</Typography>
                <Line />
              </Box>
              <Typography variant="body2">{content?.choose_brokerage_label ?? 'MISSING_CHOOSE_LABEL'}</Typography>
              <Grid
                alignContent="center"
                alignItems="center"
                container
                justifyContent="center"
                spacing={isMediumOrBelowScreen ? 1 : 2}
                sx={{ mt: isMediumOrBelowScreen ? 2 : 1, mb: 0 }}
              >
                {[1, 2, 3, 4, 5, 6].map(index => (
                  <GridItem key={index}>
                    <Button
                      aria-label={getBrokerageNode(index)?.description ?? ''}
                      onClick={() => handleBrokerageButtonClick(getBrokerageNode(index)?.description ?? '')}
                      sx={{
                        p: 2,
                        height: 46,
                        width: 159,
                        borderRadius: '0px',
                        border,
                      }}
                      variant="outlined"
                    >
                      <img
                        alt=""
                        data-qa={`${dataQa}-brokerage-image-${index}`}
                        src={getBrokerageNode(index)?.url ?? ''}
                        width={isMediumOrBelowScreen ? '80px' : 'unset'}
                      />
                    </Button>
                  </GridItem>
                ))}
              </Grid>
              {content?.security && content.security_url ? (
                <RteContent
                  config={{
                    learnMoreLink: <Link href={content.security_url}>{content.security_link_text}</Link>,
                  }}
                  data={content.security}
                  data-qa={`${dataQa}-security`}
                  sx={{ mt: 2 }}
                  typography="body2"
                />
              ) : null}
            </>
          )}
        </Box>
      }
      data-qa={dataQa}
      maxWidth="sm"
      onClose={() => onCloseCallback?.()}
      open={open}
      title={
        showManualEntry
          ? showOnlyCustomBrokerage && manualBrokerageEntryContent?.modal_title
            ? manualBrokerageEntryContent.modal_title
            : content?.manual_entry?.modal_title
          : content?.header ?? ''
      }
    />
  );
};
