import { useCallback, useEffect, useRef, useState } from 'react';

import { FinancialAccountSource } from '~/__generated__';
import { isValidBankAccount } from '~/utils/account';

export const useGetAddAccountSuccess = (accounts: any[] | undefined) => {
  const [isAccountLinkageSuccessful, setIsAccountLinkageSuccessful] = useState<boolean>();
  // previousBankAccounts will be used to store the initial state of bankAccounts prior to re-fetch of data
  const previousBankAccounts = useRef(-1);
  // filter plaid linked brokerage accounts using isManualEntry false and bank account using source equals to Plaid.
  const plaidConnectedAccounts = accounts?.filter(
    account =>
      (account?.source === FinancialAccountSource.PLAID && isValidBankAccount(account)) ||
      account?.isManualEntry === false,
  );

  const set = useCallback(() => (previousBankAccounts.current = plaidConnectedAccounts?.length ?? -1), [
    plaidConnectedAccounts,
  ]);
  const reset = () => {
    setIsAccountLinkageSuccessful(false);
    previousBankAccounts.current = -1;
  };
  // This useEffect hook compares the prevState to the current state of bankAccounts to check if account linkage was successful.
  useEffect(() => {
    if (!plaidConnectedAccounts) {
      return;
    }

    if (previousBankAccounts.current !== -1 && plaidConnectedAccounts.length > previousBankAccounts.current) {
      setIsAccountLinkageSuccessful(true);
    }
  }, [plaidConnectedAccounts]);

  return {
    isAccountLinkageSuccessful,
    reset,
    set,
  };
};
