import { addDays, differenceInDays, eachDayOfInterval, parseISO, subDays } from 'date-fns';
import { format } from 'date-fns-tz';

import { ReturnsChartData } from '~/components/AccountPerformance/ReturnsChart/hooks/useGetReturnsChartData';
import { Locale } from '~/utils/contentstack';
import { enumLocaleToLocaleMap } from '~/utils/format/date';
import { DailyChartValue } from '~/utils/types';

interface FillMissingDataParameters {
  endDate?: string;
  startDate?: string;
}

export const fillMissingData = (data: DailyChartValue[], { endDate, startDate }: FillMissingDataParameters = {}) => {
  if (!data.length) {
    return data;
  }
  const sortedData = data.sort((a, b) => (parseISO(a.date) > parseISO(b.date) ? 1 : -1));
  const firstDate = parseISO(sortedData[0].date);
  const missingStartData: DailyChartValue[] = [];
  if (startDate !== undefined && parseISO(startDate) < firstDate) {
    const startingAmount = sortedData[0].amount;
    eachDayOfInterval({ start: parseISO(startDate), end: subDays(firstDate, 1) }).map(currentDateInInterval => {
      missingStartData.push({ amount: startingAmount, date: format(currentDateInInterval, 'yyyy-MM-dd') });
    });
  }
  const allData: DailyChartValue[] = missingStartData;
  let previousDate = parseISO(sortedData[0].date);
  let previousAmount = sortedData[0].amount;
  sortedData.forEach(d => {
    const currentDate = parseISO(d.date);
    if (differenceInDays(currentDate, previousDate) > 1) {
      eachDayOfInterval({ start: addDays(previousDate, 1), end: subDays(currentDate, 1) }).map(
        currentDateInInterval => {
          const newDateStr = format(currentDateInInterval, 'yyyy-MM-dd');
          if (!allData.find(ad => newDateStr === ad.date)) {
            allData.push({
              date: newDateStr,
              amount: previousAmount,
            });
          }
        },
      );
    }

    if (!allData.find(ad => d.date === ad.date)) {
      allData.push(d);
      previousDate = parseISO(d.date);
      previousAmount = d.amount;
    }
  });

  const lastDate = parseISO(sortedData[sortedData.length - 1].date);
  if (endDate !== undefined && parseISO(endDate) > lastDate) {
    const lastAmount = sortedData[sortedData.length - 1].amount;
    eachDayOfInterval({ start: addDays(lastDate, 1), end: parseISO(endDate) }).map(currentDate => {
      allData.push({ amount: lastAmount, date: format(currentDate, 'yyyy-MM-dd') });
    });
  }

  return allData;
};

export const formatNowDateString = (locale?: Locale) =>
  format(Date.now(), locale === Locale.fr_ca ? "d MMMM yyyy, à H 'h' m zzz" : 'MMMM d, yyyy h:mm aaa zzz', {
    locale: enumLocaleToLocaleMap(locale),
  });

export const getDateFormatType = (
  data: DailyChartValue[] | ReturnsChartData[],
  showOnlyYearsInChart: boolean | undefined,
) =>
  showOnlyYearsInChart && new Set(data.map(({ date }) => new Date(date).getFullYear())).size > 1 ? 'yyyy' : undefined;

export const getXAxisTicks = (data: DailyChartValue[]) => {
  if (data.length < 5) {
    return data.map(({ date }) => date);
  }
  const start = 0;
  const end = data.length - 1;
  const midpoint = Math.ceil(end / 2);
  const quarterpoint = Math.floor(midpoint / 2);
  const threeQuarterspoint = Math.ceil((midpoint + end) / 2);

  return [data[start], data[quarterpoint], data[midpoint], data[threeQuarterspoint], data[end]].map(({ date }) => date);
};
