import {
  GetAccountNumberLabelsV2,
  GetAccountNumberLabelsV2Variables,
  GetClientNameLabelsV2,
  GetClientNameLabelsV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useAccountNumberLabels = (
  options?: QueryHookOptions<GetAccountNumberLabelsV2, GetAccountNumberLabelsV2Variables>,
): QueryResult<GetAccountNumberLabelsV2, GetAccountNumberLabelsV2Variables> => {
  return useContentstackQuery(queries.GetAccountNumberLabelsV2, options);
};

export const useClientNameLabels = (
  options?: QueryHookOptions<GetClientNameLabelsV2, GetClientNameLabelsV2Variables>,
): QueryResult<GetClientNameLabelsV2, GetClientNameLabelsV2Variables> => {
  return useContentstackQuery(queries.GetClientNameLabelsV2, options);
};
