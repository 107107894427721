import React, { ComponentProps } from 'react';

import { useGetClientCodeContent } from './contentstack';

import { Modal } from '~/components/ui/Modal';
import { Button, Grid } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  clientCode: string;
  clientEmail: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  handleRefresh: () => void;
  handleReset: () => void;
  onClose: () => void;
};

export const ClientCodeModal: React.FC<Props> = ({
  clientCode,
  clientEmail,
  contentOptions,
  dataQa = 'client-code-modal',
  handleRefresh,
  handleReset,
  onClose,
  open,
}) => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetClientCodeContent({
    variables: contentOptions,
    skip: !open,
  });

  const isMediumScreen = useIsMediumScreen();
  const content = contentData?.all_plaid_funding?.items?.[0]?.client_code;

  return (
    <Modal
      actions={
        <Grid container direction={isMediumScreen ? 'column-reverse' : 'row'} spacing={2}>
          <Grid item md xs={12}>
            <Button data-qa={`${dataQa}-reset`} fullWidth={isMediumScreen} onClick={handleReset}>
              {content?.tertiary_cta}
            </Button>
          </Grid>
          <Grid item md="auto" xs={12}>
            <Button
              color="primary"
              data-qa={`${dataQa}-cancel`}
              fullWidth={isMediumScreen}
              onClick={onClose}
              variant="outlined"
            >
              {content?.secondary_cta}
            </Button>
          </Grid>
          <Grid item md="auto" xs={12}>
            <Button
              data-qa={`${dataQa}-refresh`}
              fullWidth={isMediumScreen}
              onClick={handleRefresh}
              variant="contained"
            >
              {content?.primary_cta}
            </Button>
          </Grid>
        </Grid>
      }
      content={
        <Grid container spacing={2} sx={{ px: 4, py: 3 }}>
          {content?.imageConnection?.edges?.[0]?.node?.url && (
            <img alt={`${dataQa}-image`} data-qa={`${dataQa}-image`} src={content.imageConnection.edges[0].node.url} />
          )}
          <Grid item xs={12}>
            <RteContent config={{ clientCode }} data={content?.heading ?? ''} />
          </Grid>
          <Grid item xs={12}>
            <Typography component="span" sx={{ color: '#000000' }} variant="body2">
              {content?.sub_heading}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RteContent config={{ clientEmail }} data={content?.client_email ?? ''} />
          </Grid>
        </Grid>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={contentError}
      loading={contentLoading}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      title={content?.modal_title}
    />
  );
};
