import React, { ComponentProps, useMemo } from 'react';

import { FlagsReferenceContent } from '../../DepositsTab/contentstack';
import { cashTransferToAllowedStatusUpdates } from '../../mappers';
import { TaxWithholdingInfoContent, TransferItem, TransferStatus } from '../../types';
import { getAccountNumberOrAccountTypeString, isAccountNumberClickable, OpsDashboardUser } from '../../utils';
import { Comment, CommentComponentColumn } from '../Comment';
import { AccountNumber } from '../ui/AccountNumber';
import { ClientName } from '../ui/ClientName';

import { FlagEntity, FlagReason, UserNoteEntityType } from '~/__generated__';
import { NullStateProps } from '~/components/NullState';
import { StatusButton } from '~/components/StatusButton';
import { DISTRIBUTION_REASON_CODES } from '~/components/TaxWithholdingForm';
import {
  BasicTable,
  SortConfig,
  TableColumn,
  TableComponent as TableComponentType,
  TableData,
} from '~/components/ui/BasicTable';
import { Box, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { FlagAction } from '~/containers/OpsDashboard/common/FlagAction';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { formatPercentageString } from '~/utils/format';
import { precise } from '~/utils/format/currency';
import { formatDate } from '~/utils/format/date';

export interface Content {
  flags?: FlagsReferenceContent;
  nonRecurringtableHeaders: TableColumn[];
  nonWrappableNonRecurring: string[];
  rmdNote?: string;
  status: Record<TransferStatus, string>;
  taxWithholdingInfo?: TaxWithholdingInfoContent;
  transferToAccountNote: string;
}

export interface CashTransferTableProps {
  TableComponent?: TableComponentType;
  commentColumn?: CommentComponentColumn;
  commentEntity: UserNoteEntityType;
  contentOptions: ContentOptions;
  currentPage: number;
  currentUser: OpsDashboardUser;
  displayStrings: Content;
  flagEntity: FlagEntity;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  items: TransferItem[];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onPageChange: (p: number) => void;
  onSelectNewStatusForItem: (item: TransferItem, selectedStatus: TransferStatus) => void;
  onSort: (field: string) => () => void;
  refetchData: () => void;
  sortConfig: SortConfig;
  totalPages: number;
}

export const NonRecurringSortableFields = ['amount', 'createdAt', 'scheduledDate', 'settlementDate'];

export const NonRecurringCashTransferFlagReasons = [
  FlagReason.HIGH_TRANSACTION_VALUE,
  FlagReason.API_FAILURE,
  FlagReason.OVERDUE,
  FlagReason.POSSIBLE_DUPLICATE,
  FlagReason.OTHER,
];

export const NonRecurringCashTransferTable: React.FC<CashTransferTableProps> = ({
  commentColumn,
  commentEntity,
  contentOptions,
  currentPage,
  currentUser,
  flagEntity,
  nullStateConfig,
  displayStrings,
  items,
  onAccountClick,
  onClientClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  onPageChange,
  onSort,
  onSelectNewStatusForItem,
  sortConfig,
  TableComponent = BasicTable,
  totalPages,
  refetchData,
}) => {
  const columns: TableColumn[] = useColumns(displayStrings, onSort);

  const formattedData: TableData[] = useFormattedData(
    items,
    currentUser,
    contentOptions,
    displayStrings,
    {
      onAccountClick,
      onClientClick,
      refetchData,
      getClientNameRedirectUrl,
      getAccountNumberRedirectUrl,
    },
    onSelectNewStatusForItem,
    commentEntity,
    flagEntity,
    commentColumn,
  );
  return (
    <TableComponent
      alignItems="left"
      columns={columns}
      currentPage={currentPage}
      data={formattedData}
      nullStateConfig={nullStateConfig}
      onPageChange={onPageChange}
      showPagination
      sortConfig={sortConfig}
      totalPages={totalPages}
    />
  );
};

function useFormattedData(
  items: TransferItem[],
  currentUser: OpsDashboardUser,
  contentOptions: ContentOptions,
  displayStrings: CashTransferTableProps['displayStrings'],
  cb: {
    getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
    getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
    onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
    onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
    refetchData: () => void;
  },
  onSelectNewStatusForItem: (item: TransferItem, selectedStatus: TransferStatus) => void,
  commentEntity: UserNoteEntityType,
  flagEntity: FlagEntity,
  commentColumn?: CommentComponentColumn,
): TableData[] {
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  return useMemo(
    () =>
      items.map(item => ({
        ...item,
        rowKey: item.id,
        clientName: (
          <ClientName
            clientName={item.clientName}
            onClick={() => cb.onClientClick(item.partyId)}
            redirectUrl={cb.getClientNameRedirectUrl(item.partyId)}
          />
        ),
        accountNumber: (
          <AccountNumber
            accountNumber={item.accountNumber}
            accountNumberText={getAccountNumberOrAccountTypeString(item.accountNumber, item.accountTypeText)}
            label={showProductName ? item.productName : null}
            onClick={
              isAccountNumberClickable(item.accountState)
                ? () => cb.onAccountClick(item.partyId, item.managedProductId)
                : undefined
            }
            redirectUrl={cb.getAccountNumberRedirectUrl(item.partyId, item.managedProductId)}
          />
        ),
        notes: (
          <Stack>
            <Typography sx={{ color: 'text.secondary', textAlign: 'left' }} variant="body2">
              {item.notes ||
                (item.rmdPlanId
                  ? `${displayStrings.rmdNote} ${item.scheduledDate ? formatDate(item.scheduledDate) : '-'}`
                  : `${displayStrings.transferToAccountNote} ${item.destinationBankAccount}`)}
            </Typography>
            {item.isManagedProductTaxSheltered && item.taxInformation && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2">
                  {`${displayStrings.taxWithholdingInfo?.federalTax}: ${formatPercentageString(
                    item.taxInformation.federalTax ?? '0',
                    { decimals: 0, locale: contentOptions.locale },
                  )}`}
                </Typography>
                <Typography variant="body2">
                  {`${displayStrings.taxWithholdingInfo?.stateTax}: ${formatPercentageString(
                    item.taxInformation.stateTax ?? '0',
                    { decimals: 0, locale: contentOptions.locale },
                  )}`}
                </Typography>
                {item.taxInformation.distributionReason && (
                  <Typography variant="body2">
                    {`${displayStrings.taxWithholdingInfo?.distributionReason}: ${
                      item.taxInformation.distributionReason === DISTRIBUTION_REASON_CODES.NORMAL
                        ? displayStrings.taxWithholdingInfo?.distributionReasonValues.normal
                        : displayStrings.taxWithholdingInfo?.distributionReasonValues.premature
                    }`}
                  </Typography>
                )}
                {item.taxInformation.grossUp !== undefined && (
                  <Typography variant="body2">
                    {`${displayStrings.taxWithholdingInfo?.grossUp}: ${
                      item.taxInformation.grossUp
                        ? displayStrings.taxWithholdingInfo?.grossUpValues.yes
                        : displayStrings.taxWithholdingInfo?.grossUpValues.no
                    }`}
                  </Typography>
                )}
              </Box>
            )}
          </Stack>
        ),
        amount: precise(item.amount),
        createdAt: formatDate(item.createdAt),
        settlementDate: item.settlementDate ? formatDate(item.settlementDate) : '-',
        scheduledDate: item.scheduledDate ? formatDate(item.scheduledDate) : '-',
        status: (
          <StatusButton
            currentStatus={item.status}
            onMenuItemClick={
              item.rmdPlanId ? undefined : newStatus => onSelectNewStatusForItem(item, newStatus as TransferStatus)
            }
            statusLabels={displayStrings.status}
            statusUpdateItems={cashTransferToAllowedStatusUpdates(item.status, false)}
          />
        ),
        actions: (
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Comment
              comment={item.lastCommentData}
              content={commentColumn}
              contentOptions={contentOptions}
              currentUser={currentUser}
              entity={commentEntity}
              entityId={item.id}
              key={item.id}
              lastCommentPartyId={item.lastCommentPartyId}
              refetchData={() => cb.refetchData()}
            />
            <FlagAction
              accountId={item.accountNumber}
              accountTypeText={item.accountTypeText}
              clientName={item.clientName}
              contentOptions={contentOptions}
              entity={flagEntity}
              entityId={item.id}
              flagReasons={NonRecurringCashTransferFlagReasons}
              flags={item.flags}
              flagsContent={displayStrings.flags}
              key={`flags-${item.id}`}
              onCreate={() => cb.refetchData()}
              onResolve={() => cb.refetchData()}
            />
          </Box>
        ),
      })),
    [
      cb,
      commentColumn,
      commentEntity,
      contentOptions,
      currentUser,
      displayStrings,
      flagEntity,
      items,
      onSelectNewStatusForItem,
      showProductName,
    ],
  );
}

// @todo Have to remove once OpsV2 is used instead of Ops
function useColumns(displayStrings: CashTransferTableProps['displayStrings'], onSort?: (field: string) => () => void) {
  return useMemo(() => {
    return displayStrings.nonRecurringtableHeaders
      .filter(v => v.key !== 'schedule')
      .map(({ key, title }) => {
        return {
          key,
          title,
          wrappable: !displayStrings.nonWrappableNonRecurring.includes(key),
          onSort: onSort && NonRecurringSortableFields.includes(key) ? onSort : undefined,
        };
      });
  }, [displayStrings, onSort]);
}
