import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CASH_HOLDING, Holding, HoldingsTableFieldProps } from './utils';

import {
  Checkbox,
  Grid,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { formatCurrency } from '~/utils/format';

const TextTableCell: React.FC<{ sx?: SxProps; text: string }> = ({ sx, text }) => (
  <TableCell>
    <Typography sx={sx}>{text}</Typography>
  </TableCell>
);

export const HoldingsTableField: React.FC<HoldingsTableFieldProps> = ({
  holdings,
  content,
  errorInputs,
  setErrorInputs,
  showUnitsColumn,
  holdingSourceFieldName,
  maskedAccountNumber,
  financialInstitution,
  onChange,
  value,
  totalEstimatedValue,
}) => {
  const [page, setPage] = useState(0);
  const { setError, clearErrors } = useFormContext();
  const totalHoldings = holdings.length;
  const holdingKeys = Object.keys(value ?? {});
  const totalSelectedHoldings = holdingKeys.length;
  const { columns } = content;
  useEffect(() => {
    setPage(0);
  }, [holdings]);
  useEffect(() => {
    if (errorInputs.length) {
      setError(holdingSourceFieldName, { message: content.invalidUnits });
    } else {
      clearErrors();
    }
  }, [errorInputs.length, holdingSourceFieldName, setError, clearErrors, content]);

  const handleOnChange = (input: Record<string, { estimatedValue: number; units: string } | undefined>) => {
    onChange(Object.keys(input).length ? input : undefined);
  };

  const clearHoldingSelection = (id: string) => {
    if (value?.[id]) {
      const remainingHoldings = { ...value };
      delete remainingHoldings[id];
      handleOnChange(remainingHoldings);
      setErrorInputs(prevState => prevState.filter(item => item !== id));
    }
  };

  const handleOnSelect = (holding: 'all' | Holding) => {
    if (holding === 'all') {
      if (holdingKeys.length !== holdings.length) {
        const remainingHoldings: Record<string, { estimatedValue: number; units: string }> = {};
        holdings.forEach(
          el => (remainingHoldings[el.id] = { estimatedValue: el.marketValue, units: el.units.toString() }),
        );
        handleOnChange({ ...remainingHoldings, ...value });
      } else {
        handleOnChange({});
        setErrorInputs([]);
      }
    } else {
      if (holdingKeys.includes(holding.id)) {
        clearHoldingSelection(holding.id);
      } else {
        handleOnChange({
          ...value,
          [holding.id]: { estimatedValue: holding.marketValue, units: holding.units.toString() },
        });
      }
    }
  };

  const handleUnitInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, holding: Holding) => {
    if (isNaN(+e.target.value) || (holding.id !== CASH_HOLDING && e.target.value.includes('.'))) {
      return;
    }
    if (e.target.value === '') {
      clearHoldingSelection(holding.id);
      return;
    }
    const inputValue = parseFloat(e.target.value);
    if (inputValue > 0) {
      handleOnChange({
        ...value,
        [holding.id]: { estimatedValue: holding.marketValuePerUnit * inputValue, units: e.target.value },
      });
      if (inputValue > holding.units && !errorInputs.includes(holding.id)) {
        setErrorInputs(prevState => [...prevState, holding.id]);
        return;
      }

      if (inputValue <= holding.units && errorInputs.includes(holding.id)) {
        setErrorInputs(prevState => prevState.filter(item => item !== holding.id));
      }
    }
  };

  const visibleRows = useMemo(() => {
    return holdings.slice(page * 10, page * 10 + 10);
  }, [holdings, page]);

  const disablePaginationButton = useMemo(() => {
    return !!visibleRows.find(item => errorInputs.includes(item.id));
  }, [errorInputs, visibleRows]);

  return (
    <>
      <TableContainer>
        <Table role="table">
          <TableHead role="tablehead">
            <TableRow role="row">
              <TableCell padding="checkbox">
                <Checkbox
                  checked={totalHoldings === totalSelectedHoldings}
                  indeterminate={!!totalSelectedHoldings && totalHoldings > totalSelectedHoldings}
                  onChange={_ => handleOnSelect('all')}
                />
              </TableCell>
              <TextTableCell text={columns.assets} />
              <TextTableCell text={columns.name} />
              {showUnitsColumn && <TextTableCell text={columns.units} />}
              <TextTableCell sx={{ textAlign: 'end' }} text={columns.estimatedValue} />
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((holding, index) => {
              const error = errorInputs.includes(holding.id);
              const { asset, id, name } = holding;
              return (
                <TableRow key={id} role="row">
                  <TableCell>
                    <Checkbox checked={holdingKeys.includes(id)} onChange={_ => handleOnSelect(holding)} />
                  </TableCell>
                  <TextTableCell text={asset} />
                  {index ? (
                    <TextTableCell text={name} />
                  ) : (
                    <TableCell>
                      <RteContent config={{ maskedAccountNumber, financialInstitution }} data={name} />
                    </TableCell>
                  )}
                  {showUnitsColumn && (
                    <TableCell sx={{ display: 'flex' }}>
                      <TextField
                        error={error}
                        helperText={errorInputs.includes(id) ? content.invalidUnits : null}
                        id={id}
                        onChange={e => handleUnitInput(e, holding)}
                        placeholder={content.placeHolderUnits}
                        value={value?.[id]?.units ?? ''}
                        variant="outlinedClassic"
                      />
                      <RteContent
                        config={{
                          totalUnits: holding.units,
                        }}
                        data={content.unitsCell}
                        sx={{ alignSelf: 'center', ml: 1 }}
                      />
                    </TableCell>
                  )}
                  <TextTableCell
                    sx={{ textAlign: 'end' }}
                    text={
                      errorInputs.includes(id)
                        ? '_'
                        : formatCurrency(showUnitsColumn ? value?.[id]?.estimatedValue ?? 0 : holding.marketValue)
                    }
                  />
                </TableRow>
              );
            })}

            <TableRow role="row">
              <TablePagination
                backIconButtonProps={
                  disablePaginationButton
                    ? {
                        disabled: true,
                      }
                    : undefined
                }
                count={totalHoldings}
                labelDisplayedRows={({ from, to, count }) => (
                  <RteContent
                    component="span"
                    config={{ from, to, count }}
                    data={content.perPageText}
                    variantMapping={{ body2: 'span' }}
                  />
                )}
                nextIconButtonProps={
                  disablePaginationButton
                    ? {
                        disabled: true,
                      }
                    : undefined
                }
                onPageChange={(_, index) => setPage(index)}
                page={page}
                rowsPerPage={10}
                rowsPerPageOptions={[]}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container mt={3}>
        <Grid item xs={6}>
          <RteContent config={{ totalSelectedHoldings, totalHoldings }} data={content.totalSelectedText} />
        </Grid>
        <Grid item textAlign="end" xs={6}>
          <Typography component="div" variant="subtitle1">
            {errorInputs.length ? '_' : totalEstimatedValue}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
