import { add, compareDesc, endOfMonth, endOfYear, isAfter, isWeekend, setMonth, startOfToday } from 'date-fns';
import { range } from 'fp-ts/Array';
import React, { ComponentProps, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';

import { FaPlaidFlow } from '../../FaPlaidFlow';
import { ClientInitiatePlaidModal } from '../ClientInitiatePlaid';
import { ClientLedPlaidSuccessModal } from '../ClientLedPlaidSuccess';
import { datepickerErrorDisplay, DatepickerValidations } from '../utils/datepicker-helper';
import { FormData, WithdrawFundsV2Content } from '../WithdrawFunds/types';

import { DEFAULT_NUMBER_OF_OCCURRENCE } from './hooks/utils';
import { getNumberOfOccurrencesFieldRTEProps } from './utils';
import { WithdrawAmountHelperText } from './WithdrawalAmountHelperText';
import { contentStackNotFound, WithdrawFundsModalContent } from './WithdrawFundsModalContent';

import {
  AccountRestrictionType,
  FinancialAccountType,
  PlaidProduct,
  TransferFrequency,
  TransferFrequencyType,
} from '~/__generated__/symphonyTypes.v2';
import { LinkBankAccountModal } from '~/components/modals/LinkBankAccount';
import { PlaidReAuthentication } from '~/components/modals/PlaidReAuthentication';
import { Alert } from '~/components/ui/Alert';
import { DatePicker } from '~/components/ui/DatePicker';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownWithEditableOptions } from '~/components/ui/Dropdown/DropdownWithEditableOptions';
import { DropdownChangeEvent, DropdownItem } from '~/components/ui/Dropdown/types';
import { HelperTextContent } from '~/components/ui/HelperTextContent';
import { Link } from '~/components/ui/Link';
import { useModalState } from '~/components/ui/Modal/hooks';
import {
  AlertTitle,
  Box,
  FormControl,
  FormHelperTextProps,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { CurrencyTextField } from '~/components/ui/TextField/CurrencyTextField';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';
import { AccountRestriction } from '~/containers/AccountSummary/types';
import { useGetAddAccountSuccess } from '~/hooks/account/useGetAddAccountSuccess';
import { FinancialAccount, Weekday } from '~/hooks/financial-account/symphony';
import { DateOption } from '~/hooks/financial-account/types';
import { PlaidAccessTokenMetaData, PlaidLinkType, usePlaidLinkHook } from '~/hooks/plaid-link';
import {
  BankAccount,
  getAddAccountActions,
  getBankAccountOptions,
  getIsBankAccountAssociationNonVerified,
  getPlaidItemIdIfReAuthenticateFails,
  isRetirementAccountType,
} from '~/utils/account';
import {
  shouldDisableAfterXDays,
  shouldDisableDateAfterMaxWithdrawalDate,
  shouldDisableDateTillAsSoonAsPossibleDate,
  shouldDisableMarketHolidays,
  shouldDisableRestCurrentYearForRetirementAccounts,
  shouldDisableWeekends,
} from '~/utils/client';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { getDaysOfMonthOptions, getDaysOfWeekOptions } from '~/utils/dropdown';
import { concealAccountNumber, formatCurrency, isValidDate, localeToCurrencySymbol } from '~/utils/format';
import { toSymphonyDate } from '~/utils/symphony';

export interface Props {
  accountIdRestrictionsMap?: Record<string, AccountRestriction[]>;
  accountMinimum?: string;
  accountName: string;
  addedFinancialAccount?: BankAccount | null;
  asSoonAsPossibleDate: Date;
  availableFunds: number;
  bankAccounts?: BankAccount[];
  content?: WithdrawFundsModalContent;
  contentOptions: ContentOptions;
  contentV2?: WithdrawFundsV2Content;
  dataQa?: string;
  dateOptionValue?: DateOption;
  defaultWithdrawalAmount?: string;
  financialAccountType?: FinancialAccountType;
  formData?: FormData;
  formHooks: UseFormMethods<FormData>;
  isRaiseCash?: boolean;
  isRefetchingAccounts?: boolean;
  managedProductId: string;
  marketHolidays: string[];
  onLinkAccount?: (account: FinancialAccount) => void;
  onSourceAccountChange?: (managedProductId: string) => void;
  onTransferFrequencyTypeChange: () => void;
  partyId: string;
  partyIdFA?: string;
  plaidLinkageType?: PlaidLinkType;
  refetchBankAccounts?: () => void;
  setDisableButton?: Dispatch<SetStateAction<boolean>>;
  sourceAccounts: DropdownItem<string>[];
  symphonySubmitError?: Error;
}

export const eligibleSourceAccountName = 'eligibleSourceAccount';
export const eligibleDestinationBankAccountName = 'eligibleDestinationBankAccount';
export const withdrawalAmountName = 'withdrawalAmount';
export const withdrawalFrequencyName = 'withdrawalFrequency';
export const withdrawalDayOfWeekName = 'withdrawalDayOfWeek';
export const withdrawalDayOfMonthName = 'withdrawalDayOfMonth';
export const withdrawalDateName = 'withdrawalDate';
export const withdrawalNumberOfOccurrenceName = 'withdrawalNumberOfOccurrence';
const FieldWrapper: FC<ComponentProps<typeof Box>> = ({ children, sx }) => (
  <Box sx={{ mb: { xs: 2, md: 3 }, ...sx }}>{children}</Box>
);

export const WithdrawFundsForm: FC<Props> = ({
  dataQa = 'withdraw-funds-form',
  accountName,
  addedFinancialAccount,
  availableFunds,
  bankAccounts = [],
  asSoonAsPossibleDate,
  content = contentStackNotFound,
  contentV2,
  contentOptions,
  managedProductId,
  partyId,
  partyIdFA,
  plaidLinkageType,
  formData,
  accountMinimum = '0',
  defaultWithdrawalAmount,
  isRaiseCash,
  isRefetchingAccounts = false,
  onLinkAccount,
  onSourceAccountChange,
  marketHolidays,
  financialAccountType,
  formHooks,
  refetchBankAccounts,
  onTransferFrequencyTypeChange,
  setDisableButton,
  sourceAccounts,
  accountIdRestrictionsMap,
  symphonySubmitError,
}) => {
  const {
    components: {
      sfWithdrawFunds: {
        disableDateWeekends,
        disableDateAfterXDays,
        disableDateMarketHolidays,
        disableDateAfterMaxWithdrawalDate,
        disableDateTillAsSoonAsPossibleDate,
        disableDateCurrentYearForRetirementAccounts,
        showHardBlockForLowerLimit,
      },
    },
    featureFlags: {
      allowPlaidReAuthentication,
      allowEditManuallyLinkedBankAccounts,
      isManualLinkageForBankAccountSupported,
      isPlaidLinkageForBankAccountSupported,
      showWarningForNonVerifiedFinancialAccountLinkages,
    },
  } = useCoreConfig();

  const { palette } = useTheme();

  const recurringFrequencyOptions = useMemo(
    () => content.frequency.frequencyDropdownOptions.filter(option => option.value !== TransferFrequency.ONE_TIME),
    [content.frequency.frequencyDropdownOptions],
  );

  const [transferFrequencyType, setTransferFrequencyType] = useState(TransferFrequencyType.ONE_TIME);
  const [plaidMetaData, setPlaidMetaData] = useState<PlaidAccessTokenMetaData | undefined>(undefined);
  const accountMinimumBalance = parseFloat(accountMinimum);
  const currencySymbol = localeToCurrencySymbol[contentOptions.locale];

  const { open, openModal, onClose } = useModalState();
  const { open: isOpenPlaidLinkModal, openModal: openPlaidLinkModal, onClose: onClosePlaidLinkModal } = useModalState();
  const {
    open: isOpenClientPlaidLinkSuccessModal,
    openModal: openClientPlaidLinkSuccessModal,
    onClose: onCloseClientPlaidLinkSuccessModal,
  } = useModalState();
  const {
    open: isPlaidReAuthenticationModalOpen,
    openModal: openPlaidReAuthenticatioModal,
    onClose: closePlaidReAuthenticationModal,
  } = useModalState();

  const noBankAccountsError = content.account.noBankAccountsError;
  const noBankAccountsMessage = content.account.noBankAccountsMessage;

  const [isWithdrawalDayOfWeekUpdated, setIsWithdrawalDayOfWeekUpdated] = useState(false);
  const [isWithdrawalDayOfMonthUpdated, setIsWithdrawalDayOfMonthUpdated] = useState(false);
  const [openPlaidOtpVerification, setOpenPlaidOtpVerification] = useState(false);
  const [showVerificationWarningMessage, setShowVerificationWarningMessage] = useState(false);
  const [selectedAccountForEdit, setSelectedAccountForEdit] = useState<BankAccount>();

  const { watch, errors: fieldsErrors, control, getValues, setValue, register, reset: resetForm } = formHooks;

  register(withdrawalFrequencyName);
  const withdrawalFrequency = watch(withdrawalFrequencyName, TransferFrequency.ONE_TIME);
  const withdrawalNumberOfOccurrence = watch(withdrawalNumberOfOccurrenceName);

  const handleEditingBankAccount = useCallback(
    (item: DropdownItem) => {
      const editedAccount = bankAccounts.find(account => account.id === item.value);
      setSelectedAccountForEdit(editedAccount);
    },
    [bankAccounts],
  );
  const eligibleDestinationBankAccount = watch(eligibleDestinationBankAccountName);
  const [bankAccountOptions, setBankAccountOptions] = React.useState<DropdownItem[]>(
    getBankAccountOptions(
      bankAccounts,
      noBankAccountsMessage,
      allowEditManuallyLinkedBankAccounts ? handleEditingBankAccount : undefined,
    ),
  );

  const withdrawalAmount = watch(withdrawalAmountName);
  const withdrawalDayOfMonth = watch(withdrawalDayOfMonthName);
  const eligibleSourceAccount = watch(eligibleSourceAccountName, sourceAccounts[0].value);
  const selectedManagedProductId = sourceAccounts[0]?.value;
  let selectedAccountHasMoneyOutRestrictions = accountIdRestrictionsMap?.[selectedManagedProductId]?.find(
    v => v.restrictionType === AccountRestrictionType.MONEY_OUT,
  );

  const handleLinkBankAccountModalOnClose = () => {
    setSelectedAccountForEdit(undefined);
    onClose();
  };

  const handleSuccessPlaidLink = (metaData: PlaidAccessTokenMetaData) => {
    setPlaidMetaData(metaData);
    openClientPlaidLinkSuccessModal();
  };
  const { handleOpenPlaidLink } = usePlaidLinkHook(undefined, undefined, handleSuccessPlaidLink);
  const handleClosePlaidLinkSuccessModal = () => {
    refetchBankAccounts?.();
    onCloseClientPlaidLinkSuccessModal();
  };

  useEffect(() => {
    if (selectedAccountForEdit && !open) {
      openModal();
    }
  }, [open, openModal, selectedAccountForEdit]);

  useEffect(() => {
    // Trigger refetching list of bank accounts IFF we have a symphony submit error to remove unverified accounts.
    if (symphonySubmitError) {
      refetchBankAccounts?.();
      // Clear selected value when there is a symphony submit error
      setValue(eligibleDestinationBankAccountName, null);
      setDisableButton?.(true);
    }
  }, [symphonySubmitError]);

  const plaidItemIdIfReAuthenticateFails = useMemo(() => {
    if (eligibleDestinationBankAccount && bankAccounts.length) {
      return getPlaidItemIdIfReAuthenticateFails(bankAccounts, eligibleDestinationBankAccount);
    }
    return undefined;
  }, [bankAccounts, eligibleDestinationBankAccount]);

  useEffect(() => {
    if (!isRaiseCash) {
      setDisableButton?.(!eligibleDestinationBankAccount && !!plaidItemIdIfReAuthenticateFails);
    }
  }, [isRaiseCash, eligibleDestinationBankAccount, setDisableButton, plaidItemIdIfReAuthenticateFails]);

  useEffect(() => {
    setValue(withdrawalFrequencyName, withdrawalFrequency, { shouldValidate: true, shouldDirty: true });
  }, [withdrawalFrequency, setValue]);

  // This useMemo is to set the defaultValue for bankAccount.
  const defaultBankAccountValue = useMemo(() => bankAccountOptions[0].value.toString(), [bankAccountOptions]);

  useEffect(() => {
    if (
      (!eligibleDestinationBankAccount || eligibleDestinationBankAccount === noBankAccountsMessage) &&
      bankAccounts.length
    ) {
      setValue(eligibleDestinationBankAccountName, defaultBankAccountValue, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else if (!bankAccounts.length) {
      setValue(eligibleDestinationBankAccountName, noBankAccountsMessage, { shouldValidate: true, shouldDirty: true });
    }
    setBankAccountOptions(
      getBankAccountOptions(
        bankAccounts,
        noBankAccountsMessage,
        allowEditManuallyLinkedBankAccounts ? handleEditingBankAccount : undefined,
      ),
    );
  }, [
    defaultBankAccountValue,
    eligibleDestinationBankAccount,
    bankAccounts,
    noBankAccountsMessage,
    managedProductId,
    allowEditManuallyLinkedBankAccounts,
    handleEditingBankAccount,
    setValue,
  ]);

  useEffect(() => {
    const isEligibleDestinationBankAccountInOptions = bankAccountOptions.some(
      option => option.value === eligibleDestinationBankAccount,
    );
    if (!isEligibleDestinationBankAccountInOptions) {
      setValue(eligibleDestinationBankAccountName, defaultBankAccountValue, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [bankAccountOptions]);

  useEffect(() => {
    if (formData?.eligibleDestinationBankAccount && !symphonySubmitError) {
      setValue(eligibleDestinationBankAccountName, formData.eligibleDestinationBankAccount);
    }
    if (formData?.withdrawalAmount) {
      setValue(withdrawalAmountName, formData.withdrawalAmount, { shouldValidate: true, shouldDirty: true });
    }
    if (formData?.withdrawalFrequency) {
      setValue(withdrawalFrequencyName, formData.withdrawalFrequency, { shouldValidate: true, shouldDirty: true });
    }
  }, [formData]);

  const formValues = getValues();

  useEffect(() => {
    if (formData?.withdrawalDayOfWeek && formValues.withdrawalDayOfWeek && !isWithdrawalDayOfWeekUpdated) {
      setValue(withdrawalDayOfWeekName, formData.withdrawalDayOfWeek, { shouldValidate: true, shouldDirty: true });
      setIsWithdrawalDayOfWeekUpdated(true);
    }
  }, [formData, formValues, isWithdrawalDayOfWeekUpdated]);

  useEffect(() => {
    if (formData?.withdrawalDayOfMonth && formValues.withdrawalDayOfMonth && !isWithdrawalDayOfMonthUpdated) {
      setValue(withdrawalDayOfMonthName, formData.withdrawalDayOfMonth, { shouldValidate: true, shouldDirty: true });
      setIsWithdrawalDayOfMonthUpdated(true);
    }
  }, [formData, formValues, isWithdrawalDayOfMonthUpdated]);

  const {
    isAccountLinkageSuccessful: isBankLinkageSuccessful,
    reset: resetBankLinkageSuccessful,
    set: setBankAccountsCount,
  } = useGetAddAccountSuccess(bankAccounts);

  const handleRefetchAccounts = () => {
    resetBankLinkageSuccessful();
    setBankAccountsCount();
    refetchBankAccounts?.();
  };

  const handleCloseFaPlaidFlow = () => {
    setOpenPlaidOtpVerification(false);
    resetBankLinkageSuccessful();
  };

  // This useEffect hook checks the verification status of selected bank account everytime it changes.
  useEffect(() => {
    if (
      bankAccounts.length &&
      formValues.eligibleDestinationBankAccount &&
      defaultBankAccountValue !== noBankAccountsMessage &&
      showWarningForNonVerifiedFinancialAccountLinkages
    ) {
      setShowVerificationWarningMessage(
        getIsBankAccountAssociationNonVerified(
          formValues.eligibleDestinationBankAccount,
          bankAccounts,
          managedProductId,
        ),
      );
    }
  }, [bankAccounts, formValues, managedProductId, showWarningForNonVerifiedFinancialAccountLinkages]);

  const onLinkAccountCallback = (account: FinancialAccount) => {
    onLinkAccount?.(account);
    setValue(eligibleDestinationBankAccountName, account.id, { shouldValidate: true, shouldDirty: true });
  };

  const handleFrequencyChange = (event: DropdownChangeEvent) => {
    const value = event.target.value || (TransferFrequency.ONE_TIME as TransferFrequency);
    setValue(withdrawalFrequencyName, value, { shouldValidate: true, shouldDirty: true });
  };

  const handleWithdrawalAmountChange = ({ value: amount }: NumberFormatValues) => {
    setValue(withdrawalAmountName, amount, { shouldValidate: true, shouldDirty: true });
  };

  const handleWithdrawalAmountBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseFloat(event.target.value);
    if (!Number.isNaN(value)) {
      setValue(withdrawalAmountName, value.toFixed(2), { shouldValidate: true, shouldDirty: true });
    }
  };

  const obfuscatedAccountNumber = concealAccountNumber(accountName);
  const maxAllowedAmount = content.amount.maxAllowedAmount;
  const minAllowedAmount = content.amount.minAllowedAmount;
  const isMaxAllowedAmountLesserThanBalance = maxAllowedAmount < availableFunds;

  const isRetirementAccount = financialAccountType && isRetirementAccountType(financialAccountType);

  const selectedFinancialInstitution = useMemo(
    () => bankAccounts.find(account => account.id === eligibleDestinationBankAccount)?.financialInstitution,
    [bankAccounts, eligibleDestinationBankAccount],
  );

  const showPlaidReAuthentication = allowPlaidReAuthentication && plaidItemIdIfReAuthenticateFails;

  const todaysDate = startOfToday();
  // Allow only the next six months in the same calendar year as withdrawal date for Retirement accounts
  // Example: Feb 1, only Feb 1 - July 1 of this calendar year are available for selection
  // Example: Nov 15, only dates Nov 15 - Dec 31 of this calendar year are available for selection
  const maxWithdrawalDate: Date | null = isRetirementAccount
    ? isAfter(todaysDate, endOfMonth(setMonth(todaysDate, 5)))
      ? endOfYear(todaysDate)
      : add(todaysDate, { months: 6 })
    : disableDateAfterXDays
    ? add(todaysDate, { days: disableDateAfterXDays })
    : null;

  const shouldDisableDate = (date: Date) =>
    shouldDisableDateTillAsSoonAsPossibleDate(date, asSoonAsPossibleDate, disableDateTillAsSoonAsPossibleDate) ||
    shouldDisableDateAfterMaxWithdrawalDate(date, maxWithdrawalDate, disableDateAfterMaxWithdrawalDate) ||
    shouldDisableMarketHolidays(date, marketHolidays, disableDateMarketHolidays) ||
    shouldDisableWeekends(date, disableDateWeekends) ||
    shouldDisableAfterXDays(date, disableDateAfterXDays) ||
    shouldDisableRestCurrentYearForRetirementAccounts(
      date,
      disableDateCurrentYearForRetirementAccounts,
      isRetirementAccount,
    );

  useEffect(() => {
    if (addedFinancialAccount) {
      setValue(eligibleDestinationBankAccountName, addedFinancialAccount.id);
    }
  }, [addedFinancialAccount, eligibleDestinationBankAccountName]);

  const handleTransferFrequencyTypeChange = (value: TransferFrequencyType | null): void => {
    if (value) {
      let newTransferFrequency = TransferFrequency.ONE_TIME;
      if (value === TransferFrequencyType.RECURRING) {
        newTransferFrequency = recurringFrequencyOptions[0].value as TransferFrequency;
      }

      resetForm({ [withdrawalFrequencyName]: newTransferFrequency });
      onTransferFrequencyTypeChange();
      setTransferFrequencyType(value);
    }
  };

  const getHelperText = () => {
    return (
      <>
        <Box aria-label="available funds" id="available-funds" sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip
            tooltipContent={
              <RteContent config={{ accountNumber: obfuscatedAccountNumber }} data={content.availableFundsTooltip} />
            }
          />
          <RteContent
            config={{ availableFunds: formatCurrency(availableFunds, { locale: contentOptions.locale }) }}
            data={content.availableFunds}
          />
        </Box>
        <WithdrawAmountHelperText
          accountMinimum={accountMinimum}
          availableFunds={availableFunds}
          content={content.amount}
          contentOptions={contentOptions}
          fieldError={fieldsErrors.withdrawalAmount}
          maxAllowedAmount={maxAllowedAmount}
          obfuscatedAccountNumber={obfuscatedAccountNumber}
          showAccountMinWarning={availableFunds - parseFloat(withdrawalAmount ?? '0') < accountMinimumBalance}
        />
      </>
    );
  };

  const handleAccountDropdownChange = (
    eventValue: string | number | (string | number)[],
    onChange: (...value: any[]) => void,
  ) => {
    if (eventValue === 'link-account-action') {
      openModal();
    } else if (eventValue === 'link-plaid-account-action') {
      if (plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR) {
        setOpenPlaidOtpVerification(true);
      } else if (plaidLinkageType === PlaidLinkType.CLIENT) {
        openPlaidLinkModal();
      }
    } else {
      onChange(eventValue);
    }
  };

  const bankAccountItems = [
    ...bankAccountOptions,
    ...getAddAccountActions({
      isManualLinkageForAccountSupported: isManualLinkageForBankAccountSupported,
      isPlaidLinkageForAccountSupported: isPlaidLinkageForBankAccountSupported,
      manualLinkText: content.linkBankAccount,
      plaidLinkText: content.linkPlaidBankAccount,
    }),
  ];

  const handleSourceAccountDropdownChange = (
    sourceAccountId: string | number | (string | number)[],
    onChange: (...value: any[]) => void,
  ) => {
    setValue(eligibleSourceAccountName, sourceAccountId, { shouldValidate: true, shouldDirty: true });
    onChange(sourceAccountId);
    selectedAccountHasMoneyOutRestrictions = accountIdRestrictionsMap?.[`${sourceAccountId}`]?.find(
      v => v.restrictionType === AccountRestrictionType.MONEY_OUT,
    );

    onSourceAccountChange?.(sourceAccountId.toString());
  };

  return (
    <Box data-qa={dataQa} id="withdraw-funds-form">
      <ToggleButtonGroup
        exclusive
        fullWidth
        onChange={(_e, value) => handleTransferFrequencyTypeChange(value)}
        sx={{ pb: 2 }}
        value={transferFrequencyType}
      >
        <ToggleButton value={TransferFrequencyType.ONE_TIME}>
          {contentV2?.frequencyToggleButtonOptions.oneTime}
        </ToggleButton>
        <ToggleButton value={TransferFrequencyType.RECURRING}>
          {contentV2?.frequencyToggleButtonOptions.recurring}
        </ToggleButton>
      </ToggleButtonGroup>
      <FormControl fullWidth>
        {symphonySubmitError && <Alert error={symphonySubmitError} severity="error" />}

        <FieldWrapper>
          <Controller
            control={control}
            defaultValue={eligibleSourceAccount}
            name={eligibleSourceAccountName}
            render={({ value, name, onChange, onBlur }) => (
              <Dropdown
                disabled={sourceAccounts.length === 1}
                error={!!fieldsErrors.eligibleSourceAccount}
                id={eligibleSourceAccountName}
                items={sourceAccounts}
                label={contentV2?.sourceAccount.label}
                name={name}
                onBlur={onBlur}
                onChange={e => handleSourceAccountDropdownChange(e.target.value, onChange)}
                placeholder={contentV2?.sourceAccount.placeholder}
                value={value}
                width="100%"
              />
            )}
          />
        </FieldWrapper>
        {!isRaiseCash && (
          <FieldWrapper>
            <Controller
              control={control}
              defaultValue={defaultBankAccountValue}
              name={eligibleDestinationBankAccountName}
              render={({ value, name, onChange, onBlur }) =>
                allowEditManuallyLinkedBankAccounts ? (
                  <DropdownWithEditableOptions
                    defaultValue={value}
                    disabled={isRefetchingAccounts}
                    error={!!fieldsErrors.eligibleDestinationBankAccount}
                    id={eligibleDestinationBankAccountName}
                    items={[
                      ...bankAccountItems,
                      {
                        label: contentV2?.bankSourceCloseCta ?? '',
                        type: 'list-item-button',
                        variant: 'outlined',
                      },
                    ]}
                    label={content.account.bankAccountLabel}
                    onChange={event => {
                      handleAccountDropdownChange(event.value, onChange);
                    }}
                  />
                ) : (
                  <Dropdown
                    disabled={isRefetchingAccounts}
                    error={!!fieldsErrors.eligibleDestinationBankAccount}
                    id={eligibleDestinationBankAccountName}
                    items={bankAccountItems}
                    label={content.account.bankAccountLabel}
                    name={name}
                    onBlur={onBlur}
                    onChange={event => {
                      handleAccountDropdownChange(event.target.value, onChange);
                    }}
                    value={value}
                    width="100%"
                  />
                )
              }
              rules={{
                validate: {
                  mustHaveEligibleAccount: value => value !== noBankAccountsMessage,
                },
              }}
            />
          </FieldWrapper>
        )}
        {showPlaidReAuthentication && (
          <Alert data-qa="re-authentication-failed-error" severity="error">
            <RteContent
              config={{
                reAuthenticateLink: (
                  <AlertTitle>
                    <Link
                      data-qa={`${dataQa}-re-authenticat-error-link`}
                      onClick={openPlaidReAuthenticatioModal}
                      sx={{ color: 'error.main', textDecorationColor: palette.error.main }}
                      variant="subtitle2"
                    >
                      {contentV2?.plaidReAuthenticaionLink ?? ''}
                    </Link>
                  </AlertTitle>
                ),
              }}
              data={contentV2?.plaidReAuthenticaion ?? ''}
            />
          </Alert>
        )}
        {fieldsErrors.eligibleDestinationBankAccount && noBankAccountsError && (
          <Alert severity="error">
            <Typography variant="body2">{noBankAccountsError}</Typography>
          </Alert>
        )}
        {showVerificationWarningMessage && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <RteContent data={content.verificationWarning} />
          </Alert>
        )}
        {transferFrequencyType === TransferFrequencyType.RECURRING && (
          <>
            <FieldWrapper>
              <Controller
                control={control}
                defaultValue={withdrawalFrequency}
                name={withdrawalFrequencyName}
                render={({ value, name, onBlur, onChange }) => (
                  <Dropdown
                    id={withdrawalFrequencyName}
                    items={recurringFrequencyOptions}
                    label={content.frequency.frequencyDropdownLabel}
                    name={name}
                    onBlur={onBlur}
                    onChange={event => {
                      handleFrequencyChange(event);
                      onChange(event);
                    }}
                    value={value}
                    width="100%"
                  />
                )}
              />
            </FieldWrapper>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                {withdrawalFrequency === TransferFrequency.MONTHLY && (
                  <FieldWrapper width="100%">
                    <Controller
                      control={control}
                      defaultValue={1}
                      name={withdrawalDayOfMonthName}
                      render={({ onChange, value, name, onBlur }) => (
                        <Dropdown
                          id={withdrawalDayOfMonthName}
                          items={getDaysOfMonthOptions()}
                          label={content.frequency.monthlyDropdownLabel}
                          name={name}
                          onBlur={onBlur}
                          onChange={e => onChange(e.target.value)}
                          value={value}
                          width="100%"
                        />
                      )}
                    />
                  </FieldWrapper>
                )}
                {withdrawalFrequency === TransferFrequency.WEEKLY && (
                  <FieldWrapper width="100%">
                    <Controller
                      control={control}
                      defaultValue={Weekday.Monday}
                      name={withdrawalDayOfWeekName}
                      render={({ value, name, onChange, onBlur }) => (
                        <Dropdown
                          id={withdrawalDayOfWeekName}
                          items={getDaysOfWeekOptions(contentOptions.locale)}
                          label={content.frequency.weekdayDropdownLabel}
                          name={name}
                          onBlur={onBlur}
                          onChange={e => onChange(e.target.value)}
                          value={value}
                          width="100%"
                        />
                      )}
                    />
                  </FieldWrapper>
                )}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  defaultValue={DEFAULT_NUMBER_OF_OCCURRENCE}
                  name={withdrawalNumberOfOccurrenceName}
                  render={({ onChange, value, name, onBlur }) => (
                    <>
                      <Dropdown
                        id={withdrawalNumberOfOccurrenceName}
                        items={[
                          {
                            value: DEFAULT_NUMBER_OF_OCCURRENCE,
                            label: contentV2?.numberOfOccurrence.placeholder ?? '',
                          },
                          ...range(1, 50).map(item => ({ value: item, label: item.toString() })),
                        ]}
                        label={contentV2?.numberOfOccurrence.label}
                        name={name}
                        onBlur={onBlur}
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        width="100%"
                      />
                      <HelperTextContent id={`${withdrawalNumberOfOccurrenceName}-helper-text`}>
                        <RteContent
                          {...getNumberOfOccurrencesFieldRTEProps(
                            withdrawalFrequency,
                            withdrawalNumberOfOccurrence,
                            withdrawalDayOfMonth,
                            contentV2?.numberOfOccurrence.defaultHelperText,
                            contentV2?.numberOfOccurrence.lastDateHelperText,
                          )}
                        />
                      </HelperTextContent>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}
        <FieldWrapper sx={{ mt: -2, '& .MuiInputLabel-root': { mb: 1, ml: -1.5 } }}>
          <Controller
            control={control}
            defaultValue={defaultWithdrawalAmount ?? ''}
            name={withdrawalAmountName}
            render={({ ref, value, name }) => (
              <CurrencyTextField
                FormHelperTextProps={{ component: 'div' } as FormHelperTextProps}
                contentOptions={contentOptions}
                currencySymbol={currencySymbol}
                // Need to cast here: https://github.com/mui-org/material-ui/issues/20360
                error={!!fieldsErrors.withdrawalAmount}
                fullWidth
                helperText={getHelperText()}
                id={withdrawalAmountName}
                inputRef={ref}
                label={content.amount.withdrawalAmountLabel}
                name={name}
                onBlur={handleWithdrawalAmountBlur}
                onValueChange={handleWithdrawalAmountChange}
                placeholder={content.amount.placeholder}
                value={parseFloat(value)}
              />
            )}
            rules={{
              min: minAllowedAmount,
              max: isMaxAllowedAmountLesserThanBalance ? maxAllowedAmount : availableFunds.toFixed(2),
              required: true,
              validate: {
                accountMinimum: value =>
                  !showHardBlockForLowerLimit ||
                  !accountMinimumBalance ||
                  availableFunds - parseFloat(value) >= accountMinimumBalance,
              },
            }}
          />
        </FieldWrapper>
        {transferFrequencyType === TransferFrequencyType.ONE_TIME && (
          <FieldWrapper aria-label="calendar icon" id="date-picker">
            <Controller
              control={control}
              defaultValue={formData?.withdrawalDate ?? asSoonAsPossibleDate}
              name={withdrawalDateName}
              render={({ value, onChange }) => (
                <DatePicker
                  defaultValue={formData?.withdrawalDate ?? asSoonAsPossibleDate}
                  disablePast
                  fullWidth
                  label={content.frequency.datepicker.label}
                  onChange={onChange}
                  shouldDisableDate={date => shouldDisableDate(date as Date)}
                  value={value}
                />
              )}
              rules={{
                required: true,
                validate: {
                  [DatepickerValidations.isDate]: value => isValidDate(value),
                  [DatepickerValidations.mustBeLessThanMaxWithdrawalDate]: value =>
                    !maxWithdrawalDate || (value as Date) <= maxWithdrawalDate,
                  [DatepickerValidations.mustBeBusinessDay]: value => !isWeekend(value),
                  [DatepickerValidations.isFutureDateValidation]: value =>
                    compareDesc(value, asSoonAsPossibleDate) <= 0,
                  [DatepickerValidations.mustNotBeAMarketHoliday]: value =>
                    !marketHolidays.includes(toSymphonyDate(value)),
                },
              }}
            />
            {!!fieldsErrors.withdrawalDate &&
              datepickerErrorDisplay({
                fieldError: fieldsErrors.withdrawalDate.type,
                isRetirementAccount,
                withdrawFormContent: content.frequency.datepicker,
              })}
          </FieldWrapper>
        )}
        {content.modalInstructions && (
          <FieldWrapper>
            <Typography component="span" variant="caption">
              <RteContent data={content.modalInstructions} />
            </Typography>
          </FieldWrapper>
        )}
        {selectedAccountHasMoneyOutRestrictions && (
          <Alert data-qa="money-in-restriction-warning" severity="warning" sx={{ mb: 2 }}>
            <AlertTitle>{contentV2?.moneyOutLabels.moneyOutRestrictionHeading}</AlertTitle>
            <Typography variant="body2">{contentV2?.moneyOutLabels.moneyOutRestrictionContent}</Typography>
          </Alert>
        )}
      </FormControl>
      <Alert severity="info" sx={{ mb: 2 }}>
        {content.processingMessage}
      </Alert>
      <Typography variant="caption">{!isRetirementAccount ? content.disclaimer : null}</Typography>
      <LinkBankAccountModal
        contentOptions={contentOptions}
        managedProductId={managedProductId}
        onClose={handleLinkBankAccountModalOnClose}
        onLinkAccount={account => onLinkAccountCallback(account)}
        open={open}
        partyId={partyId}
        selectedAccount={selectedAccountForEdit}
      />
      {isPlaidLinkageForBankAccountSupported && (
        <>
          {plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR && (
            <FaPlaidFlow
              contentOptions={contentOptions}
              isAccountLinkageSuccessful={!!isBankLinkageSuccessful}
              isPlaidFlowLaunched={openPlaidOtpVerification}
              onCloseModalCallback={handleCloseFaPlaidFlow}
              partyId={partyId}
              partyIdFA={partyIdFA}
              plaidProducts={PlaidProduct.AUTH}
              refetchAccounts={handleRefetchAccounts}
            />
          )}
          {plaidLinkageType === PlaidLinkType.CLIENT && (
            <>
              <ClientInitiatePlaidModal
                connectAccount={() => {
                  handleOpenPlaidLink(partyId, [PlaidProduct.AUTH]).then(_ => onClosePlaidLinkModal());
                }}
                contentOptions={contentOptions}
                onClose={onClosePlaidLinkModal}
                open={isOpenPlaidLinkModal}
              />
              <ClientLedPlaidSuccessModal
                contentOptions={contentOptions}
                handleConnectAccount={() => handleOpenPlaidLink(partyId, [PlaidProduct.AUTH])}
                onClose={handleClosePlaidLinkSuccessModal}
                open={isOpenClientPlaidLinkSuccessModal}
                plaidMetaData={plaidMetaData}
              />
            </>
          )}
        </>
      )}
      {showPlaidReAuthentication && eligibleDestinationBankAccount && selectedFinancialInstitution && (
        <PlaidReAuthentication
          contentOptions={contentOptions}
          financialInstitution={selectedFinancialInstitution}
          onAuthenticationSuccess={handleRefetchAccounts}
          onClose={closePlaidReAuthenticationModal}
          open={isPlaidReAuthenticationModalOpen}
          partyId={partyId}
          partyIdFA={partyIdFA}
          plaidItemId={plaidItemIdIfReAuthenticateFails}
          plaidLinkageType={plaidLinkageType}
        />
      )}
    </Box>
  );
};
