import React, { ComponentProps, useCallback, useEffect, useState } from 'react';

import { CommentComponentColumn } from '../common/Comment';
import { RecurringCashTransferTable } from '../common/RecurringCashTransferTable';
import { cashTransferStatusToScheduledTransferStatusForUpdate } from '../mappers';
import { TransferItem, TransferStatus, TransferStatusFilter, TransferStatusUpdateItem } from '../types';
import { OpsDashboardUser } from '../utils';

import { generateStaticDisplayStrings, RecurringCancellationsDisplayStrings } from './content';
import { useRecurringCancellationsTabData } from './hooks';

import { Field, OrderType, TransferType, UserNoteEntityType } from '~/__generated__';
import { StatusChangeModal } from '~/components/modals/StatusChange';
import { NullStateProps } from '~/components/NullState';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { SortConfig } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Grid, ToggleButton, ToggleButtonGroup, WarningIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { useUpdateCashTransfer } from '~/containers/OpsDashboard/common/CashTransfer/symphony';
import { ContentOptions } from '~/utils/contentstack';

export interface RecurringCancellationsTab {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onUpdate: (item: TransferItem) => void;
}

const sortFieldToSymphony = new Map([
  ['amount', Field.AMOUNT],
  ['createdAt', Field.CREATED],
  ['scheduledDate', Field.SCHEDULED],
  ['settlementDate', Field.SETTLEMENT_DATE],
]);

export const RecurringCancellationsTab: React.FC<RecurringCancellationsTab> = ({
  commentColumn,
  contentOptions,
  currentUser,
  nullStateConfig,
  onAccountClick,
  onClientClick,
  getAccountNumberRedirectUrl,
  getClientNameRedirectUrl,
  onUpdate,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [content, setContent] = useState<RecurringCancellationsDisplayStrings>();
  const [isBusy, setIsBusy] = useState(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [selectedUpdateItem, setSelectedUpdateItem] = useState<TransferStatusUpdateItem>();
  const [statusFilter] = useState(TransferStatusFilter.PENDING_CANCELLATION);
  const [transferType, setTransferType] = useState<TransferType>(TransferType.WITHDRAWAL);

  const [updateCashTransfer] = useUpdateCashTransfer();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    order: OrderType.DESCENDING,
    field: 'createdAt',
  });

  const [commentEntity, setCommentEntity] = useState<UserNoteEntityType>(UserNoteEntityType.RECURRING_CASH_WITHDRAWAL);

  const { data, loading, error } = useRecurringCancellationsTabData({
    page: currentPage,
    statusFilter,
    contentOptions,
    ...sortConfig,
    field: sortFieldToSymphony.get(sortConfig.field) || Field.CREATED,
    transferType,
  });

  const { open: statusModalOpen, openModal: openStatusModal, onClose: statusModalOnClose } = useModalState();

  const onSelectNewStatusForItem = useCallback(
    (item: TransferItem, selectedStatus: TransferStatus) => {
      setSelectedUpdateItem({
        item,
        newStatus: selectedStatus,
      });
      openStatusModal();
    },
    [openStatusModal],
  );

  const onConfirmStatusChange = useCallback(async () => {
    if (selectedUpdateItem?.item) {
      setIsBusy(true);
      const { newStatus } = selectedUpdateItem;
      const { id, frequency, managedProductId } = selectedUpdateItem.item;
      const scheduledTransferStatus = cashTransferStatusToScheduledTransferStatusForUpdate(newStatus, frequency);
      if (scheduledTransferStatus) {
        try {
          await updateCashTransfer({
            variables: {
              transfer: {
                financialAccountId: managedProductId,
                frequency,
                status: scheduledTransferStatus,
                transferId: id,
                type: transferType,
              },
            },
          });
          onUpdate(selectedUpdateItem.item);
          data?.refetchRecurringCancellationsData();
          setIsBusy(false);
          statusModalOnClose();
        } catch (e: any) {
          setErrorSaving(e);
          setIsBusy(false);
        }
      }
    }
  }, [data, selectedUpdateItem, statusModalOnClose, onUpdate, transferType, updateCashTransfer]);

  const onSort = useCallback(
    (field: string) => () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      setSortConfig({ order: toggleOrder, field });
      setCurrentPage(1);
    },
    [sortConfig.order],
  );

  useEffect(() => {
    if (data?.content) {
      setContent(generateStaticDisplayStrings(data.content, transferType));
    }
  }, [data?.content, sortConfig, transferType]);

  const clearErrorsAndCloseModal = useCallback(() => {
    setErrorSaving(undefined);
    statusModalOnClose();
  }, [statusModalOnClose]);

  const handleTransferTypeChange: ComponentProps<typeof ToggleButtonGroup>['onChange'] = (_event, option) => {
    if (option !== null) {
      setTransferType(option);
    }
    if (TransferType.WITHDRAWAL === option) {
      setCommentEntity(UserNoteEntityType.RECURRING_CASH_WITHDRAWAL);
    } else {
      setCommentEntity(UserNoteEntityType.RECURRING_CASH_DEPOSIT);
    }
    setCurrentPage(1);
    setSortConfig({
      order: OrderType.DESCENDING,
      field: 'createdAt',
    });
  };

  return (
    <Grid container direction="column" spacing={3}>
      {(loading || error) && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading deposit items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      )}
      {data && content && (
        <Grid container>
          <Grid item sx={{ display: 'flex', flexDirection: 'row', mt: 2, mb: 4 }}>
            <WarningIcon sx={{ color: 'warning.light', mr: 2 }} />
            <RteContent data={content.disclaimer} />
          </Grid>
          <Grid item sx={{ display: 'flex', flexDirection: 'row', width: 1 }} xs={12}>
            <Grid xs={6}>
              <ToggleButtonGroup exclusive onChange={handleTransferTypeChange} value={transferType}>
                <ToggleButton value={TransferType.WITHDRAWAL}>Withdrawals</ToggleButton>
                <ToggleButton value={TransferType.DEPOSIT}>Deposits</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item pt={6} xs={12}>
            <RecurringCashTransferTable
              commentColumn={commentColumn}
              commentEntity={commentEntity}
              contentOptions={contentOptions}
              currentPage={currentPage}
              currentUser={currentUser}
              displayStrings={content}
              getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
              getClientNameRedirectUrl={getClientNameRedirectUrl}
              isRecurringCancellationsTab
              items={data.recurringCancelledItems}
              nullStateConfig={nullStateConfig}
              onAccountClick={onAccountClick}
              onClientClick={onClientClick}
              onPageChange={setCurrentPage}
              onSelectNewStatusForItem={onSelectNewStatusForItem}
              onSort={onSort}
              refetchData={data.refetchRecurringCancellationsData}
              sortConfig={sortConfig}
              totalPages={data.totalPages}
            />
            <StatusChangeModal
              contentOptions={contentOptions}
              errorSaving={errorSaving}
              isBusy={isBusy}
              onClose={clearErrorsAndCloseModal}
              onConfirm={onConfirmStatusChange}
              open={statusModalOpen}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
