import { TradingSuspensionsTabContent } from '../contentstack';

import { findFieldValue } from '~/utils/contentstack';

export enum SuspensionsTabEnum {
  Active = 'Active',
  History = 'History',
}

export interface Content {
  tabHeader: Record<SuspensionsTabEnum, string>;
}

export const getTradingSuspensionsContent = (content?: TradingSuspensionsTabContent): Content => {
  const contentData = content?.all_trading_suspensions?.items?.[0];
  const textFields = contentData?.fields?.text ?? [];

  return {
    tabHeader: {
      [SuspensionsTabEnum.Active]: findFieldValue(textFields, 'tab_active'),
      [SuspensionsTabEnum.History]: findFieldValue(textFields, 'tab_history'),
    },
  };
};
