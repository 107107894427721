import { CLIENT_NAME } from '../constants';

import {
  makeUseLazyQueryWithClientName,
  makeUseMutationWithClientName,
  makeUseQueryWithClientName,
  useLazyQuery,
  useMutation,
  useQuery,
} from '~/utils/apollo-client';

export const useGoalsQuery: typeof useQuery = makeUseQueryWithClientName(CLIENT_NAME);
export const useLazyGoalsQuery: typeof useLazyQuery = makeUseLazyQueryWithClientName(CLIENT_NAME);
export const useGoalsMutation: typeof useMutation = makeUseMutationWithClientName(CLIENT_NAME);
