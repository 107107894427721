import React, { useCallback } from 'react';

import { ColumnData } from '..';

import { TransferType } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, Collapse, Grid, InfoOutlinedIcon, LoadingButton, TableCell, TableRow } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type TransferCancellationPromptContent = {
  cancelRow: {
    bodyDeposit: string;
    bodyWithdrawal: string;
    ctas: { cancel: string; confirm: string };
    errorMessage: string;
  };
  columns: Array<{ data: ColumnData; header: string; showTooltip: boolean; tooltip: string }>;
};

type TransferCancellationPromptProps = {
  content: TransferCancellationPromptContent;
  contentOptions: ContentOptions;
  disabled: boolean;
  hasError: boolean;
  onConfirmCancellation: () => Promise<void>;
  onDismiss: () => void;
  open: boolean;
  transferType: TransferType;
};

export const TransferCancellationPrompt: React.FC<TransferCancellationPromptProps> = props => {
  const isMobile = useIsMediumScreen();
  const refCallback = useCallback((ref: HTMLElement) => {
    if (ref) {
      ref.focus();
    }
  }, []);
  const bodyContent = (
    <RteContent
      data={
        props.transferType === TransferType.DEPOSIT
          ? props.content.cancelRow.bodyDeposit
          : props.content.cancelRow.bodyWithdrawal
      }
    />
  );
  const cancelButton = (
    <Button onClick={props.onDismiss} sx={{ mr: 2 }} variant="text">
      {props.content.cancelRow.ctas.cancel}
    </Button>
  );
  const confirmButton = (
    <LoadingButton
      color="primary"
      disabled={props.disabled}
      loading={props.disabled}
      onClick={props.onConfirmCancellation}
      variant="contained"
    >
      {props.content.cancelRow.ctas.confirm}
    </LoadingButton>
  );
  const errorAlert = (
    <Collapse in={props.hasError} timeout="auto" unmountOnExit>
      <Alert severity="error" sx={{ mt: 2, alignItems: 'center' }}>
        <RteContent data={props.content.cancelRow.errorMessage} />
      </Alert>
    </Collapse>
  );
  return isMobile ? (
    <Modal
      actions={
        <>
          {cancelButton}
          {confirmButton}
        </>
      }
      content={
        <Grid container direction="column" spacing={1}>
          <Grid item sx={{ textAlign: 'center' }}>
            <Box p={3}>
              <InfoOutlinedIcon sx={{ color: 'info.dark', mb: 1 }} />
              {bodyContent}
            </Box>
          </Grid>
          <Grid item>{errorAlert}</Grid>
        </Grid>
      }
      onClose={props.onDismiss}
      open={props.open}
    />
  ) : (
    <TableRow aria-hidden={!props.open} role="presentation">
      <TableCell
        colSpan={props.content.columns.length}
        sx={{
          p: 0,
        }}
      >
        <Collapse in={props.open} timeout="auto" unmountOnExit>
          <Alert
            contentOptions={props.contentOptions}
            severity="info"
            sx={{
              px: 2,
              py: 1,
              my: 2,
              position: 'relative',
              border: ({ palette }) => `1px solid ${palette.divider}`,
              '& .MuiAlert-message': {
                width: 1,
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box ref={refCallback} sx={{ mr: 'auto', ':focus': { outline: 'none' } }} tabIndex={-1}>
                {bodyContent}
              </Box>
              {cancelButton}
              {confirmButton}
            </Box>
            {errorAlert}
          </Alert>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
