import React, { ComponentProps } from 'react';

import { useContactAdvisorData } from './hooks/useContactAdvisorData';

import { Link } from '~/components/ui/Link';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface Props extends Omit<ComponentProps<typeof Modal>, 'title' | 'content'> {
  contentOptions: ContentOptions;
  dataQa?: string;
  onCancel: () => void;
  open: boolean;
  partyId: string;
  supportEmail?: string;
  supportPhoneNumber?: string;
}

export const ContactAdvisorModal: React.FC<Props> = ({
  contentOptions,
  dataQa = 'contact-advisor-modal',
  onCancel,
  open,
  partyId,
  supportEmail,
  supportPhoneNumber,
  ...modalProps
}) => {
  const { data, loading, error } = useContactAdvisorData({
    contentOptions,
    partyId,
    open,
    supportEmail,
    supportPhoneNumber,
  });
  const contactAdvisorContent = data?.content;
  const contactInfo = data?.contactInfo;

  return (
    <Modal
      {...modalProps}
      actions={
        <Button onClick={onCancel} variant="outlined">
          {contactAdvisorContent?.ctas.close}
        </Button>
      }
      content={
        <Stack spacing={3}>
          <Box>
            <img alt="" src={contactAdvisorContent?.body.image ?? ''} />
            <Typography sx={{ mt: 3 }} variant="h3">
              {contactAdvisorContent?.body.heading}
            </Typography>
          </Box>
          {contactInfo?.name && (
            <Stack direction="row">
              <img alt="Advisor Name" src={contactAdvisorContent?.advisor.icon} />
              <RteContent
                config={{
                  contact: contactInfo.name,
                }}
                data={contactAdvisorContent?.advisor.content ?? ''}
                sx={{ ml: 1.5 }}
              />
            </Stack>
          )}
          <Stack direction="row">
            <img alt="Advisor Phone" src={contactAdvisorContent?.phone.icon} />
            <RteContent
              config={{
                contact: contactInfo?.phoneNumber ? (
                  <Link href={`tel:${contactInfo.phoneNumber}`}>{contactInfo.phoneNumber}</Link>
                ) : (
                  '-'
                ),
              }}
              data={contactAdvisorContent?.phone.content ?? ''}
              sx={{ ml: 1.5 }}
            />
          </Stack>
          <Stack direction="row">
            <img alt="Advisor Email" src={contactAdvisorContent?.email.icon} />
            <RteContent
              config={{
                contact: contactInfo?.email ? (
                  <Link href={`mailto:${contactInfo.email}`}>{contactInfo.email}</Link>
                ) : (
                  '-'
                ),
              }}
              data={contactAdvisorContent?.email.content ?? ''}
              sx={{ ml: 1.5 }}
            />
          </Stack>
        </Stack>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={error}
      loading={loading}
      open={open}
      title={contactAdvisorContent?.title}
    />
  );
};
