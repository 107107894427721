import * as queries from './query.gql';

import {
  GetCreateFlagContent,
  GetCreateFlagContent_all_flags_items_reasons,
  GetCreateFlagContentVariables,
} from '~/components/modals/CreateFlag/contentstack/__generated__/query.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type FlagItemReason = GetCreateFlagContent_all_flags_items_reasons;

export const useGetCreateFlagContent = (
  options?: QueryHookOptions<GetCreateFlagContent, GetCreateFlagContentVariables>,
): QueryResult<GetCreateFlagContent, GetCreateFlagContentVariables> => {
  return useContentstackQuery(queries.GetCreateFlagContent, options);
};
