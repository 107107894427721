import React, { ComponentProps, SyntheticEvent, useEffect, useState } from 'react';

import { useGetInvestmentRestrictionsContent } from './contentstack';
import {
  Restriction,
  useCreateSecurityRestriction,
  useDeleteSecurityRestriction,
  useGetInvestmentRestrictions,
} from './symphony';

import { SecuritySearchOption, TickerSearch } from '~/components/TickerSearch';
import { Alert } from '~/components/ui/Alert';
import { Link } from '~/components/ui/Link';
import { Modal } from '~/components/ui/Modal';
import {
  Button,
  Grid,
  LoadingButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onUpdateValidateRestriction?: () => void;
  partyId: string;
};

export const InvestmentRestrictions: React.FC<Props> = ({
  dataQa = 'investment-restrictions',
  contentOptions,
  partyId,
  managedProductId,
  open,
  onClose: baseOnClose,
  onUpdateValidateRestriction,
  ...modalProps
}) => {
  const { maximumRestrictions } = useCoreConfig().components.sfInvestmentRestrictions;

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [restrictions, setRestrictions] = useState<Restriction[] | undefined>();
  const [addedTickers, setAddedTickers] = useState<string[]>([]);
  const [deletedRestrictionIds, setDeletedRestrictionIds] = useState<string[]>([]);
  const [showMaxRestrictionsWarning, setShowMaxRestrictionsWarning] = useState(false);

  const [createSecurityRestrictions] = useCreateSecurityRestriction();
  const [deleteSecurityRestrictions] = useDeleteSecurityRestriction();

  const {
    sfInvestmentRestrictions: { styles: sfInvestmentRestrictionStyle },
  } = useTheme();

  const isMediumOrBelowScreen = useIsMediumScreen();

  const { data: contentData, loading: contentLoading, error: contentError } = useGetInvestmentRestrictionsContent({
    variables: contentOptions,
    skip: !open,
  });

  const {
    data: investmentRestrictionsData,
    loading: investmentRestrictionsLoading,
    error: investmentRestrictionsError,
    refetch: refetchInvestmentRestrictionsData,
  } = useGetInvestmentRestrictions({
    variables: {
      partyId,
      managedProductId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (open && !investmentRestrictionsLoading) {
      setRestrictions(investmentRestrictionsData?.managedProduct?.restrictions);
    }
  }, [open, investmentRestrictionsLoading, investmentRestrictionsData]);

  useEffect(() => {
    if (maximumRestrictions && (restrictions?.length ?? 0) < maximumRestrictions) {
      setShowMaxRestrictionsWarning(false);
    }
  }, [maximumRestrictions, restrictions]);

  const onRemove = (removedRestriction: Restriction) => () => {
    if (removedRestriction.id !== '') {
      setRestrictions(restrictions?.filter(restriction => restriction.id !== removedRestriction.id));
      setDeletedRestrictionIds([...deletedRestrictionIds, removedRestriction.id]);
    } else {
      setRestrictions(
        restrictions?.filter(restriction => restriction.security.ticker !== removedRestriction.security.ticker),
      );
      const tickers = addedTickers.filter(ticker => ticker !== removedRestriction.security.ticker);
      // Remove error when all the tickers are removed
      if (tickers.length === 0) {
        setErrorSaving(undefined);
      }
      setAddedTickers(tickers);
    }
  };

  const onClose = () => {
    setAddedTickers([]);
    setDeletedRestrictionIds([]);
    setErrorSaving(undefined);
    setShowMaxRestrictionsWarning(false);
    (baseOnClose as () => void | undefined)();
  };

  const onRestrictionSearchChange = (_event: SyntheticEvent, value: SecuritySearchOption | null) => {
    if (restrictions && maximumRestrictions && restrictions.length >= maximumRestrictions) {
      setShowMaxRestrictionsWarning(true);
      return;
    }
    if (event && value) {
      const preSelectedTickers = investmentRestrictionsData?.managedProduct?.restrictions.map(
        restriction => restriction.security.ticker,
      );
      setRestrictions(currentRestrictions => {
        currentRestrictions = Object.assign([], currentRestrictions);
        const index = currentRestrictions.findIndex(x => x.security.ticker === value.ticker);
        if (index === -1) {
          currentRestrictions.push({
            __typename: 'RestrictedBuySecurity',
            id: '',
            security: { __typename: 'Security', name: value.name || '', ticker: value.ticker || '' },
          });
        }
        return currentRestrictions;
      });
      setAddedTickers(currentTickers => {
        if (
          value.ticker &&
          currentTickers.indexOf(value.ticker) === -1 &&
          preSelectedTickers?.indexOf(value.ticker) === -1
        ) {
          currentTickers.push(value.ticker);
          setErrorSaving(undefined);
        }
        return currentTickers;
      });
    }
  };

  const onSave = async () => {
    let isSuccess = true;
    setIsBusy(true);
    try {
      setErrorSaving(undefined);
      try {
        if (addedTickers.length) {
          const createSecurityRestrictionResponse = await createSecurityRestrictions({
            variables: { partyId, managedProductId, tickers: addedTickers },
          });
          if (!createSecurityRestrictionResponse.data?.createSecurityRestriction.length) {
            isSuccess = false;
            setErrorSaving(Error('Error creating security restrictions'));
            console.error('Error creating security restrictions', createSecurityRestrictionResponse);
          }
        }
      } catch (err: any) {
        isSuccess = false;
        setErrorSaving(err);
        console.error('Error adding restrictions', err);
      }

      if (deletedRestrictionIds.length) {
        const deleteSecurityRestrictionsResponse = await deleteSecurityRestrictions({
          variables: { managedProductId, securityRestrictionIds: deletedRestrictionIds },
        });
        if (!deleteSecurityRestrictionsResponse.data?.deleteSecurityRestriction.length) {
          isSuccess = false;
          setErrorSaving(Error('Error deleting security restrictions'));
          console.error('Error deleting security restrictions', deleteSecurityRestrictionsResponse);
        }
      }

      refetchInvestmentRestrictionsData();
      if (isSuccess) {
        onUpdateValidateRestriction?.();
        onClose();
      }
    } catch (error: any) {
      setErrorSaving(error);
      console.error('Error saving restrictions', error);
    } finally {
      // We should clear all addedTickers and deleted restrictionIds after every onSave call to prevent retention of
      // already added tickers.
      setAddedTickers([]);
      setDeletedRestrictionIds([]);
      setIsBusy(false);
    }
  };

  const content = contentData?.all_investment_restrictions_modal?.items?.[0];
  const tableContent = content?.restrictions_table;
  const tickerSearchContent = content?.ticker_search;

  return (
    <Modal
      data-qa={dataQa}
      maxWidth="md"
      {...modalProps}
      actions={
        <Grid
          container
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
          justifyContent={{ xs: 'center', md: 'flex-end' }}
          spacing={2}
        >
          <Grid item>
            <Button fullWidth={isMediumOrBelowScreen} onClick={onClose} variant="outlined">
              {content?.modal_fields?.secondary_cta}
            </Button>
          </Grid>
          <Grid item mr={isMediumOrBelowScreen ? 0 : 1}>
            <LoadingButton fullWidth={isMediumOrBelowScreen} loading={isBusy} onClick={onSave} variant="contained">
              {content?.modal_fields?.primary_cta}
            </LoadingButton>
          </Grid>
        </Grid>
      }
      content={
        <>
          <Typography component="h3" sx={{ mb: 1, mt: isMediumOrBelowScreen ? 0 : 2 }} variant="subtitle2">
            {tickerSearchContent?.label}
          </Typography>
          <TickerSearch
            content={{
              noOptionsText: tickerSearchContent?.empty_results ?? '',
              placeholder: tickerSearchContent?.placeholder ?? '',
            }}
            onValueChange={onRestrictionSearchChange}
          />
          {maximumRestrictions && showMaxRestrictionsWarning && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              <RteContent config={{ maximumRestrictions }} data={content?.maximum_restrictions_warning ?? ''} />
            </Alert>
          )}
          {restrictions && restrictions.length > 0 && (
            <>
              <Typography sx={{ mb: 2, mt: isMediumOrBelowScreen ? 4 : 6 }} variant="subtitle2">
                {tableContent?.heading}
              </Typography>
              <Table
                data-qa={`${dataQa}-restrictions-table`}
                sx={{ mb: 2, 'td,th': { px: sfInvestmentRestrictionStyle.tablePadding } }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{tableContent?.columns?.ticker}</TableCell>
                    <TableCell>{tableContent?.columns?.name}</TableCell>
                    <TableCell>{tableContent?.columns?.action}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {restrictions.map((restriction, index) => {
                    const tickerId = `ticker-${restriction.id}`;
                    const nameId = `name-${restriction.id}`;
                    return (
                      <TableRow key={index}>
                        <TableCell id={tickerId}>{restriction.security.ticker}</TableCell>
                        <TableCell id={nameId}>{restriction.security.name}</TableCell>
                        <TableCell>
                          <Link
                            aria-describedby={`${tickerId} ${nameId}`}
                            component="button"
                            onClick={onRemove(restriction)}
                          >
                            {tableContent?.ctas?.remove}
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {errorSaving && <Alert contentOptions={contentOptions} error={errorSaving} severity="error" />}
            </>
          )}
          <RteContent data={content?.footer_ ?? ''} sx={{ mt: isMediumOrBelowScreen ? 4 : 6 }} />
        </>
      }
      contentOptions={contentOptions}
      error={contentError ?? investmentRestrictionsError}
      loading={contentLoading || investmentRestrictionsLoading}
      onClose={onClose}
      open={open}
      title={content?.modal_fields?.title}
    />
  );
};
