import { MutationHookOptions } from '@apollo/client';

import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { AddBrokerageSource } from '~/hooks/brokerage/symphony/__generated__/mutation.v2';
import { GetBrokerages, GetBrokerages_brokerages } from '~/hooks/brokerage/symphony/__generated__/query.v2';
import { GetBrokerageSyncStatus } from '~/hooks/brokerage/symphony/__generated__/query.v2';
import { QueryHookOptions } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type Brokerage = GetBrokerages_brokerages;

export const useGetBrokerages = (options?: QueryHookOptions<GetBrokerages>) => {
  return useSymphonyQuery(queries.GetBrokerages, options);
};

export const useAddBrokerageSource = (options?: MutationHookOptions<AddBrokerageSource>) => {
  return useSymphonyMutation(mutations.AddBrokerageSource, options);
};

export const useLazyGetBrokerageSyncStatus = (options?: QueryHookOptions<GetBrokerageSyncStatus>) => {
  return useLazySymphonyQuery(queries.GetBrokerageSyncStatus, options);
};
