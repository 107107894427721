import React, { FC, Fragment } from 'react';

import { Props as GoalsCardProps } from '.';

import { Chip } from '~/components/ui/Chip';
import { Divider } from '~/components/ui/Divider';
import { Link } from '~/components/ui/Link';
import { Accordion, AccordionDetails, AccordionSummary, ExpandMoreIcon, Grid, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { useIsMediumScreen } from '~/utils/responsiveness';

type Props = Required<Pick<GoalsCardProps, 'associatedAccounts' | 'associatedAccountsTotal' | 'content'>>;

export const AssociatedAccounts: FC<Props> = ({ associatedAccounts, associatedAccountsTotal, content }) => {
  const dataQa = 'goals-card-associated-accounts';
  const isMobile = useIsMediumScreen();
  if (associatedAccountsTotal === 0) {
    return <RteContent config={{ value: 0 }} data={content.numOfAccountAssociated} data-qa={`${dataQa}-heading`} />;
  }

  return (
    <Accordion data-qa={dataQa}>
      <AccordionSummary
        aria-controls="associatedAccounts-content"
        expandIcon={<ExpandMoreIcon />}
        id="associatedAccounts-header"
        sx={{ minHeight: 0, '.MuiAccordionSummary-content': { m: 0 } }}
      >
        <RteContent
          config={{ value: associatedAccountsTotal }}
          data={content.numOfAccountAssociated}
          data-qa={`${dataQa}-heading`}
        />
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0, mt: 2 }}>
        <Grid container rowSpacing={1}>
          {associatedAccounts.map(({ financialAccountTypeLabel, label, amount, onClick }, index) => {
            const isVerticalDivider = !isMobile && !!(index % 2);
            return (
              <Fragment key={`${label}-${index}`}>
                {index > 0 && (
                  <Grid item xs={isVerticalDivider ? 1 : 12}>
                    <Divider orientation={isVerticalDivider ? 'vertical' : 'horizontal'} />
                  </Grid>
                )}
                <Grid item md={5.5} xs={12}>
                  <Stack direction="row" justifyContent="space-between" py={1} spacing={1}>
                    <Stack direction="row" spacing={1}>
                      {onClick ? (
                        <Link data-qa={`${dataQa}-label-cta`} onClick={onClick} variant="subtitle2">
                          {label}
                        </Link>
                      ) : (
                        <Typography component="div" data-qa={`${dataQa}-label`} variant="subtitle2">
                          {label}
                        </Typography>
                      )}

                      {financialAccountTypeLabel && (
                        <Chip
                          data-qa={`${dataQa}-account-type`}
                          label={
                            <Typography component="div" variant="subtitle2">
                              {financialAccountTypeLabel}
                            </Typography>
                          }
                          size="small"
                          sx={{ borderRadius: 1 }}
                          variant="outlined"
                        />
                      )}
                    </Stack>
                    <Typography data-qa={`${dataQa}-amount`} variant="body2">
                      {amount}
                    </Typography>
                  </Stack>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
