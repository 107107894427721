import { SearchContextLiterals } from '~/containers/OpsDashboardV2/types';
import { FieldsGroup, findFieldValue } from '~/utils/contentstack';
import { PaginationContext } from '~/utils/types';

export interface ContentstackTableColumn {
  column_id: string | null;
  column_order: number | string | null;
  column_value: string | null;
}

export const sortColumnsByColumnOrder = (
  columns?: (ContentstackTableColumn | null | undefined)[],
): ContentstackTableColumn[] => {
  return (
    columns
      ?.slice()
      .filter((column): column is ContentstackTableColumn => !!column)
      .sort((a, b) => {
        if (a.column_order && b.column_order) {
          return +a.column_order - +b.column_order;
        }
        return 0;
      }) ?? []
  );
};

export const getPaginationContext = (
  fields: FieldsGroup | undefined | null,
  currentPage: number,
  recordsPerPage: number,
  total: number,
  updateRecordsPerPage: (value: number) => void,
): PaginationContext => {
  return {
    currentPage,
    recordsPerPage,
    totalItems: total,
    totalItemsLabel: findFieldValue(fields?.rte, SearchContextLiterals.TOTAL_ITEMS_LABEL),
    recordsPerPageLabel: findFieldValue(fields?.text, SearchContextLiterals.RECORDS_PER_PAGE_LABEL),
    updateRecordsPerPage,
  };
};
