import { TransferFrequency } from '~/__generated__';
import { DismissRmdConfirmationModalContent } from '~/components/Rmd/types';

export type FormData = {
  eligibleDestinationBankAccount: string;
  eligibleSourceAccount?: string;
  federalTaxWithholding?: string;
  federalTaxWithholdingPercentage?: number;
  grossUpFlag?: boolean;
  stateTaxWithholding?: string;
  stateTaxWithholdingPercentage?: number;
  withdrawalAmount: string;
  withdrawalDate?: Date;
  withdrawalDayOfMonth?: number;
  withdrawalDayOfWeek?: number;
  withdrawalFrequency: TransferFrequency;
  withdrawalNumberOfOccurrence?: number;
};

export enum WithdrawFundsContentKeys {
  FREQUENCY_TOGGLE_BUTTON_ONE_TIME = 'frequency_toggle_button_one_time',
  FREQUENCY_TOGGLE_BUTTON_RECURRING = 'frequency_toggle_button_recurring',
  MONEY_OUT_RESTRICTION_CONTENT = 'money_out_restriction_content',
  MONEY_OUT_RESTRICTION_HEADING = 'money_out_restriction_heading',
  NEW_RMD_REQUEST_SUCCESS_ACTION_LABEL = 'new_rmd_request_success_action_label',
  NUMBER_OF_OCCURRENCE_DEFAULT_HELPER_TEXT = 'number_of_occurrence_default_helper_text',
  NUMBER_OF_OCCURRENCE_LABEL = 'number_of_occurrence_label',
  NUMBER_OF_OCCURRENCE_LAST_DATE_HELPER_TEXT = 'number_of_occurrence_last_date_helper_text',
  NUMBER_OF_OCCURRENCE_PLACEHOLDER = 'number_of_occurrence_placeholder',
  REQUIREMENT_FULFILLED_CTA = 'requirement_fulfilled_cta',
  RMD_AMOUNT_TO_WITHDRAW_BY_DATE = 'amount_to_withdraw_by_date_message',
  RMD_BREAKDOWN_SUMMARY_ACCOUNT_COUNT = 'rmd_breakdown_summary_account_count',
  RMD_BREAKDOWN_SUMMARY_ACCOUNT_COUNT_MULTIPLE = 'rmd_breakdown_summary_account_count_multiple',
  RMD_BREAKDOWN_SUMMARY_ACCOUNT_COUNT_TOOLTIP = 'rmd_breakdown_summary_account_count_tooltip',
  RMD_BREAKDOWN_SUMMARY_ACCOUNT_COUNT_TOOLTIP_JOINING_WORD = 'rmd_breakdown_summary_account_count_tooltip_joining_word',
  RMD_BREAKDOWN_SUMMARY_ACCOUNT_WITH_INELIGIBLE_COUNT = 'rmd_breakdown_summary_account_with_ineligible_count',
  RMD_BREAKDOWN_SUMMARY_AMOUNT_WITHDRAWN = 'breakdown_summary_amount_withdrawn',
  RMD_BREAKDOWN_SUMMARY_PARTIAL_ACCOUNT_COUNT_TOOLTIP = 'rmd_breakdown_summary_partial_account_count_tooltip',
  RMD_BREAKDOWN_SUMMARY_SCHEDULED_WITHDRAWALS = 'breakdown_summary_scheduled_withdrawals',
  RMD_BREAKDOWN_SUMMARY_TITLE = 'breakdown_summary_title',
  RMD_BREAKDOWN_SUMMARY_TOGGLE_TEXT_HIDE = 'breakdown_toggle_text_hide',
  RMD_BREAKDOWN_SUMMARY_TOGGLE_TEXT_SHOW = 'breakdown_toggle_text_show',
  RMD_BREAKDOWN_SUMMARY_TOTAL_RMD = 'breakdown_summary_total_rmd',
  RMD_DISCLAIMER = 'rmd_disclaimer',
  RMD_SUCCESS_WITH_ONE_REMAINING = 'rmd_success_with_one_remaining',
  RMD_SUCCESS_WITH_TWO_REMAINING = 'rmd_success_with_two_remaining',
  RMD_TITLE = 'rmd_title',
  RMD_TOTAL_AMOUNT_TO_WITHDRAW = 'total_amount_to_withdraw_message',
  RMD_TOTAL_AMOUNT_TO_WITHDRAW_BY_DATE = 'total_amount_to_withdraw_by_date_message',
  SOURCE_ACCOUNT_LABEL = 'source_account_label',
  SOURCE_ACCOUNT_PLACEHOLDER = 'source_account_placeholder',
  SUCCESS_ACTION_LABEL = 'success_action_label',
  SUCCESS_ALERT_TEXT = 'success_alert_text',
  SUCCESS_COMPLETED_MESSAGE = 'success_completed_message',
  SUCCESS_IMAGE = 'success_image',
  SUCCESS_TITLE = 'success_title',
}

export interface ManagedAccountLabel {
  label: string;
  managedProductId: string;
}

export interface RmdSummaryContent extends DismissRmdConfirmationModalContent {
  breakdownSummaryItems: {
    accountCount: string;
    accountCountMultiple: string;
    accountCountTooltip: string;
    accountCountTooltipJoiningWord: string;
    accountWithIneligibleCount: string;
    amountWithdrawnText: string;
    partialAccountCountTooltip: string;
    scheduledWithdrawalsText: string;
    title: string;
    toggleText: {
      hide: string;
      show: string;
    };
    totalRMDText: string;
  };
  disclaimer: string;
  requirementFulfilledCTA: string;
  title: string;
  totalRMDAmountWithDate: string;
  totalRMDAmountWithoutDate: string;
  withdrawByWithDate: string;
}

export interface WithdrawFundsV2Content {
  bankSourceCloseCta: string;
  frequencyToggleButtonOptions: {
    oneTime: string;
    recurring: string;
  };
  moneyOutLabels: {
    moneyOutRestrictionContent: string;
    moneyOutRestrictionHeading: string;
  };
  numberOfOccurrence: {
    defaultHelperText: string;
    label: string;
    lastDateHelperText: string;
    placeholder: string;
  };
  plaidReAuthenticaion: string;
  plaidReAuthenticaionLink: string;
  rmdSummary: RmdSummaryContent;
  sourceAccount: {
    label: string;
    placeholder: string;
  };
  success: {
    actionLabel: string;
    alertText: string;
    completedMessage: string;
    image: string;
    newRmdWithdrawalRequestActionLabel: string;
    rmdSuccessWithOneRemaining: string;
    rmdSuccessWithTwoRemaining: string;
    title: string;
  };
}
