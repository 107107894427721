import { add, addBusinessDays, addYears, isPast, isToday, isWeekend } from 'date-fns';
import * as React from 'react';
import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';

import { FaPlaidFlow } from '../../FaPlaidFlow';
import { ClientInitiatePlaidModal } from '../ClientInitiatePlaid';
import { ClientLedPlaidSuccessModal } from '../ClientLedPlaidSuccess';
import { LinkBankAccountModal } from '../LinkBankAccount';
import { AddFundsDatePicker, datepickerErrorDisplay, DatepickerValidations } from '../utils/datepicker-helper';

import { isAmountField, isBankAccountField, isContributionYearField, isFrequencyField } from './contentstack';
import { GetAddFundsContent_all_add_funds_modal_items } from './contentstack/__generated__/query.v2';

import {
  AccountRestrictionType,
  BalanceType,
  BankAccountInput,
  CreateCashTransferInput,
  FinancialAccountType,
  PlaidProduct,
  SleeveType,
  TransferFrequency,
  TransferType,
} from '~/__generated__/symphonyTypes.v2';
import { PlaidReAuthentication } from '~/components/modals/PlaidReAuthentication';
import { Alert } from '~/components/ui/Alert';
import { DatePicker } from '~/components/ui/DatePicker';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownWithEditableOptions } from '~/components/ui/Dropdown/DropdownWithEditableOptions';
import { DropdownChangeEvent, DropdownItem } from '~/components/ui/Dropdown/types';
import { Link } from '~/components/ui/Link';
import { useModalState } from '~/components/ui/Modal/hooks';
import {
  AlertTitle,
  Box,
  FormControl,
  FormHelperTextProps,
  ToggleButton,
  ToggleButtonGroup,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { CurrencyTextField } from '~/components/ui/TextField/CurrencyTextField';
import { Typography } from '~/components/ui/Typography';
import { AccountRestriction } from '~/containers/AccountSummary/types';
import { isFundingAmountValid } from '~/containers/Funding/utils';
import { useGetAddAccountSuccess } from '~/hooks/account/useGetAddAccountSuccess';
import { FinancialAccount, Weekday } from '~/hooks/financial-account/symphony';
import { DateOption } from '~/hooks/financial-account/types';
import { PlaidAccessTokenMetaData, PlaidLinkType, usePlaidLinkHook } from '~/hooks/plaid-link';
import {
  BankAccount,
  getAccountBalance,
  getAddAccountActions,
  getBankAccountOptions,
  getContributionYearOptions,
  getExternalBankAccountInput,
  getIsBankAccountAssociationNonVerified,
  getPlaidItemIdIfReAuthenticateFails,
  isRetirementAccountType,
} from '~/utils/account';
import { GraphQLError, isApolloError } from '~/utils/apollo-client';
import { shouldDisableMarketHolidays, shouldDisableWeekends } from '~/utils/client';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions, findFieldValue, Locale } from '~/utils/contentstack';
import { getDaysOfMonthOptions, getDaysOfWeekOptions } from '~/utils/dropdown';
import { concealAccountNumber, formatCurrency, isValidDate, localeToCurrencySymbol } from '~/utils/format';
import { calculateAge } from '~/utils/format/date';
import { SymphonyError, toSymphonyDate, toSymphonyDateTime } from '~/utils/symphony';

export interface FormContent {
  amountField: {
    errors: {
      accountTypeMaximumAmount: string;
      maxAllowedError: string;
      maxError: string;
      minError: string;
      requiredError: string;
    };
    label: string;
    maxAllowedAmount: number;
    minAllowedAmount: number;
    placeholder: string;
  };
  bankAccountDropdown: {
    label: string;
    noBankAccountsError: string;
    noBankAccountsMessage: string;
  };
  contributionYearDropdown: {
    endDateAndMonth: string;
    label: string;
    options: DropdownItem[];
    startDateAndMonth: string;
    tooltip: string;
  };
  dateOptions: {
    later: string;
    now: string;
  };
  datePicker: AddFundsDatePicker;
  footer: string;
  frequencyDropdown: {
    label: string;
    options: {
      label: string;
      value: string;
    }[];
  };
  heading: string;
  iraRecurringContributionText: string;
  weekdayDropdown: {
    options: DropdownItem[];
  };
}

const getFormContent = (
  items: GetAddFundsContent_all_add_funds_modal_items | null | undefined,
  locale: Locale,
): FormContent => {
  const fields = items?.fields;
  const amountField = fields?.filter(isAmountField)?.[0];
  const frequencyField = fields?.filter(isFrequencyField)?.[0];
  const bankAccountField = fields?.filter(isBankAccountField)?.[0];
  const contributionYearField = fields?.filter(isContributionYearField)?.[0];

  return {
    heading: items?.heading ?? '',
    amountField: {
      label: amountField?.amount?.inputConnection?.edges?.[0]?.node?.labels?.[0]?.value ?? '',
      placeholder: amountField?.amount?.inputConnection?.edges?.[0]?.node?.placeholder ?? '',
      maxAllowedAmount: amountField?.amount?.maximum_allowed_amount ?? Number.MAX_VALUE,
      minAllowedAmount: amountField?.amount?.minimum_allowed_amount ?? Number.MIN_VALUE,
      errors: {
        minError: amountField?.amount?.amount_errors?.negative_zero_addition_amount ?? '',
        maxError: amountField?.amount?.amount_errors?.overdrawn_addition_amount ?? '',
        maxAllowedError: amountField?.amount?.amount_errors?.maximum_allowed_amount ?? '',
        requiredError: amountField?.amount?.amount_errors?.missing_addition_amount ?? '',
        accountTypeMaximumAmount: amountField?.amount?.amount_errors?.account_type_maximum_amount ?? '',
      },
    },
    frequencyDropdown: {
      label: frequencyField?.frequency?.frequency_fieldConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      options: [
        {
          label: items?.frequency_dropdown_values?.group?.one_time ?? '',
          value: TransferFrequency.ONE_TIME,
        },
        {
          label: items?.frequency_dropdown_values?.group?.weekly ?? '',
          value: TransferFrequency.WEEKLY,
        },
        {
          label: items?.frequency_dropdown_values?.group?.monthly ?? '',
          value: TransferFrequency.MONTHLY,
        },
      ].filter(o => o.label),
    },
    weekdayDropdown: {
      options: getDaysOfWeekOptions(locale),
    },
    dateOptions: {
      now: frequencyField?.frequency?.time_period_cta?.now ?? '',
      later: frequencyField?.frequency?.time_period_cta?.later ?? '',
    },
    datePicker: {
      error: frequencyField?.frequency?.date_pickerConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      label: frequencyField?.frequency?.date_pickerConnection?.edges?.[0]?.node?.label?.[1]?.value ?? '',
      pastDateError: frequencyField?.frequency?.datepicker_past_date_error ?? '',
      invalidDateError: frequencyField?.frequency?.datepicker_invalid_date ?? '',
      marketHolidayError: frequencyField?.frequency?.datepicker_market_holiday_error ?? '',
      maxDateError: frequencyField?.frequency?.datepicker_max_date_error ?? '',
      monthlyDayPickerLabel: frequencyField?.frequency?.day_pickerConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      weekdayPickerLabel:
        frequencyField?.frequency?.weekday_pickerConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
    },
    contributionYearDropdown: {
      label: contributionYearField?.contribution_year?.dropdownConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      options: getContributionYearOptions(),
      tooltip:
        contributionYearField?.contribution_year?.dropdownConnection?.edges?.[0]?.node?.label?.[0]?.tooltip ?? '',
      startDateAndMonth: contributionYearField?.contribution_year?.start_time ?? '01/01',
      endDateAndMonth: contributionYearField?.contribution_year?.end_time ?? '04/15',
    },
    bankAccountDropdown: {
      label: bankAccountField?.bank_account?.dropdownConnection?.edges?.[0]?.node?.label?.[0]?.value ?? '',
      noBankAccountsMessage: bankAccountField?.bank_account?.no_bank_accounts_message ?? '',
      noBankAccountsError: bankAccountField?.bank_account?.no_bank_accounts_error ?? '',
    },
    footer: items?.footer_ ?? '',
    iraRecurringContributionText: items?.ira_recurring_contribution_text ?? '',
  };
};

const getAmountFieldHelperText = (
  amountFieldError: any,
  minErrorMessage: string,
  maxErrorMessage: ReactNode,
  requiredErrorMessage: string,
  isMaxAllowedAmountLesserThanBalance: boolean,
  maxAllowedAmountMessage: ReactNode,
  ignoreInsufficientFunds: boolean,
  accountTypeMaximumAmount: string,
) => {
  if (amountFieldError) {
    if (amountFieldError.type === 'min') {
      return minErrorMessage;
    } else if (amountFieldError.type === 'max') {
      return ignoreInsufficientFunds
        ? maxAllowedAmountMessage
        : isMaxAllowedAmountLesserThanBalance
        ? maxAllowedAmountMessage
        : maxErrorMessage;
    } else if (amountFieldError.type === 'required') {
      return requiredErrorMessage;
    }
    if (amountFieldError.type === 'isFundingAmountValid') {
      return <RteContent data={accountTypeMaximumAmount} />;
    }
  }
  return null;
};

export interface Props {
  accountName: string;
  accountNumberFormat?: string;
  accountRestrictions?: AccountRestriction[];
  accounts?: BankAccount[];
  additionAmount?: string;
  bankAccountId?: string;
  content: GetAddFundsContent_all_add_funds_modal_items | null | undefined;
  contentOptions: ContentOptions;
  dataQa?: string;
  dateOptionValue?: DateOption;
  destinationAccountId: string;
  destinationAccountType: FinancialAccountType | null;
  frequencyValue?: TransferFrequency;
  ignoreInsufficientFunds?: boolean;
  isRefetchingAccounts?: boolean;
  isSubmitted: boolean;
  managedProductId: string;
  marketHolidays: string[];
  onBankAccountSelected?: () => void;
  onInavlidInput?: () => void;
  onLinkAccount?: (account: FinancialAccount) => void;
  onSubmitCallback?: (
    createCashTransferInputVars: CreateCashTransferInput,
    bankAccountId: string | null,
    bankAccountInput?: BankAccountInput,
  ) => Promise<void>;
  partyId: string;
  partyIdFA?: string;
  plaidLinkageType?: PlaidLinkType;
  refetchBankAccounts?: () => void;
  refetchParentContainerAccounts?: () => void;
  setDisableButton?: Dispatch<SetStateAction<boolean>>;
  soonestPossibleDays?: number;
  symphonyError?: Error;
  userBirthDate?: string | null;
  userName?: string;
}

const eligibleSourceBankAccountName = 'eligibleSourceBankAccount';
const additionAmountName = 'additionAmount';
const additionFrequencyName = 'additionFrequency';
const additionDateOptionName = 'additionDateOption';
const additionDateName = 'additionDate';
const additionDayOfWeekName = 'additionDayOfWeek';
const additionDayOfMonthName = 'additionDayOfMonth';
const contributionYearName = 'contributionYear';

type FormData = {
  additionAmount: string;
  additionDate?: Date;
  additionDateOption?: DateOption;
  additionDayOfMonth?: number;
  additionDayOfWeek?: number;
  additionFrequency: TransferFrequency;
  contributionYear?: number;
  eligibleSourceBankAccount: string;
};

export const AddFundsForm: FC<Props> = ({
  accountName,
  accounts = [],
  accountRestrictions,
  additionAmount,
  bankAccountId,
  content,
  contentOptions,
  dataQa = 'add-funds-form',
  dateOptionValue = DateOption.DateAsap,
  destinationAccountId,
  destinationAccountType,
  frequencyValue = TransferFrequency.ONE_TIME,
  ignoreInsufficientFunds = false,
  isRefetchingAccounts = false,
  isSubmitted,
  managedProductId,
  marketHolidays,
  onLinkAccount,
  onInavlidInput,
  onSubmitCallback,
  partyId,
  partyIdFA,
  plaidLinkageType,
  refetchBankAccounts,
  refetchParentContainerAccounts,
  setDisableButton,
  soonestPossibleDays = 0,
  symphonyError,
  onBankAccountSelected,
  userName = '',
  userBirthDate,
}) => {
  const hasMoneyInRestriction = accountRestrictions?.find(v => v.restrictionType === AccountRestrictionType.MONEY_IN);
  const moneyInRestrictionHeading = findFieldValue(content?.labels?.text, 'money_in_restriction_heading');
  const moneyInRestrictionContent = findFieldValue(content?.labels?.text, 'money_in_restriction_content');

  const {
    components: {
      sfAddFunds: { disableDateWeekends, disableDateAfterXDays, disableDateMarketHolidays },
      sfFunding: { amountValidationBasedOnAccountTypes },
    },
    featureFlags: {
      allowPlaidReAuthentication,
      allowEditManuallyLinkedBankAccounts,
      isManualLinkageForBankAccountSupported,
      isPlaidLinkageForBankAccountSupported,
      showWarningForNonVerifiedFinancialAccountLinkages,
    },
  } = useCoreConfig();
  const formContent = getFormContent(content, contentOptions.locale);
  const { open, openModal, onClose } = useModalState();
  const { open: isOpenPlaidLinkModal, openModal: openPlaidLinkModal, onClose: onClosePlaidLinkModal } = useModalState();
  const {
    open: isOpenClientPlaidLinkSuccessModal,
    openModal: openClientPlaidLinkSuccessModal,
    onClose: onCloseClientPlaidLinkSuccessModal,
  } = useModalState();
  const {
    open: isPlaidReAuthenticationModalOpen,
    openModal: openPlaidReAuthenticatioModal,
    onClose: closePlaidReAuthenticationModal,
  } = useModalState();

  const [plaidMetaData, setPlaidMetaData] = useState<PlaidAccessTokenMetaData | undefined>(undefined);
  const [bankAccounts, setBankAccounts] = useState(accounts);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>();
  const [openPlaidOtpVerification, setOpenPlaidOtpVerification] = useState(false);
  const [showVerificationWarningMessage, setShowVerificationWarningMessage] = useState(false);
  const [asSoonAsPossibleDate, setAsSoonAsPossibleDate] = useState<Date>(
    addBusinessDays(new Date(), soonestPossibleDays),
  );
  const [maximumFunds, setMaximumFunds] = useState(Number.MAX_VALUE);
  const [selectedAccountForEdit, setSelectedAccountForEdit] = useState<BankAccount>();
  const [isRetirementAccount, setIsRetirementAccount] = useState(false);
  const [selectedContributionYear, setSelectedContributionYear] = useState<number | null>(null);

  const noBankAccountsMessage = formContent.bankAccountDropdown.noBankAccountsMessage;
  const noBankAccountsError = formContent.bankAccountDropdown.noBankAccountsError;

  const handleEditingBankAccount = (item: DropdownItem) => {
    const editedAccount = bankAccounts.find(account => account.id === item.value);
    setSelectedAccountForEdit(editedAccount);
  };

  useEffect(() => {
    if (selectedAccountForEdit && !open) {
      openModal();
    }
  }, [open, openModal, selectedAccountForEdit]);

  const { watch, handleSubmit, errors: fieldsErrors, control, setValue, register, trigger } = useForm<FormData>();

  const formAdditionAmount = watch(additionAmountName);
  const additionFrequency = watch(additionFrequencyName);
  const contributionYear = watch(contributionYearName);
  register(additionDateOptionName);
  const additionDateOption = watch(additionDateOptionName, dateOptionValue);
  const sourceBankAccountId = watch(eligibleSourceBankAccountName);
  const contributionYearOptions = getContributionYearOptions();
  const currencySymbol = localeToCurrencySymbol[contentOptions.locale];

  const handleSuccessPlaidLink = (metaData: PlaidAccessTokenMetaData) => {
    setPlaidMetaData(metaData);
    openClientPlaidLinkSuccessModal();
  };

  const bankAccountOptions = getBankAccountOptions(
    bankAccounts,
    noBankAccountsMessage,
    allowEditManuallyLinkedBankAccounts ? handleEditingBankAccount : undefined,
  );

  const { handleOpenPlaidLink } = usePlaidLinkHook(undefined, undefined, handleSuccessPlaidLink);
  const maxAllowedDate: Date | null = disableDateAfterXDays ? add(new Date(), { days: disableDateAfterXDays }) : null;
  const handleClosePlaidLinkSuccessModal = () => {
    refetchBankAccounts?.();
    onCloseClientPlaidLinkSuccessModal();
  };

  // Trigger refetching list of bank accounts whenever we have a symphony submit error to remove unverified accounts.
  useEffect(() => {
    refetchBankAccounts?.();
    if (symphonyError) {
      setSelectedBankAccountId(undefined);
      refetchParentContainerAccounts?.();
    }
  }, [noBankAccountsError, symphonyError]);

  const plaidItemIdIfReAuthenticateFails = useMemo(() => {
    if (selectedBankAccountId) {
      return getPlaidItemIdIfReAuthenticateFails(bankAccounts, selectedBankAccountId);
    }
    return undefined;
  }, [bankAccounts, selectedBankAccountId]);

  const selectedFinancialInstitution = useMemo(
    () => bankAccounts.find(account => account.id === selectedBankAccountId)?.financialInstitution,
    [bankAccounts, selectedBankAccountId],
  );

  const showPlaidReAuthentication = allowPlaidReAuthentication && plaidItemIdIfReAuthenticateFails;

  useEffect(() => {
    if (sourceBankAccountId) {
      const sourceBankAccount = accounts.find(account => account.id === sourceBankAccountId);
      const max = sourceBankAccount
        ? getAccountBalance(sourceBankAccount, BalanceType.AVAILABLE) ?? Number.MAX_VALUE
        : Number.MAX_VALUE;
      setMaximumFunds(max);
    }
    if (sourceBankAccountId && sourceBankAccountId !== noBankAccountsMessage) {
      setDisableButton?.(!!plaidItemIdIfReAuthenticateFails);
    }
  }, [sourceBankAccountId, plaidItemIdIfReAuthenticateFails]);

  useEffect(() => {
    if (formAdditionAmount) {
      trigger(additionAmountName);
    }
  }, [maximumFunds]);
  useEffect(() => {
    if (formAdditionAmount) {
      trigger(additionAmountName);
    }
  }, [contributionYear]);
  useEffect(() => {
    setValue(additionFrequencyName, frequencyValue, { shouldValidate: true, shouldDirty: true });
  }, [frequencyValue]);

  useEffect(() => {
    setValue(additionDateOptionName, dateOptionValue, { shouldValidate: true, shouldDirty: true });
  }, [dateOptionValue]);

  useEffect(() => {
    const soonestDate = addBusinessDays(new Date(), soonestPossibleDays);
    setAsSoonAsPossibleDate(soonestDate);
    setValue(additionDateName, soonestDate, { shouldValidate: true, shouldDirty: true });
  }, [soonestPossibleDays]);

  useEffect(() => {
    setBankAccounts(accounts);
  }, [accounts]);

  useEffect(() => {
    if (isSubmitted) {
      if (Object.keys(fieldsErrors).length) {
        onInavlidInput?.();
      } else {
        handleSubmit(handleSuccessfulSubmit)();
      }
    }
  }, [isSubmitted]);

  const handleCheckAccountVerification = (accountId: string) => {
    if (showWarningForNonVerifiedFinancialAccountLinkages) {
      setShowVerificationWarningMessage(
        getIsBankAccountAssociationNonVerified(accountId, bankAccounts, managedProductId),
      );
    }
  };

  const {
    isAccountLinkageSuccessful: isBankLinkageSuccessful,
    reset: resetBankLinkageSuccessful,
    set: setBankAccountsCount,
  } = useGetAddAccountSuccess(accounts);

  const handleRefetchAccounts = () => {
    resetBankLinkageSuccessful();
    setBankAccountsCount();
    refetchBankAccounts?.();
  };

  const handleCloseFaPlaidFlow = () => {
    setOpenPlaidOtpVerification(false);
    resetBankLinkageSuccessful();
  };

  // This useMemo is to set the defaultValue for bankAccount.
  const defaultBankAccountValue = useMemo(
    () =>
      (
        bankAccountOptions.find(({ value }) => value === bankAccountId)?.value ?? bankAccountOptions[0].value
      ).toString(),
    [bankAccountOptions],
  );

  // This useEffect hook checks the verification status of defaultBankAccountValue everytime it changes.
  useEffect(() => {
    if (defaultBankAccountValue !== noBankAccountsMessage && showWarningForNonVerifiedFinancialAccountLinkages) {
      setShowVerificationWarningMessage(
        getIsBankAccountAssociationNonVerified(defaultBankAccountValue, accounts, managedProductId),
      );
    }
    setValue(eligibleSourceBankAccountName, defaultBankAccountValue);
  }, [defaultBankAccountValue]);

  useEffect(() => {
    if (selectedBankAccountId) {
      setValue(eligibleSourceBankAccountName, selectedBankAccountId, { shouldValidate: true, shouldDirty: true });
      // Reset symphony error when new account is selected
      onBankAccountSelected?.();
      setDisableButton?.(!!plaidItemIdIfReAuthenticateFails);
    }
  }, [eligibleSourceBankAccountName, selectedBankAccountId, plaidItemIdIfReAuthenticateFails]);

  useEffect(() => {
    setIsRetirementAccount(isRetirementAccountType(destinationAccountType as FinancialAccountType));
  }, [destinationAccountType]);

  const isOneTimeTransferForRetirementAccount: boolean =
    additionFrequency === TransferFrequency.ONE_TIME &&
    !!destinationAccountType &&
    isRetirementAccountType(destinationAccountType);

  const isRecurringTransferForRetirementAccount: boolean =
    additionFrequency !== TransferFrequency.ONE_TIME &&
    !!destinationAccountType &&
    isRetirementAccountType(destinationAccountType);

  const handleSuccessfulSubmit = async (formData: FormData) => {
    switch (formData.additionFrequency) {
      case TransferFrequency.MONTHLY:
        delete formData.additionDateOption;
        delete formData.additionDayOfWeek;
        delete formData.additionDate;
        break;
      case TransferFrequency.WEEKLY:
        delete formData.additionDateOption;
        delete formData.additionDayOfMonth;
        delete formData.additionDate;
        break;
      case TransferFrequency.ONE_TIME:
        delete formData.additionDayOfWeek;
        delete formData.additionDayOfMonth;
        break;
    }

    if (formData.additionDateOption === DateOption.DateAsap) {
      delete formData.additionDate;
    }

    if (isOneTimeTransferForRetirementAccount && !ifTodaysDateBetweenTaxStartAndEndDates()) {
      // Set contribution year to current year if todays date is outside tax dates coming
      // from contentstack and frequency is one time and destination account is of retirement
      // account type
      formData.contributionYear = new Date().getFullYear();
    }

    const externalBankAccountInput = getExternalBankAccountInput(formData.eligibleSourceBankAccount, bankAccounts, {
      name: userName,
      partyId,
    });
    const createCashTransferInput: CreateCashTransferInput = {
      bankAccountWithParty: externalBankAccountInput,
      cashAmount: formData.additionAmount,
      destinationFinancialAccountId: destinationAccountId,
      sleeve: SleeveType.INVESTMENT,
      sourceFinancialAccountId: !externalBankAccountInput ? formData.eligibleSourceBankAccount : null,
      type: TransferType.DEPOSIT,
      frequency: formData.additionFrequency,
      day:
        formData.additionDayOfMonth !== undefined
          ? formData.additionDayOfMonth
          : formData.additionDayOfWeek !== undefined
          ? formData.additionDayOfWeek
          : undefined,
      scheduledAt: formData.additionDate
        ? toSymphonyDateTime(new Date(formData.additionDate.setHours(0, 0, 0, 0)))
        : undefined,
      contributionYear: formData.contributionYear,
    };

    onSubmitCallback?.(
      createCashTransferInput,
      formData.eligibleSourceBankAccount,
      externalBankAccountInput?.bankAccount,
    );
  };

  const isSymphonyError = (error: GraphQLError | SymphonyError): error is SymphonyError => {
    return 'code' in error || 'correlationId' in error;
  };

  const onLinkAccountCallback = (account: FinancialAccount) => {
    handleRefetchAccounts();
    setSelectedBankAccountId(account.id ?? bankAccounts[0].id ?? '');
    onLinkAccount?.(account);
  };

  const handleDatePreferenceChange = (_: any, newPreference: DateOption) => {
    if (newPreference !== null) {
      setValue(additionDateOptionName, newPreference, { shouldValidate: true, shouldDirty: true });
    }
  };

  const handleFrequencyTypeChange = (event: DropdownChangeEvent) => {
    const value = (event.target.value || TransferFrequency.ONE_TIME) as TransferFrequency;
    setValue(additionFrequencyName, value, { shouldValidate: true, shouldDirty: true });
  };

  const handleAdditionAmountChange = ({ value: amount }: NumberFormatValues) => {
    setValue(additionAmountName, amount, { shouldValidate: true, shouldDirty: true });
  };

  const handleContributionYearChange = (event: DropdownChangeEvent) => {
    const value = event.target.value || contributionYearOptions[0].value;
    setValue(contributionYearName, value, { shouldValidate: true, shouldDirty: true });
    setSelectedContributionYear(parseInt(value as string, 10));
  };

  const getDateFromString = (dateString: string): Date => {
    // Returns date object from MM/DD date string with current year
    const [month, day] = dateString.split('/');
    return new Date(new Date().getFullYear(), parseInt(month, 10) - 1, parseInt(day, 10));
  };

  const ifTodaysDateBetweenTaxStartAndEndDates = (): boolean => {
    const today: Date = new Date();
    const { startDateAndMonth, endDateAndMonth } = formContent.contributionYearDropdown;
    const startDate: Date = getDateFromString(startDateAndMonth);
    const endDate: Date = getDateFromString(endDateAndMonth);
    return today <= endDate && today >= startDate;
  };

  const obuscatedAccountNumber = concealAccountNumber(accountName);
  const maxAllowedAmount = formContent.amountField.maxAllowedAmount;
  const minAllowedAmount = formContent.amountField.minAllowedAmount;
  const isMaxAllowedAmountLesserThanBalance = maxAllowedAmount < maximumFunds;
  // Show dropdown if the following conditions are satisfied
  // 1. Frequency is one time
  // 2. Current date between tax startDate and endDate coming from contentstack
  // 3. Destination account type is a retirement account type
  const shouldDisplayContributionYearDropdown =
    isOneTimeTransferForRetirementAccount && ifTodaysDateBetweenTaxStartAndEndDates();

  const iraRecurringContributionText = formContent.iraRecurringContributionText;

  const shouldDisableDate = (date: Date) => {
    const currentYear = new Date().getFullYear();
    const isWeekendOrHoliday =
      shouldDisableMarketHolidays(date, marketHolidays, disableDateMarketHolidays) ||
      shouldDisableWeekends(date, disableDateWeekends);

    if (!isRetirementAccount) {
      // For non-retirement accounts, disable dates more than one year in the future
      return isWeekendOrHoliday || date > addYears(new Date(), 1);
    }

    if (selectedContributionYear === currentYear - 1) {
      // For prior year IRA contributions, disable dates after April 15th of the current year
      const aprilDeadline = new Date(currentYear, 3, 15); // Month is 0-indexed
      return isWeekendOrHoliday || date > aprilDeadline;
    }

    if (selectedContributionYear === currentYear) {
      // For current year IRA contributions, disable dates after December 31st of the current year
      const decemberDeadline = new Date(currentYear, 11, 31); // Month is 0-indexed
      return isWeekendOrHoliday || date > decemberDeadline;
    }

    // Default case: disable dates more than one year in the future
    return isWeekendOrHoliday || date > addYears(new Date(), 1);
  };

  const bankAccountItems = [
    ...bankAccountOptions,
    ...getAddAccountActions({
      isManualLinkageForAccountSupported: isManualLinkageForBankAccountSupported,
      isPlaidLinkageForAccountSupported: isPlaidLinkageForBankAccountSupported,
      manualLinkText: content?.link_bank_account,
      plaidLinkText: content?.link_plaid_bank_account,
    }),
  ];

  return (
    <Box data-qa={dataQa}>
      <FormControl sx={{ '& > *, & > .MuiFormControl-root': { mb: { xs: 2, md: 3 } } }}>
        <Typography align="center" component="div" data-qa={`${dataQa}-title`} gutterBottom>
          <RteContent
            config={{
              accountNumber: obuscatedAccountNumber,
            }}
            data={formContent.heading}
          />
        </Typography>
        {symphonyError && (
          <Alert contentOptions={contentOptions} error={symphonyError} severity="error">
            {isApolloError(symphonyError)
              ? symphonyError.graphQLErrors.find(err => isSymphonyError(err) && err.errorCode === 5)
                ? content?.verification_error_message
                : content?.submit_error_message
              : content?.submit_error_message}
          </Alert>
        )}
        <Box sx={{ '& .MuiInputLabel-root': { mb: 1, ml: -1.5 } }}>
          <Controller
            control={control}
            defaultValue={additionAmount ?? ''}
            name={additionAmountName}
            onChange={handleAdditionAmountChange}
            render={({ ref, value, name, onBlur }) => (
              <CurrencyTextField
                FormHelperTextProps={{ component: 'div' } as FormHelperTextProps}
                contentOptions={contentOptions}
                currencySymbol={currencySymbol}
                // Need to cast here: https://github.com/mui-org/material-ui/issues/20360
                error={!!fieldsErrors.additionAmount}
                fullWidth
                helperText={getAmountFieldHelperText(
                  fieldsErrors.additionAmount,
                  formContent.amountField.errors.minError,
                  <RteContent
                    config={{
                      availableFunds: formatCurrency(maximumFunds, { locale: contentOptions.locale }),
                    }}
                    data={formContent.amountField.errors.maxError}
                  />,
                  formContent.amountField.errors.requiredError,
                  isMaxAllowedAmountLesserThanBalance,
                  <RteContent
                    config={{
                      maxAllowedAmount: formatCurrency(maxAllowedAmount, { locale: contentOptions.locale }),
                    }}
                    data={formContent.amountField.errors.maxAllowedError}
                  />,
                  ignoreInsufficientFunds,
                  formContent.amountField.errors.accountTypeMaximumAmount,
                )}
                id={additionAmountName}
                inputRef={ref}
                label={formContent.amountField.label}
                name={name}
                onBlur={onBlur}
                onValueChange={handleAdditionAmountChange}
                placeholder={formContent.amountField.placeholder}
                value={parseFloat(value)}
              />
            )}
            rules={{
              min: minAllowedAmount,
              max: ignoreInsufficientFunds
                ? maxAllowedAmount
                : isMaxAllowedAmountLesserThanBalance
                ? maxAllowedAmount
                : maximumFunds,
              required: true,
              validate: {
                isFundingAmountValid: value =>
                  destinationAccountType
                    ? isFundingAmountValid(
                        destinationAccountType,
                        value,
                        amountValidationBasedOnAccountTypes,
                        calculateAge(userBirthDate ?? ''),
                        contributionYear,
                      )
                    : true,
              },
            }}
          />
        </Box>
        <Controller
          control={control}
          defaultValue={frequencyValue}
          name={additionFrequencyName}
          onChange={handleFrequencyTypeChange}
          render={({ ref, value, name, onBlur }) => (
            <Dropdown
              id={additionFrequencyName}
              inputRef={ref}
              items={formContent.frequencyDropdown.options}
              label={formContent.frequencyDropdown.label}
              name={name}
              onBlur={onBlur}
              onChange={handleFrequencyTypeChange}
              value={value}
              width="100%"
            />
          )}
        />
        {additionFrequency === TransferFrequency.ONE_TIME && (
          <>
            <ToggleButtonGroup exclusive fullWidth onChange={handleDatePreferenceChange} value={additionDateOption}>
              <ToggleButton id="date-option-asap" value={DateOption.DateAsap}>
                {formContent.dateOptions.now}
              </ToggleButton>
              <ToggleButton id="date-option-choose" value={DateOption.DateChoose}>
                {formContent.dateOptions.later}
              </ToggleButton>
            </ToggleButtonGroup>
            {additionDateOption === DateOption.DateChoose && (
              <Box data-qa={`${dataQa}-date-picker`} sx={{ py: 2 }}>
                <Controller
                  control={control}
                  defaultValue={asSoonAsPossibleDate}
                  name={additionDateName}
                  render={({ onChange, value }) => (
                    <DatePicker
                      defaultValue={asSoonAsPossibleDate}
                      disablePast
                      fullWidth
                      label={formContent.datePicker.label}
                      onChange={onChange}
                      shouldDisableDate={date => shouldDisableDate(date as Date)}
                      value={value}
                    />
                  )}
                  rules={{
                    required: true,
                    validate: {
                      // We want maxAllowedDate to take priority in validation, so place it first in array
                      [DatepickerValidations.mustBeLessThanMaxAdditionDate]: value =>
                        !maxAllowedDate || value <= maxAllowedDate,
                      [DatepickerValidations.mustBeBusinessDay]: value => !isWeekend(value),
                      [DatepickerValidations.isFutureDateValidation]: value => !isPast(value) || isToday(value),
                      [DatepickerValidations.isDate]: value => isValidDate(value),
                      [DatepickerValidations.mustNotBeAMarketHoliday]: value =>
                        !marketHolidays.includes(toSymphonyDate(value)),
                    },
                  }}
                />
                {!!fieldsErrors.additionDate &&
                  datepickerErrorDisplay({
                    fieldError: fieldsErrors.additionDate.type,
                    addFundsFormContent: formContent.datePicker,
                  })}
              </Box>
            )}
          </>
        )}
        {additionFrequency === TransferFrequency.MONTHLY && (
          <Controller
            control={control}
            defaultValue={1}
            name={additionDayOfMonthName}
            render={({ value, name, onBlur, onChange }) => (
              <Dropdown
                id={additionDayOfMonthName}
                items={getDaysOfMonthOptions()}
                label={formContent.datePicker.monthlyDayPickerLabel}
                name={name}
                onBlur={onBlur}
                onChange={e => onChange(e.target.value)}
                value={value}
                width="100%"
              />
            )}
          />
        )}
        {additionFrequency === TransferFrequency.WEEKLY && (
          <Controller
            control={control}
            defaultValue={Weekday.Monday}
            name={additionDayOfWeekName}
            render={({ value, name, onBlur, onChange }) => (
              <Dropdown
                id={additionDayOfWeekName}
                items={formContent.weekdayDropdown.options}
                label={formContent.datePicker.weekdayPickerLabel}
                name={name}
                onBlur={onBlur}
                onChange={e => onChange(e.target.value)}
                value={value}
                width="100%"
              />
            )}
          />
        )}
        {shouldDisplayContributionYearDropdown ? (
          <Controller
            control={control}
            defaultValue={contributionYearOptions[0].value}
            name={contributionYearName}
            onChange={handleContributionYearChange}
            render={({ ref, value, name, onBlur }) => (
              <Dropdown
                id={contributionYearName}
                inputRef={ref}
                items={formContent.contributionYearDropdown.options}
                label={formContent.contributionYearDropdown.label}
                name={name}
                onBlur={onBlur}
                onChange={handleContributionYearChange}
                tooltipText={formContent.contributionYearDropdown.tooltip}
                value={value}
                width="100%"
              />
            )}
          />
        ) : null}
        <Controller
          control={control}
          defaultValue={defaultBankAccountValue}
          name={eligibleSourceBankAccountName}
          render={({ value, name, onBlur, onChange }) =>
            allowEditManuallyLinkedBankAccounts ? (
              <DropdownWithEditableOptions
                defaultValue={value}
                disabled={isRefetchingAccounts}
                items={[
                  ...bankAccountItems,
                  {
                    label: content?.bank_source_close_cta ?? '',
                    type: 'list-item-button',
                    variant: 'outlined',
                  },
                ]}
                label={formContent.bankAccountDropdown.label}
                onChange={item => {
                  const eventValue = item.value;
                  if (eventValue === 'link-account-action') {
                    openModal();
                  } else if (eventValue === 'link-plaid-account-action') {
                    if (plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR) {
                      setOpenPlaidOtpVerification(true);
                    } else if (plaidLinkageType === PlaidLinkType.CLIENT) {
                      openPlaidLinkModal();
                    }
                  } else {
                    onChange(eventValue);
                    const selectStringValue = eventValue.toString();
                    if (selectStringValue !== noBankAccountsMessage) {
                      setSelectedBankAccountId(selectStringValue);
                    }
                    onBankAccountSelected?.();
                    if (showWarningForNonVerifiedFinancialAccountLinkages) {
                      handleCheckAccountVerification(selectStringValue);
                    }
                  }
                }}
              />
            ) : (
              <Dropdown
                disabled={isRefetchingAccounts}
                error={!!fieldsErrors.eligibleSourceBankAccount}
                id={eligibleSourceBankAccountName}
                items={bankAccountItems}
                label={formContent.bankAccountDropdown.label}
                name={name}
                onBlur={onBlur}
                onChange={event => {
                  const { value: eventValue } = event.target;
                  if (eventValue === 'link-account-action') {
                    openModal();
                  } else if (eventValue === 'link-plaid-account-action') {
                    if (plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR) {
                      setOpenPlaidOtpVerification(true);
                    } else if (plaidLinkageType === PlaidLinkType.CLIENT) {
                      openPlaidLinkModal();
                    }
                  } else {
                    onChange(eventValue);
                    const selectStringValue = eventValue.toString();
                    if (selectStringValue !== noBankAccountsMessage) {
                      setSelectedBankAccountId(selectStringValue);
                    }
                    onBankAccountSelected?.();
                    if (showWarningForNonVerifiedFinancialAccountLinkages) {
                      handleCheckAccountVerification(selectStringValue);
                    }
                  }
                }}
                value={value}
                width="100%"
              />
            )
          }
          rules={{
            required: true,
            validate: {
              mustHaveEligibleAccount: value => value !== noBankAccountsMessage,
            },
          }}
        />
        {showPlaidReAuthentication && (
          <Alert data-qa="re-authentication-failed-error" severity="error">
            <RteContent
              config={{
                reAuthenticateLink: (
                  <AlertTitle>
                    <Link
                      data-qa={`${dataQa}-re-authenticat-error-link`}
                      onClick={openPlaidReAuthenticatioModal}
                      variant="subtitle2"
                    >
                      {content?.plaid_re_authenticate_link}
                    </Link>
                  </AlertTitle>
                ),
              }}
              data={content?.plaid_re_authenticaion ?? ''}
            />
          </Alert>
        )}
        {hasMoneyInRestriction && (
          <Alert data-qa="money-in-restriction-warning" severity="warning">
            <AlertTitle>{moneyInRestrictionHeading}</AlertTitle>
            <Typography variant="body2">{moneyInRestrictionContent}</Typography>
          </Alert>
        )}
        {fieldsErrors.eligibleSourceBankAccount && noBankAccountsError && (
          <Alert severity="error">
            <Typography variant="body2">{noBankAccountsError}</Typography>
          </Alert>
        )}
        {/* TODO: Add this in Core Figma or Move this component to partner repo - FRC: DA2-5873 */}
        {iraRecurringContributionText && isRecurringTransferForRetirementAccount && (
          <RteContent data={iraRecurringContributionText} />
        )}
        {showVerificationWarningMessage && content?.verification_warning && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <RteContent data={content.verification_warning} />
          </Alert>
        )}
        <Typography variant="caption">{formContent.footer}</Typography>
      </FormControl>
      {isPlaidLinkageForBankAccountSupported && (
        <>
          {plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR && (
            <FaPlaidFlow
              contentOptions={contentOptions}
              isAccountLinkageSuccessful={!!isBankLinkageSuccessful}
              isPlaidFlowLaunched={openPlaidOtpVerification}
              onCloseModalCallback={handleCloseFaPlaidFlow}
              partyId={partyId}
              partyIdFA={partyIdFA}
              plaidProducts={PlaidProduct.AUTH}
              refetchAccounts={handleRefetchAccounts}
            />
          )}
          {plaidLinkageType === PlaidLinkType.CLIENT && (
            <>
              <ClientInitiatePlaidModal
                connectAccount={() => {
                  handleOpenPlaidLink(partyId, [PlaidProduct.AUTH]).then(_ => onClosePlaidLinkModal());
                }}
                contentOptions={contentOptions}
                onClose={onClosePlaidLinkModal}
                open={isOpenPlaidLinkModal}
              />
              <ClientLedPlaidSuccessModal
                contentOptions={contentOptions}
                handleConnectAccount={() => handleOpenPlaidLink(partyId, [PlaidProduct.AUTH])}
                onClose={handleClosePlaidLinkSuccessModal}
                open={isOpenClientPlaidLinkSuccessModal}
                plaidMetaData={plaidMetaData}
              />
            </>
          )}
        </>
      )}
      <LinkBankAccountModal
        contentOptions={contentOptions}
        managedProductId={managedProductId}
        onClose={() => {
          setSelectedAccountForEdit(undefined);
          onClose();
        }}
        onLinkAccount={account => onLinkAccountCallback(account)}
        open={open}
        partyId={partyId}
        selectedAccount={selectedAccountForEdit}
      />
      {showPlaidReAuthentication && selectedBankAccountId && selectedFinancialInstitution && (
        <PlaidReAuthentication
          contentOptions={contentOptions}
          financialInstitution={selectedFinancialInstitution}
          onAuthenticationSuccess={handleRefetchAccounts}
          onClose={closePlaidReAuthenticationModal}
          open={isPlaidReAuthenticationModalOpen}
          partyId={partyId}
          partyIdFA={partyIdFA}
          plaidItemId={plaidItemIdIfReAuthenticateFails}
          plaidLinkageType={plaidLinkageType}
        />
      )}
    </Box>
  );
};
