import React, { ComponentProps, useContext, useMemo } from 'react';

import { OpsDashboard } from '../..';
import { Comment, CommentComponentColumn } from '../../common/Comment';
import { LabeledValueField } from '../../common/LabeledValueField';
import { StatusChip } from '../../common/StatusChip';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { GetOpsDashboardContent } from '../../contentstack/__generated__/query.v2';
import { isAccountNumberClickable, OpsDashboardUser } from '../../utils';
import { DocusignItem } from '../types';

import { UserNoteEntityType } from '~/__generated__';
import { TableData } from '~/components/ui/BasicTable';
import { Typography } from '~/components/ui/Typography';
import { DocusignStatus } from '~/containers/OpsDashboard/DocusignTab/types';
import OpsDashboardContext from '~/containers/OpsDashboard/OpsDashboardContext';
import { ContentOptions } from '~/utils/contentstack';
import { formatDate, formatDateRelativeToToday } from '~/utils/format/date';

const accountNumberColumnValue = (
  item: DocusignItem,
  showProductName: boolean,
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'],
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'],
) => (
  <AccountNumber
    accountNumber={item.accountNumber}
    accountNumberText={item.accountNumber}
    label={showProductName ? item.productName : null}
    onClick={
      isAccountNumberClickable(item.accountState)
        ? () => onAccountClick(item.partyId, item.managedProductId)
        : undefined
    }
    redirectUrl={getAccountNumberRedirectUrl(item.partyId, item.managedProductId)}
  />
);

const clientNameColumnValue = (
  item: DocusignItem,
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'],
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'],
) => (
  <ClientName
    clientName={item.clientName}
    onClick={() => onClientClick(item.partyId)}
    redirectUrl={getClientNameRedirectUrl(item.partyId)}
  />
);

const dateGeneratedColumnValue = (item: DocusignItem) => (
  <LabeledValueField label={formatDateRelativeToToday(item.dateGenerated)} value={formatDate(item.dateGenerated)} />
);
const commentColumnValue = (
  contentOptions: ContentOptions,
  item: DocusignItem,
  currentUser: OpsDashboardUser,
  refetchData: () => void,
  commentColumn?: CommentComponentColumn,
) => (
  <Comment
    comment={item.comment}
    content={commentColumn}
    contentOptions={contentOptions}
    currentUser={currentUser}
    entity={UserNoteEntityType.SIGNING_DOCUMENT}
    entityId={item.id}
    key={item.id}
    lastCommentPartyId={item.lastCommentPartyId}
    refetchData={refetchData}
  />
);

const getEmailType = (opsContentData: GetOpsDashboardContent, recipientId: number): string => {
  enum PartyEmailType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
  }
  const PRIMARY_RECIPIENT_ID = 1;
  const labelKey = recipientId === PRIMARY_RECIPIENT_ID ? PartyEmailType.PRIMARY : PartyEmailType.SECONDARY;
  return opsContentData.all_ops_dashboard?.items?.[0]?.labels?.find(item => item?.key === labelKey)?.value ?? '';
};

const emailRecipientsColumnValue = (item: DocusignItem, opsContentData?: GetOpsDashboardContent) => {
  return item.emailRecipients.map((emailRecipient, index) => (
    <>
      <Typography key={index} sx={{ color: 'text.primary' }}>
        {emailRecipient.email}
      </Typography>
      <Typography key={`email-type-${index}`} sx={{ color: 'text.secondary' }} variant="body2">
        {opsContentData && getEmailType(opsContentData, emailRecipient.recipientId)}
      </Typography>
    </>
  ));
};

const statusColumnValue = (item: DocusignItem, status?: Record<DocusignStatus, string>) => (
  <StatusChip label={status ? status[item.status] : ''} />
);
export const useFormattedItems = ({
  status,
  items,
  refetchData,
  showProductName,
}: {
  items: DocusignItem[];
  refetchData: () => void;
  showProductName: boolean;
  status?: Record<DocusignStatus, string>;
}): TableData[] => {
  const {
    contentOptions,
    currentUser,
    onAccountClick,
    onClientClick,
    commentColumn,
    opsContentData,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
  } = useContext(OpsDashboardContext);
  return useMemo(
    () =>
      items.map(item => ({
        ...item,
        rowKey: item.id,
        accountNumber: accountNumberColumnValue(item, showProductName, onAccountClick, getAccountNumberRedirectUrl),
        clientName: clientNameColumnValue(item, onClientClick, getClientNameRedirectUrl),
        dateGenerated: dateGeneratedColumnValue(item),
        comment: commentColumnValue(contentOptions, item, currentUser, refetchData, commentColumn),
        emailRecipients: emailRecipientsColumnValue(item, opsContentData),
        status: statusColumnValue(item, status),
      })),
    [
      items,
      showProductName,
      onAccountClick,
      getAccountNumberRedirectUrl,
      onClientClick,
      getClientNameRedirectUrl,
      contentOptions,
      currentUser,
      refetchData,
      commentColumn,
      opsContentData,
      status,
    ],
  );
};
