import {
  GetAddFundsContent,
  GetAddFundsContent_all_add_funds_modal_items_fields,
  GetAddFundsContent_all_add_funds_modal_items_fields_AddFundsModalFieldsAmount,
  GetAddFundsContent_all_add_funds_modal_items_fields_AddFundsModalFieldsBankAccount,
  GetAddFundsContent_all_add_funds_modal_items_fields_AddFundsModalFieldsContributionYear,
  GetAddFundsContent_all_add_funds_modal_items_fields_AddFundsModalFieldsFrequency,
  GetAddFundsContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export type Field = GetAddFundsContent_all_add_funds_modal_items_fields;
export type AmountField = GetAddFundsContent_all_add_funds_modal_items_fields_AddFundsModalFieldsAmount;
export type FrequencyField = GetAddFundsContent_all_add_funds_modal_items_fields_AddFundsModalFieldsFrequency;
export type BankAccountField = GetAddFundsContent_all_add_funds_modal_items_fields_AddFundsModalFieldsBankAccount;
export type ContributionYearField = GetAddFundsContent_all_add_funds_modal_items_fields_AddFundsModalFieldsContributionYear;

export const isAmountField = (field: Field | null): field is AmountField =>
  field?.__typename === 'AddFundsModalFieldsAmount';

export const isFrequencyField = (field: Field | null): field is FrequencyField =>
  field?.__typename === 'AddFundsModalFieldsFrequency';

export const isBankAccountField = (field: Field | null): field is BankAccountField =>
  field?.__typename === 'AddFundsModalFieldsBankAccount';

export const isContributionYearField = (field: Field | null): field is ContributionYearField =>
  field?.__typename === 'AddFundsModalFieldsContributionYear';

export const useGetAddFundsContent = (options?: QueryHookOptions<GetAddFundsContent, GetAddFundsContentVariables>) => {
  return useContentstackQuery(queries.GetAddFundsContent, options);
};

export const useLazyGetAddFundsContent = (
  options?: QueryHookOptions<GetAddFundsContent, GetAddFundsContentVariables>,
) => {
  return useLazyContentstackQuery(queries.GetAddFundsContent, options);
};
