import { RequestClose, RequestCloseVariables } from './__generated__/mutation.v2';
import { GetDestinationAccounts, GetDestinationAccountsVariables } from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, QueryHookOptions } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export const useGetDestinationAccounts = (
  options?: QueryHookOptions<GetDestinationAccounts, GetDestinationAccountsVariables>,
) => {
  return useSymphonyQuery(queries.GetDestinationAccounts, options);
};

export const useRequestClose = (options?: MutationHookOptions<RequestClose, RequestCloseVariables>) => {
  return useSymphonyMutation(mutations.RequestClose, options);
};
