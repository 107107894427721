import * as queries from './query.gql';

import {
  GetAccountErrorsModalContent,
  GetAccountErrorsModalContentVariables,
} from '~/components/modals/AccountErrors/contentstack/__generated__/query.v2';
import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export const useGetAccountErrorsModalContent = (
  options?: QueryHookOptions<GetAccountErrorsModalContent, GetAccountErrorsModalContentVariables>,
) => {
  return useContentstackQuery(queries.GetAccountErrorsModalContent, options);
};

export const useLazyGetAccountErrorsModalContent = (
  options?: QueryHookOptions<GetAccountErrorsModalContent, GetAccountErrorsModalContentVariables>,
) => {
  return useLazyContentstackQuery(queries.GetAccountErrorsModalContent, options);
};
