import {
  GetModelPortfolioDetails,
  GetModelPortfolioDetails_managedProduct_billingDataV2_finalizedBillingRateData_blendedBillingRateComponent,
  GetModelPortfolioDetails_managedProduct_billingDataV2_stagedBillingRateData_blendedBillingRateComponent,
  GetModelPortfolioDetails_managedProduct_targetModelPortfolio,
  GetModelPortfolioDetailsVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type TargetModelPortfolio = GetModelPortfolioDetails_managedProduct_targetModelPortfolio;
export type BillingRate =
  | GetModelPortfolioDetails_managedProduct_billingDataV2_finalizedBillingRateData_blendedBillingRateComponent
  | GetModelPortfolioDetails_managedProduct_billingDataV2_stagedBillingRateData_blendedBillingRateComponent;

export const useGetModelPortfolioDetails = (
  options?: QueryHookOptions<GetModelPortfolioDetails, GetModelPortfolioDetailsVariables>,
): QueryResult<GetModelPortfolioDetails, GetModelPortfolioDetailsVariables> => {
  return useSymphonyQuery(queries.GetModelPortfolioDetails, options);
};
