import { ContactAdvisorModalContent } from '../contentstack';

import { ContactAdvisorModalData } from './useContactAdvisorData';

export const getContactAdvisorModalContent = (
  content: ContactAdvisorModalContent,
): ContactAdvisorModalData['content'] => {
  const advisor = content.contact_info?.find(contact => contact?.key === 'advisor');
  const email = content.contact_info?.find(contact => contact?.key === 'email');
  const phone = content.contact_info?.find(contact => contact?.key === 'phone');
  return {
    advisor: {
      icon: advisor?.iconConnection?.edges?.[0]?.node?.url ?? '',
      content: advisor?.label ?? '',
    },
    body: {
      image: content.body?.content_imageConnection?.edges?.[0]?.node?.url ?? '',
      heading: content.body?.heading ?? '',
    },
    ctas: {
      close: content.ctas?.close ?? '',
    },
    email: {
      icon: email?.iconConnection?.edges?.[0]?.node?.url ?? '',
      content: email?.label ?? '',
    },
    phone: {
      icon: phone?.iconConnection?.edges?.[0]?.node?.url ?? '',
      content: phone?.label ?? '',
    },
    title: content.heading ?? '',
  };
};
