import React, { ComponentProps, FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { GoalsCard } from '../Card';
import { DeleteModal } from '../Delete';
import { useGoalsEvent } from '../hooks/useGoalsEvent';
import { Recommended } from '../Recommended';
import { GoalActionArgs } from '../types';

import { CompleteGoalModal } from './CompleteGoal';
import { GoalsTimeline } from './GoalsTimeline';
import { useGoalSummary } from './hooks/useGoalSummary';

import { FinancialAccountType } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { useModalState } from '~/components/ui/Modal/hooks';
import { AddIcon, Box, Button, Stack, Tab, TabContext, TabPanel } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Tabs } from '~/components/ui/Tabs';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export enum GoalsSummaryTab {
  COMPLETED_GOALS = 'COMPLETED_GOALS',
  MY_GOALS = 'MY_GOALS',
}

export interface GoalSummaryContent {
  completeGoalModal: ComponentProps<typeof CompleteGoalModal>['content'];
  ctas: { addGoal: string };
  deleteModal: ComponentProps<typeof DeleteModal>['content'];
  errors: { birthdate: { description: string; heading: string } };
  heading: string;
  nullState: { completedGoals: string; myGoals: string };
  tabs: Record<GoalsSummaryTab, string>;
  timeline: ComponentProps<typeof GoalsTimeline>['content'];
}

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  financialAccounts: {
    accountType: FinancialAccountType;
    balance?: number;
    id?: string;
    managedProductId?: string;
    maskedAccountNumber?: string;
  }[];
  headingCta?: ReactNode;
  initialTab?: GoalsSummaryTab;
  onAccountClick: (managedProductId: string) => void;
  onCreateGoal: (goalObjectiveKey?: string) => void;
  onEditGoal: (args: GoalActionArgs) => void;
  partnerConfigId: string;
  partyId: string;
}

export const Summary: FC<Props> = ({
  contentOptions,
  financialAccounts,
  dataQa = 'goal-summary',
  headingCta,
  initialTab = GoalsSummaryTab.MY_GOALS,
  onAccountClick,
  onCreateGoal,
  onEditGoal,
  partnerConfigId,
  partyId,
}) => {
  const [currentTab, setCurrentTab] = useState(initialTab);
  const [targetGoalId, setTargetGoalId] = useState<string>();
  const { open: isDeleteModalOpen, openModal: openDeleteModal, onClose: onDeleteModalClose } = useModalState();
  const {
    open: isCompleteGoalModalOpen,
    openModal: openCompleteGoalModal,
    onClose: onCompleteGoalModalClose,
  } = useModalState();
  const emitEvent = useGoalsEvent();
  const isMobile = useIsMediumScreen();

  const actions = useMemo(
    () => ({
      onDelete: (args?: GoalActionArgs) => {
        setTargetGoalId(args?.goalId);
        openDeleteModal();
      },
      onEdit: onEditGoal,
      onComplete: (args?: GoalActionArgs) => {
        setTargetGoalId(args?.goalId);
        openCompleteGoalModal();
      },
    }),
    [onEditGoal, openCompleteGoalModal, openDeleteModal],
  );

  const { data, error, loading } = useGoalSummary({
    actions,
    contentOptions,
    financialAccounts,
    onAccountClick,
    partnerConfigId,
    partyId,
  });

  const handleCreateGoal = useCallback(() => {
    const isNullState = !data?.myGoals.length && !data?.completedGoals.length;
    emitEvent({ componentName: 'summary', name: isNullState ? 'createFirstGoal' : 'createGoal' });
    onCreateGoal();
  }, [data?.completedGoals.length, data?.myGoals.length, emitEvent, onCreateGoal]);

  if (loading) {
    return <CircularProgress />;
  }
  if (error || !data) {
    return <Alert contentOptions={contentOptions} error={error} severity="error" />;
  }

  const TabLabel: FC = ({ children }) => {
    if (isMobile) {
      return <>{children}</>;
    }
    return (
      <Typography component="span" variant="h3">
        {children}
      </Typography>
    );
  };

  return (
    <Box data-qa={dataQa}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        mb={3}
        spacing={{ xs: 1, md: 0 }}
        textAlign={{ xs: 'left', md: 'right' }}
      >
        <Typography component="h2" data-qa={`${dataQa}-heading`} variant="h3">
          {data.content.heading}
        </Typography>

        {headingCta}
      </Stack>
      {!isMobile && (
        <GoalsTimeline
          content={data.content.timeline}
          contentOptions={contentOptions}
          onCreateGoal={onCreateGoal}
          partnerConfigId={partnerConfigId}
          partyId={partyId}
        />
      )}
      <TabContext value={currentTab}>
        <Stack
          alignItems="center"
          data-qa={`${dataQa}-ctas`}
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={({ palette }) => ({ borderBottom: { sm: `1px solid ${palette.divider}` }, xs: 0 })}
        >
          <Box>
            <Tabs
              DropdownProps={{ variant: 'standard' }}
              data-qa={`${dataQa}-tabs`}
              enableMobileDropdown
              onChange={(value: GoalsSummaryTab) => setCurrentTab(value)}
              value={currentTab}
            >
              <Tab
                data-qa={`${dataQa}-tab-my-goals`}
                label={<TabLabel>{data.content.tabs[GoalsSummaryTab.MY_GOALS]}</TabLabel>}
                sx={{ textTransform: 'none' }}
                value={GoalsSummaryTab.MY_GOALS}
              />
              <Tab
                data-qa={`${dataQa}-tab-completed-goals`}
                label={<TabLabel>{data.content.tabs[GoalsSummaryTab.COMPLETED_GOALS]}</TabLabel>}
                sx={{ textTransform: 'none' }}
                value={GoalsSummaryTab.COMPLETED_GOALS}
              />
            </Tabs>
          </Box>
          <Box>
            <Button
              data-qa={`${dataQa}-add-goal`}
              onClick={handleCreateGoal}
              startIcon={<AddIcon />}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {data.content.ctas.addGoal}
            </Button>
          </Box>
        </Stack>

        <TabPanel data-qa={`${dataQa}-tab-my-goals`} sx={{ px: 0 }} value={GoalsSummaryTab.MY_GOALS}>
          <Stack spacing={2}>
            {!data.myGoals.length && <RteContent data={data.content.nullState.myGoals} my={5} textAlign="center" />}
            {data.myGoals.map(goalsCardProps => (
              <GoalsCard contentOptions={contentOptions} key={goalsCardProps.goalId} {...goalsCardProps} />
            ))}
            <Recommended contentOptions={contentOptions} onCreateGoal={onCreateGoal} partyId={partyId} />
          </Stack>
        </TabPanel>

        <TabPanel data-qa={`${dataQa}-tab-completed-goals`} sx={{ px: 0 }} value={GoalsSummaryTab.COMPLETED_GOALS}>
          {!data.completedGoals.length && (
            <RteContent data={data.content.nullState.completedGoals} my={5} textAlign="center" />
          )}
          <Stack spacing={2}>
            {data.completedGoals.map(goalsCardProps => (
              <GoalsCard contentOptions={contentOptions} key={goalsCardProps.goalId} {...goalsCardProps} />
            ))}
          </Stack>
        </TabPanel>

        {targetGoalId && (
          <DeleteModal
            content={data.content.deleteModal}
            contentOptions={contentOptions}
            goalId={targetGoalId}
            onClose={onDeleteModalClose}
            open={isDeleteModalOpen}
            partyId={partyId}
          />
        )}

        {targetGoalId && (
          <CompleteGoalModal
            content={data.content.completeGoalModal}
            contentOptions={contentOptions}
            goalId={targetGoalId}
            onClose={onCompleteGoalModalClose}
            open={isCompleteGoalModalOpen}
            partyId={partyId}
          />
        )}
      </TabContext>
    </Box>
  );
};
