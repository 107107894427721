import React, { ComponentProps, forwardRef } from 'react';

import { MuiTypography, visuallyHidden } from '~/components/ui/mui';

export interface Props extends ComponentProps<typeof MuiTypography> {
  component?: any;
  htmlFor?: string;
}

export const Typography: React.FC<Props> = forwardRef(({ children, 'aria-label': ariaLabel, ...rest }, ref) => {
  /*
   *  ADA: Since aria-label only works with form elements, the screen readers do not read when it's on a div, span, etc
   *  without role assigned.  The work around is to create an invisible element for the screen reader to read
   *  and the visible element with aria-hidden.
   *
   *  Sample Rendered HTML concept:
   *    <span style="visibility:hidden;">This content is to read by SR</span>
   *    <span aria-hidden="true">This content is for presentation only.</span>
   *
   */
  if (ariaLabel) {
    return (
      <>
        <MuiTypography {...rest} style={visuallyHidden}>
          {ariaLabel}
        </MuiTypography>

        <MuiTypography aria-hidden="true" ref={ref} {...rest}>
          {children}
        </MuiTypography>
      </>
    );
  }

  return (
    <MuiTypography ref={ref} {...rest}>
      {children}
    </MuiTypography>
  );
});
