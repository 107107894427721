import { GetPendingModelsV2, GetPendingModelsV2Variables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetPendingModelChangeTabData = (
  options: QueryHookOptions<GetPendingModelsV2, GetPendingModelsV2Variables>,
): QueryResult<GetPendingModelsV2, GetPendingModelsV2Variables> => {
  return useSymphonyQuery(queries.GetPendingModelsV2, options);
};
