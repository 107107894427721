import { UpdateFundingReviewV2, UpdateFundingReviewV2Variables } from './__generated__/mutation.v2';
import {
  GetFundingReviewsV2,
  GetFundingReviewsV2_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers,
  GetFundingReviewsV2_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers_AssetTransfer,
  GetFundingReviewsV2_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers_CashTransfer,
  GetFundingReviewsV2Variables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ScheduledTransfer = GetFundingReviewsV2_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers;
export type CashTransfer = GetFundingReviewsV2_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers_CashTransfer;
export type AssetTransfer = GetFundingReviewsV2_managedProductFundingReviews_fundingReviews_managedProduct_fundingDetails_scheduledTransfers_AssetTransfer;

export const useGetOpsDashboardFundingTableData = (
  options?: QueryHookOptions<GetFundingReviewsV2, GetFundingReviewsV2Variables>,
): QueryResult<GetFundingReviewsV2, GetFundingReviewsV2Variables> => {
  return useSymphonyQuery(queries.GetFundingReviewsV2, options);
};

export const useUpdateFundingReviewV2 = (
  options?: MutationHookOptions<UpdateFundingReviewV2, UpdateFundingReviewV2Variables>,
): MutationTuple<UpdateFundingReviewV2, UpdateFundingReviewV2Variables> => {
  return useSymphonyMutation(mutations.UpdateFundingReviewV2, options);
};
