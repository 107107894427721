import { ListItem as DropdownListItem } from '~/components/ui/Dropdown/types';

export const getAccountLabelFromVisibleAccounts = (
  managedProductId: string,
  visibleAccounts?: DropdownListItem[],
  maskedAccountNumber?: string | null,
) =>
  visibleAccounts?.find(a => 'value' in a && a.value === managedProductId)?.label.toString() ??
  maskedAccountNumber ??
  '';
