import { QueryResult } from '@apollo/client';

import { GetClientInitiatePlaidContent, GetClientInitiatePlaidContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetClientInitiatePlaidContent = (
  options?: QueryHookOptions<GetClientInitiatePlaidContent, GetClientInitiatePlaidContentVariables>,
): QueryResult<GetClientInitiatePlaidContent, GetClientInitiatePlaidContentVariables> => {
  return useContentstackQuery(queries.GetClientInitiatePlaidContent, options);
};
