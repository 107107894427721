import React, { ComponentProps, Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

import { FormData, ManagedAccountLabel } from './types';
import { WithdrawFundsForm } from './WithdrawFundsForm';
import { WithdrawFundsFormProgress } from './WithdrawFundsFormProgress';

import { RmdSummary } from '~/components/Rmd/RmdSummary';
import { formatRmdResponse } from '~/components/Rmd/RmdSummary/utils';
import { RmdData } from '~/components/Rmd/types';
import { TaxWithholdingForm } from '~/components/TaxWithholdingForm';
import { TaxWithholdingPlayback } from '~/components/TaxWithholdingPlayback';
import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, Grid, LoadingButton, Skeleton, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { formatCurrencyPrecise } from '~/utils/format';

export interface Props
  extends ComponentProps<typeof WithdrawFundsForm>,
    Omit<ComponentProps<typeof TaxWithholdingForm>, 'availableFunds' | 'content'> {
  accountLabels?: ManagedAccountLabel[];
  dataQa?: string;
  disableButton?: boolean;
  error?: Error;
  errors: DeepMap<FormData, FieldError>;
  formStep: number;
  isRetirementWithdrawal: boolean;
  isSubmitting: boolean;
  loading?: boolean;
  obfuscatedAccountNumber: string;
  onBack: () => void;
  onModalClose: () => void;
  onNext: () => Promise<void>;
  onSuccessCallback: (createNewWithdrawalRequest: boolean) => void;
  onTransferFrequencyTypeChange: () => void;
  open: boolean;
  rmdData?: RmdData;
  setDisableButton?: Dispatch<SetStateAction<boolean>>;
  setTotalWithdrawalAmount: (amount: string) => void;
}

export const WithdrawFundsModalBase: React.FC<Props> = ({
  accountLabels,
  availableFunds,
  bankAccounts,
  clientAge,
  content,
  contentOptions,
  dataQa = 'withdraw-funds-modal',
  disableButton,
  error,
  errors,
  financialAccountType,
  formData,
  formHooks,
  formStep,
  isRaiseCash,
  isRefetchingAccounts,
  isRetirementWithdrawal,
  isSubmitting,
  loading,
  managedProductId,
  marketHolidays,
  obfuscatedAccountNumber,
  onBack,
  onLinkAccount,
  onModalClose,
  onNext,
  onSuccessCallback,
  open,
  partyId,
  partyIdFA,
  plaidLinkageType,
  refetchBankAccounts,
  rmdData,
  onTransferFrequencyTypeChange,
  setDisableButton,
  setTotalWithdrawalAmount,
  symphonySubmitError,
  validateCashTransferResponse,
  ...withdrawFundsFormProps
}) => {
  const isFirstStep = formStep === 1;
  const isCompletedStep = formStep === 4;
  const showRmd = rmdData && rmdData.eligibleIraAccountIds.includes(managedProductId);

  const modalActions = useMemo(
    () =>
      isCompletedStep ? (
        <Stack direction="row" justifyContent={showRmd ? 'space-between' : 'flex-end'} width="100%">
          {showRmd && (
            <Button onClick={() => onSuccessCallback(true)}>
              {withdrawFundsFormProps.contentV2?.success.newRmdWithdrawalRequestActionLabel}
            </Button>
          )}
          <Button onClick={() => onSuccessCallback(false)} variant="outlined">
            {withdrawFundsFormProps.contentV2?.success.actionLabel}
          </Button>
        </Stack>
      ) : (
        <>
          <Button id="close-btn" onClick={() => (isFirstStep ? onModalClose() : onBack())} variant="outlined">
            {isFirstStep ? content?.secondaryCta : content?.backCta}
          </Button>
          <LoadingButton
            disabled={!!Object.keys(errors).length || isSubmitting || disableButton}
            id="withdraw-btn"
            loading={isSubmitting || !!isRefetchingAccounts}
            onClick={onNext}
            variant="contained"
          >
            {formStep === 3 || !isRetirementWithdrawal ? content?.primaryCta : content?.nextCta}
          </LoadingButton>
        </>
      ),
    [
      content?.backCta,
      content?.nextCta,
      content?.primaryCta,
      content?.secondaryCta,
      disableButton,
      errors,
      formStep,
      isCompletedStep,
      isFirstStep,
      isRefetchingAccounts,
      isRetirementWithdrawal,
      isSubmitting,
      onBack,
      onModalClose,
      onNext,
      onSuccessCallback,
      showRmd,
      withdrawFundsFormProps.contentV2?.success.actionLabel,
      withdrawFundsFormProps.contentV2?.success.newRmdWithdrawalRequestActionLabel,
    ],
  );

  const getSuccessMessage = useCallback(() => {
    if (!showRmd) {
      return (
        <Alert contentOptions={contentOptions} severity="success">
          <Typography>{withdrawFundsFormProps.contentV2?.success.alertText}</Typography>
        </Alert>
      );
    }
    if (rmdData?.isRefetchingRmdData) {
      return <Skeleton />;
    }
    if (rmdData.rmdAmounts.length === 2) {
      if (rmdData.rmdAmounts[1]?.remainingRmdAmountToBeWithdrawn > 0) {
        if (rmdData.rmdAmounts[0]?.remainingRmdAmountToBeWithdrawn > 0) {
          return (
            <Alert contentOptions={contentOptions} severity="warning">
              <RteContent
                config={{
                  currentAmount: formatCurrencyPrecise(rmdData.rmdAmounts[1].remainingRmdAmountToBeWithdrawn),
                  currentDeadline: rmdData.rmdAmounts[1].deadline,
                  extendedAmount: formatCurrencyPrecise(rmdData.rmdAmounts[0].remainingRmdAmountToBeWithdrawn),
                  extendedDeadline: rmdData.rmdAmounts[0].deadline,
                }}
                data={withdrawFundsFormProps.contentV2?.success.rmdSuccessWithTwoRemaining ?? ''}
              />
            </Alert>
          );
        }
        return (
          <Alert contentOptions={contentOptions} severity="warning">
            <RteContent
              config={{
                amount: formatCurrencyPrecise(rmdData.rmdAmounts[1].remainingRmdAmountToBeWithdrawn),
                deadline: rmdData.rmdAmounts[1].deadline,
              }}
              data={withdrawFundsFormProps.contentV2?.success.rmdSuccessWithOneRemaining ?? ''}
            />
          </Alert>
        );
      }
    }
    if (rmdData.rmdAmounts[0]?.remainingRmdAmountToBeWithdrawn > 0) {
      return (
        <Alert contentOptions={contentOptions} severity="warning">
          <RteContent
            config={{
              amount: formatCurrencyPrecise(rmdData.rmdAmounts[0].remainingRmdAmountToBeWithdrawn),
              deadline: rmdData.rmdAmounts[0].deadline,
            }}
            data={withdrawFundsFormProps.contentV2?.success.rmdSuccessWithOneRemaining ?? ''}
          />
        </Alert>
      );
    }
    return (
      <Alert contentOptions={contentOptions} severity="success">
        <Typography>{withdrawFundsFormProps.contentV2?.success.alertText}</Typography>
      </Alert>
    );
  }, [contentOptions, rmdData, showRmd, withdrawFundsFormProps.contentV2]);

  const modalContent = useMemo(
    () =>
      isCompletedStep ? (
        <Stack p={2} spacing={3}>
          <Box sx={{ height: 12, mb: 10 }}>
            <img alt="Success Image" src={withdrawFundsFormProps.contentV2?.success.image} />
          </Box>
          <Typography component="h3" variant="h5">
            {withdrawFundsFormProps.contentV2?.success.completedMessage}
          </Typography>
          {getSuccessMessage()}
        </Stack>
      ) : (
        <Grid
          sx={{
            '& a': {
              textDecoration: 'none',
              color: 'primary.main',
            },
          }}
        >
          <WithdrawFundsFormProgress
            activeStep={formStep}
            dataQa={dataQa}
            displayLinearProgress={isRetirementWithdrawal}
          />
          {!loading &&
            {
              1: (
                <>
                  {showRmd && withdrawFundsFormProps.contentV2?.rmdSummary && (
                    <RmdSummary
                      content={withdrawFundsFormProps.contentV2.rmdSummary}
                      data={formatRmdResponse(
                        rmdData.rmdAmounts,
                        rmdData.eligibleIraAccountIdsWithInformation,
                        accountLabels,
                        rmdData.eligibleIraAccountIds.length,
                      )}
                      onDismissRmd={rmdData.onDismissRmd}
                    />
                  )}
                  <WithdrawFundsForm
                    availableFunds={availableFunds}
                    bankAccounts={bankAccounts}
                    content={content}
                    contentOptions={contentOptions}
                    financialAccountType={financialAccountType}
                    formData={formData}
                    formHooks={formHooks}
                    isRaiseCash={isRaiseCash}
                    isRefetchingAccounts={isRefetchingAccounts}
                    managedProductId={managedProductId}
                    marketHolidays={marketHolidays}
                    onLinkAccount={onLinkAccount}
                    onTransferFrequencyTypeChange={onTransferFrequencyTypeChange}
                    partyId={partyId}
                    partyIdFA={partyIdFA}
                    plaidLinkageType={plaidLinkageType}
                    refetchBankAccounts={refetchBankAccounts}
                    setDisableButton={setDisableButton}
                    symphonySubmitError={symphonySubmitError}
                    {...withdrawFundsFormProps}
                  />
                  {showRmd && withdrawFundsFormProps.contentV2?.rmdSummary && (
                    <Typography variant="caption">{withdrawFundsFormProps.contentV2.rmdSummary.disclaimer}</Typography>
                  )}
                </>
              ),
              2: (
                <TaxWithholdingForm
                  availableFunds={availableFunds}
                  clientAge={clientAge}
                  content={content?.taxWithholdingFormContent}
                  financialAccountType={financialAccountType}
                  formData={formData}
                  formHooks={formHooks}
                  maxAllowedAmount={content?.amount.maxAllowedAmount}
                  setTotalWithdrawalAmount={setTotalWithdrawalAmount}
                  validateCashTransferResponse={validateCashTransferResponse}
                />
              ),
              3: (
                <TaxWithholdingPlayback
                  bankAccounts={bankAccounts}
                  clientAge={clientAge ?? null}
                  content={content?.taxWithholdingPlayback ?? null}
                  distributionReason={validateCashTransferResponse?.distributionCode}
                  formData={formData}
                  frequencyDropdownOptions={content?.frequency.frequencyDropdownOptions}
                  prematureAgeContent={content?.taxWithholdingFormContent?.soft_block_client_age?.premature}
                  symphonySubmitErrorMessage={symphonySubmitError?.message}
                />
              ),
            }[formStep]}
        </Grid>
      ),
    [
      availableFunds,
      bankAccounts,
      clientAge,
      content,
      contentOptions,
      dataQa,
      financialAccountType,
      formData,
      formHooks,
      formStep,
      getSuccessMessage,
      isCompletedStep,
      isRaiseCash,
      isRefetchingAccounts,
      isRetirementWithdrawal,
      loading,
      managedProductId,
      marketHolidays,
      onLinkAccount,
      onTransferFrequencyTypeChange,
      partyId,
      partyIdFA,
      plaidLinkageType,
      refetchBankAccounts,
      rmdData?.rmdAmounts,
      setDisableButton,
      setTotalWithdrawalAmount,
      showRmd,
      symphonySubmitError,
      validateCashTransferResponse,
      withdrawFundsFormProps,
    ],
  );

  const modalTitle = useMemo(
    () => (!isCompletedStep ? content?.title : withdrawFundsFormProps.contentV2?.success.title),
    [content?.title, isCompletedStep, withdrawFundsFormProps.contentV2?.success.title],
  );

  return (
    <Modal
      actions={modalActions}
      content={modalContent}
      contentOptions={contentOptions}
      data-qa="withdraw-funds-modal"
      error={error}
      loading={loading}
      onClose={onModalClose}
      open={open}
      title={modalTitle}
    />
  );
};
