import {
  GetAccountsTabContent,
  GetAccountsTabContent_all_all_accounts_tab_items_fields_text,
  GetAccountsTabContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type GetAccountsContent = GetAccountsTabContent;
export type TextFields = (GetAccountsTabContent_all_all_accounts_tab_items_fields_text | null)[] | null;

export const useGetAccountsTabContent = (
  options?: QueryHookOptions<GetAccountsTabContent, GetAccountsTabContentVariables>,
): QueryResult<GetAccountsTabContent, GetAccountsTabContentVariables> => {
  return useContentstackQuery(queries.GetAccountsTabContent, options);
};
