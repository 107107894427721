import React, { ComponentProps, FC, useState } from 'react';

import { Account } from '.';
import { AddFundsCTA } from './AddFundsCTA';

import { ActionName } from '~/components/AccountActions';
import { useLoadingButtonState } from '~/components/ui/Button/LoadingButton/hooks';
import { Button, LoadingButton } from '~/components/ui/mui';
import { Snackbar } from '~/components/ui/Snackbar';
import { useResendEnvelopeToNextRecipient } from '~/hooks/docusign/symphony';
import { AccountState, AccountStateData, nonPendingLegalDocumentStatuses } from '~/utils/account';
import { useIsMediumScreen } from '~/utils/responsiveness';

type Props = Pick<
  ComponentProps<typeof Account>,
  | 'accountData'
  | 'allAccounts'
  | 'bankAccounts'
  | 'content'
  | 'contentV2'
  | 'contributionAmount'
  | 'dataQa'
  | 'refetchingAccounts'
> & {
  accountState: AccountState;
  accountStateData?: AccountStateData;
  addFundsData: ComponentProps<typeof AddFundsCTA>['addFundsData'];
  onActionClick: (actionName: ActionName) => void;
  onRaiseCash: () => void;
};

export const PrimaryCta: FC<Props> = ({
  accountData,
  accountState,
  accountStateData,
  addFundsData,
  allAccounts,
  bankAccounts,
  content,
  contentV2,
  contributionAmount,
  dataQa = 'account-primary-cta',
  onActionClick,
  onRaiseCash,
  refetchingAccounts,
}) => {
  const isMediumScreen = useIsMediumScreen();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [continuePendingAccountLoading, onContinuePendingAccount] = useLoadingButtonState(() =>
    onActionClick('continuePendingAccount'),
  );
  const [resendEnvelopeToNextRecipient] = useResendEnvelopeToNextRecipient();
  const { actionToInvoke, actions, ctas, labels } = content;
  const { attributes, legalDocuments, program, type } = accountData;

  const pendingLegalDocument =
    ctas.resendDocusign &&
    actions.resendDocusign?.valid?.({ state: accountState, stateData: accountStateData, type, attributes })
      ? legalDocuments?.find(legalDocument => !nonPendingLegalDocumentStatuses.includes(legalDocument.status))
      : undefined;

  const resendDocusign = async (signingDocumentId: string) => {
    try {
      await resendEnvelopeToNextRecipient({ variables: { signingDocumentId } });
      setShowSnackBar(true);
    } catch (err) {
      console.error(`Error Sending Docusign to Client`, err);
    }
  };

  return (
    <>
      {showSnackBar && (
        <Snackbar
          data-qa={`${dataQa}-resend-email-confirmation`}
          feedbackMessage={labels.resendEmailSentMessage}
          onClose={() => setShowSnackBar(false)}
        />
      )}
      {ctas.continuePendingAccount &&
        (!actions.continuePendingAccount?.valid ||
          actions.continuePendingAccount.valid({
            state: accountState,
            stateData: accountStateData,
            managedProductType: program,
            type,
            attributes,
            allAccounts,
          })) && (
          <LoadingButton
            data-qa={`${dataQa}-cta-continue-pending-account`}
            disabled={refetchingAccounts}
            fullWidth={isMediumScreen}
            loading={continuePendingAccountLoading}
            onClick={onContinuePendingAccount}
            variant="contained"
          >
            {ctas.continuePendingAccount}
          </LoadingButton>
        )}
      {ctas.finishPendingAccount &&
        actions.finishPendingAccount?.valid?.({
          state: accountState,
          stateData: accountStateData,
          type,
          attributes,
          managedProductType: program,
        }) && (
          <Button
            data-qa={`${dataQa}-cta-finish-pending-account`}
            disabled={refetchingAccounts}
            fullWidth={isMediumScreen}
            onClick={() => onActionClick('finishPendingAccount')}
            variant="contained"
          >
            {ctas.finishPendingAccount}
          </Button>
        )}
      <AddFundsCTA
        accountData={accountData}
        accountState={accountState}
        actionToInvoke={actionToInvoke}
        actions={actions}
        addFundsData={addFundsData}
        bankAccounts={bankAccounts}
        contributionAmount={contributionAmount}
        ctas={{
          addFunds: ctas.addFunds,
          bankAccount: contentV2?.ctas.bankAccount ?? '',
          journaling: contentV2?.ctas.journaling ?? '',
        }}
        dataQa={dataQa}
        onActionClick={onActionClick}
        refetchingAccounts={refetchingAccounts}
      />
      {ctas.raiseCash &&
        actions.raiseCash?.valid?.({ state: accountState, stateData: accountStateData, type, attributes }) && (
          <Button
            data-qa={`${dataQa}-cta-raise-cash`}
            disabled={refetchingAccounts}
            fullWidth={isMediumScreen}
            onClick={onRaiseCash}
            variant="contained"
          >
            {ctas.raiseCash}
          </Button>
        )}
      {pendingLegalDocument && (
        <Button
          data-qa={`${dataQa}-cta-resend-docusign`}
          disabled={refetchingAccounts}
          fullWidth={isMediumScreen}
          onClick={() => resendDocusign(pendingLegalDocument.signingDocumentId)}
          variant="contained"
        >
          {ctas.resendDocusign}
        </Button>
      )}
      {ctas.generateDocuments &&
        actions.generateDocuments?.valid?.({
          state: accountState,
          stateData: accountStateData,
        }) && (
          <Button
            data-qa={`${dataQa}-cta-generate-documents`}
            disabled={refetchingAccounts}
            fullWidth={isMediumScreen}
            onClick={() => onActionClick('generateDocuments')}
            variant="contained"
          >
            {ctas.generateDocuments}
          </Button>
        )}
    </>
  );
};
