import React from 'react';

import { TableSectionContent } from './types';

import { BasicTable, TableData } from '~/components/ui/BasicTable';
import { Pagination, PaginationProps, Skeleton, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

interface TableSectionData {
  paginationProps: PaginationProps;
  tableData?: TableData[];
}

interface Props {
  content?: TableSectionContent;
  data?: TableSectionData;
  dataQa?: string;
  error?: Error;
  loading: boolean;
}

const styles = {
  containerCommonSx: { mt: 4 },
  emptyTableContainerSx: { alignItems: 'center', justifyContent: 'center', mt: 4 },
  paginationSx: { alignItems: 'center', mt: 2.5 },
  tableContainerSx: { mt: 2, overflowY: 'auto' },
  tableSx: {
    '&': { p: 0 },
    '& .MuiTableCell-root': { p: 0, py: 0.5 },
    '& .MuiTableCell-root.MuiTableCell-head': { px: 0, py: 2 },
  },
};

export const TableSection: React.FC<Props> = ({ content, data, dataQa = 'table-section', error, loading }) => {
  if (loading) {
    return (
      <Stack sx={styles.containerCommonSx}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    );
  }

  if (error) {
    return (
      <Stack sx={styles.emptyTableContainerSx}>
        <Typography>{content?.documentsFailedText}</Typography>
      </Stack>
    );
  }

  return (
    <>
      {data?.tableData?.length ? (
        <>
          <Stack data-qa={dataQa} sx={styles.tableContainerSx}>
            <BasicTable
              alignItems="left"
              columns={content?.columns ?? []}
              data={data.tableData}
              stickyHeader
              sx={styles.tableSx}
            />
          </Stack>
          <Stack data-qa={dataQa} sx={styles.paginationSx}>
            <Pagination {...data.paginationProps} />
          </Stack>
        </>
      ) : (
        <Stack sx={styles.emptyTableContainerSx}>
          <Typography>{content?.emptyTableText}</Typography>
        </Stack>
      )}
    </>
  );
};
