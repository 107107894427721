import React from 'react';

import { useGetCustodialMinorModalContent } from './contentstack';

import { Modal } from '~/components/ui/Modal';
import { Box } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  onCloseCallback?: () => void;
  open: boolean;
}
export const CustodialMinorModal = ({ contentOptions, dataQa = 'custodial-minor', onCloseCallback, open }: Props) => {
  const {
    data: contentstackData,
    loading: contentstackLoading,
    error: contentstackError,
  } = useGetCustodialMinorModalContent({
    variables: contentOptions,
  });

  const content = contentstackData?.all_custodial_minor_modal?.items?.[0];
  return (
    <Modal
      content={
        <Box>
          <img alt="" height="72px" src={content?.imageConnection?.edges?.[0]?.node?.url ?? ''} width="72px" />
          <Typography variant="h5">{content?.body_content}</Typography>
        </Box>
      }
      contentOptions={contentOptions}
      data-qa={`${dataQa}-modal`}
      error={contentstackError}
      loading={contentstackLoading}
      onClose={onCloseCallback}
      open={!!open}
      title={content?.heading ?? 'MISSING TITLE TEXT'}
    />
  );
};

export default CustodialMinorModal;
