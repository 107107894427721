import React, { ComponentProps, useEffect, useState } from 'react';

import { useSuspendBillingData } from './hooks/useGetSuspendBillingData';

import { Modal } from '~/components/ui/Modal';
import { Box } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Switch } from '~/components/ui/Switch';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  maskedAccountNumber: string;
  onClose: () => void;
  open: boolean;
};

export const SuspendBillingModal: React.FC<Props> = ({
  dataQa = 'suspend-billing-modal',
  managedProductId,
  maskedAccountNumber,
  contentOptions,
  open,
  onClose,
  ...modalProps
}) => {
  const [isBillingSuspended, setIsBillingSuspended] = useState(false);
  const { data, error, loading } = useSuspendBillingData({ contentOptions, managedProductId, open });

  const content = data?.content;
  useEffect(() => {
    setIsBillingSuspended(!!data?.isBillingSuspended);
  }, [data?.isBillingSuspended]);

  const handleBillingSuspensionUpdate = () => {
    data?.handleBillingSuspensionUpdate(!isBillingSuspended);
    setIsBillingSuspended(!isBillingSuspended);
  };

  return (
    <Modal
      {...modalProps}
      content={
        content && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <RteContent
                config={{ maskedAccountNumber }}
                data={content.body.manageSuspensionText}
                data-qa={`${dataQa}-text`}
              />
              <Typography data-qa={`${dataQa}-subtext`} sx={{ my: 1 }} variant="body2">
                {content.body.subtext}
              </Typography>
            </Box>
            <Box sx={{ alignItems: 'center', py: 4, display: 'flex', justifyContent: 'flex-start' }}>
              <Switch
                checked={isBillingSuspended}
                color="primary"
                data-qa={`${dataQa}-switch`}
                disabled={data.isBusy}
                onChange={handleBillingSuspensionUpdate}
              />
              <Typography data-qa={`${dataQa}-switch-label`}>
                {isBillingSuspended ? content.body.suspendedText : content.body.activeText}
              </Typography>
            </Box>
          </>
        )
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={error}
      loading={loading}
      onClose={onClose}
      open={open}
      title={content?.title}
    />
  );
};
