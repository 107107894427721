import { RestrictionsTabContent } from '../contentstack';

import { FieldsGroup, findFieldValue } from '~/utils/contentstack';

export enum RestrictionsTabEnum {
  Active = 'Active',
  History = 'History',
}

enum RestrictionContentKeys {
  TAB_ACTIVE = 'tab_active',
  TAB_HISTORY = 'tab_history',
}

export interface Content {
  fields: FieldsGroup | undefined | null;
  tabHeader: Record<RestrictionsTabEnum, string>;
}

export const getRestrictionsContent = (content?: RestrictionsTabContent | null): Content => {
  const contentData = content?.items?.[0];
  const textFields = contentData?.fields?.text ?? [];

  return {
    tabHeader: {
      [RestrictionsTabEnum.Active]: findFieldValue(textFields, RestrictionContentKeys.TAB_ACTIVE),
      [RestrictionsTabEnum.History]: findFieldValue(textFields, RestrictionContentKeys.TAB_HISTORY),
    },
    fields: contentData?.fields,
  };
};
