import {
  GetEditAccountContent,
  GetEditAccountContent_all_edit_account_modal_items_edit_account_cta,
  GetEditAccountContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery, useLazyContentstackQuery } from '~/utils/contentstack';

export type EditAccountCta = GetEditAccountContent_all_edit_account_modal_items_edit_account_cta;

export const useGetEditAccountContent = (
  options?: QueryHookOptions<GetEditAccountContent, GetEditAccountContentVariables>,
) => {
  return useContentstackQuery(queries.GetEditAccountContent, options);
};

export const useLazyGetEditAccountContent = (
  options?: QueryHookOptions<GetEditAccountContent, GetEditAccountContentVariables>,
) => {
  return useLazyContentstackQuery(queries.GetEditAccountContent, options);
};
