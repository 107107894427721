import { CloseAccountStatus } from '~/__generated__';
import { ClosureStatus, ClosureStatusFilter } from '~/containers/OpsDashboard/ClosuresTab/types';

export function toClosureStatus(status: CloseAccountStatus): ClosureStatus {
  switch (status) {
    // PENDING:
    case CloseAccountStatus.PENDING:
      return ClosureStatus.PENDING;
    // IN_PROGRESS:
    case CloseAccountStatus.REACHING_OUT_TO_CLIENT:
    case CloseAccountStatus.READY_TO_TRADE:
    case CloseAccountStatus.TRADES_SUBMITTED:
    case CloseAccountStatus.IN_PROCESS_AT_BROKER:
      return ClosureStatus.IN_PROGRESS;
    // COMPLETED/CANCELLED:
    case CloseAccountStatus.COMPLETED:
      return ClosureStatus.COMPLETED;
    case CloseAccountStatus.CANCELLED:
      return ClosureStatus.CANCELLED;
    default:
      return ClosureStatus.UNRECOGNIZED;
  }
}

export function toCloseAccountStatus(statusFilter: ClosureStatusFilter): CloseAccountStatus[] | null {
  switch (statusFilter) {
    case ClosureStatusFilter.IN_PROGRESS:
      return [
        CloseAccountStatus.REACHING_OUT_TO_CLIENT,
        CloseAccountStatus.READY_TO_TRADE,
        CloseAccountStatus.TRADES_SUBMITTED,
        CloseAccountStatus.IN_PROCESS_AT_BROKER,
      ];
    case ClosureStatusFilter.PENDING:
      return [CloseAccountStatus.PENDING];
    case ClosureStatusFilter.CANCELLED:
      return [CloseAccountStatus.CANCELLED];
    case ClosureStatusFilter.COMPLETED:
      return [CloseAccountStatus.COMPLETED];
    case ClosureStatusFilter.ALL:
    default:
      return [
        CloseAccountStatus.REACHING_OUT_TO_CLIENT,
        CloseAccountStatus.READY_TO_TRADE,
        CloseAccountStatus.TRADES_SUBMITTED,
        CloseAccountStatus.IN_PROCESS_AT_BROKER,
        CloseAccountStatus.PENDING,
        CloseAccountStatus.COMPLETED,
        CloseAccountStatus.CANCELLED,
      ];
  }
}

export const closuresToAllowedStatusUpdates = (status: ClosureStatus): ClosureStatus[] => {
  switch (status) {
    case ClosureStatus.PENDING:
    case ClosureStatus.IN_PROGRESS:
      return [ClosureStatus.CANCELLED, ClosureStatus.COMPLETED];
    default:
      return [];
  }
};

export const closureStatusToCloseAccountStatusForUpdate = (status: ClosureStatus): CloseAccountStatus | null => {
  switch (status) {
    case ClosureStatus.CANCELLED:
      return CloseAccountStatus.CANCELLED;
    case ClosureStatus.COMPLETED:
      return CloseAccountStatus.COMPLETED;
    case ClosureStatus.IN_PROGRESS:
    case ClosureStatus.PENDING:
    case ClosureStatus.UNRECOGNIZED:
      return null;
  }
};
