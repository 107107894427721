import React, { useCallback, useMemo, useState } from 'react';

import { useControlFieldSectionData } from '../../hooks/useControlFieldSectionData';
import { useTableSectionData } from '../../hooks/useTableSectionData';
import { getDefaultFieldConfig, getDefaultFieldValues } from '../../utils';
import { ControlFieldSection } from '../ControlFieldSection';
import { ControlFieldConfig } from '../ControlFieldSection/types';
import { TableSection } from '../TableSection';

import { useStatementsTabContent } from './hooks/useStatementsTabContent';

import { DocumentCategoryEnum } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { Box, Skeleton } from '~/components/ui/mui';
import { Snackbar, SnackbarPositionType } from '~/components/ui/Snackbar';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

interface Props {
  clientDocumentsPerPage: number;
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
}

const styles = { snackBarSx: { width: 560 } };

export const StatementsTab: React.FC<Props> = ({
  clientDocumentsPerPage,
  contentOptions,
  dataQa = 'statements-tab',
  managedProductId,
}) => {
  const [showDownloadError, setShowDownloadError] = useState<boolean>(false);

  const { data: content, error: contentError, loading: contentLoading } = useStatementsTabContent({
    contentOptions,
  });

  const defaultFieldConfig = useMemo(
    (): ControlFieldConfig => getDefaultFieldConfig(DocumentCategoryEnum.STATEMENTS, content?.controlFields),
    [content],
  );

  const defaultFieldValues = getDefaultFieldValues(DocumentCategoryEnum.STATEMENTS);
  const fieldData = useControlFieldSectionData(defaultFieldConfig, defaultFieldValues);

  const onDownloadError = useCallback(() => {
    setShowDownloadError(true);
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setShowDownloadError(false); // Set showDownloadError to false to hide the Snackbar
  }, []);

  const { data: tableSectionData, error: tableSectionError, loading: tableSectionLoading } = useTableSectionData({
    content: content?.tableContent,
    contentOptions,
    documentCategory: DocumentCategoryEnum.STATEMENTS,
    clientDocumentsPerPage,
    fieldValues: fieldData.values,
    managedProductId,
    onDownloadError,
  });

  if (contentLoading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (contentError) {
    return <Alert contentOptions={contentOptions} error={contentError} severity="error" />;
  }

  return (
    <Box data-qa={dataQa}>
      <ControlFieldSection data={fieldData} />
      <TableSection
        content={content?.tableContent}
        data={tableSectionData}
        error={tableSectionError}
        loading={tableSectionLoading}
      />
      <Snackbar
        action={undefined} // To override the actions defined in Snackbar
        feedbackMessage="" // To override the feedback message in Snackar
        message={<Typography sx={styles.snackBarSx}>{content?.downloadError}</Typography>}
        onClose={handleCloseSnackbar}
        open={showDownloadError}
        snackbarPosition={SnackbarPositionType.BOTTOM_LEFT}
      />
    </Box>
  );
};
