import { TabState as AccountActivityTabState } from '~/components/AccountDetailsAccountActivity/types';
import { AccountDetailsTabsEnum } from '~/hooks/account-details/types';

export const clientDocumentsPerPage = 10;

export interface TabsState {
  accountActivity?: AccountActivityTabState;
}

export interface InitialAccountDetailsState {
  initialTab?: AccountDetailsTabsEnum;
}
