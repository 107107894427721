import { addSeconds } from 'date-fns';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { SessionTimeoutImage } from './image';
import { useSessionTimeoutMessageContent } from './useSessionTimeoutMessageContent';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, Skeleton, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

/*
  The purpose of this component will be to appear when the session is about to expire.
  Once this component is rendered, a timer will start... The default
  will be 10 minutes... But this can be overridden by passing in the timeOutInSeconds prop.
  In practice that value will likely come from Auth0 user context and be passed into this component.

  Once the keep alive end point for a partner is hit, the externalSessionRefreshEpoch should be updated
  and the timer reset to start again.

  If there is no activity by the user (i.e. no keep alive call) and the timeoutThreshold is met
  (i.e. timer starts at 600 and threshHold is 100, so timer runs for 500 seconds, at second 501 this screen will render)
  the screen will render and give the user an option to extend session.  If they do, keep alive end point will be called
  (passed in as the refreshCallback) and the timer will restart.

  If the timer expires, call logout function.

  The setInterval is not a precise track of time since their execution may be halted by the browser due to tab inactivity.
  As a result, for long timeout period of time like 30 min, the timeout message will not reliably get rendered.
  Instead of tracking ticks, capture the exact timestamp to time out or expire to compare against the current time for
  each interval execution.
*/
type TimeToShowMessage = { expire: number; timeout: number };

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  logoutCallback: () => void; // function that will be called once the timeout has occurred
  navigateCallback: () => void; // function to be called on the button no navigate elsewhere
  refreshCallback: () => void; // function to be called to continue (refresh) the session
  timeOutInSeconds: number; // how long before the session expired
  timeoutThreshold: number; // how long prior to the session expiring should show the session message
}

export const SessionTimeoutMessage = forwardRef((props: Props, ref) => {
  const {
    timeOutInSeconds = 1800,
    timeoutThreshold = 100,
    refreshCallback,
    contentOptions,
    dataQa = 'session-timeout',
    navigateCallback,
    logoutCallback,
  } = props;
  const getUpdatedMessageTime = useCallback(
    (): TimeToShowMessage => ({
      expire: addSeconds(Date.now(), timeOutInSeconds).getTime(),
      timeout: addSeconds(Date.now(), timeOutInSeconds - timeoutThreshold).getTime(),
    }),
    [timeOutInSeconds, timeoutThreshold],
  );

  const [expired, setExpired] = useState(false);
  const [show, setShow] = useState(false);
  const [timeToShowMessage, setTimeToShowMessage] = useState<TimeToShowMessage>(getUpdatedMessageTime);

  const sessionInterval = useRef<ReturnType<typeof setInterval>>();
  const timeoutMessageRef = useRef<HTMLElement>(null);

  const isValid = useMemo(() => timeOutInSeconds > timeoutThreshold, [timeOutInSeconds, timeoutThreshold]);

  const {
    data: sessionTimeoutMessageContentData,
    loading: sessionTimeoutMessageContentDataLoading,
    error: sessionTimeoutMessageContentDataError,
  } = useSessionTimeoutMessageContent({ variables: contentOptions, skip: !show && !expired });

  const clearSessionInterval = useCallback(() => {
    if (sessionInterval.current) {
      clearInterval(sessionInterval.current);
    }
  }, []);

  const reset = useCallback(
    keepWindowOpen => {
      setTimeToShowMessage(getUpdatedMessageTime());
      const windowOpen = keepWindowOpen && show;
      setShow(windowOpen);
      // if called with true the session was already reset
      if (!keepWindowOpen) {
        refreshCallback();
      }
    },
    [getUpdatedMessageTime, show, refreshCallback],
  );

  useImperativeHandle(ref, () => ({
    resetSession() {
      reset(true); // call to reset session can be made (say on a mouse click or scroll) but it is an odd behaviour to have the screen close on a mouse movement
    },
  }));

  const isTimeToShowScreen = useCallback(() => {
    const now = Date.now();
    if (!show && now >= timeToShowMessage.timeout && now < timeToShowMessage.expire) {
      setShow(true);
      timeoutMessageRef.current?.focus();
    }

    if (!expired && now >= timeToShowMessage.expire) {
      clearSessionInterval();
      setExpired(true);
      timeoutMessageRef.current?.focus();
      logoutCallback();
    }
  }, [clearSessionInterval, expired, logoutCallback, timeToShowMessage, show]);

  useEffect(() => {
    if (isValid && !expired) {
      sessionInterval.current = setInterval(isTimeToShowScreen, 1000);
    }
    return clearSessionInterval;
  }, [isTimeToShowScreen, isValid, clearSessionInterval, expired]);

  if (!isValid) {
    console.warn('session timeout message is not configured correctly.  Timeout cannot be less than timeout threshold');
    return null;
  }
  if (!show && !expired) {
    return null;
  }

  if (sessionTimeoutMessageContentDataError) {
    return <Alert contentOptions={contentOptions} error={sessionTimeoutMessageContentDataError} severity="error" />;
  }

  return (
    <Modal
      content={
        <Stack alignItems="center" justifyContent="center" spacing={3}>
          <SessionTimeoutImage />
          {sessionTimeoutMessageContentDataLoading && <Skeleton width={400} />}

          <Box ref={timeoutMessageRef} sx={{ textAlign: 'center', ':focus': { outline: 'none' } }} tabIndex={-1}>
            <Typography color="text.primary" component="h1" variant="h3">
              {expired ? sessionTimeoutMessageContentData?.expiredMessage : sessionTimeoutMessageContentData?.heading}
            </Typography>
            <Typography color="text.primary" component="h2" mt={2} variant="body2">
              {expired
                ? sessionTimeoutMessageContentData?.expiredSubMessage
                : sessionTimeoutMessageContentData?.subHeading}
            </Typography>
          </Box>

          {sessionTimeoutMessageContentData && !expired && (
            <Stack direction="row" spacing={2}>
              <Button id="navigate-btn" onClick={navigateCallback} variant="outlined">
                {sessionTimeoutMessageContentData.ctas.navigate}
              </Button>
              <Button id="continue-session-btn" onClick={() => reset(false)} variant="contained">
                {sessionTimeoutMessageContentData.ctas.continue}
              </Button>
            </Stack>
          )}
        </Stack>
      }
      dataQa={dataQa}
      fullScreen
      hideHeader
      open
      sx={{ '.MuiDialogContent-root': { display: 'flex', justifyContent: 'center' } }}
    />
  );
});
