import { add, addBusinessDays, isPast, isToday, isWeekend } from 'date-fns';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';

import { FaPlaidFlow } from '../../FaPlaidFlow';
import { ClientInitiatePlaidModal } from '../ClientInitiatePlaid';
import { ClientLedPlaidSuccessModal } from '../ClientLedPlaidSuccess';
import { ChangeInvestmentMethodModal } from '../Funding/ChangeInvestmentMethod';
import { HoldingTableDrawer } from '../Funding/HoldingsTable';
import { LinkBrokerageAccountModal } from '../LinkBrokerageAccount';
import { datepickerErrorDisplay, DatepickerValidations } from '../utils/datepicker-helper';

import { DestinationAccount, FormData, FormProps } from './types';
import {
  createAssetTransferInput,
  createCashTransferInput,
  getAmountFieldHelperText,
  getConfirmationFields,
  getDCAAlertMessage,
  getDropdownActions,
  getMaskedBrokerageAccountNumber,
  getPlaybackFields,
  getSourceBasedAccountsDropdownItems,
  getTransferDateMessage,
  manipulateFormData,
} from './utils';

import {
  AccountRestrictionType,
  BalanceType,
  CreateAssetTransferInput,
  CreateCashTransferInput,
  FinancialAccountType,
  PlaidProduct,
  TransferFrequency,
  TransferFrequencyType,
} from '~/__generated__/symphonyTypes.v2';
import { LinkBankAccountModal } from '~/components/modals/LinkBankAccount';
import { PlaidReAuthentication } from '~/components/modals/PlaidReAuthentication';
import { Alert } from '~/components/ui/Alert';
import { CheckboxGroup } from '~/components/ui/CheckboxGroup';
import { DatePicker } from '~/components/ui/DatePicker';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownWithEditableOptions } from '~/components/ui/Dropdown/DropdownWithEditableOptions';
import { DropdownChangeEvent, DropdownItem } from '~/components/ui/Dropdown/types';
import { Link } from '~/components/ui/Link';
import { useModalState } from '~/components/ui/Modal/hooks';
import {
  AlertTitle,
  Box,
  Button,
  EditIcon,
  FormControl,
  FormHelperTextProps,
  ModeIcon,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { TextField } from '~/components/ui/TextField';
import { CurrencyTextField } from '~/components/ui/TextField/CurrencyTextField';
import { Typography } from '~/components/ui/Typography';
import { isFundingAmountValid } from '~/containers/Funding/utils';
import { InvestmentMethods } from '~/containers/FundingV2/types';
import { useGetAddAccountSuccess } from '~/hooks/account/useGetAddAccountSuccess';
import { FinancialAccount, Weekday } from '~/hooks/financial-account/symphony';
import { DateOption } from '~/hooks/financial-account/types';
import { PlaidAccessTokenMetaData, PlaidLinkType, usePlaidLinkHook } from '~/hooks/plaid-link';
import {
  getAccountBalance,
  getContributionYearOptions,
  getExternalBankAccountInput,
  getIsBankAccountAssociationNonVerified,
  getMaskedAccountNumberWithFinancialInstitutionName,
  getPlaidItemIdIfReAuthenticateFails,
  isRetirementAccountType,
} from '~/utils/account';
import { GraphQLError, isApolloError } from '~/utils/apollo-client';
import {
  shouldDisableAfterXDays,
  shouldDisableMarketHolidays,
  shouldDisableRestCurrentYearForRetirementAccounts,
  shouldDisableWeekends,
} from '~/utils/client';
import { FundingSources, useCoreConfig } from '~/utils/config';
import { getDaysOfMonthOptions } from '~/utils/dropdown';
import { formatCurrency, isValidDate, localeToCurrencySymbol } from '~/utils/format';
import { calculateAge } from '~/utils/format/date';
import { SymphonyError, toSymphonyDate } from '~/utils/symphony';

const fundingSourceName = 'fundingSource';
const eligibleSourceAccountName = 'eligibleSourceAccount';
const additionAmountName = 'additionAmount';
const additionFrequencyName = 'additionFrequency';
const additionDateOptionName = 'additionDateOption';
const additionDateName = 'additionDate';
const additionDayOfWeekName = 'additionDayOfWeek';
const additionDayOfMonthName = 'additionDayOfMonth';
const contributionYearName = 'contributionYear';
const destinationAccountName = 'destinationAccount';
const brokerageAccountNumberFieldName = 'brokerageAccountNumber';
const brokerageNameFieldName = 'brokerageAccountName';
const brokerageAccountAliasName = 'brokerageAccountAlias';
const holdingDataName = 'holdingData';
const dcaInitialAmountName = 'dcaInitialAmount';
const dcaFrequencyName = 'dcaFrequency';
const dcaInvestmentPeriodName = 'dcaInvestmentPeriod';
const investmentMethodName = 'investmentMethod';

export const AddFundsV2Form: FC<FormProps> = ({
  accountHasRecurringDeposit,
  accountsData,
  accountRestrictions,
  additionAmount,
  accountType,
  bankAccountId,
  content,
  contentOptions,
  currentStep,
  dataQa = 'add-funds-form',
  defaultFundingSource,
  dateOptionValue = DateOption.DateAsap,
  defaultDestinationAccountId,
  defaultTransferFrequencyType,
  destinationAccounts,
  frequencyValue = TransferFrequency.ONE_TIME,
  ignoreInsufficientFunds = false,
  isRefetchingAccounts = false,
  isSubmitted,
  managedProductId,
  marketHolidays,
  onLinkAccount,
  onSubmitCallback,
  onValidationSuccessful,
  onValidationUnsuccessful,
  partyId,
  partyIdFA,
  plaidLinkageType,
  refetchParentContainerAccounts,
  setDisableButton,
  setFundingSourceToFetch,
  soonestPossibleDays = 0,
  triggerValidation,
  symphonyError,
  onAccountSelected,
  userName = '',
  userBirthDate,
}) => {
  const {
    sfAddFunds: { styles },
    palette,
  } = useTheme();
  const {
    components: {
      sfAddFunds: {
        disableDateWeekends,
        disableDateAfterXDays,
        disableDateMarketHolidays,
        disableDateCurrentYearForRetirementAccounts,
      },
      sfFunding: { amountValidationBasedOnAccountTypes, useManualBrokerageEntry },
    },
    featureFlags: {
      allowPlaidReAuthentication,
      allowEditManuallyLinkedBankAccounts,
      isManualLinkageForBankAccountSupported,
      isManualLinkageForBrokerageAccountSupported,
      isPlaidLinkageForBankAccountSupported,
      isPlaidLinkageForBrokerageAccountSupported,
      showWarningForNonVerifiedFinancialAccountLinkages,
    },
  } = useCoreConfig();
  const {
    open: isBankLinkageModalOpen,
    openModal: openBankLinkageModal,
    onClose: closeBankLinkageModal,
  } = useModalState();
  const {
    open: isManualBrokerageLinkingModalOpen,
    openModal: openManualBrokerageLinkingModal,
    onClose: closeManualBrokerageLinkingModal,
  } = useModalState();
  const {
    open: isHoldingAssetsDrawerOpen,
    openModal: openHoldingAssetsDrawer,
    onClose: closeHoldingAssetsDrawer,
  } = useModalState();
  const { open: isOpenPlaidLinkModal, openModal: openPlaidLinkModal, onClose: onClosePlaidLinkModal } = useModalState();
  const {
    open: isOpenClientPlaidLinkSuccessModal,
    openModal: openClientPlaidLinkSuccessModal,
    onClose: onCloseClientPlaidLinkSuccessModal,
  } = useModalState();
  const {
    open: isPlaidReAuthenticationModalOpen,
    openModal: openPlaidReAuthenticatioModal,
    onClose: closePlaidReAuthenticationModal,
  } = useModalState();
  const {
    open: isChangeInvestmentMethodOpen,
    openModal: openChangeInvestmentMethod,
    onClose: closeChangeInvestmentMethod,
  } = useModalState();

  const hasMoneyInRestriction = accountRestrictions?.find(v => v.restrictionType === AccountRestrictionType.MONEY_IN);
  const [transferFrequencyType, setTransferFrequencyType] = useState(defaultTransferFrequencyType);
  const [destinationAccount, setDestinationAccount] = useState<DestinationAccount | undefined>(
    destinationAccounts.find(a => a.accountId === defaultDestinationAccountId),
  );
  const [scheduleForFutureDate, setScheduleForFutureDate] = useState<boolean>(false);
  const [plaidMetaData, setPlaidMetaData] = useState<PlaidAccessTokenMetaData | undefined>(undefined);
  const [selectedAccountId, setSelectedAccountId] = useState<string>('');
  const [showConfirmAccountNumberField, setShowConfirmAccountNumberField] = useState(false);
  const [openPlaidOtpVerification, setOpenPlaidOtpVerification] = useState(false);
  const [showVerificationWarningMessage, setShowVerificationWarningMessage] = useState(false);
  const [isManualBrokerage, setIsManualBrokerage] = useState(false);
  const [asSoonAsPossibleDate, setAsSoonAsPossibleDate] = useState<Date>(
    addBusinessDays(new Date(), soonestPossibleDays),
  );
  const availableBalance = useRef<number>();
  const [maximumFunds, setMaximumFunds] = useState(Number.MAX_VALUE);
  const [selectedAccountForEdit, setSelectedAccountForEdit] = useState<FinancialAccount>();

  const noAccountsMessage = content.accountsDropdown.noAccountsMessage;
  const noAccountsError = content.accountsDropdown.noAccountsError;
  const {
    watch,
    handleSubmit,
    errors: fieldsErrors,
    control,
    setValue,
    trigger,
    clearErrors,
    getValues,
  } = useFormContext();
  const fundingSource = watch(fundingSourceName, defaultFundingSource) as FundingSources;
  const formAdditionAmount = watch(additionAmountName);
  const additionFrequency = watch(additionFrequencyName);
  const contributionYear = watch(contributionYearName);
  const sourceAccountId = watch(eligibleSourceAccountName, '');
  const scheduledTransferDate = watch(additionDateName);
  const additionDayOfMonth = watch(additionDayOfMonthName);
  const additionDayOfWeek = watch(additionDayOfWeekName);
  const dcaInitialAmount = watch(dcaInitialAmountName);
  const dcaInvestmentPeriod = watch(dcaInvestmentPeriodName);
  const dcaFrequency = watch(dcaFrequencyName);
  const investmentMethod = watch(investmentMethodName);
  const brokerageAccountNumber = useRef<string | undefined>(undefined);
  const brokerageAccountName = useRef<string | undefined>(undefined);
  const isFundingSourceBankAccount = fundingSource === FundingSources.bankAccount;
  const isNonManualBrokerageEntry = fundingSource === FundingSources.otherBrokerageAccount && !isManualBrokerage;
  const handleEditingBankAccount = useCallback(
    (item: DropdownItem) => {
      const editedAccount = accountsData?.bankAccounts?.find(account => account.id === item.value);
      setSelectedAccountForEdit(editedAccount);
    },
    [accountsData?.bankAccounts],
  );
  const maxAllowedAmount = content.amountField.maxAllowedAmount;
  const minAllowedAmount = content.amountField.minAllowedAmount;
  const isMaxAllowedAmountLesserThanBalance = maxAllowedAmount < maximumFunds;
  const selectedBrokerageAccountHasPositions =
    isNonManualBrokerageEntry &&
    !!accountsData?.brokerageAccounts?.find(acc => acc.id === selectedAccountId)?.positions?.length;
  useEffect(() => {
    if (selectedAccountForEdit && !isBankLinkageModalOpen) {
      openBankLinkageModal();
    }
  }, [isBankLinkageModalOpen, openBankLinkageModal, selectedAccountForEdit]);

  const sourceBasedAccountsDropdownItems = useMemo(
    () =>
      getSourceBasedAccountsDropdownItems({
        bankAccounts: accountsData?.bankAccounts,
        brokerageAccounts: accountsData?.brokerageAccounts,
        noAccountsMessage,
        availableAccountsHeader: content.accountsDropdown.availableAccountsHeader,
        fundingSource,
        editDetailsOnClick: allowEditManuallyLinkedBankAccounts ? handleEditingBankAccount : undefined,
      }),
    [
      accountsData?.bankAccounts,
      accountsData?.brokerageAccounts,
      noAccountsMessage,
      content.accountsDropdown.availableAccountsHeader,
      fundingSource,
      allowEditManuallyLinkedBankAccounts,
      handleEditingBankAccount,
    ],
  );

  const contributionYearOptions = getContributionYearOptions();
  const currencySymbol = localeToCurrencySymbol[contentOptions.locale];
  const isDCAInvestment = isFundingSourceBankAccount && investmentMethod === InvestmentMethods.DCA;
  const isOneTimeFrequencyType = transferFrequencyType === TransferFrequencyType.ONE_TIME;
  const isRecurringFrequencyType = transferFrequencyType === TransferFrequencyType.RECURRING;

  const handleSuccessPlaidLink = (metaData: PlaidAccessTokenMetaData) => {
    setPlaidMetaData(metaData);
    openClientPlaidLinkSuccessModal();
  };
  const handleClosePlaidLinkSuccessModal = () => {
    resetLinkageSuccessful();
    setAccountsCount();
    accountsData?.refetchAccounts?.();
    onCloseClientPlaidLinkSuccessModal();
  };
  const { handleOpenPlaidLink } = usePlaidLinkHook(undefined, undefined, handleSuccessPlaidLink);

  const maxAllowedDate: Date | null = disableDateAfterXDays ? add(new Date(), { days: disableDateAfterXDays }) : null;
  // Trigger refetching list of bank accounts whenever we have a symphony submit error to remove unverified accounts.
  useEffect(() => {
    if (symphonyError) {
      setSelectedAccountId('');
      refetchParentContainerAccounts?.();
    }
  }, [noAccountsError, symphonyError, accountsData, refetchParentContainerAccounts]);

  useEffect(() => {
    if (transferFrequencyType === TransferFrequencyType.RECURRING) {
      clearErrors();
      setValue(fundingSourceName, FundingSources.bankAccount);
      setFundingSourceToFetch?.(FundingSources.bankAccount);
      if (selectedAccountId) {
        setSelectedAccountId('');
      }
    }
  }, [transferFrequencyType]);

  useEffect(() => {
    if (triggerValidation) {
      trigger().then(value => {
        if (value) {
          onValidationSuccessful();
        } else {
          onValidationUnsuccessful();
        }
      });
    }
  }, [onValidationSuccessful, onValidationUnsuccessful, trigger, triggerValidation]);

  useEffect(() => {
    if (!accountsData?.accountsLoading || !isRefetchingAccounts) {
      let accountId = '';
      let sourceAccount;
      switch (fundingSource) {
        case FundingSources.bankAccount:
          accountId = bankAccountId ?? accountsData?.bankAccounts?.[0]?.id ?? '';
          setSelectedAccountId(accountId);
          sourceAccount = accountsData?.bankAccounts?.find(account => account.id === accountId);
          break;
        case FundingSources.otherBrokerageAccount:
          accountId = accountsData?.brokerageAccounts?.[0]?.id ?? '';
          setSelectedAccountId(accountId);
          sourceAccount = accountsData?.brokerageAccounts?.find(account => account.id === accountId);
          break;
        default:
          setSelectedAccountId('');
      }
      setValue(additionAmountName, sourceAccount ? getAccountBalance(sourceAccount, BalanceType.TOTAL_ACCOUNT) : '0');
      const max = sourceAccount
        ? getAccountBalance(sourceAccount, BalanceType.TOTAL_ACCOUNT) ?? Number.MAX_VALUE
        : Number.MAX_VALUE;
      setMaximumFunds(max);
    }
  }, [
    bankAccountId,
    accountsData?.bankAccounts,
    accountsData?.brokerageAccounts,
    accountsData?.accountsLoading,
    isRefetchingAccounts,
    fundingSource,
    setValue,
  ]);

  useEffect(() => {
    if (selectedAccountId || (fundingSource === FundingSources.otherBrokerageAccount && isManualBrokerage)) {
      let sourceAccount;
      switch (fundingSource) {
        case FundingSources.bankAccount:
          sourceAccount = accountsData?.bankAccounts?.find(account => account.id === selectedAccountId);
          break;
        case FundingSources.otherBrokerageAccount:
          sourceAccount = accountsData?.brokerageAccounts?.find(account => account.id === selectedAccountId);
          break;
        default:
          sourceAccount = undefined;
      }
      const value = sourceAccount ? getAccountBalance(sourceAccount, BalanceType.TOTAL_ACCOUNT) : '';
      availableBalance.current = +(value ?? '0');
      setValue(additionAmountName, value || minAllowedAmount);
    }
  }, [
    bankAccountId,
    accountsData?.bankAccounts,
    accountsData?.brokerageAccounts,
    fundingSource,
    setValue,
    selectedAccountId,
    isManualBrokerage,
    minAllowedAmount,
  ]);

  useEffect(() => {
    if (selectedAccountId && selectedAccountId !== noAccountsMessage) {
      setDisableButton?.(!!plaidItemIdIfReAuthenticateFails);
    }
  }, [selectedAccountId]);

  useEffect(() => {
    if (formAdditionAmount) {
      trigger(additionAmountName);
    }
  }, [maximumFunds]);
  useEffect(() => {
    if (formAdditionAmount) {
      trigger(additionAmountName);
    }
  }, [contributionYear]);
  useEffect(() => {
    setValue(additionFrequencyName, frequencyValue, { shouldValidate: true, shouldDirty: true });
  }, [frequencyValue]);

  useEffect(() => {
    setValue(additionDateOptionName, dateOptionValue, { shouldValidate: true, shouldDirty: true });
  }, [dateOptionValue]);

  useEffect(() => {
    const soonestDate = addBusinessDays(new Date(), soonestPossibleDays);
    setAsSoonAsPossibleDate(soonestDate);
    setValue(additionDateName, soonestDate, { shouldValidate: true, shouldDirty: true });
  }, [soonestPossibleDays]);

  useEffect(() => {
    setTransferFrequencyType(defaultTransferFrequencyType ?? TransferFrequencyType.ONE_TIME);
  }, [defaultTransferFrequencyType]);

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(handleSuccessfulSubmit)();
    }
  }, [isSubmitted]);

  const handleCheckAccountVerification = (accountId: string) => {
    if (showWarningForNonVerifiedFinancialAccountLinkages) {
      setShowVerificationWarningMessage(
        getIsBankAccountAssociationNonVerified(accountId, accountsData?.bankAccounts ?? [], managedProductId),
      );
    }
  };

  const {
    isAccountLinkageSuccessful: isLinkageSuccessful,
    reset: resetLinkageSuccessful,
    set: setAccountsCount,
  } = useGetAddAccountSuccess(accountsData?.bankAccounts);

  const handleRefetchAccounts = () => {
    resetLinkageSuccessful();
    setAccountsCount();
    accountsData?.refetchAccounts?.();
  };

  const handleCloseFaPlaidFlow = () => {
    setOpenPlaidOtpVerification(false);
    resetLinkageSuccessful();
  };
  useEffect(() => {
    if (fundingSource === FundingSources.otherBrokerageAccount && isManualBrokerage) {
      setValue(eligibleSourceAccountName, '');
      setValue(holdingDataName, '');
      setSelectedAccountId('');
    }
  }, [isManualBrokerage, fundingSource, setValue]);

  useEffect(() => {
    if (fundingSource === FundingSources.otherBrokerageAccount && selectedAccountId) {
      const selectedAccount = accountsData?.brokerageAccounts?.find(brokerage => brokerage.id === selectedAccountId);
      if (!isManualBrokerage && selectedAccount && !selectedAccount.accountNumber) {
        setValue(brokerageAccountNumberFieldName, '');
        setShowConfirmAccountNumberField(true);
      } else {
        setShowConfirmAccountNumberField(false);
      }
    } else {
      setShowConfirmAccountNumberField(false);
    }
  }, [selectedAccountId, accountsData?.brokerageAccounts, fundingSource, setValue, isManualBrokerage]);

  // This useEffect hook checks the verification status of defaultAccountValue everytime it changes.
  useEffect(() => {
    if (isFundingSourceBankAccount && selectedAccountId && showWarningForNonVerifiedFinancialAccountLinkages) {
      setShowVerificationWarningMessage(
        getIsBankAccountAssociationNonVerified(selectedAccountId, accountsData?.bankAccounts ?? [], managedProductId),
      );
    }
  }, [
    selectedAccountId,
    isFundingSourceBankAccount,
    accountsData?.bankAccounts,
    showWarningForNonVerifiedFinancialAccountLinkages,
    managedProductId,
  ]);
  const dcaRecurringAmount = useMemo(() => {
    const investmentAmount = parseFloat(formAdditionAmount ?? '0');
    const investmentPeriod = parseFloat(dcaInvestmentPeriod ?? '1');
    const initialAmount = parseFloat(dcaInitialAmount ?? '0');
    if (
      fundingSource === FundingSources.bankAccount &&
      selectedAccountId &&
      investmentMethod === InvestmentMethods.DCA &&
      investmentAmount &&
      investmentPeriod &&
      initialAmount
    ) {
      return (investmentAmount - initialAmount) / investmentPeriod;
    }
    return 0;
  }, [fundingSource, selectedAccountId, dcaInitialAmount, formAdditionAmount, investmentMethod, dcaInvestmentPeriod]);

  useEffect(() => {
    if (selectedAccountId) {
      setValue(eligibleSourceAccountName, selectedAccountId, { shouldValidate: true, shouldDirty: true });
      // Reset symphony error when new account is selected
      onAccountSelected?.();
      setDisableButton?.(!!plaidItemIdIfReAuthenticateFails);
    } else {
      setValue(eligibleSourceAccountName, '');
    }
  }, [eligibleSourceAccountName, selectedAccountId]);

  const isOneTimeTransferForRetirementAccount: boolean =
    isOneTimeFrequencyType &&
    !!destinationAccount?.accountType &&
    isRetirementAccountType(destinationAccount.accountType);

  const isRecurringTransferForRetirementAccount: boolean =
    isRecurringFrequencyType &&
    !!destinationAccount?.accountType &&
    isRetirementAccountType(destinationAccount.accountType);

  const handleSuccessfulSubmit = async (formData: FormData) => {
    const alteredFormData = manipulateFormData(formData);
    if (isOneTimeTransferForRetirementAccount && !ifTodaysDateBetweenTaxStartAndEndDates()) {
      // Set contribution year to current year if todays date is outside tax dates coming
      // from contentstack and frequency is one time and destination account is of retirement
      // account type
      alteredFormData.contributionYear = new Date().getFullYear();
    }
    if (fundingSource === FundingSources.otherBrokerageAccount) {
      const brokerageAccount = accountsData?.brokerageAccounts?.find(account => account.id === selectedAccountId);
      const assetTransferInput: CreateAssetTransferInput = createAssetTransferInput({
        brokerageAccount,
        showManualBrokerageDetails: isManualBrokerage,
        accountType: accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
        formData: alteredFormData,
        destinationAccountId: destinationAccount?.accountId,
      });
      onSubmitCallback?.({ assetTransferInput });
    } else if (fundingSource === FundingSources.bankAccount) {
      const externalBankAccountInput = getExternalBankAccountInput(
        formData.eligibleSourceAccount,
        accountsData?.bankAccounts ?? [],
        {
          name: userName,
          partyId,
        },
      );
      const cashTransferInput: CreateCashTransferInput[] = createCashTransferInput({
        formData: alteredFormData,
        transferFrequencyType,
        destinationAccountId: destinationAccount?.accountId,
        externalBankAccountInput,
      });
      onSubmitCallback?.({
        bankAccountId: alteredFormData.eligibleSourceAccount,
        bankAccountInput: externalBankAccountInput?.bankAccount,
        createCashTransferInputVars: cashTransferInput,
      });
    }
  };

  const isSymphonyError = (error: GraphQLError | SymphonyError): error is SymphonyError => {
    return 'code' in error || 'correlationId' in error;
  };

  const onLinkBankAccountCallback = (account: FinancialAccount) => {
    handleRefetchAccounts();
    setSelectedAccountId(account.id ?? accountsData?.bankAccounts?.[0].id ?? '');
    onLinkAccount?.(account);
  };

  const handleFundingSourceChange = () => {
    setValue(additionAmountName, '');
    setSelectedAccountId('');
    clearErrors();
  };

  const handleFrequencyTypeChange = (event: DropdownChangeEvent) => {
    const value = (event.target.value || TransferFrequency.ONE_TIME) as TransferFrequency;
    setValue(additionFrequencyName, value, { shouldValidate: true, shouldDirty: true });
  };

  const handleAdditionAmountChange = ({ value: amount }: NumberFormatValues) => {
    setValue(additionAmountName, amount, { shouldValidate: true, shouldDirty: true });
  };

  const handleContributionYearChange = (event: DropdownChangeEvent) => {
    const value = event.target.value || contributionYearOptions[0].value;
    setValue(contributionYearName, value, { shouldValidate: true, shouldDirty: true });
  };

  const getDateFromString = (dateString: string): Date => {
    // Returns date object from MM/DD date string with current year
    const [month, day] = dateString.split('/');
    return new Date(new Date().getFullYear(), parseInt(month, 10) - 1, parseInt(day, 10));
  };

  const ifTodaysDateBetweenTaxStartAndEndDates = (): boolean => {
    const today: Date = new Date();
    const { startDateAndMonth, endDateAndMonth } = content.contributionYearDropdown;
    const startDate: Date = getDateFromString(startDateAndMonth);
    const endDate: Date = getDateFromString(endDateAndMonth);
    return today <= endDate && today >= startDate;
  };

  const plaidItemIdIfReAuthenticateFails = useMemo(() => {
    if (selectedAccountId && accountsData?.bankAccounts) {
      return getPlaidItemIdIfReAuthenticateFails(accountsData.bankAccounts, selectedAccountId);
    }
    return undefined;
  }, [accountsData?.bankAccounts, selectedAccountId]);

  const selectedFinancialInstitution = useMemo(
    () => accountsData?.bankAccounts?.find(account => account.id === selectedAccountId)?.financialInstitution,
    [accountsData?.bankAccounts, selectedAccountId],
  );

  const showPlaidReAuthentication = allowPlaidReAuthentication && plaidItemIdIfReAuthenticateFails;
  // Show dropdown if the following conditions are satisfied
  // 1. Frequency is one time
  // 2. Current date between tax startDate and endDate coming from contentstack
  // 3. Destination account type is a retirement account type
  const shouldDisplayContributionYearDropdown =
    isFundingSourceBankAccount && isOneTimeTransferForRetirementAccount && ifTodaysDateBetweenTaxStartAndEndDates();

  const iraRecurringContributionText = content.iraRecurringContributionText;

  const shouldDisableDate = (date: Date) =>
    shouldDisableMarketHolidays(date, marketHolidays, disableDateMarketHolidays) ||
    shouldDisableWeekends(date, disableDateWeekends) ||
    shouldDisableAfterXDays(date, disableDateAfterXDays) ||
    shouldDisableRestCurrentYearForRetirementAccounts(
      date,
      disableDateCurrentYearForRetirementAccounts,
      isRetirementAccountType(destinationAccount?.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE),
    );
  const dcaAlertMessage = isDCAInvestment
    ? getDCAAlertMessage({
        dcaFrequency,
        dcaInitialAmount,
        dcaInvestmentPeriod,
        dcaRecurringAmount,
        content: content.customLabels.dcaTransferMessage,
      })
    : undefined;

  const handleAccountDropdownChange = (
    eventValue: string | number | (string | number)[],
    onChange: (...value: any[]) => void,
  ) => {
    if (eventValue === 'link-account-action') {
      if (fundingSource === FundingSources.bankAccount) {
        openBankLinkageModal();
      } else if (fundingSource === FundingSources.otherBrokerageAccount) {
        openManualBrokerageLinkingModal();
      }
    }
    if (eventValue === 'link-plaid-account-action') {
      if (plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR) {
        setOpenPlaidOtpVerification(true);
      } else if (plaidLinkageType === PlaidLinkType.CLIENT) {
        openPlaidLinkModal();
      }
    } else {
      onChange(eventValue);
      const selectStringValue = eventValue.toString();
      if (selectStringValue !== noAccountsMessage) {
        setSelectedAccountId(selectStringValue);
      }
      onAccountSelected?.();
      if (showWarningForNonVerifiedFinancialAccountLinkages) {
        handleCheckAccountVerification(selectStringValue);
      }
    }
  };

  const setFormValues = (brokerageName: string, alias: string, accountNumber: string) => {
    setValue(brokerageNameFieldName, brokerageName);
    setValue(brokerageAccountAliasName, alias);
    setValue(brokerageAccountNumberFieldName, accountNumber);
    setValue(
      eligibleSourceAccountName,
      `${getMaskedAccountNumberWithFinancialInstitutionName(brokerageName, accountNumber)}`,
    );
  };

  const handleManualLinkageAccountConnection = (name: string, alias: string, accountNumber: string) => {
    setFormValues(name, alias, accountNumber);
    brokerageAccountNumber.current = accountNumber;
    brokerageAccountName.current = name;
    setSelectedAccountId('');
    setIsManualBrokerage(true);
  };
  const handleCloseBrokerageLinkingModal = (newSourceId?: number) => {
    if (newSourceId) {
      setValue(additionAmountName, minAllowedAmount);
      setSelectedAccountId(`${newSourceId}`);
      accountsData?.refetchAccounts?.();
    } else {
      setSelectedAccountId('');
    }
    closeManualBrokerageLinkingModal();
  };
  const availableBalanceInfo =
    !!availableBalance.current && content.amountField.availableBalance ? (
      <RteContent
        config={{ amount: formatCurrency(availableBalance.current) }}
        data={content.amountField.availableBalance}
      />
    ) : undefined;

  const bankAccountItems = [
    ...sourceBasedAccountsDropdownItems,
    ...getDropdownActions({
      content,
      fundingSource,
      isManualLinkageForBrokerageAccountSupported,
      isManualLinkageForBankAccountSupported,
      isPlaidLinkageForBankAccountSupported,
      isPlaidLinkageForBrokerageAccountSupported,
    }),
  ];

  return (
    <Box data-qa={dataQa}>
      <Box sx={{ display: currentStep === 'collectDetails' ? 'initial' : 'none' }}>
        <>
          <ToggleButtonGroup
            exclusive
            fullWidth
            onChange={(_e, value) => setTransferFrequencyType(value)}
            sx={{ pb: 2 }}
            value={transferFrequencyType}
          >
            <ToggleButton value={TransferFrequencyType.ONE_TIME}>{content.labels.oneTime}</ToggleButton>
            <ToggleButton value={TransferFrequencyType.RECURRING}>{content.labels.recurring}</ToggleButton>
          </ToggleButtonGroup>
          <FormControl sx={{ width: '100%', '& > *, & > .MuiFormControl-root': { mb: { xs: 2, md: 3 } } }}>
            {symphonyError && (
              <Alert contentOptions={contentOptions} error={symphonyError} severity="error">
                {isApolloError(symphonyError)
                  ? symphonyError.graphQLErrors.find(err => isSymphonyError(err) && err.errorCode === 5)
                    ? content.errorMessages.verificationErrorMessage
                    : content.errorMessages.submitErrorMessage
                  : content.errorMessages.submitErrorMessage}
              </Alert>
            )}
            {isOneTimeFrequencyType && (
              <Controller
                control={control}
                defaultValue={fundingSource}
                name={fundingSourceName}
                render={({ name, onChange, value }) => (
                  <Dropdown
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: styles.maxDropdownHeight,
                        },
                      },
                    }}
                    data-qa={`${dataQa}-dropdown`}
                    error={!!fieldsErrors[fundingSourceName]}
                    id={fundingSourceName}
                    items={content.fundingSourceField.sources}
                    label={content.fundingSourceField.label}
                    name={name}
                    onChange={e => {
                      handleFundingSourceChange();
                      onChange(e.target.value);
                      setFundingSourceToFetch?.(e.target.value as FundingSources);
                    }}
                    placeholder={content.fundingSourceField.placeholder}
                    value={value}
                    width="100%"
                  />
                )}
                rules={{ required: true }}
              />
            )}
            {fundingSource === FundingSources.otherBrokerageAccount && isManualBrokerage && (
              <>
                <Box sx={{ mb: 0 }}>
                  <TextField
                    data-qa={`${dataQa}-manual-brokerage-account-name`}
                    disabled
                    fullWidth
                    id={brokerageNameFieldName}
                    label={content.labels.manualBrokerageAccountName}
                    name={brokerageNameFieldName}
                    sx={{ mb: 2 }}
                    value={brokerageAccountName.current}
                  />

                  <TextField
                    data-qa={`${dataQa}-manual-brokerage-account-number`}
                    disabled
                    fullWidth
                    id={brokerageAccountNumberFieldName}
                    label={content.labels.manualBrokerageAccountNumber}
                    name={brokerageAccountNumberFieldName}
                    value={brokerageAccountNumber.current}
                  />
                </Box>
                <Button
                  color="primary"
                  data-qa={`${dataQa}-manual-brokerage-edit`}
                  onClick={() => openManualBrokerageLinkingModal()}
                  startIcon={<EditIcon fontSize="small" />}
                  sx={styles.ctas?.editBrokerage}
                  variant="text"
                >
                  <Typography variant="body2">{content.ctas.editBrokerage}</Typography>
                </Button>
              </>
            )}
            {!accountsData?.accountsLoading && !isRefetchingAccounts ? (
              <>
                {(fundingSource === FundingSources.bankAccount || isNonManualBrokerageEntry) && (
                  <>
                    <Controller
                      control={control}
                      defaultValue={sourceAccountId}
                      name={eligibleSourceAccountName}
                      render={({ value, name, onBlur, onChange }) =>
                        allowEditManuallyLinkedBankAccounts ? (
                          <DropdownWithEditableOptions
                            defaultValue={value}
                            disabled={isRefetchingAccounts}
                            error={!!fieldsErrors.eligibleSourceAccount}
                            id={eligibleSourceAccountName}
                            items={[
                              ...bankAccountItems,
                              {
                                label: content.accountsDropdown.close,
                                type: 'list-item-button',
                                variant: 'outlined',
                              },
                            ]}
                            label={content.labels.fromAccount}
                            onChange={e => handleAccountDropdownChange(e.value, onChange)}
                          />
                        ) : (
                          <Dropdown
                            disabled={isRefetchingAccounts}
                            error={!!fieldsErrors.eligibleSourceAccount}
                            id={eligibleSourceAccountName}
                            items={bankAccountItems}
                            label={content.labels.fromAccount}
                            name={name}
                            onBlur={onBlur}
                            onChange={e => handleAccountDropdownChange(e.target.value, onChange)}
                            placeholder={content.accountsDropdown.accountsListPlaceholder}
                            value={value}
                            width="100%"
                          />
                        )
                      }
                      rules={{
                        required: true,
                        validate: {
                          mustHaveEligibleAccount: value => value !== noAccountsMessage,
                        },
                      }}
                    />
                    {fieldsErrors.eligibleSourceAccount && noAccountsError && (
                      <Alert severity="error">
                        <Typography variant="body2">{noAccountsError}</Typography>
                      </Alert>
                    )}
                  </>
                )}
                {showPlaidReAuthentication && (
                  <Alert data-qa="re-authentication-failed-error" severity="error">
                    <RteContent
                      config={{
                        reAuthenticateLink: (
                          <AlertTitle>
                            <Link
                              data-qa={`${dataQa}-re-authenticat-error-link`}
                              onClick={openPlaidReAuthenticatioModal}
                              sx={{ color: 'error.main', textDecorationColor: palette.error.main }}
                              variant="subtitle2"
                            >
                              {content.accountsDropdown.plaidReAuthenticaionLink}
                            </Link>
                          </AlertTitle>
                        ),
                      }}
                      data={content.accountsDropdown.plaidReAuthenticaion}
                    />
                  </Alert>
                )}
                {showConfirmAccountNumberField && (
                  <Box>
                    <Controller
                      control={control}
                      name={brokerageAccountNumberFieldName}
                      render={({ name, onChange, onBlur, ref, value }) => (
                        <TextField
                          data-qa={`${dataQa}-brokerage-account-number-input`}
                          error={!!fieldsErrors[brokerageAccountNumberFieldName]}
                          fullWidth
                          id={name}
                          inputRef={ref}
                          label={content.labels.manualConfirmAccountNumberEntry}
                          name={name}
                          onBlur={onBlur}
                          onChange={onChange}
                          type="text"
                          value={value}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </Box>
                )}
                <Controller
                  control={control}
                  defaultValue={defaultDestinationAccountId}
                  name={destinationAccountName}
                  render={({ value, name, onBlur, onChange }) => (
                    <Dropdown
                      disabled={isRefetchingAccounts}
                      error={!!fieldsErrors.eligibleSourceAccount}
                      id={destinationAccountName}
                      items={destinationAccounts.map(a => ({ label: a.accountName, value: a.accountId }))}
                      label={content.labels.toAccount}
                      name={name}
                      onBlur={onBlur}
                      onChange={event => {
                        const { value: eventValue } = event.target;
                        onChange(eventValue);
                        setDestinationAccount(destinationAccounts.find(a => a.accountId === eventValue));
                      }}
                      value={value}
                      width="100%"
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
                <Box sx={{ '& .MuiInputLabel-root': { mt: 2, ml: -1.5 }, mt: -4 }}>
                  <Controller
                    control={control}
                    defaultValue={additionAmount ?? ''}
                    name={additionAmountName}
                    onChange={handleAdditionAmountChange}
                    render={({ ref, value, name, onBlur }) => (
                      <CurrencyTextField
                        FormHelperTextProps={{ component: 'div' } as FormHelperTextProps}
                        contentOptions={contentOptions}
                        currencySymbol={currencySymbol}
                        disabled={fundingSource === FundingSources.journaling || selectedBrokerageAccountHasPositions}
                        // Need to cast here: https://github.com/mui-org/material-ui/issues/20360
                        error={!!fieldsErrors.additionAmount}
                        fullWidth
                        helperText={
                          <Box>
                            {availableBalanceInfo}
                            {getAmountFieldHelperText(
                              fieldsErrors.additionAmount,
                              content.amountField.errors.minError,
                              <RteContent
                                config={{
                                  availableFunds: formatCurrency(maximumFunds, { locale: contentOptions.locale }),
                                }}
                                data={content.amountField.errors.maxError}
                              />,
                              content.amountField.errors.requiredError,
                              isMaxAllowedAmountLesserThanBalance,
                              <RteContent
                                config={{
                                  maxAllowedAmount: formatCurrency(maxAllowedAmount, { locale: contentOptions.locale }),
                                }}
                                data={content.amountField.errors.maxAllowedError}
                              />,
                              ignoreInsufficientFunds,
                              content.amountField.errors.accountTypeMaximumAmount,
                            )}
                          </Box>
                        }
                        id={additionAmountName}
                        inputRef={ref}
                        label={content.amountField.label}
                        name={name}
                        onBlur={onBlur}
                        onValueChange={handleAdditionAmountChange}
                        placeholder={content.amountField.placeholder}
                        value={parseFloat(value)}
                      />
                    )}
                    rules={{
                      min: minAllowedAmount,
                      max: ignoreInsufficientFunds
                        ? maxAllowedAmount
                        : isMaxAllowedAmountLesserThanBalance
                        ? maxAllowedAmount
                        : maximumFunds,
                      required: true,
                      validate: {
                        // TODO: Rename this function as it only checks for bank accounts
                        isFundingAmountValid: value =>
                          isFundingSourceBankAccount && destinationAccount?.accountType
                            ? isFundingAmountValid(
                                destinationAccount.accountType,
                                value,
                                amountValidationBasedOnAccountTypes,
                                calculateAge(userBirthDate ?? ''),
                                contributionYear,
                              )
                            : true,
                      },
                    }}
                  />
                  {selectedBrokerageAccountHasPositions && (
                    <Button
                      disabled={
                        !!fieldsErrors.investmentAmount &&
                        fieldsErrors.investmentAmount.type !== 'aboveInitialInvestmentAmount'
                      }
                      onClick={openHoldingAssetsDrawer}
                      size="large"
                      startIcon={
                        <ModeIcon sx={{ color: fieldsErrors.investmentAmount ? undefined : 'primary.main' }} />
                      }
                      sx={styles.ctas?.changeInvestment}
                    >
                      {content.ctas.changeInvestmentAmount}
                    </Button>
                  )}
                  {fundingSource === FundingSources.bankAccount && (
                    <Button
                      disabled={
                        !!fieldsErrors.investmentAmount &&
                        fieldsErrors.investmentAmount.type !== 'aboveInitialInvestmentAmount'
                      }
                      onClick={openChangeInvestmentMethod}
                      size="large"
                      startIcon={
                        <ModeIcon sx={{ color: fieldsErrors.investmentAmount ? undefined : 'primary.main' }} />
                      }
                      sx={styles.ctas?.changeInvestment}
                    >
                      {content.ctas.changeInvestmentMethod}
                    </Button>
                  )}
                </Box>
                {isRecurringFrequencyType && (
                  <Controller
                    control={control}
                    defaultValue={frequencyValue}
                    name={additionFrequencyName}
                    onChange={handleFrequencyTypeChange}
                    render={({ ref, value, name, onBlur }) => (
                      <Dropdown
                        id={additionFrequencyName}
                        inputRef={ref}
                        items={content.frequencyDropdown.options}
                        label={content.frequencyDropdown.label}
                        name={name}
                        onBlur={onBlur}
                        onChange={handleFrequencyTypeChange}
                        value={value}
                        width="100%"
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                )}
                {isRecurringFrequencyType && additionFrequency === TransferFrequency.MONTHLY && (
                  <Controller
                    control={control}
                    defaultValue={1}
                    name={additionDayOfMonthName}
                    render={({ value, name, onBlur, onChange }) => (
                      <Dropdown
                        id={additionDayOfMonthName}
                        items={getDaysOfMonthOptions()}
                        label={content.datePicker.monthlyDayPickerLabel}
                        name={name}
                        onBlur={onBlur}
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        width="100%"
                      />
                    )}
                  />
                )}
                {isRecurringFrequencyType && additionFrequency === TransferFrequency.WEEKLY && (
                  <Controller
                    control={control}
                    defaultValue={Weekday.Monday}
                    name={additionDayOfWeekName}
                    render={({ value, name, onBlur, onChange }) => (
                      <Dropdown
                        id={additionDayOfWeekName}
                        items={content.weekdayDropdown.options}
                        label={content.datePicker.weekdayPickerLabel}
                        name={name}
                        onBlur={onBlur}
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        width="100%"
                      />
                    )}
                  />
                )}
                {shouldDisplayContributionYearDropdown ? (
                  <Controller
                    control={control}
                    defaultValue={contributionYearOptions[0].value}
                    name={contributionYearName}
                    onChange={handleContributionYearChange}
                    render={({ ref, value, name, onBlur }) => (
                      <Dropdown
                        id={contributionYearName}
                        inputRef={ref}
                        items={content.contributionYearDropdown.options}
                        label={content.contributionYearDropdown.label}
                        name={name}
                        onBlur={onBlur}
                        onChange={handleContributionYearChange}
                        tooltipText={content.contributionYearDropdown.tooltip}
                        value={value}
                        width="100%"
                      />
                    )}
                  />
                ) : null}
                {isOneTimeFrequencyType && isFundingSourceBankAccount && (
                  <Controller
                    control={control}
                    defaultValue={false}
                    name="scheduleForFutureDate"
                    render={({ onChange }) => (
                      <CheckboxGroup
                        items={[
                          {
                            name: 'scheduleForFutureDate',
                            label: content.labels.scheduleTransferForFutureDate,
                          },
                        ]}
                        onChange={e => {
                          setScheduleForFutureDate(e.scheduleForFutureDate);
                          onChange(e);
                        }}
                      />
                    )}
                  />
                )}
                {scheduleForFutureDate && isFundingSourceBankAccount && (
                  <Box data-qa={`${dataQa}-date-picker`} sx={{ pb: 2 }}>
                    <Controller
                      control={control}
                      defaultValue={asSoonAsPossibleDate}
                      name={additionDateName}
                      render={({ onChange, value }) => (
                        <DatePicker
                          defaultValue={asSoonAsPossibleDate}
                          disablePast
                          fullWidth
                          label={content.datePicker.label}
                          onChange={onChange}
                          shouldDisableDate={date => shouldDisableDate(date as Date)}
                          value={value}
                        />
                      )}
                      rules={{
                        required: true,
                        validate: {
                          // We want maxAllowedDate to take priority in validation, so place it first in array
                          [DatepickerValidations.mustBeLessThanMaxAdditionDate]: value =>
                            !maxAllowedDate || value <= maxAllowedDate,
                          [DatepickerValidations.mustBeBusinessDay]: value => !isWeekend(value),
                          [DatepickerValidations.isFutureDateValidation]: value => !isPast(value) || isToday(value),
                          [DatepickerValidations.isDate]: value => isValidDate(value),
                          [DatepickerValidations.mustNotBeAMarketHoliday]: value =>
                            !marketHolidays.includes(toSymphonyDate(value)),
                        },
                      }}
                    />
                    {!!fieldsErrors.additionDate &&
                      datepickerErrorDisplay({
                        fieldError: fieldsErrors.additionDate.type,
                        addFundsFormContent: content.datePicker,
                      })}
                  </Box>
                )}
                {showVerificationWarningMessage && content.verificationWarningMessage && (
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    <RteContent data={content.verificationWarningMessage} />
                  </Alert>
                )}
                {hasMoneyInRestriction && (
                  <Alert data-qa="money-in-restriction-warning" severity="warning">
                    <AlertTitle>{content.labels.moneyInRestrictionHeading}</AlertTitle>
                    <Typography variant="body2">{content.labels.moneyInRestrictionContent}</Typography>
                  </Alert>
                )}
                {getTransferDateMessage({
                  fundingSource,
                  isOneTimeFrequencyType,
                  dcaAlert: dcaAlertMessage,
                  asSoonAsPossibleDate,
                  scheduledTransferDate,
                  content,
                })}
                {/* TODO: Add this in Core Figma or Move this component to partner repo - FRC: DA2-5873 */}
                {iraRecurringContributionText && isRecurringTransferForRetirementAccount && (
                  <RteContent data={iraRecurringContributionText} />
                )}
              </>
            ) : (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          </FormControl>
        </>
      </Box>
      {currentStep === 'playback' &&
        getPlaybackFields({
          additionDayOfMonth,
          additionDayOfWeek,
          additionFrequency,
          asSoonAsPossibleDate,
          accountOptions: sourceBasedAccountsDropdownItems
            .filter(items => items.type === 'list-item')
            .map(account => account as DropdownItem),
          contentOptions,
          content,
          contributionYear,
          confirmedAccountNumber:
            showConfirmAccountNumberField &&
            !accountsData?.brokerageAccounts?.find(acc => acc.id === selectedAccountId)?.maskedAccountNumber
              ? getValues(brokerageAccountNumberFieldName)
              : undefined,
          destinationAccount,
          formAdditionAmount,
          fundingSource,
          dcaAlert: dcaAlertMessage,
          isOneTimeFrequencyType,
          isRecurringFrequencyType,
          scheduledTransferDate,
          manuallyAddedBrokerageSourceAccount: isManualBrokerage
            ? `${getMaskedAccountNumberWithFinancialInstitutionName(
                brokerageAccountName.current ?? null,
                getMaskedBrokerageAccountNumber(brokerageAccountNumber.current ?? ''),
              )}`
            : undefined,
          sourceAccountId,
        })}
      {currentStep === 'confirmation' &&
        getConfirmationFields({ accountHasRecurringDeposit, content, isOneTimeFrequencyType })}
      {isPlaidLinkageForBankAccountSupported && (
        <>
          {plaidLinkageType === PlaidLinkType.FINANCIAL_ADVISOR && (
            <FaPlaidFlow
              contentOptions={contentOptions}
              isAccountLinkageSuccessful={!!isLinkageSuccessful}
              isPlaidFlowLaunched={openPlaidOtpVerification}
              onCloseModalCallback={handleCloseFaPlaidFlow}
              partyId={partyId}
              partyIdFA={partyIdFA}
              plaidProducts={isFundingSourceBankAccount ? PlaidProduct.AUTH : PlaidProduct.INVESTMENTS}
              refetchAccounts={handleRefetchAccounts}
            />
          )}
          {plaidLinkageType === PlaidLinkType.CLIENT && (
            <>
              <ClientInitiatePlaidModal
                connectAccount={() => {
                  handleOpenPlaidLink(partyId, [
                    isFundingSourceBankAccount ? PlaidProduct.AUTH : PlaidProduct.INVESTMENTS,
                  ]).then(_ => onClosePlaidLinkModal());
                }}
                contentOptions={contentOptions}
                onClose={onClosePlaidLinkModal}
                open={isOpenPlaidLinkModal}
              />
              <ClientLedPlaidSuccessModal
                contentOptions={contentOptions}
                handleConnectAccount={() =>
                  handleOpenPlaidLink(partyId, [
                    isFundingSourceBankAccount ? PlaidProduct.AUTH : PlaidProduct.INVESTMENTS,
                  ])
                }
                onClose={handleClosePlaidLinkSuccessModal}
                open={isOpenClientPlaidLinkSuccessModal}
                plaidMetaData={plaidMetaData}
              />
            </>
          )}
        </>
      )}
      <LinkBankAccountModal
        contentOptions={contentOptions}
        managedProductId={managedProductId}
        onClose={closeBankLinkageModal}
        onLinkAccount={account => onLinkBankAccountCallback(account)}
        open={isBankLinkageModalOpen}
        partyId={partyId}
        selectedAccount={selectedAccountForEdit}
      />
      <LinkBrokerageAccountModal
        allowManualEntry
        contentOptions={contentOptions}
        manualBrokerageAccountName={brokerageAccountName.current}
        manualBrokerageAccountNumber={brokerageAccountNumber.current}
        onClose={handleCloseBrokerageLinkingModal}
        onManualBrokerageEntry={handleManualLinkageAccountConnection}
        onManualEntryStartOver={() => {
          setValue(eligibleSourceAccountName, '');
          setIsManualBrokerage(false);
          closeManualBrokerageLinkingModal();
        }}
        open={isManualBrokerageLinkingModalOpen}
        partyId={partyId}
        useManualEntry={useManualBrokerageEntry}
      />
      {[FundingSources.otherBrokerageAccount].includes(fundingSource) && !!selectedAccountId && (
        <HoldingTableDrawer
          accounts={{
            brokerageAccounts: accountsData?.brokerageAccounts ?? [],
            externalBrokerageAccounts: [],
          }}
          brokerageSourceId={selectedAccountId}
          content={content.holdingTableDrawer}
          defaultValues={{}}
          investmentAmountFieldName={additionAmountName}
          isJournaling={false}
          onClose={closeHoldingAssetsDrawer}
          open={isHoldingAssetsDrawerOpen}
          sx={{
            zIndex: 1500,
          }}
        />
      )}
      {fundingSource === FundingSources.bankAccount && selectedAccountId && (
        <ChangeInvestmentMethodModal
          availableBalance={availableBalanceInfo}
          contentOptions={contentOptions}
          fieldNames={{
            frequencyFieldName: dcaFrequencyName,
            initialAmountFieldName: dcaInitialAmountName,
            investmentMethodFieldName: investmentMethodName,
            noOfOccurrencesFieldName: dcaInvestmentPeriodName,
            totalAmountFieldName: additionAmountName,
          }}
          onClose={closeChangeInvestmentMethod}
          open={isChangeInvestmentMethodOpen}
        />
      )}
      {showPlaidReAuthentication && selectedAccountId && partyIdFA && selectedFinancialInstitution && (
        <PlaidReAuthentication
          contentOptions={contentOptions}
          financialInstitution={selectedFinancialInstitution}
          onAuthenticationSuccess={handleRefetchAccounts}
          onClose={closePlaidReAuthenticationModal}
          open={isPlaidReAuthenticationModalOpen}
          partyId={partyId}
          partyIdFA={partyIdFA}
          plaidItemId={plaidItemIdIfReAuthenticateFails}
          plaidLinkageType={plaidLinkageType}
        />
      )}
    </Box>
  );
};
