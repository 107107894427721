import React, { useContext } from 'react';

import OpsDashboardContext from '../../../OpsDashboardContext';
import { useClientNameContent } from '../hooks';

import { CopyField } from '~/components/ui/CopyField';
import { Link } from '~/components/ui/Link';
import { Box } from '~/components/ui/mui';

export interface Props {
  clientName?: string | null;
  onClick: () => void;
  redirectUrl?: string;
}

export const ClientName: React.FC<Props> = ({ clientName, onClick, redirectUrl }) => {
  const { contentOptions } = useContext(OpsDashboardContext);
  const { data } = useClientNameContent({ contentOptions });

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Box whiteSpace="pre-wrap">
      <Link
        color="inherit"
        href={redirectUrl}
        onClick={handleOnClick}
        sx={{ color: 'text.primary', pt: 1 }}
        underline="always"
      >
        <CopyField
          copyLabel={data?.copyLabel ?? ''}
          successLabel={data?.successfullyCopied}
          textToCopy={clientName ?? ''}
        >
          {clientName}
        </CopyField>
      </Link>
    </Box>
  );
};
