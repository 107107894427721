import React, { ComponentProps, useState } from 'react';

import { CommentComponentColumn } from '../common/Comment';
import { OpsAlertAndLoading } from '../common/OpsAlertAndLoading';
import { SearchBar } from '../common/SearchBar';
import { OpsDashboardUser } from '../utils';

import { useGetErrorsTable } from './hooks/useGetErrorsTable';
import { useUpdateFailuresV2 } from './symphony';
import { defaultAppliedFilters, ErrorsFailureContent } from './types';

import { EntityType } from '~/__generated__';
import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { AccountErrorsModal } from '~/components/modals/AccountErrors';
import { NullStateProps } from '~/components/NullState';
import { BasicTable } from '~/components/ui/BasicTable';
import { ItemsPerPage } from '~/components/ui/BasicTable/ItemsPerPage';
import { TotalItems } from '~/components/ui/BasicTable/TotalItems';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Snackbar, SnackbarPositionType } from '~/components/ui/Snackbar';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { ContentOptions } from '~/utils/contentstack/src/types';

export interface Props {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  dataQa?: string;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onUpdate: () => void;
}

export const ErrorsTable: React.FC<Props> = ({
  contentOptions,
  dataQa = 'errors-table',
  nullStateConfig,
  onUpdate,
}) => {
  const [modalRowData, setModalRowData] = useState<ErrorsFailureContent | null>();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [errorEntity, setErrorEntity] = useState<string | null>();
  const [errorId, setErrorId] = useState<string | null>();
  const [updateFailures, { loading: updateFailuresLoading }] = useUpdateFailuresV2();

  const handleResolveClick = async (entity: EntityType, failureId: string) => {
    setErrorEntity(entity);
    setErrorId(failureId);
    await toggleErrorResolve(entity, failureId, true);
    setShowSnackbar(true);
  };
  const {
    open: isOpenAccountErrorsModal,
    openModal: openAccountErrorsModal,
    onClose: closeAccountErrorsModal,
  } = useModalState();

  const handleOpenModal = (row: ErrorsFailureContent) => {
    setModalRowData(row);
    openAccountErrorsModal();
  };

  const toggleErrorResolve = async (entity: EntityType, failureId: string, isResolved: boolean) => {
    try {
      await updateFailures({
        variables: {
          entity,
          failureId,
          isResolved,
        },
      });
      onSuccessCallback();
    } catch (err) {
      console.error(err);
    }
  };

  const handleUndo = async () => {
    await toggleErrorResolve(errorEntity as EntityType, `${errorId}`, false);
    setShowSnackbar(false);
  };

  const { loading, data, error } = useGetErrorsTable({
    errorId,
    handleOpenModal,
    handleResolveClick,
    updateFailuresLoading,
  });
  const onSuccessCallback = () => {
    onUpdate();
    data?.refetchErrorsData();
  };

  return (
    <Stack data-qa={`${dataQa}-container`}>
      {(error || loading) && (
        <OpsAlertAndLoading
          ariaLabel="Loading Errors data"
          contentOptions={contentOptions}
          error={error}
          loading={loading}
        />
      )}

      {(data?.errorTabContent || data?.searchError) && !loading && (
        <>
          <Stack alignItems="center" data-qa="filter-container" flexDirection="row" justifyContent="space-between">
            <SearchBar
              onSearchChange={value => data.onSearchChange(value)}
              searchContext={data.searchContext}
              searchError={data.searchError}
              value={data.currentSearchFilter}
            />
            <Filters
              appliedFilters={data.appliedFilters}
              content={data.filtersContent}
              contentOptions={contentOptions}
              defaultAppliedFilters={defaultAppliedFilters}
              filterCountMap={data.filterCountMap}
              filters={data.errorFilterConfig}
              onSubmit={data.onFilterChange}
              openFiltersPopup={data.filtersPopupOpen}
              updateOpenFiltersPopup={data.updateOpenFiltersPopup}
            />
          </Stack>
          <AppliedFilters
            appliedFilters={data.appliedFilters}
            content={data.appliedFiltersContent}
            filters={data.errorFilterConfig}
            onAppliedFilterSelect={data.onAppliedFiltersSelect}
            searchResults={
              data.currentSearchFilter &&
              !data.searchError && (
                <RteContent
                  config={{
                    searchCount: data.paginationContext.totalItems,
                    searchTerm: data.currentSearchFilter,
                  }}
                  data={data.searchContext.totalSearchItemsLabel}
                />
              )
            }
          />
          <Stack data-qa="error-table-container">
            <BasicTable
              BottomLeftSection={<TotalItems paginationContext={data.paginationContext} />}
              BottomRightSection={<ItemsPerPage paginationContext={data.paginationContext} />}
              alignItems="left"
              columns={data.tableColumns}
              currentPage={data.currentPage}
              data={data.errorTabContent}
              enableRowHover
              nullStateConfig={nullStateConfig}
              onPageChange={data.onPageChange}
              onRowHover={data.onRowHover}
              showPagination
              sortConfig={data.sortConfig}
              totalPages={data.totalPages}
            />
            <AccountErrorsModal
              contentOptions={contentOptions}
              errorsRowData={modalRowData}
              onClose={closeAccountErrorsModal}
              open={isOpenAccountErrorsModal}
              title={data.modalTitle}
            />
          </Stack>
        </>
      )}
      {showSnackbar && !loading && !updateFailuresLoading && (
        <Snackbar
          autoHideDuration={10000}
          feedbackMessage={data?.feedbackMessage ?? ''}
          handleUndo={handleUndo}
          onClose={() => setShowSnackbar(false)}
          snackbarPosition={SnackbarPositionType.BOTTOM_LEFT}
          undoButtonLabel="Undo"
        />
      )}
    </Stack>
  );
};
