import { isDate } from 'date-fns';
import React, { ComponentProps, FC, useEffect, useState } from 'react';

import {
  AdapterDateFns,
  Box,
  InputLabelProps,
  LocalizationProvider,
  MuiDatePicker,
  PickersDay,
  useTheme,
} from '~/components/ui/mui';
import { TextField, TextFieldVariant } from '~/components/ui/TextField';

export interface Props extends Partial<ComponentProps<typeof MuiDatePicker>> {
  ariaLabel?: string;
  dataQa?: string;
  defaultValue?: Date;
  disableTextInput?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  inputLabelProps?: Partial<InputLabelProps>;
  isControlled?: boolean;
  onChange?: (date: any, keyboardInputValue?: string | undefined) => void;
  textFieldVariant?: TextFieldVariant;
}

export const DatePicker: FC<Props> = ({
  ariaLabel = 'Enter Date in mm/dd/yy format',
  dataQa = 'mui-date-picker',
  defaultValue,
  disableTextInput = false,
  error,
  fullWidth = false,
  helperText,
  inputLabelProps,
  isControlled = false,
  onChange,
  textFieldVariant = 'outlinedClassic',
  shouldDisableDate,
  value: dateValue,
  ...rest
}) => {
  const { sfDatePicker } = useTheme();
  const [value, setValue] = useState(defaultValue ?? null);

  useEffect(() => {
    if (isControlled && dateValue !== value) {
      setValue(dateValue && isDate(dateValue) ? (dateValue as Date) : null);
    }
  }, [dateValue, isControlled, value]);

  return (
    <Box data-qa={dataQa} sx={{ ...sfDatePicker?.root }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiDatePicker
          onChange={newValue => {
            setValue(newValue as Date);
            if (onChange) {
              onChange(newValue);
            }
          }}
          renderDay={(_date, _selectedDates, pickersDayProps) => (
            <PickersDay {...pickersDayProps} sx={sfDatePicker?.styles?.pickersDay} />
          )}
          renderInput={params => (
            <TextField
              aria-label={ariaLabel}
              {...params}
              InputLabelProps={inputLabelProps}
              error={error}
              fullWidth={fullWidth}
              helperText={helperText}
              onKeyDown={e => (disableTextInput ? e.preventDefault() : e)}
              variant={textFieldVariant}
            />
          )}
          shouldDisableDate={shouldDisableDate}
          value={value}
          {...rest}
        />
      </LocalizationProvider>
    </Box>
  );
};
