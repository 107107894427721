import { ContinueQuestionnaire, ContinueQuestionnaireVariables } from './__generated__/mutation.v2';
import {
  RecommendProduct as RecommendedProductData,
  RecommendProduct_recommendProduct,
  RecommendProductVariables,
} from './__generated__/mutation.v2';
import {
  StartNewQuestionnaire,
  StartNewQuestionnaire_startNewQuestionnaire,
  StartNewQuestionnaire_startNewQuestionnaire_blocks,
  StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions,
  StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets,
  StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_ContainedInConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_EqualConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_GreaterThanConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_GreaterThanOrEqualToConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_LessThanConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_LessThanOrEqualToConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_NotContainedInConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData,
  StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedBoolean,
  StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedCurrency,
  StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedInteger,
  StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedMultipleOption,
  StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_ContainedInConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_EqualConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_GreaterThanConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_GreaterThanOrEqualToConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_LessThanConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_LessThanOrEqualToConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_NotContainedInConditionSet,
  StartNewQuestionnaire_startNewQuestionnaire_steps,
  StartNewQuestionnaire_startNewQuestionnaire_steps_content,
  StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion,
  StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints,
  StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_BooleanDataPoint,
  StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_CurrencyDataPoint,
  StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_IntegerDataPoint,
  StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_MultipleOptionDataPoint,
  StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_SingleOptionDataPoint,
  StartNewQuestionnaireVariables,
} from './__generated__/mutation.v2';
import {
  GetLatestClientLevelQuestionnaire,
  GetLatestClientLevelQuestionnaire_client_kyc_questionnaire,
  GetLatestClientLevelQuestionnaire_client_kyc_questionnaire_collectedDataGroup_collectedData,
  GetLatestClientLevelQuestionnaireVariables,
} from './__generated__/query.v2';
import {
  GetLatestCompleteQuestionnaire,
  GetLatestCompleteQuestionnaire_managedProduct_calculatedRecommendations,
  GetLatestCompleteQuestionnaire_managedProduct_questionnaires,
  GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData,
  GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedBoolean,
  GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedCurrency,
  GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedInteger,
  GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedMultipleOption,
  GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption,
  GetLatestCompleteQuestionnaireVariables,
} from './__generated__/query.v2';
import { GetModelPortfolios, GetModelPortfoliosVariables } from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { DataPointType } from '~/__generated__/symphonyTypes.v2';
import { SaveRiskScores, SaveRiskScoresVariables } from '~/hooks/questionnaire/symphony/__generated__/mutation.v2';
import { GetModelPortfolios_managedProduct_modelPortfolios } from '~/hooks/questionnaire/symphony/__generated__/query.v2';
import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

/**
 * @deprecated This type is deprecated, please use RecommendProductRecommendationSet or LatestQuestionnaireRecommendationSet
 */
export type RecommendedProduct = RecommendProduct_recommendProduct;
export type RecommendProductRecommendationSet = RecommendProduct_recommendProduct;
export type LatestQuestionnaireRecommendationSet = GetLatestCompleteQuestionnaire_managedProduct_calculatedRecommendations;

export type QuestionnaireData = StartNewQuestionnaire_startNewQuestionnaire;
export type QuestionnaireBlock = StartNewQuestionnaire_startNewQuestionnaire_blocks;
export type LatestQuestionnaireData = GetLatestCompleteQuestionnaire_managedProduct_questionnaires;
export type LatestClientLevelQuestionnaireData = GetLatestClientLevelQuestionnaire_client_kyc_questionnaire;
export type QuestionnaireCollectedData =
  | StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData
  | GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData
  | GetLatestClientLevelQuestionnaire_client_kyc_questionnaire_collectedDataGroup_collectedData;
export type QuestionnaireCollectedDataInteger =
  | StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedInteger
  | GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedInteger;
export type QuestionnaireCollectedDataBoolean =
  | StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedBoolean
  | GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedBoolean;
export type QuestionnaireCollectedDataSingleOption =
  | StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption
  | GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption;
export type QuestionnaireCollectedDataMultipleOption =
  | StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedMultipleOption
  | GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedMultipleOption;
export type QuestionnaireCollectedDataCurrency =
  | StartNewQuestionnaire_startNewQuestionnaire_collectedDataGroup_collectedData_QuestionnaireCollectedCurrency
  | GetLatestCompleteQuestionnaire_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedCurrency;
export type QuestionnaireConditions =
  | StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions
  | StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions;
export type QuestionnaireStep = StartNewQuestionnaire_startNewQuestionnaire_steps;
export type QuestionnaireStepContent = StartNewQuestionnaire_startNewQuestionnaire_steps_content;
export type QuestionnaireQuestion = StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion;
export type QuestionnaireDataPoint = StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints;
export type QuestionnaireDataPointBoolean = StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_BooleanDataPoint;
export type QuestionnaireDataPointCurrency = StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_CurrencyDataPoint;
export type QuestionnaireDataPointInteger = StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_IntegerDataPoint;
export type QuestionnaireDataPointSingleOption = StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_SingleOptionDataPoint;
export type QuestionnaireDataPointMultipleOption = StartNewQuestionnaire_startNewQuestionnaire_steps_content_QuestionnaireQuestion_dataPoints_MultipleOptionDataPoint;

export type QuestionnaireRules = StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules;
export type ConditionSet =
  | StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets
  | StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets;
export type ConditionSetContainedIn =
  | StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_ContainedInConditionSet
  | StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_ContainedInConditionSet;
export type ConditionSetEqual =
  | StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_EqualConditionSet
  | StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_EqualConditionSet;
export type ConditionSetGreaterThanOrEqualTo =
  | StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_GreaterThanOrEqualToConditionSet
  | StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_GreaterThanOrEqualToConditionSet;
export type ConditionSetGreaterThan =
  | StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_GreaterThanConditionSet
  | StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_GreaterThanConditionSet;
export type ConditionSetLessThanOrEqualTo =
  | StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_LessThanOrEqualToConditionSet
  | StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_LessThanOrEqualToConditionSet;
export type ConditionSetLessThan =
  | StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_LessThanConditionSet
  | StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_LessThanConditionSet;
export type ConditionSetNotContainedIn =
  | StartNewQuestionnaire_startNewQuestionnaire_blocks_QuestionnaireBlock_conditions_QuestionnaireCondition_sets_NotContainedInConditionSet
  | StartNewQuestionnaire_startNewQuestionnaire_order_orderSteps_rules_conditions_sets_NotContainedInConditionSet;
export type ModelPortfolio = GetModelPortfolios_managedProduct_modelPortfolios;
export type LatestCompleteQuestionnaireVariables = GetLatestCompleteQuestionnaireVariables;
export const getDataPointType = (dataPoint: QuestionnaireDataPoint): DataPointType => {
  if (isDataPointTypeBoolean(dataPoint)) {
    return DataPointType.BOOLEAN;
  } else if (isDataPointTypeCurrency(dataPoint)) {
    return DataPointType.CURRENCY;
  } else if (isDataPointTypeInteger(dataPoint)) {
    return DataPointType.INTEGER;
  } else if (isDataPointTypeSingleOption(dataPoint)) {
    return DataPointType.SINGLE_OPTION;
  } else if (isDataPointTypeMultipleOption(dataPoint)) {
    return DataPointType.MULTIPLE_OPTION;
  }
  throw new Error('Invalid dataPointType: ' + dataPoint);
};

export const isDataPointTypeBoolean = (dataPoint: QuestionnaireDataPoint): dataPoint is QuestionnaireDataPointBoolean =>
  dataPoint.__typename === 'BooleanDataPoint';

export const isDataPointTypeCurrency = (
  dataPoint: QuestionnaireDataPoint,
): dataPoint is QuestionnaireDataPointCurrency => dataPoint.__typename === 'CurrencyDataPoint';

export const isDataPointTypeInteger = (dataPoint: QuestionnaireDataPoint): dataPoint is QuestionnaireDataPointInteger =>
  dataPoint.__typename === 'IntegerDataPoint';

export const isDataPointTypeMultipleOption = (
  dataPoint: QuestionnaireDataPoint,
): dataPoint is QuestionnaireDataPointMultipleOption => dataPoint.__typename === 'MultipleOptionDataPoint';

export const isDataPointTypeSingleOption = (
  dataPoint: QuestionnaireDataPoint,
): dataPoint is QuestionnaireDataPointSingleOption => dataPoint.__typename === 'SingleOptionDataPoint';

export const isQuestionnaireQuestion = (stepContent: QuestionnaireStepContent): stepContent is QuestionnaireQuestion =>
  stepContent.__typename === 'QuestionnaireQuestion';

export const isContainedInSet = (set: ConditionSet): set is ConditionSetContainedIn =>
  set.__typename === 'ContainedInConditionSet';

export const isEqualSet = (set: ConditionSet): set is ConditionSetEqual => set.__typename === 'EqualConditionSet';

export const isGreaterThanOrEqualToSet = (set: ConditionSet): set is ConditionSetGreaterThanOrEqualTo =>
  set.__typename === 'GreaterThanOrEqualToConditionSet';

export const isGreaterThanSet = (set: ConditionSet): set is ConditionSetGreaterThan =>
  set.__typename === 'GreaterThanConditionSet';

export const isLessThanOrEqualToSet = (set: ConditionSet): set is ConditionSetLessThanOrEqualTo =>
  set.__typename === 'LessThanOrEqualToConditionSet';

export const isLessThanSet = (set: ConditionSet): set is ConditionSetLessThan =>
  set.__typename === 'LessThanConditionSet';

export const isNotContainedInSet = (set: ConditionSet): set is ConditionSetNotContainedIn =>
  set.__typename === 'NotContainedInConditionSet';

export const isCollectedDataBoolean = (
  collectedData: QuestionnaireCollectedData,
): collectedData is QuestionnaireCollectedDataBoolean => collectedData.__typename === 'QuestionnaireCollectedBoolean';

export const isCollectedDataCurrency = (
  collectedData: QuestionnaireCollectedData,
): collectedData is QuestionnaireCollectedDataCurrency => collectedData.__typename === 'QuestionnaireCollectedCurrency';

export const isCollectedDataInteger = (
  collectedData: QuestionnaireCollectedData,
): collectedData is QuestionnaireCollectedDataInteger => collectedData.__typename === 'QuestionnaireCollectedInteger';

export const isCollectedDataSingleOption = (
  collectedData: QuestionnaireCollectedData,
): collectedData is QuestionnaireCollectedDataSingleOption =>
  collectedData.__typename === 'QuestionnaireCollectedSingleOption';

export const isCollectedDataMultipleOption = (
  collectedData: QuestionnaireCollectedData,
): collectedData is QuestionnaireCollectedDataMultipleOption =>
  collectedData.__typename === 'QuestionnaireCollectedMultipleOption';

export const useStartNewQuestionnaire = (
  options?: MutationHookOptions<StartNewQuestionnaire, StartNewQuestionnaireVariables>,
): MutationTuple<StartNewQuestionnaire, StartNewQuestionnaireVariables> => {
  return useSymphonyMutation(mutations.StartNewQuestionnaire, options);
};

export const useContinueQuestionnaire = (
  options?: MutationHookOptions<ContinueQuestionnaire, ContinueQuestionnaireVariables>,
): MutationTuple<ContinueQuestionnaire, ContinueQuestionnaireVariables> => {
  return useSymphonyMutation(mutations.ContinueQuestionnaire, options);
};

export const useRecommendProduct = (
  options?: MutationHookOptions<RecommendedProductData, RecommendProductVariables>,
): MutationTuple<RecommendedProductData, RecommendProductVariables> => {
  return useSymphonyMutation(mutations.RecommendProduct, options);
};

export const useGetLatestCompletedQuestionnaire = (
  options?: QueryHookOptions<GetLatestCompleteQuestionnaire, GetLatestCompleteQuestionnaireVariables>,
): QueryResult<GetLatestCompleteQuestionnaire, GetLatestCompleteQuestionnaireVariables> => {
  return useSymphonyQuery(queries.GetLatestCompleteQuestionnaire, options);
};

export const useSaveRiskScores = (
  options?: MutationHookOptions<SaveRiskScores, SaveRiskScoresVariables>,
): MutationTuple<SaveRiskScores, SaveRiskScoresVariables> => {
  return useSymphonyMutation(mutations.SaveRiskScores, options);
};

export const useGetModelPortfolios = (
  options?: QueryHookOptions<GetModelPortfolios, GetModelPortfoliosVariables>,
): QueryResult<GetModelPortfolios, GetModelPortfoliosVariables> =>
  useSymphonyQuery(queries.GetModelPortfolios, options);

export const useGetLatestClientLevelQuestionnaire = (
  options?: QueryHookOptions<GetLatestClientLevelQuestionnaire, GetLatestClientLevelQuestionnaireVariables>,
): QueryResult<GetLatestClientLevelQuestionnaire, GetLatestClientLevelQuestionnaireVariables> => {
  return useSymphonyQuery(queries.GetLatestClientLevelQuestionnaire, options);
};
