import { useMemo } from 'react';

import { DocusignColumnKeys, DocusignDisplayStrings } from '../types';

import { TableColumn } from '~/components/ui/BasicTable';

const columnsKey: DocusignColumnKeys[] = [
  'accountNumber',
  'clientName',
  'emailRecipients',
  'dateGenerated',
  'status',
  'comment',
];
export const useColumnHeaders = (
  displayStrings?: DocusignDisplayStrings,
  onSort?: (field: string) => () => void,
): TableColumn[] => {
  return useMemo(() => {
    if (!displayStrings) {
      return [];
    }
    return columnsKey.map(key => ({
      key,
      title: displayStrings.tableHeaders[key],
      wrappable: displayStrings.wrappableColumns.includes(key),
      onSort: onSort && key === 'dateGenerated' ? onSort : undefined,
    }));
  }, [displayStrings, onSort]);
};
