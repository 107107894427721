import React from 'react';

import { FilterData, TransactionTypeFilter } from '../types';

import { DateFilters } from '~/components/ControlFields/DateFilters';
import { Dropdown } from '~/components/ui/Dropdown';
import { Grid } from '~/components/ui/mui';

interface Props {
  data: FilterData;
  dataQa?: string;
}

export const FilterSection: React.FC<Props> = ({ data, dataQa = 'filter-section' }) => {
  return (
    <Grid columns={12} container data-qa={dataQa} spacing={4}>
      <DateFilters
        dateFilterConfig={data.config}
        onCustomDateRangeChange={data.onCustomDateRangeChange}
        onDateRangeOptionChange={data.onDateRangeOptionChange}
        onYearOptionChange={data.onYearOptionChange}
      />
      <Grid item minWidth="232px" xs={3}>
        <Dropdown
          dataQa={`${dataQa}-transaction-type-filter`}
          items={data.config.transactionTypeOption?.items ?? []}
          label={data.config.transactionTypeOption?.label}
          onChange={e => data.onTransactionTypeOptionChange?.(e.target.value as TransactionTypeFilter)}
          value={data.values.transactionType}
          width="100%"
        />
      </Grid>
    </Grid>
  );
};
