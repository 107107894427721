import React, { ComponentProps, FC } from 'react';

import { useAccountTotalSection } from './useAccountTotalSection';

import { AccountSectionCard } from '~/components/AccountSummary/AccountSectionCard';
import { AccountTotal } from '~/components/AccountSummary/AccountTotal';
import { PerformanceChart } from '~/components/PerformanceChart';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { Button, Skeleton, Stack, VisibilityOutlinedIcon } from '~/components/ui/mui';
import { GoalSummary } from '~/containers/Goals';
import { useBalanceOverTime } from '~/hooks/performance/useBalanceOverTime';

const PERFORMANCE_CHART_HEIGHT = 250 as const;

type Props = Omit<Parameters<typeof useBalanceOverTime>[0], 'skip'> & {
  accountsEffectiveDate?: string;
  content?: {
    accountTotal?: ComponentProps<typeof AccountTotal>['content'];
    headingCta?: { switchToAccountBalance: string; switchToGoalsView: string };
    performanceChart?: ComponentProps<typeof PerformanceChart>['content'];
  };
  dataQa?: string;
  goalSummaryProps?: Pick<
    ComponentProps<typeof GoalSummary>,
    'financialAccounts' | 'partnerConfigId' | 'onAccountClick' | 'onCreateGoal' | 'onEditGoal'
  >;
  hidePerformanceView?: boolean;
  partyId: string;
  rebalancedAccountsLoading: boolean;
};

export const AccountTotalSection: FC<Props> = ({
  accountsEffectiveDate,
  content,
  contentOptions,
  dataQa = 'account-total-section',
  hidePerformanceView,
  performanceChartTo,
  rebalancedAccounts,
  rebalancedAccountsLoading,
  partyId,
  goalSummaryProps,
}) => {
  const section = useAccountTotalSection({ hidePerformanceView, rebalancedAccounts });
  const { data: balanceOverTime, loading, error } = useBalanceOverTime({
    contentOptions,
    performanceChartTo,
    rebalancedAccounts,
    skip: !section || section.skipBalanceOverTimeQuery,
  });

  if (!section) {
    return null;
  }

  return (
    <AccountSectionCard dataQa={dataQa}>
      {section.view === 'performance' && (
        <Stack data-qa={dataQa} divider={<Divider data-qa={`${dataQa}-divider`} />} spacing={2}>
          {content?.accountTotal && balanceOverTime && (
            <AccountTotal
              balance={balanceOverTime.totalBalance}
              content={content.accountTotal}
              contentOptions={contentOptions}
              effectiveDate={accountsEffectiveDate}
              headingCta={
                <HeadingCta
                  data-qa={`${dataQa}-goals-view`}
                  loading={rebalancedAccountsLoading}
                  onClick={section.onSwitchToGoalsView}
                >
                  {content.headingCta?.switchToGoalsView}
                </HeadingCta>
              }
            />
          )}
          {!loading &&
            !error &&
            !hidePerformanceView &&
            !!balanceOverTime?.performance &&
            content?.performanceChart && (
              <PerformanceChart
                content={content.performanceChart}
                contentOptions={contentOptions}
                data={balanceOverTime.performance}
                displayDialog={balanceOverTime.performance.length <= 2}
                height={PERFORMANCE_CHART_HEIGHT}
              />
            )}
          {error && <Alert contentOptions={contentOptions} error={error} severity="error" />}
          {loading && <Skeleton data-qa={`${dataQa}-performance-chart-loading`} height={250} variant="rectangular" />}
        </Stack>
      )}

      {section.view === 'goals' && !!goalSummaryProps && (
        <GoalSummary
          {...goalSummaryProps}
          contentOptions={contentOptions}
          headingCta={
            <HeadingCta
              data-qa={`${dataQa}-cta-switch-to-account-balance`}
              loading={rebalancedAccountsLoading}
              onClick={section.onSwitchToPerformanceView}
            >
              {content?.headingCta?.switchToAccountBalance}
            </HeadingCta>
          }
          partyId={partyId}
        />
      )}
    </AccountSectionCard>
  );
};

const HeadingCta: FC<Pick<ComponentProps<typeof Button>, 'onClick'> & { loading: boolean }> = ({
  children,
  loading,
  onClick,
}) => {
  if (loading) {
    return <Skeleton width={100} />;
  }
  if (onClick && children) {
    return (
      <Button
        onClick={onClick}
        startIcon={<VisibilityOutlinedIcon />}
        sx={{ justifyContent: { xs: 'left', md: ' center' }, p: 0 }}
      >
        {children}
      </Button>
    );
  }
  return null;
};
