import { FilterContext } from '../symphony';
import { DocusignFilters, DocusignStatus } from '../types';

import { LegalDocumentStatus } from '~/__generated__';

export const fromDocusignStatusToLegalDocumentStatus = (statusFilters: DocusignStatus[]): LegalDocumentStatus[] => {
  const legalDocumentStatus: LegalDocumentStatus[] = [];
  statusFilters.forEach(status => {
    switch (status) {
      case DocusignStatus.SUCCEEDED:
        legalDocumentStatus.push(LegalDocumentStatus.SUCCEEDED);
        break;
      case DocusignStatus.DECLINED:
        legalDocumentStatus.push(LegalDocumentStatus.DECLINED);
        break;
      case DocusignStatus.FAILED:
        legalDocumentStatus.push(LegalDocumentStatus.FAILED);
        break;
      case DocusignStatus.PENDING:
        legalDocumentStatus.push(
          LegalDocumentStatus.PENDING,
          LegalDocumentStatus.PENDING_SECONDARY,
          LegalDocumentStatus.PENDING_PRIMARY,
        );
        break;
      default:
        legalDocumentStatus.push(
          LegalDocumentStatus.PENDING,
          LegalDocumentStatus.PENDING_SECONDARY,
          LegalDocumentStatus.PENDING_PRIMARY,
          LegalDocumentStatus.SUCCEEDED,
          LegalDocumentStatus.DECLINED,
          LegalDocumentStatus.FAILED,
        );
    }
  });
  return legalDocumentStatus;
};

export const fromLegalDocumentStatustoDocusignStatus = (status?: LegalDocumentStatus | null): DocusignStatus => {
  switch (status) {
    case LegalDocumentStatus.SUCCEEDED:
      return DocusignStatus.SUCCEEDED;
    case LegalDocumentStatus.DECLINED:
      return DocusignStatus.DECLINED;
    case LegalDocumentStatus.FAILED:
      return DocusignStatus.FAILED;
    case LegalDocumentStatus.PENDING:
    case LegalDocumentStatus.PENDING_PRIMARY:
    case LegalDocumentStatus.PENDING_SECONDARY:
      return DocusignStatus.PENDING;
    default:
      return DocusignStatus.ALL;
  }
};

export const getFilterCountMap = (filterContext: FilterContext[]) => {
  return filterContext.map(v => {
    return {
      count: v.count,
      filters: {
        [DocusignFilters.STATUS]: fromLegalDocumentStatustoDocusignStatus(v.filtersKey.status),
      },
    };
  });
};
