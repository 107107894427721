import { GetBalanceOverTime, GetBalanceOverTimeVariables } from './__generated__/getBalanceOverTime.v2';
import { GetReturnsData, GetReturnsDataVariables } from './__generated__/getReturnsData.v2';
import * as balanceOverTimeQuery from './getBalanceOverTime.gql';
import * as queries from './getReturnsData.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetReturnsData = (options?: QueryHookOptions<GetReturnsData, GetReturnsDataVariables>) => {
  return useSymphonyQuery(queries.GetReturnsData, options);
};

export const useGetBalanceOverTime = (
  options?: QueryHookOptions<GetBalanceOverTime, GetBalanceOverTimeVariables>,
): QueryResult<GetBalanceOverTime, GetBalanceOverTimeVariables> =>
  useSymphonyQuery(balanceOverTimeQuery.GetBalanceOverTime, options);
