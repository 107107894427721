import { SelectedModelPortfolios } from './symphony';
import { GetPortfolioDetails } from './symphony/__generated__/query.v2';
import {
  ChecklistContent,
  Diversification,
  InvestmentDurationContent,
  PlanContent,
  PlanData,
  StagedModelPortfolio,
  StagedModelPortfolioData,
} from './types';

import { DetailedChecklistItem } from '~/components/DetailedChecklist';
import { GetPlanContent_all_plan_items } from '~/containers/Plan/contentstack/__generated__/getPlanContent.v2';
import { planData_planUpdateWorkflows_selectedModelPortfolios } from '~/containers/Plan/symphony/__generated__/query.v2';
import { GetAssetClassContent_all_asset_class_data_items } from '~/hooks/asset-classes/contentstack/__generated__/query.v2';
import { GetModelPortfoliosContent_all_model_portfolio_data_items } from '~/hooks/model-portfolios/contentstack/__generated__/query.v2';
import { allocationToDonutSlice, getAssetAllocations } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { getImageAssetUrl } from '~/utils/contentstack';

interface GetPlanDataVariables {
  assetClassContentData: (GetAssetClassContent_all_asset_class_data_items | null)[];
  assetClassTier?: AssetClassTier;
  items?: GetPortfolioDetails | null;
  managedProductId: string;
  modelPortfolioContentData: (GetModelPortfoliosContent_all_model_portfolio_data_items | null)[];
  selectedModelPortfolios?: SelectedModelPortfolios[];
}

export const getNormalizedStockBondPercentage = (diversification: Diversification): Diversification => {
  const { bondPercentage, stockPercentage } = diversification;
  const bondPercentageFloat = parseFloat(bondPercentage);
  const stockPercentageFloat = parseFloat(stockPercentage);
  const sumPercentage = bondPercentageFloat + stockPercentageFloat;

  if (!bondPercentage || !stockPercentage) {
    return {
      stockPercentage: '',
      bondPercentage: '',
    };
  }

  const bondPercentageRounded = parseInt(((bondPercentageFloat / sumPercentage) * 100).toFixed(), 10);
  const stockPercentageRounded = parseInt(((stockPercentageFloat / sumPercentage) * 100).toFixed(), 10);

  // This solution assumes that should there be a rounding difference, the sum would always be greater than 100
  // This is because the only rounding error should come from rounding 2 .5s up.
  if (stockPercentageRounded + bondPercentageRounded !== 100) {
    if (stockPercentageRounded > bondPercentageRounded) {
      return {
        stockPercentage: (stockPercentageRounded - 1).toFixed(),
        bondPercentage: bondPercentageRounded.toFixed(),
      };
    } else {
      return {
        stockPercentage: stockPercentageRounded.toFixed(),
        bondPercentage: (bondPercentageRounded - 1).toFixed(),
      };
    }
  } else {
    return {
      stockPercentage: stockPercentageRounded.toFixed(),
      bondPercentage: bondPercentageRounded.toFixed(),
    };
  }
};

export const getStockBondRatio = (diversification: Diversification): string => {
  const { bondPercentage, stockPercentage } = getNormalizedStockBondPercentage(diversification);
  if (!bondPercentage || !stockPercentage) {
    return '';
  }
  return `${stockPercentage}/${bondPercentage}`;
};

export const getPlanContent = (showPortfolioCompare: boolean, content?: GetPlanContent_all_plan_items): PlanContent => {
  const checklists: ChecklistContent[] = [];
  const detailedChecklists: DetailedChecklistItem[] = [];
  const investmentDurations: InvestmentDurationContent[] = [];
  content?.items?.forEach(item => {
    switch (item?.__typename) {
      case 'PlanItemsChecklist':
        checklists.push({
          title: item.checklist?.title ?? '',
        });
        break;
      case 'PlanItemsDetailedChecklist':
        detailedChecklists.push({
          subtitle: item.detailed_checklist?.subtitle ?? '',
          title: item.detailed_checklist?.title ?? '',
          icon: getImageAssetUrl(item.detailed_checklist?.iconConnection),
        });
        break;
      case 'PlanItemsInvestmentDuration':
        investmentDurations.push({
          additionalText: item.investment_duration?.additional_text ?? '',
          key: item.investment_duration?.key ?? '',
          text: item.investment_duration?.text ?? '',
        });
        break;
      default:
        break;
    }
  });

  return {
    back: content?.back ?? '',
    checklists,
    checklistTitle: content?.checklist_title ?? '',
    detailedChecklists,
    detailedChecklistTitle: content?.detailed_checklist_title ?? '',
    heading: content?.heading ?? '',
    imageSrc: content?.header_imageConnection?.edges?.[0]?.node?.url ?? '',
    investmentDurations,
    ...(showPortfolioCompare && {
      launchPortfolioCompareContent: {
        title: content?.launch_portfolio_compare?.find(field => field?.key === 'title')?.value ?? '',
        heading: content?.launch_portfolio_compare?.find(field => field?.key === 'heading')?.value ?? '',
        subHeading: content?.launch_portfolio_compare?.find(field => field?.key === 'subHeading')?.value ?? '',
        launchCompareCta:
          content?.launch_portfolio_compare?.find(field => field?.key === 'launchPortfolioCompareCta')?.value ?? '',
        editCompareCta:
          content?.launch_portfolio_compare?.find(field => field?.key === 'editComparisonCta')?.value ?? '',
        chipRecommended:
          content?.launch_portfolio_compare?.find(field => field?.key === 'chipRecommended')?.value ?? '',
        chipRequired: content?.launch_portfolio_compare?.find(field => field?.key === 'chipRequired')?.value ?? '',
        chipCompleted: content?.launch_portfolio_compare?.find(field => field?.key === 'chipCompleted')?.value ?? '',
        imageUrl: getImageAssetUrl(content?.launch_portfolio_image_urlConnection),
      },
    }),
    next: content?.next ?? '',
    programFeeCalculator: {
      body: content?.program_fee_calculator?.body ?? '',
      month: content?.program_fee_calculator?.month ?? '',
      quarter: content?.program_fee_calculator?.quarter ?? '',
      title: content?.program_fee_calculator?.title ?? '',
      viewDetailsLinkText: content?.program_fee_calculator?.view_details_link_text ?? '',
      year: content?.program_fee_calculator?.year ?? '',
    },
    saveAndExit: content?.save_and_exit?.description ?? '',
    saveAndExitLink: content?.save_and_exit?.link ?? '',
    sendProposalLinkText: content?.send_proposal_link_text ?? '',
  };
};

export const getStagedModelPortfolioData = ({
  assetClassContentData,
  assetClassTier = AssetClassTier.MODEL,
  modelPortfolioContentData,
  selectedModelPortfolios,
  stagedModelPortfolio,
}: {
  assetClassContentData: (GetAssetClassContent_all_asset_class_data_items | null)[];
  assetClassTier?: AssetClassTier;
  modelPortfolioContentData: (GetModelPortfoliosContent_all_model_portfolio_data_items | null)[];
  selectedModelPortfolios: planData_planUpdateWorkflows_selectedModelPortfolios[];
  stagedModelPortfolio: StagedModelPortfolio;
}): StagedModelPortfolioData => {
  const modelPortfoliosData = selectedModelPortfolios.map(smp => {
    const mpc = modelPortfolioContentData.find(
      mp =>
        mp?.series_name === smp.modelPortfolio?.seriesBaseName &&
        mp?.internal_id === smp.modelPortfolio?.internalId?.toString(),
    );

    const individualAssetAllocations = getAssetAllocations(
      smp.modelPortfolio?.guidance?.diversification?.assets.allocations,
      assetClassContentData,
      assetClassTier,
    );

    const stockBondDiversification = {
      bondPercentage:
        smp.modelPortfolio?.guidance?.diversification?.assets.stockBondDiversification?.bondPercentage ?? '',
      stockPercentage:
        smp.modelPortfolio?.guidance?.diversification?.assets.stockBondDiversification?.stockPercentage ?? '',
    };

    return {
      name: mpc?.model_portfolio_name ?? '',
      seriesBaseName: smp.modelPortfolio?.seriesBaseName ?? '',
      seriesId: smp.modelPortfolio?.seriesId ?? 0,
      internalId: smp.modelPortfolio?.internalId ?? 0,
      description: mpc?.model_portfolio_description ?? '',
      strategyBrochureUrl: smp.modelPortfolio?.strategyBrochureUrl ?? '',
      stockBondDiversification,
      stockBondRatio: getStockBondRatio(stockBondDiversification),
      assetAllocation: individualAssetAllocations,
      percentage: smp.percentage ?? undefined,
      portfolioAssetsBreakdown: individualAssetAllocations.map(allocationToDonutSlice(assetClassTier)),
      providerName: smp.modelPortfolio?.provider?.name ?? '',
    };
  });

  const overallAssetAllocation = getAssetAllocations(
    stagedModelPortfolio.assetAllocations,
    assetClassContentData,
    assetClassTier,
  );

  return {
    assetAllocation: overallAssetAllocation,
    modelPortfolios: modelPortfoliosData,
    minimumBalanceForAccountOpening: stagedModelPortfolio.minimumBalanceForAccountOpening
      ? +stagedModelPortfolio.minimumBalanceForAccountOpening
      : undefined,
    portfolioAssetsBreakdown: overallAssetAllocation.map(allocationToDonutSlice(assetClassTier)),
    stockBondDiversification: stagedModelPortfolio.stockBondDiversification,
    stockBondRatio: getStockBondRatio(stagedModelPortfolio.stockBondDiversification),
    stagedModelPortfolio,
  };
};

export const getPlanData = ({
  assetClassContentData,
  assetClassTier = AssetClassTier.MODEL,
  items,
  modelPortfolioContentData,
  selectedModelPortfolios,
}: GetPlanDataVariables): PlanData => {
  // When there is a Portfolio Selection step, use the selectedModalPortfolio.
  // When there is no Portfolio Selection step, default to the first model portfolio.
  const product = items?.managedProduct;
  const modelPortfolio =
    product?.modelPortfolios?.find(
      portfolio =>
        portfolio.seriesId === selectedModelPortfolios?.[0]?.modelPortfolioSeriesId &&
        portfolio.internalId === selectedModelPortfolios[0]?.modelPortfolioInternalId,
    ) ?? product?.modelPortfolios?.[0];
  const modelPortfolioContent = modelPortfolioContentData.find(
    mc =>
      mc?.series_name === modelPortfolio?.seriesBaseName && mc?.internal_id === modelPortfolio?.internalId?.toString(),
  );

  const assetAllocation = getAssetAllocations(
    modelPortfolio?.guidance?.diversification?.assets.allocations,
    assetClassContentData,
    assetClassTier,
  );

  const stockBondDiversification = {
    bondPercentage: modelPortfolio?.guidance?.diversification?.assets.stockBondDiversification?.bondPercentage ?? '',
    stockPercentage: modelPortfolio?.guidance?.diversification?.assets.stockBondDiversification?.stockPercentage ?? '',
  };

  return {
    planId: product?.planId ?? '',
    planUpdateWorkflowId: product?.planUpdateWorkflows[0]?.id ?? undefined,
    modelPortfolio: {
      name: modelPortfolioContent?.model_portfolio_name ?? '',
      seriesBaseName: modelPortfolio?.seriesBaseName ?? '',
      seriesId: modelPortfolio?.seriesId ?? 0,
      internalId: modelPortfolio?.internalId ?? 0,
      description: modelPortfolioContent?.model_portfolio_description ?? '',
      strategyBrochureUrl: modelPortfolio?.strategyBrochureUrl ?? '',
      riskPreference: {
        value: product?.latestRiskPreference?.score?.value ?? 0,
        description: product?.latestRiskPreference?.score?.description ?? '',
      },
      providerName: modelPortfolio?.provider?.name ?? '',
      stockBondDiversification,
      assetAllocation,
      portfolioAssetsBreakdown: assetAllocation.map(allocationToDonutSlice(assetClassTier)),
      stockBondRatio: getStockBondRatio(stockBondDiversification),
    },
  };
};
