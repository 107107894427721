import merge from 'lodash.merge';

import { createDefaultTheme } from '../theme';

import { alpha, ThemeOptions } from '~/components/ui/mui';

// This modern theme file is to provide a separate theme for the re-design of various DW products.
// The Modern Theme file serves as a override for now, but it will eventually function as a independent theme.
// We are exporting the modern theme in a separate folder to reflect that it should live as it's own theme.

// Example Palette colors for now, we should plug in with the appropriate palette colors once we have it.
export const modernThemePalette = {
  // Material-ui will calculate the dark, light, etc from respective main values.
  // To allow partner themes utilize this automatic calculation, the core should not be setting them.
  primary: {
    main: '#2365F6',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#E97300',
  },
  text: {
    primary: '#434343',
    secondary: '#8c8c8c',
  },
  error: {
    main: '#FFC8CC',
  },
  success: {
    main: '#CCF6E2',
  },
  other: {
    divider: '#E6E8ED',
    neutral: '#F0F0F0',
    stroke: alpha('#000000', 0.23),
    toggleButton: '#040f24',
    toggleButtonContrastText: '#FFFFFF',
  },
  warning: {
    main: '#FF9800',
  },
  info: {
    main: '#2196f3',
    light: '#BFE0FB',
    border: alpha('#1BB3BC', 0.5),
  },
};

const components: ThemeOptions['components'] = {
  /*
  Since the palette variables like 'text.primary' aren't resolving for the MuiComponent styleOverrides,
  get the palette through the theme: https://mui.com/material-ui/customization/theme-components/#overrides-based-on-props
  sfComponent styles do not have this issue however because they are passed into the sx props.
  It may not work for the svg properties like fill, stroke, etc., so you may still have to reference the palette object.
  In doing so, it also means that the same theme option need to be in the partner repos as well to reference the correct palette object values.
   */
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        color: modernThemePalette.other.toggleButton,
        '&.MuiToggleButton-root': {
          borderColor: modernThemePalette.other.toggleButton,
        },
        '&.Mui-selected': {
          backgroundColor: modernThemePalette.other.toggleButton,
          color: modernThemePalette.other.toggleButtonContrastText,
        },
        '&.Mui-selected:hover': {
          backgroundColor: modernThemePalette.other.toggleButton,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '40px',
        borderBottom: 'none',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: ({ theme }) => ({
        color: theme.palette.text.primary,
        '.MenuItem-label': {
          fontSize: 14,
          fontWeight: 400,
        },
        '.MenuItem-description': {
          fontSize: 14,
          fontWeight: 400,
        },
      }),
    },
  },
};

export const modernThemeComponentStyles: ThemeOptions = {
  sfModal: {
    root: {},
    styles: {
      dialogActions: {
        padding: '2rem 3.6rem',
        justifyContent: 'end',
        '.MuiButton-root': {
          borderRadius: '6px',
        },
        '.MuiLoadingButton-root': {
          backgroundColor: 'secondary.main',
          color: 'primary.contrastText',
        },
      },
    },
  },
  sfDropdown: {
    styles: {
      iconColor: 'text.primary',
      madlib: {
        root: {
          '.MuiInput-underline:before': {
            borderBottomStyle: 'dashed',
          },
          '.MuiInput-root': { minHeight: 0 },
        },
        inputProps: { textTransform: 'lowercase' },
        selectedItemColor: 'primary.main',
      },
      menuItemPlaceholderColor: 'text.disabled',
      outlinedClassic: {
        inputLabel: {
          position: 'relative',
          transform: 'none',
        },
      },
    },
  },
  sfCreateFlagModal: {
    styles: {
      cancelButton: {
        borderRadius: '6px',
      },
      escalateButton: {
        borderRadius: '6px',
        backgroundColor: 'secondary.main',
        color: 'primary.contrastText',
      },
    },
  },
  sfBasicTable: {
    root: {
      '&': {
        p: 4,
        pt: 0,
        backgroundColor: 'white',
        borderCollapse: 'separate',
      },
      '.MuiTableCell-head': {
        fontWeight: 600,
        borderBottom: '1px solid',
      },
    },
    styles: {},
  },
  sfOpsDashboard: {
    styles: {
      opsHeaderLogo: {
        height: '17px',
        width: '90px',
      },
      opsHeader: {
        borderRadius: '0',
        color: 'text.primary',
        minWidth: '165px',
        py: 4,
      },
      tabsContainer: {
        background: 'linear-gradient(90deg, #363F50 0%, #363F50 76.3%, #3A60F8 100%)',
        pt: 0,
        px: 9.5,
      },
      tabs: {
        textTransform: 'none',
        padding: '0.6rem 1.2rem',
        color: 'primary',
        '&.Mui-selected': {
          color: 'primary.contrastText',
        },
      },
      tabsIndicatorColor: 'other.backgroundBlue',
      chip: {
        label: {
          cancelled: {
            backgroundColor: 'statusChipBackground.cancelled',
            color: 'error.textDark',
          },
          completed: {
            backgroundColor: 'statusChipBackground.completed',
            color: 'text.primary',
          },
          default: {
            backgroundColor: 'statusChipBackground.default',
            color: 'text.primary',
          },
          active: {
            backgroundColor: 'statusChipBackground.main',
            color: 'warning.textDark',
          },
          approved: {
            backgroundColor: 'statusChipBackground.approved',
            color: 'success.contrastText',
          },
          declined: {
            backgroundColor: 'statusChipBackground.declined',
            color: 'error.textDark',
          },
          rejected: {
            backgroundColor: 'statusChipBackground.rejected',
            color: 'error.textDark',
          },
          succeeded: {
            backgroundColor: 'statusChipBackground.succeeded',
            color: 'success.contrastText',
          },
          failed: {
            backgroundColor: 'statusChipBackground.failed',
            color: 'error.textDark',
          },
        },
        root: {
          minWidth: '105px',
          borderRadius: '4px',
        },
      },
    },
  },
  sfResolveFlagModal: {
    root: {},
    styles: {
      reasonCard: {
        backgroundColor: '#F2F2F5',
        minWidth: '155px',
        py: 1.5,
        px: 3,
        my: 0.25,
        boxShadow: 'none',
      },
      reasonDate: {
        fontSize: 'small',
      },
      reasonIcon: { marginLeft: '10px' },
      reasonTitle: {
        fontWeight: 'bold',
        my: 1,
      },
      resolveButton: {},
      resolvedIcon: {
        mx: 2,
      },
    },
  },
  sfStatusButton: {
    styles: {
      label: {
        cancelled: { backgroundColor: 'error.light', color: 'text.primary' },
        completed: { backgroundColor: 'success.main', color: 'text.primary' },
        default: { backgroundColor: 'other.neutral', color: 'text.primary' },
        in_progress: { backgroundColor: 'info.light', color: 'text.primary' },
        pending: { backgroundColor: 'other.neutral', color: 'text.primary' },
        unrecognized: { backgroundColor: 'error.main', color: 'error.contrastText' },
        needs_review: { backgroundColor: 'error.light', color: 'text.primary' },
        funded: { backgroundColor: 'success.main', color: 'text.primary' },
        below_minimum: { backgroundColor: 'other.neutral', color: 'text.primary' },
      },
      root: {},
    },
  },
  sfStatusChangeModal: {
    root: {
      '.MuiDialogContent-root': {
        border: 0,
      },
    },
    styles: {
      confirmationLogo: {
        padding: '40px 0px',
      },
    },
  },
};

export const createModernThemeOptions = (overrideOptions?: ThemeOptions) => {
  const modernTheme = createDefaultTheme({
    palette: modernThemePalette,
    ...modernThemeComponentStyles,
    components,
  });

  return overrideOptions ? merge({}, modernTheme, overrideOptions) : modernTheme;
};

export const modernThemeOptions = createModernThemeOptions();

// Example implementation within partner repo
// <ThemeProvider theme={createMuiTheme(modernThemeOptions)}>{children}</ThemeProvider>
