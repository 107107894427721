import { GetSessionTimeoutContent, GetSessionTimeoutContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetSessionTimeoutMessageContent = (
  options?: QueryHookOptions<GetSessionTimeoutContent, GetSessionTimeoutContentVariables>,
): QueryResult<GetSessionTimeoutContent, GetSessionTimeoutContentVariables> => {
  return useContentstackQuery(queries.GetSessionTimeoutContent, options);
};
