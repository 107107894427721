import React, { ComponentProps } from 'react';

import { GoalsCard, GoalsCardAssociatedAccount } from '../../Card';
import { useGetReadinessStatuses, useGoalsEvent } from '../../hooks';
import { getCliffGoalProjection, getReadinessStatus, getRetirementGoalProjection, isPartialAccount } from '../../utils';
import { GoalSummaryContent } from '..';
import { GetGoalCardSummaryContent_all_goal_page_items } from '../contentstack/__generated__/getGoalCardSummaryContent.v2';

import { useGoalCardSummaryContent } from './useGoalCardSummaryContent';
import { GoalsCardPropsToSort, useGoalSummary } from './useGoalSummary';

import { AccountType, GoalStatus, GoalType } from '~/__generated__';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { GoalItem } from '~/hooks/goals/amplify';
import { CoreConfig } from '~/utils/config';
import { ContentOptions, getImageAssetUrl } from '~/utils/contentstack';
import { formatCurrency, formatMaskedAccountNumber } from '~/utils/format';
import { formatDate } from '~/utils/format/date';
import { isDefined } from '~/utils/functions/guards';

export const getGoalModalContent = (
  goalPageContent?: Pick<GetGoalCardSummaryContent_all_goal_page_items, 'complete_goal' | 'delete_goal'> | null,
): Pick<GoalSummaryContent, 'completeGoalModal' | 'deleteModal'> => {
  return {
    completeGoalModal: {
      primaryCta: goalPageContent?.complete_goal?.primary_cta ?? '',
      secondaryCta: goalPageContent?.complete_goal?.secondary_cta ?? '',
      image: getImageAssetUrl(goalPageContent?.complete_goal?.imageConnection),
      title: goalPageContent?.complete_goal?.title ?? '',
      description: goalPageContent?.complete_goal?.description ?? '',
    },
    deleteModal: {
      primaryCta: goalPageContent?.delete_goal?.primary_cta ?? '',
      secondaryCta: goalPageContent?.delete_goal?.secondary_cta ?? '',
      image: getImageAssetUrl(goalPageContent?.delete_goal?.imageConnection),
      title: goalPageContent?.delete_goal?.title ?? '',
      description: goalPageContent?.delete_goal?.description ?? '',
    },
  };
};

export const getGoalsCardAttributes = ({
  contentOptions,
  goal,
  goalCardSummaryContentData,
}: {
  contentOptions: ContentOptions;
  goal: GoalItem;
  goalCardSummaryContentData: NonNullable<ReturnType<typeof useGoalCardSummaryContent>['data']>;
}): ComponentProps<typeof GoalsCard>['attributes'] => {
  const { locale } = contentOptions;
  const { getAttributeRte, getAttributeText } = goalCardSummaryContentData.goalCard;
  const inputNeededLabel = getAttributeText('valueInputNeeded');
  const attributes = [];
  let projectionData;

  const getAmountSavedValue = (total?: number) => {
    if (goal.balanceOnCompletion === null || total === undefined) {
      return;
    }
    return (
      <RteContent
        config={{
          value: formatCurrency(goal.balanceOnCompletion, { locale }),
          total: formatCurrency(total, { locale }),
        }}
        data={getAttributeRte('valueAmountSaved')}
      />
    );
  };

  switch (goal.goalType) {
    case GoalType.CLIFF:
      try {
        projectionData = goal.goalProjection ? getCliffGoalProjection(goal.goalProjection) : undefined;
      } catch (error: any) {
        console.warn(`GoalsCardAttributes: ${error}`);
      }

      if (goal.goalStatus === GoalStatus.COMPLETED) {
        attributes.push({
          label: getAttributeText('amountSaved'),
          value: getAmountSavedValue(projectionData?.spending.goal),
        });
      } else {
        attributes.push({
          label: getAttributeText('targetAmount'),
          value: goal.targetBalance === null ? inputNeededLabel : formatCurrency(goal.targetBalance, { locale }),
        });
        if (projectionData?.spending.projected !== undefined) {
          attributes.push({
            label: getAttributeText('projectedAmount'),
            value: formatCurrency(Math.round(projectionData.spending.projected), { locale }),
          });
        }
      }
      attributes.push({
        label: getAttributeText('targetDate'),
        value: goal.targetEndDate === null ? inputNeededLabel : formatDate(goal.targetEndDate, 'MMMM yyyy', { locale }),
      });
      break;

    case GoalType.RETIREMENT:
      try {
        projectionData = goal.goalProjection ? getRetirementGoalProjection(goal.goalProjection) : undefined;
      } catch (error: any) {
        console.warn(`GoalsCardAttributes: ${error}`);
      }
      const targetSpendingGoal = goal.targetSpending ?? projectionData?.spending.goal;

      if (goal.goalStatus === GoalStatus.COMPLETED) {
        attributes.push({
          label: getAttributeText('amountSaved'),
          value: getAmountSavedValue(targetSpendingGoal),
        });
      } else {
        attributes.push({
          label: getAttributeText('targetSpending'),
          value:
            targetSpendingGoal === undefined
              ? inputNeededLabel
              : `${formatCurrency(targetSpendingGoal, { locale })}${getAttributeText('valuePerMonth')}`,
        });
        if (projectionData?.spending.projected !== undefined) {
          attributes.push({
            label: getAttributeText('projectedAmount'),
            value: `${formatCurrency(Math.round(projectionData.spending.projected), { locale })}${getAttributeText(
              'valuePerMonth',
            )}`,
          });
        }
      }
      const retirementDate = projectionData?.retirementDate
        ? ` (${formatDate(projectionData.retirementDate, 'MMMM yyyy', { locale })})`
        : '';
      attributes.push({
        label: getAttributeText('retirementAge'),
        value: goal.user.retirementAge ? `${goal.user.retirementAge}${retirementDate}` : inputNeededLabel,
      });
      break;
  }
  return attributes;
};

export const getGoalsCardAssociatedAccounts = ({
  contentOptions: { locale },
  goal,
  financialAccounts,
  goalCardSummaryContentData,
  onAccountClick,
}: Pick<Parameters<typeof useGoalSummary>[0], 'contentOptions' | 'financialAccounts' | 'onAccountClick'> & {
  goal: GoalItem;
  goalCardSummaryContentData: NonNullable<ReturnType<typeof useGoalCardSummaryContent>['data']>;
}): GoalsCardAssociatedAccount[] => {
  if (!goal.goalAccounts?.items) {
    return [];
  }

  const inProgressCount = goal.goalAccounts.items.filter(i => i?.account && isPartialAccount(i.account)).length;

  const { external, internal } = goal.goalAccounts.items.reduce<{
    external: GoalsCardAssociatedAccount[];
    internal: GoalsCardAssociatedAccount[];
  }>(
    (acc, item) => {
      if (item?.account.accountType !== AccountType.USER || isPartialAccount(item.account)) {
        return acc;
      }

      // Externally associated account
      if (!item.account.externalId && !item.account.copilotPortfolioUlid) {
        acc.external.push({
          amount: item.account.balance === null ? undefined : formatCurrency(item.account.balance, { locale }),
          label: item.account.title,
        });
        return acc;
      }

      const financialAccount = financialAccounts.find(
        a => a.id === item.account.externalId || a.managedProductId === item.account.copilotPortfolioUlid,
      );
      if (financialAccount?.managedProductId) {
        const managedProductId = financialAccount.managedProductId;
        acc.internal.push({
          amount:
            financialAccount.balance === undefined ? undefined : formatCurrency(financialAccount.balance, { locale }),
          financialAccountTypeLabel: goalCardSummaryContentData.goalCard.getFinancialAccountTypeLabel(
            financialAccount.accountType,
          ),
          label: formatMaskedAccountNumber(
            goalCardSummaryContentData.goalCard.accountNumberFormat,
            financialAccount.maskedAccountNumber,
          ),
          onClick: () => onAccountClick(managedProductId),
        });
      }

      return acc;
    },
    { external: [], internal: [] },
  );

  return [
    ...(inProgressCount
      ? [{ label: `${goalCardSummaryContentData.goalCard.getLabelText('accountsInProgress')} (${inProgressCount})` }]
      : []),
    ...internal.sort(compareAssociatedAccount),
    ...external.sort(compareAssociatedAccount),
  ];
};

const getProjectionGapCtaLabelKey = (coreConfig: CoreConfig, goal: GoalItem) => {
  if (coreConfig.components.sfGoalDashboard.GoalsCard?.isProjectionGapCtaDisabled) {
    return;
  }
  if (goal.goalStatus === GoalStatus.ACTIVE_INCOMPLETE) {
    return 'projectionGapPending';
  }
  const goalProjectionParsed = goal.goalProjection ? JSON.parse(goal.goalProjection) : undefined;
  return goalProjectionParsed?.readinessFraction >= 1 ? 'projectionGapFinished' : 'projectionGap';
};
export const getGoalsCardCtas = ({
  actions,
  coreConfig,
  emitEvent,
  goal,
  goalCardSummaryContentData,
}: {
  actions: Parameters<typeof useGoalSummary>[0]['actions'];
  coreConfig: CoreConfig;
  emitEvent: ReturnType<typeof useGoalsEvent>;
  goal: GoalItem;
  goalCardSummaryContentData: NonNullable<ReturnType<typeof useGoalCardSummaryContent>['data']>;
}): ComponentProps<typeof GoalsCard>['ctas'] => {
  const { goalStatus, id: goalId } = goal;
  const getCta = goalCardSummaryContentData.goalCard.getCta;
  const ctas: ComponentProps<typeof GoalsCard>['ctas'] = {};
  ctas.kebabMenuItems = [];

  const handleEditGoal = () => {
    emitEvent({ componentName: 'summary', name: 'editGoal' });
    actions.onEdit({ goalId });
  };
  const handleDeleteGoal = () => {
    emitEvent({
      componentName: 'summary',
      name: goalStatus === GoalStatus.COMPLETED ? 'deleteCompletedGoal' : 'deleteGoal',
    });
    actions.onDelete({ goalId });
  };
  const isAssociatedToPartial = goal.goalAccounts?.items
    .map(ga => ga?.account)
    .filter(isDefined)
    .some(isPartialAccount);
  const kebabMenuItemDelete = {
    text: getCta('deleteGoal').label,
    icon: getCta('deleteGoal').icon,
    callback: handleDeleteGoal,
  };

  const projectionGapCtaLabelKey = getProjectionGapCtaLabelKey(coreConfig, goal);
  const projectionGapCta = projectionGapCtaLabelKey
    ? { label: getCta(projectionGapCtaLabelKey).label, onClick: handleEditGoal }
    : undefined;

  switch (goalStatus) {
    case GoalStatus.ACTIVE_COMPLETE:
      ctas.primary = { label: getCta('viewActiveGoal').label, onClick: handleEditGoal };
      ctas.projectionGap = projectionGapCta;
      if (!isAssociatedToPartial) {
        ctas.kebabMenuItems.push(
          {
            text: getCta('markGoalCompleted').label,
            icon: getCta('markGoalCompleted').icon,
            callback: () => {
              emitEvent({ componentName: 'summary', name: 'markGoalCompleted' });
              actions.onComplete({ goalId });
            },
          },
          kebabMenuItemDelete,
        );
      }
      break;
    case GoalStatus.ACTIVE_INCOMPLETE:
      ctas.primary = { label: getCta('viewPendingGoal').label, onClick: handleEditGoal };
      ctas.projectionGap = projectionGapCta;
      if (!isAssociatedToPartial) {
        ctas.kebabMenuItems.push(kebabMenuItemDelete);
      }
      break;
    case GoalStatus.COMPLETED:
      ctas.primary = { label: getCta('deleteGoal').label, onClick: handleDeleteGoal, variant: 'outlined' };
  }
  return ctas;
};

export const getGoalsCardProgressBar = (
  goal: GoalItem,
  goalCardSummaryContentData: NonNullable<ReturnType<typeof useGoalCardSummaryContent>['data']>,
  readinessStatusesData: ReturnType<typeof useGetReadinessStatuses>['data'],
): ComponentProps<typeof GoalsCard>['progressBar'] => {
  if (goal.goalStatus !== GoalStatus.ACTIVE_COMPLETE || !goal.goalProjection) {
    return;
  }
  const goalProjectionParsed = JSON.parse(goal.goalProjection);
  return typeof goalProjectionParsed.readinessFraction === 'number'
    ? {
        content: { goalPercent: goalCardSummaryContentData.goalCard.getLabelRte('successRate') },
        goalPercent: goalProjectionParsed.readinessFraction * 100,
        color: getReadinessStatus(readinessStatusesData ?? [], goalProjectionParsed.readinessFraction)?.color,
      }
    : undefined;
};

const compareAssociatedAccount = (a: GoalsCardAssociatedAccount, b: GoalsCardAssociatedAccount) => {
  if (!a.amount || !b.amount) {
    return 0;
  }
  return a.amount >= b.amount ? -1 : 1;
};

type GoalCardPropsToCompare = Pick<GoalsCardPropsToSort, 'dateToSortBy'> & {
  content: Pick<GoalsCardPropsToSort['content'], 'title'>;
};
export const goalsCardSort = (
  a: GoalCardPropsToCompare,
  b: GoalCardPropsToCompare,
  sortOrder: 'ascending' | 'descending' = 'ascending',
) => {
  if (!a.dateToSortBy || !b.dateToSortBy) {
    return a.content.title.localeCompare(b.content.title);
  }
  if (sortOrder === 'descending') {
    return b.dateToSortBy.getTime() - a.dateToSortBy.getTime();
  }
  return a.dateToSortBy.getTime() - b.dateToSortBy.getTime();
};
