import { TableColumnConfig, TableColumns } from '../TableSection/types';

export const taxReportsTableColumnsConfig: TableColumnConfig[] = [
  {
    key: TableColumns.Date,
    wrappable: false,
  },
  {
    key: TableColumns.Description,
    wrappable: false,
  },
  {
    key: TableColumns.Download,
    wrappable: false,
  },
];
