import { useCallback, useContext, useMemo, useState } from 'react';

import OpsDashboardContext from '../../OpsDashboardContext';
import { SearchContextLiterals, TOO_MANY_SEARCH_RECORDS_ERROR } from '../../types';
import { commentColumnFactory, getSearchContext } from '../../utils';
import { useGetErrorsTableContentV2 } from '../contentstack';
import { useGetErrorsV2 } from '../symphony';
import {
  defaultAppliedFilters,
  ErrorsFailureContent,
  errorsFilterConfig,
  ErrorsTabData,
  ErrorTabContentKeys,
} from '../types';
import {
  getEntityTypeFilters,
  getFilterCountMap,
  getHasResolvedErrors,
  getTableColumns,
  getTableContent,
  tableErrorsFactory,
} from '../utils';

import { EntityType, Field, OrderType } from '~/__generated__';
import { SelectedFilters } from '~/components/Filters/types';
import { SortConfig, TableColumn } from '~/components/ui/BasicTable';
import { useCoreConfig } from '~/utils/config';
import { findFieldValue } from '~/utils/contentstack';
import { getPaginationContext } from '~/utils/table';
import { AsyncResult } from '~/utils/types';

// Set 10 as constant first until we have requirements to change it
const initialRecordsPerPage = 10;

export interface Props {
  errorId?: string | null;
  handleOpenModal: (row: ErrorsFailureContent) => void;
  handleResolveClick: (entity: EntityType, failureId: string) => void;
  updateFailuresLoading: boolean;
}

export const useGetErrorsTable = ({
  handleResolveClick,
  handleOpenModal,
  updateFailuresLoading,
  errorId,
}: Props): AsyncResult<ErrorsTabData> => {
  const {
    contentOptions,
    onAccountClick,
    onClientClick,
    getClientNameRedirectUrl,
    getAccountNumberRedirectUrl,
    onParamsUpdate,
    params,
    opsContentData,
    currentUser,
  } = useContext(OpsDashboardContext);

  const state = useMemo(() => {
    let value;
    try {
      value = JSON.parse(params.state || '{}');
    } catch (e) {
      return defaultAppliedFilters;
    }

    return value;
  }, [params]);

  const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
  const [activeRowId, setActiveRowId] = useState<string | undefined>();
  const appliedFilters: SelectedFilters = state.filters || defaultAppliedFilters;
  const currentPage: number = state.currentPage || 1;
  const recordsPerPage: number = state.recordsPerPage || initialRecordsPerPage;
  const searchFilter = state.searchFilter ?? null;
  const setCurrentPage = useCallback(
    (page: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: page }) });
    },
    [onParamsUpdate, params, state],
  );
  const setRecordsPerPage = useCallback(
    (value: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, recordsPerPage: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const setSearchFilter = useCallback(
    (value: string | null) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, searchFilter: value }) });
    },
    [onParamsUpdate, params, state],
  );

  const sortConfig: SortConfig = useMemo(
    () =>
      state.sortConfig || {
        field: 'created',
        order: OrderType.DESCENDING,
      },
    [state],
  );

  const onFilterChange = useCallback(
    (filters: SelectedFilters) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, filters }) });
    },
    [onParamsUpdate, params, state],
  );

  const onAppliedFiltersSelect = useCallback(() => {
    setFiltersPopupOpen(true);
  }, []);

  const updateOpenFiltersPopup = useCallback((value: boolean) => {
    setFiltersPopupOpen(value);
  }, []);

  const {
    data: errorsData,
    error: errorsDataError,
    loading: errorsDataLoading,
    refetch: refetchErrorsData,
  } = useGetErrorsV2({
    variables: {
      pagination: {
        limit: recordsPerPage,
        offset: (currentPage - 1) * recordsPerPage,
      },
      entities: getEntityTypeFilters(appliedFilters),
      sort: {
        order: sortConfig.order,
        field: Field.CREATED,
      },
      searchFilter,
      isResolved: getHasResolvedErrors(appliedFilters),
    },
    fetchPolicy: 'no-cache',
  });

  const { data: contentData, loading: contentLoading, error: contentError } = useGetErrorsTableContentV2({
    variables: contentOptions,
  });

  const onPageChange = useCallback(
    (selectedPage: number) => {
      setCurrentPage(selectedPage);
    },
    [setCurrentPage],
  );

  const onRowHover = useCallback(
    (rowId: string | undefined) => {
      setActiveRowId(rowId);
    },
    [setActiveRowId],
  );

  const onUpdateRecordsPerPage = useCallback(
    (value: number) => {
      setRecordsPerPage(value);
    },
    [setRecordsPerPage],
  );

  const onSearchChange = useCallback(
    (searchText: string | null) => {
      setSearchFilter(searchText);
    },
    [setSearchFilter],
  );
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  const data: ErrorsTabData = useMemo(() => {
    const onSort = () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      onParamsUpdate({
        ...params,
        state: JSON.stringify({ ...state, currentPage: 1, sortConfig: { field: 'created', order: toggleOrder } }),
      });
    };
    const tableColumns: TableColumn[] = getTableColumns(contentData, onSort);
    const items = contentData?.all_errors_table?.items?.[0];
    const textFields = items?.fields?.text ?? [];
    const fields = opsContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const filterContext = errorsData?.failures.filterContext;
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_ERRORS);
    const total = errorsData?.failures.paginationContext.total ?? 0;
    const paginationContext = getPaginationContext(fields, currentPage, recordsPerPage, total, onUpdateRecordsPerPage);
    const errorsDataTreated = tableErrorsFactory(
      errorsData?.failures.failures || [],
      contentOptions,
      items?.failure_types ?? [],
    );

    const opsDashboardContent = opsContentData?.all_ops_dashboard?.items?.[0];
    const commentColumn = commentColumnFactory(opsDashboardContent?.comment_column ?? undefined);

    const errorsTableContent = getTableContent({
      contentOptions,
      currentUser,
      handleOpenModal,
      handleResolveClick,
      mpData: errorsDataTreated,
      onAccountClick,
      onClientClick,
      getClientNameRedirectUrl,
      getAccountNumberRedirectUrl,
      commentColumn,
      errorId,
      textFields,
      showProductName,
      opsDashboardContent: opsContentData,
      refetchData: refetchErrorsData,
      updateFailuresLoading,
      activeRowId,
    });
    return {
      appliedFilters,
      appliedFiltersContent: {
        allFilter: findFieldValue(textFields, ErrorTabContentKeys.ALL_FILTER),
        appliedFilters: findFieldValue(textFields, ErrorTabContentKeys.APPLIED_FILTERS),
      },
      currentPage,
      currentSearchFilter: searchFilter,
      errorFilterConfig: errorsFilterConfig.map(filter => {
        return {
          ...filter,
          label: findFieldValue(textFields, `${filter.key.toLowerCase()}_filter`),
          options: filter.options.map(option => {
            return {
              ...option,
              label: findFieldValue(textFields, `${option.id.toLowerCase()}`),
            };
          }),
        };
      }),
      errorTabContent: errorsTableContent,
      filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
      filtersContent: {
        allFilter: findFieldValue(textFields, ErrorTabContentKeys.ALL_FILTER),
        apply: findFieldValue(textFields, ErrorTabContentKeys.APPLY_CTA),
        filters: findFieldValue(textFields, ErrorTabContentKeys.FILTERS_CTA),
        resetAll: findFieldValue(textFields, ErrorTabContentKeys.RESET_ALL_CTA),
      },
      modalTitle: findFieldValue(textFields, ErrorTabContentKeys.MODAL_TITLE),
      filtersPopupOpen,
      feedbackMessage: findFieldValue(textFields, ErrorTabContentKeys.FEED_BACK_MESSAGE),
      onAppliedFiltersSelect,
      onFilterChange,
      onPageChange,
      onRowHover,
      onSearchChange,
      paginationContext,
      refetchErrorsData,
      searchError: false,
      searchContext,
      sortConfig,
      tableColumns,
      totalPages: Math.ceil(total / recordsPerPage),
      updateOpenFiltersPopup,
    };
  }, [
    contentData,
    opsContentData,
    errorsData,
    currentPage,
    recordsPerPage,
    onUpdateRecordsPerPage,
    contentOptions,
    currentUser,
    handleOpenModal,
    handleResolveClick,
    onAccountClick,
    onClientClick,
    getClientNameRedirectUrl,
    getAccountNumberRedirectUrl,
    errorId,
    showProductName,
    refetchErrorsData,
    updateFailuresLoading,
    appliedFilters,
    searchFilter,
    filtersPopupOpen,
    onAppliedFiltersSelect,
    onFilterChange,
    onPageChange,
    onSearchChange,
    sortConfig,
    updateOpenFiltersPopup,
    onParamsUpdate,
    params,
    state,
  ]);

  if (errorsDataError?.message === TOO_MANY_SEARCH_RECORDS_ERROR) {
    return {
      data: { ...data, searchError: true },
      error: contentError,
      loading: contentLoading || errorsDataLoading,
    };
  }

  return {
    data,
    error: errorsDataError || contentError,
    loading: contentLoading || errorsDataLoading,
  };
};
