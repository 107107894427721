import React, { ComponentProps, useEffect, useState } from 'react';

import { DiscardPendingChange } from '../DiscardPendingChange';

import { useLazyGetEditAccountContent } from './contentstack';
import { RecommendedProduct, useLazyGetEditPortfolioData } from './symphony';

import { FlowType, PlanUpdateWorkflowStatus } from '~/__generated__';
import { RiskToleranceResult } from '~/components/RiskToleranceResult';
import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, LoadingButton } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { useUpdatePlanUpdateWorkflow } from '~/hooks/plan/symphony';
import { useSaveRiskScores } from '~/hooks/questionnaire/symphony';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  accountEditDisabled: boolean;
  advisorName?: string;
  advisorPhoneNumber?: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  enablePendingPUWWarning?: boolean;
  isEditAccountDisabled?: boolean;
  managedProductId: string;
  onClose: () => void;
  onRetakeQuestionnaire?: (managedProductId: string) => void;
  open: boolean;
  partyId: string;
  planId?: string;
  planUpdateWorkflowId?: string;
  refetchAccounts?: () => Promise<any>;
};

export const EditAccountModal: React.FC<Props> = ({
  dataQa = 'edit-account-modal',
  accountEditDisabled,
  advisorName = '' /* TODO: Get advisor name from Symphony */,
  advisorPhoneNumber = '' /* TODO: Get advisor phone number name from Symphony */,
  enablePendingPUWWarning = false,
  isEditAccountDisabled = false,
  managedProductId,
  contentOptions,
  open,
  onClose,
  onRetakeQuestionnaire,
  planId,
  planUpdateWorkflowId,
  partyId,
  refetchAccounts,
  ...modalProps
}) => {
  const [showWarningForPendingPUW, setShowWarningForPendingPUW] = useState<boolean>(enablePendingPUWWarning);
  const [modelChangeDiscarded, setModelChangeDiscarded] = useState<boolean>(false);
  const [
    getContent,
    { data: contentstackData, loading: loadingContent, error: errorContent },
  ] = useLazyGetEditAccountContent({ variables: contentOptions });
  /**
   * TODO - Update variables to include riskScore when risk score changes.
   */
  const [
    getEditPortfolioData,
    { data: editPortfolioData, loading: loadingEditPortfolioData, error: errorEditPortfolioData },
  ] = useLazyGetEditPortfolioData({
    variables: { partyId, managedProductId },
    fetchPolicy: 'no-cache',
    skip: showWarningForPendingPUW,
  });

  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [isBusy, setIsBusy] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSaveRiskToleranceDisabled, setIsSaveRiskToleranceDisabled] = useState(false);
  const [riskScoreTemp, setRiskScoreTemp] = useState(0);
  const [saveRiskScores] = useSaveRiskScores();
  const [updatePlanUpdateWorkflow] = useUpdatePlanUpdateWorkflow();

  const content = contentstackData?.all_edit_account_modal?.items?.[0]?.edit_account;
  const contentPendingModalWarning = contentstackData?.all_edit_account_modal?.items?.[0]?.pending_modal_change_warning;
  const editManagedProduct = editPortfolioData?.managedProduct;

  useEffect(() => {
    if (open) {
      if (!editPortfolioData) {
        getEditPortfolioData();
      }
      if (!contentstackData) {
        getContent();
      }
      setIsSaveRiskToleranceDisabled(true);
    }
  }, [open]);

  const confirmNewRiskTolerance = async () => {
    setIsBusy(true);
    try {
      setErrorSaving(undefined);
      const saveRiskScoreResponse = await saveRiskScores({
        variables: { isOverride: true, managedProductId, partyId, riskScore: riskScoreTemp, source: FlowType.RETAKE },
      });

      if (editManagedProduct?.planId && saveRiskScoreResponse.data?.saveRiskScores?.id) {
        await updatePlanUpdateWorkflow({
          variables: {
            planId: editManagedProduct.planId,
            planUpdateWorkflowId: saveRiskScoreResponse.data.saveRiskScores.id,
            status: PlanUpdateWorkflowStatus.COMPLETED,
          },
        });
      }

      onEditAccountClose();
    } catch (error: any) {
      setErrorSaving(error);
      console.error(error);
    } finally {
      setIsBusy(false);
    }
  };

  const handleRetakeQuestionnaire = () => {
    onRetakeQuestionnaire?.(managedProductId);
    onEditAccountClose(true);
  };

  const onEditAccountClose = (skipRefetchAccounts?: boolean) => {
    setIsEdit(false);
    if (!skipRefetchAccounts && modelChangeDiscarded) {
      refetchAccounts?.();
    }
    onClose();
  };

  const getCta = () => content?.cta?.[+isEdit];

  const overriddenRiskScore = editManagedProduct?.latestRiskPreference?.score
    ? { riskScore: editManagedProduct.latestRiskPreference.score.value }
    : {};

  const calculatedRecommendation = Object.assign(
    {},
    editManagedProduct?.calculatedRecommendations,
    overriddenRiskScore,
  ) as RecommendedProduct;

  const loading = loadingContent || loadingEditPortfolioData;
  const error = errorContent || errorEditPortfolioData;
  const isMobile = useIsMediumScreen();

  const handleDiscard = async () => {
    await updatePlanUpdateWorkflow({
      variables: {
        planId: planId ?? '',
        planUpdateWorkflowId: planUpdateWorkflowId ?? '',
        status: PlanUpdateWorkflowStatus.CANCELLED,
      },
    });
  };

  const handlePendingModalDiscard = () => {
    setModelChangeDiscarded(true);
    setShowWarningForPendingPUW(false);
  };
  return showWarningForPendingPUW && planId && planUpdateWorkflowId ? (
    <DiscardPendingChange
      content={{
        title: contentPendingModalWarning?.heading ?? '',
        discardMessage: contentPendingModalWarning?.message ?? '',
        ctas: {
          cancel: contentPendingModalWarning?.cancel ?? '',
          discard: contentPendingModalWarning?.continue ?? '',
        },
      }}
      contentOptions={contentOptions}
      loading={loadingContent}
      onClose={onEditAccountClose}
      onDiscard={handleDiscard}
      onDiscardCallback={handlePendingModalDiscard}
      open={open}
      showDiscardSuccessful={false}
      showWarningIcon
    />
  ) : (
    <Modal
      {...modalProps}
      actions={
        <>
          {!isEditAccountDisabled && !accountEditDisabled && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexGrow: 1 }}>
              <Button
                data-qa={`${dataQa}-cta-option`}
                fullWidth={isMobile}
                onClick={isEdit ? handleRetakeQuestionnaire : () => setIsEdit(true)}
                variant="text"
              >
                {getCta()?.option ?? 'MISSING OPTION CTA'}
              </Button>
            </Box>
          )}
          <Button data-qa={`${dataQa}-cta-close`} onClick={() => onEditAccountClose()} variant="outlined">
            {!isEditAccountDisabled ? getCta()?.secondary ?? 'MISSING SECONDARY CTA' : content?.disabled_state?.cta}
          </Button>
          {!isEditAccountDisabled && (
            <LoadingButton
              data-qa={`${dataQa}-cta-primary`}
              disabled={!!error || (isEdit && isSaveRiskToleranceDisabled)}
              loading={loading || isBusy}
              onClick={isEdit ? confirmNewRiskTolerance : handleRetakeQuestionnaire}
              variant="contained"
            >
              {getCta()?.primary ?? 'MISSING PRIMARY CTA'}
            </LoadingButton>
          )}
        </>
      }
      content={
        isEditAccountDisabled ? (
          <Box data-qa={`${dataQa}-disabled`}>
            <img alt="" src={content?.disabled_state?.imageConnection?.edges?.[0]?.node?.url ?? ''} />
            <RteContent
              config={{ advisorName }}
              data={content?.disabled_state?.heading ?? ''}
              data-qa={`${dataQa}-heading`}
              sx={{ mb: 1, mt: 2 }}
            />
            <RteContent
              config={{ advisorName, advisorPhoneNumber }}
              data={content?.disabled_state?.sub_heading ?? ''}
              data-qa={`${dataQa}-sub-heading`}
            />
          </Box>
        ) : (
          <Box data-qa={`${dataQa}-risk-tolerance`}>
            <RiskToleranceResult
              contentOptions={contentOptions}
              currentRiskScore={calculatedRecommendation.riskScore ?? undefined}
              editMode={isEdit}
              /* This is part of account Action RCE flow, at this stage latestScore
               * and recommended product are same.
               */
              fromEditAccountModal
              managedProductId={managedProductId}
              onChange={riskScoreNew => {
                setRiskScoreTemp(riskScoreNew);
                calculatedRecommendation.riskScore === riskScoreNew
                  ? setIsSaveRiskToleranceDisabled(true)
                  : setIsSaveRiskToleranceDisabled(false);
              }}
              partyId={partyId}
              recommendedProduct={calculatedRecommendation}
            />
            {errorSaving && <Alert contentOptions={contentOptions} error={errorSaving} severity="error" />}
            {/* TODO: View Side by Side Portfolio. */}
          </Box>
        )
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={error}
      loading={loadingContent || loadingEditPortfolioData || (!calculatedRecommendation && !isEditAccountDisabled)}
      maxWidth={isEditAccountDisabled ? 'sm' : 'md'}
      onClose={onEditAccountClose}
      open={open}
      title={content?.modal_title || 'MISSING TITLE'}
    />
  );
};
