import React, { ComponentProps, useCallback, useContext, useMemo, useState } from 'react';

import { FlagsReferenceContent } from '../../DepositsTab/contentstack';
import { cashTransferToAllowedStatusUpdates } from '../../mappers';
import { TaxWithholdingInfoContent, TransferItem, TransferStatus } from '../../types';
import {
  getAccountNumberOrAccountTypeString,
  isAccountNumberClickable,
  lastXDigits,
  OpsDashboardUser,
} from '../../utils';
import { Comment, CommentComponentColumn } from '../Comment';
import { FlagAction } from '../FlagAction';
import { AccountNumber } from '../ui/AccountNumber';
import { ClientName } from '../ui/ClientName';

import { FlagEntity, FlagReason, TransferFrequency, TransferType, UserNoteEntityType } from '~/__generated__';
import { NullStateProps } from '~/components/NullState';
import { StatusButton } from '~/components/StatusButton';
import { DISTRIBUTION_REASON_CODES } from '~/components/TaxWithholdingForm';
import {
  BasicTable,
  SortConfig,
  TableColumn,
  TableComponent as TableComponentType,
  TableData,
} from '~/components/ui/BasicTable';
import { ItemsPerPage } from '~/components/ui/BasicTable/ItemsPerPage';
import { TotalItems } from '~/components/ui/BasicTable/TotalItems';
import { CopyField } from '~/components/ui/CopyField';
import { Box, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { OpsDashboard } from '~/containers/OpsDashboard';
import OpsDashboardContext from '~/containers/OpsDashboardV2/OpsDashboardContext';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatPercentageString } from '~/utils/format';
import { precise } from '~/utils/format/currency';
import { formatDate } from '~/utils/format/date';
import { PaginationContext } from '~/utils/types';

export interface Content {
  flags?: FlagsReferenceContent;
  frequencyLabel: string;
  nextOccurrenceLabel: string;
  nonWrappable: string[];
  rmdNote?: string;
  scheduledLabel: string;
  settlementLabel: string;
  status: Record<TransferStatus, string>;
  tableHeaders: TableColumn[];
  taxWithholdingInfo?: TaxWithholdingInfoContent;
  transferToAccountNote: string;
}

export interface CashTransferTableProps {
  TableComponent?: TableComponentType;
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentPage: number;
  currentUser: OpsDashboardUser;
  displayStrings: Content;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  items: TransferItem[];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onPageChange: (p: number) => void;
  onSelectNewStatusForItem: (item: TransferItem, selectedStatus: TransferStatus) => void;
  onSort: (field: string) => () => void;
  paginationContext: PaginationContext;
  refetchData: () => void;
  sortConfig: SortConfig;
  totalPages: number;
  transferType: TransferType;
}

export const SortableFields = ['createdAt'];

export const CashTransferFlagReasons = [
  FlagReason.HIGH_TRANSACTION_VALUE,
  FlagReason.API_FAILURE,
  FlagReason.OVERDUE,
  FlagReason.POSSIBLE_DUPLICATE,
  FlagReason.OTHER,
];

function getFrequencyString(frequency: TransferFrequency) {
  switch (frequency) {
    case TransferFrequency.BIMONTHLY:
      return 'Bimonthly';
    case TransferFrequency.BIWEEKLY:
      return 'Biweekly';
    case TransferFrequency.MONTHLY:
      return 'Monthly';
    case TransferFrequency.ONE_TIME:
      return 'One-time';
    case TransferFrequency.WEEKLY:
      return 'Weekly';
  }
}

export const CashTransferTable: React.FC<CashTransferTableProps> = ({
  commentColumn,
  contentOptions,
  currentPage,
  currentUser,
  nullStateConfig,
  displayStrings,
  items,
  onAccountClick,
  onClientClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  onPageChange,
  onSort,
  onSelectNewStatusForItem,
  sortConfig,
  TableComponent = BasicTable,
  totalPages,
  refetchData,
  transferType,
  paginationContext,
}) => {
  const columns: TableColumn[] = useColumns(displayStrings, onSort);
  const [activeRowId, setActiveRowId] = useState<string | undefined>();

  const onRowHover = (id: string | undefined) => {
    setActiveRowId(id);
  };

  const formattedData: TableData[] = useFormattedData(
    transferType,
    items,
    currentUser,
    contentOptions,
    displayStrings,
    {
      onAccountClick,
      onClientClick,
      refetchData,
      getClientNameRedirectUrl,
      getAccountNumberRedirectUrl,
    },
    onSelectNewStatusForItem,
    commentColumn,
    activeRowId,
  );
  return (
    <TableComponent
      BottomLeftSection={<TotalItems paginationContext={paginationContext} />}
      BottomRightSection={<ItemsPerPage paginationContext={paginationContext} />}
      alignItems="left"
      columns={columns}
      currentPage={currentPage}
      data={formattedData}
      enableRowHover
      nullStateConfig={nullStateConfig}
      onPageChange={onPageChange}
      onRowHover={onRowHover}
      showPagination
      sortConfig={sortConfig}
      totalPages={totalPages}
    />
  );
};

function useFormattedData(
  transferType: TransferType,
  items: TransferItem[],
  currentUser: OpsDashboardUser,
  contentOptions: ContentOptions,
  displayStrings: CashTransferTableProps['displayStrings'],
  cb: {
    getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
    getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
    onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
    onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
    refetchData: () => void;
  },
  onSelectNewStatusForItem: (item: TransferItem, selectedStatus: TransferStatus) => void,
  commentColumn?: CommentComponentColumn,
  activeRowId?: string | undefined,
): TableData[] {
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  const { opsContentData } = useContext(OpsDashboardContext);
  const opsDashboardContent = opsContentData?.all_ops_dashboard?.items?.[0];
  const copyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_ulid_label');
  const successCopyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_success_label');

  const getFlagEntity = useCallback(
    (item: TransferItem) => {
      switch (transferType) {
        case TransferType.DEPOSIT:
          return item.frequency === TransferFrequency.ONE_TIME
            ? FlagEntity.CASH_DEPOSIT
            : FlagEntity.RECURRING_CASH_DEPOSIT;
        case TransferType.WITHDRAWAL:
          return item.frequency === TransferFrequency.ONE_TIME
            ? FlagEntity.CASH_WITHDRAWAL
            : FlagEntity.RECURRING_CASH_WITHDRAWAL;
      }
    },
    [transferType],
  );

  const getCommentEntity = useCallback(
    (item: TransferItem) => {
      switch (transferType) {
        case TransferType.DEPOSIT:
          return item.frequency === TransferFrequency.ONE_TIME
            ? UserNoteEntityType.CASH_DEPOSIT
            : UserNoteEntityType.RECURRING_CASH_DEPOSIT;
        case TransferType.WITHDRAWAL:
          return item.frequency === TransferFrequency.ONE_TIME
            ? UserNoteEntityType.CASH_WITHDRAWAL
            : UserNoteEntityType.RECURRING_CASH_WITHDRAWAL;
      }
    },
    [transferType],
  );

  return useMemo(
    () =>
      items.map(item => ({
        ...item,
        rowKey: item.id,
        clientName: (
          <ClientName
            clientName={item.clientName}
            onClick={() => cb.onClientClick(item.partyId)}
            redirectUrl={cb.getClientNameRedirectUrl(item.partyId)}
          />
        ),
        accountNumber: (
          <AccountNumber
            accountNumber={item.accountNumber}
            accountNumberText={getAccountNumberOrAccountTypeString(item.accountNumber, item.accountTypeText)}
            label={showProductName ? item.productName : null}
            onClick={
              isAccountNumberClickable(item.accountState)
                ? () => cb.onAccountClick(item.partyId, item.managedProductId)
                : undefined
            }
            redirectUrl={
              isAccountNumberClickable(item.accountState)
                ? cb.getAccountNumberRedirectUrl(item.partyId, item.managedProductId)
                : undefined
            }
          />
        ),
        notes: (
          <Stack>
            <Typography sx={{ color: 'text.secondary', textAlign: 'left' }} variant="body2">
              {item.notes ||
                (item.rmdPlanId
                  ? `${displayStrings.rmdNote} ${item.scheduledDate ? formatDate(item.scheduledDate) : '-'}`
                  : `${displayStrings.transferToAccountNote} ${lastXDigits(item.destinationBankAccount, 3)}`)}
            </Typography>
            {item.isManagedProductTaxSheltered && item.taxInformation && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2">
                  {`${displayStrings.taxWithholdingInfo?.federalTax}: ${formatPercentageString(
                    item.taxInformation.federalTax ?? '0',
                    { decimals: 0, locale: contentOptions.locale },
                  )}`}
                </Typography>
                <Typography variant="body2">
                  {`${displayStrings.taxWithholdingInfo?.stateTax}: ${formatPercentageString(
                    item.taxInformation.stateTax ?? '0',
                    { decimals: 0, locale: contentOptions.locale },
                  )}`}
                </Typography>
                {item.taxInformation.distributionReason && (
                  <Typography variant="body2">
                    {`${displayStrings.taxWithholdingInfo?.distributionReason}: ${
                      item.taxInformation.distributionReason === DISTRIBUTION_REASON_CODES.NORMAL
                        ? displayStrings.taxWithholdingInfo?.distributionReasonValues.normal
                        : displayStrings.taxWithholdingInfo?.distributionReasonValues.premature
                    }`}
                  </Typography>
                )}
                {item.taxInformation.grossUp !== undefined && (
                  <Typography variant="body2">
                    {`${displayStrings.taxWithholdingInfo?.grossUp}: ${
                      item.taxInformation.grossUp
                        ? displayStrings.taxWithholdingInfo?.grossUpValues.yes
                        : displayStrings.taxWithholdingInfo?.grossUpValues.no
                    }`}
                  </Typography>
                )}
              </Box>
            )}
          </Stack>
        ),
        amount: precise(item.amount),
        createdAt: formatDate(item.createdAt),
        schedule:
          item.frequency === TransferFrequency.ONE_TIME ? (
            <Box>
              {item.scheduledDate && (
                <Typography>
                  {displayStrings.scheduledLabel} {formatDate(item.scheduledDate)}
                </Typography>
              )}
              {item.settlementDate && (
                <Typography>
                  {displayStrings.settlementLabel} {formatDate(item.settlementDate)}
                </Typography>
              )}
            </Box>
          ) : (
            <Box>
              <Typography>
                {displayStrings.frequencyLabel} {getFrequencyString(item.frequency)}
              </Typography>
              {item.scheduledDate && (
                <Typography>
                  {displayStrings.nextOccurrenceLabel} {formatDate(item.scheduledDate)}
                </Typography>
              )}
            </Box>
          ),
        status: (
          <Stack direction="row">
            <StatusButton
              currentStatus={item.status}
              onMenuItemClick={
                item.rmdPlanId ? undefined : newStatus => onSelectNewStatusForItem(item, newStatus as TransferStatus)
              }
              showDefaultButton
              statusLabels={displayStrings.status}
              statusUpdateItems={cashTransferToAllowedStatusUpdates(
                item.status,
                item.frequency !== TransferFrequency.ONE_TIME,
              )}
            />
            <Stack justifyContent="center" sx={{ width: '30px', px: 1, fontSize: 16 }}>
              {activeRowId === item.id && (
                <CopyField
                  copyLabel={copyLabel}
                  defaultState
                  disableToggling
                  successLabel={successCopyLabel}
                  textToCopy={item.id}
                />
              )}
            </Stack>
          </Stack>
        ),
        actions: (
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Comment
              comment={item.lastCommentData}
              content={commentColumn}
              contentOptions={contentOptions}
              currentUser={currentUser}
              entity={getCommentEntity(item)}
              entityId={item.id}
              key={item.id}
              lastCommentPartyId={item.lastCommentPartyId}
              refetchData={() => cb.refetchData()}
            />
            <FlagAction
              accountId={item.accountNumber}
              accountTypeText={item.accountTypeText}
              clientName={item.clientName}
              contentOptions={contentOptions}
              entity={getFlagEntity(item)}
              entityId={item.id}
              flagReasons={CashTransferFlagReasons}
              flags={item.flags}
              flagsContent={displayStrings.flags}
              key={`flags-${item.id}`}
              onCreate={() => cb.refetchData()}
              onResolve={() => cb.refetchData()}
            />
          </Box>
        ),
      })),
    [
      items,
      cb,
      showProductName,
      displayStrings,
      contentOptions,
      activeRowId,
      commentColumn,
      currentUser,
      getCommentEntity,
      getFlagEntity,
      onSelectNewStatusForItem,
    ],
  );
}

// @todo Have to remove once OpsV2 is used instead of Ops
const restrictedColumns = ['scheduledDate', 'frequency', 'nextOccurrence', 'settlementDate'];

function useColumns(displayStrings: CashTransferTableProps['displayStrings'], onSort?: (field: string) => () => void) {
  return useMemo(() => {
    return displayStrings.tableHeaders
      .filter(v => !restrictedColumns.includes(v.key))
      .map(({ key, title }) => {
        return {
          key,
          title,
          wrappable: !displayStrings.nonWrappable.includes(key),
          onSort: onSort && SortableFields.includes(key) ? onSort : undefined,
        };
      });
  }, [displayStrings, onSort]);
}
