import { ComponentProps, useCallback, useContext, useMemo, useState } from 'react';

import { useGetOpsDashboardContentV2 } from '../../contentstack';
import OpsDashboardContext from '../../OpsDashboardContext';
import {
  RemoveSuspensionData,
  SearchContextLiterals,
  TOO_MANY_SEARCH_RECORDS_ERROR,
  TradingSuspensionsContent,
  TradingSuspensionsTableContent,
} from '../../types';
import { useGetTradingSuspensionsV2Content } from '../contentstack';
import {
  GroupedTradingSuspensionManagedProduct,
  useGetGroupedTradingSuspensionsV2,
  useGetTradingSuspensionsV2,
} from '../symphony';

import {
  defaultFilters,
  geSelectedFilterManagedProductStatus,
  geSelectedFilterTags,
  geSelectedFilterTypes,
  getFilterCountMap,
  getOffset,
  getTableContent,
  getTradingSuspensionContent,
  TradingSuspensionFilters,
  TradingSuspensionsData,
} from './utils';

import { Field, ManagedProductStatus, OrderType, SuspensionType } from '~/__generated__';
import { Filters } from '~/components/Filters';
import { FilterCountMap, SelectedFilters } from '~/components/Filters/types';
import { getSelectedOptions } from '~/components/Filters/utils';
import { SortConfig, TableColumn } from '~/components/ui/BasicTable';
import { getSearchContext } from '~/containers/OpsDashboardV2/utils';
import { useCoreConfig } from '~/utils/config';
import { ContentstackTableColumn, getPaginationContext, sortColumnsByColumnOrder } from '~/utils/table';
import { AsyncResult, PaginationContext, SearchContext } from '~/utils/types';

export interface TradingSuspensionsVariable {
  onRemoveSuspensionClick: (data: RemoveSuspensionData) => void;
}

interface TradingSuspensionsTabData {
  appliedFilters: ComponentProps<typeof Filters>['appliedFilters'];
  content: TradingSuspensionsContent;
  currentPage: number;
  currentSearchFilter: string;
  filterCountMap: FilterCountMap[];
  filtersPopupOpen: boolean;
  handleGroupAccountToggle: () => void;
  isGroupAccountToggleChecked: boolean;
  onAppliedFiltersSelect: () => void;
  onFilterChange: (values: ComponentProps<typeof Filters>['appliedFilters']) => void;
  onPageChange: (pageNumber: number) => void;
  onRowHover: (rowId: string | undefined) => void;
  onSearchChange: (value: string | null) => void;
  paginationContext: PaginationContext;
  refetchTradingSuspendedData: () => void;
  searchContext: SearchContext;
  searchError?: boolean;
  sortConfig: SortConfig;
  tableColumns: TableColumn[];
  totalPages: number;
  tradingSuspendedTableContent: TradingSuspensionsTableContent[];
  updateOpenFiltersPopup: (value: boolean) => void;
}

export const ManagedProductStatusesForSuspensions: ManagedProductStatus[] = [
  ManagedProductStatus.ACTIVE,
  ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
  ManagedProductStatus.NEW,
  ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
  ManagedProductStatus.PENDING_CLOSED,
  ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
];

// Set 10 as constant first until we have requirements to change it
const initialRecordsPerPage = 10;

export const useTradingSuspensionsData = ({
  onRemoveSuspensionClick,
}: TradingSuspensionsVariable): AsyncResult<TradingSuspensionsTabData> => {
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  const {
    currentUser,
    contentOptions,
    commentColumn,
    onAccountClick,
    onClientClick,
    getClientNameRedirectUrl,
    getAccountNumberRedirectUrl,
    onParamsUpdate,
    params,
    opsContentData,
  } = useContext(OpsDashboardContext);

  const state = useMemo(() => {
    let value;
    try {
      value = JSON.parse(params.state || '{}');
    } catch (e) {
      return defaultFilters;
    }

    return value;
  }, [params]);

  const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
  const [activeRowId, setActiveRowId] = useState<string | undefined>();
  const appliedFilters: SelectedFilters = state.filters || defaultFilters;
  const currentPage: number = state.currentPage || 1;
  const recordsPerPage: number = state.recordsPerPage || initialRecordsPerPage;
  const searchFilter = state.searchFilter ?? null;
  const isGroupAccountToggleChecked = state.isGroupAccountToggleChecked ?? false;
  const setCurrentPage = useCallback(
    (page: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: page }) });
    },
    [onParamsUpdate, params, state],
  );

  const setRecordsPerPage = useCallback(
    (value: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, recordsPerPage: value }) });
    },
    [onParamsUpdate, params, state],
  );

  const setIsGroupAccountToggleChecked = useCallback(
    (value: boolean) => {
      onParamsUpdate({
        ...params,
        state: JSON.stringify({ ...state, currentPage: 1, isGroupAccountToggleChecked: value }),
      });
    },
    [onParamsUpdate, params, state],
  );
  const setSearchFilter = useCallback(
    (value: string | null) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, searchFilter: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const sortConfig: SortConfig = useMemo(
    () =>
      state.sortConfig || {
        order: OrderType.DESCENDING,
        field: 'created',
      },
    [state],
  );

  /**
   * The reason for no-cache is due to the updating the list when ever we create or resolve flag for a docusign,
   * the flagged and unflagged docusign table should be updated.
   * Otherwise, instead of the table showing the current flagged and unflagged docusign data it shows the previous cached data.
   * There is no easy way to update the cache to reflect the correct state right now.
   */

  const suspensionTags = geSelectedFilterTags(appliedFilters);
  const suspensionTypes = geSelectedFilterTypes(appliedFilters) as SuspensionType[];
  const managedProductStatuses = geSelectedFilterManagedProductStatus(
    getSelectedOptions(appliedFilters[TradingSuspensionFilters.STATUS]) ?? [],
  );
  const statuses = managedProductStatuses.length ? managedProductStatuses : ManagedProductStatusesForSuspensions;

  const {
    data: tradingSuspendedData,
    error: tradingSuspendedDataError,
    loading: tradingSuspendedDataLoading,
    refetch: refetchTradingSuspendedData,
  } = useGetTradingSuspensionsV2({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: {
      fetchLatestNote: true,
      searchFilter,
      managedProductStatuses: statuses,
      pagination: {
        limit: recordsPerPage,
        offset: getOffset(currentPage, recordsPerPage),
      },
      includedTradingSuspensions: [
        {
          suspensionTags,
          suspensionTypes,
        },
      ],
      sort: {
        field: Field.CREATED,
        order: sortConfig.order,
      },
    },
    skip: isGroupAccountToggleChecked,
  });

  const {
    data: groupedTradingSuspensionsData,
    error: groupedTradingSuspensionsDataError,
    loading: groupedTradingSuspensionsDataLoading,
    refetch: refetchGroupedTradingSuspendedData,
  } = useGetGroupedTradingSuspensionsV2({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: {
      closeRequestStatus: [],
      fetchLatestNote: true,
      searchFilter,
      includedTradingSuspensions: [
        {
          suspensionTags,
          suspensionTypes,
        },
      ],
      pagination: {
        limit: recordsPerPage,
        offset: getOffset(currentPage, recordsPerPage),
      },
      sortByTradingSuspendedDate: true,
      statuses,
    },
    skip: !isGroupAccountToggleChecked,
  });

  const { data: contentData, error: contentStackError, loading: contentLoading } = useGetTradingSuspensionsV2Content({
    variables: contentOptions,
  });

  const {
    data: opsDashboardContentData,
    error: opsDashboardContentError,
    loading: OpsContentLoading,
  } = useGetOpsDashboardContentV2({
    variables: contentOptions,
  });

  const onPageChange = useCallback(
    (selectedPage: number) => {
      setCurrentPage(selectedPage);
    },
    [setCurrentPage],
  );

  const onRowHover = useCallback(
    (rowId: string | undefined) => {
      setActiveRowId(rowId);
    },
    [setActiveRowId],
  );

  const updateRecordsPerPage = useCallback(
    (value: number) => {
      setRecordsPerPage(value);
    },
    [setRecordsPerPage],
  );

  const onSearchChange = useCallback(
    (searchText: string | null) => {
      setSearchFilter(searchText);
    },
    [setSearchFilter],
  );

  const onFilterChange = useCallback(
    (filters: SelectedFilters) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, filters }) });
    },
    [onParamsUpdate, params, state],
  );

  const onAppliedFiltersSelect = useCallback(() => {
    setFiltersPopupOpen(true);
  }, []);

  const updateOpenFiltersPopup = useCallback((value: boolean) => {
    setFiltersPopupOpen(value);
  }, []);

  const handleGroupAccountToggle = useCallback(() => {
    setIsGroupAccountToggleChecked(!isGroupAccountToggleChecked);
  }, [isGroupAccountToggleChecked, setIsGroupAccountToggleChecked]);

  const tradingTableContent = useMemo(() => getTradingSuspensionContent(contentData), [contentData]);

  const tableColumns: TableColumn[] = useMemo(() => {
    // Filter empty columns
    const contentstackTableColumn =
      tradingTableContent.columns?.reduce<ContentstackTableColumn[]>((acc, column: ContentstackTableColumn | null) => {
        if (column) {
          acc.push(column);
        }

        return acc;
      }, []) ?? [];

    const sortedColumns = sortColumnsByColumnOrder(contentstackTableColumn);

    return sortedColumns.map(
      (col): TableColumn => ({
        title: col.column_value,
        key: col.column_id ?? '',
        ...(col.column_id === 'created' && !isGroupAccountToggleChecked
          ? {
              onSort: (_: string) => () => {
                const toggleOrder =
                  sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
                onParamsUpdate({
                  ...params,
                  state: JSON.stringify({
                    ...state,
                    currentPage: 1,
                    sortConfig: { field: 'created', order: toggleOrder },
                  }),
                });
              },
            }
          : {}),
      }),
    );
  }, [isGroupAccountToggleChecked, onParamsUpdate, params, sortConfig.order, state, tradingTableContent.columns]);

  const data: TradingSuspensionsTabData | undefined = useMemo(() => {
    const fields = opsContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const paginationContext = getPaginationContext(fields, currentPage, recordsPerPage, 0, updateRecordsPerPage);
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_TRADING_SUSPENSIONS);

    const commonData = {
      appliedFilters,
      filtersPopupOpen,
      content: tradingTableContent,
      contentLoading,
      handleGroupAccountToggle,
      isGroupAccountToggleChecked,
      onPageChange,
      onRowHover,
      onSearchChange,
      onAppliedFiltersSelect,
      currentSearchFilter: searchFilter,
      onFilterChange,
      currentPage,
      refetchTradingSuspendedData: isGroupAccountToggleChecked
        ? refetchGroupedTradingSuspendedData
        : refetchTradingSuspendedData,
      sortConfig,
      tableColumns,
      searchContext,
      totalPages: 1,
      tradingSuspendedTableContent: [],
      filterCountMap: [],
      updateOpenFiltersPopup,
      paginationContext,
    };

    if (isGroupAccountToggleChecked) {
      const groupedTradingSuspensionsManagedProducts = groupedTradingSuspensionsData?.managedProducts;
      const tradingSuspensions:
        | TradingSuspensionsData[]
        | undefined = groupedTradingSuspensionsManagedProducts?.products.reduce(
        (acc: TradingSuspensionsData[], managedProduct: GroupedTradingSuspensionManagedProduct) => {
          managedProduct.tradingSuspensions.forEach((suspension, index) => {
            acc.push({
              ...suspension,
              groupingIndex: index,
              managedProduct,
            });
          });
          return acc;
        },
        [],
      );

      const tradingSuspendedTableContent = getTableContent(
        {
          commentColumn,
          contentOptions,
          currentUser,
          refetchTradingSuspendedData: refetchGroupedTradingSuspendedData,
        },
        tradingTableContent,
        onClientClick,
        onAccountClick,
        getAccountNumberRedirectUrl,
        getClientNameRedirectUrl,
        onRemoveSuspensionClick,
        showProductName,
        opsDashboardContentData,
        tradingSuspensions,
        activeRowId,
      );
      const total = groupedTradingSuspensionsManagedProducts?.paginationContext.total ?? 0;
      const filterContext = groupedTradingSuspensionsData?.tradingSuspensions.filterContext;

      return {
        ...commonData,
        totalPages: Math.ceil(total / recordsPerPage),
        searchError: groupedTradingSuspensionsDataError?.message === TOO_MANY_SEARCH_RECORDS_ERROR,
        paginationContext: {
          ...paginationContext,
          totalItems: total,
        },
        filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
        tradingSuspendedTableContent,
      };
    } else {
      const filterContext = tradingSuspendedData?.tradingSuspensions.filterContext;
      const tradingSuspendedTableContent = getTableContent(
        {
          commentColumn,
          contentOptions,
          currentUser,
          refetchTradingSuspendedData,
        },
        tradingTableContent,
        onClientClick,
        onAccountClick,
        getAccountNumberRedirectUrl,
        getClientNameRedirectUrl,
        onRemoveSuspensionClick,
        showProductName,
        opsDashboardContentData,
        tradingSuspendedData?.tradingSuspensions.tradingSuspensions,
        activeRowId,
      );
      const total = tradingSuspendedData?.tradingSuspensions.paginationContext.total ?? 0;

      return {
        ...commonData,
        totalPages: Math.ceil(total / recordsPerPage),
        paginationContext: {
          ...paginationContext,
          totalItems: total,
        },
        filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
        tradingSuspendedTableContent,
        searchError: tradingSuspendedDataError?.message === TOO_MANY_SEARCH_RECORDS_ERROR,
      };
    }
  }, [
    opsContentData?.all_ops_dashboard?.items,
    currentPage,
    recordsPerPage,
    updateRecordsPerPage,
    appliedFilters,
    filtersPopupOpen,
    tradingTableContent,
    contentLoading,
    handleGroupAccountToggle,
    isGroupAccountToggleChecked,
    onPageChange,
    onRowHover,
    onSearchChange,
    onAppliedFiltersSelect,
    searchFilter,
    onFilterChange,
    refetchGroupedTradingSuspendedData,
    refetchTradingSuspendedData,
    sortConfig,
    tableColumns,
    updateOpenFiltersPopup,
    groupedTradingSuspensionsData,
    tradingSuspendedData,
    commentColumn,
    contentOptions,
    currentUser,
    onClientClick,
    onAccountClick,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
    onRemoveSuspensionClick,
    showProductName,
    opsDashboardContentData,
    tradingSuspendedDataError,
    groupedTradingSuspensionsDataError,
    activeRowId,
  ]);

  const error: Error | undefined = useMemo(() => {
    const dataError = isGroupAccountToggleChecked ? tradingSuspendedDataError : groupedTradingSuspensionsDataError;

    return dataError ?? contentStackError ?? opsDashboardContentError;
  }, [
    groupedTradingSuspensionsDataError,
    contentStackError,
    isGroupAccountToggleChecked,
    tradingSuspendedDataError,
    opsDashboardContentError,
  ]);

  return {
    data,
    error,
    loading: contentLoading || OpsContentLoading || groupedTradingSuspensionsDataLoading || tradingSuspendedDataLoading,
  };
};
