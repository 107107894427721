import React, { useMemo, useState } from 'react';

import { LegalDocument, PlanUpdateWorkflow } from '../../../../symphony';
import { PendingChangeCard, PendingChangeContent } from '../PendingChangeCard';

import { AssociatedEntityType, PlanUpdateWorkflowStatus } from '~/__generated__';
import { ApproveModelChangeModal } from '~/components/modals/ApproveModelChange';
import { DiscardPendingChange } from '~/components/modals/DiscardPendingChange';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Snackbar } from '~/components/ui/Snackbar';
import { useResendEnvelopeToNextRecipient } from '~/hooks/docusign/symphony';
import { useUpdatePlanUpdateWorkflow } from '~/hooks/plan/symphony';
import {
  getLegalDocumentForPendingModelPortfolioUpdate,
  getNextPendingSignee,
  isPendingPlanUpdateWorkflowStatus,
  nonPendingLegalDocumentStatuses,
} from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  content: PendingChangeContent;
  contentOptions: ContentOptions;
  dataQa?: string;
  isApproveEnabled?: boolean;
  legalDocuments?: LegalDocument[];
  managedProductId: string;
  partyIdFA?: string;
  planId: string;
  planUpdateWorkflows?: PlanUpdateWorkflow[];
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  refetchAccounts?: () => Promise<any>;
  refetchingAccounts?: boolean;
  viewerPartyId: string;
}

export const PendingModelChange: React.FC<Props> = ({
  content,
  contentOptions,
  dataQa,
  managedProductId,
  partyIdFA,
  isApproveEnabled,
  planUpdateWorkflows,
  legalDocuments = [],
  planId,
  redirectToSignDocuments,
  refetchAccounts,
  refetchingAccounts,
  viewerPartyId,
}) => {
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [resendEnvelopeToNextRecipient] = useResendEnvelopeToNextRecipient();
  const [updatePlanUpdateWorkflow] = useUpdatePlanUpdateWorkflow();

  const { open, onClose, openModal } = useModalState();
  const {
    open: isApproveModelChangeModalOpen,
    onClose: onCloseApproveModelChangeModal,
    openModal: openApproveModelChangeModal,
  } = useModalState();

  const refetchItems = async () => {
    if (open) {
      onClose();
    }
    await refetchAccounts?.();
    if (isApproveModelChangeModalOpen) {
      onCloseApproveModelChangeModal();
    }
  };

  const resendDocusign = async (signingDocumentId: string) => {
    try {
      await resendEnvelopeToNextRecipient({
        variables: { signingDocumentId },
      });
      setShowSnackBar(true);
    } catch (err) {
      console.error(`Error Sending Docusign to Client`, err);
    }
  };

  const { allowDiscard, cta, ctaText, pendingPUWId, date } = useMemo(() => {
    const planUpdateWorkFlowId = planUpdateWorkflows?.find(p => isPendingPlanUpdateWorkflowStatus(p.status))?.id;
    const legalDocument = getLegalDocumentForPendingModelPortfolioUpdate(legalDocuments, planUpdateWorkFlowId);
    const nextPendingSignee = getNextPendingSignee(legalDocument?.signees ?? []);

    if (
      planUpdateWorkflows?.find(puw => puw.status === PlanUpdateWorkflowStatus.DOCS_PREPARED) &&
      legalDocument &&
      !nonPendingLegalDocumentStatuses.includes(legalDocument.status)
    ) {
      if (nextPendingSignee?.partyId === viewerPartyId) {
        return {
          allowDiscard: true,
          cta: () => {
            if (redirectToSignDocuments) {
              redirectToSignDocuments(
                managedProductId,
                {
                  entityId: planUpdateWorkFlowId ?? '',
                  entityType: AssociatedEntityType.PLAN_UPDATE_WORKFLOW,
                },
                true,
              );
            } else {
              console.error('Error: Redirect To Sign Docusign is missing');
            }
          },
          ctaText: content.cta.signDocuments,
          date: legalDocument.created,
          pendingPUWId: planUpdateWorkFlowId,
        };
      } else {
        return {
          allowDiscard: !!partyIdFA,
          cta: () => resendDocusign(legalDocument.signingDocumentId),
          ctaText: content.cta.resendDocusign,
          date: legalDocument.created,
          pendingPUWId: planUpdateWorkFlowId,
        };
      }
    }

    if (planUpdateWorkflows?.find(puw => puw.status === PlanUpdateWorkflowStatus.DOCS_SIGNED)) {
      if (isApproveEnabled) {
        return {
          allowDiscard: false,
          cta: openApproveModelChangeModal,
          ctaText: content.cta.approve,
          date: legalDocument?.created,
          pendingPUWId: planUpdateWorkFlowId,
        };
      } else {
        return {
          allowDiscard: false,
          date: legalDocument?.created,
          pendingPUWId: planUpdateWorkFlowId,
        };
      }
    }

    /**
     * This empty return statement may become redudant once we do not allow other FA's to see all existing accounts,
     * for now it ensures if a different FA whose partyID does not match,
     * they will not see pending model change component if FA's approval is left.
     * Can't control Resend document that will show up irrespectively for now.
     */
    return {};
  }, [
    planUpdateWorkflows,
    legalDocuments,
    viewerPartyId,
    partyIdFA,
    content.cta.signDocuments,
    content.cta.resendDocusign,
    content.cta.approve,
    redirectToSignDocuments,
    managedProductId,
    resendDocusign,
    isApproveEnabled,
    openApproveModelChangeModal,
  ]);

  const handleDiscard = async () => {
    await updatePlanUpdateWorkflow({
      variables: {
        planId,
        planUpdateWorkflowId: pendingPUWId ?? '',
        status: PlanUpdateWorkflowStatus.CANCELLED,
      },
    });
  };

  return pendingPUWId ? (
    <PendingChangeCard
      allowDiscard={allowDiscard}
      content={content}
      cta={cta}
      ctaText={ctaText}
      dataQa={dataQa}
      date={date}
      discardCTA={openModal}
      refetchingAccounts={refetchingAccounts}
    >
      <DiscardPendingChange
        content={content.discardPendingChange}
        contentOptions={contentOptions}
        onClose={onClose}
        onDiscard={handleDiscard}
        onDiscardCallback={refetchItems}
        open={open}
      />
      <ApproveModelChangeModal
        contentOptions={contentOptions}
        onApproveCallback={refetchItems}
        onClose={onCloseApproveModelChangeModal}
        open={isApproveModelChangeModalOpen}
        planId={planId}
        planUpdateWorkflowId={pendingPUWId}
      />
      {showSnackBar && (
        <Snackbar
          feedbackMessage={content.resendEmailSentMessage}
          onClose={() => {
            setShowSnackBar(false);
          }}
        />
      )}
    </PendingChangeCard>
  ) : null;
};
