import {
  GetActiveRestrictionsTabContent_all_active_restrictions,
  GetActiveRestrictionsTabContentQuery,
  GetActiveRestrictionsTabContentVariables,
} from './__generated__/getActiveRestrictionsContent.v2';
import * as queries from './getActiveRestrictionsContent.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ActiveRestrictionsTabContent = GetActiveRestrictionsTabContent_all_active_restrictions;

export const useGetActiveRestrictionsTabContent = (
  options?: QueryHookOptions<GetActiveRestrictionsTabContentQuery, GetActiveRestrictionsTabContentVariables>,
): QueryResult<GetActiveRestrictionsTabContentQuery, GetActiveRestrictionsTabContentVariables> => {
  return useContentstackQuery(queries.GetActiveRestrictionsTabContent, options);
};
