import { QueryHookOptions, QueryResult, QueryTuple } from '@apollo/client';

import { GetMarketHolidayList, GetMarketHolidayListVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { useLazySymphonyQuery, useSymphonyQuery } from '~/utils/symphony';

export const useGetMarketHolidayList = (
  options?: QueryHookOptions<GetMarketHolidayList, GetMarketHolidayListVariables>,
): QueryResult<GetMarketHolidayList, GetMarketHolidayListVariables> => {
  return useSymphonyQuery(queries.GetMarketHolidayList, options);
};
export const useLazyGetMarketHolidayList = (
  options?: QueryHookOptions<GetMarketHolidayList, GetMarketHolidayListVariables>,
): QueryTuple<GetMarketHolidayList, GetMarketHolidayListVariables> => {
  return useLazySymphonyQuery(queries.GetMarketHolidayList, options);
};
