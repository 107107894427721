import { compareDesc, parseISO } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { Props } from '.';

import { FinancialAccountStatus, FinancialAccountType } from '~/__generated__';
import { AccountAction } from '~/components/AccountActions';
import { ListItem as DropdownListItem } from '~/components/ui/Dropdown/types';
import { useAccountPropertyContent } from '~/containers/AccountSummary/hooks/useAccountPropertyContent';
import { AccountData } from '~/containers/AccountSummary/types';
import { getActivatedAccountStates } from '~/containers/AccountSummary/utils';
import {
  useAccountDetailsClosedManagedAccounts,
  useAccountDetailsManagedAccounts,
} from '~/hooks/account-details/symphony';
import {
  AccountDetailsManagedAccounts_client_financialAccounts,
  AccountDetailsManagedAccounts_client_financialAccounts_products,
  AccountDetailsManagedAccounts_client_financialAccounts_products_ManagedProduct,
  AccountDetailsManagedAccountsVariables,
} from '~/hooks/account-details/symphony/__generated__/accountDetailsManagedAccounts.v2';
import { getAccountState, isPendingPlanUpdateWorkflowStatus } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { formatMaskedAccountNumber } from '~/utils/format';
import { AsyncResult } from '~/utils/types';

type Data = {
  allAccounts: Pick<AccountData, 'status' | 'program'>[];
  visibleAccounts: DropdownListItem[];
};

type Content = {
  activeAccountLabel: string;
  closedAccountLabel: string;
};

type Variables = AccountDetailsManagedAccountsVariables &
  Pick<Props, 'contentOptions' | 'tradingSuspensionsFilter'> & {
    accountNumberFormat: string;
    content?: Content;
    seeDetailsAccountAction?: AccountAction;
    showClosedAccounts?: boolean;
  };

const isFinancialAccountManagedProduct = (
  product: AccountDetailsManagedAccounts_client_financialAccounts_products,
): product is AccountDetailsManagedAccounts_client_financialAccounts_products_ManagedProduct =>
  product.__typename === 'ManagedProduct';

const getVisibleManagedAccount = ({
  financialAccount,
  isSuspendedAccountStateSupported,
  seeDetailsAccountAction,
  tradingSuspensionsFilter,
}: Pick<Variables, 'seeDetailsAccountAction' | 'tradingSuspensionsFilter'> & {
  financialAccount: AccountDetailsManagedAccounts_client_financialAccounts;
  isSuspendedAccountStateSupported?: boolean;
}): AccountDetailsManagedAccounts_client_financialAccounts_products_ManagedProduct | undefined =>
  financialAccount.products?.filter(isFinancialAccountManagedProduct).find(managedProduct => {
    const { state: accountState, data: accountStateData } = getAccountState({
      financialAccountStatus: managedProduct.status,
      hasPendingPlanUpdateWorkflow: managedProduct.planUpdateWorkflows.some(p =>
        isPendingPlanUpdateWorkflowStatus(p?.status),
      ),
      firstRebalancedOn: managedProduct.firstRebalancedOn ?? undefined,
      suspendedOn: managedProduct.tradingSuspensions
        .filter(suspension => tradingSuspensionsFilter?.(suspension) ?? true)
        .sort((a, b) => {
          return compareDesc(parseISO(a.createdAt), parseISO(b.createdAt));
        })[0]?.createdAt,
      isSuspendedAccountStateSupported,
    });

    return seeDetailsAccountAction?.valid
      ? seeDetailsAccountAction.valid({
          state: accountState,
          stateData: accountStateData,
        })
      : getActivatedAccountStates().includes(accountState);
  });

export const useVisibleManagedAccounts = ({
  accountNumberFormat,
  contentOptions,
  partyId,
  seeDetailsAccountAction,
  tradingSuspensionsFilter,
  content,
  showClosedAccounts = true,
}: Variables): AsyncResult<Data> => {
  const {
    featureFlags: { isSuspendedAccountStateSupported },
    components: {
      sfAccountDetails: { showClosedAccountsInDropdown },
    },
  } = useCoreConfig();

  const {
    data: managedAccounts,
    error: managedAccountsError,
    loading: managedAccountsLoading,
  } = useAccountDetailsManagedAccounts({
    variables: { partyId },
  });

  const {
    data: accountTypeParser,
    error: accountTypeParserError,
    loading: accountTypeParserLoading,
  } = useAccountPropertyContent({ contentOptions });

  const {
    data: closedManagedAccounts,
    error: closedManagedAccountsError,
    loading: closedManagedAccountsLoading,
  } = useAccountDetailsClosedManagedAccounts({
    variables: { partyIds: [partyId ?? ''] },
    skip: !showClosedAccountsInDropdown || !showClosedAccounts,
  });

  const getVisibleManagedAccountMemoized = useCallback(
    (financialAccount: AccountDetailsManagedAccounts_client_financialAccounts) =>
      getVisibleManagedAccount({
        financialAccount,
        isSuspendedAccountStateSupported,
        seeDetailsAccountAction,
        tradingSuspensionsFilter,
      }),
    [isSuspendedAccountStateSupported, seeDetailsAccountAction, tradingSuspensionsFilter],
  );

  const visibleAccountsData = useMemo(() => {
    if (!managedAccounts?.client?.financialAccounts || !accountTypeParser) {
      return null;
    }

    const financialAccounts = managedAccounts.client.financialAccounts;

    const managedAccountOptions = financialAccounts.reduce<DropdownListItem[]>((acc, financialAccount) => {
      const visibleManagedAccount = getVisibleManagedAccountMemoized(financialAccount);

      if (visibleManagedAccount) {
        acc.push({
          label: `${accountTypeParser.getAccountTypeText(
            visibleManagedAccount.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
          )} ${formatMaskedAccountNumber(accountNumberFormat, financialAccount.maskedAccountNumber)}`,
          value: visibleManagedAccount.id,
        });
      }
      return acc;
    }, []);

    const closedManagedAccountOptions: DropdownListItem[] = showClosedAccounts
      ? closedManagedAccounts?.managedProducts.products.map(
          (closedFinancialAccount): DropdownListItem => ({
            label: formatMaskedAccountNumber(accountNumberFormat, closedFinancialAccount.maskedClosedAccountNumber),
            value: closedFinancialAccount.id,
          }),
        ) ?? []
      : [];

    const visibleAccounts: DropdownListItem[] = [];

    if (managedAccountOptions.length && closedManagedAccountOptions.length && showClosedAccounts) {
      visibleAccounts.push({
        label: content?.activeAccountLabel ?? '',
        type: 'list-item-header',
      });
    }

    visibleAccounts.push(...managedAccountOptions);

    if (closedManagedAccountOptions.length && showClosedAccounts) {
      visibleAccounts.push({
        label: content?.closedAccountLabel ?? '',
        type: 'list-item-header',
      });
      visibleAccounts.push(...closedManagedAccountOptions);
    }

    const allAccounts = financialAccounts.reduce<Pick<AccountData, 'status' | 'program'>[]>(
      (accountList, financialAccount) => {
        financialAccount.products?.filter(isFinancialAccountManagedProduct)?.forEach(product =>
          accountList.push({
            status: FinancialAccountStatus[product.status],
            program: product.program,
          }),
        );
        return accountList;
      },
      [],
    );

    return { allAccounts, visibleAccounts };
  }, [
    managedAccounts,
    accountTypeParser,
    closedManagedAccounts,
    accountNumberFormat,
    content,
    getVisibleManagedAccountMemoized,
  ]);

  const result = useMemo<AsyncResult<Data>>(() => {
    if (managedAccountsLoading || accountTypeParserLoading || closedManagedAccountsLoading) {
      return { loading: true };
    }
    if (managedAccountsError || accountTypeParserError || closedManagedAccountsError) {
      return { loading: false, error: managedAccountsError ?? closedManagedAccountsError ?? accountTypeParserError };
    }
    if (visibleAccountsData) {
      return { data: visibleAccountsData, loading: false };
    }
    return { loading: false };
  }, [
    managedAccountsLoading,
    accountTypeParserLoading,
    closedManagedAccountsLoading,
    managedAccountsError,
    accountTypeParserError,
    closedManagedAccountsError,
    visibleAccountsData,
  ]);

  return result;
};
