export type ProductName = {
  __typename: 'ProductName';
  key: string | null;
  long_text: string | null;
  text: string | null;
};

export type AccountType = {
  __typename: 'AccountType';
  key: string | null;
  text: string | null;
};

export type AccountTypeAndProductMock = {
  all_account_type: { __typename: 'AllAccountType'; items: (AccountType | null)[] };
  all_product_name: { __typename: 'AllProductName'; items: (ProductName | null)[] };
};

export type AccountNonBooleanAttribute = {
  __typename: 'StringAttribute' | 'DecimalAttribute' | 'IntegerAttribute';
  /**
   * The name of the property
   */
  name: string;
};

export type AccountBooleanAttribute = {
  __typename: 'BooleanAttribute';
  /**
   * The value of the property
   */
  booleanValue: boolean;
  /**
   * The name of the property
   */
  name: string;
};

export type AccountAttribute = AccountNonBooleanAttribute | AccountBooleanAttribute;

export interface BankAccountNonStringAttribute {
  __typename: 'DecimalAttribute' | 'IntegerAttribute' | 'BooleanAttribute';
  /**
   * The name of the property
   */
  name: string;
}

export interface BankAccountStringAttribute {
  __typename: 'StringAttribute';
  /**
   * The name of the property
   */
  name: string;
  /**
   * The value of the property
   */
  stringValue: string;
}

export type BankAccountAttribute = BankAccountNonStringAttribute | BankAccountStringAttribute;

export const savingsOptimizerAccountProgramContentKey = 'SAVE_AND_GROW';
