import { useEffect, useMemo, useState } from 'react';

import { useGetOpsDashboardContent } from '../../contentstack';
import { useGetErrorsTableContent } from '../contentstack';
import { ErrorsStatusFilters } from '../ErrorsStatusFilter';
import { useGetErrors } from '../symphony';
import { errorEntityTypes, ErrorsTabData, ErrorsTabVariables } from '../types';

import { Field } from '~/__generated__';
import { NetworkStatus } from '~/utils/apollo-client';
import { AsyncResult } from '~/utils/types';

export const sortFieldToSymphony = new Map([['dateCreated', Field.CREATED]]);
// Set 10 as constant first until we have requirements to change it
export const recordsPerPage = 10;

export const useGetErrorsTable = ({
  contentOptions,
  pageNumber,
  sortConfig,
  statusFilter,
}: ErrorsTabVariables): AsyncResult<ErrorsTabData> => {
  const [state, setState] = useState<ErrorsTabData>();
  const variables = useMemo(
    () => ({
      pagination: {
        limit: recordsPerPage,
        offset: (pageNumber - 1) * recordsPerPage,
      },
      entities: errorEntityTypes,
      ...sortConfig,
      field: sortFieldToSymphony.get(sortConfig.field) || Field.CREATED,
      ...(statusFilter !== ErrorsStatusFilters.ALL && {
        isResolved: statusFilter === ErrorsStatusFilters.RESOLVED ? true : false,
      }),
    }),
    [pageNumber, sortConfig, statusFilter],
  );
  // The reason for no-cache is due to the updating the list when ever we create or resolve flag for a closure, the flagged and unflagged closures table should be updated.
  // Otherwise, instead of the table showing the current flagged and unflagged closures data it shows the previous cached data. There is no easy way to update the cache to reflect the correct state right now.
  const {
    data: errorsData,
    error: errorsDataError,
    loading: errorsDataLoading,
    networkStatus: errorsDataNetworkStatus,
    refetch: refetchErrorsData,
  } = useGetErrors({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables,
  });

  const { data: contentData, loading: contentLoading, error: contentError } = useGetErrorsTableContent({
    variables: contentOptions,
  });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContent({
    variables: contentOptions,
  });

  const error = errorsDataError || contentError || opsDashboardContentError;
  const loading = errorsDataLoading || contentLoading || opsDashboardContentLoading;

  useEffect(() => {
    if (!loading && !error) {
      refetchErrorsData?.();
    }
  }, [pageNumber]);

  const data = useMemo(() => {
    if (loading || error) {
      return { ...state, isRefetchingErrorsData: errorsDataNetworkStatus === NetworkStatus.refetch };
    }
    const accountTypeContent = opsDashboardContentData?.all_account_type?.items || [];
    const productNameContent = opsDashboardContentData?.all_product_name?.items || [];
    const stateData = {
      refetchErrorsData,
      isRefetchingErrorsData: errorsDataNetworkStatus === NetworkStatus.refetch,
      accountTypeContent,
      contentData: contentData?.all_errors_table?.items?.[0],
      errorsData,
      productNameContent,
    };
    setState(stateData);
    return stateData;
  }, [errorsData, errorsDataLoading, contentLoading, contentData, opsDashboardContentLoading, opsDashboardContentData]);

  return { data, error, loading };
};
