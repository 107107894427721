import {
  GetAllAccountsData,
  GetAllAccountsData_managedProducts_filterContext,
  GetAllAccountsData_managedProducts_products,
  GetAllAccountsDataVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type AccountManagedProducts = GetAllAccountsData_managedProducts_products;
export type AllAccountsFilterCount = GetAllAccountsData_managedProducts_filterContext;

export const useGetAllAccountsData = (
  options?: QueryHookOptions<GetAllAccountsData, GetAllAccountsDataVariables>,
): QueryResult<GetAllAccountsData, GetAllAccountsDataVariables> => {
  return useSymphonyQuery(queries.GetAllAccountsData, options);
};
