import React, { useCallback, useMemo, useState } from 'react';

import { isAssetTransfer, LegalDocument, ScheduledTransfer } from '../../../../symphony';
import { PendingChangeCard } from '../PendingChangeCard';

import {
  AssociatedEntityType,
  LegalDocumentStatus,
  ScheduledTransferStatus,
  TransferMethodSubType,
} from '~/__generated__';
import { DiscardPendingChange } from '~/components/modals/DiscardPendingChange';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Grid } from '~/components/ui/mui';
import { Snackbar } from '~/components/ui/Snackbar';
import { PendingChangeCardContent } from '~/containers/AccountSummary/utils';
import { useResendEnvelopeToNextRecipient, useUpdateSigningDocument } from '~/hooks/docusign/symphony';
import {
  getLegalDocumentForPendingAssetTransfers,
  getNextPendingSignee,
  nonPendingLegalDocumentStatuses,
} from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  content: {
    journaling: PendingChangeCardContent;
    regularTransfers: PendingChangeCardContent;
  };
  contentOptions: ContentOptions;
  dataQa?: string;
  legalDocuments?: LegalDocument[];
  managedProductId: string;
  partyIdFA?: string;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  refetchAccounts?: () => Promise<any>;
  refetchingAccounts?: boolean;
  scheduledTransfer?: ScheduledTransfer[];
  viewerPartyId: string;
}

interface PendingAssetTransfersDocusignData {
  allowDiscard: boolean;
  content: PendingChangeCardContent;
  cta: () => void;
  ctaText: string;
  date: string;
  isJournaling: boolean;
  signingDocumentId: string;
}

export const PendingAssetTransfersDocusign: React.FC<Props> = ({
  content,
  contentOptions,
  dataQa,
  partyIdFA,
  legalDocuments = [],
  managedProductId,
  redirectToSignDocuments,
  refetchAccounts,
  refetchingAccounts,
  scheduledTransfer,
  viewerPartyId,
}) => {
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = useState<string>();
  const [resendEnvelopeToNextRecipient] = useResendEnvelopeToNextRecipient();
  const [updateSigningDocument] = useUpdateSigningDocument();
  const [selectedCardIsJournaling, setSelectedCardIsJournaling] = useState<boolean>();
  const { open, onClose, openModal } = useModalState();

  const refetchItems = async () => {
    if (open) {
      onClose();
    }
    await refetchAccounts?.();
  };

  const resendDocusign = useCallback(
    async (id: string) => {
      try {
        await resendEnvelopeToNextRecipient({
          variables: { signingDocumentId: id },
        });
        setShowSnackBar(true);
      } catch (err) {
        console.error(`Error Sending Docusign to Client`, err);
      }
    },
    [resendEnvelopeToNextRecipient],
  );

  const cards = useMemo((): PendingAssetTransfersDocusignData[] => {
    const assetTransfers =
      scheduledTransfer
        ?.filter(isAssetTransfer)
        .filter(item => item.status === ScheduledTransferStatus.PENDING)
        ?.map(item => ({
          transferId: item.id,
          isJournaling: item.methodSubType === TransferMethodSubType.JOURNAL,
        })) ?? [];

    return assetTransfers.reduce((pendingCards: PendingAssetTransfersDocusignData[], assetTransfer) => {
      const legalDocument = getLegalDocumentForPendingAssetTransfers(legalDocuments, assetTransfer.transferId);
      const nextPendingSignee = getNextPendingSignee(legalDocument?.signees ?? []);
      const assetTransferContent = assetTransfer.isJournaling ? content.journaling : content.regularTransfers;
      if (legalDocument && !nonPendingLegalDocumentStatuses.includes(legalDocument.status)) {
        if (nextPendingSignee?.partyId === viewerPartyId) {
          pendingCards.push({
            content: assetTransferContent,
            allowDiscard: true,
            cta: () => {
              if (redirectToSignDocuments) {
                redirectToSignDocuments(
                  managedProductId,
                  {
                    entityId: assetTransfer.transferId,
                    entityType: AssociatedEntityType.ASSET_DEPOSIT,
                  },
                  true,
                );
              } else {
                console.error('Error: Redirect To Sign Docusign is missing');
              }
            },
            isJournaling: assetTransfer.isJournaling,
            ctaText: assetTransferContent.cta.signDocuments,
            date: legalDocument.created,
            signingDocumentId: legalDocument.signingDocumentId,
          });
        } else {
          pendingCards.push({
            content: assetTransferContent,
            allowDiscard: !!partyIdFA,
            cta: () => resendDocusign(legalDocument.signingDocumentId),
            ctaText: assetTransferContent.cta.resendDocusign,
            isJournaling: assetTransfer.isJournaling,
            date: legalDocument.created,
            signingDocumentId: legalDocument.signingDocumentId,
          });
        }
      }
      return pendingCards;
    }, []);
  }, [
    scheduledTransfer,
    legalDocuments,
    partyIdFA,
    managedProductId,
    viewerPartyId,
    content,
    redirectToSignDocuments,
    resendDocusign,
  ]);

  const handleDiscardCTA = (signingDocumentId: string) => {
    setSelectedCardId(signingDocumentId);
    openModal();
  };

  const handleDiscard = async () => {
    if (selectedCardId) {
      await updateSigningDocument({
        variables: {
          signingDocumentId: selectedCardId,
          status: LegalDocumentStatus.DECLINED,
        },
      });
      setSelectedCardId(undefined);
    }
  };

  const handleOnClose = () => {
    setSelectedCardId(undefined);
    onClose();
  };

  return cards.length ? (
    <>
      <Grid item xs={12}>
        {cards.map((card, index) => (
          <Box key={card.signingDocumentId} pb={index === cards.length - 1 ? 0 : 2}>
            <PendingChangeCard
              allowDiscard={card.allowDiscard}
              content={card.content}
              cta={() => {
                setSelectedCardIsJournaling(card.isJournaling);
                card.cta();
              }}
              ctaText={card.ctaText}
              dataQa={dataQa}
              date={card.date}
              discardCTA={() => {
                setSelectedCardIsJournaling(card.isJournaling);
                handleDiscardCTA(card.signingDocumentId);
              }}
              refetchingAccounts={refetchingAccounts}
            />
          </Box>
        ))}
      </Grid>
      {showSnackBar && (
        <Snackbar
          feedbackMessage={
            selectedCardIsJournaling
              ? content.journaling.resendEmailSentMessage
              : content.regularTransfers.resendEmailSentMessage
          }
          onClose={() => {
            setShowSnackBar(false);
          }}
        />
      )}
      <DiscardPendingChange
        content={
          selectedCardIsJournaling
            ? content.journaling.discardPendingChange
            : content.regularTransfers.discardPendingChange
        }
        contentOptions={contentOptions}
        onClose={handleOnClose}
        onDiscard={handleDiscard}
        onDiscardCallback={refetchItems}
        open={open}
      />
    </>
  ) : null;
};
