import { GetSuspendBillingModalContent, GetSuspendBillingModalContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetSuspendBillingModalContent = (
  options?: QueryHookOptions<GetSuspendBillingModalContent, GetSuspendBillingModalContentVariables>,
) => {
  return useContentstackQuery(queries.GetSuspendBillingModalContent, options);
};
