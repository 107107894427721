import { TransferStatus, TransferStatusFilter } from './types';

import { ScheduledTransferStatus, TransferFrequency } from '~/__generated__';

export function toCashTransferStatus(status: ScheduledTransferStatus): TransferStatus {
  switch (status) {
    // ACTIVE/IN_PROGRESS:
    case ScheduledTransferStatus.ACTIVE:
    case ScheduledTransferStatus.ACTIVE_AT_BROKERAGE:
    case ScheduledTransferStatus.SUBMITTED:
    case ScheduledTransferStatus.INSUFFICIENT_ASSETS:
    case ScheduledTransferStatus.UNAUTHORIZED:
    case ScheduledTransferStatus.TRADES_SUBMITTED:
    case ScheduledTransferStatus.CASH_GENERATED:
      return TransferStatus.IN_PROGRESS;
    // PENDING:
    case ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE:
    case ScheduledTransferStatus.PENDING:
      return TransferStatus.PENDING;
    // COMPLETED:
    case ScheduledTransferStatus.COMPLETED:
      return TransferStatus.COMPLETED;
    // PENDING_CANCELLATION
    case ScheduledTransferStatus.PENDING_CANCELLATION:
      return TransferStatus.PENDING_CANCELLATION;
    // CANCELLED:
    case ScheduledTransferStatus.CANCELLED:
      return TransferStatus.CANCELLED;
    default:
      return TransferStatus.UNRECOGNIZED;
  }
}

export const cashTransferStatusToScheduledTransferStatusForUpdate = (
  status: TransferStatus,
  frequency: TransferFrequency,
): ScheduledTransferStatus | null => {
  const recurring = frequency !== TransferFrequency.ONE_TIME;
  if (!recurring) {
    switch (status) {
      case TransferStatus.CANCELLED:
        return ScheduledTransferStatus.CANCELLED;
      case TransferStatus.COMPLETED:
        return ScheduledTransferStatus.COMPLETED;
      default:
        return null;
    }
  } else {
    switch (status) {
      case TransferStatus.CANCELLED:
        return ScheduledTransferStatus.CANCELLED;
      case TransferStatus.COMPLETED:
        return ScheduledTransferStatus.COMPLETED;
      case TransferStatus.IN_PROGRESS:
        return ScheduledTransferStatus.ACTIVE;
      case TransferStatus.PENDING_CANCELLATION:
        return ScheduledTransferStatus.PENDING_CANCELLATION;
      default:
        return null;
    }
  }
  return null;
};

export function cashTransferToAllowedStatusUpdates(status: TransferStatus, recurring = false): TransferStatus[] {
  if (!recurring) {
    switch (status) {
      case TransferStatus.PENDING:
      case TransferStatus.IN_PROGRESS:
        return [TransferStatus.CANCELLED, TransferStatus.COMPLETED];
      case TransferStatus.PENDING_CANCELLATION:
        return [TransferStatus.CANCELLED];
      default:
        return [];
    }
  } else {
    switch (status) {
      case TransferStatus.PENDING:
        return [TransferStatus.IN_PROGRESS, TransferStatus.CANCELLED];
      case TransferStatus.IN_PROGRESS:
        return [TransferStatus.CANCELLED];
      case TransferStatus.PENDING_CANCELLATION:
        return [TransferStatus.CANCELLED];
      default:
        return [];
    }
  }
  return [];
}

export function statusFilterToScheduledTransferStatus(statusFilter: string[]): ScheduledTransferStatus[] | null {
  return Array.from(
    statusFilter.reduce((acc, status) => {
      const transferStatus = Object.values(TransferStatusFilter).find(value => value === status);
      switch (transferStatus) {
        case TransferStatusFilter.PENDING_IN_PROGRESS:
          [
            ScheduledTransferStatus.PENDING,
            ScheduledTransferStatus.ACTIVE,
            ScheduledTransferStatus.ACTIVE_AT_BROKERAGE,
            ScheduledTransferStatus.SUBMITTED,
            ScheduledTransferStatus.INSUFFICIENT_ASSETS,
            ScheduledTransferStatus.UNAUTHORIZED,
            ScheduledTransferStatus.TRADES_SUBMITTED,
            ScheduledTransferStatus.CASH_GENERATED,
            ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE,
          ].forEach(value => acc.add(value));
          break;
        case TransferStatusFilter.PENDING:
          [ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE, ScheduledTransferStatus.PENDING].forEach(value =>
            acc.add(value),
          );
          break;
        case TransferStatusFilter.PENDING_CANCELLATION:
          acc.add(ScheduledTransferStatus.PENDING_CANCELLATION);
          break;
        case TransferStatusFilter.IN_PROGRESS:
          [
            ScheduledTransferStatus.ACTIVE,
            ScheduledTransferStatus.ACTIVE_AT_BROKERAGE,
            ScheduledTransferStatus.SUBMITTED,
            ScheduledTransferStatus.INSUFFICIENT_ASSETS,
            ScheduledTransferStatus.UNAUTHORIZED,
            ScheduledTransferStatus.TRADES_SUBMITTED,
            ScheduledTransferStatus.CASH_GENERATED,
          ].forEach(value => acc.add(value));
          break;
        case TransferStatusFilter.CANCELLED:
          acc.add(ScheduledTransferStatus.CANCELLED);
          break;
        case TransferStatusFilter.COMPLETED:
          acc.add(ScheduledTransferStatus.COMPLETED);
          break;
        default:
      }
      return acc;
    }, new Set<ScheduledTransferStatus>()),
  );
}
