import React, { useMemo } from 'react';

import { Drawer } from '~/components/ui/Drawer';
import { Button, ChevronLeftIcon, Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useAccountDrawerContentLabels } from '~/containers/AccountsDrawer/hooks';
import { DrawerInstance } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';

interface Props {
  contentOptions: ContentOptions;
  currentDrawerComponent: React.ReactNode;
  currentDrawerInstance: DrawerInstance;
  onBackClick: () => void;
  onClose: () => void;
}

export const AccountsDrawer: React.FC<Props> = ({
  contentOptions,
  onClose,
  onBackClick,
  currentDrawerInstance,
  currentDrawerComponent,
}) => {
  const { data } = useAccountDrawerContentLabels({ contentOptions });

  const backLabel = useMemo(() => {
    switch (currentDrawerInstance) {
      case DrawerInstance.ACCOUNT_SUMMARY:
        return '';
      case DrawerInstance.ACCOUNT_PERFORMANCE:
        return data?.backToAccountDetailsLabel ?? '';
      case DrawerInstance.ACCOUNT_DETAIL:
        return data?.backToAccountSummaryLabel ?? '';
    }
  }, [currentDrawerInstance, data]);

  return (
    <Drawer
      onClose={onClose}
      open={!!currentDrawerInstance}
      sx={{
        '& .MuiDrawer-paper': {
          height: '95vh',
          overflowY: 'hidden',
        },
      }}
      title={data?.heading}
    >
      {!!backLabel && (
        <Grid item sx={{ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: 'secondary.main' }}>
          <Button
            onClick={onBackClick}
            startIcon={<ChevronLeftIcon fontSize="small" sx={{ color: 'primary.main' }} />}
            sx={{ m: 1 }}
          >
            <Typography sx={{ color: 'text.primary' }} variant="body2">
              {backLabel}
            </Typography>
          </Button>
        </Grid>
      )}
      {currentDrawerComponent}
    </Drawer>
  );
};

export default AccountsDrawer;
