import {
  GetOtherActionsModalContent,
  GetOtherActionsModalContent_all_other_actions_modal_items,
  GetOtherActionsModalContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type OtherActionsModalContent = GetOtherActionsModalContent_all_other_actions_modal_items;

export const useGetOtherActionsModalContent = (
  options?: QueryHookOptions<GetOtherActionsModalContent, GetOtherActionsModalContentVariables>,
): QueryResult<GetOtherActionsModalContent, GetOtherActionsModalContentVariables> => {
  return useContentstackQuery(queries.GetOtherActionsModalContent, options);
};
