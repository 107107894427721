import { useEffect, useState } from 'react';

import { useGetSuspendBillingModalContent } from '../contentstack';
import { useGetBillingRestrictedData, useRestrictBilling, useUnrestrictBilling } from '../symphony';

import { NetworkStatus } from '~/utils/apollo-client';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface SuspendBillingHookVariables {
  contentOptions: ContentOptions;
  managedProductId: string;
  open: boolean;
}

export interface SuspendBillingModalData {
  content: {
    body: {
      activeText: string;
      manageSuspensionText: string;
      subtext: string;
      suspendedText: string;
    };
    title: string;
  };
  handleBillingSuspensionUpdate: (isBillingSuspended: boolean) => Promise<void>;
  isBillingSuspended: boolean;
  isBusy: boolean;
}

export const useSuspendBillingData = ({
  contentOptions,
  managedProductId,
  open,
}: SuspendBillingHookVariables): AsyncResult<SuspendBillingModalData> => {
  const [state, setState] = useState<AsyncResult<SuspendBillingModalData>>({
    loading: true,
    error: undefined,
    data: undefined,
  });
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [restrictBilling] = useRestrictBilling();
  const [unrestrictBilling] = useUnrestrictBilling();

  const {
    data: billingRestricted,
    loading: billingRestrictedLoading,
    error: billingRestrictedError,
    refetch: refetchBillingRestricted,
    networkStatus: billingRestrictedNetworkStatus,
  } = useGetBillingRestrictedData({
    variables: { managedProductId },
    skip: !open,
  });

  const {
    data: suspendBillingContent,
    error: suspendBillingContentError,
    loading: suspendBillingContentLoading,
  } = useGetSuspendBillingModalContent({
    variables: contentOptions,
    skip: !open,
  });

  const isRefetchingStatus = billingRestrictedNetworkStatus === NetworkStatus.refetch;
  const content = suspendBillingContent?.all_suspend_billing_modal?.items?.[0] ?? undefined;
  const loading = !isRefetchingStatus && (suspendBillingContentLoading || billingRestrictedLoading);
  const error = suspendBillingContentError || billingRestrictedError || errorSaving;

  const handleBillingSuspensionUpdate: (suspendBilling: boolean) => Promise<void> = async (suspendBilling: boolean) => {
    setIsBusy(true);
    const mutationVariables = {
      variables: {
        managedProductId,
      },
    };
    try {
      setErrorSaving(undefined);
      const toggleBillingSuspensionResponse = suspendBilling
        ? await restrictBilling({
            ...mutationVariables,
          })
        : await unrestrictBilling({
            ...mutationVariables,
          });
      if (!toggleBillingSuspensionResponse.data) {
        setErrorSaving(Error('Error saving'));
      } else {
        await refetchBillingRestricted();
      }
    } catch (saveError: any) {
      setErrorSaving(saveError);
    } finally {
      setIsBusy(false);
    }
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    if (error) {
      setState({ loading: false, error });
      return;
    }

    setState({
      loading: false,
      data: {
        content: {
          body: {
            activeText: content?.labels?.find(item => item?.key === 'active_text')?.value ?? '',
            manageSuspensionText:
              content?.redactor_labels?.find(item => item?.key === 'heading_text')?.redactor_value ?? '',
            subtext: content?.labels?.find(item => item?.key === 'subtext')?.value ?? '',
            suspendedText: content?.labels?.find(item => item?.key === 'suspended_text')?.value ?? '',
          },
          title: content?.labels?.find(item => item?.key === 'title')?.value ?? '',
        },
        handleBillingSuspensionUpdate,
        isBillingSuspended: !!billingRestricted?.managedProduct?.billingDataV2?.isBillingRestricted,
        isBusy,
      },
    });
  }, [billingRestricted, content, error, isBusy, loading]);

  return state;
};
