import { GraphQLError, isApolloError } from '~/utils/apollo-client';
import { formatDate } from '~/utils/format/date';
import { SymphonyError } from '~/utils/symphony';

export const extractAttribute = <
  T extends { decimalValue?: string; integerValue?: number; name: string; value?: string }
>(
  attributes: T[],
  type: string,
  valueKey: keyof T,
) => {
  const attributeObject = attributes.find(attribute => attribute && attribute.name === type);
  return attributeObject ? attributeObject[valueKey] : undefined;
};

export const extractStringAttribute = (attributes: Array<{ name: string; value?: string }>, type: string) =>
  extractAttribute(attributes, type, 'value');

export const extractDecimalAttribute = (attributes: Array<{ decimalValue?: string; name: string }>, type: string) =>
  extractAttribute(attributes, type, 'decimalValue');

export const extractIntegerAttribute = (attributes: Array<{ integerValue?: number; name: string }>, type: string) =>
  extractAttribute(attributes, type, 'integerValue') as number | undefined;

export const toDateString = (date: Parameters<typeof formatDate>[0], options?: { locale?: string }): string =>
  formatDate(date, 'yyyy-MM-dd', options);

// TODO: Remove hardcoding for Z and get it based on timezoneOffset
export const toDateTimeString = (date: Date, options?: { locale?: string }): string =>
  `${formatDate(date, 'yyyy-MM-dd HH:mm:ss', options)}Z`;

const isSymphonyError = (graphQLError?: GraphQLError | SymphonyError): graphQLError is SymphonyError =>
  !!graphQLError && 'correlationId' in graphQLError;

export const getSymphonyError = (error?: Error): SymphonyError | undefined => {
  if (error && isApolloError(error) && isSymphonyError(error.graphQLErrors[0])) {
    return error.graphQLErrors[0];
  }
  return undefined;
};

export const isApolloAndSymphonyError = (error?: Error): error is SymphonyError =>
  !!error && isApolloError(error) && isSymphonyError(error.graphQLErrors[0]);
