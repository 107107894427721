import React, { ComponentProps, useMemo } from 'react';

import { Comment, CommentComponentColumn } from '../../common/Comment';
import { LabeledValueField } from '../../common/LabeledValueField';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { getAccountNumberOrAccountTypeString, OpsDashboardUser } from '../../utils';
import { ACATRequestDisplayStrings } from '../content';
import { ACATRequestItem, ACATRequestStatus } from '../hooks';

import { FlagEntity, FlagReason, UserNoteEntityType } from '~/__generated__';
import { NullStateProps } from '~/components/NullState';
import { StatusButton } from '~/components/StatusButton';
import {
  BasicTable,
  SortConfig,
  TableColumn,
  TableComponent as TableComponentType,
  TableData,
} from '~/components/ui/BasicTable';
import { Box } from '~/components/ui/mui';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { getAllowedStatusUpdatesForACATRequest } from '~/containers/OpsDashboard/ACATRequestsTab/hooks/mappers';
import { FlagAction } from '~/containers/OpsDashboard/common/FlagAction';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

export interface AcatRequestsTableProps {
  TableComponent?: TableComponentType;
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentPage: number;
  currentUser: OpsDashboardUser;
  displayStrings: ACATRequestDisplayStrings;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  items: ACATRequestItem[];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onPageChange: (p: number) => void;
  onSelectNewStatusForItem: (item: ACATRequestItem, selectedStatus: ACATRequestStatus) => void;
  onSort: (field: string) => () => void;
  refetchData: () => void;
  sortConfig?: SortConfig;
  totalPages: number;
}

export const ACATRequestsFlagReasons = [
  FlagReason.HIGH_TRANSACTION_VALUE,
  FlagReason.API_FAILURE,
  FlagReason.OVERDUE,
  FlagReason.OTHER,
];

export const SortableFields = ['createdOn'];

export const ACATRequestsTable: React.FC<AcatRequestsTableProps> = ({
  commentColumn,
  contentOptions,
  currentPage,
  currentUser,
  displayStrings,
  items,
  nullStateConfig,
  onPageChange,
  onClientClick,
  onAccountClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  onSelectNewStatusForItem,
  onSort,
  refetchData,
  TableComponent = BasicTable,
  totalPages,
  sortConfig,
}) => {
  const columns: TableColumn[] = useColumns(displayStrings, onSort);
  const formattedData: TableData[] = useFormattedData(
    items,
    currentUser,
    contentOptions,
    displayStrings,
    {
      onAccountClick,
      onClientClick,
      refetchData,
    },
    onSelectNewStatusForItem,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
    commentColumn,
  );
  return (
    <TableComponent
      alignItems="left"
      columns={columns}
      currentPage={currentPage}
      data={formattedData}
      nullStateConfig={nullStateConfig}
      onPageChange={onPageChange}
      showPagination
      sortConfig={sortConfig}
      totalPages={totalPages}
    />
  );
};

function useFormattedData(
  items: ACATRequestItem[],
  currentUser: OpsDashboardUser,
  contentOptions: ContentOptions,
  displayStrings: AcatRequestsTableProps['displayStrings'],
  cb: {
    onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
    onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
    refetchData: () => void;
  },
  onSelectNewStatusForItem: (item: ACATRequestItem, selectedStatus: ACATRequestStatus) => void,
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'],
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'],
  commentColumn?: CommentComponentColumn,
): TableData[] {
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  return useMemo(
    () =>
      items.map(item => ({
        ...item,
        rowKey: item.id,
        clientName: (
          <ClientName
            clientName={item.clientName}
            onClick={() => cb.onClientClick(item.id)}
            redirectUrl={getClientNameRedirectUrl(item.id)}
          />
        ),
        accountNumber: (
          <AccountNumber
            accountNumber={item.accountNumber}
            accountNumberText={getAccountNumberOrAccountTypeString(item.accountNumber, item.accountTypeText)}
            label={showProductName ? item.productName : null}
            onClick={() => cb.onAccountClick(item.partyId, item.managedProductId)}
            redirectUrl={getAccountNumberRedirectUrl(item.id, item.managedProductId)}
          />
        ),
        instructions: (
          <LabeledValueField
            label={
              item.destinationBankAccount
                ? `${displayStrings.transferToAccountNote} ${item.destinationBankAccount}`
                : ''
            }
            value={item.instructions}
          />
        ),
        createdOn: formatDate(item.createdOn),
        status: (
          <StatusButton
            currentStatus={item.status}
            onMenuItemClick={newStatus => onSelectNewStatusForItem(item, newStatus as ACATRequestStatus)}
            statusLabels={displayStrings.status}
            statusUpdateItems={getAllowedStatusUpdatesForACATRequest(item.status)}
          />
        ),
        actions: (
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Comment
              comment={item.comment}
              content={commentColumn}
              contentOptions={contentOptions}
              currentUser={currentUser}
              entity={UserNoteEntityType.ASSET_DEPOSIT}
              entityId={item.id.toString()}
              key={item.id.toString()}
              lastCommentPartyId={item.lastCommentPartyId}
              refetchData={() => cb.refetchData()}
            />
            <FlagAction
              accountId={item.accountNumber}
              accountTypeText={item.accountNumber}
              clientName={item.accountNumber}
              contentOptions={contentOptions}
              entity={FlagEntity.ASSET_DEPOSIT}
              entityId={item.id}
              flagReasons={ACATRequestsFlagReasons}
              flags={item.flags}
              flagsContent={displayStrings.flags}
              onCreate={() => cb.refetchData()}
              onResolve={() => cb.refetchData()}
            />
          </Box>
        ),
      })),
    [
      items,
      getClientNameRedirectUrl,
      showProductName,
      getAccountNumberRedirectUrl,
      displayStrings,
      commentColumn,
      contentOptions,
      currentUser,
      cb,
      onSelectNewStatusForItem,
    ],
  );
}

function useColumns(displayStrings: AcatRequestsTableProps['displayStrings'], onSort?: (field: string) => () => void) {
  return useMemo(
    () =>
      displayStrings.tableHeaders.map(({ key, title }) => {
        return {
          key,
          title,
          onSort: onSort && SortableFields.includes(key) ? onSort : undefined,
        };
      }),
    [displayStrings, onSort],
  );
}
