import { useCallback, useContext, useMemo, useState } from 'react';

import { GetOpsDashboardContentV2 } from '../../contentstack/__generated__/query.v2';
import OpsDashboardContext from '../../OpsDashboardContext';
import {
  getAccountNumberOrAccountTypeString,
  getLastEditDateAndTime,
  getSearchContext,
  getSortedPartnerOpsTradingSuspensions,
} from '../../utils';
import { useGetSigningDocumentsV2 } from '../symphony';
import {
  GetSigningDocumentsV2,
  GetSigningDocumentsV2_signingDocuments_legalDocuments,
  GetSigningDocumentsV2_signingDocuments_legalDocuments_signees,
  GetSigningDocumentsV2Variables,
} from '../symphony/__generated__/query.v2';
import {
  defaultAppliedFilters,
  docusignFilterConfig,
  DocusignFilters,
  DocusignItem,
  DocusignStatus,
  DocusignTabData,
  emailRecipient,
  initialRecordsPerPage,
} from '../types';

import {
  fromDocusignStatusToLegalDocumentStatus,
  fromLegalDocumentStatustoDocusignStatus,
  getFilterCountMap,
} from './mappers';

import { FinancialAccountType, ManagedProductStatus, OrderType, SigningDocumentsField } from '~/__generated__';
import { SelectedFilters } from '~/components/Filters/types';
import { getSelectedOptions } from '~/components/Filters/utils';
import { SortConfig, TableColumn } from '~/components/ui/BasicTable';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { useGetDocusignTabContent } from '~/containers/OpsDashboardV2/DocusignTab/contentstack';
import { GetDocusignTabContent } from '~/containers/OpsDashboardV2/DocusignTab/contentstack/__generated__/query.v2';
import { getFormattedItems } from '~/containers/OpsDashboardV2/DocusignTab/hooks/useFormattedItems';
import { SearchContextLiterals, TOO_MANY_SEARCH_RECORDS_ERROR } from '~/containers/OpsDashboardV2/types';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { ContentstackTableColumn, getPaginationContext, sortColumnsByColumnOrder } from '~/utils/table';
import { AsyncResult } from '~/utils/types';

export const useDocusignTabData = (): AsyncResult<DocusignTabData> => {
  const {
    contentOptions,
    onAccountClick,
    onClientClick,
    getClientNameRedirectUrl,
    getAccountNumberRedirectUrl,
    onParamsUpdate,
    params,
    currentUser,
    opsContentData,
  } = useContext(OpsDashboardContext);

  const state = useMemo(() => {
    let value;
    try {
      value = JSON.parse(params.state || '{}');
    } catch (e) {
      return defaultAppliedFilters;
    }

    return value;
  }, [params]);

  const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
  const [activeRowId, setActiveRowId] = useState<string | undefined>();
  const appliedFilters: SelectedFilters = state.filters || defaultAppliedFilters;
  const currentPage: number = state.currentPage || 1;
  const recordsPerPage: number = state.recordsPerPage || initialRecordsPerPage;
  const searchFilter = state.searchFilter ?? null;
  const setCurrentPage = useCallback(
    (page: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: page }) });
    },
    [onParamsUpdate, params, state],
  );
  const setRecordsPerPage = useCallback(
    (value: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, recordsPerPage: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const setSearchFilter = useCallback(
    (value: string | null) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, searchFilter: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const sortConfig: SortConfig = useMemo(
    () =>
      state.sortConfig || {
        field: 'created',
        order: OrderType.DESCENDING,
      },
    [state],
  );

  const onFilterChange = useCallback(
    (filters: SelectedFilters) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, filters }) });
    },
    [onParamsUpdate, params, state],
  );

  const onAppliedFiltersSelect = useCallback(() => {
    setFiltersPopupOpen(true);
  }, []);

  const updateOpenFiltersPopup = useCallback((value: boolean) => {
    setFiltersPopupOpen(value);
  }, []);

  const onPageChange = useCallback(
    (selectedPage: number) => {
      setCurrentPage(selectedPage);
    },
    [setCurrentPage],
  );

  const onRowHover = useCallback(
    (rowId: string | undefined) => {
      setActiveRowId(rowId);
    },
    [setActiveRowId],
  );

  const updateRecordsPerPage = useCallback(
    (value: number) => {
      setRecordsPerPage(value);
    },
    [setRecordsPerPage],
  );

  const onSearchChange = useCallback(
    (searchText: string | null) => {
      setSearchFilter(searchText);
    },
    [setSearchFilter],
  );

  const { showProductName } = useCoreConfig().components.sfOpsDashboard;

  const { data: contentData, error: contentStackError, loading: contentLoading } = useGetDocusignTabContent({
    variables: contentOptions,
  });

  const statusFilter = getSelectedOptions(appliedFilters[DocusignFilters.STATUS]) ?? [];
  const variables: GetSigningDocumentsV2Variables = {
    fetchLatestNote: true,
    statuses: fromDocusignStatusToLegalDocumentStatus(statusFilter as DocusignStatus[]),
    field: SigningDocumentsField.CREATED,
    order: sortConfig.order,
    limit: recordsPerPage,
    searchFilter,
    offset: (currentPage - 1) * recordsPerPage,
  };

  const {
    data: docusignData,
    loading: docusignLoading,
    error: docusignError,
    refetch: refetchDocusignData,
  } = useGetSigningDocumentsV2({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables,
  });

  const data: DocusignTabData = useMemo(() => {
    const onSort = () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      onParamsUpdate({
        ...params,
        state: JSON.stringify({ ...state, currentPage: 1, sortConfig: { field: 'created', order: toggleOrder } }),
      });
    };
    const tableColumns: TableColumn[] = getTableColumns(contentData, onSort);

    const items = contentData?.all_docusign_tab?.items?.[0];
    const textFields = items?.fields?.text ?? [];
    const fields = opsContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const total = docusignData?.signingDocuments.paginationContext.total ?? 0;
    const paginationContext = getPaginationContext(fields, currentPage, recordsPerPage, total, updateRecordsPerPage);
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_DOCUSIGN);
    const filterContext = docusignData?.signingDocuments.filterContext;
    const docusignItems = getDocusignItems(contentOptions, opsContentData, docusignData);
    const formattedItems = getFormattedItems({
      items: docusignItems,
      refetchData: refetchDocusignData,
      fields: textFields,
      contentOptions,
      getAccountNumberRedirectUrl,
      getClientNameRedirectUrl,
      onAccountClick,
      onClientClick,
      currentUser,
      opsContentData,
      showProductName,
      activeRowId,
    });

    return {
      docusignFilterConfig: docusignFilterConfig.map(filter => {
        return {
          ...filter,
          label: findFieldValue(textFields, `${filter.key.toLowerCase()}_filter`),
          options: filter.options.map(option => {
            return {
              ...option,
              label: findFieldValue(textFields, `${option.id.toLowerCase()}`),
            };
          }),
        };
      }),
      appliedFilters,
      filtersPopupOpen,
      updateOpenFiltersPopup,
      onFilterChange,
      filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
      appliedFiltersContent: {
        allFilter: findFieldValue(textFields, 'all_filter'),
        appliedFilters: findFieldValue(textFields, 'applied_filters'),
      },
      filtersContent: {
        allFilter: findFieldValue(textFields, 'all_filter'),
        apply: findFieldValue(textFields, 'apply_cta'),
        filters: findFieldValue(textFields, 'filters_cta'),
        resetAll: findFieldValue(textFields, 'reset_all_cta'),
      },
      onAppliedFiltersSelect,
      onPageChange,
      onRowHover,
      onSearchChange,
      currentPage,
      refetchData: refetchDocusignData,
      sortConfig,
      tableColumns,
      docusignTabData: formattedItems,
      currentSearchFilter: searchFilter,
      totalPages: Math.ceil(total / recordsPerPage),
      paginationContext,
      searchError: false,
      searchContext,
    };
  }, [
    contentData,
    opsContentData,
    docusignData,
    currentPage,
    recordsPerPage,
    updateRecordsPerPage,
    contentOptions,
    refetchDocusignData,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
    onAccountClick,
    onClientClick,
    currentUser,
    showProductName,
    activeRowId,
    appliedFilters,
    filtersPopupOpen,
    updateOpenFiltersPopup,
    onFilterChange,
    onAppliedFiltersSelect,
    onPageChange,
    onRowHover,
    onSearchChange,
    sortConfig,
    searchFilter,
    onParamsUpdate,
    params,
    state,
  ]);

  if (docusignError?.message === TOO_MANY_SEARCH_RECORDS_ERROR) {
    return {
      data: { ...data, searchError: true },
      error: contentStackError,
      loading: contentLoading || docusignLoading,
    };
  }

  return {
    data,
    error: docusignError || contentStackError,
    loading: contentLoading || docusignLoading,
  };
};

const getSigneesEmail = (
  signees: GetSigningDocumentsV2_signingDocuments_legalDocuments_signees[],
): emailRecipient[] => {
  const filteredSignees = signees
    .sort((a, b) => (a.recipientId ?? 0) - (b.recipientId ?? 0))
    .map(signeeEmailRecipient => ({
      email: signeeEmailRecipient.email || '',
      recipientId: signeeEmailRecipient.recipientId || 1,
    }));

  return filteredSignees;
};

export const getDocusignItems = (
  contentOptions: ContentOptions,
  opsDashboardContentData: GetOpsDashboardContentV2 | undefined,
  result?: GetSigningDocumentsV2 | undefined,
): DocusignItem[] => {
  if (!result) {
    return [];
  }
  const docusignResult: DocusignItem[] = [];
  const productNameContent = opsDashboardContentData?.all_product_name?.items || [];
  result.signingDocuments.legalDocuments?.map((item: GetSigningDocumentsV2_signingDocuments_legalDocuments) => {
    const entityNotes = item.entityNotes.length ? item.entityNotes[0] : null;
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(item.managedProduct?.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = item.managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = item.managedProduct?.clientParty?.id || '';
    // only used for activated account states
    const accountState = getAccountState({
      firstRebalancedOn: item.managedProduct?.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;
    docusignResult.push({
      id: item.signingDocumentId || '',
      accountNumber: item.managedProduct?.financialAccountNumber ?? '',
      accountNumberText: getAccountNumberOrAccountTypeString(
        item.managedProduct?.financialAccountNumber || null,
        getAccountTypeText(
          item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
          opsDashboardContentData?.all_account_type?.items || [],
        ),
      ),
      accountState,
      accountType: item.managedProduct?.accountType || '',
      clientName: `${item.managedProduct?.clientParty?.partyPerson?.givenName ?? ''} ${
        item.managedProduct?.clientParty?.partyPerson?.familyName ?? ''
      }`,
      comment: entityNotes
        ? {
            lastValue: entityNotes.note,
            ...getLastEditDateAndTime(new Date(entityNotes.created), contentOptions),
          }
        : undefined,
      dateGenerated: item.created || '',
      lastCommentPartyId: entityNotes?.createdByPartyId || '',
      managedProductId: item.managedProduct?.id || '',
      emailRecipients: getSigneesEmail(item.signees),
      partyId,
      productName: getAccountProgramText(
        item.managedProduct?.program,
        item.managedProduct?.attributes,
        productNameContent,
      ),
      status: fromLegalDocumentStatustoDocusignStatus(item.status),
    });
  });
  return docusignResult;
};

export const getTableColumns = (contentData: GetDocusignTabContent | undefined, onSort: () => void) => {
  const item = contentData?.all_docusign_tab?.items?.[0];
  // Filter empty columns
  const contentstackTableColumn =
    item?.columns?.column?.reduce<ContentstackTableColumn[]>((acc, column: ContentstackTableColumn | null) => {
      if (column) {
        acc.push(column);
      }

      return acc;
    }, []) ?? [];

  const sortedColumns = sortColumnsByColumnOrder(contentstackTableColumn);

  return sortedColumns.map(
    (col): TableColumn => ({
      title: col.column_value,
      key: col.column_id ?? '',
      ...(col.column_id === 'created'
        ? {
            onSort: (_: string) => () => {
              onSort();
            },
          }
        : {}),
    }),
  );
};
