import { MutationResult } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import { GetGoalByPartial, GetGoalByPartialVariables } from './amplify/__generated__/getGoalByPartial.v2';
import {
  UnassociatePartialFromGoal,
  UnassociatePartialFromGoalVariables,
} from './amplify/__generated__/unassociatePartialFromGoal.v2';
import { GetGoalByPartial as GetGoalByPartialQuery } from './amplify/getGoalByPartial.gql';
import { UnassociatePartialFromGoal as UnassociatePartialFromGoalMutation } from './amplify/unassociatePartialFromGoal.gql';

import { useGoalsMutation, useLazyGoalsQuery } from '~/utils/amplify';

export const useUnassociatePartial = (): [
  (clientPartyIds: string[], copilotPortfolioUlid: string) => Promise<void>,
  MutationResult<UnassociatePartialFromGoal>,
] => {
  const getGoalByPartialPromise = usePromise<GetGoalByPartial | null | undefined>();
  const [getGoalByPartial] = useLazyGoalsQuery<GetGoalByPartial, GetGoalByPartialVariables>(GetGoalByPartialQuery, {
    onCompleted: getGoalByPartialPromise.resolve,
    onError: () => {
      // if no goal account exists for the partial, an error is returned. This is handled the same as no goal association
      getGoalByPartialPromise.resolve?.(undefined);
    },
  });
  const [unassociatePartialFromGoal, unassociatePartialFromGoalResult] = useGoalsMutation<
    UnassociatePartialFromGoal,
    UnassociatePartialFromGoalVariables
  >(UnassociatePartialFromGoalMutation);

  const unassociate = useCallback(
    async (clientPartyIds: string[], copilotPortfolioUlid: string) => {
      getGoalByPartial({ variables: { clientPartyIds, copilotPortfolioUlid } });
      const goal = await getGoalByPartialPromise.value;
      if (goal?.getGoalByPartial?.id) {
        await unassociatePartialFromGoal({
          variables: { clientPartyIds, partialId: copilotPortfolioUlid },
          update: cache => {
            cache.writeQuery<GetGoalByPartial, GetGoalByPartialVariables>({
              query: GetGoalByPartialQuery,
              variables: {
                clientPartyIds,
                copilotPortfolioUlid,
              },
              data: { getGoalByPartial: null },
            });
          },
        });
      }
    },
    [getGoalByPartial, getGoalByPartialPromise.value, unassociatePartialFromGoal],
  );
  return [unassociate, unassociatePartialFromGoalResult];
};

const usePromise = <T>() => {
  const [resolve, setResolve] = useState<(value: T | PromiseLike<T>) => void>();
  const [reject, setReject] = useState<(reason?: any) => void>();
  const value = useMemo(
    () =>
      new Promise<T>((res, rej) => {
        setResolve(() => res);
        setReject(() => rej);
      }),
    [],
  );

  return { value, resolve, reject };
};
