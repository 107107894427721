export enum MeasurementName {
  FiveYears = 'fiveYears',
  OneYear = 'oneYear',
  QTD = 'QTD',
  QuarterlyPerformance = 'QuarterlyPerformance',
  SinceInception = 'sinceInception',
  TenYears = 'tenYears',
  ThreeYears = 'threeYears',
  YTD = 'YTD',
  YearToQuarter = 'yearToQuarter',
}

export type ReturnsByPeriod = Partial<{ [key in MeasurementName]: { from: Date; to: Date; value?: string } }>;
