import {
  GetBillingRatesContent,
  GetBillingRatesContent_all_account_details_items_sections_AccountDetailsSectionsBillingRates,
  GetBillingRatesContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type BillingRatesSection = GetBillingRatesContent_all_account_details_items_sections_AccountDetailsSectionsBillingRates;

export const useGetBillingRatesContent = (
  options?: QueryHookOptions<GetBillingRatesContent, GetBillingRatesContentVariables>,
): QueryResult<GetBillingRatesContent, GetBillingRatesContentVariables> => {
  return useContentstackQuery(queries.GetBillingRatesContent, options);
};
