import { ComponentProps, useCallback, useContext, useMemo, useState } from 'react';

import OpsDashboardContext from '../../OpsDashboardContext';
import { AllAccountsTabContent, SearchContextLiterals, TOO_MANY_SEARCH_RECORDS_ERROR } from '../../types';
import { useGetAccountsTabContent } from '../contentstack';
import { AllAccountsFilterCount, useGetAllAccountsData } from '../symphony';

import { getTableColumns, getTableContent, statusFilterToManagedProductStatus } from './utils';

import { ManagedProductsField, ManagedProductStatus, OrderType } from '~/__generated__';
import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { FilterConfig, FilterCountMap, FilterTypes, SelectedFilters } from '~/components/Filters/types';
import { getSelectedOptions } from '~/components/Filters/utils';
import { SortConfig, TableColumn } from '~/components/ui/BasicTable';
import { getSearchContext } from '~/containers/OpsDashboardV2/utils';
import { useCoreConfig } from '~/utils/config';
import { findFieldValue } from '~/utils/contentstack';
import { getPaginationContext } from '~/utils/table';
import { AsyncResult, PaginationContext, SearchContext } from '~/utils/types';

interface AccountsTabData {
  accountsFilterConfig: FilterConfig[];
  accountsTabContent: AllAccountsTabContent[];
  appliedFilters: SelectedFilters;
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  currentPage: number;
  currentSearchFilter: string;
  filterCountMap: FilterCountMap[];
  filtersContent: ComponentProps<typeof Filters>['content'];
  filtersPopupOpen: boolean;
  onAppliedFiltersSelect: (filterKey?: string, selectedOption?: string) => void;
  onFilterChange: (selectedFilters: SelectedFilters) => void;
  onPageChange: (pageNumber: number) => void;
  onRowHover: (rowId: string | undefined) => void;
  onSearchChange: (value: string | null) => void;
  paginationContext: PaginationContext;
  refetchAccountsData: () => void;
  searchContext: SearchContext;
  searchError: boolean;
  sortConfig: SortConfig;
  tableColumns: TableColumn[];
  totalPages: number;
  updateOpenFiltersPopup: (value: boolean) => void;
}

export enum AccountsFilters {
  STATUS = 'STATUS',
}

const accountsFilterConfig = [
  {
    key: AccountsFilters.STATUS,
    options: [
      {
        id: ManagedProductStatus.ACTIVE,
      },
      {
        id: ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
      },
      {
        id: ManagedProductStatus.NEW,
      },
      {
        id: ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
      },
      {
        id: ManagedProductStatus.PENDING_CLOSED,
      },
      {
        id: ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
      },
      {
        id: ManagedProductStatus.CLOSED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
];

export const defaultAppliedFilters: SelectedFilters = {
  [AccountsFilters.STATUS]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      ManagedProductStatus.ACTIVE,
      ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
      ManagedProductStatus.NEW,
      ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
      ManagedProductStatus.PENDING_CLOSED,
      ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
      ManagedProductStatus.CLOSED,
    ],
  },
};

// Set 10 as constant first until we have requirements to change it
const initialRecordsPerPage = 10;

export const useAccountsTabData = (): AsyncResult<AccountsTabData> => {
  const {
    contentOptions,
    onAccountClick,
    onClientClick,
    getClientNameRedirectUrl,
    getAccountNumberRedirectUrl,
    onParamsUpdate,
    params,
    opsContentData,
  } = useContext(OpsDashboardContext);

  const state = useMemo(() => {
    let value;
    try {
      value = JSON.parse(params.state || '{}');
    } catch (e) {
      return defaultAppliedFilters;
    }

    return value;
  }, [params]);

  const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
  const [activeRowId, setActiveRowId] = useState<string | undefined>();
  const appliedFilters: SelectedFilters = state.filters || defaultAppliedFilters;
  const currentPage: number = state.currentPage || 1;
  const recordsPerPage: number = state.recordsPerPage || initialRecordsPerPage;
  const searchFilter = state.searchFilter ?? null;
  const setCurrentPage = useCallback(
    (page: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: page }) });
    },
    [onParamsUpdate, params, state],
  );
  const setRecordsPerPage = useCallback(
    (value: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, recordsPerPage: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const setSearchFilter = useCallback(
    (value: string | null) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, searchFilter: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const sortConfig: SortConfig = useMemo(
    () =>
      state.sortConfig || {
        field: 'createdAt',
        order: OrderType.DESCENDING,
      },
    [state],
  );

  const { showProductName } = useCoreConfig().components.sfOpsDashboard;

  const { data: contentData, error: contentStackError, loading: contentLoading } = useGetAccountsTabContent({
    variables: contentOptions,
  });

  const onFilterChange = useCallback(
    (filters: SelectedFilters) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, filters }) });
    },
    [onParamsUpdate, params, state],
  );

  const onAppliedFiltersSelect = useCallback(() => {
    setFiltersPopupOpen(true);
  }, []);

  const updateOpenFiltersPopup = useCallback((value: boolean) => {
    setFiltersPopupOpen(value);
  }, []);

  const statusFilter = getSelectedOptions(appliedFilters[AccountsFilters.STATUS]) ?? [];
  const {
    data: accountsData,
    error: accountsError,
    loading: accountsLoading,
    refetch: refetchAccountsData,
  } = useGetAllAccountsData({
    variables: {
      pagination: {
        limit: recordsPerPage,
        offset: (currentPage - 1) * recordsPerPage,
      },
      statuses: statusFilterToManagedProductStatus(statusFilter),
      searchFilter,
      sort: {
        field: ManagedProductsField.CREATED,
        order: sortConfig.order,
      },
    },
  });

  const onUpdateRecordsPerPae = useCallback(
    (value: number) => {
      setRecordsPerPage(value);
    },
    [setRecordsPerPage],
  );

  const onPageChange = useCallback(
    (selectedPage: number) => {
      setCurrentPage(selectedPage);
    },
    [setCurrentPage],
  );

  const onRowHover = useCallback(
    (rowId: string | undefined) => {
      setActiveRowId(rowId);
    },
    [setActiveRowId],
  );

  const onSearchChange = useCallback(
    (searchText: string | null) => {
      setSearchFilter(searchText);
    },
    [setSearchFilter],
  );
  const data: AccountsTabData = useMemo(() => {
    const onSort = () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      onParamsUpdate({
        ...params,
        state: JSON.stringify({ ...state, currentPage: 1, sortConfig: { field: 'createdAt', order: toggleOrder } }),
      });
    };
    const tableColumns: TableColumn[] = getTableColumns(contentData, onSort);

    const accounts = accountsData?.managedProducts.products ?? [];
    const items = contentData?.all_all_accounts_tab?.items?.[0];
    const textFields = items?.fields?.text ?? [];
    const filterContext = accountsData?.managedProducts.filterContext;
    const fields = opsContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_ALL_ACCOUNTS);
    const total = accountsData?.managedProducts.paginationContext.total ?? 0;
    const paginationContext = getPaginationContext(fields, currentPage, recordsPerPage, total, onUpdateRecordsPerPae);

    const accountsTabContent = getTableContent(
      accounts,
      onClientClick,
      onAccountClick,
      getAccountNumberRedirectUrl,
      getClientNameRedirectUrl,
      showProductName,
      textFields,
      contentOptions,
      opsContentData,
      activeRowId,
    );
    return {
      accountsFilterConfig: accountsFilterConfig.map(filter => {
        return {
          ...filter,
          label: findFieldValue(textFields, `${filter.key.toLowerCase()}_filter`),
          options: filter.options.map(option => {
            return {
              ...option,
              label: findFieldValue(textFields, `${option.id.toLowerCase()}`),
            };
          }),
        };
      }),
      appliedFilters,
      filtersPopupOpen,
      updateOpenFiltersPopup,
      accountsTabContent,
      onFilterChange,
      appliedFiltersContent: {
        allFilter: findFieldValue(textFields, 'all_filter'),
        appliedFilters: findFieldValue(textFields, 'applied_filters'),
      },
      filtersContent: {
        allFilter: findFieldValue(textFields, 'all_filter'),
        apply: findFieldValue(textFields, 'apply_cta'),
        filters: findFieldValue(textFields, 'filters_cta'),
        resetAll: findFieldValue(textFields, 'reset_all_cta'),
      },
      onAppliedFiltersSelect,
      onPageChange,
      onRowHover,
      onSearchChange,
      currentPage,
      refetchAccountsData,
      sortConfig,
      tableColumns,
      currentSearchFilter: searchFilter,
      totalPages: Math.ceil(total / recordsPerPage),
      filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
      paginationContext,
      searchError: false,
      searchContext,
    };
  }, [
    searchFilter,
    accountsData,
    contentData,
    opsContentData,
    onClientClick,
    onAccountClick,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
    showProductName,
    contentOptions,
    currentPage,
    appliedFilters,
    filtersPopupOpen,
    updateOpenFiltersPopup,
    onFilterChange,
    onAppliedFiltersSelect,
    onPageChange,
    onSearchChange,
    refetchAccountsData,
    sortConfig,
    onParamsUpdate,
    params,
    state,
    recordsPerPage,
    onUpdateRecordsPerPae,
    activeRowId,
    onRowHover,
  ]);

  if (accountsError?.message === TOO_MANY_SEARCH_RECORDS_ERROR) {
    return {
      data: { ...data, searchError: true },
      error: contentStackError,
      loading: contentLoading || accountsLoading,
    };
  }

  return {
    data,
    error: accountsError || contentStackError,
    loading: contentLoading || accountsLoading,
  };
};

export const getFilterCountMap = (filterContext: AllAccountsFilterCount[]) => {
  return filterContext.map(v => {
    return {
      count: v.count,
      filters: {
        [AccountsFilters.STATUS]: `${v.filtersKey.status}`,
      },
    };
  });
};
