import { MutableRefObject, useEffect, useRef } from 'react';

let isFocusedOnce = false;
/**
 * The target element may require tabIndex={-1} to make the element focusable programmatically.
 * The hook returns a ref for an element that needs to receive focus upon rendering of a component. This should be used
 * only one at a time to avoid competing focus.
 *
 * An example use case is ADA may require information element to receive focus so that the screen reader reads it,
 * as well as to improve navigation after.
 * Note that LiveAnnouncer from 'react-aria-live' is another approach to make screen reader reads something, but only
 * if the current focus should not change.
 */
export const useAutoFocus = <T extends HTMLElement>(): MutableRefObject<T | null> => {
  const focusRef = useRef<T | null>(null);

  useEffect(() => {
    if (!focusRef.current) {
      isFocusedOnce = false;
      return;
    }

    if (!isFocusedOnce) {
      isFocusedOnce = true;
      focusRef.current.focus();
    }
  }); // no dep array to allow focusRef.current to resolve to a value after React has updated DOM

  return focusRef;
};
