import { compareDesc, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';

import { CommentComponentColumn } from './common/Comment';
import { GetOpsDashboardContent_all_ops_dashboard_items_comment_column } from './contentstack/__generated__/query.v2';

import { FlagReason } from '~/__generated__';
import { FlagReasonsContent } from '~/components/modals/ResolveFlag/contentstack';
import { TableColumn } from '~/components/ui/BasicTable';
import { getActivatedAccountStates } from '~/containers/AccountSummary/utils';
import { AccountState, isPartnerOpsSuspension, TradingSuspension } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { enumLocaleToLocaleMap } from '~/utils/format/date';
import { ContentstackTableColumn, sortColumnsByColumnOrder } from '~/utils/table';

/**
 * This functions allows to merge columns and optional columns then convert them to Table Columns
 * To be used by individual Tables. The input is content stack colummn list defined with objects containing
 * column Id, order and value.
 */

export const getColumnKeyValuePair = (
  columns: ContentstackTableColumn[],
  additionalColumns: ContentstackTableColumn[],
): TableColumn[] => {
  let items: ContentstackTableColumn[] = [];
  if (columns.length) {
    items = items.concat(columns);
  }
  if (additionalColumns.length) {
    items = items.concat(additionalColumns);
  }
  items = sortColumnsByColumnOrder(items);
  return items.map((col: { column_id: any; column_value: any }) => ({
    title: col.column_value,
    key: col.column_id,
  }));
};

export function lastXDigits(str: string, amount: number): string {
  return str.trim().slice(str.length - amount);
}

export const getCurrentPage = ({ limit, offset }: { limit: number | null; offset: number | null }): number =>
  limit && offset ? Math.floor(offset / limit) + 1 : 1;

export const getTotalPages = ({ limit, total }: { limit: number | null; total: number | null }): number =>
  limit && total ? Math.ceil(total / limit) : 1;

export const getLastEditDateAndTime = (
  date: Date,
  contentOptions: ContentOptions,
): { lastEditDate: string; lastEditTime: string } => {
  return {
    lastEditDate: format(date, 'MMM dd, yyyy'),
    lastEditTime: format(date, 'HH:mm a zzz', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      locale: enumLocaleToLocaleMap(contentOptions.locale),
    }),
  };
};

export interface OpsDashboardUser {
  name: string;
  partyId: string;
}

export const commentColumnFactory = (
  commentColumnContent?: GetOpsDashboardContent_all_ops_dashboard_items_comment_column,
): CommentComponentColumn => ({
  delete_modal: {
    cancel_cta: commentColumnContent?.delete_modal?.cancel_cta || '',
    delete_cta: commentColumnContent?.delete_modal?.delete_cta || '',
    error_message: commentColumnContent?.delete_modal?.error_message || '',
    modal_header: commentColumnContent?.delete_modal?.modal_header || '',
    subtitle: commentColumnContent?.delete_modal?.subtitle || '',
    title: commentColumnContent?.delete_modal?.title || '',
  },
  display_mode_menu: {
    delete: commentColumnContent?.display_mode_menu?.delete || '',
    edit: commentColumnContent?.display_mode_menu?.edit || '',
  },
  edit_mode: {
    cancel_cta: commentColumnContent?.edit_mode?.cancel_cta || '',
    error_message: commentColumnContent?.edit_mode?.error_message || '',
    placeholder: commentColumnContent?.edit_mode?.placeholder || '',
    save_cta: commentColumnContent?.edit_mode?.save_cta || '',
  },
});

export const getFlagReasonText = (reasons: FlagReasonsContent, reason: FlagReason) =>
  reasons[reason.toLowerCase() as keyof FlagReasonsContent] ?? '';

export const getAccountNumberOrAccountTypeString = (
  accountNumber: string | null,
  accountType: string | null,
): string => {
  if (accountNumber && accountType) {
    return `${accountNumber} / ${accountType}`;
  } else if (accountNumber && !accountType) {
    return `${accountNumber} / -`;
  } else if (!accountNumber && accountType) {
    return `- / ${accountType}`;
  }
  return '';
};

export const isAccountNumberClickable = (accountState: AccountState) =>
  [...getActivatedAccountStates(), AccountState.Closed].includes(accountState);

export const getSortedPartnerOpsTradingSuspensions = (suspensions: TradingSuspension[] = []) => {
  return suspensions
    .filter(suspension => isPartnerOpsSuspension(suspension))
    ?.sort((a, b) => {
      return compareDesc(parseISO(a.createdAt ?? ''), parseISO(b.createdAt ?? ''));
    });
};
