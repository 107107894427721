import {
  CashTransfersAttributes,
  CashTransfersBooleanAttribute,
  CashTransfersDecimalAttribute,
  CashTransfersIntegerAttribute,
  CashTransfersStringAttribute,
} from './symphony';

import { ManagedProductStatus } from '~/__generated__';

export const ManagedProductStatusesForCashTransfers: ManagedProductStatus[] = [
  ManagedProductStatus.ACTIVE,
  ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
  ManagedProductStatus.NEW,
  ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
  ManagedProductStatus.PENDING_CLOSED,
  ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
];

export const getBooleanAttributeValue = (
  attributes: CashTransfersAttributes[],
  attribute: string,
): boolean | undefined => {
  return attributes.find(
    (item: CashTransfersAttributes): item is CashTransfersBooleanAttribute =>
      item.name === attribute && item.__typename === 'BooleanAttribute',
  )?.booleanValue;
};

export const getDecimalAttributeValue = (
  attributes: CashTransfersAttributes[],
  attribute: string,
): string | undefined => {
  return attributes.find(
    (item: CashTransfersAttributes): item is CashTransfersDecimalAttribute =>
      item.name === attribute && item.__typename === 'DecimalAttribute',
  )?.decimalValue;
};

export const getIntegerAttributeValue = (
  attributes: CashTransfersAttributes[],
  attribute: string,
): number | undefined => {
  return attributes.find(
    (item: CashTransfersAttributes): item is CashTransfersIntegerAttribute =>
      item.name === attribute && item.__typename === 'IntegerAttribute',
  )?.integerValue;
};

export const getStringAttributeValue = (
  attributes: CashTransfersAttributes[],
  attribute: string,
): string | undefined => {
  return attributes.find(
    (item: CashTransfersAttributes): item is CashTransfersStringAttribute =>
      item.name === attribute && item.__typename === 'StringAttribute',
  )?.stringValue;
};
