import { GetRiskPreferenceHistoryModalContent_all_role_type_items } from './contentstack/__generated__/query.v2';

import { CreatedByRole } from '~/__generated__';

export const getCreatedByRoleText = (
  type: CreatedByRole,
  content: (GetRiskPreferenceHistoryModalContent_all_role_type_items | null)[],
): string => {
  return content?.find(item => item?.key === type)?.text || 'Unknown';
};
