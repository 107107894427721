import { GetOpsDashboardHeaderContentV2, GetOpsDashboardHeaderContentV2Variables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetOpsDashboardHeaderContentV2 = (
  options?: QueryHookOptions<GetOpsDashboardHeaderContentV2, GetOpsDashboardHeaderContentV2Variables>,
): QueryResult<GetOpsDashboardHeaderContentV2, GetOpsDashboardHeaderContentV2Variables> => {
  return useContentstackQuery(queries.GetOpsDashboardHeaderContentV2, options);
};
