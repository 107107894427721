import { GetClientDocumentsContent, GetClientDocumentsContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ClientDocumentscontent = GetClientDocumentsContent;

export const useGetClientDocumentsContent = (
  options?: QueryHookOptions<GetClientDocumentsContent, GetClientDocumentsContentVariables>,
): QueryResult<GetClientDocumentsContent, GetClientDocumentsContentVariables> => {
  return useContentstackQuery(queries.GetClientDocumentsContent, options);
};
