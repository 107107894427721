import { useEventBus, useEventBusListener } from '~/utils/event-bus';

export type Emitter<TData> = (args: TData) => void;

/**
 * The event names should be named after the component that emits the event.
 */
export const useAnalyticsEvent: typeof useEventBus = eventName => {
  return useEventBus(eventName);
};

export const useAnalyticsEventListener: typeof useEventBusListener = (eventName, listener) => {
  useEventBusListener(eventName, listener);
};
