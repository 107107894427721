import { useEffect, useState } from 'react';

import { useGetModelPortfolios } from './symphony';

import { LatestQuestionnaireRecommendationSet } from '~/hooks/questionnaire/symphony';
import { ApolloError } from '~/utils/apollo-client';

export const useIsValidRiskScore = ({
  managedProductId,
  partyId,
  recommendedProduct,
  skip,
}: {
  managedProductId: string;
  partyId: string;
  recommendedProduct: Pick<LatestQuestionnaireRecommendationSet, 'riskScore' | 'riskScoreMin' | 'scenarioId'>;
  skip?: boolean;
}): { error?: ApolloError; isValidRiskScore?: boolean; loading: boolean } => {
  const [isValidRiskScore, setIsValidRiskScore] = useState<boolean>();
  const [loading, setLoading] = useState(!skip); // if skip is true, loading should always be false
  const [error, setError] = useState<ApolloError>();
  const { riskScore, riskScoreMin, scenarioId } = recommendedProduct;

  const {
    data: modelPortfoliosData,
    loading: modelPortfoliosLoading,
    error: modelPortfoliosError,
  } = useGetModelPortfolios({
    variables: {
      managedProductId,
      partyId,
      riskScoreMin,
      riskScoreMax: riskScore,
      scenarioId,
    },
    fetchPolicy: 'no-cache',
    skip,
  });

  useEffect(() => {
    setIsValidRiskScore(!!modelPortfoliosData?.managedProduct?.modelPortfolios?.length);
  }, [modelPortfoliosData]);

  useEffect(() => {
    setLoading(modelPortfoliosLoading);
  }, [modelPortfoliosLoading]);

  useEffect(() => {
    setError(modelPortfoliosError);
  }, [modelPortfoliosError]);

  return { isValidRiskScore, loading, error };
};
