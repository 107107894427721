import { RemoveTradingSuspensionModalContent } from '../contentstack';

import { findFieldValue } from '~/utils/contentstack';

export enum ContentKeys {
  CREATED_BY_LABEL = 'created_by_label',
  CREATED_LABEL = 'created_label',
  DESCRIPTION = 'description',
  MODAL_TITLE = 'modal_title',
  Others = 'others',
  PRIMARY_CTA = 'primary_cta',
  SECONDARY_CTA = 'secondary_cta',
  SUB_DESCRIPTION = 'sub_description',
}

export interface Content {
  createdByLabel: string;
  createdLabel: string;
  description: string;
  primaryCta: string;
  secondaryCta: string;
  subDescription: string;
  textFields: Array<{ key: string | null; value: string | null } | null>;
  title: string;
}

export const getRemoveTradingSuspensionsModalContent = (content?: RemoveTradingSuspensionModalContent): Content => {
  const contentData = content?.all_remove_trading_suspension_modal?.items?.[0];
  const textFields = contentData?.fields?.text ?? [];

  return {
    createdLabel: findFieldValue(textFields, ContentKeys.CREATED_LABEL),
    createdByLabel: findFieldValue(textFields, ContentKeys.CREATED_BY_LABEL),
    description: findFieldValue(textFields, ContentKeys.DESCRIPTION),
    primaryCta: findFieldValue(textFields, ContentKeys.PRIMARY_CTA),
    secondaryCta: findFieldValue(textFields, ContentKeys.SECONDARY_CTA),
    subDescription: findFieldValue(textFields, ContentKeys.SUB_DESCRIPTION),
    title: findFieldValue(textFields, ContentKeys.MODAL_TITLE),
    textFields,
  };
};
