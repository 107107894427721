import {
  CashTransfersV2Attributes,
  CashTransfersV2BooleanAttribute,
  CashTransfersV2DecimalAttribute,
  CashTransfersV2IntegerAttribute,
  CashTransfersV2StringAttribute,
} from './symphony';

import { ManagedProductStatus, ScheduledTransferStatus } from '~/__generated__';

export const ManagedProductStatusesForCashTransfersV2: ManagedProductStatus[] = [
  ManagedProductStatus.ACTIVE,
  ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
  ManagedProductStatus.NEW,
  ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
  ManagedProductStatus.PENDING_CLOSED,
  ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
];

export const ScheduledTransferStatusesForCashTransfersV2: ScheduledTransferStatus[] = [
  ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE,
  ScheduledTransferStatus.PENDING,
  ScheduledTransferStatus.ACTIVE,
  ScheduledTransferStatus.ACTIVE_AT_BROKERAGE,
  ScheduledTransferStatus.SUBMITTED,
  ScheduledTransferStatus.INSUFFICIENT_ASSETS,
  ScheduledTransferStatus.UNAUTHORIZED,
  ScheduledTransferStatus.TRADES_SUBMITTED,
  ScheduledTransferStatus.CASH_GENERATED,
  ScheduledTransferStatus.PENDING_CANCELLATION,
  ScheduledTransferStatus.CANCELLED,
  ScheduledTransferStatus.COMPLETED,
];

export const getBooleanAttributeValue = (
  attributes: CashTransfersV2Attributes[],
  attribute: string,
): boolean | undefined => {
  return attributes.find(
    (item: CashTransfersV2Attributes): item is CashTransfersV2BooleanAttribute =>
      item.name === attribute && item.__typename === 'BooleanAttribute',
  )?.booleanValue;
};

export const getDecimalAttributeValue = (
  attributes: CashTransfersV2Attributes[],
  attribute: string,
): string | undefined => {
  return attributes.find(
    (item: CashTransfersV2Attributes): item is CashTransfersV2DecimalAttribute =>
      item.name === attribute && item.__typename === 'DecimalAttribute',
  )?.decimalValue;
};

export const getIntegerAttributeValue = (
  attributes: CashTransfersV2Attributes[],
  attribute: string,
): number | undefined => {
  return attributes.find(
    (item: CashTransfersV2Attributes): item is CashTransfersV2IntegerAttribute =>
      item.name === attribute && item.__typename === 'IntegerAttribute',
  )?.integerValue;
};

export const getStringAttributeValue = (
  attributes: CashTransfersV2Attributes[],
  attribute: string,
): string | undefined => {
  return attributes.find(
    (item: CashTransfersV2Attributes): item is CashTransfersV2StringAttribute =>
      item.name === attribute && item.__typename === 'StringAttribute',
  )?.stringValue;
};
