import React, { ComponentProps } from 'react';

import { useGetViewDocumentsContent } from './contentstack';

import { Modal } from '~/components/ui/Modal';
import { Button } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  partyId: string;
};

export const ViewDocumentsModal: React.FC<Props> = ({
  dataQa = 'view-documents-modal',
  contentOptions,
  partyId,
  open,
  maxWidth = 'sm',
  ...modalProps
}) => {
  const { data: contentstackData, loading: contentstackLoading, error: contentstackError } = useGetViewDocumentsContent(
    {
      variables: contentOptions,
      skip: !open,
    },
  );
  const content = contentstackData?.all_view_documents_modal?.items?.[0];

  const isMediumOrBelowScreen = useIsMediumScreen();

  return (
    <Modal
      actions={
        <Button
          fullWidth={isMediumOrBelowScreen}
          onClick={modalProps.onClose as () => void}
          variant={isMediumOrBelowScreen ? 'outlined' : 'contained'}
        >
          {content?.close_cta}
        </Button>
      }
      content={
        <>
          {content?.imageConnection?.edges?.[0]?.node?.url && (
            <img alt="" data-qa={`${dataQa}-image`} src={content.imageConnection.edges[0].node.url} />
          )}
          {content?.content && (
            <RteContent
              data={content.content}
              data-qa={`${dataQa}-content`}
              sx={{
                pt: 3,
                '& a': { color: 'primary.main' },
              }}
            />
          )}
        </>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={contentstackError}
      loading={contentstackLoading}
      maxWidth={maxWidth}
      open={open}
      title={content?.heading}
      {...modalProps}
    />
  );
};
