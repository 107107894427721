import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { AccountActionToInvoke, AccountData } from '../../../types';

import { AccountActions, ActionName } from '~/components/AccountActions';
import { AddFundsData } from '~/components/AccountActions/hooks';
import { isCustomAction, isModalAction, isStandardAction } from '~/components/AccountActions/utils';
import { AddFunds } from '~/components/AddFunds';
import { KebabMenu, KebabMenuItem } from '~/components/KebabMenu';
import { ArrowDropDownIcon, Button, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { AccountState, AccountStateData, BankAccount } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { useIsMediumScreen } from '~/utils/responsiveness';

interface Props {
  accountData: AccountData;
  accountState: AccountState;
  accountStateData?: AccountStateData;
  actionToInvoke?: AccountActionToInvoke;
  actions: ComponentProps<typeof AccountActions>['config'];
  addFundsData: AddFundsData;
  bankAccounts?: BankAccount[];
  contributionAmount?: string;
  ctas: {
    addFunds: string;
    bankAccount: string;
    journaling: string;
  };
  dataQa: string;
  onActionClick: (actionName: ActionName) => void;
  refetchingAccounts?: boolean;
}

export const AddFundsCTA: React.FC<Props> = ({
  actionToInvoke,
  accountData: { attributes, accountNumber, lastRtqDate, managedProductId, partyId, questionnaire, type },
  accountState,
  accountStateData,
  actions,
  addFundsData: {
    sourceAccountId: [, setSourceId],
    depositAmount: [, setDepositAmount],
    modal: addFundsModal,
  },
  bankAccounts = [],
  contributionAmount = '100',
  ctas: { addFunds, bankAccount, journaling },
  dataQa,
  onActionClick,
  refetchingAccounts,
}) => {
  const [isActionToInvokeDone, setActionToInvokeDone] = useState(false);
  const isMediumScreen = useIsMediumScreen();
  const { sfAccount } = useTheme();
  const { addFundsSources } = useCoreConfig().featureFlags;
  const addFundsCallback = useCallback(() => {
    const customActionArgs = { accountNumber, lastRtqDate, managedProductId, partyId, questionnaire };
    if (isStandardAction(actions.addFunds)) {
      addFundsModal.openModal();
    } else if (isCustomAction(actions.addFunds)) {
      actions.addFunds.callback(customActionArgs);
    } else if (isModalAction(actions.addFunds)) {
      actions.addFunds.callback({
        ...customActionArgs,
        openModal: addFundsModal.openModal,
      });
    }
  }, [accountNumber, actions.addFunds, addFundsModal, lastRtqDate, managedProductId, partyId, questionnaire]);

  const onAddFunds: ComponentProps<typeof AddFunds>['onAddFunds'] = useCallback(
    ({ value, account: sourceAccount }) => {
      if (sourceAccount?.id) {
        setSourceId(sourceAccount.id);
      }
      setDepositAmount(value);
      addFundsCallback();
    },
    [setSourceId, setDepositAmount, addFundsCallback],
  );

  const onAddFundsClick = useCallback(() => {
    onAddFunds({ account: bankAccounts[0], value: contributionAmount });
  }, [bankAccounts, contributionAmount, onAddFunds]);

  const multiAddFundsOptionItems = useMemo(() => {
    const items: KebabMenuItem[] = [];
    if (
      addFundsSources.includes('bankAccount') &&
      actions.addFunds?.valid?.({ state: accountState, stateData: accountStateData, type, attributes })
    ) {
      items.push({ callback: onAddFundsClick, text: bankAccount });
    }
    if (
      addFundsSources.includes('journaling') &&
      actions.journaling?.valid?.({ state: accountState, stateData: accountStateData, type, attributes })
    ) {
      items.push({ callback: () => onActionClick('journaling'), text: journaling });
    }
    return items;
  }, [
    actions.addFunds,
    accountState,
    accountStateData,
    type,
    attributes,
    actions.journaling,
    addFundsSources,
    onAddFundsClick,
    onActionClick,
    bankAccount,
    journaling,
  ]);

  useEffect(() => {
    if (actionToInvoke && accountNumber && !isActionToInvokeDone) {
      setActionToInvokeDone(true);
      const { actionName, accountNumber: targetAccountNumber } = actionToInvoke;
      // Only the Add Funds handled for now. This may need to move to Account component if more actions are added.
      if (actionName === 'addFunds' && accountNumber === targetAccountNumber) {
        onAddFundsClick();
      } else {
        console.warn(`No invoke handle for action name ${actionName} and target account number ${targetAccountNumber}`);
      }
    }
  }, [accountNumber, actionToInvoke, isActionToInvokeDone, onAddFundsClick]);

  return multiAddFundsOptionItems.length > 1 ? (
    <KebabMenu
      buttonContent={<Typography>{addFunds}</Typography>}
      buttonProps={{
        disabled: refetchingAccounts,
        endIcon: <ArrowDropDownIcon />,
        size: 'medium',
        sx: { ...sfAccount.styles?.addFundsKebabMenu?.button },
        variant: 'contained',
      }}
      items={multiAddFundsOptionItems}
      menuListProps={{
        sx: { ...sfAccount.styles?.addFundsKebabMenu?.list },
      }}
    />
  ) : actions.addFunds?.valid?.({ state: accountState, stateData: accountStateData, type, attributes }) ? (
    <Button
      data-heap={`addFunds-${partyId}`}
      data-qa={`${dataQa}-cta-add-funds`}
      disabled={refetchingAccounts}
      fullWidth={isMediumScreen}
      onClick={onAddFundsClick}
      variant="contained"
    >
      {addFunds}
    </Button>
  ) : null;
};
