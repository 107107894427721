export enum ClosureStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum ClosureStatusFilter {
  ALL = 'ALL',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
}

export enum ClosureType {
  END_MANAGEMENT = 'END_MANAGEMENT',
  LIQUIDATION = 'LIQUIDATION',
}

export enum RequestTabs {
  AllOtherRequests = 'AllOtherRequests',
  FlaggedRequests = 'FlaggedRequests',
}
