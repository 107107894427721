import { UpdateCashTransferV2, UpdateCashTransferV2Variables } from './__generated__/mutation.v2';
import {
  CashTransfersV2,
  CashTransfersV2_multiCashTransfers_transfers_attributes,
  CashTransfersV2_multiCashTransfers_transfers_attributes_BooleanAttribute,
  CashTransfersV2_multiCashTransfers_transfers_attributes_DecimalAttribute,
  CashTransfersV2_multiCashTransfers_transfers_attributes_IntegerAttribute,
  CashTransfersV2_multiCashTransfers_transfers_attributes_StringAttribute,
  CashTransfersV2_multiCashTransfers_transfers_flags,
  CashTransfersV2Variables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';
import { useSymphonyMutation } from '~/utils/symphony';

export type { CashTransfersV2, CashTransfersV2Variables } from './__generated__/query.v2';

export type CashTransfersV2Attributes = CashTransfersV2_multiCashTransfers_transfers_attributes;
export type CashTransfersV2BooleanAttribute = CashTransfersV2_multiCashTransfers_transfers_attributes_BooleanAttribute;
export type CashTransfersV2DecimalAttribute = CashTransfersV2_multiCashTransfers_transfers_attributes_DecimalAttribute;
export type CashTransfersV2IntegerAttribute = CashTransfersV2_multiCashTransfers_transfers_attributes_IntegerAttribute;
export type CashTransfersV2StringAttribute = CashTransfersV2_multiCashTransfers_transfers_attributes_StringAttribute;
export type CashTransfersV2Flag = CashTransfersV2_multiCashTransfers_transfers_flags;

export const useCashTransfersV2 = (
  options?: QueryHookOptions<CashTransfersV2, CashTransfersV2Variables>,
): QueryResult<CashTransfersV2, CashTransfersV2Variables> => {
  return useSymphonyQuery(queries.CashTransfersV2, options);
};

export const useUpdateCashTransferV2 = (
  options?: MutationHookOptions<UpdateCashTransferV2, UpdateCashTransferV2Variables>,
): MutationTuple<UpdateCashTransferV2, UpdateCashTransferV2Variables> => {
  return useSymphonyMutation(mutations.UpdateCashTransferV2, options);
};
