import React, { FC } from 'react';

import { Box, EmailIcon, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { SfTheme } from '~/utils/theme';

export interface Props {
  clientEmail: string;
  clientName?: string;
  dataQa: string;
  genericMessage?: string | null;
  message?: string | null;
  showEmail: boolean;
}

export const Greeting: FC<Props> = ({ dataQa, message, genericMessage, clientName, showEmail, clientEmail }) => {
  const {
    sfGreeting: { styles },
  } = useTheme<SfTheme>();

  return (
    <>
      <Box data-qa={dataQa} sx={{ color: 'primary.color' }}>
        {showEmail && (
          <Box>
            <Typography
              component="h1"
              sx={{ pb: (styles.displayName as Record<string, string>).paddingBottom }}
              variant="h2"
            >
              {clientName}
            </Typography>
            {clientEmail.trim() && (
              <Typography
                aria-level={1}
                component="span"
                data-qa={dataQa + '-email'}
                role="heading"
                sx={{ display: 'flex', color: 'primary.text' }}
                variant="caption"
              >
                <EmailIcon
                  sx={{
                    pr: (styles.emailIcon as Record<string, string>).paddingRight,
                  }}
                />
                {clientEmail}
              </Typography>
            )}
          </Box>
        )}
        {!showEmail && (
          <RteContent
            config={{ clientName }}
            data={clientName ? message || 'MISSING HEADER MESSAGE' : genericMessage || 'MISSING GENERIC HEADER MESSAGE'}
            sx={{ color: styles.headingColor }}
            variantMapping="h2"
          />
        )}
      </Box>
    </>
  );
};
