import {
  GetDepositsTableContentV2,
  GetDepositsTableContentV2_all_deposits_table_items_flag_referenceConnection_edges_node,
  GetDepositsTableContentV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type FlagsReferenceContent = GetDepositsTableContentV2_all_deposits_table_items_flag_referenceConnection_edges_node;

export const useGetDepositsTableContentV2 = (
  options?: QueryHookOptions<GetDepositsTableContentV2, GetDepositsTableContentV2Variables>,
): QueryResult<GetDepositsTableContentV2, GetDepositsTableContentV2Variables> => {
  return useContentstackQuery(queries.GetDepositsTableContentV2, options);
};
