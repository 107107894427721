import { TransferStatus, TransferStatusFilter } from '../../types';
import { getColumnKeyValuePair } from '../../utils';
import { FlagsReferenceContent } from '../contentstack';
import {
  GetDepositsTableContent,
  GetDepositsTableContent_all_deposits_table_items,
  GetDepositsTableContent_all_deposits_table_items_status_filters,
  GetDepositsTableContent_all_deposits_table_items_statuses,
} from '../contentstack/__generated__/query.v2';

import { TableColumn } from '~/components/ui/BasicTable';
import { ContentstackTableColumn } from '~/utils/table';

export type DepositDisplayStrings = {
  endDate?: string;
  flags: FlagsReferenceContent;
  nonRecurringtableHeaders: TableColumn[];
  nonWrappableNonRecurring: string[];
  nonWrappableRecurring: string[];
  partitionTabLabels: { allOtherRequests: string; flaggedRequests: string };
  partitionedByFlaggedStatus: boolean;
  recurringButton: { nonRecurring: string; recurring: string };
  recurringtableHeaders: TableColumn[];
  rmdNote: string;
  status: Record<TransferStatus, string>;
  statusFilter: Record<TransferStatusFilter, string>;
  transferToAccountNote: string;
};

export const generateDepositStaticDisplayStrings = (content: GetDepositsTableContent): DepositDisplayStrings => {
  const items = content.all_deposits_table?.items?.[0];

  const recurringColumns = getColumnKeyValuePair(
    items?.columns?.column as ContentstackTableColumn[],
    items?.columns?.recurring?.recurring_column as ContentstackTableColumn[],
  );

  const nonRecurringColumns = getColumnKeyValuePair(
    items?.columns?.column as ContentstackTableColumn[],
    items?.columns?.non_recurring?.non_recurring_column as ContentstackTableColumn[],
  );

  const flagsContent = items?.flag_referenceConnection?.edges?.[0]?.node ?? ({} as FlagsReferenceContent);

  return {
    flags: flagsContent,
    recurringButton: { nonRecurring: 'One-time', recurring: 'Recurring' },
    recurringtableHeaders: recurringColumns,
    nonWrappableRecurring: items?.columns?.recurring?.non_wrappable?.flatMap(f => (f ? [f] : [])) ?? [],
    nonWrappableNonRecurring: items?.columns?.non_recurring?.non_wrappable?.flatMap(f => (f ? [f] : [])) ?? [],
    nonRecurringtableHeaders: nonRecurringColumns,
    status: {
      [TransferStatus.IN_PROGRESS]: getStatusLabel(TransferStatus.IN_PROGRESS, items),
      [TransferStatus.CANCELLED]: getStatusLabel(TransferStatus.CANCELLED, items),
      [TransferStatus.COMPLETED]: getStatusLabel(TransferStatus.COMPLETED, items),
      [TransferStatus.PENDING]: getStatusLabel(TransferStatus.PENDING, items),
      [TransferStatus.PENDING_CANCELLATION]: getStatusLabel(TransferStatus.PENDING_CANCELLATION, items),
      [TransferStatus.UNRECOGNIZED]: getStatusLabel(TransferStatus.UNRECOGNIZED, items),
      [TransferStatus.PENDING_CANCELLATION]: getStatusLabel(TransferStatus.PENDING_CANCELLATION, items),
    },
    statusFilter: {
      [TransferStatusFilter.PENDING_IN_PROGRESS]: getStatusFilterLabel(TransferStatusFilter.PENDING_IN_PROGRESS, items),
      [TransferStatusFilter.PENDING]: getStatusFilterLabel(TransferStatusFilter.PENDING, items),
      [TransferStatusFilter.PENDING_CANCELLATION]: getStatusFilterLabel(
        TransferStatusFilter.PENDING_CANCELLATION,
        items,
      ),
      [TransferStatusFilter.IN_PROGRESS]: getStatusFilterLabel(TransferStatusFilter.IN_PROGRESS, items),
      [TransferStatusFilter.COMPLETED]: getStatusFilterLabel(TransferStatusFilter.COMPLETED, items),
      [TransferStatusFilter.CANCELLED]: getStatusFilterLabel(TransferStatusFilter.CANCELLED, items),
    },
    transferToAccountNote: items?.transfer_to_account_note || 'Transfer to Bank Account, linked account ending',
    endDate: items?.end_date || 'End Date',
    partitionedByFlaggedStatus: items?.requests?.partitioned_by_flagged_status || false,
    partitionTabLabels: {
      allOtherRequests: items?.requests?.partition_tabs?.find(x => x?.value === 'all_other_requests')?.label || '',
      flaggedRequests: items?.requests?.partition_tabs?.find(x => x?.value === 'flagged_requests')?.label || '',
    },
    rmdNote: items?.rmd_note || '',
  };
};

const getStatusLabel = (
  status: TransferStatus,
  items: GetDepositsTableContent_all_deposits_table_items | null | undefined,
): string => {
  const key = status.toLowerCase() as keyof GetDepositsTableContent_all_deposits_table_items_statuses;
  return items?.statuses?.[key] ?? '';
};

const getStatusFilterLabel = (
  statusFilter: TransferStatusFilter,
  items: GetDepositsTableContent_all_deposits_table_items | null | undefined,
): string => {
  const key = statusFilter.toLowerCase() as keyof GetDepositsTableContent_all_deposits_table_items_status_filters;
  return items?.status_filters?.[key] ?? '';
};
