import { SaveHarvestLosses, SaveHarvestLossesVariables } from './__generated__/mutation.v2';
import { GetHarvestLosses, GetHarvestLossesVariables } from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, QueryHookOptions } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export const useSaveHarvestLosses = (options?: MutationHookOptions<SaveHarvestLosses, SaveHarvestLossesVariables>) => {
  return useSymphonyMutation(mutations.SaveHarvestLosses, options);
};

export const useGetHarvestLosses = (options?: QueryHookOptions<GetHarvestLosses, GetHarvestLossesVariables>) => {
  return useSymphonyQuery(queries.GetHarvestLosses, options);
};
