import { ComponentProps } from 'react';

import { GetFinancialAccountAssociationVerificationRequests_financialAccountAssociationVerificationRequests_requests_entityNotes as EntityNotes } from '../symphony/__generated__/query.v2';

import {
  FinancialAccountAssociationVerificationRequestSortField,
  FinancialAccountAssociationVerificationStatus,
} from '~/__generated__';
import { SortConfig, TableColumn } from '~/components/ui/BasicTable';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { CommentComponentColumn } from '~/containers/OpsDashboard/common/Comment';
import { OpsDashboardUser } from '~/containers/OpsDashboard/utils';
import { AccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';

export interface BankVerificationDataTabVariables {
  contentOptions: ContentOptions;
  page: number;
  sortConfig: SortConfig;
  statusFilter: VerificationStatusFilter;
}

export interface BankVerificationTabData {
  bankVerificationItems: BankVerificationItem[];
  currentPage: number;
  refetchData: () => void;
  totalPages: number;
}

export interface StatusContentValue {
  key: string;
  value: string;
}

export interface StatusFilterContentValue {
  key: string;
  value: string;
}

export interface FormattedItemsProps {
  commentProps: {
    commentColumn: CommentComponentColumn | undefined;
    contentOptions: ContentOptions;
    currentUser: OpsDashboardUser;
    refetchBankVerificationData?: () => void;
  };
  content: { reviewButtonLabel: string; statuses: StatusContentValue[] };
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  handleReviewClick: (requestId: string) => void;
  items: BankVerificationItem[];
  onAccountClick: (partyId: string, managedProductId: string) => void;
  onClientClick: (partyId: string) => void;
  showProductName: boolean;
}

export type BankVerificationTabContent = {
  feedbackMessage: string;
  filterDropdownLabel: string;
  reviewButtonLabel: string;
  statusFilters: StatusFilterContentValue[];
  statuses: StatusContentValue[];
  tableColumns: TableColumn[];
};

export type BankVerificationContentTabVariables = {
  contentOptions: ContentOptions;
  onSort?: (field: string) => () => void;
  sortConfig?: SortConfig;
};

export type BankVerificationItem = {
  accountState: AccountState;
  accountType: string;
  bankAccountNumber: string;
  clientName: string;
  dateCreated: string;
  financialAccountNumber: string;
  financialInstitution: string;
  id: string;
  managedProductId: string;
  notes: EntityNotes | null;
  partyId: string;
  productName: string;
  status: FinancialAccountAssociationVerificationStatus;
};

export enum VerificationStatusFilter {
  ACCEPTED = 'ACCEPTED',
  ALL = 'ALL',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
}

export enum BankVerificationLabel {
  FEEDBACK_MESSAGE = 'FEEDBACK_MESSAGE',
  FILTER_DROPDOWN_LABEL = 'FILTER_DROPDOWN_LABEL',
  REVIEW_BUTTON_TEXT = 'REVIEW_BUTTON_TEXT',
}

export enum BankVerificationTableColumn {
  ACCOUNT_NUMBER = 'accountNumber',
  ACTIONS = 'actions',
  BANK_INFORMATION = 'bankInformation',
  CLIENT_NAME = 'clientName',
  DATE_CREATED = 'dateCreated',
  NOTES = 'notes',
  STATUS = 'status',
}

export const sortFieldToSymphony = new Map([
  ['dateCreated', FinancialAccountAssociationVerificationRequestSortField.CREATED_ON],
]);
