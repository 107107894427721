import React from 'react';
import { isIE } from 'react-device-detect';

import { CompactVariant, Indicator } from '.';

import { ArrowDropUpIcon, Box, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface Props {
  compact?: CompactVariant;
  dataQa?: string;
  editMode?: boolean;
  indicator: Indicator;
  riskBandHeightPrimary: number;
  showPortfolioBands?: boolean;
}

export const IndicatorComponent: React.FC<Props> = ({
  dataQa,
  compact,
  editMode,
  indicator,
  riskBandHeightPrimary,
  showPortfolioBands,
}) => {
  const {
    sfRiskSpectrum: { styles: sfRiskSpectrumStyles },
  } = useTheme();
  return indicator && compact !== CompactVariant.Block ? (
    <Box
      className="RiskSpectrum-indicator"
      data-qa={`${dataQa}-indicator`}
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: editMode && indicator?.isPrimary ? 'absolute' : 'relative',
        top: isIE || editMode || (!editMode && showPortfolioBands) ? riskBandHeightPrimary : 0,
        ...(indicator?.isPrimary
          ? sfRiskSpectrumStyles.indicatorPrimaryRoot
          : sfRiskSpectrumStyles.indicatorSecondaryRoot),
      }}
    >
      <ArrowDropUpIcon fontSize="large" />
      {(!compact || compact === CompactVariant.Mobile) && (
        <Typography sx={{ mt: -1, whiteSpace: 'nowrap' }} variant="caption">
          {indicator.label}
        </Typography>
      )}
    </Box>
  ) : null;
};
