import { parseISO } from 'date-fns';
import React from 'react';

import { RestrictionsHistoryTabContent } from '../contentstack';
import { RestrictionHistory } from '../symphony';

import { Field, MultiRestrictionPlacedBy } from '~/__generated__';
import { TableData } from '~/components/ui/BasicTable';
import { Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getFullName } from '~/hooks/party/utils';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

export enum RestrictionHistoryTableColumns {
  Created = 'Created',
  CreatedBy = 'CreatedBy',
  Removed = 'Removed',
  RemovedBy = 'RemovedBy',
  Type = 'Type',
}

export const SortFields: Record<string, Field> = {
  [RestrictionHistoryTableColumns.Created]: Field.DATE_ADDED,
  [RestrictionHistoryTableColumns.Removed]: Field.DATE_REMOVED,
};

export interface RestrictionsRowData {
  created?: string;
  createdByUser?: string;
  id: string;
  placedBy: string;
  removed?: string;
  removedBy: string;
  removedByUser?: string;
  type: string;
}

enum RestrictionContentKeys {
  CREATED_BY_COLUMN = 'created_by_column',
  CREATED_COLUMN = 'created_column',
  Others = 'others',
  REMOVED_BY_COLUMN = 'removed_by_column',
  REMOVED_COLUMN = 'removed_column',
  TYPE_COLUMN = 'type_column',
}

export interface Content {
  columnHeaderLabels: Record<RestrictionHistoryTableColumns, string>;
  textFields: Array<{ key: string | null; value: string | null } | null>;
}

export const getRestrictionHistoryContent = (data: RestrictionsHistoryTabContent | null | undefined): Content => {
  const content = data?.items?.[0];
  const textFields = content?.fields?.text ?? [];
  return {
    columnHeaderLabels: {
      [RestrictionHistoryTableColumns.Created]: findFieldValue(textFields, RestrictionContentKeys.CREATED_COLUMN),
      [RestrictionHistoryTableColumns.CreatedBy]: findFieldValue(textFields, RestrictionContentKeys.CREATED_BY_COLUMN),
      [RestrictionHistoryTableColumns.Removed]: findFieldValue(textFields, RestrictionContentKeys.REMOVED_COLUMN),
      [RestrictionHistoryTableColumns.RemovedBy]: findFieldValue(textFields, RestrictionContentKeys.REMOVED_BY_COLUMN),
      [RestrictionHistoryTableColumns.Type]: findFieldValue(textFields, RestrictionContentKeys.TYPE_COLUMN),
    },
    textFields,
  };
};

export const getColumns = (content: Content, onSort: (field: string) => () => void) => [
  {
    key: RestrictionHistoryTableColumns.Created,
    onSort,
    title: content.columnHeaderLabels[RestrictionHistoryTableColumns.Created],
  },
  {
    key: RestrictionHistoryTableColumns.CreatedBy,
    title: content.columnHeaderLabels[RestrictionHistoryTableColumns.CreatedBy],
  },
  {
    key: RestrictionHistoryTableColumns.Removed,
    onSort,
    title: content.columnHeaderLabels[RestrictionHistoryTableColumns.Removed],
  },
  {
    key: RestrictionHistoryTableColumns.RemovedBy,
    title: content.columnHeaderLabels[RestrictionHistoryTableColumns.RemovedBy],
  },
  {
    key: RestrictionHistoryTableColumns.Type,
    title: content.columnHeaderLabels[RestrictionHistoryTableColumns.Type],
  },
];

export const getRestrictionRowData = (
  restriction: RestrictionHistory,
  content: Content,
  contentOptions: ContentOptions,
): RestrictionsRowData => {
  const validMultiRestrictionPlacedBy = [
    MultiRestrictionPlacedBy.CUSTODIAL,
    MultiRestrictionPlacedBy.SIGFIG_TRADER,
    MultiRestrictionPlacedBy.PARTNER_OPS,
  ];
  const placedBy = validMultiRestrictionPlacedBy.includes(restriction.multiRestrictionPlacedBy)
    ? restriction.multiRestrictionPlacedBy
    : RestrictionContentKeys.Others;
  return {
    createdByUser: getFullName(restriction.addedBy?.partyPerson),
    created: formatDate(parseISO(restriction.dateAdded), 'MM/dd/yyyy', {
      locale: contentOptions.locale,
    }),
    removed: formatDate(parseISO(restriction.dateRemoved ?? ''), 'MM/dd/yyyy', {
      locale: contentOptions.locale,
    }),
    removedByUser: getFullName(restriction.removedBy?.partyPerson),
    placedBy: findFieldValue(content.textFields, placedBy.toLowerCase()),
    removedBy: findFieldValue(content.textFields, placedBy.toLowerCase()),
    type: findFieldValue(content.textFields, restriction.multiRestrictionType.toLowerCase()),
    id: `${restriction.removedMultiRestrictionId}`,
  };
};

export const getFormattedRows = ({
  content,
  contentOptions,
  restrictionData,
}: {
  content: Content;
  contentOptions: ContentOptions;
  restrictionData: RestrictionHistory[];
}): TableData[] | undefined =>
  restrictionData.map(data => {
    const restriction = getRestrictionRowData(data, content, contentOptions);

    return {
      rowKey: restriction.id,
      [RestrictionHistoryTableColumns.Created]: <Typography variant="body2">{restriction.created}</Typography>,
      [RestrictionHistoryTableColumns.CreatedBy]: (
        <Stack direction="column">
          <Typography>{restriction.placedBy}</Typography>
          <Typography variant="caption">{restriction.createdByUser}</Typography>
        </Stack>
      ),
      [RestrictionHistoryTableColumns.Removed]: restriction.removed ? (
        <Typography variant="body2">{restriction.removed}</Typography>
      ) : (
        '-'
      ),
      [RestrictionHistoryTableColumns.RemovedBy]: restriction.removedByUser ? (
        <Stack direction="column">
          <Typography>{restriction.placedBy}</Typography>
          <Typography variant="caption">{restriction.removedByUser}</Typography>
        </Stack>
      ) : (
        '-'
      ),
      [RestrictionHistoryTableColumns.Type]: <Typography variant="body2">{restriction.type}</Typography>,
    };
  });
