import { useCallback, useMemo, useState } from 'react';

import { useGetSuspensionsHistoryTabContent } from '../contentstack';
import { useGetSuspensionsHistoryData } from '../symphony';

import {
  getColumns,
  getFormattedRows,
  getSuspensionHistoryContent,
  SortFields,
  SuspensionHistoryTableColumns,
} from './utils';

import { OrderType } from '~/__generated__';
import { SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { useTheme } from '~/components/ui/mui';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Data {
  columnsData: TableColumn[];
  currentPage: number;
  onPageChange: (currentPage: number) => void;
  sortConfig: SortConfig;
  tableData: TableData[];
  totalPages: number;
}

const recordsPerPage = 10;

const useSuspensionsHistoryData = (contentOptions: ContentOptions, managedProductId: string): AsyncResult<Data> => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: SuspensionHistoryTableColumns.DateAdded,
    order: OrderType.DESCENDING,
  });

  const {
    sfSuspension: {
      styles: { suspensionTagStyleMap },
    },
  } = useTheme();

  const { data: content, error: contentError, loading: contentLoading } = useGetSuspensionsHistoryTabContent({
    variables: contentOptions,
  });

  const { data, error, loading } = useGetSuspensionsHistoryData({
    variables: {
      field: SortFields[sortConfig.field],
      managedProductId,
      order: sortConfig.order,
      pagination: {
        limit: recordsPerPage,
        offset: (currentPage - 1) * recordsPerPage,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const suspensionsHistoryContent = useMemo(() => getSuspensionHistoryContent(content), [content]);

  const onSort = useCallback(
    (field: string) => () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      setSortConfig({ order: toggleOrder, field });
      setCurrentPage(1);
    },
    [sortConfig],
  );

  const suspensionsHistoryData: Data = useMemo(() => {
    const total = data?.managedProduct?.tradingSuspensionHistory?.paginationContext.total ?? 1;

    return {
      columnsData: getColumns(suspensionsHistoryContent, onSort),
      currentPage,
      sortConfig,
      tableData:
        getFormattedRows({
          content: suspensionsHistoryContent,
          contentOptions,
          suspensionData: data?.managedProduct?.tradingSuspensionHistory?.suspensions ?? [],
          suspensionTagStyleMap,
        }) ?? [],
      totalPages: Math.ceil(total / recordsPerPage),
      onPageChange: (pageNumber: number) => setCurrentPage(pageNumber),
    };
  }, [contentOptions, currentPage, data, onSort, sortConfig, suspensionsHistoryContent, suspensionTagStyleMap]);

  return {
    data: suspensionsHistoryData,
    error: contentError ?? error,
    loading: contentLoading || loading,
  };
};

export default useSuspensionsHistoryData;
