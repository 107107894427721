import flow from 'lodash.flow';
import join from 'lodash.join';
import times from 'lodash.times';

export interface ConcealOptions {
  replaceChar: string;
  revealedChars: number;
  showAllHiddenChars: boolean;
}

const defaultConcealOptions: ConcealOptions = {
  revealedChars: 4,
  replaceChar: '*',
  showAllHiddenChars: false,
};

export const conceal = (accountNumber: string, options?: Partial<ConcealOptions>): string => {
  const { revealedChars, replaceChar, showAllHiddenChars } = {
    ...defaultConcealOptions,
    ...options,
  };
  const hiddenNumbersCount = accountNumber.length - revealedChars;
  if (hiddenNumbersCount < 1) {
    return replaceChar;
  }
  const addChars = flow([n => times(n, _n => replaceChar), n => join(n, '')]);

  return `${showAllHiddenChars ? addChars(hiddenNumbersCount) : replaceChar}${accountNumber.slice(
    accountNumber.length - revealedChars,
  )}`;
};

/**
 * Transforms the masked account number into a particular format to display.
 * @param dataFormat - The desired format to display the masked account number.
 * @param maskedAccountNumber - The masked account number.
 * @returns The formatted masked account number.
 */
export const formatMaskedAccountNumber = (
  dataFormat?: string,
  maskedAccountNumber?: string | null | undefined,
): string =>
  (dataFormat ?? '${accountNumber}')
    .replace('${accountNumber}', maskedAccountNumber?.replace('*', '') || 'UNKNOWN')
    .trim();
