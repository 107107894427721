/**
 * Instead of bringing in a whole library, I'm just copying this custom hook from:
 * https://usehooks-typescript.com/react-hook/use-local-storage
 * https://github.com/juliencrn/usehooks.ts/blob/master/packages/usehooks-ts/src/useLocalStorage/useLocalStorage.ts
 */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isIE } from 'react-device-detect';

type SetValue<T> = Dispatch<SetStateAction<T>>;

/**
 * A hook to utilize the browser's localStorage.
 * @param key - The localStorage key.
 * @param initialValue - The initial value to set.
 * @returns An array containing two elements.
 * The first is the current localStorage value and the second is a function to update the value.
 */
export const useLocalStorage = <T>(key: string, initialValue: T): [T, SetValue<T>] => {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = (): T => {
    // Prevent build error "window is undefined" but keep working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<T> = value => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window == 'undefined') {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);

      // IE 11 typeof Event is object, but other browsers it is type function so we need to initialize the event differently.
      // initEvent is deprecated in modern browsers, so we need to check if it's IE first.
      let event;
      if (isIE && typeof Event === 'object') {
        event = document.createEvent('Event');
        event.initEvent('local-storage', false, false);
      } else {
        event = new Event('local-storage');
      }

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(event);
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    window.addEventListener('local-storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue];
};
