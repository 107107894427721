import {
  GetCloseAccountContent,
  GetCloseAccountContent_all_close_account_modal_items,
  GetCloseAccountContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type CloseAccountModalContent = GetCloseAccountContent_all_close_account_modal_items;

export const useGetCloseAccountContent = (
  options?: QueryHookOptions<GetCloseAccountContent, GetCloseAccountContentVariables>,
) => {
  return useContentstackQuery(queries.GetCloseAccountContent, options);
};
