import { TransferStatus, TransferStatusFilter } from './types';

import { ScheduledTransferStatus, TransferFrequency } from '~/__generated__';

export function toCashTransferStatus(status: ScheduledTransferStatus): TransferStatus {
  switch (status) {
    // ACTIVE/IN_PROGRESS:
    case ScheduledTransferStatus.ACTIVE:
    case ScheduledTransferStatus.ACTIVE_AT_BROKERAGE:
    case ScheduledTransferStatus.SUBMITTED:
    case ScheduledTransferStatus.INSUFFICIENT_ASSETS:
    case ScheduledTransferStatus.UNAUTHORIZED:
    case ScheduledTransferStatus.TRADES_SUBMITTED:
    case ScheduledTransferStatus.CASH_GENERATED:
      return TransferStatus.IN_PROGRESS;
    // PENDING:
    case ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE:
    case ScheduledTransferStatus.PENDING:
      return TransferStatus.PENDING;
    // COMPLETED:
    case ScheduledTransferStatus.COMPLETED:
      return TransferStatus.COMPLETED;
    // PENDING_CANCELLATION
    case ScheduledTransferStatus.PENDING_CANCELLATION:
      return TransferStatus.PENDING_CANCELLATION;
    // CANCELLED:
    case ScheduledTransferStatus.CANCELLED:
      return TransferStatus.CANCELLED;
    default:
      return TransferStatus.UNRECOGNIZED;
  }
}

export const cashTransferStatusToScheduledTransferStatusForUpdate = (
  status: TransferStatus,
  frequency: TransferFrequency,
): ScheduledTransferStatus | null => {
  const recurring = frequency !== TransferFrequency.ONE_TIME;
  if (!recurring) {
    switch (status) {
      case TransferStatus.CANCELLED:
        return ScheduledTransferStatus.CANCELLED;
      case TransferStatus.COMPLETED:
        return ScheduledTransferStatus.COMPLETED;
      default:
        return null;
    }
  } else {
    switch (status) {
      case TransferStatus.CANCELLED:
        return ScheduledTransferStatus.CANCELLED;
      case TransferStatus.COMPLETED:
        return ScheduledTransferStatus.COMPLETED;
      case TransferStatus.IN_PROGRESS:
        return ScheduledTransferStatus.ACTIVE;
      case TransferStatus.PENDING_CANCELLATION:
        return ScheduledTransferStatus.PENDING_CANCELLATION;
      default:
        return null;
    }
  }
  return null;
};

export function cashTransferToAllowedStatusUpdates(status: TransferStatus, recurring = false): TransferStatus[] {
  if (!recurring) {
    switch (status) {
      case TransferStatus.PENDING:
      case TransferStatus.IN_PROGRESS:
        return [TransferStatus.CANCELLED, TransferStatus.COMPLETED];
      case TransferStatus.PENDING_CANCELLATION:
        return [TransferStatus.CANCELLED];
      default:
        return [];
    }
  } else {
    switch (status) {
      case TransferStatus.PENDING:
        return [TransferStatus.IN_PROGRESS, TransferStatus.CANCELLED];
      case TransferStatus.IN_PROGRESS:
        return [TransferStatus.CANCELLED];
      case TransferStatus.PENDING_CANCELLATION:
        return [TransferStatus.CANCELLED];
      default:
        return [];
    }
  }
  return [];
}

export function statusFilterToScheduledTransferStatus(
  statusFilter: TransferStatusFilter,
): ScheduledTransferStatus[] | null {
  switch (statusFilter) {
    case TransferStatusFilter.PENDING_IN_PROGRESS:
      return [
        ScheduledTransferStatus.PENDING,
        ScheduledTransferStatus.ACTIVE,
        ScheduledTransferStatus.ACTIVE_AT_BROKERAGE,
        ScheduledTransferStatus.SUBMITTED,
        ScheduledTransferStatus.INSUFFICIENT_ASSETS,
        ScheduledTransferStatus.UNAUTHORIZED,
        ScheduledTransferStatus.TRADES_SUBMITTED,
        ScheduledTransferStatus.CASH_GENERATED,
        ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE,
      ];
    case TransferStatusFilter.PENDING:
      return [ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE, ScheduledTransferStatus.PENDING];
    case TransferStatusFilter.PENDING_CANCELLATION:
      return [ScheduledTransferStatus.PENDING_CANCELLATION];
    case TransferStatusFilter.IN_PROGRESS:
      return [
        ScheduledTransferStatus.ACTIVE,
        ScheduledTransferStatus.ACTIVE_AT_BROKERAGE,
        ScheduledTransferStatus.SUBMITTED,
        ScheduledTransferStatus.INSUFFICIENT_ASSETS,
        ScheduledTransferStatus.UNAUTHORIZED,
        ScheduledTransferStatus.TRADES_SUBMITTED,
        ScheduledTransferStatus.CASH_GENERATED,
      ];
    case TransferStatusFilter.CANCELLED:
      return [ScheduledTransferStatus.CANCELLED];
    case TransferStatusFilter.COMPLETED:
      return [ScheduledTransferStatus.COMPLETED];
    default:
      return null;
  }
}
