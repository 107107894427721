import { useCallback, useMemo, useState } from 'react';

import { useGetRemoveTradingSuspensionsModalContent } from '../contentstack';

import { Content, getRemoveTradingSuspensionsModalContent } from './utils';

import { SuspensionType } from '~/__generated__';
import { useDeleteTradingSuspension } from '~/hooks/suspensions/symphony';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface RemoveTradingSuspensionModalData {
  content: Content;
  isBusy: boolean;
  onConfirmRemoveSuspension: () => void;
}

export interface Variables {
  contentOptions: ContentOptions;
  managedProductId: string;
  onTradingSuspensionRemoveFailure?: () => void;
  onTradingSuspensionRemoveSuccess?: () => void;
  open: boolean;
  suspensionTag: string;
  suspensionType: SuspensionType;
}

export const useRemoveTradingSuspensionModalData = ({
  contentOptions,
  managedProductId,
  onTradingSuspensionRemoveFailure,
  onTradingSuspensionRemoveSuccess,
  open,
  suspensionTag,
  suspensionType,
}: Variables): AsyncResult<RemoveTradingSuspensionModalData> => {
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const { data: content, loading, error } = useGetRemoveTradingSuspensionsModalContent({
    variables: contentOptions,
    skip: !open,
  });

  const [deleteTradingSuspension] = useDeleteTradingSuspension();

  const onConfirmRemoveSuspension = useCallback(async () => {
    setIsBusy(true);
    const mutationVariables = {
      variables: {
        managedProductId,
        suspensionType,
        suspensionTags: [suspensionTag],
      },
    };
    try {
      const toggleTradingSuspensionResponse = await deleteTradingSuspension({
        ...mutationVariables,
      });

      if (toggleTradingSuspensionResponse.data?.deleteTradingSuspension.success) {
        onTradingSuspensionRemoveSuccess?.();
      } else {
        onTradingSuspensionRemoveFailure?.();
      }
    } catch (failureError: any) {
      onTradingSuspensionRemoveFailure?.();
    } finally {
      setIsBusy(false);
    }
  }, [
    deleteTradingSuspension,
    managedProductId,
    onTradingSuspensionRemoveFailure,
    onTradingSuspensionRemoveSuccess,
    suspensionTag,
    suspensionType,
  ]);

  const data = useMemo(
    () => ({
      content: getRemoveTradingSuspensionsModalContent(content),
      isBusy,
      onConfirmRemoveSuspension,
    }),
    [content, isBusy, onConfirmRemoveSuspension],
  );

  return {
    error,
    loading,
    data,
  };
};
