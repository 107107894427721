import React from 'react';

import { commonStyles } from '../utils';

import useRestrictionsHistoryData from './hooks/useRestrictionsHistoryData';

import { Alert } from '~/components/ui/Alert';
import { BasicTable } from '~/components/ui/BasicTable';
import { ItemsPerPage } from '~/components/ui/BasicTable/ItemsPerPage';
import { TotalItems } from '~/components/ui/BasicTable/TotalItems';
import { Skeleton, Stack } from '~/components/ui/mui';
import { ContentOptions, FieldsGroup } from '~/utils/contentstack';

interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  fields: FieldsGroup | undefined | null;
  managedProductId: string;
}

const RestrictionsHistory: React.FC<Props> = ({ contentOptions, dataQa, managedProductId, fields }) => {
  const { data, error, loading } = useRestrictionsHistoryData(contentOptions, managedProductId, fields);

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (!data || error) {
    return <Alert contentOptions={contentOptions} error={error} severity="error" />;
  }

  return (
    <Stack data-qa={dataQa} sx={{ pt: 4 }}>
      <BasicTable
        BottomLeftSection={<TotalItems paginationContext={data.paginationContext} />}
        BottomRightSection={<ItemsPerPage paginationContext={data.paginationContext} />}
        alignItems="left"
        columns={data.columnsData}
        currentPage={data.currentPage}
        data={data.tableData}
        onPageChange={data.onPageChange}
        sortConfig={data.sortConfig}
        sx={commonStyles.tableSx}
        totalPages={data.totalPages}
      />
    </Stack>
  );
};

export default RestrictionsHistory;
