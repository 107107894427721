import { NumberRange } from '~/components/ui/NumberFormatRange';

interface BaseFilterConfig {
  key: string;
  label?: string;
  type: FilterTypes;
}

export interface OptionFilterConfig extends BaseFilterConfig {
  options: Option[];
}

export interface RangeFilterConfig extends BaseFilterConfig {
  maxRangeValue: number;
}

export type FilterConfig = OptionFilterConfig | RangeFilterConfig;

export interface FilterCountMap {
  count: number;
  filters: {
    [filterId: string]: string;
  };
}

export enum FilterTypes {
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  CURRENCY_RANGE = 'CURRENCY_RANGE',
  NUMBER_RANGE = 'NUMBER_RANGE',
  RADIO_GROUP = 'RADIO_GROUP',
}

type OptionFilterTypes = FilterTypes.CHECKBOX_GROUP | FilterTypes.RADIO_GROUP;
export type RangeFilterTypes = FilterTypes.CURRENCY_RANGE | FilterTypes.NUMBER_RANGE;

export interface Option {
  id: string;
  label?: string;
}

export interface SelectedOptions {
  filterType: OptionFilterTypes;
  selectedOptions: string[];
}

export interface SelectedRange {
  filterType: RangeFilterTypes;
  range: NumberRange;
}

export type SelectedFilter = SelectedOptions | SelectedRange;

export interface SelectedFilters {
  [filterId: string]: SelectedFilter | undefined;
}
