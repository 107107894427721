import { useMemo } from 'react';

import { useGetDocusignRequiredModalContent } from '../contentstack';
import { getModalContent } from '../utils';

import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

export interface Content {
  description: string;
  primaryCta: string;
  resendDocusign: string;
  secondaryCta: string;
  title: string;
}

interface Data {
  content: Content;
}

export const useGetDocusignRequiredData = ({ contentOptions }: Variables): AsyncResult<Data> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetDocusignRequiredModalContent({
    variables: contentOptions,
    skip: !open,
  });

  const data: Data | undefined = useMemo(() => {
    if (contentLoading || contentError) {
      return;
    }

    return {
      content: getModalContent(contentData),
    };
  }, [contentData, contentError, contentLoading]);

  return {
    data,
    error: contentError,
    loading: contentLoading,
  };
};
