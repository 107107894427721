import { ComponentProps, useMemo } from 'react';

import { Props } from '..';
import { AccountList } from '../AccountList';
import { AccountTotalSection as AccountTotalSectionComponent } from '../AccountTotalSection';
import { BackToMarketingPage } from '../BackToMarketingPage';
import {
  AccountListSection,
  AccountSummaryAccessibility,
  AccountTotalSection,
  isAccountListSection,
  isAccountTotalSection,
  isMarketingSection,
  isSupportQuestions,
  MarketingSection,
  SupportQuestionsSection,
  useGetAccountSummaryContent,
} from '../contentstack';
import { GetAccountSummaryContent } from '../contentstack/__generated__/queries.v2';
import { getPendingStateDescriptions } from '../utils';

import { LegalDocumentStatus } from '~/__generated__';
import { AccountTotal } from '~/components/AccountSummary/AccountTotal';
import { SupportContact } from '~/components/SupportContact';
import { AsyncResult } from '~/utils/types';

type Variables = Pick<Props, 'contentOptions' | 'hiddenSections'>;

type AccountListContent = Pick<
  ComponentProps<typeof AccountList>['content'],
  | 'accessibility'
  | 'accountNumberPrefix'
  | 'accountNumberFormat'
  | 'editPortfolioFeedbackMessage'
  | 'emptyState'
  | 'emptyStateImgUrl'
  | 'labels'
  | 'pendingStatus'
  | 'ctas'
  | 'errorStates'
  | 'shouldMaskAccountNumber'
  | 'title'
>;

type MarketingContent = Pick<
  ComponentProps<typeof BackToMarketingPage>,
  'header' | 'imageUrl' | 'subHeader' | 'video'
> & {
  ctas: { primary: string; secondary: string };
};
type SupportContactContent = Pick<
  ComponentProps<typeof SupportContact>,
  'content' | 'supportEmail' | 'supportPhoneNumber' | 'supportPhoneNumberDisplayText'
>;
type HeaderContent = {
  contactAdvisorCta: string;
  genericMessage: string;
  message: string;
};
type DocusignContent = {
  closeCta: string;
  getFeedbackMessage: (status?: LegalDocumentStatus) => string | null;
};

interface Data {
  docusignContent: DocusignContent;
  headerContent: HeaderContent;
  sectionContent: {
    accountList?: AccountListContent;
    accountTotal?: ComponentProps<typeof AccountTotalSectionComponent>['content'];
    marketing?: MarketingContent;
    supportQuestions?: SupportContactContent;
  };
}

export const useAccountSummaryContent = ({ contentOptions, hiddenSections }: Variables): AsyncResult<Data> => {
  const { data: summaryContentData, error, loading } = useGetAccountSummaryContent({
    variables: contentOptions,
  });
  const data = useMemo(() => {
    if (loading || error || !summaryContentData?.all_account_summary) {
      return undefined;
    }
    const accessibility = summaryContentData.all_account_summary.items?.[0]?.accessibility ?? undefined;
    return summaryContentData.all_account_summary.items?.[0]?.sections?.reduce<Data>(
      (acc, section) => {
        if (isAccountListSection(section) && !hiddenSections?.includes('accountList')) {
          acc.sectionContent.accountList = getAccountListContent(section, accessibility);
        }
        if (isAccountTotalSection(section) && !hiddenSections?.includes('accountTotal')) {
          acc.sectionContent.accountTotal = {
            accountTotal: getAccountTotalContent(section),
          };

          if (!hiddenSections?.includes('performanceChart')) {
            acc.sectionContent.accountTotal.performanceChart = getPerformanceChartContent(section, accessibility);
          }
        }
        if (isSupportQuestions(section) && !hiddenSections?.includes('supportQuestions')) {
          acc.sectionContent.supportQuestions = getSupportContactContent(section);
        }
        if (isMarketingSection(section) && !hiddenSections?.includes('marketing')) {
          acc.sectionContent.marketing = getMarketingContent(section);
        }

        return acc;
      },
      {
        docusignContent: getDocusignContent(summaryContentData),
        headerContent: getHeaderContent(summaryContentData),
        sectionContent: {},
      },
    );
  }, [error, hiddenSections, loading, summaryContentData]);

  return { data, loading, error };
};

const getAccountListContent = (
  section: AccountListSection,
  accessibility?: AccountSummaryAccessibility,
): AccountListContent => {
  const accountListContent = section.account_list;
  const pendingDescriptions = getPendingStateDescriptions(accountListContent?.pending_states);
  return {
    title: accountListContent?.title ?? '',
    accessibility: {
      accountMenuAriaLabel: accessibility?.account_menu_label ?? 'More actions',
    },
    accountNumberPrefix: accountListContent?.account_number_prefix ?? '',
    shouldMaskAccountNumber: accountListContent?.mask_account_number ?? true,
    accountNumberFormat: accountListContent?.account_number_format_v2Connection?.edges?.[0]?.node?.pattern ?? '',
    editPortfolioFeedbackMessage: accountListContent?.edit_portfolio_feedback_message ?? '',
    emptyState: accountListContent?.empty_state ?? '',
    emptyStateImgUrl: accountListContent?.empty_state_imageConnection?.edges?.[0]?.node?.url ?? '',
    labels: {
      balance: accountListContent?.labels?.balance ?? '',
      effectiveDate: accountListContent?.labels?.effective_date ?? '',
      modelPortfolio: accountListContent?.labels?.model_portfolio ?? '',
      partialAccount: accountListContent?.labels?.partial_account ?? '',
      pendingModelChange: accountListContent?.labels?.pending_model_change ?? '',
      pendingModelChangeGenericText: accountListContent?.labels?.pending_model_change_generic_text ?? '',
      pendingModelChangeModalDiscardMessage:
        accountListContent?.labels?.pending_model_change_modal_discard_message ?? '',
      pendingModelChangeModalSuccessfulDiscard:
        accountListContent?.labels?.pending_model_change_modal_successful_discard ?? '',
      pendingModelChangeModalTitle: accountListContent?.labels?.pending_model_change_modal_title ?? '',
      resendEmailSentMessage: accountListContent?.labels?.resend_docusign_email_sent_message ?? '',
      ytdReturn: accountListContent?.labels?.ytd_return ?? '',
      compositeModelPortfolio: accountListContent?.labels?.composite_model_portfolio ?? '',
    },
    pendingStatus: {
      labels: {
        sign: accountListContent?.labels?.sign ?? '',
        activate: accountListContent?.labels?.activate ?? '',
        fund: accountListContent?.labels?.fund ?? '',
        rebalance: accountListContent?.labels?.rebalance ?? '',
        pending_model_change: accountListContent?.labels?.pending_model_change ?? '',
      },
      descriptions: pendingDescriptions,
    },
    ctas: {
      pendingModelChangeModalClose: accountListContent?.ctas?.pending_model_change_close ?? '',
      pendingModelChangeModalCancel: accountListContent?.ctas?.pending_model_change_cancel ?? '',
      pendingModelChangeModalDiscard: accountListContent?.ctas?.pending_model_change_discard ?? '',
      addFunds: accountListContent?.ctas?.add_funds ?? '',
      approve: accountListContent?.ctas?.approve ?? '',
      openNewAccount: accountListContent?.ctas?.open_a_new_account ?? '',
      continuePendingAccount: accountListContent?.ctas?.continue_pending_account ?? '',
      finishPendingAccount: accountListContent?.ctas?.finish_pending_account ?? '',
      resendDocusign: accountListContent?.ctas?.resend_docusign ?? '',
      signDocuments: accountListContent?.ctas?.sign_documents ?? '',
      generateDocuments: accountListContent?.ctas?.generate_documents ?? '',
    },
    errorStates: {
      suspended: accountListContent?.error_states?.suspended ?? '',
      pendingClosed: accountListContent?.error_states?.pending_closed ?? '',
      needsAttention: accountListContent?.error_states?.needs_attention ?? '',
    },
  };
};
const getAccountTotalContent = (section: AccountTotalSection): ComponentProps<typeof AccountTotal>['content'] => {
  const accountTotalContent = section.balance;
  return {
    title: accountTotalContent?.title ?? '',
    balance: accountTotalContent?.labels?.balance ?? '',
    balanceRedactor: accountTotalContent?.labels?.balance_redactor ?? '',
    dataAsOf: accountTotalContent?.labels?.data_as_of ?? '',
    dateTimeFormat: accountTotalContent?.labels?.date_time_format || 'MM/dd/yyyy, HH:mm',
  };
};
const getHeaderContent = (content?: GetAccountSummaryContent): HeaderContent => {
  const headerContent = content?.all_account_summary?.items?.[0]?.header;
  return {
    contactAdvisorCta: headerContent?.contact_advisor_button_label ?? '',
    genericMessage: headerContent?.generic_message ?? '',
    message: headerContent?.message ?? '',
  };
};
const getMarketingContent = (section: MarketingSection): MarketingContent => {
  const { heading, imageConnection, primary_cta, secondary_cta, sub_heading, video_url } = section.marketing ?? {};
  return {
    ctas: { primary: primary_cta ?? '', secondary: secondary_cta ?? '' },
    header: heading ?? '',
    imageUrl: imageConnection?.edges?.[0]?.node?.url ?? '',
    subHeader: sub_heading ?? '',
    video: { ctaText: primary_cta ?? '', url: video_url ?? '' },
  };
};
const getPerformanceChartContent = (section: AccountTotalSection, accessibility?: AccountSummaryAccessibility) => {
  const accountTotalContent = section.balance;
  return {
    accessibility: { infoIcon: accessibility?.performance_chart_label ?? 'info icon' },
    disclosure: accountTotalContent?.chart?.disclosure ?? undefined,
    detailedTableCta: accountTotalContent?.chart?.detailed_table_cta ?? '',
    dialog: accountTotalContent?.chart?.information_dialog ?? undefined,
  };
};
const getDocusignContent = (content?: GetAccountSummaryContent): DocusignContent => {
  const docusignContent = content?.all_account_summary?.items?.[0]?.docusign_redirect_snackbar;
  return {
    closeCta: docusignContent?.close_button_label ?? '',
    getFeedbackMessage: status => {
      switch (status) {
        case LegalDocumentStatus.SUCCEEDED:
          return null;
        case LegalDocumentStatus.DECLINED:
          return docusignContent?.decline_message ?? null;
        default:
          return docusignContent?.message ?? null;
      }
    },
  };
};
const getSupportContactContent = (section: SupportQuestionsSection): SupportContactContent => {
  const supportContactContent = section.questions?.support_contact_informationConnection?.edges?.[0]?.node?.content;
  return {
    content: {
      title: supportContactContent?.title ?? '',
      body: supportContactContent?.body ?? '',
      helpQuestions: supportContactContent?.help_questions?.map(question => question ?? '') ?? [],
      launchSchedulingToolCta: supportContactContent?.talk_to_fa_cta?.has_scheduling_tool
        ? supportContactContent.talk_to_fa_cta.launch_scheduling_tool ?? ''
        : undefined,
      callPhoneNumberCta: !supportContactContent?.talk_to_fa_cta?.has_scheduling_tool
        ? supportContactContent?.talk_to_fa_cta?.call_phone_number ?? undefined
        : undefined,
      contactAdvisorCta: supportContactContent?.contact_advisor_button_label ?? '',
      extra: supportContactContent?.extra ?? undefined,
      footer: supportContactContent?.support_footer ?? '',
    },
    supportEmail: supportContactContent?.variables?.support_email ?? '',
    supportPhoneNumber: supportContactContent?.variables?.support_phone ?? '',
    supportPhoneNumberDisplayText: supportContactContent?.variables?.support_phone_display_text ?? '',
  };
};
