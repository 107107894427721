import { ComponentProps } from 'react';

import { CommentComponentColumn } from '../../common/Comment';
import { OpsDashboardUser } from '../../utils';
import { GetFinancialAccountAssociationVerificationRequestsV2_financialAccountAssociationVerificationRequests_requests_entityNotes as EntityNotes } from '../symphony/__generated__/query.v2';

import {
  FinancialAccountAssociationVerificationRequestSortField,
  FinancialAccountAssociationVerificationStatus,
} from '~/__generated__';
import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { FilterConfig, FilterCountMap, FilterTypes, SelectedFilters } from '~/components/Filters/types';
import { getSelectedOptions } from '~/components/Filters/utils';
import { SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { TextFields } from '~/containers/OpsDashboardV2/AllAccountsTab/contentstack';
import { getFinancialAccountAssociationVerificationStatusFromVerificationStatusFilter } from '~/containers/OpsDashboardV2/BankVerificationTab/utils/mappers';
import { GetOpsDashboardContentV2 } from '~/containers/OpsDashboardV2/contentstack/__generated__/query.v2';
import { AccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { PaginationContext, SearchContext } from '~/utils/types';

export interface BankVerificationTabData {
  appliedFilters: SelectedFilters;
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  bankVerificationFilterConfig: FilterConfig[];
  currentPage: number;
  currentSearchFilter: string;
  filterCountMap: FilterCountMap[];
  filtersContent: ComponentProps<typeof Filters>['content'];
  filtersPopupOpen: boolean;
  onAppliedFiltersSelect: (filterKey?: string, selectedOption?: string) => void;
  onFilterChange: (selectedFilters: SelectedFilters) => void;
  onPageChange: (pageNumber: number) => void;
  onRowHover: (rowId: string | undefined) => void;
  onSearchChange: (value: string | null) => void;
  paginationContext: PaginationContext;
  refetchData: () => void;
  searchContext: SearchContext;
  searchError: boolean;
  sortConfig: SortConfig;
  tableColumns: TableColumn[];
  tableData: TableData[];
  totalPages: number;
  updateOpenFiltersPopup: (value: boolean) => void;
}

export interface FormattedItemsProps {
  activeRowId?: string | undefined;
  commentProps: {
    commentColumn: CommentComponentColumn | undefined;
    contentOptions: ContentOptions;
    currentUser: OpsDashboardUser;
    refetchBankVerificationData?: () => void;
  };
  content: { reviewButtonLabel: string; textFields: TextFields };
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  handleReviewClick: (requestId: string) => void;
  items: BankVerificationItem[];
  onAccountClick: (partyId: string, managedProductId: string) => void;
  onClientClick: (partyId: string) => void;
  opsContentData?: GetOpsDashboardContentV2;
  showProductName: boolean;
}

export type BankVerificationContentTabVariables = {
  contentOptions: ContentOptions;
  onSort?: (field: string) => () => void;
  sortConfig?: SortConfig;
};

export type BankVerificationItem = {
  accountState: AccountState;
  accountType: string;
  bankAccountNumber: string;
  clientName: string;
  dateCreated: string;
  financialAccountNumber: string;
  financialInstitution: string;
  id: string;
  managedProductId: string;
  notes: EntityNotes | null;
  partyId: string;
  productName: string;
  status: FinancialAccountAssociationVerificationStatus;
};

export enum VerificationStatusFilter {
  ACCEPTED = 'ACCEPTED',
  ALL = 'ALL',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
}

export enum BankVerificationLabel {
  FEEDBACK_MESSAGE = 'FEEDBACK_MESSAGE',
  FILTER_DROPDOWN_LABEL = 'FILTER_DROPDOWN_LABEL',
  REVIEW_BUTTON_TEXT = 'REVIEW_BUTTON_TEXT',
}

export enum BankVerificationFilters {
  STATUS = 'STATUS',
}

export const BankVerificationFilterConfig = [
  {
    key: BankVerificationFilters.STATUS,
    options: [
      {
        id: VerificationStatusFilter.ACCEPTED,
      },
      {
        id: VerificationStatusFilter.IN_REVIEW,
      },
      {
        id: VerificationStatusFilter.REJECTED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
];

export const BankVerificationDefaultAppliedFilters: ComponentProps<typeof Filters>['defaultAppliedFilters'] = {
  [BankVerificationFilters.STATUS]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      VerificationStatusFilter.REJECTED,
      VerificationStatusFilter.IN_REVIEW,
      VerificationStatusFilter.ACCEPTED,
    ],
  },
};

export const sortFieldToSymphony = new Map([
  ['dateCreated', FinancialAccountAssociationVerificationRequestSortField.CREATED_ON],
]);

export const geSelectedFilterStatuses = (
  appliedFilters: ComponentProps<typeof Filters>['appliedFilters'],
): FinancialAccountAssociationVerificationStatus[] => {
  const statusFilters = (getSelectedOptions(appliedFilters[BankVerificationFilters.STATUS]) ??
    []) as VerificationStatusFilter[];
  const verificationFilters = statusFilters.length ? statusFilters : [VerificationStatusFilter.ALL];
  return verificationFilters
    .map(v => getFinancialAccountAssociationVerificationStatusFromVerificationStatusFilter(v))
    .flat();
};
