import { useMemo } from 'react';

import { useAccountsDrawerContent } from '../contentstack';

import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

interface Content {
  backToAccountDetailsLabel: string;
  backToAccountSummaryLabel: string;
  heading: string;
}

export const useAccountDrawerContentLabels = ({ contentOptions }: Variables): AsyncResult<Content> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useAccountsDrawerContent({
    variables: contentOptions,
  });

  const data: Content = useMemo(() => {
    const content = contentData?.all_accounts_drawer?.items?.[0];
    const textFields = content?.fields?.text ?? [];
    return {
      heading: findFieldValue(textFields, 'account_drawer_heading'),
      backToAccountSummaryLabel: findFieldValue(textFields, 'back_to_account_summary'),
      backToAccountDetailsLabel: findFieldValue(textFields, 'back_to_account_details'),
    };
  }, [contentData]);

  return {
    data,
    error: contentError,
    loading: contentLoading,
  };
};
