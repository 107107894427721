import {
  GetAccountPerformanceContent,
  GetAccountPerformanceContent_all_account_details_items_sections,
  GetAccountPerformanceContent_all_account_details_items_sections_AccountDetailsSectionsAccountActivity,
  GetAccountPerformanceContent_all_account_details_items_sections_AccountDetailsSectionsAssetAllocation,
  GetAccountPerformanceContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type AccountPerformanceSection = GetAccountPerformanceContent_all_account_details_items_sections;
export type AssetAllocationSection = GetAccountPerformanceContent_all_account_details_items_sections_AccountDetailsSectionsAssetAllocation;
export type AccountActivitySection = GetAccountPerformanceContent_all_account_details_items_sections_AccountDetailsSectionsAccountActivity;

export const isAssetAllocationSection = (
  section: AccountPerformanceSection | null,
): section is AssetAllocationSection => section?.__typename === 'AccountDetailsSectionsAssetAllocation';

export const isAccountActivitySection = (
  section: AccountPerformanceSection | null,
): section is AccountActivitySection => section?.__typename === 'AccountDetailsSectionsAccountActivity';

// TODO: this hook will get all contentstack data for account performance
export const useGetAccountPerformanceContent = (
  options?: QueryHookOptions<GetAccountPerformanceContent, GetAccountPerformanceContentVariables>,
): QueryResult<GetAccountPerformanceContent, GetAccountPerformanceContentVariables> => {
  return useContentstackQuery(queries.GetAccountPerformanceContent, options);
};
