import { parseISO } from 'date-fns';
import React from 'react';

import { Comment } from '../../common/Comment';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import {
  getAccountNumberOrAccountTypeString,
  getLastEditDateAndTime,
  getSortedPartnerOpsTradingSuspensions,
  isAccountNumberClickable,
} from '../../utils';
import { TextFields } from '../contentstack';
import { GetErrorsTableContentV2 } from '../contentstack/__generated__/query.v2';
import { ErrorsFailure } from '../symphony';
import { GetErrorsV2_failures_filterContext } from '../symphony/__generated__/query.v2';
import {
  EntityTypes,
  ErrorFilters,
  ErrorsFailureContent,
  ErrorsStatusFilters,
  ErrorsTypeFilters,
  ErrorTabContentKeys,
  ErrorTableContent,
  FailureType,
  FailureTypeContent,
  FailureTypeContentKey,
} from '../types';

import { EntityType, FinancialAccountType, ManagedProductStatus, UserNoteEntityType } from '~/__generated__';
import { SelectedFilters } from '~/components/Filters/types';
import { getSelectedOptions } from '~/components/Filters/utils';
import { AccountErrorType } from '~/components/modals/AccountErrors';
import { StatusButton } from '~/components/StatusButton';
import { TableColumn } from '~/components/ui/BasicTable';
import { CopyField } from '~/components/ui/CopyField';
import { Link } from '~/components/ui/Link';
import { Box, Skeleton, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';
import { ContentstackTableColumn, sortColumnsByColumnOrder } from '~/utils/table';

interface NfsErrorResponse {
  errorCode: number;
  errors: [
    {
      errorCode: number;
      field?: string;
      kind: string;
      message: string;
    },
  ];
  httpStatusCode: number;
  message: string;
}

export const getErrorTypeValue = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.COPILOT_PORTFOLIO:
      return AccountErrorType.AccountError;
    case EntityType.BANK_ACCOUNT_ASSOCIATION:
    case EntityType.CASH_DEPOSIT:
    case EntityType.CASH_WITHDRAWAL:
    case EntityType.RECURRING_CASH_DEPOSIT:
    case EntityType.RECURRING_CASH_WITHDRAWAL:
      return AccountErrorType.MoneyMovementError;
    case EntityType.ASSET_DEPOSIT:
      return AccountErrorType.TOAError;
    default:
      return AccountErrorType.OtherError;
  }
};

export const getErrorTypeContentValue = (entityType: EntityType, textFields: TextFields) => {
  const errorType = getErrorTypeValue(entityType);
  switch (errorType) {
    case AccountErrorType.AccountError:
      return findFieldValue(textFields, ErrorsTypeFilters.ACCOUNT.toLowerCase());
    case AccountErrorType.MoneyMovementError:
      return findFieldValue(textFields, ErrorsTypeFilters.MONEY_MOVEMENT.toLowerCase());
    case AccountErrorType.TOAError:
      return findFieldValue(textFields, ErrorsTypeFilters.TOA.toLowerCase());
    case AccountErrorType.OtherError:
      return findFieldValue(textFields, ErrorsTypeFilters.OTHER.toLowerCase());
  }
};

export const tableErrorsFactory = (
  errorsFailure: ErrorsFailure[],
  contentOptions: ContentOptions,
  failureTypesContent: (FailureTypeContent | null)[],
): ErrorsFailureContent[] => {
  return errorsFailure.map((row: ErrorsFailure) => {
    const entityNotes = row.entityNotes.length ? row.entityNotes[0] : null;
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(row.managedProduct.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = row.managedProduct.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = row.managedProduct.clientParty?.id ?? '';
    // only used for activated account states
    const accountState = getAccountState({
      firstRebalancedOn: row.managedProduct.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;
    return {
      created: row.created,
      entity: row.entity,
      entityId: row.entityId,
      id: `${row.id}`,
      ulid: row.ulid,
      isResolved: row.isResolved,
      comment: entityNotes
        ? {
            lastValue: entityNotes.note,
            ...getLastEditDateAndTime(new Date(entityNotes.created), contentOptions),
          }
        : undefined,
      lastCommentPartyId: entityNotes?.createdByPartyId,
      failureType: getErrorLabel(row, failureTypesContent),
      description: getErrorDescription({ description: row.description, entity: row.entity, appendErrorKey: true }),
      managedProduct: {
        accountState,
        clientParty: {
          id: partyId,
          partyPerson: {
            familyName: row.managedProduct.clientParty?.partyPerson?.familyName,
            givenName: row.managedProduct.clientParty?.partyPerson?.givenName,
          },
        },
        financialAccountNumber: row.managedProduct.financialAccountNumber,
        accountType: row.managedProduct.accountType,
        program: row.managedProduct.program,
        attributes: row.managedProduct.attributes,
      },
      managedProductId: row.managedProductId,
    };
  });
};

export const getErrorLabel = (
  { entity, failureType }: ErrorsFailure,
  failureTypesContent: (FailureTypeContent | null)[],
): string => {
  const errorTypeValue = getErrorTypeValue(entity);
  switch (errorTypeValue) {
    case AccountErrorType.AccountError:
      switch (failureType) {
        case FailureType.ACCOUNT_RESTRICTION:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.ACCOUNT_RESTRICTION_ERROR,
          );
        case FailureType.POST_SIGNING_FAILURE:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.POST_SIGNING_ERROR,
          );
        case FailureType.REQUEST_CLOSE_FAILURE:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.REQUEST_CLOSE_ERROR,
          );
        case FailureType.UPDATE_ACCOUNT_STATUS_FAILURE:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.UPDATE_ACCOUNT_STATUS_ERROR,
          );
      }
      return failureType;
    case AccountErrorType.MoneyMovementError:
      switch (failureType) {
        case FailureType.CANCELLATION_AT_BROKERAGE:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.CANCELLATION_AT_BROKERAGE_ERROR,
          );
        case FailureType.CREATE_RECEIPT:
          if (entity === EntityType.CASH_DEPOSIT || entity === EntityType.RECURRING_CASH_DEPOSIT) {
            return mapErrorFailureTypeToFailureTypesContent(
              failureTypesContent,
              FailureTypeContentKey.ADD_FUNDS_REQUEST_ERROR,
            );
          }
          if (entity === EntityType.CASH_WITHDRAWAL || entity === EntityType.RECURRING_CASH_WITHDRAWAL) {
            return mapErrorFailureTypeToFailureTypesContent(
              failureTypesContent,
              FailureTypeContentKey.WITHDRAW_FUNDS_REQUEST_ERROR,
            );
          }
          return failureType;
        case FailureType.CREATE_STANDING_INSTRUCTIONS:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.CREATE_STANDING_INSTRUCTIONS_ERROR,
          );
        default:
          return failureType;
      }
    case AccountErrorType.TOAError:
      if (failureType === FailureType.CREATION_AT_BROKERAGE) {
        return mapErrorFailureTypeToFailureTypesContent(
          failureTypesContent,
          FailureTypeContentKey.TOA_ERROR_AT_CUSTODIAN,
        );
      }
      return failureType;
    default:
      return failureType;
  }
};

/**
 * @param {(FailureTypeContent|null)[]} failureTypesContent List of Failure Types Display strings fetched from contentstack
 * @param {FailureTypeContentKey} failureTypesContentKey The key for which display string is required.
 * @return {string} Display string defined in Content Stack for a failureType. Default value `failureType` which is passed to the function is return
 * */
const mapErrorFailureTypeToFailureTypesContent = (
  failureTypesContent: (FailureTypeContent | null)[],
  failureTypesContentKey: FailureTypeContentKey,
) =>
  failureTypesContent.find(el => el?.failure_type_key === failureTypesContentKey)?.failure_type_value ??
  failureTypesContentKey;

export const getErrorDescription = ({
  description,
  entity,
  appendErrorKey,
}: {
  appendErrorKey?: boolean;
  description: string;
  entity?: EntityType;
}): string => {
  if (
    entity &&
    getErrorTypeValue(entity) === AccountErrorType.MoneyMovementError &&
    description.includes('Error while verifying bank account for bankAccountAssociationId')
  ) {
    return `Unable to access EWS bank verification to verify bank account. ${description}`;
  }
  if (description.includes('Cannot map `null` into type int')) {
    const firstBracketIndex = description.indexOf('{');
    const lastBracketIndex = description.lastIndexOf('}');
    const errorString = description.substring(firstBracketIndex, lastBracketIndex + 1);
    if (errorString) {
      try {
        const errorParsed = JSON.parse(errorString);
        const errorDescription = errorParsed.errors
          .map((error: NfsErrorResponse) => {
            return error.message;
          })
          .join(' ');
        return appendErrorKey ? `Error: ${errorDescription} ${description}` : errorDescription;
      } catch (error) {
        return description;
      }
    }
  }
  return description;
};

const shouldShowViewDetails = (row: ErrorsFailureContent): boolean => {
  const errorType = getErrorTypeValue(row.entity);
  return errorType === AccountErrorType.TOAError || errorType === AccountErrorType.MoneyMovementError;
};

export const getTableContent = ({
  contentOptions,
  currentUser,
  textFields,
  handleResolveClick,
  handleOpenModal,
  mpData,
  onAccountClick,
  onClientClick,
  getAccountNumberRedirectUrl,
  getClientNameRedirectUrl,
  commentColumn,
  showProductName,
  opsDashboardContent,
  refetchData,
  updateFailuresLoading,
  errorId,
  activeRowId,
}: ErrorTableContent) => {
  const clientName = (row: ErrorsFailureContent) => {
    const partyPerson = row.managedProduct.clientParty.partyPerson;
    return `${partyPerson?.givenName ?? ''}  ${partyPerson?.familyName ?? ''}`;
  };
  const accountTypeContent = opsDashboardContent?.all_account_type?.items || [];
  const productNameContent = opsDashboardContent?.all_product_name?.items || [];
  const opsContent = opsDashboardContent?.all_ops_dashboard?.items?.[0];
  const copyLabel = findFieldValue(opsContent?.fields?.text, 'copy_ulid_label');
  const successCopyLabel = findFieldValue(opsContent?.fields?.text, 'copy_success_label');
  const statusMap = getStatusMap(textFields);

  return mpData.map(row => ({
    rowKey: `${row.ulid}`,
    accountNumber: (
      <AccountNumber
        accountNumber={row.managedProduct.financialAccountNumber}
        accountNumberText={getAccountNumberOrAccountTypeString(
          row.managedProduct.financialAccountNumber ?? null,
          getAccountTypeText(
            row.managedProduct.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
            accountTypeContent,
          ),
        )}
        label={
          showProductName
            ? getAccountProgramText(row.managedProduct.program, row.managedProduct.attributes, productNameContent)
            : null
        }
        onClick={
          isAccountNumberClickable(row.managedProduct.accountState)
            ? () =>
                row.managedProduct.clientParty.id && row.managedProductId
                  ? onAccountClick(row.managedProduct.clientParty.id, row.managedProductId)
                  : undefined
            : undefined
        }
        redirectUrl={
          row.managedProduct.clientParty.id &&
          row.managedProductId &&
          isAccountNumberClickable(row.managedProduct.accountState)
            ? getAccountNumberRedirectUrl(row.managedProduct.clientParty.id, row.managedProductId)
            : undefined
        }
      />
    ),
    clientName: (
      <ClientName
        clientName={clientName(row)}
        onClick={() => onClientClick(row.managedProduct.clientParty.id as string)}
        redirectUrl={
          row.managedProduct.clientParty.id ? getClientNameRedirectUrl(row.managedProduct.clientParty.id) : undefined
        }
      />
    ),
    created: formatDate(parseISO(row.created)),
    errorType: getErrorTypeContentValue(row.entity, textFields),
    details: (
      <Stack>
        <Typography color="text.primary" variant="body2">
          {row.failureType?.replace(/_/g, ' ')}
        </Typography>
        <Box whiteSpace="pre-wrap">
          <Typography color="text.secondary" variant="body2">
            {row.description}
          </Typography>
        </Box>
        {shouldShowViewDetails(row) && (
          <Link color="primary.main" onClick={() => handleOpenModal(row)} sx={{ textAlign: 'left', fontSize: '14px' }}>
            {findFieldValue(textFields, ErrorTabContentKeys.VIEW_ADDITIONAL_DETAILS)}
          </Link>
        )}
      </Stack>
    ),
    status:
      updateFailuresLoading && row.id === errorId ? (
        <Skeleton />
      ) : (
        <Stack direction="row">
          <StatusButton
            currentStatus={row.isResolved ? ErrorTabContentKeys.RESOLVED : ErrorTabContentKeys.OPEN}
            onMenuItemClick={_ => handleResolveClick(row.entity, row.ulid)}
            showDefaultButton
            statusLabels={statusMap}
            statusUpdateItems={row.isResolved ? [] : [ErrorTabContentKeys.MARK_AS_RESOLVED]}
          />
          <Stack justifyContent="center" sx={{ width: '30px', px: 1, fontSize: 16 }}>
            {activeRowId === `${row.ulid}` && (
              <CopyField
                copyLabel={copyLabel}
                defaultState
                disableToggling
                successLabel={successCopyLabel}
                textToCopy={`${row.ulid}`}
              />
            )}
          </Stack>
        </Stack>
      ),
    actions: (
      <Comment
        comment={row.comment}
        content={commentColumn}
        contentOptions={contentOptions}
        currentUser={currentUser}
        entity={UserNoteEntityType.FAILURE}
        entityId={row.id.toString()}
        key={row.id.toString()}
        lastCommentPartyId={row.lastCommentPartyId}
        refetchData={refetchData}
      />
    ),
  }));
};

export const getTableColumns = (contentData: GetErrorsTableContentV2 | undefined, onSort: () => void) => {
  const columns = contentData?.all_errors_table?.items?.[0]?.columns;
  const contentstackTableColumn =
    columns?.reduce<ContentstackTableColumn[]>((acc, column: ContentstackTableColumn | null) => {
      if (column) {
        acc.push(column);
      }

      return acc;
    }, []) ?? [];

  const sortedColumns = sortColumnsByColumnOrder(contentstackTableColumn);

  return sortedColumns.map(
    (col): TableColumn => ({
      title: col.column_value,
      key: col.column_id ?? '',
      ...(col.column_id === 'created'
        ? {
            onSort: (_: string) => () => {
              onSort();
            },
          }
        : {}),
    }),
  );
};

export const getFilterCountMap = (filterContext: GetErrorsV2_failures_filterContext[]) => {
  return filterContext
    .filter(v => v.filtersKey.entity && EntityTypes.includes(v.filtersKey.entity))
    .map(v => {
      return {
        count: v.count,
        filters: {
          [ErrorFilters.STATUS]: v.filtersKey.isResolved ? ErrorsStatusFilters.RESOLVED : ErrorsStatusFilters.OPEN,
          [ErrorFilters.ERROR_TYPE]: `${entityTypeToErrorType(v.filtersKey.entity)}`,
        },
      };
    });
};

export const getHasResolvedErrors = (appliedFilters: SelectedFilters): boolean | undefined => {
  const selectedOptions = getSelectedOptions(appliedFilters[ErrorFilters.STATUS]);
  return selectedOptions?.length === 1 ? selectedOptions[0] === ErrorsStatusFilters.RESOLVED : undefined;
};

const entityTypeToErrorType = (entity: EntityType | null): ErrorsTypeFilters | null => {
  switch (entity) {
    case EntityType.COPILOT_PORTFOLIO:
      return ErrorsTypeFilters.ACCOUNT;
    case EntityType.BANK_ACCOUNT_ASSOCIATION:
    case EntityType.CASH_DEPOSIT:
    case EntityType.CASH_WITHDRAWAL:
    case EntityType.RECURRING_CASH_DEPOSIT:
    case EntityType.RECURRING_CASH_WITHDRAWAL:
      return ErrorsTypeFilters.MONEY_MOVEMENT;
    case EntityType.ASSET_DEPOSIT:
      return ErrorsTypeFilters.TOA;
    case EntityType.BANK_ACCOUNT:
      return ErrorsTypeFilters.OTHER;
    default:
      return null;
  }
};

const errorTypeToEntityTypes = (errorType: ErrorsTypeFilters): EntityType[] => {
  switch (errorType) {
    case ErrorsTypeFilters.ACCOUNT:
      return [EntityType.COPILOT_PORTFOLIO];
    case ErrorsTypeFilters.MONEY_MOVEMENT:
      return [
        EntityType.BANK_ACCOUNT_ASSOCIATION,
        EntityType.CASH_DEPOSIT,
        EntityType.CASH_WITHDRAWAL,
        EntityType.RECURRING_CASH_DEPOSIT,
        EntityType.RECURRING_CASH_WITHDRAWAL,
      ];
    case ErrorsTypeFilters.TOA:
      return [EntityType.ASSET_DEPOSIT];
    case ErrorsTypeFilters.OTHER:
      return [EntityType.BANK_ACCOUNT];
  }
};

export const getEntityTypeFilters = (appliedFilters: SelectedFilters): EntityType[] => {
  const entityTypes = getSelectedOptions(appliedFilters[ErrorFilters.ERROR_TYPE]);
  if (entityTypes?.length) {
    return entityTypes.map(v => errorTypeToEntityTypes(v as ErrorsTypeFilters)).flat();
  }
  return EntityTypes;
};

const getStatusMap = (textFields: TextFields) => {
  return {
    [ErrorTabContentKeys.OPEN]: findFieldValue(textFields, ErrorTabContentKeys.OPEN),
    [ErrorTabContentKeys.RESOLVED]: findFieldValue(textFields, ErrorTabContentKeys.RESOLVED),
    [ErrorTabContentKeys.MARK_AS_RESOLVED]: findFieldValue(textFields, ErrorTabContentKeys.MARK_AS_RESOLVED),
  };
};
