import React, { useCallback, useEffect, useState } from 'react';

import { commonStyles } from '../utils';

import useActiveTradingSuspensionsData from './hooks/useActiveTradingSuspensionsData';

import { RemoveTradingSuspension } from '~/components/modals/RemoveTradingSuspension';
import { Alert } from '~/components/ui/Alert';
import { BasicTable } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Button, CheckIcon, IconButton, InfoOutlinedIcon, Skeleton, Stack } from '~/components/ui/mui';
import { Snackbar, SnackbarPositionType } from '~/components/ui/Snackbar';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onCountUpdate: (count: number | undefined) => void;
}

const ActiveSuspensions: React.FC<Props> = ({ contentOptions, dataQa, managedProductId, onCountUpdate }) => {
  const {
    components: {
      sfActiveSuspensions: { addPartnerOpsSuspensionAllowed },
    },
  } = useCoreConfig();
  const [snackbarMessage, setSnackbarMessaage] = useState<string>();
  const [showSnackBar, setShowSnackbar] = useState(false);

  const {
    open: isOpenRemoveTradingSuspensionModal,
    openModal: openRemoveTradingSuspensionModal,
    onClose: onCloseRemoveTradingSuspensionModal,
  } = useModalState();

  const onAddTradingSuspensionSuccess = useCallback(
    (message: string | undefined, refetchActiveSuspensions: () => void) => {
      refetchActiveSuspensions();
      setShowSnackbar(false); // To close the existing opened snackbar, this will restart the time of new snackbar
      setSnackbarMessaage(message);
      setShowSnackbar(true);
    },
    [],
  );

  const { data, error, loading } = useActiveTradingSuspensionsData(
    contentOptions,
    managedProductId,
    onAddTradingSuspensionSuccess,
    undefined,
    openRemoveTradingSuspensionModal,
  );

  const onTradingSuspensionRemoved = useCallback(() => {
    data?.refetchActiveSuspensions();
    setShowSnackbar(false); // To close the existing opened snackbar, this will restart the time of new snackbar
    setSnackbarMessaage(data?.content.suspensionRemoved);
    setShowSnackbar(true);
    onCloseRemoveTradingSuspensionModal();
  }, [data, onCloseRemoveTradingSuspensionModal]);

  const handleCloseSnackbar = useCallback(() => {
    setShowSnackbar(false);
  }, []);

  useEffect(() => {
    onCountUpdate(data?.suspensionData.length);
  }, [data, onCountUpdate]);

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (!data || error) {
    return <Alert contentOptions={contentOptions} error={error} severity="error" />;
  }

  return (
    <Stack data-qa={dataQa} sx={{ pt: 4 }}>
      <Stack alignItems="center" flexDirection="row" sx={{ mb: 4 }}>
        <Button
          disabled={
            !addPartnerOpsSuspensionAllowed ||
            data.addSuspensionInProgress ||
            data.isRefetchInProgress ||
            data.isOpsSuspensionAdded
          }
          onClick={data.onAddTradingSuspension}
          size="medium"
          sx={{ color: 'primary.main' }}
          variant="outlined"
        >
          {data.content.addSuspensionButtonLabel}
        </Button>
        {addPartnerOpsSuspensionAllowed && data.isOpsSuspensionAdded && (
          <Tooltip placement="right" tooltipContent={data.content.addSuspensionInfo}>
            <IconButton aria-label="info icon" size="small" sx={{ pl: 1 }}>
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <BasicTable alignItems="left" columns={data.columnsData} data={data.tableData} sx={commonStyles.tableSx} />

      {data.removeSuspensionModalData?.suspensionTag && data.removeSuspensionModalData.suspensionType && (
        <RemoveTradingSuspension
          contentOptions={contentOptions}
          managedProductId={managedProductId}
          onClose={onCloseRemoveTradingSuspensionModal}
          onTradingSuspensionRemoveSuccess={onTradingSuspensionRemoved}
          open={isOpenRemoveTradingSuspensionModal}
          suspensionDisplayData={data.removeSuspensionModalData.suspensionDisplayData}
          suspensionTag={data.removeSuspensionModalData.suspensionTag}
          suspensionType={data.removeSuspensionModalData.suspensionType}
        />
      )}

      <Snackbar
        action={undefined} // To override the actions defined in Snackbar
        feedbackMessage="" // To override the feedback message in Snackar
        message={
          <Stack flexDirection="row">
            <CheckIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2">{snackbarMessage}</Typography>
          </Stack>
        }
        onClose={handleCloseSnackbar}
        open={showSnackBar}
        snackbarPosition={SnackbarPositionType.BOTTOM_LEFT}
      />
    </Stack>
  );
};

export default ActiveSuspensions;
