import { parseISO } from 'date-fns';
import React from 'react';

import { Comment } from '../../common/Comment';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import {
  getAccountNumberOrAccountTypeString,
  getLastEditDateAndTime,
  getSortedPartnerOpsTradingSuspensions,
  isAccountNumberClickable,
} from '../../utils';
import { ErrorsFailure } from '../symphony';
import {
  ErrorsFailureContent,
  FailureType,
  FailureTypeContent,
  FailureTypeContentKey,
  GetTableContent,
} from '../types';

import { EntityType, FinancialAccountType, ManagedProductStatus, UserNoteEntityType } from '~/__generated__';
import { AccountErrorType } from '~/components/modals/AccountErrors';
import { Button, CheckIcon, Grid, Skeleton, TaskAltIcon } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

interface NfsErrorResponse {
  errorCode: number;
  errors: [
    {
      errorCode: number;
      field?: string;
      kind: string;
      message: string;
    },
  ];
  httpStatusCode: number;
  message: string;
}

export const getErrorTypeValue = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.COPILOT_PORTFOLIO:
      return AccountErrorType.AccountError;
    case EntityType.BANK_ACCOUNT_ASSOCIATION:
    case EntityType.CASH_DEPOSIT:
    case EntityType.CASH_WITHDRAWAL:
    case EntityType.RECURRING_CASH_DEPOSIT:
    case EntityType.RECURRING_CASH_WITHDRAWAL:
      return AccountErrorType.MoneyMovementError;
    case EntityType.ASSET_DEPOSIT:
      return AccountErrorType.TOAError;
    default:
      return AccountErrorType.OtherError;
  }
};

export const tableErrorsFactory = (
  errorsFailure: ErrorsFailure[],
  contentOptions: ContentOptions,
  failureTypesContent: (FailureTypeContent | null)[],
): ErrorsFailureContent[] => {
  return errorsFailure.map((row: ErrorsFailure) => {
    const entityNotes = row.entityNotes.length ? row.entityNotes[0] : null;
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(row.managedProduct.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = row.managedProduct.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = row.managedProduct.clientParty?.id ?? '';
    // only used for activated account states
    const accountState = getAccountState({
      firstRebalancedOn: row.managedProduct.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;
    return {
      created: row.created,
      entity: row.entity,
      entityId: row.entityId,
      id: row.id,
      ulid: row.id,
      isResolved: row.isResolved,
      comment: entityNotes
        ? {
            lastValue: entityNotes.note,
            ...getLastEditDateAndTime(new Date(entityNotes.created), contentOptions),
          }
        : undefined,
      lastCommentPartyId: entityNotes?.createdByPartyId,
      failureType: getErrorLabel(row, failureTypesContent),
      description: getErrorDescription({ description: row.description, entity: row.entity, appendErrorKey: true }),
      managedProduct: {
        accountState,
        clientParty: {
          id: partyId,
          partyPerson: {
            familyName: row.managedProduct.clientParty?.partyPerson?.familyName,
            givenName: row.managedProduct.clientParty?.partyPerson?.givenName,
          },
        },
        financialAccountNumber: row.managedProduct.financialAccountNumber,
        accountType: row.managedProduct.accountType,
        program: row.managedProduct.program,
        attributes: row.managedProduct.attributes,
      },
      managedProductId: row.managedProductId,
    };
  });
};

export const getErrorLabel = (
  { entity, failureType }: ErrorsFailure,
  failureTypesContent: (FailureTypeContent | null)[],
): string => {
  const errorTypeValue = getErrorTypeValue(entity);
  switch (errorTypeValue) {
    case AccountErrorType.AccountError:
      switch (failureType) {
        case FailureType.ACCOUNT_RESTRICTION:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.ACCOUNT_RESTRICTION_ERROR,
          );
        case FailureType.POST_SIGNING_FAILURE:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.POST_SIGNING_ERROR,
          );
        case FailureType.REQUEST_CLOSE_FAILURE:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.REQUEST_CLOSE_ERROR,
          );
        case FailureType.UPDATE_ACCOUNT_STATUS_FAILURE:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.UPDATE_ACCOUNT_STATUS_ERROR,
          );
      }
      return failureType;
    case AccountErrorType.MoneyMovementError:
      switch (failureType) {
        case FailureType.CANCELLATION_AT_BROKERAGE:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.CANCELLATION_AT_BROKERAGE_ERROR,
          );
        case FailureType.CREATE_RECEIPT:
          if (entity === EntityType.CASH_DEPOSIT || entity === EntityType.RECURRING_CASH_DEPOSIT) {
            return mapErrorFailureTypeToFailureTypesContent(
              failureTypesContent,
              FailureTypeContentKey.ADD_FUNDS_REQUEST_ERROR,
            );
          }
          if (entity === EntityType.CASH_WITHDRAWAL || entity === EntityType.RECURRING_CASH_WITHDRAWAL) {
            return mapErrorFailureTypeToFailureTypesContent(
              failureTypesContent,
              FailureTypeContentKey.WITHDRAW_FUNDS_REQUEST_ERROR,
            );
          }
          return failureType;
        case FailureType.CREATE_STANDING_INSTRUCTIONS:
          return mapErrorFailureTypeToFailureTypesContent(
            failureTypesContent,
            FailureTypeContentKey.CREATE_STANDING_INSTRUCTIONS_ERROR,
          );
        default:
          return failureType;
      }
    case AccountErrorType.TOAError:
      if (failureType === FailureType.CREATION_AT_BROKERAGE) {
        return mapErrorFailureTypeToFailureTypesContent(
          failureTypesContent,
          FailureTypeContentKey.TOA_ERROR_AT_CUSTODIAN,
        );
      }
      return failureType;
    default:
      return failureType;
  }
};

/**
 * @param {(FailureTypeContent|null)[]} failureTypesContent List of Failure Types Display strings fetched from contentstack
 * @param {FailureTypeContentKey} failureTypesContentKey The key for which display string is required.
 * @return {string} Display string defined in Content Stack for a failureType. Default value `failureType` which is passed to the function is return
 * */
const mapErrorFailureTypeToFailureTypesContent = (
  failureTypesContent: (FailureTypeContent | null)[],
  failureTypesContentKey: FailureTypeContentKey,
) =>
  failureTypesContent.find(el => el?.failure_type_key === failureTypesContentKey)?.failure_type_value ??
  failureTypesContentKey;

export const getErrorDescription = ({
  description,
  entity,
  appendErrorKey,
}: {
  appendErrorKey?: boolean;
  description: string;
  entity?: EntityType;
}): string => {
  if (
    entity &&
    getErrorTypeValue(entity) === AccountErrorType.MoneyMovementError &&
    description.includes('Error while verifying bank account for bankAccountAssociationId')
  ) {
    return `Unable to access EWS bank verification to verify bank account. ${description}`;
  }
  if (description.includes('Cannot map `null` into type int')) {
    const firstBracketIndex = description.indexOf('{');
    const lastBracketIndex = description.lastIndexOf('}');
    const errorString = description.substring(firstBracketIndex, lastBracketIndex + 1);
    if (errorString) {
      try {
        const errorParsed = JSON.parse(errorString);
        const errorDescription = errorParsed.errors
          .map((error: NfsErrorResponse) => {
            return error.message;
          })
          .join(' ');
        return appendErrorKey ? `Error: ${errorDescription} ${description}` : errorDescription;
      } catch (error) {
        return description;
      }
    }
  }
  return description;
};

export const getTableContent = ({
  contentOptions,
  currentUser,
  data,
  handleOpenModal,
  handleResolveClick,
  mpData,
  onAccountClick,
  onClientClick,
  getAccountNumberRedirectUrl,
  getClientNameRedirectUrl,
  updateFailuresLoading,
  commentColumn,
  errorId,
  showProductName,
}: GetTableContent) => {
  const clientName = (row: ErrorsFailureContent) => {
    const partyPerson = row.managedProduct.clientParty.partyPerson;
    return `${partyPerson?.givenName ?? ''}  ${partyPerson?.familyName ?? ''}`;
  };

  return mpData.map(row => ({
    rowKey: `${row.id}`,
    accountNumber: (
      <AccountNumber
        accountNumber={row.managedProduct.financialAccountNumber}
        accountNumberText={getAccountNumberOrAccountTypeString(
          row.managedProduct.financialAccountNumber ?? null,
          getAccountTypeText(
            row.managedProduct.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
            data.accountTypeContent ?? [],
          ),
        )}
        label={
          showProductName
            ? getAccountProgramText(row.managedProduct.program, row.managedProduct.attributes, data.productNameContent)
            : null
        }
        onClick={
          isAccountNumberClickable(row.managedProduct.accountState)
            ? () =>
                row.managedProduct.clientParty.id && row.managedProductId
                  ? onAccountClick(row.managedProduct.clientParty.id, row.managedProductId)
                  : undefined
            : undefined
        }
        redirectUrl={
          row.managedProduct.clientParty.id && row.managedProductId
            ? getAccountNumberRedirectUrl(row.managedProduct.clientParty.id, row.managedProductId)
            : undefined
        }
      />
    ),
    clientName: (
      <ClientName
        clientName={clientName(row)}
        onClick={() => onClientClick(row.managedProduct.clientParty.id as string)}
        redirectUrl={
          row.managedProduct.clientParty.id ? getClientNameRedirectUrl(row.managedProduct.clientParty.id) : undefined
        }
      />
    ),
    dateCreated: formatDate(parseISO(row.created)) ?? '-',
    errorType: getErrorTypeValue(row.entity),
    errorMessage: (
      <>
        <Typography sx={{ maxWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden' }} variant="subtitle2">
          {row.failureType ?? ''}
        </Typography>
        <br />
        <Typography sx={{ maxWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden' }} variant="body2">
          {row.description ?? ''}
        </Typography>
      </>
    ),
    errorDetails: (
      <Typography>
        <Button onClick={() => handleOpenModal(row)} sx={{ p: 0 }}>
          <Typography sx={{ color: 'text.primary', pt: 1, textDecorationLine: 'underline' }} variant="body1">
            {data.contentData?.columns?.find(col => col?.column_id === 'errorDetails')?.column_value ?? ''}
          </Typography>
        </Button>
      </Typography>
    ),
    comment: (
      <Comment
        comment={row.comment}
        content={commentColumn}
        contentOptions={contentOptions}
        currentUser={currentUser}
        entity={UserNoteEntityType.FAILURE}
        entityId={row.id.toString()}
        key={row.id.toString()}
        lastCommentPartyId={row.lastCommentPartyId}
        refetchData={data?.refetchErrorsData}
      />
    ),
    resolved:
      (updateFailuresLoading || data?.isRefetchingErrorsData) && row.id === errorId ? (
        <Skeleton />
      ) : row.isResolved ? (
        <Grid sx={{ display: 'flex' }}>
          <TaskAltIcon fontSize="small" sx={{ mr: 0.5, color: 'success.main' }} />
          <Typography variant="subtitle2">Resolved</Typography>
        </Grid>
      ) : (
        <Button
          onClick={() => handleResolveClick(row.entity, row.id)}
          sx={{ borderRadius: '100px' }}
          variant="outlined"
        >
          <CheckIcon sx={{ px: 1 }} /> Mark as Resolved
        </Button>
      ),
  }));
};
