import {
  GetWithdrawFundsContent,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields_WithdrawFundsModalFieldsAmount,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields_WithdrawFundsModalFieldsBankAccount,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields_WithdrawFundsModalFieldsFrequency,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields_WithdrawFundsModalFieldsRaiseCash,
  GetWithdrawFundsContentVariables,
} from './__generated__/query.v2';
import { GetWithDrawFundsV2Content, GetWithDrawFundsV2ContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const isFrequencyContent = (
  field: GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields | null,
): field is GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields_WithdrawFundsModalFieldsFrequency =>
  field?.__typename === 'WithdrawFundsModalFieldsFrequency';

export const isBankAccountContent = (
  field: GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields | null,
): field is GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields_WithdrawFundsModalFieldsBankAccount =>
  field?.__typename === 'WithdrawFundsModalFieldsBankAccount';

export const isAmountContent = (
  field: GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields | null,
): field is GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields_WithdrawFundsModalFieldsAmount =>
  field?.__typename === 'WithdrawFundsModalFieldsAmount';

export const isRaiseCashContent = (
  field: GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields | null,
): field is GetWithdrawFundsContent_all_withdraw_funds_modal_items_fields_WithdrawFundsModalFieldsRaiseCash =>
  field?.__typename === 'WithdrawFundsModalFieldsRaiseCash';

export const useGetContent = (
  options?: QueryHookOptions<GetWithdrawFundsContent, GetWithdrawFundsContentVariables>,
) => {
  return useContentstackQuery(queries.GetWithdrawFundsContent, options);
};

export const useGetContentV2 = (
  options?: QueryHookOptions<GetWithDrawFundsV2Content, GetWithDrawFundsV2ContentVariables>,
) => {
  return useContentstackQuery(queries.GetWithDrawFundsV2Content, options);
};
