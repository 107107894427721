import React, { FC } from 'react';

import { TooltipVariantProps } from '.';

import { Drawer } from '~/components/ui/Drawer';
import { ModalLink } from '~/components/ui/ModalLink';
import { Box, IconButton, InfoIcon, useTheme } from '~/components/ui/mui';

export const DrawerTooltip: FC<TooltipVariantProps> = ({
  anchorText,
  'aria-label': ariaLabel,
  buttonRef,
  children,
  iconPosition,
  handleClose,
  onClick,
  onClose,
  open,
  tooltipContent,
}) => {
  const {
    sfTooltip: { styles },
  } = useTheme();
  return (
    <>
      <Box component="span" ref={buttonRef}>
        {children ? (
          <span onClick={onClick}>{children}</span>
        ) : anchorText ? (
          <span onClick={onClick}>
            <ModalLink>{anchorText}</ModalLink>
          </span>
        ) : (
          <IconButton
            aria-label={ariaLabel}
            onClick={onClick}
            size="small"
            sx={
              iconPosition === 'superscript'
                ? { ...styles.iconButton, verticalAlign: 'top', mt: 0, pt: 0, ml: 0, pl: 0 }
                : styles.iconButton
            }
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>
      <Drawer onBackdropClick={() => handleClose?.()} onClose={onClose} open={open}>
        {tooltipContent}
      </Drawer>
    </>
  );
};
