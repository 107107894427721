import React from 'react';
import { DeepMap, FieldErrors } from 'react-hook-form';

import { HoldingInput } from './HoldingsTable/utils';
import { ContributionYearOptions } from './InvestmentAmount';

import { FinancialAccountType, TransferFrequency } from '~/__generated__';
import { Link } from '~/components/ui/Link';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { getAlert } from '~/containers/Funding/utils';
import { FundingSources } from '~/utils/config';
import { formatCurrency } from '~/utils/format';

export type FieldNames = { [key in keyof FundingFormData]: string };
export const fields: FieldNames = {
  accountType: 'accountType',
  contributionYear: 'contributionYear',
  fundingSource: 'fundingSource',
  fundingSourceIndex: 'fundingSourceIndex',
  investmentAmount: 'investmentAmount',
  investmentMethod: 'investmentMethod',
  investmentSwitch: 'investmentSwitch',
  isManualBrokerageSource: 'isManualBrokerageSource',
  optionalJournalingMessage: 'optionalJournalingMessage',
  otherPrimaryAccountFundingSource: 'otherPrimaryAccountFundingSource',
  primaryAccountFundingSource: 'primaryAccountFundingSource',
  recurringAmount: 'recurringAmount',
  recurringBankAccount: 'recurringBankAccount',
  recurringDay: 'recurringDay',
  recurringFrequency: 'recurringFrequency',
  rolloverReasons: 'rolloverReasons',
  rolloverReasonOther: 'rolloverReasonOther',
  skipRecurring: 'skipRecurring',
  skipped: 'skipped',
  sourceAnnuityAccountNumber: 'sourceAnnuityAccountNumber',
  sourceAnnuityDeliveringFirm: 'sourceAnnuityDeliveringFirm',
  sourceAnnuityFirmCity: 'sourceAnnuityFirmCity',
  sourceAnnuityFirmPhoneNumber: 'sourceAnnuityFirmPhoneNumber',
  sourceAnnuityFirmState: 'sourceAnnuityFirmState',
  sourceAnnuityFirmStreetAddress: 'sourceAnnuityFirmStreetAddress',
  sourceAnnuityFirmStreetAddressSecondary: 'sourceAnnuityFirmStreetAddressSecondary',
  sourceAnnuityFirmZipCode: 'sourceAnnuityFirmZipCode',
  sourceAnnuitySurrenderType: 'sourceAnnuitySurrenderType',
  sourceBankAccount: 'sourceBankAccount',
  sourceBrokerageAccount: 'sourceBrokerageAccount',
  sourceBrokerageAccountNumber: 'sourceBrokerageAccountNumber',
  sourceBrokerageAlias: 'sourceBrokerageAlias',
  sourceBrokerageName: 'sourceBrokerageName',
  investmentPeriod: 'investmentPeriod',
  investmentType: 'investmentType',
  initialAmount: 'initialAmount',
  investmentFrequency: 'investmentFrequency',
  holdingData: 'holdingData',
};

export interface FundingFormData {
  accountType: FinancialAccountType;
  contributionYear: ContributionYearOptions | null;
  fundingSource: FundingSources;
  fundingSourceIndex: number;
  holdingData: HoldingInput;
  initialAmount: number;
  investmentAmount: string;
  investmentFrequency: string;
  investmentMethod: string;
  investmentPeriod: number;
  investmentSwitch: string;
  investmentType: string;
  isManualBrokerageSource: boolean;
  optionalJournalingMessage: string;
  otherPrimaryAccountFundingSource: string;
  primaryAccountFundingSource: string[];
  recurringAmount?: number;
  recurringBankAccount?: string;
  recurringDay?: number;
  recurringFrequency?: TransferFrequency;
  rolloverReasonOther?: string;
  rolloverReasons?: Record<string, boolean>;
  skipRecurring: boolean;
  skipped: boolean;
  sourceAnnuityAccountNumber: string;
  sourceAnnuityDeliveringFirm: string;
  sourceAnnuityFirmCity: string;
  sourceAnnuityFirmPhoneNumber: string;
  sourceAnnuityFirmState: string;
  sourceAnnuityFirmStreetAddress: string;
  sourceAnnuityFirmStreetAddressSecondary: string;
  sourceAnnuityFirmZipCode: string;
  sourceAnnuitySurrenderType: string;
  sourceBankAccount: string;
  sourceBrokerageAccount: string;
  sourceBrokerageAccountNumber: string;
  sourceBrokerageAlias: string;
  sourceBrokerageName: string;
}

export interface InitialInvestmentAmountCheck {
  higherLimitPercentage?: number;
  initialInvestmentAmount: string;
  lowerLimitPercentage?: number;
  onBack: () => void;
}

export interface InvestmentAmountFieldErrors {
  aboveInitialInvestmentAmount?: string;
  amountCrossedAccountTypeLimit?: string;
  amountCrossedCustodianDailyLimit?: string;
  belowInitialInvestmentAmount?: string;
  insignificant?: string;
  insufficient: string;
  invalid: string;
  maximumAmount: string;
}

export interface InvestmentAmountFieldContent {
  accountMinimumForAccountOpeningWarning?: {
    image: string;
    label: string;
  };
  accountMinimumWarning?: {
    image: string;
    label: string;
  };
  availableBalance?: string;
  currency: string;
  errors: InvestmentAmountFieldErrors;
  label: string;
  minimumAmount: number;
  onBackCTAText?: string;
  placeholder?: string;
  significantAmount?: number;
}

export const getInitialInvestmentAmountErrorContent = (
  message: string,
  initialInvestmentAmountCheck?: InitialInvestmentAmountCheck,
  showFullAmount?: boolean,
  onBackCTAText?: string,
) => {
  const initialInvestmentAmount = formatCurrency(
    showFullAmount && initialInvestmentAmountCheck?.higherLimitPercentage
      ? (parseFloat(initialInvestmentAmountCheck.initialInvestmentAmount) *
          (100 + initialInvestmentAmountCheck.higherLimitPercentage)) /
          100
      : parseFloat(initialInvestmentAmountCheck?.initialInvestmentAmount ?? '0'),
  );
  return (
    <RteContent
      config={{
        initialInvestmentAmount,
        resetInitialInvestmentValue: (
          <Link
            onClick={initialInvestmentAmountCheck?.onBack}
            sx={{
              fontSize: 'inherit',
            }}
          >
            {onBackCTAText}
          </Link>
        ),
      }}
      data={message}
    />
  );
};

export const getFundingAmountHelperText = (
  amount: string,
  custodianMaximumDailyLimit: number,
  field: string,
  errors: DeepMap<Record<string, any>, FieldErrors>,
  contentErrors: InvestmentAmountFieldErrors,
  minimumAmount: number,
  initialInvestmentAmountCheck?: InitialInvestmentAmountCheck,
  onBackCTAText?: string,
  significantAmount?: number,
  showFullAmount = false,
) => {
  if (errors[field]) {
    const key = errors[field]?.type as keyof InvestmentAmountFieldErrors | undefined;
    const errorText =
      key && Object.keys(contentErrors).includes(key) ? (
        key === 'aboveInitialInvestmentAmount' || key === 'belowInitialInvestmentAmount' ? (
          getInitialInvestmentAmountErrorContent(
            contentErrors[key] ?? '',
            initialInvestmentAmountCheck,
            showFullAmount,
            onBackCTAText,
          )
        ) : key === 'amountCrossedCustodianDailyLimit' ? (
          <RteContent
            config={{
              nfsDailyLimit: formatCurrency(custodianMaximumDailyLimit),
            }}
            data={contentErrors[key] ?? ''}
          />
        ) : (
          contentErrors[key]
        )
      ) : (
        contentErrors.invalid
      );

    return getAlert(errorText, field, 'error');
  }
  if (significantAmount && parseFloat(amount) < significantAmount && parseFloat(amount) >= minimumAmount) {
    return getAlert(contentErrors.insignificant, field, 'info');
  }
  return <></>;
};

export const checkForInitialInvestmentAmount = (
  value: string,
  totalAmount: number,
  initialInvestmentAmountCheck?: InitialInvestmentAmountCheck,
  checkLowerLimit?: boolean,
): boolean => {
  if (initialInvestmentAmountCheck) {
    const { higherLimitPercentage, lowerLimitPercentage, initialInvestmentAmount } = initialInvestmentAmountCheck;
    return checkLowerLimit
      ? !lowerLimitPercentage ||
          parseFloat(value) + totalAmount >= ((100 - lowerLimitPercentage) * parseFloat(initialInvestmentAmount)) / 100
      : !higherLimitPercentage ||
          parseFloat(value) + totalAmount <=
            ((100 + higherLimitPercentage) * parseFloat(initialInvestmentAmount)) / 100;
  }
  return true;
};
