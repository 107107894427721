import { GetModelChangeTableContent, GetModelChangeTableContentVariables } from './__generated__/query.v2';
import * as query from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetPendingModelChangesContent = (
  options?: QueryHookOptions<GetModelChangeTableContent, GetModelChangeTableContentVariables>,
): QueryResult<GetModelChangeTableContent, GetModelChangeTableContentVariables> => {
  return useContentstackQuery(query.GetModelChangeTableContent, options);
};
