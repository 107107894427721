import { BankAccount as FinancialBankAccount } from '../symphony';

import { BankAccount, getBankAccountId, getPlaidMaskedAccountNumber } from '~/utils/account';
import { formatMaskedAccountNumber } from '~/utils/format';

export const getBankAccount = ({
  accountNumberFormat,
  bankAccount: {
    accountNumber,
    id,
    financialInstitution,
    maskedAccountNumber,
    productName,
    balances,
    isFromExternalSource,
    attributes,
    type,
  },
}: {
  accountNumberFormat?: string;
  bankAccount: FinancialBankAccount;
}): BankAccount => ({
  id: getBankAccountId(id, isFromExternalSource, accountNumber) || 'Unknown',
  financialInstitution,
  maskedAccountNumber: formatMaskedAccountNumber(
    accountNumberFormat || '${accountNumber}',
    getPlaidMaskedAccountNumber(attributes) ?? maskedAccountNumber,
  ),
  productName,
  balances,
  isFromExternalSource,
  type,
});
