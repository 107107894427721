import React, { FC, useCallback, useState } from 'react';

import { Props as AddFundsProps } from '.';

import { DeleteBankAccountModal } from '~/components/modals/DeleteBankAccount';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Button, FormLabel, RemoveCircleOutlineIcon, TableCell, TableRow } from '~/components/ui/mui';
import { CurrencyTextField } from '~/components/ui/TextField/CurrencyTextField';
import { getBankAccountLabel, getUpdatedFinancialInstituion, isBooleanAttribute } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

interface Props {
  account: AddFundsProps['bankAccounts'][number];
  content: {
    addFundsCta: string;
    removeAcccountCta?: string;
  };
  contentOptions: ContentOptions;
  dataQa?: string;
  defaultContributionAmount: string;
  onAddFunds: AddFundsProps['onAddFunds'];
  onRemoveAccount?: AddFundsProps['onRemoveAccount'];
  partyId: string;
}

export const AddFundsRow: FC<Props> = ({
  dataQa = 'add-funds-row',
  account,
  defaultContributionAmount,
  content: { addFundsCta, removeAcccountCta },
  contentOptions,
  onAddFunds,
  onRemoveAccount,
  partyId,
}) => {
  const {
    featureFlags: { allowDeletionForExternalBankAccounts, showDeleteButtonOnAccountDetailPage },
  } = useCoreConfig();
  const [contributionAmount, setContributionAmount] = useState(defaultContributionAmount);
  const deleteBankAccountModal = useModalState();

  const onAddFundsClick = useCallback(() => {
    onAddFunds({ account, value: contributionAmount });
  }, [account, contributionAmount, onAddFunds]);

  const labelId = `add-funds-label-${account.id}`;
  const textFieldId = `add-funds-text-field-${account.id}`;

  const isMediumScreen = useIsMediumScreen();
  const showRemoveAccountButtonCell =
    !account.attributes
      ?.filter(isBooleanAttribute)
      ?.find(({ name }) => name === 'IS_SYNCED_FROM_MANAGED_PRODUCT_ASSOCIATION')?.booleanValue &&
    showDeleteButtonOnAccountDetailPage &&
    (allowDeletionForExternalBankAccounts || !account.isFromExternalSource);

  const RemoveAccountButton = () => (
    <>
      {showRemoveAccountButtonCell && (
        <Button
          color="secondary"
          fullWidth={isMediumScreen}
          onClick={() => deleteBankAccountModal.openModal()}
          size="small"
          startIcon={<RemoveCircleOutlineIcon />}
        >
          {removeAcccountCta}
        </Button>
      )}
    </>
  );

  return (
    <>
      {account.id && (
        <>
          <TableRow data-qa={dataQa}>
            <TableCell sx={{ borderBottom: isMediumScreen ? 0 : undefined }}>
              <FormLabel aria-labelledby={textFieldId} htmlFor={textFieldId} id={labelId}>
                {getBankAccountLabel(
                  getUpdatedFinancialInstituion(account.financialInstitution, account.type),
                  account.productName,
                  account.maskedAccountNumber,
                )}
              </FormLabel>
            </TableCell>
            {!isMediumScreen && onRemoveAccount && (
              <TableCell>
                <RemoveAccountButton />
              </TableCell>
            )}
            <TableCell sx={{ borderBottom: isMediumScreen ? 0 : undefined }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CurrencyTextField
                  contentOptions={contentOptions}
                  id={textFieldId}
                  onValueChange={values => setContributionAmount(values.value)}
                  size="small"
                  sx={{ mr: isMediumScreen ? undefined : 2 }}
                  value={parseFloat(contributionAmount)}
                />
                {!isMediumScreen && (
                  <Button
                    aria-describedby={labelId}
                    data-heap={`addFunds-${partyId}`}
                    onClick={onAddFundsClick}
                    size="small"
                    variant="outlined"
                  >
                    {addFundsCta}
                  </Button>
                )}
              </Box>
            </TableCell>
          </TableRow>
          {isMediumScreen && (
            <TableRow>
              <TableCell colSpan={2}>
                <Button
                  aria-describedby={labelId}
                  data-heap={`addFunds-${partyId}`}
                  fullWidth
                  onClick={onAddFundsClick}
                  size="small"
                  sx={{ mb: 2 }}
                  variant="outlined"
                >
                  {addFundsCta}
                </Button>
                <RemoveAccountButton />
              </TableCell>
            </TableRow>
          )}
          <DeleteBankAccountModal
            accountId={account.id}
            contentOptions={contentOptions}
            maskedAccountNumber={account.maskedAccountNumber ?? ''}
            onClose={deleteBankAccountModal.onClose}
            onRemoveAccount={onRemoveAccount}
            open={deleteBankAccountModal.open}
            partyId={partyId}
          />
        </>
      )}
    </>
  );
};
