import { parseISO } from 'date-fns';
import React from 'react';

import { SuspendTradingModalContent } from '../SuspendTrading/contentstack';

import { TableColumn, TableData } from '~/components/ui/BasicTable';
import { Typography } from '~/components/ui/Typography';
import { getFullName } from '~/hooks/party/utils';
import { ManagedProductTradingSuspensions } from '~/hooks/suspensions/symphony';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';
import { ContentstackTableColumn, sortColumnsByColumnOrder } from '~/utils/table';

enum ContentStackKeys {
  ACTIVE_ACCOUNT_TRADING_LABEL = 'active_account_trading_label',
  CANCEL_CTA = 'cancel_cta',
  MODAL_TITLE = 'modal_title',
  OTHER = 'other',
  SUSPEND_CONFIRM_BUTTON_CTA = 'suspend_confirm_button_cta',
  UNSUSPEND_CONFIRM_BUTTON_CTA = 'unsuspend_confirm_button_cta',
}

export const getFormattedRows = (
  content: SuspendTradingModalContent | null | undefined,
  contentOptions: ContentOptions,
  suspensionData: ManagedProductTradingSuspensions[],
): TableData[] =>
  suspensionData.map(suspension => {
    const data = content?.all_suspend_trading_modal?.items?.[0];
    const textFields = data?.fields?.text;
    const partyName = getFullName(suspension.createdByParty?.partyPerson);
    const dateAdded = formatDate(parseISO(suspension.createdAt), 'MM/dd/yyyy', {
      locale: contentOptions.locale,
    });
    const suspensionTag = suspension.suspensionTag
      ? suspension.suspensionTag
      : findFieldValue(textFields, ContentStackKeys.OTHER);
    return {
      rowKey: suspension.id,
      dateAdded: <Typography variant="body2">{dateAdded}</Typography>,
      addedBy: <Typography variant="body2">{partyName}</Typography>,
      suspensionType: <Typography variant="body2">{suspension.suspensionType}</Typography>,
      suspensionTag: <Typography variant="body2">{suspensionTag}</Typography>,
    };
  });

export const getModalContent = (content: SuspendTradingModalContent | null | undefined) => {
  const data = content?.all_suspend_trading_modal?.items?.[0];
  const textFields = data?.fields?.text;
  sortColumnsByColumnOrder(data?.columns?.column ?? []);
  return {
    columns: getTableColumns(data?.columns?.column ?? []),
    labels: textFields,
    suspendConfirmButtonCta: findFieldValue(textFields, ContentStackKeys.SUSPEND_CONFIRM_BUTTON_CTA),
    unSuspendConfirmButtonCta: findFieldValue(textFields, ContentStackKeys.UNSUSPEND_CONFIRM_BUTTON_CTA),
    cancelCta: findFieldValue(textFields, ContentStackKeys.CANCEL_CTA),
    activeAccountLabel: findFieldValue(textFields, ContentStackKeys.ACTIVE_ACCOUNT_TRADING_LABEL),
    modal_title: findFieldValue(textFields, ContentStackKeys.MODAL_TITLE),
  };
};

const getTableColumns = (columns?: (ContentstackTableColumn | null | undefined)[]) => {
  const sortedColumns = sortColumnsByColumnOrder(columns);
  return sortedColumns.map(
    (col): TableColumn => ({
      title: col.column_value,
      key: col.column_id ?? '',
    }),
  );
};
