import React, { useCallback, useState } from 'react';

import { LinkBankAccountResult, useGetLinkBankAccountContentData } from './hooks/useGetLinkBankAccountContentData';
import { LinkBankAccountForm, Props as LinkBankAccountFormProps } from './LinkBankAccountForm';
import { AddFinancialAccount_addFinancialAccount } from './symphony/__generated__/mutation.v2';

import { AssociatedEntityType } from '~/__generated__';
import { Modal } from '~/components/ui/Modal';
import { Button, Grid, LoadingButton } from '~/components/ui/mui';
import { FinancialAccount, isManagedProduct } from '~/hooks/financial-account/symphony';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Props
  extends Omit<LinkBankAccountFormProps, 'content' | 'isSubmitted' | 'onSubmit' | 'onFailure' | 'onSuccess'> {
  contentOptions: ContentOptions;
  data?: LinkBankAccountResult;
  dataQa?: string;
  onClose?: () => void;
  onLinkAccount?: (account: FinancialAccount) => void;
  open?: boolean;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntity?: { entityId: string; entityType: AssociatedEntityType },
  ) => void;
}

export const LinkBankAccountModal: React.FC<Props> = ({
  dataQa = 'link-bank-account-modal',
  open,
  onClose,
  onLinkAccount,
  contentOptions,
  data,
  redirectToSignDocuments,
  ...linkBankAccountFormProps
}) => {
  const {
    featureFlags: { isDocusignRequiredForFinancialAccountLinkageInRCE },
  } = useCoreConfig();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isMediumScreen = useIsMediumScreen();

  const handleSubmitSuccess = (successData: AddFinancialAccount_addFinancialAccount | null | undefined) => {
    onClose?.();
    setIsSubmitting(false);
    if (successData) {
      onLinkAccount?.(successData as FinancialAccount);
    }

    if (isDocusignRequiredForFinancialAccountLinkageInRCE) {
      const isFinancialAccountAssociationPending =
        successData?.products
          ?.filter(isManagedProduct)
          ?.find(product => product.id === linkBankAccountFormProps.managedProductId)
          ?.isFinancialAccountAssociationPending ?? false;

      if (isFinancialAccountAssociationPending) {
        redirectToSignDocuments?.(linkBankAccountFormProps.managedProductId);
      }
    }
  };

  const handleSubmitFailure = () => {
    setIsSubmitting(false);
  };

  const {
    data: linkBankAccountData,
    error: linkBankAccountError,
    loading: linkBankAccountLoading,
  } = useGetLinkBankAccountContentData({
    open,
    contentOptions,
  });

  const onCloseLinkBankAccount = useCallback(() => {
    onClose?.();
    linkBankAccountData?.data.updateFinancialInstitutioName();
  }, []);

  const content = linkBankAccountData?.content;

  return (
    <Modal
      actions={
        <Grid container direction={isMediumScreen ? 'column-reverse' : 'row'} justifyContent="end" spacing={2}>
          <Grid item>
            <Button fullWidth={isMediumScreen} onClick={onClose} variant="outlined">
              {content?.ctas.secondary ?? 'MISSING CANCEL TEXT'}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              fullWidth={isMediumScreen}
              loading={isSubmitting}
              onClick={() => setIsSubmitting(true)}
              variant="contained"
            >
              {content?.ctas.primary ?? 'MISSING LINK ACCOUNT TEXT'}
            </LoadingButton>
          </Grid>
        </Grid>
      }
      content={
        // TODO: use fullWidth once material ui version updated (DA2-901)
        <LinkBankAccountForm
          content={content}
          contentOptions={contentOptions}
          data={linkBankAccountData?.data}
          isSubmitted={isSubmitting}
          onFailure={handleSubmitFailure}
          onSuccess={handleSubmitSuccess}
          open={open}
          {...linkBankAccountFormProps}
          isDocusignRequiredForFinancialAccountLinkageInRCE={isDocusignRequiredForFinancialAccountLinkageInRCE}
        />
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={linkBankAccountError}
      loading={linkBankAccountLoading}
      onClose={onCloseLinkBankAccount}
      open={!!open}
      title={content?.header}
    />
  );
};
