import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetBankVerificationModalContent } from '../contentstack';
import {
  useGetFinancialAccountAssociationVerificationRequest,
  useUpdateFinancialAccountAssociationVerificationRequest,
} from '../symphony';
import { BankVerificationModalContentLabel } from '../utils/types';

import {
  FinancialAccountAssociationVerificationStatus,
  FinancialAccountSource,
  FinancialAccountType,
} from '~/__generated__';
import { getPlaidMaskedAccountNumber } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface ConfirmationContent {
  ctas: {
    primary: string;
    secondary: string;
  };
  header: string;
  iconUrl: string;
  subtitle: string;
}

interface BankVerificationContent {
  bankVerification: {
    accountDetails: {
      accountEntryMethod: string;
      accountLabel: string;
      manual: string;
      maskedAccountNumberLabel: string;
      routingLabel: string;
      statusLabel: string;
    };
    ctas: {
      primary: string;
      secondary: string;
    };
    discrepanciesContent: string;
    discrepanciesHeader: string;
    header: string;
    providedByClient: string;
    providedByPlaid: string;
    rows: {
      emailAddressLabel: string;
      mailingAddressLabel: string;
      nameLabel: string;
      phoneNumberLabel: string;
    };
  };
  confirmApprove: ConfirmationContent;
  confirmReject: ConfirmationContent;
}

interface ClientAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  state: string;
}

export interface ClientData {
  addresses: ClientAddress[];
  emails: string[];
  names: string[];
  phoneNumbers: string[];
}

export interface SourceData extends ClientData {
  accountNumber: string;
  bankAccountType: string;
  financialInstitution: string;
  plaidMaskedAccountNumber: string;
  routingNumber: string;
  source?: FinancialAccountSource | null;
}

export interface BankVerificationModal {
  confirmationState?: FinancialAccountAssociationVerificationStatus;
  content: BankVerificationContent;
  data: {
    managedData: ClientData;
    sourceData: SourceData;
  };
  handleAccept: () => void;
  handleCancel: () => void;
  handleConfirm: () => void;
  handleReject: () => void;
  updateError?: Error;
  updateLoading: boolean;
}
export interface Props {
  contentOptions: ContentOptions;
  onClose?: (feedbackMessage?: string) => void;
  requestId: string;
}

export const useBankVerificationModal = ({
  contentOptions,
  onClose,
  requestId,
}: Props): AsyncResult<BankVerificationModal> => {
  const { showMoreInfoOnBankVerificationModal } = useCoreConfig().components.sfOpsDashboard;
  const [state, setState] = useState<AsyncResult<BankVerificationModal>>({ loading: false });
  const [updateError, setUpdateError] = useState<Error | undefined>(undefined);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [confirmationState, setConfirmationState] = useState<FinancialAccountAssociationVerificationStatus | undefined>(
    undefined,
  );
  const [
    updateFinancialAccountAssociationVerificationRequest,
  ] = useUpdateFinancialAccountAssociationVerificationRequest();

  const { data: contentData, loading: contentLoading, error: contentError } = useGetBankVerificationModalContent({
    variables: contentOptions,
  });

  const content = useMemo(() => contentData?.all_bank_verification_modal?.items?.[0], [contentData]);

  const {
    data: accountData,
    loading: accountLoading,
    error: accountError,
  } = useGetFinancialAccountAssociationVerificationRequest({
    variables: { requestId, showMoreInfoOnBankVerificationModal },
  });

  const updateBankAccountVerification = useCallback(async () => {
    const id = accountData?.financialAccountAssociationVerificationRequests.requests[0]?.id;
    if (id && confirmationState) {
      setUpdateLoading(true);
      await updateFinancialAccountAssociationVerificationRequest({
        variables: {
          id: requestId,
          status: confirmationState,
        },
      })
        .then(() => {
          onClose?.(requestId);
          setUpdateLoading(false);
          setUpdateError(undefined);
          setConfirmationState(undefined);
        })
        .catch((err: any) => {
          setUpdateError(err);
          setUpdateLoading(false);
          console.error(err);
        });
    }
  }, [
    accountData?.financialAccountAssociationVerificationRequests.requests,
    onClose,
    confirmationState,
    requestId,
    updateFinancialAccountAssociationVerificationRequest,
  ]);

  const accountClientData = useMemo(() => {
    const financialAccountData = accountData?.financialAccountAssociationVerificationRequests.requests[0];
    const managedData = financialAccountData?.managedAccountHoldersDetails;
    const sourceData = financialAccountData?.sourceAccountHoldersDetails;
    const savingsLabel =
      content?.bank_verification?.labels?.find(
        label => label?.label_key === BankVerificationModalContentLabel.ACCOUNT_TYPE_SAVING,
      )?.label_value ?? '';
    const chequingLabel =
      content?.bank_verification?.labels?.find(
        label => label?.label_key === BankVerificationModalContentLabel.ACCOUNT_TYPE_CHEQUING,
      )?.label_value ?? '';
    const accountNumber = financialAccountData?.financialAccount?.accountNumber ?? '';
    const plaidMaskedAccountNumber =
      getPlaidMaskedAccountNumber(financialAccountData?.financialAccount?.attributes) ?? '';
    return {
      managedData: {
        addresses:
          managedData?.addresses.map(address => ({
            addressLine1: address.addressLine1 ?? '',
            addressLine2: address.addressLine2 ?? '',
            city: address.city ?? '',
            postalCode: address.postalCode ?? '',
            state: address.state ?? '',
          })) ?? [],
        emails: managedData?.emails ?? [],
        names: managedData?.names ?? [],
        phoneNumbers: managedData?.phoneNumbers ?? [],
      },
      sourceData: {
        addresses:
          sourceData?.addresses.map(address => ({
            addressLine1: address.addressLine1 ?? '',
            addressLine2: address.addressLine2 ?? '',
            city: address.city ?? '',
            postalCode: address.postalCode ?? '',
            state: address.state ?? '',
          })) ?? [],
        emails: sourceData?.emails ?? [],
        names: sourceData?.names ?? [],
        phoneNumbers: sourceData?.phoneNumbers ?? [],
        accountNumber,
        source: financialAccountData?.financialAccount?.source,
        plaidMaskedAccountNumber,
        bankAccountType:
          financialAccountData?.financialAccount?.type === FinancialAccountType.SAVINGS ? savingsLabel : chequingLabel,
        financialInstitution: financialAccountData?.financialAccount?.financialInstitution
          ? financialAccountData.financialAccount.financialInstitution
          : financialAccountData?.financialAccount?.type === FinancialAccountType.SAVINGS
          ? savingsLabel
          : chequingLabel,
        routingNumber: financialAccountData?.financialAccount?.routingNumber ?? '',
      },
    };
  }, [accountData, content]);

  useEffect(() => {
    if (contentLoading || accountLoading) {
      setState({ loading: true });
      setUpdateError(undefined);
      setUpdateLoading(false);
      return;
    }
    if (contentError || accountError) {
      setState({ loading: false, error: contentError ?? accountError });
      setUpdateError(undefined);
      setUpdateLoading(false);
      return;
    }
    setState({
      data: {
        confirmationState,
        content: {
          bankVerification: {
            accountDetails: {
              accountLabel:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.ACCOUNT,
                )?.label_value ?? '',
              maskedAccountNumberLabel:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.MASKED_ACCOUNT_NUMBER,
                )?.label_value ?? '',
              accountEntryMethod:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.ACCOUNT_ENTRY_METHOD,
                )?.label_value ?? '',
              manual:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.MANUAL,
                )?.label_value ?? '',
              routingLabel:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.ROUTING,
                )?.label_value ?? '',
              statusLabel:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.STATUS,
                )?.label_value ?? '',
            },
            ctas: {
              primary:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.PRIMARY_CTA,
                )?.label_value ?? '',
              secondary:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.SECONDARY_CTA,
                )?.label_value ?? '',
            },
            discrepanciesContent:
              content?.bank_verification?.labels?.find(
                label => label?.label_key === BankVerificationModalContentLabel.DISCREPANCIES_TEXT,
              )?.label_value ?? '',
            discrepanciesHeader:
              content?.bank_verification?.labels?.find(
                label => label?.label_key === BankVerificationModalContentLabel.DISCREPANCIES_HEADER,
              )?.label_value ?? '',
            header:
              content?.bank_verification?.labels?.find(
                label => label?.label_key === BankVerificationModalContentLabel.HEADER,
              )?.label_value ?? '',
            providedByClient:
              content?.bank_verification?.labels?.find(
                label => label?.label_key === BankVerificationModalContentLabel.MANAGED_DATA_HEADER,
              )?.label_value ?? '',
            providedByPlaid:
              content?.bank_verification?.labels?.find(
                label => label?.label_key === BankVerificationModalContentLabel.SOURCE_DATA_HEADER,
              )?.label_value ?? '',
            rows: {
              emailAddressLabel:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.EMAIL,
                )?.label_value ?? '',
              mailingAddressLabel:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.ADDRESS,
                )?.label_value ?? '',
              nameLabel:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.NAME,
                )?.label_value ?? '',
              phoneNumberLabel:
                content?.bank_verification?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.PHONE,
                )?.label_value ?? '',
            },
          },
          confirmApprove: {
            ctas: {
              primary:
                content?.approve?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.PRIMARY_CTA,
                )?.label_value ?? '',
              secondary:
                content?.approve?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.SECONDARY_CTA,
                )?.label_value ?? '',
            },
            iconUrl:
              content?.approve?.files?.find(file => file?.file_key === BankVerificationModalContentLabel.ICON)
                ?.file_valueConnection?.edges?.[0]?.node?.url ?? '',
            header:
              content?.approve?.labels?.find(label => label?.label_key === BankVerificationModalContentLabel.HEADER)
                ?.label_value ?? '',
            subtitle:
              content?.approve?.labels?.find(label => label?.label_key === BankVerificationModalContentLabel.SUBTITLE)
                ?.label_value ?? '',
          },
          confirmReject: {
            ctas: {
              primary:
                content?.reject?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.PRIMARY_CTA,
                )?.label_value ?? '',
              secondary:
                content?.reject?.labels?.find(
                  label => label?.label_key === BankVerificationModalContentLabel.SECONDARY_CTA,
                )?.label_value ?? '',
            },
            iconUrl:
              content?.reject?.files?.find(file => file?.file_key === BankVerificationModalContentLabel.ICON)
                ?.file_valueConnection?.edges?.[0]?.node?.url ?? '',
            header:
              content?.reject?.labels?.find(label => label?.label_key === BankVerificationModalContentLabel.HEADER)
                ?.label_value ?? '',
            subtitle:
              content?.reject?.labels?.find(label => label?.label_key === BankVerificationModalContentLabel.SUBTITLE)
                ?.label_value ?? '',
          },
        },
        data: accountClientData,
        handleAccept: () => setConfirmationState(FinancialAccountAssociationVerificationStatus.ACCEPTED),
        handleCancel: () => setConfirmationState(undefined),
        handleConfirm: updateBankAccountVerification,
        handleReject: () => setConfirmationState(FinancialAccountAssociationVerificationStatus.REJECTED),
        updateError,
        updateLoading,
      },
      loading: false,
    });
  }, [
    accountClientData,
    accountData,
    accountError,
    accountLoading,
    confirmationState,
    content,
    contentError,
    contentLoading,
    updateBankAccountVerification,
    updateError,
    updateLoading,
  ]);

  return state;
};
