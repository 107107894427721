import React, { useEffect, useState } from 'react';

import { OpsAlertAndLoading } from '../common/OpsAlertAndLoading';
import { SearchBar } from '../common/SearchBar';

import { useGetBankVerificationTabData } from './hooks';
import { BankVerificationDefaultAppliedFilters } from './utils/types';

import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { BankVerificationModal } from '~/components/modals/BankVerification';
import { NullStateProps } from '~/components/NullState';
import { BasicTable } from '~/components/ui/BasicTable';
import { ItemsPerPage } from '~/components/ui/BasicTable/ItemsPerPage';
import { TotalItems } from '~/components/ui/BasicTable/TotalItems';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  nullStateConfig?: NullStateProps;
}

export const BankVerificationTab: React.FC<Props> = ({
  contentOptions,
  dataQa = 'bank-verification-tab',
  nullStateConfig,
}) => {
  const [currentRequestId, setCurrentRequestId] = useState<string | undefined>(undefined);
  const [shouldRefetchData, setShouldRefetchData] = useState(false);
  const { open, onClose, openModal, feedbackMessage } = useModalState();
  const handleReviewClick = (requestId: string) => {
    setCurrentRequestId(requestId);
    openModal();
  };
  const { loading, data, error } = useGetBankVerificationTabData({ handleReviewClick });
  useEffect(() => {
    if (shouldRefetchData && data) {
      data.refetchData();
      setShouldRefetchData(false);
    }
  }, [shouldRefetchData, data]);

  useEffect(() => {
    if (feedbackMessage) {
      setShouldRefetchData(true);
    }
  }, [feedbackMessage]);

  return (
    <Stack data-qa={`${dataQa}-container`}>
      {(error || loading) && (
        <OpsAlertAndLoading
          ariaLabel="Loading Bank Verification items"
          contentOptions={contentOptions}
          error={error}
          loading={loading}
        />
      )}

      {(data?.tableData || data?.searchError) && !loading && (
        <>
          <Stack alignItems="center" data-qa="filter-container" flexDirection="row" justifyContent="space-between">
            <SearchBar
              onSearchChange={value => data.onSearchChange(value)}
              searchContext={data.searchContext}
              searchError={data.searchError}
              value={data.currentSearchFilter}
            />
            <Filters
              appliedFilters={data.appliedFilters}
              content={data.filtersContent}
              contentOptions={contentOptions}
              defaultAppliedFilters={BankVerificationDefaultAppliedFilters}
              filterCountMap={data.filterCountMap}
              filters={data.bankVerificationFilterConfig}
              onSubmit={data.onFilterChange}
              openFiltersPopup={data.filtersPopupOpen}
              updateOpenFiltersPopup={data.updateOpenFiltersPopup}
            />
          </Stack>
          <AppliedFilters
            appliedFilters={data.appliedFilters}
            content={data.appliedFiltersContent}
            filters={data.bankVerificationFilterConfig}
            onAppliedFilterSelect={data.onAppliedFiltersSelect}
            searchResults={
              data.currentSearchFilter &&
              !data.searchError && (
                <RteContent
                  config={{ searchCount: data.paginationContext.totalItems, searchTerm: data.currentSearchFilter }}
                  data={data.searchContext.totalSearchItemsLabel}
                />
              )
            }
          />
          <Stack data-qa="bank-verifications-table-container">
            <BasicTable
              BottomLeftSection={<TotalItems paginationContext={data.paginationContext} />}
              BottomRightSection={<ItemsPerPage paginationContext={data.paginationContext} />}
              alignItems="left"
              columns={data.tableColumns}
              currentPage={data.currentPage}
              data={data.tableData}
              enableRowHover
              nullStateConfig={data.searchError ? undefined : nullStateConfig}
              onPageChange={data.onPageChange}
              onRowHover={data.onRowHover}
              showPagination
              sortConfig={data.sortConfig}
              totalPages={data.totalPages}
            />
            {currentRequestId && (
              <BankVerificationModal
                contentOptions={contentOptions}
                onClose={onClose}
                open={open}
                requestId={currentRequestId}
              />
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};
