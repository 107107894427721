import React, { ComponentProps } from 'react';

import { useGetClientInitiatePlaidContent } from './contentstack';

import { Modal } from '~/components/ui/Modal';
import { Box, Button, Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  connectAccount: () => void;
  contentOptions: ContentOptions;
  dataQa?: string;
  onClose: () => void;
};

export const ClientInitiatePlaidModal: React.FC<Props> = ({
  contentOptions,
  connectAccount,
  dataQa = 'client-initiate-plaid',
  onClose,
  open,
}) => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetClientInitiatePlaidContent({
    variables: contentOptions,
    skip: !open,
  });

  const isMediumScreen = useIsMediumScreen();
  const content = contentData?.all_plaid_funding?.items?.[0]?.client_initiate;

  return (
    <Modal
      actions={
        <>
          <Button data-qa={`${dataQa}-close-cta`} fullWidth={isMediumScreen} onClick={onClose} variant="outlined">
            {content?.secondary_cta}
          </Button>
          <Button
            data-qa={`${dataQa}-delete-cta`}
            fullWidth={isMediumScreen}
            onClick={() => connectAccount()}
            variant="contained"
          >
            {content?.primary_cta}
          </Button>
        </>
      }
      content={
        <Grid container spacing={2} sx={{ px: 4, py: 3 }}>
          <Grid item xs={12}>
            <Box data-qa={`${dataQa}-image`} sx={{ pb: 4 }}>
              <img alt="" src={content?.imageConnection?.edges?.[0]?.node?.url ?? ''} />
            </Box>
            <Typography component="span">{content?.heading}</Typography>
          </Grid>
        </Grid>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={contentError}
      loading={contentLoading}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      title={content?.title}
    />
  );
};
