import { GetPaperworkInputs, GetPaperworkInputsVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetPaperworkInputs = (
  options?: QueryHookOptions<GetPaperworkInputs, GetPaperworkInputsVariables>,
): QueryResult<GetPaperworkInputs, GetPaperworkInputsVariables> => {
  return useSymphonyQuery(queries.GetPaperworkInputs, options);
};
