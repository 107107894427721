import React, { FC, ReactNode } from 'react';

import { MenuList } from './MenuList';

import { Divider } from '~/components/ui/Divider';
import { Box, CloseIcon, IconButton, MuiDrawer, MuiDrawerProps, Stack, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getSxPropsArray } from '~/utils/theme';

interface DrawerMenuItemCommonProps {
  label: NonNullable<ReactNode>;
}

export interface DrawerMenuItem extends DrawerMenuItemCommonProps {
  dataHeap?: string;
  description?: ReactNode;
  icon?: ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  type?: 'list-item';
}

export interface DrawerMenuItemHeader extends DrawerMenuItemCommonProps {
  type: 'list-item-header';
}

export type MenuItem = DrawerMenuItem | DrawerMenuItemHeader;

export interface Props extends Omit<MuiDrawerProps, 'title'> {
  actions?: ReactNode;
  actionsViewOption?: 'stack' | 'inherit';
  dataQa?: string;
  menuItems?: MenuItem[];
  title?: ReactNode;
}

export const Drawer: FC<Props> = ({
  actions,
  actionsViewOption = 'stack',
  children,
  dataQa = 'drawer',
  menuItems,
  onClose,
  open,
  PaperProps,
  title,
  ...otherProps
}) => {
  const {
    sfDrawer: { styles: sfDrawerStyles },
  } = useTheme();
  const showHeader = title || onClose;
  const hasChildrenOnly = !menuItems && !actions && !showHeader;

  return (
    <MuiDrawer
      PaperProps={{ sx: { borderRadius: '4px 4px 0 0', mx: 2, maxHeight: 'calc(100% - 16px)', ...PaperProps?.sx } }}
      anchor="bottom"
      aria-modal="true"
      data-qa={dataQa}
      open={open}
      role="dialog"
      {...otherProps}
    >
      {showHeader && (
        <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent={title ? 'space-between' : 'flex-end'}
            sx={[...getSxPropsArray(sfDrawerStyles.header), hasChildrenOnly && { p: 1, pb: 0 }]}
          >
            {title && (
              <Typography component="h2" variant="h6">
                {title}
              </Typography>
            )}
            {onClose && (
              <IconButton
                aria-label="close"
                autoFocus
                data-qa={`${dataQa}-close`}
                onClick={onClose as () => void}
                sx={{ p: 0 }}
              >
                <CloseIcon fontSize="small" sx={{ color: 'text.secondary' }} />
              </IconButton>
            )}
          </Stack>
          {!hasChildrenOnly && <Divider />}
        </>
      )}

      {children && (
        <Box
          data-qa={`${dataQa}-description`}
          sx={[
            { overflowY: 'auto', flexGrow: 1 },
            ...getSxPropsArray(sfDrawerStyles.description),
            hasChildrenOnly && { p: 1, pt: 0 },
          ]}
        >
          {children}
        </Box>
      )}

      {menuItems && <MenuList items={menuItems} />}

      {actions && (
        <>
          <Divider />
          {actionsViewOption === 'stack' ? (
            <Stack
              data-qa={`${dataQa}-actions`}
              spacing={2}
              sx={{ '&>*': { width: '100%' }, ...sfDrawerStyles.actions }}
            >
              {actions}
            </Stack>
          ) : (
            actions
          )}
        </>
      )}
    </MuiDrawer>
  );
};
