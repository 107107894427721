import { AmplifyScalars } from '~/__generated__';

interface ConfigReadinessStatus {
  color: string;
  threshold: number;
}

interface GoalProjectionsStatus {
  readinessStatuses: ConfigReadinessStatus[];
}

export const isConfigReadinessStatus = (object: Record<string, any>): object is ConfigReadinessStatus => {
  return (
    'color' in object &&
    typeof object.color === 'string' &&
    'threshold' in object &&
    typeof object.threshold === 'number'
  );
};

export const isGoalProjectionsStatus = (object: Record<string, any>): object is GoalProjectionsStatus => {
  return (
    'readinessStatuses' in object &&
    object.readinessStatuses instanceof Array &&
    object.readinessStatuses.length > 0 &&
    object.readinessStatuses.every(isConfigReadinessStatus)
  );
};

/**
 * Parses a goalProjectionStatus from amplify, can throw error needs to be wrapped in try-catch
 * @param jsonString of type string
 * @returns GoalProjectionsStatus object
 */
export const parseGoalProjectionsStatus = (jsonString: AmplifyScalars['AWSJSON']): GoalProjectionsStatus => {
  const parsedReadinessStatuses: GoalProjectionsStatus = JSON.parse(jsonString); // will throw error if non-parseable json

  if (!isGoalProjectionsStatus(parsedReadinessStatuses)) {
    throw Error('PartnerConfig goalProjectionsStatus is missing parameters');
  }

  return parsedReadinessStatuses;
};
