import {
  GetContactAdvisorModalContent,
  GetContactAdvisorModalContent_all_contact_advisor_modal_items,
  GetContactAdvisorModalContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ContactAdvisorModalContent = GetContactAdvisorModalContent_all_contact_advisor_modal_items;

export const useGetContactAdvisorContent = (
  options?: QueryHookOptions<GetContactAdvisorModalContent, GetContactAdvisorModalContentVariables>,
): QueryResult<GetContactAdvisorModalContent, GetContactAdvisorModalContentVariables> => {
  return useContentstackQuery(queries.GetContactAdvisorModalContent, options);
};
