import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { getLastEditDateAndTime, OpsDashboardUser } from '../../utils';

import { useDeleteCommentV2, useSaveCommentV2 } from './symphony';

import { UserNoteEntityType } from '~/__generated__';
import { DeleteComment } from '~/components/modals/DeleteComment';
import { Alert } from '~/components/ui/Alert';
import { Menu } from '~/components/ui/Menu';
import { useModalState } from '~/components/ui/Modal/hooks';
import {
  Button,
  ChatBubbleOutlineIcon,
  Grid,
  IconButton,
  MenuItem,
  MessageIcon,
  MoreHorizIcon,
  MuiTooltip,
  Popover,
  Skeleton,
  useTheme,
} from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { useClientInfo } from '~/hooks/client/useClientInfo';
import { isStringEmpty } from '~/utils/client';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export interface CommentData {
  lastEditDate: string;
  lastEditTime: string;
  lastEditUserName: string;
  lastValue: string;
}

export interface CommentComponentColumn {
  delete_modal?: {
    cancel_cta: string;
    delete_cta: string;
    error_message: string;
    modal_header: string;
    subtitle: string;
    title: string;
  };
  display_mode_menu?: {
    delete: string;
    edit: string;
  };
  edit_mode?: {
    cancel_cta: string;
    error_message: string;
    placeholder: string;
    save_cta: string;
  };
}

export interface Props {
  comment?: Omit<CommentData, 'lastEditUserName'>;
  content?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  dataQa?: string;
  entity: UserNoteEntityType;
  entityId: string;
  lastCommentPartyId?: string;
  maxLength?: number;
  /**
   * TODO: Remove refetch logic once we have a better way
   * to handle cache for mutating objects, tracked in Ticket DA2-4213
   */
  refetchData?: () => void;
}

export interface FormData {
  comment: string;
}

const COMMENT = 'comment';

export const Comment: React.FC<Props> = ({
  comment,
  content,
  contentOptions,
  currentUser,
  dataQa = 'Comment',
  entity,
  lastCommentPartyId,
  maxLength = 50,
  entityId,
  refetchData = () => {
    console.warn('You may need to pass down refetch function when updating comment for a row');
  },
}) => {
  const { name, partyId } = currentUser;
  const { control, watch } = useForm<FormData>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  // if lastCommentPartyId is undefined, then for that row, comment does not exist, set editMode to true.
  const [editMode, setEditMode] = useState<boolean>(!lastCommentPartyId);
  // if lastCommentPartyId is same as the partyId we can directly setComment, since we do not need to fetch User Info.
  const [currentComment, setCurrentComment] = useState<CommentData | undefined>(
    lastCommentPartyId === partyId && comment ? { ...comment, lastEditUserName: name } : undefined,
  );
  const [hasComment, setHasComment] = useState<boolean>(!!lastCommentPartyId);
  const [onSaveError, setOnSaveError] = useState<boolean>(false);
  const [onDeleteError, setOnDeleteError] = useState<boolean>(false);
  const { open: openModalValue, openModal, onClose } = useModalState();
  const open = Boolean(anchorEl);
  const openMenu = Boolean(anchorElMenu);
  const {
    palette,
    sfComment: { styles: sfCommentStyles },
  } = useTheme();
  const { enableCommentDeletion } = useCoreConfig().components.sfOpsDashboard;
  const [SaveCommentV2] = useSaveCommentV2();
  const [DeleteCommentV2] = useDeleteCommentV2();
  const { data: clientData, loading, error: clientDataError } = useClientInfo({
    variables: { partyId: lastCommentPartyId ?? '' },
    // skip if no lastComment or when partyId is same as that of the current user
    skip: !lastCommentPartyId || lastCommentPartyId.length === 0 || lastCommentPartyId === partyId,
  });

  useEffect(() => {
    // Populate Comment when lastCommentPartyId is different then PartyId
    if (clientData && comment && !loading) {
      const lastEditUserName = clientData.userName;
      setCurrentComment({ ...comment, lastEditUserName });
      setEditMode(false);
    }
  }, [clientData, comment, loading]);

  const handleClose = () => {
    if (editMode && currentComment) {
      setEditMode(false);
    }
    setAnchorEl(null);
    setOnSaveError(false);
  };

  const handleCancelButton = () => {
    if (editMode && currentComment) {
      setEditMode(false);
    } else {
      handleClose();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleSetEditMode = () => {
    setEditMode(true);
    handleCloseMenu();
  };

  const handleDeleteOption = () => {
    handleCloseMenu();
    openModal();
  };

  const commentValue = watch(COMMENT);
  const onSave = async () => {
    try {
      await SaveCommentV2({
        variables: {
          entity,
          entityIdentifier: entityId,
          note: commentValue,
          partyId,
        },
      });
      setCurrentComment({
        lastValue: commentValue,
        lastEditUserName: name,
        ...getLastEditDateAndTime(new Date(), contentOptions),
      });
      setEditMode(false); // will change Editmode to Display Mode
      setHasComment(true);
      refetchData();
    } catch (err) {
      console.error(err);
      setOnSaveError(true);
    }
  };

  const onDelete = async () => {
    try {
      await DeleteCommentV2({
        variables: { entity, entityIdentifier: entityId, partyId },
      });
      onClose();
      handleClose();
      setCurrentComment(undefined);
      setEditMode(true);
      setHasComment(false);
      refetchData();
    } catch (err) {
      console.error(err);
      setOnDeleteError(true);
    }
  };
  const id = open ? 'popover' : undefined;

  /** Tooltip used here is the one provided by MUI and the not custom tooltip defined in this package
   * The reason is this tooltip has to be activated by hover, which custim tooltip will require
   * some additional changes,can be picked later if needed.
   */
  return (
    <>
      <MuiTooltip arrow placement="left" title={currentComment?.lastValue ?? ''}>
        <IconButton
          aria-describedby={id}
          data-qa={`${dataQa}-comment-icon`}
          onClick={handleClick}
          sx={
            hasComment
              ? {
                  ':hover': { color: palette.primary.main },
                  color: palette.text.primary,
                }
              : { color: sfCommentStyles.defaultColor }
          }
        >
          {hasComment ? <MessageIcon fontSize="medium" /> : <ChatBubbleOutlineIcon fontSize="medium" />}
        </IconButton>
      </MuiTooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        data-qa={`${dataQa}-popover`}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container sx={{ p: 2, width: '272px' }}>
          {loading ? (
            <Skeleton />
          ) : clientDataError ? (
            <Alert contentOptions={contentOptions} error={clientDataError} severity="error" />
          ) : !editMode && currentComment ? (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography data-qa={`${dataQa}-lastEditUserName`} variant="subtitle1">
                    {currentComment.lastEditUserName}
                  </Typography>
                </Grid>
                <Grid display="flex" item justifyContent="flex-end" xs={6}>
                  <IconButton data-qa={`${dataQa}-menu-button`} onClick={handleClickMenu}>
                    <MoreHorizIcon fontSize="medium" sx={{ mt: -1.5 }} />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography data-qa={`${dataQa}-lastValue`} sx={{ pb: 1 }}>
                {currentComment.lastValue}
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {/* {TODO: Will probably require date time parser} */}
                  <Typography data-qa={`${dataQa}-lastEditDate`} sx={{ mr: 4 }} variant="caption">
                    {currentComment.lastEditDate}
                  </Typography>
                </Grid>
                <Grid display="flex" item justifyContent="flex-end" xs={6}>
                  <Typography data-qa={`${dataQa}-lastEditTime`} variant="caption">
                    {currentComment.lastEditTime}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid data-qa={`${dataQa}-save-comment`} item xs={12}>
              <Controller
                control={control}
                defaultValue={currentComment?.lastValue ?? ''}
                name={COMMENT}
                render={({ value, onChange }) => (
                  <TextField
                    dataQa={`${dataQa}-comment-input`}
                    fullWidth
                    multiline
                    onChange={e => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= maxLength && !isStringEmpty(inputValue)) {
                        onChange(e);
                      }
                    }}
                    placeholder={content?.edit_mode?.placeholder ?? ''}
                    value={value}
                  />
                )}
              />
              {onSaveError && (
                <Grid item sx={{ py: 2, width: 250 }}>
                  <Alert
                    contentOptions={contentOptions}
                    error={content?.edit_mode?.error_message ?? ''}
                    severity="error"
                  />
                </Grid>
              )}
              <Grid container display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button
                  data-qa={`${dataQa}-cancel-edit-mode`}
                  onClick={handleCancelButton}
                  sx={{ mr: 1 }}
                  variant="outlined"
                >
                  {content?.edit_mode?.cancel_cta ?? ''}
                </Button>
                <Button
                  data-qa={`${dataQa}-save-edit-mode`}
                  disabled={
                    commentValue ? commentValue.length === 0 || commentValue === currentComment?.lastValue : true
                  }
                  onClick={onSave}
                  variant="contained"
                >
                  {content?.edit_mode?.save_cta ?? ''}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Menu
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorEl={anchorElMenu}
          data-qa={`${dataQa}-display-mode-menu`}
          id="basic-menu"
          onClose={handleCloseMenu}
          open={openMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem data-qa={`${dataQa}-edit`} onClick={handleSetEditMode}>
            {content?.display_mode_menu?.edit ?? ''}
          </MenuItem>
          {enableCommentDeletion && (
            <MenuItem data-qa={`${dataQa}-delete`} onClick={handleDeleteOption}>
              {content?.display_mode_menu?.delete ?? ''}
            </MenuItem>
          )}
        </Menu>
      </Popover>
      <DeleteComment
        content={{
          ctas: {
            cancel: content?.delete_modal?.cancel_cta ?? '',
            delete: content?.delete_modal?.delete_cta ?? '',
          },
          subtitle: content?.delete_modal?.subtitle ?? '',
          title: content?.delete_modal?.title ?? '',
          header: content?.delete_modal?.modal_header ?? '',
          errorMessage: content?.delete_modal?.error_message ?? '',
        }}
        contentOptions={contentOptions}
        dataQa={`${dataQa}-delete-modal`}
        onClose={onClose}
        onDelete={onDelete}
        onDeleteError={onDeleteError}
        open={openModalValue}
      />
    </>
  );
};
