import React, { useEffect, useState } from 'react';

import { CancelIcon, InfoIcon, InputAdornment, SearchIcon, Stack } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { SearchContext } from '~/utils/types';

interface Props {
  dataQa?: string;
  disabled?: boolean;
  onSearchChange: (searchTerm: string | null) => void;
  searchContext?: SearchContext;
  searchError: boolean;
  value?: string;
}

export const SearchBar: React.FC<Props> = ({ dataQa, disabled, onSearchChange, value, searchError, searchContext }) => {
  const [currentSearchValue, setSearchValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState<string | undefined>(
    searchError ? searchContext?.tooManySearchResultsLabel : undefined,
  );

  useEffect(() => {
    if (value) {
      setSearchValue(value);
    }
  }, [value]);

  const handleSearchChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (currentSearchValue === '') {
        clearSearch();
        return;
      }
      if (currentSearchValue && currentSearchValue.length < 4) {
        setError(searchContext?.searchLengthErrorLabel);
      } else {
        onSearchChange(currentSearchValue);
      }
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setError(undefined);
    onSearchChange(null);
  };

  return (
    <Stack flexDirection="column" height="78px">
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {currentSearchValue || value ? (
                <CancelIcon fontSize="small" onClick={clearSearch} sx={{ '&:hover': { cursor: 'pointer' } }} />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
          startAdornment: null,
        }}
        dataQa={dataQa}
        disabled={disabled}
        error={!!error}
        fullWidth
        onBlur={() => setIsFocused(false)}
        onChange={e => setSearchValue(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onKeyDown={handleSearchChange}
        placeholder={searchContext?.searchPlaceholderLabel}
        size="medium"
        sx={{ width: '350px', height: '40px' }}
        value={currentSearchValue}
        variant="outlined"
      />
      <Typography align="left" sx={{ marginTop: 2 }} variant="caption">
        {isFocused && !error && <>{searchContext?.searchLengthHelperLabel}</>}
        {error && (
          <>
            <InfoIcon sx={{ color: 'error.main', pr: 0.5, fontSize: 'small' }} />
            <Typography sx={{ color: 'error.main' }} variant="caption">
              {error}
            </Typography>
          </>
        )}
      </Typography>
    </Stack>
  );
};
