import React, { ComponentProps, FC } from 'react';

import { Box, MuiCircularProgress, visuallyHidden } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

interface Props extends ComponentProps<typeof MuiCircularProgress> {
  dataQa?: string;
}

/**
 * The CircularProgress should be used to indicate page-level loading or a major section of the page is loading.
 * The Skeleton is for smaller components that are loading.
 * As a result, there should be fewer usage of CircularProgress than Skeleton, and for ADA
 * the screen readers shall announce "loading".
 *
 * Hardcoded for now, since contentstack would require ApolloProvider, but the CircularProgress maybe rendered before auth0.
 */
export const CircularProgress: FC<Props> = ({ dataQa = 'circular-progress', ...rest }) => {
  return (
    <Box data-qa={dataQa} textAlign="center">
      <MuiCircularProgress disableShrink sx={{ my: 5 }} {...rest} />
      <Typography aria-live="polite" style={visuallyHidden}>
        loading
      </Typography>
    </Box>
  );
};
