/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

export const SessionTimeoutImage = () => {
  return (
    <svg fill="none" height="100" viewBox="0 0 100 100" width="100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" fill="#FAFAFA" r="50" />
      <path
        d="M50 75C63.8071 75 75 63.8071 75 50C75 36.1929 63.8071 25 50 25C36.1929 25 25 36.1929 25 50C25 63.8071 36.1929 75 50 75Z"
        fill="#333333"
      />
      <path
        d="M50 73.125C62.7716 73.125 73.125 62.7716 73.125 50C73.125 37.2284 62.7716 26.875 50 26.875C37.2284 26.875 26.875 37.2284 26.875 50C26.875 62.7716 37.2284 73.125 50 73.125Z"
        fill="#333333"
        opacity="0.15"
      />
      <path
        d="M50 71.25C61.736 71.25 71.25 61.736 71.25 50C71.25 38.2639 61.736 28.75 50 28.75C38.2639 28.75 28.75 38.2639 28.75 50C28.75 61.736 38.2639 71.25 50 71.25Z"
        fill="#91DDF8"
      />
      <path d="M49.9999 50V28.75C44.1324 28.75 38.8199 31.1288 34.9736 34.9738L49.9999 50Z" fill="#009DD6" />
      <path
        d="M47.54 28.9038L50 33.75L52.46 28.9038C51.6513 28.81 50.8338 28.75 50 28.75C49.1663 28.75 48.3488 28.81 47.54 28.9038Z"
        fill="#007EAB"
      />
      <path
        d="M51.7666 51.7699C50.7891 52.7449 49.2066 52.7436 48.2316 51.7661C47.2566 50.7886 36.2666 36.2349 36.2666 36.2349C36.2666 36.2349 50.7966 47.2574 51.7716 48.2349C52.7466 49.2124 52.7429 50.7949 51.7666 51.7699Z"
        fill="#007EAB"
      />
    </svg>
  );
};
