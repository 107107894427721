import React from 'react';

import { SfVariant } from '../theme';

import { AssetAllocation, AssetClassTier, DonutAllocation } from './types';

import { Allocation } from '~/components/AssetAllocationTable';
import { Portfolio } from '~/components/PortfolioDetails';
import { DonutSlice, DonutSliceMeta, Props as DonutProps } from '~/components/ui/Donut';
import { Box, Grid } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { CashAllocation } from '~/hooks/account-details/symphony';
import { GetAssetClassContent_all_asset_class_data_items as AssetClassContentItem } from '~/hooks/asset-classes/contentstack/__generated__/query.v2';

interface CompositeAllocation {
  modelPortfolio: {
    guidance: Guidance;
  };
  modelPortfolioInternalId: number;
  modelPortfolioSeriesBaseName: string;
  modelPortfolioSeriesId?: number;
  percentage: string;
}

interface StockBondDiversification {
  bondPercentage: string | null;
  stockPercentage: string | null;
}

interface Diversification {
  assets: {
    allocations: AssetAllocation[];
    stockBondDiversification: StockBondDiversification | null;
  };
}

interface Guidance {
  diversification: Diversification | null;
}

export interface RecommendedModelPortfolio extends RecommendedPortfolioAbstract {
  __typename: 'RecommendedPortfolio';
  guidance?: Guidance | null;
  internalId: number | null;
  name: string | null;
  riskPreference?: {
    description: string;
    value: number;
  };
  seriesBaseName: string | null;
  seriesId: number | null;
}

export interface CompositeRecommendedModelPortfolio extends RecommendedPortfolioAbstract {
  __typename: 'CompositeRecommendedPortfolio';
  allocation: CompositeAllocation[];
  guidance: Guidance;
  minimumBalanceForAccountOpening: string;
}

interface RecommendedPortfolioAbstract {
  __typename: string;
}

export type AssetClassContent = (AssetClassContentItem | null)[];

export const getAssetClassId = (assetClassTier: AssetClassTier, ids: Record<AssetClassTier, string>): string => {
  return ids[assetClassTier];
};

export const getAssetClassContent = (
  assetClassId: string,
  assetClassTier: AssetClassTier,
  assetClassContentData?: AssetClassContent,
): AssetClassContentItem | null => {
  return (
    assetClassContentData?.find(ac => ac?.asset_class_id === assetClassId && ac.asset_class_tier === assetClassTier) ??
    null
  );
};

export const getAssetAllocations = (
  allocations: AssetAllocation[] | undefined,
  assetClassContentData?: AssetClassContent,
  assetClassTier = AssetClassTier.MODEL,
): Allocation[] => {
  const assetAllocationData =
    allocations?.map(allocation => {
      const assetClassId = getAssetClassId(assetClassTier, {
        [AssetClassTier.MODEL]: allocation.asset.name,
        [AssetClassTier.ENCLOSING]: allocation.enclosingAssetClass ?? '',
        [AssetClassTier.BROAD]: allocation.broadAssetClass ?? '',
      });
      const assetClassContentObj = getAssetClassContent(assetClassId, assetClassTier, assetClassContentData);

      return {
        color: assetClassContentObj?.color ?? '#000000',
        opacity: assetClassContentObj?.opacity ?? '100',
        order: assetClassContentObj?.order ?? null,
        assetClass: {
          key: assetClassId,
          label: assetClassContentObj?.display_name ?? '',
          tooltip:
            assetClassContentObj?.description_title && assetClassContentObj.description_body ? (
              <Box sx={{ p: 2 }}>
                <RteContent data={assetClassContentObj.description_title} sx={{ mb: 1 }} />
                <RteContent data={assetClassContentObj.description_body} />
              </Box>
            ) : undefined,
        },
        tickers:
          allocation?.recommendedSecurities?.map(i => ({
            tooltip: i.security.name ?? '',
            key: i.security.ticker ?? '',
            label: i.security.ticker ?? '',
          })) ?? [],
        percent: parseFloat(allocation?.targetAllocation ?? ''),
        targetPercent: parseFloat(allocation?.targetAllocation ?? ''),
        value: 0,
      };
    }) ?? [];

  return assetClassTier !== AssetClassTier.MODEL
    ? aggregateAssetAllocationDataByAssetClassKey(assetAllocationData)
    : assetAllocationData;
};

export const getDonutSlicesFromDonutAllocations = (
  donutAllocation: Array<DonutAllocation>,
  assetClassContentData?: AssetClassContent,
  assetClassTier = AssetClassTier.MODEL,
): DonutSlice[] => {
  const donutSlices = donutAllocation?.map(allocation => {
    const assetClassId = getAssetClassId(assetClassTier, {
      [AssetClassTier.MODEL]: allocation.asset.name,
      [AssetClassTier.ENCLOSING]: allocation.enclosingAssetClass ?? '',
      [AssetClassTier.BROAD]: allocation.broadAssetClass ?? '',
    });
    const assetClassContentObj = getAssetClassContent(assetClassId, assetClassTier, assetClassContentData);
    return {
      label: '',
      key: assetClassId,
      color: assetClassContentObj?.color ?? '#000000',
      opacity: assetClassContentObj?.opacity ?? '100',
      value: parseFloat(allocation.targetAllocation) ?? 0,
      meta: undefined,
    };
  });

  return assetClassTier !== AssetClassTier.MODEL ? aggregateDonutSlicesByKey(donutSlices) : donutSlices;
};

export const allocationToDonutSlice = (
  assetClassTier = AssetClassTier.MODEL,
  isActualAllocation = false,
  labels?: { actualLabel: string; targetLabel: string },
) => ({
  assetClass,
  color,
  opacity,
  order,
  targetPercent,
  actualPercent,
  tickers,
}: Allocation): DonutSlice<DonutSliceMeta> => {
  const { targetLabel, actualLabel } = labels ?? {};
  const meta =
    actualLabel && targetLabel
      ? { actualPercent: actualPercent ?? 0, targetLabel, actualLabel, targetPercent: targetPercent ?? 0 }
      : undefined;
  // In FRC we have cash as asset class.
  // In other partners we don't have cash as asset class.
  // Ticker for cash is null - for this we are handling cash differently.
  return {
    label:
      assetClassTier !== AssetClassTier.MODEL || assetClass.key === 'Cash'
        ? assetClass.label
        : tickers[0]?.label || 'Unknown',
    key: assetClass.key,
    color,
    opacity,
    order,
    value: isActualAllocation ? actualPercent ?? 0 : targetPercent ?? 0,
    meta,
  };
};

const aggregateDonutSlicesByKey = (donutSlices: DonutSlice[]): DonutSlice[] => {
  const assetClassIdToSliceMap: Record<string, DonutSlice> = {};

  donutSlices.forEach(slice => {
    const existingSlice = assetClassIdToSliceMap[slice.key] ?? undefined;
    const { label, key, color, opacity, value } = slice;
    assetClassIdToSliceMap[slice.key] = {
      label,
      key,
      color,
      opacity: opacity ?? '100',
      value: value + (existingSlice?.value ?? 0),
    };
  });

  return Object.values(assetClassIdToSliceMap);
};

export const aggregateAssetAllocationDataByAssetClassKey = (allocations: Allocation[]): Allocation[] => {
  const assetClassToSecuritiesMap: Record<string, Allocation> = {};

  allocations.forEach(allocation => {
    const existingSecuritiesInAssetClass = assetClassToSecuritiesMap[allocation.assetClass.key] ?? undefined;
    const newTickers = [...(existingSecuritiesInAssetClass?.tickers ?? []), ...allocation.tickers];
    assetClassToSecuritiesMap[allocation.assetClass.key] = {
      actualPercent: (existingSecuritiesInAssetClass?.actualPercent ?? 0) + (allocation?.actualPercent ?? 0),
      assetClass: {
        key: allocation.assetClass.key,
        label: allocation.assetClass.label,
        tooltip:
          newTickers.length === 0
            ? null
            : newTickers.map(security => {
                return (
                  <Grid container key={security.key} sx={{ display: 'flex', py: 2 }}>
                    <Grid item xs={3}>
                      <RteContent data={security.key} />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={8}>
                      {security.tooltip}
                    </Grid>
                  </Grid>
                );
              }),
      },
      color: allocation.color,
      opacity: allocation.opacity,
      order: allocation.order,
      percent: (existingSecuritiesInAssetClass?.percent ?? 0) + (allocation?.percent ?? 0),
      tickers: [...(existingSecuritiesInAssetClass?.tickers ?? []), ...allocation.tickers],
      targetPercent: (existingSecuritiesInAssetClass?.targetPercent ?? 0) + (allocation?.targetPercent ?? 0),
      value: (existingSecuritiesInAssetClass?.value ?? 0) + (allocation?.value ?? 0),
    };
  });

  return Object.values(assetClassToSecuritiesMap);
};

export const computeCashAllocation = (cash: CashAllocation): Allocation => {
  const cashAllocation = {
    color: '#000000',
    opacity: '100',
    order: null,
    assetClass: {
      key: 'Cash',
      label: 'Cash',
      tooltip: undefined,
    },
    tickers: [],
    percent: 0,
    targetPercent: undefined,
    actualPercent: parseFloat(cash?.nonBufferPercentage ?? '0'),
    value: parseFloat(cash?.nonBuffer?.value ?? '0'),
  };

  return cashAllocation;
};

export const getDonutProps = (
  portfolio: Portfolio,
  stockBondLabel: string,
  typographyVariants?: Partial<Record<string, SfVariant>>,
): DonutProps => ({
  centerSubtext: stockBondLabel,
  centerText: portfolio.stockBondRatio,
  centerTextVariant: typographyVariants?.stockBondSplitLabel,
  data: portfolio.portfolioAssetsBreakdown,
});

export const isCompositeRecommendedPortfolio = (
  portfolio?: RecommendedPortfolioAbstract | null,
): portfolio is CompositeRecommendedModelPortfolio => portfolio?.__typename === 'CompositeRecommendedPortfolio';

export const isRecommendedPortfolio = (
  portfolio?: RecommendedPortfolioAbstract | null,
): portfolio is RecommendedModelPortfolio => portfolio?.__typename === 'RecommendedPortfolio';

export const sortBySpecifiedOrder = <T extends { order?: number | null }>(items: T[]): T[] => {
  return items.sort((a, b) => {
    // https://stackoverflow.com/a/29829370
    return (
      (a.order === null ? 1 : 0) - (b.order === null ? 1 : 0) ||
      +((a.order ?? 0) > (b.order ?? 0)) ||
      -((a.order ?? 0) < (b.order ?? 0))
    );
  });
};
