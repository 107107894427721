import { useMemo } from 'react';

import { getModalContent } from '../utils';

import { useGetChangeInvestmentMethodModalContent } from '~/components/modals/Funding/ChangeInvestmentMethod/contentstack';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

export interface Content {
  cancel: string;
  errorMessages: {
    maxInitialAmount: string;
  };
  heading: string;
  imageUrl: string;
  infoMessages: Record<string, string>;
  investmentDurations: Record<string, DropdownItem[]>;
  investmentFrequencies: DropdownItem[];
  investmentMethods: DropdownItem[];
  labels: {
    initialAmount: string;
    investmentDuration: string;
    investmentFrequency: string;
    investmentMethod: string;
    totalAmount: string;
  };
  save: string;
  title: string;
}

interface Data {
  content: Content;
}

export const useChangeInvestmentMethodModalContent = ({ contentOptions }: Variables): AsyncResult<Data> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetChangeInvestmentMethodModalContent({
    variables: contentOptions,
  });

  const data: Data | undefined = useMemo(() => {
    if (contentLoading || contentError) {
      return;
    }

    return {
      content: getModalContent(contentData),
    };
  }, [contentData, contentError, contentLoading]);

  return {
    data,
    error: contentError,
    loading: contentLoading,
  };
};
