import { getQuarter, getYear, parseISO } from 'date-fns';

import {
  GetAccountPerformanceContent,
  GetAccountPerformanceContent_all_account_details_items,
  GetAccountPerformanceContent_all_account_details_items_sections,
  GetAccountPerformanceContent_all_account_details_items_sections_AccountDetailsSectionsAccountActivity_account_activity_account_activityConnection_edges_node,
  GetAccountPerformanceContent_all_account_performance_items_filters,
  GetAccountPerformanceContent_all_asset_allocation_section_items as AssetAllocation,
} from '../contentstack/__generated__/query.v2';

import { PerformanceMeasurementInput } from '~/__generated__';
import {
  AccountBalanceChartContent,
  AccountOverviewContent,
  AccountPerformanceSideBar,
  AssetAllocationColumn,
  MarketReviewContent,
  PerformanceFilterContent,
  PerformanceFilterDropDownOption,
  PerformanceFilterDropdowns,
  ReturnsChartContent,
} from '~/components/AccountPerformance/types';
import { getStockBondRatio } from '~/containers/Plan/utils';
import { Assets, getPastQuartersDropdownOptions } from '~/hooks/account-details/utils';
import { MeasurementName } from '~/hooks/performance/types';
import { MarketCommentary } from '~/hooks/qpr/symphony';
import { AssetAllocationTableColumns } from '~/utils/config';

export const getMonthlyMarketReviewUrl = (
  marketCommentary: MarketCommentary[],
  selectedQuarter: string,
  selectedTimePeriod: MeasurementName,
  endDate: string,
): string => {
  const endQuarter = getQuarter(parseISO(endDate));
  const endYear = getYear(parseISO(endDate));
  const quarter =
    selectedTimePeriod === MeasurementName.QuarterlyPerformance && selectedQuarter
      ? selectedQuarter
      : `Q${endQuarter} ${endYear}`;
  return marketCommentary.find(item => `Q${item.quarter} ${item.year}` === quarter)?.url || '';
};

export interface AccountPerformanceContent {
  accountOverview: AccountOverviewContent;
  assetAllocationSectionContent?: AssetAllocation | null;
  asset_allocation_column?: AssetAllocationColumn;
  balanceChart: AccountBalanceChartContent;
  marketReview: MarketReviewContent;
  performanceFilters: PerformanceFilterContent;
  returnsChart: ReturnsChartContent;
  sections: (GetAccountPerformanceContent_all_account_details_items_sections | null)[];
  sidebarItems: AccountPerformanceSideBar[];
}

const getFilterItem = (
  measurement: MeasurementName,
  filterItems?: (GetAccountPerformanceContent_all_account_performance_items_filters | null)[] | null,
) => {
  const filterItem = filterItems?.find(item => item?.key === measurement);
  return {
    label: filterItem?.value ?? '',
    value: measurement,
  };
};

/**
 * The function `getPerformanceFilterDropdownOptions` iterates through each filter item,
 * checks if the measurementInputs has a field called name which matches to the value field in performanceFilters.
 * If the value is present disabled is set to false.
 *
 * `getPastQuartersDropdownOptions` is called to get Quarters based on the firstRebalancedOn Date.
 * If the function returns any quarter that is completed. The `QuarterlyPerformance` filter option is enabled
 *
 * @param {{ label: string; value: MeasurementName; }[]} performanceFilterContent filters array fetched from contentstack and formatted by `getFormattedContentData` function
 * @param {string} firstRebalancedOn First Rebalanced Date of an Account
 * @param {PerformanceMeasurementInput[]} [measurementInputs??] measurementInputs is an object created based on firstRebalancedDate
 * @return {PerformanceFilterDropdowns}
 **/
export const getPerformanceFilterDropdownOptions = (
  performanceFilterContent: Array<{ label: string; value: MeasurementName }>,
  firstRebalancedOn: string,
  measurementInputs?: PerformanceMeasurementInput[],
): PerformanceFilterDropdowns => {
  const filterOptions: PerformanceFilterDropDownOption[] = [];
  performanceFilterContent.forEach(filter => {
    const measurementInput = measurementInputs?.find(input => input.name === filter.value);
    filterOptions.push({ ...filter, disabled: measurementInput === undefined });
  });

  const pastQuarterDropdownOptions = getPastQuartersDropdownOptions(firstRebalancedOn) ?? [];

  if (pastQuarterDropdownOptions.length > 0) {
    const quarterlyPerformanceFilterIndex = filterOptions.findIndex(
      filter => filter.value === MeasurementName.QuarterlyPerformance,
    );
    filterOptions[quarterlyPerformanceFilterIndex].disabled = false;
  }
  return { timePeriodDropdownOptions: filterOptions, pastQuarterDropdownOptions };
};

export const getFormattedContentData = (contentData: GetAccountPerformanceContent): AccountPerformanceContent => {
  const allAccountDetails = contentData.all_account_details?.items?.[0];
  const allAccountPerformance = contentData.all_account_performance?.items?.[0];
  // TODO: Reference it in Account Details section (as part of https://sigfig.atlassian.net/browse/DA2-6168)
  const allAssetAllocationSection = contentData.all_asset_allocation_section?.items?.[0];
  return {
    asset_allocation_column: allAccountDetails?.asset_allocation_column,
    assetAllocationSectionContent: allAssetAllocationSection,
    sections: allAccountDetails?.sections ?? [],
    balanceChart: {
      ctas: {
        detailedTable: allAccountPerformance?.cta?.find(item => item?.key === 'detailed_table_cta')?.value || '',
      },
      labels: {
        accountBalance: allAccountPerformance?.labels?.find(item => item?.key === 'account_balance')?.value || '',
        startingBalance: allAccountPerformance?.labels?.find(item => item?.key === 'starting_balance')?.value || '',
        totalEarned: allAccountPerformance?.labels?.find(item => item?.key === 'total_earned')?.value || '',
      },
    },
    performanceFilters: {
      ctas: {
        downloadPdf: allAccountPerformance?.cta?.find(item => item?.key === 'download_pdf_cta')?.value || '',
      },
      filters: [
        getFilterItem(MeasurementName.SinceInception, allAccountPerformance?.filters),
        getFilterItem(MeasurementName.QuarterlyPerformance, allAccountPerformance?.filters),
        getFilterItem(MeasurementName.QTD, allAccountPerformance?.filters),
        getFilterItem(MeasurementName.YTD, allAccountPerformance?.filters),
        getFilterItem(MeasurementName.OneYear, allAccountPerformance?.filters),
        getFilterItem(MeasurementName.ThreeYears, allAccountPerformance?.filters),
        getFilterItem(MeasurementName.FiveYears, allAccountPerformance?.filters),
      ],
      labels: {
        account: allAccountPerformance?.labels?.find(item => item?.key === 'account')?.value || '',
        asOf: allAccountPerformance?.labels?.find(item => item?.key === 'asOf')?.value || '',
        viewPerformance:
          allAccountPerformance?.labels?.find(item => item?.key === 'viewing_performance_for')?.value || '',
      },
    },
    accountOverview: {
      labels: {
        accountBalance: allAccountPerformance?.labels?.find(item => item?.key === 'account_balance')?.value || '',
        overview: allAccountPerformance?.labels?.find(item => item?.key === 'overview')?.value || '',
        returns: allAccountPerformance?.labels?.find(item => item?.key === 'returns')?.value || '',
        returnsHelperText:
          allAccountPerformance?.labels?.find(item => item?.key === 'returns_helper_text')?.value || '',
      },
    },
    marketReview: {
      ctas: {
        viewDetails: allAccountPerformance?.cta?.find(item => item?.key === 'view_details_cta')?.value || '',
      },
      labels: {
        subTitle: allAccountPerformance?.labels?.find(item => item?.key === 'market_review_subtitle')?.value || '',
        title: allAccountPerformance?.labels?.find(item => item?.key === 'market_review_title')?.value || '',
      },
    },
    returnsChart: {
      labels: {
        detailedTable:
          allAccountPerformance?.cta?.find(item => item?.key === 'detailed_table_cta')?.value || 'detailed_table_cta',
        hideIndicesButton: allAccountPerformance?.cta?.find(item => item?.key === 'hide_indices')?.value || '',
        returns: allAccountPerformance?.labels?.find(item => item?.key === 'returns')?.value || '',
        showIndicesButton: allAccountPerformance?.cta?.find(item => item?.key === 'show_indices')?.value || '',
      },
    },
    sidebarItems: allAccountPerformance?.sidebar ? allAccountPerformance.sidebar : [],
  };
};

export const getAccountActivityContent = (
  accountActivityContent: GetAccountPerformanceContent_all_account_details_items_sections_AccountDetailsSectionsAccountActivity_account_activity_account_activityConnection_edges_node,
) => ({
  header: {
    subText: accountActivityContent.header?.sub_text ?? '',
    title: accountActivityContent.header?.title ?? '',
    balanceIncreasedBy: accountActivityContent.header?.balance_increased_by ?? '',
    balanceDecreasedBy: accountActivityContent.header?.balance_decreased_by ?? '',
    thisQuarter: accountActivityContent.header?.this_quarter ?? '',
  },
  tableHeading: {
    activityColumnLabel: accountActivityContent.table_heading?.activity_column_label ?? '',
    valueColumnLabel: accountActivityContent.table_heading?.value_column_label ?? '',
  },
  tableBody: {
    tableRow:
      accountActivityContent.table_body?.table_row?.map(row => ({
        key: row?.key ?? '',
        label: row?.label ?? '',
        isBold: row?.is_bold ?? false,
        showTotal: row?.show_total ?? false,
      })) ?? [],
  },
});

export const getAssetAllocationContent = (
  content: Partial<GetAccountPerformanceContent_all_account_details_items>,
  assetAllocationContent: AssetAllocation | null,
  assets?: Assets,
) => {
  const sectionContent = assetAllocationContent?.content ?? [];
  const stockBondSplit = getStockBondRatio({
    bondPercentage: assets?.stockBondDiversification?.bondPercentage ?? 'bondPercentage',
    stockPercentage: assets?.stockBondDiversification?.stockPercentage ?? 'stockPercentage',
  });
  const cashSleeveContent = assetAllocationContent?.cash_sleeveConnection?.edges?.[0]?.node;
  return {
    title: sectionContent.find(item => item?.key === 'section_title')?.value || 'section_title',
    assetBreakdownTitle:
      sectionContent.find(item => item?.key === 'section_asset_breakdown_title')?.value ||
      'section_asset_breakdown_title',
    emptyState: sectionContent.find(item => item?.key === 'section_empty_state')?.value ?? 'section_empty_state',
    factSheetLinkText: sectionContent.find(item => item?.key === 'view_fact_sheet')?.value ?? 'view_fact_sheet',
    tableColumns: (content.asset_allocation_column?.values as AssetAllocationTableColumns[]) ?? undefined,
    donut: {
      actualLabel: sectionContent.find(item => item?.key === 'donut_actual_label')?.value || 'donut_actual_label',
      targetLabel: sectionContent.find(item => item?.key === 'donut_target_label')?.value || 'donut_target_label',
      stockBondSplit: {
        label:
          sectionContent.find(item => item?.key === 'donut_stock_bond_split_label')?.value ||
          'MISSING STOCK BOND SPLIT',
        value: stockBondSplit,
      },
      actualAllocationLabel:
        sectionContent.find(item => item?.key === 'donut_header_actual_allocation')?.value ??
        'donut_header_actual_allocation',
      targetAllocationLabel:
        sectionContent.find(item => item?.key === 'donut_header_target_allocation')?.value ??
        'donut_header_target_allocation',
    },
    cashSleeveContent: {
      cashLabel: cashSleeveContent?.cash_label ?? 'cash_label',
      investmentsLabel: cashSleeveContent?.investments_label ?? 'investments_label',
      caption: cashSleeveContent?.caption ?? 'caption',
      description: cashSleeveContent?.description ?? 'description',
    },
    otherAssetClass: sectionContent.find(item => item?.key === 'other_asset_class')?.value ?? 'other_asset_class',
  };
};
