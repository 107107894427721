// TODO: update amount to value
export interface DailyChartValue {
  amount: number;
  date: string;
}

export interface DailyTableValue {
  dataPoints: string[];
  date: string;
}

export interface PaginationContext {
  currentPage: number;
  recordsPerPage: number;
  recordsPerPageLabel: string;
  totalItems: number;
  totalItemsLabel: string;
  updateRecordsPerPage?: (recordsCount: number) => void;
}

export interface SearchContext {
  searchLengthErrorLabel: string;
  searchLengthHelperLabel: string;
  searchPlaceholderLabel: string;
  tooManySearchResultsLabel: string;
  totalSearchItemsLabel: string;
}

export interface SymphonyDecimalAttribute {
  __typename: 'BooleanAttribute' | 'DecimalAttribute' | 'IntegerAttribute';
  name: string;
}
export interface SymphonyBooleanAttribute {
  __typename: 'BooleanAttribute';
  booleanValue: boolean;
  name: string;
}

export interface SymphonyStringAttribute {
  __typename: 'StringAttribute';
  name: string;
  stringValue: string;
}

export interface TextField {
  key: string | null;
  value: string | null;
}

export type SymphonyAttribute = SymphonyDecimalAttribute | SymphonyStringAttribute;

export enum Environment {
  Bash = 'bash',
  Dev = 'dev',
  Local = 'local',
  Prod = 'prod',
  Staging = 'staging',
}

export interface AsyncResult<TData = any> {
  data?: TData;
  error?: Error;
  loading: boolean;
}

export interface LazyAsyncResult<TData = any> {
  load: () => void;
  result: AsyncResult<TData>;
}

/**
 * @deprecated by DA2-6027. Use AsyncResult instead.
 */
export type ComponentHookResult<TData = any> = AsyncResult<TData>;

/**
 * @deprecated by DA2-6027. Use LazyAsyncResult instead.
 */
export type LazyComponentHookResult<TData = any> = LazyAsyncResult<TData>;
