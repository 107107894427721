import {
  GetQuarterlyPerformanceReport,
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_allocations_guidance_diversification_assets,
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_marketCommentary,
  GetQuarterlyPerformanceReportVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type QuarterlyAssets = GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_allocations_guidance_diversification_assets;
export type MarketCommentary = GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_marketCommentary;

export const useGetQuarterlyPerformanceReport = (
  options?: QueryHookOptions<GetQuarterlyPerformanceReport, GetQuarterlyPerformanceReportVariables>,
): QueryResult<GetQuarterlyPerformanceReport, GetQuarterlyPerformanceReportVariables> => {
  return useSymphonyQuery(queries.GetQuarterlyPerformanceReport, options);
};
