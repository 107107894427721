import React from 'react';
import { Tooltip } from 'recharts';

import { formatDisplayDate } from '~/components/AccountPerformance/utils';
import { Allocation, AssetAllocationTable } from '~/components/AssetAllocationTable';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { Donut, DonutSlice, DonutSliceMeta, DonutTooltipWrapper } from '~/components/ui/Donut';
import { Link } from '~/components/ui/Link';
import { Box, Grid, TableContainer, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { SleeveAllocation } from '~/hooks/account-details/symphony';
import { allocationToDonutSlice } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { AssetAllocationTableColumns } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { formatCurrency, formatPercentage } from '~/utils/format';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Content {
  assetBreakdownTitle: string;
  cashSleeveContent: {
    caption: string;
    cashLabel: string;
    description: string;
    investmentsLabel: string;
  };
  donut: {
    actualAllocationLabel: string;
    actualLabel: string;
    stockBondSplit: {
      label: string;
      value: string;
    };
    targetAllocationLabel: string;
    targetLabel: string;
  };
  emptyState: string;
  factSheetLinkText: string;
  otherAssetClass: string;
  tableColumns?: AssetAllocationTableColumns[];
  title: string;
}

export interface Props {
  allocations: Allocation[];
  assetClassTier?: AssetClassTier;
  cashSleeve?: SleeveAllocation | null;
  content: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  endDate?: string;
  factSheetUrl?: string;
  isComparison?: boolean;
  isSavingsOptimizerEnabled: boolean;
  modelPortfolioName?: string | null;
  showActualAllocationInDonut?: boolean;
  showDate?: boolean;
  showFactSheetLinkInAssetAllocation?: boolean;
  showPortfolioNameInAssetAllocation?: boolean;
}

interface DonutTooltipProps extends React.ComponentProps<typeof Tooltip> {
  contentOptions: ContentOptions;
}

const DonutTooltip: React.FC<DonutTooltipProps> = ({ active, payload, contentOptions }) => {
  if (active) {
    const slice = payload?.[0].payload as DonutSlice<DonutSliceMeta> | undefined;
    const meta = slice?.meta;
    const targetPercent = meta?.targetPercent ?? 0;
    const actualPercent = meta?.actualPercent ?? 0;
    const targetLabel = meta?.targetLabel;
    const actualLabel = meta?.actualLabel;

    return (
      <DonutTooltipWrapper>
        <Typography>
          <strong>{slice?.label}</strong>
        </Typography>
        <Typography>
          {targetLabel}:{' '}
          <strong>
            {formatPercentage(targetPercent / 100, {
              decimals: 1,
              removeTrailingZeroes: false,
              locale: contentOptions.locale,
            })}
          </strong>
        </Typography>
        <Typography>
          {actualLabel}:{' '}
          <strong>
            {formatPercentage(actualPercent / 100, {
              decimals: 1,
              removeTrailingZeroes: false,
              locale: contentOptions.locale,
            })}
          </strong>
        </Typography>
      </DonutTooltipWrapper>
    );
  }
  return null;
};

export const AssetAllocation: React.FC<Props> = ({
  assetClassTier,
  dataQa = 'asset-allocation',
  cashSleeve,
  contentOptions,
  allocations,
  content: {
    assetBreakdownTitle,
    title,
    emptyState,
    factSheetLinkText,
    tableColumns = [
      AssetAllocationTableColumns.AssetClass,
      AssetAllocationTableColumns.Asset,
      AssetAllocationTableColumns.TargetAllocation,
      AssetAllocationTableColumns.ActualAllocation,
      AssetAllocationTableColumns.Value,
    ],
    donut,
    cashSleeveContent,
  },
  endDate,
  factSheetUrl,
  isComparison = false,
  isSavingsOptimizerEnabled = false,
  modelPortfolioName,
  showActualAllocationInDonut,
  showDate,
  showFactSheetLinkInAssetAllocation,
  showPortfolioNameInAssetAllocation,
}) => {
  const {
    sfAssetAllocation: { typographyVariants },
  } = useTheme();

  const isMediumScreen = useIsMediumScreen();
  const totalInvestmentValue = allocations.reduce((a, b) => a + b.value, 0);

  const isFactSheetLinkVisible = factSheetUrl && showFactSheetLinkInAssetAllocation;
  const isPortfolioNameVisible = modelPortfolioName && showPortfolioNameInAssetAllocation;
  const isDateVisible = endDate && showDate;

  return (
    <div data-qa={dataQa}>
      <Typography
        component="h2"
        sx={{ mb: isDateVisible ? 0.5 : 3 }}
        variant={typographyVariants?.sectionHeading || 'h3'}
      >
        {isSavingsOptimizerEnabled ? assetBreakdownTitle : title}
      </Typography>
      {isDateVisible && (
        <Typography sx={{ mb: 3 }} variant={typographyVariants?.date}>
          {formatDisplayDate(endDate)}
        </Typography>
      )}
      {(isFactSheetLinkVisible || isPortfolioNameVisible) && (
        <Box sx={{ mb: 3 }}>
          {isPortfolioNameVisible && (
            <Typography
              sx={{ display: 'inline-block', mr: 1.5 }}
              variant={typographyVariants?.sectionPortfolioName || 'h5'}
            >
              {modelPortfolioName}
            </Typography>
          )}
          {isFactSheetLinkVisible && (
            <Link href={factSheetUrl} rel="noopener noreferrer" target="_blank">
              {factSheetLinkText}
            </Link>
          )}
        </Box>
      )}
      {isSavingsOptimizerEnabled && (
        <>
          <Grid container direction={isMediumScreen ? undefined : 'row'} spacing={3}>
            <Grid item xs={12}>
              <Typography sx={{ color: 'text.secondary' }} variant="body2">
                {cashSleeveContent.cashLabel}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: 'text.primary' }} variant="h3">
                {formatCurrency(parseInt(cashSleeve?.value.value ?? '0', 10), { locale: contentOptions.locale })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: 'text.primary' }} variant="h5">
                {cashSleeveContent.caption}
              </Typography>
            </Grid>
            <Grid item mb={2} xs={9}>
              <RteContent
                config={{
                  cashValue: formatCurrency(parseInt(cashSleeve?.value.value ?? '0', 10), {
                    locale: contentOptions.locale,
                  }),
                }}
                data={cashSleeveContent.description}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
        </>
      )}
      {allocations.length > 0 ? (
        <>
          {isSavingsOptimizerEnabled && (
            <>
              <Grid container direction={isMediumScreen ? undefined : 'row'} spacing={3}>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'text.secondary' }} variant="body2">
                    {cashSleeveContent.investmentsLabel}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography mb={2} sx={{ color: 'text.primary' }} variant="h3">
                    {formatCurrency(totalInvestmentValue, { locale: contentOptions.locale })}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container direction={isMediumScreen ? undefined : 'row-reverse'} spacing={3}>
            <Grid item md="auto" xs={12}>
              <Donut
                centerSubtext={donut.stockBondSplit.label}
                centerText={donut.stockBondSplit.value}
                centerTextVariant={typographyVariants?.stockBondSplitLabel}
                data={allocations.map(allocationToDonutSlice(assetClassTier, showActualAllocationInDonut, donut))}
                innerRadius={75}
                label={showActualAllocationInDonut ? donut.actualAllocationLabel : donut.targetAllocationLabel}
                showTooltip
                size={275}
                tooltipContent={({ active, payload }) => (
                  <DonutTooltip active={active} contentOptions={contentOptions} payload={payload} />
                )}
              />
            </Grid>
            <Grid item md xs={12}>
              <TableContainer>
                <AssetAllocationTable
                  columns={tableColumns}
                  contentOptions={contentOptions}
                  data={allocations}
                  isComparison={isComparison}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </>
      ) : (
        <Alert severity="warning">{emptyState}</Alert>
      )}
    </div>
  );
};
