/**
 * We are currently using FeedbackMessageContext to set and access the feedbackMessageType
 * Since we are setting the value in a different container, we are wrapping the whole App with FeedbackMessageProvider
 * to access the feedbackMessageType anywhere.
 *
 * In future this Context can be used at an application level instead of using query-string or URL state(react-router-dom).
 */
import React, { useState } from 'react';

import { FeedbackMessageType } from '~/components/ui/Snackbar';

export interface FeedbackMessageContextType {
  feedbackMessageType?: FeedbackMessageType;
  setFeedbackMessageType?: (feedbackMessageType?: FeedbackMessageType) => void;
}

export const FeedbackMessageContext = React.createContext({} as FeedbackMessageContextType);

export const FeedbackMessageProvider: React.FC = ({ children }) => {
  const [feedbackMessageType, setFeedbackMessageType] = useState<FeedbackMessageType>();
  return (
    <FeedbackMessageContext.Provider
      value={{
        feedbackMessageType,
        setFeedbackMessageType,
      }}
    >
      {children}
    </FeedbackMessageContext.Provider>
  );
};
