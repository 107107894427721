import { isValid, parseISO } from 'date-fns';
import { pipe } from 'fp-ts/pipeable';
import React, { RefObject } from 'react';

import { getTransferStatusContentKey } from '../mappers';

import { ScheduledTransferStatus, TransferFrequency, TransferType } from '~/__generated__';
import { TransferAttribute } from '~/components/modals/ViewTransfers/symphony';
import { Content, Row } from '~/components/TransfersTable';
import { Link } from '~/components/ui/Link';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { getPlaidMaskedAccountNumber } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrencyPrecise, formatCurrencyRounded, formatMaskedAccountNumber } from '~/utils/format';
import { formatDate } from '~/utils/format/date';

export interface CellContentsProps {
  cancelCtaProps?: {
    onClick: () => void;
    ref: RefObject<HTMLAnchorElement>;
  };
  cancelTransferStatus?: ScheduledTransferStatus;
  content: Content;
  contentOptions: ContentOptions;
  data: Content['columns'][number]['data'];
  row: Row;
}

export const CellContents: React.FC<CellContentsProps> = ({
  cancelCtaProps,
  cancelTransferStatus,
  content,
  contentOptions,
  data,
  row,
}) => {
  const scheduledAtDate = parseISO(row.scheduledAt ?? '');
  const isRMD = row.attributes.some((attribute: TransferAttribute) => attribute.name === 'RMD_PLAN_ID');
  switch (data) {
    case 'Date':
      return <>{formatDate(row.createdOn, content.date.format, { locale: contentOptions.locale })}</>;
    case 'Amount':
      return (
        <>
          {pipe(
            row.total.value,
            parseFloat,
            content.amount.format === 'Precise'
              ? x => formatCurrencyPrecise(x, { locale: contentOptions.locale })
              : x => formatCurrencyRounded(x, { locale: contentOptions.locale }),
          )}
        </>
      );
    case 'Frequency':
      switch (row.frequency) {
        case TransferFrequency.ONE_TIME:
          return <>{content.frequency.oneTime}</>;
        case TransferFrequency.MONTHLY:
          return <>{content.frequency.monthly}</>;
        case TransferFrequency.WEEKLY:
          return <>{content.frequency.weekly}</>;
        // TODO: add support for BiWeekly and BiMonthly to transfer table (DA2-1191)
        default:
          return <></>;
      }
    case 'Description':
      const isDeposit = row.type === TransferType.DEPOSIT;
      const maskedAccountNumber =
        getPlaidMaskedAccountNumber(row.bankAccount?.attributes) ?? row.bankAccount?.maskedAccountNumber;
      const config = {
        accountNumberPrefix:
          row.bankAccount?.nickName ||
          `${row.bankAccount?.financialInstitution ?? ''}${
            row.bankAccount?.productName ? ` ${row.bankAccount.productName}` : ''
          }` ||
          'bank',
        maskedAccountNumber: formatMaskedAccountNumber(content.accountNumberFormat, maskedAccountNumber),
        settlementDate: row.scheduledAt
          ? formatDate(row.scheduledAt, content.date.format, {
              locale: contentOptions.locale,
            })
          : '-',
      };
      const dayOfWeek = isValid(scheduledAtDate)
        ? formatDate(scheduledAtDate, 'E', { locale: contentOptions.locale }) /* Mon, Tue, Wed */
        : 'unknown day';
      const dateOfMonth = isValid(scheduledAtDate)
        ? formatDate(scheduledAtDate, 'do', { locale: contentOptions.locale }) /* 1st, 2nd, 3rd */
        : 'unknown date';
      switch (row.frequency) {
        case TransferFrequency.ONE_TIME:
          return (
            <RteContent
              config={config}
              data={
                isRMD
                  ? content.description.oneTime.rmd ?? ''
                  : (isDeposit ? content.description.oneTime.deposit : content.description.oneTime.withdrawal) ?? ''
              }
            />
          );
        case TransferFrequency.MONTHLY:
          return (
            <RteContent
              config={{ ...config, dateOfMonth }}
              data={(isDeposit ? content.description.monthly.deposit : content.description.monthly.withdrawal) ?? ''}
            />
          );
        case TransferFrequency.WEEKLY:
          return (
            <RteContent
              config={{ ...config, dayOfWeek }}
              data={(isDeposit ? content.description.weekly.deposit : content.description.weekly.withdrawal) ?? ''}
            />
          );
        // TODO: add support for BiWeekly and BiMonthly to transfer table (DA2-1191)
        default:
          return <></>;
      }
    case 'Settlement Date':
      return (
        <>
          {row.scheduledAt
            ? formatDate(row.scheduledAt, content.date.format, { locale: contentOptions.locale })
            : 'MISSING SETTLEMENT DATE'}
        </>
      );
    case 'Contribution Year':
      const yearOfScheduledAtDate = isValid(scheduledAtDate)
        ? formatDate(scheduledAtDate, 'yyyy', { locale: contentOptions.locale })
        : 'N/A';
      return (
        <>
          {row.frequency !== TransferFrequency.ONE_TIME && row.type === TransferType.DEPOSIT
            ? yearOfScheduledAtDate
            : row.contributionYear ?? 'N/A'}
        </>
      );
    case 'Action':
      if (row.isCancellable && !cancelTransferStatus && !isRMD) {
        if (!cancelCtaProps) {
          throw new Error('cancelCtaProps is required if isCancellable is true');
        }
        const { onClick, ref } = cancelCtaProps;
        return (
          // pass ref to cancel button to set focus here if cancel row is dismissed
          <Link component="button" data-qa="cancel-button" onClick={onClick} ref={ref}>
            {content.action.cancel || 'MISSING CANCEL ACTION'}
          </Link>
        );
      } else if (
        row.status === ScheduledTransferStatus.PENDING_CANCELLATION ||
        (cancelTransferStatus &&
          [ScheduledTransferStatus.PENDING_CANCELLATION, ScheduledTransferStatus.CANCELLED].includes(
            cancelTransferStatus,
          ))
      ) {
        return <>{content.action.pendingCancel || 'MISSING PENDING CANCEL ACTION'}</>;
      } else if (
        row.status === ScheduledTransferStatus.PENDING ||
        cancelTransferStatus === ScheduledTransferStatus.PENDING
      ) {
        return <>{content.action.pending || 'MISSING PENDING ACTION'}</>;
      }
      return <>{content.action.inProgress || 'MISSING IN PROGRESS ACTION'}</>;
    case 'Status':
      return <>{getTransferStatusContentKey(row.status, content.status)}</>;
    case 'UNKNOWN':
      return <>Unknown transfers table data type</>;
    default:
      return <></>;
  }
};
