import React, { ComponentProps, useEffect, useState } from 'react';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Button, LoadingButton, WarningIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';

export interface DiscardPendingChangeContent {
  ctas: {
    cancel: string;
    close?: string;
    discard: string;
  };
  discardMessage: string;
  successfulDiscardMessage?: string;
  title: string;
}

export type Props = ComponentProps<typeof Modal> & {
  content: DiscardPendingChangeContent;
  contentOptions: ContentOptions;
  dataQa?: string;
  loading?: boolean;
  onClose: () => void;
  onDiscard: () => Promise<void>;
  onDiscardCallback?: () => void;
  showDiscardSuccessful?: boolean;
  showWarningIcon?: boolean;
};

export const DiscardPendingChange: React.FC<Props> = ({
  content,
  contentOptions,
  dataQa = 'discard-pending-change',
  loading = false,
  onClose,
  onDiscard,
  onDiscardCallback,
  open,
  showDiscardSuccessful = true,
  showWarningIcon = false,
  ...modalProps
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [discardSuccessful, setDiscardSuccessful] = useState(false);

  useEffect(() => {
    if (open) {
      setError(undefined);
      setDiscardSuccessful(false);
    }
  }, [open]);

  const handleDiscard = async () => {
    setIsBusy(true);
    try {
      await onDiscard();
      if (showDiscardSuccessful) {
        /*
         * If Modal has to show second stage where it gives a
         * confirmation message where model has been discarded
         */
        setDiscardSuccessful(true);
      } else {
        if (onDiscardCallback) {
          onDiscardCallback();
        }
      }
    } catch (err: any) {
      setError(err);
      console.error(err);
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <Modal
      actions={
        discardSuccessful ? (
          <Button data-qa={`${dataQa}-close-cta`} onClick={onDiscardCallback} variant="outlined">
            {content.ctas.close}
          </Button>
        ) : (
          <>
            <Button data-qa={`${dataQa}-cancel-cta`} disabled={isBusy} onClick={onClose} variant="outlined">
              {content.ctas.cancel}
            </Button>
            <LoadingButton
              data-qa={`${dataQa}-discard-cta`}
              loading={isBusy}
              onClick={handleDiscard}
              variant="contained"
            >
              {content.ctas.discard}
            </LoadingButton>
          </>
        )
      }
      content={
        <>
          {showWarningIcon && <WarningIcon fontSize="large" sx={{ color: 'warning.main', mb: 2 }} />}
          <RteContent
            data={(discardSuccessful ? content.successfulDiscardMessage : content.discardMessage) ?? ''}
            sx={{ '& h1, h2, h3, h4, h5, h6': { mb: 3 } }}
          />
          {error && <Alert contentOptions={contentOptions} error={error} severity="error" sx={{ mt: 1 }} />}
        </>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      loading={loading}
      onClose={discardSuccessful ? onDiscardCallback : onClose}
      open={open}
      title={content.title}
      {...modalProps}
    />
  );
};
