import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  FilterConfig,
  FilterCountMap,
  FilterTypes,
  OptionFilterConfig,
  RangeFilterConfig,
  SelectedFilters,
} from './types';
import {
  getFilterConfigMaxRangeValue,
  getFilterConfigOptions,
  getSelectedOptions,
  getSelectedRange,
  isRangeFilterType,
} from './utils';

import { CurrencyAmountRange } from '~/components/ui/CurrencyAmountRange';
import {
  Box,
  Button,
  Checkbox,
  ErrorIcon,
  FilterListIcon,
  FormControlLabel,
  FormGroup,
  Popover,
  Radio,
  Stack,
} from '~/components/ui/mui';
import { NumberFormatRange, NumberRange } from '~/components/ui/NumberFormatRange';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface Content {
  allFilter: string;
  apply: string;
  filters: string;
  maxValueLabel?: string;
  minValueLabel?: string;
  rangeFilterErrorMessage?: string;
  resetAll: string;
}

export interface Props {
  appliedFilters: SelectedFilters;
  content: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  defaultAppliedFilters: SelectedFilters;
  filterCountMap?: FilterCountMap[];
  filters: FilterConfig[];
  onSubmit: (selectedFilters: SelectedFilters) => void;
  openFiltersPopup: boolean;
  updateOpenFiltersPopup: (value: boolean) => void;
}

export const Filters: React.FC<Props> = ({
  appliedFilters,
  content,
  contentOptions,
  dataQa = 'filters',
  filters,
  defaultAppliedFilters,
  onSubmit,
  openFiltersPopup,
  updateOpenFiltersPopup,
  filterCountMap,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [error, setError] = useState<{ [filterId: string]: boolean }>({});
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(defaultAppliedFilters);

  useEffect(() => {
    if (openFiltersPopup) {
      setSelectedFilters(appliedFilters);
    }
  }, [appliedFilters, filters, openFiltersPopup]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (openFiltersPopup && event.code === 'Escape') {
      updateOpenFiltersPopup(false);
    }
  };

  const toggleFilterPopover = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    updateOpenFiltersPopup(!openFiltersPopup);
  };

  const handleOnClose = useCallback(() => {
    setError({});
    updateOpenFiltersPopup(false);
  }, [updateOpenFiltersPopup]);

  const handleOptionChange = useCallback((filterId: string, optionId: string) => {
    setSelectedFilters(prevState => {
      const selectedOptions = getSelectedOptions(prevState[filterId]);
      return {
        ...prevState,
        [filterId]: selectedOptions
          ? {
              filterType: FilterTypes.CHECKBOX_GROUP,
              selectedOptions: selectedOptions.includes(optionId)
                ? selectedOptions.filter(id => id !== optionId)
                : [...selectedOptions, optionId],
            }
          : {
              filterType: FilterTypes.CHECKBOX_GROUP,
              selectedOptions: [optionId],
            },
      };
    });
  }, []);

  const handleRadioOptionChange = useCallback((filterId: string, optionId: string) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [filterId]: { filterType: FilterTypes.RADIO_GROUP, selectedOptions: [optionId] },
    }));
  }, []);

  const handleRangeFilterError = useCallback((filterId: string) => {
    setError(prevState => ({
      ...prevState,
      [filterId]: true,
    }));
  }, []);

  const handleRangeFilterSelection = useCallback(
    (filterKey: string, filterType: FilterTypes, maxValue: number, isFilterSelected: boolean) => {
      if (!isFilterSelected) {
        const { [filterKey]: data, ...remainingSelectedFilters } = selectedFilters;
        setSelectedFilters(remainingSelectedFilters);
        return;
      }

      if (isRangeFilterType(filterType)) {
        setSelectedFilters(prevState => ({
          ...prevState,
          [filterKey]: { filterType, range: { maxValue, minValue: 0 } },
        }));
      }
    },
    [selectedFilters],
  );

  const handleRangeFilterValueChange = useCallback((filterKey: string, filterType: FilterTypes, range: NumberRange) => {
    setError(prevState => ({
      ...prevState,
      [filterKey]: false,
    }));
    if (isRangeFilterType(filterType)) {
      setSelectedFilters(prevState => ({
        ...prevState,
        [filterKey]: { filterType, range },
      }));
    }
  }, []);

  const handleAllOptionChange = useCallback(
    (filterKey: string, allSelected: boolean) => {
      setSelectedFilters(prevState => ({
        ...prevState,
        [filterKey]: {
          filterType: FilterTypes.CHECKBOX_GROUP,
          selectedOptions: allSelected
            ? filters
                .find((filter): filter is OptionFilterConfig => filter.key === filterKey)
                ?.options.map(option => option.id) || []
            : [],
        },
      }));
    },
    [filters],
  );

  const handleReset = useCallback(() => {
    setError({});
    setSelectedFilters(defaultAppliedFilters);
  }, [defaultAppliedFilters]);

  const handleFormSubmit = useCallback(() => {
    updateOpenFiltersPopup(false);
    setSelectedFilters(prev => {
      const updatedFilters = Object.keys(prev).reduce<SelectedFilters>((acc, filterKey) => {
        const currentFilter = prev[filterKey];
        if (!currentFilter) {
          return acc;
        }
        // NOTE: only special handling needed for checkbox and radio group
        if ('selectedOptions' in currentFilter) {
          acc[filterKey] = currentFilter.selectedOptions.length ? currentFilter : defaultAppliedFilters[filterKey];
        } else {
          acc[filterKey] = currentFilter;
        }
        return acc;
      }, {});
      onSubmit(updatedFilters);

      return updatedFilters;
    });
  }, [defaultAppliedFilters, onSubmit, updateOpenFiltersPopup]);

  const getAllFilterCount = useCallback(() => {
    let filteredCounts = filterCountMap;
    Object.keys(defaultAppliedFilters).forEach(key => {
      const currentOptions = getSelectedOptions(defaultAppliedFilters[key]);
      filteredCounts = filterCountMap?.filter(v => currentOptions?.includes(v.filters[key]));
    });
    if (!filteredCounts?.length) {
      return '';
    }
    return filteredCounts.reduce((acc, curr) => acc + curr.count, 0);
  }, [defaultAppliedFilters, filterCountMap]);

  const getFilterCount = useCallback(
    (currentKey: string, currentOption: string) => {
      const filteredCounts = filterCountMap?.filter(v => v.filters[currentKey] === currentOption) ?? [];

      if (!filteredCounts.length) {
        return '';
      }

      return filteredCounts.reduce((acc, curr) => acc + curr.count, 0);
    },
    [filterCountMap],
  );

  const isApplyButtonEnabled = useMemo(() => {
    const keys = Object.keys(selectedFilters);
    return keys.every(key => {
      const currentFilter = selectedFilters[key];
      return (
        !error[key] &&
        (!currentFilter || !('selectedOptions' in currentFilter) || currentFilter.selectedOptions.length > 0)
      );
    });
  }, [error, selectedFilters]);

  const maxValueLabel = content.maxValueLabel ?? '';
  const minValueLabel = content.minValueLabel ?? '';
  const rangeFilterErrorMessage = content.rangeFilterErrorMessage ?? '';

  return (
    <Box>
      <Button
        aria-controls={`${dataQa}-popover`}
        aria-expanded={openFiltersPopup ? 'true' : 'false'}
        endIcon={<FilterListIcon />}
        id={`${dataQa}-open-button`}
        onClick={toggleFilterPopover}
        variant="outlined"
      >
        {content.filters}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        aria-labelledby={`${dataQa}-open-button`}
        data-qa={`${dataQa}-popover`}
        id="filters-popover"
        onClose={handleOnClose}
        onKeyDown={onKeyDown}
        open={openFiltersPopup}
        role="region"
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, maxHeight: '487px', maxWidth: '360px' }}>
          <Box
            sx={{
              borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
              maxHeight: '419px',
              overflowY: 'scroll',
              width: '100%',
            }}
          >
            {filters.map((filter, index, items) => (
              <Box
                key={filter.key}
                sx={{
                  ...(index + 1 !== items.length && {
                    borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
                    mb: 2,
                  }),
                  p: 1,
                }}
              >
                {filter.type === FilterTypes.CHECKBOX_GROUP && (
                  <>
                    <Typography>{filter.label}</Typography>
                    <FormGroup>
                      <>
                        <Stack alignItems="center" direction="row" justifyContent="space-between" key={filter.key}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  getSelectedOptions(selectedFilters[filter.key])?.length ===
                                  getFilterConfigOptions(filter)?.length
                                }
                                onChange={e => handleAllOptionChange(filter.key, e.target.checked)}
                              />
                            }
                            label={content.allFilter}
                          />
                          <Typography sx={{ paddingRight: 0.5 }}>{getAllFilterCount()}</Typography>
                        </Stack>
                        {getFilterConfigOptions(filter)?.map(option => (
                          <Stack alignItems="center" direction="row" justifyContent="space-between" key={option.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    getSelectedOptions(selectedFilters[filter.key])?.includes(option.id) || false
                                  }
                                  onChange={() => handleOptionChange(filter.key, option.id)}
                                />
                              }
                              key={option.id}
                              label={option.label}
                            />
                            <Typography sx={{ paddingRight: 0.5 }}>{getFilterCount(filter.key, option.id)}</Typography>
                          </Stack>
                        ))}
                      </>
                    </FormGroup>
                  </>
                )}
                {filter.type === FilterTypes.RADIO_GROUP && (
                  <>
                    <Typography>{filter.label}</Typography>
                    <FormGroup>
                      {getFilterConfigOptions(filter)?.map(option => (
                        <FormControlLabel
                          control={
                            <Radio
                              checked={getSelectedOptions(selectedFilters[filter.key])?.includes(option.id) || false}
                              onChange={() => handleRadioOptionChange(filter.key, option.id)}
                            />
                          }
                          key={option.id}
                          label={option.label}
                          sx={{ pl: 1.5 }}
                        />
                      ))}
                    </FormGroup>
                  </>
                )}
                {isRangeFilterType(filter.type) && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedFilters[filter.key]}
                          onChange={e =>
                            handleRangeFilterSelection(
                              filter.key,
                              filter.type,
                              (filter as RangeFilterConfig).maxRangeValue,
                              e.target.checked,
                            )
                          }
                        />
                      }
                      key={filter.key}
                      label={filter.label}
                    />
                    {selectedFilters[filter.key] &&
                      (filter.type === FilterTypes.CURRENCY_RANGE ? (
                        <CurrencyAmountRange
                          contentOptions={contentOptions}
                          defaultValues={getSelectedRange(selectedFilters[filter.key])}
                          labels={{ maxValue: maxValueLabel, minValue: minValueLabel }}
                          maxInputValue={getFilterConfigMaxRangeValue(filter)}
                          onChange={currencyRange =>
                            handleRangeFilterValueChange(filter.key, filter.type, currencyRange)
                          }
                          onError={() => handleRangeFilterError(filter.key)}
                        />
                      ) : (
                        <NumberFormatRange
                          defaultValues={getSelectedRange(selectedFilters[filter.key])}
                          labels={{ maxValue: maxValueLabel, minValue: minValueLabel }}
                          maxInputValue={getFilterConfigMaxRangeValue(filter)}
                          onChange={numberRange => handleRangeFilterValueChange(filter.key, filter.type, numberRange)}
                          onError={() => handleRangeFilterError(filter.key)}
                        />
                      ))}
                    {error[filter.key] && (
                      <Stack direction="row" spacing={1} sx={{ color: 'error.main', mt: 1 }}>
                        <ErrorIcon fontSize="small" />
                        <Typography role="alert" sx={{ color: 'error.main' }} variant="caption">
                          {rangeFilterErrorMessage}
                        </Typography>
                      </Stack>
                    )}
                  </>
                )}
              </Box>
            ))}
          </Box>
          <Box sx={{ pt: 2, width: '100%' }}>
            <Stack flexDirection="row" justifyContent="space-between">
              <Button onClick={handleReset} size="medium" sx={{ color: 'primary.main' }}>
                {content.resetAll}
              </Button>
              <Button disabled={!isApplyButtonEnabled} onClick={handleFormSubmit} size="medium" variant="contained">
                {content.apply}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
