import React from 'react';

import { FilterConfig, SelectedFilters } from '../Filters/types';
import { getFilterConfigOptions, getSelectedOptions } from '../Filters/utils';

import { Typography } from '~/components//ui/Typography';
import { MuiChip, Stack } from '~/components/ui/mui';

interface Content {
  allFilter: string;
  appliedFilters: string;
}

interface Props {
  appliedFilters: SelectedFilters;
  content: Content;
  filters: FilterConfig[]; // TODO: DWCORE-9389: handle range filters
  onAppliedFilterSelect: (filterKey?: string, selectedOption?: string) => void;
  searchResults?: React.ReactNode;
}

export const AppliedFilters: React.FC<Props> = ({
  appliedFilters,
  content,
  filters,
  onAppliedFilterSelect,
  searchResults,
}) => {
  if (!Object.keys(appliedFilters).some(key => getSelectedOptions(appliedFilters[key])?.length)) {
    return null;
  }

  return (
    <Stack
      alignItems="start"
      data-qa="applied-filter-container"
      flexDirection="row"
      justifyContent="space-between"
      sx={{ my: 2 }}
    >
      <Stack sx={{ my: 1, width: '30%' }}>{searchResults}</Stack>
      <Stack sx={{ width: '70%' }}>
        <Stack flexDirection="row" flexWrap="wrap" justifyContent="right">
          <Typography component="div" sx={{ my: 1 }} variant="subtitle2">
            {content.appliedFilters}:
          </Typography>
          {filters.map((filter: FilterConfig, index) => {
            const filterConfigOptions = getFilterConfigOptions(filter);
            const selectedOptions = getSelectedOptions(appliedFilters[filter.key]);
            if (filterConfigOptions?.length && selectedOptions?.length) {
              return (
                <Stack
                  alignItems="center"
                  flexDirection="row"
                  flexWrap="wrap"
                  key={`filter-stack-${index}`}
                  sx={{ my: 1 }}
                >
                  <Typography sx={{ ml: 2 }} variant="body2">
                    {filter.label}:
                  </Typography>
                  {filterConfigOptions.length !== selectedOptions.length ? (
                    selectedOptions.map(selectedOption => {
                      const label = filterConfigOptions.find(option => option.id === selectedOption)?.label;

                      return (
                        <MuiChip
                          key={selectedOption}
                          label={label}
                          onClick={() => onAppliedFilterSelect(filter.key, selectedOption)}
                          size="small"
                          sx={{
                            backgroundColor: 'primary.selected',
                            color: 'primary.main',
                            ml: 1,
                          }}
                          variant="filled"
                        />
                      );
                    })
                  ) : (
                    <MuiChip
                      key={`${filter.key}_all`}
                      label={content.allFilter}
                      onClick={() => onAppliedFilterSelect(filter.key, 'all')}
                      size="small"
                      sx={{
                        backgroundColor: 'primary.selected',
                        color: 'primary.main',
                        ml: 1,
                      }}
                      variant="filled"
                    />
                  )}
                </Stack>
              );
            }

            return <></>;
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
