import { identity } from 'fp-ts/lib/function';
import React, { useCallback, useMemo } from 'react';

import { Drawer } from '~/components/ui/Drawer';
import { Menu } from '~/components/ui/Menu';
import { useMenu } from '~/components/ui/Menu/hooks';
import { Button, ExpandMoreIcon, MenuItem, SxProps, SystemStyleObject, Theme, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { SfStatusButtonLabelStyles } from '~/utils/theme';

export interface Props {
  currentStatus: string;
  dataQa?: string;
  onMenuItemClick?: (selectedStatus: string) => void;
  showDefaultButton?: boolean;
  statusLabels: Record<string, string>;
  statusUpdateItems: Array<string>;
}

export const StatusButton: (props: Props) => JSX.Element = ({
  currentStatus,
  dataQa = 'status-button',
  onMenuItemClick,
  statusLabels,
  statusUpdateItems,
  showDefaultButton,
}) => {
  const {
    sfStatusButton: { styles: sfStatusButtonStyles },
  } = useTheme();
  const isMobile = useIsMediumScreen();
  const { buttonProps, menuProps } = useMenu(`status-button-actions`);

  const handleClick = useCallback(
    (statusItem: string) => {
      onMenuItemClick?.(statusItem);
      menuProps.onClose();
    },
    [menuProps, onMenuItemClick],
  );

  const drawerActionItems = statusUpdateItems.map(statusItem => ({
    label: statusLabels[statusItem],
    onClick: () => handleClick(statusItem),
  }));

  const menuItems = useMemo(
    () =>
      statusUpdateItems.map((statusItem, idx) => {
        return (
          <MenuItem key={idx} onClick={() => handleClick(statusItem)}>
            {statusLabels[statusItem]}
          </MenuItem>
        );
      }),
    [handleClick, statusLabels, statusUpdateItems],
  );

  const statusStyleLabel = statusLabels[currentStatus]
    .split(' ')
    .join('_')
    .toLowerCase() as keyof SfStatusButtonLabelStyles;

  let buttonLabelStyles = {} as SxProps<Theme>;
  if (sfStatusButtonStyles.label) {
    buttonLabelStyles = sfStatusButtonStyles.label[statusStyleLabel] || sfStatusButtonStyles.label.default;
  }

  const defaultStyles = {
    backgroundColor: 'primary.selected',
    color: 'primary.main',
    width: '100%',
    '&:hover': { backgroundColor: 'primary.selected', color: 'primary.main' },
  };

  const buttonStyles = {
    ...(Object.assign({}, buttonLabelStyles) as SxProps<Theme>),
    '&:hover': buttonLabelStyles as SystemStyleObject<Theme>,
  };

  return (
    <div data-qa={dataQa}>
      <Button
        {...buttonProps}
        aria-label="More Status Actions"
        data-qa={`${dataQa}-cta`}
        disabled={!onMenuItemClick}
        endIcon={<ExpandMoreIcon />}
        onClick={event => menuItems.length > 0 && buttonProps.onClick(event)}
        sx={showDefaultButton ? defaultStyles : buttonStyles}
        variant="contained"
      >
        <Typography variant="subtitle2">{statusLabels[currentStatus]}</Typography>
      </Button>
      {menuItems.length > 0 &&
        (isMobile ? (
          <Drawer menuItems={drawerActionItems} onClose={menuProps.onClose} open={menuProps.open} />
        ) : (
          <Menu data-qa={`${dataQa}-list`} {...menuProps}>
            {menuItems.map(identity)}
          </Menu>
        ))}
    </div>
  );
};
