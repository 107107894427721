import React, { FC } from 'react';

import { useCompositeModelPortfolioAllocation } from './hooks';

import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { Box, Grid, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { formatPercentageString } from '~/utils/format';
import { SfTheme } from '~/utils/theme';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface CMPAllocation {
  modelPortfolioInternalId: number;
  modelPortfolioSeriesBaseName: string;
  modelPortfolioSeriesId?: number;
  percentage: string;
}

export interface Props {
  allocation: CMPAllocation[];
  contentOptions: ContentOptions;
  dataQa?: string;
  showloader: boolean;
}
export const CompositeModelPortfolioAllocation: FC<Props> = ({
  allocation,
  dataQa = 'composite-model-portfolio-allocation',
  contentOptions,
  showloader,
}) => {
  const {
    sfAccount: { root, typographyVariants },
  } = useTheme<SfTheme>();
  const { data, loading, error } = useCompositeModelPortfolioAllocation({
    contentOptions,
    allocation,
  });
  const compositeModelPortfolioData = data?.compositeModelPortfolioAllocationData;
  const content = data?.content;

  return (
    <Grid container data-qa={dataQa} justifyContent="space-between" mb={3} spacing={3}>
      <Grid item xs={12}>
        <Typography component="div" sx={{ mb: 5, fontWeight: 700 }} variant="h3">
          {content?.title}
        </Typography>
      </Grid>
      {(loading || showloader) && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading Composite Model Portfolio Allocation"
            contentOptions={contentOptions}
            error={error}
            loading={loading || showloader}
          />
        </Grid>
      )}
      {compositeModelPortfolioData && (
        <Grid item xs={12}>
          {compositeModelPortfolioData.map((portfolio, index) => {
            return (
              <Grid container data-qa={dataQa} justifyContent="space-between" key={index} spacing={2} sx={{ ...root }}>
                <Grid display="flex" flexDirection="row" item xs={12}>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box data-qa={`${dataQa}-image`} sx={{ height: '64px', mt: -1, py: 2, textAlign: 'center' }}>
                        <img alt="donut-chart" src={content?.donutImageUrl} />
                      </Box>
                      <Box sx={{ px: 2 }}>
                        <Typography sx={{ color: 'text.secondary' }} variant="caption">
                          {content?.portfolioLabel}
                        </Typography>
                        <Typography variant={typographyVariants?.portfolioName}>
                          {portfolio.modelPortfolioName}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography sx={{ color: 'text.secondary' }} variant="caption">
                        {content?.percentageLabel}
                      </Typography>
                      <Typography variant={typographyVariants?.portfolioName}>
                        {portfolio.percentage &&
                          formatPercentageString(portfolio.percentage, { locale: contentOptions.locale })}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};
