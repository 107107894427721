import React, { useCallback, useMemo, useState } from 'react';

import { LegalDocument } from '../../../../symphony';
import { PendingChangeCardContent } from '../../../../utils';
import { PendingChangeCard } from '../PendingChangeCard';

import { AssociatedEntityType, LegalDocumentStatus } from '~/__generated__';
import { DiscardPendingChange } from '~/components/modals/DiscardPendingChange';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Grid } from '~/components/ui/mui';
import { Snackbar } from '~/components/ui/Snackbar';
import { useResendEnvelopeToNextRecipient, useUpdateSigningDocument } from '~/hooks/docusign/symphony';
import {
  getLegalDocumentsForPendingBankAccountAssociation,
  getNextPendingSignee,
  nonPendingLegalDocumentStatuses,
} from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  content: PendingChangeCardContent;
  contentOptions: ContentOptions;
  dataQa?: string;
  legalDocuments?: LegalDocument[];
  managedProductId: string;
  partyId: string;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  refetchAccounts?: () => Promise<any>;
  refetchingAccounts?: boolean;
  viewerPartyId: string;
}

interface PendingBankAccountAssociationData {
  allowDiscard: boolean;
  cta: () => void;
  ctaText: string;
  date: string;
  signingDocumentId: string;
}

export const PendingBankAccountAssociation: React.FC<Props> = ({
  content,
  contentOptions,
  dataQa,
  managedProductId,
  legalDocuments = [],
  redirectToSignDocuments,
  refetchAccounts,
  refetchingAccounts,
  viewerPartyId,
}) => {
  const [selectedCardId, setSelectedCardId] = useState<string>();
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [resendEnvelopeToNextRecipient] = useResendEnvelopeToNextRecipient();
  const [updateSigningDocument] = useUpdateSigningDocument();
  const { open, onClose, openModal } = useModalState();

  const refetchItems = async () => {
    if (open) {
      onClose();
    }
    await refetchAccounts?.();
  };

  const resendDocusign = useCallback(
    async (id: string) => {
      try {
        await resendEnvelopeToNextRecipient({
          variables: { signingDocumentId: id },
        });
        setShowSnackBar(true);
      } catch (err) {
        console.error(`Error Sending Docusign to Client`, err);
      }
    },
    [resendEnvelopeToNextRecipient],
  );

  const pendingCards = useMemo((): PendingBankAccountAssociationData[] => {
    const documents = getLegalDocumentsForPendingBankAccountAssociation(legalDocuments);
    if (documents) {
      return documents.reduce((acc: PendingBankAccountAssociationData[], legalDocument) => {
        const bankAccountAssociationId = legalDocument.associatedEntities?.bankAccountAssociationIds[0] ?? '';
        const nextPendingSignee = getNextPendingSignee(legalDocument.signees);

        if (!nonPendingLegalDocumentStatuses.includes(legalDocument.status)) {
          if (nextPendingSignee?.partyId === viewerPartyId) {
            acc.push({
              allowDiscard: true,
              cta: () => {
                if (redirectToSignDocuments) {
                  redirectToSignDocuments(
                    managedProductId,
                    {
                      entityId: bankAccountAssociationId,
                      entityType: AssociatedEntityType.BANK_ACCOUNT_ASSOCIATION,
                    },
                    true,
                  );
                } else {
                  console.error('Error: Redirect To Sign Docusign is missing');
                }
              },
              ctaText: content.cta.signDocuments,
              date: legalDocument.created,
              signingDocumentId: legalDocument.signingDocumentId,
            });
          } else {
            acc.push({
              allowDiscard: true,
              cta: () => resendDocusign(legalDocument.signingDocumentId),
              ctaText: content.cta.resendDocusign,
              date: legalDocument.created,
              signingDocumentId: legalDocument.signingDocumentId,
            });
          }
        }

        return acc;
      }, []);
    }

    /**
     * This empty return statement may become redudant once we do not allow other FA's to see all existing accounts,
     * for now it ensures if a different FA whose partyID does not match,
     * they will not see pending model change component if FA's approval is left.
     * Can't control Resend document that will show up irrespectively for now.
     */
    return [];
  }, [
    content.cta.resendDocusign,
    content.cta.signDocuments,
    legalDocuments,
    managedProductId,
    redirectToSignDocuments,
    resendDocusign,
    viewerPartyId,
  ]);

  const handleDiscardCTA = (signingDocumentId: string) => {
    setSelectedCardId(signingDocumentId);
    openModal();
  };

  const handleDiscard = async () => {
    if (selectedCardId) {
      await updateSigningDocument({
        variables: {
          signingDocumentId: selectedCardId,
          status: LegalDocumentStatus.DECLINED,
        },
      });
      setSelectedCardId(undefined);
    }
  };

  const handleOnClose = () => {
    setSelectedCardId(undefined);
    onClose();
  };

  return pendingCards.length ? (
    <>
      <Grid item xs={12}>
        {pendingCards.map((card, index) => {
          return (
            <Box key={card.signingDocumentId} pb={index === pendingCards.length - 1 ? 0 : 2}>
              <PendingChangeCard
                allowDiscard={card.allowDiscard}
                content={content}
                cta={card.cta}
                ctaText={card.ctaText}
                dataQa={dataQa}
                date={card.date}
                discardCTA={() => handleDiscardCTA(card.signingDocumentId)}
                refetchingAccounts={refetchingAccounts}
              />
            </Box>
          );
        })}
      </Grid>
      {showSnackBar && (
        <Snackbar
          feedbackMessage={content.resendEmailSentMessage}
          onClose={() => {
            setShowSnackBar(false);
          }}
        />
      )}
      <DiscardPendingChange
        content={content.discardPendingChange}
        contentOptions={contentOptions}
        onClose={handleOnClose}
        onDiscard={handleDiscard}
        onDiscardCallback={refetchItems}
        open={open}
      />
    </>
  ) : null;
};
