import {
  DeleteCommentV2,
  DeleteCommentV2Variables,
  SaveCommentV2,
  SaveCommentV2Variables,
} from './__generated__/mutation.v2';
import * as mutations from './mutation.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useSaveCommentV2 = (
  options?: MutationHookOptions<SaveCommentV2, SaveCommentV2Variables>,
): MutationTuple<SaveCommentV2, SaveCommentV2Variables> => {
  return useSymphonyMutation(mutations.SaveCommentV2, options);
};

export const useDeleteCommentV2 = (
  options?: MutationHookOptions<DeleteCommentV2, DeleteCommentV2Variables>,
): MutationTuple<DeleteCommentV2, DeleteCommentV2Variables> => {
  return useSymphonyMutation(mutations.DeleteCommentV2, options);
};
