import { BillingRate, BillingTier, BlendedBillingRate, BlendedRates, Discount, DiscountType, FeePeriod } from './types';

import { BillingRateTargetType, BillingRateType } from '~/__generated__/symphonyTypes.v2';
import { PricingModalFees } from '~/hooks/pricing/contentstack';
import { BillingRates } from '~/utils/pricing';

export const periodToNumber = (period: FeePeriod): number => {
  switch (period) {
    case FeePeriod.ANNUALLY:
      return 1;
    case FeePeriod.QUARTERLY:
      return 4;
    case FeePeriod.MONTHLY:
      return 12;
  }
};

export const periodToAbbreviation = (period: FeePeriod): string => {
  switch (period) {
    case FeePeriod.ANNUALLY:
      return 'yr';
    case FeePeriod.QUARTERLY:
      return 'qtr';
    case FeePeriod.MONTHLY:
      return 'mo';
  }
};

/**
 * Calculates a program fee as string from account value * fee / the period arg
 * Round up to the nearest penny: https://sigfig.atlassian.net/browse/ANR-8540
 */
export const getProgramFee = (accountValue: number, fee: number, feePeriod: FeePeriod): string => {
  return (Math.floor(((fee * accountValue) / periodToNumber(feePeriod)) * 100) / 100).toString();
};

/**
 * Function to calculate an aggregate fee given an account value and an array of tiered rates
 * If only one tier, will return single rate. otherwise will return a weighted rate calculated taking the weighting of each tier and multiplying it by the rate of the tier
 * @param accountValue
 * @param tiers an array of {rate: number; lowerBound?: number; upperBound?: number;}
 * @returns a single aggregate rate; Will return lowest tiered rate if account value is 0
 */
export const calculateAggregateFee = (accountValue: number, tiers: BillingTier[]): number => {
  if (tiers.length === 0) {
    return 0;
  }

  if (accountValue === 0 || tiers.length === 1) {
    return tiers[0].rate;
  }

  return (
    tiers.reduce((prev, curr) => {
      // Error handling in case of malformed data from symphony, realistically data should not go into this conditional
      if (curr.lowerBound === undefined) {
        if (!curr.upperBound) {
          return prev + curr.rate;
        }

        return prev;
      }

      if (accountValue > curr.lowerBound) {
        if (curr.upperBound && accountValue >= curr.upperBound) {
          return prev + (curr.upperBound - curr.lowerBound) * curr.rate;
        }

        return prev + (accountValue - curr.lowerBound) * curr.rate;
      }

      return prev + 0;
    }, 0) / accountValue
  );
};

/**
 * Helper function to construct args for storybooks using hook returns
 */
export const createBillingRate = ({
  rateTarget,
  label,
  rateType,
  tiers,
}: {
  label: string;
  rateTarget: BillingRateTargetType;
  rateType: BillingRateType;
  tiers: BillingTier[];
}): BillingRate => ({
  label,
  rateTarget,
  rateType,
  tiers,
  fee: (accountValue: number) => calculateAggregateFee(accountValue, tiers),
});

/**
 * Checks for a discount applied on the managedProduct. If either rates aren't present then no discount.
 * If more than 1 tier in default & discount, will be an even basis point discount applied across tiers.
 * If 1 tier in discount, maybe more than 1 in default, will be a percentage discount.
 * @param defaultRates - BillingRate[]
 * @param discountRates - BillingRate[]
 * @returns Discount | undefined
 */
export const calculateDiscount = (
  defaultRates?: BillingRate[],
  discountRates?: BillingRate[],
): Discount | undefined => {
  if (!defaultRates || !discountRates) {
    return undefined;
  }

  const discountAdvisorFeeRate = discountRates.find(rate => rate.rateTarget === BillingRateTargetType.ADVISOR);

  if (!discountAdvisorFeeRate?.tiers.length) {
    return undefined;
  }

  if (discountAdvisorFeeRate.tiers.length > 1) {
    return {
      discountType: DiscountType.BasisPoint,
      discountValue:
        (defaultRates.find(fee => fee.rateTarget === BillingRateTargetType.ADVISOR)?.fee(100000) ?? 0) -
        (discountRates.find(fee => fee.rateTarget === BillingRateTargetType.ADVISOR)?.fee(100000) ?? 0),
    };
  }

  return { discountType: DiscountType.Percentage, discountValue: discountAdvisorFeeRate.tiers[0].rate };
};

/**
 * To be consumed inside a reduce function
 * Callback to merge multiple provider fees (if present) into a single provider fee in data
 * @param prevRates BillingRate accumulator
 * @param currentRate BillingRate being operated on
 * @returns BillingRate[], with single Provider BillingRate
 */
export const combineProviderBillingRates = (prevRates: BillingRate[], currentRate: BillingRate): BillingRate[] => {
  const providerFeeIndex = prevRates.findIndex(
    rate => rate.rateTarget === BillingRateTargetType.PROVIDER && rate.rateType === currentRate.rateType,
  );

  if (currentRate.rateTarget !== BillingRateTargetType.PROVIDER || providerFeeIndex === -1) {
    prevRates.push(currentRate);
  } else {
    // Provider fee exists, combine provider fee tiers & update the fee calculation function
    const combinedTiers = Object.values(
      [...prevRates[providerFeeIndex].tiers, ...currentRate.tiers].reduce((tierAccumulator, currTier) => {
        const key = `${currTier.lowerBound}-${currTier.upperBound}`;
        if (key in tierAccumulator) {
          tierAccumulator[key].rate += currTier.rate;
        } else {
          tierAccumulator[key] = currTier;
        }

        return tierAccumulator;
      }, {} as { [uniqueBounds: string]: BillingTier }),
    );
    prevRates[providerFeeIndex].tiers = combinedTiers;
    prevRates[providerFeeIndex].fee = (accountValue: number) => calculateAggregateFee(accountValue, combinedTiers);
  }

  return prevRates;
};

/**
 * composeBillingRate, creates the billingRate item by going through tiers in BillingRates
 * @param {number} [args.accountValue] accountValue to use in fee calculations
 * @param {BillingRate} [args.billingRate] either the effective billing rate (BillingDataV2) or overidden or default Rate
 * @param {PricingModalFees} [args.content] helps to set the label of the billing rate
 * @returns {BillingRate}
 */
export const composeBillingRate = (
  billingRate: BillingRates | null,
  content?: (PricingModalFees | null)[] | null,
): BillingRate => {
  const tiers =
    billingRate?.tiers?.map(tier => ({
      rate: Number(tier.rate),
      lowerBound: tier.lowerBound ?? undefined,
      upperBound: tier.upperBound ?? undefined,
    })) ?? [];

  return {
    fee: (accountValue: number) => calculateAggregateFee(accountValue, tiers),
    label: content?.find(fee => fee?.rate_id === String(billingRate?.rateTarget))?.label ?? 'No label found',
    rateTarget: billingRate?.rateTarget ?? BillingRateTargetType.UNKNOWN,
    rateType: billingRate?.rateType ?? BillingRateType.ANNUALIZED_PERCENTAGE,
    tiers,
  };
};

// using a generic number to calculate and sort the fees in descending order
export const getBillingRates = (
  billingRates?: (BillingRates | null)[] | null,
  content?: (PricingModalFees | null)[] | null,
): BillingRate[] | undefined =>
  billingRates
    ?.map(rate => composeBillingRate(rate, content))
    ?.reduce(combineProviderBillingRates, [])
    .sort((a, b) => a.fee(10000) - b.fee(10000));

export const getBlendedRates = (
  blendedRates?: BlendedBillingRate[],
  content?: (PricingModalFees | null)[] | null,
): BlendedRates[] | undefined =>
  blendedRates
    ?.map(item => ({
      configName: item.configName,
      rate: item.rate,
      rateType: item.rateType,
      label: content?.find(fee => fee?.rate_id === String(item.configName))?.label ?? 'No label found',
    }))
    .sort((a, b) => (a.rate !== b.rate ? (a.rate < b.rate ? -1 : 1) : 0));

export const getClientFee = (blendedBillingRates?: BlendedBillingRate[] | null): number =>
  (blendedBillingRates?.map(item => parseFloat(item.rate)) ?? []).reduce((a, b) => {
    return a + b;
  }, 0);
