import {
  GetRestrictionsHistoryTabContent_all_restrictions_history,
  GetRestrictionsHistoryTabContentQuery,
  GetRestrictionsHistoryTabContentQueryVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type RestrictionsHistoryTabContent = GetRestrictionsHistoryTabContent_all_restrictions_history;

export const useGetRestrictionsHistoryTabContent = (
  options?: QueryHookOptions<GetRestrictionsHistoryTabContentQuery, GetRestrictionsHistoryTabContentQueryVariables>,
): QueryResult<GetRestrictionsHistoryTabContentQuery, GetRestrictionsHistoryTabContentQueryVariables> => {
  return useContentstackQuery(queries.GetRestrictionsHistoryTabContent, options);
};
