import React from 'react';

import { Chip } from '~/components/ui/Chip';
import { SxProps, Theme, useTheme } from '~/components/ui/mui';
import { SfChipLabelStyles } from '~/utils/theme/types';

type chipOptions = keyof SfChipLabelStyles;

type StatusChipProps = {
  label: string;
};

export const StatusChip: React.FC<StatusChipProps> = ({ label }) => {
  const {
    sfOpsDashboard: { styles: sfOpsDashboardStyles },
  } = useTheme();

  let labelStyles = {} as SxProps<Theme>;
  let styles = {} as SxProps<Theme>;

  if (sfOpsDashboardStyles.chip) {
    const lowercaseLabel = label.toLowerCase() as chipOptions;
    styles = { ...sfOpsDashboardStyles.chip.root };
    labelStyles = sfOpsDashboardStyles.chip.label[lowercaseLabel] || sfOpsDashboardStyles.chip.label.default;
  }

  return (
    <Chip label={label} size="medium" sx={Object.assign({}, styles, labelStyles) as SxProps<Theme>} variant="filled" />
  );
};
