import { HomeOfficeFilterOptions, HomeOfficeTableColumnConfig } from '../types';

export interface HomeOfficeView {
  columns: HomeOfficeTableColumnConfig[];
  filters: HomeOfficeFilterOptions[];
}

// The initiationDate is the first date of the selected period. It is used to determine the date range for the data displayed in the table.
// In case it is null, the date ranges are not restricted, and the data is displayed for the last 3 years.
export interface HomeOfficeDashboardConfig {
  dateView: HomeOfficeView;
  initiationDate: Date | null;
  periodView: HomeOfficeView;
  showFilters: boolean;
}

export const defaultHomeOfficeDashboardConfig: HomeOfficeDashboardConfig = {
  dateView: {
    columns: [
      {
        key: 'advisorName',
        wrappable: true,
        sortable: false,
      },
      {
        key: 'aumAsOfDate',
        wrappable: false,
        sortable: true,
      },
      {
        key: 'contactInformation',
        wrappable: true,
        sortable: false,
      },
      {
        key: 'accountsActiveAsOfDate',
        wrappable: true,
        sortable: false,
      },
      {
        key: 'accountsClosedAsOfDate',
        wrappable: true,
        sortable: false,
      },
      {
        key: 'accountsInProgressAsOfDate',
        wrappable: true,
        sortable: false,
      },
    ],
    filters: [
      HomeOfficeFilterOptions.AUM_AS_OF_DATE,
      HomeOfficeFilterOptions.ACCOUNTS_ACTIVE_AS_OF_DATE,
      HomeOfficeFilterOptions.ACCOUNTS_CLOSED_AS_OF_DATE,
      HomeOfficeFilterOptions.ACCOUNTS_IN_PROGRESS_AS_OF_DATE,
    ],
  },
  periodView: {
    columns: [
      {
        key: 'advisorName',
        wrappable: true,
        sortable: false,
      },
      {
        key: 'aumForPeriod',
        wrappable: false,
        sortable: true,
      },
      {
        key: 'contactInformation',
        wrappable: true,
        sortable: false,
      },
      {
        key: 'accountsOpenedInPeriod',
        wrappable: true,
        sortable: false,
      },
      {
        key: 'accountsClosedInPeriod',
        wrappable: true,
        sortable: false,
      },
    ],
    filters: [
      HomeOfficeFilterOptions.AUM_FOR_PERIOD,
      HomeOfficeFilterOptions.ACCOUNTS_OPENED_IN_PERIOD,
      HomeOfficeFilterOptions.ACCOUNTS_CLOSED_IN_PERIOD,
    ],
  },
  showFilters: true,
  initiationDate: new Date('2022-06-01'),
};
