import React, { ComponentProps, useCallback } from 'react';

import { FinancialAccountAssociationVerificationStatus } from '~/__generated__';
import { useBankVerificationModal } from '~/components/modals/BankVerification/hooks';
import { Alert } from '~/components/ui/Alert';
import { Chip } from '~/components/ui/Chip';
import { Divider } from '~/components/ui/Divider';
import { Modal } from '~/components/ui/Modal';
import { Button, Grid, LoadingButton } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { maskAccountNumber } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  onClose?: (feedbackMessage?: string) => void;
  requestId: string;
};

export const BankVerificationModal: React.FC<Props> = ({
  contentOptions,
  dataQa = 'bank-verification-modal',
  onClose,
  open,
  requestId,
  ...modalProps
}) => {
  const { showMoreInfoOnBankVerificationModal } = useCoreConfig().components.sfOpsDashboard;
  const { data, loading, error } = useBankVerificationModal({ contentOptions, onClose, requestId });

  const onCancel = useCallback(() => {
    onClose?.('');
    data?.handleCancel();
  }, [data, onClose]);

  const getModalButtons = useCallback(() => {
    if (!data) {
      return null;
    }
    switch (data.confirmationState) {
      case FinancialAccountAssociationVerificationStatus.ACCEPTED:
        return (
          <>
            <LoadingButton loading={data.updateLoading} onClick={onCancel} variant="text">
              {data.content.confirmApprove.ctas.secondary}
            </LoadingButton>
            <LoadingButton loading={data.updateLoading} onClick={data.handleConfirm} variant="contained">
              {data.content.confirmApprove.ctas.primary}
            </LoadingButton>
          </>
        );
      case FinancialAccountAssociationVerificationStatus.REJECTED:
        return (
          <>
            <LoadingButton loading={data.updateLoading} onClick={onCancel} variant="text">
              {data.content.confirmReject.ctas.secondary}
            </LoadingButton>
            <LoadingButton loading={data.updateLoading} onClick={data.handleConfirm} variant="contained">
              {data.content.confirmReject.ctas.primary}
            </LoadingButton>
          </>
        );
      default:
        return (
          <>
            <Button onClick={data.handleReject} variant="text">
              {data.content.bankVerification.ctas.secondary}
            </Button>
            <Button onClick={data.handleAccept} variant="contained">
              {data.content.bankVerification.ctas.primary}
            </Button>
          </>
        );
    }
  }, [data, onCancel]);

  const getModalBody = useCallback(() => {
    if (data) {
      switch (data.confirmationState) {
        case FinancialAccountAssociationVerificationStatus.ACCEPTED:
          return (
            <Grid container>
              {data.updateError && (
                <Grid item xs={12}>
                  <Alert contentOptions={contentOptions} error={data.updateError} severity="error" sx={{ mb: 1 }} />
                </Grid>
              )}
              <Grid item mt={data.updateError ? 0 : 5} xs={12}>
                <img alt="" src={data.content.confirmApprove.iconUrl} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{data.content.confirmApprove.header}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{data.content.confirmApprove.subtitle}</Typography>
              </Grid>
            </Grid>
          );
        case FinancialAccountAssociationVerificationStatus.REJECTED:
          return (
            <Grid container>
              {data.updateError && (
                <Grid item xs={12}>
                  <Alert contentOptions={contentOptions} error={data.updateError} severity="error" sx={{ mb: 1 }} />
                </Grid>
              )}
              <Grid item mt={data.updateError ? 0 : 5} xs={12}>
                <img alt="" src={data.content.confirmReject.iconUrl} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{data.content.confirmReject.header}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{data.content.confirmReject.subtitle}</Typography>
              </Grid>
            </Grid>
          );
        default:
          return (
            <Grid columnSpacing={2} container>
              <Grid container item xs={12}>
                <Grid alignItems="center" container item justifyContent="flex-start">
                  <Typography variant="h6">{data.data.sourceData.financialInstitution}</Typography>
                  <Chip
                    label={data.data.sourceData.bankAccountType}
                    sx={{ color: 'text.primary', height: '20px', ml: 2 }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {`${data.content.bankVerification.accountDetails.accountLabel}: ${data.data.sourceData.accountNumber}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {`${data.content.bankVerification.accountDetails.maskedAccountNumberLabel}: ${maskAccountNumber(
                      data.data.sourceData.plaidMaskedAccountNumber,
                    )}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {`${data.content.bankVerification.accountDetails.routingLabel}: ${data.data.sourceData.routingNumber}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {`${data.content.bankVerification.accountDetails.accountEntryMethod}: ${
                      data.data.sourceData.source ?? data.content.bankVerification.accountDetails.manual
                    }`}
                  </Typography>
                </Grid>
                <Grid alignItems="center" container item justifyContent="flex-start" xs={12}>
                  <Typography variant="body2">{data.content.bankVerification.accountDetails.statusLabel}:</Typography>
                  <Chip
                    label="Pending"
                    sx={{ color: 'text.primary', fontWeight: 700, height: '20px', ml: 1 }}
                    variant="filled"
                  />
                </Grid>
              </Grid>
              {showMoreInfoOnBankVerificationModal && (
                <>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">{data.content.bankVerification.providedByPlaid}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">{data.content.bankVerification.providedByClient}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{data.content.bankVerification.rows.nameLabel}</Typography>
                    </Grid>
                    <Grid item mb={1} xs={12}>
                      {data.data.sourceData.names.map((name, index) => (
                        <Typography key={`source-name-${index}`} variant="body2">
                          {name}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{data.content.bankVerification.rows.nameLabel}</Typography>
                    </Grid>
                    <Grid item mb={1} xs={12}>
                      {data.data.managedData.names.map((name, index) => (
                        <Typography key={`managed-name-${index}`} variant="body2">
                          {name}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        {data.content.bankVerification.rows.mailingAddressLabel}
                      </Typography>
                    </Grid>
                    <Grid item mb={1} xs={12}>
                      {data.data.sourceData.addresses.map((address, index) => (
                        <div key={`source-address-${index}`}>
                          <Typography variant="body2">{address.addressLine1}</Typography>
                          <Typography variant="body2">{address.addressLine2}</Typography>
                          <Typography variant="body2">{`${address.city}, ${address.state} ${address.postalCode}`}</Typography>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        {data.content.bankVerification.rows.mailingAddressLabel}
                      </Typography>
                    </Grid>
                    <Grid item mb={1} xs={12}>
                      {data.data.managedData.addresses.map((address, index) => (
                        <div key={`managed-address-${index}`}>
                          <Typography variant="body2">{address.addressLine1}</Typography>
                          <Typography variant="body2">{address.addressLine2}</Typography>
                          <Typography variant="body2">{`${address.city}, ${address.state} ${address.postalCode}`}</Typography>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        {data.content.bankVerification.rows.emailAddressLabel}
                      </Typography>
                    </Grid>
                    <Grid item mb={1} xs={12}>
                      {data.data.sourceData.emails.map((email, index) => (
                        <Typography key={`source-email-${index}`} sx={{ overflowWrap: 'anywhere' }} variant="body2">
                          {email}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        {data.content.bankVerification.rows.emailAddressLabel}
                      </Typography>
                    </Grid>
                    <Grid item mb={1} xs={12}>
                      {data.data.managedData.emails.map((email, index) => (
                        <Typography key={`managed-email-${index}`} sx={{ overflowWrap: 'anywhere' }} variant="body2">
                          {email}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{data.content.bankVerification.rows.phoneNumberLabel}</Typography>
                    </Grid>
                    <Grid item mb={1} xs={12}>
                      {data.data.sourceData.phoneNumbers.map((phoneNumber, index) => (
                        <Typography key={`source-phone-${index}`} variant="body2">
                          {phoneNumber}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{data.content.bankVerification.rows.phoneNumberLabel}</Typography>
                    </Grid>
                    <Grid item mb={1} xs={12}>
                      {data.data.managedData.phoneNumbers.map((phoneNumber, index) => (
                        <Typography key={`managed-phone-${index}`} variant="body2">
                          {phoneNumber}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          );
      }
    } else {
      return null;
    }
  }, [contentOptions, data, showMoreInfoOnBankVerificationModal]);

  return (
    <Modal
      {...modalProps}
      actions={getModalButtons()}
      content={getModalBody()}
      contentOptions={contentOptions}
      dataQa={dataQa}
      error={error}
      hideHeader={!!data?.confirmationState}
      loading={loading}
      onClose={onClose}
      open={open}
      title={!data?.confirmationState ? data?.content.bankVerification.header : undefined}
    />
  );
};
