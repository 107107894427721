import { useMemo } from 'react';

import { useGetClientDocumentsModalContent } from '../contentstack';

import { getClientDocumentsModalContent } from './utils';

import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

export interface ClientDocumentsModalContent {
  primaryAction: string;
  title: string;
}

export const useClientDocumentsModalContent = ({
  contentOptions,
}: Variables): AsyncResult<ClientDocumentsModalContent> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetClientDocumentsModalContent({
    variables: contentOptions,
  });

  return useMemo(
    (): AsyncResult<ClientDocumentsModalContent> => ({
      data: getClientDocumentsModalContent(contentData),
      error: contentError,
      loading: contentLoading,
    }),
    [contentData, contentError, contentLoading],
  );
};
