import { ComponentProps } from 'react';

import { isPerformanceChartSection } from '../contentstack';
import {
  AccountDetailsSectionsAccountActivityData_account_activity_account_activityConnection_edges_node,
  AccountDetailsSectionsAddFundsData_add_funds as AddFundsContent,
  AccountDetailsSectionsPerformanceChartData_performance_chart_returns_labels as GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsPerformanceChart_performance_chart_returns_labels,
  AccountDetailsSectionsQuestionsData_questions_support_contact_informationConnection_edges_node_SupportContactInformation_content as SupportContactContent,
  GetAccountDetailsOverviewContent,
  GetAccountDetailsOverviewContent_all_account_details_items,
  GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsPerformanceChart as SectionContent,
  GetAccountDetailsOverviewContent_all_account_details_v2_items,
  GetAccountDetailsOverviewContent_all_asset_allocation_section_items as AssetAllocationContent,
} from '../contentstack/__generated__/query.v2';

import { AccountInfo } from '~/components/AccountInfo';
import { PerformanceTimePeriodSelect } from '~/components/PerformanceTimePeriodSelect';
import { getStockBondRatio } from '~/containers/Plan/utils';
import { Assets } from '~/hooks/account-details/utils';
import { MeasurementName } from '~/hooks/performance/types';
import { AssetAllocationTableColumns } from '~/utils/config';
import { getLabel } from '~/utils/content';

export type AccountActivityContent = AccountDetailsSectionsAccountActivityData_account_activity_account_activityConnection_edges_node;

export interface Content {
  /**
   * @deprecated accountDetailsContent referencing contentstack response should be removed
   * after AccountDetails is refactored to be decoupled from it.
   *
   * To prevent the scope of this custom hook becoming too big, the GetAccountDetailsOverviewContent query should be
   * broken up into smaller queries for narrower scope with respective custom hooks added.
   */
  accountDetailsContent?: GetAccountDetailsOverviewContent;
  accountInfoContent: ComponentProps<typeof AccountInfo>['content'];
  accountNumberFormat: string;
  addAnotherAccountViaPlaidCta?: string;
  performanceTimePeriodSelectContent: ComponentProps<typeof PerformanceTimePeriodSelect>['content'];
  quarterlyPerformance: string;
  timePeriodLabels: Partial<{ [key in MeasurementName]: string }>;
}

const getTimePeriodLabels = (
  returnsLabels?: GetAccountDetailsOverviewContent_all_account_details_items_sections_AccountDetailsSectionsPerformanceChart_performance_chart_returns_labels,
): Content['timePeriodLabels'] => ({
  [MeasurementName.FiveYears]: returnsLabels?.five_year ?? '',
  [MeasurementName.OneYear]: returnsLabels?.one_year ?? '',
  [MeasurementName.QTD]: returnsLabels?.qtd ?? '',
  [MeasurementName.SinceInception]: returnsLabels?.since_inception ?? '',
  [MeasurementName.TenYears]: returnsLabels?.ten_year ?? '',
  [MeasurementName.ThreeYears]: returnsLabels?.three_year ?? '',
  [MeasurementName.YTD]: returnsLabels?.ytd ?? '',
});

const getAccountInfoContent = (
  content?: GetAccountDetailsOverviewContent_all_account_details_items | null,
  contentV2?: GetAccountDetailsOverviewContent_all_account_details_v2_items | null,
): ComponentProps<typeof AccountInfo>['content'] => ({
  header: {
    accountNumber: {
      accountNumberFormat: contentV2?.account_number_formatConnection?.edges?.[0]?.node?.pattern ?? '${accountNumber}',
      accountNumberPrefix: content?.header?.account_number?.account_number_prefix ?? '',
    },
    labels: {
      currentBalance: content?.header?.labels?.current_balance ?? '',
      dataAsOf: content?.header?.labels?.data_as_of ?? '',
      dateTimeFormat: content?.header?.labels?.date_time_format ?? '',
      for: content?.header?.labels?.for ?? '',
      qtd: content?.header?.labels?.qtd ?? '',
      viewing: content?.header?.labels?.viewing ?? '',
      ytd: content?.header?.labels?.ytd ?? '',
    },
    viewingDropdownOptions: {
      quarterlyPerformance: content?.header?.viewing_dropdown_options?.quarterly_performance ?? '',
      sinceInception: content?.header?.viewing_dropdown_options?.since_inception ?? '',
    },
  },
});

export const getContent = (contentData?: GetAccountDetailsOverviewContent): Content => {
  const content = contentData?.all_account_details?.items?.[0] ?? undefined;
  const contentV2 = contentData?.all_account_details_v2?.items?.[0] ?? undefined;
  const returnsLabels =
    content?.sections?.find(isPerformanceChartSection)?.performance_chart?.returns_labels ?? undefined;

  return {
    accountDetailsContent: contentData,
    accountInfoContent: getAccountInfoContent(content, contentV2),
    accountNumberFormat: contentV2?.account_number_formatConnection?.edges?.[0]?.node?.pattern || '${accountNumber}',
    addAnotherAccountViaPlaidCta: getLabel(contentV2, 'add_another_account_via_plaid'),
    performanceTimePeriodSelectContent: {
      accessibility: {
        performanceTimePeriodAriaLabel: content?.accessibility?.performance_time_period_select_label ?? '',
      },
      mobile: {
        periodLabel: returnsLabels?.dropdown_item ?? '',
        title: returnsLabels?.dropdown_label ?? '',
      },
      yourPortfolioLabel: returnsLabels?.your_portfolio ?? '',
    },
    quarterlyPerformance: content?.header?.viewing_dropdown_options?.quarterly_performance ?? '',
    timePeriodLabels: getTimePeriodLabels(returnsLabels),
  };
};

export const getPerformanceChartContent = (
  section: SectionContent,
  content: GetAccountDetailsOverviewContent_all_account_details_items,
) => ({
  accessibility: { infoIcon: content.accessibility?.performance_chart_label ?? 'info icon' },
  detailedTableCta: section.performance_chart?.details_cta || 'MISSING DETAILED TABLE CTA',
  dialog: section.performance_chart?.information_dialog || undefined,
  disclosure: section.performance_chart?.disclosure || undefined,
  heading: section.performance_chart?.title || undefined,
});

export const getAccountActivityContent = (accountActivityContent: AccountActivityContent | null | undefined) => ({
  header: {
    subText: accountActivityContent?.header?.sub_text ?? '',
    title: accountActivityContent?.header?.title ?? '',
    balanceIncreasedBy: accountActivityContent?.header?.balance_increased_by ?? '',
    balanceDecreasedBy: accountActivityContent?.header?.balance_decreased_by ?? '',
    thisQuarter: accountActivityContent?.header?.this_quarter ?? '',
  },
  tableHeading: {
    activityColumnLabel: accountActivityContent?.table_heading?.activity_column_label ?? '',
    valueColumnLabel: accountActivityContent?.table_heading?.value_column_label ?? '',
  },
  tableBody: {
    tableRow:
      accountActivityContent?.table_body?.table_row?.map(row => ({
        key: row?.key ?? '',
        label: row?.label ?? '',
        isBold: row?.is_bold ?? false,
        showTotal: row?.show_total ?? false,
      })) ?? [],
  },
});

export const getAssetAllocationContent = (
  content: Partial<GetAccountDetailsOverviewContent_all_account_details_items>,
  assetAllocationContent: AssetAllocationContent | null,
  assets?: Assets,
) => {
  const sectionContent = assetAllocationContent?.content ?? [];
  const stockBondSplit = getStockBondRatio({
    bondPercentage: assets?.stockBondDiversification?.bondPercentage ?? 'bondPercentage',
    stockPercentage: assets?.stockBondDiversification?.stockPercentage ?? 'stockPercentage',
  });
  const cashSleeveContent = assetAllocationContent?.cash_sleeveConnection?.edges?.[0]?.node;
  return {
    title: sectionContent.find(item => item?.key === 'section_title')?.value || 'section_title',
    assetBreakdownTitle:
      sectionContent.find(item => item?.key === 'section_asset_breakdown_title')?.value ||
      'section_asset_breakdown_title',
    emptyState: sectionContent.find(item => item?.key === 'section_empty_state')?.value ?? 'section_empty_state',
    factSheetLinkText: sectionContent.find(item => item?.key === 'view_fact_sheet')?.value ?? 'view_fact_sheet',
    tableColumns: (content.asset_allocation_column?.values as AssetAllocationTableColumns[]) ?? undefined,
    donut: {
      actualLabel: sectionContent.find(item => item?.key === 'donut_actual_label')?.value || 'donut_actual_label',
      targetLabel: sectionContent.find(item => item?.key === 'donut_target_label')?.value || 'donut_target_label',
      stockBondSplit: {
        label:
          sectionContent.find(item => item?.key === 'donut_stock_bond_split_label')?.value ||
          'MISSING STOCK BOND SPLIT',
        value: stockBondSplit,
      },
      actualAllocationLabel:
        sectionContent.find(item => item?.key === 'donut_header_actual_allocation')?.value ??
        'donut_header_actual_allocation',
      targetAllocationLabel:
        sectionContent.find(item => item?.key === 'donut_header_target_allocation')?.value ??
        'donut_header_target_allocation',
    },
    cashSleeveContent: {
      cashLabel: cashSleeveContent?.cash_label ?? 'cash_label',
      investmentsLabel: cashSleeveContent?.investments_label ?? 'investments_label',
      caption: cashSleeveContent?.caption ?? 'caption',
      description: cashSleeveContent?.description ?? 'description',
    },
    otherAssetClass: sectionContent.find(item => item?.key === 'other_asset_class')?.value ?? 'other_asset_class',
  };
};

export const getSupportContactContent = (supportContactContent?: SupportContactContent | null) => ({
  title: supportContactContent?.title || 'MISSING TITLE',
  body: supportContactContent?.body || 'MISSING BODY',
  contactAdvisorCta: supportContactContent?.contact_advisor_button_label || 'contact_advisor_button_label',
  helpQuestions: supportContactContent?.help_questions?.map(question => question || 'MISSING QUESTION') ?? [],
  extra: supportContactContent?.extra || undefined,
  launchSchedulingToolCta: supportContactContent?.talk_to_fa_cta?.has_scheduling_tool
    ? supportContactContent.talk_to_fa_cta.launch_scheduling_tool || 'launch_scheduling_tool'
    : undefined,
  callPhoneNumberCta: !supportContactContent?.talk_to_fa_cta?.has_scheduling_tool
    ? supportContactContent?.talk_to_fa_cta?.call_phone_number ?? undefined
    : undefined,
  footer: supportContactContent?.support_footer || 'support_footer',
});

export const getAddFundsContent = (addFundsContent: AddFundsContent | null, addAnotherAccountViaPlaid?: string) => ({
  title: addFundsContent?.title || 'title',
  columns: {
    account: addFundsContent?.column_headers?.account || 'account',
    contributionAmount: addFundsContent?.column_headers?.contribution_amount || 'contribution_amount',
  },
  ctas: {
    addAnotherAccountViaPlaid: addAnotherAccountViaPlaid ?? '',
    addFunds: addFundsContent?.ctas?.add_funds || 'add_funds',
    addAnotherAccount: addFundsContent?.ctas?.add_another_account || 'add_another_account',
    removeAccount: addFundsContent?.ctas?.remove_account || 'remove_account',
  },
});
