import {
  UpdateFinancialAccountAssociationVerificationRequest,
  UpdateFinancialAccountAssociationVerificationRequestVariables,
} from './__generated__/mutation.v2';
import {
  GetFinancialAccountAssociationVerificationRequest,
  GetFinancialAccountAssociationVerificationRequestVariables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, QueryHookOptions } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export const useUpdateFinancialAccountAssociationVerificationRequest = (
  options?: MutationHookOptions<
    UpdateFinancialAccountAssociationVerificationRequest,
    UpdateFinancialAccountAssociationVerificationRequestVariables
  >,
) => {
  return useSymphonyMutation(mutations.UpdateFinancialAccountAssociationVerificationRequest, options);
};

export const useGetFinancialAccountAssociationVerificationRequest = (
  options?: QueryHookOptions<
    GetFinancialAccountAssociationVerificationRequest,
    GetFinancialAccountAssociationVerificationRequestVariables
  >,
) => {
  return useSymphonyQuery(queries.GetFinancialAccountAssociationVerificationRequest, options);
};
