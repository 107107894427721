import React, { ComponentProps, useEffect, useState } from 'react';

import { useGetApproveModelChangeContent } from './contentstack';
import { useApproveModelChange } from './hooks';

import { Modal } from '~/components/ui/Modal';
import { Button, LoadingButton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  onApproveCallback: () => void;
  onClose: () => void;
  open: boolean;
  planId: string;
  planUpdateWorkflowId: string;
};

export const ApproveModelChangeModal: React.FC<Props> = ({
  contentOptions,
  dataQa,
  onClose,
  open,
  planId,
  planUpdateWorkflowId,
  onApproveCallback,
}) => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const {
    sfApproveModelChangeModal: { styles: sfApproveModelChangeModalStyles },
  } = useTheme<SfTheme>();

  const {
    data: modalContentData,
    loading: modalContentLoading,
    error: loadContentError,
  } = useGetApproveModelChangeContent({ variables: contentOptions, skip: !open });

  useEffect(() => {
    if (open) {
      setError(undefined);
      setIsBusy(false);
    }
  }, [open]);

  const { approveModelChange, data: approveData, error: approveError } = useApproveModelChange(
    planId,
    planUpdateWorkflowId,
  );

  const handleApproval = async () => {
    setIsBusy(true);
    await approveModelChange();
  };

  useEffect(() => {
    if (approveData && !approveError) {
      onApproveCallback();
    }
  }, [approveData, approveError]);

  useEffect(() => {
    setError(loadContentError || approveError);
    setIsBusy(false);
  }, [loadContentError, approveError]);

  const modalContent = modalContentData?.all_approve_model_change?.items?.[0]?.approve_model_change;
  const alertIcon = modalContent?.imageConnection?.edges?.[0]?.node?.url ?? '';
  const modalTitle = modalContent?.title ?? '';
  const modalPrimaryButtonContent = modalContent?.primary_cta ?? '';
  const modalSecondaryButtonContent = modalContent?.secondary_cta ?? '';
  const modalDescription = modalContent?.description ?? '';

  return (
    <Modal
      actions={
        <>
          <Button disabled={isBusy} onClick={onClose} variant="outlined">
            {modalSecondaryButtonContent}
          </Button>
          <LoadingButton loading={isBusy} onClick={handleApproval} variant="contained">
            {modalPrimaryButtonContent}
          </LoadingButton>
        </>
      }
      content={
        <>
          <img alt="" src={alertIcon} style={sfApproveModelChangeModalStyles.icon} />
          <RteContent data={modalDescription} />
        </>
      }
      contentOptions={contentOptions}
      dataQa={dataQa}
      error={error}
      loading={modalContentLoading}
      onClose={onClose}
      open={open}
      title={modalTitle}
    />
  );
};
