import {
  GetACATRequestTableContentV2,
  GetACATRequestTableContentV2_all_acatrequests_table_items_flag_referenceConnection_edges_node,
  GetACATRequestTableContentV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ACATRequestsFlagReferenceContent = GetACATRequestTableContentV2_all_acatrequests_table_items_flag_referenceConnection_edges_node;

export const useGetACATRequestTableContentV2 = (
  options?: QueryHookOptions<GetACATRequestTableContentV2, GetACATRequestTableContentV2Variables>,
): QueryResult<GetACATRequestTableContentV2, GetACATRequestTableContentV2Variables> => {
  return useContentstackQuery(queries.GetACATRequestTableContentV2, options);
};
