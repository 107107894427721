import React from 'react';

import { useGetOpsDashboardHeaderContentV2 } from './contentstack';

import { Box, Button, ChevronLeftIcon, Grid, HelpIcon, SupervisedUserCircleIcon, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  onBackToAccountSummaryClick?: () => void;
  onViewClientListClick?: () => void;
  showBackToAccountSummaryInSubNav?: boolean;
  showHelpButton?: boolean;
  showListViewInSubNav?: boolean;
  showOpsUserIcon?: boolean;
  userType?: OpsUserType;
}

export enum OpsUserType {
  OPERATIONS = 'operations',
  PRINCIPAL = 'principal',
}

interface HelpButtonProps {
  href: string;
  target: string;
}

export const OpsDashboardHeader: React.FC<Props> = ({
  dataQa = 'ops-dashboard-header',
  contentOptions,
  showBackToAccountSummaryInSubNav = false,
  showListViewInSubNav = false,
  onBackToAccountSummaryClick,
  onViewClientListClick,
  showHelpButton,
  showOpsUserIcon,
  userType = OpsUserType.OPERATIONS,
}): any => {
  const {
    sfOpsDashboard: { styles: sfOpsDashboardStyles },
  } = useTheme<SfTheme>();

  const { data: contentData } = useGetOpsDashboardHeaderContentV2({
    variables: contentOptions,
  });

  const getUserLabel = (): string => {
    if (userType === OpsUserType.OPERATIONS) {
      return opsDashboardHeaderContent?.operations || '';
    } else if (userType === OpsUserType.PRINCIPAL) {
      return opsDashboardHeaderContent?.principal || '';
    }
    return '';
  };

  const getHelpButton = () => {
    const getHelpButtonBase = (buttonProps?: HelpButtonProps) => (
      <Button {...buttonProps} startIcon={<HelpIcon />} sx={sfOpsDashboardStyles.opsHeader}>
        <Typography variant="body2">{opsDashboardHeaderContent?.help}</Typography>
      </Button>
    );
    if (opsDashboardHeaderContent?.help_url) {
      return getHelpButtonBase({ href: opsDashboardHeaderContent.help_url, target: '_blank' });
    } else if (opsDashboardHeaderContent?.help_tooltip) {
      return (
        <Tooltip tooltipContent={<RteContent data={opsDashboardHeaderContent.help_tooltip} />}>
          {getHelpButtonBase()}
        </Tooltip>
      );
    }

    return getHelpButtonBase();
  };

  const opsDashboardHeaderContent = contentData?.all_ops_dashboard_header?.items?.[0];
  return (
    <>
      <Grid
        alignItems="center"
        container
        data-qa={dataQa}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'other.divider',
        }}
      >
        <Grid item sx={{ pl: 3 }} xs>
          {opsDashboardHeaderContent?.header_logoConnection?.edges?.[0]?.node?.url && (
            <Button
              onClick={onViewClientListClick}
              sx={{ ...sfOpsDashboardStyles.opsHeader, justifyContent: 'flex-start' }}
            >
              <Box
                component="img"
                src={opsDashboardHeaderContent.header_logoConnection.edges[0]?.node?.url}
                sx={sfOpsDashboardStyles.opsHeaderLogo}
              />
            </Button>
          )}
        </Grid>
        {showHelpButton && <Grid item>{getHelpButton()}</Grid>}
        {showOpsUserIcon && (
          <Grid
            item
            justifyContent="center"
            sx={{
              ...sfOpsDashboardStyles.opsHeader,
              backgroundColor: 'primary.background',
              color: 'primary.main',
              display: 'flex',
            }}
          >
            <SupervisedUserCircleIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body2">{getUserLabel()}</Typography>
          </Grid>
        )}
      </Grid>

      {(showBackToAccountSummaryInSubNav || showListViewInSubNav) && (
        <Grid item sx={sfOpsDashboardStyles.opsHeaderSubBorder}>
          {showBackToAccountSummaryInSubNav && (
            <Button
              onClick={onBackToAccountSummaryClick}
              startIcon={<ChevronLeftIcon fontSize="small" sx={{ color: 'primary.main' }} />}
              sx={{ m: 1 }}
            >
              <Typography sx={{ color: 'text.primary' }} variant="body2">
                {opsDashboardHeaderContent?.back_to_account_summary}
              </Typography>
            </Button>
          )}
          {showListViewInSubNav && (
            <Button
              onClick={onViewClientListClick}
              startIcon={<ChevronLeftIcon fontSize="small" sx={{ color: 'primary.main' }} />}
              sx={{ m: 1 }}
            >
              <Typography sx={{ color: 'text.primary' }} variant="body2">
                {opsDashboardHeaderContent?.list_view}
              </Typography>
            </Button>
          )}
        </Grid>
      )}
    </>
  );
};
