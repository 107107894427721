import * as queries from './query.gql';

import {
  GetSuspendTradingModalContent,
  GetSuspendTradingModalContentVariables,
} from '~/components/modals/SuspendTrading/contentstack/__generated__/query.v2';
import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetSuspendTradingModalContent = (
  options?: QueryHookOptions<GetSuspendTradingModalContent, GetSuspendTradingModalContentVariables>,
) => {
  return useContentstackQuery(queries.GetSuspendTradingModalContent, options);
};

export type SuspendTradingModalContent = GetSuspendTradingModalContent;
