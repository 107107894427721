import { getColumnKeyValuePair } from '../../utils';
import { ACATRequestsFlagReferenceContent } from '../contentstack';
import { GetACATRequestTableContentV2 } from '../contentstack/__generated__/query.v2';
import { ACATRequestStatus, ACATRequestStatusFilter } from '../hooks';

import { TableColumn } from '~/components/ui/BasicTable';
import { ContentstackTableColumn } from '~/utils/table';

export type ACATRequestDisplayStrings = {
  flags: ACATRequestsFlagReferenceContent;
  status: Record<ACATRequestStatus, string>;
  statusFilter: Record<ACATRequestStatusFilter, string>;
  tableHeaders: TableColumn[];
  transferToAccountNote: string;
};

export const generateACATRequestStaticDisplayStrings = (
  content: GetACATRequestTableContentV2,
): ACATRequestDisplayStrings => {
  const items = content.all_acatrequests_table?.items?.[0];
  const flagsContent = items?.flag_referenceConnection?.edges?.[0]?.node ?? ({} as ACATRequestsFlagReferenceContent);
  return {
    flags: flagsContent,
    status: {
      [ACATRequestStatus.COMPLETED]: items?.statuses?.completed ?? '',
      [ACATRequestStatus.CANCELLED]: items?.statuses?.cancelled ?? '',
      [ACATRequestStatus.PENDING]: items?.statuses?.pending ?? '',
      [ACATRequestStatus.IN_PROGRESS]: items?.statuses?.in_progress ?? '',
      [ACATRequestStatus.UNRECOGNIZED]: items?.statuses?.unrecognized_status ?? '',
    },
    statusFilter: {
      [ACATRequestStatusFilter.ALL]: items?.status_filters?.all ?? '',
      [ACATRequestStatusFilter.PENDING]: items?.status_filters?.pending ?? '',
      [ACATRequestStatusFilter.IN_PROGRESS]: items?.status_filters?.in_progress ?? '',
      [ACATRequestStatusFilter.COMPLETED]: items?.status_filters?.completed ?? '',
      [ACATRequestStatusFilter.CANCELLED]: items?.statuses?.cancelled ?? '',
    },
    tableHeaders: getColumnKeyValuePair(items?.columns?.column as ContentstackTableColumn[], []),
    transferToAccountNote: items?.transfer_to_account_note ?? '',
  };
};
