import React, { FC } from 'react';

import { useBillingRates } from './hooks';

import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { Divider } from '~/components/ui/Divider';
import { Grid, SxProps, Theme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
}

interface CellProps {
  data?: string;
  hideDivider?: boolean;
  label?: string;
  sx?: SxProps<Theme>;
}

const Cell: FC<CellProps> = ({ label, data, hideDivider, sx }) => {
  const isMediumScreenOrBelow = useIsMediumScreen();
  return (
    <>
      <Grid item md={2} xs={5}>
        <Typography component="dt" sx={{ mb: 1, ...sx }} variant="body2">
          {label}
        </Typography>
        <Typography component="dd" sx={{ ...sx }} variant="h5">
          {data}
        </Typography>
      </Grid>
      {!hideDivider && <Divider flexItem orientation="vertical" sx={{ mx: isMediumScreenOrBelow ? 3 : 6 }} />}
    </>
  );
};

export const BillingRates: FC<Props> = ({ contentOptions, dataQa = 'billing-rates', managedProductId }) => {
  const { data, loading, error } = useBillingRates({
    contentOptions,
    managedProductId,
  });
  const billingRateContent = data?.billingRateContent;
  const billingRateData = data?.billingRateData;

  return (
    <Grid container data-qa={dataQa} mb={3} spacing={3}>
      {loading && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading Billing Rates"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      )}
      {data && (
        <Grid item xs={12}>
          <Typography component="div" sx={{ mb: 5, fontWeight: 700 }} variant="h3">
            {billingRateContent?.title}
          </Typography>
          <Grid container>
            <Cell data={billingRateData?.managementFeeData} label={billingRateContent?.managementFeeLabel} />
            <Cell data={billingRateData?.advisorFeeData} label={billingRateContent?.advisorFeeLabel} />
            <Cell data={billingRateData?.sponsorFeeData} label={billingRateContent?.sponsorFeeLabel} />
            <Cell
              data={billingRateData?.estimatedAnnualRateData}
              hideDivider
              label={billingRateContent?.estimatedAnnualRateLabel}
              sx={{ fontWeight: 700 }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
