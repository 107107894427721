import React, { ComponentProps, useEffect, useMemo, useState } from 'react';

import { CommentComponentColumn } from '../common/Comment';
import { NonRecurringCashTransferTable } from '../common/NonRecurringCashTransferTable';
import { RecurringCashTransferTable } from '../common/RecurringCashTransferTable';
import { cashTransferStatusToScheduledTransferStatusForUpdate } from '../mappers';
import { RequestTabs, TransferItem, TransferStatus, TransferStatusFilter, TransferStatusUpdateItem } from '../types';
import { OpsDashboardUser } from '../utils';

import { DepositDisplayStrings, generateDepositStaticDisplayStrings } from './content';
import { DepositsStatusFilter } from './DepositsStatusFilter';
import { RecurrenceOptions, useDepositsTabData } from './hooks';

import { Field, FlagEntity, OrderType, TransferType, UserNoteEntityType } from '~/__generated__';
import { StatusChangeModal } from '~/components/modals/StatusChange';
import { NullStateProps } from '~/components/NullState';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable, SortConfig } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Grid, Tab, TabContext, TabPanel, ToggleButton, ToggleButtonGroup, useTheme } from '~/components/ui/mui';
import { Tabs } from '~/components/ui/Tabs';
import { Typography } from '~/components/ui/Typography';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { useUpdateCashTransfer } from '~/containers/OpsDashboard/common/CashTransfer/symphony';
import { ContentOptions } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export interface DepositsTabProps {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onUpdate: (item: TransferItem) => void;
}

const sortFieldToSymphony = new Map([
  ['amount', Field.AMOUNT],
  ['createdAt', Field.CREATED],
  ['scheduledDate', Field.SCHEDULED],
  ['settlementDate', Field.SETTLEMENT_DATE],
]);

export const DepositsTab: React.FC<DepositsTabProps> = ({
  commentColumn,
  contentOptions,
  currentUser,
  nullStateConfig,
  onAccountClick,
  onClientClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  onUpdate,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [content, setContent] = useState<DepositDisplayStrings>();
  const [isBusy, setIsBusy] = useState(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [selectedDepositUpdateItem, setSelectedDepositUpdateItem] = useState<TransferStatusUpdateItem>();
  const [statusFilter, setStatusFilter] = useState(TransferStatusFilter.PENDING_IN_PROGRESS);
  const [recurrenceFilter, setRecurrenceFilter] = useState(RecurrenceOptions.ONE_TIME);
  const [updateCashTransfer] = useUpdateCashTransfer();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    order: OrderType.DESCENDING,
    field: 'createdAt',
  });

  const [currentRequestTab, setCurrentRequestTab] = useState(RequestTabs.FlaggedRequests);

  const { data, loading, error } = useDepositsTabData({
    page: currentPage,
    currentRequestTab,
    statusFilter,
    recurrenceFilter,
    contentOptions,
    ...sortConfig,
    field: sortFieldToSymphony.get(sortConfig.field) || Field.CREATED,
  });

  const {
    sfOpsDashboard: { styles: sfOpsDashboardStyles },
  } = useTheme<SfTheme>();
  const { open: statusModalOpen, openModal: openStatusModal, onClose: statusModalOnClose } = useModalState();
  const statusFilterHandler = (newStatusFilter: TransferStatusFilter) => {
    setStatusFilter(newStatusFilter);
    setCurrentPage(1);
  };

  const onSelectNewStatusForItem = (item: TransferItem, selectedStatus: TransferStatus) => {
    setSelectedDepositUpdateItem({
      item,
      newStatus: selectedStatus,
    });
    openStatusModal();
  };

  const onConfirmStatusChange = async () => {
    if (selectedDepositUpdateItem?.item && selectedDepositUpdateItem.newStatus) {
      setIsBusy(true);
      const { newStatus } = selectedDepositUpdateItem;
      const { id, frequency, managedProductId } = selectedDepositUpdateItem.item;
      const scheduledTransferStatus = cashTransferStatusToScheduledTransferStatusForUpdate(newStatus, frequency);
      if (scheduledTransferStatus) {
        try {
          await updateCashTransfer({
            variables: {
              transfer: {
                financialAccountId: managedProductId,
                frequency,
                status: scheduledTransferStatus,
                transferId: id,
                type: TransferType.DEPOSIT,
              },
            },
          });
          onUpdate(selectedDepositUpdateItem.item);
          data?.refetchCashDepositsData();
          setIsBusy(false);
          statusModalOnClose();
        } catch (e: any) {
          setErrorSaving(e);
          setIsBusy(false);
        }
      }
    }
  };

  const handleRecurrenceChange: ComponentProps<typeof ToggleButtonGroup>['onChange'] = (_event, option) => {
    if (option !== null) {
      setRecurrenceFilter(option);
    }
    setCurrentPage(1);
    setSortConfig({
      order: OrderType.DESCENDING,
      field: RecurrenceOptions[option] === 'RECURRING' ? 'createdAt' : sortConfig.field,
    });
  };

  const onSort = (field: string) => () => {
    const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
    setSortConfig({ order: toggleOrder, field });
    setCurrentPage(1);
  };

  const handleRequestTabChange: ComponentProps<typeof Tabs>['onChange'] = option => {
    if (option !== null) {
      setCurrentRequestTab(option);
    }
    setRecurrenceFilter(RecurrenceOptions.ONE_TIME);
    setCurrentPage(1);
    setSortConfig({
      order: OrderType.DESCENDING,
      field: sortConfig.field,
    });
  };

  useEffect(() => {
    if (data?.content) {
      setContent(generateDepositStaticDisplayStrings(data.content));
    }
  }, [data?.content, sortConfig, recurrenceFilter]);

  const clearErrorsAndCloseModal = () => {
    setErrorSaving(undefined);
    statusModalOnClose();
  };
  const transfersTable = useMemo(() => {
    if (data && content) {
      return (
        <Grid container item>
          <Grid item sx={{ display: 'flex', flexDirection: 'row', width: 1 }} xs={12}>
            <Grid xs={6}>
              <ToggleButtonGroup exclusive onChange={handleRecurrenceChange} value={recurrenceFilter}>
                <ToggleButton value={RecurrenceOptions.ONE_TIME}>{content.recurringButton.nonRecurring}</ToggleButton>
                <ToggleButton value={RecurrenceOptions.RECURRING}>{content.recurringButton.recurring}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid data-qa="filter-container" item sx={{ display: 'flex', justifyContent: 'end' }} xs={6}>
              <Typography sx={{ marginRight: 2, alignSelf: 'center' }} variant="subtitle2">
                Filter by Status:
              </Typography>
              <DepositsStatusFilter
                currentStatusFilter={statusFilter}
                displayStrings={content}
                onChange={statusFilterHandler}
              />
            </Grid>
          </Grid>
          <Grid item pt={6} xs={12}>
            {recurrenceFilter === RecurrenceOptions.ONE_TIME && (
              <NonRecurringCashTransferTable
                TableComponent={BasicTable}
                commentColumn={commentColumn}
                commentEntity={UserNoteEntityType.CASH_DEPOSIT}
                contentOptions={contentOptions}
                currentPage={currentPage}
                currentUser={currentUser}
                displayStrings={content}
                flagEntity={FlagEntity.CASH_DEPOSIT}
                getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
                getClientNameRedirectUrl={getClientNameRedirectUrl}
                items={data.depositItems}
                nullStateConfig={nullStateConfig}
                onAccountClick={onAccountClick}
                onClientClick={onClientClick}
                onPageChange={setCurrentPage}
                onSelectNewStatusForItem={onSelectNewStatusForItem}
                onSort={onSort}
                refetchData={() => data.refetchCashDepositsData()}
                sortConfig={sortConfig}
                totalPages={data.totalPages}
              />
            )}
            {recurrenceFilter === RecurrenceOptions.RECURRING && (
              <RecurringCashTransferTable
                TableComponent={BasicTable}
                commentColumn={commentColumn}
                commentEntity={UserNoteEntityType.RECURRING_CASH_DEPOSIT}
                contentOptions={contentOptions}
                currentPage={currentPage}
                currentStatusFilter={statusFilter}
                currentUser={currentUser}
                displayStrings={content}
                flagEntity={FlagEntity.RECURRING_CASH_DEPOSIT}
                getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
                getClientNameRedirectUrl={getClientNameRedirectUrl}
                items={data.depositItems}
                nullStateConfig={nullStateConfig}
                onAccountClick={onAccountClick}
                onChangeStatusFilter={statusFilterHandler}
                onClientClick={onClientClick}
                onPageChange={setCurrentPage}
                onSelectNewStatusForItem={onSelectNewStatusForItem}
                onSort={onSort}
                refetchData={() => data.refetchCashDepositsData()}
                sortConfig={sortConfig}
                totalPages={data.totalPages}
              />
            )}
            <StatusChangeModal
              contentOptions={contentOptions}
              errorSaving={errorSaving}
              isBusy={isBusy}
              onClose={clearErrorsAndCloseModal}
              onConfirm={onConfirmStatusChange}
              open={statusModalOpen}
            />
          </Grid>
        </Grid>
      );
    }
    return null;
  }, [
    clearErrorsAndCloseModal,
    commentColumn,
    content,
    contentOptions,
    currentPage,
    currentUser,
    data,
    errorSaving,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
    handleRecurrenceChange,
    isBusy,
    nullStateConfig,
    onAccountClick,
    onClientClick,
    onConfirmStatusChange,
    onSelectNewStatusForItem,
    onSort,
    recurrenceFilter,
    sortConfig,
    statusFilter,
    statusModalOpen,
  ]);

  return (
    <Grid container direction="column" spacing={3}>
      {(loading || error) && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading deposit items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      )}
      {data && content && (
        <>
          {content.partitionedByFlaggedStatus ? (
            <Grid container item>
              <TabContext value={currentRequestTab}>
                <Grid container sx={{ pt: 0, ml: 2 }}>
                  <Grid item xs={12}>
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: sfOpsDashboardStyles.tabsIndicatorColor,
                        },
                      }}
                      data-qa="partition-tabs"
                      onChange={handleRequestTabChange}
                      value={currentRequestTab}
                    >
                      <Tab
                        data-qa="flaggedRequests-tab"
                        label={content.partitionTabLabels.flaggedRequests}
                        sx={{
                          ...sfOpsDashboardStyles.partitionTabs,
                        }}
                        value={RequestTabs.FlaggedRequests}
                      />
                      <Tab
                        data-qa="allotherrequests-tab"
                        label={content.partitionTabLabels.allOtherRequests}
                        sx={{
                          ...sfOpsDashboardStyles.partitionTabs,
                        }}
                        value={RequestTabs.AllOtherRequests}
                      />
                    </Tabs>
                  </Grid>
                </Grid>
                <Grid container sx={{ py: 5 }}>
                  <TabPanel sx={{ width: 1, mx: -1 }} value={RequestTabs.FlaggedRequests}>
                    {transfersTable}
                  </TabPanel>
                  <TabPanel sx={{ width: 1, mx: -1 }} value={RequestTabs.AllOtherRequests}>
                    {transfersTable}
                  </TabPanel>
                </Grid>
              </TabContext>
            </Grid>
          ) : (
            <>{transfersTable}</>
          )}
        </>
      )}
    </Grid>
  );
};
