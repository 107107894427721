import { startOfToday } from 'date-fns';
import React, { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { DocusignRequired } from '../DocusignRequired';
import { LinkBankAccountModal } from '../LinkBankAccount';

import { CloseAccountConfirmation } from './CloseAccountConfirmation';
import { CloseAccountForm } from './CloseAccountForm';
import { CloseAccountOptions } from './CloseAccountOptions';
import { useGetCloseAccountContent } from './contentstack';
import { CloseRequest, useHandleRequestClose, useHandleValidateCashTransfer } from './hooks';
import { useGetDestinationAccounts } from './symphony';
import { GetDestinationAccounts_client_financialAccounts as DestinationBankAccount } from './symphony/__generated__/query.v2';
import {
  ClosingOptions,
  FormData,
  getActiveClosingOptions,
  getDestinationBankAccountOptions,
  getDisplayTaxWithholdingSteps,
  getModalTitle,
  getPrimaryCtaText,
  getSecondaryCtaText,
  showPrimaryCta,
  transformFormData,
} from './utils';

import {
  AssociatedEntityInput,
  AssociatedEntityType,
  FinancialAccountAssociationVerificationStatus,
  FinancialAccountType,
  ManagedProductType,
  TransferFrequency,
} from '~/__generated__';
import { useDeleteBankAccount } from '~/components/modals/DeleteBankAccount/symphony';
import { AddFinancialAccount_addFinancialAccount } from '~/components/modals/LinkBankAccount/symphony/__generated__/mutation.v2';
import { ValidateCashTransferResponse } from '~/components/modals/WithdrawFunds/symphony';
import { FormData as TaxWithholdingFormData } from '~/components/modals/WithdrawFunds/types';
import { WithdrawFundsFormProgress } from '~/components/modals/WithdrawFunds/WithdrawFundsFormProgress';
import { TaxWithholdingForm } from '~/components/TaxWithholdingForm';
import { TaxWithholdingPlayback } from '~/components/TaxWithholdingPlayback';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Modal } from '~/components/ui/Modal';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Button, Grid, LoadingButton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { useClientInfo } from '~/hooks/client/useClientInfo';
import { FinancialAccount, useSaveFinancialAccountAssociation } from '~/hooks/financial-account/symphony';
import { PlaidLinkType } from '~/hooks/plaid-link';
import {
  getBankAccountId,
  getClientAgeFromBirthDate,
  isRetirementAccountType,
  isValidBankAccount,
} from '~/utils/account';
import { GraphQLError, isApolloError, NetworkStatus } from '~/utils/apollo-client';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { allNumericDateFormat } from '~/utils/format/date';
import { SymphonyError } from '~/utils/symphony';
import { SfTheme } from '~/utils/theme';

export type Props = ComponentProps<typeof Modal> & {
  accountId?: string | null;
  accountNumberFormat?: string;
  contentOptions: ContentOptions;
  currentBalance?: number;
  dataQa?: string;
  financialAccountType?: FinancialAccountType;
  managedProductId: string;
  managedProductType?: ManagedProductType;
  maskedAccountNumber: string;
  onClose?: (message?: string) => void;
  onLinkAccount?: (account: FinancialAccount) => void;
  partyId: string;
  partyIdFA?: string;
  plaidLinkageType?: PlaidLinkType;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntity?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  refetchAccounts?: () => void;
  showVerifiedBankAccounts?: boolean;
  syncExternalBankAccounts?: boolean;
};

export const CloseAccountModal: React.FC<Props> = ({
  accountId,
  accountNumberFormat,
  dataQa = 'close-account-modal',
  contentOptions,
  financialAccountType,
  partyId,
  managedProductId,
  maskedAccountNumber,
  maxWidth = 'sm',
  managedProductType,
  partyIdFA,
  plaidLinkageType,
  open,
  currentBalance,
  onLinkAccount,
  redirectToSignDocuments,
  refetchAccounts,
  showVerifiedBankAccounts,
  syncExternalBankAccounts,
  ...modalProps
}) => {
  const {
    components: {
      sfCloseAccount: { showEndEngagementTaxWithholdingSteps, showShouldSellAssets },
      sfWithdrawFunds: { isDocusignRequiredForRetirementWithdrawals },
    },
    featureFlags: {
      isDocusignRequiredForFinancialAccountLinkageInRCE,
      showWarningForNonVerifiedFinancialAccountLinkages,
    },
  } = useCoreConfig();
  const [bankAccounts, setBankAccounts] = useState<DestinationBankAccount[]>([]);
  const [closingStep, setClosingStep] = useState(1);
  const [selectedClosingOption, setSelectedClosingOption] = useState<ClosingOptions>();
  const [closeRequest, setCloseRequest] = useState<CloseRequest>();
  const [closeRequestError, setCloseRequestError] = useState<Error>();
  const [activeClosingOptions, setActiveClosingOptions] = useState<ClosingOptions[]>([]);
  const [validateTransferResponse, setValidateTransferResponse] = useState<ValidateCashTransferResponse>();
  const [addedFinancialAccount, setAddedFinancialAccount] = useState<
    AddFinancialAccount_addFinancialAccount | null | undefined
  >();
  const [disableButton, setDisableButton] = useState(false);
  const [entityForDocusign, setEntityForDocusign] = useState<AssociatedEntityInput>({
    entityId: '',
    entityType: AssociatedEntityType.BANK_ACCOUNT_ASSOCIATION,
  });

  const [deleteBankAccount] = useDeleteBankAccount();
  const handleValidateCashTransfer = useHandleValidateCashTransfer();
  const isRetirementAccount: boolean = !!financialAccountType && isRetirementAccountType(financialAccountType);

  const {
    open: isDocusignRequiredModalOpen,
    openModal: openDocusignRequiredModal,
    onClose: closeDocusignRequiredlModal,
  } = useModalState();
  const {
    open: linkBankAccountOpen,
    openModal: linkBankAccountOpenModal,
    onClose: linkBankAccountOnClose,
  } = useModalState();
  const initialTaxWithholdingFormData = {
    withdrawalAmount: currentBalance?.toString() ?? '',
    withdrawalFrequency: TransferFrequency.ONE_TIME,
    eligibleDestinationBankAccount: '',
  };

  const handleLinkBankAccountModalOnClose = () => {
    setSelectedAccountForEdit(undefined);
    linkBankAccountOnClose();
  };

  const [taxWithholdingFormData, setTaxWithholdingFormData] = useState<TaxWithholdingFormData>(
    initialTaxWithholdingFormData,
  );
  const taxWithholdingformHooks = useForm<TaxWithholdingFormData>();
  const { handleSubmit: handleTaxWithholdingFormSubmit } = taxWithholdingformHooks;
  const closeAccountFormHooks = useForm<FormData>({ mode: 'onTouched' });
  const { handleSubmit } = closeAccountFormHooks;
  const { data: contentstackData, loading: contentstackLoading, error: contentstackError } = useGetCloseAccountContent({
    variables: contentOptions,
    skip: !open,
  });
  const { data: clientInfoData, loading: clientInfoLoading, error: clientInfoError } = useClientInfo({
    variables: { partyId },
    skip: !open,
  });

  const {
    data: destinationAccountsData,
    loading: destinationAccountsLoading,
    error: destinationAccountsError,
    refetch: refetchDestinationAccountsData,
    networkStatus: destinationAccountsNetworkStatus,
  } = useGetDestinationAccounts({
    variables: {
      partyId,
      syncExternalBankAccounts,
      isVerified: showVerifiedBankAccounts,
      includeManagedProducts:
        isDocusignRequiredForFinancialAccountLinkageInRCE || showWarningForNonVerifiedFinancialAccountLinkages,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !open,
  });

  const loading = contentstackLoading || destinationAccountsLoading || clientInfoLoading;
  const isRefetchingAccounts = destinationAccountsNetworkStatus === NetworkStatus.refetch;
  const [error, setError] = useState<Error>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [saveFinancialAccountAssociation] = useSaveFinancialAccountAssociation();

  useEffect(() => {
    const logError = (err?: Error) => {
      if (err) {
        console.error(err);
        setError(err);
      }
    };
    if (!loading) {
      // To log error only after all the requests have completed
      logError(contentstackError);
      logError(destinationAccountsError);
      logError(clientInfoError);
    }
  }, [clientInfoError, contentstackError, destinationAccountsError, loading]);

  const [linkedBankAccount, setLinkedBankAccount] = useState<string | null>();
  const [selectedAccountForEdit, setSelectedAccountForEdit] = useState<DestinationBankAccount>();

  const content = contentstackData?.all_close_account_modal?.items?.[0] ?? undefined;
  const programName =
    contentstackData?.all_product_name?.items?.find(item => item?.key === managedProductType)?.text || '';

  const maximumWithdrawAmount = content?.close_account?.maximum_withdraw_amount;
  const displayTaxWithholdingSteps = getDisplayTaxWithholdingSteps(
    isRetirementAccount,
    content,
    selectedClosingOption,
    showEndEngagementTaxWithholdingSteps,
  );
  const showStaticModal = maximumWithdrawAmount && currentBalance ? currentBalance > maximumWithdrawAmount : false;
  const [bankAccountOptions, setBankAccountOptions] = React.useState<DropdownItem[]>([]);

  const clientAge = getClientAgeFromBirthDate(clientInfoData?.birthDate ?? '');
  const prematureAgeContent = content?.tax_withholdingConnection?.edges?.[0]?.node?.soft_block_client_age?.premature;

  const handleRequestClose = useHandleRequestClose({ isRetirementAccount, clientAge, prematureAgeContent });

  const handleEditingBankAccount = useCallback(
    (item: DropdownItem) => {
      const editedAccount = bankAccounts.find(account => account.id === item.value);
      setSelectedAccountForEdit(editedAccount);
    },
    [bankAccounts],
  );

  useEffect(() => {
    const accountDropDownItems = getDestinationBankAccountOptions(
      bankAccounts,
      accountNumberFormat,
      handleEditingBankAccount,
    );
    setBankAccountOptions(accountDropDownItems);
  }, [bankAccounts]);

  useEffect(() => {
    if (destinationAccountsData?.client?.financialAccounts) {
      const accounts: DestinationBankAccount[] = destinationAccountsData.client.financialAccounts
        .map(account => {
          return {
            ...account,
            id: getBankAccountId(account.id, account.isFromExternalSource, account.accountNumber),
          };
        })
        .filter(isValidBankAccount);
      setBankAccounts(accounts);
    }
  }, [destinationAccountsData]);

  useEffect(() => {
    setActiveClosingOptions(getActiveClosingOptions(showStaticModal, content));
  }, [content, showStaticModal]);

  const todaysDate = allNumericDateFormat(startOfToday(), { locale: contentOptions.locale });

  const setInitialState = () => {
    setClosingStep(1);
    setCloseRequestError(undefined);
    setDisableButton(false);
    setTaxWithholdingFormData(initialTaxWithholdingFormData);
    setBankAccounts([]);
    setLinkedBankAccount(undefined);
    setAddedFinancialAccount(undefined);
  };

  const onSuccessCallback = () => {
    modalProps.onClose?.(content?.feedback_message ?? '');
    refetchAccounts?.();
    setInitialState();
  };

  const deleteFinancialAccount = async (financialAccountId: string) => {
    await deleteBankAccount({
      variables: {
        bankAccountId: financialAccountId,
        partyId,
      },
    });
  };

  const isSymphonyError = (e: GraphQLError | SymphonyError): e is SymphonyError => {
    return 'code' in e || 'correlationId' in e;
  };

  const handleCloseRequestError = (err: Error) => {
    let errMessage = '';
    if (isApolloError(err)) {
      errMessage = err.graphQLErrors.find(e => isSymphonyError(e) && e.errorCode === 5)
        ? content?.labels?.find(e => e?.keys === '5')?.value ?? ''
        : '';
    }
    setCloseRequest(undefined);
    setLinkedBankAccount(undefined);
    setCloseRequestError(errMessage.length ? { ...err, message: errMessage } : err);
  };

  const handleSuccessfulFormSubmit = async (formData: any) => {
    let finalFormData = formData;
    if (displayTaxWithholdingSteps) {
      setIsSubmitting(true);
      try {
        const {
          validateCashTransferResponse,
          addedFinancialAccount: financialAccount,
        } = await handleValidateCashTransfer({
          accountId,
          addedFinancialAccount,
          bankAccounts,
          cashAmount: taxWithholdingFormData.withdrawalAmount,
          clientUserName: clientInfoData?.userName ?? '',
          deleteFinancialAccount,
          financialAccountType,
          formData,
          managedProductId,
          partyId,
        });
        if (financialAccount) {
          const newBankAccounts = [
            ...bankAccounts.filter(
              acc =>
                acc.accountNumber !== financialAccount.accountNumber &&
                acc.routingNumber !== financialAccount.routingNumber,
            ),
            financialAccount,
          ] as DestinationBankAccount[];

          setLinkedBankAccount(financialAccount.id);
          setAddedFinancialAccount(financialAccount);
          finalFormData = { ...formData, eligibleDestinationBankAccount: financialAccount.id };

          setBankAccounts(newBankAccounts);
        }
        if (validateCashTransferResponse) {
          setValidateTransferResponse(validateCashTransferResponse);
        }
        setClosingStep(3);
        setCloseRequestError(undefined);
      } catch (err) {
        console.error(err);
        if (err instanceof Error) {
          handleCloseRequestError(err);
        }
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setClosingStep(3);
    }
    const userName = clientInfoData?.userName ?? '';
    setCloseRequest(
      transformFormData(
        { name: userName, partyId },
        finalFormData,
        managedProductId,
        bankAccounts,
        selectedClosingOption,
      ),
    );
    setTaxWithholdingFormData({
      ...taxWithholdingFormData,
      eligibleDestinationBankAccount: finalFormData?.eligibleDestinationBankAccount,
    });
  };

  const onCloseCallback = async () => {
    if (modalProps.onClose) {
      modalProps.onClose();
      if (addedFinancialAccount?.id) {
        await deleteFinancialAccount(addedFinancialAccount.id);
      }
      setInitialState();
    }
  };

  const onSecondaryCtaAction = async () => {
    if (closingStep <= 2) {
      await onCloseCallback();
    } else {
      setCloseRequestError(undefined);
      setClosingStep(closingStep - 1);
    }
  };

  const handleSelectedClosingOption = (closingType: ClosingOptions) => {
    setSelectedClosingOption(closingType);
  };

  const handleSuccessfulTaxWithholdingFormSubmit = (formData: TaxWithholdingFormData) => {
    setTaxWithholdingFormData({ ...taxWithholdingFormData, ...formData });
    setClosingStep(4);
  };

  const onPrimaryCtaAction = async () => {
    if (closingStep === 1 && selectedClosingOption) {
      setClosingStep(2);
    } else if (closingStep === 2) {
      await handleSubmit(handleSuccessfulFormSubmit)();
    } else if (closingStep === 3 || closingStep === 4) {
      if (displayTaxWithholdingSteps && closingStep === 3) {
        await handleTaxWithholdingFormSubmit(handleSuccessfulTaxWithholdingFormSubmit)();
        return;
      }
      try {
        setCloseRequestError(undefined);
        setIsSubmitting(true);

        // There's no Docusign redirection logic in case of End Engagement, therefore invoking the mutation directly.
        if (selectedClosingOption === ClosingOptions.END_MANAGEMENT) {
          await handleRequestClose({
            closeRequest,
            deleteFinancialAccount,
            isRetirementAccount,
            taxWithholdingFormData,
            validateTransferResponse,
            addedFinancialAccount,
          });
        } else if (selectedClosingOption === ClosingOptions.WITHDRAW_AND_CLOSE) {
          // Docusign redirect should happen in case of Withdraw and Close.
          const financialAccountAssociationInput = {
            ...(closeRequest?.bankAccountWithParty?.bankAccount
              ? { bankAccount: closeRequest.bankAccountWithParty.bankAccount }
              : { bankAccountId: closeRequest?.closeRequestInput.transferToFinancialAccountId }),
          };
          const bankAccountAssociationResult = await saveFinancialAccountAssociation({
            variables: {
              managedProductId,
              partyId,
              financialAccountAssociation: financialAccountAssociationInput,
            },
          });
          const associationData = bankAccountAssociationResult.data?.saveFinancialAccountAssociation;
          const isDocusignRequired =
            // Check for null to maintain backwards compatibility
            associationData?.verificationStatus === null ||
            associationData?.verificationStatus === FinancialAccountAssociationVerificationStatus.NEEDS_DOCUSIGN;
          if (isRetirementAccount && isDocusignRequiredForRetirementWithdrawals) {
            // We're not opening DocusignRequired Modal in case of Retirement account instead RequestClose + maintaing the previous flow to redirect to Docusign
            handleDocusignRedirection();
          } else if (isDocusignRequiredForFinancialAccountLinkageInRCE && isDocusignRequired) {
            // Bank Association Id is only set in this case and not for Retirement Withdrawals/Closures.
            setEntityForDocusign({
              ...entityForDocusign,
              entityId: associationData.id,
            });
            openDocusignRequiredModal();
          } else {
            await handleRequestClose({
              closeRequest,
              deleteFinancialAccount,
              isRetirementAccount,
              taxWithholdingFormData,
              validateTransferResponse,
              addedFinancialAccount,
            });
            if (!displayTaxWithholdingSteps) {
              onSuccessCallback();
            } else {
              setClosingStep(5);
            }
          }
        }
      } catch (err) {
        console.error(err);
        if (err instanceof Error) {
          handleCloseRequestError(err);
        }
      } finally {
        setIsSubmitting(false);
        await onCloseCallback();
        onSuccessCallback();
      }
    } else if (closingStep === 5) {
      onSuccessCallback();
    }
  };

  const onLinkAccountCallback = (account: FinancialAccount) => {
    refetchDestinationAccountsData();
    setLinkedBankAccount(account.id ?? bankAccounts[0].id ?? '');
    onLinkAccount?.(account);
  };

  const handleDocusignRedirection = async () => {
    try {
      await handleRequestClose({
        closeRequest,
        deleteFinancialAccount,
        isRetirementAccount,
        taxWithholdingFormData,
        validateTransferResponse,
        addedFinancialAccount,
      });
      redirectToSignDocuments?.(managedProductId, entityForDocusign.entityId ? entityForDocusign : undefined);
    } catch (err) {
      console.error(err);
    }
  };

  const {
    sfCloseAccountModal: { styles },
  } = useTheme<SfTheme>();

  return (
    <>
      <Modal
        {...modalProps}
        actions={
          <>
            {closingStep !== 5 && (
              <Button id="close-btn" onClick={onSecondaryCtaAction} variant="outlined">
                {getSecondaryCtaText(
                  closingStep,
                  showStaticModal,
                  showEndEngagementTaxWithholdingSteps,
                  selectedClosingOption,
                  content,
                  isRetirementAccount,
                )}
              </Button>
            )}
            {showPrimaryCta(
              closingStep,
              activeClosingOptions,
              bankAccountOptions,
              showStaticModal,
              selectedClosingOption,
            ) && (
              <LoadingButton
                disabled={(closingStep === 1 && !selectedClosingOption) || disableButton}
                id="submit-btn"
                loading={isSubmitting || isRefetchingAccounts}
                onClick={onPrimaryCtaAction}
                variant="contained"
              >
                {getPrimaryCtaText(
                  closingStep,
                  showEndEngagementTaxWithholdingSteps,
                  selectedClosingOption,
                  content,
                  isRetirementAccount,
                )}
              </LoadingButton>
            )}
          </>
        }
        content={
          <Grid
            sx={{
              '& a': {
                textDecoration: 'none',
                color: 'primary.main',
              },
            }}
          >
            {closingStep > 1 && closingStep < 5 && displayTaxWithholdingSteps && (
              <>
                <WithdrawFundsFormProgress activeStep={closingStep - 1} displayLinearProgress />
                <RteContent
                  config={{ accountNumber: maskedAccountNumber, programName }}
                  data={content?.close_account?.heading ?? ''}
                  data-qa="available-funds-summary-title"
                  mb={3}
                  mt={1.5}
                  sx={styles.summaryTitle}
                />
              </>
            )}
            {closingStep === 1 ? (
              <CloseAccountOptions
                activeClosingOptions={activeClosingOptions}
                content={content}
                handleSelectedClosingOption={handleSelectedClosingOption}
                maskedAccountNumber={maskedAccountNumber}
                selectedClosingOption={selectedClosingOption}
                showStaticModal={showStaticModal}
              />
            ) : closingStep === 2 ? (
              <CloseAccountForm
                bankAccountOptions={bankAccountOptions}
                bankAccounts={bankAccounts}
                closeRequest={closeRequest?.closeRequestInput}
                content={content}
                contentOptions={contentOptions}
                formHooks={closeAccountFormHooks}
                isRefetchingBankAccounts={isRefetchingAccounts}
                linkBankAccountOpen={linkBankAccountOpen}
                linkBankAccountOpenModal={linkBankAccountOpenModal}
                linkedBankAccount={linkedBankAccount}
                managedProductId={managedProductId}
                partyId={partyId}
                partyIdFA={partyIdFA}
                plaidLinkageType={plaidLinkageType}
                refetchBankAccounts={refetchDestinationAccountsData}
                selectedAccountForEdit={selectedAccountForEdit}
                selectedClosingOption={selectedClosingOption}
                setDisableButton={setDisableButton}
                showShouldSellAssets={showShouldSellAssets}
                symphonySubmitError={closeRequestError}
              />
            ) : closingStep === 3 && displayTaxWithholdingSteps ? (
              <TaxWithholdingForm
                clientAge={clientAge}
                content={content?.tax_withholdingConnection?.edges?.[0]?.node}
                financialAccountType={financialAccountType}
                formData={taxWithholdingFormData}
                formHooks={taxWithholdingformHooks}
                isCloseAccount
                validateCashTransferResponse={validateTransferResponse}
              />
            ) : closingStep === 4 && displayTaxWithholdingSteps ? (
              <TaxWithholdingPlayback
                bankAccounts={bankAccounts}
                clientAge={clientAge}
                content={content?.tax_withholding_playbackConnection?.edges?.[0]?.node ?? null}
                distributionReason={validateTransferResponse?.distributionCode}
                formData={taxWithholdingFormData}
                isCloseAccount
                prematureAgeContent={prematureAgeContent}
                symphonySubmitErrorMessage={closeRequestError?.message}
              />
            ) : (closingStep === 3 && !displayTaxWithholdingSteps) || closingStep === 5 ? (
              <CloseAccountConfirmation
                closeRequestError={closeRequestError}
                content={content}
                contentOptions={contentOptions}
                isRetirementAccount={isRetirementAccount}
                selectedClosingOption={selectedClosingOption}
                sellAssets={closeRequest?.closeRequestInput.sellAssets}
                todaysDate={todaysDate}
              />
            ) : null}
          </Grid>
        }
        contentOptions={contentOptions}
        data-qa={dataQa}
        error={error}
        loading={loading && !isRefetchingAccounts}
        maxWidth={maxWidth}
        onClose={onCloseCallback}
        open={open}
        title={getModalTitle(closingStep, isRetirementAccount, selectedClosingOption, content)}
      />
      <LinkBankAccountModal
        contentOptions={contentOptions}
        managedProductId={managedProductId}
        onClose={handleLinkBankAccountModalOnClose}
        onLinkAccount={account => onLinkAccountCallback(account)}
        open={linkBankAccountOpen}
        partyId={partyId}
        selectedAccount={selectedAccountForEdit}
      />
      {isDocusignRequiredForFinancialAccountLinkageInRCE && (
        <DocusignRequired
          contentOptions={contentOptions}
          onClose={closeDocusignRequiredlModal}
          onPrimaryClick={handleDocusignRedirection}
          open={isDocusignRequiredModalOpen}
        />
      )}
    </>
  );
};
