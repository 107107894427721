import { QueryResult } from '@apollo/client';

import { GetClientActionRequiredContent, GetClientActionRequiredContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetClientActionRequiredContent = (
  options?: QueryHookOptions<GetClientActionRequiredContent, GetClientActionRequiredContentVariables>,
): QueryResult<GetClientActionRequiredContent, GetClientActionRequiredContentVariables> => {
  return useContentstackQuery(queries.GetClientActionRequiredContent, options);
};
