import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { HoldingsTable } from '~/components/Funding/HoldingsTable';
import {
  calulateTotalEstimatedValue,
  getDefaultValuesForHoldings,
  getHoldings,
  getHoldingsFromPosition,
  HoldingsTableContent,
} from '~/components/Funding/HoldingsTable/utils';
import { fields, FundingFormData } from '~/components/Funding/utils';
import { Drawer } from '~/components/ui/Drawer';
import { Box, Button } from '~/components/ui/mui';
import { AssetTransfer } from '~/containers/Funding/symphony';
import { BrokerageFinancialAccount, ExternalBrokerageFinancialAccount } from '~/hooks/financial-account/symphony';

export interface HoldingsTableModalContent extends HoldingsTableContent {
  cancel: string;
  journalingInfoText: string;
  journalingMessageLabel: string;
  save: string;
  title: string;
}
export type HoldingTableDrawerProps = ComponentProps<typeof Drawer> & {
  accounts: {
    brokerageAccounts: BrokerageFinancialAccount[];
    externalBrokerageAccounts: ExternalBrokerageFinancialAccount[];
  };
  brokerageSourceId: string;
  content: HoldingsTableModalContent;
  defaultValues: Partial<FundingFormData>;
  initialAssetTransfer?: AssetTransfer;
  investmentAmountFieldName: string;
  isJournaling: boolean;
  onClose: () => void;
};

export const HoldingTableDrawer: React.FC<HoldingTableDrawerProps> = ({
  accounts,
  brokerageSourceId,
  content,
  defaultValues,
  initialAssetTransfer,
  isJournaling,
  onClose,
  open,
  investmentAmountFieldName,
  ...drawerProps
}) => {
  const { clearErrors, setValue, watch } = useFormContext();
  const selectedItems = watch(fields.holdingData);
  const [currentBrokerageAccountId, setCurrentBrokerageAccountsId] = useState<string>('');
  const [defaultHoldings, setDefaultHoldings] = useState<Record<string, { estimatedValue: number; units: string }>>();
  const currentBrokerageAccount = useMemo(
    () =>
      isJournaling
        ? accounts.externalBrokerageAccounts.find(item => item.id === brokerageSourceId)
        : accounts.brokerageAccounts.find(item => item.id === brokerageSourceId),
    [accounts, brokerageSourceId, isJournaling],
  );
  useEffect(() => {
    if (!currentBrokerageAccountId) {
      setCurrentBrokerageAccountsId(brokerageSourceId);
      return;
    }
    if (currentBrokerageAccountId !== brokerageSourceId) {
      clearErrors(fields.holdingData);
      setValue(fields.holdingData, undefined);
      setDefaultHoldings(undefined);
      setCurrentBrokerageAccountsId(brokerageSourceId);
    }
  }, [brokerageSourceId, clearErrors, currentBrokerageAccountId, setValue]);

  const holdingProps = useMemo(() => {
    if (currentBrokerageAccount) {
      const holdings =
        'positionsV2' in currentBrokerageAccount
          ? getHoldings(currentBrokerageAccount.positionsV2 ?? [], content, currentBrokerageAccount.balances)
          : 'positions' in currentBrokerageAccount
          ? getHoldingsFromPosition(currentBrokerageAccount.positions ?? [], content, currentBrokerageAccount.balances)
          : [];
      const defaultValue =
        defaultValues.sourceBrokerageAccount === currentBrokerageAccount.id && initialAssetTransfer
          ? getDefaultValuesForHoldings(initialAssetTransfer, holdings, isJournaling)
          : undefined;
      return {
        content,
        defaultValue,
        financialInstitution: currentBrokerageAccount.financialInstitution ?? '',
        holdings,
        maskedAccountNumber: currentBrokerageAccount.maskedAccountNumber ?? '',
      };
    } else {
      return undefined;
    }
  }, [currentBrokerageAccount, content, defaultValues, initialAssetTransfer, isJournaling]);

  useEffect(() => {
    if (!defaultHoldings && !selectedItems) {
      if (holdingProps?.defaultValue) {
        setValue(fields.holdingData, holdingProps.defaultValue);
        setDefaultHoldings(holdingProps.defaultValue);
      } else {
        if (holdingProps?.holdings) {
          const holdings: Record<string, { estimatedValue: number; units: string }> = {};
          holdingProps.holdings.forEach(holding => {
            holdings[holding.id] = { estimatedValue: holding.marketValue, units: holding.units.toString() };
          });
          setValue(fields.holdingData, holdings);
          setDefaultHoldings(holdings);
        }
      }
    }
  }, [holdingProps, setValue, defaultHoldings, selectedItems]);

  const handleOnClose = () => {
    setValue(fields.holdingData, defaultHoldings);
    onClose();
  };
  const handleOnSave = () => {
    setDefaultHoldings(selectedItems ?? {});
    setValue(investmentAmountFieldName, calulateTotalEstimatedValue(selectedItems ?? {}));
    onClose();
  };
  return (
    <Drawer
      {...drawerProps}
      actions={
        <Box display="flex" justifyContent="flex-end" px={5} py={2}>
          <Button id="close-btn" onClick={() => handleOnClose()} sx={{ mr: 2 }} variant="outlined">
            {content.cancel}
          </Button>
          <Button
            disabled={Object.keys(selectedItems ?? {}).length === 0}
            id="save-btn"
            onClick={() => handleOnSave()}
            variant="contained"
          >
            {content.save}
          </Button>
        </Box>
      }
      actionsViewOption="inherit"
      onClose={handleOnClose}
      open={open}
      title={content.title}
    >
      {holdingProps ? (
        <Box px={16} py={4}>
          <HoldingsTable
            defaultValues={holdingProps.defaultValue}
            holdingSourceFieldName={fields.holdingData}
            optionalMessageFieldName={isJournaling ? fields.optionalJournalingMessage : undefined}
            optionalMessageLabel={content.journalingMessageLabel}
            optionalMessageMaxLength={20}
            showDividerBelowLabel={false}
            showUnitsColumn={isJournaling}
            validations={[
              'aboveInitialInvestmentAmount',
              'belowInitialInvestmentAmount',
              'maximumAmount',
              'noItemSelected',
            ]}
            {...holdingProps}
          />
        </Box>
      ) : null}
    </Drawer>
  );
};
