import React from 'react';

import { Grid, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface Props {
  dataQa?: string;
  heading?: React.ReactNode;
}

export const RowSection: React.FC<Props> = ({ dataQa = 'funding-row-section', heading, children }) => {
  const {
    sfFunding: { typographyVariants },
  } = useTheme();

  return (
    <Grid alignItems="start" container data-qa={dataQa} rowSpacing={3}>
      <Grid item md={8} xs={12}>
        {typeof heading === 'string' ? (
          <Typography component="h2" variant={typographyVariants?.sectionHeading}>
            {heading}
          </Typography>
        ) : (
          heading
        )}
      </Grid>
      <Grid item md={4} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
