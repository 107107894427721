import { endOfYesterday } from 'date-fns';
import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useGetAccountActivityContent } from '../contentstack';
import { useGetFinancialAccountTransactions, useLazyGetFinancialAccountTransactions } from '../symphony';
import { GetFinancialAccountTransactions } from '../symphony/__generated__/query.v2';
import {
  AccountActivityColumnKeys,
  AccountActivityData,
  FilterValues,
  MobileSortOption,
  TabState,
  TransactionTypeFilter,
} from '../types';
import {
  formatActivityData,
  formatActivityDataCSV,
  getAccountActivityContent,
  getFilterFields,
  mapSortConfig,
  mapSortConfigToOption,
  mapSortOptionToConfig,
  mapTransactionTypesFilterDropdownToSymphony,
} from '../utils';

import { useFilterData } from './useFilterData';

import { OrderType } from '~/__generated__';
import { DateRangeOptions } from '~/components/ControlFields/DateFilters/types';
import { getDateRangeValue } from '~/components/ControlFields/DateFilters/utils';
import { SortConfig } from '~/components/ui/BasicTable';
import { Dropdown } from '~/components/ui/Dropdown';
import { useModalState } from '~/components/ui/Modal/hooks';
import { ContentOptions } from '~/utils/contentstack';
import { downloadFile } from '~/utils/download';
import { formatDate } from '~/utils/format/date';
import { AsyncResult } from '~/utils/types';

interface Props {
  accountCreatedDate?: Date;
  contentOptions: ContentOptions;
  dateOptions: DateRangeOptions[];
  financialAccountId: string;
  initialTabState?: TabState;
  managedProductId: string;
  onUpdateTabState: (state: TabState) => void;
  partyId: string;
}

export const useGetAccountActivityData = ({
  accountCreatedDate,
  contentOptions,
  dateOptions,
  financialAccountId,
  initialTabState,
  onUpdateTabState,
  partyId,
}: Props): AsyncResult<AccountActivityData> => {
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(initialTabState?.currentPageNumber ?? 1);
  const [sortConfig, setSortConfig] = useState<SortConfig>(
    initialTabState?.sortConfig ?? {
      order: OrderType.DESCENDING,
      field: AccountActivityColumnKeys.DATE,
    },
  );
  // Ref is used to send the updated filter states instead of the original memoized state in the useEffect below.
  const tabRef = useRef<TabState | null>(null);
  const ENTRIES_PER_PAGE = 10;

  const { onClose, open, openModal } = useModalState();

  const { data: contentData, loading: contentLoading, error: contentError } = useGetAccountActivityContent({
    variables: contentOptions,
  });

  const onUpdateFilter = (values: FilterValues) => {
    setCurrentPageNumber(1);
    tabRef.current = {
      currentPageNumber: 1,
      filterValues: values,
      sortConfig,
    };
  };

  const onMobileSortChange = useCallback((option: MobileSortOption) => {
    setSortConfig(mapSortOptionToConfig(option));
    setCurrentPageNumber(1);
  }, []);

  const mobileSortValue: MobileSortOption = useMemo(() => mapSortConfigToOption(sortConfig), [sortConfig]);

  const onSort = useCallback(
    (field: string) => () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      setSortConfig({ order: toggleOrder, field });
      setCurrentPageNumber(1);
    },
    [sortConfig],
  );

  const content = useMemo(
    () => getAccountActivityContent(contentData ?? { all_account_details_account_activity: null }, onSort),
    [contentData, onSort],
  );

  const defaultFilterValues: FilterValues = useMemo(
    () => ({
      dateRange:
        initialTabState?.filterValues?.dateRange ??
        getDateRangeValue(
          initialTabState?.filterValues?.dateRangeOption ?? DateRangeOptions.Last30Days,
          accountCreatedDate,
          endOfYesterday(),
        ),
      dateRangeOption: initialTabState?.filterValues?.dateRangeOption ?? DateRangeOptions.Last30Days,
      transactionType: initialTabState?.filterValues?.transactionType ?? TransactionTypeFilter.ALL,
    }),
    [accountCreatedDate, initialTabState],
  );
  const filterConfig = useMemo(
    () => getFilterFields(content.filter, dateOptions, defaultFilterValues, accountCreatedDate),
    [accountCreatedDate, content.filter, dateOptions, defaultFilterValues],
  );
  const filterData = useFilterData(filterConfig, defaultFilterValues, onUpdateFilter);

  const mobileSortConfig: ComponentProps<typeof Dropdown> = useMemo(
    () => ({
      defaultValue: mapSortConfigToOption(sortConfig),
      items: Object.entries(MobileSortOption).map(entry => ({
        label: content.mobileSort.mobileSortOptionLabels[entry[1]],
        value: entry[1],
      })),
      label: content.mobileSort.label,
    }),
    [content.mobileSort],
  );

  const { data: transactionsData, loading: dataLoading, error: dataError } = useGetFinancialAccountTransactions({
    variables: {
      partyId,
      dateFrom: filterData.values.dateRange?.startDate
        ? formatDate(filterData.values.dateRange.startDate, 'yyyy-MM-dd')
        : undefined,
      dateTo: filterData.values.dateRange?.endDate
        ? formatDate(filterData.values.dateRange.endDate, 'yyyy-MM-dd')
        : undefined,
      financialAccountIds: [financialAccountId],
      pagination: {
        limit: ENTRIES_PER_PAGE,
        offset: (currentPageNumber - 1) * ENTRIES_PER_PAGE,
      },
      sort: mapSortConfig(sortConfig),
      transactionTypes: mapTransactionTypesFilterDropdownToSymphony(filterData.values.transactionType),
    },
  });

  const onUpdatePage = useCallback(
    (newPageNumber: number) => {
      setCurrentPageNumber(newPageNumber);
    },
    [setCurrentPageNumber],
  );

  const processCsvData = (csvData: GetFinancialAccountTransactions | null | undefined) => {
    if (csvData) {
      const csvHeader = content.table.tableColumns.map(col => col.title).join(',');
      const formattedCSV = formatActivityDataCSV(
        csvData.client?.financialAccounts?.[0]?.transactions.transactions ?? [],
        content,
      );
      downloadFile(`${csvHeader}\n${formattedCSV}`, 'account-activity.csv', 'text/csv');
    }
  };

  const [
    getFinancialAccountTransactions,
    { loading: csvLoading, error: csvError },
  ] = useLazyGetFinancialAccountTransactions({
    onCompleted: processCsvData,
  });

  const onDownloadCSV = useCallback(() => {
    // We have to pass in the variables when calling the hook instead of adding it to the useLazyGetFinancialAccountTransactions as additional calls will be made if variables are changed after the first manual call.
    getFinancialAccountTransactions({
      variables: {
        partyId,
        dateFrom: formatDate(filterData.values.dateRange?.startDate ?? '', 'yyyy-MM-dd'),
        dateTo: formatDate(filterData.values.dateRange?.endDate ?? '', 'yyyy-MM-dd'),
        financialAccountIds: [financialAccountId],
        sort: mapSortConfig(sortConfig),
        transactionTypes: mapTransactionTypesFilterDropdownToSymphony(filterData.values.transactionType),
      },
    });
  }, [filterData.values, getFinancialAccountTransactions, financialAccountId, partyId, sortConfig]);

  useEffect(() => {
    // Only update on unmounting of component
    return () => {
      if (tabRef.current) {
        onUpdateTabState(tabRef.current);
      }
    };
  }, []);

  useEffect(() => {
    tabRef.current = {
      currentPageNumber,
      filterValues: filterData.values,
      sortConfig,
    };
  }, [currentPageNumber, filterData.values, sortConfig]);

  const loading = useMemo(() => contentLoading, [contentLoading]);

  const error = useMemo(() => contentError || dataError, [contentError, dataError]);

  const data: AccountActivityData = useMemo(() => {
    const financialAccount = transactionsData?.client?.financialAccounts?.[0];
    return {
      content,
      currentPage: currentPageNumber,
      dataLoading,
      downloadError: csvError,
      downloadLoading: csvLoading,
      filterData,
      isFilterModalOpen: open,
      mobileSortData: {
        config: mobileSortConfig,
        onMobileSortChange,
        value: mobileSortValue,
      },
      onCloseFilterModal: onClose,
      onOpenFilterModal: openModal,
      onDownloadCSV,
      onUpdatePage,
      sortConfig,
      tableData: financialAccount?.transactions.transactions
        ? formatActivityData(financialAccount.transactions.transactions, content)
        : [],
      totalPages: financialAccount?.transactions.paginationContext.total
        ? Math.ceil(financialAccount.transactions.paginationContext.total / ENTRIES_PER_PAGE)
        : 0,
    };
  }, [
    content,
    currentPageNumber,
    csvError,
    csvLoading,
    dataLoading,
    filterData,
    mobileSortConfig,
    mobileSortValue,
    onClose,
    onDownloadCSV,
    onMobileSortChange,
    onUpdatePage,
    open,
    openModal,
    sortConfig,
    transactionsData,
  ]);

  return { data, loading, error };
};
