import { useMemo } from 'react';

import { GoalObjective } from '../types';

import {
  GetGoalObjectivesContent,
  GetGoalObjectivesContentVariables,
} from './__generated__/getGoalObjectivesContent.v2';
import { GetGoalObjectivesContent as GetGoalObjectivesContentQuery } from './getGoalObjectivesContent.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { getImageAssetUrl, useContentstackQuery } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Data {
  get: (partnerGoalExternalRef: string) => GoalObjective | undefined;
}

export const useGoalObjectivesContent = (
  options: QueryHookOptions<GetGoalObjectivesContent, GetGoalObjectivesContentVariables>,
): AsyncResult<Data> => {
  const goalObjectivesContent = useContentstackQuery<GetGoalObjectivesContent, GetGoalObjectivesContentVariables>(
    GetGoalObjectivesContentQuery,
    options,
  );

  const data: Data | undefined = useMemo(() => {
    if (goalObjectivesContent.loading || goalObjectivesContent.error) {
      return;
    }
    const goalObjectives = (goalObjectivesContent.data?.all_goal_objectives?.items?.[0]?.objectives ?? []).reduce<
      GoalObjective[]
    >((acc, item) => {
      if (item?.key) {
        acc.push({
          description: item.description ?? undefined,
          icon: {
            primary: getImageAssetUrl(item.icon?.primaryConnection),
            secondary: getImageAssetUrl(item.icon?.secondaryConnection),
          },
          label: item.label ?? undefined,
          partnerGoalExternalRef: item.key,
        });
      }
      return acc;
    }, []);

    return {
      get: partnerGoalExternalRef => goalObjectives.find(g => g.partnerGoalExternalRef === partnerGoalExternalRef),
    };
  }, [
    goalObjectivesContent.data?.all_goal_objectives?.items,
    goalObjectivesContent.error,
    goalObjectivesContent.loading,
  ]);

  return {
    data,
    error: goalObjectivesContent.error,
    loading: goalObjectivesContent.loading,
  };
};
