import { createLink } from 'apollo-absinthe-upload-link';
import fetch from 'cross-fetch';

import { CLIENT_NAME, SYMPHONY_UPLOAD_CLIENT_NAME } from './src/constants';

import {
  ApolloLink,
  ErrorResponse,
  from,
  HttpLink,
  HttpLinkConfig,
  MockLink,
  onError,
  setContext,
  TypePolicies,
} from '~/utils/apollo-client';

/**
 * PACKAGE ENTRY
 * export public methods and classes from this file
 *
 * For example:
 * export { default, NamedExport1, NamedExport2 } from './some-file';
 */
export * from './src/types';
export * from './src/utils';
export * from './src/kyc';
export * from './src/hooks';
export { toDateString as toSymphonyDate } from './src/utils';
export { toDateTimeString as toSymphonyDateTime } from './src/utils';

export const getSymphonyHttpLinkConfig = (
  uri: string,
  jwt?: string | null,
  onLogout?: () => void,
  onGenericError?: (error: ErrorResponse) => void,
) => {
  const symphonyAuthLink = setContext((_, { headers }) => {
    return {
      headers: { ...headers, authorization: jwt ? `Bearer ${jwt}` : '' },
    };
  });

  const errorLink = onError(error => {
    if (
      (error.networkError as any)?.result?.status === 403 &&
      (error.networkError as any)?.result?.code === 'AUTHENTICATION_INVALID_UNAUTHORIZED'
    ) {
      onLogout?.();
    } else if (onGenericError) {
      onGenericError(error);
    }
  });

  return {
    name: CLIENT_NAME,
    link: from([
      errorLink,
      symphonyAuthLink.concat(
        new HttpLink({
          uri,
          fetch,
        }),
      ),
    ]),
  };
};

export const getSymphonyUploadHttpLinkConfig = (uri: string, jwt?: string | null, onLogout?: () => void) => {
  const symphonyAuthLink = setContext((_, { headers }) => {
    return {
      headers: { ...headers, authorization: jwt ? `Bearer ${jwt}` : '' },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if (
      (networkError as any)?.result?.status === 403 &&
      (networkError as any)?.result?.code === 'AUTHENTICATION_INVALID_UNAUTHORIZED'
    ) {
      onLogout?.();
    }
  });

  const uploadLink = createLink({
    uri,
  });

  return {
    name: SYMPHONY_UPLOAD_CLIENT_NAME,
    link: from([errorLink, symphonyAuthLink.concat(uploadLink)]),
  };
};

export const getSymphonyMockLinkConfig = (
  mockLink: MockLink,
  onMissingMock?: (errorMsg: string) => void,
): HttpLinkConfig => ({
  name: CLIENT_NAME,
  link: ApolloLink.concat(
    onError(({ networkError }) => {
      if (networkError && networkError.message.includes('No more mocked responses')) {
        onMissingMock?.(networkError.message);
      }
    }),
    mockLink,
  ),
});

export const cacheTypePolicies: TypePolicies = {
  Query: {
    fields: {
      client: {
        /**
         * merge two client objects together when one already exists in the cache
         * see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
         */
        merge: true,
      },
    },
  },
  FinancialAccount: {
    // use FinancialAccount.id as unique id, but fallback to ManagedProduct.id if FinancialAccount.id is null
    keyFields: storeObject => {
      const financialAccount = storeObject as {
        __typename: string;
        accountNumber: string;
        id?: string;
        products?: Array<{ __typename: 'ManagedProduct' | string; id: string }>;
        routingNumber: string;
      };
      const { accountNumber, routingNumber } = financialAccount;
      const id =
        financialAccount.id ??
        financialAccount.products?.find(product => product.__typename === 'ManagedProduct')?.id ??
        (accountNumber && routingNumber ? `${routingNumber}-${accountNumber}` : null);
      return `${financialAccount.__typename}:${id}`;
    },
  },
  ManagedProduct: {
    keyFields: storeObject => {
      const managedProduct = storeObject as {
        __typename: string;
        financialAccountAssociationId?: string;
        id: string;
      };
      const id = managedProduct.financialAccountAssociationId
        ? `${managedProduct.financialAccountAssociationId}-${managedProduct.id}`
        : managedProduct.id;
      return `${managedProduct.__typename}:${id}`;
    },
    fields: {
      billingData: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
};
