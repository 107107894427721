import { addFlag, addFlagVariables } from './__generated__/mutation.v2';
import * as mutation from './mutation.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useAddFlag = (
  options?: MutationHookOptions<addFlag, addFlagVariables>,
): MutationTuple<addFlag, addFlagVariables> => {
  return useSymphonyMutation(mutation.addFlag, options);
};
