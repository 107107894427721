import { useEffect, useState } from 'react';

import { BillingRatesSection, useGetBillingRatesContent } from '../contentstack';
import { useGetBillingRates } from '../symphony';

import { ContentOptions } from '~/utils/contentstack';
import { formatPercentage } from '~/utils/format';
import { AsyncResult } from '~/utils/types';

export enum BillingConfig {
  ADVISOR_FEE = 1,
  MANAGEMENT_FEE = 2,
  SPONSOR_FEE = 3,
}

interface BillingDataVariables {
  contentOptions: ContentOptions;
  managedProductId: string;
}

interface BillingRateData {
  advisorFeeData: string;
  estimatedAnnualRateData: string;
  managementFeeData: string;
  sponsorFeeData: string;
}

interface BillingRateContent {
  advisorFeeLabel: string;
  estimatedAnnualRateLabel: string;
  managementFeeLabel: string;
  sponsorFeeLabel: string;
  title: string;
}

export interface BillingRatesComponentData {
  billingRateContent: BillingRateContent;
  billingRateData: BillingRateData;
}

export const useBillingRates = ({
  contentOptions,
  managedProductId,
}: BillingDataVariables): AsyncResult<BillingRatesComponentData> => {
  const [state, setState] = useState<AsyncResult<BillingRatesComponentData>>({ loading: true });

  const { data: billingRatesData, loading: billingRatesLoading, error: billingRatesError } = useGetBillingRates({
    variables: {
      managedProductId,
    },
  });
  const {
    data: billingRatesContentData,
    loading: billingRatesContentLoading,
    error: billingRatesContentError,
  } = useGetBillingRatesContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (billingRatesContentError || billingRatesError) {
      setState({
        error: billingRatesContentError || billingRatesError,
        loading: false,
      });
    }
  }, [billingRatesContentError, billingRatesError]);

  useEffect(() => {
    if (!billingRatesContentLoading && billingRatesContentData && !billingRatesLoading && billingRatesData) {
      const billingRatesSectionContent = billingRatesContentData.all_account_details?.items?.[0]?.sections?.find(
        (section): section is BillingRatesSection => section?.__typename === 'AccountDetailsSectionsBillingRates',
      )?.billing_rates;

      const getBillingRate = (rateConfigId: number): string => {
        return formatPercentage(
          Number(
            billingRatesData?.managedProduct?.billingDataV2?.finalizedBillingRateData?.blendedBillingRateComponent?.find(
              item => item?.configId === rateConfigId,
            )?.rate ?? 0,
          ),
        );
      };
      const getBillingContentLabel = (rateConfigId: number): string => {
        return (
          billingRatesSectionContent?.billing_rate_config?.find(item => item?.config_id === rateConfigId)?.label ?? ''
        );
      };

      const advisorFeeData = getBillingRate(BillingConfig.ADVISOR_FEE);
      const managementFeeData = getBillingRate(BillingConfig.MANAGEMENT_FEE);
      const sponsorFeeData = getBillingRate(BillingConfig.SPONSOR_FEE);
      const estimatedAnnualRateData = `${(
        parseFloat(advisorFeeData) +
        parseFloat(managementFeeData) +
        parseFloat(sponsorFeeData)
      ).toFixed(2)}%`;
      const advisorFeeLabel = getBillingContentLabel(BillingConfig.ADVISOR_FEE);
      const managementFeeLabel = getBillingContentLabel(BillingConfig.MANAGEMENT_FEE);
      const sponsorFeeLabel = getBillingContentLabel(BillingConfig.SPONSOR_FEE);
      const billingRateContent = {
        advisorFeeLabel,
        estimatedAnnualRateLabel: billingRatesSectionContent?.estimated_annual_fee ?? '',
        managementFeeLabel,
        sponsorFeeLabel,
        title: billingRatesSectionContent?.title ?? '',
      };
      const billingRateData = {
        advisorFeeData,
        estimatedAnnualRateData,
        managementFeeData,
        sponsorFeeData,
      };
      setState({
        data: { billingRateContent, billingRateData },
        loading: false,
      });
    }
  }, [billingRatesContentLoading, billingRatesContentData, billingRatesLoading, billingRatesData]);

  return state;
};
