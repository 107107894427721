import React from 'react';

import { TransferStatusFilter } from '../../types';

import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';

export type DepositsStatusFilterProps = {
  currentStatusFilter: TransferStatusFilter;
  displayStrings: {
    statusFilter: Record<TransferStatusFilter, string>;
  };
  onChange: (newStatusFilter: TransferStatusFilter) => void;
};

export const DepositsStatusFilter: React.FC<DepositsStatusFilterProps> = ({
  currentStatusFilter,
  displayStrings: { statusFilter },
  onChange,
}) => {
  const items: DropdownItem[] = [
    {
      value: TransferStatusFilter.PENDING_IN_PROGRESS,
      label: statusFilter[TransferStatusFilter.PENDING_IN_PROGRESS],
    },
    {
      value: TransferStatusFilter.PENDING,
      label: statusFilter[TransferStatusFilter.PENDING],
    },
    {
      value: TransferStatusFilter.PENDING_CANCELLATION,
      label: statusFilter[TransferStatusFilter.PENDING_CANCELLATION],
    },
    {
      value: TransferStatusFilter.IN_PROGRESS,
      label: statusFilter[TransferStatusFilter.IN_PROGRESS],
    },
    {
      value: TransferStatusFilter.COMPLETED,
      label: statusFilter[TransferStatusFilter.COMPLETED],
    },
    {
      value: TransferStatusFilter.CANCELLED,
      label: statusFilter[TransferStatusFilter.CANCELLED],
    },
  ];
  return (
    <Dropdown
      dataQa="deposits-status-filter"
      items={items}
      onChange={e => onChange(e.target.value as TransferStatusFilter)}
      value={currentStatusFilter}
      width="160px"
    />
  );
};
