import {
  GetClientExternalIdentifiers,
  GetClientExternalIdentifiersVariables,
  GetClientInfo,
  GetClientInfoVariables,
  GetClientPartyIdByExternalPartyId,
  GetClientPartyIdByExternalPartyIdVariables,
  GetPartyAttributes,
  GetPartyAttributes_client_party_attributes,
  GetPartyAttributes_client_party_attributes_StringAttribute,
  GetPartyAttributesVariables,
  GetPartyPrimaryFAInfo,
  GetPartyPrimaryFAInfoVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type PartyAttribute = GetPartyAttributes_client_party_attributes;
export type StringAttribute = GetPartyAttributes_client_party_attributes_StringAttribute;

export const isStringAttribute = (attribute: PartyAttribute): attribute is StringAttribute =>
  attribute.__typename === 'StringAttribute';

export const useGetClientInfo = (
  options?: QueryHookOptions<GetClientInfo, GetClientInfoVariables>,
): QueryResult<GetClientInfo, GetClientInfoVariables> => {
  return useSymphonyQuery(queries.GetClientInfo, options);
};

export const useGetClientExternalIdentifiers = (
  options?: QueryHookOptions<GetClientExternalIdentifiers, GetClientExternalIdentifiersVariables>,
): QueryResult<GetClientExternalIdentifiers, GetClientExternalIdentifiersVariables> => {
  return useSymphonyQuery(queries.GetClientExternalIdentifiers, options);
};

export const useGetPartyAttributes = (
  options?: QueryHookOptions<GetPartyAttributes, GetPartyAttributesVariables>,
): QueryResult<GetPartyAttributes, GetPartyAttributesVariables> => {
  return useSymphonyQuery(queries.GetPartyAttributes, options);
};

export const useGetPartyPrimaryFAInfo = (
  options?: QueryHookOptions<GetPartyPrimaryFAInfo, GetPartyPrimaryFAInfoVariables>,
): QueryResult<GetPartyPrimaryFAInfo, GetPartyPrimaryFAInfoVariables> => {
  return useSymphonyQuery(queries.GetPartyPrimaryFAInfo, options);
};

export const useGetClientPartyIdByExternalPartyId = (
  options?: QueryHookOptions<GetClientPartyIdByExternalPartyId, GetClientPartyIdByExternalPartyIdVariables>,
): QueryResult<GetClientPartyIdByExternalPartyId, GetClientPartyIdByExternalPartyIdVariables> => {
  return useSymphonyQuery(queries.GetClientPartyIdByExternalPartyId, options);
};
