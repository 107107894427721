import React, { useMemo, useState } from 'react';

import { Autocomplete, Checkbox } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';
import { DocusignStatus } from '~/containers/OpsDashboard/DocusignTab/types';

export type DocusignStatusFilterProps = {
  currentStatus: DocusignStatus[];
  displayStrings: {
    status: Record<DocusignStatus, string>;
  };
  onChange: (status: DocusignStatus[]) => void;
};

export const DocusignStatusFilter: React.FC<DocusignStatusFilterProps> = ({
  currentStatus,
  displayStrings: { status },
  onChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const items: { label: string; value: DocusignStatus }[] = useMemo(() => {
    return [
      {
        value: DocusignStatus.ALL,
        label: status[DocusignStatus.ALL],
      },
      {
        value: DocusignStatus.SUCCEEDED,
        label: status[DocusignStatus.SUCCEEDED],
      },
      {
        value: DocusignStatus.PENDING,
        label: status[DocusignStatus.PENDING],
      },
      {
        value: DocusignStatus.DECLINED,
        label: status[DocusignStatus.DECLINED],
      },
      {
        value: DocusignStatus.FAILED,
        label: status[DocusignStatus.FAILED],
      },
    ];
  }, [status]);

  return (
    <Autocomplete
      disableClearable
      disableCloseOnSelect
      disablePortal
      getOptionLabel={option => option.label}
      id="docusign-status-filter"
      multiple
      onChange={(_, selectedValues) => {
        onChange(selectedValues.map(item => item.value));
      }}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      options={items}
      renderInput={params => {
        return (
          <TextField
            {...params}
            onBlur={() => {
              setOpen(false);
            }}
            onFocus={() => {
              setOpen(true);
            }}
            sx={{
              ...{
                '& .MuiOutlinedInput-input': { position: 'absolute' }, // needed to avoid multiline dropdown
              },
            }}
          />
        );
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} color="primary" />
          {option.label}
        </li>
      )}
      renderTags={tagValue => {
        return (
          <Typography
            color="inherit"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              p: 1,
              whiteSpace: 'nowrap',
            }}
          >
            {tagValue.map(tag => tag.label).join(', ')}
          </Typography>
        );
      }}
      sx={{ width: '160px' }}
      value={items.filter(item => currentStatus.includes(item.value))}
    />
  );
};
