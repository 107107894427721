import { GetPrimaryAdvisor, GetPrimaryAdvisorVariables } from './__generated__/getPrimaryAdvisor.v2';
import * as primaryAdvisorQuery from './getPrimaryAdvisor.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetPrimaryAdvisor = (
  options?: QueryHookOptions<GetPrimaryAdvisor, GetPrimaryAdvisorVariables>,
): QueryResult<GetPrimaryAdvisor, GetPrimaryAdvisorVariables> =>
  useSymphonyQuery(primaryAdvisorQuery.GetPrimaryAdvisor, options);
