import React, { useEffect, useState } from 'react';

import { AllCashImage, TargetAllocationImage } from '.';
import { zipperStrings } from './utils';

import { AllowedRiskToleranceChange, CompactVariant, RiskBand, RiskSpectrum } from '~/components/RiskSpectrum';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { AlertTitle, Grid, Skeleton, TypographyVariant, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { LatestQuestionnaireRecommendationSet } from '~/hooks/questionnaire/symphony';
import { useIsValidRiskScore } from '~/hooks/questionnaire/useIsValidRiskScore';
import { useRecommendedProduct } from '~/hooks/questionnaire/useRecommendedProduct';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export { zipperStrings } from './utils';

export interface Props {
  allowedRiskToleranceChange?: AllowedRiskToleranceChange;
  contentOptions: ContentOptions;
  currentRiskScore?: number;
  dataQa?: string;
  editMode?: boolean;
  fromEditAccountModal?: boolean;
  managedProductId: string;
  onChange?: (newBand: number, riskScoreCurrent: number) => void;
  partyId: string;
  recommendedProduct: LatestQuestionnaireRecommendationSet;
  riskBankLabelTypographyComponent?: TypographyVariant;
  riskScoreUser?: number;
  setDisableNext?: (disabled: boolean) => void;
  showDetails?: boolean;
  showPortfolioBands?: boolean;
  validateRiskScore?: boolean;
}

export { AllCashImage } from './AllCashImage';
export { TargetAllocationImage } from './TargetAllocationImage';

export const RiskToleranceResult: React.FC<Props> = ({
  allowedRiskToleranceChange,
  currentRiskScore,
  dataQa = 'risk-tolerance-result',
  editMode,
  onChange,
  contentOptions,
  managedProductId,
  partyId,
  recommendedProduct,
  riskScoreUser,
  fromEditAccountModal,
  riskBankLabelTypographyComponent,
  showDetails,
  showPortfolioBands = false,
  validateRiskScore,
  setDisableNext,
}) => {
  const {
    sfRiskToleranceResult: { styles: sfRiskToleranceResultStyle, root: sfRiskToleranceResultRoot, typographyVariants },
  } = useTheme();
  const [riskBandDetails, setRiskBandDetails] = useState<RiskBand | null>();
  const [showInvalidScoreAlert, setShowInvalidScoreAlert] = useState(false);
  const isMediumScreen = useIsMediumScreen();
  const {
    data: recommendedProductData,
    loading: recommendedProductLoading,
    error: recommendedProductError,
  } = useRecommendedProduct({
    contentOptions,
    currentRiskScore,
    recommendedProduct,
    riskScoreUser,
    managedProductId,
    partyId,
    showPortfolioBands,
  });

  const { isValidRiskScore, loading: isValidRiskScoreLoading, error: isValidRiskScoreError } = useIsValidRiskScore({
    managedProductId,
    partyId,
    recommendedProduct,
    skip: !validateRiskScore,
  });

  const handleOnChange = (bandIndex: number) => {
    if (recommendedProductData && recommendedProduct.riskScore) {
      const bands = recommendedProductData.showPortfolioBands
        ? recommendedProductData.portfolioBands
        : recommendedProductData.riskBands;
      let updatedRiskScore =
        bands[bandIndex].riskScoreFloor +
        (recommendedProduct.riskScore - bands[recommendedProductData.riskBandNew].riskScoreFloor);
      // For not evenly spaced out risk bands the updated risk score can be greater than the selected band riskScoreCeiling.
      // If the updated risk score is more than the band ceiling then the updatedRiskScore will be middle of the risk band
      // ceiling and floor.
      if (updatedRiskScore > bands[bandIndex].riskScoreCeiling) {
        updatedRiskScore = Math.floor((bands[bandIndex].riskScoreCeiling + bands[bandIndex].riskScoreFloor) / 2);
      }
      setRiskBandDetails(
        recommendedProductData.showPortfolioBands
          ? recommendedProductData.portfolioBands[bandIndex]
          : recommendedProductData.riskBands[bandIndex],
      );
      onChange?.(updatedRiskScore, recommendedProduct.riskScore);
    }
  };

  useEffect(() => {
    if (recommendedProductData) {
      const selectedRiskBand =
        recommendedProductData.riskBandUser ??
        recommendedProductData.riskBandNew ??
        recommendedProductData.riskBandCurrent;
      setRiskBandDetails(
        recommendedProductData.showPortfolioBands
          ? recommendedProductData.portfolioBands[selectedRiskBand]
          : recommendedProductData.riskBands[selectedRiskBand],
      );
    }
  }, [recommendedProductData]);

  useEffect(() => {
    if (!isValidRiskScoreLoading) {
      const isInvalid = !!validateRiskScore && !isValidRiskScore;
      setShowInvalidScoreAlert(isInvalid);
      setDisableNext?.(isInvalid);
    }
  }, [isValidRiskScore, isValidRiskScoreLoading, setDisableNext, validateRiskScore]);

  const showExtraDetails = !!showDetails && !editMode;
  const loading = recommendedProductLoading || isValidRiskScoreLoading;
  const error = recommendedProductError ?? isValidRiskScoreError;
  return (
    <Grid container data-qa={dataQa} rowSpacing={2} sx={{ ...sfRiskToleranceResultRoot }}>
      {loading && <Skeleton />}
      {error && <Alert contentOptions={contentOptions} error={error} severity="error" />}
      {!loading && !error && (
        <>
          {!showInvalidScoreAlert && (
            <>
              {showExtraDetails && (
                <Grid item xs={12}>
                  <Typography align="center" component="h2" sx={{ color: 'text.secondary' }} variant="subtitle2">
                    {recommendedProductData?.cms.headings?.main}
                  </Typography>
                </Grid>
              )}
              <Grid item textAlign={showExtraDetails ? 'center' : 'left'} xs={12}>
                <Typography
                  component={riskBankLabelTypographyComponent ?? undefined}
                  data-qa={`${dataQa}-label`}
                  sx={{ color: sfRiskToleranceResultStyle.bandHeadingColor }}
                  variant={typographyVariants?.bandHeading}
                >
                  {riskBandDetails?.label}
                </Typography>

                <RteContent
                  data={riskBandDetails?.description ?? ''}
                  data-qa={`${dataQa}-description`}
                  sx={{ pt: 1 }}
                />
              </Grid>
            </>
          )}

          {showExtraDetails && (
            <>
              <Grid item mb={{ md: 2 }} xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                data-qa={`${dataQa}-attributes`}
                direction={{ xs: 'column', md: 'row' }}
                item
                justifyContent="center"
                spacing={{ xs: 2, md: 10 }}
                textAlign="center"
              >
                <Grid item>
                  <Typography component="h3" sx={{ color: 'text.secondary' }} variant="subtitle2">
                    {recommendedProductData?.attributes?.timeHorizon?.label}
                  </Typography>
                  <Typography
                    aria-label={recommendedProductData?.attributes?.timeHorizon?.ariaLabel}
                    data-qa={`${dataQa}-attributes-timeHorizon`}
                  >
                    {recommendedProductData?.attributes?.timeHorizon?.value}
                  </Typography>
                </Grid>
                {!isMediumScreen && (
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                )}
                <Grid item>
                  <Typography component="h3" sx={{ color: 'text.secondary' }} variant="subtitle2">
                    {recommendedProductData?.attributes?.objective?.label}
                  </Typography>
                  <Typography data-qa={`${dataQa}-attributes-objective`}>
                    {recommendedProductData?.attributes?.objective?.value}
                  </Typography>
                </Grid>
                {!isMediumScreen && (
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                )}
                <Grid item>
                  <Typography component="h3" sx={{ color: 'text.secondary' }} variant="subtitle2">
                    {recommendedProductData?.attributes?.riskProfile?.label}
                  </Typography>
                  <Typography data-qa={`${dataQa}-attributes-riskProfile`}>
                    {recommendedProductData?.attributes?.riskProfile?.value}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item mt={{ md: 2 }} xs={12}>
                <Divider />
              </Grid>
            </>
          )}

          <Grid container direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ pt: 3 }}>
            {!showInvalidScoreAlert ? (
              <>
                {showExtraDetails && !!riskBandDetails?.targetAllocations && (
                  <Grid item md={4} xs>
                    <Grid container item justifyContent={{ xs: 'center', md: 'left' }} spacing={1}>
                      <Grid item>
                        <Typography component="h3" variant="subtitle2">
                          {recommendedProductData?.cms.target_allocations?.heading}
                        </Typography>
                      </Grid>
                      <Grid alignItems="center" container direction={{ xs: 'column', md: 'row' }} item spacing={2}>
                        <Grid item>
                          {(recommendedProductData?.riskBandUser ??
                            recommendedProductData?.riskBandNew ??
                            recommendedProductData?.riskBandCurrent) === 0 ? (
                            <AllCashImage sx={sfRiskToleranceResultStyle.iconSize} />
                          ) : (
                            <TargetAllocationImage sx={sfRiskToleranceResultStyle.iconSize} />
                          )}
                        </Grid>
                        <Grid
                          aria-label={zipperStrings(
                            recommendedProductData?.cms.target_allocations?.label ?? '',
                            riskBandDetails.targetAllocations,
                          )}
                          item
                          textAlign="center"
                        >
                          <Typography
                            component="p"
                            data-qa={`${dataQa}-target-allocations`}
                            sx={{ mb: 1 }}
                            variant="h5"
                          >
                            {riskBandDetails.targetAllocations.replace(/\//g, ' / ')}
                          </Typography>
                          <Typography sx={{ color: 'text.secondary' }} variant="caption">
                            {recommendedProductData?.cms.target_allocations?.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item textAlign={{ xs: 'center', md: 'left' }} xs>
                  {showExtraDetails && (
                    <Typography component="h3" mt={{ xs: 2, md: 0 }} variant="subtitle2">
                      {recommendedProductData?.cms.headings?.recommendation}
                    </Typography>
                  )}
                  {recommendedProductData && (
                    <RiskSpectrum
                      allowedRiskToleranceChange={allowedRiskToleranceChange}
                      contentOptions={contentOptions}
                      editMode={editMode}
                      fromEditAccountModal={fromEditAccountModal}
                      onChange={handleOnChange}
                      {...recommendedProductData}
                      compact={isMediumScreen ? CompactVariant.Mobile : undefined}
                      diversificationBlockContent={recommendedProductData.cms.diversification_block}
                      riskBandHeight={isMediumScreen ? 6 : undefined}
                      riskBandHeightPrimary={isMediumScreen ? 21 : undefined}
                    />
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs>
                <Alert autoFocus data-qa={`${dataQa}-invalid-risk-score-alert`} severity="error">
                  <AlertTitle>{recommendedProductData?.cms.invalid_risk_score?.alert_title}</AlertTitle>
                  <RteContent
                    config={{
                      riskProfile: recommendedProductData?.attributes?.riskProfile?.value,
                      objective: recommendedProductData?.attributes?.objective?.value,
                    }}
                    data={
                      recommendedProductData?.cms.invalid_risk_score?.alert_message ??
                      'MISSING INVALID RISK SCORE ALERT MESSAGE'
                    }
                  />
                </Alert>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
