import { WithdrawFundsDatepicker } from '../utils/datepicker-helper';

import { isAmountContent, isBankAccountContent, isFrequencyContent, isRaiseCashContent } from './contentstack';
import {
  GetWithdrawFundsContent,
  GetWithdrawFundsContent_all_withdraw_funds_modal,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_error_messages_error,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_frequency_dropdown_values_group,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_tax_withholding_playbackConnection_edges_node,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_tax_withholdingConnection_edges_node,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_tax_withholdingConnection_edges_node_WithdrawFundsModalTaxWithholdingRetirement_allocation_bar_labels,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_tax_withholdingConnection_edges_node_WithdrawFundsModalTaxWithholdingRetirement_soft_block_client_age,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_withdraw_blocked,
  GetWithdrawFundsContent_all_withdraw_funds_modal_items_withdraw_blocked_imageConnection,
} from './contentstack/__generated__/query.v2';

import { ManagedProductType, TransferFrequency } from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Locale } from '~/utils/contentstack';
import { getDaysOfWeekOptions } from '~/utils/dropdown';

export type ContentstackWithdrawFunds = GetWithdrawFundsContent_all_withdraw_funds_modal;
export type ContentstackwithdrawBlocked = GetWithdrawFundsContent_all_withdraw_funds_modal_items_withdraw_blocked | null;
export type ImageConnection = GetWithdrawFundsContent_all_withdraw_funds_modal_items_withdraw_blocked_imageConnection | null;
export type TaxWithholdingFormContent =
  | GetWithdrawFundsContent_all_withdraw_funds_modal_items_tax_withholdingConnection_edges_node
  | null
  | undefined;
export type TaxWithholdingPlaybackContent = GetWithdrawFundsContent_all_withdraw_funds_modal_items_tax_withholding_playbackConnection_edges_node | null;
export type TaxWithholdingAllocationBarContent = GetWithdrawFundsContent_all_withdraw_funds_modal_items_tax_withholdingConnection_edges_node_WithdrawFundsModalTaxWithholdingRetirement_allocation_bar_labels;
export type WithdrawFundsAlocationBarContent = GetWithdrawFundsContent_all_withdraw_funds_modal_items_tax_withholdingConnection_edges_node_WithdrawFundsModalTaxWithholdingRetirement_allocation_bar_labels;
export type FrequencyDropdownContent = GetWithdrawFundsContent_all_withdraw_funds_modal_items_frequency_dropdown_values_group;
export type SoftBlockClientAge = GetWithdrawFundsContent_all_withdraw_funds_modal_items_tax_withholdingConnection_edges_node_WithdrawFundsModalTaxWithholdingRetirement_soft_block_client_age;
export type ErrorMessages =
  | (GetWithdrawFundsContent_all_withdraw_funds_modal_items_error_messages_error | null)[]
  | null
  | undefined;

export type Weekday = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY';

export interface Weekdays {
  FRIDAY: string;
  MONDAY: string;
  THURSDAY: string;
  TUESDAY: string;
  WEDNESDAY: string;
}

export interface WithdrawFundsModalFrequencyContent {
  ctaLater: string;
  ctaNow: string;
  datepicker: WithdrawFundsDatepicker;
  frequencyDropdownLabel: string;
  frequencyDropdownOptions: DropdownItem[];
  monthlyDropdownLabel: string;
  weekdayDropdown: {
    options: DropdownItem[];
  };
  weekdayDropdownLabel: string;
}

export interface WithdrawFundsModalAccountContent {
  bankAccountLabel: string;
  noBankAccountsError: string;
  noBankAccountsMessage: string;
}

export interface WithdrawFundsModalAmountContent {
  maxAllowedAmount: number;
  maxAllowedAmountError: string;
  minAllowedAmount: number;
  missingWithdrawalAmountError: string;
  negativeWithdrawalAmountError: string;
  overdrawnMinimumAmountError: string;
  overdrawnWithdrawalAmountError: string;
  placeholder: string;
  withdrawalAmountLabel: string;
}

export interface WithdrawFundsModalContent {
  account: WithdrawFundsModalAccountContent;
  amount: WithdrawFundsModalAmountContent;
  availableFunds: string;
  availableFundsTooltip: string;
  backCta?: string;
  disclaimer: string;
  errorMessages?: ErrorMessages;
  feedbackMessage: string;
  frequency: WithdrawFundsModalFrequencyContent;
  heading: string;
  linkBankAccount: string;
  linkPlaidBankAccount: string;
  modalInstructions: string;
  nextCta?: string;
  partner: string;
  primaryCta: string;
  processingMessage: string;
  programName: string;
  retirement_account_withdrawal_enabled: boolean;
  secondaryCta: string;
  serverErrorMessage: string;
  taxWithholdingFormContent: TaxWithholdingFormContent;
  taxWithholdingPlayback: TaxWithholdingPlaybackContent;
  title: string;
  verificationWarning: string;
  withdrawBlocked?: ContentstackwithdrawBlocked;
}

export interface WithdrawFundsModalRaiseCashContent {
  heading: string;
  notes: string;
  primary_cta: string;
  secondary_cta: string;
  title: string;
}

export const contentStackValueNotFound = 'VALUE NOT FOUND';

const amountContentNotFound: WithdrawFundsModalAmountContent = {
  withdrawalAmountLabel: contentStackValueNotFound,
  maxAllowedAmount: Number.MAX_VALUE,
  minAllowedAmount: Number.MIN_VALUE,
  placeholder: contentStackValueNotFound,
  maxAllowedAmountError: contentStackValueNotFound,
  overdrawnWithdrawalAmountError: contentStackValueNotFound,
  overdrawnMinimumAmountError: contentStackValueNotFound,
  negativeWithdrawalAmountError: contentStackValueNotFound,
  missingWithdrawalAmountError: contentStackValueNotFound,
};

const accountContentNotFound: WithdrawFundsModalAccountContent = {
  noBankAccountsError: contentStackValueNotFound,
  bankAccountLabel: contentStackValueNotFound,
  noBankAccountsMessage: contentStackValueNotFound,
};

const frequencyContentNotFound: WithdrawFundsModalFrequencyContent = {
  datepicker: {
    label: contentStackValueNotFound,
    error: contentStackValueNotFound,
    invalidDateError: contentStackValueNotFound,
    maxDateError: contentStackValueNotFound,
    marketHolidayError: contentStackValueNotFound,
    pastDateError: contentStackValueNotFound,
    retirementAccountDateError: contentStackValueNotFound,
  },
  monthlyDropdownLabel: contentStackValueNotFound,
  weekdayDropdownLabel: contentStackValueNotFound,
  frequencyDropdownLabel: contentStackValueNotFound,
  frequencyDropdownOptions: [
    { value: TransferFrequency.WEEKLY, label: contentStackValueNotFound },
    { value: TransferFrequency.MONTHLY, label: contentStackValueNotFound },
  ],
  weekdayDropdown: {
    options: getDaysOfWeekOptions(),
  },
  ctaNow: contentStackValueNotFound,
  ctaLater: contentStackValueNotFound,
};

export const contentStackNotFound: WithdrawFundsModalContent = {
  partner: contentStackValueNotFound,
  programName: contentStackValueNotFound,
  title: contentStackValueNotFound,
  heading: contentStackValueNotFound,
  availableFunds: contentStackValueNotFound,
  availableFundsTooltip: contentStackValueNotFound,
  primaryCta: contentStackValueNotFound,
  secondaryCta: contentStackValueNotFound,
  frequency: frequencyContentNotFound,
  account: accountContentNotFound,
  amount: amountContentNotFound,
  modalInstructions: contentStackValueNotFound,
  serverErrorMessage: contentStackValueNotFound,
  linkBankAccount: contentStackValueNotFound,
  linkPlaidBankAccount: contentStackValueNotFound,
  processingMessage: '',
  feedbackMessage: '',
  nextCta: contentStackValueNotFound,
  backCta: contentStackValueNotFound,
  taxWithholdingFormContent: null,
  retirement_account_withdrawal_enabled: false, // TODO: Removed after DA2-2903 is done
  taxWithholdingPlayback: null,
  verificationWarning: contentStackValueNotFound,
  errorMessages: [],
  disclaimer: '',
};
const getFrequencyContent = (
  availableTransferFrequencies: TransferFrequency[],
  locale: Locale,
  node?: ContentstackWithdrawFunds | null,
): WithdrawFundsModalFrequencyContent => {
  const items = node?.items?.[0];

  if (items) {
    const frequencies = items.frequency_dropdown_values?.group;
    const field = items.fields?.find(isFrequencyContent);
    if (field) {
      const frequency = field.frequency;
      return {
        datepicker: {
          label: frequency?.datepicker?.datepicker_label ?? contentStackValueNotFound,
          error: frequency?.datepicker?.datepicker_error ?? contentStackValueNotFound,
          invalidDateError: frequency?.datepicker?.datepicker_invalid_date ?? contentStackValueNotFound,
          pastDateError: frequency?.datepicker?.datepicker_past_date_error ?? contentStackValueNotFound,
          marketHolidayError: frequency?.datepicker?.datepicker_market_holiday_error ?? contentStackValueNotFound,
          maxDateError: frequency?.datepicker?.datepicker_max_date_error ?? contentStackValueNotFound,
          retirementAccountDateError:
            frequency?.datepicker?.datepicker_retirement_account_date_error ?? contentStackValueNotFound,
        },
        monthlyDropdownLabel:
          frequency?.monthly_withdrawal_frequency?.monthly_dropdown_label ?? contentStackValueNotFound,
        weekdayDropdownLabel:
          frequency?.weekly_withdrawal_frequency?.weekday_dropdown_label ?? contentStackValueNotFound,
        frequencyDropdownLabel: frequency?.time_period_cta?.frequency_dropdown_label ?? contentStackValueNotFound,
        frequencyDropdownOptions: availableTransferFrequencies
          .map(transferFrequency => {
            let label = '';
            switch (transferFrequency) {
              case TransferFrequency.WEEKLY:
                label = frequencies?.weekly ?? '';
                break;
              case TransferFrequency.MONTHLY:
                label = frequencies?.monthly ?? '';
                break;
            }
            return {
              value: transferFrequency,
              label,
            };
          })
          .filter(f => f.label), // Ensure display label is available.
        weekdayDropdown: {
          options: getDaysOfWeekOptions(locale),
        },
        ctaNow: frequency?.time_period_cta?.now ?? contentStackValueNotFound,
        ctaLater: frequency?.time_period_cta?.later ?? contentStackValueNotFound,
      };
    }
  }
  return frequencyContentNotFound;
};

const getAccountContent = (node?: ContentstackWithdrawFunds | null): WithdrawFundsModalAccountContent => {
  const items = node?.items?.[0];
  if (items?.fields) {
    const field = items.fields.find(isBankAccountContent);
    if (field && field.bank_account) {
      const { bank_account_errors, bank_account_label } = field?.bank_account;
      return {
        noBankAccountsError: bank_account_errors?.no_eligible_account_error ?? contentStackValueNotFound,
        bankAccountLabel: bank_account_label ?? contentStackValueNotFound,
        noBankAccountsMessage: bank_account_errors?.no_bank_accounts_message ?? '',
      };
    }
  }
  return accountContentNotFound;
};

const getAmountContent = (node?: ContentstackWithdrawFunds | null): WithdrawFundsModalAmountContent => {
  const items = node?.items?.[0];
  if (items?.fields) {
    const field = items.fields.find(isAmountContent);
    if (field) {
      const { amount } = field;
      return {
        withdrawalAmountLabel: amount?.withdrawal_amount_label ?? contentStackValueNotFound,
        maxAllowedAmount: amount?.maximum_allowed_amount ?? Number.MAX_VALUE,
        minAllowedAmount: amount?.minimum_allowed_amount ?? Number.MIN_VALUE,
        placeholder: amount?.inputConnection?.edges?.[0]?.node?.placeholder ?? '',
        maxAllowedAmountError: amount?.amount_errors?.maximum_allowed_amount ?? contentStackValueNotFound,
        overdrawnWithdrawalAmountError: amount?.amount_errors?.overdrawn_withdrawal_amount ?? contentStackValueNotFound,
        overdrawnMinimumAmountError: amount?.amount_errors?.overdrawn_minimum_amount ?? contentStackValueNotFound,
        negativeWithdrawalAmountError: amount?.amount_errors?.negative_withdrawal_amount ?? contentStackValueNotFound,
        missingWithdrawalAmountError: amount?.amount_errors?.missing_withdrawal_amount ?? contentStackValueNotFound,
      };
    }
  }
  return amountContentNotFound;
};

const getRaiseCashContent = (
  fields?: GetWithdrawFundsContent_all_withdraw_funds_modal_items['fields'],
): WithdrawFundsModalRaiseCashContent => {
  const field = fields?.find(isRaiseCashContent);
  return {
    title: field?.raise_cash?.title ?? contentStackValueNotFound,
    heading: field?.raise_cash?.heading ?? contentStackValueNotFound,
    notes: field?.raise_cash?.notes ?? contentStackValueNotFound,
    primary_cta: field?.raise_cash?.primary_cta ?? contentStackValueNotFound,
    secondary_cta: field?.raise_cash?.secondary_cta ?? contentStackValueNotFound,
  };
};

export const getWithdrawFundsContent = ({
  availableTransferFrequencies,
  isRaiseCash,
  locale,
  managedProductType,
  node,
}: {
  availableTransferFrequencies: TransferFrequency[];
  isRaiseCash?: boolean;
  locale: Locale;
  managedProductType: ManagedProductType;
  node?: GetWithdrawFundsContent | null;
}): WithdrawFundsModalContent => {
  const items = node?.all_withdraw_funds_modal?.items?.[0];
  const raiseCashContent = getRaiseCashContent(node?.all_withdraw_funds_modal?.items?.[0]?.fields);

  if (items) {
    return {
      partner: items.partner_group?.partner ?? contentStackValueNotFound,
      title: (isRaiseCash ? raiseCashContent.title : items.modal_fields?.title) ?? contentStackValueNotFound,
      programName:
        node?.all_product_name?.items?.find(item => item?.key === managedProductType)?.text ||
        contentStackValueNotFound,
      heading: (isRaiseCash ? raiseCashContent.heading : items.heading_v2) ?? contentStackValueNotFound,
      availableFunds: items.available_funds_v2 ?? contentStackValueNotFound,
      availableFundsTooltip: items.available_funds_tooltip_v2 ?? contentStackValueNotFound,
      primaryCta:
        (isRaiseCash ? raiseCashContent.primary_cta : items.modal_fields?.primary_cta) ?? contentStackValueNotFound,
      secondaryCta:
        (isRaiseCash ? raiseCashContent.secondary_cta : items.modal_fields?.secondary_cta) ?? contentStackValueNotFound,
      frequency: getFrequencyContent(availableTransferFrequencies, locale, node?.all_withdraw_funds_modal),
      account: getAccountContent(node?.all_withdraw_funds_modal),
      amount: getAmountContent(node?.all_withdraw_funds_modal),
      modalInstructions: items.modal_instructions ?? contentStackValueNotFound,
      serverErrorMessage: items.server_error_message ?? contentStackValueNotFound,
      processingMessage: items.processing_message ?? '',
      feedbackMessage: items.feedback_message ?? '',
      withdrawBlocked: items.withdraw_blocked,
      linkBankAccount: items.link_bank_account ?? contentStackValueNotFound,
      linkPlaidBankAccount: items.link_plaid_bank_account ?? contentStackValueNotFound,
      backCta: items.other_ctas?.back_cta ?? '',
      nextCta: items.other_ctas?.next_cta ?? '',
      taxWithholdingFormContent: items.tax_withholdingConnection?.edges?.[0]?.node,
      retirement_account_withdrawal_enabled: !!items.retirement_account_withdrawal_enabled, // TODO: Remove after DA2-2903 is done
      taxWithholdingPlayback: items.tax_withholding_playbackConnection?.edges?.[0]?.node ?? null,
      verificationWarning: items.verification_warning ?? '',
      errorMessages: items.error_messages?.error,
      disclaimer: items.disclaimer ?? '',
    };
  }
  return contentStackNotFound;
};
