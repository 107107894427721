import { useEffect, useState } from 'react';

import { useGetBankVerificationContent } from '../contentstack';
import { getBankVerificationColumnValue, getBankVerificationLabelContent } from '../utils/mappers';
import {
  BankVerificationContentTabVariables,
  BankVerificationTabContent,
  BankVerificationTableColumn,
  sortFieldToSymphony,
} from '../utils/types';

import { AsyncResult } from '~/utils/types';

export const useGetBankVerificationTabContent = ({
  contentOptions,
  onSort,
  sortConfig,
}: BankVerificationContentTabVariables): AsyncResult<BankVerificationTabContent> => {
  const [state, setState] = useState<AsyncResult<BankVerificationTabContent>>({ loading: true });

  const { data: contentData, loading: contentLoading, error: contentError } = useGetBankVerificationContent({
    variables: contentOptions,
  });

  const bankVerificationContentItems = contentData?.all_bank_verification_table?.items?.[0];

  useEffect(() => {
    if (contentError) {
      setState({
        error: contentError,
        loading: false,
      });
    }
  }, [contentError]);

  useEffect(() => {
    if (!(contentLoading && contentError) && bankVerificationContentItems) {
      setState({
        data: {
          tableColumns: [
            BankVerificationTableColumn.ACCOUNT_NUMBER,
            BankVerificationTableColumn.CLIENT_NAME,
            BankVerificationTableColumn.DATE_CREATED,
            BankVerificationTableColumn.BANK_INFORMATION,
            BankVerificationTableColumn.STATUS,
            BankVerificationTableColumn.ACTIONS,
            BankVerificationTableColumn.NOTES,
          ].map(col => {
            return {
              title: getBankVerificationColumnValue(col, bankVerificationContentItems.columns ?? []),
              key: col.toString(),
              onSort: sortFieldToSymphony.get(col.toString()) ? onSort : undefined,
            };
          }),
          ...getBankVerificationLabelContent(bankVerificationContentItems.labels ?? []),
          statuses: (bankVerificationContentItems.status ?? []).map(v => ({
            key: v?.status_key ?? '',
            value: v?.status_value ?? '',
          })),
          statusFilters: (bankVerificationContentItems.status_filter ?? []).map(v => ({
            key: v?.status_filter_key ?? '',
            value: v?.status_filter_value ?? '',
          })),
        },
        loading: false,
      });
    }
  }, [contentLoading, contentError, bankVerificationContentItems, sortConfig]);
  return state;
};
