import { CashTransfersV2_multiCashTransfers_transfers_attributes } from '../../common/CashTransfer/symphony/__generated__/query.v2';
import {
  getBooleanAttributeValue,
  getDecimalAttributeValue,
  getStringAttributeValue,
} from '../../common/CashTransfer/utils';
import { TransferItem } from '../../types';

export const getTaxInformation = (
  attributes: CashTransfersV2_multiCashTransfers_transfers_attributes[],
): TransferItem['taxInformation'] => {
  const taxInformation = {
    distributionReason: getStringAttributeValue(attributes, 'DISTRIBUTION_REASON'),
    federalTax: getDecimalAttributeValue(attributes, 'FEDERAL_TAX_WITHHOLDING_PERCENTAGE'),
    grossUp: getBooleanAttributeValue(attributes, 'WITHDRAWAL_GROSS_UP'),
    stateTax: getDecimalAttributeValue(attributes, 'STATE_TAX_WITHHOLDING_PERCENTAGE'),
  };

  return Object.values(taxInformation).some(value => value !== undefined) ? taxInformation : undefined;
};
