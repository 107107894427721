import { useCallback, useMemo, useState } from 'react';

import { useGetActiveTradingSuspensionsTabContent } from '../contentstack';
import { useGetActiveTradingSuspensionsData } from '../symphony';

import {
  Content,
  getActiveTradingSuspensionsContent,
  getColumns,
  getFormattedRows,
  getIsOpsSuspensionAdded,
  getSuspensionRowData,
  SuspensionRowData,
} from './utils';

import { SuspensionType } from '~/__generated__';
import { TableColumn, TableData } from '~/components/ui/BasicTable';
import { useTheme } from '~/components/ui/mui';
import { useUpdateTradingSuspension } from '~/hooks/suspensions/symphony';
import { SuspensionTag } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface RemoveSuspensionModalData {
  suspensionDisplayData: SuspensionRowData;
  suspensionTag?: string | null;
  suspensionType?: SuspensionType;
}

interface Data {
  addSuspensionInProgress: boolean;
  columnsData: TableColumn[];
  content: Content;
  isOpsSuspensionAdded: boolean;
  isRefetchInProgress: boolean;
  onAddTradingSuspension: () => void;
  refetchActiveSuspensions: () => void;
  removeSuspensionModalData?: RemoveSuspensionModalData;
  suspensionData: SuspensionRowData[];
  tableData: TableData[];
}

const useActiveTradingSuspensionsData = (
  contentOptions: ContentOptions,
  managedProductId: string,
  onAddTradingSuspensionSuccess?: (message: string | undefined, refetchActiveSuspensions: () => void) => void,
  onAddTradingSuspensionFailure?: () => void,
  openRemoveTradingSuspensionModal?: () => void,
): AsyncResult<Data> => {
  const [addSuspensionInProgress, setAddSuspensionInProgress] = useState(false);
  const [isRefetchInProgress, setIsRefetchInProgress] = useState(false);
  const [removeSuspensionModalData, setRemoveSuspensionModalData] = useState<RemoveSuspensionModalData>();

  const {
    components: {
      sfActiveSuspensions: { removePartnerOpsSuspensionAllowed },
    },
  } = useCoreConfig();
  const {
    sfSuspension: {
      styles: { suspensionTagStyleMap },
    },
  } = useTheme();

  const { data: content, error: contentError, loading: contentLoading } = useGetActiveTradingSuspensionsTabContent({
    variables: contentOptions,
  });

  const { data, error, loading, refetch: refetchActiveTradingSuspensionsData } = useGetActiveTradingSuspensionsData({
    variables: { managedProductId },
    fetchPolicy: 'no-cache',
  });

  const [addTradingSuspension] = useUpdateTradingSuspension();

  const activeTradingSuspensionsContent = useMemo(() => getActiveTradingSuspensionsContent(content), [content]);

  const refetchActiveSuspensions = useCallback(async () => {
    setIsRefetchInProgress(true);
    await refetchActiveTradingSuspensionsData();
    setIsRefetchInProgress(false);
  }, [refetchActiveTradingSuspensionsData]);

  const onAddTradingSuspension = useCallback(async () => {
    try {
      setAddSuspensionInProgress(true);
      const addSuspensionResponse = await addTradingSuspension({
        variables: {
          managedProductId,
          suspensionType: SuspensionType.PARTNER,
          suspensionTags: [SuspensionTag.OPS],
        },
      });
      if (addSuspensionResponse.data?.updateTradingSuspension?.length) {
        onAddTradingSuspensionSuccess?.(activeTradingSuspensionsContent.suspensionAdded, refetchActiveSuspensions);
      } else {
        onAddTradingSuspensionFailure?.();
      }
    } catch (addSuspensionFailure: any) {
      onAddTradingSuspensionFailure?.();
    } finally {
      setAddSuspensionInProgress(false);
    }
  }, [
    activeTradingSuspensionsContent,
    addTradingSuspension,
    managedProductId,
    onAddTradingSuspensionFailure,
    onAddTradingSuspensionSuccess,
    refetchActiveSuspensions,
  ]);

  const onRemoveSuspensionActionClick = useCallback(
    (suspension: SuspensionRowData) => {
      const removeSuspensionData = data?.managedProduct?.tradingSuspensions.find(item => item.id === suspension.id);
      setRemoveSuspensionModalData({
        suspensionDisplayData: suspension,
        suspensionTag: removeSuspensionData?.suspensionTag,
        suspensionType: removeSuspensionData?.suspensionType,
      });
      openRemoveTradingSuspensionModal?.();
    },
    [data, openRemoveTradingSuspensionModal],
  );

  const activeTradingSuspensionData: Data = useMemo(
    () => ({
      addSuspensionInProgress,
      columnsData: getColumns(activeTradingSuspensionsContent),
      content: activeTradingSuspensionsContent,
      isOpsSuspensionAdded: getIsOpsSuspensionAdded(data?.managedProduct?.tradingSuspensions ?? []),
      isRefetchInProgress,
      onAddTradingSuspension,
      refetchActiveSuspensions,
      removeSuspensionModalData,
      suspensionData:
        data?.managedProduct?.tradingSuspensions.map(suspension =>
          getSuspensionRowData(suspension, activeTradingSuspensionsContent, contentOptions, suspensionTagStyleMap),
        ) ?? [],
      tableData:
        getFormattedRows({
          content: activeTradingSuspensionsContent,
          contentOptions,
          disableRemoveSuspension: isRefetchInProgress,
          isRemoveTradingSuspensionAllowed: removePartnerOpsSuspensionAllowed,
          onRemoveSuspensionActionClick,
          suspensionData: data?.managedProduct?.tradingSuspensions ?? [],
          suspensionTagStyleMap,
        }) ?? [],
    }),
    [
      activeTradingSuspensionsContent,
      addSuspensionInProgress,
      contentOptions,
      data,
      isRefetchInProgress,
      onAddTradingSuspension,
      onRemoveSuspensionActionClick,
      refetchActiveSuspensions,
      removePartnerOpsSuspensionAllowed,
      removeSuspensionModalData,
      suspensionTagStyleMap,
    ],
  );

  return {
    data: activeTradingSuspensionData,
    error: contentError ?? error,
    loading: contentLoading || loading,
  };
};

export default useActiveTradingSuspensionsData;
