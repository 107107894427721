import { useEffect, useState } from 'react';

export interface Dimensions {
  height?: number;
  width?: number;
}

/**
 * Given a ref that points at an HTMLElement, returns the height and width of the tracked HTMLElement
 * @param ref - an HTMLElement ref
 * @returns the height and width of the tracked HTMLElement
 */
export const useRefDimensions = (ref: React.RefObject<HTMLElement | undefined>): Dimensions => {
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const element = ref.current;
    const observer = new ResizeObserver(entities => {
      setHeight(entities[0].contentRect.height);
      setWidth(entities[0].contentRect.width);
    });
    observer.observe(element);
    return () => {
      observer.unobserve(element);
      observer.disconnect();
    };
  }, [ref]);

  return { height, width };
};
