import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useHandleValidateCashTransfer } from '../../CloseAccount/hooks';
import { useDeleteBankAccount } from '../../DeleteBankAccount/symphony';
import { AddFinancialAccount_addFinancialAccount } from '../../LinkBankAccount/symphony/__generated__/mutation.v2';
import { getDistributionReasonCode, Props } from '..';
import { ValidateCashTransferResponse } from '../symphony';
import { FormData } from '../types';
import { WithdrawFundsModalBase } from '../WithdrawFundsModalBase';

import { useWithdrawFundsData } from './useWithdrawFundsData';
import { DEFAULT_FORM_DATA, DEFAULT_NUMBER_OF_OCCURRENCE } from './utils';

import {
  AssociatedEntityInput,
  AssociatedEntityType,
  CreateCashTransferInput,
  FinancialAccountAssociationVerificationStatus,
  SleeveType,
  TransferFrequency,
  TransferType,
} from '~/__generated__';
import { RmdData } from '~/components/Rmd/types';
import { WITHHOLD_OPTIONS } from '~/components/TaxWithholdingForm';
import {
  FinancialAccount,
  useCreateCashTransfer,
  useSaveFinancialAccountAssociation,
} from '~/hooks/financial-account/symphony';
import { BankAccount, getExternalBankAccountInput, isRetirementAccountType } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { toSymphonyDate, toSymphonyDateTime } from '~/utils/symphony';
import { AsyncResult } from '~/utils/types';

interface Variables
  extends Pick<
    Props,
    | 'accountId'
    | 'availableTransferFrequencies'
    | 'contentOptions'
    | 'financialAccountType'
    | 'isRaiseCash'
    | 'managedProductId'
    | 'managedProductType'
    | 'onClose'
    | 'onLinkAccount'
    | 'onWithdrawSuccess'
    | 'open'
    | 'partyId'
    | 'redirectToSignDocuments'
    | 'showVerifiedBankAccounts'
    | 'syncExternalBankAccounts'
  > {
  asSoonAsPossibleDate: Date;
  openDocusignRequiredModal: () => void;
}

type Data = Pick<
  ComponentProps<typeof WithdrawFundsModalBase>,
  | 'addedFinancialAccount'
  | 'bankAccounts'
  | 'clientAge'
  | 'content'
  | 'contentV2'
  | 'errors'
  | 'formData'
  | 'formHooks'
  | 'formStep'
  | 'isRefetchingAccounts'
  | 'isRetirementWithdrawal'
  | 'isSubmitting'
  | 'marketHolidays'
  | 'onBack'
  | 'onLinkAccount'
  | 'onModalClose'
  | 'onNext'
  | 'refetchBankAccounts'
  | 'setTotalWithdrawalAmount'
  | 'symphonySubmitError'
  | 'validateCashTransferResponse'
> & {
  handlePrimaryClickOnDocusignRequired: () => Promise<void>;
  handleTransferFrequencyTypeChange: () => void;
  onSuccessCallback: (createNewWithdrawalRequest: boolean) => void;
  rmdData?: RmdData;
};

interface UseWithdrawFundsModalResult extends Omit<AsyncResult, 'data'> {
  data: Data;
}

export const useWithdrawFundsModal = ({
  accountId,
  asSoonAsPossibleDate,
  availableTransferFrequencies = [TransferFrequency.MONTHLY],
  contentOptions,
  financialAccountType,
  isRaiseCash,
  managedProductId,
  managedProductType,
  onClose,
  onLinkAccount,
  onWithdrawSuccess,
  open = false,
  openDocusignRequiredModal,
  partyId,
  redirectToSignDocuments,
  showVerifiedBankAccounts,
  syncExternalBankAccounts,
}: Variables): UseWithdrawFundsModalResult => {
  const {
    featureFlags: {
      isDocusignRequiredForFinancialAccountLinkageInRCE,
      showWarningForNonVerifiedFinancialAccountLinkages,
    },
    components: {
      sfWithdrawFunds: { isDocusignRequiredForRetirementWithdrawals },
    },
  } = useCoreConfig();
  const [entityForDocusign, setEntityForDocusign] = useState<AssociatedEntityInput>({
    entityId: '',
    entityType: AssociatedEntityType.BANK_ACCOUNT_ASSOCIATION,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalWithdrawalAmount, setTotalWithdrawalAmount] = useState('');
  const [formStep, setFormStep] = useState<number>(1);
  const [formData, setFormData] = useState<FormData>(DEFAULT_FORM_DATA);
  const [symphonySubmitError, setSymphonySubmitError] = useState<Error | undefined>();
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [validateCashTransferResponse, setValidateCashTransferResponse] = useState<ValidateCashTransferResponse>();
  const [addedFinancialAccount, setAddedFinancialAccount] = useState<
    AddFinancialAccount_addFinancialAccount | null | undefined
  >();
  const [createCashTransferPayload, setCreateCashTransferPayload] = useState<CreateCashTransferInput>();
  const openRef = useRef<boolean>(open);

  const [createCashTransfer] = useCreateCashTransfer();
  const [saveFinancialAccountAssociation] = useSaveFinancialAccountAssociation();
  const [deleteBankAccount] = useDeleteBankAccount();
  const handleValidateCashTransfer = useHandleValidateCashTransfer();

  const { data: withdrawFundsData, error, loading } = useWithdrawFundsData({
    availableTransferFrequencies,
    contentOptions,
    isRaiseCash,
    managedProductType,
    partyId,
    showVerifiedBankAccounts,
    skip: !open,
    syncExternalBankAccounts,
    includeManagedProducts:
      !!isDocusignRequiredForFinancialAccountLinkageInRCE || !!showWarningForNonVerifiedFinancialAccountLinkages,
  });

  const form = useForm<FormData>();
  const isRetirementWithdrawal: boolean =
    !!financialAccountType && isRetirementAccountType(financialAccountType) && !isRaiseCash;

  useEffect(() => {
    if (withdrawFundsData?.financialAccounts) {
      setBankAccounts(withdrawFundsData.financialAccounts);
    }
  }, [withdrawFundsData?.financialAccounts]);

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  const handleSubmit = async (submittedData: FormData) => {
    setIsSubmitting(true);
    let transferDay;

    switch (submittedData.withdrawalFrequency) {
      case TransferFrequency.MONTHLY:
        delete submittedData.withdrawalDayOfWeek;
        delete submittedData.withdrawalDate;
        transferDay = submittedData.withdrawalDayOfMonth;
        break;
      case TransferFrequency.WEEKLY:
        delete submittedData.withdrawalDayOfMonth;
        delete submittedData.withdrawalDate;
        transferDay = submittedData.withdrawalDayOfWeek;
        break;
      case TransferFrequency.ONE_TIME:
        delete submittedData.withdrawalDayOfWeek;
        delete submittedData.withdrawalDayOfMonth;
        break;
    }

    const externalBankAccountInput = getExternalBankAccountInput(
      submittedData.eligibleDestinationBankAccount,
      bankAccounts,
      { name: withdrawFundsData?.clientName ?? '', partyId },
    );

    const createCashTransferInput: CreateCashTransferInput = {
      bankAccountWithParty: externalBankAccountInput,
      cashAmount: isRetirementWithdrawal
        ? parseFloat(totalWithdrawalAmount).toFixed(2)
        : submittedData.withdrawalAmount,
      day: transferDay,
      destinationFinancialAccountId:
        !externalBankAccountInput && !!submittedData.eligibleDestinationBankAccount
          ? submittedData.eligibleDestinationBankAccount
          : null,
      frequency: submittedData.withdrawalFrequency,
      scheduledAt: getScheduledAtDate(submittedData.withdrawalFrequency, submittedData.withdrawalDate),
      sleeve: SleeveType.INVESTMENT,
      sourceFinancialAccountId: submittedData.eligibleSourceAccount,
      type: TransferType.WITHDRAWAL,
      ...(isRetirementWithdrawal && {
        distributionReason: getDistributionReasonCode(
          withdrawFundsData?.clientAge,
          validateCashTransferResponse?.distributionCode,
          withdrawFundsData?.content.taxWithholdingFormContent?.soft_block_client_age?.premature,
        ),
        federalTaxWithholdingCode: submittedData.federalTaxWithholding,
        ...(submittedData.federalTaxWithholding !== WITHHOLD_OPTIONS.DO_NOT_WITHHOLD && {
          federalTaxWithholdingPercentage: submittedData.federalTaxWithholdingPercentage?.toString(),
        }),
        stateTaxWithholdingCode: submittedData.stateTaxWithholding,
        ...(submittedData.stateTaxWithholding !== WITHHOLD_OPTIONS.DO_NOT_WITHHOLD && {
          stateTaxWithholdingPercentage: submittedData.stateTaxWithholdingPercentage?.toString(),
        }),
        transactionId: validateCashTransferResponse?.transactionId,
        withdrawalGrossUp: submittedData.grossUpFlag ?? false,
      }),
    };

    if (submittedData.withdrawalFrequency !== TransferFrequency.ONE_TIME) {
      createCashTransferInput.numberOfOccurrences =
        submittedData.withdrawalNumberOfOccurrence === DEFAULT_NUMBER_OF_OCCURRENCE
          ? undefined
          : submittedData.withdrawalNumberOfOccurrence;
    }

    setCreateCashTransferPayload(createCashTransferInput);

    try {
      if (
        addedFinancialAccount?.id &&
        createCashTransferInput.destinationFinancialAccountId !== addedFinancialAccount.id
      ) {
        await deleteFinancialAccount(addedFinancialAccount.id);
      }
      let bankAccountAssociationResult;
      const hasFinancialAccountAssociation =
        externalBankAccountInput?.bankAccount || createCashTransferInput.destinationFinancialAccountId;
      if (hasFinancialAccountAssociation) {
        const financialAccountAssociationInput = {
          ...(externalBankAccountInput?.bankAccount
            ? { bankAccount: externalBankAccountInput.bankAccount }
            : { bankAccountId: createCashTransferInput.destinationFinancialAccountId }),
        };
        bankAccountAssociationResult = await saveFinancialAccountAssociation({
          variables: {
            managedProductId,
            partyId,
            financialAccountAssociation: financialAccountAssociationInput,
          },
        });
      }
      const associationData = bankAccountAssociationResult?.data?.saveFinancialAccountAssociation;
      const isDocusignRequired =
        // Check for null to maintain backwards compatibility
        associationData?.verificationStatus === null ||
        associationData?.verificationStatus === FinancialAccountAssociationVerificationStatus.NEEDS_DOCUSIGN;

      if (isRetirementWithdrawal && isDocusignRequiredForRetirementWithdrawals) {
        setEntityForDocusign({
          ...entityForDocusign,
          entityId: associationData?.id ?? '',
        });
        // We're not opening DocusignRequired Modal in case of Retirement account instead CreateCashTransfer + maintaing the previous flow to redirect to Docusign
        handleDocusignRedirection();
      } else if (isDocusignRequiredForFinancialAccountLinkageInRCE && isDocusignRequired) {
        setEntityForDocusign({
          ...entityForDocusign,
          entityId: associationData.id,
        });
        openDocusignRequiredModal();
      } else {
        await createCashTransfer({ variables: { createCashTransferInput } });
        onWithdrawSuccess?.();
        setSymphonySubmitError(undefined);
        withdrawFundsData?.rmdData?.refetch();
        setFormStep(4);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
    const errorMessage = withdrawFundsData?.content.errorMessages?.find(
      e => e?.error_code === err?.graphQLErrors?.[0]?.errorCode?.toString(),
    );
    if (openRef.current) {
      setSymphonySubmitError({ ...err, message: errorMessage?.message ? errorMessage.message : err.message });
    }
  };

  const getScheduledAtDate = (
    withdrawalFrequency: TransferFrequency,
    withdrawalDate?: Date,
    isValidate?: boolean,
  ): string | undefined => {
    if (withdrawalDate) {
      return isValidate ? toSymphonyDate(withdrawalDate) : toSymphonyDateTime(withdrawalDate);
    }
    if (isValidate) {
      return toSymphonyDate(asSoonAsPossibleDate);
    }
    return withdrawalFrequency === TransferFrequency.ONE_TIME ? toSymphonyDateTime(asSoonAsPossibleDate) : undefined;
  };

  const deleteFinancialAccount = async (financialAccountId: string) => {
    await deleteBankAccount({
      variables: {
        bankAccountId: financialAccountId,
        partyId,
      },
    });
  };

  const handlePreValidate = async (submittedData: FormData) => {
    setIsSubmitting(true);
    try {
      const {
        validateCashTransferResponse: response,
        addedFinancialAccount: financialAccount,
      } = await handleValidateCashTransfer({
        accountId,
        addedFinancialAccount,
        bankAccounts,
        cashAmount: submittedData.withdrawalAmount,
        clientUserName: withdrawFundsData?.clientName ?? '',
        deleteFinancialAccount,
        financialAccountType,
        formData: submittedData,
        managedProductId,
        partyId,
      });
      if (financialAccount) {
        setBankAccounts([
          ...bankAccounts.filter(
            acc =>
              acc.accountNumber !== financialAccount.accountNumber &&
              acc.routingNumber !== financialAccount.routingNumber,
          ),
          financialAccount,
        ]);

        setAddedFinancialAccount(financialAccount);
        setFormData(prevFormData => {
          if (prevFormData && financialAccount.id) {
            return {
              ...prevFormData,
              eligibleDestinationBankAccount: financialAccount.id,
            };
          }
          return prevFormData;
        });
      }
      if (response) {
        setValidateCashTransferResponse(response);
      }
      setFormStep(step => step + 1);
      setSymphonySubmitError(undefined);
    } catch (err) {
      handleError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSuccessCallback = useCallback(
    (createNewWithdrawalRequest: boolean) => {
      if (!createNewWithdrawalRequest) {
        onClose?.(withdrawFundsData?.content.feedbackMessage);
      }
      setInitialState();
    },
    [onClose, withdrawFundsData?.content?.feedbackMessage],
  );

  const onBack = () => {
    setSymphonySubmitError(undefined);
    setFormStep(step => step - 1);
  };

  const setInitialState = () => {
    setFormStep(1);
    form.reset();
    setFormData(DEFAULT_FORM_DATA);
    setSymphonySubmitError(undefined);
    setAddedFinancialAccount(undefined);
  };

  const onModalClose = () => {
    if (addedFinancialAccount?.id) {
      deleteFinancialAccount(addedFinancialAccount.id);
    }
    setInitialState();
    onClose?.();
  };

  const onNext = async () => {
    await form.handleSubmit(async () => {
      const completeFormData = {
        ...formData,
        ...form.getValues(),
        ...(formData?.withdrawalFrequency !== TransferFrequency.ONE_TIME && { grossUpFlag: false }),
      };
      setFormData(completeFormData);
      if (formStep < 3 && isRetirementWithdrawal) {
        if (formStep === 1) {
          await handlePreValidate(completeFormData);
        } else {
          setFormStep(step => step + 1);
        }
      } else {
        await handleSubmit(completeFormData);
      }
    })();
  };

  const onLinkAccountCallback = (account: FinancialAccount) => {
    if (!bankAccounts.find(a => a.id === account.id)) {
      if (account && account.id) {
        setBankAccounts([...bankAccounts, account as BankAccount]);
      }
    }
    setSymphonySubmitError(undefined);
    onLinkAccount?.(account);
  };

  const handleDocusignRedirection = async () => {
    if (createCashTransferPayload) {
      try {
        await createCashTransfer({
          variables: { createCashTransferInput: createCashTransferPayload },
        });
        redirectToSignDocuments?.(managedProductId, entityForDocusign.entityId ? entityForDocusign : undefined);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleTransferFrequencyTypeChange = () => {
    setSymphonySubmitError(undefined);
  };

  return {
    data: {
      addedFinancialAccount,
      bankAccounts,
      errors: form.errors,
      formData,
      formHooks: form,
      formStep,
      handlePrimaryClickOnDocusignRequired: handleDocusignRedirection,
      isRetirementWithdrawal,
      isSubmitting,
      marketHolidays: withdrawFundsData?.marketHolidays ?? [],
      onBack,
      onSuccessCallback,
      onLinkAccount: onLinkAccountCallback,
      onModalClose,
      onNext,
      setTotalWithdrawalAmount,
      symphonySubmitError,
      handleTransferFrequencyTypeChange,
      validateCashTransferResponse,
      ...withdrawFundsData,
    },
    loading,
    error,
  };
};
