import { DeleteComment, DeleteCommentVariables, SaveComment, SaveCommentVariables } from './__generated__/mutation.v2';
import * as mutations from './mutation.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useSaveComment = (
  options?: MutationHookOptions<SaveComment, SaveCommentVariables>,
): MutationTuple<SaveComment, SaveCommentVariables> => {
  return useSymphonyMutation(mutations.SaveComment, options);
};

export const useDeleteComment = (
  options?: MutationHookOptions<DeleteComment, DeleteCommentVariables>,
): MutationTuple<DeleteComment, DeleteCommentVariables> => {
  return useSymphonyMutation(mutations.DeleteComment, options);
};
