import { TaxWithholdingInfoContent, TransferStatus, TransferStatusFilter } from '../../types';
import { getColumnKeyValuePair } from '../../utils';
import { FlagsReferenceContent, TaxWithholdingContent } from '../contentstack';
import {
  GetWithdrawalTableContent,
  GetWithdrawalTableContent_all_withdrawals_table_items,
  GetWithdrawalTableContent_all_withdrawals_table_items_status_filters,
  GetWithdrawalTableContent_all_withdrawals_table_items_statuses,
} from '../contentstack/__generated__/query.v2';

import { TableColumn } from '~/components/ui/BasicTable';
import { ContentstackTableColumn } from '~/utils/table';

export type WithdrawalDisplayStrings = {
  endDate?: string;
  flags: FlagsReferenceContent;
  nonRecurringtableHeaders: TableColumn[];
  nonWrappableNonRecurring: string[];
  nonWrappableRecurring: string[];
  partitionTabLabels: { allOtherRequests: string; flaggedRequests: string };
  partitionedByFlaggedStatus: boolean;
  recurringButton: { nonRecurring: string; recurring: string };
  recurringtableHeaders: TableColumn[];
  rmdNote: string;
  status: Record<TransferStatus, string>;
  statusFilter: Record<TransferStatusFilter, string>;
  taxWithholdingInfo?: TaxWithholdingInfoContent;
  transferToAccountNote: string;
};

export const generateWithdrawalStaticDisplayStrings = (
  content: GetWithdrawalTableContent,
): WithdrawalDisplayStrings => {
  const items = content.all_withdrawals_table?.items?.[0];

  const recurringColumns = getColumnKeyValuePair(
    items?.columns?.column as ContentstackTableColumn[],
    items?.columns?.recurring?.recurring_column as ContentstackTableColumn[],
  );

  const nonRecurringColumns = getColumnKeyValuePair(
    items?.columns?.column as ContentstackTableColumn[],
    items?.columns?.non_recurring?.non_recurring_column as ContentstackTableColumn[],
  );

  const flagsContent = items?.flag_referenceConnection?.edges?.[0]?.node ?? ({} as FlagsReferenceContent);

  return {
    flags: flagsContent,
    recurringButton: { nonRecurring: 'One-time', recurring: 'Recurring' },
    recurringtableHeaders: recurringColumns,
    nonWrappableRecurring: items?.columns?.recurring?.non_wrappable?.flatMap(f => (f ? [f] : [])) ?? [],
    nonWrappableNonRecurring: items?.columns?.non_recurring?.non_wrappable?.flatMap(f => (f ? [f] : [])) ?? [],
    nonRecurringtableHeaders: nonRecurringColumns,
    status: {
      [TransferStatus.IN_PROGRESS]: getStatusLabel(TransferStatus.IN_PROGRESS, items),
      [TransferStatus.CANCELLED]: getStatusLabel(TransferStatus.CANCELLED, items),
      [TransferStatus.COMPLETED]: getStatusLabel(TransferStatus.COMPLETED, items),
      [TransferStatus.PENDING]: getStatusLabel(TransferStatus.PENDING, items),
      [TransferStatus.PENDING_CANCELLATION]: getStatusLabel(TransferStatus.PENDING_CANCELLATION, items),
      [TransferStatus.UNRECOGNIZED]: getStatusLabel(TransferStatus.UNRECOGNIZED, items),
    },
    statusFilter: {
      [TransferStatusFilter.PENDING_IN_PROGRESS]: getStatusFilterLabel(TransferStatusFilter.PENDING_IN_PROGRESS, items),
      [TransferStatusFilter.PENDING]: getStatusFilterLabel(TransferStatusFilter.PENDING, items),
      [TransferStatusFilter.PENDING_CANCELLATION]: getStatusFilterLabel(
        TransferStatusFilter.PENDING_CANCELLATION,
        items,
      ),
      [TransferStatusFilter.IN_PROGRESS]: getStatusFilterLabel(TransferStatusFilter.IN_PROGRESS, items),
      [TransferStatusFilter.COMPLETED]: getStatusFilterLabel(TransferStatusFilter.COMPLETED, items),
      [TransferStatusFilter.CANCELLED]: getStatusFilterLabel(TransferStatusFilter.CANCELLED, items),
    },
    taxWithholdingInfo: getTaxWithholdingInfoContent(items?.tax_withholding_infoConnection?.edges?.[0]?.node),
    transferToAccountNote: items?.transfer_to_account_note || 'Transfer to Bank Account, linked account ending',
    partitionedByFlaggedStatus: items?.requests?.partitioned_by_flagged_status || false,
    partitionTabLabels: {
      allOtherRequests: items?.requests?.partition_tabs?.find(x => x?.value === 'all_other_requests')?.label || '',
      flaggedRequests: items?.requests?.partition_tabs?.find(x => x?.value === 'flagged_requests')?.label || '',
    },
    rmdNote: items?.rmd_note || '',
  };
};

const getTaxWithholdingInfoContent = (
  taxWithholdingContent?: TaxWithholdingContent | null,
): TaxWithholdingInfoContent => {
  return {
    distributionReason: taxWithholdingContent?.distribution_reason ?? '',
    distributionReasonValues: {
      normal: taxWithholdingContent?.distribution_reason_values?.find(reason => reason?.key === 'normal')?.value ?? '',
      premature:
        taxWithholdingContent?.distribution_reason_values?.find(reason => reason?.key === 'premature')?.value ?? '',
    },
    federalTax: taxWithholdingContent?.federal_tax ?? '',
    grossUp: taxWithholdingContent?.gross_up ?? '',
    grossUpValues: {
      no: taxWithholdingContent?.gross_up_values?.find(item => item?.key === 'no')?.value ?? '',
      yes: taxWithholdingContent?.gross_up_values?.find(item => item?.key === 'yes')?.value ?? '',
    },
    stateTax: taxWithholdingContent?.state_tax ?? '',
  };
};

const getStatusLabel = (
  status: TransferStatus,
  items: GetWithdrawalTableContent_all_withdrawals_table_items | null | undefined,
): string => {
  const key = status.toLowerCase() as keyof GetWithdrawalTableContent_all_withdrawals_table_items_statuses;
  return items?.statuses?.[key] ?? '';
};

const getStatusFilterLabel = (
  statusFilter: TransferStatusFilter,
  items: GetWithdrawalTableContent_all_withdrawals_table_items | null | undefined,
): string => {
  const key = statusFilter.toLowerCase() as keyof GetWithdrawalTableContent_all_withdrawals_table_items_status_filters;
  return items?.status_filters?.[key] ?? '';
};
