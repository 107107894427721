import { useCallback, useContext, useMemo, useState } from 'react';

import { useGetOpsDashboardContent } from '../../contentstack';
import OpsDashboardContext from '../../OpsDashboardContext';
import { RemoveSuspensionData, TradingSuspensionsContent, TradingSuspensionsTableContent } from '../../types';
import { useGetTradingSuspensionsContent } from '../contentstack';
import {
  GroupedTradingSuspensionManagedProduct,
  useGetGroupedTradingSuspensions,
  useGetTradingSuspensions,
} from '../symphony';

import { getOffset, getTableContent, getTradingSuspensionContent, TradingSuspensionsData } from './utils';

import { Field, OrderType, SuspensionType } from '~/__generated__';
import { SortConfig, TableColumn } from '~/components/ui/BasicTable';
import { useTheme } from '~/components/ui/mui';
import { SuspensionTag } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentstackTableColumn, sortColumnsByColumnOrder } from '~/utils/table';
import { AsyncResult } from '~/utils/types';

export interface TradingSuspensionsVariable {
  onRemoveSuspensionClick: (data: RemoveSuspensionData) => void;
}

interface TradingSuspensionsTabData {
  content: TradingSuspensionsContent;
  handleGroupAccountToggle: () => void;
  handleOpsOnlySuspensionToggle: () => void;
  isGroupAccountToggleChecked: boolean;
  isOpsOnlySuspensionToggleChecked: boolean;
  onPageChange: (pageNumber: number) => void;
  pageNumber: number;
  refetchTradingSuspendedData: () => void;
  sortConfig: SortConfig;
  tableColumns: TableColumn[];
  totalPages: number;
  tradingSuspendedTableContent: TradingSuspensionsTableContent[];
}

// Set 10 as constant first until we have requirements to change it
const recordsPerPage = 10;

export const useTradingSuspensionsData = ({
  onRemoveSuspensionClick,
}: TradingSuspensionsVariable): AsyncResult<TradingSuspensionsTabData> => {
  const [isGroupAccountToggleChecked, setIsGroupAccountToggleChecked] = useState(false);
  const [isOpsOnlySuspensionToggleChecked, setIsOpsOnlySuspensionToggleChecked] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    order: OrderType.DESCENDING,
    field: 'created',
  });

  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  const {
    sfSuspension: {
      styles: { suspensionTagStyleMap },
    },
  } = useTheme();
  const {
    currentUser,
    contentOptions,
    commentColumn,
    onAccountClick,
    onClientClick,
    getClientNameRedirectUrl,
    getAccountNumberRedirectUrl,
  } = useContext(OpsDashboardContext);

  /**
   * The reason for no-cache is due to the updating the list when ever we create or resolve flag for a docusign,
   * the flagged and unflagged docusign table should be updated.
   * Otherwise, instead of the table showing the current flagged and unflagged docusign data it shows the previous cached data.
   * There is no easy way to update the cache to reflect the correct state right now.
   */
  const {
    data: tradingSuspendedData,
    error: tradingSuspendedDataError,
    loading: tradingSuspendedDataLoading,
    refetch: refetchTradingSuspendedData,
  } = useGetTradingSuspensions({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: {
      fetchLatestNote: true,
      onlyOpsSuspensions: isOpsOnlySuspensionToggleChecked,
      pagination: {
        limit: recordsPerPage,
        offset: getOffset(pageNumber, recordsPerPage),
      },
      sort: {
        field: Field.CREATED,
        order: sortConfig.order,
      },
    },
    skip: isGroupAccountToggleChecked,
  });

  const {
    data: groupedTradingSuspensionsData,
    error: groupedTradingSuspensionsDataError,
    loading: groupedTradingSuspensionsDataLoading,
    refetch: refetchGroupedTradingSuspendedData,
  } = useGetGroupedTradingSuspensions({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: {
      closeRequestStatus: [],
      fetchLatestNote: true,
      ...(isOpsOnlySuspensionToggleChecked
        ? {
            includedTradingSuspensions: [
              {
                suspensionTags: [SuspensionTag.OPS],
                suspensionTypes: [SuspensionType.PARTNER],
              },
            ],
          }
        : {
            includedTradingSuspensions: [
              {
                suspensionTypes: [SuspensionType.PARTNER, SuspensionType.TRADER],
              },
            ],
          }),
      pagination: {
        limit: recordsPerPage,
        offset: getOffset(pageNumber, recordsPerPage),
      },
      sortByTradingSuspendedDate: true,
      statuses: [],
    },
    skip: !isGroupAccountToggleChecked,
  });

  const { data: contentData, error: contentStackError, loading: contentLoading } = useGetTradingSuspensionsContent({
    variables: contentOptions,
  });

  const { data: opsDashboardContentData, error: opsDashboardContentError } = useGetOpsDashboardContent({
    variables: contentOptions,
  });

  const onPageChange = useCallback((selectedPage: number) => {
    setPageNumber(selectedPage);
  }, []);

  const handleGroupAccountToggle = useCallback(() => {
    onPageChange(1);
    setIsGroupAccountToggleChecked(prev => !prev);
  }, [onPageChange]);

  const handleOpsOnlySuspensionToggle = useCallback(() => {
    onPageChange(1);
    setIsOpsOnlySuspensionToggleChecked(prev => !prev);
  }, [onPageChange]);

  const tradingTableContent = useMemo(() => getTradingSuspensionContent(contentData), [contentData]);

  const tableColumns: TableColumn[] = useMemo(() => {
    // Filter empty columns
    const contentstackTableColumn =
      tradingTableContent.columns?.reduce<ContentstackTableColumn[]>((acc, column: ContentstackTableColumn | null) => {
        if (column) {
          acc.push(column);
        }

        return acc;
      }, []) ?? [];

    const sortedColumns = sortColumnsByColumnOrder(contentstackTableColumn);

    return sortedColumns.map(
      (col): TableColumn => ({
        title: col.column_value,
        key: col.column_id ?? '',
        ...(col.column_id === 'created' && !isGroupAccountToggleChecked
          ? {
              onSort: (_: string) => () => {
                setSortConfig(prev => ({
                  field: 'created',
                  order: prev.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING,
                }));
                setPageNumber(1);
              },
            }
          : {}),
      }),
    );
  }, [isGroupAccountToggleChecked, tradingTableContent]);

  const data: TradingSuspensionsTabData | undefined = useMemo(() => {
    if (!contentData || !opsDashboardContentData) {
      return undefined;
    }

    const commonData = {
      content: tradingTableContent,
      contentLoading,
      handleGroupAccountToggle,
      handleOpsOnlySuspensionToggle,
      isGroupAccountToggleChecked,
      isOpsOnlySuspensionToggleChecked,
      onPageChange,
      pageNumber,
      refetchTradingSuspendedData: isGroupAccountToggleChecked
        ? refetchGroupedTradingSuspendedData
        : refetchTradingSuspendedData,
      sortConfig,
      tableColumns,
      totalPages: 1,
      tradingSuspendedTableContent: [],
    };

    if (groupedTradingSuspensionsDataLoading || tradingSuspendedDataLoading) {
      return commonData;
    }

    if (isGroupAccountToggleChecked && groupedTradingSuspensionsData?.managedProducts) {
      const groupedTradingSuspensionsManagedProducts = groupedTradingSuspensionsData.managedProducts;
      const tradingSuspensions: TradingSuspensionsData[] = groupedTradingSuspensionsManagedProducts.products.reduce(
        (acc: TradingSuspensionsData[], managedProduct: GroupedTradingSuspensionManagedProduct) => {
          managedProduct.tradingSuspensions.forEach((suspension, index) => {
            acc.push({
              ...suspension,
              groupingIndex: index,
              managedProduct,
            });
          });
          return acc;
        },
        [],
      );

      const tradingSuspendedTableContent = getTableContent(
        {
          commentColumn,
          contentOptions,
          currentUser,
          refetchTradingSuspendedData: refetchGroupedTradingSuspendedData,
        },
        tradingTableContent,
        tradingSuspensions,
        onClientClick,
        onAccountClick,
        getAccountNumberRedirectUrl,
        getClientNameRedirectUrl,
        onRemoveSuspensionClick,
        opsDashboardContentData,
        showProductName,
        suspensionTagStyleMap,
      );
      const total = groupedTradingSuspensionsManagedProducts.paginationContext.total ?? 0;

      return {
        ...commonData,
        totalPages: Math.ceil(total / recordsPerPage),
        tradingSuspendedTableContent,
      };
    } else if (!isGroupAccountToggleChecked && tradingSuspendedData) {
      const tradingSuspendedTableContent = getTableContent(
        {
          commentColumn,
          contentOptions,
          currentUser,
          refetchTradingSuspendedData,
        },
        tradingTableContent,
        tradingSuspendedData.tradingSuspensions.tradingSuspensions,
        onClientClick,
        onAccountClick,
        getAccountNumberRedirectUrl,
        getClientNameRedirectUrl,
        onRemoveSuspensionClick,
        opsDashboardContentData,
        showProductName,
        suspensionTagStyleMap,
      );
      const total = tradingSuspendedData.tradingSuspensions.paginationContext.total ?? 0;

      return {
        ...commonData,
        totalPages: Math.ceil(total / recordsPerPage),
        tradingSuspendedTableContent,
      };
    }

    return commonData;
  }, [
    commentColumn,
    contentData,
    contentLoading,
    contentOptions,
    currentUser,
    handleGroupAccountToggle,
    handleOpsOnlySuspensionToggle,
    isGroupAccountToggleChecked,
    isOpsOnlySuspensionToggleChecked,
    groupedTradingSuspensionsData,
    onAccountClick,
    onClientClick,
    onPageChange,
    onRemoveSuspensionClick,
    opsDashboardContentData,
    pageNumber,
    refetchGroupedTradingSuspendedData,
    refetchTradingSuspendedData,
    showProductName,
    sortConfig,
    suspensionTagStyleMap,
    tableColumns,
    tradingSuspendedData,
    tradingTableContent,
  ]);

  const error: Error | undefined = useMemo(() => {
    const dataError = isGroupAccountToggleChecked ? tradingSuspendedDataError : groupedTradingSuspensionsDataError;

    return dataError ?? contentStackError ?? opsDashboardContentError;
  }, [
    groupedTradingSuspensionsDataError,
    contentStackError,
    isGroupAccountToggleChecked,
    tradingSuspendedDataError,
    opsDashboardContentError,
  ]);

  return {
    data,
    error,
    loading: contentLoading,
  };
};
