import React, { ComponentProps, useContext, useMemo, useState } from 'react';

import { Comment, CommentComponentColumn } from '../../common/Comment';
import { FlagAction } from '../../common/FlagAction';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import OpsDashboardContext from '../../OpsDashboardContext';
import { getAccountNumberOrAccountTypeString, isAccountNumberClickable, OpsDashboardUser } from '../../utils';
import { ClosureDisplayStrings } from '../content';
import { ClosureItem } from '../hooks';
import { closuresToAllowedStatusUpdates } from '../hooks/mappers';

import { FlagEntity, FlagReason, UserNoteEntityType } from '~/__generated__';
import { NullStateProps } from '~/components/NullState';
import { StatusButton } from '~/components/StatusButton';
import {
  BasicTable,
  SortConfig,
  TableColumn,
  TableComponent as TableComponentType,
  TableData,
} from '~/components/ui/BasicTable';
import { ItemsPerPage } from '~/components/ui/BasicTable/ItemsPerPage';
import { TotalItems } from '~/components/ui/BasicTable/TotalItems';
import { CopyField } from '~/components/ui/CopyField';
import { Box, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { ClosureStatus } from '~/containers/OpsDashboardV2/ClosuresTab/types';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';
import { PaginationContext } from '~/utils/types';

export interface ClosuresTableProps {
  TableComponent?: TableComponentType;
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentPage: number;
  currentUser: OpsDashboardUser;
  displayStrings: ClosureDisplayStrings;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  items: ClosureItem[];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onPageChange: (p: number) => void;
  onSelectNewStatusForItem: (item: ClosureItem, selectedStatus: ClosureStatus) => void;
  onSort: (field: string) => () => void;
  paginationContext: PaginationContext;
  refetchData: () => void;
  sortConfig?: SortConfig;
  totalPages: number;
}

export const ClosuresFlagReasons = [
  FlagReason.HIGH_TRANSACTION_VALUE,
  FlagReason.API_FAILURE,
  FlagReason.OVERDUE,
  FlagReason.OTHER,
];

export const SortableFields = ['createdAt'];

export const ClosuresTable: React.FC<ClosuresTableProps> = ({
  commentColumn,
  contentOptions,
  currentPage,
  currentUser,
  displayStrings,
  items,
  nullStateConfig,
  onPageChange,
  onClientClick,
  onAccountClick,
  getAccountNumberRedirectUrl,
  getClientNameRedirectUrl,
  onSelectNewStatusForItem,
  onSort,
  refetchData,
  TableComponent = BasicTable,
  totalPages,
  sortConfig,
  paginationContext,
}) => {
  const columns: TableColumn[] = useColumns(displayStrings, onSort);
  const [activeRowId, setActiveRowId] = useState<string | undefined>();

  const onRowHover = (id: string | undefined) => {
    setActiveRowId(id);
  };
  const formattedData: TableData[] = useFormattedData(
    items,
    currentUser,
    contentOptions,
    displayStrings,
    {
      onAccountClick,
      onClientClick,
      refetchData,
      getAccountNumberRedirectUrl,
      getClientNameRedirectUrl,
    },
    onSelectNewStatusForItem,
    commentColumn,
    activeRowId,
  );
  return (
    <TableComponent
      BottomLeftSection={<TotalItems paginationContext={paginationContext} />}
      BottomRightSection={<ItemsPerPage paginationContext={paginationContext} />}
      alignItems="left"
      columns={columns}
      currentPage={currentPage}
      data={formattedData}
      enableRowHover
      nullStateConfig={nullStateConfig}
      onPageChange={onPageChange}
      onRowHover={onRowHover}
      showPagination
      sortConfig={sortConfig}
      totalPages={totalPages}
    />
  );
};

function useFormattedData(
  items: ClosureItem[],
  currentUser: OpsDashboardUser,
  contentOptions: ContentOptions,
  displayStrings: ClosuresTableProps['displayStrings'],
  cb: {
    getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
    getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
    onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
    onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
    refetchData: () => void;
  },
  onSelectNewStatusForItem: (item: ClosureItem, selectedStatus: ClosureStatus) => void,
  commentColumn?: CommentComponentColumn,
  activeRowId?: string | undefined,
): TableData[] {
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;
  const { opsContentData } = useContext(OpsDashboardContext);
  const opsDashboardContent = opsContentData?.all_ops_dashboard?.items?.[0];
  const copyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_ulid_label');
  const successCopyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_success_label');
  return useMemo(
    () =>
      items.map(item => ({
        ...item,
        rowKey: item.id,
        clientName: (
          <ClientName
            clientName={item.clientName}
            onClick={() => cb.onClientClick(item.partyId)}
            redirectUrl={cb.getClientNameRedirectUrl(item.partyId)}
          />
        ),
        accountNumber: (
          <AccountNumber
            accountNumber={item.accountNumber}
            accountNumberText={getAccountNumberOrAccountTypeString(item.accountNumber, item.accountTypeText)}
            label={showProductName ? item.productName : null}
            onClick={
              isAccountNumberClickable(item.accountState)
                ? () => cb.onAccountClick(item.partyId, item.managedProductId)
                : undefined
            }
            redirectUrl={
              isAccountNumberClickable(item.accountState)
                ? cb.getAccountNumberRedirectUrl(item.partyId, item.managedProductId)
                : undefined
            }
          />
        ),
        notes: (
          <Box whiteSpace="pre-wrap">
            <Typography variant="body1">{displayStrings.closureTypes[item.closureType] ?? ''}</Typography>
            <Typography sx={{ color: 'text.secondary', textAlign: 'left' }} variant="body2">
              {item.flags.find(flag => flag.reason === FlagReason.REJECTED_BANK_ACCOUNT_ASSOCIATION)
                ? displayStrings.bankVerificationFailedNote
                : item.destinationBankAccount
                ? `${displayStrings.transferToAccountNote} ${item.destinationBankAccount}`
                : ''}
            </Typography>
          </Box>
        ),
        createdAt: formatDate(item.createdAt),
        status: (
          <Stack direction="row">
            <StatusButton
              currentStatus={item.status}
              onMenuItemClick={newStatus => onSelectNewStatusForItem(item, newStatus as ClosureStatus)}
              showDefaultButton
              statusLabels={displayStrings.status}
              statusUpdateItems={closuresToAllowedStatusUpdates(item.status)}
            />
            <Stack justifyContent="center" sx={{ width: '30px', px: 1, fontSize: 16 }}>
              {activeRowId === item.id && (
                <CopyField
                  copyLabel={copyLabel}
                  defaultState
                  disableToggling
                  successLabel={successCopyLabel}
                  textToCopy={item.id}
                />
              )}
            </Stack>
          </Stack>
        ),
        actions: (
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Comment
              comment={item.lastCommentData}
              content={commentColumn}
              contentOptions={contentOptions}
              currentUser={currentUser}
              entity={UserNoteEntityType.CLOSE_WORKFLOW}
              entityId={item.id}
              key={item.id}
              lastCommentPartyId={item.lastCommentPartyId}
              refetchData={() => cb.refetchData()}
            />
            <FlagAction
              accountId={item.accountNumber}
              accountTypeText={item.accountTypeText}
              clientName={item.clientName}
              contentOptions={contentOptions}
              entity={FlagEntity.CLOSE_WORKFLOW}
              entityId={item.id}
              flagReasons={ClosuresFlagReasons}
              flags={item.flags}
              flagsContent={displayStrings.flags}
              onCreate={() => cb.refetchData()}
              onResolve={() => cb.refetchData()}
            />
          </Box>
        ),
      })),
    [items, cb, showProductName, displayStrings, commentColumn, contentOptions, currentUser, onSelectNewStatusForItem],
  );
}

function useColumns(displayStrings: ClosuresTableProps['displayStrings'], onSort?: (field: string) => () => void) {
  return useMemo(
    () =>
      displayStrings.tableHeaders.map(({ key, title }) => {
        return {
          key,
          title,
          onSort: onSort && SortableFields.includes(key) ? onSort : undefined,
        };
      }),
    [displayStrings, onSort],
  );
}
