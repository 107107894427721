import React, { FC } from 'react';

import { Props as GoalsCardProps } from '.';

import { KebabMenu } from '~/components/KebabMenu';
import { Box, Button, Stack } from '~/components/ui/mui';

type Props = Pick<GoalsCardProps, 'ctas' | 'dataQa'>;

export const Actions: FC<Props> = ({ ctas, dataQa = 'goals-card-actions' }) => {
  if (!ctas) {
    return null;
  }

  return (
    <Stack data-qa={dataQa} direction="row" spacing={1}>
      {ctas.primary && (
        <Box>
          <Button data-qa={`${dataQa}-primary`} variant="contained" {...ctas.primary}>
            {ctas.primary.label}
          </Button>
        </Box>
      )}
      {!!ctas.kebabMenuItems?.length && (
        <Box>
          <KebabMenu dataQa={`${dataQa}-kebab-menu`} items={ctas.kebabMenuItems} />
        </Box>
      )}
    </Stack>
  );
};
