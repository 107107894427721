import React, { useCallback, useMemo } from 'react';

import ActiveRestrictions from './ActiveRestrictions';
import useRestrictionsData from './hooks/useRestrictionsData';
import { RestrictionsTabEnum } from './hooks/utils';
import RestrictionsHistory from './RestrictionsHistory';

import { Alert } from '~/components/ui/Alert';
import { Box, Skeleton, useTheme } from '~/components/ui/mui';
import { TabData, TabsContainer } from '~/components/ui/TabsContainer';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onCountUpdate: (count: number | undefined) => void;
}

export const Restrictions: React.FC<Props> = ({
  contentOptions,
  dataQa = 'restrictions',
  managedProductId,
  onCountUpdate,
}) => {
  const { palette } = useTheme();
  const { data, error, loading } = useRestrictionsData(contentOptions);

  const tabsData: TabData[] = useMemo(
    () =>
      Object.values(RestrictionsTabEnum).map(tab => ({
        label: <Typography variant="subtitle2">{data?.content.tabHeader[tab] ?? ''}</Typography>,
        id: tab,
      })),
    [data],
  );

  const getTabComponent = useCallback(
    (currentTab: string): React.ReactNode => {
      switch (currentTab) {
        case RestrictionsTabEnum.Active: {
          return (
            <ActiveRestrictions
              contentOptions={contentOptions}
              managedProductId={managedProductId}
              onCountUpdate={onCountUpdate}
            />
          );
        }
        case RestrictionsTabEnum.History: {
          return (
            <RestrictionsHistory
              contentOptions={contentOptions}
              fields={data?.content.fields}
              managedProductId={managedProductId}
            />
          );
        }
        default: {
          return null;
        }
      }
    },
    [contentOptions, data?.content.fields, managedProductId, onCountUpdate],
  );

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <Alert contentOptions={contentOptions} error={error} severity="error" />;
  }

  return (
    <Box data-qa={dataQa}>
      <TabsContainer
        getTabComponent={getTabComponent}
        tabSx={{ borderBottom: `2px solid ${palette.divider}` }}
        tabs={tabsData}
        variant="fullWidth"
      />
    </Box>
  );
};
