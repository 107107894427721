import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

import { useGetReturnsData } from './symphony';
import { ReturnsByPeriod } from './types';

import { PerformanceMeasurementInput } from '~/__generated__';
import { MeasurementName } from '~/hooks/performance/types';
import { AsyncResult } from '~/utils/types';

interface UseReturnsVariables {
  accounts: string[];
  measurements: PerformanceMeasurementInput[];
  skip: boolean;
}

const isMeasurementName = (name: string): name is MeasurementName =>
  Object.values<string>(MeasurementName).includes(name);

export const useReturns = ({ accounts, measurements, skip }: UseReturnsVariables): AsyncResult<ReturnsByPeriod> => {
  const [state, setState] = useState<AsyncResult<ReturnsByPeriod>>({ loading: false });

  const { data: returnsData, error: returnsDataError, loading: returnsDataLoading } = useGetReturnsData({
    variables: {
      accounts,
      measurements,
    },
    skip,
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (returnsDataLoading) {
      setState({ loading: true });
      return;
    }
    if (returnsDataError) {
      setState({ loading: false, error: returnsDataError });
      return;
    }
    setState({
      data: returnsData?.performance?.reduce<ReturnsByPeriod>(
        (acc, { measurement: { from, to, name }, rateOfReturns }) => {
          if (name && isMeasurementName(name)) {
            acc[name] = {
              from: parseISO(from),
              to: parseISO(to),
              value: rateOfReturns?.cumulative ?? undefined,
            };
          }
          return acc;
        },
        {},
      ),
      loading: false,
    });
  }, [returnsData, returnsDataError, returnsDataLoading]);

  return state;
};
