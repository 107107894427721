import { useCallback, useMemo, useState } from 'react';

import {
  FilterConfig,
  FilterData,
  FilterValues,
  TransactionTypeFilter,
} from '~/components/AccountDetailsAccountActivity/types';
import { useDateFilterData } from '~/components/ControlFields/DateFilters/hooks/useDateFilterData';
import { DateFilterValues } from '~/components/ControlFields/DateFilters/types';

export const useFilterData = (
  defaultFilterConfig: FilterConfig,
  defaultFilterValues: FilterValues,
  onValueChange?: (values: FilterValues) => void,
): FilterData => {
  const [values, setValues] = useState<FilterValues>(defaultFilterValues);

  const onDateFilterValueChange = (value: DateFilterValues) => {
    setValues(prev => {
      onValueChange?.({ ...prev, ...value });
      return {
        ...prev,
        ...value,
      };
    });
  };

  const { config: dateFilterConfig, onCustomDateRangeChange, onDateRangeOptionChange } = useDateFilterData(
    {
      customDateRange: defaultFilterConfig.customDateRange,
      dateRangeOption: defaultFilterConfig.dateRangeOption,
    },
    {
      dateRange: defaultFilterValues.dateRange,
      dateRangeOption: defaultFilterValues.dateRangeOption,
    },
    onDateFilterValueChange,
  );

  const onTransactionTypeOptionChange = useCallback((transactionType: TransactionTypeFilter) => {
    setValues(prev => {
      onValueChange?.({ ...prev, transactionType });
      return {
        ...prev,
        transactionType,
      };
    });
  }, []);

  const config = useMemo((): FilterConfig => {
    return {
      ...dateFilterConfig,
      transactionTypeOption: defaultFilterConfig.transactionTypeOption
        ? {
            ...defaultFilterConfig.transactionTypeOption,
            value: values.transactionType,
          }
        : undefined,
    };
  }, [dateFilterConfig, defaultFilterConfig, values]);

  return useMemo(
    (): FilterData => ({
      config,
      onCustomDateRangeChange,
      onDateRangeOptionChange,
      onTransactionTypeOptionChange,
      values,
    }),
    [config, onCustomDateRangeChange, onDateRangeOptionChange, onTransactionTypeOptionChange, values],
  );
};
