import {
  GetBankVerificationContent_all_bank_verification_table_items_columns,
  GetBankVerificationContent_all_bank_verification_table_items_labels,
} from '../contentstack/__generated__/query.v2';

import {
  BankVerificationLabel,
  BankVerificationTableColumn,
  StatusContentValue,
  StatusFilterContentValue,
  VerificationStatusFilter,
} from './types';

import { FinancialAccountAssociationVerificationStatus } from '~/__generated__';
/**
 * @param {VerificationStatusFilter}  statusFilter - Value of Status Filter defined as enum in utils/types.ts
 * @returns {FinancialAccountAssociationVerificationStatus[]} Returns of list of Verification Status associated to a financial account based on statusFilter passed to the function.
 */
export const getFinancialAccountAssociationVerificationStatusFromVerificationStatusFilter = (
  statusFilter: VerificationStatusFilter,
): FinancialAccountAssociationVerificationStatus[] => {
  const financialAccountAssociationVerificationStatus: FinancialAccountAssociationVerificationStatus[] = [];
  switch (statusFilter) {
    case VerificationStatusFilter.ACCEPTED:
      financialAccountAssociationVerificationStatus.push(FinancialAccountAssociationVerificationStatus.ACCEPTED);
      break;
    case VerificationStatusFilter.REJECTED:
      financialAccountAssociationVerificationStatus.push(FinancialAccountAssociationVerificationStatus.REJECTED);
      break;
    case VerificationStatusFilter.IN_REVIEW:
      financialAccountAssociationVerificationStatus.push(FinancialAccountAssociationVerificationStatus.IN_REVIEW);
      break;
    default:
      financialAccountAssociationVerificationStatus.push(
        FinancialAccountAssociationVerificationStatus.ACCEPTED,
        FinancialAccountAssociationVerificationStatus.REJECTED,
        FinancialAccountAssociationVerificationStatus.IN_REVIEW,
      );
      break;
  }
  return financialAccountAssociationVerificationStatus;
};

/**
 * @param {VerificationStatusFilter}  statusFilter - Value of Status Filter defined as enum in utils/types.ts
 * @param {StatusFilterContentValue[]}  bankVerificationStatusFilterContent - List of object containing key value pairs of Statuses to be shown in the Filter Dropdown. Data originates from Content Stack
 * @returns {string} Returns value of status filter defined in content stack based on statusFilter passed to the function.
 */
export const getBankVerificationStatusFilterValue = (
  statusFilter: VerificationStatusFilter,
  bankVerificationStatusFilterContent: StatusFilterContentValue[],
): string => bankVerificationStatusFilterContent.find(v => v.key === statusFilter.toString())?.value ?? '';

/**
 * @param {FinancialAccountAssociationVerificationStatus}  status - Status of Financial Account Associated to a bank which failed plaid's fuzzy matching logic.
 * @param {StatusFilterContentValue[]}  bankVerificationStatusContent - List of object containing key value pairs of Statuses to be shown in the Status Column. Data originates from Content Stack
 * @returns {string} Returns value of status defined in content stack based on status passed to the function.
 */
export const getBankVerificationStatusValue = (
  status: FinancialAccountAssociationVerificationStatus,
  bankVerificationStatusContent: StatusContentValue[],
): string => bankVerificationStatusContent.find(v => v.key === status.toString())?.value ?? '';

/**
 * @param {BankVerificationTableColumn}  column - Value of Status Filter defined as enum in utils/types.ts
 * @param {(GetBankVerificationContent_all_bank_verification_table_items_columns | null)[]}  bankVerificationColumnContent - List of object containing key value pairs of Columns of Bank Verification Table. Key is the column key/id and value is the text displayed to the user. Data originates from Content Stack
 * @returns {FinancialAccountAssociationVerificationStatus[]} Returns of list of Verification Status associated to a financial account based on statusFilter passed to the function.
 */
export const getBankVerificationColumnValue = (
  column: BankVerificationTableColumn,
  bankVerificationColumnContent: (GetBankVerificationContent_all_bank_verification_table_items_columns | null)[],
): string => bankVerificationColumnContent.find(v => v?.column_key === column.toString())?.column_value ?? '';

/**
 * @param {(GetBankVerificationContent_all_bank_verification_table_items_labels | null)[]}  bankVerificationLabelContent - List of object containing key value pairs of Labels for Bank Verification Table. Key is mapped to the enum BankVerificationLabel defined in utils/types and value is the text displayed to the user. Data originates from Content Stack
 * @returns {FinancialAccountAssociationVerificationStatus[]} Returns of list of Verification Status associated to a financial account based on statusFilter passed to the function.
 */
export const getBankVerificationLabelContent = (
  bankVerificationLabelContent: (GetBankVerificationContent_all_bank_verification_table_items_labels | null)[],
) => {
  const feedbackMessage =
    bankVerificationLabelContent.find(v => v?.label_key === BankVerificationLabel.FEEDBACK_MESSAGE)?.label_value ?? '';
  const reviewButtonLabel =
    bankVerificationLabelContent.find(v => v?.label_key === BankVerificationLabel.REVIEW_BUTTON_TEXT)?.label_value ??
    '';
  const filterDropdownLabel =
    bankVerificationLabelContent.find(v => v?.label_key === BankVerificationLabel.FILTER_DROPDOWN_LABEL)?.label_value ??
    '';

  return {
    feedbackMessage,
    reviewButtonLabel,
    filterDropdownLabel,
  };
};
