import React, { ComponentProps, FC, useCallback, useMemo } from 'react';

import { AccountList, Content } from './AccountList';
import { AccountTotalSection } from './AccountTotalSection';
import { BackToMarketingPage } from './BackToMarketingPage';
import { ConfirmDiscardPendingApplicationModal } from './ConfirmDiscardPendingApplicationModal';
import { ConfirmNewAccountModal } from './ConfirmNewAccountModal';
import { Greeting } from './Greeting';
import { useAccountSummaryContent, useAccountSummaryContentV2, useRmdWithdrawFundsModal } from './hooks';
import { AugmentableConfig, usePartialAccountModals } from './hooks/usePartialAccountModals';
import { AccountSummaryTradingSuspensions, useGetDigitalWealthAccounts } from './symphony';
import { AccountSummaryGetDigitalWealthAccountsVariables } from './symphony/__generated__/query.v2';
import { AccountActionToInvoke, AccountDetailsToShow, AccountProps as AccountProps } from './types';
import {
  AccountSummaryFeatureFlags,
  findMostRecentEffectiveDate,
  getAccounts,
  getClientNameToDisplay,
  getFinancialAccountsForGoalSummary,
  getRebalancedAccounts,
} from './utils';

import {
  AssociatedEntityType,
  FinancialAccountStatus,
  FinancialAccountType,
  LegalDocumentStatus,
  ManagedProductType,
  OnboardingStates,
  PerformanceMethodTypes,
} from '~/__generated__/symphonyTypes.v2';
import { isCustomAction } from '~/components/AccountActions/utils';
import { AccountSectionCard } from '~/components/AccountSummary/AccountSectionCard';
import { ErrorComponent } from '~/components/ErrorComponent';
import { ContactAdvisorModal } from '~/components/modals/ContactAdvisor';
import CustodialMinorModal from '~/components/modals/CustodialMinorModal';
import { WithdrawFundsModal } from '~/components/modals/WithdrawFunds';
import { RmdNotification } from '~/components/Rmd/RmdNotification';
import { SupportContact } from '~/components/SupportContact';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Button, Skeleton, Stack } from '~/components/ui/mui';
import { Snackbar } from '~/components/ui/Snackbar';
import { useGetClientInfo } from '~/hooks/client/symphony';
import { useAccountActionsMenu } from '~/hooks/client/useAccountActionsMenu';
import { useModelPortfoliosContent } from '~/hooks/model-portfolios/useModelPortfoliosContent';
import { PlaidLinkType } from '~/hooks/plaid-link';
import { isAccountVisible } from '~/utils/account';
import { NetworkStatus } from '~/utils/apollo-client';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { getEmail } from '~/utils/client';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions, Product } from '~/utils/contentstack/src/types';
import { calculateAge } from '~/utils/format/date';
import { useIsMediumScreen } from '~/utils/responsiveness';

export { AccountState } from '~/utils/account';

export type AccountSummarySectionBlock =
  | 'accountList'
  | 'accountTotal'
  | 'clientInfo'
  | 'marketing'
  | 'performanceChart'
  | 'supportQuestions';

export const ViewerPartyIdContext = React.createContext<string>('');

export interface Props {
  accountCompareFunction?: ComponentProps<typeof AccountList>['accountCompareFunction']; // to sort account list
  accountDetailsToShow?: AccountDetailsToShow;
  accountProps?: Partial<AccountProps>; // TODO: Move all Account Component Props to this Prop item.
  accountSummaryGetDigitalWealthAccountsVariables?: AccountSummaryGetDigitalWealthAccountsVariables;
  accountTotalSectionProps?: {
    goalSummaryProps?: Omit<
      NonNullable<ComponentProps<typeof AccountTotalSection>['goalSummaryProps']>,
      'onAccountClick' | 'financialAccounts'
    >;
  };
  accountsFilter?: (status: FinancialAccountStatus, onboardingState?: OnboardingStates) => boolean;
  actionToInvoke?: AccountActionToInvoke;
  actions: AugmentableConfig;
  assetClassTier?: (program: ManagedProductType) => AssetClassTier;
  checkClientAge?: boolean;
  contentOptions: ContentOptions;
  ctasOverrides?: Partial<Content['ctas']>;
  dataQa?: string;
  docusignLegalDocumentStatus?: LegalDocumentStatus;
  featureFlags?: AccountSummaryFeatureFlags;
  hiddenSections?: AccountSummarySectionBlock[];
  onRetakeQuestionnaire?: (managedProductId: string) => void;
  partyId: string;
  performanceMethod?: PerformanceMethodTypes;
  plaidLinkageType?: PlaidLinkType;
  redirectToAccountDetails?: (managedProductId: string) => void;
  redirectToFaDashboard?: () => void;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  showLearnMoreMarketingCta?: boolean;
  tradingSuspensionsFilter?: (suspension: AccountSummaryTradingSuspensions) => boolean;
  viewerPartyId: string;
}

export const AccountSummary: FC<Props> = ({
  accountCompareFunction,
  accountDetailsToShow,
  accountProps,
  accountSummaryGetDigitalWealthAccountsVariables,
  accountTotalSectionProps,
  accountsFilter,
  actions,
  actionToInvoke,
  assetClassTier: getAssetClassTier,
  checkClientAge,
  contentOptions,
  ctasOverrides,
  dataQa = 'account-summary',
  docusignLegalDocumentStatus,
  featureFlags = { ignoreInsufficientFunds: false, performanceChartTo: 'today' },
  hiddenSections,
  onRetakeQuestionnaire,
  partyId,
  performanceMethod,
  plaidLinkageType,
  redirectToAccountDetails,
  redirectToFaDashboard,
  redirectToSignDocuments,
  showLearnMoreMarketingCta,
  tradingSuspensionsFilter,
  viewerPartyId,
}) => {
  const {
    defaultContributionAmount,
    ignoreInsufficientFunds,
    showVerifiedBankAccounts,
    syncExternalBankAccounts,
    performanceChartTo,
  } = featureFlags;
  const isMobile = useIsMediumScreen();
  const showClientInfo = !hiddenSections?.includes('clientInfo');
  const {
    components: {
      sfAccountSummary: { activeAccountsOnly, fallBackToStagedModelPortfolio, minorAgeLimit, showContactAdvisorCta },
      sfSupportContact,
    },
    featureFlags: { rmdEnabled, showErrorComponentOnInvalidPartyId },
  } = useCoreConfig();

  const { data: clientNameData, loading: clientNameLoading, error: clientNameError } = useGetClientInfo({
    variables: { partyId },
    skip: !checkClientAge && !showClientInfo,
  });
  const {
    data: accountsData,
    loading: accountsLoading,
    refetch: refetchAccounts,
    networkStatus: accountsNetworkStatus,
  } = useGetDigitalWealthAccounts({
    variables: {
      activeAccountsOnly,
      partyId,
      withModelPortfolio: !!accountDetailsToShow?.showModelPortfolioInfo,
      withLegalDocuments: true,
      withStagedModelPortfolio: !!accountDetailsToShow?.showModelPortfolioInfo && fallBackToStagedModelPortfolio,
      ...accountSummaryGetDigitalWealthAccountsVariables,
    },
    // TODO: Add proper error handling
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const isAccountsRefetching = accountsNetworkStatus === NetworkStatus.refetch;

  const { data: modelPortfoliosContent, loading: modelPortfoliosContentLoading } = useModelPortfoliosContent({
    contentOptions,
  });

  const { isClientAMinor }: { isClientAMinor: boolean } = useMemo(() => {
    const age = calculateAge(clientNameData?.client?.party?.partyPerson?.birthDate ?? '');
    return { isClientAMinor: age < minorAgeLimit };
  }, [clientNameData?.client?.party?.partyPerson?.birthDate]);

  const allAccounts = useMemo(() => {
    if (!accountsData || !modelPortfoliosContent) {
      return [];
    }
    return getAccounts(
      contentOptions.locale,
      accountsData,
      partyId,
      modelPortfoliosContent.nameParser,
      tradingSuspensionsFilter,
    );
  }, [accountsData, contentOptions.locale, modelPortfoliosContent, partyId, tradingSuspensionsFilter]);
  // visible accounts
  const accounts = useMemo(() => {
    return allAccounts
      .filter(account => (accountsFilter ?? isAccountVisible)(account.status, account.onboardingState))
      .filter(account => (isClientAMinor ? account.type === FinancialAccountType.UTMA : true));
  }, [accountsFilter, allAccounts]);
  const accountsEffectiveDate = useMemo(() => findMostRecentEffectiveDate(accounts), [accounts]);
  const rebalancedAccounts = useMemo(() => getRebalancedAccounts(accounts), [accounts]);
  const harvestLosses = accountsData?.client?.kyc?.harvestLosses;

  if (
    featureFlags.redirectToAccountDetailsIfSingleAccount &&
    rebalancedAccounts.length === 1 &&
    redirectToAccountDetails
  ) {
    redirectToAccountDetails(rebalancedAccounts[0].managedProductId);
  }

  const {
    data: summaryContentData,
    error: summaryContentError,
    loading: summaryContentLoading,
  } = useAccountSummaryContent({
    contentOptions,
    hiddenSections,
  });

  // TODO: ANR-8782: Migrate All the content used for Account Summary to Content V2
  const {
    data: summaryV2ContentData,
    error: summaryV2ContentError,
    loading: summaryV2ContentLoading,
  } = useAccountSummaryContentV2({ contentOptions });

  const { data: accountActionsItems } = useAccountActionsMenu({
    contentOptions,
    harvestLosses,
  });

  const {
    confirmDiscardPendingApplicationModalProps,
    confirmNewAccountModalProps,
    partialAccountActionsConfig,
  } = usePartialAccountModals({
    accountsFilter,
    actions,
    allAccounts,
    contentOptions,
    partyId,
    refetchAccounts,
  });

  const docusignFeedbackMessage = summaryContentData?.docusignContent.getFeedbackMessage(docusignLegalDocumentStatus);

  const contentLoading = summaryContentLoading || summaryV2ContentLoading || modelPortfoliosContentLoading;

  const {
    open: isContactAdvisorModalOpen,
    openModal: onOpenContactAdvisorModal,
    onClose: onCloseContactAdvisorModal,
  } = useModalState();

  const {
    data: rmdWithdrawFundsModalData,
    loading: rmdWithdrawFundsModalLoading,
    error: rmdWithdrawFundsModalError,
  } = useRmdWithdrawFundsModal({
    accounts,
    contentOptions,
    defaultWithdrawalAmount: defaultContributionAmount,
    onWithdrawSuccess: refetchAccounts,
    partyId,
    partyIdFA: accountProps?.partyIdFA,
    plaidLinkageType,
    redirectToSignDocuments,
    showVerifiedBankAccounts,
    syncExternalBankAccounts,
  });

  const onBackToMarketing = useCallback(() => {
    if (isCustomAction(actions.backToMarketing)) {
      actions.backToMarketing.callback({ partyId });
    }
  }, [actions.backToMarketing, partyId]);

  return (
    <ViewerPartyIdContext.Provider value={viewerPartyId}>
      {clientNameError || summaryContentError || summaryV2ContentError ? (
        <ErrorComponent
          contentOptions={contentOptions}
          error={clientNameError ?? (summaryContentError || summaryV2ContentError)}
          isAuthorizationError={clientNameError && showErrorComponentOnInvalidPartyId}
        />
      ) : (
        <Stack data-qa={dataQa} spacing={{ xs: 3, md: 6 }}>
          {showClientInfo && (
            <Box sx={{ my: isMobile ? 3 : 8 }}>
              {clientNameLoading || contentLoading ? (
                <Skeleton data-qa={`${dataQa}-greeting-loading`} width="10%" />
              ) : (
                <Stack direction="row" justifyContent="space-between">
                  <Greeting
                    clientEmail={getEmail(clientNameData?.client?.party)}
                    clientName={getClientNameToDisplay(
                      clientNameData?.client?.party?.partyPerson,
                      contentOptions.product,
                    )}
                    dataQa={`${dataQa}-greeting`}
                    showEmail={[Product.DigitalWealthPro, Product.OpsDashboard].includes(contentOptions.product)}
                    {...summaryContentData?.headerContent}
                  />
                  {showContactAdvisorCta && (
                    <Button onClick={onOpenContactAdvisorModal} variant="contained">
                      {summaryContentData?.headerContent.contactAdvisorCta}
                    </Button>
                  )}
                </Stack>
              )}
            </Box>
          )}
          {rmdEnabled && summaryV2ContentData?.rmdNotification && rmdWithdrawFundsModalData && !!accounts.length && (
            <>
              <RmdNotification
                content={summaryV2ContentData.rmdNotification}
                contentOptions={contentOptions}
                hideWithdrawCta={!(actions.withdrawFunds?.valid?.({ isClientLevel: true }) ?? false)}
                openWithdrawFundsModal={rmdWithdrawFundsModalData.handleOpenRmdWithdrawFundsModal}
                partyId={partyId}
                reload={isAccountsRefetching}
              />
              {!rmdWithdrawFundsModalLoading &&
                !rmdWithdrawFundsModalError &&
                rmdWithdrawFundsModalData.withdrawFundsModalProps.managedProductId && (
                  <WithdrawFundsModal
                    {...rmdWithdrawFundsModalData.withdrawFundsModalProps}
                    onSourceAccountChange={rmdWithdrawFundsModalData.onSourceAccountChange}
                    open={rmdWithdrawFundsModalData.isWithdrawalModalOpen}
                  />
                )}
            </>
          )}
          {contentLoading && <AccountSectionCard dataQa={`${dataQa}-content-loading`} loading />}
          {summaryContentData?.sectionContent &&
            Object.keys(summaryContentData.sectionContent).map(section => {
              switch (section) {
                case 'marketing':
                  if (!summaryContentData.sectionContent.marketing) {
                    return null;
                  }
                  const {
                    ctas: marketingCtas,
                    ...restOfMarketingContent
                  } = summaryContentData.sectionContent.marketing;
                  return (
                    <BackToMarketingPage
                      key={section}
                      learnMore={{
                        ctaText: marketingCtas.secondary,
                        callback: onBackToMarketing,
                      }}
                      partyId={partyId}
                      showLearnMoreMarketingCta={showLearnMoreMarketingCta}
                      {...restOfMarketingContent}
                    />
                  );
                case 'supportQuestions':
                  if (!summaryContentData.sectionContent.supportQuestions) {
                    return null;
                  }
                  return (
                    <AccountSectionCard key={section}>
                      <SupportContact
                        dataQa={`${dataQa}-question`}
                        onLaunchSchedulingTool={
                          isCustomAction(actions.launchSchedulingTool)
                            ? actions.launchSchedulingTool.callback
                            : undefined
                        }
                        onOpenContactAdvisorModal={onOpenContactAdvisorModal}
                        {...summaryContentData.sectionContent.supportQuestions}
                      />
                    </AccountSectionCard>
                  );
                case 'accountTotal':
                  return (
                    <AccountTotalSection
                      accountsEffectiveDate={accountsEffectiveDate}
                      content={{
                        ...summaryContentData.sectionContent.accountTotal,
                        ...summaryV2ContentData?.sectionContent.accountTotal,
                      }}
                      contentOptions={contentOptions}
                      goalSummaryProps={
                        accountTotalSectionProps?.goalSummaryProps
                          ? {
                              financialAccounts: getFinancialAccountsForGoalSummary(accountsData),
                              onAccountClick: managedProductId => redirectToAccountDetails?.(managedProductId),
                              ...accountTotalSectionProps.goalSummaryProps,
                            }
                          : undefined
                      }
                      hidePerformanceView={hiddenSections?.includes('performanceChart')}
                      key={section}
                      partyId={partyId}
                      performanceChartTo={performanceChartTo}
                      rebalancedAccounts={rebalancedAccounts}
                      rebalancedAccountsLoading={accountsLoading}
                    />
                  );
                case 'accountList':
                  if (!summaryContentData.sectionContent.accountList) {
                    return null;
                  }
                  const {
                    ctas: accountListCtas,
                    ...restOfAccountListContent
                  } = summaryContentData.sectionContent.accountList;
                  return (
                    <AccountSectionCard
                      dataQa={`${dataQa}-accounts`}
                      key={section}
                      loading={accountsLoading && !isAccountsRefetching}
                    >
                      <AccountList
                        accountCompareFunction={accountCompareFunction}
                        accountDetailsToShow={accountDetailsToShow}
                        accountProps={accountProps}
                        accounts={accounts}
                        assetClassTier={getAssetClassTier}
                        content={{
                          ...restOfAccountListContent,
                          ctas: { ...accountListCtas, ...ctasOverrides },
                          ctasMoreAction: accountActionsItems ?? [],
                          actionToInvoke,
                          actions: {
                            ...actions,
                            ...partialAccountActionsConfig,
                          },
                          modelPortfolioDonutDimensions: {
                            size: 64,
                            innerRadius: 10,
                          },
                        }}
                        contentOptions={contentOptions}
                        contentV2={summaryV2ContentData?.sectionContent.accountList}
                        defaultContributionAmount={defaultContributionAmount}
                        effectiveDate={accountsEffectiveDate}
                        ignoreInsufficientFunds={ignoreInsufficientFunds}
                        onRetakeQuestionnaire={onRetakeQuestionnaire}
                        partyId={partyId}
                        performanceMethod={performanceMethod}
                        plaidLinkageType={plaidLinkageType}
                        redirectToSignDocuments={redirectToSignDocuments}
                        refetchAccounts={refetchAccounts}
                        refetchingAccounts={isAccountsRefetching}
                        showVerifiedBankAccounts={showVerifiedBankAccounts}
                        syncExternalBankAccounts={syncExternalBankAccounts}
                      />
                    </AccountSectionCard>
                  );
                default:
                  return null;
              }
            })}

          {(showContactAdvisorCta || sfSupportContact.showContactAdvisorCta) && (
            <ContactAdvisorModal
              contentOptions={contentOptions}
              onCancel={onCloseContactAdvisorModal}
              onClose={onCloseContactAdvisorModal}
              open={isContactAdvisorModalOpen}
              partyId={partyId}
              supportEmail={summaryContentData?.sectionContent.supportQuestions?.supportEmail}
              supportPhoneNumber={summaryContentData?.sectionContent.supportQuestions?.supportPhoneNumber}
            />
          )}
          {confirmNewAccountModalProps && <ConfirmNewAccountModal {...confirmNewAccountModalProps} />}
          {confirmDiscardPendingApplicationModalProps && (
            <ConfirmDiscardPendingApplicationModal {...confirmDiscardPendingApplicationModalProps} />
          )}
          {checkClientAge && isClientAMinor && (
            <CustodialMinorModal
              contentOptions={contentOptions}
              onCloseCallback={redirectToFaDashboard}
              open={isClientAMinor}
            />
          )}
          {docusignFeedbackMessage && (
            <Snackbar
              closeButtonLabel={summaryContentData?.docusignContent.closeCta}
              dataQa={dataQa}
              defaultOpen={!!docusignLegalDocumentStatus}
              feedbackMessage={docusignFeedbackMessage}
            />
          )}
        </Stack>
      )}
    </ViewerPartyIdContext.Provider>
  );
};
