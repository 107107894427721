import { GetWithDrawFundsV2Content } from '../contentstack/__generated__/query.v2';
import { RmdSummaryContent, WithdrawFundsContentKeys, WithdrawFundsV2Content } from '../types';

import { TransferFrequency } from '~/__generated__';
import { getRmdConfirmationModalContent } from '~/components/Rmd/utils';
import { findFieldValue, getImageAssetUrl } from '~/utils/contentstack';

export const DEFAULT_NUMBER_OF_OCCURRENCE = 0;
export const DEFAULT_FORM_DATA = {
  withdrawalFrequency: TransferFrequency.ONE_TIME,
  withdrawalAmount: '',
  eligibleDestinationBankAccount: '',
};

const getRmdSummaryContent = (content?: GetWithDrawFundsV2Content): RmdSummaryContent => {
  const textItems = content?.all_withdraw_funds_modal_v2?.items?.[0]?.fields?.text ?? [];
  const rteItems = content?.all_withdraw_funds_modal_v2?.items?.[0]?.fields?.rte ?? [];

  return {
    ...getRmdConfirmationModalContent(textItems),
    requirementFulfilledCTA: findFieldValue(textItems, WithdrawFundsContentKeys.REQUIREMENT_FULFILLED_CTA),
    withdrawByWithDate: findFieldValue(rteItems, WithdrawFundsContentKeys.RMD_AMOUNT_TO_WITHDRAW_BY_DATE),
    breakdownSummaryItems: {
      toggleText: {
        show: findFieldValue(textItems, WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_TOGGLE_TEXT_SHOW),
        hide: findFieldValue(textItems, WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_TOGGLE_TEXT_HIDE),
      },
      title: findFieldValue(textItems, WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_TITLE),
      accountCount: findFieldValue(textItems, WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_ACCOUNT_COUNT),
      accountCountMultiple: findFieldValue(
        rteItems,
        WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_ACCOUNT_COUNT_MULTIPLE,
      ),
      accountCountTooltip: findFieldValue(
        textItems,
        WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_ACCOUNT_COUNT_TOOLTIP,
      ),
      partialAccountCountTooltip: findFieldValue(
        rteItems,
        WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_PARTIAL_ACCOUNT_COUNT_TOOLTIP,
      ),
      accountCountTooltipJoiningWord: findFieldValue(
        textItems,
        WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_ACCOUNT_COUNT_TOOLTIP_JOINING_WORD,
      ),
      accountWithIneligibleCount: findFieldValue(
        rteItems,
        WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_ACCOUNT_WITH_INELIGIBLE_COUNT,
      ),
      totalRMDText: findFieldValue(textItems, WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_TOTAL_RMD),
      amountWithdrawnText: findFieldValue(textItems, WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_AMOUNT_WITHDRAWN),
      scheduledWithdrawalsText: findFieldValue(
        textItems,
        WithdrawFundsContentKeys.RMD_BREAKDOWN_SUMMARY_SCHEDULED_WITHDRAWALS,
      ),
    },
    disclaimer: findFieldValue(textItems, WithdrawFundsContentKeys.RMD_DISCLAIMER),
    title: findFieldValue(textItems, WithdrawFundsContentKeys.RMD_TITLE),
    totalRMDAmountWithDate: findFieldValue(rteItems, WithdrawFundsContentKeys.RMD_TOTAL_AMOUNT_TO_WITHDRAW_BY_DATE),
    totalRMDAmountWithoutDate: findFieldValue(textItems, WithdrawFundsContentKeys.RMD_TOTAL_AMOUNT_TO_WITHDRAW),
  };
};

export const getV2Content = (content?: GetWithDrawFundsV2Content): WithdrawFundsV2Content => {
  const textItems = content?.all_withdraw_funds_modal_v2?.items?.[0]?.fields?.text ?? [];
  const rteItems = content?.all_withdraw_funds_modal_v2?.items?.[0]?.fields?.rte ?? [];
  const imageItems = content?.all_withdraw_funds_modal_v2?.items?.[0]?.fields?.image ?? [];

  return {
    success: {
      actionLabel: findFieldValue(textItems, WithdrawFundsContentKeys.SUCCESS_ACTION_LABEL),
      alertText: findFieldValue(textItems, WithdrawFundsContentKeys.SUCCESS_ALERT_TEXT),
      completedMessage: findFieldValue(textItems, WithdrawFundsContentKeys.SUCCESS_COMPLETED_MESSAGE),
      image: getImageAssetUrl(imageItems.find(i => i?.key === WithdrawFundsContentKeys.SUCCESS_IMAGE)?.imageConnection),
      rmdSuccessWithOneRemaining: findFieldValue(rteItems, WithdrawFundsContentKeys.RMD_SUCCESS_WITH_ONE_REMAINING),
      rmdSuccessWithTwoRemaining: findFieldValue(rteItems, WithdrawFundsContentKeys.RMD_SUCCESS_WITH_TWO_REMAINING),
      title: findFieldValue(textItems, WithdrawFundsContentKeys.SUCCESS_TITLE),
      newRmdWithdrawalRequestActionLabel: findFieldValue(
        textItems,
        WithdrawFundsContentKeys.NEW_RMD_REQUEST_SUCCESS_ACTION_LABEL,
      ),
    },
    rmdSummary: getRmdSummaryContent(content),
    sourceAccount: {
      label: findFieldValue(textItems, WithdrawFundsContentKeys.SOURCE_ACCOUNT_LABEL),
      placeholder: findFieldValue(textItems, WithdrawFundsContentKeys.SOURCE_ACCOUNT_PLACEHOLDER),
    },
    frequencyToggleButtonOptions: {
      oneTime: findFieldValue(textItems, WithdrawFundsContentKeys.FREQUENCY_TOGGLE_BUTTON_ONE_TIME),
      recurring: findFieldValue(textItems, WithdrawFundsContentKeys.FREQUENCY_TOGGLE_BUTTON_RECURRING),
    },
    numberOfOccurrence: {
      defaultHelperText: findFieldValue(textItems, WithdrawFundsContentKeys.NUMBER_OF_OCCURRENCE_DEFAULT_HELPER_TEXT),
      lastDateHelperText: findFieldValue(rteItems, WithdrawFundsContentKeys.NUMBER_OF_OCCURRENCE_LAST_DATE_HELPER_TEXT),
      label: findFieldValue(textItems, WithdrawFundsContentKeys.NUMBER_OF_OCCURRENCE_LABEL),
      placeholder: findFieldValue(textItems, WithdrawFundsContentKeys.NUMBER_OF_OCCURRENCE_PLACEHOLDER),
    },
    moneyOutLabels: {
      moneyOutRestrictionContent: findFieldValue(textItems, WithdrawFundsContentKeys.MONEY_OUT_RESTRICTION_CONTENT),
      moneyOutRestrictionHeading: findFieldValue(textItems, WithdrawFundsContentKeys.MONEY_OUT_RESTRICTION_HEADING),
    },
    plaidReAuthenticaion: findFieldValue(rteItems, 'plaidReAuthenticaion'),
    plaidReAuthenticaionLink: findFieldValue(textItems, 'plaidReAuthenticaionLink'),
    bankSourceCloseCta: findFieldValue(textItems, 'bankSourceCloseCta'),
  };
};
