import React, { useMemo } from 'react';

import { getBankVerificationStatusFilterValue } from '../utils/mappers';
import { StatusFilterContentValue, VerificationStatusFilter } from '../utils/types';

import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';

export type BankVerificationStatusFilterProps = {
  content: {
    statusFilters: StatusFilterContentValue[];
  };
  currentStatus: VerificationStatusFilter;
  onChange: (newStatusFilter: VerificationStatusFilter) => void;
};

export const BankVerificationStatusFilter: React.FC<BankVerificationStatusFilterProps> = ({
  currentStatus,
  content: { statusFilters },
  onChange,
}) => {
  const items: DropdownItem[] = useMemo(
    () =>
      [
        VerificationStatusFilter.ALL,
        VerificationStatusFilter.IN_REVIEW,
        VerificationStatusFilter.REJECTED,
        VerificationStatusFilter.ACCEPTED,
      ].map(statusFilter => {
        return {
          value: statusFilter,
          label: getBankVerificationStatusFilterValue(statusFilter, statusFilters),
        };
      }),
    [statusFilters],
  );

  return (
    <Dropdown
      dataQa="bank-verification-status-filter"
      items={items}
      onChange={e => onChange(e.target.value as VerificationStatusFilter)}
      value={currentStatus}
      width="160px"
    />
  );
};
