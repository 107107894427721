import * as queries from './query.gql';

import {
  GetRiskPreferenceHistoryModalContent,
  GetRiskPreferenceHistoryModalContentVariables,
} from '~/components/modals/RiskPreferenceHistory/contentstack/__generated__/query.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetRiskPreferenceHistoryModalContent = (
  options?: QueryHookOptions<GetRiskPreferenceHistoryModalContent, GetRiskPreferenceHistoryModalContentVariables>,
): QueryResult<GetRiskPreferenceHistoryModalContent, GetRiskPreferenceHistoryModalContentVariables> => {
  return useContentstackQuery(queries.GetRiskPreferenceHistoryModalContent, options);
};
