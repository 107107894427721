// eslint-disable-next-line no-restricted-imports
import { uniq, uniqBy } from 'lodash';
import { ComponentProps } from 'react';

import { TransferStatus, TransferStatusFilter, UrgencyFilter } from '../../types';
import { getColumnKeyValuePair } from '../../utils';
import { FlagsReferenceContent } from '../contentstack';
import {
  GetDepositsTableContentV2,
  GetDepositsTableContentV2_all_deposits_table_items,
  GetDepositsTableContentV2_all_deposits_table_items_status_filters,
  GetDepositsTableContentV2_all_deposits_table_items_statuses,
} from '../contentstack/__generated__/query.v2';

import { MultiCashTransferType, TransferFrequencyType } from '~/__generated__';
import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { FilterConfig, FilterTypes, SelectedFilters } from '~/components/Filters/types';
import { TableColumn } from '~/components/ui/BasicTable';
import { CashTransfersV2_multiCashTransfers_filterContext } from '~/containers/OpsDashboardV2/common/CashTransfer/symphony/__generated__/query.v2';
import { toCashTransferStatus } from '~/containers/OpsDashboardV2/mappers';
import { findFieldValue } from '~/utils/contentstack';
import { ContentstackTableColumn } from '~/utils/table';

export type DepositDisplayStrings = {
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  depositsFilterConfig: FilterConfig[];
  endDate?: string;
  filtersContent: ComponentProps<typeof Filters>['content'];
  flags: FlagsReferenceContent;
  frequencyLabel: string;
  nextOccurrenceLabel: string;
  nonWrappable: string[];
  partitionTabLabels: { allOtherRequests: string; flaggedRequests: string };
  partitionedByFlaggedStatus: boolean;
  recurringButton: { nonRecurring: string; recurring: string };
  rmdNote: string;
  scheduledLabel: string;
  settlementLabel: string;
  status: Record<TransferStatus, string>;
  statusFilter: Record<TransferStatusFilter, string>;
  tableHeaders: TableColumn[];
  transferToAccountNote: string;
};

export enum DepositsFilters {
  FREQUENCY = 'FREQUENCY',
  STATUS = 'STATUS',
  URGENCY = 'URGENCY',
}

const depositsFilterConfig = [
  {
    key: DepositsFilters.STATUS,
    options: [
      {
        id: TransferStatus.PENDING,
      },
      {
        id: TransferStatus.IN_PROGRESS,
      },
      {
        id: TransferStatus.PENDING_CANCELLATION,
      },
      {
        id: TransferStatus.CANCELLED,
      },
      {
        id: TransferStatus.COMPLETED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
  {
    key: DepositsFilters.FREQUENCY,
    options: [
      {
        id: TransferFrequencyType.ONE_TIME,
      },
      {
        id: TransferFrequencyType.RECURRING,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
  {
    key: DepositsFilters.URGENCY,
    options: [
      {
        id: UrgencyFilter.ESCALATED,
      },
      {
        id: UrgencyFilter.NOT_ESCALATED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
];

export const defaultAppliedFilters: SelectedFilters = {
  [DepositsFilters.STATUS]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      TransferStatus.PENDING,
      TransferStatus.IN_PROGRESS,
      TransferStatus.PENDING_CANCELLATION,
      TransferStatus.CANCELLED,
      TransferStatus.COMPLETED,
    ],
  },
  [DepositsFilters.FREQUENCY]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [TransferFrequencyType.ONE_TIME, TransferFrequencyType.RECURRING],
  },
  [DepositsFilters.URGENCY]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [UrgencyFilter.ESCALATED, UrgencyFilter.NOT_ESCALATED],
  },
};

export const generateDepositStaticDisplayStrings = (content: GetDepositsTableContentV2): DepositDisplayStrings => {
  const items = content.all_deposits_table?.items?.[0];

  const recurringColumns = getColumnKeyValuePair(
    items?.columns?.column as ContentstackTableColumn[],
    items?.columns?.recurring?.recurring_column as ContentstackTableColumn[],
  );

  const nonRecurringColumns = getColumnKeyValuePair(
    items?.columns?.column as ContentstackTableColumn[],
    items?.columns?.non_recurring?.non_recurring_column as ContentstackTableColumn[],
  );

  const flagsContent = items?.flag_referenceConnection?.edges?.[0]?.node ?? ({} as FlagsReferenceContent);
  const nonWrappableNonRecurring = items?.columns?.non_recurring?.non_wrappable?.flatMap(f => (f ? [f] : [])) ?? [];
  const nonWrappableRecurring = items?.columns?.recurring?.non_wrappable?.flatMap(f => (f ? [f] : [])) ?? [];
  const tableHeaders = [...nonRecurringColumns, ...recurringColumns];
  const nonWrappable = [...nonWrappableNonRecurring, ...nonWrappableRecurring];
  const textFields = items?.fields?.text ?? [];

  return {
    flags: flagsContent,
    recurringButton: { nonRecurring: 'One-time', recurring: 'Recurring' },
    tableHeaders: uniqBy(tableHeaders, 'key'),
    nonWrappable: uniq(nonWrappable),
    settlementLabel: findFieldValue(textFields, 'settlement'),
    nextOccurrenceLabel: findFieldValue(textFields, 'next_occurrence'),
    scheduledLabel: findFieldValue(textFields, 'scheduled'),
    frequencyLabel: findFieldValue(textFields, 'frequency'),
    status: {
      [TransferStatus.IN_PROGRESS]: getStatusLabel(TransferStatus.IN_PROGRESS, items),
      [TransferStatus.CANCELLED]: getStatusLabel(TransferStatus.CANCELLED, items),
      [TransferStatus.COMPLETED]: getStatusLabel(TransferStatus.COMPLETED, items),
      [TransferStatus.PENDING]: getStatusLabel(TransferStatus.PENDING, items),
      [TransferStatus.PENDING_CANCELLATION]: getStatusLabel(TransferStatus.PENDING_CANCELLATION, items),
      [TransferStatus.UNRECOGNIZED]: getStatusLabel(TransferStatus.UNRECOGNIZED, items),
      [TransferStatus.PENDING_CANCELLATION]: getStatusLabel(TransferStatus.PENDING_CANCELLATION, items),
    },
    statusFilter: {
      [TransferStatusFilter.PENDING_IN_PROGRESS]: getStatusFilterLabel(TransferStatusFilter.PENDING_IN_PROGRESS, items),
      [TransferStatusFilter.PENDING]: getStatusFilterLabel(TransferStatusFilter.PENDING, items),
      [TransferStatusFilter.PENDING_CANCELLATION]: getStatusFilterLabel(
        TransferStatusFilter.PENDING_CANCELLATION,
        items,
      ),
      [TransferStatusFilter.IN_PROGRESS]: getStatusFilterLabel(TransferStatusFilter.IN_PROGRESS, items),
      [TransferStatusFilter.COMPLETED]: getStatusFilterLabel(TransferStatusFilter.COMPLETED, items),
      [TransferStatusFilter.CANCELLED]: getStatusFilterLabel(TransferStatusFilter.CANCELLED, items),
    },
    transferToAccountNote: items?.transfer_to_account_note || 'Transfer to Bank Account, linked account ending',
    endDate: items?.end_date || 'End Date',
    partitionedByFlaggedStatus: items?.requests?.partitioned_by_flagged_status || false,
    partitionTabLabels: {
      allOtherRequests: items?.requests?.partition_tabs?.find(x => x?.value === 'all_other_requests')?.label || '',
      flaggedRequests: items?.requests?.partition_tabs?.find(x => x?.value === 'flagged_requests')?.label || '',
    },
    rmdNote: items?.rmd_note || '',
    depositsFilterConfig: depositsFilterConfig.map(filter => {
      return {
        ...filter,
        label: findFieldValue(textFields, `${filter.key.toLowerCase()}_filter`),
        options: filter.options.map(option => {
          return {
            ...option,
            label: findFieldValue(textFields, `${option.id.toLowerCase()}`),
          };
        }),
      };
    }),
    appliedFiltersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      appliedFilters: findFieldValue(textFields, 'applied_filters'),
    },
    filtersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      apply: findFieldValue(textFields, 'apply_cta'),
      filters: findFieldValue(textFields, 'filters_cta'),
      resetAll: findFieldValue(textFields, 'reset_all_cta'),
    },
  };
};

const getStatusLabel = (
  status: TransferStatus,
  items: GetDepositsTableContentV2_all_deposits_table_items | null | undefined,
): string => {
  const key = status.toLowerCase() as keyof GetDepositsTableContentV2_all_deposits_table_items_statuses;
  return items?.statuses?.[key] ?? '';
};

const getStatusFilterLabel = (
  statusFilter: TransferStatusFilter,
  items: GetDepositsTableContentV2_all_deposits_table_items | null | undefined,
): string => {
  const key = statusFilter.toLowerCase() as keyof GetDepositsTableContentV2_all_deposits_table_items_status_filters;
  return items?.status_filters?.[key] ?? '';
};

export const getFilterCountMap = (filterContext: CashTransfersV2_multiCashTransfers_filterContext[]) => {
  const depositTransferType = [MultiCashTransferType.CASH_DEPOSIT, MultiCashTransferType.RECURRING_CASH_DEPOSIT];
  return filterContext
    .filter(
      v =>
        depositTransferType.includes(<MultiCashTransferType>v.filtersKey.multiCashTransferType) &&
        toCashTransferStatus(v.filtersKey.status) !== TransferStatus.UNRECOGNIZED,
    )
    .map(v => {
      return {
        count: v.count,
        filters: {
          [DepositsFilters.STATUS]: toCashTransferStatus(v.filtersKey.status),
          [DepositsFilters.FREQUENCY]:
            v.filtersKey.multiCashTransferType === MultiCashTransferType.CASH_DEPOSIT
              ? TransferFrequencyType.ONE_TIME
              : TransferFrequencyType.RECURRING,
          [DepositsFilters.URGENCY]: v.filtersKey.escalated ? UrgencyFilter.ESCALATED : UrgencyFilter.NOT_ESCALATED,
        },
      };
    });
};
