import { EventType, Handler } from 'mitt';
import { useCallback, useContext, useEffect } from 'react';
import { BusContext, Provider } from 'react-bus';

export const EventBusProvider = Provider;
export type EventBusHandler<TData> = Handler<TData>;

/**
 * If EventBusProvider is not present and as a result the BusContext is unavailable, the event will not get emitted.
 * It will fail silently.
 */
export const useEventBus = <TData = any>(eventName: EventType): EventBusHandler<TData> => {
  const eventBus = useContext(BusContext);

  return useCallback(
    (eventData?: TData) => {
      eventBus?.emit(eventName, eventData);
    },
    [eventBus, eventName],
  );
};

type WildcardHandler<TData> = (type: EventType, event: TData) => void;
export const useEventBusListener = <TData, TEventType extends EventType = '*'>(
  eventName: TEventType,
  listener: TEventType extends '*' ? WildcardHandler<TData> : EventBusHandler<TData>,
): void => {
  const eventBus = useContext(BusContext);
  useEffect(() => {
    eventBus?.on<TData>(eventName, listener);
    return () => {
      eventBus?.off<TData>(eventName, listener);
    };
  }, [eventBus, eventName, listener]);
};
