import { useCallback, useMemo, useState } from 'react';

import { useGetRestrictionsHistoryTabContent } from '../contentstack';
import { useGetRestrictionsHistoryData } from '../symphony';

import {
  getColumns,
  getFormattedRows,
  getRestrictionHistoryContent,
  RestrictionHistoryTableColumns,
  SortFields,
} from './utils';

import { OrderType } from '~/__generated__';
import { SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { ContentOptions, FieldsGroup } from '~/utils/contentstack';
import { getPaginationContext } from '~/utils/table';
import { AsyncResult, PaginationContext } from '~/utils/types';

interface Data {
  columnsData: TableColumn[];
  currentPage: number;
  onPageChange: (currentPage: number) => void;
  paginationContext: PaginationContext;
  sortConfig: SortConfig;
  tableData: TableData[];
  totalPages: number;
}

const initialRecordsPerPage = 10;

const useRestrictionsHistoryData = (
  contentOptions: ContentOptions,
  managedProductId: string,
  fields: FieldsGroup | undefined | null,
): AsyncResult<Data> => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(initialRecordsPerPage);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: RestrictionHistoryTableColumns.Created,
    order: OrderType.DESCENDING,
  });

  const { data: content, error: contentError, loading: contentLoading } = useGetRestrictionsHistoryTabContent({
    variables: contentOptions,
  });

  const { data, error, loading } = useGetRestrictionsHistoryData({
    variables: {
      field: SortFields[sortConfig.field],
      managedProductId,
      order: sortConfig.order,
      pagination: {
        limit: recordsPerPage,
        offset: (currentPage - 1) * recordsPerPage,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const updateRecordsPerPage = useCallback(
    (value: number) => {
      setRecordsPerPage(value);
    },
    [setRecordsPerPage],
  );

  const suspensionsHistoryContent = useMemo(() => getRestrictionHistoryContent(content?.all_restrictions_history), [
    content,
  ]);

  const onSort = useCallback(
    (field: string) => () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      setSortConfig({ order: toggleOrder, field });
      setCurrentPage(1);
    },
    [sortConfig],
  );

  const suspensionsHistoryData: Data = useMemo(() => {
    const total = data?.managedProduct?.multiRestrictionHistory?.paginationContext.total ?? 1;
    const paginationContext = getPaginationContext(fields, currentPage, recordsPerPage, total, updateRecordsPerPage);

    return {
      columnsData: getColumns(suspensionsHistoryContent, onSort),
      currentPage,
      sortConfig,
      tableData:
        getFormattedRows({
          content: suspensionsHistoryContent,
          contentOptions,
          restrictionData: data?.managedProduct?.multiRestrictionHistory?.multiRestrictions ?? [],
        }) ?? [],
      totalPages: Math.ceil(total / recordsPerPage),
      onPageChange: (pageNumber: number) => setCurrentPage(pageNumber),
      paginationContext,
    };
  }, [
    contentOptions,
    currentPage,
    data,
    fields,
    onSort,
    recordsPerPage,
    sortConfig,
    suspensionsHistoryContent,
    updateRecordsPerPage,
  ]);

  return {
    data: suspensionsHistoryData,
    error: contentError ?? error,
    loading: contentLoading || loading,
  };
};

export default useRestrictionsHistoryData;
