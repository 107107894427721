import React, { ComponentProps, FC, ReactNode, useMemo, useState } from 'react';

import { GoalActionArgs } from '../types';

import { Actions } from './Actions';
import { AssociatedAccounts } from './AssociatedAccounts';
import { Attributes } from './Attributes';
import { Heading } from './Heading';
import { ProgressBar } from './ProgressBar';

import { KebabMenuItem } from '~/components/KebabMenu';
import { Divider } from '~/components/ui/Divider';
import { Link } from '~/components/ui/Link';
import {
  ArrowForwardIosIcon,
  Box,
  Button,
  CheckCircleIcon,
  Collapse,
  Grid,
  Paper,
  Stack,
  useTheme,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface GoalsCardAssociatedAccount {
  amount?: string;
  financialAccountTypeLabel?: string;
  label: string;
  onClick?: () => void;
}

interface Content {
  completedDate: string;
  description?: string;
  image: string;
  lastUpdated: string;
  numOfAccountAssociated: string;
  showLess: string;
  showMore: string;
  title: string;
}
type Cta = ComponentProps<typeof Button> & { label: string };

export interface Props {
  associatedAccounts?: GoalsCardAssociatedAccount[];
  associatedAccountsTotal: number;
  attributes?: { label: string; value: ReactNode }[];
  content: Content;
  contentOptions: ContentOptions;
  ctas?: {
    kebabMenuItems?: KebabMenuItem<GoalActionArgs>[];
    primary?: Cta;
    projectionGap?: Cta;
  };
  dataQa?: string;
  dates: {
    completed?: Date;
    lastUpdated?: Date;
  };
  progressBar?: Omit<ComponentProps<typeof ProgressBar>, 'contentOptions' | 'dataQa'>;
  variant?: 'standard' | 'compact';
}

export const GoalsCard: FC<Props> = ({
  associatedAccounts,
  associatedAccountsTotal,
  attributes,
  content,
  contentOptions,
  ctas,
  dataQa = 'goals-card',
  dates,
  progressBar,
  variant = 'standard',
}) => {
  const [showMore, setShowMore] = useState(false);
  const isMobile = useIsMediumScreen();
  const isCompactVariant = variant === 'compact';

  const attributeRow = useMemo(
    () =>
      (attributes || ctas) && (
        <Stack direction="row" justifyContent="space-between" pl={{ xs: 0, md: 2 }} spacing={2}>
          <Attributes attributes={attributes} />
          {!isMobile && <Actions ctas={ctas} />}
        </Stack>
      ),
    [attributes, ctas, isMobile],
  );

  const completionMessage = useMemo(
    () =>
      dates.completed && (
        <Stack direction="row" justifyContent={{ xs: 'left', md: 'right' }} spacing={1}>
          <RteContent
            config={{ date: formatDate(dates.completed, 'MMMM yyyy', { locale: contentOptions.locale }) }}
            data={content.completedDate}
            sx={{ color: 'success.dark' }}
          />
          <CheckCircleIcon color="success" />
        </Stack>
      ),
    [content.completedDate, contentOptions.locale, dates.completed],
  );

  const projectionGapCta = useMemo(
    () =>
      ctas?.projectionGap?.label ? (
        <Box>
          <Button
            data-qa={`${dataQa}-cta`}
            endIcon={<ArrowForwardIosIcon />}
            onClick={ctas.projectionGap.onClick}
            sx={{ justifyContent: 'left', px: 0, '.MuiButton-endIcon svg': { fontSize: 16 } }}
          >
            <Typography component="div" variant="subtitle2">
              {ctas.projectionGap.label}
            </Typography>
          </Button>
        </Box>
      ) : null,
    [ctas?.projectionGap, dataQa],
  );

  return (
    <Paper data-qa={dataQa} sx={{ p: 2 }} variant="outlined">
      {isMobile && (
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <GoalImage src={content.image} />
            {ctas && <Actions ctas={ctas} />}
          </Stack>
          <Stack spacing={2}>
            <Heading content={content} contentOptions={contentOptions} dates={dates} />
            {progressBar && (
              <Box width="fit-content">
                <ProgressBar contentOptions={contentOptions} {...progressBar} />
              </Box>
            )}
            {completionMessage}
            {content.description && <RteContent data={content.description} dataQa={`${dataQa}-body`} />}
          </Stack>

          <Collapse in={showMore}>
            <Stack divider={<Divider />} spacing={1}>
              {projectionGapCta}
              {attributeRow}
              {associatedAccounts && (
                <AssociatedAccounts
                  associatedAccounts={associatedAccounts}
                  associatedAccountsTotal={associatedAccountsTotal}
                  content={content}
                />
              )}
            </Stack>
          </Collapse>
          <Divider />
          <Link aria-controls="goalsCard-content" id="goalsCard-header" onClick={() => setShowMore(!showMore)}>
            {showMore ? content.showLess : content.showMore}
          </Link>
        </Stack>
      )}

      {!isMobile && (
        <Stack divider={<Divider />} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <GoalImage src={content.image} />
            </Grid>
            <Grid item xs>
              <Stack divider={<Divider />} height="100%" justifyContent="space-between" spacing={1} width="100%">
                <Grid container height="100%" justifyContent="space-between">
                  <Grid item xs={isCompactVariant ? true : 7}>
                    <Stack height="100%" justifyContent="space-between">
                      <Heading content={content} contentOptions={contentOptions} dates={dates} />
                      {content.description && <RteContent data={content.description} dataQa={`${dataQa}-body`} />}
                      {projectionGapCta}
                    </Stack>
                  </Grid>
                  <Grid item justifyContent="right" xs={isCompactVariant ? 'auto' : 4}>
                    <Stack height="100%" justifyContent={isCompactVariant ? 'top' : 'center'} spacing={2}>
                      {completionMessage}
                      {progressBar && <ProgressBar contentOptions={contentOptions} {...progressBar} />}
                    </Stack>
                  </Grid>
                  {!isCompactVariant && <Grid item xs={0.3} />}
                </Grid>

                {isCompactVariant && attributeRow}
              </Stack>
            </Grid>
          </Grid>

          {!isCompactVariant && attributeRow}

          {associatedAccounts && (
            <Box pl={1}>
              <AssociatedAccounts
                associatedAccounts={associatedAccounts}
                associatedAccountsTotal={associatedAccountsTotal}
                content={content}
              />
            </Box>
          )}
        </Stack>
      )}
    </Paper>
  );
};

const GoalImage: FC<{ src: string }> = ({ src }) => {
  const { sfGoals } = useTheme();
  return (
    <Box
      alt=""
      component="img"
      data-qa="goals-card-image"
      src={src}
      sx={{
        bgcolor: 'grey.200',
        borderRadius: 1,
        p: { xs: 1, md: 2 },
        objectFit: 'contain',
        height: { xs: '32px', md: '138px' },
        width: { xs: '32px', md: '138px' },
        ...sfGoals.styles.card?.goalImage,
      }}
    />
  );
};
