import { useCallback } from 'react';

import { CreateCliffGoal, CreateCliffGoalVariables } from './__generated__/createCliffGoal.v2';
import { CreateRetirementGoal, CreateRetirementGoalVariables } from './__generated__/createRetirementGoal.v2';
import {
  GetGoalByPartial,
  GetGoalByPartial_getGoalByPartial,
  GetGoalByPartialVariables,
} from './__generated__/getGoalByPartial.v2';
import { GetGoals, GetGoals_getUserByPartyId_goals_items, GetGoalsVariables } from './__generated__/getGoals.v2';
import { GetPartnerConfig, GetPartnerConfigVariables } from './__generated__/getPartnerConfig.v2';
import { GetRetirementTargets, GetRetirementTargetsVariables } from './__generated__/getRetirementTargets.v2';
import { UpdateCliffGoal, UpdateCliffGoalVariables } from './__generated__/updateCliffGoal.v2';
import { UpdateRetirementGoal, UpdateRetirementGoalVariables } from './__generated__/updateRetirementGoal.v2';
import * as createCliffMutation from './createCliffGoal.gql';
import * as createRetirementMutation from './createRetirementGoal.gql';
import * as getGoalByPartialQuery from './getGoalByPartial.gql';
import * as getGoalsQueries from './getGoals.gql';
import * as getPartnerConfigQueries from './getPartnerConfig.gql';
import { GetRetirementTargets as GetRetirementTargetsQuery } from './getRetirementTargets.gql';
import { UpdateCliffGoal as UpdateCliffGoalMutation } from './updateCliffGoal.gql';
import { UpdateRetirementGoal as UpdateRetirementGoalMutation } from './updateRetirementGoal.gql';
import { updateGoalsCache } from './utils';

import { useGoalsMutation, useGoalsQuery, useLazyGoalsQuery } from '~/utils/amplify';
import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult, QueryTuple } from '~/utils/apollo-client';

export const MAX_ACCOUNTS_PER_GOAL = 10; // Currently hardcoded on the AWS backend that is not exposed.

export type GoalItem = GetGoals_getUserByPartyId_goals_items;
export type PartialGoal = GetGoalByPartial_getGoalByPartial;

export const useGetPartnerConfig = (
  options: QueryHookOptions<GetPartnerConfig, GetPartnerConfigVariables>,
): QueryResult<GetPartnerConfig, GetPartnerConfigVariables> =>
  useGoalsQuery(getPartnerConfigQueries.GetPartnerConfig, options);

export const useGetGoals = (
  options?: QueryHookOptions<GetGoals, GetGoalsVariables>,
): QueryResult<GetGoals, GetGoalsVariables> => {
  return useGoalsQuery(getGoalsQueries.GetGoals, options);
};

export const useCreateCliffGoal = (): MutationTuple<CreateCliffGoal, CreateCliffGoalVariables> =>
  useGoalsMutation(createCliffMutation.CreateCliffGoal);

export const useCreateRetirementGoal = (): MutationTuple<CreateRetirementGoal, CreateRetirementGoalVariables> =>
  useGoalsMutation(createRetirementMutation.CreateRetirementGoal);

export const useGetGoalByPartial = (
  options?: QueryHookOptions<GetGoalByPartial, GetGoalByPartialVariables>,
): QueryResult<GetGoalByPartial, GetGoalByPartialVariables> =>
  useGoalsQuery(getGoalByPartialQuery.GetGoalByPartial, options);

export const useLazyGetRetirementTargets = (
  options?: QueryHookOptions<GetRetirementTargets, GetRetirementTargetsVariables>,
): QueryTuple<GetRetirementTargets, GetRetirementTargetsVariables> =>
  useLazyGoalsQuery(GetRetirementTargetsQuery, options);

export const useUpdateCliffGoal = (partyId: string): MutationTuple<UpdateCliffGoal, UpdateCliffGoalVariables> => {
  const [updateCliffGoal, result] = useGoalsMutation(UpdateCliffGoalMutation);
  return [
    useCallback(
      async options => {
        return await updateCliffGoal({
          update: (cache, { data }) => {
            const updatedCliffGoalData = data?.updateCliffGoal;
            if (updatedCliffGoalData && options?.variables) {
              const { goalId, targetBalance } = options.variables;
              updateGoalsCache(cache, {
                cliff: { targetBalance, targetEndDate: updatedCliffGoalData.targetEndDate },
                goalId,
                partyId,
                goalStatus: updatedCliffGoalData.goalStatus,
                title: options.variables.title ?? undefined,
              });
            }
          },
          ...options,
        });
      },
      [partyId, updateCliffGoal],
    ),
    result,
  ];
};

export const useUpdateRetirementGoal = ({
  hookOptions,
  partyId,
}: {
  hookOptions?: MutationHookOptions<UpdateRetirementGoal, UpdateRetirementGoalVariables>;
  partyId: string;
}): MutationTuple<UpdateRetirementGoal, UpdateRetirementGoalVariables> => {
  const [updateRetirementGoal, result] = useGoalsMutation(UpdateRetirementGoalMutation, hookOptions);
  return [
    useCallback(
      async options => {
        return await updateRetirementGoal({
          update: (cache, { data }) => {
            const goalStatus = data?.updateRetirementGoal?.goalStatus;
            if (goalStatus && options?.variables) {
              updateGoalsCache(cache, {
                goalStatus,
                goalId: options.variables.goalId,
                partyId,
                retirement: options.variables,
                title: options.variables.title ?? undefined,
              });
            }
          },
          ...options,
        });
      },
      [partyId, updateRetirementGoal],
    ),
    result,
  ];
};
