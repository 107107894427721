import React, { useEffect, useState } from 'react';

import { OneTimePasswordRole, PlaidEventAction } from '~/__generated__';
import { ErrorComponent } from '~/components/ErrorComponent';
import { ClientActionRequiredModal } from '~/components/modals/ClientActionRequired';
import { ClientCodeModal } from '~/components/modals/ClientCode';
import { FAClientLinkSuccessModal } from '~/components/modals/FAClientLinkSuccess';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Skeleton } from '~/components/ui/mui';
import { useClientInfo } from '~/hooks/client/useClientInfo';
import { useCreateOneTimePassword } from '~/hooks/otp/symphony';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  financialInstitution?: string;
  isAccountLinkageSuccessful: boolean;
  isPlaidFlowLaunched: boolean;
  onCloseModalCallback?: () => void;
  partyId: string;
  partyIdFA?: string;
  plaidItemId?: string;
  plaidProducts: 'AUTH' | 'INVESTMENTS';
  refetchAccounts?: () => void;
}

// This component is a controller to implement multiple OTP Modals related to Plaid Linkage
export const FaPlaidFlow: React.FC<Props> = ({
  contentOptions,
  onCloseModalCallback,
  isAccountLinkageSuccessful,
  isPlaidFlowLaunched = false,
  partyId,
  plaidProducts,
  refetchAccounts,
  plaidItemId,
  partyIdFA,
  financialInstitution,
}) => {
  const [otpEmail, setOtpEmail] = useState<string | undefined>();
  const [clientCode, setClientCode] = useState<string | undefined>();
  const [createOneTimePassword] = useCreateOneTimePassword();
  const {
    components: {
      sfFaPlaidFlow: { loginBaseUrl, otpExpiryTimeInMinutes },
    },
  } = useCoreConfig();
  const {
    open: isClientActionRequiredModalOpen,
    openModal: openClientActionRequiredModal,
    onClose: closeClientActionRequiredModal,
  } = useModalState();

  const {
    open: isClientCodeModalOpen,
    openModal: openClientCodeModal,
    onClose: closeClientCodeModal,
  } = useModalState();

  const {
    open: isFAClientSuccessModalOpen,
    openModal: openFaClientSuccessModal,
    onClose: closeFAClientSuccessModal,
  } = useModalState(false);

  const { data: clientInfoData, loading: clientInfoLoading, error: clientInfoError } = useClientInfo({
    variables: { partyId },
  });

  useEffect(() => {
    if (isAccountLinkageSuccessful) {
      closeClientCodeModal();
      openFaClientSuccessModal();
    }
  }, [isAccountLinkageSuccessful]);

  useEffect(() => {
    if (!isClientActionRequiredModalOpen && isPlaidFlowLaunched) {
      openClientActionRequiredModal();
    }
  }, [isPlaidFlowLaunched]);

  const handleCloseClientActionRequiredModal = () => {
    closeClientActionRequiredModal();
    if (isPlaidFlowLaunched && onCloseModalCallback) {
      onCloseModalCallback();
    }
  };

  const handleCloseClientCodeModal = () => {
    closeClientCodeModal();
    if (isPlaidFlowLaunched && onCloseModalCallback) {
      onCloseModalCallback();
    }
  };

  const handleCloseFAClientSuccessModal = () => {
    closeFAClientSuccessModal();
    if (isPlaidFlowLaunched && onCloseModalCallback) {
      onCloseModalCallback();
    }
  };

  useEffect(() => {
    if (!otpEmail) {
      setOtpEmail(clientInfoData?.email);
    }
  }, [otpEmail, clientInfoData]);

  const handleClientCodeGeneration = async (email: string) => {
    setOtpEmail(email);
    const otpResult = await createOneTimePassword({
      variables: {
        email,
        expires: otpExpiryTimeInMinutes * 60,
        partyId,
        roles: [OneTimePasswordRole.PLAID_CUSTOMER],
        loginLink:
          (loginBaseUrl ?? '') +
          `${plaidProducts ? `?products=${plaidProducts}` : ''}` +
          `${plaidItemId ? `&plaidItemId=${plaidItemId}` : ''}`,
        eventAction: plaidItemId ? PlaidEventAction.RE_AUTHENTICATION : PlaidEventAction.LOGIN,
        faPartyId: partyIdFA,
        financialInstitution,
      },
    });

    if (otpResult.data) {
      setClientCode(otpResult.data.createOneTimePassword?.otp ?? undefined);
      if (isClientActionRequiredModalOpen) {
        closeClientActionRequiredModal();
      }
      if (!isClientCodeModalOpen) {
        openClientCodeModal();
      }
    } else {
      throw new Error(`Unable to generate OTP`);
    }
  };

  const handleRefreshAccounts = () => {
    if (refetchAccounts) {
      refetchAccounts();
    }
  };

  if (clientInfoLoading) {
    return (
      <Box alignItems="center" display="flex" flexDirection="column" mt={2}>
        <Skeleton height={100} variant="circular" width={100} />
        <Skeleton height={45} sx={{ mt: 1 }} variant="text" width={150} />
        <Skeleton height={80} sx={{ mt: 4 }} variant="text" width="100%" />
      </Box>
    );
  }
  if (clientInfoError) {
    return <ErrorComponent contentOptions={contentOptions} error={clientInfoError} />;
  }

  return (
    <>
      <ClientActionRequiredModal
        clientEmail={otpEmail ?? ''}
        contentOptions={contentOptions}
        // The key triggers a re-render of the component when otpEmail changes so that
        // the editedEmail default state within the component is set rather than using a unnecessary useEffect within.
        key={otpEmail}
        onClose={handleCloseClientActionRequiredModal}
        onConfirm={handleClientCodeGeneration}
        open={isClientActionRequiredModalOpen}
      />

      <ClientCodeModal
        clientCode={clientCode ?? ''}
        clientEmail={otpEmail ?? ''}
        contentOptions={contentOptions}
        handleRefresh={handleRefreshAccounts}
        handleReset={() => handleClientCodeGeneration(otpEmail ?? '')}
        onClose={handleCloseClientCodeModal}
        open={isClientCodeModalOpen}
      />

      <FAClientLinkSuccessModal
        contentOptions={contentOptions}
        onClose={handleCloseFAClientSuccessModal}
        open={isFAClientSuccessModalOpen}
      />
    </>
  );
};
