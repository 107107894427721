import { RestrictBilling, RestrictBillingVariables } from './__generated__/mutation.v2';
import { UnrestrictBilling, UnrestrictBillingVariables } from './__generated__/mutation.v2';
import { GetBillingRestrictedData, GetBillingRestrictedDataVariables } from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, QueryHookOptions } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export const useGetBillingRestrictedData = (
  options?: QueryHookOptions<GetBillingRestrictedData, GetBillingRestrictedDataVariables>,
) => {
  return useSymphonyQuery(queries.GetBillingRestrictedData, options);
};

export const useRestrictBilling = (options?: MutationHookOptions<RestrictBilling, RestrictBillingVariables>) => {
  return useSymphonyMutation(mutations.RestrictBilling, options);
};

export const useUnrestrictBilling = (options?: MutationHookOptions<UnrestrictBilling, UnrestrictBillingVariables>) => {
  return useSymphonyMutation(mutations.UnrestrictBilling, options);
};
