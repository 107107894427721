import { FilterConfig, FilterTypes, Option, RangeFilterTypes, SelectedFilter } from './types';

import { NumberRange } from '~/components/ui/NumberFormatRange';

/**
 * Checks if a given FilterType is one of the defined in RangeFilterTypes.
 *
 * This function checks if the provided `filterType` is one of the defined FilterTypes in RangeFilterTypes.
 *
 * @param {FilterTypes} filterType - The filter type to check.
 * @returns {boolean} `true` if a given FilterType is one of the defined in RangeFilterTypes, otherwise `false`.
 */
export const isRangeFilterType = (filterType: FilterTypes): filterType is RangeFilterTypes => {
  return filterType === FilterTypes.CURRENCY_RANGE || filterType === FilterTypes.NUMBER_RANGE;
};

/**
 * Gets the maximum range value from a given filter configuration.
 *
 * This function checks if the `filterConfig` object has a `maxRangeValue` property and returns it if it exists.
 * If the `filterConfig` does not have a `maxRangeValue` property, the function returns `undefined`.
 *
 * @param {FilterConfig} filterConfig - The filter configuration object to examine.
 * @returns {number | undefined} The maximum range value if available, otherwise `undefined`.
 */
export const getFilterConfigMaxRangeValue = (filterConfig: FilterConfig): number | undefined => {
  if (!('maxRangeValue' in filterConfig)) {
    return undefined;
  }

  return filterConfig.maxRangeValue;
};

/**
 * Gets the options from a given filter configuration.
 *
 * This function checks if the `filterConfig` object has an `options` property and returns the options
 * if they exist. If the `filterConfig` does not have an `options` property, the function returns `undefined`.
 *
 * @param {FilterConfig} filterConfig - The filter configuration object to examine.
 * @returns {Option[] | undefined} An array of options if available, otherwise `undefined`.
 */
export const getFilterConfigOptions = (filterConfig: FilterConfig): Option[] | undefined => {
  if (!('options' in filterConfig)) {
    return undefined;
  }

  return filterConfig.options;
};

/**
 * Gets the selected options from a given filter.
 *
 * This function examines a `SelectedFilter` object and returns the selected options if they exist.
 * If the `selectedFilter` is undefined or does not contain the `selectedOptions` property,
 * the function returns `undefined`.
 *
 * @param {SelectedFilter | undefined} selectedFilter - The filter object to examine.
 * @returns {string[] | undefined} An array of selected option strings if available, otherwise `undefined`.
 */
export const getSelectedOptions = (selectedFilter: SelectedFilter | undefined): string[] | undefined => {
  if (!selectedFilter || !('selectedOptions' in selectedFilter)) {
    return undefined;
  }

  return selectedFilter.selectedOptions;
};

/**
 * Gets the selected range from a given filter.
 *
 * This function checks a `SelectedFilter` object for a `range` property and returns it if found.
 * If the `selectedFilter` is undefined or does not have a `range` property, the function returns `undefined`.
 *
 * @param {SelectedFilter | undefined} selectedFilter - The filter object to examine.
 * @returns {NumberRange | undefined} A `NumberRange` object if the range is found, otherwise `undefined`.
 */
export const getSelectedRange = (selectedFilter: SelectedFilter | undefined): NumberRange | undefined => {
  if (!selectedFilter || !('range' in selectedFilter)) {
    return undefined;
  }

  return selectedFilter.range;
};
