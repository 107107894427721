import { parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { CashTransfers, CashTransfersVariables, useCashTransfers } from '../../common/CashTransfer/symphony';
import { getIntegerAttributeValue, ManagedProductStatusesForCashTransfers } from '../../common/CashTransfer/utils';
import { useGetOpsDashboardContent } from '../../contentstack';
import { GetOpsDashboardContent } from '../../contentstack/__generated__/query.v2';
import { statusFilterToScheduledTransferStatus, toCashTransferStatus } from '../../mappers';
import { TransferItem, TransferStatusFilter } from '../../types';
import {
  getCurrentPage,
  getLastEditDateAndTime,
  getSortedPartnerOpsTradingSuspensions,
  getTotalPages,
} from '../../utils';
import { useGetRecurringCancellationsTableContent } from '../contentstack';

import {
  Field,
  FinancialAccountType,
  ManagedProductStatus,
  OrderType,
  TransferFrequencyType,
  TransferType,
} from '~/__generated__';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface RecurringCancellationsTabVariables {
  contentOptions: ContentOptions;
  field: Field;
  order: OrderType;
  page: number;
  statusFilter: TransferStatusFilter;
  transferType: TransferType;
}

export interface RecurringCancellationsTabData {
  content: any;
  currentPage: number;
  recurringCancelledItems: TransferItem[];
  refetchRecurringCancellationsData: () => void;
  totalPages: number;
}

export const useRecurringCancellationsTabData = ({
  page,
  statusFilter,
  contentOptions,
  field,
  order,
  transferType,
}: RecurringCancellationsTabVariables): AsyncResult<RecurringCancellationsTabData> => {
  const [state, setState] = useState<AsyncResult<RecurringCancellationsTabData>>({ loading: true });

  const variables: CashTransfersVariables = useMemo(
    () => ({
      limit: 10,
      offset: (page - 1) * 10,
      frequency: TransferFrequencyType.RECURRING,
      statuses: statusFilterToScheduledTransferStatus(statusFilter),
      field,
      order,
      fetchLatestNote: true,
      transferType,
      managedProductStatuses: ManagedProductStatusesForCashTransfers,
    }),
    [field, order, page, statusFilter, transferType],
  );

  const {
    data: recurringCancellationsData,
    loading: recurringCancellationsLoading,
    error: recurringCancellationsError,
    refetch: refetchRecurringCancellationsData,
  } = useCashTransfers({ variables, errorPolicy: 'all', fetchPolicy: 'no-cache' });

  const {
    data: contentstackData,
    loading: contentstackLoading,
    error: contentstackError,
  } = useGetRecurringCancellationsTableContent({
    variables: contentOptions,
  });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (recurringCancellationsError || contentstackError || opsDashboardContentError) {
      setState({
        error: recurringCancellationsError || contentstackError || opsDashboardContentError,
        loading: false,
      });
    }
  }, [contentstackError, opsDashboardContentError, recurringCancellationsError]);

  useEffect(() => {
    if (
      !recurringCancellationsLoading &&
      recurringCancellationsData &&
      !contentstackLoading &&
      contentstackData &&
      !opsDashboardContentLoading &&
      opsDashboardContentData
    ) {
      const recurringCancelledItems: TransferItem[] = getRecurringCancellationsItems(
        contentOptions,
        recurringCancellationsData,
        opsDashboardContentData,
      );
      const { limit, offset, total } = recurringCancellationsData.cashTransfers.paginationContext;
      const currentPage = getCurrentPage({ limit, offset });
      const totalPages = getTotalPages({ limit, total });
      setState({
        data: {
          recurringCancelledItems,
          currentPage,
          totalPages,
          content: contentstackData,
          refetchRecurringCancellationsData,
        },
        loading: false,
      });
    }
  }, [
    contentstackLoading,
    contentstackData,
    opsDashboardContentLoading,
    opsDashboardContentData,
    contentOptions,
    recurringCancellationsLoading,
    recurringCancellationsData,
    refetchRecurringCancellationsData,
  ]);

  return state;
};

const getRecurringCancellationsItems = (
  contentOptions: ContentOptions,
  data: CashTransfers,
  opsDashboardContent?: GetOpsDashboardContent,
): TransferItem[] => {
  const programeNames = opsDashboardContent?.all_product_name?.items || [];

  return data.cashTransfers.transfers.map(item => {
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(item.managedProduct?.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = item.managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = item.managedProduct?.clientParty?.id || '';
    const accountState = getAccountState({
      firstRebalancedOn: item.managedProduct?.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;
    return {
      id: item.id,
      accountNumber: item.managedProduct?.financialAccountNumber || '',
      accountState,
      accountTypeText: getAccountTypeText(
        item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
        opsDashboardContent?.all_account_type?.items || [],
      ),
      amount: parseFloat(item.total.value),
      clientName: `${item.managedProduct?.clientParty?.partyPerson?.givenName ?? ''} ${
        item.managedProduct?.clientParty?.partyPerson?.familyName ?? ''
      }`,
      createdAt: item.createdOn,
      destinationBankAccount: item.bankAccount?.accountNumber || '',
      flags: item.flags,
      frequency: item.frequency,
      managedProductId: item.managedProduct?.id || '',
      notes: item.notes || undefined,
      partyId,
      productName: getAccountProgramText(item.managedProduct?.program, item.managedProduct?.attributes, programeNames),
      rmdPlanId: getIntegerAttributeValue(item.attributes, 'RMD_PLAN_ID'),
      scheduledDate: item.scheduledAt || undefined,
      settlementDate: item.settlementDate || undefined,
      status: toCashTransferStatus(item.status),
      lastCommentData: item.entityNotes[0]
        ? {
            ...getLastEditDateAndTime(parseISO(item.entityNotes[0].created), contentOptions),
            lastValue: item.entityNotes[0].note,
          }
        : undefined,
      lastCommentPartyId: item.entityNotes[0]?.createdByPartyId ?? undefined,
    };
  });
};
