import React, { ComponentProps } from 'react';

import { GetOpsDashboardContentV2 } from '../../../OpsDashboardV2/contentstack/__generated__/query.v2';
import { Comment, CommentComponentColumn } from '../../common/Comment';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { RemoveSuspensionData, RestrictionsContent, RestrictionsTableContent } from '../../types';
import {
  getAccountNumberOrAccountTypeString,
  getLastEditDateAndTime,
  isAccountNumberClickable,
  OpsDashboardUser,
} from '../../utils';
import { GetRestrictionsContentV2 } from '../contentstack/__generated__/query.v2';
import { FilterContext, Restrictions } from '../symphony';

import {
  FinancialAccountType,
  ManagedProductStatus,
  MultiRestrictionEntityType,
  MultiRestrictionPlacedBy,
  MultiRestrictionType,
  SuspensionType,
  UserNoteEntityType,
} from '~/__generated__';
import { Filters } from '~/components/Filters';
import { FilterCountMap, FilterTypes } from '~/components/Filters/types';
import { getSelectedOptions } from '~/components/Filters/utils';
import { CopyField } from '~/components/ui/CopyField';
import { Button, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { getFullName } from '~/hooks/party/utils';
import { getAccountProgramText, getAccountState, SuspensionTag } from '~/utils/account';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

export interface RestrictionsData extends Restrictions {
  groupingIndex?: number;
}

export const ManagedProductStatusesForRestrictions: ManagedProductStatus[] = [
  ManagedProductStatus.ACTIVE,
  ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
  ManagedProductStatus.NEW,
  ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
  ManagedProductStatus.PENDING_CLOSED,
  ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
  ManagedProductStatus.CLOSED,
];

export enum RestrictionFilters {
  ACCOUNT_STATUS = 'ACCOUNT_STATUS',
  PLACED_BY = 'PLACED_BY',
  TYPE = 'TYPE',
}

export enum RestrictionsCreatorFilter {
  ALL = 'ALL',
  CUSTODIAL = 'CUSTODIAL',
  OTHERS = 'OTHERS',
  PARTNER_OPS = 'PARTNER_OPS',
  SIGFIG_TRADER = 'SIGFIG_TRADER',
}

export enum RestrictionContentKeys {
  EMPTY_SUSPENSION_TAG_LABEL = 'empty_suspension_tag_label',
  GROUP_SUSPENSION_TOGGLE_LABEL = 'group_suspension_toggle_label',
  OPS_PARTNER_SUSPENSION_TOGGLE_LABEL = 'ops_partner_suspension_toggle_label',
  REMOVE_SUSPENSION_CTA = 'remove_suspension_cta',
  REMOVE_SUSPENSION_FAILURE = 'remove_suspension_failure',
  REMOVE_SUSPENSION_SUCCESS = 'remove_suspension_success',
}

const RestrictionsFilterConfig = [
  {
    key: RestrictionFilters.PLACED_BY,
    options: [
      {
        id: RestrictionsCreatorFilter.CUSTODIAL,
      },
      {
        id: RestrictionsCreatorFilter.PARTNER_OPS,
      },
      {
        id: RestrictionsCreatorFilter.SIGFIG_TRADER,
      },
      {
        id: RestrictionsCreatorFilter.OTHERS,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
  {
    key: RestrictionFilters.TYPE,
    options: [
      {
        id: MultiRestrictionType.MONEY_IN,
      },
      {
        id: MultiRestrictionType.MONEY_OUT,
      },
      {
        id: MultiRestrictionType.TRADING,
      },
      {
        id: MultiRestrictionType.BILLING,
      },
      {
        id: MultiRestrictionType.LIQUIDATING_TRADES_ONLY,
      },
      {
        id: MultiRestrictionType.CLOSING_TRADES_ONLY,
      },
      {
        id: MultiRestrictionType.REGULATION_T,
      },
      {
        id: MultiRestrictionType.EDUCATIONAL_ASSETS_ONLY,
      },
      {
        id: MultiRestrictionType.UNKNOWN_RESTRICTION,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
  {
    key: RestrictionFilters.ACCOUNT_STATUS,
    options: [
      {
        id: ManagedProductStatus.ACTIVE,
      },
      {
        id: ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
      },
      {
        id: ManagedProductStatus.NEW,
      },
      {
        id: ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
      },
      {
        id: ManagedProductStatus.PENDING_CLOSED,
      },
      {
        id: ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
      },
      {
        id: ManagedProductStatus.CLOSED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
];

export const defaultFilters: ComponentProps<typeof Filters>['defaultAppliedFilters'] = {
  [RestrictionFilters.PLACED_BY]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      RestrictionsCreatorFilter.CUSTODIAL,
      RestrictionsCreatorFilter.PARTNER_OPS,
      RestrictionsCreatorFilter.SIGFIG_TRADER,
      RestrictionsCreatorFilter.OTHERS,
    ],
  },
  [RestrictionFilters.TYPE]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      MultiRestrictionType.MONEY_IN,
      MultiRestrictionType.MONEY_OUT,
      MultiRestrictionType.TRADING,
      MultiRestrictionType.BILLING,
      MultiRestrictionType.LIQUIDATING_TRADES_ONLY,
      MultiRestrictionType.CLOSING_TRADES_ONLY,
      MultiRestrictionType.REGULATION_T,
      MultiRestrictionType.EDUCATIONAL_ASSETS_ONLY,
      MultiRestrictionType.UNKNOWN_RESTRICTION,
    ],
  },
  [RestrictionFilters.ACCOUNT_STATUS]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      ManagedProductStatus.ACTIVE,
      ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
      ManagedProductStatus.NEW,
      ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
      ManagedProductStatus.PENDING_CLOSED,
      ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
      ManagedProductStatus.CLOSED,
    ],
  },
};

interface CommentProps {
  commentColumn: CommentComponentColumn | undefined;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  refetchData: () => void;
}

interface TableContentParams {
  activeRowId?: string | undefined;
  commentProps: CommentProps;
  content: RestrictionsContent;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onRemoveSuspensionClick: (suspensionData: RemoveSuspensionData) => void;
  opsDashboardContentData?: GetOpsDashboardContentV2;
  restrictionContent: GetRestrictionsContentV2 | undefined;
  restrictions?: RestrictionsData[];
  showProductName: boolean;
}

export const getRestrictionsContent = (content: GetRestrictionsContentV2 | undefined): RestrictionsContent => {
  const item = content?.all_restrictions_table?.items?.[0];
  const textFields = item?.fields?.text ?? [];
  return {
    columns: item?.columns?.column,
    emptySuspensionTagLabel: findFieldValue(textFields, RestrictionContentKeys.EMPTY_SUSPENSION_TAG_LABEL),
    groupSuspensionToggleLabel: findFieldValue(textFields, RestrictionContentKeys.GROUP_SUSPENSION_TOGGLE_LABEL),
    opsPartnerSuspensionToggleLabel: findFieldValue(
      textFields,
      RestrictionContentKeys.OPS_PARTNER_SUSPENSION_TOGGLE_LABEL,
    ),
    removeSuspensionCta: findFieldValue(textFields, RestrictionContentKeys.REMOVE_SUSPENSION_CTA),
    removeSuspensionFailure: findFieldValue(textFields, RestrictionContentKeys.REMOVE_SUSPENSION_FAILURE),
    removeSuspensionSuccess: findFieldValue(textFields, RestrictionContentKeys.REMOVE_SUSPENSION_SUCCESS),
    filterConfig: RestrictionsFilterConfig.map(filter => {
      return {
        ...filter,
        label: findFieldValue(textFields, `${filter.key.toLowerCase()}_filter`),
        options: filter.options.map(option => {
          return {
            ...option,
            label: findFieldValue(textFields, `${option.id.toLowerCase()}`),
          };
        }),
      };
    }),
    appliedFiltersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      appliedFilters: findFieldValue(textFields, 'applied_filters'),
    },
    filtersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      apply: findFieldValue(textFields, 'apply_cta'),
      filters: findFieldValue(textFields, 'filters_cta'),
      resetAll: findFieldValue(textFields, 'reset_all_cta'),
    },
  };
};

export const getTableContent = ({
  commentProps,
  restrictionContent,
  content,
  onClientClick,
  onAccountClick,
  getAccountNumberRedirectUrl,
  getClientNameRedirectUrl,
  onRemoveSuspensionClick,
  showProductName,
  opsDashboardContentData,
  restrictions,
  activeRowId,
}: TableContentParams): RestrictionsTableContent[] => {
  if (!restrictions) {
    return [];
  }

  return restrictions.map(restriction => {
    const entityNotes = restriction.entityNotes.length ? restriction.entityNotes[0] : null;
    const programNames = opsDashboardContentData?.all_product_name?.items || [];
    const accountTypeContent = opsDashboardContentData?.all_account_type?.items || [];
    const opsDashboardContent = opsDashboardContentData?.all_ops_dashboard?.items?.[0];
    const copyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_ulid_label');
    const successCopyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_success_label');
    const restrictionsLabels = restrictionContent?.all_restrictions_table?.items?.[0];

    const shouldShowAccountClick = isAccountNumberClickable(
      getAccountState({
        firstRebalancedOn: restriction.managedProduct.firstRebalancedOn ?? undefined,
        financialAccountStatus: restriction.managedProduct.status,
        suspendedOn: restriction.placedBy === MultiRestrictionPlacedBy.PARTNER_OPS ? restriction.createdAt : undefined,
      }).state,
    );

    const suspensionDisplayContent = {
      restrictionPlacedByUser: getFullName(restriction.lastUpdatedByParty?.partyPerson) ?? '',
      created: restriction.createdAt ? formatDate(restriction.createdAt) : '-',
      restrictionPlacedBy: restriction.placedBy,
    };

    const suspension: RemoveSuspensionData = {
      data: suspensionDisplayContent,
      managedProductId: restriction.managedProduct.id,
      suspensionTag: SuspensionTag.OPS,
      suspensionType: SuspensionType.PARTNER,
    };

    const placedByKey = toFilterByMultiRestrictionPlacedBy(restriction.placedBy).toLowerCase();
    const placedBy = findFieldValue(restrictionsLabels?.fields?.text, placedByKey);

    // zero index (or first row) for grouped suspension and undefined index (or every row) for non-grouped suspension
    const showAccountAndClientData = restriction.groupingIndex === undefined || restriction.groupingIndex === 0;
    const accountNumber =
      restriction.managedProduct.closedAccountNumber || restriction.managedProduct.financialAccountNumber;

    return {
      rowKey: restriction.id,
      accountNumber: showAccountAndClientData ? (
        <AccountNumber
          accountNumber={accountNumber}
          accountNumberText={getAccountNumberOrAccountTypeString(
            accountNumber,
            getAccountTypeText(
              restriction.managedProduct.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
              accountTypeContent,
            ),
          )}
          label={
            showProductName
              ? getAccountProgramText(
                  restriction.managedProduct.program,
                  restriction.managedProduct.attributes,
                  programNames,
                )
              : null
          }
          onClick={
            shouldShowAccountClick && restriction.managedProduct.clientParty?.id && restriction.managedProduct.id
              ? () =>
                  onAccountClick(
                    restriction.managedProduct.clientParty?.id ?? '',
                    restriction.managedProduct.id,
                    AccountDetailsTabsEnum.restrictions,
                  )
              : undefined
          }
          redirectUrl={
            restriction.managedProduct.clientParty?.id && restriction.managedProduct.id && shouldShowAccountClick
              ? getAccountNumberRedirectUrl(restriction.managedProduct.clientParty.id, restriction.managedProduct.id)
              : undefined
          }
        />
      ) : (
        <></>
      ),
      clientName: showAccountAndClientData ? (
        <ClientName
          clientName={getFullName(restriction.managedProduct.clientParty?.partyPerson)}
          onClick={() => onClientClick(restriction.managedProduct.clientParty?.id ?? '')}
          redirectUrl={
            restriction.managedProduct.clientParty?.id
              ? getClientNameRedirectUrl(restriction.managedProduct.clientParty.id)
              : undefined
          }
        />
      ) : (
        <></>
      ),
      createdAt: formatDate(restriction.createdAt),
      createdBy: (
        <Stack direction="column">
          <Typography>{placedBy}</Typography>
          <Typography variant="caption">{getFullName(restriction.lastUpdatedByParty?.partyPerson)}</Typography>
        </Stack>
      ),
      accountStatus: showAccountAndClientData
        ? findFieldValue(restrictionsLabels?.fields?.text, restriction.managedProduct.status.toLowerCase())
        : '',
      type: findFieldValue(restrictionsLabels?.fields?.text, restriction.multiRestrictionType.toLowerCase()),
      actions:
        restriction.placedBy === MultiRestrictionPlacedBy.PARTNER_OPS ? (
          <Stack direction="row">
            <Button onClick={() => onRemoveSuspensionClick(suspension)} size="small" variant="outlined">
              {content.removeSuspensionCta}
            </Button>
            <Stack justifyContent="center" sx={{ width: '30px', px: 1, fontSize: 16 }}>
              {activeRowId === restriction.id && (
                <CopyField
                  copyLabel={copyLabel}
                  defaultState
                  disableToggling
                  successLabel={successCopyLabel}
                  textToCopy={restriction.id}
                />
              )}
            </Stack>
          </Stack>
        ) : (
          <Stack justifyContent="center" sx={{ width: '30px', px: 1, fontSize: 16 }}>
            {activeRowId === restriction.id && (
              <CopyField
                copyLabel={copyLabel}
                defaultState
                disableToggling
                successLabel={successCopyLabel}
                textToCopy={restriction.id}
              />
            )}
          </Stack>
        ),
      comment: (
        <Comment
          comment={
            entityNotes
              ? {
                  lastValue: entityNotes.note,
                  ...getLastEditDateAndTime(new Date(entityNotes.created), commentProps.contentOptions),
                }
              : undefined
          }
          content={commentProps.commentColumn}
          contentOptions={commentProps.contentOptions}
          currentUser={commentProps.currentUser}
          entity={
            restriction.entityType === MultiRestrictionEntityType.RESTRICTION
              ? UserNoteEntityType.RESTRICTION
              : UserNoteEntityType.TRADING_SUSPENSION
          }
          entityId={restriction.id}
          key={restriction.id}
          lastCommentPartyId={entityNotes?.createdByPartyId}
          refetchData={commentProps.refetchData}
        />
      ),
    };
  });
};

export const getOffset = (pageNumber: number, recordsPerPage: number) => (pageNumber - 1) * recordsPerPage;

export const geSelectedFilterTypes = (
  appliedFilters: ComponentProps<typeof Filters>['appliedFilters'],
): MultiRestrictionType[] => {
  const typeFilters = getSelectedOptions(appliedFilters[RestrictionFilters.TYPE]) || [];
  return typeFilters.length
    ? (typeFilters as MultiRestrictionType[])
    : [
        MultiRestrictionType.MONEY_IN,
        MultiRestrictionType.MONEY_OUT,
        MultiRestrictionType.TRADING,
        MultiRestrictionType.BILLING,
        MultiRestrictionType.LIQUIDATING_TRADES_ONLY,
        MultiRestrictionType.CLOSING_TRADES_ONLY,
        MultiRestrictionType.REGULATION_T,
        MultiRestrictionType.EDUCATIONAL_ASSETS_ONLY,
        MultiRestrictionType.UNKNOWN_RESTRICTION,
      ];
};

const toMultiRestrictionPlacedByFilter = (filter: RestrictionsCreatorFilter): MultiRestrictionPlacedBy[] => {
  switch (filter) {
    case RestrictionsCreatorFilter.ALL:
      return [
        MultiRestrictionPlacedBy.SIGFIG_TRADER,
        MultiRestrictionPlacedBy.PARTNER_OPS,
        MultiRestrictionPlacedBy.PARTNER_ACAT,
        MultiRestrictionPlacedBy.PARTNER_BORD,
        MultiRestrictionPlacedBy.PARTNER_WITHOUT_TAG,
        MultiRestrictionPlacedBy.CUSTODIAL,
      ];
    case RestrictionsCreatorFilter.CUSTODIAL:
      return [MultiRestrictionPlacedBy.CUSTODIAL];
    case RestrictionsCreatorFilter.PARTNER_OPS:
      return [MultiRestrictionPlacedBy.PARTNER_OPS];
    case RestrictionsCreatorFilter.SIGFIG_TRADER:
      return [MultiRestrictionPlacedBy.SIGFIG_TRADER];
    case RestrictionsCreatorFilter.OTHERS:
      return [
        MultiRestrictionPlacedBy.PARTNER_ACAT,
        MultiRestrictionPlacedBy.PARTNER_BORD,
        MultiRestrictionPlacedBy.PARTNER_WITHOUT_TAG,
      ];
  }
};

const toFilterByMultiRestrictionPlacedBy = (
  restriction: MultiRestrictionPlacedBy | null,
): RestrictionsCreatorFilter => {
  switch (restriction) {
    case MultiRestrictionPlacedBy.SIGFIG_TRADER:
      return RestrictionsCreatorFilter.SIGFIG_TRADER;
    case MultiRestrictionPlacedBy.PARTNER_OPS:
      return RestrictionsCreatorFilter.PARTNER_OPS;
    case MultiRestrictionPlacedBy.CUSTODIAL:
      return RestrictionsCreatorFilter.CUSTODIAL;
    case MultiRestrictionPlacedBy.PARTNER_ACAT:
    case MultiRestrictionPlacedBy.PARTNER_BORD:
    case MultiRestrictionPlacedBy.PARTNER_WITHOUT_TAG:
    case null:
      return RestrictionsCreatorFilter.OTHERS;
  }
};

export const geSelectedPlacedBy = (
  appliedFilters: ComponentProps<typeof Filters>['appliedFilters'],
): MultiRestrictionPlacedBy[] => {
  const placedByFilters = getSelectedOptions(appliedFilters[RestrictionFilters.PLACED_BY]) || [];
  return placedByFilters.length
    ? placedByFilters.map(v => toMultiRestrictionPlacedByFilter(v as RestrictionsCreatorFilter)).flat()
    : [
        MultiRestrictionPlacedBy.SIGFIG_TRADER,
        MultiRestrictionPlacedBy.PARTNER_OPS,
        MultiRestrictionPlacedBy.PARTNER_ACAT,
        MultiRestrictionPlacedBy.PARTNER_BORD,
        MultiRestrictionPlacedBy.PARTNER_WITHOUT_TAG,
        MultiRestrictionPlacedBy.CUSTODIAL,
      ];
};

export const getFilterCountMap = (filterContext: FilterContext[]): FilterCountMap[] => {
  return filterContext
    .filter(
      v =>
        !!v.filtersKey.managedProductStatus &&
        ManagedProductStatusesForRestrictions.includes(v.filtersKey.managedProductStatus),
    )
    .map(v => {
      return {
        count: v.count,
        filters: {
          [RestrictionFilters.PLACED_BY]: toFilterByMultiRestrictionPlacedBy(v.filtersKey.multiRestrictionPlacedBy),
          [RestrictionFilters.TYPE]: `${v.filtersKey.multiRestrictionType}`,
          [RestrictionFilters.ACCOUNT_STATUS]: `${v.filtersKey.managedProductStatus}`,
        },
      };
    });
};

export const geSelectedFilterManagedProductStatus = (appliedFilters: string[]): ManagedProductStatus[] => {
  return appliedFilters as ManagedProductStatus[];
};
