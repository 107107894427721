import { GetTaxReportsTabContent, GetTaxReportsTabContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';
export type TaxReportsTabContent = GetTaxReportsTabContent;

export const useGetTaxReportsTabContent = (
  options?: QueryHookOptions<TaxReportsTabContent, GetTaxReportsTabContentVariables>,
): QueryResult<GetTaxReportsTabContent, GetTaxReportsTabContentVariables> => {
  return useContentstackQuery(queries.GetTaxReportsTabContent, options);
};
