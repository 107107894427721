interface Label {
  key: string | null;
  value: string | null;
}

interface Content {
  labels?: (Label | null)[] | null;
  rte_labels?: (Label | null)[] | null;
}

/**
 * @param content Standard content with labels
 * @param key this will be unique string which will be used to find the label content
 * @param defaultLabel value of this will be returned if the key don't exists in content.labels or content/label don't exists
 * @returns Label value corresponding to key
 */
export const getLabel = (content: Content | null | undefined, key: string, defaultLabel?: string): string =>
  content?.labels?.find(item => item?.key === key)?.value ?? defaultLabel ?? key;

/**
 * @param content Standard content with labels
 * @param key this will be string which will be used to find the labels content
 * @returns Label values that includes the key
 */
export const getLabels = (key: string, content?: Content | null): (Label | null)[] | null =>
  content?.labels?.filter(item => item?.key?.includes(key)) ?? null;

/**
 * @param content Standard content with Rte labels
 * @param key this will be unique string which will be used to find the label content
 * @param defaultLabel value of this will be returned if the key don't exists in content.labels or content/label don't exists
 * @returns Label value corresponding to key
 *  */
export const getRteLabel = (content: Content | null | undefined, key: string, defaultLabel?: string): string =>
  content?.rte_labels?.find(item => item?.key === key)?.value ?? defaultLabel ?? key;
