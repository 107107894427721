import * as yup from 'yup';

import { BalanceType } from '~/__generated__';
import { BrokerageFinancialAccount, ExternalBrokerageFinancialAccount } from '~/hooks/financial-account/symphony';

export interface BrokerageSourceYupSchemaArgs {
  brokerageAccountNumberDefaultValue?: string;
  brokerageAccountNumberFieldName: string;
  brokerageAliasDefaultValue?: string;
  brokerageAliasFieldName: string;
  brokerageNameDefaultValue?: string;
  brokerageNameFieldName: string;
  brokerageSourceDefaultValue?: string;
  brokerageSourceFieldName: string;
}

/**
 * Get the yup schema required for yup validation.
 * @param args - The arguments object required for yup schema.
 * @param [args.brokerageAccountNumberDefaultValue] - The default value for the brokerage account number field.
 * @param args.brokerageAccountNumberFieldName - Brokerage account number form field name.
 * @param [args.brokerageAliasDefaultValue] - The default value for the brokerage alias field.
 * @param args.brokerageAliasFieldName - Brokerage alias form field name.
 * @param [args.brokerageNameDefaultValue] - The default value for the brokerage name field.
 * @param args.brokerageNameFieldName - Brokerage name form field name.
 * @param [args.brokerageSourceDefaultValue] - The default value for the brokerage source field.
 * @param args.brokerageSourceFieldName - Brokerage source form field name.
 * @returns The yup schema object for the brokerage source field.
 */
export const getBrokerageSourceYupSchema = ({
  brokerageAccountNumberDefaultValue,
  brokerageAccountNumberFieldName,
  brokerageAliasDefaultValue,
  brokerageAliasFieldName,
  brokerageNameDefaultValue,
  brokerageNameFieldName,
  brokerageSourceDefaultValue,
  brokerageSourceFieldName,
}: BrokerageSourceYupSchemaArgs) => ({
  [brokerageSourceFieldName]: yup
    .string()
    .default(brokerageSourceDefaultValue ?? '')
    .required(),
  [brokerageNameFieldName]: yup.string().default(brokerageNameDefaultValue ?? ''),
  [brokerageAliasFieldName]: yup
    .string()
    .default(brokerageAliasDefaultValue ?? '')
    .nullable(),
  [brokerageAccountNumberFieldName]: yup.string().default(brokerageAccountNumberDefaultValue ?? ''),
});

export const generateIDForExternalBrokerage = (financialInstitution: string, accountNumber: string) =>
  `${financialInstitution}${accountNumber}`;

export const getEligibleExternalBrokerageAccountsForJournaling = (
  accounts: (BrokerageFinancialAccount | ExternalBrokerageFinancialAccount)[],
): ExternalBrokerageFinancialAccount[] => {
  const finalAccounts: ExternalBrokerageFinancialAccount[] = [];
  accounts.forEach(item => {
    if ('positionsV2' in item) {
      const balance = parseFloat(item.balances?.find(b => b.type === BalanceType.CASH)?.balance.value ?? '0');
      const hasPositions = item.positionsV2?.find(
        p => !!parseFloat(p.quantity ?? '0') || !!p.identifiers.find(i => i.name === 'TICKER')?.value,
      );
      if (balance || hasPositions) {
        finalAccounts.push(item);
      }
    }
  });

  return finalAccounts;
};
