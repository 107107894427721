import { OrderType, SigningDocumentsField } from '~/__generated__';
import { AccountState } from '~/utils/account';

export enum DocusignStatus {
  ALL,
  DECLINED,
  FAILED,
  PENDING,
  SUCCEEDED,
}

export type DocusignColumnKeys =
  | 'accountNumber'
  | 'accountType'
  | 'clientName'
  | 'comment'
  | 'dateGenerated'
  | 'emailRecipients'
  | 'status';

export type emailRecipient = {
  email: string;
  recipientId: number;
};

export type DocusignItem = {
  accountNumber: string;
  accountState: AccountState;
  accountType: string;
  clientName: string;
  comment?: {
    lastEditDate: string;
    lastEditTime: string;
    lastValue: string;
  };
  dateGenerated: string;
  emailRecipients: emailRecipient[];
  id: string;
  lastCommentPartyId: string;
  managedProductId: string;
  partyId: string;
  productName: string;
  status: DocusignStatus;
};

export type DocusignDisplayStrings = {
  status: Record<DocusignStatus, string>;
  tableHeaders: Partial<Record<DocusignColumnKeys, string>>;
  wrappableColumns: string[];
};

export interface DocusignTabVariables {
  fetchLatestNote?: boolean;
  field: SigningDocumentsField;
  order: OrderType;
  page: number;
  statusFilters: DocusignStatus[];
}

export interface DocusignTabData {
  content: DocusignDisplayStrings;
  currentPage: number;
  docusignItems: DocusignItem[];
  refetchData: () => void;
  totalPages: number;
}
