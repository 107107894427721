import { GetClientDocumentsModalContent } from '../contentstack/__generated__/query.v2';

import { ClientDocumentsModalContent } from './useClientDocumentsModalContent';

export const getClientDocumentsModalContent = (
  contentData?: GetClientDocumentsModalContent,
): ClientDocumentsModalContent => {
  const content = contentData?.all_client_documents_modal?.items?.[0]?.content;

  return {
    primaryAction: content?.find(item => item?.key === 'primaryAction')?.value ?? '',
    title: content?.find(item => item?.key === 'title')?.value ?? '',
  };
};
