import { ComponentProps } from 'react';

import { CommentComponentColumn } from '../common/Comment';
import { OpsDashboardUser } from '../utils';

import { ErrorsStatusFilters } from './ErrorsStatusFilter';
import { GetErrors } from './symphony/__generated__/query.v2';

import { EntityType, FinancialAccountType, ManagedProductType, Scalars as SymphonyScalars } from '~/__generated__';
import { SortConfig } from '~/components/ui/BasicTable';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { AccountState } from '~/utils/account';
import { AccountAttribute, ProductName } from '~/utils/account/types';
import { ContentOptions } from '~/utils/contentstack';

export enum FailureType {
  ACCOUNT_RESTRICTION = 'ACCOUNT_RESTRICTION',
  CANCELLATION_AT_BROKERAGE = 'CANCELLATION_AT_BROKERAGE',
  CREATE_RECEIPT = 'CREATE_RECEIPT',
  CREATE_STANDING_INSTRUCTIONS = 'CREATE_STANDING_INSTRUCTIONS',
  CREATION_AT_BROKERAGE = 'CREATION_AT_BROKERAGE',
  POST_SIGNING_FAILURE = 'POST_SIGNING_FAILURE',
  REQUEST_CLOSE_FAILURE = 'REQUEST_CLOSE_FAILURE',
  UPDATE_ACCOUNT_STATUS_FAILURE = 'UPDATE_ACCOUNT_STATUS_FAILURE',
}

export enum FailureTypeContentKey {
  ACCOUNT_RESTRICTION_ERROR = 'ACCOUNT_RESTRICTION_ERROR',
  ADD_FUNDS_REQUEST_ERROR = 'ADD_FUNDS_REQUEST_ERROR',
  CANCELLATION_AT_BROKERAGE_ERROR = 'CANCELLATION_AT_BROKERAGE_ERROR',
  CREATE_STANDING_INSTRUCTIONS_ERROR = 'CREATE_STANDING_INSTRUCTIONS_ERROR',
  POST_SIGNING_ERROR = 'POST_SIGNING_ERROR',
  REQUEST_CLOSE_ERROR = 'REQUEST_CLOSE_ERROR',
  TOA_ERROR_AT_CUSTODIAN = 'TOA_ERROR_AT_CUSTODIAN',
  UPDATE_ACCOUNT_STATUS_ERROR = 'UPDATE_ACCOUNT_STATUS_ERROR',
  WITHDRAW_FUNDS_REQUEST_ERROR = 'WITHDRAW_FUNDS_REQUEST_ERROR',
}

export const errorEntityTypes = [
  EntityType.ASSET_DEPOSIT,
  EntityType.BANK_ACCOUNT_ASSOCIATION,
  EntityType.CASH_DEPOSIT,
  EntityType.CASH_WITHDRAWAL,
  EntityType.COPILOT_PORTFOLIO,
];

export interface ErrorsFailureContent {
  comment?: {
    lastEditDate: string;
    lastEditTime: string;
    lastValue: string;
  };
  created: SymphonyScalars['DateTime'];
  description?: string;
  entity: EntityType;
  entityId?: string | null;
  failureType?: string;
  id: string;
  isResolved: boolean;
  lastCommentPartyId?: string;
  managedProduct: {
    accountState: AccountState;
    accountType?: FinancialAccountType | null;
    attributes?: AccountAttribute[];
    clientParty: {
      id?: string | null;
      partyPerson?: {
        familyName?: string | null;
        givenName?: string | null;
      };
    };
    financialAccountNumber?: string | null;
    program?: ManagedProductType;
  };
  managedProductId?: string | null;
  ulid: string;
}

export interface GetTableContent {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  data: ErrorsTabData;
  errorId?: string | null;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  handleOpenModal: (row: ErrorsFailureContent) => void;
  handleResolveClick: (entity: EntityType, failureId: string) => void;
  mpData: ErrorsFailureContent[];
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  showProductName: boolean;
  updateFailuresLoading: boolean;
}

export interface AccountTypeContent {
  key: string | null;
  text: string | null;
}

export interface FailureTypeContent {
  failure_type_key: string | null;
  failure_type_value: string | null;
}

export interface ErrorsColumns {
  column_id: string | null;
  column_order: number | null;
  column_value: string | null;
}

export interface StatusFilter {
  all: string | null;
  open: string | null;
  resolved: string | null;
}

export interface ErrorsContentData {
  columns: (ErrorsColumns | null)[] | null;
  failure_types: (FailureTypeContent | null)[] | null;
  feedback_message: string | null;
  status_filter: StatusFilter | null;
}

export type ErrorsTabData = {
  accountTypeContent?: (AccountTypeContent | null)[];
  contentData?: ErrorsContentData | null;
  errorsData?: GetErrors;
  isRefetchingErrorsData?: boolean;
  productNameContent?: (ProductName | null)[];
  refetchErrorsData?: () => void;
};

export type ErrorsTabVariables = {
  contentOptions: ContentOptions;
  pageNumber: number;
  sortConfig: SortConfig;
  statusFilter: ErrorsStatusFilters;
};
