import { useContext, useEffect, useState } from 'react';

import OpsDashboardContext from '../../OpsDashboardContext';
import { RequestTabs } from '../../types';
import { useGetOpsDashboardFundingTableContent } from '../contentstack';
import { FundingStatusFilterTypes, FundingTabContentData } from '../types';

import { FundingSourceType } from '~/__generated__';
import { AsyncResult } from '~/utils/types';

export const useGetFundingTabContent = (): AsyncResult<FundingTabContentData> => {
  const [state, setState] = useState<AsyncResult<FundingTabContentData>>({ loading: true });
  const { contentOptions } = useContext(OpsDashboardContext);
  const { data: contentData, loading: contentLoading, error: contentError } = useGetOpsDashboardFundingTableContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (contentError) {
      setState({
        error: contentError,
        loading: false,
      });
    }
  }, [contentError]);

  useEffect(() => {
    if (!contentLoading && contentData) {
      const content = contentData.all_ops_dashboard_funding_table?.items?.[0];
      setState({
        data: {
          tableHeaders: content?.columns?.column ?? [],
          fundingSourceMapping:
            content?.funding_source_mapping?.reduce(
              (acc, source) => ({
                ...acc,
                [source?.funding_source ?? FundingSourceType.CASH]: source?.display_text ?? '',
              }),
              {},
            ) ?? {},
          flagsContent: content?.flag_referenceConnection?.edges?.[0]?.node ?? undefined,
          staticStringMapping:
            content?.static_string_mapping?.map(item => ({
              key: item?.key ?? '',
              display_value: item?.display_value ?? '',
            })) ?? [],
          statusFiltersDropdownItems:
            content?.status_filters?.map(statusFilter => ({
              label: statusFilter?.value ?? '',
              value: statusFilter?.key?.toUpperCase() as FundingStatusFilterTypes,
            })) ?? [],
          statuses: {
            BELOW_MINIMUM: content?.statuses?.below_minimum ?? '',
            NEEDS_REVIEW: content?.statuses?.needs_review ?? '',
            FUNDED: content?.statuses?.funded ?? '',
          },
          partitionedByFlaggedStatus: content?.requests?.partitioned_by_flagged_status ?? true,
          partitionTabs: {
            [RequestTabs.FlaggedRequests]:
              content?.requests?.partition_tabs?.find(tab => tab?.value === RequestTabs.FlaggedRequests)?.label ?? '',
            [RequestTabs.AllOtherRequests]:
              content?.requests?.partition_tabs?.find(tab => tab?.value === RequestTabs.AllOtherRequests)?.label ?? '',
          },
        },
        loading: false,
      });
    }
  }, [contentLoading, contentData]);

  return state;
};
