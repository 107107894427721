import { parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { useCashTransfersV2 } from '../../common/CashTransfer/symphony';
import { CashTransfersV2, CashTransfersV2Variables } from '../../common/CashTransfer/symphony/__generated__/query.v2';
import { getIntegerAttributeValue, ManagedProductStatusesForCashTransfersV2 } from '../../common/CashTransfer/utils';
import { useGetOpsDashboardContentV2 } from '../../contentstack';
import { GetOpsDashboardContentV2 } from '../../contentstack/__generated__/query.v2';
import { statusFilterToScheduledTransferStatus, toCashTransferStatus } from '../../mappers';
import { SearchContextLiterals, TOO_MANY_SEARCH_RECORDS_ERROR, TransferItem } from '../../types';
import {
  getCurrentPage,
  getLastEditDateAndTime,
  getSearchContext,
  getSortedPartnerOpsTradingSuspensions,
  getTotalPages,
  getTransferTypes,
} from '../../utils';
import { getFilterCountMap } from '../content';
import { useGetWithdrawalTableContent } from '../contentstack';
import { GetWithdrawalTableContentV2 } from '../contentstack/__generated__/query.v2';

import { getTaxInformation } from './utils';

import { Field, FinancialAccountType, ManagedProductStatus, OrderType, TransferType } from '~/__generated__';
import { FilterCountMap } from '~/components/Filters/types';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { getPaginationContext } from '~/utils/table';
import { AsyncResult, PaginationContext, SearchContext } from '~/utils/types';

const initialRecordsPerPage = 10;

export enum RecurrenceOptions {
  RECURRING,
  ONE_TIME,
}

export interface WithdrawalsTabVariables {
  contentOptions: ContentOptions;
  field: Field;
  hasOpenFlags: boolean | null;
  order: OrderType;
  page: number;
  recordsPerPage: number;
  recurrenceFilter: string[];
  searchFilter: string | null;
  statusFilter: string[];
  updateRecordsPerPage: (value: number) => void;
}

export interface WithdrawalsTabData {
  content?: GetWithdrawalTableContentV2;
  currentPage: number;
  filterCountMap: FilterCountMap[];
  paginationContext: PaginationContext;
  refetchCashWithdrawalsData: () => void;
  searchContext: SearchContext;
  searchError: boolean;
  totalPages: number;
  withdrawalItems: TransferItem[];
}

export const useWithdrawalsTabData = ({
  page,
  recurrenceFilter,
  statusFilter,
  searchFilter,
  contentOptions,
  field,
  order,
  hasOpenFlags,
  updateRecordsPerPage,
  recordsPerPage,
}: WithdrawalsTabVariables): AsyncResult<WithdrawalsTabData> => {
  const [state, setState] = useState<AsyncResult<WithdrawalsTabData>>({ loading: true });

  const variables: CashTransfersV2Variables = useMemo(
    () => ({
      fetchLatestNote: true,
      field,
      hasOpenFlags,
      limit: recordsPerPage || initialRecordsPerPage,
      managedProductStatuses: ManagedProductStatusesForCashTransfersV2,
      offset: (page - 1) * (recordsPerPage || initialRecordsPerPage),
      order,
      statuses: statusFilterToScheduledTransferStatus(statusFilter),
      searchFilter,
      transferTypes: getTransferTypes(recurrenceFilter, TransferType.WITHDRAWAL),
    }),
    [field, hasOpenFlags, recordsPerPage, page, order, statusFilter, searchFilter, recurrenceFilter],
  );

  // The reason for no-cache is due to the updating the list when ever we create or resolve flag  for a withdrawal, the flagged and unflagged withdrawals table should be updated.
  // Otherwise, instead of the table showing the current flagged and unflagged withdrawals data it shows the previous cached data. There is no easy way to update the cache to reflect the correct state right now.
  const {
    data: cashWithdrawalsData,
    loading: cashWithdrawalsLoading,
    error: cashWithdrawalsError,
    refetch: refetchCashWithdrawalsData,
  } = useCashTransfersV2({ variables, errorPolicy: 'all', fetchPolicy: 'no-cache' });

  const {
    data: contentstackData,
    loading: contentstackLoading,
    error: contentstackError,
  } = useGetWithdrawalTableContent({
    variables: contentOptions,
  });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContentV2({
    variables: contentOptions,
  });

  useEffect(() => {
    const error = cashWithdrawalsError || contentstackError || opsDashboardContentError;
    if (error && cashWithdrawalsError?.message !== TOO_MANY_SEARCH_RECORDS_ERROR) {
      setState({
        error,
        loading: false,
      });
    }
  }, [cashWithdrawalsError, contentstackError, opsDashboardContentError]);

  useEffect(() => {
    const withdrawalItems: TransferItem[] = getWithdrawalItems(
      contentOptions,
      cashWithdrawalsData,
      opsDashboardContentData,
    );
    const pageContext = cashWithdrawalsData?.multiCashTransfers.paginationContext;
    const limit = pageContext?.limit || null;
    const offset = pageContext?.offset || null;
    const total = pageContext?.total || 0;
    const currentPage = getCurrentPage({ limit, offset });
    const totalPages = getTotalPages({ limit, total });
    const fields = opsDashboardContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const filterContext = cashWithdrawalsData?.multiCashTransfers.filterContext;
    const paginationContext = getPaginationContext(
      fields,
      currentPage,
      recordsPerPage || initialRecordsPerPage,
      total,
      updateRecordsPerPage,
    );
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_WITHDRAWALS);
    setState({
      data: {
        withdrawalItems,
        currentPage,
        totalPages,
        content: contentstackData,
        paginationContext,
        refetchCashWithdrawalsData,
        searchContext,
        searchError: cashWithdrawalsError?.message === TOO_MANY_SEARCH_RECORDS_ERROR,
        filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
      },
      loading: cashWithdrawalsLoading || contentstackLoading || opsDashboardContentLoading,
    });
  }, [
    cashWithdrawalsData,
    cashWithdrawalsLoading,
    contentstackLoading,
    contentstackData,
    opsDashboardContentLoading,
    opsDashboardContentData,
    refetchCashWithdrawalsData,
    contentOptions,
    cashWithdrawalsError?.message,
    recordsPerPage,
    updateRecordsPerPage,
  ]);

  return state;
};

const getWithdrawalItems = (
  contentOptions: ContentOptions,
  data?: CashTransfersV2,
  opsDashboardContent?: GetOpsDashboardContentV2,
): TransferItem[] => {
  const programeNames = opsDashboardContent?.all_product_name?.items || [];
  if (!data) {
    return [];
  }

  return data.multiCashTransfers.transfers.map(item => {
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(item.managedProduct?.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = item.managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = item.managedProduct?.clientParty?.id || '';
    // only used for activated account states
    const accountState = getAccountState({
      firstRebalancedOn: item.managedProduct?.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;

    return {
      id: item.id,
      accountNumber: item.managedProduct?.financialAccountNumber || '',
      accountState,
      accountTypeText: getAccountTypeText(
        item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
        opsDashboardContent?.all_account_type?.items || [],
      ),
      amount: parseFloat(item.total.value),
      clientName: `${item.managedProduct?.clientParty?.partyPerson?.givenName ?? ''} ${
        item.managedProduct?.clientParty?.partyPerson?.familyName ?? ''
      }`,
      createdAt: item.createdOn,
      destinationBankAccount: item.bankAccount?.accountNumber || '',
      flags: item.flags,
      frequency: item.frequency,
      isManagedProductTaxSheltered: !!item.managedProduct?.isTaxSheltered,
      managedProductId: item.managedProduct?.id || '',
      notes: item.notes || undefined,
      partyId,
      productName: getAccountProgramText(item.managedProduct?.program, item.managedProduct?.attributes, programeNames),
      rmdPlanId: getIntegerAttributeValue(item.attributes, 'RMD_PLAN_ID'),
      scheduledDate: item.scheduledAt || undefined,
      settlementDate: item.settlementDate || undefined,
      status: toCashTransferStatus(item.status),
      taxInformation: getTaxInformation(item.attributes),
      lastCommentData: item.entityNotes[0]
        ? {
            ...getLastEditDateAndTime(parseISO(item.entityNotes[0].created), contentOptions),
            lastValue: item.entityNotes[0].note,
          }
        : undefined,
      transferType: item.type,
      lastCommentPartyId: item.entityNotes[0]?.createdByPartyId ?? undefined,
    };
  });
};
