import React, { useState } from 'react';

import { SxProps, Tab, TabContext, TabPanel, Theme } from '~/components/ui/mui';
import { Tabs } from '~/components/ui/Tabs';

/**
 * contentCallback: Callback which returns tab content section
 *                      (It is a callback so that to prevent unnecessary rendering)
 * id: Unique ID for each tabs
 * label: Tab header
 */
export interface TabData {
  disabled?: boolean;
  id: string;
  label: React.ReactElement;
}

interface Props {
  dataQa?: string;
  getTabComponent: (tab: string) => React.ReactNode;
  initialTab?: string;
  tabSx?: SxProps<Theme>;
  tabs: TabData[];
  variant?: 'standard' | 'scrollable' | 'fullWidth';
}

export const TabsContainer: React.FC<Props> = ({
  dataQa = 'tabs-container',
  getTabComponent,
  initialTab,
  tabSx,
  tabs,
  variant = 'fullWidth',
}: Props) => {
  const [currentTab, setCurrentTab] = useState<string>(initialTab ?? tabs[0]?.id);

  if (tabs.length === 0) {
    return null;
  }

  return (
    <TabContext data-qa={`${dataQa}-tab-section`} value={currentTab}>
      <Tabs data-qa={`${dataQa}-tabs`} onChange={setCurrentTab} value={currentTab} variant={variant}>
        {tabs.map(tab => {
          const id = tab.id;
          return (
            <Tab
              data-qa={`${dataQa}-${id}-tab`}
              disabled={tab.disabled}
              key={`${id}-tab`}
              label={tab.label}
              sx={{ textTransform: 'none', ...tabSx }}
              value={id}
            />
          );
        })}
      </Tabs>
      <TabPanel
        data-qa={`${dataQa}-${currentTab}-tab-content`}
        key={`${dataQa}-${currentTab}-tab-content`}
        sx={{ p: 0 }}
        value={currentTab}
      >
        {getTabComponent(currentTab)}
      </TabPanel>
    </TabContext>
  );
};
