import * as mutation from './mutation.gql';

import {
  UpdateFlagStatus,
  UpdateFlagStatusVariables,
} from '~/components/modals/ResolveFlag/symphony/__generated__/mutation.v2';
import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useUpdateFlagStatus = (
  options?: MutationHookOptions<UpdateFlagStatus, UpdateFlagStatusVariables>,
): MutationTuple<UpdateFlagStatus, UpdateFlagStatusVariables> => {
  return useSymphonyMutation(mutation.UpdateFlagStatus, options);
};
