import { useEffect, useMemo, useState } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link/src/types/index';

import { useCreatePlaidAccessToken, useCreatePlaidLinkToken, useRefreshPlaidAccessToken } from './symphony';

import { PlaidProduct } from '~/__generated__';

export interface PlaidLinkComponentHookData {
  error?: Error;
  handleOpenPlaidLink: (partyId: string, products?: PlaidProduct[]) => Promise<void>;
  loading: boolean;
}

export enum PlaidLinkType {
  CLIENT = 'CLIENT',
  FINANCIAL_ADVISOR = 'FINANCIAL_ADVISOR',
}

export const ChasePlaidInstitutionId = 'ins_56';

export interface PlaidAccessTokenMetaData {
  accounts: {
    id: string;
    mask: string;
    name: string;
    type: string;
  }[];
  institution: {
    institutionId: string;
    name: string;
  };
}

export const usePlaidLinkHook = (
  onEvent?: () => void,
  onExit?: () => void,
  onSuccess?: (metaData: PlaidAccessTokenMetaData, isValidFinancialAccount?: boolean) => void,
  plaidItemId?: string,
): PlaidLinkComponentHookData => {
  const [linkToken, setLinkToken] = useState('');
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(false);
  const [linkTokenError, setLinkTokenError] = useState<Error>();
  const [accessTokenError, setAccessTokenError] = useState<Error>();
  const [partyIdBeingLinked, setPartyIdBeingLinked] = useState<string | undefined>();

  const [createPlaidLinkToken] = useCreatePlaidLinkToken();
  const [createPlaidAccessToken] = useCreatePlaidAccessToken();
  const [refreshPlaidAccessToken] = useRefreshPlaidAccessToken();

  const handleOpenPlaidLink = async (partyId: string, products?: PlaidProduct[]) => {
    setLoading(true);
    setError(undefined);
    setLinkTokenError(undefined);
    setAccessTokenError(undefined);
    setPartyIdBeingLinked(partyId);
    try {
      const plaidLinkToken = await createPlaidLinkToken({ variables: { partyId, products, itemId: plaidItemId } });
      if (plaidLinkToken.data?.createPlaidLinkToken.linkToken) {
        setLinkToken(plaidLinkToken.data.createPlaidLinkToken.linkToken);
      }
    } catch (err: any) {
      console.error(err);
      setLinkTokenError(err);
    }
  };

  const config: PlaidLinkOptions = useMemo(() => {
    const onSuccessCallback = async (successPublicToken: string, successMetadata: PlaidLinkOnSuccessMetadata) => {
      try {
        const accessTokenMetaData = {
          accounts: successMetadata.accounts.map(account => ({
            id: account.id ?? '',
            mask: account.mask ?? '',
            name: account.name ?? '',
            type: account.type ?? '',
          })),
          institution: {
            name: successMetadata.institution?.name ?? '',
            institutionId: successMetadata.institution?.institution_id ?? '',
          },
        };
        if (plaidItemId && partyIdBeingLinked) {
          await refreshPlaidAccessToken({
            variables: { partyId: partyIdBeingLinked, itemId: plaidItemId, metadata: accessTokenMetaData },
          });
          onSuccess?.(accessTokenMetaData, true);
          setLinkToken('');
        } else {
          const accessTokenResult = await createPlaidAccessToken({
            variables: {
              metadata: accessTokenMetaData,
              partyId: partyIdBeingLinked ?? '',
              publicToken: successPublicToken,
            },
          });
          setLinkToken('');
          if (accessTokenResult) {
            const isValidAccount =
              accessTokenResult.data?.createPlaidAccessToken?.financialAccounts?.reduce((isValid, account) => {
                if (!account.accountNumber || !account.routingNumber) {
                  isValid = false;
                }
                return isValid;
              }, true) ?? true;
            onSuccess?.(accessTokenMetaData, isValidAccount);
          }
        }
      } catch (err: any) {
        console.error(err);
        setAccessTokenError(err);
      }
    };

    const onExitCallback = () => {
      setLinkToken('');
      onExit?.();
    };

    return {
      token: linkToken,
      onSuccess: onSuccessCallback,
      onEvent,
      onExit: onExitCallback,
    };
  }, [
    createPlaidAccessToken,
    linkToken,
    onEvent,
    onExit,
    onSuccess,
    partyIdBeingLinked,
    plaidItemId,
    refreshPlaidAccessToken,
  ]);

  const { error: plaidError, open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (accessTokenError || linkTokenError || plaidError?.error) {
      setLoading(false);
      setError(accessTokenError || linkTokenError || plaidError?.error);
    }
  }, [accessTokenError, linkTokenError, plaidError]);

  useEffect(() => {
    if (ready) {
      setLoading(false);
      open();
    }
  }, [open, ready]);

  return { error, loading, handleOpenPlaidLink };
};
