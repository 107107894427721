import { TotalCashTransfers, TotalCashTransfersVariables } from './__generated__/query.v2';
import { TotalClosures, TotalClosuresVariables } from './__generated__/query.v2';
import { TotalDocusign } from './__generated__/query.v2';
import { TotalFailures } from './__generated__/query.v2';
import { TotalFinancialAccountAssociationVerificationRequests } from './__generated__/query.v2';
import { TotalFundingReviews } from './__generated__/query.v2';
import { TotalPendingModelChanges, TotalPendingModelChangesVariables } from './__generated__/query.v2';
import { TotalRecurringCancellationsCashTransfers } from './__generated__/query.v2';
import { TotalSuspensions } from './__generated__/query.v2';
import * as queries from './query.gql';

import { TotalACATRequests, TotalACATRequestsVariables } from '~/containers/OpsDashboard/symphony/__generated__/query.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useTotalCashTransfers = (
  options?: QueryHookOptions<TotalCashTransfers, TotalCashTransfersVariables>,
): QueryResult<TotalCashTransfers> => {
  return useSymphonyQuery(queries.TotalCashTransfers, options);
};

export const useTotalRecurringCancellationsCashTransfers = (
  options?: QueryHookOptions<TotalRecurringCancellationsCashTransfers>,
): QueryResult<TotalRecurringCancellationsCashTransfers> => {
  return useSymphonyQuery(queries.TotalRecurringCancellationsCashTransfers, options);
};

export const useTotalClosures = (
  options?: QueryHookOptions<TotalClosures, TotalClosuresVariables>,
): QueryResult<TotalClosures> => {
  return useSymphonyQuery(queries.TotalClosures, options);
};

export const useTotalSuspensions = (options?: QueryHookOptions<TotalSuspensions>): QueryResult<TotalSuspensions> => {
  return useSymphonyQuery(queries.TotalSuspensions, options);
};

export const useTotalFailures = (options?: QueryHookOptions<TotalFailures>): QueryResult<TotalFailures> => {
  return useSymphonyQuery(queries.TotalFailures, options);
};

export const useTotalDocusign = (options?: QueryHookOptions<TotalDocusign>): QueryResult<TotalDocusign> => {
  return useSymphonyQuery(queries.TotalDocusign, options);
};

export const useTotalPendingModelChanges = (
  options?: QueryHookOptions<TotalPendingModelChanges, TotalPendingModelChangesVariables>,
): QueryResult<TotalPendingModelChanges, TotalPendingModelChangesVariables> => {
  return useSymphonyQuery(queries.TotalPendingModelChanges, options);
};

export const useTotalACATRequests = (
  options?: QueryHookOptions<TotalACATRequests, TotalACATRequestsVariables>,
): QueryResult<TotalACATRequests> => {
  return useSymphonyQuery(queries.TotalACATRequests, options);
};

export const useTotalFinancialAccountAssociationVerificationRequests = (
  options?: QueryHookOptions<TotalFinancialAccountAssociationVerificationRequests>,
): QueryResult<TotalFinancialAccountAssociationVerificationRequests> => {
  return useSymphonyQuery(queries.TotalFinancialAccountAssociationVerificationRequests, options);
};
export const useTotalFundingReviews = (
  options?: QueryHookOptions<TotalFundingReviews>,
): QueryResult<TotalFundingReviews> => {
  return useSymphonyQuery(queries.TotalFundingReviews, options);
};
