import React, { ComponentProps, useEffect, useState } from 'react';

import { useGetDeleteBankAccountModalContent } from './contentstack';
import { useDeleteBankAccount } from './symphony';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, LoadingButton } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { ExtendedApolloError } from '~/utils/symphony';

export type Props = ComponentProps<typeof Modal> & {
  accountId: string;
  contentOptions: ContentOptions;
  maskedAccountNumber: string;
  onClose?: () => void;
  onRemoveAccount?: (accountId: string) => void;
  partyId: string;
};

export const DeleteBankAccountModal: React.FC<Props> = ({
  contentOptions,
  accountId,
  maskedAccountNumber,
  onClose,
  onRemoveAccount,
  open,
  dataQa = 'delete-bank-account-modal',
  partyId,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [deleteBankAccount, { error: onDeleteBankAccountError }] = useDeleteBankAccount();

  useEffect(() => {
    // Needed because this variable is undefined in the catch block
    if (onDeleteBankAccountError) {
      console.error(`Error received while deleting bank account, ${onDeleteBankAccountError}`);
    }
  }, [onDeleteBankAccountError]);

  const handleSubmit = async () => {
    setIsSubmitted(true);
    try {
      await deleteBankAccount({
        variables: {
          bankAccountId: accountId,
          partyId,
          softDelete: true,
        },
      });
      onClose?.();
      onRemoveAccount?.(accountId);
    } catch {
      setIsSubmitted(false);
    }
  };

  const {
    data: deleteBankAccountModalContent,
    loading: contentLoading,
    error: contentError,
  } = useGetDeleteBankAccountModalContent({
    variables: { partner: contentOptions.partner, locale: contentOptions.locale, product: contentOptions.product },
    skip: !open,
  });

  const content = deleteBankAccountModalContent?.all_delete_bank_account_modal?.items?.[0];

  return (
    <Modal
      actions={
        <>
          <Button
            color="primary"
            data-qa={`${dataQa}-cta-secondary`}
            onClick={onClose as () => void}
            variant="outlined"
          >
            {content?.cta?.secondary}
          </Button>
          <LoadingButton
            data-qa={`${dataQa}-cta-primary`}
            id="add-btn"
            loading={isSubmitted}
            onClick={handleSubmit}
            variant="contained"
          >
            {content?.cta?.primary}
          </LoadingButton>
        </>
      }
      content={
        <>
          <Box data-qa={`${dataQa}-content`} flexDirection="column" sx={{ p: 2 }}>
            <Typography component="div" data-qa={`${dataQa}-content-heading`} variant="h4">
              {content?.labels?.header}
            </Typography>
            <Typography data-qa={`${dataQa}-content-sub-heading`} sx={{ pt: 2 }} variant="body2">
              {content?.labels?.sub_header}
            </Typography>
          </Box>
          {onDeleteBankAccountError && (
            <Alert contentOptions={contentOptions} error={onDeleteBankAccountError} severity="error">
              {
                content?.error?.find(
                  err =>
                    err?.error_code === (onDeleteBankAccountError as ExtendedApolloError).graphQLErrors[0]?.errorCode,
                )?.message
              }
            </Alert>
          )}
        </>
      }
      contentOptions={contentOptions}
      data-qa={`${dataQa}-modal`}
      error={contentError}
      loading={contentLoading}
      onClose={onClose}
      open={open}
      title={
        <RteContent
          config={{
            maskedAccountNumber,
          }}
          data={content?.modal_title ?? ''}
          data-qa={`${dataQa}-title`}
        />
      }
    />
  );
};
