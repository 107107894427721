import React, { FC, useMemo } from 'react';

import { formatBalance, formatDisplayDate } from '../utils';

import { PerformanceDetails, PerformanceDetailsType } from '~/components/modals/PerformanceDetails';
import { Chart } from '~/components/PerformanceChart/Chart';
import { fillMissingData } from '~/components/PerformanceChart/utils';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Button, Grid, OpenInNewIcon, useTheme } from '~/components/ui/mui';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';
import { AccountPerformanceContent } from '~/containers/AccountPerformance/utils';
import { OpeningClosingBalance } from '~/hooks/qpr/useGetDataForQPR';
import { ContentOptions } from '~/utils/contentstack';
import { DailyChartValue } from '~/utils/types';

export interface Props {
  content: AccountPerformanceContent;
  contentOptions: ContentOptions;
  dataQa?: string;
  endDate: string;
  openingClosingBalance?: OpeningClosingBalance;
  performanceChartData: DailyChartValue[];
  returnPercentage?: string;
  showFullHorizontalChart?: boolean;
  startDate?: string | null;
}

export const AccountBalanceChart: FC<Props> = ({
  content,
  contentOptions,
  dataQa = 'account-balance',
  endDate,
  openingClosingBalance,
  performanceChartData,
  returnPercentage,
  showFullHorizontalChart = false,
  startDate,
}) => {
  const {
    sfAccountPerformance: { styles, typographyVariants },
  } = useTheme();
  const { open, openModal, onClose } = useModalState();
  const { accountOverview, balanceChart } = content;
  const endingBalance = useMemo(() => {
    if (openingClosingBalance?.closingBalance) {
      return formatBalance(openingClosingBalance.closingBalance, contentOptions);
    }
    return 'Unknown';
  }, [contentOptions, openingClosingBalance]);
  const startingBalance = useMemo(() => {
    if (openingClosingBalance?.openingBalance) {
      return formatBalance(openingClosingBalance.openingBalance, contentOptions);
    }
    return 'Unknown';
  }, [contentOptions, openingClosingBalance]);
  const balanceChange = useMemo(() => {
    if (openingClosingBalance?.difference) {
      return formatBalance(openingClosingBalance.difference, contentOptions);
    }
    return 'Unknown';
  }, [contentOptions, openingClosingBalance]);
  const performanceTableData = useMemo(() => {
    return fillMissingData(performanceChartData).map(performanceData => {
      return { date: performanceData.date, dataPoints: [performanceData.amount.toString(10)] };
    });
  }, [performanceChartData]);

  /*
    Determine the order and size of the chart grid based of 'showFullHorizontalChart' flag
    whether it should be displayed in full horizontally or not.
  */
  const chartGridOrder = showFullHorizontalChart ? 1 : 2;
  const chartGridSize = showFullHorizontalChart ? 12 : 8;
  const balanceGridSize = showFullHorizontalChart ? 12 : 4;

  return (
    <Box
      id="accountBalance"
      sx={{ ...styles.container, justifyContent: 'space-between', ...(showFullHorizontalChart && { mt: 0 }) }}
    >
      <Grid container data-qa={dataQa}>
        <Grid item sm={4}>
          <Typography component="h2" variant={typographyVariants?.accountBalanceHeading}>
            {balanceChart.labels.accountBalance}
          </Typography>
          <Typography color="text.secondary" sx={{ mt: 1 }} variant={typographyVariants?.date}>
            {`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <Grid container justifyContent="flex-end">
            <Button onClick={openModal} sx={{ mb: 3 }} variant="outlined">
              {balanceChart.ctas.detailedTable} <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sm={12}>
        {/* Chart Container */}
        <Grid item order={chartGridOrder} sm={chartGridSize}>
          <Chart contentOptions={contentOptions} data={performanceChartData} height={200} />
          {performanceChartData.length > 0 && (
            <PerformanceDetails
              contentOptions={contentOptions}
              onClose={onClose}
              open={open}
              performanceData={performanceTableData}
              type={PerformanceDetailsType.AccountBalance}
            />
          )}
        </Grid>
        {/* Account Balance Information */}
        <Grid item order={1} sm={balanceGridSize}>
          <Grid columnGap={7} container sx={{ ...(showFullHorizontalChart && { mt: 3 }) }}>
            <Grid item sm={!showFullHorizontalChart && 12} sx={{ ...(!showFullHorizontalChart && { my: 4 }) }}>
              <Typography color="text.secondary" variant={typographyVariants?.accountBalance}>
                {balanceChart.labels.accountBalance}
              </Typography>
              <Typography sx={{ mt: 0.5 }} variant={typographyVariants?.balanceValue}>
                {endingBalance}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="text.secondary" variant={typographyVariants?.accountBalance}>
                {balanceChart.labels.startingBalance}
              </Typography>
              <Typography sx={{ mt: 1 }} variant={typographyVariants?.otherBalance}>
                {startingBalance}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="text.secondary" variant={typographyVariants?.accountBalance}>
                {showFullHorizontalChart ? accountOverview.labels.returns : balanceChart.labels.totalEarned}
                {showFullHorizontalChart && (
                  <Tooltip
                    tooltipContent={
                      <Typography variant="caption">{accountOverview.labels.returnsHelperText}</Typography>
                    }
                  />
                )}
              </Typography>
              <Typography
                sx={{ ...(!showFullHorizontalChart && { mt: 1 }) }}
                variant={typographyVariants?.otherBalance}
              >
                {showFullHorizontalChart ? returnPercentage : balanceChange}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
