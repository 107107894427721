import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormHelperText,
  FormLabel,
  MuiTypography,
} from '~/components/ui/mui';

export type CheckboxGroupChangeEvent = (state: Record<string, boolean>) => void;

export type Item = Pick<FormControlLabelProps, 'checked' | 'defaultChecked' | 'label' | 'disabled'> &
  Required<Pick<FormControlLabelProps, 'name'>>;

export interface Props extends Omit<FormControlProps, 'onChange'> {
  dataQa?: string;
  description?: string;
  disabled?: boolean;
  items: Item[];
  legend?: string;
  onChange?: CheckboxGroupChangeEvent;
  value?: Record<string, boolean>;
}

export const CheckboxGroup: React.FC<Props> = ({
  dataQa = 'checkbox-group',
  disabled,
  description,
  color = 'primary',
  error,
  items,
  legend,
  onChange,
  required,
  value,
}) => {
  const [state, setState] = useState<Record<string, boolean>>(
    value
      ? value
      : // this is added for backward compatibility for checkGroup's uncontrolled usage
        items.reduce((acc, item) => Object.assign(acc, { [item.name]: !!item.checked || !!item.defaultChecked }), {}),
  );

  useEffect(() => {
    if (value) {
      setState(value);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...state, [event.target.name]: event.target.checked };
    setState(newState);
    if (onChange) {
      onChange(newState);
    }
  };
  return (
    <FormControl component="fieldset" data-qa={dataQa} error={error} required={required}>
      <FormLabel component="legend">{legend}</FormLabel>
      <FormGroup>
        {items.map((item, index) => (
          <FormControlLabel
            control={<Checkbox checked={state[item.name]} color={color} name={item.name} onChange={handleChange} />}
            disabled={disabled || item.disabled}
            key={index}
            label={<MuiTypography variant="body2">{item.label}</MuiTypography>}
          />
        ))}
      </FormGroup>
      <FormHelperText>{description}</FormHelperText>
    </FormControl>
  );
};
