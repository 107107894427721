import React, { ComponentProps } from 'react';

import { OpsDashboard } from '../..';
import { Comment, CommentComponentColumn } from '../../common/Comment';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { GetOpsDashboardContent } from '../../contentstack/__generated__/query.v2';
import { RemoveSuspensionData, TradingSuspensionsContent, TradingSuspensionsTableContent } from '../../types';
import {
  getAccountNumberOrAccountTypeString,
  getLastEditDateAndTime,
  isAccountNumberClickable,
  OpsDashboardUser,
} from '../../utils';
import { GetTradingSuspensionsContent } from '../contentstack/__generated__/query.v2';
import { TradingSuspensions } from '../symphony';

import { FinancialAccountType, MultiRestrictionPlacedBy, UserNoteEntityType } from '~/__generated__';
import { Chip } from '~/components/ui/Chip';
import { Button } from '~/components/ui/mui';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { getFullName } from '~/hooks/party/utils';
import { getAccountProgramText, getAccountState, isPartnerOpsSuspension } from '~/utils/account';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

export interface TradingSuspensionsData extends TradingSuspensions {
  groupingIndex?: number;
}

export const getTradingSuspensionContent = (
  content: GetTradingSuspensionsContent | undefined,
): TradingSuspensionsContent => {
  const item = content?.all_trading_suspensions_table?.items?.[0];
  const textFields = item?.fields?.text ?? [];
  return {
    columns: item?.columns?.column,
    emptySuspensionTagLabel: findFieldValue(textFields, 'empty_suspension_tag_label'),
    groupSuspensionToggleLabel: findFieldValue(textFields, 'group_suspension_toggle_label'),
    opsPartnerSuspensionToggleLabel: findFieldValue(textFields, 'ops_partner_suspension_toggle_label'),
    removeSuspensionCta: findFieldValue(textFields, 'remove_suspension_cta'),
    removeSuspensionFailure: findFieldValue(textFields, 'remove_suspension_failure'),
    removeSuspensionSuccess: findFieldValue(textFields, 'remove_suspension_success'),
  };
};

export const getTableContent = (
  commentProps: {
    commentColumn: CommentComponentColumn | undefined;
    contentOptions: ContentOptions;
    currentUser: OpsDashboardUser;
    refetchTradingSuspendedData: () => void;
  },
  content: TradingSuspensionsContent,
  tradingSuspensions: TradingSuspensionsData[],
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'],
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'],
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'],
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'],
  onRemoveSuspensionClick: (suspensionData: RemoveSuspensionData) => void,
  opsDashboardContentData: GetOpsDashboardContent,
  showProductName: boolean,
  suspensionTagStyleMap?: Record<string, string>,
): TradingSuspensionsTableContent[] => {
  return tradingSuspensions.map((tradingSuspension, index) => {
    const entityNotes = tradingSuspension.entityNotes.length ? tradingSuspension.entityNotes[0] : null;
    const partyPerson = tradingSuspension.createdByParty?.partyPerson;
    const programNames = opsDashboardContentData.all_product_name?.items || [];
    const accountTypeContent = opsDashboardContentData.all_account_type?.items || [];

    const shouldShowAccountClick = tradingSuspension.managedProduct
      ? isAccountNumberClickable(
          // only used for activated account states
          getAccountState({
            firstRebalancedOn: tradingSuspension.managedProduct.firstRebalancedOn ?? undefined,
            financialAccountStatus: tradingSuspension.managedProduct.status,
            suspendedOn: tradingSuspension.createdAt,
          }).state,
        )
      : false;

    const suspensionDisplayContent = {
      placedByUser: getFullName(partyPerson) || 'Operations',
      placedBy: MultiRestrictionPlacedBy.PARTNER_OPS,
      created: tradingSuspension.createdAt ? formatDate(tradingSuspension.createdAt) : '-',
    };

    const suspension: RemoveSuspensionData = {
      data: suspensionDisplayContent,
      managedProductId: tradingSuspension.managedProduct.id,
      suspensionType: tradingSuspension.suspensionType,
      suspensionTag: tradingSuspension.suspensionTag
        ? `${tradingSuspension.suspensionTag}`
        : content.emptySuspensionTagLabel,
    };

    const suspensionTag = tradingSuspension.suspensionTag
      ? `${tradingSuspension.suspensionTag}`
      : content.emptySuspensionTagLabel;

    // zero index (or first row) for grouped suspension and undefined index (or every row) for non-grouped suspension
    const showAccountAndClientData =
      tradingSuspension.groupingIndex === undefined || tradingSuspension.groupingIndex === 0;

    return {
      rowKey: tradingSuspension.id + index,
      accountNumber: showAccountAndClientData ? (
        <AccountNumber
          accountNumber={tradingSuspension.managedProduct.financialAccountNumber}
          accountNumberText={getAccountNumberOrAccountTypeString(
            tradingSuspension.managedProduct.financialAccountNumber,
            getAccountTypeText(
              tradingSuspension.managedProduct.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
              accountTypeContent,
            ),
          )}
          label={
            showProductName
              ? getAccountProgramText(
                  tradingSuspension.managedProduct.program,
                  tradingSuspension.managedProduct.attributes,
                  programNames,
                )
              : null
          }
          onClick={
            shouldShowAccountClick &&
            tradingSuspension.managedProduct.clientParty?.id &&
            tradingSuspension.managedProduct.id
              ? () =>
                  onAccountClick(
                    tradingSuspension.managedProduct.clientParty?.id ?? '',
                    tradingSuspension.managedProduct.id ?? '',
                    AccountDetailsTabsEnum.suspensions,
                  )
              : undefined
          }
          redirectUrl={
            tradingSuspension.managedProduct.clientParty?.id && tradingSuspension.managedProduct.id
              ? getAccountNumberRedirectUrl(
                  tradingSuspension.managedProduct.clientParty.id,
                  tradingSuspension.managedProduct.id,
                )
              : undefined
          }
        />
      ) : (
        <></>
      ),
      clientName: showAccountAndClientData ? (
        <ClientName
          clientName={getFullName(tradingSuspension.managedProduct.clientParty?.partyPerson)}
          onClick={() => onClientClick(tradingSuspension.managedProduct.clientParty?.id ?? '')}
          redirectUrl={
            tradingSuspension.managedProduct.clientParty?.id
              ? getClientNameRedirectUrl(tradingSuspension.managedProduct.clientParty.id)
              : undefined
          }
        />
      ) : (
        <></>
      ),
      created: tradingSuspension.createdAt ? formatDate(tradingSuspension.createdAt) : '-',
      suspendedBy: getFullName(partyPerson) || 'Operations',
      suspensionType: `${tradingSuspension.suspensionType}`,
      suspensionTag: (
        <Chip
          label={suspensionTag}
          sx={{
            backgroundColor: suspensionTagStyleMap && suspensionTagStyleMap[suspensionTag.toLowerCase()],
            borderRadius: 1,
            width: '104px',
          }}
          variant="filled"
        />
      ),
      removeSuspensionCta: isPartnerOpsSuspension(tradingSuspension) ? (
        <Button onClick={() => onRemoveSuspensionClick(suspension)} size="small" variant="outlined">
          {content.removeSuspensionCta}
        </Button>
      ) : (
        <></>
      ),
      comment: (
        <Comment
          comment={
            entityNotes
              ? {
                  lastValue: entityNotes.note,
                  ...getLastEditDateAndTime(new Date(entityNotes.created), commentProps.contentOptions),
                }
              : undefined
          }
          content={commentProps.commentColumn}
          contentOptions={commentProps.contentOptions}
          currentUser={commentProps.currentUser}
          entity={UserNoteEntityType.TRADING_SUSPENSION}
          entityId={tradingSuspension.id}
          key={tradingSuspension.id}
          lastCommentPartyId={entityNotes?.createdByPartyId}
          refetchData={commentProps.refetchTradingSuspendedData}
        />
      ),
    };
  });
};

export const getOffset = (pageNumber: number, recordsPerPage: number) => (pageNumber - 1) * recordsPerPage;
