import { GetErrorsTableContent, GetErrorsTableContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetErrorsTableContent = (
  options?: QueryHookOptions<GetErrorsTableContent, GetErrorsTableContentVariables>,
): QueryResult<GetErrorsTableContent, GetErrorsTableContentVariables> => {
  return useContentstackQuery(queries.GetErrorsTableContent, options);
};
