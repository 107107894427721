import { GetRestrictionsContentV2, GetRestrictionsContentV2Variables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetRestrictionsContent = (
  options?: QueryHookOptions<GetRestrictionsContentV2, GetRestrictionsContentV2Variables>,
): QueryResult<GetRestrictionsContentV2, GetRestrictionsContentV2Variables> => {
  return useContentstackQuery(queries.GetRestrictionsContentV2, options);
};
