import { useCallback, useState } from 'react';

/**
 * Returns Modal visibility controls to be passed to Modal components
 * @param defaultOpenState Set to true if modal should be open initially, defaults to false
 * @example
 * const Component = (props: Props) => {
 *  const { open, openModal, onClose } = useModalState();
 *
 *  return (
 *    <>
 *      <button onClick={openModal}>Open modal 1</button>
 *      <Modal open={open} onClose={onClose} />
 *    </>
 *  );
 * };
 */
export interface ModalStateData {
  feedbackMessage: string;
  onClose: (feedbackMessageFromModal?: any) => void;
  open: boolean;
  openModal: () => void;
}

export const useModalState = (defaultOpenState = false): ModalStateData => {
  const [open, setOpen] = useState(defaultOpenState);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const openModal = useCallback(() => {
    setOpen(true);
  }, []);
  const onClose = useCallback((feedbackMessageFromModal = '') => {
    // Check to bypass onClick mouseEvent received as argument
    if (typeof feedbackMessageFromModal === 'string') {
      setFeedbackMessage(feedbackMessageFromModal);
    }
    setOpen(false);
  }, []);

  return { openModal, onClose, open, feedbackMessage };
};

/**
 * Can be used to create multiple useModalState hooks to support multiple modals in a single component
 * @example
 * const useModalState1 = makeModalStateHook();
 * const useModalState2 = makeModalStateHook();
 *
 * const Component = (props: Props) => {
 *  const { open, openModal, onClose } = useModalState1();
 *  const { open: modal2Open, openModal: openModal2, onClose: onCloseModal2 } = useModalState2();
 *
 *  return (
 *    <>
 *      <button onClick={openModal}>Open modal 1</button>
 *      <button onClick={openModal2}>Open modal 2</button>
 *      <Modal open={open} onClose={onClose} />
 *      <Modal open={modal2Open} onClose={onCloseModal2} />
 *    </>
 *  );
 * };
 */
export const makeModalStateHook = () => useModalState;
