import { useEffect, useState } from 'react';

import { CMPAllocation } from '..';
import {
  CompositeModelPortfolioAllocationSection,
  useGetCompositeModelPortfolioAllocationContent,
} from '../contentstack';
import { GetCompositeModelPortfolioAllocationContent } from '../contentstack/__generated__/query.v2';

import { useGetAllModelPortfoliosContentAcrossPages } from '~/hooks/model-portfolios/contentstack';
import { GetModelPortfoliosContent_all_model_portfolio_data_items } from '~/hooks/model-portfolios/contentstack/__generated__/query.v2';
import { ContentOptions, getImageAssetUrl } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface CompositeModelPortfolioAllocationDataVariables {
  allocation: CMPAllocation[];
  contentOptions: ContentOptions;
}

export interface CompositeModelPortfolioAllocationData {
  modelPortfolioInternalId: number;
  modelPortfolioName: string;
  modelPortfolioSeriesId?: number;
  percentage: string;
}

export interface CompositeModelPortfolioAllocationContent {
  donutImageUrl: string;
  percentageLabel: string;
  portfolioLabel: string;
  title: string;
}

export interface CompositeModelPortfolioAllocationComponentData {
  compositeModelPortfolioAllocationData: CompositeModelPortfolioAllocationData[];
  content: CompositeModelPortfolioAllocationContent;
}

export const useCompositeModelPortfolioAllocation = ({
  contentOptions,
  allocation,
}: CompositeModelPortfolioAllocationDataVariables): AsyncResult<CompositeModelPortfolioAllocationComponentData> => {
  const [state, setState] = useState<AsyncResult<CompositeModelPortfolioAllocationComponentData>>({
    loading: true,
  });

  const {
    data: contentData,
    loading: contentLoading,
    error: contentError,
  } = useGetCompositeModelPortfolioAllocationContent({
    variables: contentOptions,
  });

  const {
    data: modelPortfoliosContentData,
    loading: modelPortfoliosContentLoading,
    error: modelPortfoliosContentError,
  } = useGetAllModelPortfoliosContentAcrossPages({
    contentOptions,
  });

  useEffect(() => {
    if (contentError || modelPortfoliosContentError) {
      setState({
        error: contentError || modelPortfoliosContentError,
        loading: false,
      });
    }
  }, [modelPortfoliosContentError, contentError]);

  useEffect(() => {
    if (!contentLoading && !modelPortfoliosContentLoading && contentData && modelPortfoliosContentData) {
      const modelPortfolios = modelPortfoliosContentData.all_model_portfolio_data?.items || [];
      const compositeModelPortfolioAllocationData = getCompositeModelPortfolioSleeveAllocationData(
        allocation,
        modelPortfolios,
      );
      const content = getCompositeModelPortfolioAllocationContent(contentData);
      setState({
        data: { compositeModelPortfolioAllocationData, content },
        loading: false,
      });
    }
  }, [contentLoading, modelPortfoliosContentLoading, contentData, modelPortfoliosContentData, allocation]);

  return state;
};

export const getCompositeModelPortfolioSleeveAllocationData = (
  targetModelPortfolioAllocation: CMPAllocation[],
  modelPortfoliosContentData: (GetModelPortfoliosContent_all_model_portfolio_data_items | null)[],
): CompositeModelPortfolioAllocationData[] => {
  return targetModelPortfolioAllocation.map(sleeve => {
    const modelPortfolioContent = modelPortfoliosContentData?.find(
      mc =>
        mc?.series_name === sleeve.modelPortfolioSeriesBaseName &&
        mc?.internal_id === sleeve.modelPortfolioInternalId?.toString(),
    );

    return {
      modelPortfolioInternalId: sleeve.modelPortfolioInternalId,
      modelPortfolioName: modelPortfolioContent?.model_portfolio_name_shorthand || 'Unknown',
      modelPortfolioSeriesId: sleeve.modelPortfolioSeriesId,
      percentage: sleeve.percentage,
    };
  });
};

export const getCompositeModelPortfolioAllocationContent = (
  contentData: GetCompositeModelPortfolioAllocationContent | undefined,
): CompositeModelPortfolioAllocationContent => {
  const compositeModelPortfolioAllocationContent = (contentData?.all_account_details?.items?.[0]?.sections?.find(
    section => section?.__typename === 'AccountDetailsSectionsCompositeModelPortfolioAllocation',
  ) as CompositeModelPortfolioAllocationSection)?.composite_model_portfolio_allocation;
  const content =
    compositeModelPortfolioAllocationContent?.composite_model_portfolio_allocation_referenceConnection?.edges?.[0]?.node
      ?.sections?.[0]?.composite_model_portfolio_allocation_details || null;
  return {
    donutImageUrl: getImageAssetUrl(content?.donut_imageConnection),
    percentageLabel: content?.labels?.percentage ?? 'percentage',
    portfolioLabel: content?.labels?.portfolio ?? 'portfolio',
    title: content?.title ?? 'title',
  };
};
