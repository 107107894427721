import React from 'react';
import { Alert } from '~/components/ui/Alert';
import { BasicTable } from '~/components/ui/BasicTable';
import { Skeleton, Stack } from '~/components/ui/mui';
import { commonStyles } from '../utils';

import useSuspensionsHistoryData from './hooks/useSuspensionsHistoryData';

import { ContentOptions } from '~/utils/contentstack';

interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
}

const SuspensionsHistory: React.FC<Props> = ({ contentOptions, dataQa, managedProductId }) => {
  const { data, error, loading } = useSuspensionsHistoryData(contentOptions, managedProductId);

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (!data || error) {
    return <Alert contentOptions={contentOptions} error={error} severity="error" />;
  }

  return (
    <Stack data-qa={dataQa} sx={{ pt: 4 }}>
      <BasicTable
        alignItems="left"
        columns={data.columnsData}
        currentPage={data.currentPage}
        data={data.tableData}
        onPageChange={data.onPageChange}
        sortConfig={data.sortConfig}
        sx={commonStyles.tableSx}
        totalPages={data.totalPages}
      />
    </Stack>
  );
};

export default SuspensionsHistory;
