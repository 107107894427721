import { TableColumn } from '~/components/ui/BasicTable';

export enum TableColumns {
  Date = 'Date',
  Description = 'Description',
  Download = 'Download',
  Ticker = 'Ticker',
}

export interface TableColumnConfig {
  key: TableColumns;
  wrappable: boolean;
}

export interface TableSectionContent {
  columns: TableColumn[];
  documentsFailedText: string;
  downloadIconContent: DownloadIconContent;
  emptyTableText: string;
}

interface DownloadIconContent {
  downloadAction: string;
  downloadIconUrl: string;
}
