import parseISO from 'date-fns/parseISO';
import React from 'react';
import {
  CartesianGrid,
  DotProps,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { getDateFormatType, getXAxisTicks } from './utils';

import { alpha, Box, Paper, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { abbreviatedDateFormat, formatCurrency, formatCurrencyPrecise } from '~/utils/format';
import { roundDown, roundUp } from '~/utils/math';
import { getTextProps } from '~/utils/theme';
import { DailyChartValue } from '~/utils/types';

export interface Props {
  contentOptions: ContentOptions;
  data: DailyChartValue[];
  height?: number | string;
  width?: number | string;
}

const CustomTooltip = ({ active, label, labelFormatter, payload, formatter }: TooltipProps<number, number>) => {
  if (active) {
    const value = payload?.[0]?.value;
    return (
      <Paper sx={{ py: 2, px: 3 }}>
        <Typography component="div" sx={{ color: 'text.secondary', mb: 0.5 }} variant="caption">
          {label && labelFormatter?.(label, [])}
        </Typography>
        <Typography variant="subtitle1">{value && formatter?.(value)}</Typography>
      </Paper>
    );
  }
  return null;
};

const CustomActiveDot = ({ cx, cy, fill }: DotProps) => {
  return (
    <g>
      <circle cx={cx} cy={cy} fill={alpha(fill ?? '', 0.3)} r="8.5" />
      <circle cx={cx} cy={cy} fill={fill} r="4.5" />
    </g>
  );
};

export const Chart: React.FC<Props> = ({ contentOptions, height = 400, width = '100%', data }) => {
  const theme = useTheme();
  const styles = theme.sfPerformanceChart.styles;
  const {
    components: {
      sfAccountPerformance: { showOnlyYearsInChart },
    },
  } = useCoreConfig();
  const yAxisMin = roundDown(Math.min(...data.map(p => p.amount)), 1000);
  const yAxisMax = roundUp(Math.max(...data.map(p => p.amount)), 1000);

  const xAxisPadding = 40 + yAxisMax.toString().length * 5;

  const dateFormatType = getDateFormatType(data, showOnlyYearsInChart);

  return (
    <Box aria-hidden>
      <ResponsiveContainer height={height} id="performance-chart" width={width}>
        <LineChart data={data} margin={{ top: 20, left: 10, right: 10 }}>
          <CartesianGrid stroke={theme.palette.divider} strokeDasharray="10 10" strokeWidth={2} vertical={false} />
          <XAxis
            dataKey="date"
            interval="preserveStartEnd"
            padding={{ right: xAxisPadding }}
            stroke={theme.palette.divider}
            strokeDasharray="10 10"
            strokeDashoffset="10"
            strokeWidth={2}
            textAnchor="middle"
            tick={getTextProps(theme, 'caption')}
            tickFormatter={tick =>
              abbreviatedDateFormat(parseISO(tick), { locale: contentOptions.locale }, dateFormatType)
            }
            ticks={getXAxisTicks(data)}
          />
          <YAxis
            axisLine={false}
            domain={[yAxisMin, yAxisMax]}
            mirror
            orientation="right"
            tick={{ ...getTextProps(theme, 'caption'), dy: -10 }}
            tickFormatter={tick => formatCurrency(tick, { locale: contentOptions.locale })}
            tickSize={0}
          />
          <Tooltip
            content={CustomTooltip}
            formatter={(value: number) => formatCurrencyPrecise(value, { locale: contentOptions.locale })}
            labelFormatter={(label: string) =>
              abbreviatedDateFormat(parseISO(label), { locale: contentOptions.locale })
            }
          />
          <Line
            activeDot={<CustomActiveDot fill={styles.lineActiveDotColor || styles.lineColor} />}
            dataKey="amount"
            dot={false}
            stroke={styles.lineColor as string | undefined}
            strokeWidth={2}
            type="monotone"
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
