import {
  GetBillingHistory,
  GetBillingHistory_managedProduct_billingRecordHistory,
  GetBillingHistoryVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type BillingRecordHistory = GetBillingHistory_managedProduct_billingRecordHistory;

export const useGetBillingHistory = (options?: QueryHookOptions<GetBillingHistory, GetBillingHistoryVariables>) => {
  return useSymphonyQuery(queries.GetBillingHistory, options);
};
