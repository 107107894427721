import { GetSigningDocuments, GetSigningDocumentsVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetSigningDocuments = (
  options?: QueryHookOptions<GetSigningDocuments, GetSigningDocumentsVariables>,
): QueryResult<GetSigningDocuments, GetSigningDocumentsVariables> => {
  return useSymphonyQuery(queries.GetSigningDocuments, options);
};
