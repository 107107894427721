import {
  GetAccountDetailsV2Content,
  GetAccountDetailsV2Content_all_account_details_v2_items,
} from '../contentstack/__generated__/query.v2';

import { AccountDetailsHeaderContent, AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { getLabel } from '~/utils/content';
import { TextField } from '~/utils/types';

export interface Content {
  accountDetailsHeader: AccountDetailsHeaderContent;
  accountNumberFormat: string;
  advisorLabel: string;
  clientLabel: string;
  editPortfolioFeedbackMessage: string;
  tabHeader: Partial<Record<AccountDetailsTabsEnum, string>>;
  textFields: (TextField | null)[] | null;
}

const getAccountDetailsHeaderContent = (
  content?: GetAccountDetailsV2Content_all_account_details_v2_items | null,
): AccountDetailsHeaderContent => ({
  header: {
    accountNumberFormat:
      content?.account_number_formatConnection?.edges?.[0]?.node?.pattern ?? 'account_number_formatConnection',
    activeAccountLabel: getLabel(content, 'dropdown_group_active_label'),
    addFundsCta: getLabel(content, 'add_funds_cta'),
    bankAccountCta: getLabel(content, 'bank_account_cta'),
    belowAccountMinimumLabel: getLabel(content, 'below_account_minimum_label'),
    closedAccountLabel: getLabel(content, 'dropdown_group_closed_label'),
    journalingCta: getLabel(content, 'journaling_cta'),
    raiseCashCta: getLabel(content, 'raise_cash_cta'),
    title: getLabel(content, 'title'),
    viewingDropdownOptions: {
      quarterlyPerformance: '', // This is not used in V2, just to reuse existing components
    },
  },
  accessibility: {
    accountDetailsMenuPreLabel: getLabel(content, 'account_details_menu_pre_label'),
    accountDetailsMenuLabel: getLabel(content, 'account_details_menu_label'),
  },
});

export const getContent = (
  tabs: Partial<AccountDetailsTabsEnum>[],
  contentData?: GetAccountDetailsV2Content,
): Content => {
  const content = contentData?.all_account_details_v2?.items?.[0] ?? undefined;
  const tabsContent = contentData?.all_account_details_tabs?.items?.[0];

  return {
    accountDetailsHeader: getAccountDetailsHeaderContent(content),
    accountNumberFormat: content?.account_number_formatConnection?.edges?.[0]?.node?.pattern || '${accountNumber}',
    editPortfolioFeedbackMessage: getLabel(content, 'edit_portfolio_feedback_message'),
    clientLabel: getLabel(content, 'client_label'),
    advisorLabel: getLabel(content, 'advisor_label'),
    textFields: content?.labels ?? [],
    tabHeader: tabs.reduce<Content['tabHeader']>((acc, tab) => {
      const tabItemContent = tabsContent?.tabs?.find(item => tab.toString() === item?.key);
      acc[tab] = tabItemContent?.label ?? tabItemContent?.key ?? '';

      return acc;
    }, {}),
  };
};
