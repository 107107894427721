import {
  ValidateCashTransfer,
  ValidateCashTransfer_validateCashTransfer,
  ValidateCashTransferVariables,
} from './__generated__/mutation.v2';
import * as mutation from './mutation.gql';

import {
  GetBankAccountsForTransfer,
  GetBankAccountsForTransferVariables,
} from '~/hooks/financial-account/symphony/__generated__/query.v2';
import * as queries from '~/hooks/financial-account/symphony/query.gql';
import { MutationHookOptions, MutationTuple, QueryHookOptions } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ValidateCashTransferResponse = ValidateCashTransfer_validateCashTransfer | null | undefined;

export const useGetAccountBalances = (
  options?: QueryHookOptions<GetBankAccountsForTransfer, GetBankAccountsForTransferVariables>,
) => {
  return useSymphonyQuery(queries.GetBankAccountsForTransfer, options);
};

export const useLazyGetAccountBalances = (
  options?: QueryHookOptions<GetBankAccountsForTransfer, GetBankAccountsForTransferVariables>,
) => {
  return useLazySymphonyQuery(queries.GetBankAccountsForTransfer, options);
};

export const useValidateCashTransfer = (
  options?: MutationHookOptions<ValidateCashTransfer, ValidateCashTransferVariables>,
): MutationTuple<ValidateCashTransfer, ValidateCashTransferVariables> => {
  return useSymphonyMutation(mutation.ValidateCashTransfer, options);
};
