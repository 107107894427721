import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

import { useCashTransfersV2 } from '../../common/CashTransfer/symphony';
import {
  CashTransfersV2,
  CashTransfersV2_multiCashTransfers_transfers_attributes_IntegerAttribute,
  CashTransfersV2Variables,
} from '../../common/CashTransfer/symphony/__generated__/query.v2';
import { ManagedProductStatusesForCashTransfersV2 } from '../../common/CashTransfer/utils';
import { useGetOpsDashboardContentV2 } from '../../contentstack';
import { GetOpsDashboardContentV2 } from '../../contentstack/__generated__/query.v2';
import { statusFilterToScheduledTransferStatus, toCashTransferStatus } from '../../mappers';
import { SearchContextLiterals, TOO_MANY_SEARCH_RECORDS_ERROR, TransferItem } from '../../types';
import {
  getCurrentPage,
  getLastEditDateAndTime,
  getSearchContext,
  getSortedPartnerOpsTradingSuspensions,
  getTotalPages,
  getTransferTypes,
} from '../../utils';
import { getFilterCountMap } from '../content';
import { useGetDepositsTableContentV2 } from '../contentstack';
import { GetDepositsTableContentV2 } from '../contentstack/__generated__/query.v2';

import { Field, FinancialAccountType, ManagedProductStatus, OrderType, TransferType } from '~/__generated__';
import { FilterCountMap } from '~/components/Filters/types';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { getPaginationContext } from '~/utils/table';
import { AsyncResult, PaginationContext, SearchContext } from '~/utils/types';

export enum RecurrenceOptions {
  RECURRING,
  ONE_TIME,
}

const initialRecordsPerPage = 10;

export interface DepositsTabVariables {
  contentOptions: ContentOptions;
  field: Field;
  hasOpenFlags: boolean | null;
  order: OrderType;
  page: number;
  recordsPerPage: number;
  recurrenceFilter: string[];
  searchFilter: string | null;
  statusFilter: string[];
  updateRecordsPerPage: (value: number) => void;
}

export interface DepositsTabData {
  content?: GetDepositsTableContentV2;
  currentPage: number;
  depositItems: TransferItem[];
  filterCountMap: FilterCountMap[];
  paginationContext: PaginationContext;
  refetchCashDepositsData: () => void;
  searchContext: SearchContext;
  searchError: boolean;
  totalPages: number;
}

export const useDepositsTabData = ({
  page,
  recurrenceFilter,
  statusFilter,
  searchFilter,
  contentOptions,
  field,
  order,
  hasOpenFlags,
  updateRecordsPerPage,
  recordsPerPage,
}: DepositsTabVariables): AsyncResult<DepositsTabData> => {
  const [state, setState] = useState<AsyncResult<DepositsTabData>>({ loading: true });
  const {
    data: contentstackData,
    loading: contentstackLoading,
    error: contentstackError,
  } = useGetDepositsTableContentV2({
    variables: contentOptions,
  });

  const variables: CashTransfersV2Variables = {
    fetchLatestNote: true,
    field,
    hasOpenFlags,
    limit: recordsPerPage || initialRecordsPerPage,
    managedProductStatuses: ManagedProductStatusesForCashTransfersV2,
    offset: (page - 1) * (recordsPerPage || initialRecordsPerPage),
    order,
    searchFilter,
    statuses: statusFilterToScheduledTransferStatus(statusFilter),
    transferTypes: getTransferTypes(recurrenceFilter, TransferType.DEPOSIT),
  };

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContentV2({
    variables: contentOptions,
  });

  // The reason for no-cache is due to the updating the list when ever we create or resolve flag  for a deposit, the flagged and unflagged deposits table should be updated.
  // Otherwise, instead of the table showing the current flagged and unflagged deposits data it shows the previous cached data. There is no easy way to update the cache to reflect the correct state right now.
  const {
    data: cashDepositsData,
    loading: cashDepositsLoading,
    error: cashDepositsError,
    refetch: refetchCashDepositsData,
  } = useCashTransfersV2({
    variables,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    skip: contentstackLoading && !contentstackData,
  });

  useEffect(() => {
    const error = cashDepositsError || contentstackError || opsDashboardContentError;
    if (error && cashDepositsError?.message !== TOO_MANY_SEARCH_RECORDS_ERROR) {
      setState({
        error,
        loading: false,
      });
    }
  }, [cashDepositsError, contentstackError, opsDashboardContentError]);

  useEffect(() => {
    const depositItems: TransferItem[] = getDepositsItems(contentOptions, cashDepositsData, opsDashboardContentData);
    const pageContext = cashDepositsData?.multiCashTransfers.paginationContext;
    const limit = pageContext?.limit || null;
    const offset = pageContext?.offset || null;
    const total = pageContext?.total || 0;
    const currentPage = getCurrentPage({ limit, offset });
    const totalPages = getTotalPages({ limit, total });
    const fields = opsDashboardContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const filterContext = cashDepositsData?.multiCashTransfers.filterContext;
    const paginationContext = getPaginationContext(
      fields,
      currentPage,
      recordsPerPage || initialRecordsPerPage,
      total,
      updateRecordsPerPage,
    );
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_DEPOSITS);

    setState({
      data: {
        depositItems,
        currentPage,
        totalPages,
        content: contentstackData,
        paginationContext,
        refetchCashDepositsData,
        filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
        searchContext,
        searchError: cashDepositsError?.message === TOO_MANY_SEARCH_RECORDS_ERROR,
      },
      loading: cashDepositsLoading || contentstackLoading || opsDashboardContentLoading,
    });
  }, [
    cashDepositsData,
    cashDepositsLoading,
    contentstackLoading,
    contentstackData,
    opsDashboardContentLoading,
    opsDashboardContentData,
    contentOptions,
    refetchCashDepositsData,
    cashDepositsError?.message,
  ]);

  return state;
};

const getDepositsItems = (
  contentOptions: ContentOptions,
  data?: CashTransfersV2,
  opsDashboardContent?: GetOpsDashboardContentV2,
): TransferItem[] => {
  if (!data) {
    return [];
  }
  const programeNames = opsDashboardContent?.all_product_name?.items || [];

  return data.multiCashTransfers.transfers.map(item => {
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(item.managedProduct?.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = item.managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = item.managedProduct?.clientParty?.id || '';
    // only used for activated account states
    const accountState = getAccountState({
      firstRebalancedOn: item.managedProduct?.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;
    return {
      id: item.id,
      accountNumber: item.managedProduct?.financialAccountNumber || '',
      accountState,
      accountTypeText: getAccountTypeText(
        item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
        opsDashboardContent?.all_account_type?.items || [],
      ),
      amount: parseFloat(item.total.value),
      clientName: `${item.managedProduct?.clientParty?.partyPerson?.givenName ?? ''} ${
        item.managedProduct?.clientParty?.partyPerson?.familyName ?? ''
      }`,
      createdAt: item.createdOn,
      destinationBankAccount: item.bankAccount?.accountNumber || '',
      flags: item.flags,
      frequency: item.frequency,
      managedProductId: item.managedProduct?.id || '',
      notes: item.notes || undefined,
      partyId,
      productName: getAccountProgramText(item.managedProduct?.program, item.managedProduct?.attributes, programeNames),
      rmdPlanId: (item.attributes.find(attribute => attribute.name === 'RMD_PLAN_ID') as
        | CashTransfersV2_multiCashTransfers_transfers_attributes_IntegerAttribute
        | undefined)?.integerValue,
      scheduledDate: item.scheduledAt || undefined,
      settlementDate: item.settlementDate || undefined,
      numberOfOccurrences: item.numberOfOccurrences || undefined,
      status: toCashTransferStatus(item.status),
      lastCommentData: item.entityNotes[0]
        ? {
            ...getLastEditDateAndTime(parseISO(item.entityNotes[0].created), contentOptions),
            lastValue: item.entityNotes[0].note,
          }
        : undefined,
      transferType: item.type,
      lastCommentPartyId: item.entityNotes[0]?.createdByPartyId ?? undefined,
    };
  });
};
