import React from 'react';

import { RteContent } from '~/components/ui/redactor/RteContent';
import { PaginationContext } from '~/utils/types';

interface Props {
  paginationContext: PaginationContext;
}

export const TotalItems: React.FC<Props> = ({ paginationContext }) => {
  const { currentPage, totalItems, recordsPerPage, totalItemsLabel } = paginationContext;
  const itemsTillNow = currentPage * recordsPerPage;
  const startValue = itemsTillNow - recordsPerPage + 1;
  const endValue = totalItems < itemsTillNow ? totalItems : itemsTillNow;
  return <RteContent config={{ startValue, endValue, totalItems }} data={totalItemsLabel} />;
};
