import { CLIENT_NAME } from '../constants';

import {
  makeUseLazyQueryWithClientName,
  makeUseQueryWithClientName,
  useLazyQuery,
  useQuery,
} from '~/utils/apollo-client';

export const useContentstackQuery: typeof useQuery = makeUseQueryWithClientName(CLIENT_NAME);
export const useLazyContentstackQuery: typeof useLazyQuery = makeUseLazyQueryWithClientName(CLIENT_NAME);
