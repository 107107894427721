import React from 'react';

import { Menu } from '~/components/ui/Menu';
import { useMenu } from '~/components/ui/Menu/hooks';
import { Button, ExpandMoreIcon, MenuItem, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { PaginationContext } from '~/utils/types';

interface Props {
  paginationContext: PaginationContext;
}
const recordsPerPageOptions = [10, 25, 50, 100];
const defaultStyles = {
  backgroundColor: 'white',
  color: 'text.primary',
  padding: 0,
  '&:hover': { backgroundColor: 'white', color: 'text.primary' },
};

export const ItemsPerPage: React.FC<Props> = ({ paginationContext }) => {
  const { recordsPerPage, recordsPerPageLabel } = paginationContext;
  const { buttonProps, menuProps } = useMenu(`records-per-page-actions`);
  return (
    <div>
      <Stack alignItems="center" flexDirection="row" justifyContent="flex-end">
        <Typography variant="subtitle2">{recordsPerPageLabel}</Typography>
        <Button
          {...buttonProps}
          aria-label="items-per-page"
          endIcon={<ExpandMoreIcon />}
          onClick={event => buttonProps.onClick(event)}
          sx={defaultStyles}
          variant="contained"
        >
          <Typography variant="subtitle2">{recordsPerPage}</Typography>
        </Button>
      </Stack>

      <Menu data-qa="records-numbers" {...menuProps}>
        {recordsPerPageOptions.map(option => (
          <MenuItem key={option} onClick={() => paginationContext.updateRecordsPerPage?.(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
