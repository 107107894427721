import { getDate, getDay, getYear, parseISO } from 'date-fns';
import React, { ReactNode } from 'react';

import { UseFundingContentData } from './hooks/useFundingContent';
import { AnnuityTransfer, AssetTransfer, CashTransfer } from './symphony';
import { PositionIdentifiers, SecuritySearchOption } from './types';

import {
  FinancialAccountType,
  FundingSourceType,
  OnboardingStates,
  QuantityType,
  TransferFrequency,
} from '~/__generated__';
import { HelperTextContent } from '~/components/ui/HelperTextContent';
import { MuiAlertColor } from '~/components/ui/mui';
import { Position } from '~/hooks/financial-account/symphony';
import { FundingSources } from '~/utils/config';

export const getAlert = (message: string | ReactNode, inputId: string, severity: MuiAlertColor) => {
  return (
    <HelperTextContent error id={`${inputId}-helper-text`} severity={severity}>
      {message}
    </HelperTextContent>
  );
};

// Recurring deposit section should be hidden for some account types
const accountTypeSupportsRecurring = (type: FinancialAccountType) =>
  ![FinancialAccountType.INHERITED_TRADITIONAL_IRA, FinancialAccountType.INHERITED_ROTH_IRA].includes(type);

export const isRecurringDepositVisible = (
  formAccountType: FinancialAccountType,
  source: FundingSources,
  displayBrokerageWithManualCustodianEntry?: boolean,
  isFundingSourceEnabled?: boolean,
) => {
  return (
    accountTypeSupportsRecurring(formAccountType) &&
    (source === FundingSources.bankAccount ||
      ((source === FundingSources.otherBrokerageAccount || source === FundingSources.otherCustomBrokerageAccount) &&
        !displayBrokerageWithManualCustodianEntry) ||
      !isFundingSourceEnabled)
  );
};

/**
 * Given an array on Positions, construct the PositionsInput object required for the createAssetTransfer mutation positions attribute.
 * @param positions List of Positions
 * @returns The PositionsInput for the createAssetTransfer mutation
 */
export const getPositionsInput = (positions: Position[]) =>
  positions.map(p => ({
    identifiers: [
      { name: PositionIdentifiers.CUSIP, value: p.security?.cusip ?? '' },
      {
        name: PositionIdentifiers.ID,
        value: p.identifiers.find(i => i.name === PositionIdentifiers.ID)?.value ?? '',
      },
      { name: PositionIdentifiers.NAME, value: p.security?.name ?? '' },
      {
        name: PositionIdentifiers.TICKER,
        value: p.identifiers.find(i => i.name === PositionIdentifiers.TICKER)?.value ?? '',
      },
      {
        name: PositionIdentifiers.TYPE,
        value: p.identifiers.find(i => i.name === PositionIdentifiers.TYPE)?.value ?? '',
      },
    ],
    quantities: [{ type: p.quantities[0].type, units: p.quantities[0].units }],
  }));

export const getPositionFromSecuritySearchResult = (
  searchResult: SecuritySearchOption | null,
  numberOfUnits?: number,
): Position => ({
  __typename: 'Position',
  identifiers: [
    {
      __typename: 'StringAttribute',
      name: PositionIdentifiers.NAME,
      value: searchResult?.name ?? '',
    },
    {
      __typename: 'StringAttribute',
      name: PositionIdentifiers.TYPE,
      value: searchResult?.type ?? '',
    },
    {
      __typename: 'StringAttribute',
      name: PositionIdentifiers.ID,
      value: searchResult?.id ?? '',
    },
    {
      __typename: 'StringAttribute',
      name: PositionIdentifiers.TICKER,
      value: searchResult?.ticker ?? '',
    },
  ],
  quantities: [
    {
      __typename: 'Quantity',
      marketValue: null,
      percentOfTotal: null,
      type: QuantityType.UNKNOWN_QUANTITY,
      units: numberOfUnits?.toString() ?? '',
    },
  ],
  security: {
    __typename: 'Security',
    cusip: searchResult?.cusip ?? '',
    identifier: '',
    name: searchResult?.name ?? '',
  },
});

/**
 * Given the CashTransfer, get the day of the week if the transfer frequency is weekly or biweekly
 * or day of the month when the transfer is monthly or bimonthly.
 * @param transfer - The cash transfer.
 * @returns A number indicating the day of the week or date.
 */
export const getTransferFrequencyDay = (transfer?: CashTransfer): number | undefined => {
  if (transfer?.frequency && transfer.scheduledAt) {
    if (transfer.frequency === TransferFrequency.WEEKLY || transfer.frequency === TransferFrequency.BIWEEKLY) {
      return getDay(parseISO(transfer.scheduledAt));
    } else if (transfer.frequency === TransferFrequency.MONTHLY || transfer.frequency === TransferFrequency.BIMONTHLY) {
      return getDate(parseISO(transfer.scheduledAt));
    }
  }
  return undefined;
};

export const getPositionTotal = (position: Position) =>
  position.quantities.reduce(
    (accumulator, quantity) => parseFloat(quantity.marketValue?.value ?? '0') + accumulator,
    0,
  );

export const getPositionsAndCashTotal = (positions?: Position[], cashValue?: string) =>
  (positions ?? []).flatMap(getPositionTotal).reduce((acc, quantityTotal) => acc + quantityTotal, 0) +
  parseFloat(cashValue ?? '0');

/**
 * Determines what the funding source is based on the input given.
 * @param items - An object containing the attributes to determine the funding source.
 * @returns A funding source enum.
 *
 */
export const getCurrentFundingSource = (items: FundingSourceItems): FundingSources | undefined => {
  const fundingContent = items.fundingContentData?.content?.all_onboarding_fund_account_basic?.items?.[0];
  const sourceContent = fundingContent?.source_of_funds;

  switch (items.fundingSourceType) {
    case FundingSourceType.ANNUITY:
      return FundingSources.annuity;
    case FundingSourceType.CASH:
      return FundingSources.bankAccount;
    case FundingSourceType.CHECK:
      return FundingSources.check;
    case FundingSourceType.EXCHANGE:
      return FundingSources.exchange;
    case FundingSourceType.OTHER_BROKERAGE_ACCOUNT:
      return items.isCustomBrokerageFundingSource
        ? FundingSources.otherCustomBrokerageAccount
        : FundingSources.otherBrokerageAccount;
    case FundingSourceType.EMPLOYER_SPONSORED_PLAN:
      return FundingSources.employerSponsoredPlan;
    default:
      if (items.initialCashTransfer) {
        return FundingSources.bankAccount;
      }
      if (items.initialAssetTransfer) {
        return FundingSources.otherBrokerageAccount;
      }
      if (items.initialAnnuityTransfer) {
        return FundingSources.annuity;
      }
      if (
        items.accountType &&
        sourceContent?.fromConnection?.edges?.[0]?.node?.items?.find(
          item => item?.data_value === FundingSources.fundLater,
        )
      ) {
        return FundingSources.fundLater;
      }
      if (items.onboardingState === OnboardingStates.PLAYBACK) {
        return FundingSources.fundLater;
      }
      return;
  }
};
/**
 * For IRA accounts check maximum value .
 * @param accountType - Financial account type,
 * @param contributionYear - Selected contribution year for IRA account,
 * @param amount - Amount Input value when source is Banking,
 * @param age - User Age value
 * @returns boolean.
 *
 */
export const isFundingAmountValid = (
  accountType: FinancialAccountType,
  amount: number,
  amountValidationTypes: AmountValidationType[],
  age: number,
  contributionYear?: number,
): boolean => {
  const foundAccount = amountValidationTypes.find(account => account.accountType === accountType);

  return foundAccount && !!amount
    ? amount <=
        (!contributionYear || contributionYear === getYear(new Date())
          ? age >= foundAccount.ageMidpoint
            ? foundAccount.currentYear.valueGreaterThanAge
            : foundAccount.currentYear.valueLesserThanAge
          : age >= foundAccount.ageMidpoint
          ? foundAccount.previousYear.valueGreaterThanAge
          : foundAccount.previousYear.valueLesserThanAge)
    : true;
};

export interface FundingSourceItems {
  accountType?: FinancialAccountType | null;
  fundingContentData?: UseFundingContentData;
  fundingSourceType?: FundingSourceType | null;
  initialAnnuityTransfer?: AnnuityTransfer;
  initialAssetTransfer?: AssetTransfer;
  initialCashTransfer?: CashTransfer;
  isCustomBrokerageFundingSource?: boolean;
  onboardingState?: OnboardingStates;
}

export interface AmountValidationType {
  accountType: FinancialAccountType;
  ageMidpoint: number;
  currentYear: {
    valueGreaterThanAge: number;
    valueLesserThanAge: number;
  };
  previousYear: {
    valueGreaterThanAge: number;
    valueLesserThanAge: number;
  };
}
