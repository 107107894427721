export enum AllocationDetailsColumns {
  ALLOCATION_PERCENTAGE = 'ALLOCATION_PERCENTAGE',
  ASSET_CLASS_LABEL = 'ASSET_CLASS_LABEL',
  SECURITY_NAME = 'SECURITY_NAME',
}

export enum AssetAllocationTableColumns {
  ActualAllocation = 'ActualAllocation',
  Allocation = 'Allocation',
  Asset = 'Asset',
  AssetClass = 'AssetClass',
  TargetAllocation = 'TargetAllocation',
  Value = 'Value',
}

export enum BrokerageAccountLinkingMethods {
  ACCOUNT_STATEMENT = 'ACCOUNT_STATEMENT',
  EXISTING_ACCOUNT = 'EXISTING_ACCOUNT',
  MANUAL_LINKAGE = 'MANUAL_LINKAGE',
  PLAID = 'PLAID',
}

export enum FADashboardTabs {
  AccountActions = 'AccountActions',
  ActiveClientsTab = 'ActiveClients',
  ApplicationInProgressTab = 'ApplicationInProgress',
}

export enum FundingSources {
  annuity = 'annuity',
  bankAccount = 'bankAccount',
  check = 'check',
  employerSponsoredPlan = 'employerSponsoredPlan',
  exchange = 'exchange',
  fundLater = 'fundLater',
  journaling = 'journaling',
  otherBrokerageAccount = 'otherBrokerageAccount',
  otherCustomBrokerageAccount = 'otherCustomBrokerageAccount',
}
