import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { DropdownItem, DropdownWithEditableOptionsProps, EditableListItem } from './types';

import {
  ArrowDropDownRoundedIcon,
  ArrowDropUpRoundedIcon,
  Button,
  Checkbox,
  EditIcon,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  useTheme,
} from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

const ellipsisStyles = { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };

export const DropdownWithEditableOptions: React.FC<DropdownWithEditableOptionsProps> = ({
  dataQa = 'dropdown-with-edit',
  defaultValue,
  disabled,
  items,
  label,
  multiple,
  placeholderValue = '',
  onChange,
  ref,
  variant = 'outlinedClassic',
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedValue, setSelectedvalue] = useState<DropdownItem | undefined>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { palette } = useTheme();
  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleToggle = (value: DropdownItem) => {
    setSelectedvalue(value);
    onChange?.(value);
    if (!multiple) {
      handleClose();
    }
  };

  useEffect(() => {
    if (defaultValue && items.length) {
      const value = items.find(
        (item: EditableListItem): item is DropdownItem =>
          (item.type === 'list-item' || !item.type) && item.value === defaultValue,
      );
      if (defaultValue !== selectedValue?.value || value?.label !== selectedValue.label) {
        setSelectedvalue(value);
      }
    }
  }, [defaultValue, items]);

  const isClassicVariant = variant === 'outlinedClassic';

  return (
    <>
      {label && <Typography variant="caption">{label}</Typography>}
      <Button
        aria-haspopup="true"
        aria-labelledby="client-portfolio-label selectPortfolio"
        data-qa={dataQa}
        disabled={disabled}
        endIcon={open ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
        fullWidth
        id="selectPortfolio"
        onClick={handleClick}
        ref={ref}
        sx={[
          {
            justifyContent: selectedValue?.label || placeholderValue ? 'space-between' : 'flex-end',
            p: 0,
            color: !selectedValue ? 'text.secondary' : 'text.primary',
          },
          isClassicVariant && {
            p: 2,
            border: `1px solid ${palette.divider}`,
          },
        ]}
      >
        <Typography sx={ellipsisStyles} variant="body1">
          {selectedValue ? selectedValue.label : placeholderValue}
        </Typography>
      </Button>

      <Popover
        PaperProps={{
          sx: {
            width: anchorEl && anchorEl.offsetWidth,
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => handleClose()}
        open={open}
      >
        {items.map((item, index) => {
          if (item.type === 'list-item-header') {
            return (
              <ListSubheader aria-level={2} key={index} role="heading" sx={{ lineHeight: 1, mt: 2 }}>
                {item.label}
              </ListSubheader>
            );
          } else if (item.type === 'list-item-button') {
            return (
              <ListItem key={index}>
                <Button
                  disabled={item.disabled}
                  fullWidth
                  onClick={() => {
                    if (item.shouldClosePopUp) {
                      handleClose();
                    }
                    item.onClick ? item.onClick() : handleClose();
                  }}
                  size="small"
                  variant={item.variant}
                >
                  {item.label}
                </Button>
              </ListItem>
            );
          } else if (item.icon) {
            return (
              <ListItem
                key={item.value}
                sx={{
                  '&.MuiListItem-root': {
                    px: 0,
                    py: 0,
                  },
                }}
              >
                <ListItemButton
                  dense
                  disabled={item.disabled}
                  onClick={() => {
                    handleToggle(item);
                    item.onClick?.();
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={<Typography variant="body1">{item.label}</Typography>} />
                </ListItemButton>
              </ListItem>
            );
          } else {
            const labelId = `checkbox-list-label-${index}`;
            return (
              <ListItem
                key={labelId}
                secondaryAction={
                  item.editDetailsOnClick ? (
                    <IconButton
                      aria-label={`edit ${item.label}`}
                      disabled={item.disabled}
                      edge="end"
                      onClick={() => {
                        item.editDetailsOnClick?.(item);
                        handleClose();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : undefined
                }
                sx={{
                  '&.MuiListItem-root': {
                    px: 0,
                    py: 0,
                  },
                  backgroundColor: item.value === selectedValue?.value ? 'primary.selected' : undefined,
                }}
              >
                <ListItemButton
                  aria-checked={item.value === selectedValue?.value}
                  dense
                  disabled={item.disabled}
                  onClick={() => handleToggle(item)}
                  role="checkbox"
                >
                  {multiple && (
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedValue?.value === item.value}
                        disableRipple
                        disabled={item.disabled}
                        edge="start"
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    id={labelId}
                    primary={
                      <Typography sx={ellipsisStyles} variant="body1">
                        {item.label}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          }
        })}
      </Popover>
    </>
  );
};
