import uniqueId from 'lodash.uniqueid';
import React, { FC } from 'react';
import NumberFormat from 'react-number-format';

import { InputLabel, MuiOutlinedTextFieldProps } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { TextField } from '~/components/ui/TextField';
import { ContentOptions } from '~/utils/contentstack';
import { getCurrencyInputProps } from '~/utils/format';

/**
 * These are props that have conflicting types between NumberFormat and TextField components.
 * Collisions are omitted from TextField  and picked from NumberFormat, essentially choosing the NumberFormat
 * type over the TextField type.
 */
type Collisions = 'allowNegative' | 'onValueChange' | 'value' | 'defaultValue' | 'type' | 'isAllowed' | 'decimalScale';

export type Props = {
  contentOptions: ContentOptions;
  currencySymbol?: string;
  dataQa?: string;
  decimalScale?: number;
  isNumericString?: boolean;
} & Omit<MuiOutlinedTextFieldProps, 'variant' | Collisions> &
  Pick<React.ComponentProps<typeof NumberFormat>, Collisions>;

// TODO: Replace CurrencyTextField with TextField that uses Intl.NumberFormat
export const CurrencyTextField: FC<Props> = ({
  allowNegative = false,
  currencySymbol = '$',
  contentOptions,
  dataQa = 'currency-text-field',
  decimalScale = 2,
  id,
  isNumericString = false,
  InputLabelProps,
  inputProps,
  label,
  placeholder,
  sx,
  ref,
  size,
  error,
  ...rest
}) => {
  if (!id) {
    id = uniqueId('currencyField' + '-id');
  }
  return (
    <>
      {label && (
        <InputLabel
          data-qa={`${dataQa}-label`}
          error={error}
          htmlFor={id}
          {...InputLabelProps}
          sx={{ position: 'initial', ...InputLabelProps?.sx }}
        >
          <RteContent data={label as string} sx={{ whiteSpace: 'normal' }} />
        </InputLabel>
      )}
      <NumberFormat
        allowNegative={allowNegative}
        customInput={TextField}
        data-qa={`${dataQa}-number-format`}
        decimalScale={decimalScale}
        error={error}
        inputProps={{ ...inputProps, placeholder, id }}
        inputRef={ref}
        isNumericString={isNumericString}
        /**
         * There's a collision here between the size props that NumberFormat and TextField expect.
         * For other collisions we use the type that NumberFormat expects, but here we want to pass
         * size ('small' | 'medium') to the underlying material-ui TextField component
         */
        size={size as any}
        sx={{ color: 'text.primary', ...sx }}
        {...getCurrencyInputProps(contentOptions.locale, currencySymbol)}
        {...rest}
      />
    </>
  );
};
