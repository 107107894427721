import { parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { CommentData } from '../../common/Comment';
import { useGetOpsDashboardContentV2 } from '../../contentstack';
import { GetOpsDashboardContentV2 } from '../../contentstack/__generated__/query.v2';
import { SearchContextLiterals, TOO_MANY_SEARCH_RECORDS_ERROR } from '../../types';
import {
  getCurrentPage,
  getLastEditDateAndTime,
  getSearchContext,
  getSortedPartnerOpsTradingSuspensions,
  getTotalPages,
} from '../../utils';
import { getFilterCountMap } from '../content';
import { useGetClosureTableContentV2 } from '../contentstack';
import { GetClosureTableContentV2 } from '../contentstack/__generated__/query.v2';
import { ClosureFlags, useAccountClosuresV2 } from '../symphony';
import {
  AccountClosuresV2,
  AccountClosuresV2_closeWorkflows_closeWorkflows,
  AccountClosuresV2_closeWorkflows_closeWorkflows_managedProduct_relatedParties,
  AccountClosuresV2Variables,
} from '../symphony/__generated__/query.v2';

import { toClosureStatus } from './mappers';

import {
  CloseAccountStatus,
  CloseWorkflowField,
  FinancialAccountType,
  ManagedProductStatus,
  OrderType,
} from '~/__generated__';
import { FilterCountMap } from '~/components/Filters/types';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { ClosureStatus, ClosureType } from '~/containers/OpsDashboardV2/ClosuresTab/types';
import { AccountState, getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { getPaginationContext } from '~/utils/table';
import { AsyncResult, PaginationContext, SearchContext } from '~/utils/types';
// Set 10 as constant first until we have requirements to change it
const initialRecordsPerPage = 10;

export type ClosuresTabVariables = {
  contentOptions: ContentOptions;
  field?: CloseWorkflowField;
  hasOpenFlags: boolean | null;
  order?: OrderType;
  page: number;
  recordsPerPage: number;
  searchFilter: string | null;
  statuses: CloseAccountStatus[];
  updateRecordsPerPage: (value: number) => void;
};

export type ClosureItem = {
  accountNumber: string;
  accountState: AccountState;
  accountTypeText: string;
  clientName: string;
  closureType: ClosureType;
  createdAt: string;
  destinationBankAccount?: string;
  flags: ClosureFlags[];
  id: string;
  lastCommentData?: Omit<CommentData, 'lastEditUserName'>;
  lastCommentPartyId?: string;
  managedProductId: string;
  partyId: string;
  productName: string;
  status: ClosureStatus;
};

export type ClosuresTabData = {
  closureItems: ClosureItem[];
  content?: GetClosureTableContentV2;
  currentPage: number;
  filterCountMap: FilterCountMap[];
  paginationContext: PaginationContext;
  refetchAccountClosuresV2Data: () => void;
  searchContext: SearchContext;
  searchError: boolean;
  totalPages: number;
};

export const useClosuresTabData = ({
  field,
  order,
  page,
  statuses,
  searchFilter,
  hasOpenFlags,
  contentOptions,
  recordsPerPage,
  updateRecordsPerPage,
}: ClosuresTabVariables): AsyncResult<ClosuresTabData> => {
  const [state, setState] = useState<AsyncResult<ClosuresTabData>>({ loading: true });
  const variables: AccountClosuresV2Variables = useMemo(
    () => ({
      pagination: {
        limit: recordsPerPage || initialRecordsPerPage,
        offset: (page - 1) * (recordsPerPage || initialRecordsPerPage),
      },
      statuses,
      searchFilter,
      hasOpenFlags,
      fetchLatestNote: true,
      ...(field && order && { sort: { field, order } }),
    }),
    [recordsPerPage, page, statuses, searchFilter, hasOpenFlags, field, order],
  );

  // The reason for no-cache is due to the updating the list when ever we create or resolve flag for a closure, the flagged and unflagged closures table should be updated.
  // Otherwise, instead of the table showing the current flagged and unflagged closures data it shows the previous cached data. There is no easy way to update the cache to reflect the correct state right now.
  const {
    data: AccountClosuresV2Data,
    loading: AccountClosuresV2Loading,
    error: AccountClosuresV2Error,
    refetch: refetchAccountClosuresV2Data,
  } = useAccountClosuresV2({ variables, errorPolicy: 'all', fetchPolicy: 'no-cache' });

  const {
    data: contentstackData,
    loading: contentstackLoading,
    error: contentstackError,
  } = useGetClosureTableContentV2({
    variables: contentOptions,
  });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContentV2({
    variables: contentOptions,
  });

  useEffect(() => {
    if (AccountClosuresV2Error || contentstackError || opsDashboardContentError) {
      setState({
        error: AccountClosuresV2Error || contentstackError || opsDashboardContentError,
        loading: false,
      });
    }
  }, [AccountClosuresV2Error, contentstackError, opsDashboardContentError]);

  useEffect(() => {
    const closureItems: ClosureItem[] = getClosureItems(contentOptions, AccountClosuresV2Data, opsDashboardContentData);
    const pageContext = AccountClosuresV2Data?.closeWorkflows.paginationContext;
    const limit = pageContext?.limit || null;
    const offset = pageContext?.offset || null;
    const total = pageContext?.total || 0;
    const currentPage = getCurrentPage({ limit, offset });
    const totalPages = getTotalPages({ limit, total });
    const fields = opsDashboardContentData?.all_ops_dashboard?.items?.[0]?.fields;
    const filterContext = AccountClosuresV2Data?.closeWorkflows.filterContext;
    const paginationContext = getPaginationContext(
      fields,
      currentPage,
      recordsPerPage || initialRecordsPerPage,
      total,
      updateRecordsPerPage,
    );
    const searchContext = getSearchContext(fields, SearchContextLiterals.SEARCH_CLOSURES);

    setState({
      data: {
        closureItems,
        currentPage,
        totalPages,
        refetchAccountClosuresV2Data,
        paginationContext,
        content: contentstackData,
        searchContext,
        searchError: AccountClosuresV2Error?.message === TOO_MANY_SEARCH_RECORDS_ERROR,
        filterCountMap: filterContext ? getFilterCountMap(filterContext) : [],
      },
      loading: AccountClosuresV2Loading || contentstackLoading || opsDashboardContentLoading,
    });
  }, [
    AccountClosuresV2Data,
    AccountClosuresV2Loading,
    contentstackLoading,
    contentstackData,
    opsDashboardContentLoading,
    opsDashboardContentData,
    contentOptions,
    refetchAccountClosuresV2Data,
    AccountClosuresV2Error?.message,
    recordsPerPage,
    updateRecordsPerPage,
  ]);

  return state;
};

const getInitialParty = (
  relatedParties: (AccountClosuresV2_closeWorkflows_closeWorkflows_managedProduct_relatedParties | null)[],
) => {
  return relatedParties.find(party => party?.isInitialParty === true);
};

const getAccountNumber = (item: AccountClosuresV2_closeWorkflows_closeWorkflows) => {
  if (item.status === CloseAccountStatus.COMPLETED) {
    return item.managedProduct?.closedAccountNumber || '';
  }
  return item.managedProduct?.financialAccountNumber || '';
};

const getClosureItems = (
  contentOptions: ContentOptions,
  data?: AccountClosuresV2,
  opsDashboardContentData?: GetOpsDashboardContentV2,
): ClosureItem[] => {
  if (!data) {
    return [];
  }
  const programNames = opsDashboardContentData?.all_product_name?.items || [];
  return data.closeWorkflows.closeWorkflows.map(item => {
    const initialParty = getInitialParty(item.managedProduct?.relatedParties ?? []);
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(item.managedProduct?.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = item.managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = initialParty?.party?.id || '';
    // only used for activated account states
    const accountState = getAccountState({
      firstRebalancedOn: item.managedProduct?.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;
    return {
      accountNumber: getAccountNumber(item),
      accountState,
      accountTypeText: getAccountTypeText(
        item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
        opsDashboardContentData?.all_account_type?.items || [],
      ),
      clientName: `${initialParty?.party?.partyPerson?.givenName ?? ''} ${
        initialParty?.party?.partyPerson?.familyName ?? ''
      }`,
      closureType: item.sellAssets ? ClosureType.LIQUIDATION : ClosureType.END_MANAGEMENT,
      createdAt: item.createdAt,
      flags: item.flags,
      destinationBankAccount: item.transferToBankAccount || undefined,
      managedProductId: item.managedProductId || '',
      partyId,
      productName: getAccountProgramText(item.managedProduct?.program, item.managedProduct?.attributes, programNames),
      status: toClosureStatus(item.status),
      lastCommentData: item.entityNotes[0]
        ? {
            ...getLastEditDateAndTime(parseISO(item.entityNotes[0].created), contentOptions),
            lastValue: item.entityNotes[0].note,
          }
        : undefined,
      lastCommentPartyId: item.entityNotes[0]?.createdByPartyId ?? undefined,
      id: item.id ?? '',
    };
  });
};
