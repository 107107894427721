import { useEffect, useMemo, useState } from 'react';

import { useGetOpsDashboardContentV2 } from '../../contentstack';
import { useGetPendingModelChangesContent } from '../contentstack';
import { useGetPendingModelChangeTabData } from '../symphony';
import { getPendingModelChangesContent, getPendingModelChangesData, PendingModelChangeRow } from '../utils';

import { ManagedProductStatus, PlanUpdateWorkflowStatus } from '~/__generated__';
import { DiscardPendingChangeContent } from '~/components/modals/DiscardPendingChange';
import { Content as ModelChangeDetailsModal } from '~/components/modals/ModelChangeDetails';
import { TableColumn } from '~/components/ui/BasicTable';
import { useGetAssetClassContent } from '~/hooks/asset-classes/contentstack';
import { ProductName } from '~/utils/account/types';
import { AssetClassContent } from '~/utils/asset-allocation';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export interface PendingModelChangesTabColumns {
  column_id: string | null;
  column_order: number | null;
  column_value: string | null;
}

export interface PendingModelChangesTabContentData {
  columns: (PendingModelChangesTabColumns | null)[] | null;
}

export interface PendingModelChangesSymphonyData {
  allPendingModels: PendingModelChangeRow[];
  pagination: {
    limit: number;
    offset: number;
    total: number;
  };
}

export interface RowModalLabel {
  key: string;
  label: string;
}
export interface AccountTypeContent {
  key: string | null;
  text: string | null;
}

export interface PendingModelChangesTabContent {
  discardModalContent?: DiscardPendingChangeContent;
  modelChangeDetailsModalContent?: ModelChangeDetailsModal;
  rowModalsLabels?: RowModalLabel[];
  tableColumns?: TableColumn[] | null;
}
export interface PendingModelChangesTabData {
  accountTypeContent?: (AccountTypeContent | null)[];
  assetClassContent?: AssetClassContent;
  content?: PendingModelChangesTabContent;
  data?: PendingModelChangesSymphonyData;
  productNameContent?: (ProductName | null)[];
  refetch?: () => Promise<any>;
}

export interface PendingModelChangesTabVariables {
  contentOptions: ContentOptions;
  pageNumber: number;
}

export const recordsPerPage = 10;

export const useGetPendingModelChangesTab = ({
  contentOptions,
  pageNumber,
}: PendingModelChangesTabVariables): AsyncResult<PendingModelChangesTabData> => {
  const [state, setState] = useState<AsyncResult<PendingModelChangesTabData>>({ loading: true });

  const { loading: contentLoading, error: contentError, data: contentData } = useGetPendingModelChangesContent({
    variables: contentOptions,
  });

  const { data: assetClassContentData, loading: assetClassContentLoading } = useGetAssetClassContent({
    variables: contentOptions,
  });

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContentV2({
    variables: contentOptions,
  });

  const variables = useMemo(() => {
    return {
      managedProductStatuses: [ManagedProductStatus.ACTIVE],
      statuses: [PlanUpdateWorkflowStatus.DOCS_SIGNED],
      pagination: {
        offset: (pageNumber - 1) * recordsPerPage,
        limit: recordsPerPage,
      },
    };
  }, [pageNumber]);

  /**
   * The reason for no-cache is due to the updating the list when ever we create or resolve flag for a pending modal,
   * the flagged and unflagged pending modal table should be updated.
   * Otherwise, instead of the table showing the current flagged and unflagged pending modal data it shows the previous cached data.
   * There is no easy way to update the cache to reflect the correct state right now.
   */
  const {
    loading: pendingModelsDataLoading,
    error: pendingModelsDataError,
    data: pendingModelsData,
    refetch,
  } = useGetPendingModelChangeTabData({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables,
  });

  useEffect(() => {
    if (contentError || pendingModelsDataError || opsDashboardContentError) {
      setState({ error: contentError || pendingModelsDataError || opsDashboardContentError, loading: false });
    }
  }, [contentError, pendingModelsDataError, opsDashboardContentError]);

  useEffect(() => {
    if (
      !contentLoading &&
      contentData &&
      !pendingModelsDataLoading &&
      pendingModelsData &&
      assetClassContentData &&
      !assetClassContentLoading &&
      !opsDashboardContentLoading &&
      opsDashboardContentData
    ) {
      const accountTypeContent = opsDashboardContentData?.all_account_type?.items || [];
      const productNameContent = opsDashboardContentData?.all_product_name?.items || [];
      const assetClassContent = assetClassContentData.all_asset_class_data?.items || [];
      setState({
        loading: false,
        data: {
          content: getPendingModelChangesContent(contentData),
          data: getPendingModelChangesData(pendingModelsData),
          assetClassContent,
          productNameContent,
          accountTypeContent,
          refetch,
        },
      });
    }
  }, [
    contentLoading,
    contentData,
    pendingModelsDataLoading,
    pendingModelsData,
    assetClassContentLoading,
    assetClassContentData,
    opsDashboardContentData,
    opsDashboardContentLoading,
  ]);

  return state;
};
