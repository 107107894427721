import {
  GetBillingHistoryContent,
  GetBillingHistoryContent_all_account_details_items_sections_AccountDetailsSectionsBillingHistory,
  GetBillingHistoryContent_all_account_details_items_sections_AccountDetailsSectionsBillingHistory_billing_history,
  GetBillingHistoryContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type BillingHistorySection = GetBillingHistoryContent_all_account_details_items_sections_AccountDetailsSectionsBillingHistory;
export type BillingHistoryType = GetBillingHistoryContent_all_account_details_items_sections_AccountDetailsSectionsBillingHistory_billing_history | null;

export const useGetBillingHistoryContent = (
  options?: QueryHookOptions<GetBillingHistoryContent, GetBillingHistoryContentVariables>,
): QueryResult<GetBillingHistoryContent, GetBillingHistoryContentVariables> => {
  return useContentstackQuery(queries.GetBillingHistoryContent, options);
};
