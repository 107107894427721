import { QueryResult } from '@apollo/client';

import * as queries from './query.gql';

import {
  GetClientCodeContent,
  GetClientCodeContentVariables,
} from '~/components/modals/ClientCode/contentstack/__generated__/query.v2';
import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetClientCodeContent = (
  options?: QueryHookOptions<GetClientCodeContent, GetClientCodeContentVariables>,
): QueryResult<GetClientCodeContent, GetClientCodeContentVariables> => {
  return useContentstackQuery(queries.GetClientCodeContent, options);
};
