import { range } from 'fp-ts/lib/Array';

import { Month } from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Locale } from '~/utils/contentstack';
import { enumLocaleToLocaleMap } from '~/utils/format/date';

// TODO: Add tests for this file once dependency issues have been resolved. Currently adding index.test.ts will never pass due to an error

export const numberToMonth: Record<number, Month> = {
  0: Month.JANUARY,
  1: Month.FEBRUARY,
  2: Month.MARCH,
  3: Month.APRIL,
  4: Month.MAY,
  5: Month.JUNE,
  6: Month.JULY,
  7: Month.AUGUST,
  8: Month.SEPTEMBER,
  9: Month.OCTOBER,
  10: Month.NOVEMBER,
  11: Month.DECEMBER,
};

export const getDaysOfWeekOptions = (locale: Locale = Locale.en_us): DropdownItem[] => {
  const dateLocale = enumLocaleToLocaleMap(locale);
  return range(1, 5).map(value => ({ value, label: dateLocale.localize?.day(value) ?? value.toString() }));
};

export const getDaysOfMonthOptions = (): DropdownItem[] =>
  range(1, 28).map(value => ({ value, label: value.toString() }));

/**
 * Creates DropdownItem[] for months of the year
 * @param locale locale for proper localized date display
 * @param months 0-11 key Object with corresponding month values
 * @returns DropdownItem[]
 */
export const getMonthOfYearOptions = (
  locale: Locale = Locale.en_us,
  months?: Record<number, Month>,
): DropdownItem[] => {
  const dateLocale = enumLocaleToLocaleMap(locale);

  return range(0, 11).map(monthIndex => ({
    value: months?.[monthIndex] ?? monthIndex,
    label: dateLocale.localize?.month(monthIndex) ?? monthIndex.toString(),
  }));
};

/**
 * Generates a list of completed months for a given year.
 *
 * @note: Months are 0-based index
 * @param {number} selectedYear - The selected year.
 * @param locale locale for proper localized date display
 * @param months 0-11 key Object with corresponding month values
 * @returns {DropdownItem<number>[]} - Dropdown options for completed months.
 */
export const getCompletedMonthOptionsForYear = (
  selectedYear: number,
  locale: Locale = Locale.en_us,
  months?: Record<number, Month>,
): DropdownItem[] => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // getMonth() returns zero-based index

  const monthOptions = getMonthOfYearOptions(locale, months);
  const completedMonths: DropdownItem[] = [];

  if (selectedYear === currentYear) {
    for (let month = 0; month < currentMonth; month++) {
      completedMonths.push(monthOptions[month]);
    }
  } else if (selectedYear < currentYear) {
    completedMonths.push(...monthOptions);
  }

  return completedMonths;
};
