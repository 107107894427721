import {
  GetFinancialAccountAssociationVerificationRequests,
  GetFinancialAccountAssociationVerificationRequests_financialAccountAssociationVerificationRequests_requests_financialAccount,
  GetFinancialAccountAssociationVerificationRequests_financialAccountAssociationVerificationRequests_requests_financialAccount_products,
  GetFinancialAccountAssociationVerificationRequests_financialAccountAssociationVerificationRequests_requests_financialAccount_products_ManagedProduct,
  GetFinancialAccountAssociationVerificationRequestsVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

type Product = GetFinancialAccountAssociationVerificationRequests_financialAccountAssociationVerificationRequests_requests_financialAccount_products;
type ManagedProduct = GetFinancialAccountAssociationVerificationRequests_financialAccountAssociationVerificationRequests_requests_financialAccount_products_ManagedProduct;
type FinancialAccount = GetFinancialAccountAssociationVerificationRequests_financialAccountAssociationVerificationRequests_requests_financialAccount;

export const isManagedProduct = (product: Product): product is ManagedProduct =>
  product.__typename === 'ManagedProduct';

export const findManagedProduct = (financialAccount: FinancialAccount | null): ManagedProduct | undefined =>
  financialAccount?.products?.find(isManagedProduct);

export const useGetBankVerificationRequests = (
  options?: QueryHookOptions<
    GetFinancialAccountAssociationVerificationRequests,
    GetFinancialAccountAssociationVerificationRequestsVariables
  >,
): QueryResult<
  GetFinancialAccountAssociationVerificationRequests,
  GetFinancialAccountAssociationVerificationRequestsVariables
> => {
  return useSymphonyQuery(queries.GetFinancialAccountAssociationVerificationRequests, options);
};
