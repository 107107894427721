import React, { useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

import { useCarousel } from '~/components/ui/Carousel/hooks';
import { Box, Grid, GridSpacing, ResponsiveStyleValue, useTheme } from '~/components/ui/mui';

export interface Props extends Pick<ReturnType<typeof useCarousel>, 'focused' | 'next' | 'previous' | 'setFocused'> {
  dataQa?: string;
  spacing?: ResponsiveStyleValue<GridSpacing>;
}

export const Carousel: React.FC<Props> = ({
  children,
  dataQa = 'carousel',
  focused,
  next,
  previous,
  setFocused,
  spacing = 2,
}) => {
  const childCount = React.Children.count(children);
  const childRefs = useRef<HTMLDivElement[]>([]);
  const swipeHandlers = useSwipeable({ onSwipedLeft: next, onSwipedRight: previous });

  const {
    transitions: {
      duration: { standard },
      easing: { easeInOut },
    },
  } = useTheme();

  useEffect(() => {
    childRefs.current = childRefs.current.slice(0, childCount);
  }, [childCount, children]);

  const offset = childRefs.current
    .filter((_, i) => i < focused)
    .reduce((acc, childRef) => acc + childRef.clientWidth, 0);

  return (
    <Box data-qa={dataQa} {...swipeHandlers} overflow="visible">
      <Grid
        container
        role="list"
        spacing={spacing}
        sx={{
          transform: `translateX(-${offset}px)`,
          transition: `transform ${standard}ms ${easeInOut}`,
        }}
        wrap="nowrap"
      >
        {React.Children.map(children, (child, i) => (
          <Grid
            data-qa={`${dataQa}-item-${i}`}
            item
            key={i}
            onKeyDown={() => setFocused(i)}
            ref={el => el && (childRefs.current[i] = el)}
            role="listitem"
            sx={{ maxWidth: '90%' }}
            xs="auto"
          >
            {child}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
