import React, { ComponentProps, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useChangeInvestmentMethodModalContent } from './hooks/useChangeInvestmentMethodModalContent';

import { TransferFrequency } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { Dropdown } from '~/components/ui/Dropdown';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, Grid, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { CurrencyTextField } from '~/components/ui/TextField/CurrencyTextField';
import { Typography } from '~/components/ui/Typography';
import { InvestmentMethods } from '~/containers/FundingV2/types';
import { ContentOptions } from '~/utils/contentstack';
import { format } from '~/utils/format/currency';

export type ChangeInvestmentMethodModalProps = ComponentProps<typeof Modal> & {
  availableBalance?: JSX.Element;
  contentOptions: ContentOptions;
  dataQa?: string;
  fieldNames: {
    frequencyFieldName: string;
    initialAmountFieldName: string;
    investmentMethodFieldName: string;
    noOfOccurrencesFieldName: string;
    totalAmountFieldName: string;
  };
  onClose: () => void;
  open: boolean;
};

export const ChangeInvestmentMethodModal: React.FC<ChangeInvestmentMethodModalProps> = ({
  availableBalance,
  contentOptions,
  dataQa = 'change-investment-method-modal',
  fieldNames,
  onClose,
  open,
  ...modalProps
}) => {
  const { control, clearErrors, errors, getValues, setError, setValue, watch } = useFormContext();
  const { data, error, loading } = useChangeInvestmentMethodModalContent({ contentOptions });

  const content = data?.content;

  const getModalFieldName = (fieldName: string) => {
    return `${fieldName}-modal`;
  };

  const initialAmountDefaultValue = getValues(fieldNames.initialAmountFieldName);
  const totalAmountDefaultValue = getValues(fieldNames.totalAmountFieldName);
  const durationDefaultValue = getValues(fieldNames.noOfOccurrencesFieldName);
  const frequencyDefaultValue = getValues(fieldNames.frequencyFieldName);
  const investmentMethodDefaultValue = getValues(fieldNames.investmentMethodFieldName) ?? InvestmentMethods.LUMP_SUM;

  const initialAmountModalFieldName = getModalFieldName(fieldNames.initialAmountFieldName);
  const totalAmountModalFieldName = getModalFieldName(fieldNames.totalAmountFieldName);

  const initialAmount = watch(initialAmountModalFieldName) ?? initialAmountDefaultValue;
  const totalAmount = watch(totalAmountModalFieldName) ?? totalAmountDefaultValue;
  const duration = watch(getModalFieldName(fieldNames.noOfOccurrencesFieldName)) ?? durationDefaultValue;
  const frequency = watch(getModalFieldName(fieldNames.frequencyFieldName)) ?? frequencyDefaultValue;
  const investmentMethod =
    watch(getModalFieldName(fieldNames.investmentMethodFieldName)) ?? investmentMethodDefaultValue;

  useEffect(() => {
    if (isDCA) {
      setValue(initialAmountModalFieldName, initialAmountDefaultValue ?? totalAmountDefaultValue, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [investmentMethod]);

  useEffect(() => {
    if (open && totalAmount !== totalAmountDefaultValue) {
      setValue(totalAmountModalFieldName, totalAmountDefaultValue);
      if (
        totalAmountDefaultValue &&
        initialAmount &&
        parseFloat(totalAmountDefaultValue) <= parseFloat(initialAmount)
      ) {
        // TODO: Revisit this and see if we can use `trigger` instead of `setError`
        setError(initialAmountModalFieldName, { type: 'max' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const isDCA = investmentMethod === InvestmentMethods.DCA;
  const isInputValid =
    (!isDCA && !!totalAmount) ||
    (isDCA && !!totalAmount && !!initialAmount && !!duration && !!frequency && !Object.values(errors).length);

  const closeModal = () => {
    onClose();
    clearErrors(initialAmountModalFieldName);
  };

  const handleOnClose = () => {
    setValue(getModalFieldName(fieldNames.totalAmountFieldName), totalAmountDefaultValue);
    setValue(getModalFieldName(fieldNames.initialAmountFieldName), initialAmountDefaultValue);
    setValue(getModalFieldName(fieldNames.noOfOccurrencesFieldName), durationDefaultValue);
    setValue(getModalFieldName(fieldNames.frequencyFieldName), frequencyDefaultValue);
    setValue(getModalFieldName(fieldNames.investmentMethodFieldName), investmentMethodDefaultValue);
    closeModal();
  };

  const handleOnSave = () => {
    setValue(fieldNames.totalAmountFieldName, totalAmount);
    setValue(fieldNames.investmentMethodFieldName, investmentMethod);
    setValue(fieldNames.initialAmountFieldName, isDCA ? initialAmount : undefined);
    setValue(fieldNames.frequencyFieldName, isDCA ? frequency : undefined);
    setValue(fieldNames.noOfOccurrencesFieldName, isDCA ? parseInt(duration, 10) : undefined);

    if (!isDCA) {
      setValue(getModalFieldName(fieldNames.initialAmountFieldName), undefined);
      setValue(getModalFieldName(fieldNames.frequencyFieldName), undefined);
      setValue(getModalFieldName(fieldNames.noOfOccurrencesFieldName), undefined);
    }

    closeModal();
  };

  return (
    <Modal
      {...modalProps}
      actions={
        <>
          <Button id="close-btn" onClick={() => handleOnClose()} variant="outlined">
            {content?.cancel}
          </Button>
          <Button disabled={!isInputValid} id="save-btn" onClick={() => handleOnSave()} variant="contained">
            {content?.save}
          </Button>
        </>
      }
      content={
        <Stack data-qa={`${dataQa}-content`} justifyContent="center">
          <Box height="64px" sx={{ mt: 2, mb: 4 }}>
            <img alt="" src={content?.imageUrl} />
          </Box>
          <Typography component="div" sx={{ mb: 2 }} variant="h3">
            {content?.heading}
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                defaultValue={totalAmountDefaultValue}
                name={totalAmountModalFieldName}
                render={({ name, value }) => (
                  <CurrencyTextField
                    InputLabelProps={{ sx: { mb: 0.5 } }}
                    contentOptions={contentOptions}
                    fullWidth
                    label={content?.labels.totalAmount}
                    name={name}
                    onValueChange={({ value: newValue }) => {
                      setValue(totalAmountModalFieldName, newValue, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                      // TODO: Revisit this and see if we can use `trigger` instead of `setError` and `clearErrors`
                      if (parseFloat(newValue) > parseFloat(initialAmount) && errors[initialAmountModalFieldName]) {
                        clearErrors(initialAmountModalFieldName);
                      } else if (
                        parseFloat(newValue) <= parseFloat(initialAmount) &&
                        !errors[initialAmountModalFieldName]
                      ) {
                        setError(initialAmountModalFieldName, { type: 'max' });
                      }
                    }}
                    value={parseFloat(value)}
                  />
                )}
              />
              {availableBalance}
            </Grid>
            <Grid item md={6} sx={{ mt: -0.25 }} xs={12}>
              <Controller
                control={control}
                defaultValue={investmentMethodDefaultValue}
                name={getModalFieldName(fieldNames.investmentMethodFieldName)}
                render={({ onChange, value }) => (
                  <Dropdown
                    items={content?.investmentMethods ?? []}
                    label={content?.labels.investmentMethod}
                    onChange={onChange}
                    value={value}
                    width="100%"
                  />
                )}
              />
            </Grid>
          </Grid>
          {isDCA && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item md={4} xs={12}>
                <Controller
                  control={control}
                  defaultValue={initialAmountDefaultValue}
                  name={initialAmountModalFieldName}
                  render={({ name, value }) => (
                    <CurrencyTextField
                      InputLabelProps={{ sx: { mb: 0.5 } }}
                      contentOptions={contentOptions}
                      error={!!errors[initialAmountModalFieldName]}
                      fullWidth
                      helperText={
                        errors[initialAmountModalFieldName] ? content?.errorMessages.maxInitialAmount : undefined
                      }
                      label={content?.labels.initialAmount}
                      name={name}
                      onValueChange={({ value: newValue }) =>
                        setValue(initialAmountModalFieldName, newValue, {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                      value={parseFloat(value)}
                    />
                  )}
                  rules={{ max: totalAmount - 0.01 }}
                />
              </Grid>
              <Grid item md={4} sx={{ mt: -0.25 }} xs={12}>
                <Controller
                  control={control}
                  defaultValue={frequencyDefaultValue}
                  name={getModalFieldName(fieldNames.frequencyFieldName)}
                  render={({ onChange, value }) => (
                    <Dropdown
                      items={content?.investmentFrequencies ?? []}
                      label={content?.labels.investmentFrequency}
                      onChange={onChange}
                      value={value}
                      width="100%"
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sx={{ mt: -0.25 }} xs={12}>
                <Controller
                  control={control}
                  defaultValue={durationDefaultValue}
                  name={getModalFieldName(fieldNames.noOfOccurrencesFieldName)}
                  render={({ onChange, value }) => (
                    <Dropdown
                      items={content?.investmentDurations[frequency] ?? []}
                      label={content?.labels.investmentDuration}
                      onChange={onChange}
                      value={value}
                      width="100%"
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          {isInputValid && (
            <Alert severity="info" sx={{ mt: 3 }}>
              <RteContent
                config={{
                  initialAmount: format(isDCA ? initialAmount : totalAmount, { trimDecimals: false }),
                  recurringAmount: format((totalAmount - initialAmount) / duration, { trimDecimals: false }),
                  duration,
                }}
                data={
                  isDCA
                    ? frequency === TransferFrequency.WEEKLY
                      ? content?.infoMessages.dca_weekly ?? ''
                      : content?.infoMessages.dca_monthly ?? '' // TODO: Add support for other frequencies as and when needed
                    : content?.infoMessages.lump_sum ?? ''
                }
              />
            </Alert>
          )}
        </Stack>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={error}
      loading={loading}
      onClose={handleOnClose}
      open={open}
      title={content?.title}
    />
  );
};
