import uniqueId from 'lodash.uniqueid';
import * as React from 'react';
import { useState } from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  MuiTypography,
  TextareaAutosize,
  TextareaAutosizeProps,
  useTheme,
} from '~/components/ui/mui';

export type TextareaChangeEvent = (newValue: string) => void;

export interface Props extends Omit<TextareaAutosizeProps, 'onChange'> {
  dataQa?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  maxLength?: number;
  onChange?: TextareaChangeEvent;
  required?: boolean;
  rows?: number;
  value?: string;
}

export const Textarea: React.FC<Props> = ({
  dataQa = 'mui-text-area',
  value,
  error,
  disabled,
  label,
  rows = 4,
  maxLength,
  required,
  onChange,
  ...rest
}) => {
  const {
    palette,
    sfTextarea: { styles: sfTextareaStyles },
  } = useTheme();
  const [copy, setCopy] = useState(value);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCopy(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };
  const count = copy?.length || 0;
  const characterLimitDataQa = dataQa + '-character-limit';
  const characterLimitId = uniqueId(characterLimitDataQa);
  const textareaId = uniqueId(dataQa + '-id');
  const labelId = uniqueId(dataQa + '-label');
  return (
    <Box
      sx={{
        'label + textarea': { marginTop: 1 },
        textarea: {
          backgroundColor: disabled ? palette.action.disabledBackground : null,
          border: sfTextareaStyles.border,
          borderColor: error ? palette.error.main : null,
          borderRadius: sfTextareaStyles.borderRadius,
          color: disabled ? palette.action.disabled : null,
          padding: sfTextareaStyles.padding,
          width: '100%',
          '&:focus': {
            outline: 'none',
            boxShadow: sfTextareaStyles.focusBoxShadow,
          },
        },
        '.MuiFormLabel-root': {
          position: 'relative',
          transform: 'none',
        },
      }}
    >
      <FormControl disabled={disabled} error={error} fullWidth required={required}>
        {!!label && (
          <InputLabel htmlFor={textareaId} id={labelId}>
            {label}
          </InputLabel>
        )}
        <TextareaAutosize
          aria-describedby={characterLimitId}
          aria-labelledby={label ? labelId : undefined}
          data-qa={dataQa}
          disabled={disabled}
          id={textareaId}
          maxLength={maxLength}
          minRows={rows}
          onChange={handleChange}
          required={required}
          value={copy}
          {...rest}
        />
        {!!maxLength && (
          <Box textAlign="right">
            <Box aria-hidden>{`${count} / ${maxLength}`}</Box>
            <MuiTypography
              aria-live="polite"
              data-qa={characterLimitDataQa}
              id={characterLimitId}
              sx={{ visibility: 'hidden' }}
            >{`${count} out of ${maxLength} characters`}</MuiTypography>
          </Box>
        )}
      </FormControl>
    </Box>
  );
};
