import React, { ComponentProps, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useUpdateCashTransferV2 } from '../common/CashTransfer/symphony';
import { CommentComponentColumn } from '../common/Comment';
import { OpsAlertAndLoading } from '../common/OpsAlertAndLoading';
import { cashTransferStatusToScheduledTransferStatusForUpdate } from '../mappers';
import OpsDashboardContext from '../OpsDashboardContext';
import { TransferItem, TransferStatus, TransferStatusUpdateItem } from '../types';
import { getHasOpenFlags, OpsDashboardUser } from '../utils';

import {
  defaultAppliedFilters,
  DepositDisplayStrings,
  DepositsFilters,
  generateDepositStaticDisplayStrings,
} from './content';
import { useDepositsTabData } from './hooks';

import { Field, OrderType, TransferType } from '~/__generated__';
import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { SelectedFilters } from '~/components/Filters/types';
import { getSelectedOptions } from '~/components/Filters/utils';
import { StatusChangeModal } from '~/components/modals/StatusChange';
import { NullStateProps } from '~/components/NullState';
import { BasicTable, SortConfig } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { CashTransferTable } from '~/containers/OpsDashboardV2/common/CashTransferTable';
import { SearchBar } from '~/containers/OpsDashboardV2/common/SearchBar';
import { ContentOptions } from '~/utils/contentstack';

export interface DepositsTabProps {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onUpdate: (item: TransferItem) => void;
}

const sortFieldToSymphony = new Map([
  ['amount', Field.AMOUNT],
  ['createdAt', Field.CREATED],
  ['scheduledDate', Field.SCHEDULED],
  ['settlementDate', Field.SETTLEMENT_DATE],
]);

export const DepositsTab: React.FC<DepositsTabProps> = ({
  commentColumn,
  contentOptions,
  currentUser,
  nullStateConfig,
  onAccountClick,
  onClientClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  onUpdate,
}) => {
  const { onParamsUpdate, params } = useContext(OpsDashboardContext);

  const state = useMemo(() => {
    let value;
    try {
      value = JSON.parse(params.state || '{}');
    } catch (e) {
      return defaultAppliedFilters;
    }

    return value;
  }, [params]);
  const appliedFilters: SelectedFilters = state.filters || defaultAppliedFilters;
  const currentPage: number = state.currentPage || 1;
  const recordsPerPage: number = state.recordsPerPage;
  const searchFilter = state.searchFilter ?? null;
  const setCurrentPage = useCallback(
    (page: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: page }) });
    },
    [onParamsUpdate, params, state],
  );
  const setRecordsPerPage = useCallback(
    (value: number) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, recordsPerPage: value }) });
    },
    [onParamsUpdate, params, state],
  );
  const sortConfig: SortConfig = useMemo(
    () =>
      state.sortConfig || {
        order: OrderType.DESCENDING,
        field: 'createdAt',
      },
    [state],
  );
  const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);

  const [content, setContent] = useState<DepositDisplayStrings>();
  const [isBusy, setIsBusy] = useState(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [selectedDepositUpdateItem, setSelectedDepositUpdateItem] = useState<TransferStatusUpdateItem>();
  const [updateCashTransferV2] = useUpdateCashTransferV2();

  const { data, loading, error } = useDepositsTabData({
    page: currentPage,
    statusFilter: getSelectedOptions(appliedFilters[DepositsFilters.STATUS]) ?? [],
    recurrenceFilter: getSelectedOptions(appliedFilters[DepositsFilters.FREQUENCY]) ?? [],
    searchFilter: searchFilter || null,
    contentOptions,
    ...sortConfig,
    field: sortFieldToSymphony.get(sortConfig.field) || Field.CREATED,
    hasOpenFlags: getHasOpenFlags(appliedFilters),
    updateRecordsPerPage: setRecordsPerPage,
    recordsPerPage,
  });

  const { open: statusModalOpen, openModal: openStatusModal, onClose: statusModalOnClose } = useModalState();

  const onSelectNewStatusForItem = useCallback(
    (item: TransferItem, selectedStatus?: TransferStatus) => {
      if (selectedStatus) {
        setSelectedDepositUpdateItem({
          item,
          newStatus: selectedStatus,
        });
      }
      openStatusModal();
    },
    [openStatusModal],
  );

  const onConfirmStatusChange = useCallback(async () => {
    if (selectedDepositUpdateItem?.item && selectedDepositUpdateItem.newStatus) {
      setIsBusy(true);
      const { newStatus } = selectedDepositUpdateItem;
      const { id, frequency, managedProductId } = selectedDepositUpdateItem.item;
      const scheduledTransferStatus = cashTransferStatusToScheduledTransferStatusForUpdate(newStatus, frequency);
      if (scheduledTransferStatus) {
        try {
          await updateCashTransferV2({
            variables: {
              transfer: {
                financialAccountId: managedProductId,
                frequency,
                status: scheduledTransferStatus,
                transferId: id,
                type: TransferType.DEPOSIT,
              },
            },
          });
          onUpdate(selectedDepositUpdateItem.item);
          data?.refetchCashDepositsData();
          setIsBusy(false);
          statusModalOnClose();
        } catch (e: any) {
          setErrorSaving(e);
          setIsBusy(false);
        }
      }
    }
  }, [data, onUpdate, selectedDepositUpdateItem, statusModalOnClose, updateCashTransferV2]);

  const onSort = useCallback(
    (field: string) => () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      onParamsUpdate({
        ...params,
        state: JSON.stringify({ ...state, currentPage: 1, sortConfig: { order: toggleOrder, field } }),
      });
    },
    [onParamsUpdate, params, state, sortConfig],
  );

  useEffect(() => {
    if (data?.content) {
      setContent(generateDepositStaticDisplayStrings(data.content));
    }
  }, [data?.content]);

  const clearErrorsAndCloseModal = useCallback(() => {
    setErrorSaving(undefined);
    statusModalOnClose();
  }, [statusModalOnClose]);

  const setSearchFilter = useCallback(
    (value: string | null) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, searchFilter: value }) });
    },
    [onParamsUpdate, params, state],
  );

  const onFilterChange = useCallback(
    (filters: SelectedFilters) => {
      onParamsUpdate({ ...params, state: JSON.stringify({ ...state, currentPage: 1, filters }) });
    },
    [onParamsUpdate, params, state],
  );

  const onAppliedFiltersSelect = useCallback(() => {
    setFiltersPopupOpen(true);
  }, []);

  const updateOpenFiltersPopup = useCallback((value: boolean) => {
    setFiltersPopupOpen(value);
  }, []);

  return (
    <Stack>
      {(error || loading) && (
        <OpsAlertAndLoading
          ariaLabel="Loading deposit items"
          contentOptions={contentOptions}
          error={error}
          loading={loading}
        />
      )}
      {data && content && !loading && (
        <>
          <Stack alignItems="center" data-qa="filter-container" flexDirection="row" justifyContent="space-between">
            <SearchBar
              onSearchChange={setSearchFilter}
              searchContext={data.searchContext}
              searchError={data.searchError}
              value={searchFilter}
            />
            <Filters
              appliedFilters={appliedFilters}
              content={content.filtersContent}
              contentOptions={contentOptions}
              defaultAppliedFilters={defaultAppliedFilters}
              filterCountMap={data.filterCountMap}
              filters={content.depositsFilterConfig}
              onSubmit={onFilterChange}
              openFiltersPopup={filtersPopupOpen}
              updateOpenFiltersPopup={updateOpenFiltersPopup}
            />
          </Stack>
          <AppliedFilters
            appliedFilters={appliedFilters}
            content={content.appliedFiltersContent}
            filters={content.depositsFilterConfig}
            onAppliedFilterSelect={onAppliedFiltersSelect}
            searchResults={
              searchFilter &&
              !data.searchError && (
                <RteContent
                  config={{ searchCount: data.paginationContext.totalItems, searchTerm: searchFilter }}
                  data={data.searchContext.totalSearchItemsLabel}
                />
              )
            }
          />
          <Stack data-qa="deposits-table-container">
            <CashTransferTable
              TableComponent={BasicTable}
              commentColumn={commentColumn}
              contentOptions={contentOptions}
              currentPage={currentPage}
              currentUser={currentUser}
              displayStrings={content}
              getAccountNumberRedirectUrl={getAccountNumberRedirectUrl}
              getClientNameRedirectUrl={getClientNameRedirectUrl}
              items={data.depositItems}
              nullStateConfig={nullStateConfig}
              onAccountClick={onAccountClick}
              onClientClick={onClientClick}
              onPageChange={setCurrentPage}
              onSelectNewStatusForItem={onSelectNewStatusForItem}
              onSort={onSort}
              paginationContext={data.paginationContext}
              refetchData={() => data.refetchCashDepositsData()}
              sortConfig={sortConfig}
              totalPages={data.totalPages}
              transferType={TransferType.DEPOSIT}
            />
            <StatusChangeModal
              contentOptions={contentOptions}
              errorSaving={errorSaving}
              isBusy={isBusy}
              onClose={clearErrorsAndCloseModal}
              onConfirm={onConfirmStatusChange}
              open={statusModalOpen}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
