import { useEffect, useState } from 'react';

import { useGetFAClientLinkSuccessModalContent } from '../../contentstack';

import { ContentOptions, getImageAssetUrl } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Content {
  heading: string;
  imgUrl: string;
  modalTitle: string;
  primaryCtaText: string;
  subHeading: string;
}

interface Data {
  content: Content;
}

interface Variables {
  contentOptions: ContentOptions;
}

export const useGetFAClientLinkSuccessData = ({ contentOptions }: Variables): AsyncResult<Data> => {
  const [state, setState] = useState<AsyncResult<Data>>({
    loading: false,
  });

  const { data: contentData, loading: contentLoading, error: contentError } = useGetFAClientLinkSuccessModalContent({
    variables: contentOptions,
    skip: !open,
  });

  useEffect(() => {
    if (contentLoading) {
      setState({ loading: true });
      return;
    }

    if (contentError) {
      setState({ loading: false, error: contentError });
      return;
    }

    const content = contentData?.all_plaid_funding?.items?.[0]?.fa_client_link_success;

    setState({
      loading: false,
      data: {
        content: {
          heading: content?.heading ?? '',
          imgUrl: getImageAssetUrl(content?.imageConnection),
          modalTitle: content?.title ?? '',
          primaryCtaText: content?.primary_cta ?? '',
          subHeading: content?.sub_heading ?? '',
        },
      },
    });
  }, [contentData, contentLoading, contentError]);

  return state;
};
