import { differenceInDays } from 'date-fns';

import { GetRmdDetails_client_rmdData_activeRmdDetails } from './symphony/__generated__/query.v2';
import { DismissRmdConfirmationModalContent, RmdDetails, RmdStatus } from './types';

import { ContentOptions, ContentstackField, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

const isRmdDueInLessThan30Days = (rmd: RmdDetails): boolean =>
  rmd.daysTillDeadline < 31 && rmd.remainingRmdAmountToBeWithdrawn > 0;

const isRmdComplete = (rmd: RmdDetails): boolean =>
  rmd.hasCompleteInformation && rmd.remainingRmdAmountToBeWithdrawn === 0;

const isRmdPartiallyAvailable = (rmd: RmdDetails): boolean =>
  !rmd.hasCompleteInformation && rmd.remainingRmdAmountToBeWithdrawn > 0;

const isRmdPartiallyComplete = (rmd: RmdDetails): boolean =>
  !rmd.hasCompleteInformation && rmd.remainingRmdAmountToBeWithdrawn === 0;

const getRmdStatus = (rmd: RmdDetails): RmdStatus => {
  if (rmd.hasNoInformation) {
    return RmdStatus.NO_DATA;
  } else if (isRmdDueInLessThan30Days(rmd) && !rmd.hasCompleteInformation) {
    return RmdStatus.LESS_THAN_31_DAYS_PARTIAL;
  } else if (isRmdDueInLessThan30Days(rmd)) {
    return RmdStatus.LESS_THAN_31_DAYS;
  } else if (isRmdPartiallyAvailable(rmd)) {
    return RmdStatus.PARTIAL_DATA_AVAILABLE;
  } else if (isRmdPartiallyComplete(rmd)) {
    return RmdStatus.PARTIAL_DATA_COMPLETED;
  } else if (isRmdComplete(rmd)) {
    return RmdStatus.COMPLETED;
  } else {
    return RmdStatus.MORE_THAN_30_DAYS;
  }
};

const formatRmdAmounts = (
  activeRmdDetails: GetRmdDetails_client_rmdData_activeRmdDetails[],
  contentOptions: ContentOptions,
): RmdDetails[] => {
  return activeRmdDetails.map(amount => ({
    daysTillDeadline: differenceInDays(new Date(amount.deadline), new Date()),
    deadline: formatDate(amount.deadline, 'MMMM dd, yyyy', { locale: contentOptions.locale }),
    hasCompleteInformation: amount.hasCompleteInformation,
    hasNoInformation: amount.hasNoInformation,
    remainingRmdAmountToBeWithdrawn: Math.max(
      parseFloat(amount.totalRmdAmount) +
        parseFloat(amount.totalPastWithdrawnAmount) -
        parseFloat(amount.totalScheduledWithdrawalAmount),
      0,
    ),
    totalScheduledWithdrawalAmount: parseFloat(amount.totalScheduledWithdrawalAmount),
    totalAmountWithdrawnYtd: parseFloat(amount.totalPastWithdrawnAmount),
    totalRmdAmountToBeWithdrawn: parseFloat(amount.totalRmdAmount),
    yearOfRmd: +formatDate(amount.deadline, 'yyyy', { locale: contentOptions.locale }),
  }));
};

export const getRmdAmountAndRmdStatus = (
  activeRmdDetails: GetRmdDetails_client_rmdData_activeRmdDetails[],
  contentOptions: ContentOptions,
  hasIneligibleIraManagedProducts: boolean,
  eligibleIraManagedProductIds: string[],
): { rmdAmounts: RmdDetails[]; rmdStatus: RmdStatus } => {
  const formattedRmdAmounts = formatRmdAmounts(activeRmdDetails, contentOptions);
  if (hasIneligibleIraManagedProducts && eligibleIraManagedProductIds.length === 0) {
    return { rmdAmounts: formattedRmdAmounts, rmdStatus: RmdStatus.INELIGIBLE_IRA };
  }
  switch (formattedRmdAmounts.length) {
    case 1:
      return { rmdAmounts: formattedRmdAmounts, rmdStatus: getRmdStatus(formattedRmdAmounts[0]) };
    case 2:
      const extendedRmdStatus = getRmdStatus(formattedRmdAmounts[0]);
      const latestRmdStatus = getRmdStatus(formattedRmdAmounts[1]);
      if (extendedRmdStatus === RmdStatus.COMPLETED) {
        return { rmdAmounts: [formattedRmdAmounts[1]], rmdStatus: latestRmdStatus };
      } else if (
        extendedRmdStatus === RmdStatus.MORE_THAN_30_DAYS &&
        (latestRmdStatus === RmdStatus.MORE_THAN_30_DAYS || latestRmdStatus === RmdStatus.PARTIAL_DATA_AVAILABLE)
      ) {
        return { rmdAmounts: formattedRmdAmounts, rmdStatus: RmdStatus.MORE_THAN_30_DAYS_MULTIPLE };
      } else {
        return { rmdAmounts: formattedRmdAmounts, rmdStatus: extendedRmdStatus };
      }
    default:
      return { rmdAmounts: formattedRmdAmounts, rmdStatus: RmdStatus.NO_RMD };
  }
};

enum RmdConfirmationModalContentKeys {
  RMD_DISMISS_MODAL_CANCEL_CTA = 'rmdDismissModalCancelCTA',
  RMD_DISMISS_MODAL_CONFIRM_CTA = 'rmdDismissModalConfirmCTA',
  RMD_DISMISS_MODAL_MESSAGE = 'rmdDismissModalMessage',
  RMD_DISMISS_MODAL_TITLE = 'rmdDismissModalTitle',
  RMD_REQUIREMENT_FULFILLED_MODAL_CANCEL_CTA = 'rmdRequirementFulfilledModalCancelCTA',
  RMD_REQUIREMENT_FULFILLED_MODAL_CONFIRM_CTA = 'rmdRequirementFulfilledModalConfirmCTA',
  RMD_REQUIREMENT_FULFILLED_MODAL_MESSAGE = 'rmdRequirementFulfilledModalMessage',
  RMD_REQUIREMENT_FULFILLED_MODAL_SUB_MESSAGE = 'rmdRequirementFulfilledModalSubMessage',
  RMD_REQUIREMENT_FULFILLED_MODAL_TITLE = 'rmdRequirementFulfilledModalTitle',
}

export const getRmdConfirmationModalContent = (
  textFields: (ContentstackField | null)[] | null | undefined,
): DismissRmdConfirmationModalContent => {
  return {
    dismissRmdModal: {
      primaryCta: findFieldValue(textFields, RmdConfirmationModalContentKeys.RMD_DISMISS_MODAL_CONFIRM_CTA),
      secondaryCta: findFieldValue(textFields, RmdConfirmationModalContentKeys.RMD_DISMISS_MODAL_CANCEL_CTA),
      title: findFieldValue(textFields, RmdConfirmationModalContentKeys.RMD_DISMISS_MODAL_TITLE),
      message: findFieldValue(textFields, RmdConfirmationModalContentKeys.RMD_DISMISS_MODAL_MESSAGE),
    },
    requirementFulfilledRmdModal: {
      primaryCta: findFieldValue(
        textFields,
        RmdConfirmationModalContentKeys.RMD_REQUIREMENT_FULFILLED_MODAL_CONFIRM_CTA,
      ),
      secondaryCta: findFieldValue(
        textFields,
        RmdConfirmationModalContentKeys.RMD_REQUIREMENT_FULFILLED_MODAL_CANCEL_CTA,
      ),
      title: findFieldValue(textFields, RmdConfirmationModalContentKeys.RMD_REQUIREMENT_FULFILLED_MODAL_TITLE),
      message: findFieldValue(textFields, RmdConfirmationModalContentKeys.RMD_REQUIREMENT_FULFILLED_MODAL_MESSAGE),
      subMessage: findFieldValue(
        textFields,
        RmdConfirmationModalContentKeys.RMD_REQUIREMENT_FULFILLED_MODAL_SUB_MESSAGE,
      ),
    },
  };
};
