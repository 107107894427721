import { Status } from '..';

import { ScheduledTransferStatus } from '~/__generated__';

export function getTransferStatusContentKey(status: ScheduledTransferStatus, statusContentMap: Status): string {
  let contentValue: string | undefined = '';
  switch (status) {
    // ACTIVE/IN_PROGRESS:
    case ScheduledTransferStatus.ACTIVE:
    case ScheduledTransferStatus.ACTIVE_AT_BROKERAGE:
    case ScheduledTransferStatus.SUBMITTED:
    case ScheduledTransferStatus.INSUFFICIENT_ASSETS:
    case ScheduledTransferStatus.UNAUTHORIZED:
    case ScheduledTransferStatus.TRADES_SUBMITTED:
    case ScheduledTransferStatus.CASH_GENERATED:
      contentValue = statusContentMap.in_progress;
      break;
    // PENDING:
    case ScheduledTransferStatus.ATTEMPTING_TO_SUBMIT_TO_BROKERAGE:
    case ScheduledTransferStatus.PENDING:
      contentValue = statusContentMap.pending;
      break;
    // COMPLETED:
    case ScheduledTransferStatus.COMPLETED:
      contentValue = statusContentMap.completed;
      break;
    // CANCELLED:
    case ScheduledTransferStatus.PENDING_CANCELLATION:
    case ScheduledTransferStatus.CANCELLED:
      contentValue = statusContentMap.cancelled;
      break;
    default:
      contentValue = 'MISSING STATUS';
      break;
  }

  return contentValue || 'MISSING STATUS';
}
