import { addBusinessDays } from 'date-fns';
import React, { ComponentProps, useCallback, useEffect, useState } from 'react';

import { DocusignRequired } from '../DocusignRequired';

import { useWithdrawFundsModal } from './hooks/useWithdrawFundsModal';
import { ManagedAccountLabel } from './types';
import { WithdrawFundsForm } from './WithdrawFundsForm';
import { WithdrawFundsModalBase } from './WithdrawFundsModalBase';

import { AssociatedEntityType, FinancialAccountType, ManagedProductType, TransferFrequency } from '~/__generated__';
import { DISTRIBUTION_REASON_CODES } from '~/components/TaxWithholdingForm';
import { Modal } from '~/components/ui/Modal';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Button } from '~/components/ui/mui';
import { FinancialAccount } from '~/hooks/financial-account/symphony';
import { PlaidLinkType } from '~/hooks/plaid-link';
import { isRetirementAccountType } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { concealAccountNumber } from '~/utils/format';

export interface Props
  extends Omit<
    ComponentProps<typeof WithdrawFundsForm>,
    | 'addedFinancialAccount'
    | 'asSoonAsPossibleDate'
    | 'content'
    | 'contentV2'
    | 'formHooks'
    | 'isRefetchingAccounts'
    | 'marketHolidays'
    | 'refetchBankAccounts'
    | 'onTransferFrequencyTypeChange'
  > {
  accountId?: string;
  accountLabels?: ManagedAccountLabel[];
  availableTransferFrequencies?: TransferFrequency[];
  contentOptions: ContentOptions;
  dataQa?: string;
  financialAccountType?: FinancialAccountType;
  isRaiseCash?: boolean;
  managedProductId: string;
  managedProductType: ManagedProductType;
  onClose?: (message?: string) => void;
  onLinkAccount?: (account: FinancialAccount) => void;
  onWithdrawSuccess?: () => void;
  open?: boolean;
  partyId: string;
  plaidLinkageType?: PlaidLinkType;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntity?: { entityId: string; entityType: AssociatedEntityType },
    isDocusignEnvelopeCreated?: boolean,
  ) => void;
  showVerifiedBankAccounts?: boolean;
  soonestPossibleDays?: number;
  syncExternalBankAccounts?: boolean;
}

export const getDistributionReasonCode = (
  clientAge?: number | null,
  distributionCode?: string | null,
  prematureAgeContent?: number | null,
) => {
  if (distributionCode) {
    return distributionCode;
  } else if (clientAge && prematureAgeContent) {
    return clientAge < prematureAgeContent ? DISTRIBUTION_REASON_CODES.PREMATURE : DISTRIBUTION_REASON_CODES.NORMAL;
  }
  return null;
};

export const WithdrawFundsModal: React.FC<Props> = ({
  accountLabels,
  availableTransferFrequencies,
  contentOptions,
  dataQa = 'withdraw-funds-modal',
  isRaiseCash,
  accountId,
  financialAccountType,
  managedProductId,
  managedProductType,
  onClose,
  onLinkAccount,
  onWithdrawSuccess,
  open = false,
  partyId,
  partyIdFA,
  plaidLinkageType,
  redirectToSignDocuments,
  showVerifiedBankAccounts,
  soonestPossibleDays = 3,
  syncExternalBankAccounts,
  ...withdrawFundsFormProps
}) => {
  const {
    featureFlags: { isDocusignRequiredForFinancialAccountLinkageInRCE },
  } = useCoreConfig();
  const {
    open: isDocusignRequiredModalOpen,
    openModal: openDocusignRequiredModal,
    onClose: closeDocusignRequiredModal,
  } = useModalState();
  const asSoonAsPossibleDate = addBusinessDays(new Date().setHours(0, 0, 0, 0), soonestPossibleDays);
  const [disableButton, setDisableButton] = useState(false);
  const [isRefetchingAccountData, setRefetchingAccountData] = useState(false);

  const handleWithdrawFundsOnOpen = useCallback(async () => {
    setRefetchingAccountData(true);
    await onWithdrawSuccess?.();
    setRefetchingAccountData(false);
  }, [onWithdrawSuccess]);

  useEffect(() => {
    if (open) {
      // This will ensure that each time the WithdrawFundsModal is opened, it retrieves the most up-to-date account information, including the current available withdrawal balance.
      handleWithdrawFundsOnOpen();
    }
  }, [open]);

  const { data, loading, error } = useWithdrawFundsModal({
    accountId,
    asSoonAsPossibleDate,
    availableTransferFrequencies,
    contentOptions,
    openDocusignRequiredModal,
    financialAccountType,
    isRaiseCash,
    managedProductId,
    managedProductType,
    onClose,
    onLinkAccount,
    onWithdrawSuccess,
    open,
    partyId,
    redirectToSignDocuments,
    showVerifiedBankAccounts,
    syncExternalBankAccounts,
  });

  if (
    financialAccountType &&
    isRetirementAccountType(financialAccountType) &&
    !data.content?.retirement_account_withdrawal_enabled
  ) {
    const imageUrl = data.content?.withdrawBlocked?.imageConnection?.edges?.[0]?.node?.url;
    return (
      <Modal
        actions={
          <>
            <Button id="close-btn" onClick={() => onClose?.()} variant="outlined">
              {data.content?.withdrawBlocked?.cta_close_label}
            </Button>
          </>
        }
        content={
          <Box data-qa={`${dataQa}-modal-container`} flexDirection="column" id="non-functional-modal-container">
            {imageUrl && (
              <Box data-qa={`${dataQa}-image`} sx={{ pb: 2 }}>
                <img alt="" height="80" src={imageUrl} />
              </Box>
            )}
            {data.content?.withdrawBlocked?.content}
          </Box>
        }
        data-qa={`${dataQa}-non-functional-modal-container`}
        loading={loading}
        onClose={onClose}
        open={open}
        title={data.content?.title}
      />
    );
  }

  return (
    <>
      <WithdrawFundsModalBase
        accountLabels={accountLabels}
        addedFinancialAccount={data.addedFinancialAccount}
        asSoonAsPossibleDate={asSoonAsPossibleDate}
        bankAccounts={data.bankAccounts}
        clientAge={data.clientAge}
        content={data.content}
        contentOptions={contentOptions}
        contentV2={data.contentV2}
        dataQa={dataQa}
        disableButton={disableButton}
        error={error}
        errors={data.errors}
        financialAccountType={financialAccountType}
        formData={data.formData}
        formHooks={data.formHooks}
        formStep={data.formStep}
        isRaiseCash={isRaiseCash}
        isRefetchingAccounts={data.isRefetchingAccounts}
        isRetirementWithdrawal={data.isRetirementWithdrawal}
        isSubmitting={data.isSubmitting}
        loading={loading || isRefetchingAccountData}
        managedProductId={managedProductId}
        marketHolidays={data.marketHolidays}
        obfuscatedAccountNumber={concealAccountNumber(withdrawFundsFormProps.accountName)}
        onBack={data.onBack}
        onLinkAccount={data.onLinkAccount}
        onModalClose={data.onModalClose}
        onNext={data.onNext}
        onSuccessCallback={data.onSuccessCallback}
        onTransferFrequencyTypeChange={data.handleTransferFrequencyTypeChange}
        open={open}
        partyId={partyId}
        partyIdFA={partyIdFA}
        plaidLinkageType={plaidLinkageType}
        refetchBankAccounts={data.refetchBankAccounts}
        rmdData={data.rmdData}
        setDisableButton={setDisableButton}
        setTotalWithdrawalAmount={data.setTotalWithdrawalAmount}
        symphonySubmitError={data.symphonySubmitError}
        validateCashTransferResponse={data.validateCashTransferResponse}
        {...withdrawFundsFormProps}
      />
      {isDocusignRequiredForFinancialAccountLinkageInRCE && (
        <DocusignRequired
          contentOptions={contentOptions}
          onClose={closeDocusignRequiredModal}
          onPrimaryClick={data.handlePrimaryClickOnDocusignRequired}
          open={isDocusignRequiredModalOpen}
        />
      )}
    </>
  );
};
