import { UpdateCloseWorkflowV2, UpdateCloseWorkflowV2Variables } from './__generated__/mutation.v2';
import {
  AccountClosuresV2,
  AccountClosuresV2_closeWorkflows_closeWorkflows_flags,
  AccountClosuresV2Variables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type ClosureFlags = AccountClosuresV2_closeWorkflows_closeWorkflows_flags;

export const useAccountClosuresV2 = (
  options?: QueryHookOptions<AccountClosuresV2, AccountClosuresV2Variables>,
): QueryResult<AccountClosuresV2, AccountClosuresV2Variables> => {
  return useSymphonyQuery(queries.AccountClosuresV2, options);
};

export const useUpdateCloseWorkflowV2 = (
  options?: MutationHookOptions<UpdateCloseWorkflowV2, UpdateCloseWorkflowV2Variables>,
): MutationTuple<UpdateCloseWorkflowV2, UpdateCloseWorkflowV2Variables> => {
  return useSymphonyMutation(mutations.UpdateCloseWorkflowV2, options);
};
