import { TransferStatus } from '../../types';
import { getColumnKeyValuePair } from '../../utils';
import {
  GetRecurringCancellationsTableContent,
  GetRecurringCancellationsTableContent_all_recurring_cancellations_table_items,
  GetRecurringCancellationsTableContent_all_recurring_cancellations_table_items_statuses,
} from '../contentstack/__generated__/query.v2';

import { TransferType } from '~/__generated__';
import { TableColumn } from '~/components/ui/BasicTable';
import { ContentstackTableColumn } from '~/utils/table';

export type RecurringCancellationsDisplayStrings = {
  disclaimer: string;
  endDate?: string;
  flags?: undefined;
  nonWrappableRecurring: string[];
  recurringtableHeaders: TableColumn[];
  status: Record<TransferStatus, string>;
  transferToAccountNote: string;
  transferTypeButton: { deposits: string; withdrawals: string };
};

export const generateStaticDisplayStrings = (
  content: GetRecurringCancellationsTableContent,
  transferType?: TransferType,
): RecurringCancellationsDisplayStrings => {
  const items = content.all_recurring_cancellations_table?.items?.[0];

  const columns = getColumnKeyValuePair(items?.columns?.column as ContentstackTableColumn[], []);

  return {
    disclaimer: items?.disclaimer || '',
    transferTypeButton: { withdrawals: 'Withdrawals', deposits: 'Deposits' },
    recurringtableHeaders: columns,
    status: {
      [TransferStatus.IN_PROGRESS]: getStatusLabel(TransferStatus.IN_PROGRESS, items),
      [TransferStatus.CANCELLED]: getStatusLabel(TransferStatus.CANCELLED, items),
      [TransferStatus.COMPLETED]: getStatusLabel(TransferStatus.COMPLETED, items),
      [TransferStatus.PENDING]: getStatusLabel(TransferStatus.PENDING, items),
      [TransferStatus.UNRECOGNIZED]: getStatusLabel(TransferStatus.UNRECOGNIZED, items),
      [TransferStatus.PENDING_CANCELLATION]: getStatusLabel(TransferStatus.PENDING_CANCELLATION, items),
    },
    transferToAccountNote: getInstructionsString(items, transferType),
    nonWrappableRecurring: items?.columns?.non_wrappable?.flatMap(f => (f ? [f] : [])) ?? [],
  };
};

const getStatusLabel = (
  status: TransferStatus,
  items?: GetRecurringCancellationsTableContent_all_recurring_cancellations_table_items | null | undefined,
): string => {
  const key = status.toLowerCase() as keyof GetRecurringCancellationsTableContent_all_recurring_cancellations_table_items_statuses;
  return items?.statuses?.[key] ?? '';
};

function getInstructionsString(
  items?: GetRecurringCancellationsTableContent_all_recurring_cancellations_table_items | null | undefined,
  transferType?: TransferType,
): string {
  switch (transferType) {
    case TransferType.DEPOSIT:
      return items?.deposit_cancellation_instructions ?? '';
    case TransferType.WITHDRAWAL:
      return items?.withdrawal_cancellation_instructions ?? '';
    default:
      return '';
  }
}
