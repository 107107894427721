import React, { ComponentProps, useMemo } from 'react';

import { useGetPricingContent } from './contentstack';
import { useGetEstimatedFees } from './hooks/useGetEstimatedFees';

import { HorizontalLines } from '~/components/modals/Pricing/HorizontalLines';
import { Modal } from '~/components/ui/Modal';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { format } from '~/utils/format/currency';
import { SfTheme } from '~/utils/theme';

export type Props = ComponentProps<typeof Modal> & {
  accountValue: number;
  contentOptions: ContentOptions;
  maskedAccountNumber: string;
  onClose?: () => void;
};

export const PricingModal: React.FC<Props> = ({
  contentOptions,
  accountValue,
  maskedAccountNumber,
  onClose,
  open,
  dataQa = 'pricing-modal',
}) => {
  const {
    sfPricingModal: { typographyVariants },
  } = useTheme<SfTheme>();

  const { data: pricingContent, loading: contentLoading, error: contentError } = useGetPricingContent({
    variables: contentOptions,
  });

  const content = pricingContent?.all_pricing_modal?.items?.[0];
  const feeItems = useMemo(() => content?.fees ?? [], [content?.fees]);

  const { estimatedAnnualFeePercent, estimatedMonthlyFee } = useGetEstimatedFees(accountValue, feeItems);

  return (
    <Modal
      actions={
        <>
          <Button color="primary" data-qa={`${dataQa}-close-button`} onClick={onClose as () => void} variant="outlined">
            {content?.cta?.secondary}
          </Button>
        </>
      }
      content={
        <Box data-qa={`${dataQa}-table-container`} flexDirection="column" sx={{ px: { xs: 0, md: 15 } }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{content?.labels?.account_value}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    {format(Number(accountValue), { trimDecimals: false })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feeItems.length > 0 && (
                  <>
                    {feeItems.map(({ label, value }: any) => (
                      <TableRow key={label}>
                        <TableCell sx={{ borderBottom: 'none' }}>
                          <Typography variant={typographyVariants?.feeItem}>{label}</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none', textAlign: 'right' }}>
                          <Typography variant={typographyVariants?.feeItem}>{value}%</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
                {feeItems.length > 1 && (
                  <>
                    <HorizontalLines />
                    <TableRow>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        <Typography>{content?.labels?.estimated_annual_fee}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: 'none',
                          textAlign: 'right',
                        }}
                      >
                        <Typography>{estimatedAnnualFeePercent}%</Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell sx={{ borderBottom: 'none', paddingTop: 0 }}>
                    {content?.labels?.estimated_monthly_fee}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', paddingTop: 0, textAlign: 'right' }}>
                    {format(Number(estimatedMonthlyFee), { trimDecimals: false })}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={contentError}
      loading={contentLoading}
      onClose={onClose}
      open={open}
      title={
        <RteContent
          config={{
            maskedAccountNumber,
          }}
          data={content?.modal_title ?? ''}
        />
      }
    />
  );
};
