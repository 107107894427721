import { differenceInDays, parseISO } from 'date-fns';
import React from 'react';

import { Comment } from '../../common/Comment';
import { FlagAction } from '../../common/FlagAction';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { OpsDashboardContextValue } from '../../OpsDashboardContext';
import { getAccountNumberOrAccountTypeString } from '../../utils';
import { FundingTabFlagItemsContent } from '../contentstack';
import { FundingDetailsColumn } from '../FundingDetailsColumn';
import { FundingTabContentData, FundingTabSymphonyData } from '../types';

import { FinancialAccountType, FlagEntity, FlagReason, FundingReviewStatus, UserNoteEntityType } from '~/__generated__';
import { StatusButton } from '~/components/StatusButton';
import { TableColumn, TableData } from '~/components/ui/BasicTable';
import { Box } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText } from '~/utils/account';
import { AccountType, ProductName } from '~/utils/account/types';
import { formatCurrency } from '~/utils/format';

const FundingReviewFlagReasons = [
  FlagReason.HIGH_TRANSACTION_VALUE,
  FlagReason.API_FAILURE,
  FlagReason.POSSIBLE_DUPLICATE,
  FlagReason.OVERDUE,
  FlagReason.OTHER,
];

export interface FormatterInputs extends OpsDashboardContextValue {
  acceptedStatusUpdations: FundingReviewStatus[];
  accountTypes: AccountType[];
  content: FundingTabContentData;
  fundingData: FundingTabSymphonyData;
  handleFundingReviewStatusChangeForItem: (id: string, newStatus: FundingReviewStatus) => void;
  productNames: ProductName[];
}

export interface FormatterReturnValue {
  tableColumns: TableColumn[];
  tableData: TableData[];
}

export const capitalizeEnums = (string: string): string => {
  return string
    .toLowerCase()
    .replace(/[a-z]/, match => match.toUpperCase())
    .replaceAll('_', ' ');
};

/**
 * @param {FormatterInputs}  - Ops dashboard context values, Symphony and ContentStack returned data
 * @param {acceptedStatusUpdation} - All statuses to which a funding review can be updated
 * @returns {FormatterReturnValue} - Collation of table columns and table data
 *
 */
export const formatDataToTableRows = ({
  acceptedStatusUpdations,
  content,
  contentOptions,
  currentUser,
  commentColumn,
  fundingData,
  handleFundingReviewStatusChangeForItem,
  onAccountClick,
  onClientClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  productNames,
  accountTypes,
}: FormatterInputs): FormatterReturnValue => {
  const data = fundingData.fundingReviews.map(product => {
    const fundingReviewItemStatus = product.fundingDetails.fundingReviewStatus;
    return {
      rowKey: product.id,
      accountNumber: (
        <AccountNumber
          accountNumber={product?.financialAccountNumber}
          accountNumberText={getAccountNumberOrAccountTypeString(
            product?.financialAccountNumber || null,
            getAccountTypeText(
              product?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
              accountTypes,
            ),
          )}
          label={getAccountProgramText(product.program, product.attributes, productNames)}
          onClick={() => onAccountClick(product.initialClientParty.partyId, product.managedProductId)}
          redirectUrl={getAccountNumberRedirectUrl(product.initialClientParty.partyId, product.managedProductId)}
        />
      ),
      clientName: (
        <ClientName
          clientName={`${product.initialClientParty.givenName} ${product.initialClientParty.familyName}`}
          onClick={() => onClientClick(product.initialClientParty.partyId)}
          redirectUrl={getClientNameRedirectUrl(product.initialClientParty.partyId)}
        />
      ),
      fundedAmount: formatCurrency(parseFloat(product.fundingDetails.fundingAmount)),
      initialInvestment: formatCurrency(parseFloat(product.fundingDetails.fundingValue)),
      details: (
        <FundingDetailsColumn
          displayStrings={{
            fundLater:
              content.staticStringMapping.find(stringItem => stringItem && stringItem.key === 'fundLater')
                ?.display_value ?? '',
            fundingSource:
              content.staticStringMapping.find(stringItem => stringItem && stringItem.key === 'fundingSource')
                ?.display_value ?? '',
          }}
          fundLater={product.fundingDetails.fundLater}
          fundingSourceType={product.fundingDetails.fundingSources[0]}
          transfers={product.fundingDetails.transfers ?? []}
        />
      ),
      inception: (
        <RteContent
          config={{
            inception: differenceInDays(new Date(), parseISO(product.createdAt)),
          }}
          data={
            content.staticStringMapping.find(stringMapping => stringMapping && stringMapping.key === 'inception')
              ?.display_value ?? ''
          }
        />
      ),
      fundingStatus: (
        <StatusButton
          currentStatus={fundingReviewItemStatus}
          onMenuItemClick={newStatus =>
            handleFundingReviewStatusChangeForItem(product.id, newStatus as FundingReviewStatus)
          }
          statusLabels={content.statuses}
          statusUpdateItems={fundingReviewItemStatus !== FundingReviewStatus.FUNDED ? acceptedStatusUpdations : []}
        />
      ),
      actions: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Comment
            comment={product.entityNotes}
            content={commentColumn}
            contentOptions={contentOptions}
            currentUser={currentUser}
            entity={UserNoteEntityType.FUNDING_REVIEW}
            entityId={product.id.toString()}
            key={product.id.toString()}
            lastCommentPartyId={product.lastCommentPartyId}
          />
          <FlagAction
            accountId={product.financialAccountNumber}
            accountTypeText={product.accountType}
            clientName={product.initialClientParty.givenName}
            contentOptions={contentOptions}
            entity={FlagEntity.FUNDING_REVIEW}
            entityId={product.id}
            flagReasons={FundingReviewFlagReasons}
            flags={product.flags}
            flagsContent={content.flagsContent ?? ({} as FundingTabFlagItemsContent)}
            onCreate={() => fundingData.refetchFundingReviewsData()}
            onResolve={() => fundingData.refetchFundingReviewsData()}
          />
        </Box>
      ),
    };
  });
  return {
    tableColumns: content.tableHeaders.map(column => ({
      key: column?.column_id ?? '',
      title: column?.column_value ?? '',
    })),
    tableData: data,
  };
};
