import React from 'react';

import { AddAccounts } from './AddAccount';
import { AddFundsRow } from './Row';

import { FinancialAccountType } from '~/__generated__/symphonyTypes.v2';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { BankAccount } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

interface Content {
  columns: {
    account: string;
    contributionAmount: string;
  };
  ctas: {
    addAnotherAccount?: string;
    addAnotherAccountViaPlaid?: string;
    addFunds: string;
    removeAccount?: string;
  };
  title: string;
}

export interface Props {
  bankAccounts: BankAccount[];
  content: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  defaultContributionAmount?: string;
  onAddFunds: (value: { account: BankAccount; value: string }) => void;
  onAddManualAccount?: () => void;
  onAddPlaidAccount?: () => void;
  onRemoveAccount?: (accountId: string) => void;
  partyId: string;
  type?: FinancialAccountType;
}

export const AddFunds: React.FC<Props> = ({
  dataQa = 'add-funds',
  bankAccounts,
  content: { title, columns, ctas },
  contentOptions,
  defaultContributionAmount = '100.00',
  onAddFunds,
  onAddManualAccount,
  onAddPlaidAccount,
  onRemoveAccount,
  partyId,
}) => {
  const {
    sfAddFunds: { typographyVariants, root, styles },
  } = useTheme();
  const {
    featureFlags: { isManualLinkageForBankAccountSupported, isPlaidLinkageForBankAccountSupported },
  } = useCoreConfig();
  const isMediumScreen = useIsMediumScreen();

  return (
    <Box data-qa={dataQa} sx={{ ...root }}>
      <Typography
        aria-level={2}
        component="h3"
        role="heading"
        sx={{ mb: 2 }}
        variant={typographyVariants?.sectionHeading || 'h3'}
      >
        {title}
      </Typography>
      <TableContainer>
        <Table sx={{ ...styles.table }}>
          <TableHead>
            <TableRow>
              <TableCell>{columns.account}</TableCell>
              {!isMediumScreen && ctas.removeAccount && onRemoveAccount && <TableCell />}
              <TableCell>{columns.contributionAmount}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankAccounts.map(account => {
              return (
                <AddFundsRow
                  account={account}
                  content={{ addFundsCta: ctas.addFunds, removeAcccountCta: ctas.removeAccount }}
                  contentOptions={contentOptions}
                  defaultContributionAmount={defaultContributionAmount}
                  key={account.id}
                  onAddFunds={onAddFunds}
                  onRemoveAccount={onRemoveAccount}
                  partyId={partyId}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {onAddManualAccount && isManualLinkageForBankAccountSupported && (
        <AddAccounts content={{ addCta: ctas.addAnotherAccount }} onAddAccount={onAddManualAccount} />
      )}
      {onAddPlaidAccount && isPlaidLinkageForBankAccountSupported && (
        <AddAccounts
          content={{ addCta: ctas.addAnotherAccountViaPlaid }}
          isPlaidAddAccount
          onAddAccount={onAddPlaidAccount}
        />
      )}
    </Box>
  );
};
