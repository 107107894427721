import uniqueId from 'lodash.uniqueid';
import React, { useCallback, useMemo, useState } from 'react';

export const useMenu = (menuId: string) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const id = useMemo(() => uniqueId(`${menuId}-`), [menuId]);

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return {
    buttonProps: {
      'aria-controls': anchorEl ? id : undefined,
      'aria-haspopup': true,
      'aria-expanded': !!anchorEl,
      onClick,
    },
    menuProps: {
      id,
      anchorEl,
      open: Boolean(anchorEl),
      onClose,
    },
  };
};
