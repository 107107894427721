import { Locale } from '~/utils/contentstack';

interface Options {
  currency: string;
  currencyDisplay: string;
  locale: string;
  trimDecimals: boolean;
}

export enum localeToCurrencySymbol {
  'en-ca' = '$',
  'en-us' = '$',
  'fr-ca' = '$',
}

export const isInteger = (amount: number): boolean => {
  return amount % 1 === 0;
};

export const precise = (amount: number, options: Partial<Options> = {}): string => {
  return format(amount, {
    ...options,
    trimDecimals: false,
  });
};

export const flexible = (amount: number, options: Partial<Options> = {}): string => {
  return format(amount, {
    ...options,
    trimDecimals: isInteger(amount),
  });
};

export const rounded = (amount: number, options: Partial<Options> = {}): string => {
  return format(amount, {
    ...options,
    trimDecimals: true,
  });
};

export const toSI = (value: number, decimals = 0, prefix?: string) => {
  const POSTFIXES = ['', 'K', 'M', 'G'];
  const prefixLabel = prefix || '';

  if (value === 0) {
    return value;
  }

  // determines what level of prefix should be applied
  const level = Math.floor(Math.log10(Math.abs(value)) / 3);

  // if zero, we don't need a prefix
  if (level === 0) {
    return prefixLabel + value;
  }

  // scale the number
  const scale = Math.pow(10, level * 3);
  const scaled = value / scale;

  let result = scaled.toFixed(decimals);

  // remove '.0' case
  if (/\.0$/.test(result)) {
    result = result.substr(0, result.length - 2);
  }
  const postfix = POSTFIXES[level];

  return prefixLabel + result + postfix;
};

export const format = (
  amount: number,
  { locale = 'en-US', currency = 'USD', trimDecimals = true, currencyDisplay = 'narrowSymbol' }: Partial<Options> = {},
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay,
    minimumFractionDigits: trimDecimals ? 0 : undefined,
    maximumFractionDigits: trimDecimals ? 0 : undefined,
  }).format(amount);
};

export const getCurrencyInputProps = (
  locale: Locale,
  currencySymbol: string,
  suffix = '',
): { decimalSeparator: string; prefix?: string; suffix?: string; thousandSeparator: string } => {
  if (locale === Locale.fr_ca) {
    return {
      decimalSeparator: ',',
      thousandSeparator: ' ',
      suffix: ` ${currencySymbol}${suffix}`,
    };
  }
  return {
    decimalSeparator: '.',
    thousandSeparator: ',',
    prefix: currencySymbol,
    suffix,
  };
};

export const getOrdinalNumber = (n: number) => {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
};
