import React, { FC, useMemo } from 'react';

import { useBillingHistory } from './hooks';

import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable } from '~/components/ui/BasicTable';
import { Box, Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
}

export const BillingHistory: FC<Props> = ({ contentOptions, dataQa = 'billing-history', managedProductId }) => {
  const { data, loading, error } = useBillingHistory({
    contentOptions,
    managedProductId,
  });

  const billingHistoryData = useMemo(
    () =>
      data?.rows.map((row, idx) => ({
        ...row,
        rowKey: `${idx}`,
      })),
    [data],
  );

  return (
    <Grid container data-qa={dataQa} justifyContent="space-between" mb={3} spacing={3}>
      {loading && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading Billing Rates"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      )}
      {data && data.columns && billingHistoryData && (
        <>
          <Grid item xs={12}>
            <Typography component="div" sx={{ mb: 5, fontWeight: 700 }} variant="h3">
              {data.title}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <BasicTable
                  alignItems="left"
                  columns={data.columns}
                  data={billingHistoryData}
                  dataQa={`${dataQa}-table`}
                  sortConfig={data.sortConfig}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
