import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

import { useCashTransfers } from '../../common/CashTransfer/symphony';
import {
  CashTransfers,
  CashTransfers_cashTransfers_transfers_attributes_IntegerAttribute,
  CashTransfersVariables,
} from '../../common/CashTransfer/symphony/__generated__/query.v2';
import { ManagedProductStatusesForCashTransfers } from '../../common/CashTransfer/utils';
import { useGetOpsDashboardContent } from '../../contentstack';
import { GetOpsDashboardContent } from '../../contentstack/__generated__/query.v2';
import { statusFilterToScheduledTransferStatus, toCashTransferStatus } from '../../mappers';
import { RequestTabs, TransferItem, TransferStatusFilter } from '../../types';
import {
  getCurrentPage,
  getLastEditDateAndTime,
  getSortedPartnerOpsTradingSuspensions,
  getTotalPages,
} from '../../utils';
import { useGetDepositsTableContent } from '../contentstack';
import { GetDepositsTableContent } from '../contentstack/__generated__/query.v2';

import {
  Field,
  FinancialAccountType,
  ManagedProductStatus,
  OrderType,
  TransferFrequencyType,
  TransferType,
} from '~/__generated__';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { getAccountProgramText, getAccountState } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

export enum RecurrenceOptions {
  RECURRING,
  ONE_TIME,
}

export interface DepositsTabVariables {
  contentOptions: ContentOptions;
  currentRequestTab: RequestTabs;
  field: Field;
  order: OrderType;
  page: number;
  recurrenceFilter: RecurrenceOptions;
  statusFilter: TransferStatusFilter;
}

export interface DepositsTabData {
  content: GetDepositsTableContent;
  currentPage: number;
  depositItems: TransferItem[];
  refetchCashDepositsData: () => void;
  totalPages: number;
}

export const useDepositsTabData = ({
  page,
  recurrenceFilter,
  statusFilter,
  contentOptions,
  field,
  order,
  currentRequestTab,
}: DepositsTabVariables): AsyncResult<DepositsTabData> => {
  const [state, setState] = useState<AsyncResult<DepositsTabData>>({ loading: true });
  const { data: contentstackData, loading: contentstackLoading, error: contentstackError } = useGetDepositsTableContent(
    {
      variables: contentOptions,
    },
  );
  const content = contentstackData?.all_deposits_table?.items?.[0];
  const variables: CashTransfersVariables = {
    limit: 10,
    offset: (page - 1) * 10,
    frequency:
      recurrenceFilter === RecurrenceOptions.ONE_TIME
        ? TransferFrequencyType.ONE_TIME
        : TransferFrequencyType.RECURRING,
    statuses: statusFilterToScheduledTransferStatus(statusFilter),
    field,
    order,
    hasOpenFlags: content?.requests?.partitioned_by_flagged_status
      ? currentRequestTab === RequestTabs.FlaggedRequests
      : null,
    fetchLatestNote: true,
    transferType: TransferType.DEPOSIT,
    managedProductStatuses: ManagedProductStatusesForCashTransfers,
  };

  const {
    data: opsDashboardContentData,
    loading: opsDashboardContentLoading,
    error: opsDashboardContentError,
  } = useGetOpsDashboardContent({
    variables: contentOptions,
  });

  // The reason for no-cache is due to the updating the list when ever we create or resolve flag  for a deposit, the flagged and unflagged deposits table should be updated.
  // Otherwise, instead of the table showing the current flagged and unflagged deposits data it shows the previous cached data. There is no easy way to update the cache to reflect the correct state right now.
  const {
    data: cashDepositsData,
    loading: cashDepositsLoading,
    error: cashDepositsError,
    refetch: refetchCashDepositsData,
  } = useCashTransfers({
    variables,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    skip: contentstackLoading && !contentstackData,
  });
  useEffect(() => {
    if (cashDepositsError || contentstackError || opsDashboardContentError) {
      setState({
        error: cashDepositsError || contentstackError || opsDashboardContentError,
        loading: false,
      });
    }
  }, [cashDepositsError, contentstackError, opsDashboardContentError]);

  useEffect(() => {
    if (
      !cashDepositsLoading &&
      cashDepositsData &&
      !contentstackLoading &&
      contentstackData &&
      !opsDashboardContentLoading &&
      opsDashboardContentData
    ) {
      const depositItems: TransferItem[] = getDepositsItems(contentOptions, cashDepositsData, opsDashboardContentData);
      const { limit, offset, total } = cashDepositsData.cashTransfers.paginationContext;
      const currentPage = getCurrentPage({ limit, offset });
      const totalPages = getTotalPages({ limit, total });
      setState({
        data: {
          depositItems,
          currentPage,
          totalPages,
          content: contentstackData,
          refetchCashDepositsData,
        },
        loading: false,
      });
    }
  }, [
    cashDepositsData,
    cashDepositsLoading,
    contentstackLoading,
    contentstackData,
    opsDashboardContentLoading,
    opsDashboardContentData,
    contentOptions,
    refetchCashDepositsData,
  ]);

  return state;
};

const getDepositsItems = (
  contentOptions: ContentOptions,
  data: CashTransfers,
  opsDashboardContent?: GetOpsDashboardContent,
): TransferItem[] => {
  const programeNames = opsDashboardContent?.all_product_name?.items || [];

  return data.cashTransfers.transfers.map(item => {
    const latestOpsTradingSuspension = getSortedPartnerOpsTradingSuspensions(item.managedProduct?.tradingSuspensions)[0]
      ?.createdAt;
    const managedProductStatus = item.managedProduct?.status ?? ManagedProductStatus.UNKNOWN_FINANCIAL_ACCOUNT_STATUS;
    const partyId = item.managedProduct?.clientParty?.id || '';
    // only used for activated account states
    const accountState = getAccountState({
      firstRebalancedOn: item.managedProduct?.firstRebalancedOn ?? undefined,
      financialAccountStatus: managedProductStatus,
      suspendedOn: latestOpsTradingSuspension,
    }).state;
    return {
      id: item.id,
      accountNumber: item.managedProduct?.financialAccountNumber || '',
      accountState,
      accountTypeText: getAccountTypeText(
        item.managedProduct?.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
        opsDashboardContent?.all_account_type?.items || [],
      ),
      amount: parseFloat(item.total.value),
      clientName: `${item.managedProduct?.clientParty?.partyPerson?.givenName ?? ''} ${
        item.managedProduct?.clientParty?.partyPerson?.familyName ?? ''
      }`,
      createdAt: item.createdOn,
      destinationBankAccount: item.bankAccount?.accountNumber || '',
      flags: item.flags,
      frequency: item.frequency,
      managedProductId: item.managedProduct?.id || '',
      notes: item.notes || undefined,
      partyId,
      productName: getAccountProgramText(item.managedProduct?.program, item.managedProduct?.attributes, programeNames),
      rmdPlanId: (item.attributes.find(
        attribute => attribute.name === 'RMD_PLAN_ID',
      ) as CashTransfers_cashTransfers_transfers_attributes_IntegerAttribute)?.integerValue,
      scheduledDate: item.scheduledAt || undefined,
      settlementDate: item.settlementDate || undefined,
      numberOfOccurrences: item.numberOfOccurrences || undefined,
      status: toCashTransferStatus(item.status),
      lastCommentData: item.entityNotes[0]
        ? {
            ...getLastEditDateAndTime(parseISO(item.entityNotes[0].created), contentOptions),
            lastValue: item.entityNotes[0].note,
          }
        : undefined,
      lastCommentPartyId: item.entityNotes[0]?.createdByPartyId ?? undefined,
    };
  });
};
