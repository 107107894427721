import React, { useEffect, useState } from 'react';

import { Box, Button, CheckCircleOutlineIcon, CloseIcon, IconButton, MuiSnackbar } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface Props extends React.ComponentProps<typeof MuiSnackbar> {
  autoHideDuration?: number;
  closeButtonLabel?: string | null;
  dataQa?: string;
  defaultOpen?: boolean;
  feedbackMessage: string;
  handleUndo?: () => void;
  onClose?: () => void;
  snackbarPosition?: SnackbarPositionType;
  undoButtonLabel?: string;
}

export enum FeedbackMessageType {
  ADD_FUNDS = 'ADD_FUNDS',
  CLOSE_ACCOUNT = 'CLOSE_ACCOUNT',
  EDIT_PORTFOLIO = 'EDIT_PORTFOLIO',
  WITHDRAW_FUNDS = 'WITHDRAW_FUNDS',
}

export enum SnackbarPositionType {
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
}

export interface SnackbarPositions {
  [position: string]: { horizontal: 'right' | 'left' | 'center'; vertical: 'top' | 'bottom' };
}

export const snackbarPositions: SnackbarPositions = {
  [SnackbarPositionType.TOP_RIGHT]: { vertical: 'top', horizontal: 'right' },
  [SnackbarPositionType.BOTTOM_LEFT]: { vertical: 'bottom', horizontal: 'left' },
  [SnackbarPositionType.BOTTOM_CENTER]: { vertical: 'bottom', horizontal: 'center' },
};

export const Snackbar: React.FC<Props> = ({
  autoHideDuration,
  dataQa = 'snackbar',
  feedbackMessage,
  closeButtonLabel,
  handleUndo,
  undoButtonLabel,
  onClose,
  defaultOpen = true,
  snackbarPosition = SnackbarPositionType.TOP_RIGHT,
  ...rest
}) => {
  // TODO: convert this to have functionality state same as Modal Component
  const [showSnackbar, setShowSnackbar] = useState(defaultOpen);
  useEffect(() => {
    let hideSnackbarTimer: NodeJS.Timeout | null = null;
    if (!onClose && !autoHideDuration) {
      hideSnackbarTimer = setTimeout(() => {
        setShowSnackbar(false);
      }, 5000);
    }
    return () => {
      if (hideSnackbarTimer) {
        clearTimeout(hideSnackbarTimer);
      }
    };
  }, []);

  const handleCloseSnackbar = (_?: React.SyntheticEvent<any> | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (onClose) {
      onClose();
      return;
    }
    setShowSnackbar(false);
  };

  return (
    <>
      {showSnackbar && (
        <MuiSnackbar
          action={
            <>
              {undoButtonLabel && (
                <Button>
                  <Typography onClick={() => handleUndo?.()} sx={{ fontWeight: 'bold', mr: 1 }}>
                    {undoButtonLabel}
                  </Typography>
                </Button>
              )}
              <IconButton aria-label="close" color="inherit" onClick={handleCloseSnackbar} sx={{ p: 0.5 }}>
                {!undoButtonLabel && (
                  <Typography sx={{ fontWeight: 'bold', mr: 1 }}>{closeButtonLabel || 'Close'}</Typography>
                )}
                <CloseIcon />
              </IconButton>
            </>
          }
          anchorOrigin={snackbarPositions?.[snackbarPosition]}
          autoHideDuration={autoHideDuration ?? 5000}
          data-qa={dataQa}
          message={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineIcon />
              <Box data-qa={`${dataQa}-snackbar-message`} sx={{ ml: 1 }}>
                {feedbackMessage}
              </Box>
            </Box>
          }
          onClose={handleCloseSnackbar}
          open
          {...rest}
        />
      )}
    </>
  );
};
