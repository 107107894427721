import { useEffect, useState } from 'react';

import {
  GetModelPortfoliosContent,
  GetModelPortfoliosContent_all_model_portfolio_data_items,
  GetModelPortfoliosContentVariables,
} from './__generated__/query.v2';
import * as modelPortfoliosQuery from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { ContentOptions, useContentstackQuery } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

type ModelPortfolioContent = GetModelPortfoliosContent_all_model_portfolio_data_items;

interface AllModelPortfoliosDataAggregator {
  items: ModelPortfolioContent[];
  total: number;
}

interface AllModelPortfoliosContentVariables {
  contentOptions: ContentOptions;
  skip?: boolean;
}

export const useGetModelPortfoliosContent = (
  options?: QueryHookOptions<GetModelPortfoliosContent, GetModelPortfoliosContentVariables>,
): QueryResult<GetModelPortfoliosContent, GetModelPortfoliosContentVariables> => {
  return useContentstackQuery(modelPortfoliosQuery.GetModelPortfoliosContent, {
    ...options,
    variables: {
      ...(options?.variables as GetModelPortfoliosContentVariables),
      skip: 0,
      limit: 100,
    },
  });
};

export const useGetAllModelPortfoliosContentAcrossPages = ({
  contentOptions,
  skip,
}: AllModelPortfoliosContentVariables): AsyncResult<GetModelPortfoliosContent> => {
  const limit = 100;
  const [state, setState] = useState<AsyncResult<GetModelPortfoliosContent>>({ loading: !skip });
  const [loading, setLoading] = useState<boolean>(!skip);
  const [collectedModelPortfolioData, setCollectedModelPortfolioData] = useState<AllModelPortfoliosDataAggregator>({
    total: 0,
    items: [],
  });

  const {
    data: mpDataPage,
    loading: mpDataPageLoading,
    error: mpDataPageError,
    refetch: refetchMpData,
  } = useGetModelPortfoliosContent({
    // Adding no-cache because the internal hook state would go bad if the same content page is loaded again
    // with data from a cache update
    fetchPolicy: 'no-cache',
    variables: {
      ...contentOptions,
      skip: 0,
      limit,
    },
    skip,
  });

  useEffect(() => {
    setLoading(!skip);
    setCollectedModelPortfolioData({
      total: 0,
      items: [],
    });
    setState({ loading: !skip });
  }, [skip, contentOptions]);

  useEffect(() => {
    if (mpDataPageError) {
      setState({
        error: mpDataPageError,
        loading: false,
      });
    }
  }, [mpDataPageError]);

  useEffect(() => {
    if (mpDataPage && !mpDataPageLoading) {
      const total = mpDataPage.all_model_portfolio_data?.total ?? 0;
      const pageModelPortfolios = (mpDataPage.all_model_portfolio_data?.items ?? []) as ModelPortfolioContent[];
      const currentModelPortfolios = collectedModelPortfolioData.items;
      const allModelPortfolios = [...currentModelPortfolios, ...pageModelPortfolios];
      setCollectedModelPortfolioData({
        ...collectedModelPortfolioData,
        total,
        items: allModelPortfolios,
      });
      if (total > allModelPortfolios.length) {
        refetchMpData({
          skip: allModelPortfolios.length,
          limit,
        });
      } else {
        setLoading(false);
      }
    }
  }, [mpDataPage, mpDataPageLoading]);

  useEffect(() => {
    if (!loading && collectedModelPortfolioData) {
      setState({
        data: {
          all_model_portfolio_data: {
            ...collectedModelPortfolioData,
            __typename: 'AllModelPortfolioData',
          },
        },
        loading: false,
      });
    }
  }, [collectedModelPortfolioData, loading]);
  return state;
};
