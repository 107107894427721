import { GetSuspensionsHistoryTabContent, GetSuspensionsHistoryTabContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type SuspensionsHistoryTabContent = GetSuspensionsHistoryTabContent;

export const useGetSuspensionsHistoryTabContent = (
  options?: QueryHookOptions<GetSuspensionsHistoryTabContent, GetSuspensionsHistoryTabContentVariables>,
): QueryResult<GetSuspensionsHistoryTabContent, GetSuspensionsHistoryTabContentVariables> => {
  return useContentstackQuery(queries.GetSuspensionsHistoryTabContent, options);
};
