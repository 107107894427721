import React, { ComponentProps, FC } from 'react';

import { Dropdown } from '~/components/ui/Dropdown';
import { MuiTabs } from '~/components/ui/mui';
import { useIsScreenSize } from '~/utils/responsiveness';

type BaseTabsProps = ComponentProps<typeof MuiTabs>;
type OptionalDropdownProps =
  | {
      DropdownProps?: never;
      enableMobileDropdown?: false;
    }
  | {
      DropdownProps?: Partial<ComponentProps<typeof Dropdown>>;
      enableMobileDropdown: true;
    };
export type Props = Omit<BaseTabsProps, 'onChange'> &
  OptionalDropdownProps & {
    dataQa?: string;
    onChange?: (newValue: Parameters<NonNullable<BaseTabsProps['onChange']>>[1]) => void;
  };

const isReactElement = (child: React.ReactNode): child is React.ReactElement => {
  return !!child && typeof child === 'object' && 'props' in child;
};

export const Tabs: FC<Props> = ({
  children,
  dataQa = 'tabs',
  DropdownProps,
  enableMobileDropdown,
  onChange,
  value,
  ...rest
}) => {
  const isMobile = useIsScreenSize('sm');

  if (isMobile && enableMobileDropdown && Array.isArray(children)) {
    const items = React.Children.toArray(children)
      .filter(isReactElement)
      .map(child => ({
        label: child.props.label,
        value: child.props.value,
      }));

    return (
      <Dropdown
        dataQa={`${dataQa}-mobile`}
        items={items}
        onChange={({ target: { value: newValue } }) => onChange?.(newValue)}
        value={value}
        width="100%"
        {...DropdownProps}
      />
    );
  }

  return (
    <MuiTabs data-qa={`${dataQa}`} onChange={(_, newValue) => onChange?.(newValue)} value={value} {...rest}>
      {children}
    </MuiTabs>
  );
};
