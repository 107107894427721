import {
  GetClosureTableContentV2,
  GetClosureTableContentV2_all_closures_table_items_flag_referenceConnection_edges_node,
  GetClosureTableContentV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ClosuresFlagReferenceContent = GetClosureTableContentV2_all_closures_table_items_flag_referenceConnection_edges_node;

export const useGetClosureTableContentV2 = (
  options?: QueryHookOptions<GetClosureTableContentV2, GetClosureTableContentV2Variables>,
): QueryResult<GetClosureTableContentV2, GetClosureTableContentV2Variables> => {
  return useContentstackQuery(queries.GetClosureTableContentV2, options);
};
