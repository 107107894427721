import { GetAccountDrawerContent, GetAccountDrawerContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useAccountsDrawerContent = (
  options?: QueryHookOptions<GetAccountDrawerContent, GetAccountDrawerContentVariables>,
): QueryResult<GetAccountDrawerContent, GetAccountDrawerContentVariables> => {
  return useContentstackQuery(queries.GetAccountDrawerContent, options);
};
