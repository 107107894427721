import {
  GetActiveTradingSuspensionsTabContent,
  GetActiveTradingSuspensionsTabContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ActiveTradingSuspensionsTabContent = GetActiveTradingSuspensionsTabContent;

export const useGetActiveTradingSuspensionsTabContent = (
  options?: QueryHookOptions<GetActiveTradingSuspensionsTabContent, GetActiveTradingSuspensionsTabContentVariables>,
): QueryResult<GetActiveTradingSuspensionsTabContent, GetActiveTradingSuspensionsTabContentVariables> => {
  return useContentstackQuery(queries.GetActiveTradingSuspensionsTabContent, options);
};
