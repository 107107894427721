import { ComparisonContent } from './contentstack';
import { GetComparisonContent_all_comparison_items_confirmation_modal } from './contentstack/__generated__/query.v2';
import { BillingRate, TargetModelPortfolio } from './symphony';

import { QuestionnaireFilterTypes } from '~/__generated__';
import { Allocation } from '~/components/AssetAllocationTable';
import { RiskBand } from '~/components/RiskSpectrum';
import { GetAssetClassContent_all_asset_class_data_items } from '~/hooks/asset-classes/contentstack/__generated__/query.v2';
import { CalculatedRecommendations } from '~/hooks/managed-product/symphony';
import { GetModelPortfoliosContent_all_model_portfolio_data_items } from '~/hooks/model-portfolios/contentstack/__generated__/query.v2';
import { getAssetAllocations, isCompositeRecommendedPortfolio, isRecommendedPortfolio } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { getImageAssetUrl } from '~/utils/contentstack';
import { formatPercentage } from '~/utils/format';
import { getClientFee } from '~/utils/pricing';

export enum ComparisonState {
  Error = 'Error',
  Info = 'Info',
  Success = 'Success',
}

export interface PortfolioAssetDetailsContent {
  clientFee: string;
  currentPortfolioHeading: string;
  donutAllocationLabel: string;
  minimumInvestmentLabel: string;
  modalLinkText: string;
  newPortfolioHeading: string;
  stockBondLabel: string;
}

export interface ModelPortfolioAllocationData {
  description: string;
  internalId: number;
  modelPortfolioName: string;
  percentage?: string;
  seriesId: number;
  sleeveAssetAllocations: Allocation[];
  sleeveStockBondDiversification: {
    bondPercentage: string;
    stockPercentage: string;
  };
}

export interface ModelPortfolioData {
  assetAllocation: Allocation[];
  clientFee: string;
  filter: QuestionnaireFilterTypes;
  managedProductId: string;
  minimumBalanceForAccountOpening?: number;
  minimumBalanceForRebalance?: number;
  modelPortfolios?: ModelPortfolioAllocationData[];
  stockBondDiversification: {
    bondPercentage: string;
    stockPercentage: string;
  };
}

export interface ComparisonModalContent {
  body: string;
  confirmationModalType: string;
  heading: string;
  iconUrl: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}

export const getModelPortfolioData = (
  managedProductId?: string | null,
  targetModelPortfolioData?: TargetModelPortfolio | null,
  assetClassContentData?: (GetAssetClassContent_all_asset_class_data_items | null)[],
  modelPortfolioContentData?: (GetModelPortfoliosContent_all_model_portfolio_data_items | null)[],
  assetClassTier?: AssetClassTier,
  billingRates?: BillingRate[] | null,
): ModelPortfolioData => {
  const targetSingleMPData = isRecommendedPortfolio(targetModelPortfolioData) ? targetModelPortfolioData : null;
  const targetCompositeMPData = isCompositeRecommendedPortfolio(targetModelPortfolioData)
    ? targetModelPortfolioData
    : null;
  const modelPortfolioContent = isRecommendedPortfolio(targetModelPortfolioData)
    ? modelPortfolioContentData?.find(
        mc =>
          mc?.series_name === targetSingleMPData?.seriesBaseName &&
          mc?.internal_id === targetSingleMPData?.internalId?.toString(),
      )
    : null;

  const modelPortfolios = isCompositeRecommendedPortfolio(targetModelPortfolioData)
    ? targetCompositeMPData?.allocation.map(alloc => {
        const mpContent = modelPortfolioContentData?.find(
          mc =>
            mc?.series_name === alloc.modelPortfolioSeriesBaseName &&
            mc.internal_id === alloc.modelPortfolioInternalId?.toString(),
        );
        return {
          description: mpContent?.model_portfolio_description ?? '',
          internalId: alloc.modelPortfolioInternalId ?? 0,
          modelPortfolioName: mpContent?.model_portfolio_name ?? '',
          percentage: alloc.percentage ?? 0,
          seriesId: alloc.modelPortfolioSeriesId ?? 0,
          sleeveAssetAllocations: getAssetAllocations(
            alloc.modelPortfolio?.guidance?.diversification?.assets.allocations,
            assetClassContentData,
            assetClassTier,
          ),
          sleeveStockBondDiversification: {
            bondPercentage:
              alloc.modelPortfolio?.guidance?.diversification?.assets?.stockBondDiversification?.bondPercentage ?? '',
            stockPercentage:
              alloc.modelPortfolio?.guidance?.diversification?.assets?.stockBondDiversification?.stockPercentage ?? '',
          },
        };
      })
    : [
        {
          description: modelPortfolioContent?.model_portfolio_description ?? '',
          internalId: targetSingleMPData?.internalId ?? 0,
          modelPortfolioName: modelPortfolioContent?.model_portfolio_name ?? '',
          seriesId: targetSingleMPData?.seriesId ?? 0,
          sleeveAssetAllocations: getAssetAllocations(
            targetSingleMPData?.guidance?.diversification?.assets.allocations,
            assetClassContentData,
            assetClassTier,
          ),
          sleeveStockBondDiversification: {
            bondPercentage:
              targetSingleMPData?.guidance?.diversification?.assets?.stockBondDiversification?.bondPercentage ?? '',
            stockPercentage:
              targetSingleMPData?.guidance?.diversification?.assets?.stockBondDiversification?.stockPercentage ?? '',
          },
        },
      ];

  const minimumBalanceForAccountOpening =
    (isCompositeRecommendedPortfolio(targetModelPortfolioData)
      ? targetCompositeMPData?.compositeMinimumBalanceForAccountOpening
      : targetSingleMPData?.accountMinimumBalanceForAccountOpening) ?? '';

  const minimumBalanceForRebalance =
    (isCompositeRecommendedPortfolio(targetModelPortfolioData)
      ? targetCompositeMPData?.compositeMinimumBalanceForRebalance
      : targetSingleMPData?.accountMinimumBalanceForRebalance) ?? '';

  const clientFee = formatPercentage(getClientFee(billingRates));

  return {
    clientFee,
    filter: QuestionnaireFilterTypes.ONBOARDING_COMPLETED,
    managedProductId: managedProductId ?? '',
    minimumBalanceForAccountOpening: minimumBalanceForAccountOpening
      ? parseFloat(minimumBalanceForAccountOpening)
      : undefined,
    minimumBalanceForRebalance: minimumBalanceForRebalance ? parseFloat(minimumBalanceForRebalance) : undefined,
    modelPortfolios,
    stockBondDiversification: {
      bondPercentage:
        targetModelPortfolioData?.guidance?.diversification?.assets?.stockBondDiversification?.bondPercentage ?? '',
      stockPercentage:
        targetModelPortfolioData?.guidance?.diversification?.assets?.stockBondDiversification?.stockPercentage ?? '',
    },
    assetAllocation: getAssetAllocations(
      targetModelPortfolioData?.guidance?.diversification?.assets?.allocations,
      assetClassContentData,
      assetClassTier,
    ),
  };
};

export const mergeAllocationData = (current: Allocation[], target: Allocation[]) => {
  const allocationData: Allocation[] = [];
  const assetClassesInCurrent: string[] = [];

  current.forEach(currentItem => {
    const mergedItem = target.find(newItem => newItem.assetClass.key === currentItem.assetClass.key);
    allocationData.push({
      // in cases where the allocation isn't found in target, we just use the current values
      ...(mergedItem ?? currentItem),
      targetPercent: mergedItem?.percent ?? 0,
      actualPercent: currentItem.percent,
    });
    assetClassesInCurrent.push(currentItem.assetClass.key);
  });

  target
    .filter(item => item.assetClass.key && !assetClassesInCurrent.includes(item.assetClass.key))
    .forEach(targetItem => {
      allocationData.push({
        ...targetItem,
        targetPercent: targetItem.percent,
        actualPercent: 0,
      });
    });

  return allocationData;
};

export const findRiskBandData = (
  riskBands?: RiskBand[],
  recommendedData?: CalculatedRecommendations,
  overriddenRiskScore?: number,
): RiskBand | undefined => {
  const riskScoreToUse = overriddenRiskScore ?? recommendedData?.riskScore;
  return riskBands?.find(
    band => riskScoreToUse && riskScoreToUse >= band.riskScoreFloor && riskScoreToUse <= band.riskScoreCeiling,
  );
};

/**
 * Helper function to normalize Comparison Confirmation Modal content
 * @param content Comparison Confirmation Modal content
 * @returns Normalized object with default empty string fallbacks
 */
export const getComparisonModalContent = (
  content?: GetComparisonContent_all_comparison_items_confirmation_modal | null,
): ComparisonModalContent => ({
  confirmationModalType: content?.confirmation_modal_type ?? '',
  body: content?.body ?? '',
  heading: content?.modal_heading ?? '',
  iconUrl: getImageAssetUrl(content?.modal_imageConnection),
  primaryButtonText: content?.primary_button_text ?? '',
  secondaryButtonText: content?.secondary_button_text ?? '',
});

export const getPortfolioAssetDetailsContent = (
  comparisonContent: ComparisonContent,
): PortfolioAssetDetailsContent => ({
  clientFee: comparisonContent?.client_fee ?? '',
  currentPortfolioHeading: comparisonContent?.current_portfolio_heading ?? '',
  donutAllocationLabel: comparisonContent?.donut_allocation_label ?? '',
  minimumInvestmentLabel: comparisonContent?.minimum_investment_label ?? '',
  newPortfolioHeading: comparisonContent?.new_portfolio_heading ?? '',
  stockBondLabel: comparisonContent?.stock_bond_label ?? '',
  modalLinkText: comparisonContent?.modal_link_text ?? '',
});

export const getComparisonState = (
  accountBalance: number | undefined,
  rebalanceMinimum: number,
  minimumBalance: number,
) => {
  if (accountBalance && accountBalance >= rebalanceMinimum) {
    return accountBalance >= minimumBalance ? ComparisonState.Success : ComparisonState.Info;
  }
  return ComparisonState.Error;
};
