import React, { useCallback, useRef, useState } from 'react';

import { Content, Props, Row } from '..';
import { CellContents } from '../CellContents';
import { TransferCancellationPrompt } from '../TransferCancellationPrompt';

import { ScheduledTransferStatus, TransferType } from '~/__generated__';
import { TableCell, TableRow } from '~/components/ui/mui';
import { ContentOptions } from '~/utils/contentstack';

export interface BodyRowProps {
  cancelTransferStatusMap: { [key: string]: ScheduledTransferStatus };
  content: Content;
  contentOptions: ContentOptions;
  managedProductId: string;
  onConfirmCancel: Props['onConfirmCancel'];
  onWithdrawalCancelled?: Props['onWithdrawalCancelled'];
  row: Row;
  setCancelTransferStatus: (rowId: string, cancelTransferStatus?: ScheduledTransferStatus) => void;
}

export const BodyRow: React.FC<BodyRowProps> = ({
  content,
  contentOptions,
  managedProductId,
  onConfirmCancel: baseOnConfirmCancel,
  setCancelTransferStatus,
  cancelTransferStatusMap,
  onWithdrawalCancelled,
  row,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [hasError, setHasError] = useState(false);
  const includeCancel = content.columns.filter(col => col.data === 'Action').length > 0 && row.isCancellable;
  const cancelCtaRef = useRef<HTMLAnchorElement>(null);

  const onDismiss = useCallback(() => {
    setHasError(false);
    setIsExpanded(false);
    if (cancelCtaRef.current) {
      cancelCtaRef.current.focus();
    }
  }, []);
  const onConfirmCancel = useCallback(async () => {
    setIsBusy(true);
    setHasError(false);
    try {
      const res = await baseOnConfirmCancel({
        variables: {
          transfer: {
            financialAccountId: managedProductId,
            frequency: row.frequency,
            transferId: row.id,
            type: row.type,
          },
        },
      });
      setCancelTransferStatus(row.id, res.data?.cancelCashTransfer?.status);
      setIsExpanded(false);
      if (row.type === TransferType.WITHDRAWAL) {
        onWithdrawalCancelled?.();
      }
    } catch (err) {
      console.error(err);
      setHasError(true);
    }
    setIsBusy(false);
  }, [baseOnConfirmCancel]);

  return (
    <>
      <TableRow>
        {content.columns.map((column, index) => (
          <TableCell key={index} sx={includeCancel ? { borderBottom: { md: 'none' } } : {}}>
            <CellContents
              cancelCtaProps={
                includeCancel
                  ? {
                      onClick: () => setIsExpanded(true),
                      ref: cancelCtaRef,
                    }
                  : undefined
              }
              cancelTransferStatus={cancelTransferStatusMap[row.id]}
              content={content}
              contentOptions={contentOptions}
              data={column.data}
              row={row}
            />
          </TableCell>
        ))}
      </TableRow>
      {includeCancel && (
        <TransferCancellationPrompt
          content={content}
          contentOptions={contentOptions}
          disabled={isBusy}
          hasError={hasError}
          onConfirmCancellation={onConfirmCancel}
          onDismiss={onDismiss}
          open={isExpanded}
          transferType={row.type}
        />
      )}
    </>
  );
};
