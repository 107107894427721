import { getColumnKeyValuePair } from '../../utils';
import {
  GetClosureTableContent,
  GetClosureTableContent_all_closures_table_items,
} from '../contentstack/__generated__/query.v2';

import { TableColumn } from '~/components/ui/BasicTable';
import { ClosuresFlagReferenceContent } from '~/containers/OpsDashboard/ClosuresTab/contentstack';
import {
  ClosureStatus,
  ClosureStatusFilter,
  ClosureType,
  RequestTabs,
} from '~/containers/OpsDashboard/ClosuresTab/types';
import { findFieldValue } from '~/utils/contentstack';
import { ContentstackTableColumn } from '~/utils/table';

export type ClosureDisplayStrings = {
  bankVerificationFailedNote: string;
  closureTypes: Record<ClosureType, string>;
  flags: ClosuresFlagReferenceContent;
  partitionLabels: Record<RequestTabs, string>;
  status: Record<ClosureStatus, string>;
  statusFilter: Record<ClosureStatusFilter, string>;
  tableHeaders: TableColumn[];
  transferToAccountNote: string;
};

export const generateClosureStaticDisplayStrings = (content: GetClosureTableContent): ClosureDisplayStrings => {
  const items = content.all_closures_table?.items?.[0];
  const flagsContent = items?.flag_referenceConnection?.edges?.[0]?.node ?? ({} as ClosuresFlagReferenceContent);
  return {
    flags: flagsContent,
    status: {
      [ClosureStatus.IN_PROGRESS]: items?.statuses?.in_progress ?? '',
      [ClosureStatus.COMPLETED]: items?.statuses?.completed ?? '',
      [ClosureStatus.CANCELLED]: items?.statuses?.cancelled ?? '',
      [ClosureStatus.PENDING]: items?.statuses?.pending ?? '',
      [ClosureStatus.UNRECOGNIZED]: items?.statuses?.unrecognized_status ?? '',
    },
    statusFilter: {
      [ClosureStatusFilter.ALL]: items?.status_filters?.all ?? '',
      [ClosureStatusFilter.PENDING]: items?.status_filters?.pending ?? '',
      [ClosureStatusFilter.IN_PROGRESS]: items?.status_filters?.in_progress ?? '',
      [ClosureStatusFilter.COMPLETED]: items?.status_filters?.completed ?? '',
      [ClosureStatusFilter.CANCELLED]: items?.status_filters?.cancelled ?? '',
    },
    partitionLabels: getPartitionLabels(items),
    tableHeaders: getColumnKeyValuePair(items?.columns?.column as ContentstackTableColumn[], []),
    transferToAccountNote: items?.transfer_to_account_note ?? '',
    bankVerificationFailedNote: findFieldValue(items?.fields?.text ?? [], 'BankVerificationFailedNote'),
    closureTypes: {
      [ClosureType.END_MANAGEMENT]: items?.closure_types?.end_management ?? '',
      [ClosureType.LIQUIDATION]: items?.closure_types?.liquidation ?? '',
    },
  };
};

const getPartitionLabels = (
  item: GetClosureTableContent_all_closures_table_items | null | undefined,
): Record<RequestTabs, string> => {
  const partitionTabsContent = item?.partition_tabs ?? [];
  return {
    [RequestTabs.FlaggedRequests]:
      partitionTabsContent.find(tab => tab?.value === RequestTabs.FlaggedRequests)?.label ?? '',
    [RequestTabs.AllOtherRequests]:
      partitionTabsContent.find(tab => tab?.value === RequestTabs.AllOtherRequests)?.label ?? '',
  };
};
