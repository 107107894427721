import { useLocalStorage } from '../local-storage/useLocalStorage';

import { Locale } from '~/utils/contentstack';

const DEFAULT_LANGUAGE = Locale.en_us;
const LOCALE_KEY = 'locale';

/**
 * A hook to set the locale localStorage entry.
 * @returns An array containing two elements.
 * The first is the current active locale value stored in localStorage. The second is a function to update the locale value.
 */
export const useLocale = (): [Locale | undefined, (newState: Locale) => void] => {
  const [locale, setLocale] = useLocalStorage<Locale | undefined>(LOCALE_KEY, undefined);

  const updateLocale = (newLocale?: Locale) => {
    document.documentElement.lang = newLocale || DEFAULT_LANGUAGE;
    setLocale(newLocale || DEFAULT_LANGUAGE);
  };

  return [locale, updateLocale];
};
