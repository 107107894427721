import * as queries from './query.gql';

import {
  GetDocusignTabContent,
  GetDocusignTabContentVariables,
} from '~/containers/OpsDashboardV2/DocusignTab/contentstack/__generated__/query.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetDocusignTabContent = (
  options?: QueryHookOptions<GetDocusignTabContent, GetDocusignTabContentVariables>,
): QueryResult<GetDocusignTabContent, GetDocusignTabContentVariables> => {
  return useContentstackQuery(queries.GetDocusignTabContent, options);
};
