import { ComponentProps } from 'react';

import { CommentComponentColumn } from '../common/Comment';
import { OpsDashboardUser } from '../utils';

import { EntityType, FinancialAccountType, ManagedProductType, Scalars as SymphonyScalars } from '~/__generated__';
import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { FilterConfig, FilterCountMap, FilterTypes, SelectedFilters } from '~/components/Filters/types';
import { SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { TextFields } from '~/containers/OpsDashboardV2/AllAccountsTab/contentstack';
import { GetOpsDashboardContentV2 } from '~/containers/OpsDashboardV2/contentstack/__generated__/query.v2';
import { AccountState } from '~/utils/account';
import { AccountAttribute } from '~/utils/account/types';
import { ContentOptions } from '~/utils/contentstack';
import { PaginationContext, SearchContext } from '~/utils/types';

export enum FailureType {
  ACCOUNT_RESTRICTION = 'ACCOUNT_RESTRICTION',
  CANCELLATION_AT_BROKERAGE = 'CANCELLATION_AT_BROKERAGE',
  CREATE_RECEIPT = 'CREATE_RECEIPT',
  CREATE_STANDING_INSTRUCTIONS = 'CREATE_STANDING_INSTRUCTIONS',
  CREATION_AT_BROKERAGE = 'CREATION_AT_BROKERAGE',
  POST_SIGNING_FAILURE = 'POST_SIGNING_FAILURE',
  REQUEST_CLOSE_FAILURE = 'REQUEST_CLOSE_FAILURE',
  UPDATE_ACCOUNT_STATUS_FAILURE = 'UPDATE_ACCOUNT_STATUS_FAILURE',
}

export enum FailureTypeContentKey {
  ACCOUNT_RESTRICTION_ERROR = 'ACCOUNT_RESTRICTION_ERROR',
  ADD_FUNDS_REQUEST_ERROR = 'ADD_FUNDS_REQUEST_ERROR',
  CANCELLATION_AT_BROKERAGE_ERROR = 'CANCELLATION_AT_BROKERAGE_ERROR',
  CREATE_STANDING_INSTRUCTIONS_ERROR = 'CREATE_STANDING_INSTRUCTIONS_ERROR',
  POST_SIGNING_ERROR = 'POST_SIGNING_ERROR',
  REQUEST_CLOSE_ERROR = 'REQUEST_CLOSE_ERROR',
  TOA_ERROR_AT_CUSTODIAN = 'TOA_ERROR_AT_CUSTODIAN',
  UPDATE_ACCOUNT_STATUS_ERROR = 'UPDATE_ACCOUNT_STATUS_ERROR',
  WITHDRAW_FUNDS_REQUEST_ERROR = 'WITHDRAW_FUNDS_REQUEST_ERROR',
}

export const EntityTypes = [
  EntityType.ASSET_DEPOSIT,
  EntityType.BANK_ACCOUNT,
  EntityType.BANK_ACCOUNT_ASSOCIATION,
  EntityType.CASH_DEPOSIT,
  EntityType.CASH_WITHDRAWAL,
  EntityType.COPILOT_PORTFOLIO,
  EntityType.RECURRING_CASH_DEPOSIT,
  EntityType.RECURRING_CASH_WITHDRAWAL,
];

export interface ErrorsFailureContent {
  comment?: {
    lastEditDate: string;
    lastEditTime: string;
    lastValue: string;
  };
  created: SymphonyScalars['DateTime'];
  description?: string;
  entity: EntityType;
  entityId?: string | null;
  failureType?: string;
  id: string;
  isResolved: boolean;
  lastCommentPartyId?: string;
  managedProduct: {
    accountState: AccountState;
    accountType?: FinancialAccountType | null;
    attributes?: AccountAttribute[];
    clientParty: {
      id?: string | null;
      partyPerson?: {
        familyName?: string | null;
        givenName?: string | null;
      };
    };
    financialAccountNumber?: string | null;
    program?: ManagedProductType;
  };
  managedProductId?: string | null;
  ulid: string;
}

export interface GetTableContent {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  data: ErrorsTabData;
  errorId?: number | null;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  handleOpenModal: (row: ErrorsFailureContent) => void;
  handleResolveClick: (entity: EntityType, failureId: number) => void;
  mpData: ErrorsFailureContent[];
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  showProductName: boolean;
  updateFailuresLoading: boolean;
}

export interface FailureTypeContent {
  failure_type_key: string | null;
  failure_type_value: string | null;
}

export interface ErrorTableContent {
  activeRowId?: string | undefined;
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  errorId?: string | null;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  handleOpenModal: (row: ErrorsFailureContent) => void;
  handleResolveClick: (entity: EntityType, failureId: string) => void;
  mpData: ErrorsFailureContent[];
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  opsDashboardContent?: GetOpsDashboardContentV2;
  refetchData?: () => void;
  showProductName: boolean;
  textFields: TextFields;
  updateFailuresLoading: boolean;
}

export interface ErrorsTabData {
  appliedFilters: SelectedFilters;
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  currentPage: number;
  currentSearchFilter: string;
  errorFilterConfig: FilterConfig[];
  errorTabContent: TableData[];
  feedbackMessage: string;
  filterCountMap: FilterCountMap[];
  filtersContent: ComponentProps<typeof Filters>['content'];
  filtersPopupOpen: boolean;
  modalTitle: string;
  onAppliedFiltersSelect: (filterKey?: string, selectedOption?: string) => void;
  onFilterChange: (selectedFilters: SelectedFilters) => void;
  onPageChange: (pageNumber: number) => void;
  onRowHover: (rowId: string | undefined) => void;
  onSearchChange: (value: string | null) => void;
  paginationContext: PaginationContext;
  refetchErrorsData: () => void;
  searchContext: SearchContext;
  searchError: boolean;
  sortConfig: SortConfig;
  tableColumns: TableColumn[];
  totalPages: number;
  updateOpenFiltersPopup: (value: boolean) => void;
}

export enum ErrorsStatusFilters {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
}

export enum ErrorsTypeFilters {
  ACCOUNT = 'ACCOUNT',
  MONEY_MOVEMENT = 'MONEY_MOVEMENT',
  OTHER = 'OTHER',
  TOA = 'TOA',
}

export enum ErrorFilters {
  ERROR_TYPE = 'ERROR_TYPE',
  STATUS = 'STATUS',
}

export const errorsFilterConfig = [
  {
    key: ErrorFilters.STATUS,
    options: [
      {
        id: ErrorsStatusFilters.OPEN,
      },
      {
        id: ErrorsStatusFilters.RESOLVED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
  {
    key: ErrorFilters.ERROR_TYPE,
    options: [
      {
        id: ErrorsTypeFilters.ACCOUNT,
      },
      {
        id: ErrorsTypeFilters.TOA,
      },
      {
        id: ErrorsTypeFilters.MONEY_MOVEMENT,
      },
      {
        id: ErrorsTypeFilters.OTHER,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
];

export const defaultAppliedFilters: SelectedFilters = {
  [ErrorFilters.STATUS]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [ErrorsStatusFilters.OPEN, ErrorsStatusFilters.RESOLVED],
  },
  [ErrorFilters.ERROR_TYPE]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      ErrorsTypeFilters.ACCOUNT,
      ErrorsTypeFilters.TOA,
      ErrorsTypeFilters.MONEY_MOVEMENT,
      ErrorsTypeFilters.OTHER,
    ],
  },
};

export enum ErrorTabContentKeys {
  ALL_FILTER = 'all_filter',
  APPLIED_FILTERS = 'applied_filters',
  APPLY_CTA = 'apply_cta',
  FEED_BACK_MESSAGE = 'feed_back_message',
  FILTERS_CTA = 'filters_cta',
  MARK_AS_RESOLVED = 'mark_as_resolved',
  MODAL_TITLE = 'modal_title',
  OPEN = 'open',
  RESET_ALL_CTA = 'reset_all_cta',
  RESOLVED = 'resolved',
  VIEW_ADDITIONAL_DETAILS = 'view_additional_details',
}
