import { InitialInvestmentAmountCheck, InvestmentAmountFieldContent } from '../utils';

import { BalanceType, PositionInput, PositionTransferInput, QuantityType } from '~/__generated__';
import { AssetTransfer } from '~/containers/Funding/symphony';
import { isStringAttribute } from '~/hooks/client/symphony';
import { Balances, Position, PositionV2 } from '~/hooks/financial-account/symphony';

export const CASH_HOLDING = 'CASH_HOLDING';

export const SECURITY_ID = 'SECURITY_ID';

export type HoldingInput = Record<string, { estimatedValue: number; units: string } | undefined>;

const HoldingsTableValidationsList = [
  'aboveInitialInvestmentAmount',
  'belowInitialInvestmentAmount',
  'maximumAmount',
  'noItemSelected',
] as const;

export type HoldingsTableValidations = typeof HoldingsTableValidationsList[number];

export interface HoldingsTableContent {
  cashLabel: string;
  cashNameText: string;
  columns: {
    assets: string;
    estimatedValue: string;
    name: string;
    units: string;
  };
  customSectionLabel?: string;
  invalidUnits: string;
  investmentAmountFieldContent?: InvestmentAmountFieldContent;
  noItemSelected: string;
  perPageText: string;
  placeHolderUnits: string;
  sectionLabel?: string;
  totalSelectedText: string;
  unitsCell: string;
}
export interface Holding {
  asset: string;
  id: string;
  marketValue: number;
  marketValuePerUnit: number;
  name: string;
  units: number;
}
export interface HoldingsTableFieldProps {
  content: HoldingsTableContent;
  dataQa?: string;
  errorInputs: string[];
  financialInstitution: string;
  holdingSourceFieldName: string;
  holdings: Holding[];
  maskedAccountNumber: string;
  onChange: (e?: HoldingInput) => void;
  setErrorInputs: React.Dispatch<React.SetStateAction<string[]>>;
  showUnitsColumn?: boolean;
  totalEstimatedValue: string;
  value?: HoldingInput;
}

export interface Props
  extends Omit<HoldingsTableFieldProps, 'totalEstimatedValue' | 'errorInputs' | 'setErrorInputs' | 'onChange'> {
  defaultValues?: HoldingInput;
  initialInvestmentAmountCheck?: InitialInvestmentAmountCheck;
  maximumInvestmentAmount?: number;
  optionalMessageFieldName?: string;
  optionalMessageLabel?: string;
  optionalMessageMaxLength?: number;
  showDividerBelowLabel?: boolean;
  validations: HoldingsTableValidations[];
}

// TODO: Rename to getHoldingsFromPosititionV2 and export for SAN DA PRO.
/**
 * @param postions Accepts PositionV2
 * @param content Accepts content
 * @param balances Accepts balance for the chosen brokerage
 * @returns Holding type needed by holdings table
 */
export const getHoldings = (
  positions: PositionV2[],
  content?: HoldingsTableContent,
  balances?: Balances[] | null,
): Holding[] => {
  const cashHolding = getCashHoldings(content, balances);
  const holdings: Holding[] = [];
  positions.forEach(position => {
    const id = position.identifiers.find(item => item.name === SECURITY_ID)?.value ?? '';
    const units = parseFloat(position.quantity ?? '0');
    const marketValue = parseFloat(position.marketValues?.[0].value ?? '0');
    const asset = position.identifiers.find(item => item.name === 'TICKER')?.value ?? '';
    if (units > 0 && asset) {
      holdings.push({
        id,
        asset,
        marketValue,
        marketValuePerUnit: marketValue ? marketValue / units : 0,
        name: position.identifiers.find(item => item.name === 'NAME')?.value ?? '',
        units,
      });
    }
  });
  return cashHolding ? [cashHolding, ...holdings] : holdings;
};

/**
 * @param postions Accepts Position
 * @param content Accepts content
 * @param balances Accepts balance for the chosen brokerage
 * @returns Holding type needed by holdings table
 */
export const getHoldingsFromPosition = (
  positions: Position[],
  content?: HoldingsTableContent,
  balances?: Balances[] | null,
): Holding[] => {
  const cashHolding = getCashHoldings(content, balances);
  const holdings: Holding[] = [];
  positions.forEach(position => {
    const id = position.identifiers.find(item => item.name === SECURITY_ID)?.value ?? '';
    // For position V1, we do not want to show the units as we sum up the market value for each position
    const units = 1;
    const marketValue = position.quantities.reduce(
      (accumulator, quantity) => parseFloat(quantity.marketValue?.value ?? '0') + accumulator,
      0,
    );
    const asset = position.identifiers.find(item => item.name === 'TICKER')?.value ?? '';
    if (asset) {
      holdings.push({
        id,
        asset,
        marketValue,
        marketValuePerUnit: marketValue ? marketValue / units : 0,
        name: position.identifiers.find(item => item.name === 'NAME')?.value ?? '',
        units,
      });
    }
  });
  return cashHolding ? [cashHolding, ...holdings] : holdings;
};

/**
 * We want to use cash as holding list item this helps us convert the value
 * @param balances balances for the selected account
 * @param content HoldingTableContent to set up cash as Holding so that it can be used in table
 * @returns Cash balances converted to holding type
 */
export const getCashHoldings = (content?: HoldingsTableContent, balances?: Balances[] | null): Holding | undefined => {
  const units = parseFloat(balances?.find(b => b.type === BalanceType.CASH)?.balance.value ?? '0');
  if (!units) {
    return;
  }

  return {
    id: CASH_HOLDING,
    asset: content?.cashLabel ?? '',
    marketValue: units,
    marketValuePerUnit: 1,
    name: content?.cashNameText ?? '',
    units,
  };
};

/**
 *
 * @param holdings latest holdings for the selected account
 * @param assetTransfer current asset transfer
 * @returns list of stale holdings in assetTransfer
 */
export const getStaleHoldingsList = (holdings: Holding[], assetTransfer: AssetTransfer): string[] => {
  const positions = assetTransfer.positions;
  return positions
    .filter(position => {
      const item = holdings.find(holding => holding.id === position.security?.identifier);
      return !item || parseFloat(position.quantities[0].units) > item.units;
    })
    .map(item => item.security?.identifier ?? '');
};

/**
 * Calculates the total estimated value
 * @param value formData for holdings table
 * @param holdingKeys optional keys if present, if not will be initialised in the function
 * @returns total sum of selected values
 */
export const calulateTotalEstimatedValue = (value?: HoldingInput, holdingKeys?: string[]): number => {
  let partialSum = 0;
  const keys = holdingKeys ?? Object.keys(value ?? {});
  keys.forEach(hK => {
    const estimatedValue = value?.[hK]?.estimatedValue;
    if (estimatedValue) {
      partialSum += estimatedValue;
    }
  });
  return partialSum;
};

/**
 * If there already exists a journaling transfer we will to prefill if applicable
 * @param assetTransfer current Asset Transfer
 * @param holdings current holdings
 * @returns defaultValues for the table
 */
export const getDefaultValuesForHoldings = (
  assetTransfer: AssetTransfer,
  holdings: Holding[],
  isJournaling = true,
): Record<string, { estimatedValue: number; units: string }> => {
  const positions = assetTransfer.positions;
  let defaultValues: Record<string, { estimatedValue: number; units: string }> = {};
  positions.forEach(item => {
    const id = item.security?.identifier ?? '';
    const holding = holdings.find(el => el.id === id);
    const units = parseFloat(item.quantities[0].units);
    if (holding && (!isJournaling || holding.units >= units)) {
      defaultValues = {
        ...defaultValues,
        [id]: {
          estimatedValue: holding.marketValuePerUnit
            ? isJournaling
              ? holding.marketValuePerUnit * units
              : holding.marketValuePerUnit
            : 0,
          units: units.toString(),
        },
      };
    }
  });
  const cashAmount = parseFloat(assetTransfer.cash?.value ?? '0');
  return cashAmount
    ? { CASH_HOLDING: { estimatedValue: cashAmount, units: cashAmount.toString() }, ...defaultValues }
    : defaultValues;
};

/**
 * transforms results to some of the items needed to create Journaling transfer.
 * @param value formData for Holdings Table
 * @param holdings original PositionV2
 * @returns cashAmount and the PositionsTransferUnit needed in AssetTransfer
 */
export const getPositionTransferInput = (
  value: HoldingInput,
  holdings: PositionV2[],
): { cashAmount?: string; positionTransferInput?: PositionTransferInput } => {
  const cashAmount = value[CASH_HOLDING]?.estimatedValue;
  let estimatedValue = cashAmount ?? 0;
  const positions: PositionInput[] = [];
  holdings.forEach(holding => {
    const data = value[holding.identifiers.find(item => item.name === SECURITY_ID)?.value ?? ''];
    const attributes = holding.attributes?.filter(isStringAttribute);
    if (data) {
      estimatedValue += data.estimatedValue;
      positions.push({
        attributes: {},
        fromTypeCode: attributes?.find(item => item.name === 'FROM_TYPE_CODE')?.value ?? '',
        identifiers: [
          { name: 'TYPE', value: 'OTHER' },
          ...holding.identifiers.map(id => ({ name: id.name, value: id.value })),
        ],
        quantities: [
          {
            type: QuantityType.UNKNOWN_QUANTITY,
            units: data.units,
            marketValue: { currencyCode: '', value: data.estimatedValue.toString() },
          },
        ],
        toTypeCode: attributes?.find(item => item.name === 'TO_TYPE_CODE')?.value ?? '',
      });
    }
  });

  return {
    cashAmount: cashAmount?.toString(),
    positionTransferInput: { estimatedValue: estimatedValue.toFixed(2), positions },
  };
};
