export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSJSON: string;
}

export interface AccountInput {
  accountId: Scalars['String'];
  amount: Scalars['Float'];
  contributionFrequency: CashFlowSourceFrequency;
  partnerInvestmentProfileConfigId: Scalars['String'];
}

export enum AccountType {
  HOUSE = 'HOUSE',
  USER = 'USER'
}

export interface AssociatePartialToGoalInput {
  goalId: Scalars['String'];
  partialId: Scalars['String'];
}

export interface CalculateCliffProjectionInput {
  goalId: Scalars['ID'];
}

export interface CalculateRetirementProjectionInput {
  goalId: Scalars['ID'];
}

export enum CashFlowRateFrequency {
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY'
}

export enum CashFlowSourceFrequency {
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

export interface CashFlowSourceInfo {
  amount: Scalars['Float'];
  cashFlowType: Scalars['String'];
}

export interface CreateUserInfoInput {
  annualIncome?: InputMaybe<Scalars['Int']>;
  dateOfBirth: Scalars['AWSDate'];
  externalId: Scalars['ID'];
  partnerId: Scalars['ID'];
}

export enum CurrencyCode {
  CAD = 'CAD',
  USD = 'USD'
}

export interface GetGoalByPartialInput {
  copilotPortfolioUlid: Scalars['ID'];
}

export enum GoalStatus {
  ACTIVE_COMPLETE = 'ACTIVE_COMPLETE',
  ACTIVE_INCOMPLETE = 'ACTIVE_INCOMPLETE',
  COMPLETED = 'COMPLETED'
}

export enum GoalType {
  CLIFF = 'CLIFF',
  RETIREMENT = 'RETIREMENT'
}

export interface LinkGoalAndAccountsInput {
  accountInfo: Array<AccountInput>;
  goalId: Scalars['String'];
}

export interface LinkGoalAndOtherAccountInput {
  accountTitle: Scalars['String'];
  amount: Scalars['Float'];
  balance: Scalars['Float'];
  contributionFrequency: CashFlowSourceFrequency;
  goalId: Scalars['String'];
  partnerInvestmentProfileConfigId: Scalars['String'];
}

export enum ManagedProductType {
  DIGITAL_ADVICE = 'DIGITAL_ADVICE',
  DIGITAL_ADVICE_PRO = 'DIGITAL_ADVICE_PRO'
}

export interface ModelAccountFilterInput {
  accountType?: InputMaybe<ModelAccountTypeInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAccountFilterInput>>>;
  balance?: InputMaybe<ModelFloatInput>;
  externalId?: InputMaybe<ModelIDInput>;
  goalAccountId?: InputMaybe<ModelIDInput>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelAccountFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAccountFilterInput>>>;
  partnerInvestmentProfilesConfigId?: InputMaybe<ModelIDInput>;
  title?: InputMaybe<ModelStringInput>;
  userId?: InputMaybe<ModelIDInput>;
}

export interface ModelAccountTypeInput {
  eq?: InputMaybe<AccountType>;
  ne?: InputMaybe<AccountType>;
}

export enum ModelAttributeTypes {
  _null = '_null',
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet'
}

export interface ModelBooleanInput {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
}

export interface ModelCashFlowConfigFilterInput {
  and?: InputMaybe<Array<InputMaybe<ModelCashFlowConfigFilterInput>>>;
  cashFlowType?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelCashFlowConfigFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCashFlowConfigFilterInput>>>;
  title?: InputMaybe<ModelStringInput>;
}

export interface ModelCashFlowRateFrequencyInput {
  eq?: InputMaybe<CashFlowRateFrequency>;
  ne?: InputMaybe<CashFlowRateFrequency>;
}

export interface ModelCashFlowSourceFilterInput {
  amount?: InputMaybe<ModelFloatInput>;
  and?: InputMaybe<Array<InputMaybe<ModelCashFlowSourceFilterInput>>>;
  cashFlowType?: InputMaybe<ModelStringInput>;
  endDate?: InputMaybe<ModelStringInput>;
  frequency?: InputMaybe<ModelCashFlowSourceFrequencyInput>;
  goalAccountId?: InputMaybe<ModelIDInput>;
  growRateAmount?: InputMaybe<ModelFloatInput>;
  growRateFrequency?: InputMaybe<ModelCashFlowRateFrequencyInput>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelCashFlowSourceFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCashFlowSourceFilterInput>>>;
  startDate?: InputMaybe<ModelStringInput>;
  transactionType?: InputMaybe<ModelTransactionTypeInput>;
}

export interface ModelCashFlowSourceFrequencyInput {
  eq?: InputMaybe<CashFlowSourceFrequency>;
  ne?: InputMaybe<CashFlowSourceFrequency>;
}

export interface ModelCurrencyCodeInput {
  eq?: InputMaybe<CurrencyCode>;
  ne?: InputMaybe<CurrencyCode>;
}

export interface ModelFloatInput {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  ge?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  le?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
}

export interface ModelGoalAccountFilterInput {
  accountId?: InputMaybe<ModelIDInput>;
  and?: InputMaybe<Array<InputMaybe<ModelGoalAccountFilterInput>>>;
  goalId?: InputMaybe<ModelIDInput>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelGoalAccountFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelGoalAccountFilterInput>>>;
}

export interface ModelGoalConditionInput {
  and?: InputMaybe<Array<InputMaybe<ModelGoalConditionInput>>>;
  balanceOnCompletion?: InputMaybe<ModelFloatInput>;
  completedEndDate?: InputMaybe<ModelStringInput>;
  createdBy?: InputMaybe<ModelIDInput>;
  goalProjection?: InputMaybe<ModelStringInput>;
  goalStatus?: InputMaybe<ModelGoalStatusInput>;
  goalType?: InputMaybe<ModelGoalTypeInput>;
  not?: InputMaybe<ModelGoalConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelGoalConditionInput>>>;
  partnerConfigId?: InputMaybe<ModelIDInput>;
  partnerGoalExternalRef?: InputMaybe<ModelStringInput>;
  sharedWith?: InputMaybe<ModelIDInput>;
  targetBalance?: InputMaybe<ModelFloatInput>;
  targetEndDate?: InputMaybe<ModelStringInput>;
  targetSpending?: InputMaybe<ModelFloatInput>;
  title?: InputMaybe<ModelStringInput>;
  userId?: InputMaybe<ModelIDInput>;
}

export interface ModelGoalConfigFilterInput {
  and?: InputMaybe<Array<InputMaybe<ModelGoalConfigFilterInput>>>;
  configurationMeta?: InputMaybe<ModelStringInput>;
  goalType?: InputMaybe<ModelGoalTypeInput>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelGoalConfigFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelGoalConfigFilterInput>>>;
  title?: InputMaybe<ModelStringInput>;
}

export interface ModelGoalFilterInput {
  and?: InputMaybe<Array<InputMaybe<ModelGoalFilterInput>>>;
  balanceOnCompletion?: InputMaybe<ModelFloatInput>;
  completedEndDate?: InputMaybe<ModelStringInput>;
  createdBy?: InputMaybe<ModelIDInput>;
  goalProjection?: InputMaybe<ModelStringInput>;
  goalStatus?: InputMaybe<ModelGoalStatusInput>;
  goalType?: InputMaybe<ModelGoalTypeInput>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelGoalFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelGoalFilterInput>>>;
  partnerConfigId?: InputMaybe<ModelIDInput>;
  partnerGoalExternalRef?: InputMaybe<ModelStringInput>;
  sharedWith?: InputMaybe<ModelIDInput>;
  targetBalance?: InputMaybe<ModelFloatInput>;
  targetEndDate?: InputMaybe<ModelStringInput>;
  targetSpending?: InputMaybe<ModelFloatInput>;
  title?: InputMaybe<ModelStringInput>;
  userId?: InputMaybe<ModelIDInput>;
}

export interface ModelGoalStatusInput {
  eq?: InputMaybe<GoalStatus>;
  ne?: InputMaybe<GoalStatus>;
}

export interface ModelGoalTypeInput {
  eq?: InputMaybe<GoalType>;
  ne?: InputMaybe<GoalType>;
}

export interface ModelHistoricGoalProjectionFilterInput {
  and?: InputMaybe<Array<InputMaybe<ModelHistoricGoalProjectionFilterInput>>>;
  goalId?: InputMaybe<ModelIDInput>;
  goalProjection?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIDInput>;
  inputs?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelHistoricGoalProjectionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelHistoricGoalProjectionFilterInput>>>;
  projectionDate?: InputMaybe<ModelStringInput>;
}

export interface ModelIDInput {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<ModelSizeInput>;
}

export interface ModelIntInput {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
}

export interface ModelMonthInput {
  eq?: InputMaybe<Month>;
  ne?: InputMaybe<Month>;
}

export interface ModelPartnerCashFlowConfigFilterInput {
  and?: InputMaybe<Array<InputMaybe<ModelPartnerCashFlowConfigFilterInput>>>;
  cashFlowConfigId?: InputMaybe<ModelIDInput>;
  defaultAmount?: InputMaybe<ModelFloatInput>;
  id?: InputMaybe<ModelIDInput>;
  maxAmount?: InputMaybe<ModelFloatInput>;
  minAmount?: InputMaybe<ModelFloatInput>;
  not?: InputMaybe<ModelPartnerCashFlowConfigFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPartnerCashFlowConfigFilterInput>>>;
  partnerConfigId?: InputMaybe<ModelIDInput>;
}

export interface ModelPartnerConfigFilterInput {
  and?: InputMaybe<Array<InputMaybe<ModelPartnerConfigFilterInput>>>;
  currencyCode?: InputMaybe<ModelCurrencyCodeInput>;
  goalProjectionsStatus?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIDInput>;
  inflationAnnualIncome?: InputMaybe<ModelFloatInput>;
  inflationMonth?: InputMaybe<ModelMonthInput>;
  inflationRate?: InputMaybe<ModelFloatInput>;
  lifeExpectancy?: InputMaybe<ModelIntInput>;
  not?: InputMaybe<ModelPartnerConfigFilterInput>;
  numberIterations?: InputMaybe<ModelIntInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPartnerConfigFilterInput>>>;
  outputPercentile?: InputMaybe<ModelIntInput>;
  partnerName?: InputMaybe<ModelIDInput>;
  replacementRate?: InputMaybe<ModelFloatInput>;
}

export interface ModelPartnerGoalFilterInput {
  and?: InputMaybe<Array<InputMaybe<ModelPartnerGoalFilterInput>>>;
  externalRef?: InputMaybe<ModelStringInput>;
  goalConfigId?: InputMaybe<ModelIDInput>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelPartnerGoalFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPartnerGoalFilterInput>>>;
  partnerConfigId?: InputMaybe<ModelIDInput>;
}

export interface ModelPartnerInvestmentProfilesConfigFilterInput {
  and?: InputMaybe<Array<InputMaybe<ModelPartnerInvestmentProfilesConfigFilterInput>>>;
  expectedAnnualReturn?: InputMaybe<ModelFloatInput>;
  expectedVolatility?: InputMaybe<ModelFloatInput>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelPartnerInvestmentProfilesConfigFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPartnerInvestmentProfilesConfigFilterInput>>>;
  partnerConfigId?: InputMaybe<ModelIDInput>;
  riskRanking?: InputMaybe<ModelIntInput>;
  title?: InputMaybe<ModelStringInput>;
}

export interface ModelSizeInput {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
}

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface ModelStringInput {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<ModelSizeInput>;
}

export interface ModelStringKeyConditionInput {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
}

export interface ModelTransactionFilterInput {
  accountId?: InputMaybe<ModelIDInput>;
  amount?: InputMaybe<ModelFloatInput>;
  and?: InputMaybe<Array<InputMaybe<ModelTransactionFilterInput>>>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelTransactionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTransactionFilterInput>>>;
  transactionType?: InputMaybe<ModelTransactionTypeInput>;
}

export interface ModelTransactionTypeInput {
  eq?: InputMaybe<TransactionType>;
  ne?: InputMaybe<TransactionType>;
}

export interface ModelUserFilterInput {
  and?: InputMaybe<Array<InputMaybe<ModelUserFilterInput>>>;
  annualIncome?: InputMaybe<ModelIntInput>;
  dateOfBirth?: InputMaybe<ModelStringInput>;
  externalId?: InputMaybe<ModelIDInput>;
  id?: InputMaybe<ModelIDInput>;
  not?: InputMaybe<ModelUserFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserFilterInput>>>;
  partnerId?: InputMaybe<ModelIDInput>;
  retirementAge?: InputMaybe<ModelIntInput>;
  userStatus?: InputMaybe<ModelUserStatusInput>;
}

export interface ModelUserStatusInput {
  eq?: InputMaybe<UserStatus>;
  ne?: InputMaybe<UserStatus>;
}

export enum Month {
  APRIL = 'APRIL',
  AUGUST = 'AUGUST',
  DECEMBER = 'DECEMBER',
  FEBRUARY = 'FEBRUARY',
  JANUARY = 'JANUARY',
  JULY = 'JULY',
  JUNE = 'JUNE',
  MARCH = 'MARCH',
  MAY = 'MAY',
  NOVEMBER = 'NOVEMBER',
  OCTOBER = 'OCTOBER',
  SEPTEMBER = 'SEPTEMBER'
}

export interface NewAccountInfoInput {
  balance: Scalars['Float'];
  externalAccountId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
}

export interface NewCliffGoalInput {
  branchId?: InputMaybe<Scalars['String']>;
  managedProductType: ManagedProductType;
  partnerGoalExternalRef: Scalars['String'];
  targetBalance?: InputMaybe<Scalars['Float']>;
  targetMonth?: InputMaybe<Month>;
  targetYear?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
}

export interface NewPartnerInput {
  currencyCode: CurrencyCode;
  goalProjectionsStatus: Scalars['AWSJSON'];
  inflationAnnualIncome: Scalars['Float'];
  inflationMonth: Month;
  inflationRate: Scalars['Float'];
  lifeExpectancy: Scalars['Int'];
  numberIterations: Scalars['Int'];
  outputPercentile: Scalars['Int'];
  partnerName: Scalars['ID'];
  replacementRate: Scalars['Float'];
}

export interface NewRetirementGoalInput {
  branchId?: InputMaybe<Scalars['String']>;
  managedProductType: ManagedProductType;
  partnerGoalExternalRef: Scalars['String'];
  targetSpending?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
  user?: InputMaybe<UserInfo>;
}

export interface PartnerSettingsInput {
  partnerId: Scalars['ID'];
}

export enum RetirementTargetType {
  SAVINGS = 'SAVINGS',
  SPENDING = 'SPENDING'
}

export enum TransactionType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export interface UnassociatePartialAccountFromGoal {
  partialId: Scalars['String'];
}

export interface UnlinkAccountGoalInput {
  accountId: Scalars['ID'];
  goalId: Scalars['ID'];
}

export interface UpdateAssociatedAccountInput {
  accountId: Scalars['ID'];
  accountTitle?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
  balance?: InputMaybe<Scalars['Float']>;
  contributionFrequency: CashFlowSourceFrequency;
  goalId: Scalars['ID'];
  partnerInvestmentProfileConfigId: Scalars['ID'];
}

export interface UpdateCliffGoalDetailsInput {
  goalId: Scalars['ID'];
  targetBalance: Scalars['Float'];
  targetMonth: Month;
  targetYear: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
}

export interface UpdateGoalInput {
  balanceOnCompletion?: InputMaybe<Scalars['Float']>;
  completedEndDate?: InputMaybe<Scalars['AWSDate']>;
  createdBy?: InputMaybe<Scalars['ID']>;
  goalProjection?: InputMaybe<Scalars['AWSJSON']>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalType?: InputMaybe<GoalType>;
  id: Scalars['ID'];
  partnerConfigId?: InputMaybe<Scalars['ID']>;
  partnerGoalExternalRef?: InputMaybe<Scalars['String']>;
  sharedWith?: InputMaybe<Array<Scalars['ID']>>;
  targetBalance?: InputMaybe<Scalars['Float']>;
  targetEndDate?: InputMaybe<Scalars['AWSDate']>;
  targetSpending?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
}

export interface UpdateGoalTitleInput {
  goalId: Scalars['ID'];
  title: Scalars['String'];
}

export interface UpdateMaxOAS {
  amount?: InputMaybe<Scalars['Float']>;
  partnerId: Scalars['String'];
}

export interface UpdateRetirementGoalInput {
  cashFlowSources?: InputMaybe<Array<CashFlowSourceInfo>>;
  goalId: Scalars['ID'];
  targetSpending?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserInfo>;
}

export interface UpsertAccountInfoInput {
  balance: Scalars['Float'];
  copilotPortfolioUlid: Scalars['String'];
  externalId: Scalars['String'];
  title: Scalars['String'];
}

export interface UserInfo {
  annualIncome: Scalars['Int'];
  retirementAge: Scalars['Int'];
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
