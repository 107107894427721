import {
  GetOpsDashboardContent,
  GetOpsDashboardContent_all_account_type_items,
  GetOpsDashboardContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type OpsDashboardAccountTypes = GetOpsDashboardContent_all_account_type_items;

export const useGetOpsDashboardContent = (
  options?: QueryHookOptions<GetOpsDashboardContent, GetOpsDashboardContentVariables>,
): QueryResult<GetOpsDashboardContent, GetOpsDashboardContentVariables> => {
  return useContentstackQuery(queries.GetOpsDashboardContent, options);
};
