import {
  GetClosureTableContent,
  GetClosureTableContent_all_closures_table_items_flag_referenceConnection_edges_node,
  GetClosureTableContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type ClosuresFlagReferenceContent = GetClosureTableContent_all_closures_table_items_flag_referenceConnection_edges_node;

export const useGetClosureTableContent = (
  options?: QueryHookOptions<GetClosureTableContent, GetClosureTableContentVariables>,
): QueryResult<GetClosureTableContent, GetClosureTableContentVariables> => {
  return useContentstackQuery(queries.GetClosureTableContent, options);
};
