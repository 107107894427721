import React, { AnchorHTMLAttributes, ComponentProps, ComponentType, ReactNode } from 'react';

import { htmlToReact } from '../utils/transformers';

import { Box, BoxProps } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';

export interface Props extends BoxProps {
  anchorComponent?: ComponentType<AnchorHTMLAttributes<HTMLAnchorElement>>;
  className?: string;
  config?: Record<string, ReactNode>;
  data: string;
  dataQa?: string;
  defaultBodyVariant?: ComponentProps<typeof Typography>['variant'];
  gutterBottom?: boolean; // Adds a margin below all paragraph breaks. Useful for multi-paragraph content.
  variantMapping?: ComponentProps<typeof Typography>['variantMapping'] | string;
}

/**
 * The RteContent component takes in output from the Redactor RTE and renders a React component with the appropriate formatting.
 * An optional `config` object can also be passed if the Redactor output contains variable content.
 * Also accepts an optional `variantMapping` prop that can be used to customize the heading component that gets rendered.
 * Essentially a component wrapper around the htmlToReact function.
 *
 * @param props - Component props.
 * @param props.anchorComponent - The JSX anchor component to render.
 * @param props.config - Variable replacement configuration.
 * @param props.data - The content string to render.
 * @param props.defaultBodyVariant - MUI Typography variant to set default variant for body instruction in html-to-react
 * @param props.gutterBottom - If `true`, the text will have a bottom margin.
 * @param props.variantMapping - The MUI Typography's `variantMapping` object or a string. Using type string will force
 * all variants to render as the string provided.
 * @returns The output markup.
 */
export const RteContent: React.FC<Props> = ({
  anchorComponent,
  className,
  config,
  data,
  dataQa = 'rte-content',
  defaultBodyVariant,
  gutterBottom,
  variantMapping,
  ...otherProps
}) => {
  const variants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'inherit'] as const;
  const mapping =
    typeof variantMapping === 'string'
      ? variants.reduce<NonNullable<ComponentProps<typeof Typography>['variantMapping']>>((acc, variant) => {
          acc[variant] = variantMapping;
          return acc;
        }, {})
      : variantMapping;

  return (
    // Spread otherProps in case they are passed in from another source (ex: Tooltip adding props related to accessibility)
    <Box className={className} data-qa={dataQa} {...otherProps}>
      {htmlToReact(data, config, mapping, anchorComponent, gutterBottom, defaultBodyVariant)}
    </Box>
  );
};
