import {
  GetRestrictionsHistory_managedProduct_multiRestrictionHistory_multiRestrictions,
  GetRestrictionsHistoryQuery,
  GetRestrictionsHistoryVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type RestrictionHistory = GetRestrictionsHistory_managedProduct_multiRestrictionHistory_multiRestrictions;

export const useGetRestrictionsHistoryData = (
  options?: QueryHookOptions<GetRestrictionsHistoryQuery, GetRestrictionsHistoryVariables>,
): QueryResult<GetRestrictionsHistoryQuery, GetRestrictionsHistoryVariables> => {
  return useSymphonyQuery(queries.GetRestrictionsHistory, options);
};
