import { ComponentProps } from 'react';

import { CashTransfersV2Flag } from './common/CashTransfer/symphony';
import { CommentData } from './common/Comment';

import { SuspensionType, TransferFrequency, TransferType } from '~/__generated__/symphonyTypes.v2';
import { AppliedFilters } from '~/components/AppliedFilters';
import { Filters } from '~/components/Filters';
import { AccountState } from '~/utils/account';
import { ContentstackTableColumn } from '~/utils/table';

export const TOO_MANY_SEARCH_RECORDS_ERROR = 'Too many parties found';

export enum SearchContextLiterals {
  NO_ENTRIES = 'no_entries_found',
  RECORDS_PER_PAGE_LABEL = 'records_per_page_label',
  RESULT_LIMIT_TERROR = 'too_many_results_error',
  SEARCH_ALL_ACCOUNTS = 'search_all_accounts',
  SEARCH_BANK_VERIFICATIONS = 'search_bank_verifications',
  SEARCH_CLOSURES = 'search_closures',
  SEARCH_DEPOSITS = 'search_deposits',
  SEARCH_DOCUSIGN = 'search_docusign',
  SEARCH_ERRORS = 'search_errors',
  SEARCH_ERROR_LABEL = 'search_length_error_label',
  SEARCH_HELPER_LABEL = 'search_length_helper_label',
  SEARCH_RECURRING_CANCELLATIONS = 'search_recurring_cancellations',
  SEARCH_RESTRICTIONS = 'search_restrictions',
  SEARCH_TRADING_SUSPENSIONS = 'search_trading_suspensions',
  SEARCH_WITHDRAWALS = 'search_withdrawals',
  TOTAL_ITEMS_LABEL = 'total_items_label',
  TOTAL_SEARCH_ITEMS_LABEL = 'total_search_items_label',
}

export enum TransferStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum TransferStatusFilter {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  PENDING_IN_PROGRESS = 'PENDING_IN_PROGRESS',
}

export enum FrequencyFilter {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export enum UrgencyFilter {
  ESCALATED = 'ESCALATED',
  NOT_ESCALATED = 'NOT_ESCALATED',
}

export type GrossUpValues = {
  no: string;
  yes: string;
};

export type DistributionReasonValues = {
  normal: string;
  premature: string;
};

export type TaxInformation = {
  distributionReason?: string;
  federalTax?: string;
  grossUp?: boolean;
  stateTax?: string;
};

export type TaxWithholdingInfoContent = {
  distributionReason: string;
  distributionReasonValues: DistributionReasonValues;
  federalTax: string;
  grossUp: string;
  grossUpValues: GrossUpValues;
  stateTax: string;
};

export type TransferItem = {
  accountNumber: string;
  accountState: AccountState;
  accountTypeText: string;
  amount: number;
  clientName: string;
  createdAt: string;
  destinationBankAccount: string;
  flags: CashTransfersV2Flag[];
  frequency: TransferFrequency;
  id: string;
  isManagedProductTaxSheltered?: boolean;
  lastCommentData?: Omit<CommentData, 'lastEditUserName'>;
  lastCommentPartyId?: string;
  managedProductId: string;
  notes?: string;
  numberOfOccurrences?: number;
  partyId: string;
  productName: string;
  rmdPlanId?: number;
  scheduledDate?: string;
  settlementDate?: string;
  status: TransferStatus;
  taxInformation?: TaxInformation;
  transferType: TransferType;
};

export interface TransferStatusUpdateItem {
  item: TransferItem;
  newStatus: TransferStatus;
}

export enum RequestTabs {
  AllOtherRequests = 'AllOtherRequests',
  FlaggedRequests = 'FlaggedRequests',
}
export type TradingSuspensionsTableContent = {
  accountNumber: JSX.Element;
  clientName: JSX.Element;
  comment: JSX.Element;
  created: string;
  removeSuspensionCta: JSX.Element;
  rowKey: string;
  suspendedBy: string;
  suspensionTag: JSX.Element;
  suspensionType: string;
};

export type TradingSuspensionsContent = {
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  columns: (ContentstackTableColumn | null)[] | undefined | null;
  emptySuspensionTagLabel: string;
  filterConfig: ComponentProps<typeof Filters>['filters'];
  filtersContent: ComponentProps<typeof Filters>['content'];
  groupSuspensionToggleLabel: string;
  opsPartnerSuspensionToggleLabel: string;
  removeSuspensionCta: string;
  removeSuspensionFailure: string;
  removeSuspensionSuccess: string;
};

export interface SuspensionDisplayData {
  created?: string;
  restrictionPlacedBy?: string;
  restrictionPlacedByUser?: string;
}

export interface RemoveSuspensionData {
  data: SuspensionDisplayData;
  managedProductId: string;
  suspensionTag: string;
  suspensionType: SuspensionType;
}

export enum SidebarState {
  COLLAPSED = 'COLLAPSED',
  OPEN = 'OPEN',
}

export type AccountsTabContent = {
  columns: (ContentstackTableColumn | null)[] | undefined | null;
};

export type AllAccountsTabContent = {
  accountNumber: JSX.Element;
  accountValue: string;
  clientName: JSX.Element;
  createdAt: string;
  email: string;
  rowKey: string;
  status: JSX.Element;
};

export type RestrictionsTableContent = {
  accountNumber: JSX.Element;
  accountStatus: string;
  actions: JSX.Element;
  clientName: JSX.Element;
  comment: JSX.Element;
  createdAt: string;
  createdBy: JSX.Element;
  rowKey: string;
  type: string;
};

export type RestrictionsContent = {
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  columns: (ContentstackTableColumn | null)[] | undefined | null;
  emptySuspensionTagLabel: string;
  filterConfig: ComponentProps<typeof Filters>['filters'];
  filtersContent: ComponentProps<typeof Filters>['content'];
  groupSuspensionToggleLabel: string;
  opsPartnerSuspensionToggleLabel: string;
  removeSuspensionCta: string;
  removeSuspensionFailure: string;
  removeSuspensionSuccess: string;
};
