import {
  GetOpsDashboardFundingTableContent,
  GetOpsDashboardFundingTableContent_all_ops_dashboard_funding_table_items_flag_referenceConnection_edges_node,
  GetOpsDashboardFundingTableContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type FundingTabFlagItemsContent = GetOpsDashboardFundingTableContent_all_ops_dashboard_funding_table_items_flag_referenceConnection_edges_node;

export const useGetOpsDashboardFundingTableContent = (
  options?: QueryHookOptions<GetOpsDashboardFundingTableContent, GetOpsDashboardFundingTableContentVariables>,
): QueryResult<GetOpsDashboardFundingTableContent, GetOpsDashboardFundingTableContentVariables> => {
  return useContentstackQuery(queries.GetOpsDashboardFundingTableContent, options);
};
