import {
  GetGroupedTradingSuspensions,
  GetGroupedTradingSuspensions_managedProducts_products,
  GetGroupedTradingSuspensions_managedProducts_products_tradingSuspensions,
  GetGroupedTradingSuspensionsVariables,
} from './__generated__/query.v2';
import {
  GetTradingSuspensions,
  GetTradingSuspensions_tradingSuspensions_tradingSuspensions,
  GetTradingSuspensions_tradingSuspensions_tradingSuspensions_managedProduct,
  GetTradingSuspensionsVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type TradingSuspensionManagedProduct = GetTradingSuspensions_tradingSuspensions_tradingSuspensions_managedProduct;
export type TradingSuspensions = GetTradingSuspensions_tradingSuspensions_tradingSuspensions;
export type GroupedTradingSuspensions = GetGroupedTradingSuspensions_managedProducts_products_tradingSuspensions;
export type GroupedTradingSuspensionManagedProduct = GetGroupedTradingSuspensions_managedProducts_products;

export const useGetTradingSuspensions = (
  options?: QueryHookOptions<GetTradingSuspensions, GetTradingSuspensionsVariables>,
): QueryResult<GetTradingSuspensions, GetTradingSuspensionsVariables> => {
  return useSymphonyQuery(queries.GetTradingSuspensions, options);
};

export const useGetGroupedTradingSuspensions = (
  options?: QueryHookOptions<GetGroupedTradingSuspensions, GetGroupedTradingSuspensionsVariables>,
): QueryResult<GetGroupedTradingSuspensions, GetGroupedTradingSuspensionsVariables> => {
  return useSymphonyQuery(queries.GetGroupedTradingSuspensions, options);
};
