import React from 'react';

import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { EmailIcon, Grid, PersonIcon, PhoneIcon, Skeleton, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useClientInfo } from '~/hooks/client/useClientInfo';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  clientLabel?: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  hideIcons?: boolean;
  partyId: string;
  showDivider?: boolean;
  showLabel?: boolean;
  showNameAsText?: boolean;
  showPhoneNumber?: boolean;
}

export const ClientInfo: React.FC<Props> = ({
  contentOptions,
  dataQa = 'client-info',
  partyId,
  showPhoneNumber = false,
  showNameAsText = false,
  hideIcons = false,
  clientLabel,
  showLabel,
  showDivider,
}) => {
  const {
    sfClientInfo: { root: sfClientInfoRoot, styles, typographyVariants },
  } = useTheme();
  const { data, loading, error } = useClientInfo({ variables: { partyId } });

  return (
    <Grid container data-qa={dataQa} justifyContent="center" sx={sfClientInfoRoot}>
      <Grid item xs={12}>
        {error && <Alert contentOptions={contentOptions} error={error} severity="error" />}

        {showLabel && (
          <Grid item sx={{ display: 'flex', mb: 1 }}>
            <Typography color="text.secondary" variant="caption">
              {clientLabel}
            </Typography>
          </Grid>
        )}

        {loading ? (
          <Skeleton width="50%" />
        ) : (
          <Grid item sx={{ display: 'flex' }}>
            {showNameAsText && !hideIcons && <PersonIcon sx={{ mr: 1 }} />}
            <Typography
              component="h1"
              sx={showNameAsText ? styles.text : styles.header}
              variant={showNameAsText ? typographyVariants?.text : typographyVariants?.header}
            >
              {data?.fullName}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={5} xs={12}>
        {(loading || data?.email) && (
          <Grid item sx={{ display: 'flex' }}>
            {!hideIcons && <EmailIcon sx={{ mr: 1 }} />}
            {loading && <Skeleton width={50} />}
            <Typography variant={typographyVariants?.email}>{data?.email}</Typography>
          </Grid>
        )}
        {(loading || data?.phone) && showPhoneNumber && (
          <Grid item sx={{ display: 'flex' }}>
            {showDivider && <Divider orientation="vertical" sx={{ mr: 3 }} />}
            {!hideIcons && <PhoneIcon sx={{ mr: 1 }} />}
            {loading && <Skeleton width={50} />}
            <Typography variant={typographyVariants?.phone}>{data?.phone}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
