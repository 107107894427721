export interface DismissRmdConfirmationModalContent {
  dismissRmdModal: {
    message: string;
    primaryCta: string;
    secondaryCta: string;
    title: string;
  };
  requirementFulfilledRmdModal: {
    message: string;
    primaryCta: string;
    secondaryCta: string;
    subMessage: string;
    title: string;
  };
}

export interface RmdDetails {
  daysTillDeadline: number;
  deadline: string;
  hasCompleteInformation: boolean;
  hasNoInformation: boolean;
  remainingRmdAmountToBeWithdrawn: number;
  totalAmountWithdrawnYtd: number;
  totalRmdAmountToBeWithdrawn: number;
  totalScheduledWithdrawalAmount: number;
  yearOfRmd: number;
}

export enum RmdStatus {
  COMPLETED = 'COMPLETED',
  INELIGIBLE_IRA = 'INELIGIBLE_IRA',
  LESS_THAN_31_DAYS = 'LESS_THAN_31_DAYS',
  LESS_THAN_31_DAYS_PARTIAL = 'LESS_THAN_31_DAYS_PARTIAL',
  MORE_THAN_30_DAYS = 'MORE_THAN_30_DAYS',
  MORE_THAN_30_DAYS_MULTIPLE = 'MORE_THAN_30_DAYS_MULTIPLE',
  NO_DATA = 'NO_DATA',
  NO_RMD = 'NO_RMD',
  PARTIAL_DATA_AVAILABLE = 'PARTIAL_DATA_AVAILABLE',
  PARTIAL_DATA_COMPLETED = 'PARTIAL_DATA_COMPLETED',
}

export interface RmdData {
  eligibleIraAccountIds: string[];
  eligibleIraAccountIdsWithInformation: string[];
  hasIneligibleIraAccounts: boolean;
  ineligibleIraAccountIds: string[];
  isRefetchingRmdData: boolean;
  onDismissRmd: () => void;
  refetch: () => void;
  rmdAmounts: RmdDetails[];
  rmdStatus: RmdStatus;
}

export interface RmdNotificationContent extends DismissRmdConfirmationModalContent {
  completedHeader: string;
  completedText: string;
  ctas: {
    dismissRmdNotification: string;
    requirementFulfilled: string;
    setupWithdrawal: string;
  };
  extendedHeader: string;
  extendedText: string;
  header: string;
  ineligibleIRAHeader: string;
  ineligibleIRAText: string;
  lessThan31DaysHeader: string;
  lessThan31DaysText: string;
  moreThan30DaysText: string;
  noDataText: string;
  partialDataAvailableText: string;
  partialDataCompletedText: string;
}
