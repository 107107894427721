import { useEffect, useState } from 'react';

import { useGetRiskPreferenceHistoryModalContent } from '../contentstack';
import {
  GetRiskPreferenceHistoryModalContent,
  GetRiskPreferenceHistoryModalContent_all_rtq_history_modal_items_column,
} from '../contentstack/__generated__/query.v2';
import { useGetRiskPreferenceHistoryData } from '../symphony';
import { GetRiskPreferenceHistoryData } from '../symphony/__generated__/query.v2';
import { getCreatedByRoleText } from '../utils';

import { TableColumn } from '~/components/ui/BasicTable';
import { ContentOptions } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';
import { AsyncResult } from '~/utils/types';

export interface RiskPreferenceHistoryVariables {
  accountId: string | null;
  contentOptions: ContentOptions;
  managedProductId: string;
  open: boolean;
}

export interface FormattedRiskPreferenceHistoryData {
  date: string;
  isOverride: string;
  raisedBy: string;
  value: number;
}

export interface RiskPreferenceHistoryData {
  formattedRiskPreferenceHistoryDataValues: FormattedRiskPreferenceHistoryData[];
  primaryCTA: string;
  tableColumns: TableColumn[];
  title: string;
}

export const useRiskPreferenceHistoryData = ({
  contentOptions,
  open,
  managedProductId,
  accountId,
}: RiskPreferenceHistoryVariables): AsyncResult<RiskPreferenceHistoryData> => {
  const [state, setState] = useState<AsyncResult<RiskPreferenceHistoryData>>({ loading: true });
  const {
    data: modalContentData,
    loading: modalContentLoading,
    error: modalContentError,
  } = useGetRiskPreferenceHistoryModalContent({
    variables: contentOptions,
    skip: !open,
  });

  const {
    data: riskPreferenceHistoryData,
    loading: riskPreferenceHistoryDataLoading,
    error: riskPreferenceHistoryDataError,
  } = useGetRiskPreferenceHistoryData({
    variables: { managedProductId },
    skip: !open,
  });

  useEffect(() => {
    if (modalContentError || riskPreferenceHistoryDataError) {
      setState({
        error: modalContentError || riskPreferenceHistoryDataError,
        loading: false,
      });
    }
  }, [modalContentError, riskPreferenceHistoryDataError]);

  useEffect(() => {
    if (modalContentData && !modalContentLoading && riskPreferenceHistoryData && !riskPreferenceHistoryDataLoading) {
      const modalContent = modalContentData.all_rtq_history_modal?.items?.[0]?.modal ?? null;
      const formattedRiskPreferenceHistoryDataValues = formattedDataValues(riskPreferenceHistoryData, modalContentData);
      const tableColumns: TableColumn[] = modalContentData.all_rtq_history_modal?.items?.[0]?.column?.map(
        (col: GetRiskPreferenceHistoryModalContent_all_rtq_history_modal_items_column | null) => ({
          key: col?.column_id ?? '',
          title: col?.column_label ?? '',
        }),
      ) as TableColumn[];
      setState({
        data: {
          primaryCTA: modalContent?.primary_cta ?? '',
          title: `${modalContent?.title ?? ''} ${accountId}`,
          tableColumns,
          formattedRiskPreferenceHistoryDataValues,
        },
        loading: false,
      });
    }
  }, [modalContentData, modalContentLoading, riskPreferenceHistoryData, riskPreferenceHistoryDataLoading]);

  return state;
};

const formattedDataValues = (
  data: GetRiskPreferenceHistoryData,
  contentData: GetRiskPreferenceHistoryModalContent,
): FormattedRiskPreferenceHistoryData[] => {
  return (
    data?.managedProduct?.riskPreferenceHistory?.map(risk => ({
      value: risk?.value,
      date: risk?.date ? formatDate(risk?.date, 'MM/dd/yyyy') : '',
      isOverride: risk?.isOverride ? 'True' : 'False',
      raisedBy: getCreatedByRoleText(risk?.createdByRole, contentData.all_role_type?.items || []),
    })) ?? []
  );
};
