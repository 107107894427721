import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

import { InputLabel, MuiOutlinedTextFieldProps, Stack } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { Typography } from '~/components/ui/Typography';

export type NumberRange = { maxValue: number; minValue: number };

export interface Props extends Omit<MuiOutlinedTextFieldProps, 'variant' | 'onChange' | 'onError'> {
  dataQa?: string;
  defaultValues?: NumberRange;
  labels?: { maxValue: string; minValue: string };
  maxInputValue?: number;
  onChange: (numberRange: NumberRange) => void;
  onError: (numberRange?: NumberRange) => void;
}

export const NumberFormatRange: React.FC<Props> = ({
  dataQa = 'number-format-range',
  defaultValues,
  labels,
  maxInputValue,
  onChange,
  onError,
}) => {
  const [maxValue, setMaxValue] = useState(defaultValues?.maxValue);
  const [minValue, setMinValue] = useState(defaultValues?.minValue);
  const [error, setError] = useState(false);

  const isAllowed = (value: string) => (maxInputValue ? maxInputValue >= parseInt(value, 10) : true);

  const handleValueChange = (data: NumberRange) => {
    setMinValue(data.minValue);
    setMaxValue(data.maxValue);

    if (data.maxValue < data.minValue) {
      setError(true);
      onError(data);
      return;
    }
    setError(false);
    onChange(data);
  };

  return (
    <Stack alignItems="center" data-qa={dataQa} direction="row" spacing={0}>
      <Stack display="inline-flex">
        <InputLabel>{labels?.minValue}</InputLabel>
        <NumberFormat
          customInput={TextField}
          decimalScale={0}
          defaultValue={minValue}
          error={error}
          fullWidth
          id="minValue"
          isAllowed={values => isAllowed(values.value)}
          onValueChange={values =>
            handleValueChange({ minValue: parseInt(values.value || '0', 10), maxValue: maxValue ?? 0 })
          }
          variant="outlined"
        />
      </Stack>
      <Typography sx={{ mx: 2 }} variant="body1">
        To
      </Typography>
      <Stack display="inline-flex">
        <InputLabel>{labels?.maxValue}</InputLabel>
        <NumberFormat
          InputLabelProps={{ shrink: true }}
          customInput={TextField}
          decimalScale={0}
          defaultValue={maxValue}
          error={error}
          fullWidth
          id="maxValue"
          isAllowed={values => isAllowed(values.value)}
          onValueChange={values =>
            handleValueChange({ minValue: minValue ?? 0, maxValue: parseInt(values.value || '0', 10) })
          }
          variant="outlined"
        />
      </Stack>
    </Stack>
  );
};
