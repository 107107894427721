import { QueryResult } from '@apollo/client';

import { GetFAClientLinkSuccessModalContent, GetFAClientLinkSuccessModalContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetFAClientLinkSuccessModalContent = (
  options?: QueryHookOptions<GetFAClientLinkSuccessModalContent, GetFAClientLinkSuccessModalContentVariables>,
): QueryResult<GetFAClientLinkSuccessModalContent, GetFAClientLinkSuccessModalContentVariables> => {
  return useContentstackQuery(queries.GetFAClientLinkSuccessModalContent, options);
};
