import React, { ComponentProps } from 'react';

import { OpsDashboardV2 } from '.';
import { CommentComponentColumn } from './common/Comment';
import { GetOpsDashboardContentV2 } from './contentstack/__generated__/query.v2';
import { OpsDashboardUser } from './utils';

import { FlagReason } from '~/__generated__';
import { ContentOptions } from '~/utils/contentstack';

export interface OpsDashboardContextValue {
  commentColumn?: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboardV2>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboardV2>['getClientNameRedirectUrl'];
  hiddenFlagReasons: FlagReason[];
  onAccountClick: ComponentProps<typeof OpsDashboardV2>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboardV2>['onClientClick'];
  onParamsUpdate: ComponentProps<typeof OpsDashboardV2>['onParamsUpdate'];
  opsContentData?: GetOpsDashboardContentV2;
  params: ComponentProps<typeof OpsDashboardV2>['params'];
}

const OpsDashboardContext = React.createContext<OpsDashboardContextValue>({} as OpsDashboardContextValue);

export const OpsDashboardProvider = OpsDashboardContext.Provider;
export default OpsDashboardContext;
