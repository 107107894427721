import React from 'react';

import { ManagedAccountLabel, RmdSummaryContent } from '../../modals/WithdrawFunds/types';

import { RmdDetails } from '~/components/Rmd/types';
import { Stack, TypographyVariant } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { formatCurrencyPrecise } from '~/utils/format';

export interface RmdSummaryData {
  eligibleIraAccountWithInformationLabels: string[];
  remainingRmdAmountToBeWithdrawn: number;
  rmdAmounts: {
    deadline: string;
    rmdAmountToBeWithdrawn: number;
  }[];
  sumOfScheduledWithdrawals: number;
  totalAmountWithdrawnYtd: number;
  totalIraAccounts: number;
  totalRmdAmountToBeWithdrawn: number;
}

export const formatRmdResponse = (
  data?: RmdDetails[],
  eligibleIraAccountWithInformationManagedProductIds?: string[],
  accountLabels?: ManagedAccountLabel[],
  numberOfEligibleAccounts?: number,
): RmdSummaryData => {
  const eligibleAccountsNames: string[] =
    eligibleIraAccountWithInformationManagedProductIds?.map(id =>
      (accountLabels?.find(account => account.managedProductId === id)?.label ?? '').toString(),
    ) ?? [];
  const response: RmdSummaryData = {
    eligibleIraAccountWithInformationLabels: eligibleAccountsNames,
    rmdAmounts: [],
    sumOfScheduledWithdrawals: 0,
    totalIraAccounts: numberOfEligibleAccounts ?? 0,
    totalAmountWithdrawnYtd: 0,
    remainingRmdAmountToBeWithdrawn: 0,
    totalRmdAmountToBeWithdrawn: 0,
  };

  (data ?? []).forEach(rmdDetail => {
    const {
      deadline,
      remainingRmdAmountToBeWithdrawn,
      totalAmountWithdrawnYtd,
      totalScheduledWithdrawalAmount,
      totalRmdAmountToBeWithdrawn,
    } = rmdDetail;

    response.rmdAmounts.push({
      deadline,
      rmdAmountToBeWithdrawn: remainingRmdAmountToBeWithdrawn,
    });
    response.sumOfScheduledWithdrawals += totalScheduledWithdrawalAmount;
    response.totalAmountWithdrawnYtd += totalAmountWithdrawnYtd;
    response.remainingRmdAmountToBeWithdrawn += remainingRmdAmountToBeWithdrawn;
    response.totalRmdAmountToBeWithdrawn += totalRmdAmountToBeWithdrawn;
  });

  return response;
};

export const amountWithDescription = (
  rteConfig: { config?: Record<string, React.ReactNode>; data: string },
  amount?: number,
  variant?: TypographyVariant,
) => (
  <Stack direction="row" justifyContent="space-between" width="100%">
    <RteContent defaultBodyVariant={variant ?? 'body2'} {...rteConfig} />
    {amount && <Typography variant={variant ?? 'body2'}>{formatCurrencyPrecise(amount)}</Typography>}
  </Stack>
);

export const getTotalAmountSection = (data: RmdSummaryData, content: RmdSummaryContent) => {
  if (data.rmdAmounts.length === 1) {
    return amountWithDescription(
      { data: content.totalRMDAmountWithDate, config: { deadline: data.rmdAmounts[0].deadline } },
      data.remainingRmdAmountToBeWithdrawn,
      'body1',
    );
  }
  return (
    <Stack spacing={2} sx={{ px: 2, mx: 2 }}>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2, backgroundColor: 'white' }}>
        <Typography variant="subtitle1">{content.totalRMDAmountWithoutDate}</Typography>
        <Typography variant="subtitle1">{formatCurrencyPrecise(data.remainingRmdAmountToBeWithdrawn)}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-around" spacing={2}>
        {data.rmdAmounts.map(({ deadline, rmdAmountToBeWithdrawn }) => (
          <Stack
            alignItems="center"
            key={rmdAmountToBeWithdrawn}
            spacing={1}
            sx={{ p: 1.5, backgroundColor: 'white' }}
            width="100%"
          >
            <Typography variant="subtitle1">{formatCurrencyPrecise(rmdAmountToBeWithdrawn)}</Typography>
            <RteContent config={{ deadline }} data={content.withdrawByWithDate} sx={{ color: 'text.secondary' }} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export const getAccountsString = (accounts: string[], joiningWord: string) => {
  if (accounts.length === 1) {
    return accounts[0];
  } else {
    const tempAccounts = [...accounts];
    const lastAccount = tempAccounts.pop();
    return `${tempAccounts.join(', ')} ${joiningWord} ${lastAccount}`;
  }
};
