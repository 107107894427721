import { parseISO } from 'date-fns';
import React, { ComponentProps } from 'react';

import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { getAccountNumberOrAccountTypeString } from '../../utils';
import { GetModelChangeTableContentV2 } from '../contentstack/__generated__/query.v2';
import { PendingModelChangesSymphonyData, PendingModelChangesTabContent, PendingModelChangesTabData } from '../hooks';
import { GetPendingModelsV2 } from '../symphony/__generated__/query.v2';

import { FinancialAccountType, ManagedProductType } from '~/__generated__';
import { Donut } from '~/components/ui/Donut';
import { Link } from '~/components/ui/Link';
import { Button, Grid, InfoOutlinedIcon, MuiTypography } from '~/components/ui/mui';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { GetClientInfo_client_party_partyPerson } from '~/hooks/client/symphony/__generated__/query.v2';
import { getAccountProgramText } from '~/utils/account';
import { AccountAttribute } from '~/utils/account/types';
import { getDonutSlicesFromDonutAllocations } from '~/utils/asset-allocation';
import { AssetClassTier, DonutAllocation } from '~/utils/asset-allocation/types';
import { getFullName } from '~/utils/client';
import { formatDate } from '~/utils/format/date';

export interface PendingModelPortfolio {
  assetsAllocation: DonutAllocation[];
  description: string;
  name: string;
  submitted_date: string;
}

export interface PendingModalContent {
  modalType: 'approve' | 'discard' | 'modelChange';
  rowData: PendingModelChangeRow;
}

export interface PendingModelChangeRow {
  comment?: {
    lastEditDate: string;
    lastEditTime: string;
    lastValue: string;
  };
  isResolved: boolean;
  lastCommentPartyId?: string;
  managedProduct: {
    accountType?: FinancialAccountType | null;
    attributes: AccountAttribute[];
    clientParty: {
      id?: string | null;
      partyPerson?: GetClientInfo_client_party_partyPerson;
    };
    financialAccountNumber?: string | null;
    program: ManagedProductType;
  };
  managedProductId: string;
  pendingModelDetails: PendingModelPortfolio;
  planId: string;
  planUpdateWorkFlowId: string;
}

export interface GetTableContent {
  assetClassTier?: AssetClassTier;
  data: PendingModelChangesTabData;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  handleOpenModal: (data: PendingModalContent) => void;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
}

/**
 * The function formatDataToRows will restructure the pending model changes data into the table data format required by BasicTable component.
 * @param {GetTableContent} - The function takes the data fetched from symphony and handlers for modals.
 * @returns {TableData[]} The function manipulates table data i.e. the pending model changes coming from symphony into a TableData[] structure to render it on BasicTable component.
 */

export const formatDataToRows = ({
  assetClassTier,
  data,
  handleOpenModal,
  onAccountClick,
  onClientClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
}: GetTableContent) =>
  data.data?.allPendingModels.map(row => ({
    rowKey: row.managedProductId,
    accountNumber: (
      <AccountNumber
        accountNumber={row.managedProduct.financialAccountNumber}
        accountNumberText={getAccountNumberOrAccountTypeString(
          row.managedProduct.financialAccountNumber || null,
          getAccountTypeText(
            row.managedProduct.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
            data.accountTypeContent ?? [],
          ),
        )}
        label={getAccountProgramText(
          row.managedProduct.program,
          row.managedProduct.attributes,
          data.productNameContent ?? [],
        )}
        onClick={() => onAccountClick(row.managedProduct.clientParty.id as string, row.managedProductId as string)}
        redirectUrl={
          row.managedProduct.clientParty.id
            ? getAccountNumberRedirectUrl(row.managedProduct.clientParty.id, row.managedProductId)
            : undefined
        }
      />
    ),
    clientName: (
      <ClientName
        clientName={getFullName(row.managedProduct.clientParty.partyPerson)}
        onClick={() => onClientClick(row.managedProduct.clientParty.id as string)}
        redirectUrl={
          row.managedProduct.clientParty.id ? getClientNameRedirectUrl(row.managedProduct.clientParty.id) : undefined
        }
      />
    ),
    pendingModelChange: (
      <Grid alignItems="center" columnSpacing={3} columns={12} container>
        <Grid item xs={2}>
          <Donut
            data={getDonutSlicesFromDonutAllocations(
              row.pendingModelDetails.assetsAllocation,
              data.assetClassContent ?? [],
              assetClassTier,
            )}
            innerRadius={16}
            size={80}
          />
        </Grid>
        <Grid item xs={10}>
          <MuiTypography variant="subtitle2">{row.pendingModelDetails.name}</MuiTypography>
          <Link
            color="inherit"
            onClick={() =>
              handleOpenModal({
                rowData: row,
                modalType: 'modelChange',
              })
            }
            sx={{ color: 'text.secondary' }}
            underline="none"
          >
            <MuiTypography sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} variant="caption">
              {data.content?.rowModalsLabels?.find(item => item.key === 'modelChange')?.label}{' '}
              <InfoOutlinedIcon fontSize="inherit" />
            </MuiTypography>
          </Link>
        </Grid>
      </Grid>
    ),
    submitted: <MuiTypography variant="subtitle2">{row.pendingModelDetails.submitted_date}</MuiTypography>,
    actions: (
      <Grid alignContent="center" container justifyContent="space-evenly">
        <Button
          onClick={() =>
            handleOpenModal({
              rowData: row,
              modalType: 'discard',
            })
          }
          variant="outlined"
        >
          {data.content?.rowModalsLabels?.find(item => item.key === 'discard')?.label}
        </Button>
        <Button
          onClick={() =>
            handleOpenModal({
              rowData: row,
              modalType: 'approve',
            })
          }
          variant="contained"
        >
          {data.content?.rowModalsLabels?.find(item => item.key === 'approve')?.label}
        </Button>
      </Grid>
    ),
  }));

/**
 * The function getPendingModelChangesContent will restructure the contentstack data asper the component's requirements.
 * @param {GetModelChangeTableContentV2} - The function takes the data fetched from contentstack.
 * @returns {PendingModelChangesTabContent} - Returns all contentstack dependent keys in an object.
 */

export const getPendingModelChangesContent = (content: GetModelChangeTableContentV2): PendingModelChangesTabContent => {
  const pendingModelTableContent = content.all_pending_model_change_table?.items?.[0];
  const questionnaire = content.all_questionnaire?.items?.[0]?.questions;

  return {
    rowModalsLabels: pendingModelTableContent?.row_labels
      ? pendingModelTableContent.row_labels.map(rowLabel => ({
          key: rowLabel?.key ?? '',
          label: rowLabel?.label ?? '',
        }))
      : [],

    modelChangeDetailsModalContent: {
      close: pendingModelTableContent?.model_change_details_modal?.close ?? '',
      header: pendingModelTableContent?.model_change_details_modal?.header ?? '',
      fields:
        pendingModelTableContent?.model_change_details_modal?.fields?.map(field => ({
          isMadlibs: field?.ismadlibs ?? false,
          key: field?.key ?? '',
          label: field?.label ?? '',
        })) ?? [],
      singleOptionsList: questionnaire
        ? questionnaire.map(ques => ({
            key: ques?.question?.key ?? '',
            options: ques?.question?.choices
              ? ques.question.choices.map(choice => ({
                  key: choice?.answer?.key ?? '',
                  value: choice?.answer?.label ?? '',
                }))
              : [],
          }))
        : [],
    },

    discardModalContent: {
      discardMessage: pendingModelTableContent?.discard_modal?.discard_message ?? '',
      successfulDiscardMessage: pendingModelTableContent?.discard_modal?.successful_discard_message ?? '',
      ctas: {
        close: pendingModelTableContent?.discard_modal?.cta?.find(el => el?.key === 'close')?.label ?? '',
        discard: pendingModelTableContent?.discard_modal?.cta?.find(el => el?.key === 'discard')?.label ?? '',
        cancel: pendingModelTableContent?.discard_modal?.cta?.find(el => el?.key === 'cancel')?.label ?? '',
      },
      title: pendingModelTableContent?.discard_modal?.title ?? '',
    },

    tableColumns: pendingModelTableContent?.columns
      ? pendingModelTableContent.columns.map(col => ({
          key: col?.column_id ?? '',
          title: col?.column_value ?? '',
        }))
      : [],
  };
};

/**
 * The function getPendingModelChangesData will restructure the symphony data as per the Pending Model Changes Tab data's requirements.
 * @param {GetPendingModelsV2} - The function takes the data fetched from symphony.
 * @returns {PendingModelChangesSymphonyData} - Returns symphony data in required format
 */
export const getPendingModelChangesData = (rawData: GetPendingModelsV2): PendingModelChangesSymphonyData => {
  const paginationContext = rawData.getPlanUpdateWorkflows.paginationContext;

  return {
    pagination: {
      limit: paginationContext.limit ?? 0,
      offset: paginationContext.offset ?? 0,
      total: paginationContext.total ?? 0,
    },

    allPendingModels: rawData.getPlanUpdateWorkflows.planUpdateWorkflows
      .filter(puw => puw.selectedModelPortfolios.length)
      .map(puw => {
        return {
          planId: puw.managedProduct.planId,
          planUpdateWorkFlowId: puw.id,
          isResolved: true,
          managedProduct: {
            attributes: puw.managedProduct.attributes,
            program: puw.managedProduct.program,
            accountType: puw.managedProduct.accountType,
            clientParty: {
              id: puw.managedProduct.relatedParties?.find(party => party.isInitialParty === true)?.party?.id ?? '',
              partyPerson:
                puw.managedProduct.relatedParties?.find(party => party.isInitialParty === true)?.party?.partyPerson ??
                undefined,
            },
            financialAccountNumber: puw.managedProduct.financialAccountNumber,
          },
          managedProductId: puw.managedProduct.id,
          pendingModelDetails: {
            // TODO: Add handler for MSI
            assetsAllocation:
              puw.selectedModelPortfolios[0]?.modelPortfolio?.guidance?.diversification?.assets.allocations ?? [],
            description: puw.selectedModelPortfolios[0]?.modelPortfolio?.description ?? '',
            name: puw.selectedModelPortfolios[0]?.modelPortfolio?.name ?? '',
            submitted_date: formatDate(parseISO(puw.createdAt)) ?? '',
          },
        };
      }),
  };
};
