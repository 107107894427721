import produce from 'immer';
import { useCallback, useMemo, useState } from 'react';

import { useGetRmdDetails, useUpdateRmdData } from '../symphony';
import { GetRmdDetails, GetRmdDetailsVariables } from '../symphony/__generated__/query.v2';
import * as queries from '../symphony/query.gql';
import { RmdData } from '../types';
import { getRmdAmountAndRmdStatus } from '../utils';

import { ApolloError } from '~/utils/apollo-client';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Props {
  contentOptions: ContentOptions;
  partyId: string;
}

export const useRmdData = ({ contentOptions, partyId }: Props): AsyncResult<RmdData> => {
  const {
    featureFlags: { rmdEnabled },
  } = useCoreConfig();
  const [isRefetchingRmdData, setIsRefetchingRmdData] = useState(false);
  const [refetchRmdDataError, setRefetchRmdDataError] = useState<ApolloError | undefined>();

  const { data: rmdData, loading: rmdDataLoading, error: rmdDataError, refetch } = useGetRmdDetails({
    variables: { partyId },
    skip: !rmdEnabled,
  });

  const [updateRmdData, { loading: updateRmdDataLoading, error: updateRmdDataError }] = useUpdateRmdData();

  const loading = useMemo(() => rmdDataLoading || updateRmdDataLoading, [rmdDataLoading, updateRmdDataLoading]);

  const error = useMemo(() => rmdDataError || updateRmdDataError || refetchRmdDataError, [
    rmdDataError,
    updateRmdDataError,
    refetchRmdDataError,
  ]);

  const onDismissRmd = useCallback(async () => {
    try {
      await updateRmdData({
        variables: {
          party_id: partyId,
          is_dismissed: true,
        },
        update: (cache, { data: updateRmdDataResponse }) => {
          const queryOptions = {
            query: queries.GetRmdDetails,
            variables: { partyId },
          };
          const rmdCache = cache.readQuery<GetRmdDetails, GetRmdDetailsVariables>(queryOptions);
          if (rmdCache && updateRmdDataResponse) {
            cache.writeQuery<GetRmdDetails>({
              ...queryOptions,
              data: produce(rmdCache, draft => {
                if (draft.client?.rmdData) {
                  draft.client.rmdData = updateRmdDataResponse.updateRmdData;
                }
              }),
            });
          }
        },
      });
    } catch (e) {
      console.error('Error dismissing RMD', e);
    }
  }, [partyId, updateRmdData]);

  const refetchRmdData = useCallback(async () => {
    try {
      setIsRefetchingRmdData(true);
      await refetch();
    } catch (e) {
      setRefetchRmdDataError(e as ApolloError);
    } finally {
      setIsRefetchingRmdData(false);
    }
  }, [refetch]);

  const data: RmdData | undefined = useMemo(() => {
    if (rmdEnabled && rmdData?.client?.rmdData) {
      return {
        ...getRmdAmountAndRmdStatus(
          rmdData.client.rmdData.activeRmdDetails,
          contentOptions,
          rmdData.client.rmdData.hasIneligibleIraManagedProducts,
          rmdData.client.rmdData.eligibleIraManagedProductIds,
        ),
        refetch: refetchRmdData,
        onDismissRmd,
        eligibleIraAccountIds: rmdData.client.rmdData.eligibleIraManagedProductIds,
        eligibleIraAccountIdsWithInformation: rmdData.client.rmdData.eligibleIraManagedProductIdsWithInformation,
        hasIneligibleIraAccounts: rmdData.client.rmdData.hasIneligibleIraManagedProducts,
        ineligibleIraAccountIds: rmdData.client.rmdData.ineligibleIraManagedProductIds,
        isRefetchingRmdData,
      };
    }
    return undefined;
  }, [contentOptions, onDismissRmd, refetchRmdData, rmdData?.client?.rmdData, rmdEnabled, isRefetchingRmdData]);

  return { data, loading, error };
};
