import {
  GetActiveTradingSuspensions,
  GetActiveTradingSuspensions_managedProduct_tradingSuspensions,
  GetActiveTradingSuspensionsVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type TradingSuspensions = GetActiveTradingSuspensions_managedProduct_tradingSuspensions;

export const useGetActiveTradingSuspensionsData = (
  options?: QueryHookOptions<GetActiveTradingSuspensions, GetActiveTradingSuspensionsVariables>,
): QueryResult<GetActiveTradingSuspensions, GetActiveTradingSuspensionsVariables> => {
  return useSymphonyQuery(queries.GetActiveTradingSuspensions, options);
};
