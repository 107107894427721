import * as React from 'react';

import { Box } from '~/components/ui/mui';

export interface Props {
  centered?: boolean;
  errorImage?: string;
}

export const GetErrorMessageImage: React.FC<Props> = ({ centered, errorImage }) => {
  return (
    <Box textAlign={centered ? 'center' : 'initial'}>
      {errorImage ? (
        <img alt="" src={errorImage} style={{ margin: 16 }} />
      ) : (
        <svg fill="none" height="100" viewBox="0 0 100 100" width="100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" fill="#FAFAFA" r="50" />
          <path
            d="M72.5 30H27.5C26.125 30 25 31.125 25 32.5V65C25 66.375 26.125 67.5 27.5 67.5H37.5V77.5C37.5 78.625 38.875 79.125 39.625 78.375L50.5 67.5H72.5C73.875 67.5 75 66.375 75 65V32.5C75 31.125 73.875 30 72.5 30Z"
            fill="#64B6F7"
          />
          <path
            d="M80 67.5C80 74.375 74.375 80 67.5 80C60.625 80 55 74.375 55 67.5C55 60.625 60.625 55 67.5 55C74.375 55 80 60.625 80 67.5Z"
            fill="#F44336"
          />
          <path
            d="M71.8752 62.2501L72.7502 63.1251C73.0002 63.3751 73.0002 63.7501 72.7502 64.0001L63.8752 72.8751C63.6252 73.1251 63.2502 73.1251 63.0002 72.8751L62.1252 72.0001C61.8752 71.7501 61.8752 71.3751 62.1252 71.1251L71.0002 62.2501C71.2502 62.0001 71.6252 62.0001 71.8752 62.2501Z"
            fill="white"
          />
          <path
            d="M72.7502 71.8752L71.8752 72.7502C71.6252 73.0002 71.2502 73.0002 71.0002 72.7502L62.1252 63.8752C61.8752 63.6252 61.8752 63.2502 62.1252 63.0002L63.0002 62.1252C63.2502 61.8752 63.6252 61.8752 63.8752 62.1252L72.7502 71.0002C73.0002 71.2502 73.0002 71.6252 72.7502 71.8752Z"
            fill="white"
          />
        </svg>
      )}
    </Box>
  );
};
