import { ComponentProps, useMemo } from 'react';

import { Props } from '..';
import { AccountTotalSection } from '../AccountTotalSection';
import {
  AccountListLabelsV2,
  AccountListSectionV2,
  AccountSummaryV2ContentFields,
  useGetAccountSummaryContentV2,
} from '../contentstack';
import { AccountSummaryV2ContentKeys } from '../types';
import { PendingChangeCardContent } from '../utils';

import { RmdNotificationContent } from '~/components/Rmd/types';
import { getRmdConfirmationModalContent } from '~/components/Rmd/utils';
import { findFieldValue } from '~/utils/contentstack';
import { AsyncResult, TextField } from '~/utils/types';

const getValueFromKey = (
  items: ({ key?: string | null; rtecontentvalue?: string | null; value?: string | null } | null)[],
  key: string,
): string => {
  const item = items.find(label => label?.key === key);
  return item?.rtecontentvalue ?? item?.value ?? '';
};
export interface AccountListContentV2 {
  accessibility: {
    accountMenuAccount: string;
    accountMenuAccountInProgress: string;
  };
  ctas: {
    bankAccount: string;
    journaling: string;
  };
  errorStates: { billingRestricted: string };
  pendingAccountProfileUpdateContent: PendingChangeCardContent;
  pendingAssetTransfersContent: PendingChangeCardContent;
  pendingBankAccountAssociationContent: PendingChangeCardContent;
  pendingJournalingUpdateContent: PendingChangeCardContent;
  textFields: (TextField | null)[] | null;
}

interface Data {
  rmdNotification: RmdNotificationContent;
  sectionContent: {
    accountList?: AccountListContentV2;
    accountTotal?: ComponentProps<typeof AccountTotalSection>['content'];
  };
}

type Variables = Pick<Props, 'contentOptions'>;

export const useAccountSummaryContentV2 = ({ contentOptions }: Variables): AsyncResult<Data> => {
  const { data: summaryV2ContentData, error, loading } = useGetAccountSummaryContentV2({
    variables: contentOptions,
  });

  const data = useMemo(() => {
    const accountList = summaryV2ContentData?.all_account_summary_v2?.items?.[0]?.sections?.[0]?.account_list;
    const fields = summaryV2ContentData?.all_account_summary_v2?.items?.[0]?.fields;
    return {
      rmdNotification: getRmdNotificationContent(fields),
      sectionContent: {
        accountList: getAccountListContentV2(accountList, fields),
        accountTotal: {
          headingCta: {
            switchToAccountBalance: findFieldValue(fields?.text, 'switchToAccountBalance'),
            switchToGoalsView: findFieldValue(fields?.text, 'switchToGoalsView'),
          },
        },
      },
    };
  }, [summaryV2ContentData?.all_account_summary_v2?.items]);

  return { data, error, loading };
};

const getRmdNotificationContent = (fields?: AccountSummaryV2ContentFields | null): RmdNotificationContent => {
  const textFields = fields?.text ?? [];
  const rteFields = fields?.rte ?? [];

  return {
    ...getRmdConfirmationModalContent(textFields),
    ctas: {
      dismissRmdNotification: findFieldValue(textFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_DISMISS_CTA),
      requirementFulfilled: findFieldValue(
        textFields,
        AccountSummaryV2ContentKeys.RMD_NOTIFICATION_REQUIREMENT_FULFILLED_CTA,
      ),
      setupWithdrawal: findFieldValue(textFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_SETUP_WITHDRAWAL_CTA),
    },
    header: findFieldValue(textFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_HEADER),
    completedHeader: findFieldValue(textFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_COMPLETED_HEADER),
    completedText: findFieldValue(textFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_COMPLETED),
    ineligibleIRAHeader: findFieldValue(textFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_INELIGIBLE_IRA_HEADER),
    ineligibleIRAText: findFieldValue(rteFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_INELIGIBLE_IRA),
    lessThan31DaysHeader: findFieldValue(
      rteFields,
      AccountSummaryV2ContentKeys.RMD_NOTIFICATION_LESS_THAN_31_DAYS_HEADER,
    ),
    lessThan31DaysText: findFieldValue(rteFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_LESS_THAN_31_DAYS),
    moreThan30DaysText: findFieldValue(rteFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_MORE_THAN_30_DAYS),
    extendedHeader: findFieldValue(textFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_EXTENDED_HEADER),
    extendedText: findFieldValue(rteFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_EXTENDED),
    noDataText: findFieldValue(rteFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_NO_DATA),
    partialDataAvailableText: findFieldValue(rteFields, AccountSummaryV2ContentKeys.RMD_NOTIFICATION_PARTIAL_DATA),
    partialDataCompletedText: findFieldValue(
      rteFields,
      AccountSummaryV2ContentKeys.RMD_NOTIFICATION_PARTIAL_DATA_COMPLETED,
    ),
  };
};

const getAccountListContentV2 = (
  accountListContent?: AccountListSectionV2 | null,
  fields?: AccountSummaryV2ContentFields | null,
): AccountListContentV2 => {
  const labels = accountListContent?.labels ?? [];
  const ctas = accountListContent?.ctas ?? [];
  const textFields = fields?.text ?? [];
  return {
    accessibility: {
      accountMenuAccount: findFieldValue(
        textFields,
        AccountSummaryV2ContentKeys.ACCESSIBILITY_ACCOUNT_LIST_ACCOUNT_MENU_ACCOUNT,
      ),
      accountMenuAccountInProgress: findFieldValue(
        textFields,
        AccountSummaryV2ContentKeys.ACCESSIBILITY_ACCOUNT_LIST_ACCOUNT_MENU_ACCOUNT_IN_PROGRESS,
      ),
    },
    ctas: {
      bankAccount: getValueFromKey(ctas, 'bankAccount'),
      journaling: getValueFromKey(ctas, 'journaling'),
    },
    errorStates: {
      billingRestricted:
        accountListContent?.error_states?.find(error => error?.key === 'billingRestricted')?.rtecontentvalue ?? '',
    },
    pendingAccountProfileUpdateContent: getPendingChangeCardContent(labels, 'pendingAccountProfileUpdate'),
    pendingAssetTransfersContent: getPendingChangeCardContent(labels, 'pendingAssetTransfersDocusign'),
    pendingBankAccountAssociationContent: getPendingChangeCardContent(labels, 'pendingBankAccountAssociation'),
    pendingJournalingUpdateContent: getPendingChangeCardContent(labels, 'pendingJournalingDocusign'),
    textFields,
  };
};

export const getPendingChangeCardContent = (
  labels: (AccountListLabelsV2 | null)[],
  key: string,
): PendingChangeCardContent => ({
  header: getValueFromKey(labels, key),
  message: getValueFromKey(labels, `${key}Message`),
  genericMessage: getValueFromKey(labels, `${key}GenericMessage`),
  cta: {
    approve: getValueFromKey(labels, 'approve'),
    resendDocusign: getValueFromKey(labels, 'resendDocusign'),
    signDocuments: getValueFromKey(labels, 'signDocuments'),
  },
  discardPendingChange: {
    title: getValueFromKey(labels, `${key}ModalTitle`),
    discardMessage: getValueFromKey(labels, `${key}ModalDiscardMessage`),
    successfulDiscardMessage: getValueFromKey(labels, `${key}ModalSuccessfulDiscard`),
    ctas: {
      cancel: getValueFromKey(labels, 'cancel'),
      close: getValueFromKey(labels, 'close'),
      discard: getValueFromKey(labels, 'discard'),
    },
  },
  resendEmailSentMessage: getValueFromKey(labels, 'resendEmailSentMessage'),
});
