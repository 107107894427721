import React, { ComponentProps, useEffect, useState } from 'react';

import { OpsDashboard } from '..';
import { CommentComponentColumn } from '../common/Comment';
import { OpsDashboardUser } from '../utils';

import { recordsPerPage, useGetPendingModelChangesTab } from './hooks';
import { formatDataToRows, PendingModalContent } from './utils';

import { PlanUpdateWorkflowStatus } from '~/__generated__';
import { ApproveModelChangeModal } from '~/components/modals/ApproveModelChange';
import { DiscardPendingChange } from '~/components/modals/DiscardPendingChange';
import { ModelChangeDetails } from '~/components/modals/ModelChangeDetails';
import { NullStateProps } from '~/components/NullState';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable, TableData } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Grid } from '~/components/ui/mui';
import { useUpdatePlanUpdateWorkflow } from '~/hooks/plan/symphony';
import { getAccountProgramText } from '~/utils/account';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { getFullName } from '~/utils/client';
import { ContentOptions } from '~/utils/contentstack/src/types';
import { concealAccountNumber } from '~/utils/format';

export interface Props {
  assetClassTier?: AssetClassTier;
  commentColumn: CommentComponentColumn;
  contentOptions: ContentOptions;
  currentUser: OpsDashboardUser;
  dataQa?: string;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  nullStateConfig?: NullStateProps;
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
  onUpdate: () => void;
}

export const PendingModelChangesTab: React.FC<Props> = ({
  assetClassTier,
  contentOptions,
  dataQa,
  onAccountClick,
  onClientClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
  nullStateConfig,
  onUpdate,
}) => {
  const [tableData, setTableData] = useState<TableData[] | null>();
  const [modalRowData, setModalRowData] = useState<PendingModalContent>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [updatePlanUpdateWorkflow] = useUpdatePlanUpdateWorkflow();

  useEffect(() => {
    if (modalRowData?.rowData) {
      if (modalRowData.modalType === 'approve') {
        openApprovePendingModelModal();
      }
      if (modalRowData.modalType === 'discard') {
        openDiscardModelChangeModal();
      }
      if (modalRowData.modalType === 'modelChange') {
        openModelChangeModal();
      }
    }
  }, [modalRowData]);

  const {
    open: isOpenModelChangeModal,
    openModal: openModelChangeModal,
    onClose: closeModelChangeModal,
  } = useModalState();

  const {
    open: isApproveModelChangeModalOpen,
    openModal: openApprovePendingModelModal,
    onClose: closeApprovePendingModelModal,
  } = useModalState();

  const {
    open: isOpenDiscardModelChangeModal,
    openModal: openDiscardModelChangeModal,
    onClose: closeDiscardModelChangesModal,
  } = useModalState();

  const { data, error, loading } = useGetPendingModelChangesTab({ contentOptions, pageNumber });

  const handleDiscard = async (planId: string, planUpdateWorkflowId: string) => {
    await updatePlanUpdateWorkflow({
      variables: {
        planId,
        planUpdateWorkflowId,
        status: PlanUpdateWorkflowStatus.CANCELLED,
      },
    });
  };

  const refetchItems = async () => {
    onUpdate();
    await data?.refetch?.();
    if (isApproveModelChangeModalOpen) {
      closeApprovePendingModelModal();
    } else {
      closeDiscardModelChangesModal();
    }
  };

  useEffect(() => {
    if (data?.data && data.content) {
      const treatedTableData = formatDataToRows({
        assetClassTier,
        data,
        handleOpenModal: setModalRowData,
        onAccountClick,
        onClientClick,
        getAccountNumberRedirectUrl,
        getClientNameRedirectUrl,
      });
      setTotalPages(Math.ceil((data.data.pagination.total ?? 0) / recordsPerPage));
      setTableData(treatedTableData);
    }
  }, [data]);

  return (
    <Grid container data-qa={`${dataQa}-container`} direction="column" spacing={3}>
      {(loading || error) && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading error items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      )}
      {data?.content?.tableColumns && (
        <Grid item xs={5}>
          {tableData && (
            <BasicTable
              alignItems="left"
              columns={data.content.tableColumns}
              currentPage={pageNumber}
              data={tableData}
              nullStateConfig={nullStateConfig}
              onPageChange={setPageNumber}
              showPagination
              totalPages={totalPages}
            />
          )}
          <ApproveModelChangeModal
            contentOptions={contentOptions}
            onApproveCallback={refetchItems}
            onClose={closeApprovePendingModelModal}
            open={isApproveModelChangeModalOpen}
            planId={modalRowData?.rowData.planId ?? ''}
            planUpdateWorkflowId={modalRowData?.rowData.planUpdateWorkFlowId ?? ''}
          />
          <DiscardPendingChange
            content={{
              title: data.content.discardModalContent?.title ?? '',
              discardMessage: data.content.discardModalContent?.discardMessage ?? '',
              ctas: {
                cancel: data.content.discardModalContent?.ctas.cancel ?? '',
                discard: data.content.discardModalContent?.ctas.discard ?? '',
                close: data.content.discardModalContent?.ctas.close ?? '',
              },
            }}
            contentOptions={contentOptions}
            onClose={closeDiscardModelChangesModal}
            onDiscard={() =>
              handleDiscard(modalRowData?.rowData.planId ?? '', modalRowData?.rowData.planUpdateWorkFlowId ?? '')
            }
            onDiscardCallback={refetchItems}
            open={isOpenDiscardModelChangeModal}
            showDiscardSuccessful={false}
          />
          {isOpenModelChangeModal && (
            <ModelChangeDetails
              accountNumber={concealAccountNumber(modalRowData?.rowData.managedProduct.financialAccountNumber ?? '')}
              assetClassTier={assetClassTier}
              clientName={getFullName(modalRowData?.rowData.managedProduct.clientParty.partyPerson) ?? ''}
              content={{
                close: data.content.modelChangeDetailsModalContent?.close ?? '',
                fields: data.content.modelChangeDetailsModalContent?.fields ?? [],
                header: data.content.modelChangeDetailsModalContent?.header ?? '',
                singleOptionsList: data.content.modelChangeDetailsModalContent?.singleOptionsList ?? [],
              }}
              contentOptions={contentOptions}
              managedProductId={modalRowData?.rowData.managedProductId ?? ''}
              onClose={closeModelChangeModal}
              open={isOpenModelChangeModal}
              partyId={modalRowData?.rowData.managedProduct.clientParty.id ?? ''}
              program={getAccountProgramText(
                modalRowData?.rowData.managedProduct.program,
                modalRowData?.rowData.managedProduct.attributes,
                data.productNameContent ?? [],
              )}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
