import React, { ComponentProps } from 'react';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { Box, Button } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';

export type Props = ComponentProps<typeof Modal> & {
  content?: {
    ctas: {
      cancel: string;
      delete: string;
    };
    errorMessage: string;
    header: string;
    subtitle: string;
    title: string;
  };
  contentOptions: ContentOptions;
  dataQa?: string;
  onDelete: () => void;
  onDeleteError: boolean;
};

export const DeleteComment: React.FC<Props> = ({
  onDelete,
  content,
  contentOptions,
  dataQa = 'delete-comment-modal',
  onDeleteError,
  open,
  ...modalProps
}) => {
  return (
    <Modal
      actions={
        <>
          <Button data-qa={`${dataQa}-close-cta`} onClick={modalProps.onClose as () => void} variant="outlined">
            {content?.ctas.cancel ?? ''}
          </Button>
          <Button data-qa={`${dataQa}-delete-cta`} onClick={onDelete} variant="contained">
            {content?.ctas.delete ?? ''}
          </Button>
        </>
      }
      content={
        <>
          {onDeleteError && (
            <Alert contentOptions={contentOptions} error={content?.errorMessage ?? ''} severity="error" />
          )}
          <Box sx={{ m: 4 }}>
            <Typography data-qa={`${dataQa}-body-title`} sx={{ mb: 2 }} variant="h4">
              {content?.title}
            </Typography>
            <Typography data-qa={`${dataQa}-body-subtitle`} variant="body2">
              {content?.subtitle}
            </Typography>
          </Box>
        </>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      open={open}
      title={content?.header ?? ''}
      {...modalProps}
    />
  );
};
