import React, { useState } from 'react';

import { FundingTransferDetails } from './types';
import { capitalizeEnums } from './utils';

import { FundingSourceType } from '~/__generated__';
import { Accordion, AccordionDetails, AccordionSummary, ExpandLessIcon, ExpandMoreIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { maskAccountNumber } from '~/utils/account';

export interface Props {
  displayStrings: {
    fundLater: string;
    fundingSource: string;
  };
  fundLater: boolean;
  fundingSourceType: FundingSourceType;
  transfers: FundingTransferDetails[];
}

export const FundingDetailsColumn: React.FC<Props> = ({ fundingSourceType, fundLater, transfers, displayStrings }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const getTransferString = (transfer: FundingTransferDetails) => (
    <RteContent
      config={{
        fundingSource: maskAccountNumber(transfer.transferSource),
        fundingMethod: transfer.transferMethod,
      }}
      data={displayStrings.fundingSource}
    />
  );
  return (
    <>
      {fundLater && <RteContent data={displayStrings.fundLater} />}
      {transfers.length > 0 &&
        (transfers.length <= 3 ? (
          <>{transfers.map(transfer => getTransferString(transfer))}</>
        ) : (
          <Accordion expanded={expanded} onClick={() => setExpanded(prevState => !prevState)}>
            <AccordionSummary expandIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
              {getTransferString(transfers[0])}
            </AccordionSummary>
            <AccordionDetails sx={{ pl: 0, pr: 0 }}>
              {transfers.slice(1).map(transfer => getTransferString(transfer))}
            </AccordionDetails>
          </Accordion>
        ))}
      {!fundLater && !transfers.length && <Typography variant="body1">{capitalizeEnums(fundingSourceType)}</Typography>}
    </>
  );
};
