import { UseFormMethods } from 'react-hook-form';

import { FinancialAccountType, Scalars as SymphonyScalars } from '~/__generated__';
import { LinkManualBrokerageContent } from '~/components/modals/LinkBrokerageAccount/contentstack';
import { FundingSources } from '~/utils/config';

export interface CustodianFields {
  custodian: string;
  custodianAccountNumber: string;
  custodianCashAmount?: SymphonyScalars['Decimal'] | null;
  custodianValue: number;
  tickers?: TickerFormFields[];
}

export interface TickerFormFields {
  assetName: string;
  assetSymbol: string;
  numberOfShares: number;
}

export interface FundingFormData {
  accountType: FinancialAccountType;
  amountToInvest: number;
  annuityAccountNumber: string;
  annuityDeliveringFirm: string;
  annuityFirmCity: string;
  annuityFirmPhoneNumber: string;
  annuityFirmState: string;
  annuityFirmStreetAddress: string;
  annuityFirmStreetAddressSecondary: string;
  annuityFirmZipCode: string;
  annuitySurrenderType: string;
  brokerageAccountNumber: string;
  brokerageAllOrPortion: string;
  brokerageAmount: number;
  brokerageIncludeCash: boolean;
  cash: string;
  contributionYear: number;
  custodianFundingInfo: CustodianFields[] | undefined;
  fundLaterReason: string;
  initialAmount: number;
  investmentFrequency: string;
  investmentPeriod: number;
  investmentType: string;
  manualBrokerageAmount: number;
  recurringAmount: number;
  recurringBankAccount: string;
  recurringDay: number;
  recurringFrequency: string;
  skipRecurring: boolean;
  source: FundingSources;
  sourceBankAccount: string;
  sourceBrokerageAccount: string;
  sourceBrokerageAlias: string | null;
  sourceBrokerageName: string;
}

export interface FundingFormComponentProps {
  formHooks: UseFormMethods<FundingFormData>;
}

export interface FundingFeatureFlags {
  // Restriction of FundingSources will be applied for FinancialAccountType which
  // are added here, FinancialAccountType which are not added here won't have
  // any restriction.
  // The FundingSources for a FinancialAccountType added here are the FundingSources
  // which are allowed for that specific FinancialAccountType
  // e.g. {"JOINT": [FundingSources.otherBrokerageAccount]} would mean only otherBrokerageAccount
  // is allowed for JOINT account type
  displayBankSourceByRoutingNumber?: boolean;
  displayBrokerageWithManualCustodianEntry?: boolean;
  fundingSourceRestrictions?: Partial<Record<FinancialAccountType, FundingSources[]>>;
  isDCAEnabled?: boolean;
  isFundingSourceEnabled?: boolean;
  onlyManualEntryAllowed?: boolean;
  partialFundingDisabled?: boolean;
  showInvestmentAmountOnFundLater?: boolean;
  showRecurringDepositSection?: boolean;
  showVerifiedBankAccounts?: boolean;
  syncExternalBankAccounts?: boolean;
}

export interface SecuritySearchOption {
  cusip: string;
  id: string;
  name: string;
  ticker: string;
  type: string;
}

export enum BrokerageSourceType {
  All = 'ALL',
  Portion = 'PORTION',
}

export enum PositionIdentifiers {
  CUSIP = 'CUSIP',
  ID = 'SECURITY_ID',
  NAME = 'NAME',
  TICKER = 'TICKER',
  TYPE = 'TYPE',
}

export interface CustomBrokerageFundingSource {
  brokerageName: string;
  isValid: (accountType: FinancialAccountType) => boolean;
  name: string;
}

export type CustomBrokerageEntryContent = Partial<LinkManualBrokerageContent> & {
  brokerageSourceLabel?: string;
  disclaimer?: string;
  editDetails?: string;
};
