import {
  GetSuspensionsHistory,
  GetSuspensionsHistory_managedProduct_tradingSuspensionHistory_suspensions,
  GetSuspensionsHistoryVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type TradingSuspensionsHistory = GetSuspensionsHistory_managedProduct_tradingSuspensionHistory_suspensions;

export const useGetSuspensionsHistoryData = (
  options?: QueryHookOptions<GetSuspensionsHistory, GetSuspensionsHistoryVariables>,
): QueryResult<GetSuspensionsHistory, GetSuspensionsHistoryVariables> => {
  return useSymphonyQuery(queries.GetSuspensionsHistory, options);
};
