import {
  GetGroupedTradingSuspensionsV2,
  GetGroupedTradingSuspensionsV2_managedProducts_products,
  GetGroupedTradingSuspensionsV2_managedProducts_products_tradingSuspensions,
  GetGroupedTradingSuspensionsV2Variables,
} from './__generated__/query.v2';
import {
  GetTradingSuspensionsV2,
  GetTradingSuspensionsV2_tradingSuspensions_tradingSuspensions,
  GetTradingSuspensionsV2_tradingSuspensions_tradingSuspensions_managedProduct,
  GetTradingSuspensionsV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type TradingSuspensionManagedProduct = GetTradingSuspensionsV2_tradingSuspensions_tradingSuspensions_managedProduct;
export type TradingSuspensions = GetTradingSuspensionsV2_tradingSuspensions_tradingSuspensions;
export type GroupedTradingSuspensions = GetGroupedTradingSuspensionsV2_managedProducts_products_tradingSuspensions;
export type GroupedTradingSuspensionManagedProduct = GetGroupedTradingSuspensionsV2_managedProducts_products;

export const useGetTradingSuspensionsV2 = (
  options?: QueryHookOptions<GetTradingSuspensionsV2, GetTradingSuspensionsV2Variables>,
): QueryResult<GetTradingSuspensionsV2, GetTradingSuspensionsV2Variables> => {
  return useSymphonyQuery(queries.GetTradingSuspensionsV2, options);
};

export const useGetGroupedTradingSuspensionsV2 = (
  options?: QueryHookOptions<GetGroupedTradingSuspensionsV2, GetGroupedTradingSuspensionsV2Variables>,
): QueryResult<GetGroupedTradingSuspensionsV2, GetGroupedTradingSuspensionsV2Variables> => {
  return useSymphonyQuery(queries.GetGroupedTradingSuspensionsV2, options);
};
