import React, { FC, useState } from 'react';

import { useGoalsEvent } from '../hooks/useGoalsEvent';

import { useDeleteGoal } from './amplify';

import { Alert } from '~/components/ui/Alert';
import { Modal } from '~/components/ui/Modal';
import { ModalActionsLayout } from '~/components/ui/Modal/ModalActionsLayout';
import { Button, Collapse, Grid, LoadingButton } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { GetGoals, GetGoalsVariables } from '~/hooks/goals/amplify/__generated__/getGoals.v2';
import * as queries from '~/hooks/goals/amplify/getGoals.gql';
import { ContentOptions } from '~/utils/contentstack';
import produce from '~/utils/immer';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Props {
  content: {
    description: string;
    image: string;
    primaryCta: string;
    secondaryCta: string;
    title: string;
  };
  contentOptions: ContentOptions;
  dataQa?: string;
  goalId: string;
  onBackToDashboard?: () => void;
  onClose: () => void;
  open: boolean;
  partyId: string;
}

export const DeleteModal: FC<Props> = ({
  content,
  contentOptions,
  dataQa = 'goal-delete-modal',
  goalId,
  onBackToDashboard,
  onClose,
  open,
  partyId,
}) => {
  const [busy, setBusy] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState<Error>();
  const [deleteGoal] = useDeleteGoal();
  const isMobile = useIsMediumScreen();
  const emitEvent = useGoalsEvent();

  const handleDeleteGoal = async () => {
    try {
      setBusy(true);
      emitEvent({ componentName: 'deleteGoal', name: 'confirmDeleteGoal' });

      await deleteGoal({
        variables: { clientPartyIds: [partyId], goalId },
        update: (cache, { data }) => {
          const queryOptions = { query: queries.GetGoals, variables: { partyId } };
          const goalsCache = cache.readQuery<GetGoals, GetGoalsVariables>(queryOptions);
          if (goalsCache) {
            cache.writeQuery<GetGoals>({
              ...queryOptions,
              data: produce(goalsCache, draft => {
                const goalsItems = draft.getUserByPartyId?.goals?.items;
                const deletedGoalId = data?.deleteGoalById?.id;
                if (goalsItems && deletedGoalId) {
                  const goalIndex = goalsItems.findIndex(g => g?.id === deletedGoalId);
                  if (goalIndex !== -1) {
                    goalsItems.splice(goalIndex, 1);
                  }
                }
              }),
            });
          }
        },
      });
      onClose();
      onBackToDashboard?.();
    } catch (error: any) {
      setErrorDeleting(error);
    }
    setBusy(false);
  };

  const handleClose = () => {
    emitEvent({ componentName: 'deleteGoal', name: 'cancelDeleteGoal' });
    setErrorDeleting(undefined);
    onClose();
  };

  return (
    <Modal
      actions={
        <ModalActionsLayout
          justifyContent={{ md: 'flex-end', xs: undefined }}
          primaryActions={
            <LoadingButton fullWidth={isMobile} loading={busy} onClick={handleDeleteGoal} variant="contained">
              {content.primaryCta}
            </LoadingButton>
          }
          secondaryActions={
            <Button disabled={busy} fullWidth={isMobile} onClick={handleClose} variant="outlined">
              {content.secondaryCta}
            </Button>
          }
        />
      }
      content={
        <Grid container direction="column" sx={{ textAlign: 'center' }}>
          {content.image && (
            <Grid item mb={2}>
              <img alt="" src={content.image} width="110px" />{' '}
            </Grid>
          )}
          <Grid item>
            <RteContent data={content.description} gutterBottom={isMobile} />
          </Grid>

          <Grid item>
            <Collapse in={!!errorDeleting} sx={{ mt: 2, textAlign: 'left' }}>
              {errorDeleting && <Alert contentOptions={contentOptions} error={errorDeleting} severity="error" />}
            </Collapse>
          </Grid>
        </Grid>
      }
      dataQa={dataQa}
      onClose={handleClose}
      open={open}
      title={content.title}
    />
  );
};
