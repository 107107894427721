import { useRequestClose } from '../symphony';

import { BankAccountInputWithParty, RequestCloseInput } from '~/__generated__/symphonyTypes.v2';
import { AddFinancialAccount_addFinancialAccount } from '~/components/modals/LinkBankAccount/symphony/__generated__/mutation.v2';
import { getDistributionReasonCode } from '~/components/modals/WithdrawFunds';
import { ValidateCashTransferResponse } from '~/components/modals/WithdrawFunds/symphony';
import { FormData as TaxWithholdingFormData } from '~/components/modals/WithdrawFunds/types';
import { WITHHOLD_OPTIONS } from '~/components/TaxWithholdingForm';

export interface CloseRequest {
  bankAccountWithParty?: BankAccountInputWithParty | null;
  closeRequestInput: RequestCloseInput;
}

export interface UseHandleRequestCloseArgs {
  addedFinancialAccount?: AddFinancialAccount_addFinancialAccount | null;
  closeRequest?: CloseRequest;
  deleteFinancialAccount: (financialAccountId: string) => Promise<void>;
  isRetirementAccount: boolean;
  taxWithholdingFormData: TaxWithholdingFormData;
  validateTransferResponse: ValidateCashTransferResponse;
}

type UseHandleRequestClose = (args: {
  clientAge?: number | null;
  isRetirementAccount: boolean;
  prematureAgeContent?: number | null;
}) => (args: UseHandleRequestCloseArgs) => Promise<RequestCloseData>;

interface RequestCloseData {
  bankAccountAssociationId: string;
  isFinancialAccountAssociationPending: boolean;
}

export const useHandleRequestClose: UseHandleRequestClose = ({
  clientAge,
  isRetirementAccount,
  prematureAgeContent,
}) => {
  const [requestClose] = useRequestClose();

  return async ({
    addedFinancialAccount,
    closeRequest,
    deleteFinancialAccount,
    taxWithholdingFormData,
    validateTransferResponse,
  }): Promise<RequestCloseData> => {
    if (
      addedFinancialAccount?.id &&
      closeRequest?.closeRequestInput.transferToFinancialAccountId !== addedFinancialAccount.id
    ) {
      await deleteFinancialAccount(addedFinancialAccount.id);
    }
    const { data } = await requestClose({
      variables: {
        input: {
          ...closeRequest?.closeRequestInput,
          ...(isRetirementAccount && {
            distributionReason: getDistributionReasonCode(
              clientAge,
              validateTransferResponse?.distributionCode,
              prematureAgeContent,
            ),
            federalTaxWithholdingCode: taxWithholdingFormData.federalTaxWithholding,
            ...(taxWithholdingFormData.federalTaxWithholding !== WITHHOLD_OPTIONS.DO_NOT_WITHHOLD && {
              federalTaxWithholdingPercentage: parseFloat(
                taxWithholdingFormData.federalTaxWithholdingPercentage?.toString() ?? '',
              ),
            }),
            stateTaxWithholdingCode: taxWithholdingFormData.stateTaxWithholding,
            ...(taxWithholdingFormData.stateTaxWithholding !== WITHHOLD_OPTIONS.DO_NOT_WITHHOLD && {
              stateTaxWithholdingPercentage: parseFloat(
                taxWithholdingFormData.stateTaxWithholdingPercentage?.toString() ?? '',
              ),
            }),
            transactionId: validateTransferResponse?.transactionId,
          }),
        } as RequestCloseInput,
        bankAccountWithParty: closeRequest?.bankAccountWithParty,
      },
    });
    return {
      bankAccountAssociationId: data?.requestClose?.bankAccountAssociationId ?? '',
      isFinancialAccountAssociationPending: data?.requestClose?.isFinancialAccountAssociationPending ?? false,
    };
  };
};
