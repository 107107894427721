import { GetTradingSuspensionsTabContent, GetTradingSuspensionsTabContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type TradingSuspensionsTabContent = GetTradingSuspensionsTabContent;

export const useGetTradingSuspensionsTabContent = (
  options?: QueryHookOptions<GetTradingSuspensionsTabContent, GetTradingSuspensionsTabContentVariables>,
): QueryResult<GetTradingSuspensionsTabContent, GetTradingSuspensionsTabContentVariables> => {
  return useContentstackQuery(queries.GetTradingSuspensionsTabContent, options);
};
