import React, { ComponentProps, useEffect, useState } from 'react';

import { useGetCreateFlagContent } from './contentstack';
import { useAddFlag } from './symphony';
import { getFlagReasonsOptions } from './utils';

import { FlagEntity, FlagReason } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownChangeEvent } from '~/components/ui/Dropdown/types';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, LoadingButton, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Textarea } from '~/components/ui/Textarea';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { SfTheme } from '~/utils/theme';

export type Props = ComponentProps<typeof Modal> & {
  accountId: string;
  accountTypeText: string;
  clientName: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  entity: FlagEntity;
  entityId: string;
  flagReasons: FlagReason[];
  onClose: () => void;
  onCreate: () => void;
  open: boolean;
};

interface RequestDataInterface {
  description: string;
  reason: string;
}

export const CreateFlagModal: React.FC<Props> = ({
  accountId,
  accountTypeText,
  clientName,
  contentOptions,
  dataQa,
  entity,
  entityId,
  onClose,
  open,
  flagReasons = [],
  onCreate,
}: Props) => {
  const { data, loading, error } = useGetCreateFlagContent({
    variables: contentOptions,
    skip: !open,
  });
  const [requestData, setRequestData] = useState<RequestDataInterface>({
    reason: '',
    description: '',
  });
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const {
    sfCreateFlagModal: { styles: sfCreateFlagModalStyles },
  } = useTheme<SfTheme>();
  const [addFlag] = useAddFlag();
  const [addFlagError, setAddFlagError] = useState<Error>();

  useEffect(() => {
    if (open) {
      setIsConfirmationModal(false);
      setAddFlagError(undefined);
      setRequestData({ description: '', reason: '' });
    }
  }, [open]);

  const allFlagsReasons = data?.all_flags?.items?.[0]?.reasons ?? null;
  const reasonOptions = getFlagReasonsOptions(flagReasons, allFlagsReasons);
  const placeholderText =
    data?.all_flags?.items?.[0]?.create_modal_extra_content?.placeholder_text ?? 'Select Reason for Escalation';
  const createFlagModalFields = data?.all_flags?.items?.[0]?.create_flag_modal;
  const confirmationModalFields = data?.all_flags?.items?.[0]?.confirm_create_flag_modal;
  const extraContent = data?.all_flags?.items?.[0]?.create_modal_extra_content;
  const confirmLogoUrl = confirmationModalFields?.imageConnection?.edges?.[0]?.node?.url;

  const onChangeReason = (event: DropdownChangeEvent) =>
    setRequestData({ ...requestData, reason: event.target.value as string });

  const onChangeDescription = (value: string) => setRequestData({ ...requestData, description: value });

  const escalateRequestCall = async () => {
    try {
      setIsBusy(true);
      await addFlag({
        variables: {
          reason: requestData.reason as FlagReason,
          description: requestData.description || null,
          entity,
          entityIdentifier: entityId,
        },
      });
      onCreate();
      onClose();
    } catch (err: any) {
      console.error(err);
      setAddFlagError(err);
    } finally {
      setIsBusy(false);
    }
  };
  const onEscalate = () => {
    isConfirmationModal ? escalateRequestCall() : setIsConfirmationModal(true);
  };

  return (
    <Modal
      actions={
        <>
          <Button disabled={isBusy} onClick={onClose} sx={sfCreateFlagModalStyles.cancelButton} variant="text">
            {isConfirmationModal ? confirmationModalFields?.secondary_cta : createFlagModalFields?.secondary_cta}
          </Button>
          <LoadingButton
            disabled={
              requestData.reason === '' ||
              (requestData.reason === FlagReason.OTHER && requestData.description.trim().length < 1) ||
              !!addFlagError
            }
            loading={isBusy}
            onClick={onEscalate}
            sx={sfCreateFlagModalStyles.escalateButton}
            variant="contained"
          >
            {isConfirmationModal ? confirmationModalFields?.primary_cta : createFlagModalFields?.primary_cta}
          </LoadingButton>
        </>
      }
      content={
        isConfirmationModal ? (
          <>
            {confirmLogoUrl && <img alt="" src={confirmLogoUrl} />}
            {confirmationModalFields?.description && (
              <Typography sx={{ mt: 4 }} variant="body2">
                <RteContent data={confirmationModalFields.description as string} />
              </Typography>
            )}
            {addFlagError && (
              <>
                <Alert
                  contentOptions={contentOptions}
                  error={addFlagError}
                  severity="error"
                  sx={{ mt: 2, alignItems: 'center' }}
                />
              </>
            )}
          </>
        ) : (
          <Box sx={{ pr: 7 }}>
            <Typography
              sx={{ mb: 1 }}
              variant="subtitle2"
            >{`${extraContent?.account_text} ${accountId} / ${accountTypeText}`}</Typography>
            <Typography sx={{ mb: 4 }} variant="subtitle2">{`${extraContent?.client_text} ${clientName}`}</Typography>
            {reasonOptions && (
              <>
                <Dropdown
                  dataQa="escalate-reason-dropdown"
                  id="reason-select"
                  items={reasonOptions}
                  labelId="reason-select-label"
                  onChange={onChangeReason}
                  placeholder={placeholderText}
                  value={requestData.reason}
                />
              </>
            )}
            <Typography sx={{ mb: 1, mt: 4 }} variant="subtitle2">
              {extraContent?.add_description_text}
            </Typography>
            <Textarea maxLength={300} onChange={onChangeDescription} />
          </Box>
        )
      }
      contentOptions={contentOptions}
      dataQa={dataQa}
      error={error}
      hideHeader={isConfirmationModal}
      loading={loading}
      onClose={onClose}
      open={open}
      title={createFlagModalFields?.title}
    />
  );
};
