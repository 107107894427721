import React, { ComponentProps, useEffect, useState } from 'react';

import { useGetSuspendTradingModalContent } from './contentstack';
import { getFormattedRows, getModalContent } from './utils';

import { SuspensionType } from '~/__generated__';
import { commonStyles } from '~/components/TradingSuspensions/utils';
import { Alert } from '~/components/ui/Alert';
import { BasicTable } from '~/components/ui/BasicTable';
import { Modal } from '~/components/ui/Modal';
import { Button, LoadingButton } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import {
  useDeleteTradingSuspension,
  useLazyGetManagedProductTradingSuspensions,
  useUpdateTradingSuspension,
} from '~/hooks/suspensions/symphony';
import { isPartnerOpsSuspension, SuspensionTag } from '~/utils/account';
import { ContentOptions } from '~/utils/contentstack';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onClose: () => void;
  open: boolean;
  refetchAccounts?: () => Promise<any>;
};

export const SuspendTradingModal: React.FC<Props> = ({
  dataQa = 'suspend-trading-modal',
  managedProductId,
  contentOptions,
  open,
  onClose,
  refetchAccounts,
  ...modalProps
}) => {
  const { data: content, loading: loadingContent, error: errorContent } = useGetSuspendTradingModalContent({
    variables: contentOptions,
  });
  const [
    getManagedProductTradingSuspensions,
    { data: tradingSuspendedData, loading: loadingTradingSuspendedData, error: errorTradingSuspendedData },
  ] = useLazyGetManagedProductTradingSuspensions({ variables: { managedProductId }, fetchPolicy: 'no-cache' });

  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [isBusy, setIsBusy] = useState(false);
  const [deleteTradingSuspension] = useDeleteTradingSuspension();
  const [updateTradingSuspension] = useUpdateTradingSuspension();

  const externalDataLoading = loadingContent || loadingTradingSuspendedData;

  useEffect(() => {
    if (open) {
      getManagedProductTradingSuspensions();
    }
  }, [getManagedProductTradingSuspensions, open]);

  const isOpsTradingSuspended = tradingSuspendedData?.managedProduct?.tradingSuspensions.find(suspension =>
    isPartnerOpsSuspension(suspension),
  );

  const setTradingSuspended = async () => {
    setIsBusy(true);
    const mutationVariables = {
      variables: {
        managedProductId,
        suspensionType: SuspensionType.PARTNER,
        suspensionTags: [SuspensionTag.OPS],
      },
    };
    try {
      setErrorSaving(undefined);
      const toggleTradingSuspensionResponse = isOpsTradingSuspended
        ? await deleteTradingSuspension({
            ...mutationVariables,
          })
        : await updateTradingSuspension({
            ...mutationVariables,
          });
      if (!toggleTradingSuspensionResponse.data) {
        setErrorSaving(Error('Error saving'));
      }
      if (refetchAccounts) {
        await refetchAccounts();
      }
      onClose();
    } catch (error: any) {
      setErrorSaving(error);
    } finally {
      setIsBusy(false);
    }
  };
  const modalContent = getModalContent(content);
  const tradingSuspensionsTable = () => {
    const tradingSuspensions = tradingSuspendedData?.managedProduct?.tradingSuspensions;
    if (tradingSuspensions?.length) {
      const tableData = getFormattedRows(content, contentOptions, tradingSuspensions);
      return <BasicTable alignItems="left" columns={modalContent.columns} data={tableData} sx={commonStyles.tableSx} />;
    }

    return (
      <Typography data-qa={`${dataQa}-text`} sx={{ mb: 12, mt: 4 }} variant="h4">
        {modalContent.activeAccountLabel}
      </Typography>
    );
  };

  return (
    <Modal
      {...modalProps}
      actions={
        <>
          <Button data-qa={`${dataQa}-cancel`} disabled={isBusy} onClick={onClose} variant="outlined">
            {modalContent.cancelCta}
          </Button>
          <LoadingButton
            data-qa={`${dataQa}-cta-primary`}
            loading={isBusy || externalDataLoading}
            onClick={setTradingSuspended}
            variant="contained"
          >
            {isOpsTradingSuspended ? modalContent.unSuspendConfirmButtonCta : modalContent.suspendConfirmButtonCta}
            {errorSaving && <Alert contentOptions={contentOptions} error={errorSaving} severity="error" />}
          </LoadingButton>
        </>
      }
      content={tradingSuspensionsTable()}
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={errorContent ?? errorTradingSuspendedData}
      loading={externalDataLoading}
      onClose={onClose}
      open={open}
      title={modalContent.modal_title}
    />
  );
};
