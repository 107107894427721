import React, { ReactNode } from 'react';

import { closingReasonName, eligibleDestinationBankAccountName, shouldSellAssetsName } from './CloseAccountForm';
import { CloseAccountModalContent } from './contentstack';
import {
  GetCloseAccountContent_all_close_account_modal_items,
  GetCloseAccountContent_all_close_account_modal_items_closing_reasons,
} from './contentstack/__generated__/query.v2';
import {
  GetDestinationAccounts_client_financialAccounts as DestinationBankAccount,
  GetDestinationAccounts_client_financialAccounts,
} from './symphony/__generated__/query.v2';

import { BankAccountInputWithParty, CloseReason, FinancialAccountSource, RequestCloseInput } from '~/__generated__';
import { Divider } from '~/components/ui/Divider';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Link } from '~/components/ui/Link';
import { Box, ForumIcon, PhoneAndroidIcon, SendIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import {
  getBankAccountLabel,
  getExternalBankAccountInput,
  getPlaidMaskedAccountNumber,
  getUpdatedFinancialInstituion,
  isBankAccountEditable,
  sortBankAccounts,
} from '~/utils/account';
import { formatMaskedAccountNumber } from '~/utils/format';

export interface FormData {
  [closingReasonName]: CloseReason;
  [eligibleDestinationBankAccountName]: string;
  [shouldSellAssetsName]: boolean;
}

export enum ClosingOptions {
  END_MANAGEMENT = 'END_MANAGEMENT',
  WITHDRAW_AND_CLOSE = 'WITHDRAW_AND_CLOSE',
}

export const getActiveClosingOptions = (
  showStaticModal: boolean,
  items?: CloseAccountModalContent,
): ClosingOptions[] => {
  const activeStates: ClosingOptions[] = [];
  if (!showStaticModal) {
    if (items) {
      if (items.withdraw_and_close?.enabled) {
        activeStates.push(ClosingOptions.WITHDRAW_AND_CLOSE);
      }
      if (items.end_management?.enabled) {
        activeStates.push(ClosingOptions.END_MANAGEMENT);
      }
    }
  }
  return activeStates;
};

export const getModalTitle = (
  closingStep: number,
  isRetirementAccount: boolean,
  selectedOption?: ClosingOptions,
  items?: CloseAccountModalContent,
): string => {
  if (items) {
    if (closingStep === 1) {
      return items.close_account?.modal_title ?? '';
    } else {
      switch (selectedOption) {
        case ClosingOptions.WITHDRAW_AND_CLOSE:
          if (closingStep === 5 && isRetirementAccount) {
            return `${items.withdraw_and_close?.modal_title ?? ''} ${
              items.withdraw_and_close?.confirm_retirement?.modal_title_suffix ?? ''
            }`;
          } else {
            return items.withdraw_and_close?.modal_title ?? '';
          }
        case ClosingOptions.END_MANAGEMENT:
          return items.end_management?.modal_title ?? '';
      }
    }
  }
  return '';
};

export const showPrimaryCta = (
  closingStep: number,
  activeClosingOptions: ClosingOptions[],
  bankAccountOptions: DropdownItem[],
  showStaticModal: boolean,
  selectedClosingOption?: ClosingOptions,
): boolean => {
  switch (closingStep) {
    case 1:
      return !showStaticModal && activeClosingOptions.length > 0;
    case 2:
      return bankAccountOptions.length > 0 || selectedClosingOption === ClosingOptions.END_MANAGEMENT;
    case 3:
      return true;
    case 4:
      return true;
    case 5:
      return true;
    default:
      return false;
  }
};

export const getPrimaryCtaText = (
  closingStep: number,
  showEndEngagementTaxWithholdingSteps: boolean,
  selectedOption?: ClosingOptions,
  items?: CloseAccountModalContent,
  isRetirementAccount?: boolean,
): string => {
  if (items) {
    switch (closingStep) {
      case 1:
        return items.cta?.close_account?.primary ?? '';
      case 2:
        if (selectedOption === ClosingOptions.END_MANAGEMENT) {
          return items.cta?.end_management?.primary ?? '';
        }
        return isRetirementAccount ? items.cta?.navigation?.next ?? '' : items.cta?.withdraw_and_close?.primary ?? '';
      case 3:
        return isRetirementAccount &&
          items.display_tax_withholding_steps &&
          ((selectedOption === ClosingOptions.END_MANAGEMENT && showEndEngagementTaxWithholdingSteps) ||
            selectedOption === ClosingOptions.WITHDRAW_AND_CLOSE)
          ? items.cta?.navigation?.next ?? ''
          : items.cta?.confirm?.primary ?? '';
      case 4:
        return items.cta?.withdraw_and_close?.primary_retirement ?? '';
      case 5:
        return items.cta?.confirm_retirement?.primary ?? '';
      default:
        return '';
    }
  }
  return '';
};

export const getSecondaryCtaText = (
  closingStep: number,
  showStaticModal: boolean,
  showEndEngagementTaxWithholdingSteps: boolean,
  selectedOption?: ClosingOptions,
  items?: CloseAccountModalContent,
  isRetirementAccount?: boolean,
): string => {
  if (items) {
    switch (closingStep) {
      case 1:
        return (showStaticModal ? items.cta?.static_modal_close_action : items.cta?.close_account?.secondary) ?? '';
      case 2:
        if (selectedOption === ClosingOptions.END_MANAGEMENT) {
          return items.cta?.end_management?.secondary ?? '';
        }
        return items.cta?.withdraw_and_close?.secondary ?? '';
      case 3:
        return isRetirementAccount &&
          items.display_tax_withholding_steps &&
          ((selectedOption === ClosingOptions.END_MANAGEMENT && showEndEngagementTaxWithholdingSteps) ||
            selectedOption === ClosingOptions.WITHDRAW_AND_CLOSE)
          ? items.cta?.navigation?.back ?? ''
          : items.cta?.confirm?.secondary ?? '';
      case 4:
        return items.cta?.navigation?.back ?? '';
      case 5:
        return items.cta?.confirm?.secondary ?? '';
      default:
        return '';
    }
  }
  return '';
};

export const getHeaderText = (showStaticModal: boolean, content?: CloseAccountModalContent): string => {
  return (
    (showStaticModal ? content?.close_account?.static_close_account_body : content?.close_account?.message?.body) ?? ''
  );
};

export const getDestinationBankAccountOptions = (
  accounts: GetDestinationAccounts_client_financialAccounts[],
  accountNumberFormat?: string,
  editDetailsOnClick?: (item: DropdownItem) => void,
): DropdownItem[] => {
  const options = [...sortBankAccounts(accounts)]
    .map(account => {
      const accountId = account.id;
      const maskedAccountNumber = getPlaidMaskedAccountNumber(account.attributes) ?? account.maskedAccountNumber;
      const accountName = getBankAccountLabel(
        getUpdatedFinancialInstituion(account.financialInstitution, account.type),
        account.productName,
        formatMaskedAccountNumber(accountNumberFormat, maskedAccountNumber),
      );
      if (accountId && accountName) {
        return {
          value: accountId,
          label: accountName,
          editDetailsOnClick:
            isBankAccountEditable(account) && account.source !== FinancialAccountSource.PLAID
              ? editDetailsOnClick
              : undefined,
        };
      }
      return null;
    })
    .filter(Boolean);

  return options as DropdownItem[];
};

export const getClosingReasonOptions = (
  reasons?: (GetCloseAccountContent_all_close_account_modal_items_closing_reasons | null)[],
): DropdownItem[] => {
  return (reasons ?? []).map(r => ({
    label: r?.reason?.label ?? '',
    value: r?.reason?.value ?? '',
  }));
};

export const transformFormData = (
  userInfo: { name: string; partyId: string },
  formData: FormData,
  managedProductId: string,
  bankAccounts: DestinationBankAccount[],
  selectedClosingOption?: ClosingOptions,
): { bankAccountWithParty?: BankAccountInputWithParty | null; closeRequestInput: RequestCloseInput } => {
  if (selectedClosingOption === ClosingOptions.WITHDRAW_AND_CLOSE) {
    const externalBankAccountInput = getExternalBankAccountInput(
      formData[eligibleDestinationBankAccountName],
      bankAccounts,
      userInfo,
    );
    return {
      closeRequestInput: {
        managedAccountId: managedProductId,
        closeBrokerageAccount: true,
        reason: formData[closingReasonName],
        sellAssets: true,
        transferToFinancialAccountId: !externalBankAccountInput ? formData[eligibleDestinationBankAccountName] : null,
      },
      bankAccountWithParty: externalBankAccountInput,
    };
  } else {
    return {
      closeRequestInput: {
        managedAccountId: managedProductId,
        closeBrokerageAccount: false,
        reason: formData[closingReasonName],
        sellAssets: formData[shouldSellAssetsName] || false,
      },
    };
  }
};

export const getFooter = (content?: GetCloseAccountContent_all_close_account_modal_items): ReactNode => {
  return (
    content?.modal_footer?.content && (
      <>
        <Divider sx={{ my: 4, maxWidth: '220px', mx: 'auto' }} variant="middle" />
        <Box sx={{ textAlign: 'center', my: 4 }}>
          <RteContent
            config={{
              supportEmail: (
                <Link href={`mailto:${content.modal_footer.support_email?.href}`} sx={{ pt: 1 }} variant="body2">
                  <SendIcon
                    fontSize="small"
                    sx={{ verticalAlign: 'middle', mr: 1, display: { xs: 'none', md: 'inline-block' } }}
                  />
                  {content.modal_footer.support_email?.title}
                </Link>
              ),
              supportPhoneNumber: (
                <Link href={`tel:+1${content.modal_footer.support_phone_number?.href}`} sx={{ pt: 1 }} variant="body2">
                  <PhoneAndroidIcon
                    fontSize="small"
                    sx={{ verticalAlign: 'middle', mr: 1, display: { xs: 'none', md: 'inline-block' } }}
                  />
                  {content.modal_footer.support_phone_number?.title}
                </Link>
              ),
              liveChat: (
                <Link sx={{ pt: 1 }} variant="body2">
                  <ForumIcon
                    fontSize="small"
                    sx={{ verticalAlign: 'middle', mr: 1, display: { xs: 'none', md: 'inline-block' } }}
                  />
                  {content.modal_footer.live_chat?.title}
                </Link>
              ),
            }}
            data={content.modal_footer.content}
          />
        </Box>
      </>
    )
  );
};

/**
 * Returns true
 *  i) WithDrawAndClose workflow - when account is retirement  and tax_with_holding steps enabled on content stack
 *  ii) EndEngagement workflow - when above condition is true and endEngagement tax_with_holding is enabled
 */
export const getDisplayTaxWithholdingSteps = (
  isRetirementAccount: boolean,
  content: GetCloseAccountContent_all_close_account_modal_items | undefined,
  selectedClosingOption: ClosingOptions | undefined,
  showEndEngagementTaxWithholdingSteps: boolean,
): boolean | null | undefined => {
  if (selectedClosingOption && selectedClosingOption === ClosingOptions.END_MANAGEMENT && isRetirementAccount) {
    return showEndEngagementTaxWithholdingSteps && content?.display_tax_withholding_steps;
  } else {
    return isRetirementAccount && content?.display_tax_withholding_steps;
  }
};
