import { addBusinessDays, startOfToday } from 'date-fns';

import { GetDestinationAccounts_client_financialAccounts as DestinationBankAccount } from '../symphony/__generated__/query.v2';

import { FinancialAccountType, TransferFrequency, TransferType } from '~/__generated__/symphonyTypes.v2';
import { useAddFinancialAccount } from '~/components/modals/LinkBankAccount/symphony';
import {
  AddFinancialAccount,
  AddFinancialAccount_addFinancialAccount,
} from '~/components/modals/LinkBankAccount/symphony/__generated__/mutation.v2';
import { useValidateCashTransfer, ValidateCashTransferResponse } from '~/components/modals/WithdrawFunds/symphony';
import { BankAccountTemporaryIdPrefix, getBankAccountType } from '~/utils/account';
import { BankAccountStringAttribute } from '~/utils/account/types';
import { FetchResult } from '~/utils/apollo-client';
import { toSymphonyDate } from '~/utils/symphony';

export interface UseHandleValidateCashTransferArgs {
  accountId?: string | null;
  addedFinancialAccount?: AddFinancialAccount_addFinancialAccount | null;
  bankAccounts: Partial<DestinationBankAccount>[];
  cashAmount: string;
  clientUserName: string;
  deleteFinancialAccount: (financialAccountId: string) => Promise<void>;
  financialAccountType?: FinancialAccountType;
  formData: any;
  managedProductId: string;
  partyId: string;
}

export interface UseHandleValidateCashTransferResponseArgs {
  addedFinancialAccount?: AddFinancialAccount_addFinancialAccount | null;
  validateCashTransferResponse?: ValidateCashTransferResponse;
}

type UseHandleValidateCashTransfer = () => (
  args: UseHandleValidateCashTransferArgs,
) => Promise<UseHandleValidateCashTransferResponseArgs>;

export const useHandleValidateCashTransfer: UseHandleValidateCashTransfer = () => {
  const [addFinancialAccount] = useAddFinancialAccount();
  const [validateCashTransfer] = useValidateCashTransfer();

  return async ({
    accountId,
    addedFinancialAccount,
    bankAccounts,
    cashAmount,
    clientUserName,
    deleteFinancialAccount,
    financialAccountType,
    formData,
    managedProductId,
    partyId,
  }): Promise<UseHandleValidateCashTransferResponseArgs> => {
    const bankAccount = bankAccounts.find(account => account.id === formData?.eligibleDestinationBankAccount);
    if (!bankAccount) {
      return {};
    }

    let addedFinancialAccountResponse: FetchResult<AddFinancialAccount> | null = null;
    if (formData.eligibleDestinationBankAccount.includes(BankAccountTemporaryIdPrefix)) {
      addedFinancialAccountResponse = await addFinancialAccount({
        variables: {
          bankAccount: {
            accountNumber: bankAccount.accountNumber as string,
            attributes: bankAccount.attributes
              ?.filter(
                (attribute): attribute is BankAccountStringAttribute => attribute.__typename === 'StringAttribute',
              )
              ?.map(({ name, stringValue }) => ({ name, value: stringValue })),
            financialInstitution: bankAccount.financialInstitution as string,
            isSynced: true,
            nameOnBankAccount: bankAccount.nameOnBankAccount ? bankAccount.nameOnBankAccount : clientUserName, // Can't use ?? because we want to use user.name when nameOnBankAccount is empty ('') as well
            productName: bankAccount.productName,
            type: getBankAccountType(bankAccount.type as FinancialAccountType),
            routingNumber: bankAccount.routingNumber as string,
          },
          managedProductId,
          partyId,
        },
      });
      // Delete bank account if addedFinancialAccount has already some value
      if (addedFinancialAccount?.id) {
        deleteFinancialAccount(addedFinancialAccount.id);
      }
    }

    const validateCashTransferResponse = await validateCashTransfer({
      variables: {
        partyId,
        validateTransferInput: {
          accountType: financialAccountType as FinancialAccountType,
          bankAccountId: formData.eligibleDestinationBankAccount.includes(BankAccountTemporaryIdPrefix)
            ? addedFinancialAccountResponse?.data?.addFinancialAccount?.id ?? ''
            : (bankAccount.id as string),
          cashAmount,
          financialAccountId: accountId as string,
          frequency: TransferFrequency.ONE_TIME,
          type: TransferType.WITHDRAWAL,
          managedId: managedProductId,
          scheduledAt: toSymphonyDate(addBusinessDays(startOfToday(), 3)),
        },
      },
    });
    return {
      validateCashTransferResponse: validateCashTransferResponse.data?.validateCashTransfer,
      addedFinancialAccount: addedFinancialAccountResponse?.data?.addFinancialAccount,
    };
  };
};
