import React, { ComponentProps } from 'react';

import { ClientDocuments } from '../../ClientDocuments';

import { useClientDocumentsModalContent } from './hooks/useClientDocumentsModalContent';

import { ErrorComponent } from '~/components/ErrorComponent';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { Drawer } from '~/components/ui/Drawer';
import { Modal } from '~/components/ui/Modal';
import { Button, Container, Grid } from '~/components/ui/mui';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onClose: () => void;
  open: boolean;
};

export const clientDocumentsPerPage = 3;

export const ClientDocumentsModal: React.FC<Props> = ({
  contentOptions,
  dataQa = 'client-documents-modal',
  managedProductId,
  onClose,
  open,
}) => {
  const { data, error, loading } = useClientDocumentsModalContent({
    contentOptions,
  });

  const isMediumOrBelowScreen = useIsMediumScreen();

  return (
    <Drawer
      actions={
        <Grid
          container
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
          justifyContent={{ xs: 'center', md: 'flex-end' }}
        >
          <Grid item>
            <Button fullWidth={isMediumOrBelowScreen} onClick={onClose} variant="outlined">
              {data?.primaryAction}
            </Button>
          </Grid>
        </Grid>
      }
      dataQa={dataQa}
      disableEscapeKeyDown
      onClose={onClose}
      open={open}
      title={data?.title}
    >
      {error ? (
        <ErrorComponent contentOptions={contentOptions} error={error} />
      ) : loading ? (
        <CircularProgress />
      ) : (
        <Container maxWidth="lg">
          <ClientDocuments
            clientDocumentsPerPage={clientDocumentsPerPage}
            contentOptions={contentOptions}
            managedProductId={managedProductId}
          />
        </Container>
      )}
    </Drawer>
  );
};
