import {
  GetOpsDashboardFundingTableContentV2,
  GetOpsDashboardFundingTableContentV2_all_ops_dashboard_funding_table_items_flag_referenceConnection_edges_node,
  GetOpsDashboardFundingTableContentV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type FundingTabFlagItemsContent = GetOpsDashboardFundingTableContentV2_all_ops_dashboard_funding_table_items_flag_referenceConnection_edges_node;

export const useGetOpsDashboardFundingTableContentV2 = (
  options?: QueryHookOptions<GetOpsDashboardFundingTableContentV2, GetOpsDashboardFundingTableContentV2Variables>,
): QueryResult<GetOpsDashboardFundingTableContentV2, GetOpsDashboardFundingTableContentV2Variables> => {
  return useContentstackQuery(queries.GetOpsDashboardFundingTableContentV2, options);
};
