import { GetCustodialMinorModalContent, GetCustodialMinorModalContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetCustodialMinorModalContent = (
  options?: QueryHookOptions<GetCustodialMinorModalContent, GetCustodialMinorModalContentVariables>,
) => {
  return useContentstackQuery(queries.GetCustodialMinorModalContent, options);
};
