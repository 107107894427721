import { Dispatch, SetStateAction, useState } from 'react';

export const useCarousel = (
  itemCount: number,
  initialFocused?: number,
): {
  focused: number;
  next: () => void;
  previous: () => void;
  setFocused: Dispatch<SetStateAction<number>>;
} => {
  if (initialFocused && (initialFocused < 0 || initialFocused > itemCount - 1)) {
    throw new Error('invalid value for initialFocused');
  }

  const [focused, setFocused] = useState(initialFocused ?? 0);

  const next = () => {
    setFocused(currentValue => Math.min(itemCount - 1, currentValue + 1));
  };

  const previous = () => {
    setFocused(currentValue => Math.max(0, currentValue - 1));
  };

  return { focused, next, previous, setFocused };
};
