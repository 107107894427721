import {
  GetOpsDashboardContentV2,
  GetOpsDashboardContentV2_all_account_type_items,
  GetOpsDashboardContentV2Variables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type OpsDashboardAccountTypes = GetOpsDashboardContentV2_all_account_type_items;
export type OpsDashboardContentV2 = GetOpsDashboardContentV2;

export const useGetOpsDashboardContentV2 = (
  options?: QueryHookOptions<GetOpsDashboardContentV2, GetOpsDashboardContentV2Variables>,
): QueryResult<GetOpsDashboardContentV2, GetOpsDashboardContentV2Variables> => {
  return useContentstackQuery(queries.GetOpsDashboardContentV2, options);
};
