import {
  GetLinkBankAccountModalContent,
  GetLinkBankAccountModalContent_all_link_bank_account_modal_items,
  GetLinkBankAccountModalContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type LinkBankAccountContent = GetLinkBankAccountModalContent_all_link_bank_account_modal_items;

export const useGetLinkBankAccountContent = (
  options?: QueryHookOptions<GetLinkBankAccountModalContent, GetLinkBankAccountModalContentVariables>,
): QueryResult<GetLinkBankAccountModalContent, GetLinkBankAccountModalContentVariables> => {
  return useContentstackQuery(queries.GetLinkBankAccountModalContent, options);
};
