import React, { ComponentProps, useEffect } from 'react';

import { useLazyGetPerformanceCalculationInfoModal } from './contentstack';

import { Modal } from '~/components/ui/Modal';
import { Button } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
};

export const PerformanceCalculationInfo: React.FC<Props> = ({
  dataQa = 'view-performance-calulcation-info',
  contentOptions,
  open,
  maxWidth = 'sm',
  ...modalProps
}) => {
  const [
    getContent,
    { data: contentstackData, loading: contentstackLoading, error: contentstackError },
  ] = useLazyGetPerformanceCalculationInfoModal({
    variables: contentOptions,
  });
  useEffect(() => {
    if (open && !contentstackData) {
      getContent();
    }
  }, [contentstackData, getContent, open]);
  const content = contentstackData?.all_performance_calculation_info_modal?.items?.[0];
  const isMobileScreen = useIsMediumScreen();
  return (
    <Modal
      actions={
        <Button fullWidth={isMobileScreen} onClick={modalProps.onClose as () => void} variant="outlined">
          {content?.close_cta}
        </Button>
      }
      content={
        <Typography data-qa={`${dataQa}-content`} sx={{ pt: 2 }} variant="body2">
          {content?.body}
        </Typography>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={contentstackError}
      loading={contentstackLoading}
      maxWidth={maxWidth}
      open={open}
      title={content?.heading ?? ''}
      {...modalProps}
    />
  );
};
