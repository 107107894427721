import { STATE_TAX_ELIGIBILITY_INDICATORS, WITHHOLD_OPTIONS } from '.';

import { ValidateCashTransferResponse } from '~/components/modals/WithdrawFunds/symphony';
import { DropdownItem } from '~/components/ui/Dropdown/types';

export interface StateTaxWithholdingOptionsHookVariables {
  federalTaxWithholding?: string;
  stateTaxWithholdingOptions: DropdownItem[];
  validateCashTransferResponse: ValidateCashTransferResponse;
}

export const getDropdownItems = (
  options: ({ label?: string | null; value?: string | null } | null)[],
): DropdownItem[] => {
  return options?.map(item => ({ label: item?.label ?? '', value: item?.value ?? '' })) ?? [];
};

export const getStateTaxWithholdingOptions = ({
  federalTaxWithholding,
  stateTaxWithholdingOptions,
  validateCashTransferResponse,
}: StateTaxWithholdingOptionsHookVariables): DropdownItem[] => {
  const stateTaxMinimumNotApplicableOptions = stateTaxWithholdingOptions.filter(
    option => option.value !== WITHHOLD_OPTIONS.WITHHOLD_MINIMUM,
  );
  const stateTaxRequiredWithMinimumOptions = stateTaxWithholdingOptions.filter(
    option => option.value !== WITHHOLD_OPTIONS.DO_NOT_WITHHOLD,
  );
  const stateTaxRequiredWithoutMinimumOptions = stateTaxWithholdingOptions.filter(
    option => option.value === WITHHOLD_OPTIONS.WITHHOLD,
  );
  const stateTaxNotApplicableOptions = stateTaxWithholdingOptions.filter(
    option => option.value === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD,
  );

  if (!validateCashTransferResponse?.stateWithholdingTaxIndicator) {
    return stateTaxMinimumNotApplicableOptions;
  }

  switch (validateCashTransferResponse.stateTaxEligibilityCode) {
    case STATE_TAX_ELIGIBILITY_INDICATORS.OptOut:
    case STATE_TAX_ELIGIBILITY_INDICATORS.OptIn:
    case STATE_TAX_ELIGIBILITY_INDICATORS.Voluntary:
      return validateCashTransferResponse.stateWithholdingTaxMinimumIndicator
        ? stateTaxWithholdingOptions
        : stateTaxMinimumNotApplicableOptions;
    case STATE_TAX_ELIGIBILITY_INDICATORS.Mandatory:
      if (validateCashTransferResponse.stateWithholdingTaxMinimumIndicator) {
        return federalTaxWithholding === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD
          ? stateTaxWithholdingOptions
          : stateTaxRequiredWithMinimumOptions;
      } else {
        return federalTaxWithholding === WITHHOLD_OPTIONS.DO_NOT_WITHHOLD
          ? stateTaxMinimumNotApplicableOptions
          : stateTaxRequiredWithoutMinimumOptions;
      }
    case STATE_TAX_ELIGIBILITY_INDICATORS.NoStateTax:
      return stateTaxNotApplicableOptions;
    case null:
    case undefined:
      return stateTaxMinimumNotApplicableOptions;
    default:
      return stateTaxWithholdingOptions;
  }
};
