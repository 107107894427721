import { endOfYesterday, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { QuarterlyAssets, useGetQuarterlyPerformanceReport } from './symphony';
import {
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_portfolioSummary,
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_sleevesAllocation,
} from './symphony/__generated__/query.v2';

import { GetAccountActivity_client_financialAccounts_financialAccountSummary } from '~/components/AccountActivity/symphony/__generated__/query.v2';
import { useGetCompositeModelPortfolioAllocationContent } from '~/components/CompositeModelPortfolioAllocation/contentstack';
import {
  CompositeModelPortfolioAllocationContent,
  getCompositeModelPortfolioAllocationContent,
} from '~/components/CompositeModelPortfolioAllocation/hooks';
import { getStartDateEndDateOfQuarter, Quarter } from '~/hooks/account-details/utils';
import { useGetClientInfo } from '~/hooks/client/symphony';
import { GetClientInfo } from '~/hooks/client/symphony/__generated__/query.v2';
import { useGetAllModelPortfoliosContentAcrossPages } from '~/hooks/model-portfolios/contentstack';
import { ContentOptions } from '~/utils/contentstack';
import { toSymphonyDate } from '~/utils/symphony';
import { AsyncResult } from '~/utils/types';

export interface AccountActivityVariables {
  contentOptions: ContentOptions;
  managedProductId: string;
  partyId: string;
  partyIdFA: string;
  quarter: string;
  securityIds: string[];
}

export interface ModelPortfolioContent {
  modelPortfolioIdentifier: string;
  modelPortfolioName: string;
  modelPortfolioSeriesBaseName: string;
}

export interface OpeningClosingBalance {
  closingBalance?: string | null;
  difference?: string | null;
  openingBalance?: string | null;
}

export interface QprData {
  accountActivityQuarterData?: GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_portfolioSummary | null;
  accountActivityYearData?: GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_portfolioSummary | null;
  assets?: QuarterlyAssets;
  clientInfo?: GetClientInfo;
  faInfo?: GetClientInfo;
  modelPortfolioSeriesBaseName?: string | null;
  modelPortfoliosContentData: ModelPortfolioContent[];
  openingClosingQuarterBalance?: OpeningClosingBalance;
  openingClosingYearBalance?: OpeningClosingBalance;
  sleeves?: GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_sleevesAllocation[] | null;
  sleevesContent: CompositeModelPortfolioAllocationContent;
}

/**
 * @param accountActivityData - An optional param of type GetAccountActivity_client_financialAccounts_financialAccountSummary.
 * @returns {OpeningClosingBalance | undefined} The opening and closing balance by sorting the list and picking the head and tail from the array.
 */
export const getOpeningClosingBalance = (
  accountActivityData?: GetAccountActivity_client_financialAccounts_financialAccountSummary | null,
): OpeningClosingBalance | undefined => {
  const dailyValues = accountActivityData?.dailyFinancialAccountValues?.reduce((acc, val) => {
    if (!!val?.portfolioValue && !!val.date) {
      return [...acc, { date: val.date, portfolioValue: val.portfolioValue }];
    }
    return acc;
  }, new Array<{ date: string; portfolioValue: string }>());

  const sortedAccountActivityData = dailyValues
    ?.sort((v1, v2) => parseISO(v1.date).getTime() - parseISO(v2.date).getTime())
    .map(v => v.portfolioValue);

  if (sortedAccountActivityData?.length) {
    const openingBalance = sortedAccountActivityData[0];
    const closingBalance = sortedAccountActivityData[sortedAccountActivityData.length - 1];

    const difference =
      closingBalance && openingBalance ? (Number(closingBalance) - Number(openingBalance)).toString() : undefined;
    return {
      openingBalance,
      closingBalance,
      difference,
    };
  }
  return;
};

export const useGetDataForQPR = ({
  variables: { contentOptions, managedProductId, partyId, partyIdFA, quarter, securityIds },
  skip,
}: {
  skip?: boolean;
  variables: AccountActivityVariables;
}): AsyncResult<QprData> => {
  const [state, setState] = useState<AsyncResult<QprData>>({
    loading: !skip,
  });

  const { yearStartDate, yearEndDate, startDate, endDate } = useMemo(() => {
    const [selectedQuarter, selectedYear] = quarter.split(' ');
    const [selectedYearStartDate, selectedYearEndDate] = [`${selectedYear}-01-01`, toSymphonyDate(endOfYesterday())];
    return {
      yearStartDate: selectedYearStartDate,
      yearEndDate: selectedYearEndDate,
      ...getStartDateEndDateOfQuarter(selectedQuarter as Quarter, selectedYear),
    };
  }, [quarter]);

  const { data, loading: quarterlyReportLoading, error: quarterlyReportError } = useGetQuarterlyPerformanceReport({
    variables: { managedProductId, startDate, endDate, securityIds },
    skip,
  });

  const { data: cmpData, loading: cmpLoading, error: cmpError } = useGetCompositeModelPortfolioAllocationContent({
    variables: contentOptions,
    skip,
  });

  const { data: dataForYear, loading: loadingForYear, error: yearlyReportError } = useGetQuarterlyPerformanceReport({
    variables: { managedProductId, startDate: yearStartDate, endDate: yearEndDate, securityIds },
    skip,
  });

  const { data: clientInfo, loading: clientInfoLoading, error: clientInfoError } = useGetClientInfo({
    variables: { partyId },
    skip,
  });

  const { data: faInfo, loading: faInfoLoading, error: faInfoError } = useGetClientInfo({
    variables: { partyId: partyIdFA },
    skip,
  });

  const {
    data: modelPortfoliosContentData,
    loading: modelPortfoliosContentLoading,
    error: modelPortfoliosContentError,
  } = useGetAllModelPortfoliosContentAcrossPages({
    contentOptions,
    skip,
  });

  useEffect(() => {
    if (skip) {
      return;
    }

    const loading = [
      clientInfoLoading,
      cmpLoading,
      faInfoLoading,
      loadingForYear,
      modelPortfoliosContentLoading,
      quarterlyReportLoading,
    ];
    const error = [
      clientInfoError,
      cmpError,
      faInfoError,
      modelPortfoliosContentError,
      quarterlyReportError,
      yearlyReportError,
    ];

    if (loading.includes(true)) {
      setState({ loading: true });
      return;
    }

    const currentError = error.find(el => !!el);
    if (currentError) {
      console.error(currentError);
      setState({
        loading: false,
        error: currentError,
      });
      return;
    }

    const accountActivityQuarterData = data?.managedProduct?.quarterlyPerformanceReport?.portfolioSummary;
    const accountActivityYearData = dataForYear?.managedProduct?.quarterlyPerformanceReport?.portfolioSummary;
    const quarterlyPerformanceReport = data?.managedProduct?.quarterlyPerformanceReport;
    setState({
      loading: false,
      data: {
        accountActivityQuarterData,
        accountActivityYearData,
        assets: quarterlyPerformanceReport?.allocations?.guidance?.diversification?.assets,
        clientInfo,
        faInfo,
        modelPortfoliosContentData:
          modelPortfoliosContentData?.all_model_portfolio_data?.items?.map(item => ({
            modelPortfolioIdentifier: item?.internal_id ?? '',
            modelPortfolioName: item?.model_portfolio_name_shorthand || 'Unknown',
            modelPortfolioSeriesBaseName: item?.series_name ?? '',
          })) ?? [],
        modelPortfolioSeriesBaseName: quarterlyPerformanceReport?.sleevesAllocation?.[0]?.modelPortfolioSeriesBaseName,
        openingClosingQuarterBalance: getOpeningClosingBalance(accountActivityQuarterData),
        openingClosingYearBalance: getOpeningClosingBalance(accountActivityYearData),
        sleeves: quarterlyPerformanceReport?.sleevesAllocation,
        sleevesContent: getCompositeModelPortfolioAllocationContent(cmpData),
      },
    });
  }, [
    clientInfo,
    clientInfoError,
    clientInfoLoading,
    cmpData,
    cmpError,
    cmpLoading,
    data,
    dataForYear,
    faInfo,
    faInfoError,
    faInfoLoading,
    loadingForYear,
    modelPortfoliosContentData?.all_model_portfolio_data?.items,
    modelPortfoliosContentError,
    modelPortfoliosContentLoading,
    quarterlyReportError,
    quarterlyReportLoading,
    skip,
    yearlyReportError,
  ]);

  return state;
};
