import React, { ComponentProps, useCallback } from 'react';

import { Divider } from '~/components/ui/Divider';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem, DropdownVariant } from '~/components/ui/Dropdown/types';
import { Box, Button, TableContainer, visuallyHidden } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { useLocale } from '~/hooks/locale';
import { MeasurementName } from '~/hooks/performance/types';
import { formatPercentage } from '~/utils/format';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Period {
  id: MeasurementName;
  label: string;
  value?: string;
}

interface Content {
  accessibility: { performanceTimePeriodAriaLabel: string };
  mobile: {
    periodLabel: string;
    title: string;
  };
  yourPortfolioLabel: string;
}

export interface Props extends ComponentProps<typeof TableContainer> {
  buttonGroupProps?: ComponentProps<typeof Box>;
  content: Content;
  dataQa?: string;
  mobileDropdownVariant?: DropdownVariant;
  onPeriodClick: (periodId: MeasurementName) => void;
  periods: Period[];
  selectedPeriodId?: string;
}

export const PerformanceTimePeriodSelect: React.FC<Props> = ({
  buttonGroupProps,
  content: { accessibility, mobile, yourPortfolioLabel },
  dataQa = 'performance-time-period-select',
  mobileDropdownVariant,
  onPeriodClick,
  periods,
  selectedPeriodId = periods[0]?.id,
  sx,
  ...boxProps
}) => {
  const isMobile = useIsMediumScreen();
  const [locale] = useLocale();

  const handlPeriodClick = useCallback(
    (periodId: MeasurementName) => {
      onPeriodClick(periodId);
    },
    [onPeriodClick],
  );

  if (isMobile) {
    const dropdownTimePeriods: DropdownItem[] = periods.map(period => ({
      disabled: !period.value,
      label: (
        <RteContent
          config={{
            period: period.label,
            percentage: period.value ? `(${formatPercentage(parseFloat(period.value))})` : '(--%)',
          }}
          data={mobile.periodLabel}
        />
      ),
      value: period.id,
    }));
    return (
      <Box sx={{ mt: 2 }}>
        <Dropdown
          items={dropdownTimePeriods}
          label={mobile.title}
          onChange={event => {
            handlPeriodClick(event.target.value as MeasurementName);
          }}
          value={selectedPeriodId}
          variant={mobileDropdownVariant}
          variantMenu="menu"
          width="100%"
        />
      </Box>
    );
  }

  return (
    <TableContainer
      data-qa={dataQa}
      sx={{
        display: 'flex',
        bgcolor: 'grey.50',
        ...sx,
      }}
      {...boxProps}
    >
      <Typography
        aria-hidden="true"
        component="div"
        sx={{ flex: '0 1 15%', alignSelf: 'flex-end', px: 2, pb: 2, whiteSpace: 'nowrap' }}
        variant="body2"
      >
        <Divider sx={{ my: 2, mr: -2 }} />
        {yourPortfolioLabel}
      </Typography>
      <Box
        aria-label={accessibility.performanceTimePeriodAriaLabel}
        role="group"
        sx={{
          display: 'flex',
          flex: '1 0 auto',
        }}
        {...buttonGroupProps}
      >
        {periods.map((period, index) => {
          return (
            <Button
              aria-hidden={!period.value}
              disabled={!period.value}
              key={index}
              onClick={period.value ? () => handlPeriodClick(period.id) : undefined}
              size="large"
              sx={[
                {
                  textAlign: 'left',
                  color: 'text.primary',
                  display: 'block',
                  flex: '1 0 auto',
                  borderTop: '3px solid transparent',
                  borderRadius: '4px',
                  p: 2,
                  '&.Mui-disabled': {
                    color: 'text.primary',
                  },
                  '&:last-of-type .MuiDivider-root': {
                    mr: 0,
                  },
                },
                selectedPeriodId === period.id && {
                  bgcolor: 'white',
                  borderTop: theme => `3px solid ${theme.palette.primary.main}`,
                  boxShadow: `0px 0px 20px rgba(0, 0, 0, 0.2)`,
                },
              ]}
            >
              <Typography sx={{ whiteSpace: 'nowrap' }} variant="body2">
                {period.label}
              </Typography>
              <Divider sx={{ my: 2, mx: -2 }} />
              <Typography sx={{ whiteSpace: 'nowrap' }} variant="body2">
                {period.value
                  ? formatPercentage(parseFloat(period.value), {
                      decimals: 2,
                      removeTrailingZeroes: false,
                      locale,
                    })
                  : '--'}
              </Typography>
              {selectedPeriodId === period.id && <Box style={visuallyHidden}>Selected</Box>}
            </Button>
          );
        })}
      </Box>
    </TableContainer>
  );
};
