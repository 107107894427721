import { GetStatementsTabContent, GetStatementsTabContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type StatementsTabContent = GetStatementsTabContent;

export const useGetStatementsTabContent = (
  options?: QueryHookOptions<GetStatementsTabContent, GetStatementsTabContentVariables>,
): QueryResult<GetStatementsTabContent, GetStatementsTabContentVariables> => {
  return useContentstackQuery(queries.GetStatementsTabContent, options);
};
