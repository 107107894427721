import { useMemo } from 'react';

import { useGetAccountDetailsV2Content } from '../contentstack';

import { getContent } from './utils';

import { AccountDetailsHeaderContent, AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult, TextField } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

export interface Content {
  accountDetailsHeader: AccountDetailsHeaderContent;
  accountNumberFormat: string;
  advisorLabel: string;
  clientLabel: string;
  editPortfolioFeedbackMessage: string;
  tabHeader: Partial<Record<AccountDetailsTabsEnum, string>>;
  textFields: (TextField | null)[] | null;
}

export const useContent = ({ contentOptions }: Variables): AsyncResult<Content> => {
  const {
    components: {
      sfAccountDetailsV2: { tabs },
    },
  } = useCoreConfig();

  const { data: content, loading, error } = useGetAccountDetailsV2Content({
    variables: contentOptions,
  });

  const data = useMemo(() => getContent(tabs, content), [content, tabs]);

  return {
    loading,
    error,
    data,
  };
};
