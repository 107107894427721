import * as React from 'react';

import { Divider } from '~/components/ui/Divider';
import { Donut, DonutSlice } from '~/components/ui/Donut';
import { ModalLink } from '~/components/ui/ModalLink';
import { Box, FiberManualRecordIcon, Grid, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { Diversification } from '~/containers/Plan/types';
import { getNormalizedStockBondPercentage, getStockBondRatio } from '~/containers/Plan/utils';
import { ModelPortfolioAllocationData, PortfolioAssetDetailsContent } from '~/hooks/comparison/utils';
import { formatCurrency, formatPercentage, formatPercentageString } from '~/utils/format';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { SfTheme } from '~/utils/theme';

export interface Props {
  allocations?: ModelPortfolioAllocationData[];
  clientFee?: string;
  content: PortfolioAssetDetailsContent;
  dataQa?: string;
  donutProps?: { hideInnerText: boolean; innerRadius: number; size: number };
  isCopilot: boolean;
  minimumInvestmentAmount?: number;
  modelPortfolioType: string;
  openModal?: () => void;
  portfolioAssetsBreakdown: DonutSlice[];
  portfolioRiskLabel: string;
  setModalDetails?: any;
  setPortfolioToViewInModal?: (index: number) => void;
  showAssetAllocation?: boolean;
  stockBondRatio: Diversification;
}

export const PortfolioAssetDetails: React.FC<Props> = ({
  allocations,
  clientFee,
  dataQa = 'portfolio-asset-details',
  minimumInvestmentAmount,
  portfolioAssetsBreakdown,
  portfolioRiskLabel,
  isCopilot,
  stockBondRatio,
  openModal,
  setModalDetails,
  setPortfolioToViewInModal,
  modelPortfolioType,
  content,
  showAssetAllocation = true,
  donutProps,
}) => {
  const {
    sfComparison: { typographyVariants },
  } = useTheme<SfTheme>();
  const handleClick = (idx: number) => {
    setModalDetails(modelPortfolioType);
    setPortfolioToViewInModal?.(idx);
    openModal?.();
  };
  const isMobile = useIsMediumScreen();

  const { stockPercentage, bondPercentage } = getNormalizedStockBondPercentage(stockBondRatio);
  const [stockLabel, bondLabel] = content.stockBondLabel.split('/');

  const minimumInvestmentTemplate = () => {
    return (
      minimumInvestmentAmount && (
        <>
          <Divider sx={{ my: 0.5 }} />
          <Grid container justifyContent="space-evenly" sx={{ my: 0.5 }}>
            <Grid item xs={showAssetAllocation ? 9 : 7}>
              <Typography variant="body2">{content.minimumInvestmentLabel}</Typography>
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={3}>
              <Typography variant="body2">{formatCurrency(minimumInvestmentAmount)}</Typography>
            </Grid>
          </Grid>
        </>
      )
    );
  };
  return (
    <Box data-qa={`${dataQa}-container`} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography sx={{ m: 'auto' }} variant="subtitle2">
        {modelPortfolioType === 'current' ? content.currentPortfolioHeading : content.newPortfolioHeading}
      </Typography>
      {clientFee && <RteContent config={{ clientFee }} data={content.clientFee} sx={{ m: 'auto' }} />}
      <Box sx={{ flex: '1 1 0', mx: 'auto' }}>
        {isMobile ? (
          <Box sx={{ py: 2 }}>
            <Typography pr={0.5} sx={{ display: 'inline' }}>
              {formatPercentageString(stockPercentage)}
            </Typography>
            <Typography sx={{ color: 'text.secondary', display: 'inline' }} variant="caption">
              {stockLabel.trim()}
            </Typography>
            <FiberManualRecordIcon sx={{ color: 'primary.main', mx: 2, height: 10 }} />
            <Typography pr={0.5} sx={{ display: 'inline' }}>
              {formatPercentageString(bondPercentage)}
            </Typography>
            <Typography sx={{ color: 'text.secondary', display: 'inline' }} variant="caption">
              {bondLabel.trim()}
            </Typography>
          </Box>
        ) : (
          <Donut
            centerSubtext={!donutProps?.hideInnerText ? content.stockBondLabel : undefined}
            centerSubtextVariant={typographyVariants?.stockBondSplitSubTextLabel}
            centerText={!donutProps?.hideInnerText ? getStockBondRatio(stockBondRatio) : undefined}
            data={portfolioAssetsBreakdown}
            innerRadius={donutProps?.innerRadius ?? 50}
            label={content.donutAllocationLabel}
            size={donutProps?.size ?? 200}
          />
        )}
      </Box>
      {allocations && allocations.length > 1 ? (
        <>
          {showAssetAllocation && <Divider sx={{ mb: 0.5 }} />}
          <Box sx={showAssetAllocation ? { minHeight: '14vh' } : {}}>
            {allocations.map((allocation, idx) => (
              <Grid container justifyContent="space-evenly" key={idx} sx={{ my: 0.5 }}>
                <Grid item xs={showAssetAllocation ? 9 : 7}>
                  {showAssetAllocation ? (
                    <ModalLink
                      data-qa={`${dataQa}-view-asset-allocation`}
                      onClick={() => handleClick(idx)}
                      sx={{ textDecoration: 'underline', color: 'text.primary', textAlign: 'left' }}
                      variant="body2"
                    >
                      {allocation.modelPortfolioName}
                    </ModalLink>
                  ) : (
                    <Typography sx={{ margin: 'auto' }} variant="body2">
                      {allocation.modelPortfolioName}
                    </Typography>
                  )}
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={3}>
                  <Typography variant="body2">
                    {formatPercentage(parseFloat(allocation.percentage ?? '0') / 100, {
                      decimals: 0,
                      removeTrailingZeroes: true,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
          {showAssetAllocation && minimumInvestmentTemplate()}
        </>
      ) : (
        <>
          {showAssetAllocation ? (
            <>
              <Typography sx={{ margin: 'auto', mt: 2, textAlign: 'center' }}>
                {
                  // TODO: https://sigfig.atlassian.net/browse/INV-4778
                  isCopilot ? (allocations && allocations[0].modelPortfolioName) || '' : portfolioRiskLabel
                }
              </Typography>
              <ModalLink
                data-qa={`${dataQa}-show-asset-allocation-modal`}
                onClick={() => handleClick(0)}
                sx={{ display: 'inline', textDecoration: 'underline', my: 3.5 }}
                variant="body2"
              >
                {content.modalLinkText}
              </ModalLink>
              {minimumInvestmentTemplate()}
            </>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ mb: 2 }}>{allocations && allocations[0].modelPortfolioName}</Typography>
              <Typography sx={{ margin: 'auto', mb: 2 }} variant="body2">
                {portfolioRiskLabel}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
