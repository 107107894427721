import { endOfYesterday } from 'date-fns';
import React from 'react';

import { GetAccountActivityContent } from './contentstack/__generated__/query.v2';
import { Transactions } from './symphony';
import {
  AccountActivityColumnKeys,
  AccountActivityContent,
  AccountActivityContentKeys,
  FilterConfig,
  FilterContent,
  FilterValues,
  MobileSortOption,
  TransactionTypeFilter,
} from './types';

import { Field, FinancialAccountTransactionType, OrderType, SortInput } from '~/__generated__';
import { DateRangeOptions } from '~/components/ControlFields/DateFilters/types';
import { SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { Box } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { findFieldValue } from '~/utils/contentstack';
import { formatCurrency } from '~/utils/format';
import { formatDate } from '~/utils/format/date';

export const getAccountActivityContent = (
  content: GetAccountActivityContent,
  onSort: (field: string) => () => void,
): AccountActivityContent => {
  const textContent = content.all_account_details_account_activity?.items?.[0]?.fields?.text ?? [];
  const rteContent = content.all_account_details_account_activity?.items?.[0]?.fields?.rte ?? [];

  const tableColumns: TableColumn[] = [
    {
      key: AccountActivityColumnKeys.DATE,
      onSort,
      title: findFieldValue(textContent, AccountActivityContentKeys.TABLE_COLUMN_DATE),
    },
    {
      key: AccountActivityColumnKeys.DESCRIPTION,
      title: findFieldValue(textContent, AccountActivityContentKeys.TABLE_COLUMN_DESCRIPTION),
      wrappable: true,
    },
    {
      key: AccountActivityColumnKeys.SYMBOL,
      title: findFieldValue(textContent, AccountActivityContentKeys.TABLE_COLUMN_SYMBOL),
    },
    {
      key: AccountActivityColumnKeys.AMOUNT,
      title: findFieldValue(textContent, AccountActivityContentKeys.TABLE_COLUMN_AMOUNT),
    },
  ];

  const filter = {
    dateRange: findFieldValue(textContent, AccountActivityContentKeys.DATE_RANGE),
    dateRangeOptionLabels: {
      [DateRangeOptions.Custom]: findFieldValue(textContent, AccountActivityContentKeys.DATE_RANGE_CUSTOM),
      [DateRangeOptions.Last30Days]: findFieldValue(textContent, AccountActivityContentKeys.DATE_RANGE_LAST_30_DAYS),
      [DateRangeOptions.Last60Days]: findFieldValue(textContent, AccountActivityContentKeys.DATE_RANGE_LAST_60_DAYS),
      [DateRangeOptions.Last90Days]: findFieldValue(textContent, AccountActivityContentKeys.DATE_RANGE_LAST_90_DAYS),
      [DateRangeOptions.YTD]: findFieldValue(textContent, AccountActivityContentKeys.DATE_RANGE_YTD),
    },
    endDate: findFieldValue(textContent, AccountActivityContentKeys.END_DATE),
    endDateErrorMessage: findFieldValue(textContent, AccountActivityContentKeys.END_DATE_ERROR_MESSAGE),
    invalidDateRangeErrorMessage: findFieldValue(
      textContent,
      AccountActivityContentKeys.INVALID_DATE_RANGE_ERROR_MESSAGE,
    ),
    filter: findFieldValue(textContent, AccountActivityContentKeys.FILTER_LABEL),
    closeFilter: findFieldValue(textContent, AccountActivityContentKeys.FILTER_CLOSE),
    minDateErrorMessage: findFieldValue(rteContent, AccountActivityContentKeys.MIN_DATE_ERROR_MESSAGE),
    startDate: findFieldValue(textContent, AccountActivityContentKeys.START_DATE),
    startDateErrorMessage: findFieldValue(textContent, AccountActivityContentKeys.START_DATE_ERROR_MESSAGE),
    transactionType: findFieldValue(textContent, AccountActivityContentKeys.TRANSACTION_TYPES),
    transactionTypeOptionLabels: {
      [TransactionTypeFilter.ALL]: findFieldValue(textContent, AccountActivityContentKeys.TRANSACTION_TYPES_ALL),
      [TransactionTypeFilter.FEES]: findFieldValue(textContent, AccountActivityContentKeys.TRANSACTION_TYPES_FEES),
      [TransactionTypeFilter.FUNDS_TRANSFER]: findFieldValue(
        textContent,
        AccountActivityContentKeys.TRANSACTION_TYPES_FUNDS_TRANSFER,
      ),
      [TransactionTypeFilter.INCOME]: findFieldValue(textContent, AccountActivityContentKeys.TRANSACTION_TYPES_INCOME),
      [TransactionTypeFilter.OTHER]: findFieldValue(textContent, AccountActivityContentKeys.TRANSACTION_TYPES_OTHER),
      [TransactionTypeFilter.REINVESTMENT]: findFieldValue(
        textContent,
        AccountActivityContentKeys.TRANSACTION_TYPES_REINVESTMENT,
      ),
      [TransactionTypeFilter.TRADE_ACTIVITY]: findFieldValue(
        textContent,
        AccountActivityContentKeys.TRANSACTION_TYPES_TRADE_ACTIVITY,
      ),
    },
  };

  return {
    filter,
    mobileSort: {
      label: findFieldValue(textContent, AccountActivityContentKeys.SORT_FILTER),
      mobileSortOptionLabels: {
        [MobileSortOption.DATE_ASCENDING]: findFieldValue(
          textContent,
          AccountActivityContentKeys.SORT_FILTER_DATE_ASCENDING,
        ),
        [MobileSortOption.DATE_DESCENDING]: findFieldValue(
          textContent,
          AccountActivityContentKeys.SORT_FILTER_DATE_DESCENDING,
        ),
      },
    },
    disclaimer: findFieldValue(textContent, AccountActivityContentKeys.DISCLAIMER),
    download: findFieldValue(textContent, AccountActivityContentKeys.DOWNLOAD),
    table: {
      descriptions: {
        [FinancialAccountTransactionType.ADVISORY_FEE]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_FEES,
        ),
        [FinancialAccountTransactionType.BOND_OPTION_TRADE]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_BOND_OPTION_TRADE,
        ),
        [FinancialAccountTransactionType.CAPITAL_GAINS]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_CAPITAL_GAINS,
        ),
        [FinancialAccountTransactionType.CAPITAL_GAINS_REINVESTED]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_CAPITAL_GAINS_REINVESTED,
        ),
        [FinancialAccountTransactionType.DIVIDEND_RECEIVED]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_DIVIDEND_RECEIVED,
        ),
        [FinancialAccountTransactionType.DIVIDEND_REINVESTED]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_DIVIDEND_REINVESTED,
        ),
        [FinancialAccountTransactionType.FUNDS_TRANSFER]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_FUNDS_TRANSFER,
        ),
        [FinancialAccountTransactionType.INTEREST_RECEIVED]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_INTEREST_RECEIVED,
        ),
        [FinancialAccountTransactionType.INTEREST_REINVESTED]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_INTEREST_REINVESTED,
        ),
        [FinancialAccountTransactionType.OTHER]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_OTHER,
        ),
        [FinancialAccountTransactionType.REINVESTMENT]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_REINVESTMENT,
        ),
        [FinancialAccountTransactionType.RETIREMENT_CONTRIBUTION]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_RETIREMENT_CONTRIBUTION,
        ),
        [FinancialAccountTransactionType.SECURITY_NAME_CHANGE]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_SECURITY_NAME_CHANGE,
        ),
        [FinancialAccountTransactionType.TRADE]: findFieldValue(
          textContent,
          AccountActivityContentKeys.TABLE_DESCRIPTION_TRADE,
        ),
      },
      emptyMessage: findFieldValue(textContent, AccountActivityContentKeys.EMPTY_MESSAGE),
      emptyMessageWithCTA: {
        after: findFieldValue(textContent, AccountActivityContentKeys.EMPTY_MESSAGE_AFTER_CTA),
        before: findFieldValue(textContent, AccountActivityContentKeys.EMPTY_MESSAGE_BEFORE_CTA),
        cta: findFieldValue(textContent, AccountActivityContentKeys.EMPTY_MESSAGE_CTA),
      },
      symbolPlaceholder: findFieldValue(textContent, AccountActivityContentKeys.SYMBOL_PLACEHOLDER),
      tableColumns,
    },
  };
};

export const getFilterFields = (
  content: FilterContent,
  dateRangeOptions: DateRangeOptions[],
  defaultValues: FilterValues,
  accountCreatedDate?: Date,
): FilterConfig => {
  const customDateRange: FilterConfig['customDateRange'] = {
    defaultValues: defaultValues.dateRange,
    disableFuture: true,
    labels: {
      endDate: content.endDate,
      endDateErrorLabel: content.endDateErrorMessage,
      minDateErrorLabel: content.minDateErrorMessage,
      startDate: content.startDate,
      startDateErrorLabel: content.startDateErrorMessage,
      invalidDateRangeErrorLabel: content.invalidDateRangeErrorMessage,
    },
    maxDate: endOfYesterday(),
    minDate: accountCreatedDate,
  };
  const dateRangeOption: FilterConfig['dateRangeOption'] = {
    defaultValue: defaultValues.dateRangeOption,
    items: dateRangeOptions.map(item => ({
      label: content.dateRangeOptionLabels[item] ?? '',
      value: item,
    })),
    label: content.dateRange,
    maxDate: endOfYesterday(),
    minDate: accountCreatedDate,
  };
  const transactionTypeOption: FilterConfig['transactionTypeOption'] = {
    defaultValue: defaultValues.transactionType,
    items: Object.entries(content.transactionTypeOptionLabels).map(entry => ({
      label: entry[1],
      value: entry[0],
    })),
    label: content.transactionType,
  };
  return { customDateRange, dateRangeOption, transactionTypeOption };
};

export const mapTransactionTypesFilterDropdownToSymphony = (
  transactionTypesFilter?: TransactionTypeFilter,
): FinancialAccountTransactionType[] => {
  switch (transactionTypesFilter) {
    case TransactionTypeFilter.ALL:
      return [
        FinancialAccountTransactionType.ADVISORY_FEE,
        FinancialAccountTransactionType.FUNDS_TRANSFER,
        FinancialAccountTransactionType.RETIREMENT_CONTRIBUTION,
        FinancialAccountTransactionType.CAPITAL_GAINS,
        FinancialAccountTransactionType.DIVIDEND_RECEIVED,
        FinancialAccountTransactionType.INTEREST_RECEIVED,
        FinancialAccountTransactionType.OTHER,
        FinancialAccountTransactionType.CAPITAL_GAINS_REINVESTED,
        FinancialAccountTransactionType.DIVIDEND_REINVESTED,
        FinancialAccountTransactionType.INTEREST_REINVESTED,
        FinancialAccountTransactionType.REINVESTMENT,
        FinancialAccountTransactionType.BOND_OPTION_TRADE,
        FinancialAccountTransactionType.SECURITY_NAME_CHANGE,
        FinancialAccountTransactionType.TRADE,
        FinancialAccountTransactionType.UNKNOWN,
      ];
    case TransactionTypeFilter.FEES:
      return [FinancialAccountTransactionType.ADVISORY_FEE];
    case TransactionTypeFilter.FUNDS_TRANSFER:
      return [FinancialAccountTransactionType.FUNDS_TRANSFER, FinancialAccountTransactionType.RETIREMENT_CONTRIBUTION];
    case TransactionTypeFilter.INCOME:
      return [
        FinancialAccountTransactionType.CAPITAL_GAINS,
        FinancialAccountTransactionType.DIVIDEND_RECEIVED,
        FinancialAccountTransactionType.INTEREST_RECEIVED,
      ];
    case TransactionTypeFilter.OTHER:
      return [FinancialAccountTransactionType.OTHER, FinancialAccountTransactionType.UNKNOWN];
    case TransactionTypeFilter.REINVESTMENT:
      return [
        FinancialAccountTransactionType.CAPITAL_GAINS_REINVESTED,
        FinancialAccountTransactionType.DIVIDEND_REINVESTED,
        FinancialAccountTransactionType.INTEREST_REINVESTED,
        FinancialAccountTransactionType.REINVESTMENT,
      ];
    case TransactionTypeFilter.TRADE_ACTIVITY:
      return [
        FinancialAccountTransactionType.BOND_OPTION_TRADE,
        FinancialAccountTransactionType.SECURITY_NAME_CHANGE,
        FinancialAccountTransactionType.TRADE,
      ];
    default:
      return [];
  }
};

export const formatActivityData = (transactions: Transactions[], content: AccountActivityContent): TableData[] => {
  return transactions.map((transaction, index) => {
    return {
      rowKey: index.toString(),
      date: formatDate(new Date(transaction.transactionDate ?? ''), 'MM/dd/yyyy'),
      description: (
        <Box>
          <Typography variant="body2">{content.table.descriptions[transaction.type] ?? transaction.type}</Typography>
          <Typography variant="caption">{transaction.description}</Typography>
        </Box>
      ),
      symbol: getSymbol(transaction) ?? '-',
      amount: formatCurrency(+transaction.amount),
    };
  });
};

export const formatActivityDataCSV = (
  transactions: Transactions[],
  content: AccountActivityContent,
  separator = ',',
): string => {
  return transactions
    .map(transaction => {
      return [
        formatDate(new Date(transaction.transactionDate ?? ''), 'MM/dd/yyyy'),
        `${content.table.descriptions[transaction.type] ?? transaction.type} ${transaction.description}`,
        getSymbol(transaction) ?? '',
        transaction.amount,
      ].join(separator);
    })
    .join('\n');
};

const getSymbol = (transaction: Transactions): string | undefined => {
  if (transaction.securityData?.ticker && transaction.securityData.ticker.length > 0) {
    return transaction.securityData.ticker;
  } else if (transaction.securityData?.cusip && transaction.securityData.cusip.length > 0) {
    return transaction.securityData.cusip;
  } else if (transaction.securityId && transaction.securityId.length > 0) {
    return transaction.securityId;
  } else {
    return undefined;
  }
};

export const mapSortConfig = (sortConfig: SortConfig): SortInput | undefined => {
  switch (sortConfig.field) {
    case AccountActivityColumnKeys.DATE:
      return { field: Field.TRANSACTION_DATE, order: sortConfig.order };
    case AccountActivityColumnKeys.AMOUNT:
      return { field: Field.AMOUNT, order: sortConfig.order };
    default:
      return undefined;
  }
};

export const mapSortOptionToConfig = (sortFilter: MobileSortOption): SortConfig => {
  switch (sortFilter) {
    case MobileSortOption.DATE_ASCENDING:
      return {
        field: AccountActivityColumnKeys.DATE,
        order: OrderType.ASCENDING,
      };
    case MobileSortOption.DATE_DESCENDING:
    default:
      return {
        field: AccountActivityColumnKeys.DATE,
        order: OrderType.DESCENDING,
      };
  }
};

export const mapSortConfigToOption = (sortConfig: SortConfig): MobileSortOption => {
  switch (sortConfig.field) {
    case AccountActivityColumnKeys.DATE:
      if (sortConfig.order === OrderType.ASCENDING) {
        return MobileSortOption.DATE_ASCENDING;
      } else {
        return MobileSortOption.DATE_DESCENDING;
      }
    default:
      return MobileSortOption.DATE_DESCENDING;
  }
};
