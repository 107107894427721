import { isBefore, startOfYear, subDays } from 'date-fns';

import { DateRangeOptions } from './types';

import { DateRange } from '~/components/ui/DateRangePicker';

/**
 * Computes DateRange values for the selected DateRange options (like Last30Days, Last60Days etc.)
 * @param value - Type DateRangeOptions
 * @param min - Type Date, optional, to overwrite default start date if later than start date
 * @param max - Type Date, optional, else default to today
 * @returns DateRange { startDate: Date, endDate: Date }
 */
export const getDateRangeValue = (value?: DateRangeOptions, min?: Date, max?: Date): DateRange | undefined => {
  const today = new Date();
  const endDate = max && isBefore(max, today) ? max : today;
  switch (value) {
    case DateRangeOptions.Last30Days: {
      const defaultStartDate = subDays(today, 30);
      return {
        startDate: min && isBefore(defaultStartDate, min) ? min : defaultStartDate,
        endDate,
      };
    }
    case DateRangeOptions.Last60Days: {
      const defaultStartDate = subDays(today, 60);
      return {
        startDate: min && isBefore(defaultStartDate, min) ? min : defaultStartDate,
        endDate,
      };
    }
    case DateRangeOptions.Last90Days: {
      const defaultStartDate = subDays(today, 90);
      return {
        startDate: min && isBefore(defaultStartDate, min) ? min : defaultStartDate,
        endDate,
      };
    }
    case DateRangeOptions.YTD: {
      const defaultStartDate = startOfYear(endDate);
      return {
        startDate: min && isBefore(defaultStartDate, min) ? min : defaultStartDate,
        endDate,
      };
    }
    default: {
      return undefined;
    }
  }
};
