import React from 'react';
import { FieldError } from 'react-hook-form';

import { WithdrawFundsModalAmountContent } from './WithdrawFundsModalContent';

import { Alert } from '~/components/ui/Alert';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency } from '~/utils/format';

export interface Props {
  accountMinimum: string;
  availableFunds: number;
  content: WithdrawFundsModalAmountContent;
  contentOptions: ContentOptions;
  fieldError?: FieldError;
  maxAllowedAmount: number;
  obfuscatedAccountNumber: string;
  showAccountMinWarning: boolean;
}

export const WithdrawAmountHelperText: React.FC<Props> = ({
  accountMinimum,
  availableFunds,
  content,
  contentOptions,
  fieldError,
  maxAllowedAmount,
  obfuscatedAccountNumber,
  showAccountMinWarning,
}) => {
  const getLowerLimitMessage = () => (
    <RteContent
      config={{ maskedAccountNumber: obfuscatedAccountNumber, minimumAmount: accountMinimum }}
      data={content.overdrawnMinimumAmountError}
    />
  );

  const getErrorMessage = (type: string) => {
    switch (type) {
      case 'accountMinimum':
        return getLowerLimitMessage();
      case 'min':
        return <>{content.negativeWithdrawalAmountError}</>;
      case 'max':
        const isMaxAllowedAmountLesserThanBalance = maxAllowedAmount < availableFunds;
        return isMaxAllowedAmountLesserThanBalance ? (
          <RteContent
            config={{ maxAllowedAmount: formatCurrency(maxAllowedAmount, { locale: contentOptions.locale }) }}
            data={content.maxAllowedAmountError}
          />
        ) : (
          /**
           * TODO: This error has different typography styles compared to other errors here because RteContent applies
           * its own styles, figure out a way to override this
           */
          <RteContent
            config={{ availableFunds: formatCurrency(availableFunds, { locale: contentOptions.locale }) }}
            data={content.overdrawnWithdrawalAmountError}
          />
        );
      case 'required':
        return <>{content.missingWithdrawalAmountError}</>;
      default:
        return null;
    }
  };

  return (
    <>
      {fieldError && (
        <Alert id="withdrawal-amount-error" severity="error" sx={{ mt: 2 }}>
          {getErrorMessage(fieldError.type)}
        </Alert>
      )}
      {showAccountMinWarning && !fieldError && (
        <Alert id="withdrawal-amount-error" severity="warning" sx={{ mt: 2 }}>
          {getLowerLimitMessage()}
        </Alert>
      )}
    </>
  );
};
