import { GetAccountActivityContent, GetAccountActivityContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetAccountActivityContent = (
  options?: QueryHookOptions<GetAccountActivityContent, GetAccountActivityContentVariables>,
): QueryResult<GetAccountActivityContent, GetAccountActivityContentVariables> => {
  return useContentstackQuery(queries.GetAccountActivityContent, options);
};
