import React from 'react';

import { Typography } from '~/components/ui/Typography';

export enum DatepickerValidations {
  isDate = 'isDate',
  isFutureDateValidation = 'isFutureDateValidation',
  mustBeBusinessDay = 'mustBeBusinessDay',
  mustBeLessThanMaxAdditionDate = 'mustBeLessThanMaxAdditionDate',
  mustBeLessThanMaxWithdrawalDate = 'mustBeLessThanMaxWithdrawalDate',
  mustNotBeAMarketHoliday = 'mustNotBeAMarketHoliday',
}

export interface AddFundsDatePicker {
  error: string;
  invalidDateError: string;
  label: string;
  marketHolidayError: string;
  maxDateError: string;
  monthlyDayPickerLabel: string;
  pastDateError: string;
  weekdayPickerLabel: string;
}

export interface WithdrawFundsDatepicker {
  error: string;
  invalidDateError: string;
  label: string;
  marketHolidayError: string;
  maxDateError: string;
  pastDateError: string;
  retirementAccountDateError: string;
}

export interface DatepickerErrorDisplayArgs {
  addFundsFormContent?: AddFundsDatePicker;
  fieldError: string;
  isRetirementAccount?: boolean;
  withdrawFormContent?: WithdrawFundsDatepicker;
}

export const datepickerErrorDisplay = ({
  fieldError,
  addFundsFormContent,
  withdrawFormContent,
  isRetirementAccount,
}: DatepickerErrorDisplayArgs): React.ReactNode => {
  let msg;
  const formContent = addFundsFormContent ?? withdrawFormContent;
  if (formContent) {
    switch (fieldError) {
      case DatepickerValidations.isFutureDateValidation:
        msg = formContent?.pastDateError;
        break;
      case DatepickerValidations.isDate:
        msg = formContent?.invalidDateError;
        break;
      case DatepickerValidations.mustBeBusinessDay:
        msg = formContent?.error;
        break;
      case DatepickerValidations.mustNotBeAMarketHoliday:
        msg = formContent?.marketHolidayError;
        break;
      case DatepickerValidations.mustBeLessThanMaxAdditionDate:
        msg = formContent?.maxDateError;
        break;
      case DatepickerValidations.mustBeLessThanMaxWithdrawalDate:
        msg = isRetirementAccount
          ? 'retirementAccountDateError' in formContent
            ? formContent?.retirementAccountDateError
            : ''
          : 'maxDateError' in formContent
          ? formContent?.maxDateError
          : '';
        break;
      default:
        msg = '';
    }

    return (
      msg && (
        <Typography
          color="error"
          component="span"
          id={addFundsFormContent ? 'addition-date-error' : 'withdrawal-date-error'}
          role="alert"
          variant="caption"
        >
          {msg}
        </Typography>
      )
    );
  } else {
    return null;
  }
};
