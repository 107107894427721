import React from 'react';

import { FilterContent, FilterData, MobileSortData, MobileSortOption, TransactionTypeFilter } from '../types';

import { DateFilters } from '~/components/ControlFields/DateFilters';
import { Dropdown } from '~/components/ui/Dropdown';
import { Modal } from '~/components/ui/Modal';
import { Button, Stack } from '~/components/ui/mui';

interface Props {
  content: FilterContent;
  dataQa?: string;
  filterData: FilterData;
  onClose: () => void;
  open: boolean;
  sortData: MobileSortData;
}

export const MobileFilter: React.FC<Props> = ({
  content,
  filterData,
  onClose,
  open,
  dataQa = 'filter-section',
  sortData,
}) => {
  return (
    <Modal
      content={
        <Stack spacing={2}>
          <DateFilters
            dateFilterConfig={filterData.config}
            onCustomDateRangeChange={filterData.onCustomDateRangeChange}
            onDateRangeOptionChange={filterData.onDateRangeOptionChange}
            onYearOptionChange={filterData.onYearOptionChange}
          />
          <Dropdown
            dataQa={`${dataQa}-transaction-type-filter`}
            items={filterData.config.transactionTypeOption?.items ?? []}
            label={filterData.config.transactionTypeOption?.label}
            onChange={e => filterData.onTransactionTypeOptionChange?.(e.target.value as TransactionTypeFilter)}
            value={filterData.values.transactionType}
            width="100%"
          />
          <Dropdown
            dataQa={`${dataQa}-sort-filter`}
            items={sortData.config.items}
            label={sortData.config.label}
            onChange={e => sortData.onMobileSortChange(e.target.value as MobileSortOption)}
            value={sortData.value}
            width="100%"
          />
          <Button onClick={onClose} variant="contained">
            {content.closeFilter}
          </Button>
        </Stack>
      }
      onClose={onClose}
      open={open}
      title={content.filter}
    />
  );
};
