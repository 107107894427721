import React, { ComponentProps } from 'react';

import { Box } from '~/components/ui/mui';

export const TargetAllocationImage: React.FC<ComponentProps<typeof Box>> = ({ sx, ...rest }) => {
  return (
    <Box sx={{ height: 118, width: 118, ...sx }} {...rest}>
      <svg fill="none" height="100%" viewBox="0 0 118 118" width="100%" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M59 118C91.5848 118 118 91.5848 118 59C118 26.4152 91.5848 0 59 0C26.4152 0 0 26.4152 0 59C0 91.5848 26.4152 118 59 118Z"
          fill="#FAFAFA"
        />
        <path
          d="M49.331 50.3717L38.6973 47.7129L39.3023 45.2879L48.6685 47.6292L58.6685 38.8792L68.7873 41.4092L78.3373 35.4404L79.6623 37.5604L69.2123 44.0917L59.331 41.6217L49.331 50.3717Z"
          fill="#7849B8"
        />
        <path
          d="M84 52.75V80.25H74V52.75C74 52.06 74.56 51.5 75.25 51.5H82.75C83.44 51.5 84 52.06 84 52.75Z"
          fill="#91DDF8"
        />
        <path d="M74 61.5V80.25H64V61.5H74Z" fill="#009DD6" />
        <path
          d="M64 59V80.25H54V59C54 58.31 54.56 57.75 55.25 57.75H62.75C63.44 57.75 64 58.31 64 59Z"
          fill="#009DD6"
        />
        <path d="M54 67.75V80.25H44V67.75H54Z" fill="#007EAB" />
        <path
          d="M44 64V80.25H34V64C34 63.31 34.56 62.75 35.25 62.75H42.75C43.44 62.75 44 63.31 44 64Z"
          fill="#007EAB"
        />
        <path
          d="M79 39C80.3807 39 81.5 37.8807 81.5 36.5C81.5 35.1193 80.3807 34 79 34C77.6193 34 76.5 35.1193 76.5 36.5C76.5 37.8807 77.6193 39 79 39Z"
          fill="#7849B8"
        />
        <path
          d="M69 45.25C70.3807 45.25 71.5 44.1307 71.5 42.75C71.5 41.3693 70.3807 40.25 69 40.25C67.6193 40.25 66.5 41.3693 66.5 42.75C66.5 44.1307 67.6193 45.25 69 45.25Z"
          fill="#7849B8"
        />
        <path
          d="M59 42.75C60.3807 42.75 61.5 41.6307 61.5 40.25C61.5 38.8693 60.3807 37.75 59 37.75C57.6193 37.75 56.5 38.8693 56.5 40.25C56.5 41.6307 57.6193 42.75 59 42.75Z"
          fill="#7849B8"
        />
        <path
          d="M49 51.5C50.3807 51.5 51.5 50.3807 51.5 49C51.5 47.6193 50.3807 46.5 49 46.5C47.6193 46.5 46.5 47.6193 46.5 49C46.5 50.3807 47.6193 51.5 49 51.5Z"
          fill="#7849B8"
        />
        <path
          d="M39 49C40.3807 49 41.5 47.8807 41.5 46.5C41.5 45.1193 40.3807 44 39 44C37.6193 44 36.5 45.1193 36.5 46.5C36.5 47.8807 37.6193 49 39 49Z"
          fill="#7849B8"
        />
      </svg>
    </Box>
  );
};
