import React, { useEffect, useState } from 'react';

import { BillingHistorySection, useGetBillingHistoryContent } from '../contentstack';
import { useGetBillingHistory } from '../symphony';

import { OrderType } from '~/__generated__';
import { SortConfig, TableColumn } from '~/components/ui/BasicTable';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency, formatPercentage } from '~/utils/format';
import { formatDate } from '~/utils/format/date';
import { sortColumnsByColumnOrder } from '~/utils/table';
import { AsyncResult } from '~/utils/types';

interface BillingHistoryVariables {
  contentOptions: ContentOptions;
  managedProductId: string;
}

interface BillingHistoryComponentData {
  columns: TableColumn[];
  rows: Record<string, React.ReactNode>[];
  sortConfig: SortConfig;
  title: string;
}

export const useBillingHistory = ({
  contentOptions,
  managedProductId,
}: BillingHistoryVariables): AsyncResult<BillingHistoryComponentData> => {
  const [state, setState] = useState<AsyncResult<BillingHistoryComponentData>>({ loading: true });
  const sortConfig: SortConfig = {
    order: OrderType.DESCENDING,
    field: 'date',
  };

  const { data: billingHistoryData, loading: billingHistoryLoading, error: billingHistoryError } = useGetBillingHistory(
    {
      variables: {
        managedProductId,
      },
    },
  );
  const {
    data: billingHistoryContentData,
    loading: billingHistoryContentLoading,
    error: billingHistoryContentError,
  } = useGetBillingHistoryContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (billingHistoryContentError || billingHistoryError) {
      setState({
        error: billingHistoryContentError || billingHistoryError,
        loading: false,
      });
    }
  }, [billingHistoryContentError, billingHistoryError]);

  useEffect(() => {
    if (!billingHistoryContentLoading && billingHistoryContentData && !billingHistoryLoading && billingHistoryData) {
      const billingHistoryContent = billingHistoryContentData.all_account_details?.items?.[0]?.sections?.find(
        (section): section is BillingHistorySection => section?.__typename === 'AccountDetailsSectionsBillingHistory',
      )?.billing_history;

      const billingHistoryColumns = billingHistoryContent?.columns ?? [];
      const sortedColumns = sortColumnsByColumnOrder(billingHistoryColumns);
      const columns = sortedColumns.map(col => ({
        title: col?.column_value ?? '',
        key: col?.column_id ?? '',
      }));

      const billingRecordHistory = billingHistoryData.managedProduct?.billingRecordHistory;

      const billingRecordData =
        billingRecordHistory?.map(record => ({
          billableValue: record.billableValue,
          billingDate: record.billingDate,
          billingStartDate: record.billingStartDate,
          fees: record.fees,
          rate: record.rate,
        })) ?? [];

      const rows = billingRecordData
        .sort((a, b) => (a.billingStartDate < b.billingStartDate ? 1 : -1))
        .map((row, idx) => ({
          id: idx,
          date: formatDate(row.billingDate),
          fees: formatCurrency(parseFloat(row.fees)),
          ratesAndDiscount: formatPercentage(parseFloat(row.rate)),
          billingStartDate: formatDate(row.billingStartDate),
          accountValue: formatCurrency(parseFloat(row.billableValue)),
        }));

      setState({
        data: { columns, rows, sortConfig, title: billingHistoryContent?.title ?? '' },
        loading: false,
      });
    }
  }, [billingHistoryContentLoading, billingHistoryContentData, billingHistoryLoading, billingHistoryData]);

  return state;
};
