import {
  GetGroupedRestrictions_managedProducts_products,
  GetGroupedRestrictionsQuery,
  GetGroupedRestrictionsVariables,
  GetMultiRestrictions,
  GetMultiRestrictions_multiRestrictions_filterContext,
  GetMultiRestrictions_multiRestrictions_multiRestrictions,
  GetMultiRestrictionsVariables,
} from './__generated__/queries.v2';
import * as queries from './queries.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type Restrictions = GetMultiRestrictions_multiRestrictions_multiRestrictions;
export type FilterContext = GetMultiRestrictions_multiRestrictions_filterContext;
export type GroupedRestrictionsManagedProduct = GetGroupedRestrictions_managedProducts_products;

export const useMultiRestrictions = (
  options?: QueryHookOptions<GetMultiRestrictions, GetMultiRestrictionsVariables>,
): QueryResult<GetMultiRestrictions, GetMultiRestrictionsVariables> => {
  return useSymphonyQuery(queries.GetMultiRestrictions, options);
};

export const useGetGroupedRestrictions = (
  options?: QueryHookOptions<GetGroupedRestrictionsQuery, GetGroupedRestrictionsVariables>,
): QueryResult<GetGroupedRestrictionsQuery, GetGroupedRestrictionsVariables> => {
  return useSymphonyQuery(queries.GetGroupedRestrictions, options);
};
