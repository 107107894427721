import debounce from 'lodash/debounce';
import { SyntheticEvent, useEffect, useState } from 'react';

import { SecuritySearchOption } from '.';
import { useLazyGetSecuritySearchResults } from './symphony';

export interface UseTickerSearchVariables {
  includeHoldingsInfo?: boolean;
}

export interface UseTickerSearchData {
  debounceSearchInput: (_event: SyntheticEvent, value: string | null) => void;
  loading: boolean;
  securitySearchOptions?: SecuritySearchOption[];
}

export const useTickerSearch = ({ includeHoldingsInfo }: UseTickerSearchVariables): UseTickerSearchData => {
  const [securitySearchOptions, setSecuritySearchOptions] = useState<SecuritySearchOption[] | undefined>(undefined);
  const [getSecuritySearchResults, { data: securitySearchResultsData, loading }] = useLazyGetSecuritySearchResults({
    fetchPolicy: 'no-cache',
  });

  const onTickerSearchInputChange = (_event: SyntheticEvent, value: string | null) => {
    if (!value) {
      setSecuritySearchOptions(undefined);
      return;
    }
    getSecuritySearchResults({ variables: { keyword: value, includeHoldingsInfo } });
  };

  const debounceSearchInput = debounce(onTickerSearchInputChange, 500);

  useEffect(() => {
    if (securitySearchResultsData?.securitySearch?.length) {
      setSecuritySearchOptions(
        securitySearchResultsData.securitySearch.map(item => ({
          id: item?.name || '',
          name: item?.name || '',
          ticker: item?.ticker || '',
          label: item?.name || '',
          ...(includeHoldingsInfo && {
            holdingsInfo: {
              isManualSecurity: item?.security?.isManualSecurity,
              expenseRatio: item?.security?.fees?.expenseRatio,
              identifier: item?.security?.identifier,
              marketPrice: item?.marketPrices?.[0].value,
              portfolioAssetClassBlend: item?.portfolioAssetClassBlend,
              simpleSecurityType: item?.security?.simpleSecurityType,
            },
          }),
        })),
      );
    } else if (securitySearchResultsData?.securitySearch) {
      setSecuritySearchOptions([]);
    } else {
      setSecuritySearchOptions(undefined);
    }
  }, [securitySearchResultsData, includeHoldingsInfo]);

  return {
    debounceSearchInput,
    securitySearchOptions,
    loading,
  };
};
