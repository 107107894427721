import React, { FC } from 'react';

import { Donut, DonutSlice } from '~/components/ui/Donut';
import { Box, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { SfTheme } from '~/utils/theme';

export interface Props {
  dataQa?: string;
  modelPortfolio: {
    donutAllocations: DonutSlice[];
    donutInnerRadius: number;
    donutShowTooltip: boolean;
    donutSize: number;
  };
  modelPortfolioLabel: string;
  modelPortfolioName: string;
  showLabels?: boolean;
}

export const ModelPortfolioInfo: FC<Props> = ({
  dataQa = 'account-model-portfolio',
  modelPortfolio,
  modelPortfolioLabel,
  modelPortfolioName,
  showLabels,
}) => {
  const {
    sfAccount: { typographyVariants },
  } = useTheme<SfTheme>();

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ mt: -1 }}>
        <Donut
          data={modelPortfolio.donutAllocations}
          dataQa={`${dataQa}-donut`}
          innerRadius={modelPortfolio.donutInnerRadius}
          showTooltip={modelPortfolio.donutShowTooltip}
          size={modelPortfolio.donutSize}
        />
      </Box>
      <Box>
        {showLabels && (
          <Typography sx={{ color: 'text.secondary' }} variant="caption">
            {modelPortfolioLabel}
          </Typography>
        )}
        <Typography component="p" variant={typographyVariants?.portfolioName}>
          {modelPortfolioName}
        </Typography>
      </Box>
    </Box>
  );
};
