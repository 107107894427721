import { format, parseISO } from 'date-fns';
import { pipe } from 'fp-ts/lib/function';

import { ContentOptions } from '~/utils/contentstack';
import { formatCurrencyPrecise } from '~/utils/format';

const formatDate = 'MMM dd, yyyy';

export const formatDisplayDate = (date?: string | null) => {
  return date ? format(parseISO(date), formatDate) : 'Unknown';
};

export const formatBalance = (balance: string, contentOptions: ContentOptions) => {
  return pipe(balance, parseFloat, x => formatCurrencyPrecise(x, { locale: contentOptions.locale }));
};

/**
 * @param securityName - The security name to be formatted.
 * @returns {string} Returns the second token of a security name after splitting on ' - '.
 */
export const formatSecurityName = (securityName: string): string => {
  const tokens = securityName.split(' - ', 2);
  return tokens.length > 1 ? tokens[1] : securityName;
};
