import {
  GetBillingRates,
  GetBillingRates_managedProduct_billingDataV2,
  GetBillingRates_managedProduct_billingDataV2_finalizedBillingRateData_blendedBillingRateComponent,
  GetBillingRatesVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type BillingRates = GetBillingRates_managedProduct_billingDataV2;
export type BlendedRates = GetBillingRates_managedProduct_billingDataV2_finalizedBillingRateData_blendedBillingRateComponent;

export const useGetBillingRates = (options?: QueryHookOptions<GetBillingRates, GetBillingRatesVariables>) => {
  return useSymphonyQuery(queries.GetBillingRates, options);
};
