import * as queries from './query.gql';

import {
  GetApproveModelChangeContent,
  GetApproveModelChangeContentVariables,
} from '~/components/modals/ApproveModelChange/contentstack/__generated__/query.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetApproveModelChangeContent = (
  options?: QueryHookOptions<GetApproveModelChangeContent, GetApproveModelChangeContentVariables>,
): QueryResult<GetApproveModelChangeContent, GetApproveModelChangeContentVariables> => {
  return useContentstackQuery(queries.GetApproveModelChangeContent, options);
};
