import { useCallback } from 'react';

export const useGetAdditionalDates = (
  month: number,
  year: number,
  data: { dataPoints: string[]; date: number; month: number; year: number }[],
) => {
  const getNextDates = useCallback((): [boolean, number, number] => {
    if (month === 12) {
      const newYear = year + 1;
      const newYearData = data.filter(d => d.year === newYear).sort((a, b) => (a.month < b.month ? -1 : 1));
      if (newYearData.length) {
        return [true, newYear, newYearData[0].month];
      }
      return [false, year, month];
    } else {
      const newMonth = month + 1;
      const newMonthData = data.find(d => d.year === year && d.month === newMonth);
      if (newMonthData) {
        return [true, year, newMonth];
      }
      return [false, year, month];
    }
  }, [data, month, year]);

  const getPreviousDates = useCallback((): [boolean, number, number] => {
    if (month === 1) {
      const newYear = year - 1;
      const newYearData = data.filter(d => d.year === newYear).sort((a, b) => (a.month < b.month ? 1 : -1));
      if (newYearData.length) {
        return [true, newYear, newYearData[0].month];
      }
      return [false, year, month];
    } else {
      const newMonth = month - 1;
      const newMonthData = data.find(d => d.year === year && d.month === newMonth);
      if (newMonthData) {
        return [true, year, newMonth];
      }
      return [false, year, month];
    }
  }, [data, month, year]);
  return { getNextDates, getPreviousDates };
};
