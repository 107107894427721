import { addYears, startOfToday } from 'date-fns';
import { useMemo } from 'react';

import { useGetAddFundsV2Content } from '../contentstack';
import { ModalContent } from '../types';
import { getFormContent } from '../utils';

import { useGetClientInfo } from '~/hooks/client/symphony';
import { GetClientInfo } from '~/hooks/client/symphony/__generated__/query.v2';
import {
  GetBankAccountBalances_client_financialAccounts,
  GetBrokerageAccountBalances_client_financialAccounts,
  GetExternalBrokerageAccountBalances_client_financialAccounts,
} from '~/hooks/financial-account/symphony/__generated__/query.v2';
import {
  GetFinancialAccountsVariables,
  useGetFinancialAccountsData,
} from '~/hooks/financial-account/useGetFinancialAccountsData';
import { useGetMarketHolidayList } from '~/hooks/market-holidays/symphony';
import { GetMarketHolidayList } from '~/hooks/market-holidays/symphony/__generated__/query.v2';
import { NetworkStatus } from '~/utils/apollo-client';
import { FundingSources } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { toSymphonyDate } from '~/utils/symphony';
import { AsyncResult } from '~/utils/types';

export interface UseGetAddFundsDataArgs {
  bankAccountsFetchingVariables?: GetFinancialAccountsVariables['bankAccountsFetchingVariables'];
  contentOptions: ContentOptions;
  fundingSourceToFetch?: FundingSources;
  managedProductId: string;
  open: boolean;
  partyId: string;
}
export interface AddFundsModalData {
  accountsData?: {
    accountsLoading?: boolean;
    accountsNetworkStatus?: NetworkStatus;
    bankAccounts?: GetBankAccountBalances_client_financialAccounts[];
    brokerageAccounts?: GetBrokerageAccountBalances_client_financialAccounts[];
    externalBrokerageAccounts?: GetExternalBrokerageAccountBalances_client_financialAccounts[];
    refetchAccounts?: () => Promise<any>;
  };
  clientInfoData?: GetClientInfo;
  content: ModalContent;
  marketHolidayList?: GetMarketHolidayList;
}

export const useGetAddFundsData = ({
  bankAccountsFetchingVariables,
  contentOptions,
  fundingSourceToFetch,
  open,
  partyId,
  managedProductId,
}: UseGetAddFundsDataArgs): AsyncResult<AddFundsModalData> => {
  const {
    data: financialAccountsData,
    error: accountsDataError,
    loading: accountsDataLoading,
  } = useGetFinancialAccountsData({
    fundingSourceToFetch,
    initialSourceToFetch: [],
    partyId,
    bankAccountsFetchingVariables,
    skipFetching: !open,
    managedProductId,
  });

  const {
    data: marketHolidayList,
    loading: marketHolidayListLoading,
    error: marketHolidayListError,
  } = useGetMarketHolidayList({
    variables: {
      from: toSymphonyDate(startOfToday(), contentOptions),
      to: toSymphonyDate(addYears(startOfToday(), 1), contentOptions),
    },
    skip: bankAccountsFetchingVariables?.showWarningForPendingPUW || !open,
  });
  const { data: clientInfoData, loading: clientInfoLoading, error: clientInfoError } = useGetClientInfo({
    variables: { partyId },
    skip:
      !bankAccountsFetchingVariables?.syncExternalBankAccounts ||
      !open ||
      bankAccountsFetchingVariables?.showWarningForPendingPUW,
  });

  const { data: contentstackData, loading: contentstackLoading, error: contentError } = useGetAddFundsV2Content({
    variables: contentOptions,
    skip: !open,
  });

  const error = accountsDataError || contentError || clientInfoError || marketHolidayListError;
  const loading = clientInfoLoading || contentstackLoading || marketHolidayListLoading || accountsDataLoading;

  const v1Content = contentstackData?.all_add_funds_modal?.items?.[0];
  const v2Content = contentstackData?.all_add_funds_modal_v2?.items?.[0];
  const data: AddFundsModalData = useMemo(() => {
    return {
      accountsData: {
        bankAccounts: financialAccountsData?.bankAccounts,
        brokerageAccounts: financialAccountsData?.brokerageAccounts,
        accountsLoading: accountsDataLoading,
        accountsNetworkStatus: financialAccountsData?.networkStatus,
        refetchAccounts: financialAccountsData?.refetchAccounts,
      },
      clientInfoData,
      content: getFormContent(v1Content, v2Content, contentOptions.locale),
      marketHolidayList,
    };
  }, [
    financialAccountsData?.bankAccounts,
    financialAccountsData?.brokerageAccounts,
    accountsDataLoading,
    financialAccountsData?.networkStatus,
    financialAccountsData?.refetchAccounts,
    clientInfoData,
    marketHolidayList,
    v1Content,
    v2Content,
    contentOptions.locale,
  ]);

  return { data, error, loading };
};
