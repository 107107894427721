import React, { FC } from 'react';

import { Chip } from '~/components/ui/Chip';
import { Link } from '~/components/ui/Link';
import { Box, Grid, MuiChipProps, Stack, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { SfTheme } from '~/utils/theme';

export type AccountInfoVariants =
  | 'infoChip' // Default variant used for account summary
  | 'standard'; // Created to support a different widgets layout

export interface Props {
  chips?: Array<Pick<MuiChipProps, 'label' | 'variant'>>;
  dataQa?: string;
  name: string;
  onSeeDetailsClick?: () => void;
  subtitle?: string;
  variant?: AccountInfoVariants;
}

export const AccountInfo: FC<Props> = ({
  chips,
  dataQa = 'account-info',
  name,
  onSeeDetailsClick,
  subtitle,
  variant = 'infoChip',
}) => {
  const {
    sfAccountInfo: { root, styles, typographyVariants },
  } = useTheme<SfTheme>();

  return variant === 'infoChip' ? (
    <Stack sx={root}>
      <Grid alignItems="center" container spacing={1}>
        <Grid item>
          {onSeeDetailsClick && (
            <Link
              color="inherit"
              component="button"
              data-qa={`${dataQa}-details`}
              onClick={onSeeDetailsClick}
              role="link"
              sx={{ display: 'block', color: 'text.primary', ...styles.seeMoreLink }}
              variant="h6"
            >
              {name}
            </Link>
          )}
          {!onSeeDetailsClick && (
            <Typography component="p" data-qa={`${dataQa}-name`} variant="h6">
              {name}
            </Typography>
          )}
        </Grid>
        {chips?.map((chip, index) => (
          <Grid data-qa={`${dataQa}-chips`} item key={index}>
            <Chip {...chip} maxLabelLength={11} size="small" sx={styles.infoChip} />
          </Grid>
        ))}
      </Grid>
      {subtitle && (
        <Grid item>
          <Typography variant="caption">{subtitle}</Typography>
        </Grid>
      )}
    </Stack>
  ) : (
    <Box sx={{ ...root }}>
      {onSeeDetailsClick && (
        <Link
          data-qa={`${dataQa}-account-program-link`}
          onClick={onSeeDetailsClick}
          sx={{ display: 'block', ...styles.accountProgramLink }}
          variant="h6"
        >
          {name}
        </Link>
      )}
      {!onSeeDetailsClick && (
        <Typography component="p" data-qa={`${dataQa}-account-program-text`} variant="h6">
          {name}
        </Typography>
      )}
      {subtitle && (
        <Typography data-qa={`${dataQa}-subtitle`} sx={{ ...styles.subHeading }} variant={typographyVariants?.account}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
