import { differenceInMilliseconds } from 'date-fns';
import { useEffect, useState } from 'react';

import { GetLinkBrokerageAccountContent_all_link_brokerage_account_items } from '../contentstack/__generated__/query.v2';

import { ProcessingStatusTypes, SourceStatusTypes } from '~/__generated__';
import { useAddBrokerageSource, useLazyGetBrokerageSyncStatus } from '~/hooks/brokerage/symphony';
import { useInterval } from '~/utils/interval';

const pollFrequency = 2 * 1000; // how often to check if sync has completed in milliseconds
const maxPollDuration = 2 * 60 * 1000; // maximum time to wait for sync status in milliseconds

export const useAddBrokerage = ({
  content,
  partyId,
  username,
  password,
  selectedBrokerageAlias,
  setSelectedBrokerageAlias,
}: {
  content?: GetLinkBrokerageAccountContent_all_link_brokerage_account_items | null;
  partyId: string;
  password: string;
  selectedBrokerageAlias: string;
  setSelectedBrokerageAlias: (alias: string | null) => void;
  username: string;
}): { addBrokerage: () => void; cancel: () => void; error?: any; loading: boolean; syncedSourceId?: number } => {
  const [syncing, setSyncing] = useState(false);
  const [error, setError] = useState('');
  const [isSyncPending, setIsSyncPending] = useState(false);
  const [syncStatusPollingStartTime, setSyncStatusPollingStartTime] = useState<Date | undefined>();
  const [pendingSourceId, setPendingSourceId] = useState<number | undefined>();
  const [syncedSourceId, setSyncedSourceId] = useState<number | undefined>();

  const [addBrokerageSource, { error: addBrokerageSourceError }] = useAddBrokerageSource();

  const [
    getSyncStatus,
    { data: syncStatusData, loading: syncStatusLoading, error: syncStatusError },
  ] = useLazyGetBrokerageSyncStatus({
    fetchPolicy: 'no-cache',
  });

  useInterval(
    () => {
      waitForSync();
    },
    isSyncPending ? pollFrequency : null,
  );

  const hasMaxSyncPollingTimeElapsed = (): boolean => {
    // Polling should not go for more than syncStatusPollingMaxDurationMillis. Accounts can be stuck in a pending state, so session
    // can get extended indefinitely if the polling is never canceled. This allows time for yodlee to sync accounts.
    return (
      !!syncStatusPollingStartTime && differenceInMilliseconds(new Date(), syncStatusPollingStartTime) > maxPollDuration
    );
  };

  const cancelSyncPoller = (): void => {
    setSyncStatusPollingStartTime(undefined);
    setSyncing(false);
    setIsSyncPending(false);
  };

  const checkSyncStatus = async (id?: number) => getSyncStatus({ variables: { partyId, brokerageSourceId: id } });

  const waitForSync = () => {
    // Don't start a new timer if we canceled it because the max check time period was reached
    if (!isSyncPending && hasMaxSyncPollingTimeElapsed()) {
      return;
    }

    if (!syncStatusPollingStartTime) {
      setSyncStatusPollingStartTime(new Date());
    }

    if (
      syncStatusError ||
      syncStatusData?.brokerageSourceSync.sourceStatus === SourceStatusTypes.SIGFIG_ERROR ||
      hasMaxSyncPollingTimeElapsed()
    ) {
      setError(content?.errors?.sync ?? '');
      cancelSyncPoller();
      return;
    }

    if (!isSyncPending) {
      setIsSyncPending(true);
    }

    if (pendingSourceId) {
      checkSyncStatus(pendingSourceId);
    }
  };

  useEffect(() => {
    if (
      !syncStatusLoading &&
      !syncStatusError &&
      syncStatusData &&
      syncStatusData.brokerageSourceSync?.processingStatus === ProcessingStatusTypes.SUCCESS &&
      syncStatusData.brokerageSourceSync?.sourceStatus === SourceStatusTypes.SUCCESS
    ) {
      cancelSyncPoller();
      setSyncedSourceId(pendingSourceId);
      setPendingSourceId(undefined);
      setSelectedBrokerageAlias('');
    }
  }, [syncStatusLoading, syncStatusError, syncStatusData, pendingSourceId]);

  useEffect(() => {
    if (!pendingSourceId) {
      setSyncedSourceId(undefined);
    }
  }, [pendingSourceId]);

  useEffect(() => {
    if (addBrokerageSourceError) {
      // Most errors are returned as status codes in the response data and are handled in addBrokerage. But if the call
      // fails, we should present the general sync error and log the details
      console.error(addBrokerageSourceError);

      setError(content?.errors?.sync ?? '');
    }

    if (syncStatusError) {
      cancelSyncPoller();
      console.error(syncStatusError);

      setError(content?.errors?.sync ?? '');
    }
  }, [addBrokerageSourceError, syncStatusError]);

  const addBrokerage = async () => {
    try {
      setSyncing(true);

      const brokerageSourceResult = await addBrokerageSource({
        variables: {
          partyId,
          loginName: username,
          loginPassword: password,
          brokerageAlias: selectedBrokerageAlias,
        },
      });

      const data = brokerageSourceResult.data?.addBrokerageSource;

      // Handle error status codes. These do not result in addBrokerageSourceError being set.
      if (!data?.isValid) {
        setSyncing(false);

        switch (data?.statusCode) {
          case 6:
            setError(content?.errors?.account_already_linked ?? '');
            return;
          case 200:
            setError(content?.errors?.invalid_credentials ?? '');
            return;
          default:
            console.error(
              `AddBrokerageSource Invalid - statusCode: ${data?.statusCode}, statusMessage: ${data?.statusMessage}`,
            );
            setError(content?.errors?.sync ?? '');
            return;
        }
      }

      const newSourceId = data?.id ?? undefined;
      setPendingSourceId(newSourceId);

      if (newSourceId && !addBrokerageSourceError) {
        await checkSyncStatus(newSourceId);
        setIsSyncPending(true);
      }
    } catch (err) {
      setSyncing(false);
    }
  };

  const cancel = () => {
    setSyncing(false);
    setIsSyncPending(false);
    setError('');
    setSelectedBrokerageAlias('');
  };

  return { addBrokerage, loading: syncing, error, cancel, syncedSourceId };
};
