import { useCallback, useMemo, useState } from 'react';

import { useGetActiveRestrictionsTabContent } from '../contentstack';
import { ActiveRestrictions, useGetActiveRestrictionsData } from '../symphony';

import {
  Content,
  getActiveRestrictionsContent,
  getColumns,
  getFormattedRows,
  getIsOpsSuspensionAdded,
  getRestrictionsRowData,
  RestrictionRowData,
} from './utils';

import { SuspensionType } from '~/__generated__';
import { TableColumn, TableData } from '~/components/ui/BasicTable';
import { getFullName } from '~/hooks/party/utils';
import { useUpdateTradingSuspension } from '~/hooks/suspensions/symphony';
import { SuspensionTag } from '~/utils/account';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';
import { AsyncResult } from '~/utils/types';

interface SuspensionDisplayData {
  created?: string;
  restrictionPlacedBy?: string;
  restrictionPlacedByUser?: string;
}

interface RemoveSuspensionModalData {
  suspensionDisplayData: SuspensionDisplayData;
  suspensionTag?: string | null;
  suspensionType?: SuspensionType;
}

interface Data {
  addRestrictionInProgress: boolean;
  columnsData: TableColumn[];
  content: Content;
  isOpsSuspensionAdded: boolean;
  isRefetchInProgress: boolean;
  onAddTradingSuspension: () => void;
  refetchActiveRestrictions: () => void;
  removeSuspensionModalData?: RemoveSuspensionModalData;
  suspensionData: RestrictionRowData[];
  tableData: TableData[];
}

const useActiveRestrictionsData = (
  contentOptions: ContentOptions,
  managedProductId: string,
  onAddTradingSuspensionSuccess?: (message: string | undefined, refetchActiveSuspensions: () => void) => void,
  onAddTradingSuspensionFailure?: () => void,
  openRemoveTradingSuspensionModal?: () => void,
): AsyncResult<Data> => {
  const [addRestrictionInProgress, setAddRestrictionInProgress] = useState(false);
  const [isRefetchInProgress, setIsRefetchInProgress] = useState(false);
  const [removeSuspensionModalData, setRemoveSuspensionModalData] = useState<RemoveSuspensionModalData>();

  const {
    components: {
      sfActiveSuspensions: { removePartnerOpsSuspensionAllowed },
    },
  } = useCoreConfig();

  const { data: content, error: contentError, loading: contentLoading } = useGetActiveRestrictionsTabContent({
    variables: contentOptions,
  });

  const { data, error, loading, refetch: refetchActiveRestrictionsData } = useGetActiveRestrictionsData({
    variables: {
      managedProductId,
    },
    fetchPolicy: 'no-cache',
  });

  const [addTradingSuspension] = useUpdateTradingSuspension();

  const activeTradingSuspensionsContent = useMemo(
    () => getActiveRestrictionsContent(content?.all_active_restrictions),
    [content],
  );

  const refetchActiveRestrictions = useCallback(async () => {
    setIsRefetchInProgress(true);
    await refetchActiveRestrictionsData();
    setIsRefetchInProgress(false);
  }, [refetchActiveRestrictionsData]);

  const onAddTradingSuspension = useCallback(async () => {
    try {
      setAddRestrictionInProgress(true);
      const addSuspensionResponse = await addTradingSuspension({
        variables: {
          managedProductId,
          suspensionType: SuspensionType.PARTNER,
          suspensionTags: [SuspensionTag.OPS],
        },
      });
      if (addSuspensionResponse.data?.updateTradingSuspension?.length) {
        onAddTradingSuspensionSuccess?.(activeTradingSuspensionsContent.restrictionAdded, refetchActiveRestrictions);
      } else {
        onAddTradingSuspensionFailure?.();
      }
    } catch (addSuspensionFailure: any) {
      onAddTradingSuspensionFailure?.();
    } finally {
      setAddRestrictionInProgress(false);
    }
  }, [
    activeTradingSuspensionsContent.restrictionAdded,
    addTradingSuspension,
    managedProductId,
    onAddTradingSuspensionFailure,
    onAddTradingSuspensionSuccess,
    refetchActiveRestrictions,
  ]);

  const onRemoveSuspensionActionClick = useCallback(
    (suspension: ActiveRestrictions) => {
      const displayData: SuspensionDisplayData = {
        restrictionPlacedByUser: getFullName(suspension.lastUpdatedByParty?.partyPerson),
        restrictionPlacedBy: suspension.placedBy,
        created: suspension.createdAt ? formatDate(suspension.createdAt) : '-',
      };
      setRemoveSuspensionModalData({
        suspensionDisplayData: displayData,
        suspensionType: SuspensionType.PARTNER,
        suspensionTag: SuspensionTag.OPS,
      });
      openRemoveTradingSuspensionModal?.();
    },
    [openRemoveTradingSuspensionModal],
  );

  const activeRestrictionsData: Data = useMemo(
    () => ({
      addRestrictionInProgress,
      columnsData: getColumns(activeTradingSuspensionsContent),
      content: activeTradingSuspensionsContent,
      isOpsSuspensionAdded: getIsOpsSuspensionAdded(data?.managedProduct?.multiRestrictions ?? []),
      isRefetchInProgress,
      onAddTradingSuspension,
      refetchActiveRestrictions,
      removeSuspensionModalData,
      suspensionData:
        data?.managedProduct?.multiRestrictions.map(restriction =>
          getRestrictionsRowData(restriction, activeTradingSuspensionsContent, contentOptions),
        ) ?? [],
      tableData:
        getFormattedRows({
          content: activeTradingSuspensionsContent,
          contentOptions,
          disableRemoveSuspension: isRefetchInProgress,
          isRemoveTradingSuspensionAllowed: removePartnerOpsSuspensionAllowed,
          onRemoveSuspensionActionClick,
          activeRestrictions: data?.managedProduct?.multiRestrictions ?? [],
        }) ?? [],
    }),
    [
      activeTradingSuspensionsContent,
      addRestrictionInProgress,
      contentOptions,
      data?.managedProduct?.multiRestrictions,
      isRefetchInProgress,
      onAddTradingSuspension,
      onRemoveSuspensionActionClick,
      refetchActiveRestrictions,
      removePartnerOpsSuspensionAllowed,
      removeSuspensionModalData,
    ],
  );

  return {
    data: activeRestrictionsData,
    error: contentError ?? error,
    loading: contentLoading || loading,
  };
};

export default useActiveRestrictionsData;
