// eslint-disable-next-line no-restricted-imports
import { pick } from 'lodash';
import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { clientDocumentsPerPage, InitialAccountDetailsState, TabsState } from '../types';

import { Content } from './utils';

import { AssociatedEntityType, ManagedProductType } from '~/__generated__';
import { AccountActions } from '~/components/AccountActions';
import { AccountDetailsAccountActivity } from '~/components/AccountDetailsAccountActivity';
import { TabState as AccountActivityTabState } from '~/components/AccountDetailsAccountActivity/types';
import { AccountDetailsOverview } from '~/components/AccountDetailsOverview';
import { AddFunds } from '~/components/AddFunds';
import { ClientDocuments } from '~/components/ClientDocuments';
import { Restrictions } from '~/components/Restrictions';
import { TradingSuspensions } from '~/components/TradingSuspensions';
import { Paper, Stack, SxProps, Theme, useTheme } from '~/components/ui/mui';
import { TabData } from '~/components/ui/TabsContainer';
import { Typography } from '~/components/ui/Typography';
import { DetailedFinancialAccount, ManagedProduct } from '~/hooks/account-details/symphony';
import {
  AccountDetailsGetDigitalWealthAccounts,
  AccountDetailsGetDigitalWealthAccountsVariables,
} from '~/hooks/account-details/symphony/__generated__/query.v2';
import { AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { FinancialAccount as BankAccountFinancialAccount } from '~/hooks/financial-account/symphony';
import { MeasurementName, ReturnsByPeriod } from '~/hooks/performance/types';
import { AccountState, BankAccount } from '~/utils/account';
import { ApolloQueryResult } from '~/utils/apollo-client';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export interface Variables {
  account?: DetailedFinancialAccount;
  accountState: AccountState;
  actions: ComponentProps<typeof AccountActions>['config'];
  bankAccounts: BankAccount[];
  contentOptions: ContentOptions;
  digitalWealthAccountsInitialLoading: boolean;
  feedbackMessageType?: string;
  getAssetClassTier?: (program: ManagedProductType) => AssetClassTier;
  initialState?: InitialAccountDetailsState;
  initialTimePeriod: MeasurementName;
  managedProduct?: ManagedProduct;
  managedProductId: string;
  onAddFundsClick: ComponentProps<typeof AddFunds>['onAddFunds'];
  onDocumentSigningTriggered?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
    state?: string,
  ) => void;
  onLinkAccountCallback: (newBankAccount: BankAccountFinancialAccount) => void;
  onRemoveAccount: ComponentProps<typeof AddFunds>['onRemoveAccount'];
  partyId: string;
  refetchDigitalWealthAccountsData: (
    variables?: Partial<AccountDetailsGetDigitalWealthAccountsVariables>,
  ) => Promise<ApolloQueryResult<AccountDetailsGetDigitalWealthAccounts>>;
  returnsDataOverride?: ReturnsByPeriod;
  tabs: AccountDetailsTabsEnum[];
  tabsContent?: Content['tabHeader'];
}

interface TabComponentData {
  getTabComponent: (tab: string) => React.ReactNode;
  startingTab?: AccountDetailsTabsEnum;
  tabsData: TabData[];
}

const isValidTab = (currentTab: string): currentTab is keyof typeof AccountDetailsTabsEnum =>
  currentTab in AccountDetailsTabsEnum;

const paperSxProps: SxProps<Theme> = {
  border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
  borderRadius: 1,
  boxShadow: 'none',
  p: 4,
};

const tabCountStyle: SxProps<Theme> = {
  backgroundColor: 'primary.main',
  borderRadius: '50%',
  color: 'primary.contrastText',
  height: '20px',
  ml: 1,
  width: '20px',
};

export const useTabComponent = ({
  account,
  accountState,
  actions,
  bankAccounts,
  contentOptions,
  digitalWealthAccountsInitialLoading,
  getAssetClassTier,
  initialState,
  initialTimePeriod,
  managedProduct,
  managedProductId,
  onAddFundsClick,
  onDocumentSigningTriggered,
  onLinkAccountCallback,
  onRemoveAccount,
  partyId,
  refetchDigitalWealthAccountsData,
  returnsDataOverride,
  tabs,
  tabsContent,
}: Variables): TabComponentData => {
  const {
    sfAccountDetails: { typographyVariants },
  } = useTheme();
  const {
    components: { sfAccountDetailsV2, sfAccountDetailsOverview },
  } = useCoreConfig();
  const [tabStates, setTabStates] = useState<TabsState>({});
  const [tabCountMap, setTabCountMap] = useState<Record<AccountDetailsTabsEnum, number | undefined>>({
    [AccountDetailsTabsEnum.activity]: undefined,
    [AccountDetailsTabsEnum.documents]: undefined,
    [AccountDetailsTabsEnum.overview]: undefined,
    [AccountDetailsTabsEnum.suspensions]: undefined,
    [AccountDetailsTabsEnum.restrictions]: undefined,
  });

  const setAccountActivityTabState = (accountActivityState: AccountActivityTabState) =>
    setTabStates(state => ({ ...state, accountActivity: accountActivityState }));

  const getAccountDetailsOverview = useCallback(() => {
    const accountDetailsOverviewConfig = pick(sfAccountDetailsV2, [
      'defaultContributionAmount',
      'hiddenSections',
      'plaidLinkageType',
      'showVerifiedBankAccounts',
      'syncExternalBankAccounts',
    ]);

    return (
      <AccountDetailsOverview
        account={account}
        actions={actions}
        bankAccounts={bankAccounts}
        config={{ ...sfAccountDetailsOverview, ...accountDetailsOverviewConfig }}
        contentOptions={contentOptions}
        digitalWealthAccountsInitialLoading={digitalWealthAccountsInitialLoading}
        getAssetClassTier={getAssetClassTier}
        initialTimePeriod={initialTimePeriod}
        managedProduct={managedProduct}
        managedProductId={managedProductId}
        onAddFundsClick={onAddFundsClick}
        onLinkAccountCallback={onLinkAccountCallback}
        onRemoveAccount={onRemoveAccount}
        onSignDocumentsRedirect={onDocumentSigningTriggered}
        partyId={partyId}
        refetchDigitalWealthAccountsData={refetchDigitalWealthAccountsData}
        returnsDataOverride={returnsDataOverride}
      />
    );
  }, [
    account,
    actions,
    bankAccounts,
    contentOptions,
    digitalWealthAccountsInitialLoading,
    getAssetClassTier,
    initialTimePeriod,
    managedProduct,
    managedProductId,
    onAddFundsClick,
    onDocumentSigningTriggered,
    onLinkAccountCallback,
    onRemoveAccount,
    partyId,
    refetchDigitalWealthAccountsData,
    returnsDataOverride,
    sfAccountDetailsV2,
    sfAccountDetailsOverview,
  ]);

  const getTabComponent = useCallback(
    (currentTab: string): React.ReactNode => {
      const tabCountSetter = (count: number | undefined) => {
        if (count !== undefined) {
          setTabCountMap(
            prev => (count !== prev[currentTab as AccountDetailsTabsEnum] ? { ...prev, [currentTab]: count } : prev), // count condition is needed to prevent infinite loop
          );
        }
      };

      if (isValidTab(currentTab)) {
        switch (AccountDetailsTabsEnum[currentTab]) {
          case AccountDetailsTabsEnum.overview: {
            return getAccountDetailsOverview();
          }
          case AccountDetailsTabsEnum.activity: {
            return (
              <Paper sx={paperSxProps}>
                <AccountDetailsAccountActivity
                  accountCreatedDate={managedProduct?.assignedOn ? new Date(managedProduct.assignedOn) : undefined}
                  contentOptions={contentOptions}
                  financialAccountId={account?.id ?? ''}
                  initialTabState={tabStates.accountActivity}
                  managedProductId={managedProductId}
                  onAddFunds={onAddFundsClick}
                  onUpdateTabState={setAccountActivityTabState}
                  partyId={partyId}
                />
              </Paper>
            );
          }
          case AccountDetailsTabsEnum.documents: {
            return (
              <Paper sx={paperSxProps}>
                <ClientDocuments
                  clientDocumentsPerPage={clientDocumentsPerPage}
                  contentOptions={contentOptions}
                  managedProductId={managedProductId}
                />
              </Paper>
            );
          }
          case AccountDetailsTabsEnum.suspensions: {
            return (
              <Paper sx={paperSxProps}>
                <TradingSuspensions
                  contentOptions={contentOptions}
                  managedProductId={managedProductId}
                  onCountUpdate={tabCountSetter}
                />
              </Paper>
            );
          }
          case AccountDetailsTabsEnum.restrictions: {
            return (
              <Paper sx={paperSxProps}>
                <Restrictions
                  contentOptions={contentOptions}
                  managedProductId={managedProductId}
                  onCountUpdate={tabCountSetter}
                />
              </Paper>
            );
          }
          default: {
            return null;
          }
        }
      }
      return null;
    },
    [
      getAccountDetailsOverview,
      managedProduct?.assignedOn,
      contentOptions,
      account?.id,
      tabStates.accountActivity,
      managedProductId,
      onAddFundsClick,
      partyId,
    ],
  );

  const isTabDisabled = useCallback(
    (tab: AccountDetailsTabsEnum) => {
      return tab !== AccountDetailsTabsEnum.documents && accountState === AccountState.Closed;
    },
    [accountState],
  );

  const startingTab = useMemo(() => {
    const initialTab = initialState?.initialTab;
    return initialTab && tabs.find(tab => tab === initialTab && !isTabDisabled(tab))
      ? initialTab
      : tabs.find(tab => !isTabDisabled(tab));
  }, [initialState, isTabDisabled, tabs]);

  const tabsData: TabData[] = useMemo(
    () =>
      sfAccountDetailsV2.tabs.map(tab => ({
        label: (
          <Stack alignItems="center" flexDirection="row">
            <Typography sx={{ textTransform: 'none' }} variant={typographyVariants?.tabTitle}>
              {tabsContent?.[tab] ?? ''}
            </Typography>
            {tabCountMap[tab] !== undefined && (
              <Stack alignItems="center" justifyContent="center" sx={tabCountStyle}>
                {tabCountMap[tab]}
              </Stack>
            )}
          </Stack>
        ),
        id: tab.toString(),
        disabled: isTabDisabled(tab),
      })),
    [isTabDisabled, sfAccountDetailsV2, tabsContent, tabCountMap, typographyVariants],
  );

  useEffect(() => {
    // Not fetching trading suspensions, when tab is not enabled
    if (tabs.includes(AccountDetailsTabsEnum.suspensions)) {
      setTabCountMap(prev => ({
        ...prev,
        [AccountDetailsTabsEnum.suspensions]: managedProduct?.tradingSuspensions?.length ?? undefined,
      }));
    }
    if (tabs.includes(AccountDetailsTabsEnum.restrictions)) {
      setTabCountMap(prev => ({
        ...prev,
        [AccountDetailsTabsEnum.restrictions]: managedProduct?.multiRestrictions?.length ?? undefined,
      }));
    }
  }, [managedProduct, tabs]);

  return {
    getTabComponent,
    startingTab,
    tabsData,
  };
};
