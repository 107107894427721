import { useEffect, useMemo } from 'react';

import { BalanceType } from '~/__generated__';
import { generateIDForExternalBrokerage } from '~/components/Funding/BrokerageSource/utils';
import { hasManagedProduct } from '~/containers/Funding/symphony';
import {
  BrokerageFinancialAccount,
  ExternalBrokerageFinancialAccount,
  FinancialAccount,
  useLazyGetBankAccountBalances,
  useLazyGetBrokerageAccountBalances,
  useLazyGetExternalBrokerageAccountBalances,
} from '~/hooks/financial-account/symphony';
import { getBankAccountId, sortAccountsByFinancialInstitutionAndMaskedNumber } from '~/utils/account';
import { ApolloQueryResult, NetworkStatus } from '~/utils/apollo-client';
import { FundingSources } from '~/utils/config';
import { AsyncResult } from '~/utils/types';

export interface FinancialAccounts {
  bankAccounts: FinancialAccount[];
  brokerageAccounts: BrokerageFinancialAccount[];
  externalBrokerageAccounts: ExternalBrokerageFinancialAccount[];
  networkStatus?: NetworkStatus;
  refetchAccounts?: () => Promise<ApolloQueryResult<any>>;
}

export interface GetFinancialAccountsVariables {
  bankAccountsFetchingVariables?: {
    includeManagedProducts?: boolean;
    showVerifiedBankAccounts?: boolean;
    showWarningForPendingPUW?: boolean;
    syncExternalBankAccounts?: boolean;
  };
  fundingSourceToFetch?: FundingSources;
  initialSourceToFetch: FundingSources[];
  managedProductId: string;
  partyId: string;
  skipFetching?: boolean;
}
export const useGetFinancialAccountsData = ({
  bankAccountsFetchingVariables,
  partyId,
  initialSourceToFetch,
  fundingSourceToFetch,
  skipFetching,
  managedProductId,
}: GetFinancialAccountsVariables): AsyncResult<FinancialAccounts> => {
  const [
    getBankAccountBalances,
    {
      data: bankAccountData,
      loading: bankAccountDataLoading,
      error: bankAccountsError,
      refetch: refetchBankAccounts,
      networkStatus: bankAccountsNetworkStatus,
    },
  ] = useLazyGetBankAccountBalances({
    variables: {
      partyId,
      skipDeleted: true,
      syncExternal: bankAccountsFetchingVariables?.syncExternalBankAccounts ?? true,
      isVerified: bankAccountsFetchingVariables?.showVerifiedBankAccounts ?? true,
      includeManagedProducts: bankAccountsFetchingVariables?.includeManagedProducts ?? true,
      managedProductIds: [managedProductId],
    },
    skip: (bankAccountsFetchingVariables?.showWarningForPendingPUW || skipFetching) ?? undefined,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [
    getBrokerageAccountBalances,
    {
      data: brokerageAccountData,
      loading: brokerageAccountDataLoading,
      error: brokerageAccountsError,
      refetch: refetchBrokerageAccounts,
      networkStatus: brokerageAccountsNetworkStatus,
    },
  ] = useLazyGetBrokerageAccountBalances({
    variables: { partyId },
    skip: skipFetching ?? undefined,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [
    getExternalBrokerageAccountBalances,
    {
      data: externalBrokerageAccountData,
      loading: externalBrokerageAccountDataLoading,
      error: externalBrokerageAccountsError,
    },
  ] = useLazyGetExternalBrokerageAccountBalances({
    variables: { partyId },
    skip: skipFetching ?? undefined,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const loading = bankAccountDataLoading || brokerageAccountDataLoading || externalBrokerageAccountDataLoading;

  useEffect(() => {
    if (
      (fundingSourceToFetch === FundingSources.bankAccount ||
        initialSourceToFetch.includes(FundingSources.bankAccount)) &&
      !bankAccountDataLoading &&
      !bankAccountData &&
      !bankAccountsError
    ) {
      getBankAccountBalances();
    } else if (
      (fundingSourceToFetch === FundingSources.otherBrokerageAccount ||
        initialSourceToFetch.includes(FundingSources.otherBrokerageAccount)) &&
      !brokerageAccountDataLoading &&
      !brokerageAccountData &&
      !brokerageAccountsError
    ) {
      getBrokerageAccountBalances();
    } else if (
      (fundingSourceToFetch === FundingSources.journaling ||
        initialSourceToFetch.includes(FundingSources.journaling)) &&
      !externalBrokerageAccountDataLoading &&
      !externalBrokerageAccountData &&
      !externalBrokerageAccountsError
    ) {
      getExternalBrokerageAccountBalances();
    }
  }, [
    initialSourceToFetch,
    fundingSourceToFetch,
    getBankAccountBalances,
    getBrokerageAccountBalances,
    getExternalBrokerageAccountBalances,
    externalBrokerageAccountData,
    externalBrokerageAccountDataLoading,
    externalBrokerageAccountsError,
    bankAccountData,
    bankAccountDataLoading,
    bankAccountsError,
    brokerageAccountData,
    brokerageAccountDataLoading,
    brokerageAccountsError,
  ]);

  const state: AsyncResult<FinancialAccounts> = useMemo(() => {
    if (loading) {
      return { loading };
    }
    const bankAccounts = (bankAccountData?.client?.financialAccounts ?? [])
      .map(account => ({
        ...account,
        id: getBankAccountId(account.id, account.isFromExternalSource, account.accountNumber),
      }))
      .filter(
        ({ id, financialInstitution, maskedAccountNumber, routingNumber, accountNumber }) =>
          id && financialInstitution && maskedAccountNumber && routingNumber && accountNumber,
      );

    if (
      bankAccounts.every(
        account => account.balances?.find(item => item.type === BalanceType.TOTAL_ACCOUNT)?.balance.value,
      )
    ) {
      bankAccounts.sort(
        (a, b) =>
          parseFloat(b.balances?.find(item => item.type === BalanceType.TOTAL_ACCOUNT)?.balance.value ?? '0') -
          parseFloat(a.balances?.find(item => item.type === BalanceType.TOTAL_ACCOUNT)?.balance.value ?? '0'),
      );
    } else if (bankAccounts.every(account => account.syncedOn)) {
      bankAccounts.sort((a, b) => new Date(a.syncedOn as string).valueOf() - new Date(b.syncedOn as string).valueOf());
    } else {
      sortAccountsByFinancialInstitutionAndMaskedNumber(bankAccounts);
    }

    const brokerageAccounts = (brokerageAccountData?.client?.financialAccounts ?? []).filter(
      f =>
        !hasManagedProduct(f) &&
        !!f.financialAccountFundingEligibility?.isValidFundingSource &&
        f.financialInstitution &&
        (f.maskedAccountNumber || f.id),
    );

    if (
      brokerageAccounts.every(
        account => account.balances?.find(item => item.type === BalanceType.TOTAL_ACCOUNT)?.balance.value,
      )
    ) {
      brokerageAccounts.sort(
        (a, b) =>
          parseFloat(b.balances?.find(item => item.type === BalanceType.TOTAL_ACCOUNT)?.balance.value ?? '0') -
          parseFloat(a.balances?.find(item => item.type === BalanceType.TOTAL_ACCOUNT)?.balance.value ?? '0'),
      );
    } else if (brokerageAccounts.every(account => account.syncedOn)) {
      brokerageAccounts.sort(
        (a, b) => new Date(a.syncedOn as string).valueOf() - new Date(b.syncedOn as string).valueOf(),
      );
    } else {
      sortAccountsByFinancialInstitutionAndMaskedNumber(brokerageAccounts);
    }

    const externalBrokerageAccounts = (externalBrokerageAccountData?.client?.financialAccounts ?? []).map(item => ({
      ...item,
      id: generateIDForExternalBrokerage(item.financialInstitution ?? '', item.accountNumber ?? ''),
    }));

    return {
      loading,
      data: {
        bankAccounts,
        brokerageAccounts,
        externalBrokerageAccounts,
        networkStatus:
          fundingSourceToFetch === FundingSources.bankAccount
            ? bankAccountsNetworkStatus
            : fundingSourceToFetch === FundingSources.otherBrokerageAccount
            ? brokerageAccountsNetworkStatus
            : undefined,
        refetchAccounts:
          fundingSourceToFetch === FundingSources.bankAccount
            ? refetchBankAccounts
            : fundingSourceToFetch === FundingSources.otherBrokerageAccount
            ? refetchBrokerageAccounts
            : undefined,
      },
      error:
        fundingSourceToFetch === FundingSources.bankAccount
          ? bankAccountsError
          : fundingSourceToFetch === FundingSources.otherBrokerageAccount &&
            !brokerageAccountData?.client?.financialAccounts?.length
          ? brokerageAccountsError
          : fundingSourceToFetch === FundingSources.journaling
          ? externalBrokerageAccountsError
          : undefined,
    };
  }, [
    loading,
    bankAccountsError,
    brokerageAccountsError,
    externalBrokerageAccountsError,
    fundingSourceToFetch,
    bankAccountData,
    brokerageAccountData,
    externalBrokerageAccountData,
    refetchBankAccounts,
    refetchBrokerageAccounts,
    bankAccountsNetworkStatus,
    brokerageAccountsNetworkStatus,
  ]);

  return state;
};
