import {
  differenceInCalendarQuarters,
  format,
  isAfter,
  isSameDay,
  isValid,
  parseISO,
  startOfQuarter,
  startOfToday,
  startOfYear,
  subYears,
} from 'date-fns';
import React, { ComponentProps } from 'react';

import { AssetClassAllocation, ManagedProductCompositeRecommendedPortfolioAllocation } from '../symphony';
import {
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio,
  AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets,
} from '../symphony/__generated__/query.v2';

import { PerformanceMeasurementInput, PerformanceMethodTypes } from '~/__generated__';
import { AccountActions } from '~/components/AccountActions';
import { isCustomAction, isStandardAction } from '~/components/AccountActions/utils';
import { Allocation } from '~/components/AssetAllocationTable';
import { CompositeModelPortfolioAllocationData } from '~/components/pdfs/components/MultiSleeveAllocation';
import { Period } from '~/components/PerformanceTimePeriodSelect';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Box, PaperProps } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { MeasurementName, ReturnsByPeriod } from '~/hooks/performance/types';
import { QuarterlyAssets } from '~/hooks/qpr/symphony';
import {
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_allocations_guidance_diversification_assets_allocations as QprAssetClassAllocation,
  GetQuarterlyPerformanceReport_managedProduct_quarterlyPerformanceReport_sleevesAllocation as Sleeve,
} from '~/hooks/qpr/symphony/__generated__/query.v2';
import { ModelPortfolioContent } from '~/hooks/qpr/useGetDataForQPR';
import {
  aggregateAssetAllocationDataByAssetClassKey,
  AssetClassContent,
  computeCashAllocation,
  getAssetClassContent,
  getAssetClassId,
} from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { toSymphonyDate } from '~/utils/symphony';
import { SfComponent, SfRceOptions, SfVariant } from '~/utils/theme';

/**
 * The block IDs from the "sections" modular blocks on Contentstack
 */
export type AccountDetailsSectionBlock =
  | 'account_activity'
  | 'annual_review'
  | 'add_funds'
  | 'asset_allocation'
  | 'billing_rates'
  | 'billing_history'
  | 'clientInfo'
  | 'performance_chart'
  | 'portfolio_breakdown'
  | 'questions'
  | 'composite_model_portfolio_allocation';

export interface AccountDetailsFeatureFlags {
  defaultContributionAmount?: string;
  ignoreInsufficientFunds?: boolean;
  performanceChartTo?: 'today' | 'syncedOn';
  showVerifiedBankAccounts?: boolean;
  syncExternalBankAccounts?: boolean;
}

export type Assets = AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets;

export const getPerformanceMeasurements = ({
  accountActivationDate,
  performanceMethod,
  quarterly,
  to,
}: {
  accountActivationDate: string;
  performanceMethod?: PerformanceMethodTypes;
  quarterly?: {
    from: string;
    to: string;
  };
  to: string;
}): PerformanceMeasurementInput[] => {
  const activationDate = parseISO(accountActivationDate);
  if (!isValid(activationDate)) {
    return [];
  }

  const today = startOfToday();
  const qtdStart = startOfQuarter(today);
  const ytdStart = startOfYear(today);
  const yearMeasurements = [
    { value: 1, name: MeasurementName.OneYear },
    { value: 3, name: MeasurementName.ThreeYears },
    { value: 5, name: MeasurementName.FiveYears },
    { value: 10, name: MeasurementName.TenYears },
  ].reduce<PerformanceMeasurementInput[]>((acc, { value, name }) => {
    const periodStart = subYears(today, value);
    if (isAfter(periodStart, activationDate) || isSameDay(periodStart, activationDate)) {
      acc.push({
        from: toSymphonyDate(periodStart),
        to,
        name,
        performanceMethod,
      });
    }
    return acc;
  }, []);

  let performanceMeasurements = [
    {
      from: toSymphonyDate(
        isAfter(qtdStart, activationDate) || isSameDay(qtdStart, activationDate) ? qtdStart : activationDate,
      ),
      to,
      name: MeasurementName.QTD,
      performanceMethod,
    },
    {
      from: toSymphonyDate(
        isAfter(ytdStart, activationDate) || isSameDay(ytdStart, activationDate) ? ytdStart : activationDate,
      ),
      to,
      name: MeasurementName.YTD,
      performanceMethod,
    },
    {
      from: toSymphonyDate(activationDate),
      to,
      name: MeasurementName.SinceInception,
      performanceMethod,
    },
    ...yearMeasurements,
  ];

  if (quarterly) {
    const yearToQuarterStart = parseISO(`${quarterly.from.split('-')[0]}-01-01`);

    performanceMeasurements = [
      ...performanceMeasurements,
      {
        from: quarterly.from,
        to: quarterly.to,
        name: MeasurementName.QuarterlyPerformance,
        performanceMethod,
      },
      {
        from: toSymphonyDate(
          isAfter(yearToQuarterStart, activationDate) || isSameDay(yearToQuarterStart, activationDate)
            ? yearToQuarterStart
            : activationDate,
        ),
        to: quarterly.to,
        name: MeasurementName.YearToQuarter,
        performanceMethod,
      },
    ];
  }

  return performanceMeasurements;
};

export const getMeasurementForChart = (
  measurements: PerformanceMeasurementInput[],
  selectedTimePeriod?: MeasurementName,
): PerformanceMeasurementInput[] => {
  if (!measurements.length) {
    return [];
  }

  const defaultTimePeriod =
    selectedTimePeriod === MeasurementName.QuarterlyPerformance
      ? MeasurementName.YearToQuarter
      : MeasurementName.SinceInception;
  const selectedMeasurements = measurements.filter(m => m.name === selectedTimePeriod);
  return selectedMeasurements.length ? selectedMeasurements : measurements.filter(m => m.name === defaultTimePeriod);
};

// format Q2 2022 to Jan 1 - Q2 ‘21
export const formatQuarterToQTY = (quarter: string): string => {
  if (!quarter) {
    return '';
  }
  const [selectedQuarter, year] = quarter.split(' ');
  return `${format(parseISO(`${parseInt(year, 10)}-01-01`), 'MMM d')} - ${selectedQuarter} '${year.substring(
    year.length - 2,
    year.length,
  )}`;
};

export const getPerformanceTimePeriods = (
  returns?: ReturnsByPeriod,
  timePeriodLabels?: Partial<{ [key in MeasurementName]: string }>,
  quarter?: string,
): Period[] => {
  const periods = [
    MeasurementName.OneYear,
    MeasurementName.ThreeYears,
    MeasurementName.FiveYears,
    MeasurementName.TenYears,
    MeasurementName.SinceInception,
  ];
  if (!quarter) {
    periods.unshift(MeasurementName.QTD, MeasurementName.YTD);
  }

  const performanceTimePeriods: Period[] = quarter
    ? [
        {
          id: MeasurementName.QuarterlyPerformance,
          label: quarter,
          value: returns?.QuarterlyPerformance?.value,
        },
        {
          id: MeasurementName.YearToQuarter,
          label: formatQuarterToQTY(quarter),
          value: returns?.yearToQuarter?.value,
        },
      ]
    : [];

  return [
    ...performanceTimePeriods,
    ...periods
      .map(key => ({
        id: key,
        label: timePeriodLabels?.[key] ?? '',
        value: returns?.[key]?.value,
      }))
      .filter(period => period.label),
  ];
};

export const getPaperProps = (sfRce: SfComponent<SfRceOptions, Record<string, SfVariant>>): PaperProps => ({
  className: 'AccountDetails-Card',
  square: false,
  sx: sfRce.styles.card,
  variant: 'outlined',
});

export const toAllocation = (
  assetClassAllocations?: AssetClassAllocation[] | QprAssetClassAllocation[],
  assetClassContent?: AssetClassContent | null,
  otherAssetClassLabel?: string | null,
  assetClassTier: AssetClassTier | undefined = AssetClassTier.MODEL,
): Allocation[] => {
  const allocations =
    assetClassAllocations?.map(allocation => {
      const assetClassId = getAssetClassId(assetClassTier, {
        [AssetClassTier.MODEL]: allocation.asset.name,
        [AssetClassTier.ENCLOSING]: allocation.enclosingAssetClass ?? '',
        [AssetClassTier.BROAD]: allocation.broadAssetClass ?? '',
      });
      const assetClassContentObj = getAssetClassContent(assetClassId, assetClassTier, assetClassContent ?? undefined);
      const targetPercent = parseFloat(allocation.targetAllocation || '0');
      const actualPercent = parseFloat(allocation.actualAllocation || '0');

      return {
        color: assetClassContentObj?.color || '#000000',
        opacity: assetClassContentObj?.opacity || '100',
        order: assetClassContentObj?.order || null,
        assetClass: {
          key: assetClassId,
          label: assetClassContentObj?.display_name || otherAssetClassLabel || 'Other',
          tooltip:
            assetClassContentObj?.description_title && assetClassContentObj.description_body ? (
              <Box sx={{ p: 2 }}>
                <RteContent data={assetClassContentObj.description_title} sx={{ mb: 1 }} />
                <RteContent data={assetClassContentObj.description_body} />
              </Box>
            ) : undefined,
        },
        tickers:
          allocation.securityAllocation?.map(item => ({
            key: item.security.ticker || '',
            label: item.security.ticker || 'Unknown',
            tooltip: item.security.name,
          })) ?? [],
        percent: targetPercent,
        targetPercent,
        actualPercent,
        value: parseFloat(allocation.actualAllocationValue?.value ?? '0'),
      };
    }) ?? [];

  return [
    ...(assetClassTier !== AssetClassTier.MODEL
      ? aggregateAssetAllocationDataByAssetClassKey(allocations)
      : allocations),
  ];
};

export const getOnLaunchSchedulingTool = (actions: ComponentProps<typeof AccountActions>['config']) => {
  const { launchSchedulingTool } = actions;
  return isStandardAction(launchSchedulingTool)
    ? () => console.warn('standard scheduling tool not implemented')
    : isCustomAction(launchSchedulingTool)
    ? launchSchedulingTool.callback
    : undefined;
};

export const getPastQuartersDropdownOptions = (
  startDate: string,
  endingDate: Date = startOfToday(),
): DropdownItem<string>[] | null => {
  if (!startDate) {
    return null;
  }
  const firstRebalancedDate = parseISO(startDate);
  let year = firstRebalancedDate.getFullYear();
  const month = firstRebalancedDate.getMonth() + 1;
  const quarters = differenceInCalendarQuarters(endingDate, firstRebalancedDate);
  let dropdownValues: DropdownItem<string>[] = [];
  let quarter = Math.ceil(month / 3);
  for (let i = 0; i < quarters; i++) {
    dropdownValues = [{ label: `Q${quarter} ${year}`, value: `Q${quarter} ${year}` }, ...dropdownValues];
    if (quarter === 4) {
      quarter = 1;
      year += 1;
    } else {
      quarter += 1;
    }
  }
  return dropdownValues;
};

export type Quarter = 'Q1' | 'Q2' | 'Q3' | 'Q4';

export const getStartDateEndDateOfQuarter = (
  quarter: Quarter | undefined,
  year: string,
): { endDate: string; startDate: string } => {
  if (!quarter) {
    return { startDate: '', endDate: '' };
  }
  const [startDate, endDate] = {
    Q1: [`${year}-01-01`, `${year}-03-31`],
    Q2: [`${year}-04-01`, `${year}-06-30`],
    Q3: [`${year}-07-01`, `${year}-09-30`],
    Q4: [`${year}-10-01`, `${year}-12-31`],
  }[quarter];

  return { startDate, endDate };
};

export const getCompositeModelPortfolioAllocationData = (
  targetModelPortfolio:
    | AccountDetailsGetDigitalWealthAccounts_client_detailedAccounts_products_ManagedProduct_targetModelPortfolio
    | null
    | undefined,
): ManagedProductCompositeRecommendedPortfolioAllocation[] | null => {
  if (
    targetModelPortfolio?.__typename === 'CompositeRecommendedPortfolio' &&
    targetModelPortfolio.compositeModelPortfolioAllocation
  ) {
    return targetModelPortfolio.compositeModelPortfolioAllocation;
  }
  return null;
};

export const getAllocations = (
  assetClassTier: AssetClassTier,
  assets?: Assets | QuarterlyAssets,
  assetClassContent?: AssetClassContent | null,
  otherAssetClass?: string | null,
): Allocation[] => {
  const nonBufferCashAllocation = assets?.cashAllocation;
  const hasCashInAssetAllocation = assets?.allocations.find(allocation => allocation.asset.name === 'Cash');
  const computedCashAllocation =
    nonBufferCashAllocation && !hasCashInAssetAllocation ? [computeCashAllocation(nonBufferCashAllocation)] : [];

  const assetAllocations = toAllocation(assets?.allocations, assetClassContent, otherAssetClass, assetClassTier);
  return [...assetAllocations, ...computedCashAllocation];
};

export const getSleeves = (
  assetClassTier: AssetClassTier,
  sleeves: Sleeve[],
  modelPortfoliosContentData: ModelPortfolioContent[],
  assetClassContent?: AssetClassContent | null,
  otherAssetClass?: string | null,
): CompositeModelPortfolioAllocationData[] => {
  return sleeves.map(sleeve => {
    const modelPortfolioContent = modelPortfoliosContentData.find(
      mc =>
        mc.modelPortfolioSeriesBaseName === sleeve.modelPortfolioSeriesBaseName &&
        mc.modelPortfolioIdentifier === sleeve.modelPortfolioIdentifier?.toString(),
    );
    {
      const modelPortfolio = sleeve.modelPortfolio;
      return {
        allocation: getAllocations(
          assetClassTier,
          modelPortfolio?.guidance?.diversification?.assets,
          assetClassContent,
          otherAssetClass,
        ),
        modelPortfolioName: modelPortfolioContent?.modelPortfolioName ?? '',
        percentage: sleeve.percentage ?? '',
      };
    }
  });
};
