import {
  GetActiveRestrictions_managedProduct_multiRestrictions,
  GetActiveRestrictionsQuery,
  GetActiveRestrictionsVariables,
} from './__generated__/getActiveRestrictionsData.v2';
import * as queries from './getActiveRestrictionsData.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type ActiveRestrictions = GetActiveRestrictions_managedProduct_multiRestrictions;

export const useGetActiveRestrictionsData = (
  options?: QueryHookOptions<GetActiveRestrictionsQuery, GetActiveRestrictionsVariables>,
): QueryResult<GetActiveRestrictionsQuery, GetActiveRestrictionsVariables> => {
  return useSymphonyQuery(queries.GetActiveRestrictions, options);
};
