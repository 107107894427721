import React, { SyntheticEvent } from 'react';

import { useTickerSearch } from './useTickerSearch';

import { Divider } from '~/components/ui/Divider';
import { Autocomplete, Box, SearchIcon, SxProps, Theme, useTheme } from '~/components/ui/mui';
import { TextField } from '~/components/ui/TextField';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface HoldingsInfo {
  cusip?: string | null;
  expenseRatio?: string | null;
  identifier?: string | null;
  isManualSecurity?: boolean;
  marketPrice?: string | null;
  portfolioAssetClassBlend?: string | null;
  simpleSecurityType?: string | null;
}

export interface SecuritySearchOption {
  holdingsInfo?: HoldingsInfo;
  id?: string;
  isDivider?: boolean;
  label: string;
  name: string;
  ticker: string;
}

interface TickerSearchContent {
  label?: string;
  noOptionsText: string;
  placeholder: string;
}

export interface Props {
  ariaDescribedBy?: string;
  ariaLabelledBy?: string;
  content: TickerSearchContent;
  customOptions?: SecuritySearchOption[];
  dataQa?: string;
  error?: boolean;
  includeHoldingsInfo?: boolean;
  onTextInputChange?: (value: string) => void;
  onValueChange: (_event: SyntheticEvent, value: SecuritySearchOption | null, reason: string) => void;
  sx?: SxProps<Theme> | undefined;
  tickerSearchValue?: SecuritySearchOption | null;
}

export const TickerSearch: React.FC<Props> = ({
  ariaDescribedBy,
  ariaLabelledBy,
  content,
  customOptions = [],
  dataQa = 'ticker-search',
  error,
  onValueChange,
  onTextInputChange,
  includeHoldingsInfo,
  sx,
  tickerSearchValue,
}) => {
  const isMediumOrBelowScreen = useIsMediumScreen();
  const {
    sfAutocomplete: { styles: sfAutocompleteStyles },
  } = useTheme();

  const { loading: securitySearchOptionsLoading, securitySearchOptions, debounceSearchInput } = useTickerSearch({
    includeHoldingsInfo,
  });

  const LOADING_OPTION = 'Loading...';

  return (
    <Autocomplete
      disablePortal
      filterOptions={options => options}
      noOptionsText={securitySearchOptions ? content.noOptionsText : ''}
      onChange={onValueChange}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          debounceSearchInput(event, value);
        }
      }}
      options={
        securitySearchOptionsLoading
          ? [{ name: LOADING_OPTION, label: '', ticker: '', id: LOADING_OPTION }]
          : [...(securitySearchOptions ? securitySearchOptions : []), ...customOptions]
      }
      popupIcon={<SearchIcon />}
      renderInput={params => (
        <TextField
          autoFocus
          data-qa={`${dataQa}-ticker-search`}
          error={error}
          label={content.label}
          placeholder={content.placeholder}
          sx={{
            '& .MuiAutocomplete-inputRoot': {
              borderRadius: sfAutocompleteStyles.borderRadius,
            },
          }}
          {...params}
          inputProps={{
            ...params.inputProps,
            'aria-describedby': ariaDescribedBy,
            'aria-labelledby': ariaLabelledBy,
          }}
          onChange={event => {
            onTextInputChange?.(event.target.value);
          }}
        />
      )}
      renderOption={(props, option: SecuritySearchOption | null) => {
        const isLoading = option?.name === LOADING_OPTION;
        return (
          <>
            {option?.isDivider && <Divider />}
            {option && (
              <Box
                component="li"
                {...props}
                data-qa={`${dataQa}-ticker-${option.ticker}`}
                key={option.id}
                sx={{
                  color: customOptions.find(o => o.name === option.name) ? 'primary.main' : 'inherit',
                  ...(isLoading && { pointerEvents: 'none' }),
                }}
                tabIndex={isLoading ? -1 : props.tabIndex}
              >
                {option.name} {option.ticker}
              </Box>
            )}
          </>
        );
      }}
      sx={{
        width: isMediumOrBelowScreen ? undefined : '50%',
        '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
        ...sx,
      }}
      value={tickerSearchValue ?? null}
    />
  );
};
