import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { useVisibleManagedAccounts } from './useVisibleManagedAccounts';

import { AssociatedEntityType, BalanceType, FinancialAccountType, SleeveType } from '~/__generated__';
import { AccountActions, AccountActionsMenu, ActionName } from '~/components/AccountActions';
import { AddFundsData, useAccountActionModals } from '~/components/AccountActions/hooks';
import { isCustomAction } from '~/components/AccountActions/utils';
import { getAccountLabelFromVisibleAccounts } from '~/components/AccountHeader/util';
import { KebabMenu, KebabMenuItem } from '~/components/KebabMenu';
import { Alert } from '~/components/ui/Alert';
import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownChangeEvent, DropdownItem } from '~/components/ui/Dropdown/types';
import { ModalStateData } from '~/components/ui/Modal/hooks';
import { ArrowDropDownIcon, Box, Button, Grid, Skeleton, useTheme } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import {
  AvailableWithdrawalValueBySleeve,
  DetailedFinancialAccount,
  FinancialAccountManagedProductTradingSuspensions,
  isManagedProduct,
} from '~/hooks/account-details/symphony';
import { AccountDetailsGetDigitalWealthAccounts } from '~/hooks/account-details/symphony/__generated__/query.v2';
import { AccountDetailsHeaderContent } from '~/hooks/account-details/types';
import { AccountDetailsFeatureFlags } from '~/hooks/account-details/utils';
import { useAccountActionsMenu } from '~/hooks/client/useAccountActionsMenu';
import { FinancialAccount } from '~/hooks/financial-account/symphony';
import { PlaidLinkType } from '~/hooks/plaid-link';
import {
  AccountState,
  AccountStateData,
  BankAccount,
  isPendingPlanUpdateWorkflowStatus,
  shouldEnablePendingPUWWarning,
} from '~/utils/account';
import { ApolloQueryResult } from '~/utils/apollo-client';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';
import { formatMaskedAccountNumber } from '~/utils/format';
import { useIsMediumScreen } from '~/utils/responsiveness';

export type Props = {
  account?: DetailedFinancialAccount;
  accountState: AccountState;
  accountStateData?: AccountStateData;
  actions: ComponentProps<typeof AccountActions>['config'];
  addFundsActionModal: AddFundsData;
  bankAccounts: Partial<BankAccount>[];
  content: AccountDetailsHeaderContent;
  contentOptions: ContentOptions;
  dataQa?: string;
  digitalWealthAccountsData?: AccountDetailsGetDigitalWealthAccounts;
  digitalWealthAccountsInitialLoading: boolean;
  featureFlags?: AccountDetailsFeatureFlags;
  handleFeedbackMessage?: (feedbackMessages: string) => void;
  isRefetchingAccounts: boolean;
  managedProductId: string;
  onAddFundsClick: (value: { account: Partial<BankAccount>; value: string }) => void;
  onLinkAccountCallback: (newBankAccount: FinancialAccount) => void;
  onRetakeQuestionnaire?: (managedProductId: string) => void;
  onViewPerformanceReport?: (managedProductId: string) => void;
  partyId: string;
  partyIdFA?: string;
  performanceViewOption?: string;
  plaidLinkageType?: PlaidLinkType;
  redirectToSignDocuments?: (
    managedProductId: string,
    associatedEntities?: { entityId: string; entityType: AssociatedEntityType },
  ) => void;
  refetchDigitalWealthAccountsData?: () => Promise<ApolloQueryResult<AccountDetailsGetDigitalWealthAccounts>>;
  setPerformanceViewOption: (option: string) => void;
  tradingSuspensionsFilter?: (suspension: FinancialAccountManagedProductTradingSuspensions) => boolean;
  viewerPartyId?: string;
};

export const AccountHeader: React.FC<Props> = ({
  account,
  accountState,
  accountStateData,
  actions,
  addFundsActionModal,
  bankAccounts,
  content,
  contentOptions,
  dataQa = 'account-header',
  digitalWealthAccountsData,
  digitalWealthAccountsInitialLoading,
  featureFlags,
  handleFeedbackMessage,
  isRefetchingAccounts,
  managedProductId,
  onAddFundsClick,
  onLinkAccountCallback,
  onRetakeQuestionnaire,
  onViewPerformanceReport,
  partyId,
  partyIdFA,
  performanceViewOption,
  plaidLinkageType,
  redirectToSignDocuments,
  refetchDigitalWealthAccountsData,
  setPerformanceViewOption,
  tradingSuspensionsFilter,
  viewerPartyId,
}) => {
  const isMediumScreenOrBelow = useIsMediumScreen();
  const { sfAccountDetails } = useTheme();
  const {
    components: {
      sfAccountDetails: { enableViewPerformanceReport },
    },
    featureFlags: { addFundsSources },
  } = useCoreConfig();
  const {
    depositAmount: [depositAmount],
    sourceAccountId: [sourceId],
    modal: addFundsModal,
  } = addFundsActionModal;
  const [isRefetching, setIsRefetching] = useState(false);
  const [harvestLosses, setHarvestLosses] = useState(false);
  const {
    addRestrictions: { modal: addRestrictionsModal },
    viewClientDocuments: { modal: viewClientDocumentsModal },
    viewDocuments: { modal: viewDocumentsModal },
    viewPricing: { modal: viewPricingModal },
    viewRiskPreferenceHistory: { modal: viewRiskPreferenceHistoryModal },
    viewTransfers: { modal: viewTransfersModal },
    withdrawFunds: { modal: withdrawFundsModal },
    closeAccount: { modal: closeAccountModal },
    suspendBilling: { modal: suspendBillingModal },
    suspendTrading: { modal: suspendTradingModal },
    editAccount: { modal: editAccountModal },
    toggleTlh: { modal: tlhModal },
    retakeQuestionnaire: { modal: retakeQuestionnaireModal },
    switchWithinAnAccount: { modal: switchWithinAnAccountModal },
    other: { modal: otherActionsModal },
  } = useAccountActionModals();
  const { defaultContributionAmount, ignoreInsufficientFunds, showVerifiedBankAccounts, syncExternalBankAccounts } =
    featureFlags ?? {};
  const managedProduct = account?.products?.find(isManagedProduct);
  const accountNumberFormat = content.header.accountNumberFormat || '${accountNumber}';

  const accountType: FinancialAccountType =
    managedProduct?.accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE;

  const accountAttributes = useMemo(() => managedProduct?.attributes ?? [], [managedProduct?.attributes]);

  const groupLabelsContent = useMemo(
    () => ({
      activeAccountLabel: content.header.activeAccountLabel,
      closedAccountLabel: content.header.closedAccountLabel,
    }),
    [content],
  );

  const showRaiseCashCta =
    content.header.raiseCashCta &&
    actions.raiseCash?.valid?.({
      state: accountState,
      stateData: accountStateData,
      type: accountType,
      attributes: accountAttributes,
    });

  const isQuarterlyPerformanceActionValid = actions.quarterlyPerformance?.valid?.({
    state: accountState,
    stateData: accountStateData,
    type: accountType,
    attributes: accountAttributes,
  });

  const { data: accountActionsItems } = useAccountActionsMenu({
    contentOptions,
    enableViewPerformanceReport,
    harvestLosses,
  });

  const {
    data: visibleManagedAccounts,
    error: visibleManagedAccountsError,
    loading: visibleManagedAccountsLoading,
  } = useVisibleManagedAccounts({
    accountNumberFormat: content.header.accountNumberFormat,
    contentOptions,
    partyId,
    seeDetailsAccountAction: actions.seeDetails,
    tradingSuspensionsFilter,
    content: groupLabelsContent,
  });

  const tlhModalOnClose = async () => {
    setIsRefetching(true);
    const refetchData = await refetchDigitalWealthAccountsData?.();
    setHarvestLosses(!!refetchData?.data.client?.kyc?.harvestLosses);
    setIsRefetching(false);
    tlhModal.onClose();
  };

  const balance = account?.balances?.find(bal => bal.type === BalanceType.TOTAL_ACCOUNT)?.balance.value;

  const getAvailableWithdrawalBalance = (availableWithdrawalValueBySleeve?: AvailableWithdrawalValueBySleeve) => {
    return parseFloat(
      availableWithdrawalValueBySleeve?.find(sleeve => sleeve.sleeveType === SleeveType.INVESTMENT)?.availableValue ??
        '0',
    );
  };

  const availableWithdrawalBalance = getAvailableWithdrawalBalance(
    managedProduct?.funds?.availableWithdrawalValueBySleeve,
  );

  const onManagedProductChange = (event: DropdownChangeEvent) => {
    const value = event.target.value as string;
    if (actions.seeDetails && isCustomAction(actions.seeDetails)) {
      actions.seeDetails.callback({ partyId, managedProductId: value });
    }
  };

  const accountNumber = account?.accountNumber ?? undefined;

  const isQuarterlyPerformanceSelected =
    !!performanceViewOption && performanceViewOption === content.header.viewingDropdownOptions.quarterlyPerformance;

  const [enablePendingPUWWarning, planUpdateWorkflowId, planId] = useMemo(() => {
    return [
      shouldEnablePendingPUWWarning(partyIdFA, accountState),
      managedProduct?.planUpdateWorkflows.find(p => isPendingPlanUpdateWorkflowStatus(p?.status))?.id,
      managedProduct?.planId,
    ];
  }, [accountState, managedProduct?.planId, managedProduct?.planUpdateWorkflows, partyIdFA]);

  const handleOnAddFundsClick = useCallback(
    () => onAddFundsClick({ value: defaultContributionAmount || '', account: bankAccounts[0] }),
    [onAddFundsClick, defaultContributionAmount, bankAccounts],
  );

  const onActionClick = useCallback(
    (actionName: ActionName) => {
      const accountAction = actions[actionName];
      if (accountAction && isCustomAction(accountAction)) {
        accountAction.callback({
          accountNumber,
          enablePendingPUWWarning,
          maskedAccountNumber: account?.maskedAccountNumber ?? '',
          managedProductId,
          partyId,
          type: accountType,
        });
      } else {
        console.warn(`standard ${actionName} not implemented`);
      }
    },
    [
      actions,
      accountType,
      accountNumber,
      enablePendingPUWWarning,
      account?.maskedAccountNumber,
      managedProductId,
      partyId,
    ],
  );

  const multiAddFundsOptionItems = useMemo(() => {
    const items: KebabMenuItem[] = [];
    if (
      addFundsSources.includes('bankAccount') &&
      actions.addFunds?.valid?.({
        state: accountState,
        stateData: accountStateData,
        type: accountType,
        attributes: accountAttributes,
      })
    ) {
      items.push({ callback: handleOnAddFundsClick, text: content.header.bankAccountCta });
    }
    if (
      addFundsSources.includes('journaling') &&
      actions.journaling?.valid?.({
        state: accountState,
        stateData: accountStateData,
        type: accountType,
        attributes: accountAttributes,
      })
    ) {
      items.push({ callback: () => onActionClick('journaling'), text: content.header.journalingCta });
    }
    return items;
  }, [
    accountAttributes,
    handleOnAddFundsClick,
    actions.addFunds,
    accountState,
    accountStateData,
    accountType,
    actions.journaling,
    addFundsSources,
    onActionClick,
    content.header,
  ]);

  const accountLabels = useMemo(() => {
    if (!visibleManagedAccounts?.visibleAccounts) {
      return undefined;
    }
    const accounts = visibleManagedAccounts.visibleAccounts.filter(
      acc => acc.type !== 'list-item-header',
    ) as DropdownItem[];
    return accounts.map(acc => ({ label: acc.label.toString(), managedProductId: acc.value.toString() }));
  }, [visibleManagedAccounts?.visibleAccounts]);

  useEffect(() => {
    const harvestLossesFromSymphony = digitalWealthAccountsData?.client?.kyc?.harvestLosses;
    if (harvestLossesFromSymphony) {
      setHarvestLosses(harvestLossesFromSymphony);
    }
  }, [digitalWealthAccountsData?.client?.kyc?.harvestLosses]);

  const handleModalClose = (modal: ModalStateData, successMessage?: string) => {
    if (handleFeedbackMessage) {
      handleFeedbackMessage(successMessage ?? '');
    }
    modal.onClose(successMessage);
  };

  const commonAddFundsProps = {
    open: addFundsModal.open,
    onClose: (successMessage?: string) => handleModalClose(addFundsModal, successMessage),
    enablePendingPUWWarning,
    accountName: account?.maskedAccountNumber || 'unknown',
    bankAccountId: sourceId,
    additionAmount: depositAmount,
    partyId,
    managedProductId,
    ignoreInsufficientFunds,
    onLinkAccount: onLinkAccountCallback,
    refetchAccounts: refetchDigitalWealthAccountsData,
    redirectToSignDocuments,
    showVerifiedBankAccounts,
    syncExternalBankAccounts,
    legalDocuments: managedProduct?.legalDocuments ?? [],
    viewerPartyId,
  };

  const ariaLabel = `${content.accessibility.accountDetailsMenuPreLabel} ${account?.maskedAccountNumber} ${content.accessibility.accountDetailsMenuLabel}`;
  return (
    <>
      {!digitalWealthAccountsInitialLoading && account && managedProduct && (
        <AccountActions
          addFunds={{
            ...commonAddFundsProps,
            destinationAccountId: managedProduct.id,
            accountRestrictions: managedProduct.accountRestrictions,
            destinationAccountType: managedProduct.accountType as FinancialAccountType,
          }}
          addFundsV2={{
            ...commonAddFundsProps,
            accountType,
            accountHasRecurringDeposit: false, // TODO: determine this after https://sigfig.atlassian.net/browse/DWCORE-8004
            accountRestrictions: managedProduct.accountRestrictions,
            defaultDestinationAccountId: managedProductId,
            destinationAccounts: [
              {
                accountId: managedProductId,
                accountName: getAccountLabelFromVisibleAccounts(
                  managedProductId,
                  visibleManagedAccounts?.visibleAccounts,
                  account.maskedAccountNumber,
                ),
                accountType,
              },
            ],
            partyIdFA,
          }}
          addRestrictions={{
            open: addRestrictionsModal.open,
            onClose: addRestrictionsModal.onClose,
          }}
          allAccounts={visibleManagedAccounts?.allAccounts ?? []}
          attributes={accountAttributes}
          closeAccount={{
            accountId: account.id,
            open: closeAccountModal.open,
            partyId,
            managedProductId,
            managedProductType: managedProduct.program,
            maskedAccountNumber: formatMaskedAccountNumber(accountNumberFormat, account.maskedAccountNumber),
            onClose: (successMessage?: string) => handleModalClose(closeAccountModal, successMessage),
            onLinkAccount: onLinkAccountCallback,
            refetchAccounts: refetchDigitalWealthAccountsData,
            currentBalance: availableWithdrawalBalance,
            redirectToSignDocuments,
            showVerifiedBankAccounts,
            syncExternalBankAccounts,
          }}
          config={actions}
          contentOptions={contentOptions}
          editAccount={{
            enablePendingPUWWarning,
            open: editAccountModal.open,
            onClose: editAccountModal.onClose,
            accountEditDisabled: true,
            managedProductId: managedProduct.id,
            onRetakeQuestionnaire,
            // TODO: add isEditAccountDisabled here
            partyId,
            planId,
            planUpdateWorkflowId,
            refetchAccounts: refetchDigitalWealthAccountsData,
          }}
          managedProductId={managedProduct.id}
          managedProductType={managedProduct.program}
          other={{
            open: otherActionsModal.open,
            onClose: otherActionsModal.onClose,
          }}
          partyId={partyId}
          plaidLinkageType={plaidLinkageType}
          retakeQuestionnaire={{
            open: retakeQuestionnaireModal.open,
            onClose: retakeQuestionnaireModal.onClose,
          }}
          state={accountState}
          stateData={accountStateData}
          suspendBilling={{
            open: suspendBillingModal.open,
            managedProductId,
            maskedAccountNumber: account.maskedAccountNumber ?? '',
            onClose: suspendBillingModal.onClose,
          }}
          suspendTrading={{
            open: suspendTradingModal.open,
            managedProductId,
            onClose: suspendTradingModal.onClose,
          }}
          switchWithinAnAccount={{
            open: switchWithinAnAccountModal.open,
            onClose: switchWithinAnAccountModal.onClose,
          }}
          toggleTlh={{
            open: tlhModal.open,
            onClose: tlhModalOnClose,
            isRefetching,
            partyId,
          }}
          type={accountType}
          viewClientDocuments={{
            open: viewClientDocumentsModal.open,
            onClose: viewClientDocumentsModal.onClose,
            managedProductId,
          }}
          viewDocuments={{
            open: viewDocumentsModal.open,
            onClose: viewDocumentsModal.onClose,
            partyId,
          }}
          viewPricing={{
            open: viewPricingModal.open,
            onClose: viewPricingModal.onClose,
            accountValue: parseFloat(balance ?? '0'),
            maskedAccountNumber: account.maskedAccountNumber ?? '',
          }}
          viewRiskPreferenceHistory={{
            accountId: account.accountNumber ?? '',
            managedProductId,
            open: viewRiskPreferenceHistoryModal.open,
            onClose: viewRiskPreferenceHistoryModal.onClose,
          }}
          viewTransfers={{
            open: viewTransfersModal.open,
            onClose: viewTransfersModal.onClose,
            onWithdrawalCancelled: refetchDigitalWealthAccountsData,
            partyId,
            accountId: account.id as string,
            managedProductId,
            accountNumberFormat,
          }}
          withdrawFunds={{
            accountIdRestrictionsMap: { [`${managedProductId}`]: managedProduct.accountRestrictions },
            accountMinimum: managedProduct.accountMinimums?.minimumBalanceForRebalance,
            open: withdrawFundsModal.open,
            onClose: (successMessage?: string) => handleModalClose(withdrawFundsModal, successMessage),
            partyId,
            managedProductId,
            accountName: account.maskedAccountNumber || 'unknown',
            accountId: account.id as string,
            accountLabels,
            managedProductType: managedProduct.program,
            /**
             * TODO: BalanceType.WITHDRAWAL_LIMIT seems appropriate to use here, it doesn't seem to be hooked up in Symphony right now,
             * using BalanceType.TOTAL_ACCOUNT for now
             */
            availableFunds: availableWithdrawalBalance,
            defaultWithdrawalAmount: defaultContributionAmount,
            onLinkAccount: onLinkAccountCallback,
            onWithdrawSuccess: refetchDigitalWealthAccountsData,
            redirectToSignDocuments,
            showVerifiedBankAccounts,
            sourceAccounts: [
              {
                value: managedProductId,
                label: getAccountLabelFromVisibleAccounts(
                  managedProductId,
                  visibleManagedAccounts?.visibleAccounts,
                  account.maskedAccountNumber,
                ),
              },
            ],
            syncExternalBankAccounts,
          }}
        />
      )}
      {/* title not available in Account Details V1*/}
      {content.header.title && (
        <Typography color="text.secondary" component="h1" mt={4} variant="overline" width="100%">
          {content.header.title}
        </Typography>
      )}
      <Grid className="AccountDetails-header" container mt={content.header.title ? undefined : 4} spacing={3}>
        <Grid item md="auto" xs={12}>
          {(digitalWealthAccountsInitialLoading || visibleManagedAccountsLoading) && <Skeleton />}
          {managedProduct?.id && visibleManagedAccounts && (
            <Dropdown
              data-qa={`${dataQa}-account-name`}
              id="account-select"
              // TODO: ANR-6427 - CMS update ticket for ADA.
              inputProps={{ 'aria-label': 'Performance for', sx: sfAccountDetails.styles.dropdownLabel }}
              items={visibleManagedAccounts.visibleAccounts}
              onChange={onManagedProductChange}
              sx={sfAccountDetails.styles.accountDropdown}
              value={managedProduct.id}
              variant="accountDetails"
            />
          )}
          {visibleManagedAccountsError && (
            <Alert contentOptions={contentOptions} error={visibleManagedAccountsError} severity="error" />
          )}
          {!!managedProduct?.valueBelowMinimum && (
            <Alert severity="warning" sx={{ my: 2 }} variant="outlined">
              {content.header.belowAccountMinimumLabel}
            </Alert>
          )}
        </Grid>
        <Grid item md xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', height: '100%' }}>
            {enableViewPerformanceReport && onViewPerformanceReport && isQuarterlyPerformanceActionValid && (
              <Button
                disabled={isRefetchingAccounts}
                fullWidth={isMediumScreenOrBelow}
                onClick={() => onViewPerformanceReport(managedProductId)}
                sx={{ mr: 2 }}
                variant="outlined"
              >
                {/* TODO: to come from contentstack */}
                {'View Performance Report'}
              </Button>
            )}
            {multiAddFundsOptionItems.length > 1 ? (
              <KebabMenu
                buttonContent={<Typography>{content.header.addFundsCta}</Typography>}
                buttonProps={{
                  endIcon: <ArrowDropDownIcon />,
                  size: 'medium',
                  sx: { ...sfAccountDetails.styles.addFundsKebabMenu?.button },
                  variant: 'contained',
                }}
                items={multiAddFundsOptionItems}
                menuListProps={{
                  sx: { ...sfAccountDetails.styles.addFundsKebabMenu?.list },
                }}
              />
            ) : (
              multiAddFundsOptionItems.length === 1 && (
                <Button
                  data-heap={`addFunds-${partyId}`}
                  disabled={isRefetchingAccounts}
                  fullWidth={isMediumScreenOrBelow}
                  onClick={handleOnAddFundsClick}
                  sx={{ md: { ml: 2 } }}
                  variant="contained"
                >
                  {content.header.addFundsCta}
                </Button>
              )
            )}
            {showRaiseCashCta && !isQuarterlyPerformanceSelected && (
              <Button
                disabled={isRefetchingAccounts}
                fullWidth={isMediumScreenOrBelow}
                onClick={withdrawFundsModal.openModal}
                sx={{ md: { ml: 2 } }}
                variant="contained"
              >
                {content.header.raiseCashCta}
              </Button>
            )}
            {accountActionsItems?.length && managedProduct && !isQuarterlyPerformanceSelected && (
              <AccountActionsMenu
                accountAttributes={accountAttributes}
                accountState={accountState}
                accountStateData={accountStateData}
                accountType={accountType}
                /**
                 * There shouldn't be a problem with the way the accountNumber prop is set in the following line, but doing
                 * so causes a bunch of "React Hook is call conditionally" false positives. Storing account?.accountNumber ?? undefined
                 * in a separate variable fixes the false positives. It's not very clear why this fixes the false positives,
                 * so leaving this here as a reference in case other people run into similar problems
                 */
                // accountNumber={account?.accountNumber ?? undefined}
                allAccounts={visibleManagedAccounts?.allAccounts ?? []}
                ariaLabel={ariaLabel}
                config={actions}
                customActionArgs={{
                  accountNumber,
                  balance,
                  managedProductId,
                  maskedAccountNumber: account?.maskedAccountNumber ?? '',
                  partyId,
                }}
                items={accountActionsItems}
                managedProductType={managedProduct.program}
                modalLaunchers={{
                  viewClientDocuments: viewClientDocumentsModal.openModal,
                  viewDocuments: viewDocumentsModal.openModal,
                  viewPricing: viewPricingModal.openModal,
                  viewRiskPreferenceHistory: viewRiskPreferenceHistoryModal.openModal,
                  viewTransfers: viewTransfersModal.openModal,
                  addRestrictions: addRestrictionsModal.openModal,
                  withdrawFunds: withdrawFundsModal.openModal,
                  closeAccount: closeAccountModal.openModal,
                  suspendBilling: suspendBillingModal.openModal,
                  suspendTrading: suspendTradingModal.openModal,
                  editAccount: editAccountModal.openModal,
                  toggleTlh: tlhModal.openModal,
                  switchWithinAnAccount: switchWithinAnAccountModal.openModal,
                  retakeQuestionnaire: retakeQuestionnaireModal.openModal,
                  other: otherActionsModal.openModal,
                }}
                partyId={partyId}
                quarterlyPerformanceAction={{
                  setPerformanceViewOption,
                  quarterlyPerformanceViewOption: content.header.viewingDropdownOptions.quarterlyPerformance,
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
