import React from 'react';

import { ClosingOptions } from '~/components/modals/CloseAccount/utils';
import { Alert } from '~/components/ui/Alert';
import { Box } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  closeRequestError?: Error | undefined;
  content: any;
  contentOptions: ContentOptions;
  isRetirementAccount?: boolean;
  selectedClosingOption?: ClosingOptions;
  sellAssets?: boolean;
  todaysDate?: string;
}

export const CloseAccountConfirmation: React.FC<Props> = ({
  isRetirementAccount,
  content,
  contentOptions,
  todaysDate,
  closeRequestError,
  selectedClosingOption,
  sellAssets,
}) => {
  return (
    <Box sx={{ px: 2, py: 3 }}>
      <RteContent
        data={
          selectedClosingOption === ClosingOptions.END_MANAGEMENT
            ? sellAssets
              ? content?.end_management?.confirm_sell?.message ?? ''
              : content?.end_management?.confirm_retain?.message ?? ''
            : isRetirementAccount
            ? content?.withdraw_and_close?.confirm_retirement?.message ?? ''
            : content?.withdraw_and_close?.confirm?.message ?? ''
        }
        sx={{ mb: 2 }}
      />
      <RteContent
        config={{ date: todaysDate }}
        data={
          selectedClosingOption === ClosingOptions.END_MANAGEMENT
            ? sellAssets
              ? content?.end_management?.confirm_sell?.details ?? ''
              : content?.end_management?.confirm_retain?.details ?? ''
            : isRetirementAccount
            ? content?.withdraw_and_close?.confirm_retirement?.details ?? ''
            : content?.withdraw_and_close?.confirm?.details ?? ''
        }
      />
      {closeRequestError && <Alert contentOptions={contentOptions} error={closeRequestError} severity="error" />}
    </Box>
  );
};
