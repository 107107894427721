import React from 'react';

import { Dropdown } from '~/components/ui/Dropdown';
import { DropdownItem } from '~/components/ui/Dropdown/types';

export enum ErrorsStatusFilters {
  ALL = 'ALL',
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
}

export type ErrorsStatusFilterProps = {
  currentStatusFilter: ErrorsStatusFilters;
  displayStrings: {
    statusFilter: Record<ErrorsStatusFilters, string>;
  };
  onChange: (newStatusFilter: ErrorsStatusFilters) => void;
};

export const ErrorsStatusFilter: React.FC<ErrorsStatusFilterProps> = ({
  currentStatusFilter,
  displayStrings: { statusFilter },
  onChange,
}) => {
  const items: DropdownItem[] = [
    {
      value: ErrorsStatusFilters.ALL,
      label: statusFilter[ErrorsStatusFilters.ALL],
    },
    {
      value: ErrorsStatusFilters.OPEN,
      label: statusFilter[ErrorsStatusFilters.OPEN],
    },
    {
      value: ErrorsStatusFilters.RESOLVED,
      label: statusFilter[ErrorsStatusFilters.RESOLVED],
    },
  ];
  return (
    <Dropdown
      dataQa="errors-status-filter"
      items={items}
      onChange={e => onChange(e.target.value as ErrorsStatusFilters)}
      value={currentStatusFilter}
      width="160px"
    />
  );
};
