import { ComponentProps } from 'react';

import { FinancialAccountTransactionType } from '~/__generated__';
import {
  DateFilterConfig,
  DateFilterData,
  DateFilterValues,
  DateRangeOptions,
} from '~/components/ControlFields/DateFilters/types';
import { SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { Dropdown } from '~/components/ui/Dropdown';

export interface FilterContent {
  closeFilter: string;
  dateRange: string;
  dateRangeOptionLabels: Record<DateRangeOptions, string>;
  endDate: string;
  endDateErrorMessage: string;
  filter: string;
  invalidDateRangeErrorMessage: string;
  minDateErrorMessage: string;
  startDate: string;
  startDateErrorMessage: string;
  transactionType: string;
  transactionTypeOptionLabels: Record<TransactionTypeFilter, string>;
}

export interface MobileSortContent {
  label: string;
  mobileSortOptionLabels: Record<MobileSortOption, string>;
}

export interface AccountActivityContent {
  disclaimer: string;
  download: string;
  filter: FilterContent;
  mobileSort: MobileSortContent;
  table: {
    descriptions: Partial<Record<FinancialAccountTransactionType, string>>;
    emptyMessage: string;
    emptyMessageWithCTA: {
      after: string;
      before: string;
      cta: string;
    };
    symbolPlaceholder: string;
    tableColumns: TableColumn[];
  };
}

export enum AccountActivityColumnKeys {
  AMOUNT = 'amount',
  DATE = 'date',
  DESCRIPTION = 'description',
  SYMBOL = 'symbol',
}

export interface MobileSortData {
  config: ComponentProps<typeof Dropdown>;
  onMobileSortChange: (option: MobileSortOption) => void;
  value: MobileSortOption;
}

export interface AccountActivityData {
  content: AccountActivityContent;
  currentPage: number;
  dataLoading: boolean;
  downloadError?: Error;
  downloadLoading: boolean;
  filterData: FilterData;
  isFilterModalOpen: boolean;
  mobileSortData: MobileSortData;
  onCloseFilterModal: () => void;
  onDownloadCSV: () => void;
  onOpenFilterModal: () => void;
  onUpdatePage: (page: number) => void;
  sortConfig: SortConfig;
  tableData: TableData[];
  totalPages: number;
}

export interface TabState {
  currentPageNumber?: number;
  filterValues?: FilterValues;
  sortConfig?: SortConfig;
}

export interface FilterConfig extends DateFilterConfig {
  transactionTypeOption?: ComponentProps<typeof Dropdown>;
}

export interface FilterValues extends DateFilterValues {
  transactionType?: TransactionTypeFilter;
}

export interface FilterData extends DateFilterData {
  config: FilterConfig;
  onTransactionTypeOptionChange?: (option: TransactionTypeFilter) => void;
  values: FilterValues;
}

export enum AccountActivityContentKeys {
  DATE_RANGE = 'date_range',
  DATE_RANGE_CUSTOM = 'date_range_custom',
  DATE_RANGE_LAST_30_DAYS = 'date_range_last_30_days',
  DATE_RANGE_LAST_60_DAYS = 'date_range_last_60_days',
  DATE_RANGE_LAST_90_DAYS = 'date_range_last_90_days',
  DATE_RANGE_YTD = 'date_range_ytd',
  DISCLAIMER = 'disclaimer',
  DOWNLOAD = 'download',
  EMPTY_MESSAGE = 'empty_message',
  EMPTY_MESSAGE_AFTER_CTA = 'empty_message_after_cta',
  EMPTY_MESSAGE_BEFORE_CTA = 'empty_message_before_cta',
  EMPTY_MESSAGE_CTA = 'empty_message_cta',
  END_DATE = 'end_date',
  END_DATE_ERROR_MESSAGE = 'end_date_error_message',
  FILTER_CLOSE = 'filter_close',
  FILTER_LABEL = 'filter_label',
  INVALID_DATE_RANGE_ERROR_MESSAGE = 'invalid_date_range_error_message',
  MIN_DATE_ERROR_MESSAGE = 'min_date_error_message',
  SORT_FILTER = 'sort_filter',
  SORT_FILTER_DATE_ASCENDING = 'sort_filter_date_ascending',
  SORT_FILTER_DATE_DESCENDING = 'sort_filter_date_descending',
  START_DATE = 'start_date',
  START_DATE_ERROR_MESSAGE = 'start_date_error_message',
  SYMBOL_PLACEHOLDER = 'symbol_placeholder',
  TABLE_COLUMN_AMOUNT = 'table_column_amount',
  TABLE_COLUMN_DATE = 'table_column_date',
  TABLE_COLUMN_DESCRIPTION = 'table_column_description',
  TABLE_COLUMN_SYMBOL = 'table_column_symbol',
  TABLE_DESCRIPTION_BOND_OPTION_TRADE = 'table_description_bond_option_trade',
  TABLE_DESCRIPTION_CAPITAL_GAINS = 'table_description_capital_gains',
  TABLE_DESCRIPTION_CAPITAL_GAINS_REINVESTED = 'table_description_capital_gains_reinvested',
  TABLE_DESCRIPTION_DIVIDEND_RECEIVED = 'table_description_dividend_received',
  TABLE_DESCRIPTION_DIVIDEND_REINVESTED = 'table_description_dividend_reinvested',
  TABLE_DESCRIPTION_FEES = 'table_description_fees',
  TABLE_DESCRIPTION_FUNDS_TRANSFER = 'table_description_funds_transfer',
  TABLE_DESCRIPTION_INTEREST_RECEIVED = 'table_description_interest_received',
  TABLE_DESCRIPTION_INTEREST_REINVESTED = 'table_description_interest_reinvested',
  TABLE_DESCRIPTION_OTHER = 'table_description_other',
  TABLE_DESCRIPTION_REINVESTMENT = 'table_description_reinvestment',
  TABLE_DESCRIPTION_RETIREMENT_CONTRIBUTION = 'table_description_retirement_contribution',
  TABLE_DESCRIPTION_SECURITY_NAME_CHANGE = 'table_description_security_name_change',
  TABLE_DESCRIPTION_TRADE = 'table_description_trade',
  TRANSACTION_TYPES = 'transaction_types',
  TRANSACTION_TYPES_ALL = 'transaction_types_all',
  TRANSACTION_TYPES_FEES = 'transaction_types_fees',
  TRANSACTION_TYPES_FUNDS_TRANSFER = 'transaction_types_funds_transfer',
  TRANSACTION_TYPES_INCOME = 'transaction_types_income',
  TRANSACTION_TYPES_OTHER = 'transaction_types_other',
  TRANSACTION_TYPES_REINVESTMENT = 'transaction_types_reinvestment',
  TRANSACTION_TYPES_TRADE_ACTIVITY = 'transaction_types_trade_activity',
}

export enum MobileSortOption {
  DATE_ASCENDING = 'DATE_ASCENDING',
  DATE_DESCENDING = 'DATE_DESCENDING',
}

export enum TransactionTypeFilter {
  ALL = 'ALL',
  FEES = 'FEES',
  FUNDS_TRANSFER = 'FUNDS_TRANSFER',
  INCOME = 'INCOME',
  OTHER = 'OTHER',
  REINVESTMENT = 'REINVESTMENT',
  TRADE_ACTIVITY = 'TRADE_ACTIVITY',
}
