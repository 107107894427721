import {
  GetBankVerificationContentV2,
  GetBankVerificationContentV2_all_bank_verification_table_items_labels,
} from '../contentstack/__generated__/query.v2';

import { BankVerificationFilters, BankVerificationLabel, VerificationStatusFilter } from './types';

import { FinancialAccountAssociationVerificationStatus } from '~/__generated__';
import { TableColumn } from '~/components/ui/BasicTable';
import { FilterContext } from '~/containers/OpsDashboardV2/BankVerificationTab/symphony';
import { ContentstackTableColumn, sortColumnsByColumnOrder } from '~/utils/table';

/**
 * @param {VerificationStatusFilter}  statusFilter - Value of Status Filter defined as enum in utils/types.ts
 * @returns {FinancialAccountAssociationVerificationStatus[]} Returns of list of Verification Status associated to a financial account based on statusFilter passed to the function.
 */
export const getFinancialAccountAssociationVerificationStatusFromVerificationStatusFilter = (
  statusFilter: VerificationStatusFilter,
): FinancialAccountAssociationVerificationStatus[] => {
  const financialAccountAssociationVerificationStatus: FinancialAccountAssociationVerificationStatus[] = [];
  switch (statusFilter) {
    case VerificationStatusFilter.ACCEPTED:
      financialAccountAssociationVerificationStatus.push(FinancialAccountAssociationVerificationStatus.ACCEPTED);
      break;
    case VerificationStatusFilter.REJECTED:
      financialAccountAssociationVerificationStatus.push(FinancialAccountAssociationVerificationStatus.REJECTED);
      break;
    case VerificationStatusFilter.IN_REVIEW:
      financialAccountAssociationVerificationStatus.push(FinancialAccountAssociationVerificationStatus.IN_REVIEW);
      break;
    default:
      financialAccountAssociationVerificationStatus.push(
        FinancialAccountAssociationVerificationStatus.ACCEPTED,
        FinancialAccountAssociationVerificationStatus.REJECTED,
        FinancialAccountAssociationVerificationStatus.IN_REVIEW,
      );
      break;
  }
  return financialAccountAssociationVerificationStatus;
};

/**
 * @param {(GetBankVerificationContentV2_all_bank_verification_table_items_labels | null)[]}  bankVerificationLabelContent - List of object containing key value pairs of Labels for Bank Verification Table. Key is mapped to the enum BankVerificationLabel defined in utils/types and value is the text displayed to the user. Data originates from Content Stack
 * @returns {FinancialAccountAssociationVerificationStatus[]} Returns of list of Verification Status associated to a financial account based on statusFilter passed to the function.
 */
export const getBankVerificationLabelContent = (
  bankVerificationLabelContent: (GetBankVerificationContentV2_all_bank_verification_table_items_labels | null)[],
) => {
  const feedbackMessage =
    bankVerificationLabelContent.find(v => v?.label_key === BankVerificationLabel.FEEDBACK_MESSAGE)?.label_value ?? '';
  const reviewButtonLabel =
    bankVerificationLabelContent.find(v => v?.label_key === BankVerificationLabel.REVIEW_BUTTON_TEXT)?.label_value ??
    '';
  const filterDropdownLabel =
    bankVerificationLabelContent.find(v => v?.label_key === BankVerificationLabel.FILTER_DROPDOWN_LABEL)?.label_value ??
    '';

  return {
    feedbackMessage,
    reviewButtonLabel,
    filterDropdownLabel,
  };
};

export const getTableColumns = (contentData: GetBankVerificationContentV2 | undefined, onSort: () => void) => {
  const item = contentData?.all_bank_verification_table?.items?.[0];
  const columns = item?.columns?.map(v => {
    return {
      ...v,
      column_id: `${v?.column_key}`,
      column_order: +`${v?.column_order}`,
      column_value: `${v?.column_value}`,
    };
  });
  // Filter empty columns
  const contentstackTableColumn =
    columns?.reduce<ContentstackTableColumn[]>((acc, column: ContentstackTableColumn | null) => {
      if (column) {
        acc.push(column);
      }

      return acc;
    }, []) ?? [];

  const sortedColumns = sortColumnsByColumnOrder(contentstackTableColumn);
  return sortedColumns.map(
    (col): TableColumn => ({
      title: col.column_value,
      key: col.column_id ?? '',
      ...(col.column_id === 'dateCreated'
        ? {
            onSort: (_: string) => () => {
              onSort();
            },
          }
        : {}),
    }),
  );
};

export const getFilterCountMap = (filterContext: FilterContext[]) => {
  return filterContext.map(v => {
    return {
      count: v.count,
      filters: {
        [BankVerificationFilters.STATUS]: `${v.filtersKey.status}`,
      },
    };
  });
};
