import { addYears, max, min, parseISO, subYears } from 'date-fns';
import { pipe } from 'fp-ts/lib/function';
// eslint-disable-next-line no-restricted-imports
import { first, last } from 'lodash';
import React, { FC, useState } from 'react';

import { useGetReadinessStatuses } from '../../hooks/useGetReadinessStatuses';
import { getReadinessStatus } from '../../utils';

import { groupGoals, parseGoals } from './util';

import { Moment, Timeline } from '~/components/Timeline';
import { Alert } from '~/components/ui/Alert';
import { Divider } from '~/components/ui/Divider';
import { Box, Button, MuiTypography, Skeleton, Stack } from '~/components/ui/mui';
import { useGetGoals, useGetPartnerConfig } from '~/hooks/goals/amplify';
import { useGoalObjectivesContent } from '~/hooks/goals/contentstack/useGoalObjectivesContent';
import { ContentOptions } from '~/utils/contentstack';
import { formatCurrency } from '~/utils/format';
import { formatDate } from '~/utils/format/date';

interface Props {
  content: {
    addGoal: string;
    completedGoal: string;
    myGoal: string;
    today: string;
  };
  contentOptions: ContentOptions;
  dataQa?: string;
  onCreateGoal: () => void;
  partnerConfigId: string;
  partyId: string;
}

export const GoalsTimeline: FC<Props> = ({
  content,
  contentOptions,
  dataQa = 'goals-timeline',
  onCreateGoal,
  partnerConfigId,
  partyId,
}) => {
  const goalObjectivesResult = useGoalObjectivesContent({ variables: contentOptions });
  const [expandedGoalId, setExpandedGoalId] = useState<string>();
  const goalsResponse = useGetGoals({ variables: { partyId } });
  const configResponse = useGetPartnerConfig({ variables: { partnerConfigId } });
  const readinessStatusesResponse = useGetReadinessStatuses({ contentOptions, partnerConfigId });

  if (
    goalsResponse.loading ||
    configResponse.loading ||
    readinessStatusesResponse.loading ||
    goalObjectivesResult.loading
  ) {
    return <Skeleton height={300} />;
  }

  const error =
    goalsResponse.error ?? configResponse.error ?? readinessStatusesResponse.error ?? goalObjectivesResult.error;
  if (error) {
    return <Alert error={error} severity="error" />;
  }

  const moments = pipe(
    goalsResponse.data?.getUserByPartyId?.goals?.items ?? [],
    parseGoals,
    goals => [...goals].sort((a, b) => a.date.getTime() - b.date.getTime()),
    groupGoals,
    groupedGoals =>
      groupedGoals.map<Moment>(group => {
        const date = new Date(group.reduce((sum, goal) => sum + goal.date.getTime(), 0) / group.length);
        return {
          key: date.getTime(),
          date,
          tickStyle: group.some(goal => !goal.isComplete) ? 'outlined' : 'filled',
          tooltip: (
            <Stack alignItems="center" spacing={1} width={44}>
              {group.map(goal => (
                <Tooltip
                  amount={goal.amount}
                  color={
                    goal.readinessFraction !== undefined
                      ? getReadinessStatus(readinessStatusesResponse.data ?? [], goal.readinessFraction)?.color
                      : undefined
                  }
                  date={goal.date}
                  expanded={expandedGoalId === goal.id}
                  icon={goalObjectivesResult.data?.get(goal.key)?.icon.secondary}
                  key={goal.id}
                  locale={contentOptions.locale}
                  name={goal.title}
                  onClick={() => setExpandedGoalId(curr => (curr !== goal.id ? goal.id : undefined))}
                />
              ))}
            </Stack>
          ),
        };
      }),
  );

  const firstMoment = first(moments);
  const lastMoment = last(moments);

  if (!goalsResponse.data?.getUserByPartyId || !configResponse.data?.partnerConfig || !firstMoment || !lastMoment) {
    return <NullState content={{ addGoal: content.addGoal }} onCreateGoal={onCreateGoal} />;
  }

  return (
    <Stack data-qa={dataQa} mb={10} spacing={2}>
      <Legend content={{ completedGoal: content.completedGoal, myGoal: content.myGoal }} data-qa={`${dataQa}-legend`} />
      <Timeline
        content={{ today: content.today }}
        end={max([
          addYears(
            parseISO(goalsResponse.data.getUserByPartyId.dateOfBirth),
            configResponse.data.partnerConfig.lifeExpectancy,
          ),
          addYears(Date.now(), 10),
          addYears(lastMoment.date, 10),
        ])}
        moments={moments}
        start={min([subYears(firstMoment.date, 10), subYears(Date.now(), 10)])}
      />
    </Stack>
  );
};

const NullState = ({ content, onCreateGoal }: { content: { addGoal: string }; onCreateGoal: () => void }) => (
  <Box height={200}>
    <Stack alignItems="center" direction="row" height="100%">
      <Box bgcolor={({ palette }) => palette.grey[300]} height={12} width={2} />
      <Box bgcolor={({ palette }) => palette.grey[300]} height={2} width="100%" />
      <Button onClick={() => onCreateGoal()} sx={{ minWidth: 'max-content' }} variant="outlined">
        {content.addGoal}
      </Button>
      <Box bgcolor={({ palette }) => palette.grey[300]} height={2} width="100%" />
      <Box bgcolor={({ palette }) => palette.grey[300]} height={12} width={2} />
    </Stack>
  </Box>
);

const Tooltip = ({
  amount,
  color,
  date,
  expanded,
  icon,
  locale,
  name,
  onClick,
}: {
  amount: number;
  color?: string;
  date: Date;
  expanded?: boolean;
  icon?: string;
  locale: string;
  name: string;
  onClick: () => void;
}) => (
  <Box
    bgcolor={({ palette }) => palette.grey[100]}
    border="solid 2px"
    borderColor={color ?? 'common.white'}
    borderRadius={2}
    onClick={onClick}
    p={1}
    sx={{ cursor: 'pointer' }}
    zIndex={expanded ? 1 : undefined}
  >
    <Stack
      alignItems="center"
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      spacing={expanded ? 1 : undefined}
    >
      <Box height={24} width={24}>
        <img alt="" height="100%" src={icon} />
      </Box>
      {expanded && (
        <Stack>
          <MuiTypography noWrap variant="subtitle2">
            {name}
          </MuiTypography>
          <Stack direction="row" spacing={1}>
            <MuiTypography noWrap variant="body2">
              {formatDate(date, 'MMM yyyy', { locale })}
            </MuiTypography>
            <MuiTypography noWrap variant="body2">
              {formatCurrency(amount, { locale })}
            </MuiTypography>
          </Stack>
        </Stack>
      )}
    </Stack>
  </Box>
);

const Legend = ({ content }: { content: { completedGoal: string; myGoal: string } }) => (
  <Stack direction="row" pb={2} spacing={4}>
    <Stack alignItems="center" direction="row" spacing={2}>
      <Box
        bgcolor={({ palette }) => palette.common.white}
        border={({ palette }) => `3px solid ${palette.common.black}`}
        borderRadius={6}
        height={6}
        width={6}
      />
      <MuiTypography variant="caption">{content.myGoal}</MuiTypography>
    </Stack>
    <Stack alignItems="center" direction="row" spacing={2}>
      <Box bgcolor={({ palette }) => palette.common.black} borderRadius={12} height={12} width={12} />
      <MuiTypography variant="caption">{content.completedGoal}</MuiTypography>
    </Stack>
  </Stack>
);
