import React, { ComponentProps, useState } from 'react';

import { Box, ContentCopyIcon } from '~/components/ui/mui';
import { Tooltip } from '~/components/ui/Tooltip';

export interface Props {
  children?: React.ReactNode;
  contentCopyIconColor?: ComponentProps<typeof ContentCopyIcon>['color'];
  copyLabel: string;
  dataQa?: string;
  defaultState?: boolean;
  disableToggling?: boolean;
  successLabel?: string;
  textToCopy: string;
}

export const CopyField: React.FC<Props> = ({
  contentCopyIconColor = 'inherit',
  dataQa,
  copyLabel,
  children,
  textToCopy,
  successLabel,
  defaultState,
  disableToggling,
}) => {
  const [showCopyIcon, setShowCopyIcon] = useState(defaultState);
  const [isCopyInProgress, setIsCopyInProgress] = useState<boolean>(false);

  const copyText = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopyInProgress(true);
      setTimeout(() => {
        setIsCopyInProgress(false);
      }, 1500);
    });
  };

  const handleShowCopyIcon = (value: boolean) => {
    if (!disableToggling) {
      setShowCopyIcon(value);
    }
  };

  return (
    <Box
      data-qa={dataQa}
      onMouseEnter={() => handleShowCopyIcon(true)}
      onMouseLeave={() => handleShowCopyIcon(false)}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {children}
      <Box sx={{ visibility: showCopyIcon ? 'visible' : 'hidden' }}>
        <Tooltip
          disableHoverListener={false}
          disableOnClick
          placement="top"
          tooltipContent={isCopyInProgress ? successLabel ?? '' : copyLabel}
          variant="popup"
        >
          <ContentCopyIcon color={contentCopyIconColor} fontSize="inherit" onClick={copyText} sx={{ py: 0, px: 0.5 }} />
        </Tooltip>
      </Box>
    </Box>
  );
};
