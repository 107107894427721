import { EditPortfolioData_managedProduct_calculatedRecommendations } from './__generated__/query.v2';
import { EditPortfolioData, EditPortfolioDataVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyQuery } from '~/utils/symphony';

export type RecommendedProduct = EditPortfolioData_managedProduct_calculatedRecommendations;

export const useGetEditPortfolioData = (options?: QueryHookOptions<EditPortfolioData, EditPortfolioDataVariables>) => {
  return useSymphonyQuery(queries.EditPortfolioData, options);
};

export const useLazyGetEditPortfolioData = (
  options?: QueryHookOptions<EditPortfolioData, EditPortfolioDataVariables>,
) => {
  return useLazySymphonyQuery(queries.EditPortfolioData, options);
};
