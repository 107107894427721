import { useIsScreenSize } from './useIsScreenSize';

/**
 * Checks if the viewport is smaller than the `md` breakpoint defined in the theme.
 *
 * Both useIsMediumScreen and useIsSmallScreen are currently using the same breakpoint rule.
 * Please refer to https://sigfig.atlassian.net/browse/DA2-3569 for more information.
 *
 */
export const useIsMediumScreen = () => useIsScreenSize('md');
