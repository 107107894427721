import React, { ComponentProps, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { BankVerificationStatusFilter } from './BankVerificationStatusFilter';
import { useGetBankVerificationTabContent, useGetBankVerificationTabData } from './hooks';
import { getFormattedItems } from './utils';
import { VerificationStatusFilter } from './utils/types';

import { OrderType } from '~/__generated__';
import { BankVerificationModal } from '~/components/modals/BankVerification';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable, SortConfig, TableData } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Grid } from '~/components/ui/mui';
import { Snackbar, SnackbarPositionType } from '~/components/ui/Snackbar';
import { Typography } from '~/components/ui/Typography';
import { OpsDashboard } from '~/containers/OpsDashboard';
import OpsDashboardContext from '~/containers/OpsDashboard/OpsDashboardContext';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'];
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'];
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'];
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'];
}

export const BankVerificationTab: React.FC<Props> = ({
  contentOptions,
  dataQa = 'bank-verification-tab',
  onAccountClick,
  onClientClick,
  getClientNameRedirectUrl,
  getAccountNumberRedirectUrl,
}) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState(VerificationStatusFilter.ALL);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    order: OrderType.DESCENDING,
    field: 'dateCreated',
  });
  const [currentRequestId, setCurrentRequestId] = useState<string | undefined>(undefined);
  const [shouldRefetchData, setShouldRefetchData] = useState(false);
  const { currentUser, commentColumn } = useContext(OpsDashboardContext);
  const { showProductName } = useCoreConfig().components.sfOpsDashboard;

  const { open, onClose, openModal, feedbackMessage } = useModalState();

  const onSort = useCallback(
    (field: string) => () => {
      const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
      setSortConfig({ order: toggleOrder, field });
      setCurrentPage(1);
    },
    [sortConfig],
  );

  const {
    loading: bankVerificationDataLoading,
    data: bankVerificationData,
    error: bankVerificationDataError,
  } = useGetBankVerificationTabData({
    contentOptions,
    statusFilter,
    page: currentPage,
    sortConfig,
  });

  const {
    loading: bankVerificationContentLoading,
    data: bankVerificationContentData,
    error: bankVerificationContentError,
  } = useGetBankVerificationTabContent({
    contentOptions,
    onSort,
    sortConfig,
  });

  const error = bankVerificationDataError || bankVerificationContentError;
  const loading = bankVerificationContentLoading || bankVerificationDataLoading;

  const handleReviewClick = (requestId: string) => {
    setCurrentRequestId(requestId);
    openModal();
  };

  useEffect(() => {
    if (shouldRefetchData && bankVerificationData) {
      bankVerificationData.refetchData();
      setShouldRefetchData(false);
    }
  }, [shouldRefetchData, bankVerificationData]);

  useEffect(() => {
    if (feedbackMessage) {
      setShouldRefetchData(true);
    }
  }, [feedbackMessage]);

  const formattedItems: TableData[] = useMemo(() => {
    return getFormattedItems({
      commentProps: {
        commentColumn,
        contentOptions,
        currentUser,
        refetchBankVerificationData: bankVerificationData?.refetchData,
      },
      items: bankVerificationData?.bankVerificationItems ?? [],
      showProductName,
      onAccountClick,
      onClientClick,
      handleReviewClick,
      content: {
        reviewButtonLabel: bankVerificationContentData?.reviewButtonLabel ?? '',
        statuses: bankVerificationContentData?.statuses ?? [],
      },
      getClientNameRedirectUrl,
      getAccountNumberRedirectUrl,
    });
  }, [bankVerificationData, bankVerificationContentData]);

  const handleStatusFilterChange = (newStatusFilter: VerificationStatusFilter) => {
    setStatusFilter(newStatusFilter);
    setCurrentPage(1);
  };
  return (
    <>
      <Grid container data-qa={dataQa} direction="column" spacing={3}>
        {(loading || error) && (
          <Grid item>
            <AlertAndLoading
              ariaLabel="Loading Bank Verification items"
              contentOptions={contentOptions}
              error={error}
              loading={loading}
            />
          </Grid>
        )}
        {bankVerificationContentData && bankVerificationData && (
          <>
            <Grid data-qa={`${dataQa}-filter-container`} item sx={{ display: 'flex', justifyContent: 'end' }} xs={5}>
              <Typography sx={{ marginRight: 2, alignSelf: 'center' }} variant="subtitle2">
                {bankVerificationContentData.filterDropdownLabel}
              </Typography>
              <BankVerificationStatusFilter
                content={{ statusFilters: bankVerificationContentData.statusFilters }}
                currentStatus={statusFilter}
                onChange={handleStatusFilterChange}
              />
            </Grid>
            <Grid data-qa={`${dataQa}-container`} item pt={6}>
              <BasicTable
                alignItems="left"
                columns={bankVerificationContentData.tableColumns}
                currentPage={currentPage}
                data={formattedItems}
                onPageChange={setCurrentPage}
                showPagination
                sortConfig={sortConfig}
                totalPages={bankVerificationData.totalPages}
              />
            </Grid>
          </>
        )}
        {showSnackbar && (
          <Snackbar
            autoHideDuration={10000}
            feedbackMessage={bankVerificationContentData?.feedbackMessage ?? ''}
            onClose={() => setShowSnackbar(false)}
            snackbarPosition={SnackbarPositionType.BOTTOM_LEFT}
            undoButtonLabel="Undo"
          />
        )}
      </Grid>
      {currentRequestId && (
        <BankVerificationModal
          contentOptions={contentOptions}
          onClose={onClose}
          open={open}
          requestId={currentRequestId}
        />
      )}
    </>
  );
};
