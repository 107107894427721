import React, { useCallback, useState } from 'react';

import { useTradingSuspensionsData } from './hooks/useTradingSuspensionsData';

import { RemoveTradingSuspension } from '~/components/modals/RemoveTradingSuspension';
import { NullStateProps } from '~/components/NullState';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable } from '~/components/ui/BasicTable';
import { useModalState } from '~/components/ui/Modal/hooks';
import { CheckIcon, Stack } from '~/components/ui/mui';
import { Snackbar, SnackbarPositionType } from '~/components/ui/Snackbar';
import { Switch } from '~/components/ui/Switch';
import { Typography } from '~/components/ui/Typography';
import { RemoveSuspensionData } from '~/containers/OpsDashboard/types';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  nullStateConfig?: NullStateProps;
}

export const TradingSuspendedTable: React.FC<Props> = ({
  contentOptions,
  dataQa = 'trading-suspended-table',
  nullStateConfig,
}) => {
  const [suspensionData, setSuspensionData] = useState<RemoveSuspensionData>();
  const [snackbarMessage, setSnackbarMessaage] = useState<string>();
  const [showSnackBar, setShowSnackbar] = useState(false);

  const { open, openModal, onClose } = useModalState();

  const onRemoveSuspensionClick = (data: RemoveSuspensionData) => {
    setSuspensionData(data);
    openModal();
  };

  const { data, loading, error } = useTradingSuspensionsData({
    onRemoveSuspensionClick,
  });

  const onTradingSuspensionRemoveFailure = useCallback(() => {
    setShowSnackbar(false); // To close the existing opened snackbar, this will restart the time of new snackbar
    setSnackbarMessaage(data?.content.removeSuspensionFailure);
    setShowSnackbar(true);
  }, [data]);

  const onTradingSuspensionRemoveSuccess = useCallback(() => {
    data?.refetchTradingSuspendedData();
    setShowSnackbar(false); // To close the existing opened snackbar, this will restart the time of new snackbar
    setSnackbarMessaage(data?.content.removeSuspensionSuccess);
    setShowSnackbar(true);
    onClose();
  }, [data, onClose]);

  const handleCloseSnackbar = useCallback(() => {
    setShowSnackbar(false);
  }, []);

  return (
    <>
      <Stack data-qa={`${dataQa}-container`} spacing={3}>
        {data?.content && (
          <Stack flexDirection="row" sx={{ mb: 4 }}>
            <Stack alignItems="center" flexDirection="row">
              <Switch
                checked={data.isGroupAccountToggleChecked}
                color="primary"
                data-qa={`${dataQa}-group-account-switch`}
                onChange={data.handleGroupAccountToggle}
              />
              <Typography variant="body1">{data.content.groupSuspensionToggleLabel}</Typography>
            </Stack>
            <Stack alignItems="center" flexDirection="row">
              <Switch
                checked={data.isOpsOnlySuspensionToggleChecked}
                color="primary"
                data-qa={`${dataQa}-ops-only-suspension-switch`}
                onChange={data.handleOpsOnlySuspensionToggle}
              />
              <Typography variant="body1">{data.content.opsPartnerSuspensionToggleLabel}</Typography>
            </Stack>
          </Stack>
        )}

        {(!data || error) && (
          <AlertAndLoading
            ariaLabel="Loading trading suspended items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        )}

        {data?.tradingSuspendedTableContent && (
          <BasicTable
            alignItems="left"
            columns={data.tableColumns}
            currentPage={data.pageNumber}
            data={data.tradingSuspendedTableContent}
            nullStateConfig={nullStateConfig}
            onPageChange={data.onPageChange}
            showPagination
            sortConfig={data.sortConfig}
            totalPages={data.totalPages}
          />
        )}
      </Stack>

      {suspensionData && suspensionData.suspensionType && suspensionData.suspensionTag && (
        <RemoveTradingSuspension
          contentOptions={contentOptions}
          managedProductId={suspensionData.managedProductId}
          onClose={onClose}
          onTradingSuspensionRemoveFailure={onTradingSuspensionRemoveFailure}
          onTradingSuspensionRemoveSuccess={onTradingSuspensionRemoveSuccess}
          open={open}
          suspensionDisplayData={suspensionData.data}
          suspensionTag={suspensionData.suspensionTag}
          suspensionType={suspensionData.suspensionType}
        />
      )}

      <Snackbar
        action={undefined} // To override the actions defined in Snackbar
        feedbackMessage="" // To override the feedback message in Snackar
        message={
          <Stack flexDirection="row">
            <CheckIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2">{snackbarMessage}</Typography>
          </Stack>
        }
        onClose={handleCloseSnackbar}
        open={showSnackBar}
        snackbarPosition={SnackbarPositionType.BOTTOM_LEFT}
      />
    </>
  );
};
