import { GetModelChangeTableContentV2, GetModelChangeTableContentV2Variables } from './__generated__/query.v2';
import * as query from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetPendingModelChangesContent = (
  options?: QueryHookOptions<GetModelChangeTableContentV2, GetModelChangeTableContentV2Variables>,
): QueryResult<GetModelChangeTableContentV2, GetModelChangeTableContentV2Variables> => {
  return useContentstackQuery(query.GetModelChangeTableContentV2, options);
};
