import React, { ComponentProps } from 'react';

import { useModelChangeDetails } from './hooks/useModelChangeDetails';

import { PortfolioAssetDetails } from '~/components/PortfolioAssetDetails';
import { Divider } from '~/components/ui/Divider';
import { Modal } from '~/components/ui/Modal';
import { Box, Button, Card, Grid } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { allocationToDonutSlice } from '~/utils/asset-allocation';
import { AssetClassTier } from '~/utils/asset-allocation/types';
import { ContentOptions } from '~/utils/contentstack';

export interface Content {
  close: string;
  fields: { isMadlibs: boolean; key: string; label: string }[];
  header: string;
  singleOptionsList: { key: string; options: { key: string; value: string }[] }[];
}
export type Props = ComponentProps<typeof Modal> & {
  accountNumber: string;
  assetClassTier?: AssetClassTier;
  clientName: string;
  content: Content;
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  partyId: string;
  program: string;
};

export const ModelChangeDetails: React.FC<Props> = ({
  accountNumber,
  assetClassTier,
  clientName,
  content,
  contentOptions,
  dataQa,
  managedProductId,
  open,
  partyId,
  program,
  ...modalProps
}) => {
  const { data, loading, error } = useModelChangeDetails({
    assetClassTier,
    content,
    contentOptions,
    clientName,
    managedProductId,
    partyId,
  });

  const {
    currentAssetAllocationData,
    currentModelPortfolioData,
    currentRiskBand,
    newAssetAllocationData,
    newModelPortfolioData,
    newRiskBand,
    portfolioAssetDetailsContent,
    recommendationRationaleField,
  } = { ...data };
  const donutProps = { size: 90, innerRadius: 18, hideInnerText: true };
  return (
    <Modal
      actions={
        <Button data-qa={`${dataQa}-close-cta`} onClick={modalProps.onClose as () => void} variant="outlined">
          {content.close}
        </Button>
      }
      content={
        <>
          <Grid container justifyContent="space-between" mb={4}>
            <Grid item pr={2} xs={6}>
              <Card
                sx={{
                  boxSizing: 'border-box',
                  height: '100%',
                  pt: 3,
                  pb: 1,
                }}
                variant="outlined"
              >
                {currentModelPortfolioData && portfolioAssetDetailsContent && currentAssetAllocationData && (
                  <PortfolioAssetDetails
                    allocations={currentModelPortfolioData.modelPortfolios}
                    content={portfolioAssetDetailsContent}
                    donutProps={donutProps}
                    isCopilot={false}
                    minimumInvestmentAmount={currentModelPortfolioData.minimumBalanceForAccountOpening}
                    modelPortfolioType="current"
                    portfolioAssetsBreakdown={currentAssetAllocationData.map(allocationToDonutSlice(assetClassTier))}
                    portfolioRiskLabel={currentRiskBand?.label ?? ''}
                    showAssetAllocation={false}
                    stockBondRatio={currentModelPortfolioData.stockBondDiversification}
                  />
                )}
              </Card>
            </Grid>
            <Grid item pl={2} xs={6}>
              <Card
                // Take note of pb styling here, lesser by 1 to factor in the 8px borderTop
                sx={{
                  boxSizing: 'border-box',
                  height: '100%',
                  pt: 2,
                  pb: 1,
                  borderTop: ({ palette }) => `8px solid ${palette.primary.main}`,
                }}
                variant="outlined"
              >
                {newModelPortfolioData && portfolioAssetDetailsContent && newAssetAllocationData && (
                  <PortfolioAssetDetails
                    allocations={newModelPortfolioData.modelPortfolios}
                    content={portfolioAssetDetailsContent}
                    donutProps={donutProps}
                    isCopilot={false}
                    minimumInvestmentAmount={newModelPortfolioData.minimumBalanceForAccountOpening}
                    modelPortfolioType="new"
                    portfolioAssetsBreakdown={newAssetAllocationData.map(allocationToDonutSlice(assetClassTier))}
                    portfolioRiskLabel={newRiskBand?.label ?? ''}
                    showAssetAllocation={false}
                    stockBondRatio={newModelPortfolioData.stockBondDiversification}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
          {recommendationRationaleField?.value && (
            <Box>
              <Typography mb={2} variant="subtitle2">
                {recommendationRationaleField.label}
              </Typography>
              <Typography variant="body2">{recommendationRationaleField.value}</Typography>
            </Box>
          )}

          <Divider sx={{ my: 4 }} />
          <RteContent config={{ accountNumber, program }} data={data?.detailsLabel ?? ''} mb={2} />
          <Grid container>
            {data?.fields
              ?.filter(field => field.value)
              ?.map(el => (
                <Grid item key={el.key} mb={2} xs={4}>
                  <RteContent data={el.label} />
                  <Typography>{el.value}</Typography>
                </Grid>
              ))}
          </Grid>
        </>
      }
      contentOptions={contentOptions}
      data-qa={dataQa}
      error={error}
      loading={loading}
      open={open}
      title={content.header}
      {...modalProps}
    />
  );
};
