import {
  GetChangeInvestmentMethodModalContent,
  GetChangeInvestmentMethodModalContentVariables,
} from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetChangeInvestmentMethodModalContent = (
  options?: QueryHookOptions<GetChangeInvestmentMethodModalContent, GetChangeInvestmentMethodModalContentVariables>,
): QueryResult<GetChangeInvestmentMethodModalContent, GetChangeInvestmentMethodModalContentVariables> => {
  return useContentstackQuery(queries.GetChangeInvestmentMethodModalContent, options);
};
