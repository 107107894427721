import React, { ComponentProps } from 'react';

import { OtherActionsModalContent, useGetOtherActionsModalContent } from './contentstack';

import { Modal } from '~/components/ui/Modal';
import { Button } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { ContentOptions } from '~/utils/contentstack';

export type Props = ComponentProps<typeof Modal> & {
  contentOptions: ContentOptions;
  dataQa?: string;
};

export const OtherActionsModal: React.FC<Props> = ({
  dataQa = 'other-actions-modal',
  contentOptions,
  open,
  maxWidth = 'sm',
  ...modalProps
}) => {
  const { data: contentstackData } = useGetOtherActionsModalContent({
    variables: contentOptions,
    skip: !open,
  });
  const content: OtherActionsModalContent | undefined =
    contentstackData?.all_other_actions_modal?.items?.[0] ?? undefined;

  return (
    <Modal
      actions={
        <Button onClick={modalProps.onClose as () => void} variant="contained">
          {content?.modal?.primary_cta}
        </Button>
      }
      content={<RteContent data={content?.modal_content || ''} />}
      contentOptions={contentOptions}
      data-qa={dataQa}
      maxWidth={maxWidth}
      open={open}
      title={content?.modal?.title}
      {...modalProps}
    />
  );
};
