import React, { ComponentProps } from 'react';

import { Comment, CommentComponentColumn } from '../../common/Comment';
import { AccountNumber } from '../../common/ui/AccountNumber';
import { ClientName } from '../../common/ui/ClientName';
import { GetOpsDashboardContentV2 } from '../../contentstack/__generated__/query.v2';
import { RemoveSuspensionData, TradingSuspensionsContent, TradingSuspensionsTableContent } from '../../types';
import {
  getAccountNumberOrAccountTypeString,
  getLastEditDateAndTime,
  isAccountNumberClickable,
  OpsDashboardUser,
} from '../../utils';
import { GetTradingSuspensionsContentV2 } from '../contentstack/__generated__/query.v2';
import { TradingSuspensions } from '../symphony';

import {
  FinancialAccountType,
  ManagedProductStatus,
  MultiRestrictionPlacedBy,
  SuspensionType,
  UserNoteEntityType,
} from '~/__generated__';
import { Filters } from '~/components/Filters';
import { FilterCountMap, FilterTypes } from '~/components/Filters/types';
import { getSelectedOptions } from '~/components/Filters/utils';
import { CopyField } from '~/components/ui/CopyField';
import { Button, Stack } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { getAccountTypeText } from '~/containers/AccountSummary/utils';
import { OpsDashboard } from '~/containers/OpsDashboard';
import { ManagedProductStatusesForSuspensions } from '~/containers/OpsDashboardV2/TradingSuspendedTable/hooks/useTradingSuspensionsData';
import { AccountDetailsTabsEnum } from '~/hooks/account-details/types';
import { getFullName } from '~/hooks/party/utils';
import { getAccountProgramText, getAccountState, isPartnerOpsSuspension } from '~/utils/account';
import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { formatDate } from '~/utils/format/date';

export interface TradingSuspensionsData extends TradingSuspensions {
  groupingIndex?: number;
}

export enum TradingSuspensionFilters {
  STATUS = 'STATUS',
  TAG = 'TAG',
  TYPE = 'TYPE',
}

export enum TradingSuspensionTypeFilter {
  ALL = 'ALL',
  PARTNER = 'PARTNER',
  TRADER = 'TRADER',
}

export enum TradingSuspensionTagFilter {
  ALL = 'ALL',
  BORD = 'BORD',
  CUSTODIAL = 'CUSTODIAL',
  OPS = 'OPS',
  OTHER = 'OTHER',
}

interface FilterContext {
  count: number;
  filtersKey: {
    managedProductStatus: ManagedProductStatus | null;
    suspensionTag: string | null;
    suspensionType: SuspensionType | null;
  };
}

const TradingSuspensionFilterConfig = [
  {
    key: TradingSuspensionFilters.TYPE,
    options: [
      {
        id: TradingSuspensionTypeFilter.TRADER,
      },
      {
        id: TradingSuspensionTypeFilter.PARTNER,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
  {
    key: TradingSuspensionFilters.TAG,
    options: [
      {
        id: TradingSuspensionTagFilter.OPS,
      },
      {
        id: TradingSuspensionTagFilter.CUSTODIAL,
      },
      {
        id: TradingSuspensionTagFilter.OTHER,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
  {
    key: TradingSuspensionFilters.STATUS,
    options: [
      {
        id: ManagedProductStatus.ACTIVE,
      },
      {
        id: ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
      },
      {
        id: ManagedProductStatus.NEW,
      },
      {
        id: ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
      },
      {
        id: ManagedProductStatus.PENDING_CLOSED,
      },
      {
        id: ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
      },
    ],
    type: FilterTypes.CHECKBOX_GROUP,
  },
];

export const defaultFilters: ComponentProps<typeof Filters>['defaultAppliedFilters'] = {
  [TradingSuspensionFilters.TAG]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      TradingSuspensionTagFilter.OPS,
      TradingSuspensionTagFilter.CUSTODIAL,
      TradingSuspensionTagFilter.OTHER,
    ],
  },
  [TradingSuspensionFilters.TYPE]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [TradingSuspensionTypeFilter.PARTNER, TradingSuspensionTypeFilter.TRADER],
  },
  [TradingSuspensionFilters.STATUS]: {
    filterType: FilterTypes.CHECKBOX_GROUP,
    selectedOptions: [
      ManagedProductStatus.ACTIVE,
      ManagedProductStatus.LEGAL_DOCUMENTS_PREPARED,
      ManagedProductStatus.NEW,
      ManagedProductStatus.LEGAL_DOCUMENTS_SIGNED,
      ManagedProductStatus.PENDING_CLOSED,
      ManagedProductStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
    ],
  },
};

export const getTradingSuspensionContent = (
  content: GetTradingSuspensionsContentV2 | undefined,
): TradingSuspensionsContent => {
  const item = content?.all_trading_suspensions_table?.items?.[0];
  const textFields = item?.fields?.text ?? [];
  return {
    columns: item?.columns?.column,
    emptySuspensionTagLabel: findFieldValue(textFields, 'empty_suspension_tag_label'),
    groupSuspensionToggleLabel: findFieldValue(textFields, 'group_suspension_toggle_label'),
    opsPartnerSuspensionToggleLabel: findFieldValue(textFields, 'ops_partner_suspension_toggle_label'),
    removeSuspensionCta: findFieldValue(textFields, 'remove_suspension_cta'),
    removeSuspensionFailure: findFieldValue(textFields, 'remove_suspension_failure'),
    removeSuspensionSuccess: findFieldValue(textFields, 'remove_suspension_success'),
    filterConfig: TradingSuspensionFilterConfig.map(filter => {
      return {
        ...filter,
        label: findFieldValue(textFields, `${filter.key.toLowerCase()}_filter`),
        options: filter.options.map(option => {
          return {
            ...option,
            label: findFieldValue(textFields, `${option.id.toLowerCase()}`),
          };
        }),
      };
    }),
    appliedFiltersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      appliedFilters: findFieldValue(textFields, 'applied_filters'),
    },
    filtersContent: {
      allFilter: findFieldValue(textFields, 'all_filter'),
      apply: findFieldValue(textFields, 'apply_cta'),
      filters: findFieldValue(textFields, 'filters_cta'),
      resetAll: findFieldValue(textFields, 'reset_all_cta'),
    },
  };
};

export const getTableContent = (
  commentProps: {
    commentColumn: CommentComponentColumn | undefined;
    contentOptions: ContentOptions;
    currentUser: OpsDashboardUser;
    refetchTradingSuspendedData: () => void;
  },
  content: TradingSuspensionsContent,
  onClientClick: ComponentProps<typeof OpsDashboard>['onClientClick'],
  onAccountClick: ComponentProps<typeof OpsDashboard>['onAccountClick'],
  getAccountNumberRedirectUrl: ComponentProps<typeof OpsDashboard>['getAccountNumberRedirectUrl'],
  getClientNameRedirectUrl: ComponentProps<typeof OpsDashboard>['getClientNameRedirectUrl'],
  onRemoveSuspensionClick: (suspensionData: RemoveSuspensionData) => void,
  showProductName: boolean,
  opsDashboardContentData?: GetOpsDashboardContentV2,
  tradingSuspensions?: TradingSuspensionsData[],
  activeRowId?: string | undefined,
): TradingSuspensionsTableContent[] => {
  if (!tradingSuspensions) {
    return [];
  }

  return tradingSuspensions.map(tradingSuspension => {
    const entityNotes = tradingSuspension.entityNotes.length ? tradingSuspension.entityNotes[0] : null;
    const partyPerson = tradingSuspension.createdByParty?.partyPerson;
    const programNames = opsDashboardContentData?.all_product_name?.items || [];
    const accountTypeContent = opsDashboardContentData?.all_account_type?.items || [];
    const opsDashboardContent = opsDashboardContentData?.all_ops_dashboard?.items?.[0];
    const copyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_ulid_label');
    const successCopyLabel = findFieldValue(opsDashboardContent?.fields?.text, 'copy_success_label');

    const shouldShowAccountClick = tradingSuspension.managedProduct
      ? isAccountNumberClickable(
          // only used for activated account states
          getAccountState({
            firstRebalancedOn: tradingSuspension.managedProduct.firstRebalancedOn ?? undefined,
            financialAccountStatus: tradingSuspension.managedProduct.status,
            suspendedOn: tradingSuspension.createdAt,
          }).state,
        )
      : false;

    const suspensionDisplayContent = {
      restrictionPlacedByUser: getFullName(partyPerson) || 'Operations',
      restrictionPlacedBy: MultiRestrictionPlacedBy.PARTNER_OPS,
      created: tradingSuspension.createdAt ? formatDate(tradingSuspension.createdAt) : '-',
    };

    const suspension: RemoveSuspensionData = {
      data: suspensionDisplayContent,
      managedProductId: tradingSuspension.managedProduct.id,
      suspensionType: tradingSuspension.suspensionType,
      suspensionTag: tradingSuspension.suspensionTag
        ? `${tradingSuspension.suspensionTag}`
        : content.emptySuspensionTagLabel,
    };

    // zero index (or first row) for grouped suspension and undefined index (or every row) for non-grouped suspension
    const showAccountAndClientData =
      tradingSuspension.groupingIndex === undefined || tradingSuspension.groupingIndex === 0;

    return {
      rowKey: tradingSuspension.id,
      accountNumber: showAccountAndClientData ? (
        <AccountNumber
          accountNumber={tradingSuspension.managedProduct.financialAccountNumber}
          accountNumberText={getAccountNumberOrAccountTypeString(
            tradingSuspension.managedProduct.financialAccountNumber,
            getAccountTypeText(
              tradingSuspension.managedProduct.accountType || FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE,
              accountTypeContent,
            ),
          )}
          label={
            showProductName
              ? getAccountProgramText(
                  tradingSuspension.managedProduct.program,
                  tradingSuspension.managedProduct.attributes,
                  programNames,
                )
              : null
          }
          onClick={
            shouldShowAccountClick &&
            tradingSuspension.managedProduct.clientParty?.id &&
            tradingSuspension.managedProduct.id
              ? () =>
                  onAccountClick(
                    tradingSuspension.managedProduct.clientParty?.id ?? '',
                    tradingSuspension.managedProduct.id ?? '',
                    AccountDetailsTabsEnum.suspensions,
                  )
              : undefined
          }
          redirectUrl={
            tradingSuspension.managedProduct.clientParty?.id &&
            tradingSuspension.managedProduct.id &&
            shouldShowAccountClick
              ? getAccountNumberRedirectUrl(
                  tradingSuspension.managedProduct.clientParty.id,
                  tradingSuspension.managedProduct.id,
                )
              : undefined
          }
        />
      ) : (
        <></>
      ),
      clientName: showAccountAndClientData ? (
        <ClientName
          clientName={getFullName(tradingSuspension.managedProduct.clientParty?.partyPerson)}
          onClick={() => onClientClick(tradingSuspension.managedProduct.clientParty?.id ?? '')}
          redirectUrl={
            tradingSuspension.managedProduct.clientParty?.id
              ? getClientNameRedirectUrl(tradingSuspension.managedProduct.clientParty.id)
              : undefined
          }
        />
      ) : (
        <></>
      ),
      created: tradingSuspension.createdAt ? formatDate(tradingSuspension.createdAt) : '-',
      suspendedBy: getFullName(partyPerson) || 'Operations',
      suspensionType: `${tradingSuspension.suspensionType}`,
      suspensionTag: (
        <Stack direction="row">
          <Typography>
            {tradingSuspension.suspensionTag ? `${tradingSuspension.suspensionTag}` : content.emptySuspensionTagLabel}
          </Typography>
          <Stack justifyContent="center" sx={{ width: '30px', px: 1, fontSize: 16 }}>
            {activeRowId === tradingSuspension.id && (
              <CopyField
                copyLabel={copyLabel}
                defaultState
                disableToggling
                successLabel={successCopyLabel}
                textToCopy={tradingSuspension.id}
              />
            )}
          </Stack>
        </Stack>
      ),
      removeSuspensionCta: isPartnerOpsSuspension(tradingSuspension) ? (
        <Button onClick={() => onRemoveSuspensionClick(suspension)} size="small" variant="outlined">
          {content.removeSuspensionCta}
        </Button>
      ) : (
        <></>
      ),
      comment: (
        <Comment
          comment={
            entityNotes
              ? {
                  lastValue: entityNotes.note,
                  ...getLastEditDateAndTime(new Date(entityNotes.created), commentProps.contentOptions),
                }
              : undefined
          }
          content={commentProps.commentColumn}
          contentOptions={commentProps.contentOptions}
          currentUser={commentProps.currentUser}
          entity={UserNoteEntityType.TRADING_SUSPENSION}
          entityId={tradingSuspension.id}
          key={tradingSuspension.id}
          lastCommentPartyId={entityNotes?.createdByPartyId}
          refetchData={commentProps.refetchTradingSuspendedData}
        />
      ),
    };
  });
};

export const getOffset = (pageNumber: number, recordsPerPage: number) => (pageNumber - 1) * recordsPerPage;

export const geSelectedFilterTypes = (appliedFilters: ComponentProps<typeof Filters>['appliedFilters']) => {
  const typeFilters = getSelectedOptions(appliedFilters[TradingSuspensionFilters.TYPE]) || [];
  return typeFilters.length ? typeFilters : [TradingSuspensionTypeFilter.TRADER, TradingSuspensionTypeFilter.PARTNER];
};

const statusFilterToTradingSuspensionTagFilter = (filter: string) => {
  switch (filter) {
    case TradingSuspensionTagFilter.OPS:
      return [TradingSuspensionTagFilter.OPS];
    case TradingSuspensionTagFilter.CUSTODIAL:
      return [TradingSuspensionTagFilter.CUSTODIAL];
    case TradingSuspensionTagFilter.OTHER:
      return [TradingSuspensionTagFilter.BORD, null];
    default:
      return [];
  }
};

const toSuspensionTag = (filter: string | null) => {
  const value = filter ? filter.toLowerCase() : null;
  switch (value) {
    case 'ops':
      return TradingSuspensionTagFilter.OPS;
    case 'custodial':
      return TradingSuspensionTagFilter.CUSTODIAL;
    case 'bord':
    case null:
      return TradingSuspensionTagFilter.OTHER;
    default:
      return '';
  }
};

export const geSelectedFilterTags = (appliedFilters: ComponentProps<typeof Filters>['appliedFilters']) => {
  const tagFilters = getSelectedOptions(appliedFilters[TradingSuspensionFilters.TAG]) || [];
  if (tagFilters.length) {
    return tagFilters.map(v => statusFilterToTradingSuspensionTagFilter(v)).flat();
  }
  return [TradingSuspensionTagFilter.OPS, TradingSuspensionTagFilter.CUSTODIAL, TradingSuspensionTagFilter.BORD, null];
};

export const getFilterCountMap = (filterContext: FilterContext[]): FilterCountMap[] => {
  return filterContext
    .filter(
      v =>
        !!v.filtersKey.suspensionType &&
        !!v.filtersKey.managedProductStatus &&
        ManagedProductStatusesForSuspensions.includes(v.filtersKey.managedProductStatus),
    )
    .map(v => {
      return {
        count: v.count,
        filters: {
          [TradingSuspensionFilters.TYPE]: `${v.filtersKey.suspensionType}`,
          [TradingSuspensionFilters.TAG]: toSuspensionTag(v.filtersKey.suspensionTag),
          [TradingSuspensionFilters.STATUS]: `${v.filtersKey.managedProductStatus}`,
        },
      };
    });
};

export const geSelectedFilterManagedProductStatus = (appliedFilters: string[]): ManagedProductStatus[] => {
  return appliedFilters as ManagedProductStatus[];
};
