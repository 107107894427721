import {
  GetAccountActionsContent,
  GetAccountActionsContentVariables,
} from './__generated__/getAccountActionsContent.v2';
import * as queries from './getAccountActionsContent.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetAccountActionsContent = (
  options?: QueryHookOptions<GetAccountActionsContent, GetAccountActionsContentVariables>,
): QueryResult<GetAccountActionsContent, GetAccountActionsContentVariables> =>
  useContentstackQuery(queries.GetAccountActionsContent, options);
