import { startOfDay, subDays } from 'date-fns';
import { useContext, useEffect, useState } from 'react';

import OpsDashboardContext from '../../OpsDashboardContext';
import { RequestTabs } from '../../types';
import { getLastEditDateAndTime } from '../../utils';
import { useGetOpsDashboardFundingTableData } from '../symphony';
import { GetFundingReviewsV2Variables } from '../symphony/__generated__/query.v2';
import { FundingStatusFilterTypes, FundingTabSymphonyData, TransferMethods } from '../types';

import {
  FinancialAccountType,
  FundingReviewSortField,
  FundingReviewStatus,
  FundingSourceType,
  ManagedProductType,
  ScheduledTransferStatus,
  TransferMethodSubType,
  TransferType,
} from '~/__generated__';
import { SortConfig } from '~/components/ui/BasicTable';
import { ManagedProductAttributes } from '~/hooks/account-details/symphony';
import { toSymphonyDateTime } from '~/utils/symphony';
import { AsyncResult } from '~/utils/types';

export const sortFieldToSymphony = new Map([['inception', FundingReviewSortField.ACTIVATED_AT]]);
export const recordsPerPage = 10;

export interface GetFundingDataVariables {
  activeRequestTab: RequestTabs;
  contentDataLoading: boolean | null;
  inception: number | null;
  pageNumber: number;
  partitionedByFlagStatus: boolean | null;
  sortConfig: SortConfig;
  statusFilter: FundingStatusFilterTypes;
}

export const useGetFundingTabData = ({
  partitionedByFlagStatus,
  activeRequestTab,
  statusFilter,
  pageNumber,
  inception,
  contentDataLoading,
  sortConfig,
}: GetFundingDataVariables): AsyncResult<FundingTabSymphonyData> => {
  const [state, setState] = useState<AsyncResult<FundingTabSymphonyData>>({ loading: true });

  const activatedDateFrom = toSymphonyDateTime(startOfDay(subDays(new Date(), inception ?? 0)));
  const variables: GetFundingReviewsV2Variables = {
    statuses:
      statusFilter === FundingStatusFilterTypes.ALL
        ? [FundingReviewStatus.BELOW_MINIMUM, FundingReviewStatus.FUNDED, FundingReviewStatus.NEEDS_REVIEW]
        : [statusFilter as FundingReviewStatus],
    fetchLatestNote: true,
    hasOpenFlags: partitionedByFlagStatus ? activeRequestTab === RequestTabs.FlaggedRequests : null,
    pagination: {
      limit: recordsPerPage,
      offset: (pageNumber - 1) * recordsPerPage,
    },
    activatedDateFrom,
    sort: {
      field: sortFieldToSymphony.get(sortConfig.field) || FundingReviewSortField.ACTIVATED_AT,
      order: sortConfig.order,
    },
  };
  const { contentOptions } = useContext(OpsDashboardContext);
  const { data, loading, error, refetch } = useGetOpsDashboardFundingTableData({
    variables,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    skip: contentDataLoading || false,
  });

  useEffect(() => {
    if (error) {
      setState({
        error,
        loading: false,
      });
    }
  }, [error]);

  useEffect(() => {
    setState(prevData => ({
      ...prevData,
      loading,
    }));
    if (!loading && data) {
      setState({
        data: {
          pagination: data.managedProductFundingReviews.paginationContext as FundingTabSymphonyData['pagination'],
          fundingReviews: data.managedProductFundingReviews.fundingReviews.map(fundingReview => {
            const { managedProduct, entityNotes } = fundingReview;
            const initialClientParty = managedProduct?.relatedPartiesV2?.filter(party => party.isInitialParty)[0];
            const transfers = !managedProduct?.createWorkflow?.fundLater
              ? managedProduct?.fundingDetails.scheduledTransfers
                  .filter(
                    transfer =>
                      transfer.status !== ScheduledTransferStatus.CANCELLED && transfer.type === TransferType.DEPOSIT,
                  )
                  ?.map(filteredTransfer => {
                    switch (filteredTransfer.__typename) {
                      case 'AssetTransfer':
                        const isJournalTransfer = filteredTransfer.methodSubType === TransferMethodSubType.JOURNAL;
                        return {
                          transferSource: filteredTransfer.sourceFinancialAccountAccountNumber as string,
                          transferMethod: isJournalTransfer ? TransferMethods.JOURNAL : TransferMethods.ACAT,
                        };
                      case 'CashTransfer':
                        return {
                          transferSource: filteredTransfer.bankAccount?.accountNumber as string,
                          transferMethod: TransferMethods.ACH,
                        };
                      default:
                        return {
                          transferSource: '',
                          transferMethod: TransferMethods.UNKNOWN,
                        };
                    }
                  })
              : null;

            return {
              financialAccountNumber: managedProduct?.financialAccountNumber ?? '',
              maskedFinancialAccountNumber: managedProduct?.maskedFinancialAccountNumber ?? '',
              accountType: managedProduct?.accountType as FinancialAccountType,
              attributes: managedProduct?.attributes as ManagedProductAttributes[],
              program: managedProduct?.program as ManagedProductType,
              managedProductId: managedProduct?.id ?? '',
              initialClientParty: {
                displayName: initialClientParty?.party?.partyPerson?.displayName ?? '',
                givenName: initialClientParty?.party?.partyPerson?.givenName ?? '',
                familyName: initialClientParty?.party?.partyPerson?.familyName ?? '',
                partyId: initialClientParty?.partyId ?? '',
              },
              fundingDetails: {
                transfers: transfers ?? null,
                fundLater: managedProduct?.createWorkflow?.fundLater ?? false,
                fundLaterAmount: managedProduct?.createWorkflow?.fundLaterAmount ?? null,
                fundingSources: managedProduct?.createWorkflow?.fundingSources ?? [FundingSourceType.UNKNOWN],
                fundingValue: managedProduct?.createWorkflow?.fundingValue ?? '0',
                fundingAmount: fundingReview.fundedAmount ?? '0',
                fundingReviewStatus: fundingReview.status,
              },
              flags: fundingReview.flags.map(flag => ({
                status: flag.status,
                reason: flag.reason,
                description: flag.description ?? '',
                entityIdentifier: flag.entityIdentifier,
                created: flag.lastUpdated,
                id: flag.id,
                lastUpdated: flag.lastUpdated,
              })),
              entityNotes: entityNotes.length
                ? {
                    lastValue: entityNotes[0].note,
                    ...getLastEditDateAndTime(new Date(entityNotes[0].created), contentOptions),
                  }
                : undefined,
              lastCommentPartyId: entityNotes.length ? entityNotes[0].createdByPartyId : undefined,
              createdAt: fundingReview.createdAt ?? '',
              id: fundingReview.id,
            };
          }),
          refetchFundingReviewsData: refetch,
        },
        loading: false,
      });
    }
  }, [loading, data, contentOptions, refetch]);

  return state;
};
