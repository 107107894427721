import { GetOthersTabContent, GetOthersTabContentVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export type OthersTabContent = GetOthersTabContent;

export const useGetOthersTabContent = (
  options?: QueryHookOptions<GetOthersTabContent, GetOthersTabContentVariables>,
): QueryResult<GetOthersTabContent, GetOthersTabContentVariables> => {
  return useContentstackQuery(queries.GetOthersTabContent, options);
};
