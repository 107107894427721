import { ComponentProps, useMemo, useState } from 'react';

import { AccountTotalSection } from '.';

import { useCoreConfig } from '~/utils/config';

type Options = Pick<ComponentProps<typeof AccountTotalSection>, 'hidePerformanceView' | 'rebalancedAccounts'>;

type View = 'performance' | 'goals';
type Data = {
  isNullState: boolean;
  onSwitchToGoalsView?: () => void;
  onSwitchToPerformanceView?: () => void;
  skipBalanceOverTimeQuery: boolean;
  view: View;
};

export const useAccountTotalSection = ({ hidePerformanceView, rebalancedAccounts }: Options): Data | undefined => {
  const {
    featureFlags: {
      goals: { enableGoalsFeature },
    },
  } = useCoreConfig();

  const isNullState = !rebalancedAccounts.length;
  const [view, setView] = useState<View>(enableGoalsFeature ? 'goals' : 'performance');

  return useMemo(() => {
    if (isNullState && !enableGoalsFeature) {
      // In a null state, with goals disabled, there's nothing to render.
      return undefined;
    }

    const onSwitchToGoalsView = enableGoalsFeature ? () => setView('goals') : undefined;
    if (isNullState) {
      return {
        isNullState,
        onSwitchToGoalsView,
        skipBalanceOverTimeQuery: true,
        view,
      };
    }
    return {
      isNullState,
      onSwitchToGoalsView,
      onSwitchToPerformanceView: () => setView('performance'),
      skipBalanceOverTimeQuery: !!hidePerformanceView || view !== 'performance',
      view,
    };
  }, [enableGoalsFeature, hidePerformanceView, isNullState, view]);
};
