import { CashTransfersFlag } from './common/CashTransfer/symphony';
import { CommentData } from './common/Comment';

import { SuspensionType, TransferFrequency } from '~/__generated__/symphonyTypes.v2';
import { AccountState } from '~/utils/account';
import { ContentstackTableColumn } from '~/utils/table';

export enum TransferStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum TransferStatusFilter {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  PENDING_IN_PROGRESS = 'PENDING_IN_PROGRESS',
}

export type GrossUpValues = {
  no: string;
  yes: string;
};

export type DistributionReasonValues = {
  normal: string;
  premature: string;
};

export type TaxInformation = {
  distributionReason?: string;
  federalTax?: string;
  grossUp?: boolean;
  stateTax?: string;
};

export type TaxWithholdingInfoContent = {
  distributionReason: string;
  distributionReasonValues: DistributionReasonValues;
  federalTax: string;
  grossUp: string;
  grossUpValues: GrossUpValues;
  stateTax: string;
};

export type TransferItem = {
  accountNumber: string;
  accountState: AccountState;
  accountTypeText: string;
  amount: number;
  clientName: string;
  createdAt: string;
  destinationBankAccount: string;
  flags: CashTransfersFlag[];
  frequency: TransferFrequency;
  id: string;
  isManagedProductTaxSheltered?: boolean;
  lastCommentData?: Omit<CommentData, 'lastEditUserName'>;
  lastCommentPartyId?: string;
  managedProductId: string;
  notes?: string;
  numberOfOccurrences?: number;
  partyId: string;
  productName: string;
  rmdPlanId?: number;
  scheduledDate?: string;
  settlementDate?: string;
  status: TransferStatus;
  taxInformation?: TaxInformation;
};

export interface TransferStatusUpdateItem {
  item: TransferItem;
  newStatus: TransferStatus;
}

export enum RequestTabs {
  AllOtherRequests = 'AllOtherRequests',
  FlaggedRequests = 'FlaggedRequests',
}
export type TradingSuspensionsTableContent = {
  accountNumber: JSX.Element;
  clientName: JSX.Element;
  comment: JSX.Element;
  created: string;
  removeSuspensionCta: JSX.Element;
  rowKey: string;
  suspendedBy: string;
  suspensionTag: JSX.Element;
  suspensionType: string;
};

export type TradingSuspensionsContent = {
  columns: (ContentstackTableColumn | null)[] | undefined | null;
  emptySuspensionTagLabel: string;
  groupSuspensionToggleLabel: string;
  opsPartnerSuspensionToggleLabel: string;
  removeSuspensionCta: string;
  removeSuspensionFailure: string;
  removeSuspensionSuccess: string;
};

export interface SuspensionDisplayData {
  created?: string;
  restrictionPlacedBy?: string;
  restrictionPlacedByUser?: string;
}

export interface RemoveSuspensionData {
  data: SuspensionDisplayData;
  managedProductId: string;
  suspensionTag: string;
  suspensionType: SuspensionType;
}

export enum SidebarState {
  COLLAPSED = 'COLLAPSED',
  OPEN = 'OPEN',
}
