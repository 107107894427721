import { useMemo } from 'react';

import { TabContent } from '../../../types';
import { useGetStatementsTabContent } from '../contentstack';

import { getStatementsTabContent } from './utils';

import { ContentOptions } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

export const useStatementsTabContent = ({ contentOptions }: Variables): AsyncResult<TabContent> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useGetStatementsTabContent({
    variables: contentOptions,
  });

  return useMemo(
    () => ({
      error: contentError,
      data: getStatementsTabContent(contentData),
      loading: contentLoading,
    }),
    [contentData, contentError, contentLoading],
  );
};
