import React, { ComponentProps } from 'react';

import { Modal } from '~/components/ui/Modal';
import { Button, LoadingButton, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';

export type ConfirmationModalProps = ComponentProps<typeof Modal> & {
  content: {
    message: string;
    primaryCta: string;
    secondaryCta: string;
    subMessage?: string;
    title: string;
  };
  icon?: React.ReactElement;
  onClose: () => void;
  onPrimaryClick: () => void;
  open: boolean;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  content,
  icon,
  onClose,
  onPrimaryClick,
  open,
  ...rest
}) => {
  return (
    <Modal
      actions={
        <>
          <Button onClick={onClose} variant="outlined">
            {content.secondaryCta}
          </Button>
          <LoadingButton onClick={onPrimaryClick} variant="contained">
            {content.primaryCta}
          </LoadingButton>
        </>
      }
      content={
        <Stack justifyContent="space-between" spacing={3} sx={{ my: 2 }}>
          <Stack>
            {icon}
            <RteContent data={content.message} />
          </Stack>
          {content.subMessage && <RteContent data={content.subMessage} defaultBodyVariant="body2" />}
        </Stack>
      }
      onClose={onClose}
      open={open}
      title={content.title}
      {...rest}
    />
  );
};
