import {
  AddFinancialAccount,
  AddFinancialAccountVariables,
  UpdateFinancialAccount,
  UpdateFinancialAccountVariables,
} from './__generated__/mutation.v2';
import * as mutation from './mutation.gql';

import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyMutation } from '~/utils/symphony';

export const useAddFinancialAccount = (
  options?: MutationHookOptions<AddFinancialAccount, AddFinancialAccountVariables>,
): MutationTuple<AddFinancialAccount, AddFinancialAccountVariables> => {
  return useSymphonyMutation(mutation.AddFinancialAccount, options);
};

export const useUpdateFinancialAccount = (
  options?: MutationHookOptions<UpdateFinancialAccount, UpdateFinancialAccountVariables>,
): MutationTuple<UpdateFinancialAccount, UpdateFinancialAccountVariables> => {
  return useSymphonyMutation(mutation.UpdateFinancialAccount, options);
};
