import { startOfDay, subDays } from 'date-fns';
import { useEffect } from 'react';

import { OpsDashboardTabs } from '..';
import { ManagedProductStatusesForCashTransfers } from '../common/CashTransfer/utils';
import {
  useTotalACATRequests,
  useTotalCashTransfers,
  useTotalClosures,
  useTotalDocusign,
  useTotalFailures,
  useTotalFinancialAccountAssociationVerificationRequests,
  useTotalFundingReviews,
  useTotalPendingModelChanges,
  useTotalRecurringCancellationsCashTransfers,
  useTotalSuspensions,
} from '../symphony';

import { CloseAccountStatus, ManagedProductStatus, PlanUpdateWorkflowStatus, TransferType } from '~/__generated__';
import { ApolloError } from '~/utils/apollo-client';
import { toSymphonyDateTime } from '~/utils/symphony';

const useLogError = (error?: ApolloError) => {
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);
};

const getTotalValues = (totalValue?: number | null): number | null =>
  totalValue || totalValue === 0 ? totalValue : null;

export const useTotals: (
  inception: number,
  enabledTabs: OpsDashboardTabs[],
) => {
  totalACATRequests: number | null;
  totalBankVerificationRequests: number | null;
  totalClosures: number | null;
  totalClosuresCountRefetch: () => void;
  totalDeposits: number | null;
  totalDepositsCountRefetch: () => void;
  totalDocusign: number | null;
  totalFailureRefetch: () => void;
  totalFailures: number | null;
  totalFundingCountRefetch: () => void;
  totalFundingReviews: number | null;
  totalPendingModelChangeRefetch: () => void;
  totalPendingModelChanges: number | null;
  totalRecurringCancellations: number | null;
  totalRecurringCancellationsRefetch: () => void;
  totalSuspensions: number | null;
  totalSuspensionsCountRefetch: () => void;
  totalWithdrawals: number | null;
  totalWithdrawalsCountRefetch: () => void;
  totalsError?: ApolloError;
} = (inception, enabledTabs) => {
  // Adding no-cache to the fetchPolicies in this file in order to trigger a fresh totalCount everytime we come back to the page
  const totalWithdrawalsQueryResult = useTotalCashTransfers({
    variables: {
      transferType: TransferType.WITHDRAWAL,
      managedProductStatuses: ManagedProductStatusesForCashTransfers,
    },
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.Withdrawals),
  });
  const totalDepositsQueryResult = useTotalCashTransfers({
    variables: {
      transferType: TransferType.DEPOSIT,
      managedProductStatuses: ManagedProductStatusesForCashTransfers,
    },
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.Deposits),
  });
  const totalRecurringCancellationsQueryResult = useTotalRecurringCancellationsCashTransfers({
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.RecurringCancellations),
  });
  const totalClosuresQueryResult = useTotalClosures({
    variables: {
      statuses: Object.values(CloseAccountStatus),
    },
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.Closures),
  });
  const totalSuspensionsQueryResult = useTotalSuspensions({
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.TradingSuspensions),
  });
  const totalFundingReviewsQueryResult = useTotalFundingReviews({
    variables: {
      activatedDateFrom: toSymphonyDateTime(startOfDay(subDays(new Date(), inception ?? 0))),
    },
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.Funding),
  });
  const totalFailuresQueryResult = useTotalFailures({
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.Errors),
  });
  const totalDocusignQueryResult = useTotalDocusign({
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.Docusign),
  });
  const totalPendingModelChangesResult = useTotalPendingModelChanges({
    variables: {
      managedProductStatuses: [ManagedProductStatus.ACTIVE],
      statuses: [PlanUpdateWorkflowStatus.DOCS_SIGNED],
    },
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.PendingModelChanges),
  });
  const totalACATRequestsQueryResult = useTotalACATRequests({
    variables: {
      statuses: [ManagedProductStatus.ACTIVE],
    },
    fetchPolicy: 'no-cache',
    skip: !enabledTabs.includes(OpsDashboardTabs.ACATRequests),
  });

  const totalFinancialAccountAssociationVerificationQueryRequest = useTotalFinancialAccountAssociationVerificationRequests(
    {
      fetchPolicy: 'no-cache',
      skip: !enabledTabs.includes(OpsDashboardTabs.BankVerification),
    },
  );
  const totalRecurringDeposits = getTotalValues(totalDepositsQueryResult.data?.recurring.paginationContext.total);
  const totalOneTimeDeposits = getTotalValues(totalDepositsQueryResult.data?.oneTime.paginationContext.total);
  const totalDeposits =
    totalRecurringDeposits !== null && totalOneTimeDeposits !== null
      ? totalRecurringDeposits + totalOneTimeDeposits
      : null;

  const totalRecurringWithdrawals = getTotalValues(totalWithdrawalsQueryResult.data?.recurring.paginationContext.total);
  const totalOneTimeWithdrawals = getTotalValues(totalWithdrawalsQueryResult.data?.oneTime.paginationContext.total);
  const totalWithdrawals =
    totalRecurringWithdrawals !== null && totalOneTimeWithdrawals !== null
      ? totalRecurringWithdrawals + totalOneTimeWithdrawals
      : null;
  const totalClosures = getTotalValues(totalClosuresQueryResult.data?.closures.paginationContext.total);
  const totalSuspensions = getTotalValues(totalSuspensionsQueryResult.data?.suspensions.paginationContext.total);
  const totalBankVerificationRequests = getTotalValues(
    totalFinancialAccountAssociationVerificationQueryRequest.data?.financialAccountAssociationVerificationRequests
      .paginationContext.total,
  );
  const totalFailures = getTotalValues(totalFailuresQueryResult.data?.failures.paginationContext.total);
  const totalDocusign = getTotalValues(totalDocusignQueryResult.data?.signingDocuments.paginationContext.total);
  const totalPendingModelChanges = getTotalValues(
    totalPendingModelChangesResult.data?.getPlanUpdateWorkflows.paginationContext.total,
  );
  const totalACATRequests = getTotalValues(totalACATRequestsQueryResult.data?.assetDeposits.paginationContext.total);
  const totalRecurringCancellationsWithdrawals = getTotalValues(
    totalRecurringCancellationsQueryResult.data?.recurringWithdrawals.paginationContext.total,
  );
  const totalRecurringCancellationsDeposits = getTotalValues(
    totalRecurringCancellationsQueryResult.data?.recurringDeposits.paginationContext.total,
  );
  const totalRecurringCancellations =
    totalRecurringCancellationsWithdrawals !== null && totalRecurringCancellationsDeposits !== null
      ? totalRecurringCancellationsWithdrawals + totalRecurringCancellationsDeposits
      : null;
  const totalFundingReviews = getTotalValues(
    totalFundingReviewsQueryResult.data?.managedProductFundingReviews.paginationContext.total,
  );

  const totalClosuresCountRefetch = () => {
    totalClosuresQueryResult.refetch();
  };

  const totalDepositsCountRefetch = () => {
    totalDepositsQueryResult.refetch();
  };

  const totalFundingCountRefetch = () => {
    totalFundingReviewsQueryResult.refetch();
  };

  const totalFailureRefetch = () => {
    totalFailuresQueryResult.refetch();
  };
  const totalRecurringCancellationsRefetch = () => {
    totalRecurringCancellationsQueryResult.refetch();
  };

  const totalPendingModelChangeRefetch = () => {
    totalPendingModelChangesResult.refetch();
  };

  const totalSuspensionsCountRefetch = () => {
    totalSuspensionsQueryResult.refetch();
  };

  const totalWithdrawalsCountRefetch = () => {
    totalWithdrawalsQueryResult.refetch();
  };

  useLogError(totalDepositsQueryResult.error);
  useLogError(totalWithdrawalsQueryResult.error);
  useLogError(totalClosuresQueryResult.error);
  useLogError(totalSuspensionsQueryResult.error);
  useLogError(totalFailuresQueryResult.error);
  useLogError(totalDocusignQueryResult.error);
  useLogError(totalPendingModelChangesResult.error);
  useLogError(totalACATRequestsQueryResult.error);
  useLogError(totalFinancialAccountAssociationVerificationQueryRequest.error);
  return {
    totalDeposits,
    totalDepositsCountRefetch,
    totalWithdrawals,
    totalWithdrawalsCountRefetch,
    totalBankVerificationRequests,
    totalClosures,
    totalClosuresCountRefetch,
    totalSuspensions,
    totalSuspensionsCountRefetch,
    totalFailures,
    totalDocusign,
    totalPendingModelChanges,
    totalACATRequests,
    totalRecurringCancellations,
    totalFailureRefetch,
    totalRecurringCancellationsRefetch,
    totalPendingModelChangeRefetch,
    totalFundingReviews,
    totalFundingCountRefetch,
  };
};
