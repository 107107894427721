import * as React from 'react';

import { OrderType } from '~/__generated__';
import { NullState, NullStateProps } from '~/components/NullState';
import {
  Box,
  ExpandMoreIcon,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
  visuallyHidden,
} from '~/components/ui/mui';

export type SortConfig = {
  field: string;
  order: OrderType;
};

export type TableData = {
  [key: string]: React.ReactNode;
  rowKey: string;
};

export type TableColumn = {
  key: string;
  onSort?: (field: string) => () => void;
  title: string | React.ReactNode;
  wrappable?: boolean;
};

export type TableComponentProps = React.ComponentProps<typeof Table> & {
  // Prop to pass in React node to render anything to the left of pagination section
  BottomLeftSection?: React.ReactNode;
  // Prop to pass in React node to render anything to the right of pagination section
  BottomRightSection?: React.ReactNode;
  // Use to override NullState when more complex component is required.
  NullStateComponent?: React.ReactNode;
  alignItems?: 'left' | 'center';
  columns: TableColumn[];
  currentPage?: number;
  data: TableData[];
  dataQa?: string;
  enableRowHover?: boolean;
  nullStateConfig?: NullStateProps;
  onPageChange?: (page: number) => void;
  onRowHover?: (rowId: string | undefined) => void;
  showPagination?: boolean;
  sortConfig?: SortConfig;
  totalPages?: number;
};

export type TableComponent = React.FC<TableComponentProps>;

const toggleOrder = (order: OrderType): 'asc' | 'desc' => (order === OrderType.ASCENDING ? 'asc' : 'desc');

export const BasicTable: TableComponent = ({
  dataQa = 'basic-table',
  columns,
  data,
  nullStateConfig,
  onPageChange,
  alignItems = 'center',
  showPagination = true,
  currentPage = 1,
  totalPages = 1,
  sortConfig,
  stickyHeader,
  sx,
  BottomLeftSection,
  BottomRightSection,
  NullStateComponent,
  enableRowHover,
  onRowHover,
}) => {
  const {
    sfBasicTable: { root: sfBasicTableRoot },
  } = useTheme();

  const handleChangePage = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    onPageChange?.(newPage);
  };

  const handleOnRowHover = (rowId: string | undefined) => {
    if (enableRowHover && onRowHover) {
      onRowHover(rowId);
    }
  };

  return (
    <>
      <Table data-qa={dataQa} role="table" stickyHeader={stickyHeader} sx={{ ...sfBasicTableRoot, ...sx }}>
        <TableHead>
          <TableRow role="row">
            {columns.map(column => (
              <TableCell align={alignItems} key={column.key} role="columnheader" sx={{ py: 4 }}>
                {column.onSort && sortConfig ? (
                  <TableSortLabel
                    IconComponent={ExpandMoreIcon}
                    active={sortConfig.field === column.key}
                    direction={sortConfig.field === column.key ? toggleOrder(sortConfig.order) : 'asc'}
                    onClick={column.onSort(column.key)}
                  >
                    {column.title}
                    {sortConfig.field === column.key ? (
                      <Box component="span" style={visuallyHidden}>
                        {toggleOrder(sortConfig.order) === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  column.title
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => {
            return (
              <TableRow
                key={row.rowKey}
                onMouseEnter={() => handleOnRowHover(row.rowKey)}
                onMouseLeave={() => handleOnRowHover(undefined)}
                role="row"
              >
                {columns.map(column => {
                  return (
                    <TableCell
                      align={alignItems}
                      key={column.key}
                      role="cell"
                      sx={{ py: 4, whiteSpace: column.wrappable ? 'normal' : 'nowrap' }}
                    >
                      {row[column.key]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {/* Display a message when there is no data present in the table otherwise show pagination */}
      {!data.length ? (
        NullStateComponent ?? <NullState message={nullStateConfig?.message} styles={nullStateConfig?.styles} />
      ) : (
        <Grid container justifyContent="space-between" sx={sfBasicTableRoot}>
          <Grid item xs={2}>
            {BottomLeftSection}
          </Grid>
          {showPagination && onPageChange && (
            <Grid container item justifyContent="center" xs={8}>
              <Grid item>
                <Pagination count={totalPages} onChange={handleChangePage} page={currentPage} />
              </Grid>
            </Grid>
          )}
          <Grid item xs={2}>
            {BottomRightSection}
          </Grid>
        </Grid>
      )}
    </>
  );
};
