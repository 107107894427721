import React, { useContext, useEffect, useState } from 'react';

import OpsDashboardContext from '../OpsDashboardContext';
import { RequestTabs } from '../types';

import { useGetFundingTabContent } from './hooks/useGetFundingTabContent';
import { recordsPerPage, sortFieldToSymphony, useGetFundingTabData } from './hooks/useGetFundingTabData';
import { useUpdateFundingReviewV2 } from './symphony';
import { FundingStatusFilterTypes } from './types';
import { formatDataToTableRows } from './utils';

import { FundingReviewSortField, FundingReviewStatus, OrderType } from '~/__generated__';
import { StatusChangeModal } from '~/components/modals/StatusChange';
import { NullStateProps } from '~/components/NullState';
import { AlertAndLoading } from '~/components/ui/AlertAndLoading';
import { BasicTable, SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { Dropdown } from '~/components/ui/Dropdown';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Grid, Tab, TabContext, TabPanel, useTheme } from '~/components/ui/mui';
import { Tabs } from '~/components/ui/Tabs';
import { Typography } from '~/components/ui/Typography';
import { AccountType, ProductName } from '~/utils/account/types';
import { ContentstackTableColumn, sortColumnsByColumnOrder } from '~/utils/table';
import { SfTheme } from '~/utils/theme';

export interface Props {
  acceptedStatusUpdations: FundingReviewStatus[];
  dataQa: string;
  fetchDataFromDay?: number;
  nullStateConfig?: NullStateProps;
}
export const FundingTab: React.FC<Props> = ({
  dataQa = 'funding-tab',
  fetchDataFromDay,
  acceptedStatusUpdations,
  nullStateConfig,
}) => {
  const [activeRequestTab, setActiveRequestTab] = useState<RequestTabs>(RequestTabs.FlaggedRequests);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [statusFilter, setStatusFilter] = useState<FundingStatusFilterTypes>('ALL');
  const [tableData, setTableData] = useState<TableData[] | null>();
  const [tableColumns, setTableColumns] = useState<TableColumn[] | null>();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedFundingReviewItem, setSelectedFundingReviewItem] = useState<{
    id: string;
    status: FundingReviewStatus;
  } | null>(null);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    order: OrderType.DESCENDING,
    field: 'inception',
  });
  const {
    sfOpsDashboard: { styles: sfOpsDashboardStyles },
  } = useTheme<SfTheme>();
  const {
    currentUser,
    contentOptions,
    commentColumn,
    onAccountClick,
    onClientClick,
    getAccountNumberRedirectUrl,
    getClientNameRedirectUrl,
    hiddenFlagReasons,
    opsContentData,
    params,
    onParamsUpdate,
  } = useContext(OpsDashboardContext);

  const { open: statusModalOpen, openModal: openStatusModal, onClose: statusModalOnClose } = useModalState();

  const [updateFundingStatus] = useUpdateFundingReviewV2();
  const { data: contentData, loading: contentDataLoading, error: contentDataError } = useGetFundingTabContent();
  const { data: fundingData, loading: fundingDataLoading, error: fundingDataError } = useGetFundingTabData({
    activeRequestTab,
    statusFilter,
    pageNumber,
    inception: fetchDataFromDay ?? null,
    partitionedByFlagStatus: contentData?.partitionedByFlaggedStatus ?? null,
    contentDataLoading,
    sortConfig,
  });

  const onSort = (field: FundingReviewSortField) => () => {
    const toggleOrder = sortConfig.order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING;
    setSortConfig({ order: toggleOrder, field });
    setPageNumber(1);
  };

  const treatedColumns = (sortedColumns: ContentstackTableColumn[]) => {
    return sortedColumns.map((col: { column_id: any; column_value: any }) => {
      return {
        title: col?.column_value,
        key: col?.column_id,
        onSort: sortFieldToSymphony.get(col?.column_id) ? onSort : undefined,
      };
    });
  };

  useEffect(() => {
    if (contentData?.tableHeaders) {
      const sortedColumns = sortColumnsByColumnOrder(contentData.tableHeaders);
      const columns = treatedColumns(sortedColumns);
      setTableColumns(columns as TableColumn[] | undefined);
    }
  }, [contentData?.tableHeaders, sortConfig]);

  const handleRequestTabChange = (newTab: RequestTabs) => {
    setPageNumber(1);
    setActiveRequestTab(newTab);
  };

  const handleTabStatusFilterChange = (newStatus: FundingStatusFilterTypes) => {
    setPageNumber(1);
    setStatusFilter(newStatus);
  };
  const onConfirmStatusChange = async () => {
    if (selectedFundingReviewItem?.id && selectedFundingReviewItem.status) {
      const { id, status } = selectedFundingReviewItem;
      setIsBusy(true);
      try {
        await updateFundingStatus({
          variables: {
            fundingReviewId: id,
            status,
          },
        });
        fundingData?.refetchFundingReviewsData();
        setIsBusy(false);
        statusModalOnClose();
      } catch (err: any) {
        console.error(err);
        setErrorSaving(err);
      }
    }
  };

  const handleFundingReviewStatusChangeForItem = (itemId: string, newStatus: FundingReviewStatus) => {
    if (itemId && newStatus) {
      openStatusModal();
      setSelectedFundingReviewItem({
        id: itemId,
        status: newStatus,
      });
    }
  };
  const loading = contentDataLoading || fundingDataLoading;
  const error = contentDataError || fundingDataError;

  useEffect(() => {
    if (fundingData?.fundingReviews && contentData) {
      const formattedData = formatDataToTableRows({
        acceptedStatusUpdations,
        contentOptions,
        currentUser,
        commentColumn,
        hiddenFlagReasons,
        handleFundingReviewStatusChangeForItem,
        onAccountClick,
        onClientClick,
        getClientNameRedirectUrl,
        getAccountNumberRedirectUrl,
        content: contentData,
        fundingData,
        productNames: opsContentData?.all_product_name?.items?.map(item => item as ProductName) ?? [],
        accountTypes: opsContentData?.all_account_type?.items?.map(item => item as AccountType) ?? [],
        params,
        onParamsUpdate,
      });
      setTableData(formattedData.tableData);
      setTableColumns(formattedData.tableColumns);
      setTotalPages(Math.ceil((fundingData.pagination.total ?? 0) / recordsPerPage));
    }
  }, [contentData, fundingData?.fundingReviews]);

  return (
    <Grid container data-qa={dataQa} direction="column" spacing={3}>
      {(loading || error) && (
        <Grid item>
          <AlertAndLoading
            ariaLabel="Loading error items"
            contentOptions={contentOptions}
            error={error}
            loading={loading}
          />
        </Grid>
      )}
      {fundingData && contentData && (
        <>
          <>
            {contentData.partitionedByFlaggedStatus ? (
              <Grid container item>
                <TabContext value={activeRequestTab}>
                  <Grid container sx={{ pt: 0, ml: 2 }}>
                    <Grid item xs={12}>
                      <Tabs
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: sfOpsDashboardStyles.tabsIndicatorColor,
                          },
                        }}
                        data-qa="partition-tabs"
                        onChange={handleRequestTabChange}
                        value={activeRequestTab}
                      >
                        <Tab
                          data-qa="flaggedRequests-tab"
                          label={contentData.partitionTabs?.[RequestTabs.FlaggedRequests] ?? 'Flagged'}
                          sx={{
                            ...sfOpsDashboardStyles.partitionTabs,
                          }}
                          value={RequestTabs.FlaggedRequests}
                        />
                        <Tab
                          data-qa="allotherrequests-tab"
                          label={contentData.partitionTabs?.[RequestTabs.AllOtherRequests] ?? 'Other'}
                          sx={{
                            ...sfOpsDashboardStyles.partitionTabs,
                          }}
                          value={RequestTabs.AllOtherRequests}
                        />
                      </Tabs>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ py: 5 }}>
                    <Grid data-qa={`${dataQa}-filter-container`} item sx={{ display: 'flex', marginLeft: 'auto' }}>
                      <Typography sx={{ marginRight: 2, alignSelf: 'center' }} variant="subtitle2">
                        Filter by status:
                      </Typography>
                      <Dropdown
                        items={contentData.statusFiltersDropdownItems}
                        onChange={e => handleTabStatusFilterChange(e.target.value as FundingStatusFilterTypes)}
                        value={statusFilter}
                      />
                    </Grid>
                    {tableData && tableColumns && (
                      <>
                        <TabPanel sx={{ width: 1 }} value={RequestTabs.FlaggedRequests}>
                          <BasicTable
                            alignItems="left"
                            columns={tableColumns}
                            currentPage={pageNumber}
                            data={tableData}
                            nullStateConfig={nullStateConfig}
                            onPageChange={setPageNumber}
                            showPagination
                            sortConfig={sortConfig}
                            totalPages={totalPages}
                          />
                        </TabPanel>
                        <TabPanel sx={{ width: 1 }} value={RequestTabs.AllOtherRequests}>
                          <BasicTable
                            alignItems="left"
                            columns={tableColumns}
                            currentPage={pageNumber}
                            data={tableData}
                            nullStateConfig={nullStateConfig}
                            onPageChange={setPageNumber}
                            showPagination
                            sortConfig={sortConfig}
                            totalPages={totalPages}
                          />
                        </TabPanel>
                      </>
                    )}
                  </Grid>
                </TabContext>
              </Grid>
            ) : (
              <>
                {tableData && tableColumns && (
                  <BasicTable
                    alignItems="left"
                    columns={tableColumns}
                    currentPage={pageNumber}
                    data={tableData}
                    onPageChange={setPageNumber}
                    showPagination
                    sortConfig={sortConfig}
                    totalPages={totalPages}
                  />
                )}
              </>
            )}
            <StatusChangeModal
              contentOptions={contentOptions}
              errorSaving={errorSaving}
              isBusy={isBusy}
              onClose={statusModalOnClose}
              onConfirm={onConfirmStatusChange}
              open={statusModalOpen}
            />
          </>
        </>
      )}
    </Grid>
  );
};
