import { FlagItemReason } from './contentstack';
import { GetCreateFlagContent_all_flags_items_reasons } from './contentstack/__generated__/query.v2';

export interface FlagReasonsOption {
  label: string;
  value: string;
}

export const getFlagReasonsOptions = (
  flagReasons: string[],
  allFlagsReasons: GetCreateFlagContent_all_flags_items_reasons | null,
): FlagReasonsOption[] => {
  return flagReasons.map(reason => ({
    label: allFlagsReasons?.[reason.toLowerCase() as keyof FlagItemReason] ?? '',
    value: reason ?? '',
  }));
};
