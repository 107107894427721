import React, { useState } from 'react';

import { RmdSummaryContent } from '../../modals/WithdrawFunds/types';

import { amountWithDescription, getAccountsString, getTotalAmountSection, RmdSummaryData } from './utils';

import { ConfirmationModal } from '~/components/modals/Confirmation';
import { Divider } from '~/components/ui/Divider';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Button, Collapse, Container, ExpandLessIcon, ExpandMoreIcon, Stack } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';

export interface Props {
  content: RmdSummaryContent;
  data: RmdSummaryData;
  onDismissRmd: () => void;
}

export const RmdSummary: React.FC<Props> = ({ content, data, onDismissRmd }) => {
  const [expanded, setExpanded] = useState(false);
  const { open, openModal: openConfirmationModal, onClose: onCloseConfirmationModal } = useModalState();

  if (data.rmdAmounts.length === 0) {
    return <></>;
  }

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: 'other.backgroundGrey',
          px: 3,
          py: 2,
          my: 2,
        }}
      >
        <Stack divider={<Divider orientation="horizontal" />} spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography component="h3" variant="subtitle1">
              {content.title}
            </Typography>
            <Button onClick={openConfirmationModal}>{content.requirementFulfilledCTA}</Button>
          </Stack>
          {getTotalAmountSection(data, content)}
        </Stack>
        <Collapse
          in={expanded}
          sx={{
            backgroundColor: 'white',
            px: 3,
            mx: 2,
            py: expanded ? 3 : 0,
            my: expanded ? 2 : 0,
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="flex-start">
              <Typography component="h4" sx={{ mr: 0.5 }} variant="caption">
                {content.breakdownSummaryItems.title}
              </Typography>
              {data.totalIraAccounts > data.eligibleIraAccountWithInformationLabels.length ? (
                <>
                  <RteContent
                    config={{
                      eligibleAccounts: data.eligibleIraAccountWithInformationLabels.length,
                      totalAccounts: data.totalIraAccounts,
                    }}
                    data={content.breakdownSummaryItems.accountWithIneligibleCount}
                  />
                  <Tooltip
                    tooltipContent={
                      <RteContent
                        config={{
                          accounts: getAccountsString(
                            data.eligibleIraAccountWithInformationLabels,
                            content.breakdownSummaryItems.accountCountTooltipJoiningWord,
                          ),
                        }}
                        data={content.breakdownSummaryItems.partialAccountCountTooltip}
                      />
                    }
                  />
                </>
              ) : data.totalIraAccounts > 1 ? (
                <>
                  <RteContent
                    config={{ accounts: data.totalIraAccounts }}
                    data={content.breakdownSummaryItems.accountCountMultiple}
                  />
                  <Tooltip
                    tooltipContent={
                      <Stack>
                        <Typography variant="caption">{content.breakdownSummaryItems.accountCountTooltip}</Typography>
                        {data.eligibleIraAccountWithInformationLabels.map((label, index) => (
                          <Typography key={index} variant="caption">
                            {label}
                          </Typography>
                        ))}
                      </Stack>
                    }
                  />
                </>
              ) : (
                <Typography variant="caption">{content.breakdownSummaryItems.accountCount}</Typography>
              )}
            </Stack>
            {amountWithDescription(
              { data: content.breakdownSummaryItems.totalRMDText },
              data.totalRmdAmountToBeWithdrawn,
            )}
            <Divider flexItem orientation="horizontal" />
            {amountWithDescription(
              { data: content.breakdownSummaryItems.amountWithdrawnText },
              data.totalAmountWithdrawnYtd,
            )}
            {amountWithDescription(
              { data: content.breakdownSummaryItems.scheduledWithdrawalsText },
              -1 * data.sumOfScheduledWithdrawals,
            )}
            <Divider flexItem orientation="horizontal" />
            {amountWithDescription(
              data.rmdAmounts.length === 1
                ? {
                    data: content.totalRMDAmountWithDate,
                    config: { deadline: data.rmdAmounts[0].deadline },
                  }
                : { data: content.totalRMDAmountWithoutDate },
              data.remainingRmdAmountToBeWithdrawn,
            )}
          </Stack>
        </Collapse>
        <Stack alignItems="center" direction="row" justifyContent="flex-end" sx={{ mt: !expanded ? 2 : 0 }}>
          <Button
            onClick={() => setExpanded(prevState => !prevState)}
            startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            <Typography sx={{ fontWeight: 'bold' }} variant="body2">
              {expanded ? content.breakdownSummaryItems.toggleText.hide : content.breakdownSummaryItems.toggleText.show}
            </Typography>
          </Button>
        </Stack>
      </Container>
      <ConfirmationModal
        content={content.requirementFulfilledRmdModal}
        onClose={onCloseConfirmationModal}
        onPrimaryClick={onDismissRmd}
        open={open}
      />
    </>
  );
};
