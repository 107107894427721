import React from 'react';

import { Link } from '~/components/ui/Link';
import { AddIcon, Box, LinkIcon } from '~/components/ui/mui';

export interface Props {
  content: {
    addCta?: string;
  };
  isPlaidAddAccount?: boolean;
  onAddAccount?: () => void;
}

export const AddAccounts: React.FC<Props> = ({ content, onAddAccount, isPlaidAddAccount = false }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Link component="button" onClick={onAddAccount} sx={{ display: 'inline-flex', alignItems: 'center' }}>
        {isPlaidAddAccount ? <LinkIcon sx={{ fontSize: 18, mr: 0.5 }} /> : <AddIcon sx={{ fontSize: 18, mr: 0.5 }} />}
        {content.addCta}
      </Link>
    </Box>
  );
};
