import React from 'react';

import { Modal } from '~/components/ui/Modal';
import { useModalState } from '~/components/ui/Modal/hooks';
import { Box, Button, CardMedia, Grid, PlayIcon } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Props {
  dataQa?: string;
  header: string;
  imageUrl: string;
  learnMore: {
    callback: () => void;
    ctaText: string;
  };
  partyId: string;
  showLearnMoreMarketingCta?: boolean;
  subHeader: string;
  video: {
    ctaText: string;
    url: string;
  };
}

export const BackToMarketingPage: React.FC<Props> = ({
  dataQa = 'back-to-marketing',
  header,
  subHeader,
  video,
  imageUrl,
  learnMore,
  partyId,
  showLearnMoreMarketingCta = true,
}) => {
  const isMediumScreen = useIsMediumScreen();
  const { open, openModal, onClose } = useModalState();

  return (
    <Grid container sx={{ my: 10 }}>
      <Grid
        data-qa={`${dataQa}-image`}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        <img alt="" onClick={openModal} src={imageUrl} />
      </Grid>
      <Box sx={{ textAlign: 'center', width: '100%', mt: 4 }}>
        <RteContent data={header} data-qa={`${dataQa}-header`} sx={{ mb: 2 }} />
        <RteContent data={subHeader} data-qa={`${dataQa}-subHeader`} />
        <Grid display="flex" flexDirection="row" justifyContent="center" sx={{ pt: 4 }} textAlign="center">
          {showLearnMoreMarketingCta && (
            <Button
              data-heap={`backToMarketing-learnMore-${partyId}`}
              data-qa={`${dataQa}-cta-learn-more`}
              onClick={learnMore.callback}
              variant="contained"
            >
              {learnMore.ctaText}
            </Button>
          )}
          {video.url && (
            <Grid item>
              <Modal
                content={<CardMedia autoPlay component="video" controls data-qa={`${dataQa}-player`} src={video.url} />}
                dataQa={`${dataQa}-marketing-intro-video-modal`}
                dividers={false}
                onClose={onClose}
                open={open}
              />
              <Button
                color="primary"
                data-heap={`backToMarketing-watchVideo-${partyId}`}
                data-qa={`${dataQa}-cta-video`}
                fullWidth={isMediumScreen}
                onClick={openModal}
                startIcon={<PlayIcon />}
                sx={{ ml: 2 }}
                variant="outlined"
              >
                {video.ctaText}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};
