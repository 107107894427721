import {
  CashTransfers,
  CashTransfers_cashTransfers_transfers_attributes,
  CashTransfers_cashTransfers_transfers_attributes_BooleanAttribute,
  CashTransfers_cashTransfers_transfers_attributes_DecimalAttribute,
  CashTransfers_cashTransfers_transfers_attributes_IntegerAttribute,
  CashTransfers_cashTransfers_transfers_attributes_StringAttribute,
  CashTransfers_cashTransfers_transfers_flags,
  CashTransfersVariables,
} from './__generated__/query.v2';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import {
  UpdateCashTransfer,
  UpdateCashTransferVariables,
} from '~/containers/OpsDashboard/common/CashTransfer/symphony/__generated__/mutation.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';
import { useSymphonyMutation } from '~/utils/symphony';

export type { CashTransfers, CashTransfersVariables } from './__generated__/query.v2';

export type CashTransfersAttributes = CashTransfers_cashTransfers_transfers_attributes;
export type CashTransfersBooleanAttribute = CashTransfers_cashTransfers_transfers_attributes_BooleanAttribute;
export type CashTransfersDecimalAttribute = CashTransfers_cashTransfers_transfers_attributes_DecimalAttribute;
export type CashTransfersIntegerAttribute = CashTransfers_cashTransfers_transfers_attributes_IntegerAttribute;
export type CashTransfersStringAttribute = CashTransfers_cashTransfers_transfers_attributes_StringAttribute;
export type CashTransfersFlag = CashTransfers_cashTransfers_transfers_flags;

export const useCashTransfers = (
  options?: QueryHookOptions<CashTransfers, CashTransfersVariables>,
): QueryResult<CashTransfers, CashTransfersVariables> => {
  return useSymphonyQuery(queries.CashTransfers, options);
};

export const useUpdateCashTransfer = (
  options?: MutationHookOptions<UpdateCashTransfer, UpdateCashTransferVariables>,
): MutationTuple<UpdateCashTransfer, UpdateCashTransferVariables> => {
  return useSymphonyMutation(mutations.UpdateCashTransfer, options);
};
