import React, { useCallback, useMemo } from 'react';

import { OthersTab } from './components/OthersTab';
import { StatementsTab } from './components/StatementsTab';
import { TaxReportsTab } from './components/TaxReportsTab';
import { TradeConfirmationTab } from './components/TradeConfirmationTab';
import { useClientDocumentsContent } from './hooks/useClientDocumentsContent';

import { DocumentCategoryEnum } from '~/__generated__';
import { Alert } from '~/components/ui/Alert';
import { Box, Skeleton } from '~/components/ui/mui';
import { TabData, TabsContainer } from '~/components/ui/TabsContainer';
import { Typography } from '~/components/ui/Typography';
import { useCoreConfig } from '~/utils/config';
import { ContentOptions } from '~/utils/contentstack';

export interface Props {
  clientDocumentsPerPage: number; // TODO: Remove this once it is not being used in modal
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
}

const isValidTab = (currentTab: string): currentTab is keyof typeof DocumentCategoryEnum =>
  currentTab in DocumentCategoryEnum;

const styles = { tabSx: { py: 4 } };

export const ClientDocuments: React.FC<Props> = ({
  clientDocumentsPerPage,
  contentOptions,
  dataQa = 'client-documents',
  managedProductId,
}) => {
  const {
    components: {
      sfClientDocuments: { tabs },
    },
  } = useCoreConfig();

  const { data, loading, error } = useClientDocumentsContent({
    tabs,
    contentOptions,
  });

  const getTabComponent = useCallback(
    (currentTab: string): React.ReactNode => {
      if (isValidTab(currentTab)) {
        switch (DocumentCategoryEnum[currentTab]) {
          case DocumentCategoryEnum.STATEMENTS: {
            return (
              <Box sx={styles.tabSx}>
                <StatementsTab
                  clientDocumentsPerPage={clientDocumentsPerPage}
                  contentOptions={contentOptions}
                  managedProductId={managedProductId}
                />
              </Box>
            );
          }
          case DocumentCategoryEnum.TRADE_CONFIRMATIONS: {
            return (
              <Box sx={styles.tabSx}>
                <TradeConfirmationTab
                  clientDocumentsPerPage={clientDocumentsPerPage}
                  contentOptions={contentOptions}
                  managedProductId={managedProductId}
                />
              </Box>
            );
          }
          case DocumentCategoryEnum.TAX_REPORTS: {
            return (
              <Box sx={styles.tabSx}>
                <TaxReportsTab
                  clientDocumentsPerPage={clientDocumentsPerPage}
                  contentOptions={contentOptions}
                  managedProductId={managedProductId}
                />
              </Box>
            );
          }
          case DocumentCategoryEnum.OTHERS: {
            return (
              <Box sx={styles.tabSx}>
                <OthersTab
                  clientDocumentsPerPage={clientDocumentsPerPage}
                  contentOptions={contentOptions}
                  managedProductId={managedProductId}
                />
              </Box>
            );
          }
          default: {
            return null;
          }
        }
      }
      return null;
    },
    [contentOptions, clientDocumentsPerPage, managedProductId],
  );

  const tabsData: TabData[] = useMemo(
    () =>
      tabs.map(tab => ({
        label: <>{data?.content.tabHeader[tab] ?? ''}</>,
        id: tab.toString(),
      })),
    [data, tabs],
  );

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <Alert contentOptions={contentOptions} error={error} severity="error" />;
  }

  return (
    <Box data-qa={dataQa}>
      <TabsContainer getTabComponent={getTabComponent} tabs={tabsData} />
      <Typography variant="body2">{data?.content.disclaimer}</Typography>
    </Box>
  );
};
