import React, { ComponentProps } from 'react';

import { DrawerTooltip } from './DrawerTooltip';
import { PopupTooltip } from './PopupTooltip';

import { Box, ClickAwayListener, MuiTooltip, useTheme, visuallyHidden } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface TooltipVariantProps extends Omit<Props, 'variant'> {
  buttonRef: React.MutableRefObject<HTMLElement | undefined>;
  handleClose?: () => void;
  onClick: () => void;
}
export interface CustomProps {
  anchorText?: string;
  children?: React.ReactNode;
  dataQa?: string;
  // if provided true, tooltip visibility will not toggle on click
  disableOnClick?: boolean;
  iconPosition?: string;
  tooltipContent: NonNullable<React.ReactNode>;
  /**
   * @deprecated
   * If children and anchorText are not provided, generic Info icon will be used.
   */
  useGenericInfoIcon?: boolean;
  variant?: 'popup' | 'drawer';
}

/**
 * ADA for Tooltip works such a way that the anchor element has the aria-labelledby id referencing the tool content.
 * As a result, when the tooltip opens, since the anchor element still has the focus, the screen reader reads
 * the tooltip content.
 * This relies on the screen reader to pick up on the popup element entering the DOM that aria-labelledby references,
 * but the results may vary.
 * OSX VoiceOver in Chrome seems to work OK while VO in Safari does not.  JAWS with Chrome does not seem to work either.
 *
 * The alternative solution to make tooltip content to get read by screen reader is to use assertive aria-live as the
 * tooltip gets opened.
 *
 * Allow user to close an open tooltip via keyboard input(space or enter key).
 */
const TooltipContent: React.FC<
  {
    contentRef: React.MutableRefObject<HTMLElement | undefined>;
    onBlur: (e: React.FocusEvent<HTMLDivElement>) => void;
  } & Pick<Props, 'tooltipContent' | 'variant'>
> = ({ contentRef, onBlur, tooltipContent, variant }) => {
  const {
    sfTooltip: { styles },
  } = useTheme();

  return (
    <Box onBlur={variant === 'popup' ? onBlur : undefined} ref={contentRef} sx={styles.content}>
      <Typography variant="tooltip">{tooltipContent}</Typography>
    </Box>
  );
};

export type Props = Omit<ComponentProps<typeof MuiTooltip>, keyof CustomProps | 'title' | 'onClick'> & CustomProps;
// TODO: DA2-391 & DA2-460 build out tooltip implementation and ada requirements
export const Tooltip: React.FC<Props> = ({
  'aria-label': ariaLabel,
  children,
  dataQa = 'tooltip',
  tooltipContent,
  variant,
  disableHoverListener = true,
  disableOnClick,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const isMobile = useIsMediumScreen();
  const buttonRef = React.useRef<HTMLElement>();
  const contentRef = React.useRef<HTMLElement>();

  const tooltipVariant = variant ?? (isMobile ? 'drawer' : 'popup');

  const onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (
      !buttonRef.current?.contains(e.relatedTarget as Node) &&
      !contentRef.current?.contains(e.relatedTarget as Node) &&
      (contentRef.current?.getElementsByTagName('a').length ?? 1) < 1
    ) {
      onClose();
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (open && event.code === 'Escape') {
      onClose();
    }
  };

  const toggleTooltip = () => {
    if (!disableOnClick) {
      setOpen(!open);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const variantProps = {
    'aria-label': ariaLabel ?? 'info icon',
    buttonRef,
    open,
    onBlur,
    onKeyDown,
    onClick: toggleTooltip,
    onClose,
    onPointerEnter: !disableHoverListener ? () => setOpen(true) : undefined,
    onPointerLeave: !disableHoverListener ? onClose : undefined,
    tooltipContent: <TooltipContent contentRef={contentRef} onBlur={onBlur} tooltipContent={tooltipContent} />,
    disableHoverListener,
    ...props,
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box component="span" data-qa={dataQa}>
        <Typography aria-live="assertive" style={visuallyHidden}>
          {open ? tooltipContent : ''}
        </Typography>
        {tooltipVariant === 'popup' && <PopupTooltip {...variantProps}>{children}</PopupTooltip>}
        {tooltipVariant === 'drawer' && (
          <DrawerTooltip {...variantProps} handleClose={onClose}>
            {children}
          </DrawerTooltip>
        )}
      </Box>
    </ClickAwayListener>
  );
};
