import React from 'react';

import { LinearProgress } from '~/components/ui/mui';

export interface Props {
  activeStep: number;
  dataQa?: string;
  displayLinearProgress?: boolean;
  steps?: number;
}

export const WithdrawFundsFormProgress: React.FC<Props> = ({
  activeStep,
  dataQa = 'withdraw-funds-progress-bar',
  displayLinearProgress,
  steps = 3,
}) => {
  return (
    <>
      {displayLinearProgress ? (
        <LinearProgress
          data-qa={`${dataQa}-progress`}
          sx={{
            width: ({ spacing }) => `calc(100% + ${spacing(6)})`,
            top: ({ spacing }) => spacing(-2),
            left: ({ spacing }) => spacing(-3),
          }}
          value={(100 * activeStep) / steps}
          variant="determinate"
        />
      ) : null}
    </>
  );
};
