import React, { FC, useEffect } from 'react';

import { useTaxWitholdingAllocation } from './hooks';

import { TransferFrequency } from '~/__generated__';
import { FormData } from '~/components/modals/WithdrawFunds/types';
import { TaxWithholdingAllocationBarContent } from '~/components/modals/WithdrawFunds/WithdrawFundsModalContent';
import { Divider } from '~/components/ui/Divider';
import { Card, FiberManualRecordIcon, Grid } from '~/components/ui/mui';
import { Typography } from '~/components/ui/Typography';
import { formatCurrencyPrecise } from '~/utils/format';
import { useIsMediumScreen } from '~/utils/responsiveness';

export interface Props {
  availableFunds?: number;
  content: TaxWithholdingAllocationBarContent | undefined | null;
  coverTaxWithholding?: boolean;
  dataQa?: string;
  federalTaxPercent: number;
  formData?: FormData;
  isCloseAccount?: boolean;
  maxAllowedAmount?: number;
  setShowMaximumAllowedAmountError: (error: boolean) => void;
  setShowOverdrawnWithdrawalError: (error: boolean) => void;
  setTotalWithdrawalAmount?: (amount: string) => void;
  stateTaxPercent: number;
}

const INVALID_AMOUNT_PLACEHOLDER = '-';

export const TaxWithholdingAllocationBar: FC<Props> = ({
  availableFunds,
  content,
  dataQa = 'withdraw-funds-tax-allocation-bar',
  federalTaxPercent,
  formData,
  isCloseAccount,
  maxAllowedAmount,
  setShowMaximumAllowedAmountError,
  setShowOverdrawnWithdrawalError,
  setTotalWithdrawalAmount,
  stateTaxPercent,
  coverTaxWithholding = false,
}) => {
  const isMobile = useIsMediumScreen();
  const {
    federalTaxColor,
    federalTaxValue,
    isValidTaxPercent,
    moneyYouGet,
    moneyYouGetColor,
    stateTaxColor,
    stateTaxValue,
    totalWithdrawalAmount,
  } = useTaxWitholdingAllocation({
    coverTaxWithholding,
    federalTaxPercent,
    formData,
    stateTaxPercent,
  });

  useEffect(() => {
    setTotalWithdrawalAmount?.(totalWithdrawalAmount.toString());
    if (isCloseAccount) {
      return;
    }
    if ((availableFunds ?? 0) >= (maxAllowedAmount ?? 0) && totalWithdrawalAmount > (maxAllowedAmount ?? 0)) {
      setShowMaximumAllowedAmountError(true);
    } else if (totalWithdrawalAmount > (availableFunds ?? 0)) {
      setShowOverdrawnWithdrawalError(true);
    } else {
      setShowMaximumAllowedAmountError(false);
      setShowOverdrawnWithdrawalError(false);
    }
  }, [totalWithdrawalAmount]);

  return (
    <Grid data-qa={dataQa} id="tax-withholding-form">
      <Card sx={{ px: { xs: 2, md: 3 }, py: 2.5 }} variant="outlined">
        <Grid container spacing={2}>
          <Grid
            item
            justifyContent="end"
            md="auto"
            sx={{ display: 'flex', flexDirection: 'column' }}
            width="auto"
            xs={12}
          >
            <Typography data-qa="withdrawal-amount-label" sx={{ color: 'text.primary' }} variant="caption">
              {formData?.withdrawalFrequency === TransferFrequency.MONTHLY
                ? content?.monthly_withdrawal
                : content?.total_withdrawal}
            </Typography>
            <Typography data-qa="withdrawal-amount-value" sx={{ color: 'text.primary' }} variant="body2">
              {isValidTaxPercent ? formatCurrencyPrecise(totalWithdrawalAmount) : INVALID_AMOUNT_PLACEHOLDER}
            </Typography>
            {isMobile && <Divider orientation="horizontal" sx={{ mt: 2 }} />}
          </Grid>
          {!isMobile && <Divider flexItem orientation="vertical" sx={{ ml: 2.5, mt: 2 }} />}
          <Grid alignItems="center" container direction="column" item width="auto" xs="auto">
            <Grid alignItems="center" item sx={{ display: 'flex' }}>
              <FiberManualRecordIcon
                data-qa="money-you-get-record-icon"
                sx={{ color: moneyYouGetColor, width: '15px' }}
              />
              <Typography data-qa="money-you-get-label" sx={{ color: 'text.primary' }} variant="caption">
                {content?.money_you_get}
              </Typography>
            </Grid>
            <Typography data-qa="money-you-get-value" sx={{ color: 'text.primary' }} variant="body2">
              {isValidTaxPercent ? formatCurrencyPrecise(moneyYouGet) : INVALID_AMOUNT_PLACEHOLDER}
            </Typography>
          </Grid>
          <Grid alignItems="center" container direction="column" item width="auto" xs="auto">
            <Grid alignItems="center" item sx={{ display: 'flex' }}>
              <FiberManualRecordIcon data-qa="federal-tax-record-icon" sx={{ color: federalTaxColor, width: '15px' }} />
              <Typography data-qa="federal-tax-label" sx={{ color: 'text.primary' }} variant="caption">
                {content?.federal_tax}
              </Typography>
            </Grid>
            <Typography data-qa="federal-tax-value" sx={{ color: 'text.primary' }} variant="body2">
              {isValidTaxPercent ? formatCurrencyPrecise(federalTaxValue) : INVALID_AMOUNT_PLACEHOLDER}
            </Typography>
          </Grid>
          <Grid alignItems="center" container direction="column" item width="auto" xs="auto">
            <Grid alignItems="center" item sx={{ display: 'flex' }}>
              <FiberManualRecordIcon data-qa="state-tax-record-icon" sx={{ color: stateTaxColor, width: '15px' }} />
              <Typography data-qa="state-tax-label" sx={{ color: 'text.primary' }} variant="caption">
                {content?.state_tax}
              </Typography>
            </Grid>
            <Typography data-qa="state-tax-value" sx={{ color: 'text.primary' }} variant="body2">
              {isValidTaxPercent ? formatCurrencyPrecise(stateTaxValue) : INVALID_AMOUNT_PLACEHOLDER}
            </Typography>
          </Grid>
        </Grid>
        <Grid sx={{ mt: 2.5, display: 'flex', columnGap: '4px' }}>
          <Grid
            data-qa="money-you-get-bar"
            sx={{
              bgcolor: moneyYouGetColor,
              height: 13,
              width: `${isValidTaxPercent ? 100 - (federalTaxPercent + stateTaxPercent) : 100}%`,
            }}
          />
          {isValidTaxPercent && (
            <>
              <Grid
                data-qa="federal-tax-bar"
                sx={{
                  bgcolor: federalTaxColor,
                  height: 13,
                  width: `${federalTaxPercent}%`,
                }}
              />
              <Grid
                data-qa="state-tax-bar"
                sx={{
                  bgcolor: stateTaxColor,
                  height: 13,
                  width: `${stateTaxPercent}%`,
                }}
              />
            </>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};
