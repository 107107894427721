import { TabContent } from '../../../types';
import { TableColumns } from '../../TableSection/types';
import { StatementsTabContent } from '../contentstack';
import { statementsTableColumnsConfig } from '../utils';

import { getImageAssetUrl } from '~/utils/contentstack';

export const getStatementsTabContent = (contentData?: StatementsTabContent): TabContent => {
  const content = contentData?.all_statements_tab?.items?.[0];

  return {
    controlFields:
      content?.fields?.map(field => ({
        controlFieldLabels:
          field?.field_labels?.map(item => ({
            key: item?.key ?? 'fields.field_labels.key',
            value: item?.value ?? 'fields.field_labels.value',
          })) ?? [],
        key: field?.key ?? 'field.key',
      })) ?? [],
    downloadError: content?.labels?.find(item => item?.key === 'download_error')?.label ?? 'download_error',
    tableContent: {
      columns: statementsTableColumnsConfig.map(column => ({
        key: column.key,
        title:
          column.key !== TableColumns.Download
            ? content?.labels?.find(item => item?.key === `column_${column.key.toLowerCase()}`)?.label ??
              `column_${column.key.toLowerCase()}`
            : '',
        wrappable: column.wrappable,
      })),
      documentsFailedText:
        content?.labels?.find(item => item?.key === 'documents_failed_text')?.label ?? 'documents_failed_text',
      downloadIconContent: {
        downloadAction: content?.labels?.find(item => item?.key === 'download_action')?.label ?? 'download_action',
        downloadIconUrl: getImageAssetUrl(content?.download_iconConnection),
      },
      emptyTableText: content?.labels?.find(item => item?.key === 'empty_table_text')?.label ?? 'empty_table_text',
    },
  };
};
