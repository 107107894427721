import { useMemo } from 'react';

import { useAccountNumberLabels, useClientNameLabels } from '../contentstack';

import { ContentOptions, findFieldValue } from '~/utils/contentstack';
import { AsyncResult } from '~/utils/types';

interface Variables {
  contentOptions: ContentOptions;
}

interface Content {
  copyLabel: string;
  successfullyCopied: string;
}

export const useClientNameContent = ({ contentOptions }: Variables): AsyncResult<Content> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useClientNameLabels({
    variables: contentOptions,
  });

  const data: Content | undefined = useMemo(() => {
    if (contentLoading || contentError) {
      return;
    }

    const content = contentData?.all_client_name_labels?.items?.[0];
    const textFields = content?.fields?.text ?? [];

    return {
      successfullyCopied: findFieldValue(textFields, 'successfully_copied_label'),
      copyLabel: findFieldValue(textFields, 'copy_label'),
    };
  }, [contentData, contentError, contentLoading]);

  return {
    data,
    error: contentError,
    loading: contentLoading,
  };
};

export const useAccountNumberContent = ({ contentOptions }: Variables): AsyncResult<Content> => {
  const { data: contentData, loading: contentLoading, error: contentError } = useAccountNumberLabels({
    variables: contentOptions,
  });

  const data: Content | undefined = useMemo(() => {
    if (contentLoading || contentError) {
      return;
    }

    const content = contentData?.all_account_number_labels?.items?.[0];
    const textFields = content?.fields?.text ?? [];

    return {
      successfullyCopied: findFieldValue(textFields, 'successfully_copied_label'),
      copyLabel: findFieldValue(textFields, 'copy_label'),
    };
  }, [contentData, contentError, contentLoading]);

  return {
    data,
    error: contentError,
    loading: contentLoading,
  };
};
