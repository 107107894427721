import { GetPendingModels, GetPendingModelsVariables } from './__generated__/query.v2';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export const useGetPendingModelChangeTabData = (
  options: QueryHookOptions<GetPendingModels, GetPendingModelsVariables>,
): QueryResult<GetPendingModels, GetPendingModelsVariables> => {
  return useSymphonyQuery(queries.GetPendingModels, options);
};
