import React, { ComponentProps, FC } from 'react';

import { Divider } from '~/components/ui/Divider';
import { Box, LoadingButton, Stack, useTheme } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Typography } from '~/components/ui/Typography';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { SfTheme } from '~/utils/theme';

interface Props {
  dataQa?: string;
  effectiveDate?: ComponentProps<typeof RteContent>;
  emptyState: {
    img: string;
    label: string;
  };
  heading: string;
  primaryCta?: ComponentProps<typeof LoadingButton> & {
    dataHeap?: string;
    label: string;
  };
}

export const AccountList: FC<Props> = ({
  children,
  dataQa = 'account-list',
  effectiveDate,
  emptyState,
  heading,
  primaryCta,
}) => {
  const isMobile = useIsMediumScreen();
  const {
    sfAccountList: { typographyVariants },
  } = useTheme<SfTheme>();
  const { dataHeap, ...restPrimaryCtaProps } = primaryCta ?? {};

  return (
    <Stack data-qa={dataQa} divider={<Divider />} spacing={3}>
      <Box alignItems="center">
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={{ xs: 2, md: 0 }}>
          <Box alignItems="center" sx={{ display: { xs: 'block', sm: 'flex' } }}>
            <Typography component="h3" data-qa={`${dataQa}-title`} variant={typographyVariants?.heading}>
              {heading}
            </Typography>
            {effectiveDate && <RteContent sx={{ ml: { xs: 0, sm: 1 } }} {...effectiveDate} />}
          </Box>
          <Box textAlign="right">
            {primaryCta && (
              <LoadingButton data-heap={dataHeap} fullWidth={isMobile} variant="contained" {...restPrimaryCtaProps}>
                {primaryCta.label}
              </LoadingButton>
            )}
          </Box>
        </Stack>
      </Box>

      {!Array.isArray(children) && children}

      {(!children || (Array.isArray(children) && !children.length)) && (
        <Stack spacing={3} sx={{ py: 8 }} textAlign="center">
          <Box sx={{ mx: 'auto', height: { xs: 80, md: 100 }, width: { xs: 80, md: 100 } }}>
            <img alt="" src={emptyState.img} />
          </Box>
          <RteContent data={emptyState.label} />
        </Stack>
      )}

      {Array.isArray(children) && !!children.length && (
        <Stack divider={<Divider />} spacing={3}>
          {children}
        </Stack>
      )}
    </Stack>
  );
};
